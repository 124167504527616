import { useMemo } from 'react';
import styled from 'styled-components';
import guid from '../../utils/guid';
import FormattingTooltip from '../basicComponents/tooltip/dollarsTooltip';
import { ClientEntityAllocations, ClientEntityCommitments } from './types';

type SimpleTableProps = {
  data: ClientEntityCommitments | ClientEntityAllocations | undefined;
};

const SimpleTable = ({ data }: SimpleTableProps) => {
  const tableRows = [
    { assets: 'Private Equity', target: data?.privateEquityPercentage, capacity: data?.privateEquityCapacity },
    { assets: 'Private Credit', target: data?.privateCreditPercentage, capacity: data?.privateCreditCapacity },
    { assets: 'Real Assets', target: data?.privateRealAssetPercentage, capacity: data?.privateRealAssetsCapacity }
  ];

  const footer = useMemo(() => {
    const sum = {
      total: 'Total',
      target: 0,
      capacity: 0
    };
    for (let i = 0; i < tableRows.length; i++) {
      sum.target += tableRows[i].target as number;
      sum.capacity += tableRows[i].capacity as number;
    }
    return sum;
  }, [data]);

  return (
    <SimpleTableWrap>
      <SimpleTableHeader>
        <HeaderItem>ASSET CLASS</HeaderItem>
        <HeaderItem>TARGET</HeaderItem>
        <HeaderItem>CAPACITY</HeaderItem>
      </SimpleTableHeader>
      <SimpleTableBody>
        {tableRows.map((el) => (
          <SimpleTableRow key={guid()}>
            <RowItem>{el.assets}</RowItem>
            <RowItem>{el.target?.toFixed(0)}%</RowItem>
            <RowItem>
              <FormattingTooltip>{el?.capacity as number}</FormattingTooltip>
            </RowItem>
          </SimpleTableRow>
        ))}
      </SimpleTableBody>
      <SimpleTableFooter>
        <FooterItem>{footer.total}</FooterItem>
        <FooterItem>{footer.target.toFixed(0)}%</FooterItem>
        <FooterItem>
          <FormattingTooltip>{footer.capacity}</FormattingTooltip>
        </FooterItem>
      </SimpleTableFooter>
    </SimpleTableWrap>
  );
};

const SimpleTableBody = styled.div`
  display: flex;
  flex-direction: column;
  //max-height: 150px;
  //overflow-y: scroll;
`;

const FooterItem = styled.div`
  width: 25%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-family: Blinker, serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0;
  color: ${({ theme }) => theme.font.strong};
`;

const SimpleTableFooter = styled.div`
  display: flex;
  border-radius: 4px;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.layer[2]};
  padding: 5px 24px;
`;

const RowItem = styled.div`
  width: 25%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-family: Blinker, serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0;
  color: ${({ theme }) => theme.font.base};
`;

const SimpleTableRow = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  background-color: ${({ theme }) => theme.layer[1]};
  margin-bottom: 3px;
  justify-content: space-between;
  border-radius: 4px;
  padding: 5px 24px;
`;

const HeaderItem = styled.div`
  width: 25%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-family: Blinker, serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0;
  color: ${({ theme }) => theme.header.action};
`;

const SimpleTableHeader = styled.div`
  display: flex;
  border-radius: 4px;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.header.base};
  padding: 5px 24px;
`;

const SimpleTableWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 3px;
`;

export default SimpleTable;
