import { getTimezoneDate, isValidDate } from '@/components/allocations-page/utils';
import BasicTooltip from '@/components/basicComponents/tooltip';
import DropdownActions from '@/components/table/fat-dropdownActions';
import NoResults from '@/components/table/noResults';
import { TableRowCell, TableRowWrapper, TextCell } from '@/components/table/styles';
import guid from '@/utils/guid';
import { format } from 'date-fns';
import { ROLE, tenantsTableColumns } from '../constants';
import { IUser } from '../types';

interface TableBodyProps {
  tenantsTableTableData?: IUser[];
  openModalWindow: (user: IUser, type: string) => void;
}

const TableBody = ({ tenantsTableTableData, openModalWindow }: TableBodyProps) => {
  if (!tenantsTableTableData?.length) {
    return <NoResults />;
  }

  return (
    <>
      {tenantsTableTableData.map((user: IUser) => {
        const lastLoginDate = new Date(user.lastLogin);
        const isLastLoginValidDate = isValidDate(lastLoginDate);
        const convertedLastLogin = isLastLoginValidDate ? getTimezoneDate(lastLoginDate) : null;
        const formattedLastLogin = isLastLoginValidDate ? format(lastLoginDate, 'MMM d, yyyy') : null;
        const tz_shorthand = lastLoginDate.toLocaleTimeString('en-us', { timeZoneName: 'short' }).split(' ')[2];
        const truncatedEmail = user.email.length > 30 ? `${user.email.substring(0, 30)}...` : user.email;

        return (
          <TableRowWrapper key={guid()}>
            <TableRowCell width={tenantsTableColumns[0].width}>
              <TextCell>{truncatedEmail}</TextCell>
            </TableRowCell>
            <TableRowCell width={tenantsTableColumns[1].width}>
              <TextCell>
                {user.lastLogin && isLastLoginValidDate && (
                  <BasicTooltip tooltipContent={`${formattedLastLogin} ${format(lastLoginDate, 'h:mm a')} ${tz_shorthand}`} zIndex={1000}>
                    {formattedLastLogin}
                  </BasicTooltip>
                )}
                {user.lastLogin && !isValidDate(new Date(user.lastLogin)) && user.lastLogin}
              </TextCell>
            </TableRowCell>
            <TableRowCell width={tenantsTableColumns[2].width}>
              <TextCell>{user.firstName}</TextCell>
            </TableRowCell>
            <TableRowCell width={tenantsTableColumns[3].width}>
              <TextCell>{user.lastName}</TextCell>
            </TableRowCell>
            <TableRowCell width={tenantsTableColumns[4].width}>
              <TextCell>{user.mobileNumber}</TextCell>
            </TableRowCell>
            <TableRowCell width={tenantsTableColumns[5].width}>
              <TextCell>
                {user?.roles?.length > 1 ? (
                  <BasicTooltip
                    tooltipContent={user.roles
                      .map((role) => ROLE[role])
                      .toString()
                      .replace(/,/g, ', ')}
                    zIndex={1000}
                  >
                    Multiple ({user.roles.length})
                  </BasicTooltip>
                ) : (
                  user?.roles && ROLE[user.roles[0]]
                )}
              </TextCell>
            </TableRowCell>
            <TableRowCell width={tenantsTableColumns[6].width}>
              <TextCell>{user.tenant.name}</TextCell>
            </TableRowCell>
            <TableRowCell width={tenantsTableColumns[7].width}>
              {user.lastLogin !== 'Disabled' && (
                <DropdownActions
                  items={[
                    {
                      name: 'Impersonate',
                      onClick: () => openModalWindow(user, 'impersonate')
                    }
                  ]}
                />
              )}
            </TableRowCell>
          </TableRowWrapper>
        );
      })}
    </>
  );
};

export default TableBody;
