import { ApprovedIcon } from '@/assets/icons/info-icons/approved';
import { InfoIcon } from '@/assets/icons/info-icons/info';
import Button from '@/components/fat-basicComponents/button';
import { useResponsive } from '@/hooks/use-responsive';
import guid from '@/utils/guid';
import { Dispatch, SetStateAction } from 'react';
import styled, { useTheme } from 'styled-components';
import { ButtonsControl, ImportText } from './styles';

interface StatusProps {
  errorsCount: number | null;
  matched: any;
  showTable: boolean;
  setShowTable: Dispatch<SetStateAction<boolean>>;
  startOver: () => void;
  confirmButton: {
    name: string;
    onClick: () => void;
    disabled?: boolean;
  };
  success: any;
  error: any;
}

export const Status = ({ errorsCount, matched, showTable, setShowTable, startOver, confirmButton, success, error }: StatusProps) => {
  const { isMobile } = useResponsive();
  const theme = useTheme();

  return (
    <>
      {!errorsCount ? (
        <>
          <EntityCodesWrapper>
            <ApprovedIcon width={75} height={75} fill={theme.context.success} />
            <MatchedWrapper isMobile={isMobile}>
              {matched.map((item: any) => (
                <ImportText key={guid()} fontWeight="400">
                  <CountWrap>
                    <Count>{item.count}</Count>
                  </CountWrap>
                  <ImportText fontWeight="700">{item.text}</ImportText>
                </ImportText>
              ))}
            </MatchedWrapper>
            <ImportText fontWeight="700">{success.allMatched}</ImportText>
            {showTable ? (
              <ImportText fontWeight="400" fontSize="16px" color={theme.font.weak}>
                {success.continue}
              </ImportText>
            ) : (
              <>
                <ImportText fontWeight="600" color={theme.font.action} style={{ cursor: 'pointer' }} onClick={() => setShowTable(true)}>
                  {success.viewAll}
                </ImportText>
                <ImportText fontWeight="400" fontSize="16px" color={theme.font.weak}>
                  {success.orContinue}
                </ImportText>
              </>
            )}
          </EntityCodesWrapper>
          {!showTable && (
            <ButtonsControl>
              <Button styleType="outline" size="lg" onClick={startOver}>
                Start Over
              </Button>
              <Button size="lg" styleType={confirmButton.disabled ? 'disabled' : 'default'} onClick={confirmButton.onClick}>
                {confirmButton.name}
              </Button>
            </ButtonsControl>
          )}
        </>
      ) : (
        <EntityCodesWrapper>
          <UnrecognizedWrapper>
            <InfoIcon width={30} height={30} fill={theme.context.warning} />
            <ImportText fontWeight="400">
              <CountWrap>
                <Count>{`${errorsCount}`}</Count>
              </CountWrap>
              <ImportText fontWeight="700">{error.text}</ImportText>
            </ImportText>
          </UnrecognizedWrapper>
          <p style={{ color: theme.font.weak }}>{error.description}</p>
        </EntityCodesWrapper>
      )}
    </>
  );
};

const EntityCodesWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 11px;
`;

const MatchedWrapper = styled.div<{ isMobile: boolean }>`
  display: flex;
  flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};
  gap: ${({ isMobile }) => (isMobile ? '15px' : '30px')};
`;

const UnrecognizedWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;

const CountWrap = styled.div`
  background-color: ${({ theme }) => theme.footer.base};
  display: inline-block;
  border-radius: 12px;
  font-size: 13px;
  margin-right: 6px;
  padding: 1px 15px;
`;

const Count = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: ${({ theme }) => theme.context.light};
  text-align: center;
  font-family: Blinker;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 22.4px;
`;
