import { Investment } from '@/components/fat-dealCatalog-page/types';
import { ISeriesCodesTableData, MissingIdError } from '@/components/fat-settings-page/importData/types';
import FilterPage from '@/components/table/fat-filterPage';
import TableHeader from '@/components/table/fat-tableHeader';
import { TableBodyWrapper, TableWrapper } from '@/components/table/styles';
import { ISort } from '@/components/table/types';
import { useResponsive } from '@/hooks/use-responsive';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { columns } from '../constants';
import MobileTable from './mobileTable';
import TableBody from './tableBody';

interface TableProps {
  tableData: ISeriesCodesTableData[];
  seriesCodesError: MissingIdError[];
  investmentList: Investment[];
  investmentListLoading: boolean;
  handleChangeAssignment: (investmentName: string, seriesCode: string) => void;
  resetAssignment: (seriesCode: string) => void;
}

const Table = ({ tableData, seriesCodesError, investmentList, investmentListLoading, handleChangeAssignment, resetAssignment }: TableProps) => {
  const { isMobile, isTablet } = useResponsive();
  const [sort, setSort] = useState<ISort>({
    key: 'ENTITY_CODE',
    asc: true
  });
  const [search, setSearch] = useState('');
  const [primaryFilter, setPrimaryFilter] = useState('All Series Codes');
  const [sortedTableData, setSortedTableData] = useState<ISeriesCodesTableData[]>([]);

  useEffect(() => {
    if (seriesCodesError?.length) {
      setPrimaryFilter('Unrecognized Series Codes');
      return;
    }
    setPrimaryFilter('All Series Codes');
  }, []);

  useEffect(() => {
    const sortedData = [...tableData].sort((a, b) => {
      switch (sort.key) {
        case 'SERIES_CODE':
          return sort.asc ? a.investmentCode.localeCompare(b.investmentCode) : b.investmentCode.localeCompare(a.investmentCode);
        case 'SERIES_NAME':
          return sort.asc ? a.seriesName?.localeCompare(b.seriesName) : b.seriesName?.localeCompare(a.seriesName);
        case 'ASSIGNMENT':
          const aAssignment = a.seriesAssignment === 'Select A Strategy' ? '' : a.seriesAssignment;
          const bAssignment = b.seriesAssignment === 'Select A Strategy' ? '' : b.seriesAssignment;
          return sort.asc ? aAssignment.localeCompare(bAssignment) : bAssignment.localeCompare(aAssignment);
        case 'STATUS':
          return sort.asc ? a.seriesStatus.localeCompare(b.seriesStatus) : b.seriesStatus.localeCompare(a.seriesStatus);
        default:
          return 0;
      }
    });

    setSortedTableData(sortedData);
  }, [sort, tableData]);

  const filteredTableData = () => {
    const newTableData = [...sortedTableData];
    const filteredTableData = newTableData.filter(
      (item) => item.seriesName?.toLowerCase().includes(search.toLowerCase()) || item.investmentCode.toLowerCase().includes(search.toLowerCase())
    );
    if (primaryFilter !== 'Unrecognized Series Codes') return filteredTableData;

    return filteredTableData.filter((item) => item.seriesStatus === 'Required' || item.seriesStatus === 'Complete');
  };

  return (
    <>
      <CustomFilterPage
        isMobile={isMobile}
        isTablet={isTablet}
        search={{
          value: search,
          onChange: setSearch,
          placeholder: 'Search'
        }}
        primaryFilter={{
          value: primaryFilter,
          onChange: setPrimaryFilter,
          selects: ['All Series Codes', 'Unrecognized Series Codes']
        }}
        resultsValue={filteredTableData().length}
        refetch={() => null as any}
      />
      <TableWrapper padding="0">
        {isMobile ? (
          <MobileTable
            tableData={filteredTableData()}
            investmentList={investmentList}
            investmentListLoading={investmentListLoading}
            handleChangeAssignment={handleChangeAssignment}
            resetAssignment={resetAssignment}
          />
        ) : (
          <>
            <CustomTableHeader isTablet={isTablet} refetch={() => null as any} columns={columns} savedSort={sort} savedSetSort={setSort} />
            <TableBodyWrapper>
              <TableBody
                tableData={filteredTableData()}
                investmentList={investmentList}
                investmentListLoading={investmentListLoading}
                handleChangeAssignment={handleChangeAssignment}
                resetAssignment={resetAssignment}
              />
            </TableBodyWrapper>
          </>
        )}
      </TableWrapper>
    </>
  );
};

export default Table;

const CustomFilterPage = styled(FilterPage)<{ isMobile: boolean; isTablet: boolean }>`
  position: sticky;
  top: ${({ isMobile, isTablet }) => (isMobile || isTablet ? '50px' : '0')};
  z-index: 9;
  margin-top: 0;
  margin-left: -1px;
  width: calc(100% + 2px);
  padding: ${({ isMobile }) => (isMobile ? '20px 0 0 0' : '20px 0 10px 0')};
`;

const CustomTableHeader = styled(TableHeader)<{ isTablet: boolean }>`
  position: sticky;
  top: ${({ isTablet }) => (isTablet ? '130px' : '80px')};
  z-index: 8;
  padding-top: 0;
`;
