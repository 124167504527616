import { getUTCDate } from '@/components/allocations-page/utils';
import BasicTooltip from '@/components/basicComponents/tooltip';
import FormattingTooltip from '@/components/basicComponents/tooltip/dollarsTooltip';
import { formatterClient } from '@/components/clients-page/utils';
import { TableBodyWrapper, TableRowCell } from '@/components/table/styles';
import { formatCash } from '@/utils/formatCash';
import styled from 'styled-components';
import { AssetClassTargetAllocationDto, IAllocationPacingTarget, SubAssetClassTargetAllocationDto } from '../../types';

interface CapacityCellProps {
  row: AssetClassTargetAllocationDto | SubAssetClassTargetAllocationDto;
  bold?: boolean;
  isMobile?: boolean;
  commitmentPlansTotal: number | null;
  targetOCRatio: number | null;
}

const capacityToolTipContent = (pacingTarget: IAllocationPacingTarget) => {
  if (!pacingTarget) return <></>;

  return (
    <TableBodyWrapper>
      <TableRowCell width={100}>
        <TitleCell>Plan Start {getUTCDate(new Date()).toUpperCase()}</TitleCell>
      </TableRowCell>
      <TableRowCell width={100}>
        <TitleCell>Cumulative Plan Goal Since Start: {formatterClient.format(pacingTarget.cumulativePacingTarget)}</TitleCell>
      </TableRowCell>
      <TableRowCell width={100}>
        <TitleCell>Commitments Since Start: {formatterClient.format(pacingTarget.cumulativeCommitments)}</TitleCell>
      </TableRowCell>
      <TableRowCell width={100}>
        <TitleCell>
          Remaining Pacing Target Through {getUTCDate(new Date(pacingTarget.pacingTargetThroughDate)).toUpperCase()}:{' '}
          {formatterClient.format(pacingTarget.remainingPacingTarget)}
        </TitleCell>
      </TableRowCell>
    </TableBodyWrapper>
  );
};

const CapacityCell = ({ row, bold = false, isMobile = false, commitmentPlansTotal, targetOCRatio }: CapacityCellProps) => {
  if (commitmentPlansTotal !== null && commitmentPlansTotal > 0 && targetOCRatio === null) {
    return (
      <TitleCell bold={bold}>
        {row.illiquid ? (
          <CellWithTooltip>
            {formatCash(row?.pacingTarget?.remainingPacingTarget)}
            <TooltipWrapper isMobile={isMobile} id="infoWrap">
              <BasicTooltip tooltipContent={capacityToolTipContent(row.pacingTarget)} withTooltipIcon zIndex={1000} />
            </TooltipWrapper>
          </CellWithTooltip>
        ) : (
          <CellWithTooltip>
            {formatCash(row.capacity)}
            <TooltipWrapper isMobile={isMobile} id="infoWrap">
              <BasicTooltip
                tooltipContent="This is an evergreen category and the pacing target reflects the total overall capacity."
                tooltipWidth={400}
                withTooltipIcon
                zIndex={1000}
              />
            </TooltipWrapper>
          </CellWithTooltip>
        )}
      </TitleCell>
    );
  }

  if (commitmentPlansTotal !== null && commitmentPlansTotal === 0 && targetOCRatio === null) {
    return (
      <TitleCell bold={bold}>
        {row.illiquid ? (
          <CellWithTooltip>
            --
            <TooltipWrapper isMobile={isMobile} id="infoWrap">
              <BasicTooltip
                tooltipContent="Create a commitment plan to see capacity for drawdown asset classes."
                tooltipWidth={400}
                withTooltipIcon
                zIndex={1000}
              />
            </TooltipWrapper>
          </CellWithTooltip>
        ) : (
          <FormattingTooltip zIndex={1000}>{row.capacity}</FormattingTooltip>
        )}
      </TitleCell>
    );
  }

  return (
    <TitleCell bold={bold}>
      <FormattingTooltip zIndex={1000}>{row.capacity}</FormattingTooltip>
    </TitleCell>
  );
};

export default CapacityCell;

const CellWithTooltip = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const TooltipWrapper = styled.div<{ isMobile: boolean }>`
  opacity: ${({ isMobile }) => (isMobile ? 1 : 0)};
`;

const TitleCell = styled.span<{ color?: string; bold?: boolean }>`
  font-family: Blinker, serif;
  font-size: 16px;
  font-weight: ${({ bold }) => (bold ? 600 : 400)};
  line-height: 22px;
  letter-spacing: 0;
  text-align: left;
  color: ${({ theme }) => theme.font.base};
`;
