import * as React from 'react';

export const EditIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={props.width || 23}
    height={props.height || 24}
    onClick={props.onClick || undefined}
    style={props?.style}
    viewBox="0 0 23 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.4578 4.29429L17.3815 3.37832C17.5012 3.25838 17.6434 3.16323 17.7999 3.09831C17.9564 3.03339 18.1241 3 18.2936 3C18.463 3 18.6308 3.03339 18.7873 3.09831C18.9438 3.16323 19.086 3.25838 19.2057 3.37832L20.1217 4.29429C20.2416 4.41398 20.3367 4.55618 20.4017 4.71268C20.4666 4.86919 20.5 5.03698 20.5 5.20642C20.5 5.37586 20.4666 5.54364 20.4017 5.70015C20.3367 5.85666 20.2416 5.99881 20.1217 6.1185L19.2057 7.04224L16.4578 4.29429ZM15.5418 5.21802L8.6875 12.0723V14.8125H11.4277L18.2819 7.95821L15.5418 5.21802Z"
      fill={props.fill || '#4587EC'}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.9918 6.00373H4.37313V19.1269H17.4963V10.5017L19.375 8.6143V19.1269C19.3735 19.6242 19.1749 20.1006 18.8228 20.4517C18.4706 20.8028 17.9936 21 17.4963 21H4.37313C3.87681 20.9985 3.40124 20.8007 3.05028 20.4497C2.69932 20.0988 2.50148 19.6232 2.5 19.1269V6.00373C2.5 5.50643 2.69717 5.02941 3.04829 4.67723C3.3994 4.32506 3.87583 4.12649 4.37313 4.125H14.8632L12.9918 6.00373Z"
      fill={props.fill || '#4587EC'}
    />
  </svg>
);
