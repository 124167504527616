import { useQuery } from '@apollo/client';
import { useState } from 'react';
import styled from 'styled-components';

import ModalWrappedContent from '@/components/fat-basicComponents/modal/modalWrappedContent';
import { User } from '../../../types/user';
import FilterSelect from '../../basicComponents/select';
import { OWNERS_QUERY } from '../queries';
import { IRowData } from '../types';

interface EditOwnerProps {
  onClose: () => void;
  updateAllocationOwners: (variables: any) => void;
  isOpen: boolean;
  allocation: IRowData | null;
  refetch: any;
}

const EditOwner = ({ onClose, updateAllocationOwners, isOpen, allocation, refetch }: EditOwnerProps) => {
  if (!allocation) return <></>;
  const [advisor, setAdvisor] = useState(`${allocation?.advisorOwner?.firstName ?? ''} ${allocation?.advisorOwner?.lastName ?? ''}`);
  const [operations, setOperations] = useState(`${allocation?.operationsOwner?.firstName ?? ''} ${allocation?.operationsOwner?.lastName ?? ''}`);
  const { data } = useQuery(OWNERS_QUERY, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'no-cache',
    variables: {
      id: allocation.id
    }
  });

  const saveSelectors = () => {
    const [advisorFirstName, advisorLastName] = advisor.split(' ');
    const [operationsFirstName, operationsLastName] = operations.split(' ');
    const newAdvisorOwner = data.AllocationOwners.advisorOwners.find(
      (advisorOwner: User) => advisorOwner.firstName === advisorFirstName && advisorOwner.lastName === advisorLastName
    );
    const newOperationsOwner = data.AllocationOwners.operationsOwners.find(
      (operationsOwner: User) => operationsOwner.firstName === operationsFirstName && operationsOwner.lastName === operationsLastName
    );
    updateAllocationOwners({
      variables: {
        data: {
          id: allocation.id,
          advisorOwnerId: newAdvisorOwner?.id,
          operationsOwnerId: newOperationsOwner?.id
        }
      }
    });
    onClose();
  };
  const shouldDisabledSave = !data?.AllocationOwners?.advisorOwners?.length && !data?.AllocationOwners?.operationsOwners?.length;
  return (
    <ModalWrappedContent
      isOpen={isOpen}
      onClose={onClose}
      title={'Edit Owner'}
      confirmButton={{ name: 'Save', onClick: saveSelectors, disabled: shouldDisabledSave }}
      showRequiredFields
    >
      <Selectors>
        <FilterSelect
          data={data?.AllocationOwners?.advisorOwners.map((owner: User) => `${owner.firstName} ${owner.lastName}`) || []}
          selected={advisor}
          setSelected={setAdvisor}
          disabled={!data?.AllocationOwners?.advisorOwners.length}
          label={'Advisor'}
          width={'100%'}
          required
          minHeight="37px"
        />
        <FilterSelect
          data={data?.AllocationOwners?.operationsOwners.map((owner: User) => `${owner.firstName} ${owner.lastName}`) || []}
          selected={operations}
          setSelected={setOperations}
          disabled={!data?.AllocationOwners?.operationsOwners.length}
          label={'Operations'}
          width={'100%'}
          required
          minHeight="37px"
        />
      </Selectors>
    </ModalWrappedContent>
  );
};

export default EditOwner;

const Selectors = styled.div`
  display: flex;
  justify-content: space-between;
  column-gap: 10px;
`;
