import LoginHero from '@/components/authentication/login-hero';
import ResetPassword from '@/components/authentication/resetPassword';
import { useAuth } from '@/hooks/use-auth';
import { useResponsive } from '@/hooks/use-responsive';
import { useTheme } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { Helmet as Head } from 'react-helmet';
import styled from 'styled-components';

type Platform = 'Amplify' | 'Auth0' | 'Firebase' | 'JWT';

const PasswordRecovery = () => {
  const { platform }: { platform: Platform } = useAuth();
  const { isMobile, isTablet, isPortrait } = useResponsive();
  const [loginPageHeight, setLoginPageHeight] = useState('');
  const [isNextReady, setIsNextReady] = useState(false);

  const theme = useTheme();
  // useEffect(() => {
  //   gtm.push({ event: 'page_view' });
  // }, []);

  useEffect(() => {
    setIsNextReady(true);
    setLoginPageHeight(window.innerHeight.toString() + 'px');
  }, [isPortrait]);

  return (
    <>
      <Head>
        <title>Password Recovery | Material Kit Pro</title>
      </Head>
      {isNextReady && (
        <Container isMobile={isMobile} isTablet={isTablet} isPortrait={isPortrait} loginPageHeight={loginPageHeight}>
          {isMobile || (isPortrait && isTablet) ? (
            <HeroBlockMobile backgroundColor="#36474f">
              <LoginHero />
            </HeroBlockMobile>
          ) : (
            <HeroBlock backgroundColor="#36474f">
              <LoginHero />
            </HeroBlock>
          )}
          {isMobile || (isPortrait && isTablet) ? (
            <ResetPasswordMobileWrap>
              <ResetPassword />
            </ResetPasswordMobileWrap>
          ) : (
            <ResetPasswordWrap>
              <ResetPassword />
            </ResetPasswordWrap>
          )}
        </Container>
      )}
    </>
  );
};

export default PasswordRecovery;

const Container = styled.div<{ isMobile: boolean; isTablet: boolean; isPortrait: boolean; loginPageHeight: string }>`
  height: ${({ isMobile, isTablet, isPortrait, loginPageHeight }) => (isMobile || (isPortrait && isTablet) ? loginPageHeight : '100vh')};
  width: 100%;
  display: flex;
  flex-direction: ${({ isMobile, isTablet, isPortrait }) => (isMobile || (isPortrait && isTablet) ? 'column' : 'row')};
  justify-content: center;
`;

const HeroBlock = styled.div<{ backgroundColor?: string }>`
  background-color: ${({ backgroundColor }) => (backgroundColor ? backgroundColor : '#36474f')};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 50%;
  position: relative;
`;

const HeroBlockMobile = styled.div<{ backgroundColor?: string }>`
  width: 100%;
  height: 36%;
  background-color: ${({ backgroundColor }) => (backgroundColor ? backgroundColor : '#36474f')};
`;

const ResetPasswordWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  width: 50%;
  overflow: hidden;
  position: relative;
`;

const ResetPasswordMobileWrap = styled(ResetPasswordWrap)`
  width: 100%;
`;
