import * as React from 'react';

export const AllocationsMenuIcon = ({ ...props }: React.SVGProps<SVGSVGElement>) => (
  <svg
    style={props.style}
    width={props.width || '28'}
    height={props.height || '28'}
    viewBox="0 0 28 28"
    fill={props.fill || 'none'}
    stroke={props.stroke || undefined}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M24.9981 6.45813L12.4832 18.9731L7.82626 14.3162L9.37415 12.7683L12.4832 15.8773L23.4568 4.89706L24.9981 6.45813ZM22.5742 11.9911C22.7265 12.6387 22.8039 13.3018 22.8047 13.9671C22.809 15.9209 22.1629 17.8207 20.9684 19.3668C19.7738 20.9129 18.0986 22.0175 16.207 22.5065C14.3153 22.9954 12.3147 22.8409 10.5206 22.0674C8.7264 21.2938 7.24068 19.9451 6.29763 18.2339C5.35458 16.5227 5.00782 14.5464 5.31202 12.6164C5.61621 10.6864 6.55408 8.91247 7.97775 7.57432C9.40141 6.23617 11.2299 5.40986 13.175 5.22566C15.1202 5.04145 17.0713 5.50982 18.7209 6.55693L20.3017 4.9761C18.4642 3.68374 16.2709 2.99332 14.0244 3.00005C11.6844 2.99484 9.40364 3.73603 7.51368 5.11588C5.62373 6.49573 4.22309 8.44234 3.51527 10.6728C2.80746 12.9032 2.82938 15.3013 3.57787 17.5184C4.32635 19.7356 5.76236 21.6562 7.67723 23.0013C9.5921 24.3464 11.886 25.0457 14.2256 24.9977C16.5652 24.9497 18.8285 24.1569 20.6866 22.7344C22.5447 21.3119 23.9007 19.334 24.5577 17.088C25.2146 14.842 25.1381 12.4451 24.3394 10.2456L22.5742 11.9911Z"
      fill={props.fill || '#FAFAFA'}
    />
  </svg>
);
