import styled from 'styled-components';

import FormattingTooltip from '@/components/basicComponents/tooltip/dollarsTooltip';
import { IInvestmentEntity } from '@/components/fat-dealCatalog-page/fat-seekingInterest-page/types';
import { investorColumns } from '../constatnts';

interface TableSumFooterProps {
  listInvestmentEntity: IInvestmentEntity[];
}

const TableSumFooterInvestor = ({ listInvestmentEntity }: TableSumFooterProps) => {
  const total = listInvestmentEntity.reduce<{
    estimatedCommitment: number;
    taxableEstimate: number;
    exemptEstimate: number;
  }>(
    (sum, value: IInvestmentEntity) => {
      return {
        estimatedCommitment: (sum.estimatedCommitment += Number(value.investor_interest[0]?.amount)),
        taxableEstimate: !value.investor_interest[0]?.exempt
          ? (sum.taxableEstimate += Number(value.investor_interest[0]?.amount))
          : (sum.taxableEstimate += 0),
        exemptEstimate: value.investor_interest[0]?.exempt
          ? (sum.exemptEstimate += Number(value.investor_interest[0]?.amount))
          : (sum.exemptEstimate += 0)
      };
    },
    { estimatedCommitment: 0, taxableEstimate: 0, exemptEstimate: 0 }
  );

  return (
    <TableSumWrap>
      <TableSumItem width={investorColumns[0].width}>Total</TableSumItem>
      <TableSumItem width={investorColumns[1].width}></TableSumItem>
      <TableSumItem width={investorColumns[2].width}></TableSumItem>
      <TableSumItem width={investorColumns[3].width}>
        <FormattingTooltip zIndex={1000}>{total.taxableEstimate}</FormattingTooltip>
      </TableSumItem>
      <TableSumItem width={investorColumns[4].width}>
        <FormattingTooltip zIndex={1000}>{total.exemptEstimate}</FormattingTooltip>
      </TableSumItem>
      <TableSumItem width={investorColumns[5].width}>
        <FormattingTooltip zIndex={1000}>{total.estimatedCommitment}</FormattingTooltip>
      </TableSumItem>
      <TableSumItem width={investorColumns[6].width}></TableSumItem>
    </TableSumWrap>
  );
};

const TableSumWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 0 24px;
  width: 100%;
  min-height: 60px;
  background-color: ${({ theme }) => theme.layer[2]};
  border-radius: 8px;
  margin-top: 6px;
  margin-bottom: 30px;
`;

const TableSumItem = styled.div<{ width?: number; justifyContent?: string }>`
  display: flex;
  justify-content: ${({ justifyContent }) => `${justifyContent}`};
  width: ${({ width }) => `${width}%`};
  color: ${({ theme }) => theme.font.base};
  font-family: Blinker, serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0;
`;

export default TableSumFooterInvestor;
