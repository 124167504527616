import { format } from 'date-fns';
import styled, { useTheme } from 'styled-components';

import { CalendarGrey } from '@/assets/static/icons/dashboard-page/calendar-grey';
import { SandClock } from '@/assets/static/icons/dashboard-page/sand-clock';
import { useNavigate } from 'react-router-dom';
import { ContainerPage } from '../../../styles/common';
import { dateFormatter } from '../../../utils/dateFormatter';
import { IClose, ITypeClose } from '../types/funds-closing-soon';

interface CloseItemProps {
  title: string;
  data: IClose[];
  isMobile: boolean;
}

export const CloseItem = ({ title, data, isMobile }: CloseItemProps) => {
  const navigate = useNavigate();
  const theme = useTheme();

  const redirectToAllocation = (investments: string) => {
    const investmentsParams = encodeURIComponent(JSON.stringify([investments]));

    navigate(`/recommendations?investments=${investmentsParams}`);
  };

  const getClose = (days: IClose['daysToClose'], date: IClose['dateObject']) => {
    if (days === 0) return 'Today';
    const amountDays = `${days} Day${days === 1 ? '' : 's'}`;
    return days < 15 ? amountDays : dateFormatter(format(new Date(date), 'MM/dd/yyyy'), 'MM/dd/yyyy');
  };

  const getColorOption = (type: ITypeClose) => {
    switch (type) {
      case 'toTwoWeeks':
        return theme.context.error;
      case 'fromTwoWeeksToMonth':
      case 'fromMonth':
        return theme.font.weak;
      default:
        break;
    }
  };

  const getImageOption = (type: ITypeClose) => {
    switch (type) {
      case 'toTwoWeeks':
        return <SandClock fill={theme.context.error} />;
      case 'fromTwoWeeksToMonth':
      case 'fromMonth':
        return <CalendarGrey fill={theme.font.weak} />;
      default:
        break;
    }
  };

  return (
    <ContentItem isMobile={isMobile}>
      <ItemTitle>{title}</ItemTitle>
      <FundRow>
        <FundText>Fund</FundText>
        <FundText>Recommendations</FundText>
      </FundRow>
      {data.length ? (
        <Rows>
          {data.map(({ count, id, name, daysToClose, dateObject, type }) => (
            <Row key={id}>
              {getImageOption(type)}
              <RowInfo>
                <RowTitle>{name}</RowTitle>
                <RowCloses color={getColorOption(type)}>{`Closes: ${getClose(daysToClose, dateObject)}`}</RowCloses>
              </RowInfo>
              <RowOutstanding onClick={() => redirectToAllocation(name)}>{count}</RowOutstanding>
            </Row>
          ))}
        </Rows>
      ) : (
        <RowTitle style={{ paddingTop: '16px' }}>{`No approaching ${title.toLowerCase()} dates`}</RowTitle>
      )}
    </ContentItem>
  );
};

const ContentItem = styled(ContainerPage)<{ isMobile?: boolean }>`
  width: 50%;
  display: flex;
  flex-direction: column;
  padding: ${({ isMobile }) => (isMobile ? '20px 13px' : '20px 40px')};
  background: ${({ theme }) => theme.layer[1]};
  border-radius: 10px;

  @media (max-width: 1300px) {
    width: 100%;
  }
`;

const ItemTitle = styled.span`
  font-family: Lato;
  font-weight: 700;
  font-size: 23px;
  color: ${({ theme }) => theme.font.strong};
`;

const FundRow = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${({ theme }) => theme.border.base};
  padding: 20px 0;
`;

const FundText = styled.span`
  font-family: Lato;
  font-weight: 700;
  font-size: 16px;
  color: ${({ theme }) => theme.font.weak};
`;

const Rows = styled.div`
  display: flex;
  flex-direction: column;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.border.base};
  padding: 20px 0;
`;

const RowInfo = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  column-gap: 10px;
  padding: 0 15px;
`;

const RowTitle = styled.span`
  color: ${({ theme }) => theme.font.base};
  font-family: Lato;
  font-weight: 700;
  font-size: 16px;
`;

const RowCloses = styled.span<{ color: string }>`
  font-family: Lato;
  font-weight: 500;
  font-size: 13px;
  color: ${({ color }) => color};
`;

const RowOutstanding = styled.div`
  height: 50px;
  max-width: 100px;
  min-width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.font.action};
  border-radius: 5px;
  background: ${({ theme }) => theme.layer.base};
  padding: 10px;
  color: ${({ theme }) => theme.font.action};
  font-size: 19px;
  cursor: pointer;
`;
