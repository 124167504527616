import FormattingTooltip from '@/components/basicComponents/tooltip/dollarsTooltip';
import NoResults from '@/components/table/noResults';
import RedirectTitleCell from '@/components/table/redirectTitleCell';
import { TableRowCell, TableRowWrapper, TextCell } from '@/components/table/styles';
import { TableColumns } from '@/components/table/types';
import { useAuth } from '@/hooks/use-auth';
import guid from '@/utils/guid';
import styled from 'styled-components';
import { IListEntity } from '../types';

interface TableBodyEntityProps {
  listEntity: IListEntity[];
  columns: TableColumns[];
  openInvestorDetails: (id: string) => void;
  openEntityDetails: (investorId: string, entityId: string) => void;
}

const TableBodyEntity = ({ listEntity, columns, openInvestorDetails, openEntityDetails }: TableBodyEntityProps) => {
  const { user } = useAuth();

  if (!listEntity?.length) {
    return <NoResults />;
  }

  return (
    <>
      {listEntity.map((row) => (
        <CustomTableRowWrapper key={row.id}>
          <TableRowCell width={columns[0].width}>
            <RedirectTitleCell id="actionCurioText" onClick={() => openEntityDetails(row.family.id, row.id)}>
              {row.entityName}
            </RedirectTitleCell>
          </TableRowCell>
          <TableRowCell width={columns[1].width}>
            <RedirectTitleCell id="actionCurioText" onClick={() => openInvestorDetails(row.family.id)}>
              {row.family.name}
            </RedirectTitleCell>
          </TableRowCell>
          <TableRowCell width={columns[2].width}>{user && user.tenant.type === 'fundManager' && <TextCell>{row.tenant.name}</TextCell>}</TableRowCell>
          <TableRowCell width={columns[3].width}>
            <TextCell>{row.investmentCount}</TextCell>
          </TableRowCell>
          <TableRowCell width={columns[4].width}>
            <TextCell>
              {row.ignoreAllocationTargets ? (
                <>--</>
              ) : (
                <FormattingTooltip zIndex={1000} key={guid()}>
                  {row.totalEntityValue}
                </FormattingTooltip>
              )}
            </TextCell>
          </TableRowCell>
          <TableRowCell width={columns[5].width}>
            <TextCell>
              <FormattingTooltip zIndex={1000} key={guid()}>
                {row.totalCommitments}
              </FormattingTooltip>
            </TextCell>
          </TableRowCell>
        </CustomTableRowWrapper>
      ))}
    </>
  );
};

export default TableBodyEntity;

const CustomTableRowWrapper = styled(TableRowWrapper)`
  justify-content: space-between;
`;
