import React, { useState } from 'react';
import {
  MobileRow,
  MobileRowTitle,
  MobileRowValue,
  NoResults
} from '@/components/table/mobileTable/mobileTable';
import styled, { css } from 'styled-components';

import { IInfoIcon } from '@/assets/icons/info-icons/i-info';
import guid from '@/utils/guid';
import BasicTooltip from '@/components/basicComponents/tooltip';
import { OPERATIONS_STATUS } from '@/components/opportunities-entity/proposed';
import DropdownActions, {
  EmptyDropdownActions
} from '@/components/table/dropdownActions';
import RedirectTitleCell from '@/components/table/redirectTitleCell';
import { blinkingFrame } from '@/components/table/styles';
import { IItems, TableColumns } from '@/components/table/types';
import Amount from './cell/amount';
import ClientsAdvisor from './cell/clientsAdvisor';
import Entity from './cell/entity';
import LastUpdate from './cell/lastUpdate';
import NextStep from './cell/nextStep';
import { ModalWindowType } from './modals/types';
import { FieldsForRedirectWindows, IRowData, TypeWindow } from './types';

type MobileTableProps = {
  columns: TableColumns[];
  recommendationsRows: IRowData[] | [];
  updateLoading: boolean;
  detailsClick: (type: TypeWindow, id: FieldsForRedirectWindows) => void;
  openModalWindow: (type: ModalWindowType, row: IRowData) => void;
  currentRow: IRowData | null;
  changeSelectRow: (row: IRowData, entityName: string) => void;
  canShowChangeAmountButton: (row: IRowData) => any; // need fix it
  actionsByStatus: (row: IRowData) => IItems[];
  onClickEntity: (data: IRowData) => void;
  onClickInvestment: (data: IRowData) => void;
  omClickLastUpdate: (data: IRowData) => void;
};

const MobileTable = ({
  columns,
  recommendationsRows,
  openModalWindow,
  currentRow,
  changeSelectRow,
  canShowChangeAmountButton,
  actionsByStatus,
  detailsClick,
  updateLoading,
  onClickEntity,
  onClickInvestment,
  omClickLastUpdate
}: MobileTableProps) => {
  const [selectedRow, setSelectedRow] = useState<string>();

  const TableRow = ({ el }: { el: IRowData }) => {
    return (
      <MobileTableRowWrap
        key={guid()}
        loading={updateLoading && el.id === currentRow?.id}
        onClick={() => setSelectedRow(el.id)}
        isSelected={el.id === selectedRow}
      >
        <MobileRow>
          <MobileRowTitle>{columns[0].title}</MobileRowTitle>
          <MobileRowValue>
            <ClientsAdvisor
              onRedirect={() =>
                detailsClick('details', {
                  familyName: el.legalEntity.family.name
                })
              }
              familyName={el.legalEntity.family.name}
              advisor={el?.legalEntity?.advisor?.initials}
            />
          </MobileRowValue>
        </MobileRow>

        <MobileRow>
          <MobileRowTitle>{columns[1].title}</MobileRowTitle>
          <MobileRowValue>
            <Entity
              onClick={onClickEntity}
              isMobile
              row={el}
              changeSelect={(value) => changeSelectRow(el, value)}
              onRedirect={() =>
                detailsClick('entityDetails', {
                  id: el.legalEntity.id,
                  entityName: el.legalEntity.entityName,
                  familyId: el.legalEntity.family.id,
                  familyName: el.legalEntity.family.name
                })
              }
            />
          </MobileRowValue>
        </MobileRow>

        <MobileRow>
          <MobileRowTitle>{columns[2].title}</MobileRowTitle>
          <MobileRowValue onClick={() => onClickInvestment(el)}>
            <RedirectTitleCell color={'#4587EC'}>
              {el.investment.name}
            </RedirectTitleCell>
          </MobileRowValue>
        </MobileRow>

        <MobileRow>
          <MobileRowTitle>{columns[3].title}</MobileRowTitle>
          <AmountMobileRowValue>
            {el.committedCapital !== el.legalEntity.biteSize &&
              !(
                [
                  OPERATIONS_STATUS.READY_FOR_CLIENT_REVIEW,
                  OPERATIONS_STATUS.SENT_TO_CLIENT
                ].includes(el.operationsStatus) && el.reason === 'Always Show'
              ) && (
                <BasicTooltip tooltipContent={'Differs from default bite size'}>
                  <IconWrapper>
                    <IInfoIcon
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyItems: 'center'
                      }}
                      fill={'#EF9208'}
                    />
                  </IconWrapper>
                </BasicTooltip>
              )}
            {!el.isAmountHidden ? (
              <Amount
                isMobile
                showChangeAmountButton={canShowChangeAmountButton(el)}
                amount={el.committedCapital}
                openWindow={() => openModalWindow('amount', el)}
              />
            ) : null}
          </AmountMobileRowValue>
        </MobileRow>

        <MobileRow>
          <MobileRowTitle>{columns[4].title}</MobileRowTitle>
          <MobileRowValue>
            {el.assignee &&
              `${el.assignee.firstName && el.assignee?.firstName} ${
                el.assignee?.lastName && el.assignee.lastName
              }`}
          </MobileRowValue>
        </MobileRow>

        <MobileRow>
          <MobileRowTitle>{columns[5].title}</MobileRowTitle>
          <MobileRowValue onClick={() => omClickLastUpdate(el)}>
            <LastUpdate isMobile row={el} />
          </MobileRowValue>
        </MobileRow>

        <MobileRow>
          <MobileRowTitle>{columns[6].title}</MobileRowTitle>
          <MobileRowValue>
            <NextStep width={'80%'} status={el.operationsStatus} />
            {actionsByStatus(el).length && el.id === selectedRow ? (
              <DropdownActions
                isMobile
                items={actionsByStatus(el)}
                disabled={updateLoading && el.id === currentRow?.id}
              />
            ) : (
              <EmptyDropdownActions />
            )}
          </MobileRowValue>
        </MobileRow>
      </MobileTableRowWrap>
    );
  };

  return (
    <>
      {recommendationsRows.length ? (
        recommendationsRows.map((el, index) => {
          return <TableRow el={el} key={el.id + index} />;
        })
      ) : (
        <NoResults>No Results</NoResults>
      )}
    </>
  );
};

export default MobileTable;

const AmountMobileRowValue = styled(MobileRowValue)`
  gap: 5px;
`;
const IconWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const MobileTableRowWrap = styled.div<{
  zIndex?: number;
  loading?: boolean;
  isSelected?: boolean;
}>`
  //z-index: 30;
  width: 100%;
  padding: 14px 24px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  background-color: ${({ isSelected }) => (isSelected ? '#f0f1f3' : '#fff')};
  border-radius: 8px;
  opacity: ${({ loading }) => (loading ? 0.5 : 1)};
  pointer-events: ${({ loading }) => (loading ? 'none' : 'auto')};
  animation: ${({ loading }) => (loading ? blinkingFrame : null)} 1.5s infinite;
  ${({ zIndex }) =>
    zIndex &&
    css`
      z-index: ${zIndex};
    `}
`;
