import { useMutation, useQuery } from '@apollo/client';
// import { parse } from 'csv-parse/sync';
import { useState } from 'react';
import {
  CloseBtn,
  ModalControlWrap,
  ModalPageTitle
} from '@/components/client-details';
import { TierDropdown } from '@/components/dueDiligence-page/dueDiligenceRecord/tierDropdown';
import {
  DUE_DILIGENCE_FUNNEL_LIST_QUERY,
  IMPORT_DUE_DILIGENCE_RECORD_MUTATION
} from '@/components/dueDiligence-page/queries';
import { IFunnelData } from '@/components/dueDiligence-page/types';
import Header from '@/components/header';
import { SORT_ORDER } from '@/components/table/types';
import { useResponsive } from '@/hooks/use-responsive';
import { CloseInfoIcon } from '@/assets/icons/info-icons/closeInfo';
import { MainWrap, PaddingWrap } from '@/styles/common';
import styled from 'styled-components';
import FilterSelect from '../../../basicComponents/select';
import { IFile } from '../../../dueDiligence-page/dueDiligenceRecord';
import { DropFileArea } from '../../../dueDiligence-page/dueDiligenceRecord/dropFileArea';
import { Loading } from '../../../loading';
import {
  importDealAssistantAIGroundTruth,
  importDealAssistantAIPromptGroup
} from '../../queries';
import { SelectLabel } from '../importData';
import { parse } from 'csv-parse/sync';

interface DueDiligenceImportDataProps {
  handleClose: () => void;
}

export const DueDiligenceImportData = ({
  handleClose
}: DueDiligenceImportDataProps) => {
  const { isMobile, isTablet } = useResponsive();
  const fileExtensions = ['CSV'];
  const [DDRImportFunnel, setDDRImportFunnel] = useState<IFunnelData[]>([]);
  const [promptsImportFunnel, setPromptsImportFunnel] = useState<IFunnelData[]>(
    []
  );
  const [groundTruthImportFunnel, setGroundTruthImportFunnel] = useState<
    IFunnelData[]
  >([]);
  const [drag, setDrag] = useState(false);
  const [files, setFiles] = useState<IFile[]>([]);

  const { data, loading, error } = useQuery(DUE_DILIGENCE_FUNNEL_LIST_QUERY, {
    notifyOnNetworkStatusChange: true,
    variables: {
      data: {
        statusFilter: ['PUBLISHED'],
        sortDirection: SORT_ORDER.ASC,
        sortBy: 'ORDER'
      }
    },
    onCompleted: (data) => {
      setDDRImportFunnel(data.listDueDiligenceFunnels);
      setPromptsImportFunnel(data.listDueDiligenceFunnels);
      setGroundTruthImportFunnel(data.listDueDiligenceFunnels);
    }
  });

  const [importDDRRecords, { loading: importLoading }] = useMutation(
    IMPORT_DUE_DILIGENCE_RECORD_MUTATION
  );
  const [importPrompts, { loading: importPromptsLoading }] = useMutation(
    importDealAssistantAIPromptGroup
  );
  const [importGroundTruth, { loading: imporGroundTruthLoading }] = useMutation(
    importDealAssistantAIGroundTruth
  );

  const [selectedDDRImportFunnel, setSelectedDDRImportFunnel] =
    useState<IFunnelData>();
  const [selectedPromptsImportFunnel, setSelectedPromptsImportFunnel] =
    useState<IFunnelData>();
  const [selectedGroundTruthImportFunnel, setSelectedGroundTruthImportFunnel] =
    useState<IFunnelData>();

  const handleDDRImportFunnelChange = (value: string) => {
    const chosenItem = DDRImportFunnel.find((item) => item.name === value);
    if (chosenItem) {
      setSelectedDDRImportFunnel(chosenItem);
    }
  };

  const handlePromptsImportFunnelChange = (value: string) => {
    const chosenItem = promptsImportFunnel.find((item) => item.name === value);
    if (chosenItem) {
      setSelectedPromptsImportFunnel(chosenItem);
    }
  };

  const handleGroundTruthImportFunnelChange = (value: string) => {
    const chosenItem = promptsImportFunnel.find((item) => item.name === value);
    if (chosenItem) {
      setSelectedGroundTruthImportFunnel(chosenItem);
    }
  };

  const onDDRImportDropHandler = async (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();

    if (!selectedDDRImportFunnel) return;
    const uploadFiles = e.dataTransfer.files;

    const text = await uploadFiles[0].text();
    const records = parse(text, {
      columns: true,
      skip_empty_lines: true
    });

    const response = await importDDRRecords({
      variables: {
        data: {
          funnelId: selectedDDRImportFunnel.id,
          jsonString: JSON.stringify(records)
        }
      }
    });

    setDrag(false);
    if (uploadFiles && uploadFiles.length > 0) {
      setFiles([
        ...files,
        { ...uploadFiles[0], name: uploadFiles[0].name, loading: true }
      ]);
    }
  };

  const DDRImporthandleUploadFile = async (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    e.preventDefault();
    const uploadFiles = e.target.files;

    if (uploadFiles && uploadFiles.length > 0) {
      setFiles([
        ...files,
        { ...uploadFiles[0], name: uploadFiles[0].name, loading: true }
      ]);
    }
  };

  const promptsImportOnDropHandler = async (
    e: React.DragEvent<HTMLDivElement>
  ) => {
    e.preventDefault();

    if (!selectedPromptsImportFunnel) return;
    const uploadFiles = e.dataTransfer.files;

    const text = await uploadFiles[0].text();
    const records = parse(text, {
      columns: true,
      skip_empty_lines: true
    });

    const response = await importPrompts({
      variables: {
        data: {
          funnelId: selectedPromptsImportFunnel.id,
          jsonString: JSON.stringify(records)
        }
      }
    });

    setDrag(false);
    if (uploadFiles && uploadFiles.length > 0) {
      setFiles([
        ...files,
        { ...uploadFiles[0], name: uploadFiles[0].name, loading: true }
      ]);
    }
  };

  const promptsImportHandleUploadFile = async (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    e.preventDefault();
    const uploadFiles = e.target.files;

    if (uploadFiles && uploadFiles.length > 0) {
      setFiles([
        ...files,
        { ...uploadFiles[0], name: uploadFiles[0].name, loading: true }
      ]);
    }
  };

  const groundTruthImportOnDropHandler = async (
    e: React.DragEvent<HTMLDivElement>
  ) => {
    e.preventDefault();

    if (!selectedGroundTruthImportFunnel) return;
    const uploadFiles = e.dataTransfer.files;

    const text = await uploadFiles[0].text();
    const records = parse(text, {
      columns: true,
      skip_empty_lines: true
    });

    const response = await importGroundTruth({
      variables: {
        data: {
          funnelId: selectedGroundTruthImportFunnel.id,
          jsonString: JSON.stringify(records)
        }
      }
    });

    setDrag(false);
    if (uploadFiles && uploadFiles.length > 0) {
      setFiles([
        ...files,
        { ...uploadFiles[0], name: uploadFiles[0].name, loading: true }
      ]);
    }
  };

  const groundTruthImportHandleUploadFile = async (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    e.preventDefault();
    const uploadFiles = e.target.files;

    if (uploadFiles && uploadFiles.length > 0) {
      setFiles([
        ...files,
        { ...uploadFiles[0], name: uploadFiles[0].name, loading: true }
      ]);
    }
  };

  return (
    <MainWrap>
      <Header
        modalControl={
          <ModalControlWrap>
            <CloseBtn onClick={handleClose}>
              <CloseInfoIcon width={18} height={18} />
            </CloseBtn>
            <ModalPageTitle isTablet={isTablet} isMobile={isMobile}>
              Due Diligence Funnel Settings
            </ModalPageTitle>
          </ModalControlWrap>
        }
      />
      <PaddingWrap>
        <TierDropdownWrapper>
          <TierDropdown title="Past Due Diligence Records Import">
            <UploadText></UploadText>
            <SelectLabel>Select funnel to use for import</SelectLabel>
            <FilterSelect
              data={DDRImportFunnel?.map((item) => item.name) || []}
              selected={
                DDRImportFunnel?.find(
                  (item) => item.id === selectedDDRImportFunnel?.id
                )?.name ?? ''
              }
              setSelected={handleDDRImportFunnelChange}
              width={isMobile ? '100%' : '35%'}
              minHeight={'43px'}
              fontSize="16"
              fontWeight="300"
            />
            {importLoading ? (
              <Loading />
            ) : (
              <DropFileArea
                id={'handle-upload-file'}
                handleUploadFile={DDRImporthandleUploadFile}
                onDropHandler={() => {}} //onDDRImportDropHandler
                drag={drag}
                setDrag={setDrag}
              />
            )}
          </TierDropdown>
        </TierDropdownWrapper>
      </PaddingWrap>
      <PaddingWrap>
        <TierDropdownWrapper>
          <TierDropdown title="DDR Prompts Import">
            <UploadText></UploadText>
            <SelectLabel>Select funnel to use for import</SelectLabel>
            <FilterSelect
              data={promptsImportFunnel?.map((item) => item.name) || []}
              selected={
                promptsImportFunnel?.find(
                  (item) => item.id === selectedPromptsImportFunnel?.id
                )?.name ?? ''
              }
              setSelected={handlePromptsImportFunnelChange}
              width={isMobile ? '100%' : '35%'}
              minHeight={'43px'}
              fontSize="16"
              fontWeight="300"
            />
            {importPromptsLoading ? (
              <Loading />
            ) : (
              <DropFileArea
                id={'handle-upload-file'}
                handleUploadFile={promptsImportHandleUploadFile}
                onDropHandler={() => {}} //promptsImportOnDropHandler
                drag={drag}
                setDrag={setDrag}
              />
            )}
          </TierDropdown>
        </TierDropdownWrapper>
      </PaddingWrap>
      <PaddingWrap>
        <TierDropdownWrapper>
          <TierDropdown title="Ground Truth Import">
            <UploadText></UploadText>
            <SelectLabel>Select funnel to use for import</SelectLabel>
            <FilterSelect
              data={groundTruthImportFunnel?.map((item) => item.name) || []}
              selected={
                groundTruthImportFunnel?.find(
                  (item) => item.id === selectedGroundTruthImportFunnel?.id
                )?.name ?? ''
              }
              setSelected={handleGroundTruthImportFunnelChange}
              width={isMobile ? '100%' : '35%'}
              minHeight={'43px'}
              fontSize="16"
              fontWeight="300"
            />
            {imporGroundTruthLoading ? (
              <Loading />
            ) : (
              <DropFileArea
                id={'handle-upload-file'}
                handleUploadFile={groundTruthImportHandleUploadFile}
                onDropHandler={() => {}} //groundTruthImportOnDropHandler
                drag={drag}
                setDrag={setDrag}
              />
            )}
          </TierDropdown>
        </TierDropdownWrapper>
      </PaddingWrap>
    </MainWrap>
  );
};

export const TierDropdownWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 30px;
`;

const UploadText = styled.p`
  font-weight: 400;
  font-size: 16px;
  color: #373f4e;
  padding-left: 20px;
`;
