import React, { useEffect, useRef } from 'react';
import styled, { keyframes } from 'styled-components';

import { useResponsive } from '@/hooks/use-responsive';
import { useClickOutside } from '@/hooks/useClickOutside';
import Portal from './portal';

interface ModalProps {
  children: React.ReactNode;
  isOpen: boolean;
  onClose: () => void;
  idRootElement?: string;
  background?: boolean;
  className?: string;
  width?: string;
  isUseOutsideClick?: boolean;
  showCloseIcon?: boolean;
}

const Modal = ({
  children,
  isOpen,
  onClose,
  idRootElement,
  className,
  background = false,
  width,
  isUseOutsideClick = true,
  showCloseIcon = true
}: ModalProps) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const { isMobile } = useResponsive();
  useEffect(() => {
    const closeOnEscapeKey = (event: KeyboardEvent) => (event.key === 'Escape' ? onClose() : null);
    document.body.addEventListener('keydown', closeOnEscapeKey);
    return () => {
      document.body.removeEventListener('keydown', closeOnEscapeKey);
    };
  }, [onClose]);

  useClickOutside<HTMLDivElement>(ref, isUseOutsideClick ? onClose : () => null);

  return (
    <>
      {isOpen && (
        <Portal id={idRootElement}>
          <Shadow background={background}>
            <Container width={width} isMobile={isMobile} ref={ref} className={className}>
              {showCloseIcon ? <Cross src="/static/icons/blue-cross.svg" alt="Cross" onClick={onClose} /> : null}
              {children}
            </Container>
          </Shadow>
        </Portal>
      )}
    </>
  );
};

const opacityFrame = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const Shadow = styled.div<{ background: boolean }>`
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ background }) => (background ? '#323232' : '#000c')};
  z-index: 102;
`;
const Container = styled.div<{ isMobile: boolean; width?: string }>`
  animation: ${opacityFrame} 400ms;
  position: relative;
  width: ${({ width }) => (width ? width : '600px')};
  margin: ${({ isMobile }) => (isMobile ? '0 15px' : '0')};
  background: #fafafa;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 85px 50px 45px;
  border-radius: 10px;
`;
const Cross = styled.img`
  position: absolute;
  top: 35px;
  right: 65px;
  cursor: pointer;
`;
export default Modal;
