import { Box, Modal } from '@mui/material';
import React from 'react';
import styled from 'styled-components';

interface ModalWindowProps {
  children: React.ReactNode;
  isOpen: boolean;
  width?: string;
  closeModalWindow: () => void;
}

const ModalWindow = ({ children, isOpen, closeModalWindow, width = '50%' }: ModalWindowProps) => {
  return (
    <ModalWindowWrapper open={isOpen} onClose={closeModalWindow} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
      <ModalWindowContainer width={width}>
        <ModalWindowCross onClick={closeModalWindow} src="/static/icons/dark-cross.svg" alt="Cross" />
        {children}
      </ModalWindowContainer>
    </ModalWindowWrapper>
  );
};

export default ModalWindow;

const ModalWindowWrapper = styled(Modal)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background: rgba(51, 54, 63, 0.19);
`;
const ModalWindowContainer = styled(Box)<{ width: string }>`
  position: relative;
  background: #ffffff;
  border-radius: 6px;
  width: ${({ width }) => width};
`;
const ModalWindowCross = styled.img`
  position: absolute;
  top: 27px;
  right: 27px;
  cursor: pointer;
`;
