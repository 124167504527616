import { SortIcon } from '@/assets/icons/sortIcon';
import { BurgerMenuIcon } from '@/assets/static/icons/burger-menu';
import { CloseMenuIcon } from '@/assets/static/icons/close-menu';
import { getUTCDate } from '@/components/allocations-page/utils';
import Tabs, { ITab, TabMobileWrap, TabTitle, TabsControl, TabsLine } from '@/components/basicComponents/fat-tabs';
import BasicTooltip from '@/components/basicComponents/tooltip';
import { useResponsive } from '@/hooks/use-responsive';
import { Dispatch, SetStateAction, useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { Investment } from '../types';

interface DetailsProps {
  tabs?: ITab[];
  activeTab?: string;
  setActiveTab?: (value: string) => void;
  isTabMenuOpen?: boolean;
  setIsTabMenuOpen?: Dispatch<SetStateAction<boolean>>;
  detailsData: Investment | null;
}

export const Details = ({ tabs, activeTab, setActiveTab, isTabMenuOpen, setIsTabMenuOpen, detailsData }: DetailsProps) => {
  const { isMobile, isDesktop, isTablet, isLargeDesktop } = useResponsive();
  const theme = useTheme();

  const [isDetailsOpen, setIsDetailsOpen] = useState(true);

  if (!detailsData) return <></>;

  return (
    <DetailsWrap isOpen={isDetailsOpen} isMobile={isMobile}>
      <DetailsBoby isMobile={isMobile} isOpen={isDetailsOpen}>
        <ExpandableWrapp isMobile={isMobile} isOpen={isDetailsOpen}>
          {/* <CardsContainer isMobile={isMobile} isOpen={isDetailsOpen}>
            <Card isMobile={isMobile}>
              <CardTitle>{detailsData.assetClass}</CardTitle>
              <CardDesc>ASSET CLASS</CardDesc>
            </Card>
            <Card isMobile={isMobile}>
              <CardTitle>
                <FormattingTooltip zIndex={102}>{detailsData.minimum}</FormattingTooltip>
              </CardTitle>
              <CardDesc>MINIMUM</CardDesc>
            </Card>
          </CardsContainer> */}

          <MoreDetails isMobile={isMobile} isOpen={isDetailsOpen}>
            <MoreDetailItem isMobile={isMobile}>
              {detailsData.managerId && (
                <ItemRow isMobile={isMobile}>
                  <ItemTitle>Manager</ItemTitle>
                  <ItemValue>{detailsData.managerId}</ItemValue>
                </ItemRow>
              )}

              {detailsData.type && (
                <ItemRow isMobile={isMobile}>
                  <ItemTitle>Type</ItemTitle>
                  <ItemValue>{detailsData.type}</ItemValue>
                </ItemRow>
              )}

              {detailsData?.targetIRR || detailsData?.targetReturn ? (
                <ItemRow isMobile={isMobile}>
                  <ItemTitle>Target IRR</ItemTitle>
                  <ItemValue>{detailsData?.targetIRR ? detailsData.targetIRR : detailsData?.targetReturn}%</ItemValue>
                </ItemRow>
              ) : (
                <></>
              )}

              {detailsData.subAssetClasses.filter((sector) => sector !== '').length ? (
                <ItemRow isMobile={isMobile}>
                  <ItemTitle>Sectors</ItemTitle>
                  <ItemsWrap>
                    {detailsData.subAssetClasses
                      .filter((x, i, a) => a.indexOf(x) == i)
                      .map((el) => {
                        const key = 'sector_' + el.trim();

                        if (el.length && el.length > 15) {
                          return (
                            <BasicTooltip key={key} tooltipContent={el.trim()}>
                              <ItemValues>{el.trim().slice(0, 15) + ' ...'}</ItemValues>
                            </BasicTooltip>
                          );
                        }
                        if (el.length) {
                          return <ItemValues key={key}>{el.trim()}</ItemValues>;
                        }
                      })}
                  </ItemsWrap>
                </ItemRow>
              ) : (
                // <ItemRow isMobile={isMobile}>
                //   <ItemTitle>Sectors</ItemTitle>
                //   <ItemValue>{'Not Set'}</ItemValue>
                // </ItemRow>
                <></>
              )}
            </MoreDetailItem>

            <MoreDetailItem isMobile={isMobile}>
              {detailsData.assetClass && (
                <ItemRow isMobile={isMobile}>
                  <ItemTitle>Asset Class</ItemTitle>
                  <ItemValue>{detailsData.assetClass.name}</ItemValue>
                </ItemRow>
              )}

              <ItemRow isMobile={isMobile}>
                <ItemTitle>Tax Exempt Suitable</ItemTitle>
                <ItemValue>{detailsData.ubtiBlocker ? 'Yes' : 'No'}</ItemValue>
              </ItemRow>

              {!detailsData.targetYield || (
                <ItemRow isMobile={isMobile}>
                  <ItemTitle>Target Yield</ItemTitle>
                  <ItemValue>{detailsData.targetYield}%</ItemValue>
                </ItemRow>
              )}

              {detailsData.tags.filter((sector) => sector !== '').length ? (
                <ItemRow isMobile={isMobile}>
                  <ItemTitle>Focus</ItemTitle>
                  <ItemsWrap>
                    {detailsData.tags
                      .filter((x, i, a) => a.indexOf(x) == i)
                      .map((el) => {
                        const key = 'tag_' + el.trim();

                        if (el.length && el.length > 15) {
                          return (
                            <BasicTooltip key={key} tooltipContent={el.trim()}>
                              <ItemValues>{el.trim().slice(0, 15) + ' ...'}</ItemValues>
                            </BasicTooltip>
                          );
                        }
                        if (el.length) {
                          return <ItemValues key={key}>{el.trim()}</ItemValues>;
                        }
                      })}
                  </ItemsWrap>
                </ItemRow>
              ) : (
                // <ItemRow isMobile={isMobile}>
                //   <ItemTitle>Focus</ItemTitle>
                //   <ItemValue>{'Not Set'}</ItemValue>
                // </ItemRow>
                <></>
              )}
            </MoreDetailItem>

            <MoreDetailItem isMobile={isMobile}>
              {detailsData?.subAssetClass && (
                <ItemRow isMobile={isMobile}>
                  <ItemTitle>Sub Asset Class</ItemTitle>
                  <ItemValue>{detailsData.subAssetClass.name}</ItemValue>
                </ItemRow>
              )}
              {detailsData?.investorDocsDue && (
                <ItemRow isMobile={isMobile}>
                  <ItemTitle>Investor Docs Due</ItemTitle>
                  <ItemValue>{getUTCDate(new Date(detailsData.investorDocsDue))}</ItemValue>
                </ItemRow>
              )}
              {!detailsData.vintageYear || (
                <ItemRow isMobile={isMobile}>
                  <ItemTitle>Vintage</ItemTitle>
                  <ItemValue>{detailsData.vintageYear}</ItemValue>
                </ItemRow>
              )}
            </MoreDetailItem>
          </MoreDetails>
        </ExpandableWrapp>
      </DetailsBoby>

      <ControlDetails isMobile={isMobile} isOpen={isDetailsOpen}>
        {tabs && activeTab && setActiveTab && setIsTabMenuOpen && (
          <>
            {isDesktop || isTablet || isLargeDesktop ? (
              <>
                <Tabs tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab} />
                <TabsLine />
              </>
            ) : (
              <TabsControl isMobile={isMobile}>
                {tabs.length !== 1 && (
                  <>
                    {isTabMenuOpen ? (
                      <CloseMenuIcon fill={theme.action.primary} onClick={() => setIsTabMenuOpen(!isTabMenuOpen)} style={{ cursor: 'pointer' }} />
                    ) : (
                      <BurgerMenuIcon fill={theme.action.primary} onClick={() => setIsTabMenuOpen(!isTabMenuOpen)} style={{ cursor: 'pointer' }} />
                    )}
                  </>
                )}
                <TabMobileWrap onClick={() => setActiveTab(activeTab)} isActive>
                  <TabTitle isActive isMobile={isMobile}>
                    {activeTab}
                  </TabTitle>
                </TabMobileWrap>
              </TabsControl>
            )}
          </>
        )}

        <HideDetailsWrap withoutTabs={!tabs} isMobile={isMobile} onClick={() => setIsDetailsOpen(!isDetailsOpen)}>
          <ArrowBtn isRotate={isDetailsOpen}>
            <SortIcon fill={theme.action.primary} />
          </ArrowBtn>
          <ControlDetailsTitle isMobile={isMobile}>{isDetailsOpen ? 'Hide Details' : 'Show Details'}</ControlDetailsTitle>
        </HideDetailsWrap>
      </ControlDetails>
    </DetailsWrap>
  );
};

const ItemValues = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px;
  max-height: 24px;
  background-color: ${({ theme }) => theme.layer[2]};
  color: ${({ theme }) => theme.font.base};
  border-radius: 10px;
  min-width: 140px;
  font-family: Blinker, serif;
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0;
`;

const ArrowBtn = styled.div<{ isRotate: boolean }>`
  transform: ${({ isRotate }) => (isRotate ? 'rotate(180deg)' : 'rotate(0deg)')};
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.3s;
`;

const ControlDetailsTitle = styled.div<{ isMobile: boolean }>`
  font-family: Blinker, serif;
  font-size: ${({ isMobile }) => (isMobile ? '13px' : '16px')};
  font-weight: 600;
  line-height: 22px;
  color: ${({ theme }) => theme.action.primary};
`;

const HideDetailsWrap = styled.div<{ isMobile: boolean; withoutTabs: boolean }>`
  position: relative;
  top: ${({ isMobile, withoutTabs }) => (isMobile || withoutTabs ? '0' : '15px')};
  margin-left: auto;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 10px 0;
  gap: 12.5px;
`;

const ItemValue = styled.div`
  font-family: Blinker, serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  color: ${({ theme }) => theme.font.base};
`;

const ItemsWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
`;

const ItemTitle = styled.div`
  font-family: Blinker, serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  color: ${({ theme }) => theme.font.base};
  min-width: 150px;
`;

const ItemRow = styled.div<{ isMobile: boolean }>`
  display: flex;
  justify-content: ${({ isMobile }) => (isMobile ? 'space-between' : 'flex-start')};
  width: 100%;
  gap: 10px;
  @media (max-width: 1100px) {
    gap: 5px;
  }
`;

const MoreDetailItem = styled.div<{ isMobile: boolean }>`
  display: flex;
  flex-direction: column;
  width: ${({ isMobile }) => (isMobile ? '100%' : '32%')};
  gap: 14px;
  padding: 0 30px;
  @media (max-width: 1100px) {
    padding: 15px;
  }
  @media (max-width: 1079px) {
    padding: 15px 60px;
  }
  @media (max-width: 445px) {
    padding: 15px 20px;
  }
`;

const CardsContainer = styled.div<{ isOpen: boolean; isMobile: boolean }>`
  display: flex;
  flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};
  align-items: ${({ isMobile }) => (isMobile ? 'center' : 'flex-start')};
  gap: 10px;
`;

const MoreDetails = styled.div<{ isOpen: boolean; isMobile: boolean }>`
  display: flex;
  flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};
  align-items: ${({ isMobile }) => (isMobile ? 'center' : 'flex-start')};
  margin-top: 15px;
  border-radius: 10px;
  justify-content: space-between;
`;

const CardTitle = styled.div`
  color: ${({ theme }) => theme.font.strong};
  font-family: Blinker, serif;
  font-size: 23px;
  font-weight: 700;
  line-height: 32px;
  text-align: center;
`;

const CardDesc = styled.div`
  color: ${({ theme }) => theme.font.weak};
  font-family: Blinker, serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0;
  text-align: center;
`;

const Card = styled.div<{ short?: boolean; isMobile?: boolean }>`
  display: flex;
  cursor: ${({ short }) => (short ? 'pointer' : 'default')};
  width: ${({ short, isMobile }) => (short ? '25.3%' : isMobile ? '100%' : '50.9%')};
  //height: 80px;
  min-height: 80px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.layer.base};
  border-radius: 10px;
`;

const DetailsWrap = styled.div<{ isOpen: boolean; isMobile: boolean }>`
  display: flex;
  width: calc(100%);
  /* margin-left: -60px; */
  flex-direction: column;
  padding: ${({ isMobile }) => (isMobile ? '10px 20px 0 20px' : '20px 60px 0 60px')};
  background-color: ${({ theme }) => theme.layer[1]};
  border-bottom: 1px solid ${({ theme }) => theme.border.base};
  transition: 1s;
`;

const ControlDetails = styled.div<{ isMobile: boolean; isOpen: boolean }>`
  display: flex;
  justify-content: space-between;
  padding-top: ${({ isMobile, isOpen }) => (isMobile && isOpen ? '25px' : '0')};
`;

const DetailsBoby = styled.div<{ isOpen: boolean; isMobile: boolean }>`
  opacity: ${({ isOpen }) => (isOpen ? '1' : '0')};
  display: grid;
  grid-template-rows: ${({ isOpen }) => (isOpen ? '1fr' : '0fr')};
  overflow: ${({ isOpen }) => (isOpen ? 'visible' : 'hidden')};
  transition: grid-template-rows 300ms;
`;

const ExpandableWrapp = styled.div<{ isMobile: boolean; isOpen: boolean }>`
  min-height: 0;
`;
