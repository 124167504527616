import { useFormik } from 'formik';
import { useState } from 'react';
import * as Yup from 'yup';

import styled from 'styled-components';
import { useAuth } from '../../hooks/use-auth';
import { useMounted } from '../../hooks/use-mounted';
import { useResponsive } from '../../hooks/use-responsive';
import Button from '../basicComponents/button';
import { InputFieldProps } from '../input-fields';
import { LinksProps } from '../links';
import { TextBoxProps } from '../textbox';

const ResetPassword = () => {
  const isMounted = useMounted();
  const { isMobile, isTablet, isPortrait } = useResponsive();
  const [isRequestedReset, setIsRequestedReset] = useState<boolean>(false);
  const { resetPassword } = useAuth();
  const formik = useFormik({
    initialValues: {
      email: '',
      submit: null,
      isSubmitting: true
    },
    validationSchema: Yup.object({
      email: Yup.string().email('Must be a valid email').max(255).required('Email is required')
    }),
    onSubmit: async (values, helpers): Promise<void> => {
      try {
        resetPassword(values.email);
        setIsRequestedReset(true);
      } catch (err: any) {
        console.error(err);
        if (isMounted()) {
          helpers.setStatus({ success: false });
          helpers.setErrors({ submit: err.message });
          helpers.setSubmitting(false);
        }
      }
    }
  });

  return (
    <div style={{ width: isMobile || (isPortrait && isTablet) ? '70%' : '50%' }}>
      {isRequestedReset ? (
        <div style={{ width: '100%', textAlign: 'center' }}>
          If your email address is registered you will receive a password reset email in a few minutes.
        </div>
      ) : (
        <>
          <p
            style={{
              paddingBottom: '44px',
              color: 'passwordLabelText',
              fontWeight: 500,
              fontSize: 16,
              width: '100%',
              paddingLeft: '10px',
              textAlign: 'center'
            }}
          >
            Please enter your email address to receive a reset password.
          </p>
          <form noValidate onSubmit={formik.handleSubmit}>
            <TextBoxProps text="Enter your Email" padding="0 0 0 10px" />
            <InputFieldProps
              error={Boolean(formik.touched.email && formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
              name="email"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              type="email"
              value={formik.values.email}
            />

            <ResetPasswordButton id="sign-in-button" type="submit" disabled={formik.isSubmitting || !formik.isValid}>
              Request reset
            </ResetPasswordButton>
          </form>
        </>
      )}
      <CustomLinksProps underline="none" textColor="#578fe4" url={'/'}>
        Back to log in
      </CustomLinksProps>
    </div>
  );
};

export default ResetPassword;

const ResetPasswordButton = styled(Button)`
  text-transform: uppercase;
  margin-top: 30px;
  width: 100%;
  background-color: #172d4d;
  line-height: 33.25px;
`;

const CustomLinksProps = styled(LinksProps)`
  display: flex;
  justify-content: center;
  font-size: 16px;
  font-weight: 400;
  margin-top: 20px;
`;
