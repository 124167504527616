import FilterSelect from '@/components/basicComponents/select';
import { Investment } from '@/components/fat-dealCatalog-page/types';
import { ISeriesCodesTableData } from '@/components/fat-settings-page/importData/types';
import NoResults from '@/components/table/noResults';
import { TableRowCell, TableRowWrapper, TextCell } from '@/components/table/styles';
import styled from 'styled-components';
import { RowStatus } from '../../entityCodes/table/cell/rowStatus';
import { columns } from '../constants';

interface TableBodyProps {
  tableData: ISeriesCodesTableData[];
  investmentList: Investment[];
  investmentListLoading: boolean;
  handleChangeAssignment: (investmentName: string, seriesCode: string) => void;
  resetAssignment: (seriesCode: string) => void;
}

const TableBody = ({ tableData, investmentList, investmentListLoading, handleChangeAssignment, resetAssignment }: TableBodyProps) => {
  if (!tableData?.length) {
    return <NoResults />;
  }

  return (
    <>
      {tableData.map((row) => (
        <CustomTableRowWrapper key={row.id}>
          <TableRowCell width={columns[0].width}>
            <TextCell>{row.investmentCode}</TextCell>
          </TableRowCell>
          <TableRowCell width={columns[1].width}>
            <TextCell>{row.seriesName}</TextCell>
          </TableRowCell>
          <TableRowCell width={columns[2].width}>
            {row.seriesStatus === 'Assigned' ? (
              <TextCell>{row.seriesAssignment}</TextCell>
            ) : (
              <AssignmentSelect
                data={investmentList.map((investment) => investment.name)}
                selected={row.seriesAssignment}
                setSelected={(value) => handleChangeAssignment(value, row.investmentCode)}
                width="100%"
                openZIndex={35}
                loading={investmentListLoading}
                withSearch
                clearSelectionButton={{ defaultValue: 'Select A Strategy', onClick: () => resetAssignment(row.investmentCode) }}
              />
            )}
          </TableRowCell>
          <TableRowCell width={columns[3].width}>
            <TextCell>
              <RowStatus status={row.seriesStatus} />
            </TextCell>
          </TableRowCell>
        </CustomTableRowWrapper>
      ))}
    </>
  );
};

export default TableBody;

const CustomTableRowWrapper = styled(TableRowWrapper)`
  justify-content: space-between;
`;

const AssignmentSelect = styled(FilterSelect)`
  border-radius: 4px;
  #titleSelect {
    color: ${({ theme }) => theme.font.strong};
    font-size: 16px;
    line-height: 22.4px;
  }
`;
