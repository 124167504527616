import { Dispatch, SetStateAction, useEffect, useMemo } from 'react';
import styled from 'styled-components';

import { SortIcon } from '@/assets/icons/sortIcon';
import { BurgerMenuIcon } from '@/assets/static/icons/burger-menu';
import { CloseMenuIcon } from '@/assets/static/icons/close-menu';
import { useResponsive } from '../../../hooks/use-responsive';
import { ITab } from '../../../types/opportunity';
import { dateFormatter } from '../../../utils/dateFormatter';
import guid from '../../../utils/guid';
import FormattingTooltip from '../../basicComponents/tooltip/dollarsTooltip';
import { InvestorPotentialAllocationsType, Mode } from '../types';

type DetailsProps = {
  isOpen: boolean;
  setOpen: () => void;
  data: InvestorPotentialAllocationsType | undefined;
  currentTab: { value: string };
  changeTab: (value: string) => void;
  tabs: ITab[];
  setMode: (value: Mode) => void;
  setIsTabMenuOpen: Dispatch<SetStateAction<boolean>>;
  isTabMenuOpen: boolean;
};

const Details = ({ data, isOpen, setOpen, currentTab, changeTab, tabs, setMode, isTabMenuOpen, setIsTabMenuOpen }: DetailsProps) => {
  const { isMobile, isDesktop, isTablet, isLargeDesktop } = useResponsive();

  useEffect(() => {
    if (!isOpen && (isMobile || isTablet)) {
      window.scrollTo(0, 0);
    }
  }, [isOpen]);

  const currentTabTitle = useMemo(() => {
    switch (currentTab.value) {
      case 'pending':
        return { title: tabs[0].title, amount: tabs[0].amount };
      case 'investmentReview':
        return { title: tabs[1].title, amount: tabs[1].amount };
      case 'advisorReview':
        return { title: tabs[2].title, amount: tabs[2].amount };
      case 'compliance':
        return { title: tabs[3].title, amount: tabs[3].amount };
      default:
        return {};
    }
  }, [currentTab.value]);

  return data ? (
    <>
      <DetailsWrap isOpen={isOpen} isMobile={isMobile}>
        {isOpen ? (
          <>
            <CardsContainer isMobile={isMobile} isOpen={isOpen}>
              <Card isMobile={isMobile}>
                <CardTitle>{data?.investment.assetClass}</CardTitle>
                <CardDesc>ASSET CLASS</CardDesc>
              </Card>
              <Card isMobile={isMobile}>
                <CardTitle>
                  <FormattingTooltip zIndex={102}>{data?.investment.minimum}</FormattingTooltip>
                </CardTitle>
                <CardDesc>MINIMUM</CardDesc>
              </Card>
            </CardsContainer>

            <MoreDetails isMobile={isMobile} isOpen={isOpen}>
              <MoreDetailItem isMobile={isMobile}>
                <ItemRow isMobile={isMobile}>
                  <ItemTitle>Manager</ItemTitle>
                  <ItemValue>{data?.investment.managerId}</ItemValue>
                </ItemRow>

                <ItemRow isMobile={isMobile}>
                  <ItemTitle>Asset Class</ItemTitle>
                  <ItemValue>{data?.investment.assetClass}</ItemValue>
                </ItemRow>

                <ItemRow isMobile={isMobile}>
                  <ItemTitle>Minimum</ItemTitle>
                  <ItemValue>
                    <FormattingTooltip>{data?.investment.minimum}</FormattingTooltip>
                  </ItemValue>
                </ItemRow>

                <ItemRow isMobile={isMobile}>
                  <ItemTitle>Investment Type</ItemTitle>
                  <ItemValue>{data?.investment.type ? data?.investment.type : 'Not Set'}</ItemValue>
                </ItemRow>

                <ItemRow isMobile={isMobile}>
                  <ItemTitle>Vintage</ItemTitle>
                  <ItemValue>{data?.investment.vintageYear}</ItemValue>
                </ItemRow>
              </MoreDetailItem>

              <MoreDetailItem isMobile={isMobile}>
                <ItemRow isMobile={isMobile}>
                  <ItemTitle>Next Close</ItemTitle>
                  <ItemValue>{data?.investment?.nextClose ? dateFormatter(data?.investment.nextClose, 'yyyy-MM-dd') : null}</ItemValue>
                </ItemRow>

                <ItemRow isMobile={isMobile}>
                  <ItemTitle>Final Close</ItemTitle>
                  <ItemValue>{data?.investment?.finalClose ? dateFormatter(data?.investment.finalClose, 'yyyy-MM-dd') : null}</ItemValue>
                </ItemRow>

                <ItemRow isMobile={isMobile}>
                  <ItemTitle>Custodied</ItemTitle>
                  <ItemValue>{data?.investment.custodied ? 'Yes' : 'No'}</ItemValue>
                </ItemRow>

                <ItemRow isMobile={isMobile}>
                  <ItemTitle>UBTI Blocker</ItemTitle>
                  <ItemValue>{data?.investment.ubtiBlocker ? 'Yes' : 'No'}</ItemValue>
                </ItemRow>
              </MoreDetailItem>

              <MoreDetailItem isMobile={isMobile}>
                <ItemRow isMobile={isMobile}>
                  <ItemTitle>SRI</ItemTitle>
                  <ItemValue>{data?.investment.sri ? 'Yes' : 'No'}</ItemValue>
                </ItemRow>

                <ItemRow isMobile={isMobile}>
                  <ItemTitle>Target IRR</ItemTitle>
                  <ItemValue>{data?.investment.targetIRR}%</ItemValue>
                </ItemRow>

                <ItemRow isMobile={isMobile}>
                  <ItemTitle>Target Yield</ItemTitle>
                  <ItemValue>{data?.investment.targetYield}%</ItemValue>
                </ItemRow>

                {data?.investment.subAssetClasses.filter((sector) => sector !== '').length ? (
                  <ItemRow isMobile={isMobile}>
                    <ItemTitle>Strategies</ItemTitle>
                    <ItemsWrap>
                      {data?.investment.subAssetClasses
                        .filter((x, i, a) => a.indexOf(x) == i)
                        .map((el) => {
                          if (el.length) {
                            return <ItemValues key={guid()}>{el.trim()}</ItemValues>;
                          }
                        })}
                    </ItemsWrap>
                  </ItemRow>
                ) : (
                  <ItemRow isMobile={isMobile}>
                    <ItemTitle>Strategies</ItemTitle>
                    <ItemValue>{'Not Set'}</ItemValue>
                  </ItemRow>
                )}
              </MoreDetailItem>
            </MoreDetails>
          </>
        ) : null}

        <Tabs>
          {isDesktop || isTablet || isLargeDesktop ? (
            <MainTabs isOpen={isOpen}>
              {tabs.map((tab, tabId) => (
                <TabsWrap key={tab.value + tabId} onClick={() => changeTab(tab.value)} isActive={currentTab.value === tab.value}>
                  {tab.value !== 'pending' && <CounterTitle isActive={currentTab.value === tab.value}>{tab.amount}</CounterTitle>}
                  <TabsDetailsTitle isActive={currentTab.value === tab.value}>{tab.title}</TabsDetailsTitle>
                </TabsWrap>
              ))}
            </MainTabs>
          ) : (
            <MainTabs isOpen={isOpen} isMobile={isMobile}>
              {isTabMenuOpen ? (
                <CloseMenuIcon onClick={() => setIsTabMenuOpen(!isTabMenuOpen)} style={{ cursor: 'pointer' }} />
              ) : (
                <BurgerMenuIcon onClick={() => setIsTabMenuOpen(!isTabMenuOpen)} style={{ cursor: 'pointer' }} />
              )}
              <TabsWrap onClick={() => changeTab(currentTab.value)} isActive isMobile={isMobile}>
                {currentTab.value !== 'pending' && (
                  <CounterTitle isActive isMobile={isMobile}>
                    {currentTabTitle.amount}
                  </CounterTitle>
                )}
                <TabsDetailsTitle isMobile={isMobile} isActive>
                  {currentTabTitle.title}
                </TabsDetailsTitle>
              </TabsWrap>
            </MainTabs>
          )}

          <SecondaryTabs isMobile={isMobile}>
            <ControlDetails isMobile={isMobile} onClick={setOpen}>
              <ArrowBtn isRotate={isOpen}>
                <SortIcon fill={'#4587EC'} />
              </ArrowBtn>
              <ControlDetailsTitle isMobile={isMobile}>{isOpen ? 'Hide Details' : 'Show Details'}</ControlDetailsTitle>
            </ControlDetails>
          </SecondaryTabs>
        </Tabs>
      </DetailsWrap>
    </>
  ) : (
    <></>
  );
};

const Tabs = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

const ItemValues = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px;
  max-height: 24px;
  background: #f0f1f3;
  border-radius: 10px;
  min-width: 140px;
  font-family: Blinker, serif;
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0;
`;

const MainTabs = styled.div<{ isOpen: boolean; isMobile?: boolean }>`
  display: flex;
  width: 85%;
  justify-content: flex-start;
  align-items: center;
  gap: 14px;
  padding: ${({ isMobile }) => (isMobile ? '20px 0 10px 0' : '20px 0 0 0')};
  z-index: ${({ isOpen }) => (isOpen ? 'z-index: 9999' : 'z-index: 0')};
`;

const SecondaryTabs = styled.div<{ isMobile: boolean }>`
  display: flex;
  justify-content: flex-end;
  width: ${({ isMobile }) => (isMobile ? '35%' : '15%')};
`;

const TabsWrap = styled.div<{ isActive: boolean; isMobile?: boolean }>`
  cursor: pointer;
  background-color: ${({ isActive }) => (isActive ? '#3ab070' : '#fafafa')};
  height: ${({ isMobile }) => (isMobile ? 'auto' : '100%')};
  color: #ffffff;
  display: flex;
  align-content: center;
  align-items: center;
  gap: 10px;
  justify-content: center;
  border-radius: ${({ isMobile }) => (isMobile ? '4px' : '10px 10px 0 0')};
  padding: ${({ isMobile }) => (isMobile ? '6px 13px' : '10px 19px')};
  transition: background-color 1s ease-in-out;
`;

const TabsDetailsTitle = styled.div<{ isActive: boolean; isMobile?: boolean }>`
  font-family: Lato, serif;
  font-size: ${({ isMobile }) => (isMobile ? '13px' : '16px')};
  font-weight: 600;
  line-height: 22px;
  color: ${({ isActive }) => (isActive ? '#ffffff' : '#4587ec')};
  display: flex;
  justify-content: center;
  align-items: center;
  transition: color 1s ease-in-out;
`;

export const CounterTitle = styled.div<{ isActive: boolean; isMobile?: boolean }>`
  font-family: Blinker, serif;
  display: flex;
  color: ${({ isActive }) => (isActive ? '#000000' : '#ffffff')};
  font-size: 13px;
  font-weight: 700;
  line-height: 18.2px;
  border-radius: 10px;
  background: ${({ isActive }) => (isActive ? '#ffffff' : '#4587ec')};
  height: ${({ isMobile }) => (isMobile ? '14px' : '21px')};
  width: ${({ isMobile }) => (isMobile ? '20px' : '34px')};
  justify-content: center;
  align-items: center;
  transition: 1s ease-in-out;
`;

const ArrowBtn = styled.div<{ isRotate: boolean }>`
  transform: ${({ isRotate }) => (isRotate ? 'rotate(180deg)' : 'rotate(0deg)')};
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 1s;
`;

const ControlDetailsTitle = styled.div<{ isMobile: boolean }>`
  font-family: Blinker, serif;
  font-size: ${({ isMobile }) => (isMobile ? '13px' : '16px')};
  font-weight: 600;
  line-height: 22px;
  color: #4587ec;
`;

const ControlDetails = styled.div<{ isMobile?: boolean }>`
  cursor: pointer;
  display: flex;
  align-self: flex-end;
  padding: ${({ isMobile }) => (isMobile ? '16px 0' : '10px 0')};
  gap: 12.5px;
  z-index: 30;
`;

const ItemValue = styled.div`
  font-family: Blinker, serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  color: #373f4e;
`;

const ItemsWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
`;

const ItemTitle = styled.div`
  font-family: Blinker, serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  color: #373f4e;
  width: 150px;
`;

const ItemRow = styled.div<{ isMobile: boolean }>`
  display: flex;
  justify-content: ${({ isMobile }) => (isMobile ? 'space-between' : 'flex-start')};
  width: 100%;
  gap: 10px;
  @media (max-width: 1100px) {
    gap: 5px;
  }
`;

const MoreDetailItem = styled.div<{ isMobile: boolean }>`
  display: flex;
  flex-direction: column;
  width: ${({ isMobile }) => (isMobile ? '100%' : '32%')};
  gap: 14px;
  padding: 30px;
  @media (max-width: 1100px) {
    padding: 15px;
  }
  @media (max-width: 1079px) {
    padding: 15px 60px;
  }
  @media (max-width: 445px) {
    padding: 15px 20px;
  }
`;

const CardsContainer = styled.div<{ isOpen: boolean; isMobile: boolean }>`
  display: flex;
  flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};
  align-items: ${({ isMobile }) => (isMobile ? 'center' : 'flex-start')};
  gap: 10px;
  height: ${({ isOpen }) => (isOpen ? '100%' : '0px')};
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
  transition: all 1s ease-in-out;
`;

const MoreDetails = styled.div<{ isOpen: boolean; isMobile: boolean }>`
  display: flex;
  flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};
  align-items: ${({ isMobile }) => (isMobile ? 'center' : 'flex-start')};
  //padding-top: ${({ isOpen }) => (isOpen ? '25px' : 0)};
  //padding-bottom: ${({ isOpen }) => (isOpen ? '34px' : 0)};
  height: ${({ isOpen }) => (isOpen ? '100%' : '0px')};
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
  transition: all 0.5s ease-in-out;
  background-color: #fafafa;
  margin-top: 15px;
  border-radius: 10px;
  justify-content: space-between;
`;

const CardTitle = styled.div`
  color: #2e2e2e;
  font-family: Blinker, serif;
  font-size: 23px;
  font-weight: 700;
  line-height: 32px;
  text-align: center;
`;

const CardDesc = styled.div`
  color: #7f7f7f;
  font-family: Blinker, serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0;
  text-align: center;
`;

const Card = styled.div<{ short?: boolean; isMobile?: boolean }>`
  display: flex;
  cursor: ${({ short }) => (short ? 'pointer' : 'default')};
  width: ${({ short, isMobile }) => (short ? '25.3%' : isMobile ? '100%' : '50.9%')};
  //height: 80px;
  min-height: 80px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #fafafa;
  border-radius: 10px;
`;

const DetailsWrap = styled.div<{ isOpen: boolean; isMobile: boolean }>`
  display: flex;
  width: calc(100% + 48px);
  margin-left: -24px;
  flex-direction: column;
  padding: ${({ isOpen, isMobile }) => (isOpen ? (isMobile ? '10px 20px 0 20px' : '20px 60px 0 60px') : isMobile ? '0 20px' : '0 60px')};
  background: #ffffff;
  border-bottom: 1px solid #c7cbd2;
  transition: 1s;
`;

export const IconWrap = styled.div`
  display: flex;
`;

export default Details;
