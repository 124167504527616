import { gql } from '@apollo/client';

export const MANAGER_INVESTMENTS_MIN_QUERY = gql`
  query ManagerInvestments($managerId: String, $page: Int, $rowsPerPage: Int, $sort: String, $order: String) {
    ManagerInvestments(managerId: $managerId, page: $page, rowsPerPage: $rowsPerPage, sort: $sort, order: $order) {
      investments {
        id
        name
      }
    }
  }
`;

export const GET_DOCUMENT_RECORD_QUERY = gql`
  query GetDocumentRecord($id: String!) {
    getDocumentRecord(id: $id) {
      id
      name
      status
      statusUpdatedAt
      note
      createdAt
      updatedAt
      deletedAt
      assignee {
        id
        firstName
        lastName
        email
      }
      publisher {
        id
        firstName
        lastName
        email
      }
      owner {
        id
        firstName
        lastName
        email
      }
      fields {
        id
        value
        commentary
        createdAt
        updatedAt
        deletedAt
        recordId
        fieldId
        labelerData {
          filename
          value
        }
        AI {
          answers
          summary
          citations {
            answer
            filename
            text
            embeddingId
            pageNumbers
            embeddingScore
            prompt
          }
        }
        documentField {
          id
          name
          type
          order
        }
        attachments {
          id
          includeInAutomatedContent
          secure_blob_storage_asset {
            id
            assetKey
            url
          }
        }
        promptGroup {
          id
          prompts
          summaryPrompt
          contextPrompt
          rubricTemplates
          useCustomContextPrompt
          useCustomSummaryPrompt
        }
      }
    }
  }
`;

export const GET_LIST_DOCUMENT_RECORDS_TOTAL_QUERY = gql`
  query ListDocumentRecords($data: DocumentRecordsListDto!) {
    listDocumentRecords(data: $data) {
      total
    }
  }
`;

export const LIST_DOCUMENT_RECORDS_QUERY = gql`
  query ListDocumentRecords($data: DocumentRecordsListDto!) {
    listDocumentRecords(data: $data) {
      total
      limit
      offset
      records {
        id
        name
        status
        statusUpdatedAt
        owner {
          id
          firstName
          lastName
          email
        }
      }
    }
  }
`;

export const INVESTMENT_DETAILS_QUERY = gql`
  query Investment($id: String!) {
    Investment(id: $id) {
      id
      symbol
      name
      managerId
      assetClass {
        id
        name
      }
      subAssetClass {
        id
        name
      }
      subAssetClasses
      targetReturn
      targetYield
      vintageYear
      nextClose
      finalClose
      description
    }
  }
`;

export const createDocumentRecordMutation = gql`
  mutation CreateDocumentRecord($data: DocumentRecordCreateDto!) {
    createDocumentRecord(data: $data) {
      id
    }
  }
`;

export const updateDocumentRecordMutation = gql`
  mutation updateDocumentRecord($data: DocumentRecordUpdateDto!) {
    updateDocumentRecord(data: $data) {
      id
    }
  }
`;

export const updateDocumentRecordStatusMutation = gql`
  mutation updateDocumentRecordStatus($data: DocumentRecordUpdateStatusDto!) {
    updateDocumentRecordStatus(data: $data) {
      id
    }
  }
`;

export const updateDocumentFieldAnswerMutation = gql`
  mutation updateDocumentFieldAnswer($data: DocumentFieldAnswerUpdateDto!) {
    updateDocumentFieldAnswer(data: $data) {
      id
    }
  }
`;

export const deleteDocumentRecordMutation = gql`
  mutation deleteDocumentRecord($id: String!) {
    deleteDocumentRecord(id: $id)
  }
`;

export const addDocumentFieldAnswerAttachmentMutation = gql`
  mutation addDocumentFieldAnswerAttachment($data: DocumentFieldAnswerAddAttachmentDto!) {
    addDocumentFieldAnswerAttachment(data: $data) {
      id
    }
  }
`;

export const updateDocumentFieldAnswerAttachmentMutation = gql`
  mutation updateDocumentFieldAnswerAttachment($data: DocumentFieldAnswerAddAttachmentUpdateDto!) {
    updateDocumentFieldAnswerAttachment(data: $data) {
      id
    }
  }
`;

export const deleteDocumentFieldAnswerAttachmentMutation = gql`
  mutation deleteDocumentFieldAnswerAttachment($id: String!) {
    deleteDocumentFieldAnswerAttachment(id: $id)
  }
`;

export const GET_DOCUMENT_FIELD_ATTACHMENT_URL_QUERY = gql`
  query getDocumentFieldAnswerAttachmentUploadUrl($data: DocumentFieldAnswerAttachmentUploadUrlDto!) {
    getDocumentFieldAnswerAttachmentUploadUrl(data: $data) {
      assetKey
      url
    }
  }
`;
