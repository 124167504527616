import { Path, Svg } from '@react-pdf/renderer';

export const AiIcon = () => (
  <Svg width={15} height={15}>
    <Path
      d="M1.5 10.3465C1.55357 10.1714 1.68079 10.0509 1.80515 9.92652C3.75177 7.98181 5.69744 6.03519 7.6431 4.08952C7.92051 3.81212 8.23235 3.81212 8.50976 4.08857C8.5815 4.16031 8.65516 4.23014 8.72307 4.30475C8.923 4.52285 8.92108 4.84139 8.7202 5.06044C8.71255 5.06905 8.70394 5.0767 8.69629 5.08531C6.69705 7.08455 4.69878 9.08378 2.69858 11.0821C2.4891 11.2915 2.17917 11.3269 1.96776 11.1471C1.77932 10.9864 1.57748 10.8276 1.5 10.5722C1.5 10.4966 1.5 10.422 1.5 10.3465ZM7.23465 6.04093C7.23943 6.03901 7.24613 6.0371 7.24995 6.03423C7.65363 5.63152 8.0573 5.2288 8.45906 4.82513C8.54802 4.73521 8.54419 4.6252 8.45619 4.5305C8.39497 4.46545 8.32992 4.40328 8.26679 4.34014C8.12522 4.19953 8.03147 4.19857 7.89181 4.33727C7.52736 4.70173 7.16386 5.06618 6.79845 5.42968C6.76784 5.46029 6.73053 5.48325 6.69992 5.50716C6.88741 5.69465 7.05864 5.86588 7.23465 6.04188V6.04093Z"
      fill="#757575"
    />
    <Path
      d="M10.2785 6.82994L11.5001 6.52192L10.2833 6.21486C10.2451 6.20529 10.2154 6.17564 10.2058 6.13738L9.89592 4.90723L9.58599 6.13738C9.57642 6.17564 9.54677 6.20529 9.50851 6.21486L8.29175 6.52192L9.51329 6.82994C9.55155 6.8395 9.58121 6.86915 9.59077 6.90742L9.89496 8.11557L10.1992 6.90742C10.2087 6.86915 10.2384 6.8395 10.2766 6.82994H10.2785Z"
      fill="#757575"
    />
    <Path
      d="M9.91107 3.48192L10.6247 3.30209L9.91394 3.12321C9.89194 3.11747 9.87472 3.10025 9.86898 3.07825L9.68819 2.35986L9.5074 3.07825C9.50166 3.10025 9.48444 3.11747 9.46244 3.12321L8.75171 3.30209L9.46531 3.48192C9.48731 3.48766 9.50453 3.50488 9.51027 3.52688L9.68819 4.23283L9.86612 3.52688C9.87185 3.50488 9.88907 3.48766 9.91107 3.48192Z"
      fill="#757575"
    />
    <Path
      d="M3.38446 2.86034L4.09806 2.6805L3.38733 2.50163C3.36533 2.49589 3.34811 2.47867 3.34237 2.45667L3.16158 1.73828L2.98079 2.45667C2.97505 2.47867 2.95783 2.49589 2.93583 2.50163L2.2251 2.6805L2.9387 2.86034C2.9607 2.86608 2.97792 2.8833 2.98366 2.9053L3.16158 3.61125L3.3395 2.9053C3.34524 2.8833 3.36246 2.86608 3.38446 2.86034Z"
      fill="#757575"
    />
    <Path
      d="M5.85058 3.89229L6.9296 3.62062L5.85537 3.34991C5.82189 3.34131 5.79606 3.31548 5.78745 3.282L5.51387 2.19629L5.24029 3.282C5.23168 3.31548 5.20585 3.34131 5.17237 3.34991L4.09814 3.62062L5.17716 3.89229C5.21064 3.9009 5.23646 3.92673 5.24507 3.96021L5.51387 5.02678L5.78267 3.96021C5.79128 3.92673 5.8171 3.9009 5.85058 3.89229Z"
      fill="#757575"
    />
  </Svg>
);
