import styled from 'styled-components';

export const ImportWrapper = styled.div`
  margin-top: 30px;
  background: ${({ theme }) => theme.layer[1]};
  padding: 40px 60px;
  border: 1px solid ${({ theme }) => theme.border.base};
  border-radius: 10px;
`;

export const ImportText = styled.span<{ fontWeight: string; fontSize?: string; color?: string }>`
  color: ${({ color, theme }) => color ?? theme.font.base};
  text-align: center;
  font-size: ${({ fontSize }) => fontSize ?? '19px'};
  font-weight: ${({ fontWeight }) => fontWeight};
  line-height: 26.6px;
`;

export const ButtonsControl = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 50px;
`;

export const StatusCell = styled.div<{ color: string }>`
  display: flex;
  gap: 4px;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
  line-height: 22.4px;
  color: ${({ color }) => color};
`;
