import Label from '@/components/basicComponents/label';
import FilterSelect from '@/components/basicComponents/select';
import { DropFileArea } from '@/components/dueDiligence-page/dueDiligenceRecord/dropFileArea';
import { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';
import { DealTrialInfoBanner } from './dealTrialInfoBanner';

interface AiPaneZeroStateProps {
  trialMode: boolean;
  useUnstructured: boolean;
  selectedProcessingOption: string;
  setSelectedProcessingOption: Dispatch<SetStateAction<string>>;
  handleUploadFile: (e: React.ChangeEvent<HTMLInputElement>, selectedProcessingOption?: string) => void;
  onDropHandler: (e: React.DragEvent<HTMLDivElement>, selectedProcessingOption?: string) => void;
  drag: boolean;
  setDrag: Dispatch<SetStateAction<boolean>>;
}

const processingOptions = ['Text only', 'Text with enhanced table processing', 'Full OCR (required for all image documents)'];

export const AiPaneZeroState = ({
  trialMode,
  useUnstructured,
  selectedProcessingOption,
  setSelectedProcessingOption,
  handleUploadFile,
  onDropHandler,
  drag,
  setDrag
}: AiPaneZeroStateProps) => {
  return (
    <AiPaneBodyWrapper>
      <UploadText>Please upload source docs before using the AI Assistant.</UploadText>
      <UploadedFilesWrapper>
        <CountFiles>0</CountFiles>
        <DocumentText>Documents</DocumentText>
      </UploadedFilesWrapper>
      {trialMode && <DealTrialInfoBanner title="Deal Pilot Trial" description="You are limited to one file upload during the trial period." />}
      {useUnstructured && (
        <ProcessingSelectWrap>
          <Label required={false} fontSize="13px">
            Select a document processing option:
          </Label>
          <CustomSelect data={processingOptions} selected={selectedProcessingOption} setSelected={setSelectedProcessingOption} width="100%" />
        </ProcessingSelectWrap>
      )}
      {selectedProcessingOption !== 'Select' && (
        <DropFileArea
          id={'handle-upload-file'}
          selectedProcessingOption={selectedProcessingOption}
          handleUploadFile={handleUploadFile}
          onDropHandler={onDropHandler}
          drag={drag}
          setDrag={setDrag}
          withIcon
        />
      )}
    </AiPaneBodyWrapper>
  );
};

const AiPaneBodyWrapper = styled.div`
  padding-top: 40px;
`;

const UploadText = styled.p`
  font-weight: 400;
  font-size: 16px;
  color: ${({ theme }) => theme.font.base};
  padding-left: 20px;
`;

const UploadedFilesWrapper = styled.div`
  margin-top: 40px;
  padding-bottom: 40px;
  width: 100%;
  background: ${({ theme }) => theme.layer.base};
`;

const CountFiles = styled.div`
  font-weight: 300;
  font-size: 60px;
  text-align: center;
  color: ${({ theme }) => theme.font.base};
`;

const DocumentText = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: ${({ theme }) => theme.font.weak};
  padding-top: 5px;
  border-top: 1px solid ${({ theme }) => theme.border.base};
  max-width: 120px;
  text-align: center;
  width: 100%;
  margin: 0 auto;
`;

const ProcessingSelectWrap = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
`;

const CustomSelect = styled(FilterSelect)`
  #dropDownSelect {
    padding: 10px 0;
  }
  #dropDownSelectItem {
    color: ${({ theme }) => theme.action.primary};
    font-family: Blinker;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 18.2px;
  }
`;
