import { Loader } from '@/components/loaders/loader';
import { updatesTableColumnsEditMode, updatesTableColumnsViewMode } from '@/components/managerUpdates-page/constatnts';
import TableHeader from '@/components/table/fat-tableHeader';
import NoResults from '@/components/table/noResults';
import { TableBodyWrapper, TableWrapper } from '@/components/table/styles';
import { IItems, ISort } from '@/components/table/types';
import { useResponsive } from '@/hooks/use-responsive';
import styled from 'styled-components';
import { IDocumentRecordEntity } from '../../types';
import { TableBody } from './tableBody';

type TableProp = {
  tableData: IDocumentRecordEntity[];
  sort: ISort;
  setSort: (value: ISort) => void;
  loading: boolean;
  dropdownActions: (row: IDocumentRecordEntity) => IItems[];
  openEditManagerUpdatePage: (updateId: string) => void;
  canUserEdit: boolean;
};

export const Table = ({ tableData, sort, setSort, loading, dropdownActions, openEditManagerUpdatePage, canUserEdit }: TableProp) => {
  const { isTablet } = useResponsive();

  return (
    <TableWrapper padding="0">
      <CustomTableHeader
        isTablet={isTablet}
        refetch={() => {}}
        columns={canUserEdit ? updatesTableColumnsEditMode : updatesTableColumnsViewMode}
        savedSort={sort}
        savedSetSort={setSort}
      />
      <TableBodyWrapper>
        {loading ? (
          <Loader />
        ) : (
          <>
            {!tableData.length ? (
              <NoResults />
            ) : (
              <TableBody
                tableData={tableData}
                dropdownActions={dropdownActions}
                openEditManagerUpdatePage={openEditManagerUpdatePage}
                canUserEdit={canUserEdit}
              />
            )}
          </>
        )}
      </TableBodyWrapper>
    </TableWrapper>
  );
};

const CustomTableHeader = styled(TableHeader)<{ isTablet: boolean }>`
  position: sticky;
  top: ${({ isTablet }) => (isTablet ? '95px' : '45px')};
  z-index: 8;
  padding-top: 0;

  #tableColumnTitle {
    flex-basis: auto;
  }
`;
