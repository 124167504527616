import ModalWrappedContent from '@/components/fat-basicComponents/modal/modalWrappedContent';
import styled from 'styled-components';

interface ConfirmDialogProps {
  onClose: () => void;
  isOpen: boolean;
  onSave: () => void;
}

const ConfirmDialog = ({ onClose, isOpen, onSave }: ConfirmDialogProps) => {
  return (
    <ModalWrappedContent
      isOpen={isOpen}
      onClose={onClose}
      confirmButton={{
        name: 'Confirm',
        onClick: onSave
      }}
    >
      <ModalBody>
        <ModalTitle>Update Commitment Plan</ModalTitle>
        <SubTitle>This action will overwrite your existing commitment plan. This action cannot be undone. Please Confirm.</SubTitle>
      </ModalBody>
    </ModalWrappedContent>
  );
};

export default ConfirmDialog;

const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 30px 0;
`;

const ModalTitle = styled.h2`
  text-align: center;
  color: ${({ theme }) => theme.font.strong};
  font-size: 33px;
  font-weight: 400;
  line-height: 46.2px;
`;

const SubTitle = styled.p`
  text-align: center;
  color: ${({ theme }) => theme.font.base};
  font-size: 19px;
  font-weight: 400;
  line-height: 26.6px;
`;
