import { useMutation } from '@apollo/client';
import { useNavigate, useLocation } from 'react-router-dom';
import { DeleteBucketIcon } from '@/assets/static/icons/delete-bucket';
import { useEffect, useState } from 'react';
import ModalWrappedContent from '@/components/basicComponents/modal/modalWrappedContent';
import { ITier } from '@/components/dueDiligence-page/dueDiligenceRecord/types';
import {
  DELETE_DUE_DILIGENCE_SECTION,
  GET_FUNNEL_QUERY,
  UPDATE_DRAFT_DUE_DILIGENCE_SECTION
} from '@/components/settings-page/queries';
import styled from 'styled-components';

interface DeleteSectionProps {
  onClose: () => void;
  isOpen: boolean;
  currentId: string;
  currentTier: ITier | null;
  openErrorModal: (type: string, title: string, description: string) => void;
  funnelRefetch: () => void;
}

const DeleteSection = ({
  onClose,
  isOpen,
  currentId,
  currentTier,
  openErrorModal,
  funnelRefetch
}: DeleteSectionProps) => {
  if (!currentTier) return <></>;
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const [sectionName, setSectionName] = useState('');

  useEffect(() => {
    const currentSection = currentTier.sections.find(
      (section) => section.id === currentId
    );
    if (currentSection) {
      setSectionName(currentSection.name);
    }
  }, [currentTier]);

  const [updateDraftSection] = useMutation(UPDATE_DRAFT_DUE_DILIGENCE_SECTION, {
    refetchQueries: () => [
      {
        query: GET_FUNNEL_QUERY,
        variables: {
          id: queryParams.get('funnelId')
        }
      }
    ]
  });
  const [deleteSection] = useMutation(DELETE_DUE_DILIGENCE_SECTION);

  const saveValue = () => {
    deleteSection({
      variables: {
        id: currentId
      },
      onCompleted: () => {
        const filteredSections = currentTier.sections.filter(
          (section) => section.id !== currentId
        );
        if (!filteredSections.length) {
          funnelRefetch();
          return;
        }
        for (const [index, section] of filteredSections.entries()) {
          updateDraftSection({
            variables: {
              data: {
                id: section.id,
                order: index + 1
              }
            }
          });
        }
      },
      onError: () => {
        openErrorModal(
          'linked-field-error',
          'Cannot Delete This Section',
          'You cannot delete this section because there are other fields linked to it. Please unlink the fields before deleting this one.'
        );
      }
    });
    onClose();
  };

  return (
    <ModalWrappedContent
      isOpen={isOpen}
      onClose={onClose}
      confirmButton={{ name: 'Confirm', onClick: saveValue }}
    >
      <ModalContainer>
        <DeleteBucketIcon width={46} height={60} />
        <ModalTitle>
          Please confirm you want to delete{' '}
          <span style={{ fontWeight: 700 }}>{sectionName}</span>.
        </ModalTitle>
        <ModalDescription>
          Data will be permanently lost upon deletion. There is no undo for this
          operation.
        </ModalDescription>
      </ModalContainer>
    </ModalWrappedContent>
  );
};

export default DeleteSection;

const ModalContainer = styled.div`
  font-weight: 400;
  padding: 10px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;

const ModalTitle = styled.div`
  font-size: 33px;
  font-weight: 400;
  text-align: center;
  color: #2e2e2e;
`;

const ModalDescription = styled.div`
  font-size: 16px;
  line-height: 27px;
  text-align: center;
  color: #373f4e;
  font-weight: 400;
`;
