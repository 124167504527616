import { useState } from 'react';
import { styled } from 'styled-components';

import { SignOutIcon } from '@/assets/static/icons/signOut';
import { LetterAvatars } from '@/components/avatar';
import { User } from '@/types/user';
import SignOut from './fat-modals/sign-out';

interface ProfileProps {
  user: User;
}

export const Profile = ({ user }: ProfileProps) => {
  const [isModalWindowSignOutOpen, setIsModalWindowSignOutOpen] = useState(false);

  const closeModalWindow = () => setIsModalWindowSignOutOpen((prevState) => !prevState);

  return (
    <>
      {isModalWindowSignOutOpen && <SignOut isOpen={isModalWindowSignOutOpen} onClose={closeModalWindow} />}
      <StyledBox>
        <ProfileTitle>Profile</ProfileTitle>
        <CustomLetterAvatars user={user} size={150} />
        <UserName>{`${user.firstName} ${user.lastName}`}</UserName>
        <UserEmail>{user.email}</UserEmail>
        <SignOutButton onClick={() => setIsModalWindowSignOutOpen(true)}>
          <SignOutIcon />
          Sign out
        </SignOutButton>
      </StyledBox>
    </>
  );
};

const StyledBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: Blinker;
  width: 50%;
`;

const ProfileTitle = styled.p`
  letter-spacing: 1px;
  line-height: 41px;
  color: ${({ theme }) => theme.font.strong};
  padding: 5%;
  font-weight: 600;
  font-size: 32px;
`;

const UserName = styled.p`
  line-height: 41px;
  color: ${({ theme }) => theme.font.strong};
  padding: 2%;
  font-size: 32px;
  font-weight: 300;
`;

const UserEmail = styled.p`
  letter-spacing: 1px;
  line-height: 41px;
  color: ${({ theme }) => theme.font.strong};
  padding-bottom: 5%;
  font-size: 14px;
  font-weight: 400;
`;

const CustomLetterAvatars = styled(LetterAvatars)`
  background: #2a2e3f;
`;

const SignOutButton = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  padding: 5px;
  color: ${(props) => props.theme.font.strong};
`;
