// import { Box } from '@mui/material';
import {
  default as MUITooltip,
  tooltipClasses,
  TooltipProps as MUITooltipProps
} from '@mui/material/Tooltip';
import { styled } from 'styled-components';
import React from 'react';

import { IPlacement } from './type';

interface TooltipProps {
  title: string;
  enterNextDelay?: number;
  enterDelay?: number;
  arrow?: boolean;
  placement?: IPlacement;
  className?: string;
  children: React.ReactNode;
}

export const Tooltip = ({
  title,
  enterNextDelay,
  enterDelay,
  arrow,
  placement = 'top',
  className,
  children
}: TooltipProps) => {
  return (
    <CustomTooltip
      className={className}
      title={title}
      arrow={arrow}
      placement={placement}
      enterNextDelay={enterNextDelay}
      enterDelay={enterDelay}
    >
      <div style={{ display: 'flex', alignItems: 'center' }}>{children}</div>
    </CustomTooltip>
  );
};

const CustomTooltip = styled(({ className, ...props }: MUITooltipProps) => (
  <MUITooltip {...props} classes={{ tooltip: className }} />
))`
  & .${tooltipClasses.tooltip} {
    padding: 10px 20px;
    font-size: 16px;
    color: ${(props) => props.theme.info.contrastText};
    background: ${(props) => props.theme.body.header};
  }

  & .${tooltipClasses.arrow} {
    color: ${(props) => props.theme.body.header};
  }
`;
