import Button from '@/components/fat-basicComponents/button';
import ModalWrappedContent from '@/components/fat-basicComponents/modal/modalWrappedContent';
import { Loader } from '@/components/loaders/loader';
import { useCallback } from 'react';
import styled from 'styled-components';

export enum DialogStatus {
  LOADING = 'loading',
  SUCCESS = 'success',
  FAILED = 'failed',
  WARNING = 'warning'
}

interface IDialogProps {
  status: DialogStatus | undefined;
  isOpen: boolean;
  isModify: boolean;
  onClose: () => void;
  onSuccess: () => void;
  onCreateInvestment: () => void;
  onUpdateInvestment: () => void;
  updateInvestmentRestrictionsErrors: string[] | null;
}

const Dialog = ({
  isOpen,
  isModify,
  onClose,
  status,
  onSuccess,
  onCreateInvestment,
  onUpdateInvestment,
  updateInvestmentRestrictionsErrors
}: IDialogProps) => {
  const renderContent = useCallback(() => {
    switch (status) {
      case DialogStatus.LOADING:
        return <Loading />;
      case DialogStatus.SUCCESS:
        return <Success onSuccess={onSuccess} />;
      case DialogStatus.FAILED:
        return (
          <Failed
            onClose={onClose}
            reFetch={isModify ? onUpdateInvestment : onCreateInvestment}
            updateInvestmentRestrictionsErrors={updateInvestmentRestrictionsErrors}
          />
        );
      case DialogStatus.WARNING:
        return <Warning onClose={onClose} onConfirm={onUpdateInvestment} />;
      default:
        return <Default onClose={onClose} />;
    }
  }, [status]);

  const shouldShowCloseIcon = () => status !== DialogStatus.LOADING && status !== DialogStatus.SUCCESS;

  return (
    <ModalWrappedContent
      background={false}
      isUseOutsideClick={false}
      showCancelButton={false}
      showCloseIcon={shouldShowCloseIcon()}
      isOpen={isOpen}
      onClose={onClose}
    >
      {renderContent()}
    </ModalWrappedContent>
  );
};

const Loading = () => {
  return (
    <ModalBody>
      <Title>Saving Investment</Title>
      <ModalText>Please allow a few moment for this process to complete.</ModalText>
      <Loader />
    </ModalBody>
  );
};

const Success = ({ onSuccess }: { onSuccess: () => void }) => {
  return (
    <ModalBody>
      <Icon src="/static/icons/check-circle.svg" alt="Success" />
      <Title>Investment Saved Successfully</Title>
      <WrapperButton>
        <Button onClick={onSuccess} size="md">
          OK
        </Button>
      </WrapperButton>
    </ModalBody>
  );
};

const Failed = ({
  onClose,
  reFetch,
  updateInvestmentRestrictionsErrors
}: {
  onClose: () => void;
  reFetch: () => void;
  updateInvestmentRestrictionsErrors: string[] | null;
}) => {
  return (
    <ModalBody>
      <Title>Proccess Failed</Title>
      {updateInvestmentRestrictionsErrors ? (
        <>
          <ErrorMessages>
            {updateInvestmentRestrictionsErrors.map((error, index) => (
              <ErrorMessage key={index}>{error}</ErrorMessage>
            ))}
          </ErrorMessages>
          <Button onClick={onClose} size="md">
            OK
          </Button>
        </>
      ) : (
        <>
          <ModalText>We're sorry. This process did not complete. Please try again.</ModalText>
          <WrapperButton>
            <Button styleType="outline" onClick={onClose} size="md">
              Cancel
            </Button>
            <Button onClick={reFetch} size="md">
              Retry
            </Button>
          </WrapperButton>
        </>
      )}
    </ModalBody>
  );
};

const Warning = ({ onClose, onConfirm }: { onClose: () => void; onConfirm: () => void }) => {
  return (
    <ModalBody>
      <Title>Warning</Title>
      <ModalText>Allocations for this investment are underway. Changing some fields may imply that some allocations are no longer valid.</ModalText>
      <WrapperButton>
        <Button styleType="outline" onClick={onClose} size="md">
          Cancel
        </Button>
        <Button onClick={onConfirm} size="md">
          Confirm
        </Button>
      </WrapperButton>
    </ModalBody>
  );
};

const Default = ({ onClose }: { onClose: () => void }) => {
  return (
    <ModalBody>
      <Title>Something Went Wrong</Title>
      <WrapperButton>
        <Button onClick={onClose} size="md">
          OK
        </Button>
      </WrapperButton>
    </ModalBody>
  );
};

const ModalBody = styled.div`
  text-align: center;
`;
const ModalText = styled.div<{ bold?: boolean }>`
  display: inline-block;
  text-align: center;
  font-size: 19px;
  font-weight: ${({ bold }) => (bold ? 600 : 400)};
  margin-left: ${({ bold }) => (bold ? '3px' : 0)};
`;

const ErrorMessages = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 20px 0;
  max-height: 180px;
  overflow: auto;
`;

const ErrorMessage = styled.div`
  display: inline-block;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
`;

const Title = styled.h1`
  font-family: 'Blinker', serif;
  font-weight: 400;
  font-size: 33px;
  text-align: center;
  color: ${({ theme }) => theme.font.strong};
  margin-bottom: 10px;
`;

const Icon = styled.img`
  width: 60px;
  height: 60px;
`;

const WrapperButton = styled.span`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 50px;
  gap: 10px;
`;

export default Dialog;
