import { CloseInfoIcon } from '@/assets/icons/info-icons/closeInfo';
import Label from '@/components/basicComponents/label';
import FilterSelect from '@/components/basicComponents/select';
import { ToggleCircle } from '@/components/fat-form-field';
import { blinkingFrame } from '@/components/table/styles';
import { useResponsive } from '@/hooks/use-responsive';
import { Dispatch, SetStateAction } from 'react';
import styled, { useTheme } from 'styled-components';
import { AddButton } from '../../addButton';
import { Section } from '../../section';
import { IListData } from '../../types';
import { addStrategies, delStrategies, onChangeStrategies } from '../../utils';

interface AllocationSettingsSectionProps {
  strategies: IListData;
  setStrategies: Dispatch<SetStateAction<IListData>>;
  tags: IListData;
  setTags: Dispatch<SetStateAction<IListData>>;
  control: any;
  openModalWindow: (type: string) => void;
}

export const AllocationSettingsSection = ({ strategies, setStrategies, tags, setTags, control, openModalWindow }: AllocationSettingsSectionProps) => {
  const { isMobile } = useResponsive();
  const theme = useTheme();

  return (
    <Section title="Allocation Settings">
      <AllocationSettingsSectionWrap>
        <Line>
          <Space>
            <ToggleCircleWrap>
              <ToggleCircle
                nameField="ubtiBlocker"
                control={control}
                label="Tax Exempt Suitable"
                tooltip="Answer Yes if this strategy is suitable for tax exempt investors. Either there is a UBTI Blocker in place, such as an offshore vehicle, or there is no risk of generating UBTI"
                tooltipWidth={300}
              />
            </ToggleCircleWrap>
          </Space>
        </Line>
        <Line>
          <StrategiesWrap>
            <Label required={false} tooltip={'Used for categorization and automated allocation rules'} tooltipWidth={250}>
              Sectors
            </Label>
            {strategies.chosen.map((el, index) => (
              <StrategiesItemWrap key={`strategies-${index}`} loading={index === strategies.chosen.length - 1 && strategies.duplicate ? 1 : 0}>
                <CustomFilterSelect
                  data={strategies.list}
                  selected={strategies.chosen[index]}
                  setSelected={(value) => onChangeStrategies(value, index, strategies, setStrategies)}
                  disabled={
                    strategies.chosen[strategies.chosen.length - 1] !== el || strategies.chosen[strategies.chosen.length - 1] === strategies.duplicate
                  }
                  width="100%"
                  withSearch
                  fontSize="16px"
                />
                <DelItemWrap onClick={() => delStrategies(el, index, strategies, setStrategies)}>
                  <CloseInfoIcon width={21} height={20} fill={theme.font.action} />
                </DelItemWrap>
              </StrategiesItemWrap>
            ))}
            {strategies.list.length && strategies.chosen[strategies.chosen.length - 1] !== 'None' ? (
              <AddButton text="Add" onClick={() => addStrategies(strategies, setStrategies)} />
            ) : (
              <AddButton text="Create New" onClick={() => openModalWindow('create-strategy')} />
            )}
          </StrategiesWrap>
          <StrategiesWrap>
            <Label required={false} tooltip={'Used for categorization (informational field only)'}>
              Focus
            </Label>
            {tags.chosen.map((el, index) => (
              <StrategiesItemWrap key={`tags-${index}`} loading={index === tags.chosen.length - 1 && tags.duplicate ? 1 : 0}>
                <CustomFilterSelect
                  data={tags.list}
                  selected={tags.chosen[index]}
                  setSelected={(value) => onChangeStrategies(value, index, tags, setTags)}
                  disabled={tags.chosen[tags.chosen.length - 1] !== el || tags.chosen[tags.chosen.length - 1] === tags.duplicate}
                  width="100%"
                  withSearch
                  fontSize="16px"
                />
                <DelItemWrap onClick={() => delStrategies(el, index, tags, setTags)}>
                  <CloseInfoIcon width={21} height={20} fill={theme.font.action} />
                </DelItemWrap>
              </StrategiesItemWrap>
            ))}
            {tags.list.length && tags.chosen[tags.chosen.length - 1] !== 'None' ? (
              <AddButton text="Add" onClick={() => addStrategies(tags, setTags)} />
            ) : (
              <AddButton text="Create New" onClick={() => openModalWindow('create-tag')} />
            )}
          </StrategiesWrap>
        </Line>
      </AllocationSettingsSectionWrap>
    </Section>
  );
};

const AllocationSettingsSectionWrap = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 20px;
`;

const Space = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
`;

const Line = styled.span<{ width50?: boolean }>`
  display: flex;
  justify-content: space-between;
  width: ${({ width50 }) => (width50 ? '50%' : '100%')};
  gap: 30px;
  padding-right: ${({ width50 }) => (width50 ? '15px' : '0')};
  margin-top: 4px;
`;

const ToggleCircleWrap = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 30%;
`;

const CustomFilterSelect = styled(FilterSelect)`
  background: ${({ disabled, theme }) => (disabled ? theme.action.disabled : theme.layer[2])};
  border: 1px solid ${({ theme }) => theme.border.base};
  padding: 10px 12px;
  border-radius: 5px;

  #titleSelect {
    text-transform: none;
    font-family: Blinker;
    font-size: 16px;
    font-weight: 400;
    line-height: 22.4px;
  }
`;

const StrategiesWrap = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

const StrategiesItemWrap = styled.div<{ loading?: boolean | number }>`
  display: flex;
  width: 100%;
  align-items: center;
  margin-bottom: 10px;
  position: relative;

  opacity: ${({ loading }) => (loading ? 0.5 : 1)};
  pointer-events: ${({ loading }) => (loading ? 'none' : 'auto')};
  animation: ${({ loading }) => (loading ? blinkingFrame : null)} 1.5s infinite;
`;

const DelItemWrap = styled.div`
  position: relative;
  top: 3px;
  padding-left: 15px;
  cursor: pointer;
`;
