import { useRef, useState } from 'react';
import styled, { css } from 'styled-components';

import { ArrowDown } from '@/assets/icons/info-icons/arrowDown';
import { useResponsive } from '../../hooks/use-responsive';
import { useClickOutside } from '../../hooks/useClickOutside';
import { FadeAnimation } from '../../styles/animations';
import { SIZE_DROPDOWN_ACTIONS } from './constants';
import { IItems } from './types';

interface ClientDropdownProps {
  items: IItems[];
  disabled?: boolean;
  isMobile?: boolean;
  selected?: boolean;
  isListCenter?: boolean;
  showListItems?: boolean;
}

const DropdownActions = ({
  items,
  disabled = false,
  isMobile = false,
  selected = false,
  isListCenter = false,
  showListItems = true
}: ClientDropdownProps) => {
  const { isTablet } = useResponsive();
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const handleClick = (event: any) => {
    if (event.target.id !== 'modal') {
      setIsOpen(!isOpen);
    }
  };
  useClickOutside(ref, () => setIsOpen(false));

  return (
    <>
      {isMobile ? (
        <ClientDropdownMobileWrap isOpen={isOpen} onClick={handleClick} id="infoWrap" ref={ref} disabled={disabled}>
          <ArrowDown />
          {(isOpen || showListItems) && (
            <ClientDropdownModal isOpen={isOpen} isListCenter={isListCenter}>
              {items.map(({ name, onClick }) => (
                <ClientDropdownItem key={name} onClick={onClick}>
                  {name}
                </ClientDropdownItem>
              ))}
            </ClientDropdownModal>
          )}
        </ClientDropdownMobileWrap>
      ) : (
        <ClientDropdownWrap isOpen={isOpen} onClick={handleClick} id="infoWrap" ref={ref} opacityTime={0} disabled={disabled} removepointerevents>
          <ArrowDown />
          {(isOpen || showListItems) && (
            <ClientDropdownModal isOpen={isOpen}>
              {items.map(({ name, onClick }) => (
                <ClientDropdownItem key={name} onClick={onClick}>
                  {name}
                </ClientDropdownItem>
              ))}
            </ClientDropdownModal>
          )}
        </ClientDropdownWrap>
      )}
    </>
  );
};

export const EmptyDropdownActions = () => {
  const { isTablet } = useResponsive();

  return (
    <>
      <ClientDropdownWrap isTablet={isTablet} disabled={false} isOpen={false}>
        <ArrowDown />
        <ClientDropdownModal isOpen={false}></ClientDropdownModal>
      </ClientDropdownWrap>
    </>
  );
};

const ClientDropdownItem = styled.div`
  display: flex;
  cursor: pointer;
  padding: 9px 11px;
  color: #4587ec;
  background: #fff;
  width: 285px;
  text-transform: capitalize;

  &:hover {
    color: #fff;
    background: #1c488a;
  }

  font-family: Blinker, serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0;
  text-align: left;
`;

const ClientDropdownMobileWrap = styled.div<{
  disabled: boolean;
  isOpen: boolean;
}>`
  cursor: pointer;
  border-radius: 4px;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background: ${({ isOpen }) => (isOpen ? '#3AB070' : '#1c488a')};
  margin-left: auto;
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
`;

const ClientDropdownWrap = styled(FadeAnimation)<{
  isTablet?: boolean;
  disabled?: boolean;
  selected?: boolean;
}>`
  cursor: pointer;
  border-radius: 4px;
  width: ${SIZE_DROPDOWN_ACTIONS}px;
  height: ${SIZE_DROPDOWN_ACTIONS}px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  ${({ isTablet, selected, isOpen }) =>
    isTablet
      ? css`
          ${selected
            ? css`
                opacity: 1;
                background: #3ab070;
              `
            : css`
                background: #1c488a;
              `}
        `
      : css`
          background: ${isOpen ? '#3AB070' : '#1c488a'};
        `};
  margin-left: auto;
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
`;

const ClientDropdownModal = styled(FadeAnimation)<{ isListCenter?: boolean }>`
  z-index: 10;
  cursor: default;
  top: 45px;
  ${({ isListCenter }) =>
    !isListCenter &&
    css`
      right: 0;
    `};
  display: flex;
  flex-direction: column;
  position: absolute;
  background: #ffffff;
  border: 1px solid #c7cbd2;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  padding: 10px 0;
`;

export default DropdownActions;
