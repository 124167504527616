import { EditTodoIcon } from '@/assets/icons/info-icons/editTodo';
import { AddButtonIcon } from '@/assets/static/icons/add-button-icon';
import { UploadFileIcon } from '@/assets/static/icons/upload-file';
import Button from '@/components/fat-basicComponents/button';
import { useResponsive } from '@/hooks/use-responsive';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';
import { SectionSelect, SectionZeroStateText, SectionZeroStateWrapper } from '..';
import { Section } from '../../edit-entity/section';

const ITEMS = ['Bank Name', 'Intermediary Bank', 'Beneficiary Account Name', 'Beneficiary Account Number', 'Account Type'];

interface BankAccountsSectionProps {
  saveScrollPostion: () => void;
}

export const BankAccountsSection = ({ saveScrollPostion }: BankAccountsSectionProps) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { isMobile } = useResponsive();

  const [showSectionZeroState, setShowSectionZeroState] = useState(false);
  const [selectedAction, setSelectedAction] = useState('Please Select');

  const openBankAccountFormPage = () => {
    saveScrollPostion();
    navigate('bankAccountInfo?fromSection=Bank Accounts&backToTitle=Investor Documentation');
  };

  const openAddDocumentPage = () => {
    saveScrollPostion();
    navigate('addDocument?fromSection=Bank Accounts&backToTitle=Investor Documentation');
  };

  if (showSectionZeroState) {
    return (
      <Section title="Bank Accounts">
        <SectionZeroStateWrapper>
          <SectionZeroStateText>No information has been added yet.</SectionZeroStateText>
          <SectionSelect
            selected={selectedAction}
            setSelected={setSelectedAction}
            data={['Enter Bank Account Info', 'Upload a file', 'Skip']}
            width="400px"
          />
          {selectedAction === 'Enter Bank Account Info' && (
            <Button
              onClick={openBankAccountFormPage}
              styleType="outline"
              icon={<AddButtonIcon fill={theme.font.action} />}
              size="md"
              width="max-content"
            >
              Add
            </Button>
          )}
          {selectedAction === 'Upload a file' && (
            <Button
              onClick={openAddDocumentPage}
              styleType="outline"
              icon={<UploadFileIcon width={23} height={23} fill={theme.font.action} />}
              size="md"
              width="max-content"
            >
              Upload a file
            </Button>
          )}
        </SectionZeroStateWrapper>
      </Section>
    );
  }

  return (
    <Section title="Bank Accounts">
      <BankAccountsSectionContent>
        <BankAccountsWrapper isMobile={isMobile}>
          <BankAccountsItemsWrapper>
            {ITEMS.map((item) => (
              <TwoColumnWrapper key={item} isMobile={isMobile}>
                <BankAccountsItem>{item}</BankAccountsItem>
                <BankAccountsItem isBold>MOCKED DATA</BankAccountsItem>
              </TwoColumnWrapper>
            ))}
          </BankAccountsItemsWrapper>
          <EditIconWrapper isMobile={isMobile} id="editButton" onClick={openBankAccountFormPage}>
            <EditTodoIcon />
          </EditIconWrapper>
        </BankAccountsWrapper>
        <Divider />
        <Button onClick={openBankAccountFormPage} styleType="outline" icon={<AddButtonIcon fill={theme.font.action} />} size="md" width="max-content">
          Add
        </Button>
      </BankAccountsSectionContent>
    </Section>
  );
};

const BankAccountsSectionContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const Divider = styled.div`
  height: 1px;
  width: 100%;
  margin: 20px 0;
  background-color: ${({ theme }) => theme.border.base};
`;

const BankAccountsWrapper = styled.div<{ isMobile: boolean }>`
  display: flex;
  align-items: center;
  gap: 15px;
  flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};
  padding: ${({ isMobile }) => (isMobile ? '0' : '20px')};
  transition: 0.3s ease-in-out;

  @media (hover: hover) {
    &:hover #editButton {
      opacity: 1;
    }

    &:hover {
      background-color: ${({ theme }) => theme.layer[12]};
    }
  }
`;

const BankAccountsItemsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const TwoColumnWrapper = styled.div<{ isMobile: boolean }>`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  width: ${({ isMobile }) => (isMobile ? '100%' : '60%')};
  padding: 2px 10px;
`;

const BankAccountsItem = styled.div<{ isBold?: boolean }>`
  font-family: Blinker;
  font-size: 16px;
  font-weight: ${({ isBold }) => (isBold ? 600 : 400)};
  line-height: 22.4px;
  color: ${({ theme }) => theme.font.base};
  padding: 4px;
`;

const EditIconWrapper = styled.div<{ isMobile: boolean }>`
  width: ${({ isMobile }) => (isMobile ? '100%' : 'max-content')};
  padding: 12px;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.action.default};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  cursor: pointer;
  opacity: ${({ isMobile }) => (isMobile ? '1' : '0')};
  transition: 0.3s ease-in-out;
`;
