import { EditTodoIcon } from '@/assets/icons/info-icons/editTodo';
import { AddButtonIcon } from '@/assets/static/icons/add-button-icon';
import Button from '@/components/fat-basicComponents/button';
import { useResponsive } from '@/hooks/use-responsive';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';
import { SectionZeroStateText, SectionZeroStateWrapper } from '..';
import { Section } from '../../edit-entity/section';

interface EligibilitySectionProps {
  saveScrollPostion: () => void;
}

export const EligibilitySection = ({ saveScrollPostion }: EligibilitySectionProps) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { isMobile } = useResponsive();

  const [showSectionZeroState, setShowSectionZeroState] = useState(false);

  const openEligibilityPage = () => {
    saveScrollPostion();
    navigate('eligibility?fromSection=Eligibility&backToTitle=Investor Documentation');
  };

  if (showSectionZeroState) {
    return (
      <Section title="Eligibility">
        <SectionZeroStateWrapper>
          <SectionZeroStateText>No information has been added yet.</SectionZeroStateText>
          <Button onClick={openEligibilityPage} styleType="outline" icon={<AddButtonIcon fill={theme.font.action} />} size="md" width="max-content">
            Add Eligibility Information
          </Button>
        </SectionZeroStateWrapper>
      </Section>
    );
  }

  return (
    <Section title="Eligibility">
      <EligibilityWrapper isMobile={isMobile}>
        <EligibilityItemsWrapper>
          <TwoColumnWrapper isMobile={isMobile}>
            <EligibilityItem>Accredited Investor</EligibilityItem>
            <EligibilityItem isBold>MOCKED DATA</EligibilityItem>
          </TwoColumnWrapper>
          <TwoColumnWrapper isMobile={isMobile}>
            <EligibilityItem isBold>Bank Acting As Trustee</EligibilityItem>
            <EligibilityItem isBold></EligibilityItem>
          </TwoColumnWrapper>
          <TwoColumnWrapper isMobile={isMobile}>
            <EligibilityItem isBold>Government Benefit Plan</EligibilityItem>
            <EligibilityItem isBold></EligibilityItem>
          </TwoColumnWrapper>
          <Divider />
          <TwoColumnWrapper isMobile={isMobile}>
            <EligibilityItem>Qualified Purchaser</EligibilityItem>
            <EligibilityItem isBold>MOCKED DATA</EligibilityItem>
          </TwoColumnWrapper>
          <Divider />
        </EligibilityItemsWrapper>
        <EditIconWrapper onClick={openEligibilityPage} isMobile={isMobile} id="editButton">
          <EditTodoIcon />
        </EditIconWrapper>
      </EligibilityWrapper>
      <Button onClick={openEligibilityPage} styleType="outline" icon={<AddButtonIcon fill={theme.font.action} />} size="md" width="max-content">
        Add Eligibility Information
      </Button>
    </Section>
  );
};

const EligibilityWrapper = styled.div<{ isMobile: boolean }>`
  display: flex;
  align-items: center;
  gap: 15px;
  flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};
  padding: ${({ isMobile }) => (isMobile ? '0' : '20px')};
  transition: 0.3s ease-in-out;

  @media (hover: hover) {
    &:hover #editButton {
      opacity: 1;
    }

    &:hover {
      background-color: ${({ theme }) => theme.layer[12]};
    }
  }
`;

const EligibilityItemsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
`;

const TwoColumnWrapper = styled.div<{ isMobile: boolean }>`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  gap: 20px;
  width: ${({ isMobile }) => (isMobile ? '100%' : '60%')};
  padding: 2px 10px;
`;

const EligibilityItem = styled.div<{ isBold?: boolean }>`
  font-family: Blinker;
  font-size: 16px;
  font-weight: ${({ isBold }) => (isBold ? 600 : 400)};
  line-height: 22.4px;
  color: ${({ theme }) => theme.font.base};
  padding: 4px;
`;

const EditIconWrapper = styled.div<{ isMobile: boolean }>`
  width: ${({ isMobile }) => (isMobile ? '100%' : 'max-content')};
  padding: 12px;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.action.default};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  cursor: pointer;
  opacity: ${({ isMobile }) => (isMobile ? '1' : '0')};
  transition: 0.3s ease-in-out;
`;

const Divider = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${({ theme }) => theme.border.base};
  margin: 20px 0;
`;
