import * as React from 'react';

export const IInfoIcon = ({ ...props }: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" style={props?.style} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.7974 16.2344L13.6457 16.8515C13.1905 17.0298 12.8273 17.1647 12.556 17.2561C12.2847 17.3521 11.9698 17.4001 11.6112 17.4001C11.0595 17.4001 10.6296 17.2675 10.3215 17.0024C10.0181 16.7327 9.86635 16.3921 9.86635 15.9807C9.86635 15.8207 9.87784 15.6584 9.90083 15.4938C9.92382 15.3247 9.9606 15.135 10.0112 14.9247L10.5767 12.9224C10.6273 12.7304 10.6686 12.5498 10.7008 12.3807C10.7376 12.207 10.756 12.0492 10.756 11.9075C10.756 11.6515 10.7031 11.4732 10.5974 11.3727C10.4916 11.2721 10.2893 11.2218 9.99049 11.2218C9.84336 11.2218 9.69164 11.2447 9.53532 11.2904C9.38359 11.3361 9.25256 11.3795 9.14221 11.4207L9.29394 10.8035C9.66635 10.6527 10.0227 10.5247 10.3629 10.4195C10.7031 10.3098 11.025 10.255 11.3284 10.255C11.8755 10.255 12.2962 10.3875 12.5905 10.6527C12.8893 10.9132 13.0388 11.2538 13.0388 11.6744C13.0388 11.7612 13.0273 11.9144 13.0043 12.1338C12.9859 12.3532 12.9491 12.5544 12.8939 12.7372L12.3284 14.7327C12.2824 14.8927 12.2411 15.0755 12.2043 15.2812C12.1675 15.487 12.1491 15.6424 12.1491 15.7475C12.1491 16.0127 12.2089 16.1932 12.3284 16.2892C12.448 16.3852 12.6549 16.4332 12.9491 16.4332C13.087 16.4332 13.2434 16.4104 13.4181 16.3647C13.5928 16.3144 13.7192 16.271 13.7974 16.2344ZM13.9422 7.86181C13.9422 8.20924 13.8089 8.50638 13.5422 8.75324C13.2801 8.99553 12.9629 9.11667 12.5905 9.11667C12.2181 9.11667 11.8985 8.99553 11.6319 8.75324C11.3652 8.50638 11.2319 8.20924 11.2319 7.86181C11.2319 7.51438 11.3652 7.21724 11.6319 6.97038C11.8985 6.72353 12.2181 6.6001 12.5905 6.6001C12.9629 6.6001 13.2801 6.72353 13.5422 6.97038C13.8089 7.21724 13.9422 7.51438 13.9422 7.86181Z"
        fill={props.fill || '#EF9208'}
      />
      <path
        d="M11.5477 3C9.76726 2.99893 8.02648 3.526 6.54561 4.51453C5.06474 5.50305 3.91035 6.90861 3.22849 8.55336C2.54663 10.1981 2.36793 12.0082 2.71503 13.7545C3.06212 15.5008 3.91939 17.105 5.17838 18.364C6.43738 19.623 8.04153 20.4802 9.78786 20.8273C11.5342 21.1744 13.3442 20.9957 14.989 20.3139C16.6337 19.632 18.0393 18.4776 19.0278 16.9968C20.0164 15.5159 20.5434 13.7751 20.5424 11.9946C20.5409 9.60952 19.5928 7.32255 17.9063 5.63604C16.2198 3.94954 13.9328 3.00143 11.5477 3ZM11.5477 19.1967C10.1231 19.1978 8.73009 18.7763 7.54499 17.9856C6.35989 17.1949 5.43592 16.0704 4.88998 14.7545C4.34404 13.4386 4.20063 11.9903 4.47791 10.5929C4.7552 9.19542 5.44072 7.9116 6.44774 6.90382C7.45476 5.89604 8.73808 5.20958 10.1353 4.93124C11.5325 4.65291 12.9809 4.79521 14.2973 5.34016C15.6136 5.88512 16.7387 6.80824 17.5303 7.99275C18.322 9.17725 18.7445 10.5699 18.7445 11.9946C18.7417 13.9029 17.9827 15.7324 16.6338 17.0822C15.2849 18.4321 13.4561 19.1925 11.5477 19.1967Z"
        fill={props.fill || '#EF9208'}
      />
    </svg>
  );
};
