import { Dispatch, SetStateAction, useRef } from 'react';
import styled, { css, useTheme } from 'styled-components';

import { CloseInfoIcon } from '@/assets/icons/info-icons/closeInfo';
import { MinusScoreIcon } from '@/assets/icons/minusScore';
import { PlusScoreIcon } from '@/assets/icons/plusScore';
import { useResponsive } from '../../../hooks/use-responsive';
import { useClickOutside } from '../../../hooks/useClickOutside';

interface ChangeScoreProps {
  count: number;
  setCount: Dispatch<SetStateAction<number>>;
  onClose: Dispatch<SetStateAction<boolean>>;
  updateScore: (count: number) => void;
}

export const ChangeScore = ({ count, setCount, onClose, updateScore }: ChangeScoreProps) => {
  const { isMobile } = useResponsive();
  const theme = useTheme();
  const ref = useRef<HTMLDivElement | null>(null);

  useClickOutside<HTMLDivElement>(ref, () => onClose(false));
  return (
    <ChangeScoreWrapper isMobile={isMobile} ref={ref}>
      <SelectHeader>
        <SelectTitle>Score:</SelectTitle>
        <div>
          <CloseInfoIcon width={14} height={13} fill={theme.font.action} style={{ cursor: 'pointer' }} onClick={() => onClose(false)} />
        </div>
      </SelectHeader>
      <SelectBody>
        <ControlButton
          onClick={() => {
            setCount((prev) => prev - 1);
            updateScore(count - 1);
          }}
        >
          <MinusScoreIcon fill={theme.font.action} />
        </ControlButton>
        <Count>{count}</Count>
        <ControlButton
          onClick={() => {
            setCount((prev) => prev + 1);
            updateScore(count + 1);
          }}
        >
          <PlusScoreIcon fill={theme.font.action} />
        </ControlButton>
      </SelectBody>
    </ChangeScoreWrapper>
  );
};

const ChangeScoreWrapper = styled.div<{ isMobile: boolean }>`
  position: absolute;
  top: ${({ isMobile }) => (isMobile ? '50px' : '60px')};
  ${({ isMobile }) =>
    isMobile
      ? css`
          left: 0;
        `
      : css`
          right: 45px;
        `};
  background: ${({ theme }) => theme.layer[1]};
  border: 1px solid ${({ theme }) => theme.border.base};
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.15);
  border-radius: 3px;
  width: 100%;
  max-width: 158px;
  z-index: 20;
`;

const SelectHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 6.5px 8px;
`;
const SelectTitle = styled.div`
  font-weight: 400;
  font-size: 13px;
  color: ${({ theme }) => theme.font.weak};
`;

const Cross = styled.img`
  width: 14px;
  cursor: pointer;
`;

const SelectBody = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2px;
  padding: 10px;
  border-top: 1px solid ${({ theme }) => theme.border.base};
`;
const ControlButton = styled.div`
  padding: 7px;
  border: 1px solid ${({ theme }) => theme.font.action};
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const Count = styled(ControlButton)`
  border: 1px solid ${({ theme }) => theme.border.base};
  font-weight: 400;
  font-size: 13px;
  color: ${({ theme }) => theme.font.strong};
  height: 32px;
  width: 40px;
`;
