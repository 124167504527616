import FormattingTooltip from '@/components/basicComponents/tooltip/dollarsTooltip';
import { IInvestmentEntityByAdvisoryFirm } from '@/components/fat-dealPipeline-page/types';
import { Loader } from '@/components/loaders/loader';
import DropdownActions from '@/components/table/fat-dropdownActions';
import {
  MobileRow,
  MobileRowTitle,
  MobileRowValue,
  MobileTableRowWrap,
  MobileTableWrapper,
  NoResults
} from '@/components/table/mobileTable/mobileTable';
import { format } from 'date-fns';
import styled from 'styled-components';
import { advisorColumns } from '../../constatnts';

interface MobileTableAdvisorProps {
  listInvestmentEntityByAdvisoryFirm: IInvestmentEntityByAdvisoryFirm[];
  loading: boolean;
  openAdvisorFirmDetails: (id: string) => void;
}

const MobileTableAdvisor = ({ listInvestmentEntityByAdvisoryFirm, loading, openAdvisorFirmDetails }: MobileTableAdvisorProps) => {
  if (loading) return <Loader />;

  const TableRow = ({ el }: { el: IInvestmentEntityByAdvisoryFirm }) => {
    return (
      <MobileTableRowWrap key={el.id}>
        <MobileRow>
          <MobileRowTitle>{advisorColumns[0].title}</MobileRowTitle>
          <MobileRowValue isRedirect onClick={() => openAdvisorFirmDetails(el.id)}>
            {el.name}
          </MobileRowValue>
        </MobileRow>

        <MobileRow>
          <MobileRowTitle>{advisorColumns[1].title}</MobileRowTitle>
          <MobileRowValue>
            {el.estimatedTaxableCommitment ? <FormattingTooltip zIndex={1000}>{el.estimatedTaxableCommitment}</FormattingTooltip> : <></>}
          </MobileRowValue>
        </MobileRow>

        <MobileRow>
          <MobileRowTitle>{advisorColumns[2].title}</MobileRowTitle>
          <MobileRowValue>
            {el.estimatedExemptCommitment ? <FormattingTooltip zIndex={1000}>{el.estimatedExemptCommitment}</FormattingTooltip> : <></>}
          </MobileRowValue>
        </MobileRow>

        <MobileRow>
          <MobileRowTitle>{advisorColumns[3].title}</MobileRowTitle>
          <MobileRowValue>
            <FormattingTooltip zIndex={1000}>{el.estimatedCommitment}</FormattingTooltip>
          </MobileRowValue>
        </MobileRow>

        <MobileRow>
          <MobileRowTitle>{advisorColumns[4].title}</MobileRowTitle>
          <MobileRowValue>{el.investorCount}</MobileRowValue>
        </MobileRow>

        <MobileRow>
          <MobileRowTitle>{advisorColumns[5].title}</MobileRowTitle>
          <MobileRowValue>{el.updatedAt && format(new Date(el.updatedAt), 'LLL d, yyyy')}</MobileRowValue>
        </MobileRow>

        <DropdownActionsWrapper>
          <DropdownActions
            isMobile
            items={[
              {
                name: 'View Investors',
                onClick: () => openAdvisorFirmDetails(el.id)
              }
            ]}
            isListCenter
          />
        </DropdownActionsWrapper>
      </MobileTableRowWrap>
    );
  };

  return (
    <MobileTableWrapper>
      {listInvestmentEntityByAdvisoryFirm.length ? (
        listInvestmentEntityByAdvisoryFirm.map((el, index) => {
          return <TableRow el={el} key={index} />;
        })
      ) : (
        <NoResults>No Results</NoResults>
      )}
    </MobileTableWrapper>
  );
};

export default MobileTableAdvisor;

const DropdownActionsWrapper = styled.div`
  margin: 0 auto;
`;
