import styled from 'styled-components';

import { Container, Title } from '../styles';
import { IFundsClosingSoon } from '../types/funds-closing-soon';
import { CloseItem } from './close-item';
import { updateListOfClose } from './utils';

interface FundsClosingSoonProps {
  data: IFundsClosingSoon;
  isMobile: boolean;
}

export const FundsClosingSoon = ({ data, isMobile }: FundsClosingSoonProps) => {
  return (
    <Container>
      <Title>Funds Closing Soon</Title>
      <Content>
        <CloseItem isMobile={isMobile} data={updateListOfClose(data.finalClose)} title={'Final Close'} />
        <CloseItem isMobile={isMobile} data={updateListOfClose(data.nextClose)} title={'Next Close'} />
      </Content>
    </Container>
  );
};

const Content = styled.div`
  display: flex;
  align-items: stretch;
  gap: 30px;
  padding-top: 10px;
  padding-bottom: 50px;
  @media (max-width: 1300px) {
    flex-direction: column;
  }
`;
