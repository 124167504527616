import * as React from 'react';

export const ArrowUp = ({ ...props }: React.SVGProps<SVGSVGElement>) => (
  <svg
    onClick={props.onClick || undefined}
    width={props.width || 28}
    height={props.height || 28}
    style={props?.style}
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M9.41 17.705L14 13.127L18.59 17.705L20 16.295L14 10.295L8 16.295L9.41 17.705Z" fill={props.fill || '#4587EC'} />
  </svg>
);
