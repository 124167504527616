import { AiAssistantIcon } from '@/assets/static/icons/ai-assistant';
import styled from 'styled-components';

interface TrialLimitBannerProps {
  currentDDRRecordCount: number;
  maxDeals: number;
}

const TrialLimitBanner = ({ currentDDRRecordCount, maxDeals }: TrialLimitBannerProps) => {
  return (
    <BannerWrapper>
      <AiAssistantIcon width={33} height={33} fill="#FAFAFA" />
      <DescriptionWrap>
        <Text fontSize="20px" fontWeight={700}>
          Welcome to your Deal Pilot Trial.
        </Text>
        <Text fontSize="16px" fontWeight={400}>
          to sign up for more.
        </Text>
      </DescriptionWrap>
      <DescriptionWrap>
        <Text fontSize="16px" fontWeight={400}>
          You have used Deal Pilot to complete{' '}
          <Text fontSize="16px" fontWeight={700}>
            {currentDDRRecordCount} of your {maxDeals} deal trial limit.{' '}
          </Text>
          Contact us
        </Text>
      </DescriptionWrap>
      <DealsRemainingWrap>
        <DealsRemaining>{maxDeals - currentDDRRecordCount}</DealsRemaining>
        <Text fontSize="16px" fontWeight={700}>
          Deals Remaining
        </Text>
      </DealsRemainingWrap>
    </BannerWrapper>
  );
};

export default TrialLimitBanner;

const BannerWrapper = styled.div`
  background-color: #ef9208;
  padding: 7px 30px;
  display: flex;
  gap: 10px;
  color: #fafafa;
`;

const DescriptionWrap = styled.div`
  display: flex;
  flex-direction: column;
  line-height: 18px;
`;

const Text = styled.span<{ fontWeight: number; fontSize: string }>`
  font-size: ${({ fontSize }) => fontSize};
  font-weight: ${({ fontWeight }) => fontWeight};
`;

const DealsRemainingWrap = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  line-height: 18px;
`;

const DealsRemaining = styled.div`
  padding: 1px 10px;
  font-weight: 700;
  font-size: 13px;
  background-color: #f4f5f6;
  display: inline-block;
  border-radius: 12px;
  color: #2e2e2e;
  margin-right: 6px;
`;
