import { RejectedBannerIcon } from '@/assets/icons/info-icons/rejectedBanner';
import styled from 'styled-components';

interface ErrorBanner {
  errorMessage: string;
  errorDescription: string;
}

export const ErrorBanner = ({
  errorMessage,
  errorDescription
}: ErrorBanner) => {
  return (
    <StatusBannerWrapper>
      <RejectedBannerIcon />
      <SeparateStick />
      <StatusWrapper>
        <Status>{errorMessage}</Status>
        <Description>{errorDescription}</Description>
      </StatusWrapper>
    </StatusBannerWrapper>
  );
};

const StatusBannerWrapper = styled.div`
  background: #d63b4b;
  margin: 0 -24px;
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 10px 40px;
`;

const SeparateStick = styled.div`
  width: 1px;
  height: 46px;
  background-color: #f4f5f6;
`;

const StatusWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Status = styled.span`
  color: #f4f5f6;
  font-weight: 700;
  font-size: 16px;
`;

const Description = styled.span`
  color: #f4f5f6;
  font-weight: 400;
  font-size: 13px;
`;
