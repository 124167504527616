import FormattingTooltip from '@/components/basicComponents/tooltip/dollarsTooltip';
import guid from '@/utils/guid';
import styled from 'styled-components';
import { CommitmentForecastValueDto } from '../../../types';

interface SubTotalRowProps {
  tableData: CommitmentForecastValueDto[];
  columnWidth: number;
}

interface CommitmentSummary {
  [year: number]: number;
}

const SubTotalRow = ({ tableData, columnWidth }: SubTotalRowProps) => {
  const dateCommitmentSummary: CommitmentSummary = {};

  const addItemValue = (date: string, allocation: number) => {
    const year = new Date(date).getFullYear();

    if (!dateCommitmentSummary[year]) {
      dateCommitmentSummary[year] = 0;
    }
    dateCommitmentSummary[year] += allocation;
  };

  tableData.forEach((commitment) => {
    commitment.data.forEach((item) => {
      addItemValue(item.date, item.value);
    });
  });
  return (
    <SubTotalRowWrapper>
      <SubTotalItem width={15}>Subtotal</SubTotalItem>
      {Object.values(dateCommitmentSummary).map((value) => (
        <SubTotalItem key={guid()} width={columnWidth}>
          <FormattingTooltip zIndex={1000}>{value}</FormattingTooltip>
        </SubTotalItem>
      ))}
    </SubTotalRowWrapper>
  );
};

const SubTotalRowWrapper = styled.div`
  border-radius: 4px;
  background: ${({ theme }) => theme.layer[2]};
  padding: 10px 24px;
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;
  color: ${({ theme }) => theme.font.weak};
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
  width: 100%;
`;

const SubTotalItem = styled.div<{ width: number }>`
  display: flex;
  justify-content: flex-start;
  width: ${({ width }) => `${width}%`};
`;

export default SubTotalRow;
