import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';

import { ArrowDown } from '@/assets/icons/info-icons/arrowDown';
import { ArrowUp } from '@/assets/static/icons/todo-page/arrow-up';
import { NewIcon } from '@/assets/static/icons/todo-page/new';
import { OlderIcon } from '@/assets/static/icons/todo-page/older';
import { statusNextStepRelations } from '@/components/client-details/constants';
import { finalCloseColorOptions, finalCloseImageOptions } from '@/components/dashboard-page/funds-closing-soon/constants';
import { useResponsive } from '@/hooks/use-responsive';
import guid from '@/utils/guid';
import { AssignedToMe, AssignedToMeItem, OwnedByMeItem, OwnedByMeNotAssignedToMe } from '../SubItem';
import { Container } from '../styles';
import { getDateTypeDescription, updateListOfClose } from './utils';

interface Details {
  assignedToMe: AssignedToMe[];
  ownedByMeNotAssignedToMe: OwnedByMeNotAssignedToMe[];
}

interface Summary {
  assignedToMeTaskCount: number;
  newTaskCount: number;
  oldTaskCount: number;
  ownedByMeNotAssignedToMeTaskCount: number;
}

interface Investment {
  finalClose: string;
  id: string;
  name: string;
  summary: Summary;
  nextClose: string;
}

export interface ToDoList {
  investment: Investment;
  details: Details;
}

interface ListProps {
  data: ToDoList;
}

const ToDoList = ({ data }: ListProps) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const [searchParams, setSearchParams] = useSearchParams();

  const openedSection = searchParams.get('openedSection') || '';
  const position = searchParams.get('position') || 0;

  const { isMobile, isTablet, isPortrait, isDesktop, isLargeDesktop } = useResponsive();
  const [tabs, seTab] = useState('');
  const [investment, setInvestment] = useState({
    id: null,
    name: null
  });
  const newData = useMemo(() => updateListOfClose(data), [data]);

  useEffect(() => {
    if (openedSection) {
      const { investment } = newData.find((item: any) => item.investment.id === openedSection);
      setInvestment({
        id: investment.id,
        name: investment.name
      });
    }
  }, []);

  useEffect(() => {
    if (data && position) {
      makeScroll(position);
    }
  }, [data]);

  const getAllocatorTabValue = (tab: string) => {
    switch (tab) {
      case 'Investment review':
        return 'investmentReview';
      case 'Advisor review':
        return 'advisorReview';
      case 'Compliance review':
        return 'compliance';
      default:
        return 'pending';
    }
  };

  const onRedirect = (status: string, tab: string) => {
    const allocationPage = ['Advisor review', 'Investment review', 'Compliance review'];
    saveScrollPostion();

    if (allocationPage.includes(tab)) {
      return navigate(`/allocations/allocator/${investment.id}?currentTab=${getAllocatorTabValue(tab)}`);
    } else {
      const nextTaskParams = encodeURIComponent(JSON.stringify([statusNextStepRelations[tab as keyof typeof statusNextStepRelations]]));
      const investmentsParams = encodeURIComponent(JSON.stringify([investment.name]));

      return navigate(`/recommendations?primaryFilter=${status}&nextTask=${nextTaskParams}&investments=${investmentsParams}`);
    }
  };

  const checkIsOpened = (value: string) => {
    setSearchParams(
      (prev) => {
        if (openedSection === value) {
          prev.delete('openedSection');
          return prev;
        }

        prev.set('openedSection', value);
        return prev;
      },
      { replace: true }
    );
  };

  const saveScrollPostion = () => {
    setSearchParams(
      (prev) => {
        prev.set('position', window.scrollY.toString());
        return prev;
      },
      { replace: true }
    );
  };

  const makeScroll = (position: string | number) => {
    if (!position) return;
    setTimeout(() => window.scrollTo({ top: Number(position), behavior: 'smooth' }), 0);
    setSearchParams(
      (prev) => {
        prev.delete('position');
        return prev;
      },
      { replace: true }
    );
  };

  return (
    <Container
      style={{
        paddingRight: isMobile ? 25 : 50,
        paddingLeft: isMobile ? 25 : 50
      }}
    >
      {newData.map((info: any) => (
        <Wrapper key={guid()}>
          {openedSection === info.investment.id ? (
            <OpenedWrapper isMobile={isMobile} key={guid()}>
              <CardHeader
                onClick={() => {
                  checkIsOpened(info.investment.id);
                  setInvestment({
                    id: info.investment.id,
                    name: info.investment.name
                  });
                }}
              >
                <CardText>
                  <Title isMobile={isMobile}>{info.investment.name}</Title>
                  {!info.shouldHideDateType && (
                    <SubTitle color={finalCloseColorOptions[info.type as keyof typeof finalCloseColorOptions]}>
                      {<img src={finalCloseImageOptions[info.type as keyof typeof finalCloseColorOptions]} alt={'image'} />}
                      {getDateTypeDescription(info)}
                    </SubTitle>
                  )}
                </CardText>
                {isDesktop || isLargeDesktop || isTablet ? (
                  <CardSwitcher
                    isMobile={isMobile}
                    onClick={() => {
                      checkIsOpened(info.investment.id);
                      setInvestment({
                        id: info.investment.id,
                        name: info.investment.name
                      });
                    }}
                  >
                    <Switcher>
                      <ArrowUp fill={theme.font.action} width={24} height={24} />
                    </Switcher>
                  </CardSwitcher>
                ) : null}
              </CardHeader>
              <ListWrapper>
                <FirstListWrapper>
                  <ListTitle>
                    <Numbers isActive={true}>{info.investment.summary.assignedToMeTaskCount}</Numbers>
                    <Text isActive={true}>Assigned To Me</Text>
                  </ListTitle>
                  <FirstSubListWrap isMobile={isMobile}>
                    <AssignedToMeItem
                      data={info.details}
                      isMobile={isMobile}
                      seTab={seTab}
                      onRedirect={onRedirect}
                      key={guid()}
                      isTablet={isTablet}
                      isPortrait={isPortrait}
                    />
                  </FirstSubListWrap>
                </FirstListWrapper>
                <SecondListWrapper>
                  <OpenedListTitle>
                    <Numbers isActive={false}>{info.investment.summary.ownedByMeNotAssignedToMeTaskCount}</Numbers>
                    <Text isActive={false}>Owned By Me/Assigned To Someone Else</Text>
                  </OpenedListTitle>
                  <SubListWrap isMobile={isMobile}>
                    <OwnedByMeItem
                      data={info.details}
                      isMobile={isMobile}
                      seTab={seTab}
                      onRedirect={onRedirect}
                      key={guid()}
                      isTablet={isTablet}
                      isPortrait={isPortrait}
                    />
                  </SubListWrap>
                </SecondListWrapper>
              </ListWrapper>
              {isMobile ? (
                <CardSwitcher
                  isMobile={isMobile}
                  onClick={() => {
                    checkIsOpened(info.investment.id);
                    setInvestment({
                      id: info.investment.id,
                      name: info.investment.name
                    });
                  }}
                >
                  <Switcher>
                    <ArrowUp fill={theme.font.action} width={24} height={24} />
                  </Switcher>
                </CardSwitcher>
              ) : null}
            </OpenedWrapper>
          ) : (
            <ClosedWrapper isMobile={isMobile} key={guid()}>
              <CardHeader
                isMobile={isMobile}
                onClick={() => {
                  checkIsOpened(info.investment.id);
                  setInvestment({
                    id: info.investment.id,
                    name: info.investment.name
                  });
                }}
              >
                <CardHeaderChild isMobile={isMobile}>
                  <CardText>
                    <Title isMobile={isMobile}>{info.investment.name}</Title>
                    {!info.shouldHideDateType && (
                      <SubTitle color={finalCloseColorOptions[info.type as keyof typeof finalCloseColorOptions]}>
                        {<img src={finalCloseImageOptions[info.type as keyof typeof finalCloseColorOptions]} alt={'image'} />}
                        {getDateTypeDescription(info)}
                      </SubTitle>
                    )}
                  </CardText>
                </CardHeaderChild>
                <ListInfo isMobile={isMobile}>
                  <Assigned isMobile={isMobile}>
                    <Numbers isActive={true}>{info.investment.summary.assignedToMeTaskCount}</Numbers>
                    <Text isActive={true}>Assigned To Me</Text>
                  </Assigned>
                  <Owned isMobile={isMobile}>
                    <Numbers isActive={false}>{info.investment.summary.ownedByMeNotAssignedToMeTaskCount}</Numbers>
                    <Text isActive={false}>
                      Owned By Me/ <br />
                      Assigned To Someone Else
                    </Text>
                  </Owned>
                  <OpenedIcons isMobile={isMobile}>
                    <NewIcons>
                      <NewIcon />
                      <IconText color={theme.context.success}>{info.investment.summary.newTaskCount}</IconText>
                    </NewIcons>
                    <OldIcons>
                      <OlderIcon height={22.5} width={22.5} />
                      <IconText color={theme.context.warning}>{info.investment.summary.oldTaskCount}</IconText>
                    </OldIcons>
                  </OpenedIcons>
                </ListInfo>
                <CardSwitcher
                  isMobile={isMobile}
                  onClick={() => {
                    checkIsOpened(info.investment.id);
                    setInvestment({
                      id: info.investment.id,
                      name: info.investment.name
                    });
                  }}
                >
                  <ArrowDown fill={theme.font.action} width={12} height={12} />
                </CardSwitcher>
              </CardHeader>
            </ClosedWrapper>
          )}
        </Wrapper>
      ))}
    </Container>
  );
};

export default ToDoList;

const SubListWrap = styled.div<{ isMobile?: boolean }>`
  display: flex;
  flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};
  align-items: center;
  gap: 20px;
  margin-left: 10px;
  margin-top: 4px;
`;
const FirstSubListWrap = styled.div<{ isMobile?: boolean }>`
  display: flex;
  flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};
  align-items: center;
  gap: 20px;
  margin-left: 10px;
  margin-top: 20px;
`;

export const Line = styled.div<{ isMobile?: boolean }>`
  display: flex;
  width: 20px;
  height: 1px;
  background: ${({ theme }) => theme.border.base};
  transform: ${({ isMobile }) => (isMobile ? 'rotate(270deg)' : 'rotate(-90deg)')};
  &:last-child {
    display: none;
  }
`;
const ListInfo = styled.div<{ isMobile?: boolean }>`
  display: flex;
  margin: ${({ isMobile }) => (isMobile ? '0 auto' : '0')};
  flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};
  width: ${({ isMobile }) => (isMobile ? 'auto' : '45%')};
  gap: ${({ isMobile }) => (isMobile ? '20px' : '0')};
  justify-content: space-between;
`;
const NewIcons = styled.div`
  display: flex;
  gap: 8px;
`;
const Wrapper = styled.div``;
const OldIcons = styled.div`
  display: flex;
  gap: 8px;
`;
const OpenedIcons = styled.div<{ isMobile: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 25px;
  width: ${({ isMobile }) => (isMobile ? 'auto' : '20%')};
`;
const Owned = styled.div<{ isMobile: boolean }>`
  display: flex;
  align-items: center;
  gap: 10px;
  width: ${({ isMobile }) => (isMobile ? 'auto' : '30%')};
`;
const Assigned = styled.div<{ isMobile: boolean }>`
  display: flex;
  align-items: center;
  gap: 10px;
  width: ${({ isMobile }) => (isMobile ? 'auto' : '30%')};
`;
const CardHeaderChild = styled.div<{ isMobile: boolean }>`
  display: flex;
  justify-content: space-between;
  width: ${({ isMobile }) => (isMobile ? 'auto' : '50%')};
`;
const IconText = styled.div<{ color?: string }>`
  font-family: 'Blinker', serif;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 22px;
  text-align: center;
  color: ${({ color }) => color};
`;
const CardSwitcher = styled.div<{ isMobile?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: ${({ isMobile }) => (isMobile ? '100%' : '50px')};
  height: ${({ isMobile }) => (isMobile ? '0' : '50px')};
  min-width: 50px;
  border-radius: 42px;
  margin: ${({ isMobile }) => (isMobile ? '10px 0' : '0 26px 0 0')};
  margin-left: auto;
  /* @media (min-width: 600px) {
    margin: 0 26px 0 10px;
  } */
`;
const ClosedWrapper = styled.div<{ isMobile?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: ${({ isMobile }) => (isMobile ? '10px 0' : '30px 40px')};
  gap: 10px;
  background: ${({ theme }) => theme.layer.base};
  border: 1px solid ${({ theme }) => theme.border.base};
  border-radius: 10px;
  margin-top: 10px;

  &:hover {
    background: ${({ theme }) => theme.layer[2]};

    ${CardSwitcher} {
      svg {
        path {
          fill: ${({ theme }) => theme.context.light};
        }
      }
    }

    &:hover ${CardSwitcher} {
      background: ${({ theme }) => theme.action.hover};
    }
  }
`;
const CardHeader = styled.div<{ isMobile?: boolean }>`
  width: 100%;
  display: flex;
  align-items: ${({ isMobile }) => (isMobile ? 'flex-start' : 'center')};
  padding: ${({ isMobile }) => (isMobile ? '0px 15px' : '0')};
  gap: ${({ isMobile }) => (isMobile ? '35px' : '20px')};
  flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};
  cursor: pointer;
`;
const CardText = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
`;
const Switcher = styled.div`
  display: flex;
  align-items: center;
`;
const Text = styled.div<{ isActive?: boolean }>`
  font-family: 'Blinker', serif;
  font-style: normal;
  font-weight: ${({ isActive }) => (isActive ? 700 : 400)};
  font-size: ${({ isActive }) => (isActive ? '19px' : '16px')};
  line-height: ${({ isActive }) => (isActive ? '20px' : '16px')};
  color: ${({ isActive, theme }) => (isActive ? theme.font.base : theme.font.weak)};
`;

const Numbers = styled.div<{ isActive?: boolean }>`
  font-family: 'Blinker', serif;
  font-style: normal;
  font-weight: ${({ isActive }) => (isActive ? 700 : 600)};
  font-size: ${({ isActive }) => (isActive ? '50px' : '33px')};
  line-height: ${({ isActive }) => (isActive ? '50px' : '30px')};
  color: ${({ isActive, theme }) => (isActive ? theme.font.base : theme.font.weak)};
`;
const SecondListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  background: ${({ theme }) => theme.layer.base};
  padding: 35px 30px;
  border: 1px solid ${({ theme }) => theme.border.base};
  border-radius: 0 0 10px 10px;
`;
const FirstListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background: ${({ theme }) => theme.layer[1]};
  padding: 35px 30px;
  border: 1px solid ${({ theme }) => theme.border.base};
  border-bottom: none;
  border-radius: 10px 10px 0px 0px;
`;
const ListTitle = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`;
const OpenedListTitle = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`;
const ListWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-top: 20px;
`;
const OpenedWrapper = styled.div<{ isMobile?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: ${({ isMobile }) => (isMobile ? '10px 15px' : '30px 40px')};
  gap: 10px;
  background: ${({ theme }) => theme.layer.base};
  border: 1px solid ${({ theme }) => theme.border.base};
  border-radius: 10px;
  margin-top: 10px;
`;
const Title = styled.div<{ isMobile?: boolean }>`
  display: flex;
  align-items: center;
  font-family: 'Blinker', serif;
  font-style: normal;
  font-weight: 600;
  font-size: 23px;
  line-height: ${({ isMobile }) => (isMobile ? '28px' : '42px')};
  color: ${({ theme }) => theme.font.base};
  margin-bottom: ${({ isMobile }) => (isMobile ? '10px' : '0')};
`;
const SubTitle = styled.div<{ color?: string }>`
  display: flex;
  align-items: center;
  font-family: 'Blinker', serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.7px;
  color: ${({ color }) => color};
  padding-left: 5px;
  gap: 5px;
`;
