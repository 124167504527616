import { ExportDataIcon } from '@/assets/icons/exportDataIcon';
import { InfoIcon } from '@/assets/icons/info-icons/info';
import { RedStarIcon } from '@/assets/icons/info-icons/redStar';
import styled, { useTheme } from 'styled-components';
import { IColumnsSection } from './types';

interface ColumnsSectionProps {
  columnsSection: IColumnsSection;
  downloadExistingData?: () => void;
}

export const ColumnsSection = ({ columnsSection, downloadExistingData }: ColumnsSectionProps) => {
  const theme = useTheme();

  return (
    <Section>
      <InfoIcon width={29} height={30} fill={theme.font.weak} />
      <div style={{ width: '100%' }}>
        <SectionBody>
          <SectionTitle>{columnsSection.title}</SectionTitle>
          {downloadExistingData && (
            <ExportDataWrap onClick={downloadExistingData}>
              <ExportDataIcon fill={theme.font.action} />
              <ExportDataTitle>Download Existing Data</ExportDataTitle>
            </ExportDataWrap>
          )}
        </SectionBody>
        <Columns>
          {columnsSection.columns.map((column) => (
            <ColumnWrap key={column.name}>
              {column.isRequired && <RedStarIcon width={7} height={8} fill={theme.context.error} />}
              <Column isRequired={column.isRequired}>{column.name}</Column>
            </ColumnWrap>
          ))}
        </Columns>
      </div>
    </Section>
  );
};

const Section = styled.div`
  width: 100%;
  display: flex;
  gap: 15px;
`;

const SectionTitle = styled.div`
  color: ${({ theme }) => theme.font.base};
  font-family: Blinker;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 22.4px;
  padding: 3px 0;
  margin-bottom: 15px;
`;

const SectionBody = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 10px;
`;

const Columns = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: auto auto auto;
  gap: 10px;
`;

const ColumnWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

const Column = styled.div<{ isRequired: boolean }>`
  color: ${({ theme }) => theme.font.base};
  font-family: Blinker;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22.4px;
  padding-left: ${({ isRequired }) => (isRequired ? '0' : '12px')};
`;

const ExportDataWrap = styled.div`
  display: flex;
  cursor: pointer;
  gap: 6px;
  justify-content: center;
  align-items: center;
  padding: 5px 20px;
`;

const ExportDataTitle = styled.div`
  font-family: Blinker, serif;
  font-size: 13px;
  font-weight: 600;
  line-height: 18px;
  text-align: left;
  color: ${({ theme }) => theme.font.action};
`;
