import client from '@/apollo-client';
import { templateStyles } from '@/components/dueDiligence-page/DDR-PDF/PDFTemplate';
import { getFontByKey, getValueByKey } from '@/components/dueDiligence-page/DDR-PDF/utils';
import { getAssetSignedUrl } from '@/components/settings-page/queries';
import { ISetting } from '@/components/settings-page/settings-pages/firmSettings/types';
import { isURL } from '@/components/settings-page/settings-pages/firmSettings/utils';
import { Document, Page, Image as PdfImage, StyleSheet, Text, View } from '@react-pdf/renderer';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { IGetDocumentRecordQuery } from '../../types';
import { OneColumnSection } from './oneColumnSection';

interface PDFTemplateProps {
  documentRecord: IGetDocumentRecordQuery;
  firmSettings: ISetting[];
  templateStylingData: ISetting[];
  includeAiSuggested: boolean;
  investmentName: string;
  investmentDescription: string;
  generatingPdf: (isPdfReady: boolean) => void;
}

export const PDFTemplate = ({
  documentRecord,
  firmSettings,
  templateStylingData,
  includeAiSuggested,
  investmentName,
  investmentDescription,
  generatingPdf
}: PDFTemplateProps) => {
  if (!documentRecord) return <></>;

  const [imageSizes, setImageSizes] = useState<{ [url: string]: { width: number; height: number } }>({});
  const [logoImg, setLogoImg] = useState('');
  const [watermark, setWatermark] = useState('');

  useEffect(() => {
    try {
      const parsedValue = getValueByKey(templateStylingData, 'watermark') ? JSON.parse(getValueByKey(templateStylingData, 'watermark')) : '';
      if (isURL(parsedValue.value)) {
        updateImageUrl(parsedValue.assetKey, setWatermark);
        return;
      }
      if (parsedValue.value) {
        setWatermark(parsedValue.value);
      }
    } catch (error) {
      console.error(error);
    }
  }, [templateStylingData]);

  useEffect(() => {
    try {
      const parsedValue = getValueByKey(firmSettings, 'logo') ? JSON.parse(getValueByKey(firmSettings, 'logo')) : '';
      if (parsedValue.value) {
        updateImageUrl(parsedValue.assetKey, setLogoImg);
      }
    } catch (error) {
      console.error(error);
    }
  }, [firmSettings]);

  useEffect(() => {
    const getImageSizeFromUrl = (url: string) => {
      const img = new Image();

      img.onload = () => {
        const imageSize = {
          width: img.naturalWidth,
          height: img.naturalHeight
        };
        setImageSizes((prevSizes) => ({ ...prevSizes, [url]: imageSize }));
      };
      img.src = url;
    };

    documentRecord.fields.forEach((field) => {
      if (field.attachments && field.attachments.length > 0) {
        field.attachments.forEach((attachment) => {
          if (attachment.includeInAutomatedContent) {
            getImageSizeFromUrl(attachment.secure_blob_storage_asset.url);
          }
        });
      }
    });
  }, [documentRecord]);

  const updateImageUrl = async (assetKey: string, setImage: Dispatch<SetStateAction<string>>) => {
    const { data } = await client.query({
      query: getAssetSignedUrl,
      fetchPolicy: 'network-only',
      variables: {
        assetKey: assetKey
      }
    });
    setImage(data.getAssetSignedUrl);
  };

  const styles = StyleSheet.create({
    investmentName: {
      fontFamily: getFontByKey(templateStylingData, 'headerFontStyle').fontFamily
        ? getFontByKey(templateStylingData, 'headerFontStyle').fontFamily
        : 'Roboto Slab',
      fontWeight: 700,
      fontSize: '22px',
      color: Boolean(getFontByKey(templateStylingData, 'headerFontStyle').fontColor)
        ? `#${getFontByKey(templateStylingData, 'headerFontStyle').fontColor}`
        : '#757575'
    },
    fieldValue: {
      fontSize: 9,
      fontFamily: getFontByKey(templateStylingData, 'bodyFontStyle').fontFamily
        ? getFontByKey(templateStylingData, 'bodyFontStyle').fontFamily
        : 'Open Sans',
      color: Boolean(getFontByKey(templateStylingData, 'bodyFontStyle').fontColor)
        ? `#${getFontByKey(templateStylingData, 'bodyFontStyle').fontColor}`
        : '#757575',
      fontWeight: getFontByKey(templateStylingData, 'bodyFontStyle').fontWeight ? getFontByKey(templateStylingData, 'bodyFontStyle').fontWeight : 600,
      paddingTop: '2px',
      lineHeight: '1.3px'
    }
  });

  return (
    <Document onRender={() => generatingPdf(true)}>
      <Page size="A4" style={templateStyles.page}>
        <View style={templateStyles.headerWrap} fixed>
          <Text style={templateStyles.firmName}>{getValueByKey(firmSettings, 'firmName')}</Text>
          <Text style={templateStyles.tierName}>{documentRecord?.name ?? ''}</Text>
        </View>
        {watermark !== 'None' && isURL(watermark) ? (
          <View style={templateStyles.watermarkWrap} fixed>
            <PdfImage style={templateStyles.watermark} src={watermark} />
          </View>
        ) : null}
        {watermark !== 'None' && !isURL(watermark) ? (
          <View
            style={{
              position: 'absolute',
              bottom: watermark.length > 15 ? '250px' : '400px',
              left: '50px',
              opacity: 0.1
            }}
            fixed
          >
            <Text style={templateStyles.watermarkText}>{watermark}</Text>
          </View>
        ) : null}
        <View style={templateStyles.contentWrap}>
          <View style={templateStyles.header}>
            <View style={templateStyles.titleWrap}>
              <View
                style={{
                  ...styles.investmentName,
                  width: '100%'
                }}
              >
                <Text>{investmentName}</Text>
              </View>
            </View>
            <Text
              style={{
                ...styles.fieldValue,
                fontSize: '10px',
                color: '#5A5A5A',
                paddingTop: '15px'
              }}
            >
              {investmentDescription}
            </Text>
          </View>
          <OneColumnSection
            fields={documentRecord?.fields ?? []}
            imageSizes={imageSizes}
            templateStylingData={templateStylingData}
            includeAiSuggested={includeAiSuggested}
          />
        </View>
        <View style={templateStyles.footerWrap} fixed>
          <View style={templateStyles.footer}>
            <View style={templateStyles.logoWrap}>{logoImg.length ? <PdfImage style={templateStyles.logoImg} src={logoImg} /> : null}</View>
            <View style={templateStyles.footerSectionWrap}>
              <Text style={templateStyles.firmName}>{getValueByKey(firmSettings, 'firmName')}</Text>
              <Text style={templateStyles.firmAddress}>{getValueByKey(firmSettings, 'address')}</Text>
            </View>
            <View style={templateStyles.footerSectionWrap}>
              <Text>{getValueByKey(firmSettings, 'website')}</Text>
              <Text>{getValueByKey(firmSettings, 'email')}</Text>
              {getValueByKey(firmSettings, 'phone').length ? <Text>Office: {getValueByKey(firmSettings, 'phone')}</Text> : null}
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};
