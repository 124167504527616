import { subject } from '@casl/ability';
import { useEffect, useRef, useState } from 'react';
import { User } from 'src/types/user';

import { TodoCalendar } from '@/assets/icons/todo-calendar';
import { JfgLogoOnly } from '@/assets/static/logo/JFGLogoOnly';
import { JFGSimpleLogo } from '@/assets/static/logo/JFGSimpleLogo';
import { ACTIONS } from '@/components/recommendations-page/types';
import jfgTabs from '@/constants/tabs/jfgTabs';
import { useLocation, useNavigate } from 'react-router-dom';
import styled, { css, keyframes } from 'styled-components';
import { buildAbilityFor } from '../../config/ability';
import { useAuth } from '../../hooks/use-auth';
import ControlButton from './ControlButton';

interface DashboardNavbarProps {
  refetch: any;
  moduleConfig: any;
  showComplianceTab: boolean;
}

export const DashboardNavbar = ({ refetch, moduleConfig, showComplianceTab }: DashboardNavbarProps) => {
  const { userTasks } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const [active, setActive] = useState(false);
  const [isNavOpen, setIsNavOpen] = useState(false);
  const [isMouseInside, setIsMouseInside] = useState(false);
  const [isNavFixed, setIsNavFixed] = useState(false);

  const mouseRef = useRef(isMouseInside);
  mouseRef.current = isMouseInside;

  useEffect(() => {
    if (mouseRef.current) {
      setTimeout(() => {
        if (mouseRef.current) {
          setIsNavOpen(true);
        }
      }, 1200);
    }
  }, [isMouseInside]);

  const onMouseLeave = () => {
    setIsMouseInside(false);
    setIsNavOpen(false);
  };

  const onLogoClick = () => {
    setIsNavFixed(!isNavFixed);
    setIsNavOpen(!isNavOpen);
  };

  const ability = buildAbilityFor(useAuth().user as User);
  const filteredTabsForPermission = jfgTabs.filter((el) => {
    if (!ability?.can(ACTIONS.SHOW_SETTING, subject('Settings', {}))) {
      return el.label !== 'Settings';
    }
    return el;
  });

  const filteredTabs = filteredTabsForPermission.filter((el) => {
    if (showComplianceTab) {
      return el.label !== 'Manager Updates';
    }
    return el.label !== 'Manager Updates' && el.label !== 'Compliance';
  });

  const moduleConfigFilteredTabs = !!moduleConfig
    ? filteredTabs.filter((tab) => {
        if (moduleConfig.DDR && ['dealRegistry', 'dueDiligence', 'settings'].includes(tab.path)) {
          return tab;
        }

        if (
          moduleConfig.Allocator &&
          [
            'todo',
            'dashboard',
            'clients',
            'allocations',
            'recommendations',
            'families/advisor/?advisor=all',
            'investments',
            'compliance',
            'settings'
          ].includes(tab.path)
        ) {
          return tab;
        }
        return undefined;
      })
    : filteredTabs;

  const selectedTabIndex = moduleConfigFilteredTabs.findIndex((tab) => location.pathname.includes(tab.path.split('/')[0])) ?? 0;

  useEffect(() => {
    if (selectedTabIndex === 0) {
      setActive(true);
    } else {
      setActive(false);
    }
  }, [selectedTabIndex]);

  return (
    <NavBarWrap
      onMouseEnter={() => {
        setIsMouseInside(true);
      }}
      onMouseLeave={onMouseLeave}
    >
      <DashboardNavbarWrap isNavOpen={isNavOpen}>
        <LogoWrap>
          <ControlButton icon={isNavOpen} visible={false} onClick={() => setIsNavFixed(!isNavFixed)} />
          {isNavOpen ? <JfgLogoOnly onClick={onLogoClick} /> : <JFGSimpleLogo onClick={onLogoClick} />}
          <ControlButton icon={isNavOpen} visible={true} onClick={() => setIsNavFixed(!isNavFixed)} />
        </LogoWrap>
        {moduleConfig.Allocator && (
          <ToDoWrap
            active={active}
            isNavOpen={isNavOpen}
            onClick={() => {
              navigate('todo');
              refetch();
            }}
          >
            <NumberWrap isNavOpen={isNavOpen}>
              <TodoCalendar />
              <ToDoNumber>{userTasks}</ToDoNumber>
            </NumberWrap>
            <ToDoTitle isNavOpen={isNavOpen}>TO DO</ToDoTitle>
          </ToDoWrap>
        )}
        <Divider />
        <MenuWrap>
          {moduleConfigFilteredTabs?.map((el, index) => {
            return (
              !el?.disabled && (
                <MenuItemWrap
                  active={index === selectedTabIndex}
                  onClick={(event) => {
                    event.preventDefault();
                    const returnUrl = (location.state?.returnUrl as string | undefined) || `/${el.path}`;
                    navigate(returnUrl, { replace: true });
                    setIsNavOpen(false);
                    setIsNavFixed(false);
                    refetch();
                  }}
                  key={el.path}
                >
                  {el.icon}
                  <MenuItemTitle>{el.label}</MenuItemTitle>
                </MenuItemWrap>
              )
            );
          })}
        </MenuWrap>
      </DashboardNavbarWrap>
    </NavBarWrap>
  );
};

const displayToDoTitle = keyframes`
  to {
    visibility: visible;
  }
`;

const NavBarWrap = styled.div`
  display: flex;
  position: fixed;
  transition: 0.3s;
  z-index: 1000;
`;

const MenuItemTitle = styled.div`
  font-family: Lato;
  color: #fff;
  font-weight: 700;
  visibility: hidden;
  white-space: nowrap;
  animation: 0s linear 0.1s forwards ${displayToDoTitle};
`;

const MenuItemWrap = styled.div<{ active: boolean }>`
  cursor: pointer;
  background-color: ${({ active }) => (active ? '#3AB070' : 'transparent')};
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 30px;
  padding: 20px 25px;
`;

const MenuWrap = styled.div`
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  margin: 0 -10px;
  &::-webkit-scrollbar {
    display: none;
    background-color: transparent;
    width: 6px;
  }
  &::-webkit-scrollbar-track {
    display: none;
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #babac0;
    border-radius: 6px;
    border: 2px solid transparent;
  }
  &::-webkit-scrollbar-button {
    display: none;
  }
`;

const Divider = styled.div`
  background-color: #202e35;
  height: 1px;
  width: 100%;
  margin: 10px 0;
  padding: 0 10px;
`;

const todoTitleFontSize = keyframes`
  to {
    font-size: 16px;
  }
`;

const ToDoTitle = styled.div<{ isNavOpen: boolean }>`
  font-family: Lato;
  font-weight: 700;
  font-size: 0;
  color: #fff;
  animation: ${({ isNavOpen }) => (isNavOpen ? css`0s linear .1s forwards ${todoTitleFontSize}` : 'none')};
`;

const flexDirectionRow = keyframes`
  to {
    flex-direction: row;
  }
`;

const NumberWrap = styled.div<{ isNavOpen: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
  animation: ${({ isNavOpen }) => (isNavOpen ? css`0s linear .1s forwards ${flexDirectionRow}` : 'none')};
  transition: 0.3s;
`;

const ToDoNumber = styled.div`
  font-family: Lato;
  font-size: 23px;
  color: #fff;
  font-weight: 700;
`;

const ToDoWrap = styled.div<{ isNavOpen: boolean; active?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${({ active }) => (active ? '#3AB070' : '#465D67')};
  border-radius: 10px;
  padding: ${({ isNavOpen }) => (isNavOpen ? '20px' : '18px 16px')};
  transition: 0.3s;
  max-height: 100px;
  height: 100%;
  cursor: pointer;
`;

const LogoWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 28px;
  min-height: 64px;
`;

const DashboardNavbarWrap = styled.div<{ isNavOpen: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100vh;
  position: ${({ isNavOpen }) => (isNavOpen ? 'absolute' : 'fixed')};
  width: ${({ isNavOpen }) => (isNavOpen ? '250px' : '80px')};
  background-color: #36474f;
  transition: 0.3s;
  padding: 20px 10px;
  padding-bottom: 0;
  z-index: 400;
  top: 0;
  bottom: 0;
  left: 0;
  box-shadow: 4px 0px 8px 0px rgba(0, 0, 0, 0.25);
`;
