import * as React from 'react';

export const PaginationBtnIcon = ({ ...props }: React.SVGProps<SVGSVGElement>) => (
  <svg
    onClick={props.onClick}
    style={{ cursor: (props.cursor as string) || 'default', transform: (props.rotate as string) || 'rotate(0)' }}
    width="7"
    height="10"
    viewBox="0 0 7 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M6.88861 8.825L3.07361 5L6.88861 1.175L5.71361 -5.13608e-08L0.713611 5L5.71361 10L6.88861 8.825Z" fill={props.fill || '#ABABAB'} />
  </svg>
);
