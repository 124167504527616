import FormattingTooltip from '@/components/basicComponents/tooltip/dollarsTooltip';
import { TableColumns } from '@/components/table/types';
import styled from 'styled-components';
import { IInvestorTotalRow } from '../types';

interface TableSumFooterProps {
  totalRowData: IInvestorTotalRow | null;
  showSpecificColumn: boolean;
  tableColumns: TableColumns[];
}

const TableSumFooter = ({ totalRowData, showSpecificColumn, tableColumns }: TableSumFooterProps) => {
  if (!totalRowData) return <></>;

  const WIDTH = 100 / tableColumns.length;

  return (
    <TableSumWrap>
      <TableSumItem width={WIDTH}>Total</TableSumItem>
      {showSpecificColumn && (
        <TableSumItem width={WIDTH}>
          {totalRowData.ignoreAllocationTargets ? <>--</> : <FormattingTooltip>{totalRowData.totalValue}</FormattingTooltip>}
        </TableSumItem>
      )}
      {showSpecificColumn && <TableSumItem width={WIDTH}></TableSumItem>}
      {showSpecificColumn && <TableSumItem width={WIDTH}></TableSumItem>}
      <TableSumItem width={WIDTH}>
        <FormattingTooltip>{totalRowData.economicExposure}</FormattingTooltip>
      </TableSumItem>
      {showSpecificColumn && (
        <TableSumItem width={WIDTH}>
          {totalRowData.ignoreAllocationTargets ? <>--</> : <FormattingTooltip>{totalRowData.totalCapacity}</FormattingTooltip>}
        </TableSumItem>
      )}
    </TableSumWrap>
  );
};

const TableSumWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
  width: 100%;
  min-height: 60px;
  background-color: ${({ theme }) => theme.layer[2]};
  border-radius: 8px;
  padding: 0 24px;
`;

const TableSumItem = styled.div<{ width: number; cursor?: string }>`
  display: flex;
  justify-content: flex-start;
  width: ${({ width }) => `${width}%`};
  color: ${({ theme }) => theme.font.base};
  font-family: Blinker, serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0;
  cursor: ${({ cursor }) => cursor};
`;

export default TableSumFooter;
