import client from '@/apollo-client';
import { RejectedBannerIcon } from '@/assets/icons/info-icons/rejectedBanner';
import { Banner } from '@/components/basicComponents/banner';
import { FormFooter } from '@/components/fat-basicComponents/formFooter';
import Header from '@/components/fat-header';
import { GoBackButton } from '@/components/fat-header/goBackButton';
import { PageTitle } from '@/components/fat-header/pageTitle';
import { LoaderOnWholeScreen } from '@/components/loaders/loader-on-whole-screen';
import { defaultSelectValue } from '@/components/opportunities-entity/modify/constants';
import { useGoBack } from '@/hooks/useGoBack';
import { useStatusData } from '@/hooks/useStatusData';
import { MainWrap } from '@/styles/common';
import { useMutation, useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { FieldErrors, useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';
import { DrawdownTargetsDto } from '../fat-investorDetails-page/fat-entityDetails-page/fat-pacingTab/types';
import { LIST_INVESTORS_QUERY } from '../queries';
import { IListInvestors, IListInvestorsQuery, ListCallback } from '../types';
import { AssetClassTargetsPage } from './fat-assetClassTargets';
import AddInvestorHousehold from './fat-modals/addInvestorHousehold';
import Loading from './fat-modals/loading';
import {
  createInvestorMutation,
  createLegalEntityMutation,
  DEFAULT_DRAWDOWN_TARGETS,
  GET_ASSET_CLASSES,
  GET_LEGAL_ENTITY,
  updateLegalEntityMutation
} from './queries';
import { DataIntegration } from './sections/dataIntegration';
import { InvestorDetails } from './sections/investorDetails';
import { SetAllocationBiteSize } from './sections/setAllocationBiteSize';
import { SetAllocationRestrictions } from './sections/setAllocationRestrictions';
import { SetAllocationTargets } from './sections/setAllocationTargets';
import { CreateInvestorDto, IAssetClass, ILegalEntity } from './types';
import {
  getAllocationTargetsToUpdate,
  getDefaultDataFields,
  getSumAssetClassPercentages,
  resetAssetClassTargets,
  structurAssetClassesWithPrevValues,
  updateAssetClassDrawdownTargets
} from './utils';

export const clientAdvisorSectionOptions = ['None', 'Assign a client advisor'];
export const houseHoldSectionOptions = ['Create a new Investor Household', 'Assign to existing'];
export const capacityCalculationOptions = ['Pacing Model', 'Over Commitment Ratio'];
const assetClassOptions = ['Use default asset class allocation targets', 'Customize targets for this investor entity'];

const initialSectionsState = {
  allocationTargetsSection: false,
  allocationRestrictionsSection: false,
  defaultBiteSizeSection: false,
  dataIntegrationSection: false
};

const defaultFormValues: CreateInvestorDto = {
  entityName: '',
  familyId: defaultSelectValue,
  ignoreAllocationTargets: true,
  ignoreBiteSize: true,
  sectorsToAvoid: [],
  taxExempt: false,
  onHold: false,
  defaultBiteSize1: '',
  defaultBiteSize2: '',
  overCommitRatio: '',
  privatePercentage: '',
  totalEntityValue: '',
  advisorReportingAccountNumber: '',
  advisorCRMId: '',
  administratorCode: '',
  investorHouseholdOption: houseHoldSectionOptions[0],
  capacityCalculationOption: capacityCalculationOptions[0]
};

interface SubAssetClassWithPrevValues {
  id: string;
  name: string;
  defaultPercentage: string;
  prevPercentage: string;
  percentage: string;
}

export interface AssetClassWithPrevValues {
  id: string;
  name: string;
  defaultPercentage: string;
  prevPercentage: string;
  percentage: string;
  subAssetClasses: SubAssetClassWithPrevValues[];
}

export const CreateInvestorPage = () => {
  const { getValues, setValue, control, reset, handleSubmit } = useForm<CreateInvestorDto>({ defaultValues: defaultFormValues });
  const theme = useTheme();
  const navigate = useNavigate();
  const goBack = useGoBack();
  const { data: statusData } = useStatusData();

  const [searchParams] = useSearchParams();
  const backToTitle = searchParams.get('backToTitle') || '';
  const tenantId = searchParams.get('tenantId');
  const tenantName = searchParams.get('tenantName') || null;
  const entityId = searchParams.get('entityId') || '';
  const openSections = searchParams.get('openSections') || '{}';

  const [isEditMode] = useState<boolean>(!!entityId);
  const [householdList, setHouseholdList] = useState<{ id: string; name: string }[]>([]);
  const [isAssetClassTargetsPageOpen, setIsAssetClassTargetsPageOpen] = useState<boolean>(false);
  const [scrollPosition, setScrollPosition] = useState<number>(0);
  const [updateError, setUpdateError] = useState<string | null>(null);

  const [assetClassTargets, setAssetClassTargets] = useState<AssetClassWithPrevValues[]>([]);
  const [assetClassChartWithTableData, setAssetClassChartWithTableData] = useState<DrawdownTargetsDto | null>(null);
  const [assetClassSelectedOption, setAssetClassSelectedOption] = useState(assetClassOptions[0]);

  const [sectionsState, setSectionsState] = useState<typeof initialSectionsState>(initialSectionsState);
  const [newInvestorHouseholdName, setNewInvestorHouseholdName] = useState<string | null>(null);
  const [modalWindow, setModalWindow] = useState({
    isOpen: false,
    type: ''
  });

  const [createLegalEntity, { loading: createLegalEntityLoading }] = useMutation(createLegalEntityMutation, {
    onError: (error) => {
      window.scrollTo(0, 0);
      setUpdateError(error.message);
    }
  });

  const [updateEntity, { loading: updateEntityLoading }] = useMutation(updateLegalEntityMutation, {
    onError: (error) => {
      window.scrollTo(0, 0);
      setUpdateError(error.message);
    }
  });

  const [createInvestor, { loading: createInvestorLoading }] = useMutation(createInvestorMutation, {
    onError: (error) => {
      window.scrollTo(0, 0);
      setUpdateError(error.message);
    }
  });

  const { loading: legalEntityLoading } = useQuery<{ legalEntity: ILegalEntity }>(GET_LEGAL_ENTITY, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'no-cache',
    skip: !entityId,
    variables: {
      id: entityId
    },
    onCompleted: async (data) => {
      reset((defaultData) => ({
        ...defaultData,
        ...getDefaultDataFields(data.legalEntity)
      }));

      const { data: defaultAssetClasses } = await client.query<{ assetClasses: IAssetClass[] }>({
        query: GET_ASSET_CLASSES,
        variables: {
          tenantId: data.legalEntity.tenant.id
        }
      });

      if (!data.legalEntity.allocationTargets.length) {
        const { data: defaultDrawdownTargetsData } = await client.query<{ defaultDrawdownTargets: DrawdownTargetsDto }>({
          query: DEFAULT_DRAWDOWN_TARGETS,
          variables: {
            tenantId: data.legalEntity.tenant.id
          }
        });

        const drawdownTargets = structurAssetClassesWithPrevValues(
          defaultDrawdownTargetsData.defaultDrawdownTargets.targets.drawdown,
          defaultAssetClasses.assetClasses
        );
        const evergreenTargets = structurAssetClassesWithPrevValues(
          defaultDrawdownTargetsData.defaultDrawdownTargets.targets.evergreen,
          defaultAssetClasses.assetClasses
        );
        setAssetClassTargets([...drawdownTargets, ...evergreenTargets]);
        const sumAssetClassPercentages = getSumAssetClassPercentages([...drawdownTargets, ...evergreenTargets]);
        setValue('privatePercentage', sumAssetClassPercentages);
        setAssetClassChartWithTableData(defaultDrawdownTargetsData.defaultDrawdownTargets);
        setAssetClassSelectedOption(assetClassOptions[0]);
        return;
      }

      const drawdownTargets = structurAssetClassesWithPrevValues(data.legalEntity.drawdownTargets.targets.drawdown, defaultAssetClasses.assetClasses);
      const evergreenTargets = structurAssetClassesWithPrevValues(
        data.legalEntity.drawdownTargets.targets.evergreen,
        defaultAssetClasses.assetClasses
      );
      setAssetClassTargets([...drawdownTargets, ...evergreenTargets]);
      const sumAssetClassPercentages = getSumAssetClassPercentages([...drawdownTargets, ...evergreenTargets]);
      setValue('privatePercentage', sumAssetClassPercentages);
      setAssetClassChartWithTableData(data.legalEntity.drawdownTargets);
      setAssetClassSelectedOption(assetClassOptions[1]);
    }
  });

  const {
    loading: listHouseholdsLoading,
    refetch: listHouseholdsRefetch,
    error: listHouseholdsError
  } = useQuery<IListInvestorsQuery>(LIST_INVESTORS_QUERY, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
    variables: {
      data: {
        sortBy: 'INVESTOR',
        sortDirection: 'ASC',
        search: null,
        limit: 1000,
        offset: 0,
        includeRecommendations: true,
        filter: tenantName
      }
    },
    onCompleted: ({ listInvestors }: { listInvestors: ListCallback<IListInvestors> }) => {
      setHouseholdList(listInvestors.data.map((item) => ({ id: item.id, name: item.name })));
    },
    onError: (error) => {
      console.error(error);
    }
  });

  const { loading: defaultDrawdownTargetsLoading } = useQuery<{ defaultDrawdownTargets: DrawdownTargetsDto }>(DEFAULT_DRAWDOWN_TARGETS, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'no-cache',
    skip: isEditMode,
    variables: {
      tenantId
    },
    onCompleted: async ({ defaultDrawdownTargets }) => {
      setAssetClassChartWithTableData(defaultDrawdownTargets);
      const { data: defaultAssetClasses } = await client.query<{ assetClasses: IAssetClass[] }>({
        query: GET_ASSET_CLASSES,
        variables: {
          tenantId
        }
      });

      const drawdownTargets = structurAssetClassesWithPrevValues(defaultDrawdownTargets.targets.drawdown, defaultAssetClasses.assetClasses);
      const evergreenTargets = structurAssetClassesWithPrevValues(defaultDrawdownTargets.targets.evergreen, defaultAssetClasses.assetClasses);
      setAssetClassTargets([...drawdownTargets, ...evergreenTargets]);
      const sumAssetClassPercentages = getSumAssetClassPercentages([...drawdownTargets, ...evergreenTargets]);
      setValue('privatePercentage', sumAssetClassPercentages);
    },
    onError: (error) => {
      console.error(error);
    }
  });

  useEffect(() => {
    if (legalEntityLoading) return;

    const openSectionsObject = JSON.parse(openSections);
    const openSectionsIds = Object.keys(openSectionsObject);
    if (!openSectionsIds.length) return;
    scrollToSection(openSectionsIds[0]);
    setSectionsState((prev) => ({ ...prev, ...openSectionsObject }));
  }, [openSections, legalEntityLoading]);

  const onCreateInvestor = async (data: CreateInvestorDto) => {
    setUpdateError(null);
    const {
      familyId,
      entityName,
      overCommitRatio,
      totalEntityValue,
      defaultBiteSize1,
      defaultBiteSize2,
      privatePercentage,
      taxExempt,
      sectorsToAvoid,
      onHold,
      advisorReportingAccountNumber,
      ignoreAllocationTargets,
      ignoreBiteSize,
      investorHouseholdOption,
      capacityCalculationOption
    } = data;
    const allocationTargets = getAllocationTargetsToUpdate(assetClassTargets, statusData?.target_sub_asset_classes, privatePercentage);

    if (isEditMode) {
      let newFamilyId: string | null = null;
      if (newInvestorHouseholdName && newInvestorHouseholdName === familyId) {
        await createInvestor({
          variables: {
            data: {
              name: newInvestorHouseholdName,
              legalEntityId: entityId,
              tenantName
            }
          },
          onCompleted: (data) => {
            newFamilyId = data.createInvestor.id;
          }
        });
      }

      await updateEntity({
        variables: {
          data: {
            id: entityId,
            familyId: newFamilyId ? newFamilyId : householdList.find((item) => item.name === familyId)?.id,
            entityName,
            taxExempt,
            sectorsToAvoid,
            onHold,
            advisorReportingAccountNumber,
            ignoreAllocationTargets,
            ignoreBiteSize,
            ...(!ignoreAllocationTargets && {
              allocationTargets: assetClassSelectedOption === assetClassOptions[0] ? [] : allocationTargets,
              totalEntityValue: parseFloat(totalEntityValue),
              privatePercentage: parseFloat(privatePercentage),
              overCommitRatio: capacityCalculationOption === capacityCalculationOptions[1] ? parseFloat('1.' + overCommitRatio) : null
            }),
            ...(!ignoreBiteSize && {
              defaultBiteSize1: parseFloat(defaultBiteSize1),
              defaultBiteSize2: parseFloat(defaultBiteSize2)
            })
          }
        },
        onCompleted: ({ updateLegalEntity }) => {
          setUpdateError(null);
          setTimeout(() => {
            const baseUrl = `/investors/${updateLegalEntity.family.id}`;
            if (backToTitle === 'Investor') {
              navigate(`${baseUrl}?backToTitle=Investors`, { replace: true });
              return;
            }
            navigate(`${baseUrl}/${updateLegalEntity.id}?backToTitle=Investor`, { replace: true });
          }, 0);

          goBack({ fallBack: '/investors' });
        }
      });
      return;
    }
    createLegalEntity({
      variables: {
        data: {
          entityName,
          taxExempt,
          sectorsToAvoid,
          onHold,
          advisorReportingAccountNumber,
          ignoreAllocationTargets,
          ignoreBiteSize,
          familyId: investorHouseholdOption === houseHoldSectionOptions[1] ? householdList.find((item) => item.name === familyId)?.id : null,
          tenantId,
          allocationTargets: assetClassSelectedOption === assetClassOptions[0] ? [] : allocationTargets,
          totalEntityValue: !ignoreAllocationTargets ? parseFloat(totalEntityValue) : 0,
          privatePercentage: !ignoreAllocationTargets ? parseFloat(privatePercentage) : 0,
          overCommitRatio: capacityCalculationOption === capacityCalculationOptions[1] ? parseFloat('1.' + overCommitRatio) : null,
          ...(!ignoreBiteSize && {
            defaultBiteSize1: parseFloat(defaultBiteSize1),
            defaultBiteSize2: parseFloat(defaultBiteSize2)
          })
        }
      },
      onCompleted: ({ createLegalEntity }) => {
        navigate(`/investors/${createLegalEntity.family.id}/${createLegalEntity.id}?backToTitle=Investors`, { replace: true });
      }
    });
  };

  const scrollToSection = (id: string) => {
    const element = document.getElementById(id);
    if (element) {
      window.scrollTo({
        top: element.offsetTop,
        behavior: 'smooth'
      });
    }
  };

  const onInvalidForm = (data: FieldErrors<CreateInvestorDto>) => {
    let errorIds: string[] = [];
    let updatedSectionsState = { ...sectionsState };
    if (data.hasOwnProperty('entityName') || data.hasOwnProperty('familyId')) {
      errorIds.push('investorDetailsSection');
    }
    if (data.hasOwnProperty('totalEntityValue') || data.hasOwnProperty('privatePercentage') || data.hasOwnProperty('overCommitRatio')) {
      errorIds.push('allocationTargetsSection');
      updatedSectionsState = { ...updatedSectionsState, allocationTargetsSection: true };
    }
    if (data.hasOwnProperty('defaultBiteSize1') || data.hasOwnProperty('defaultBiteSize2')) {
      errorIds.push('defaultBiteSizeSection');
      updatedSectionsState = { ...updatedSectionsState, defaultBiteSizeSection: true };
    }
    if (errorIds.length) {
      scrollToSection(errorIds[0]);
    }

    setSectionsState(updatedSectionsState);
  };

  const openAssetClassTargetsPage = () => {
    setIsAssetClassTargetsPageOpen(true);
    setScrollPosition(window.scrollY);
  };

  const closeAssetClassTargetsPage = () => {
    setIsAssetClassTargetsPageOpen(false);
    setTimeout(() => {
      window.scrollTo(0, scrollPosition);
    }, 0);
    setScrollPosition(0);
  };

  const resetAssetClassTargetsToDefaultValues = (assetClassTargets: AssetClassWithPrevValues[], privatePercentage: string) => {
    const defaultPrivatePercentage = assetClassTargets
      .reduce((acc, assetClass) => {
        return acc + parseFloat(assetClass?.defaultPercentage ?? '0');
      }, 0)
      .toFixed(2);

    const defaultAssetClassTargets = resetAssetClassTargets(assetClassTargets);
    const updatedAssetClassDrawdownTargets = updateAssetClassDrawdownTargets(
      assetClassChartWithTableData.targets.drawdown,
      defaultAssetClassTargets,
      defaultPrivatePercentage
    );
    const updatedAssetClassEvergreen = updateAssetClassDrawdownTargets(
      assetClassChartWithTableData.targets.evergreen,
      defaultAssetClassTargets,
      defaultPrivatePercentage
    );

    setValue('privatePercentage', defaultPrivatePercentage);
    setAssetClassChartWithTableData({
      ...assetClassChartWithTableData,
      targets: { drawdown: updatedAssetClassDrawdownTargets, evergreen: updatedAssetClassEvergreen }
    });
    setAssetClassTargets(defaultAssetClassTargets);
    closeAssetClassTargetsPage();
  };

  const saveAssetClassTargets = (assetClassTargets: AssetClassWithPrevValues[], privatePercentage: string) => {
    const updatedAssetClassDrawdownTargets = updateAssetClassDrawdownTargets(
      assetClassChartWithTableData.targets.drawdown,
      assetClassTargets,
      privatePercentage
    );
    const updatedAssetClassEvergreen = updateAssetClassDrawdownTargets(
      assetClassChartWithTableData.targets.evergreen,
      assetClassTargets,
      privatePercentage
    );

    setValue('privatePercentage', privatePercentage);
    setAssetClassChartWithTableData({
      ...assetClassChartWithTableData,
      targets: { drawdown: updatedAssetClassDrawdownTargets, evergreen: updatedAssetClassEvergreen }
    });
    const updatedAssetClassTargets = assetClassTargets.map((assetClass) => {
      return {
        ...assetClass,
        prevPercentage: assetClass.percentage,
        subAssetClasses: assetClass.subAssetClasses.map((subAssetClass) => {
          return {
            ...subAssetClass,
            prevPercentage: subAssetClass.percentage
          };
        })
      };
    });
    setAssetClassTargets(updatedAssetClassTargets);
    closeAssetClassTargetsPage();
  };

  const openModalWindow = (type: string) => {
    setModalWindow({ type, isOpen: true });
  };

  const closeModalWindow = () => {
    setModalWindow({ ...modalWindow, isOpen: false });
  };

  const saveSectionState = (id: string, state: { isSectionOpen: boolean }) => {
    setSectionsState((prev) => ({ ...prev, [id]: state.isSectionOpen }));
  };

  if (listHouseholdsLoading || legalEntityLoading || defaultDrawdownTargetsLoading) {
    return <LoaderOnWholeScreen size={60} />;
  }

  return isAssetClassTargetsPageOpen ? (
    <AssetClassTargetsPage
      assetClassTargets={assetClassTargets}
      targetSubAssetClasses={statusData?.target_sub_asset_classes}
      saveAssetClassTargets={saveAssetClassTargets}
      resetAssetClassTargetsToDefaultValues={resetAssetClassTargetsToDefaultValues}
      closeAssetClassTargetsPage={closeAssetClassTargetsPage}
      assetClassSelectedOption={assetClassSelectedOption}
      setAssetClassSelectedOption={setAssetClassSelectedOption}
    />
  ) : (
    <>
      {(createLegalEntityLoading || updateEntityLoading) && (
        <Loading
          isOpen={createLegalEntityLoading || updateEntityLoading}
          title="Saving Entity"
          description="Please allow a few moment for this process to complete."
        />
      )}
      {modalWindow.isOpen && modalWindow.type === 'add-investor-household' && (
        <AddInvestorHousehold
          isOpen={modalWindow.isOpen}
          getNewFamilyName={(value) => {
            setNewInvestorHouseholdName(value);
            setValue('familyId', value);
            setHouseholdList((prevList) => [...prevList, { id: null, name: value }]);
          }}
          onClose={closeModalWindow}
          setValue={setValue}
        />
      )}
      <MainWrap>
        <Header modalControl={<GoBackButton handleClose={() => goBack({ fallBack: '/investors' })} backToTitle={backToTitle} />} />
        <PageTitle title={isEditMode ? 'Edit Entity' : 'Add New Investor'} />
      </MainWrap>
      <MainWrap>
        {updateError && <Banner icon={<RejectedBannerIcon fill={theme.context.light} />} title="Error" description={updateError} bgColor="#D63B4B" />}
        <PaddingWrap>
          <SectionsWrap>
            <InvestorDetails householdList={householdList} control={control} isEditMode={isEditMode} openModalWindow={openModalWindow} />
            <SetAllocationTargets
              control={control}
              isSectionOpen={sectionsState.allocationTargetsSection}
              assetClassChartWithTableData={assetClassChartWithTableData}
              saveSectionState={saveSectionState}
              openAssetClassTargetsPage={openAssetClassTargetsPage}
            />
            <SetAllocationRestrictions
              control={control}
              isSectionOpen={sectionsState.allocationRestrictionsSection}
              saveSectionState={saveSectionState}
              setValue={setValue}
            />
            <SetAllocationBiteSize control={control} isSectionOpen={sectionsState.defaultBiteSizeSection} saveSectionState={saveSectionState} />
            <DataIntegration control={control} isSectionOpen={sectionsState.dataIntegrationSection} saveSectionState={saveSectionState} />
          </SectionsWrap>
          <FormFooter
            onCancel={() => goBack({ fallBack: '/investors' })}
            onSave={handleSubmit(onCreateInvestor, onInvalidForm)}
            disableSaveButton={false}
          />
        </PaddingWrap>
      </MainWrap>
    </>
  );
};

const PaddingWrap = styled.div`
  padding: 50px 16px 0 16px;
  @media (min-width: 600px) {
    padding-left: 50px;
    padding-right: 50px;
  }
`;

export const SectionsWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const TwoColumnEntityWrapper = styled.div<{ isMobile: boolean }>`
  display: grid;
  grid-template-columns: ${({ isMobile }) => (isMobile ? '1fr' : '1fr 1fr')};
  gap: 20px;
`;

export const RadioWrapper = styled.div`
  color: ${({ theme }) => theme.font.base};
`;

export const RadioInputWrapper = styled.div<{ isMobile: boolean }>`
  display: flex;
  align-items: ${({ isMobile }) => (isMobile ? 'flex-start' : 'center')};
  flex-direction: ${({ isMobile }) => (isMobile ? 'column-reverse' : 'row')};
  gap: ${({ isMobile }) => (isMobile ? '15px' : '0')};
`;

export const OptionsWrapper = styled.div`
  max-width: 300px;
  width: 100%;
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;
`;
