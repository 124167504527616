import ModalWrappedContent from '@/components/basicComponents/modal/modalWrappedContent';
import styled from 'styled-components';

interface LinkedFieldErrorProps {
  onClose: () => void;
  isOpen: boolean;
  title: string;
  description: string;
}

const LinkedFieldError = ({
  onClose,
  isOpen,
  title,
  description
}: LinkedFieldErrorProps) => {
  return (
    <ModalWrappedContent
      isOpen={isOpen}
      onClose={onClose}
      showCancelButton={false}
      confirmButton={{ name: 'OK', onClick: onClose }}
    >
      <ModalContainer>
        <ModalTitle>{title}</ModalTitle>
        {description}
      </ModalContainer>
    </ModalWrappedContent>
  );
};

export default LinkedFieldError;

const ModalContainer = styled.div`
  font-weight: 400;
  padding: 10px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  text-align: center;
`;

const ModalTitle = styled.div`
  font-size: 33px;
  font-weight: 400;
  text-align: center;
  color: #2e2e2e;
`;
