import * as React from 'react';

export const ChartIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || 12}
    height={props.height || 12}
    onClick={props.onClick || undefined}
    style={props?.style}
    viewBox="0 0 12 12"
    fill="none"
  >
    <path d="M3.00114 3.74805H0V11.9955H3.00114V3.74805Z" fill={props.fill || '#202020'} />
    <path d="M11.9992 6.74902H8.99805V11.9999H11.9992V6.74902Z" fill={props.fill || '#202020'} />
    <path d="M7.50114 0H4.5V12H7.50114V0Z" fill={props.fill || '#202020'} />
  </svg>
);
