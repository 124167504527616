import * as React from 'react';

export const NavOpen = ({ ...props }: React.SVGProps<SVGSVGElement>) => (
  <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M-3.74821e-07 1.28338L3.70688 4.99998L-4.99053e-08 8.71658L1.1417 9.85828L6 4.99998L1.1417 0.141678L-3.74821e-07 1.28338Z"
      fill={props.fill || '#AFB8BC'}
    />
  </svg>
);
