import * as React from 'react';

export const ManagerUpdatesMenuIcon = ({ ...props }: React.SVGProps<SVGSVGElement>) => (
  <svg
    style={props.style}
    width={props.width || '28'}
    height={props.height || '28'}
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.6904 5.19803H20.5947V3H18.3901V5.19803H9.59137V3H7.39334V5.19803H6.29104C6.00271 5.19803 5.7172 5.25492 5.45089 5.36546C5.18459 5.47601 4.94272 5.63803 4.73914 5.84222C4.53556 6.04641 4.37426 6.28875 4.26451 6.55539C4.15477 6.82202 4.09875 7.10772 4.09962 7.39606V22.802C4.09962 23.3849 4.3312 23.944 4.74341 24.3562C5.15562 24.7684 5.7147 25 6.29765 25H21.697C22.2805 25 22.8403 24.7686 23.2535 24.3566C23.6668 23.9446 23.8998 23.3855 23.9016 22.802V7.39606C23.9007 7.10654 23.8429 6.82003 23.7313 6.55289C23.6197 6.28574 23.4565 6.04319 23.2512 5.83908C23.0459 5.63497 22.8024 5.47332 22.5346 5.36333C22.2668 5.25334 21.9799 5.19716 21.6904 5.19803V5.19803ZM21.6904 22.802H6.29104V11.7987H21.6904V22.802ZM21.6904 9.60066H6.29104V7.39606H21.6904V9.60066ZM10.6937 16.2013H8.48907V13.9967H10.6937V16.2013ZM15.0897 16.2013H12.8917V13.9967H15.0897V16.2013ZM19.4924 16.2013H17.2943V13.9967H19.4924V16.2013ZM10.6937 20.5974H8.48907V18.3993H10.6937V20.5974ZM15.0897 20.5974H12.8917V18.3993H15.0897V20.5974ZM19.4924 20.5974H17.2943V18.3993H19.4924V20.5974Z"
      fill={props.fill || '#FAFAFA'}
    />
  </svg>
);
