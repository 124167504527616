import { ApprovedIcon } from '@/assets/icons/info-icons/approved';
import { RejectedBannerIcon } from '@/assets/icons/info-icons/rejectedBanner';
import { Banner } from '@/components/basicComponents/banner';
import RadioButton from '@/components/basicComponents/radioButton';
import FilterSelect from '@/components/basicComponents/select';
import Button from '@/components/fat-basicComponents/button';
import Input from '@/components/fat-basicComponents/input';
import { formatPercentageInputValue, handleCursorPositionInPercentageInput } from '@/components/fat-basicComponents/input/utils';
import Header from '@/components/fat-header';
import { GoBackButton } from '@/components/fat-header/goBackButton';
import { PageTitle } from '@/components/fat-header/pageTitle';
import { useResponsive } from '@/hooks/use-responsive';
import { MainWrap, PaddingWrap } from '@/styles/common';
import { AnimatePresence, motion } from 'framer-motion';
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { AssetClassWithPrevValues, RadioInputWrapper, RadioWrapper } from '..';

interface AssetClassTargetsPageProps {
  assetClassTargets: AssetClassWithPrevValues[];
  targetSubAssetClasses: boolean;
  resetAssetClassTargetsToDefaultValues: (assetClassTargets: AssetClassWithPrevValues[], privatePercentage: string) => void;
  saveAssetClassTargets: (assetClassTargets: AssetClassWithPrevValues[], privatePercentage: string) => void;
  closeAssetClassTargetsPage: () => void;
  assetClassSelectedOption: string;
  setAssetClassSelectedOption: Dispatch<SetStateAction<string>>;
  pageTitle?: string;
  mode?: 'editEntity' | 'settings';
}

const assetClassOptions = {
  editEntity: ['Use default asset class allocation targets', 'Customize targets for this investor entity'],
  settings: ['Use Curio default asset class targets', "Set my firm's default asset class targets"]
};

const bannerDescription = {
  editEntity: 'By default all investor entities use the same default asset class allocation targets',
  settings: 'All new investor entities will use these settings by default.'
};

export const AssetClassTargetsPage = ({
  assetClassTargets,
  targetSubAssetClasses,
  resetAssetClassTargetsToDefaultValues,
  saveAssetClassTargets,
  closeAssetClassTargetsPage,
  assetClassSelectedOption,
  setAssetClassSelectedOption,
  pageTitle = 'Asset Class Targets',
  mode = 'editEntity'
}: AssetClassTargetsPageProps) => {
  const { isMobile } = useResponsive();
  const theme = useTheme();

  const [assetClassTargetsCopy, setAssetClassTargetsCopy] = useState<AssetClassWithPrevValues[]>([]);
  const [withSubAssetClasses, setWithSubAssetClasses] = useState<boolean>(false);

  useEffect(() => {
    setAssetClassTargetsCopy(assetClassTargets || []);
    const hasSubAssetClasses = assetClassTargets.some((assetClass) => assetClass.subAssetClasses.length > 0);
    setWithSubAssetClasses(hasSubAssetClasses);
  }, [assetClassTargets]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleAssetClassInputChange = (assetClassId: string, newValue: string) => {
    const updatedAssetClasses = assetClassTargetsCopy.map((assetClass) => {
      if (assetClass.id === assetClassId) {
        return {
          ...assetClass,
          percentage: newValue
        };
      }
      return assetClass;
    });

    setAssetClassTargetsCopy(updatedAssetClasses);
  };

  const handleSubAssetClassInputChange = (assetClassId: string, subAssetClassId: string, newValue: string) => {
    const updatedAssetClasses = assetClassTargetsCopy.map((assetClass) => {
      if (assetClass.id === assetClassId) {
        return {
          ...assetClass,
          percentage:
            assetClass.subAssetClasses
              .reduce((acc, current) => {
                if (current.id === subAssetClassId) {
                  return acc + parseFloat(newValue || '0');
                }
                return acc + parseFloat(current.percentage || '0');
              }, 0)
              .toFixed(2) + '%',
          subAssetClasses: assetClass.subAssetClasses.map((subAssetClass) => {
            if (subAssetClass.id === subAssetClassId) {
              return {
                ...subAssetClass,
                percentage: newValue
              };
            }
            return subAssetClass;
          })
        };
      }
      return assetClass;
    });

    setAssetClassTargetsCopy(updatedAssetClasses);
  };

  const currentTotal = useMemo(() => {
    return assetClassTargetsCopy.reduce((acc, current) => acc + parseFloat(current.percentage || '0'), 0);
  }, [assetClassTargetsCopy]);

  const onSave = () => {
    if (assetClassSelectedOption === assetClassOptions[mode][0]) {
      resetAssetClassTargetsToDefaultValues(assetClassTargetsCopy, currentTotal.toFixed(2));
      return;
    }
    if (assetClassSelectedOption === assetClassOptions[mode][1]) {
      saveAssetClassTargets(assetClassTargetsCopy, currentTotal.toFixed(2));
      return;
    }
  };

  return (
    <>
      <MainWrap>
        <Header modalControl={<GoBackButton handleClose={closeAssetClassTargetsPage} backToTitle={''} />} />
        <PageTitle title={pageTitle} />
      </MainWrap>
      <MainWrap>
        <PaddingWrap>
          <AssetClassTargetsWrap>
            <RadioWrapper>
              Customize asset class allocation targets
              <RadioInputWrapper isMobile={isMobile}>
                <OptionsWrapper>
                  {assetClassOptions[mode].map((option, index) => (
                    <RadioButton
                      key={option + index}
                      name="customizeAssetClassAllocationTargets"
                      radioButtonText={option}
                      selectedOption={assetClassSelectedOption}
                      handleRadioChange={(event) => setAssetClassSelectedOption(event.target.value)}
                    />
                  ))}
                </OptionsWrapper>
                {assetClassSelectedOption === assetClassOptions[mode][0] && (
                  <RadioWrapper>
                    <Banner
                      title=""
                      description={bannerDescription[mode]}
                      icon={<RejectedBannerIcon fill={theme.header.base} />}
                      bgColor={theme.layer[1]}
                      color={theme.font.base}
                    />
                  </RadioWrapper>
                )}
              </RadioInputWrapper>
            </RadioWrapper>
            <AnimatePresence>
              {assetClassSelectedOption === assetClassOptions[mode][1] && (
                <motion.div
                  initial={{
                    opacity: 0
                  }}
                  animate={{
                    opacity: 1
                  }}
                  exit={{
                    opacity: 0
                  }}
                >
                  <PrivateCapitalTotal>
                    <AssetClassTitle>PRIVATE CAPITAL TOTAL</AssetClassTitle>
                    <AssetClassTotal>{`${currentTotal.toFixed(2)}%`}</AssetClassTotal>
                  </PrivateCapitalTotal>
                  <AssetClassTargets>
                    {targetSubAssetClasses && withSubAssetClasses ? (
                      <WithSubAssetClassesWrapper>
                        {assetClassTargetsCopy.map((assetClass) => (
                          <div key={assetClass.id}>
                            <AssetClassHeaderWrap>
                              <AssetClassTitle>{assetClass.name}</AssetClassTitle>
                              <AssetClassTotal>{assetClass.percentage}</AssetClassTotal>
                            </AssetClassHeaderWrap>
                            <AssetClassTableBody>
                              {assetClass.subAssetClasses.map((subAssetClass) => (
                                <AssetClassTableRow key={subAssetClass.id} isMobile={isMobile}>
                                  <AssetClassTitle>{subAssetClass.name}</AssetClassTitle>
                                  <Input
                                    value={subAssetClass.percentage}
                                    onChange={(e) => {
                                      const formattedValue = formatPercentageInputValue(e);
                                      handleSubAssetClassInputChange(assetClass.id, subAssetClass.id, formattedValue);
                                    }}
                                    onClick={handleCursorPositionInPercentageInput}
                                    onKeyUp={handleCursorPositionInPercentageInput}
                                    size="md"
                                    width="225px"
                                  />
                                </AssetClassTableRow>
                              ))}
                            </AssetClassTableBody>
                          </div>
                        ))}
                      </WithSubAssetClassesWrapper>
                    ) : (
                      <div>
                        <AssetClassTableBody>
                          {assetClassTargetsCopy.map((assetClass) => (
                            <AssetClassTableRow key={assetClass.id} isMobile={isMobile}>
                              <AssetClassTitle>{assetClass.name}</AssetClassTitle>
                              <Input
                                value={assetClass.percentage}
                                onChange={(e) => {
                                  const formattedValue = formatPercentageInputValue(e);
                                  handleAssetClassInputChange(assetClass.id, formattedValue);
                                }}
                                onClick={handleCursorPositionInPercentageInput}
                                onKeyUp={handleCursorPositionInPercentageInput}
                                size="md"
                                width="225px"
                              />
                            </AssetClassTableRow>
                          ))}
                        </AssetClassTableBody>
                      </div>
                    )}

                    <AssetClassBannerWrap isMobile={isMobile}>
                      <AssetClassBanner>
                        <Banner
                          title={currentTotal.toFixed(2) + '%'}
                          description="Private Capital targets must not exceed 100%"
                          icon={currentTotal <= 100 ? <ApprovedIcon fill={theme.context.light} /> : <RejectedBannerIcon fill={theme.context.light} />}
                          bgColor={currentTotal <= 100 ? theme.context.success : theme.context.error}
                        />
                      </AssetClassBanner>
                    </AssetClassBannerWrap>
                  </AssetClassTargets>
                </motion.div>
              )}
            </AnimatePresence>

            <ButtonsWraap>
              <Button styleType="outline" size="lg" onClick={closeAssetClassTargetsPage}>
                Cancel
              </Button>
              <Button
                styleType={
                  assetClassSelectedOption === assetClassOptions[mode][0] ||
                  (assetClassSelectedOption === assetClassOptions[mode][1] && currentTotal <= 100)
                    ? 'default'
                    : 'disabled'
                }
                size="lg"
                onClick={onSave}
              >
                Save
              </Button>
            </ButtonsWraap>
          </AssetClassTargetsWrap>
        </PaddingWrap>
      </MainWrap>
    </>
  );
};

const AssetClassTargetsWrap = styled.div`
  margin: 50px 0 50px 0;
  padding: 55px 40px 40px;
  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.border.base};
  background-color: ${({ theme }) => theme.layer[1]};
  color: ${({ theme }) => theme.font.base};
`;

const PrivateCapitalTotal = styled.div`
  display: flex;
  align-items: center;
  font-size: 19px;
  font-weight: 400;
  line-height: 26.6px;
  padding: 10px 30px;
  margin-top: 50px;
  border-radius: 4px;
  background: ${({ theme }) => theme.layer[3]};
  color: ${({ theme }) => theme.header.action};
`;

const WithSubAssetClassesWrapper = styled.div`
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  gap: 60px;
`;

const AssetClassTargets = styled.div`
  display: flex;
  flex-direction: column;
  gap: 60px;
`;

const OptionsWrapper = styled.div`
  max-width: 400px;
  width: 100%;
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;
`;

const CustomizeSelectWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const CustomizeSelect = styled(FilterSelect)`
  background-color: ${({ theme }) => theme.layer[1]};
  border: 1px solid ${({ theme }) => theme.border.base};
  padding: 10px;
  border-radius: 5px;

  #titleSelect {
    text-transform: none;
    font-family: Blinker;
    font-size: 16px;
    line-height: 22.4px;
  }

  #dropDownSelect {
    top: 50px;
  }
`;

const AssetClassTableBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px 30px;
`;

const AssetClassTableRow = styled.div<{ isMobile: boolean }>`
  display: flex;
  align-items: center;
  flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};
`;

const AssetClassHeaderWrap = styled.div`
  padding: 10px 30px;
  background-color: ${({ theme }) => theme.layer[2]};
  display: flex;
  align-items: center;
`;

const AssetClassTitle = styled.div`
  max-width: 228px;
  width: 100%;
`;

const AssetClassTotal = styled.div`
  font-weight: 700;
`;

const AssetClassBannerWrap = styled.div<{ isMobile: boolean }>`
  padding: ${({ isMobile }) => (isMobile ? '50px 0 0 0' : '50px 0 0 185px')};
  border-top: 1px solid ${({ theme }) => theme.border.base};
`;

const AssetClassBanner = styled.div`
  max-width: 341px;
  width: 100%;
`;

const ButtonsWraap = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
  margin: 50px 0 10px 0;
`;
