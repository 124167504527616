import { CloseInfoIcon } from '@/assets/icons/info-icons/closeInfo';
import { useLocation } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';

interface GoBackButtonProps {
  handleClose?: () => void;
  backToTitle?: string;
  withAdditionalOption?: boolean;
}

export const GoBackButton = ({ handleClose, backToTitle, withAdditionalOption = false }: GoBackButtonProps) => {
  const location = useLocation();
  const theme = useTheme();

  return (
    <GoBack withAdditionalOption={withAdditionalOption} onClick={handleClose ? handleClose : () => null}>
      <CloseInfoIcon fill={theme.action.primary} width={18} height={18} />
      <BackToTitle>{backToTitle && location.key !== 'default' ? `Back To ${backToTitle}` : 'Back'}</BackToTitle>
    </GoBack>
  );
};

export const GoBackButtonWrap = styled.div`
  display: flex;
  gap: 1px;
  align-items: center;
`;

const GoBack = styled.div<{ withAdditionalOption: boolean }>`
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  border-radius: ${({ withAdditionalOption }) => (withAdditionalOption ? '20px 0px 0px 20px' : '20px')};
  background: ${({ theme }) => theme.layer[1]};
  padding: 10px 30px;
`;

const BackToTitle = styled.div`
  color: ${({ theme }) => theme.action.primary};
  font-family: Blinker;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 22.4px;
`;

export const AdditionalOptionWrap = styled.div`
  padding: 9px 30px;
  border-radius: 0px 20px 20px 0px;
  background: ${({ theme }) => theme.layer.base};
  cursor: pointer;
`;
