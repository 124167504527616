import { useNavigate, useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Button from '@/components/basicComponents/button';
import FilterSelect from '@/components/basicComponents/select';
import {
  CloseBtn,
  ModalControlWrap,
  ModalPageTitle
} from '@/components/client-details';
import { ErrorBanner } from '@/components/dueDiligence-page/dueDiligenceRecord/errorBanner';
import {
  ButtonWrapper,
  ControlWrapper
} from '@/components/dueDiligence-page/modifyDiligence';
import Header from '@/components/header';
import { useResponsive } from '@/hooks/use-responsive';
import { CloseInfoIcon } from '@/assets/icons/info-icons/closeInfo';
import { MainWrap, PaddingWrap } from '@/styles/common';
import styled from 'styled-components';
import { IFirmSettings } from '.';
import { MarginTop, SettingsContainer } from '../importData';
import { fontSelect } from './constants';

interface EditFontStyleProps {
  handleClose: () => void;
  createFirmSetting: (type: string, key: string, value: string) => void;
  updateFirmSetting: (id: string, value: string) => void;
  currentRow: IFirmSettings | null;
}

export const EditFontStyle = ({
  handleClose,
  createFirmSetting,
  updateFirmSetting,
  currentRow
}: EditFontStyleProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const { isMobile, isTablet } = useResponsive();

  const [chosenFont, setChosenFont] = useState<{
    fontFamily: string;
    fontColor: string;
    fontWeight: string;
  }>({
    fontFamily: '',
    fontColor: '',
    fontWeight: ''
  });
  const [error, setError] = useState(false);

  useEffect(() => {
    if (currentRow?.value) {
      const values = JSON.parse(currentRow.value);
      setChosenFont({
        fontFamily: values.fontFamily,
        fontColor: values.fontColor,
        fontWeight: values.fontWeight
      });
    }
    return () => {
      setChosenFont({
        fontFamily: '',
        fontColor: '',
        fontWeight: ''
      });
    };
  }, [currentRow]);

  useEffect(() => {
    if (!currentRow?.value) {
      setChosenFont({
        fontFamily:
          queryParams.get('edit') === 'headerFontStyle'
            ? 'Sanchez'
            : 'Open Sans',
        fontColor:
          queryParams.get('edit') === 'headerFontStyle' ? '35739C' : '757575',
        fontWeight:
          queryParams.get('edit') === 'headerFontStyle' ? '400' : '600'
      });
    }
  }, [queryParams.get('edit')]);

  const validateInput = (value: string) => {
    const regex = /^[0-9a-f]{0,6}$/i;

    if (!regex.test(value)) {
      setError(true);
      return false;
    }
    setError(false);
    return true;
  };

  const saveValue = (key: string) => {
    if (!validateInput(chosenFont.fontColor)) return;
    const defaultColor = key === 'headerFontStyle' ? '35739C' : '757575';
    const headertFontValue = JSON.stringify({
      fontFamily: chosenFont.fontFamily,
      fontColor: chosenFont.fontColor.length
        ? chosenFont.fontColor
        : defaultColor,
      fontWeight: chosenFont.fontWeight
    });
    if (queryParams.get('id') !== 'null') {
      updateFirmSetting(queryParams.get('id') as string, headertFontValue);
      return;
    }
    createFirmSetting('templateStyling', key, headertFontValue);
  };

  return (
    <MainWrap>
      <Header
        modalControl={
          <ModalControlWrap>
            <CloseBtn onClick={handleClose}>
              <CloseInfoIcon width={18} height={18} />
            </CloseBtn>
            <ModalPageTitle isTablet={isTablet} isMobile={isMobile}>
              {queryParams.get('fieldName')}
            </ModalPageTitle>
          </ModalControlWrap>
        }
      />
      <MarginTop />
      {error && (
        <ErrorBanner
          errorMessage={
            'Please enter a valid hexidecimal value for the Header Font Color'
          }
          errorDescription={'Provide six characters using only 0 - 9 and a - f'}
        />
      )}
      <PaddingWrap>
        <SettingsContainer isMobile={isMobile}>
          <FontStyleWrapper>
            <div style={{ width: '100%' }}>
              Header Font Family
              <FilterSelect
                data={Object.keys(fontSelect)}
                selected={chosenFont.fontFamily}
                setSelected={(value) =>
                  setChosenFont({
                    ...chosenFont,
                    fontWeight: fontSelect[value as keyof typeof fontSelect][0],
                    fontFamily: value
                  })
                }
                width="100%"
                minHeight="44px"
                fontSize="16px"
              />
            </div>
            <div style={{ width: '100%' }}>
              Header Font Color
              <Input
                type="string"
                value={chosenFont.fontColor}
                onChange={(event) =>
                  setChosenFont({
                    ...chosenFont,
                    fontColor: event.target.value
                  })
                }
              />
            </div>
            <div style={{ width: '100%' }}>
              Header Font Weight
              <FilterSelect
                data={
                  fontSelect[chosenFont.fontFamily as keyof typeof fontSelect]
                }
                selected={chosenFont.fontWeight}
                setSelected={(value) =>
                  setChosenFont({ ...chosenFont, fontWeight: value })
                }
                width="100%"
                minHeight="44px"
                fontSize="16px"
              />
            </div>
          </FontStyleWrapper>
          <CustomControlWrapper isMobile={isMobile}>
            <ButtonWrapper>
              <CancelButton
                isMobile={isMobile}
                onClick={handleClose}
                disabled={false}
              >
                Cancel
              </CancelButton>
              <SaveButton
                isMobile={isMobile}
                onClick={() => saveValue(queryParams.get('edit') as string)}
                disabled={false}
              >
                Save
              </SaveButton>
            </ButtonWrapper>
          </CustomControlWrapper>
        </SettingsContainer>
      </PaddingWrap>
    </MainWrap>
  );
};

const Input = styled.input`
  width: 100%;
  border: 1px solid #c7cbd2;
  border-radius: 4px;
  padding: 12px 8px;
  font-weight: 400;
  font-size: 16px;
  color: #373f4e;

  &:focus {
    outline: none;
  }
`;

const CustomControlWrapper = styled(ControlWrapper)`
  justify-content: flex-end;
  margin: 30px 0;
`;

const FontStyleWrapper = styled.div`
  display: flex;
  gap: 10px;
`;

const CancelButton = styled(Button)<{ isMobile: boolean }>`
  background: white;
  border: 1px solid #4587ec;
  color: #4587ec;
  padding: ${({ isMobile }) => (isMobile ? '8.5px 40.5px' : '11.5px 86.5px')};
`;

const SaveButton = styled(Button)<{ isMobile: boolean }>`
  background: ${({ disabled }) => (disabled ? '#dedede' : '#1c488a')};
  padding: ${({ isMobile }) => (isMobile ? '8.5px 50.5px' : '11.5px 93.5px')};
`;
