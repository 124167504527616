import {
  AdvisorFragment,
  AdvisorOwnerFragment,
  AllocationFragment,
  AssigneeFragment,
  EntitiesFragment,
  FamilyFragment,
  InvestmentFragment,
  LegalEntityFragment,
  OutstandingAllocationsFragment,
  OwnersFragment,
  PreAllocationStatusFragment,
  SelectedGroupLegalEntityFragment,
  SuitabilityCriteriaFragment
} from '@/components/allocations-page/fragments';
import { gql } from '@apollo/client';

export const buttonNames = ['Advisor Review', 'Compliance Review', 'Recommendations'];
export const columns = [
  {
    width: 14,
    title: 'Entity',
    key: 'entity',
    keySort: 'entity'
  },
  {
    width: 16,
    title: 'Client/Advisor',
    key: 'family',
    keySort: 'client/advisor'
  },
  {
    width: 12,
    title: 'Overall Capacity',
    key: 'overallCapacity',
    keySort: 'overall capacity'
  },
  {
    width: 12,
    title: 'Asset Class Capacity',
    key: 'assetClassCapacity',
    keySort: 'asset class capacity'
  },
  {
    width: 17,
    title: 'Suitability',
    key: 'suitability',
    keySort: 'suitability'
  },
  {
    width: 13,
    title: 'Amount',
    key: 'amount',
    keySort: 'amount'
  },
  {
    width: 12,
    title: 'Decision',
    key: 'decision',
    keySort: 'decision'
  },

  {
    width: 8,
    title: ' ',
    key: ''
  }
];

export const basicColumns = [
  {
    width: 14,
    title: 'Entity',
    key: 'entity',
    keySort: 'entity'
  },
  {
    width: 16,
    title: 'Client / Advisor',
    key: 'family',
    keySort: 'client / advisor'
  },
  {
    width: 12,
    title: 'Overall Capacity',
    key: 'overallCapacity',
    keySort: 'overall capacity'
  },
  {
    width: 12,
    title: 'Asset Class Capacity',
    key: 'assetClassCapacity',
    keySort: 'asset class capacity'
  },
  {
    width: 17,
    title: 'Suitability',
    key: 'suitability',
    keySort: 'suitability'
  },
  {
    width: 13,
    title: 'Amount',
    key: 'amount',
    keySort: 'amount'
  },
  {
    width: 12,
    title: 'Decision',
    key: 'decision',
    keySort: 'decision'
  }
];

export const reason = {
  default: 'No',
  additionTextToReason: 'Yes - ',
  items: ['Client Preference', 'Risk Profile', 'Timing', 'Liquidity Needs', 'Below Bite Size', 'Diversification', 'Needs']
};

export const investorReviewQuery = gql`
  ${InvestmentFragment}
  ${SelectedGroupLegalEntityFragment}
  ${EntitiesFragment}
  ${OutstandingAllocationsFragment}
  ${PreAllocationStatusFragment}
  ${LegalEntityFragment}
  ${FamilyFragment}
  ${AdvisorFragment}
  ${SuitabilityCriteriaFragment}
  ${OwnersFragment}
  query PotentialAllocations(
    $investmentId: String!
    $advisorFilter: String
    $suitabilityFilter: String
    $page: Int
    $rowsPerPage: Int
    $sort: String
    $order: String
    $search: String
    $includeProposedInvestments: Boolean
    $allocationStatusFilter: AllocationFilter
    $additionalAdvisorFilters: [AdditionalFilterDto!]
    $additionalDecisionFilters: [AdditionalDecisionFilterDto!]
    $additionalSuitabilityFilters: [AdditionalFilterDto!]
  ) {
    PotentialAllocations(
      investmentId: $investmentId
      advisorFilter: $advisorFilter
      suitabilityFilter: $suitabilityFilter
      page: $page
      rowsPerPage: $rowsPerPage
      sort: $sort
      order: $order
      search: $search
      includeProposedInvestments: $includeProposedInvestments
      allocationStatusFilter: $allocationStatusFilter
      additionalAdvisorFilters: $additionalAdvisorFilters
      additionalDecisionFilters: $additionalDecisionFilters
      additionalSuitabilityFilters: $additionalSuitabilityFilters
    ) {
      total
      page
      perPage
      totalAmount {
        length
        amount
      }
      investment {
        ...InvestmentFragment
        investmentOwner {
          ...OwnersFragment
        }
        operationsOwner {
          ...OwnersFragment
        }
        complianceOwner {
          ...OwnersFragment
        }
      }
      allocations {
        existingCommitment
        editable
        allocationStatus
        committedCapital
        decision
        reason
        override
        suitability
        proposalType
        suitabilityCriteria {
          ...SuitabilityCriteriaFragment
        }
        preAllocationStatus {
          ...PreAllocationStatusFragment
        }
        selectedGroupLegalEntity {
          ...SelectedGroupLegalEntityFragment
        }
        investment {
          ...InvestmentFragment
        }
        legalEntity {
          ...LegalEntityFragment
          entities {
            ...EntitiesFragment
            outstandingAllocations {
              ...OutstandingAllocationsFragment
            }
          }
          outstandingAllocations {
            ...OutstandingAllocationsFragment
          }
          family {
            ...FamilyFragment
          }
          advisor {
            ...AdvisorFragment
          }
        }
      }
    }
  }
`;

export const AllocationsQuery = gql`
  ${AdvisorOwnerFragment}
  ${AssigneeFragment}
  ${SelectedGroupLegalEntityFragment}
  ${SuitabilityCriteriaFragment}
  ${InvestmentFragment}
  ${FamilyFragment}
  ${AdvisorFragment}
  ${AllocationFragment}
  ${OutstandingAllocationsFragment}
  ${LegalEntityFragment}
  ${EntitiesFragment}
  query Allocations(
    $investmentId: String!
    $status: String
    $advisorFilter: String
    $suitabilityFilter: String
    $page: Int
    $rowsPerPage: Int
    $sort: String
    $order: String
    $search: String
    $includeProposedInvestments: Boolean
    $additionalAdvisorFilters: [AdditionalFilterDto!]
    $additionalDecisionFilters: [AdditionalDecisionFilterDto!]
    $additionalSuitabilityFilters: [AdditionalFilterDto!]
  ) {
    Allocations(
      investmentId: $investmentId
      status: $status
      advisorFilter: $advisorFilter
      suitabilityFilter: $suitabilityFilter
      page: $page
      rowsPerPage: $rowsPerPage
      sort: $sort
      order: $order
      search: $search
      includeProposedInvestments: $includeProposedInvestments
      additionalAdvisorFilters: $additionalAdvisorFilters
      additionalDecisionFilters: $additionalDecisionFilters
      additionalSuitabilityFilters: $additionalSuitabilityFilters
    ) {
      total
      page
      perPage
      totalAmount {
        length
        amount
      }
      allocations {
        ...AllocationFragment
        assignee {
          ...AssigneeFragment
        }
        advisorOwner {
          ...AdvisorOwnerFragment
        }
        suitabilityCriteria {
          ...SuitabilityCriteriaFragment
        }
        selectedGroupLegalEntity {
          ...SelectedGroupLegalEntityFragment
        }
        investment {
          ...InvestmentFragment
        }
        legalEntity {
          ...LegalEntityFragment
          entities {
            ...EntitiesFragment
            outstandingAllocations {
              ...OutstandingAllocationsFragment
            }
          }
          outstandingAllocations {
            ...OutstandingAllocationsFragment
          }
          family {
            ...FamilyFragment
          }
          advisor {
            ...AdvisorFragment
          }
        }
      }
    }
  }
`;

export const createAllocationMutation = gql`
  mutation createAllocations($data: [AllocationEntityCreateDto!]!) {
    createAllocations(data: $data) {
      id
    }
  }
`;

export const deleteAllocationMutation = gql`
  mutation deleteAllocation($allocationId: String!) {
    deleteAllocation(allocationId: $allocationId) {
      id
    }
  }
`;

export const updateAllocationMutation = gql`
  mutation updateAllocations($data: [AllocationEntityUpdateDto!]!) {
    updateAllocations(data: $data) {
      id
      allocationStatus
      committedCapital
      lastUpdatedAt
      operationsStatus
      investmentApprovedAt
      advisorApprovedAt
      complianceApprovedAt
      decision
      reason
      override
      suitability
      sentToClientAt
      clientApprovedAt
      subdocsGeneratedAt
      subdocsReviewedAt
      subdocsSentToClientAt
      subdocsSignedByClientAt
      subdocsSentToManagerAt
      subdocsCompletedAt
      commitmentSubmittedToReportingSystemAt
      commitmentRecordedInReportingSystemAt
      terminatedAt
      completedAt
      proposalType
      selectedGroupLegalEntity {
        id
        entityName
      }
      assignee {
        id
        email
        firstName
        lastName
      }
      investmentOwner {
        id
        firstName
        lastName
      }
      advisorOwner {
        id
        firstName
        lastName
      }
      complianceOwner {
        id
        firstName
        lastName
      }
      operationsOwner {
        id
        firstName
        lastName
      }
    }
  }
`;
