import { format } from 'date-fns';
import { ROLE } from './constants';
import { Investment, IOwner } from './types';

export const getOwnersFromInvestment = (investment: Partial<Investment>): Array<IOwner> => {
  if (!investment) return [];

  return [investment.investmentOwner, investment.operationsOwner, investment.complianceOwner].filter(Boolean);
};

export const hasPermissionToCreate = (roles: Array<ROLE | string> | undefined | null): boolean => {
  if (!roles) return false;
  if (roles.includes(ROLE.INVESTMENT_LEAD) || roles.includes(ROLE.INVESTMENT_MEMBER)) return true;
  return false;
};

export const hasPermissionToUpdate = (
  roles: Array<ROLE | string> | undefined | null,
  owners: Array<IOwner> = [],
  userId: string | undefined | null
): boolean => {
  if (hasPermissionToCreate(roles)) return true;
  // owners of the investment
  if (owners.length !== 0 && userId) {
    return owners.map((owner) => owner?.id).includes(userId);
  }
  return false;
};

export const getUTCDate = (dateInput: Date) => {
  const date = new Date(dateInput);
  const cleanedDate = new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate());
  return format(cleanedDate, 'MMM d, yyyy');
};

export const getBrowserTimezone = () => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
};

export const convertDateToTimezone = (date: Date, timezone: string) => {
  const dateInUTC = new Date(date);
  const dateInTimezone = new Date(dateInUTC.toLocaleString('en-US', { timeZone: timezone }));
  return dateInTimezone;
};

export const getTimezoneDate = (date: Date) => {
  const timezone = getBrowserTimezone();
  return convertDateToTimezone(date, timezone);
};

export const isValidDate = (d: unknown) => {
  return d instanceof Date && !isNaN(d as unknown as number);
};
