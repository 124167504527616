import { useMutation, useQuery } from '@apollo/client';
import { useEffect, useRef, useState } from 'react';
import styled, { useTheme } from 'styled-components';

import client from '@/apollo-client';
import { ApprovedIcon } from '@/assets/icons/info-icons/approved';
import { RejectedBannerIcon } from '@/assets/icons/info-icons/rejectedBanner';
import { AiAssistantIcon } from '@/assets/static/icons/ai-assistant';
import { BurgerMenuIcon } from '@/assets/static/icons/burger-menu';
import { CloseMenuIcon } from '@/assets/static/icons/close-menu';
import { Banner } from '@/components/basicComponents/banner';
import { TabsTitle } from '@/components/basicComponents/fat-tabs';
import Button from '@/components/fat-basicComponents/button';
import Header from '@/components/fat-header';
import { GoBackButton } from '@/components/fat-header/goBackButton';
import { PageTitle } from '@/components/fat-header/pageTitle';
import { useClickOutside } from '@/hooks/useClickOutside';
import { useGoBack } from '@/hooks/useGoBack';
import { format } from 'date-fns';
import { useParams, useSearchParams } from 'react-router-dom';
import { useResponsive } from '../../../hooks/use-responsive';
import { MainWrap, PaddingWrap } from '../../../styles/common';
import { User } from '../../../types/user';
import { Loader } from '../../loaders/loader';
import MobileModal from '../../mobileModal';
import {
  DUE_DILIGENCE_RECORD_QUERY,
  GET_DUE_DILIGENCE_FUNNEL,
  UPDATE_FIELD_ANSWER_MUTATION,
  UPDATE_RECORD_STATUS_MUTATION,
  UPDATE_TIER_STATUS_MUTATION,
  deleteDueDiligenceRecordAttachment
} from '../queries';
import { AdvancedOptions } from './advanced-options';
import { AiPane } from './ai-pane';
import { DataRoom } from './data-room';
import { EditFieldPage } from './editFieldPage';
import FieldModal from './fieldModal';
import { MobileNotes } from './mobileNotes';
import Notes from './notes';
import { TierCheckList } from './tierCheckList';
import { TierDropdown } from './tierDropdown';
import { TierSection } from './tierSection';
import TierStatus from './tierStatus';
import { IChecklistItem, ISection, ISectionFields, ITier, NoteDataType, RecordStatusEnum, TierStatusEnum } from './types';

export interface IFile extends File {
  investmentId?: string;
  loading?: boolean;
  status?: string;
}

export const DueDiligenceRecord = () => {
  const { isMobile, isTablet } = useResponsive();
  const params = useParams();
  const goBack = useGoBack();
  const theme = useTheme();

  const [searchParams, setSearchParams] = useSearchParams();

  const generateAnswers = searchParams.get('generateAnswers') || '';
  const backToTitle = searchParams.get('backToTitle') || '';

  const [activeTab, setActiveTab] = useState('');
  const [isNotesOpen, setIsNotesOpen] = useState(false);
  const [noteData, setNoteData] = useState<NoteDataType | null>(null);
  const [modalWindow, setModalWindow] = useState({ isOpen: false, type: 'edit-todo' });
  const [currentRow, setCurrentRow] = useState<ISection | IChecklistItem | ITier | ISectionFields | null>(null);
  const [tiers, setTiers] = useState<ITier[]>([]);
  const [recordStatus, setRecordStatusStatus] = useState<RecordStatusEnum | null>(null);
  const [isUpdateActiveTab, setIsUpdateActiveTab] = useState(true);
  const [isBackToLoading, setIsBackToLoading] = useState(false);
  const [editFieldPage, setEditFieldPage] = useState({ isOpen: false, typeWindow: 'default' });
  const [scrollTo, setScrollTo] = useState(0);
  const [isAiPaneOpen, setIsAiPaneOpen] = useState(false);
  const [files, setFiles] = useState<IFile[]>([]);
  const [isDataRoomOpen, setIsDataRoomOpen] = useState(false);
  const [generateAnswersLoading, setGenerateAnswersLoading] = useState(false);
  const [isGenerateAnswersLoadingCompleted, setIsGenerateAnswersLoadingCompleted] = useState(false);
  const [currentField, setCurrentField] = useState<ISectionFields | null>(null);
  const [allFields, setAllFields] = useState<ISectionFields[] | null>(null);
  const [showTabs, setShowTabs] = useState(true);
  const [isTabMenuOpen, setIsTabMenuOpen] = useState(false);
  const [recordName, setRecordName] = useState('');
  const [generateAnswersDealRegistry, setGenerateAnswersDealRegistry] = useState([]);

  const tabsContainerRef = useRef<HTMLDivElement | null>(null);
  const burgerMenuRef = useRef<HTMLDivElement | null>(null);

  useClickOutside<HTMLDivElement>(burgerMenuRef, () => setIsTabMenuOpen(false));

  const { data, loading, error, refetch } = useQuery(DUE_DILIGENCE_RECORD_QUERY, {
    fetchPolicy: 'cache-and-network',
    variables: {
      id: params?.recordId
    }
  });

  useEffect(() => {
    if (data) {
      const fetchData = async () => {
        setRecordName(data.getDueDiligenceRecord.investment.name);
        const { data: funnelData } = await client.query({
          fetchPolicy: 'network-only',
          query: GET_DUE_DILIGENCE_FUNNEL,
          variables: {
            id: data?.getDueDiligenceRecord.funnel.id
          }
        });

        const updatedTiers = data.getDueDiligenceRecord.funnel.tiers.map((oldTier: ITier) => {
          const matchingTier = funnelData.getDueDiligenceFunnel.latestFunnel.tiers.find((tier: ITier) => tier.order === oldTier.order);

          if (matchingTier) {
            return {
              ...oldTier,
              name: matchingTier.name
            };
          }
          return oldTier;
        });

        setTiers(updatedTiers);

        const findActiveTier = updatedTiers.find((tier: ITier) => {
          if (data?.getDueDiligenceRecord.activeTier.order === tier.order) {
            return tier;
          }
        });

        if (findActiveTier && isUpdateActiveTab) {
          setActiveTab(findActiveTier.name);
        }
      };

      fetchData();
    }
  }, [data, isUpdateActiveTab, client]);

  useEffect(() => {
    if (!error && !loading) {
      const fields: ISectionFields[] = [];
      data.getDueDiligenceRecord.funnel.tiers.forEach((tier: ITier) => {
        tier.sections.forEach((section) => {
          fields.push(...section.fields);
        });
      });
      setAllFields(fields);

      if (isUpdateActiveTab) {
        // setActiveTab(data.getDueDiligenceRecord.activeTier.name);
        setIsUpdateActiveTab(false);
      }
      setRecordStatusStatus(data.getDueDiligenceRecord.status);
    }
  }, [data, loading, error]);

  useEffect(() => {
    if (currentField) {
      setEditFieldPage({ ...editFieldPage, typeWindow: currentField.type });
    }
  }, [currentField]);

  useEffect(() => {
    if (editFieldPage.typeWindow === 'default') {
      window.scrollTo(0, scrollTo);
    }
  }, [editFieldPage.typeWindow]);

  useEffect(() => {
    if (!generateAnswers) return;

    setGenerateAnswersDealRegistry(JSON.parse(generateAnswers));
    setIsAiPaneOpen(true);
    setSearchParams(
      (prev) => {
        prev.delete('generateAnswers');
        return prev;
      },
      { replace: true }
    );
  }, []);

  useEffect(() => {
    const checkScreenWidth = () => {
      const tabsContainer = tabsContainerRef.current;

      if (tabsContainer) {
        const tabElements = Array.from(tabsContainer.children) as HTMLDivElement[];

        let totalTabWidth = 0;
        tabElements.forEach((tabElement) => {
          totalTabWidth += tabElement.offsetWidth;
        });

        const screenWidth = window.innerWidth;

        setShowTabs(totalTabWidth <= screenWidth - 200);
      }
    };

    if (tabsContainerRef.current) {
      checkScreenWidth();
    }

    window.addEventListener('resize', checkScreenWidth);

    return () => {
      window.removeEventListener('resize', checkScreenWidth);
    };
  }, [tiers, tabsContainerRef]);

  const [updateFieldAnswer] = useMutation(UPDATE_FIELD_ANSWER_MUTATION, {
    onCompleted: () => {
      setIsUpdateActiveTab(false);
      refetch();
    }
  });

  const [updateTierStatus] = useMutation(UPDATE_TIER_STATUS_MUTATION, {
    onCompleted: () => {
      setIsUpdateActiveTab(false);
      refetch();
    }
  });
  const [updateRecordStatus] = useMutation(UPDATE_RECORD_STATUS_MUTATION, {
    onCompleted: () => {
      setIsUpdateActiveTab(false);
      refetch();
    }
  });

  const [deleteAttachment] = useMutation(deleteDueDiligenceRecordAttachment, {
    onCompleted: () => {
      setIsUpdateActiveTab(false);
      refetch();
    }
  });

  const openNotes = (note: string, updateAt: Date, task: string, assignedBy?: User, assignedTo?: User) => {
    setIsNotesOpen(!isNotesOpen);
    setNoteData({ updateAt, note, task, assignedBy, assignedTo });
  };

  const openModalWindow = (type: string, row: ISection | IChecklistItem | ITier | ISectionFields) => {
    setModalWindow({ ...modalWindow, isOpen: true, type });
    setCurrentRow(row);
  };

  const closeModalWindow = () => setModalWindow({ ...modalWindow, isOpen: false });

  const openEditPage = (type: string, row: ISectionFields) => {
    setScrollTo(window.scrollY);
    setEditFieldPage({ ...editFieldPage, typeWindow: type });
    setCurrentField(row);
    refetch();
  };

  const closeEditPage = () => {
    setEditFieldPage({ ...editFieldPage, typeWindow: 'default' });
    setCurrentField(null);
    refetch();
  };

  const backToPreviousTier = (tierData: ITier) => {
    setIsBackToLoading(true);
    setIsUpdateActiveTab(true);
    updateTierStatus({
      variables: {
        data: {
          id: tierData.data.id,
          status: TierStatusEnum.NOT_STARTED
        }
      },
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: DUE_DILIGENCE_RECORD_QUERY,
          variables: {
            id: params?.recordId
          }
        }
      ],
      onCompleted: () => {
        setIsBackToLoading(false);
      }
    });
  };

  const advanceToNexTier = () => {
    setIsUpdateActiveTab(true);
    window.scrollTo(0, 0);
  };

  const isLastTier = () => tiers[tiers.length - 1].name === activeTab;

  const isFieldValue = () => {
    const currentTier = tiers.filter((tier) => tier.name === activeTab && tier);
    const isSectionCompleted = currentTier[0]?.sections?.filter((item) => !item.data.completed);
    const isCheckListCompleted = currentTier[0]?.checklistItems?.filter((item) => !item.data.completed && item.required);
    return Boolean(isSectionCompleted?.length || isCheckListCompleted?.length);
  };

  const prevTierStatus = () => {
    if (activeTab === tiers[0].name) return null;
    const tierNumber = Number(activeTab[activeTab.length - 1]);
    const prevTier = tiers.filter((tier) => tier.name === `Tier ${tierNumber - 1}` && tier);
    return prevTier[0]?.data.status;
  };

  const totalScore = () => {
    const currentTier = tiers.filter((tier) => tier.name === activeTab && tier);
    return currentTier[0].sections.reduce((accumulator, currentValue) => accumulator + currentValue.data.score, 0);
  };

  const handleGenerateAnswersLoadingChange = (loadingStatus: boolean, showCompleteIcon: boolean) => {
    setGenerateAnswersLoading(loadingStatus);
    setIsGenerateAnswersLoadingCompleted(showCompleteIcon);
  };

  const openAdvancedOptions = () => {
    setIsAiPaneOpen(false);
    setEditFieldPage({ ...editFieldPage, typeWindow: 'advanced-options' });
  };

  const closeAdvancedOptions = () => {
    setIsAiPaneOpen(true);
    setEditFieldPage({ ...editFieldPage, typeWindow: 'default' });
  };

  const changeActiveFunnel = (tab: string) => {
    setActiveTab(tab);
    setIsTabMenuOpen(false);
  };

  if (loading || !tiers.length) {
    return <CustomLoader size={60} />;
  }

  return isDataRoomOpen ? (
    <DataRoom setIsDataRoomOpen={setIsDataRoomOpen} />
  ) : (
    <>
      <DueDiligenceFunnelWrapper>
        {((!isMobile && !isTablet) || !isAiPaneOpen) && (
          <>
            {editFieldPage.typeWindow === 'advanced-options' && (
              <AdvancedOptions
                handleClose={closeAdvancedOptions}
                dueDiligenceRecordsRefetch={refetch}
                files={files}
                setFiles={setFiles}
                handleGenerateAnswersLoadingChange={handleGenerateAnswersLoadingChange}
                generateAnswersDealRegistry={generateAnswersDealRegistry}
                dueDiligenceRecord={data?.getDueDiligenceRecord}
              />
            )}
            {editFieldPage.typeWindow !== 'default' && editFieldPage.typeWindow !== 'advanced-options' && (
              <EditFieldPage
                handleClose={closeEditPage}
                allFields={allFields}
                currentField={currentField}
                setIsAiPaneOpen={setIsAiPaneOpen}
                setCurrentField={setCurrentField}
                investmentId={data?.getDueDiligenceRecord.investment.id ?? null}
                editFieldPage={editFieldPage}
                updateFieldAnswer={updateFieldAnswer}
                deleteAttachment={deleteAttachment}
                recordId={params?.recordId}
                refetch={refetch}
              />
            )}
            {editFieldPage.typeWindow === 'default' && (
              <>
                {isMobile && isNotesOpen && (
                  <MobileModal onClose={() => setIsNotesOpen(false)} title="Notes" onClick={() => null}>
                    <MobileNotes data={noteData} />
                  </MobileModal>
                )}
                <FieldModal
                  modalWindow={modalWindow}
                  closeModalWindow={closeModalWindow}
                  currentRow={currentRow}
                  updateTierStatus={updateTierStatus}
                  updateRecordStatus={updateRecordStatus}
                  recordId={params?.recordId}
                  advanceToNexTier={advanceToNexTier}
                  owner={data?.getDueDiligenceRecord.owner}
                  backToPreviousTier={backToPreviousTier}
                  activeTab={activeTab}
                  openEditPage={openEditPage}
                />
                <MainWrap>
                  <Header modalControl={<GoBackButton handleClose={() => goBack({ fallBack: '/dueDiligence' })} backToTitle={backToTitle} />} />
                  <PageTitle title={recordName} />
                  {recordStatus === RecordStatusEnum.APPROVED && (
                    <Banner
                      title={`Approved By ${data?.getDueDiligenceRecord.approver?.firstName} ${data?.getDueDiligenceRecord.approver
                        ?.lastName} on ${format(new Date(data?.getDueDiligenceRecord.statusUpdatedAt), 'LLL d, yyyy')} `}
                      description=""
                      icon={<ApprovedIcon fill={theme.context.light} />}
                      bgColor={theme.context.success}
                      color={theme.context.light}
                    />
                  )}
                  {recordStatus === RecordStatusEnum.REJECTED && (
                    <Banner
                      title={`Rejected By ${data?.getDueDiligenceRecord.approver?.firstName} ${data?.getDueDiligenceRecord.approver
                        ?.lastName} on ${format(new Date(data?.getDueDiligenceRecord.statusUpdatedAt), 'LLL d, yyyy')} `}
                      description={data?.getDueDiligenceRecord.note}
                      icon={<RejectedBannerIcon fill={theme.context.light} />}
                      bgColor={theme.context.error}
                      color={theme.context.light}
                    />
                  )}
                  <PaddingWrap>
                    <Notes onClick={() => setIsNotesOpen(false)} isOpen={isNotesOpen} data={noteData} />
                    <TabsWrapper isMobile={isMobile}>
                      {showTabs ? (
                        <Tabs isMobile={isMobile} ref={tabsContainerRef}>
                          {tiers.map((item, index) => (
                            <TabsTitle key={item.id} onClick={() => setActiveTab(item.name)} activeTab={activeTab === item.name}>
                              {item.name}
                            </TabsTitle>
                          ))}
                        </Tabs>
                      ) : (
                        <BurgerMenuWrapper isMobile={isMobile} ref={burgerMenuRef}>
                          <ActiveTabWrapper>
                            {isTabMenuOpen ? (
                              <CloseMenuIcon onClick={() => setIsTabMenuOpen(!isTabMenuOpen)} style={{ cursor: 'pointer' }} />
                            ) : (
                              <BurgerMenuIcon onClick={() => setIsTabMenuOpen(!isTabMenuOpen)} style={{ cursor: 'pointer' }} />
                            )}
                            <ActiveTab activeTab>{activeTab}</ActiveTab>
                          </ActiveTabWrapper>
                          {isTabMenuOpen && (
                            <BurgerMenu isMobile={isMobile}>
                              {tiers.map((item) => (
                                <MenuItem key={item.id} onClick={() => changeActiveFunnel(item.name)} activeTab={activeTab === item.name}>
                                  {item.name}
                                </MenuItem>
                              ))}
                            </BurgerMenu>
                          )}
                        </BurgerMenuWrapper>
                      )}

                      {/* <ButtonsWrap> */}
                      {/* <AssistantButton isMobile={isMobile} onClick={() => setIsDataRoomOpen(true)}>
                        <DataRoomIcon />
                        Data Room
                      </AssistantButton> */}
                      <AiAssistantButton
                        isMobile={isMobile}
                        styleType="outline"
                        onClick={() => setIsAiPaneOpen(!isAiPaneOpen)}
                        size="md"
                        icon={<AiAssistantIcon fill={theme.font.action} />}
                        secondaryIcon={isGenerateAnswersLoadingCompleted && <ApprovedIcon width={20} height={20} fill={theme.context.success} />}
                        isLoading={generateAnswersLoading}
                      >
                        AI Assistant
                      </AiAssistantButton>
                      {/* </ButtonsWrap> */}
                    </TabsWrapper>
                    <TabsLine />
                    {tiers.map((tier) => (
                      <TierWrapper key={tier.id}>
                        {tier.name === activeTab && (
                          <>
                            <TierStatusWrapper isMobile={isMobile}>
                              <TierStatus
                                tierData={tier}
                                activeTab={activeTab}
                                firstTierName={tiers[0].name}
                                openModalWindow={openModalWindow}
                                updateRecordStatus={updateRecordStatus}
                                updateTierStatus={updateTierStatus}
                                recordId={params?.recordId}
                                isAllDone={!isFieldValue()}
                                isLastTier={isLastTier()}
                                recordStatus={recordStatus}
                                loading={isBackToLoading}
                              />
                              {tier.sections.filter((section) => section.userCanScore).length ? (
                                <TotalScore>
                                  Total Score
                                  {totalScore() === 0 && (
                                    <CountWrap>
                                      <Count>{totalScore()}</Count>
                                    </CountWrap>
                                  )}
                                  {totalScore() > 0 && (
                                    <CountWrap color={theme.context.success}>
                                      <Count>+{totalScore()}</Count>
                                    </CountWrap>
                                  )}
                                  {totalScore() < 0 && (
                                    <CountWrap color={theme.context.error}>
                                      <Count>{totalScore()}</Count>
                                    </CountWrap>
                                  )}
                                </TotalScore>
                              ) : (
                                <></>
                              )}
                            </TierStatusWrapper>
                            <TierDropdownWrapper>
                              {tier.sections.map((section) => (
                                <TierDropdown
                                  key={section.id}
                                  title={section.name}
                                  openNotes={openNotes}
                                  openModalWindow={openModalWindow}
                                  sectionData={section}
                                  tierStatus={tier.data.status}
                                  owner={data?.getDueDiligenceRecord.owner}
                                  prevTierStatus={prevTierStatus()}
                                  isBackToLoading={isBackToLoading}
                                  recordStatus={recordStatus}
                                >
                                  <TierSection
                                    sectionData={section}
                                    prevTierStatus={prevTierStatus()}
                                    openEditPage={openEditPage}
                                    recordStatus={recordStatus}
                                    openModalWindow={openModalWindow}
                                  />
                                </TierDropdown>
                              ))}
                              {tier.checklistItems.length ? (
                                <TierDropdown title={`${activeTab}: Checklist`} checkListData={tier.checklistItems}>
                                  <TierCheckList
                                    recordStatus={recordStatus}
                                    tierStatus={tier.data.status}
                                    checkListData={tier.checklistItems}
                                    openModalWindow={openModalWindow}
                                    openNotes={openNotes}
                                    owner={data?.getDueDiligenceRecord.owner}
                                    prevTierStatus={prevTierStatus()}
                                    isBackToLoading={isBackToLoading}
                                  />
                                </TierDropdown>
                              ) : (
                                <></>
                              )}
                            </TierDropdownWrapper>
                            <ButtonWrapper>
                              <Button onClick={() => setModalWindow({ isOpen: true, type: 'download-pdf' })} styleType="outline" size="lg">
                                Download
                              </Button>
                              {tier.data.status === TierStatusEnum.IN_PROGRESS && (
                                <>
                                  <Button
                                    onClick={() => openModalWindow('reject-investment', tier)}
                                    styleType={isFieldValue() ? 'disabled' : 'outline'}
                                    size="lg"
                                  >
                                    Reject
                                  </Button>
                                  {isLastTier() ? (
                                    <Button
                                      onClick={() => openModalWindow('approve-diligence', tier)}
                                      styleType={isFieldValue() ? 'disabled' : 'default'}
                                      size="lg"
                                    >
                                      Approve Diligence
                                    </Button>
                                  ) : (
                                    <Button
                                      onClick={() => openModalWindow('advance-to', tier)}
                                      styleType={isFieldValue() ? 'disabled' : 'default'}
                                      size="lg"
                                    >
                                      Advance To Next Tier
                                    </Button>
                                  )}
                                </>
                              )}
                            </ButtonWrapper>
                          </>
                        )}
                      </TierWrapper>
                    ))}
                  </PaddingWrap>
                </MainWrap>
              </>
            )}
          </>
        )}
        <AiPane
          dueDiligenceRecord={data?.getDueDiligenceRecord}
          dueDiligenceRecordsRefetch={refetch}
          files={files}
          setFiles={setFiles}
          isAiPaneOpen={isAiPaneOpen}
          onClose={() => setIsAiPaneOpen(false)}
          handleGenerateAnswersLoadingChange={handleGenerateAnswersLoadingChange}
          generateAnswersDealRegistry={generateAnswersDealRegistry}
          freeChatOnly={editFieldPage.typeWindow !== 'default' && editFieldPage.typeWindow !== 'advanced-options'}
          openAdvancedOptions={openAdvancedOptions}
        />
      </DueDiligenceFunnelWrapper>
    </>
  );
};

const CustomLoader = styled(Loader)`
  height: 100%;
  align-items: center;
`;

const AiAssistantButton = styled(Button)<{ isMobile: boolean }>`
  order: ${({ isMobile }) => (isMobile ? 1 : 2)};
  &:hover {
    svg {
      path {
        fill: ${({ theme }) => theme.context.light};
      }
    }
  }
`;

const TabsWrapper = styled(PaddingWrap)<{ isMobile: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};
  margin: 10px -24px 0 -24px;
`;

const Tabs = styled.div<{ isMobile: boolean }>`
  padding-top: 10px;
  display: flex;
  justify-content: ${({ isMobile }) => (isMobile ? 'center' : 'flex-start')};
  gap: 5px;
  order: ${({ isMobile }) => (isMobile ? 2 : 1)};
`;

const TabsLine = styled.div`
  border-bottom: 1px ${({ theme }) => theme.border.base} solid;
  margin-left: -50px;
  margin-right: -50px;
`;

const BurgerMenuWrapper = styled.div<{ isMobile: boolean }>`
  margin-top: 15px;
  position: relative;
  width: ${({ isMobile }) => (isMobile ? 'auto' : '70%')};
  order: ${({ isMobile }) => (isMobile ? 2 : 1)};
`;

const ActiveTabWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 15px;
`;

const ActiveTab = styled(TabsTitle)`
  width: max-content;
  margin: 0;
  cursor: auto;
`;

const BurgerMenu = styled.div<{ isMobile: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: absolute;
  left: ${({ isMobile }) => (isMobile ? '-60px' : '0')};
  top: 60px;
  z-index: 10;
  background: ${({ theme }) => theme.layer[1]};
  min-width: 300px;
  width: ${({ isMobile }) => (isMobile ? '100%' : 'auto')};
`;

const MenuItem = styled(TabsTitle)`
  margin: 0;
  border-radius: 0;
  padding: 10px 20px;
`;

const TierWrapper = styled.div``;

const TierStatusWrapper = styled.div<{ isMobile: boolean }>`
  display: flex;
  justify-content: space-between;
  flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};
  align-items: ${({ isMobile }) => (isMobile ? 'start' : 'center')};
  gap: ${({ isMobile }) => (isMobile ? '10px' : '0')};
  padding: ${({ isMobile }) => (isMobile ? '30px 23.5px 20px 23.5px' : '30px 129px 20px 23.5px')};
`;

const TotalScore = styled.div`
  display: flex;
  gap: 10px;
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  color: ${({ theme }) => theme.font.weak};
`;

const CountWrap = styled.div<{ color?: string }>`
  background-color: ${({ color, theme }) => (color ? color : theme.layer[1])};
  display: inline-block;
  border-radius: 12px;
  color: ${({ color, theme }) => (color ? theme.context.light : theme.font.strong)};
  padding: 1px 11px;
  font-size: 13px;
  margin-right: 6px;
`;

const Count = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  font-weight: 700;
`;

const TierDropdownWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
  padding: 30px 0 20px 0;
`;

const DueDiligenceFunnelWrapper = styled.div`
  display: flex;
  justify-content: center;
  transition: all 0.3s;
`;
