import { InfoIcon } from '@/assets/icons/info-icons/info';
import { Banner } from '@/components/basicComponents/banner';
import Input from '@/components/fat-basicComponents/input';
import {
  formatPercentageInputValue,
  handleCursorPositionInPercentageInput,
  validatePercentageInputValue
} from '@/components/fat-basicComponents/input/utils';
import FooterModal from '@/components/fat-settings-page/importData/footer';
import { ProgressBar } from '@/components/fat-settings-page/importData/progressBar';
import { ImportWrapper } from '@/components/fat-settings-page/importData/styles';
import { useResponsive } from '@/hooks/use-responsive';
import { Control, Controller, UseFormTrigger } from 'react-hook-form';
import styled, { useTheme } from 'styled-components';
import { ICreateAutoPlanForm } from '..';

interface ForecastOptionsProps {
  autoPlanWizardSteps: { name: string; status: string }[];
  control: Control<ICreateAutoPlanForm, any>;
  trigger: UseFormTrigger<ICreateAutoPlanForm>;
  startOver: () => void;
  nextStep: () => void;
}

export const ForecastOptions = ({ autoPlanWizardSteps, control, trigger, startOver, nextStep }: ForecastOptionsProps) => {
  const { isMobile } = useResponsive();
  const theme = useTheme();

  const moveToTheNextStep = async () => {
    const isGrowthRateValid = await trigger('growthRate');
    if (!isGrowthRateValid) return;

    nextStep();
  };

  return (
    <>
      <StepWrapper isMobile={isMobile}>
        <ProgressBar importSteps={autoPlanWizardSteps} />
        <StepDetailsWrapper>
          <StepTitle>Forecast Options</StepTitle>
          <StepDescription>
            When the investor’s portfolio is growing more quickly, larger commitments are required to meet the investor’s allocation targets. Control
            those assumptions here.
          </StepDescription>
        </StepDetailsWrapper>
        <StepBodyWrapper isMobile={isMobile}>
          <FieldWrapper isMobile={isMobile}>
            <Controller
              name="growthRate"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: 'Growth Rate ex. Drawdowns is required'
                },
                validate: validatePercentageInputValue
              }}
              render={({ field: { value, onChange, onBlur }, fieldState: { error, invalid } }) => (
                <Input
                  label="Growth Rate ex. Drawdowns"
                  value={value}
                  onChange={(e) => {
                    const formattedValue = formatPercentageInputValue(e);
                    onChange(formattedValue);
                  }}
                  onClick={handleCursorPositionInPercentageInput}
                  onKeyUp={handleCursorPositionInPercentageInput}
                  onBlur={onBlur}
                  errorText={error?.message}
                  isRequiredField
                />
              )}
            />
            <Banner
              title=""
              description="Provide a blended growth rate that applies to the investor’s public portfolio and any evergreen private capital funds."
              icon={<InfoIcon width={26} height={26} fill={theme.header.base} />}
              bgColor={theme.layer[1]}
              color={theme.font.base}
            />
          </FieldWrapper>
        </StepBodyWrapper>
      </StepWrapper>
      <FooterModal startOver={startOver} confirmButton={{ name: 'Continue', onClick: moveToTheNextStep, disabled: false }} />
    </>
  );
};

const StepWrapper = styled(ImportWrapper)<{ isMobile: boolean }>`
  margin-bottom: 150px;
  padding: ${({ isMobile }) => (isMobile ? '20px 30px' : '40px 60px')};
`;

const StepDetailsWrapper = styled.div``;

const StepTitle = styled.p`
  font-size: 20px;
  font-weight: 700;
  line-height: 22.4px;
  text-align: center;
  margin-top: 10px;
  color: ${({ theme }) => theme.font.base};
`;

const StepDescription = styled.p`
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;
  text-align: center;
  padding: 10px;
  color: ${({ theme }) => theme.font.weak};
`;

const StepBodyWrapper = styled.div<{ isMobile: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: ${({ isMobile }) => (isMobile ? '10px' : '40px')};
`;

const FieldWrapper = styled.div<{ isMobile: boolean }>`
  display: grid;
  grid-template-columns: ${({ isMobile }) => (isMobile ? '1fr' : '1fr 1fr')};
  gap: 10px;

  & > *:nth-child(1) {
    order: ${({ isMobile }) => (isMobile ? 2 : 1)};
  }

  & > *:nth-child(2) {
    order: ${({ isMobile }) => (isMobile ? 1 : 2)};
  }
`;
