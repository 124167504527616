import { MinusScoreIcon } from '@/assets/icons/minusScore';
import { getUTCDate } from '@/components/allocations-page/utils';
import BasicTooltip from '@/components/basicComponents/tooltip';
import FormattingTooltip from '@/components/basicComponents/tooltip/dollarsTooltip';
import Button from '@/components/fat-basicComponents/button';
import NoResults from '@/components/table/noResults';
import RedirectTitleCell from '@/components/table/redirectTitleCell';
import guid from '@/utils/guid';
import styled, { useTheme } from 'styled-components';
import { TableRowCell, TableRowWrapper, TextCell } from '../../table/styles';
import { opportunitiesTableColumns } from '../constants';
import { Investment, TypeWindow } from '../types';

interface OpenOpportunitiesTableBodyProps {
  openOpportunitiesData: Investment[];
  openPage: (id: string, type: TypeWindow) => void;
}

const TableBody = ({ openOpportunitiesData, openPage }: OpenOpportunitiesTableBodyProps) => {
  const theme = useTheme();

  if (!openOpportunitiesData?.length) {
    return <NoResults />;
  }

  return (
    <>
      {openOpportunitiesData.map((row) => (
        <TableRowWrapper key={guid()}>
          <TableRowCell width={opportunitiesTableColumns[0].width}>
            <RedirectTitleCell
              id="actionCurioText"
              onClick={() => openPage(row.id, row.securityStatus === 'Active' ? 'opportunityDetails' : 'allocator')}
            >
              {row.name}
            </RedirectTitleCell>
          </TableRowCell>
          <TableRowCell width={opportunitiesTableColumns[1].width}>
            <TextCell>{row.subAssetClass ? `${row.assetClass.name} - ${row.subAssetClass.name}` : row.assetClass.name}</TextCell>
          </TableRowCell>
          <TableRowCell width={opportunitiesTableColumns[2].width}>
            <TextCell>
              {row?.subAssetClasses?.length > 1 ? (
                <BasicTooltip tooltipContent={row.subAssetClasses.toString().replace(/,/g, ', ')} zIndex={1000}>
                  Multiple ({row.subAssetClasses.length})
                </BasicTooltip>
              ) : (
                row?.subAssetClasses && row?.subAssetClasses[0]
              )}
            </TextCell>
          </TableRowCell>
          <TableRowCell width={opportunitiesTableColumns[3].width}>
            <TextCell>{row.type}</TextCell>
          </TableRowCell>
          <TableRowCell width={opportunitiesTableColumns[4].width}>
            <TextCell>{row.investorDocsDue && getUTCDate(new Date(row.investorDocsDue))}</TextCell>
          </TableRowCell>
          <TableRowCell width={opportunitiesTableColumns[5].width}>
            <TextCell>
              {row.aggregateCommitment ? (
                <FormattingTooltip zIndex={1000} key={guid()}>
                  {row.aggregateCommitment}
                </FormattingTooltip>
              ) : (
                <></>
              )}
            </TextCell>
          </TableRowCell>
          <TableRowCell width={opportunitiesTableColumns[6].width}>
            {row.securityStatus === 'Open' && (
              <ButtonWrap id="infoWrap">
                <Button size="sm" onClick={() => openPage(row.id, 'allocator')}>
                  Allocate
                </Button>
              </ButtonWrap>
            )}
          </TableRowCell>
          <TableRowCell width={opportunitiesTableColumns[7].width}>
            {row.securityStatus !== 'Open' && (
              <TooltipWrapper>
                <BasicTooltip tooltipContent="Closed" zIndex={1000}>
                  <MinusScoreIcon width={29} height={29} fill={theme.font.disabled} />
                </BasicTooltip>
              </TooltipWrapper>
            )}
          </TableRowCell>
        </TableRowWrapper>
      ))}
    </>
  );
};

export default TableBody;

const ButtonWrap = styled.div`
  opacity: 0;
`;

const TooltipWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  top: 3px;
`;
