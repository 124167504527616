import { UPDATE_INVESTMENT } from '@/components/dueDiligence-page/queries';
import ModalWrappedContent from '@/components/fat-basicComponents/modal/modalWrappedContent';
import { IInvestmentWorkflowTransitionTask, Investment } from '@/components/fat-dealCatalog-page/types';
import { useMutation } from '@apollo/client';
import styled from 'styled-components';
import { updateInvestmentWorkflowTransitionTaskMutation } from '../queries';

interface CloseFundProps {
  onClose: () => void;
  isOpen: boolean;
  currentRow: Investment | null;
  refetch: () => void;
  statuses: IInvestmentWorkflowTransitionTask[];
}

const CloseFund = ({ onClose, isOpen, currentRow, refetch, statuses }: CloseFundProps) => {
  if (!currentRow) return <></>;

  const [updateInvestmentWorkflowTransitionTask] = useMutation(updateInvestmentWorkflowTransitionTaskMutation);
  const [updateInvestment] = useMutation(UPDATE_INVESTMENT);

  const saveValue = async () => {
    const currentStatusIndex = statuses.findIndex((status) => status.id === currentRow.investmentWorkflowTransitionTask.id);
    const newStatus = statuses[currentStatusIndex + 1];

    await updateInvestmentWorkflowTransitionTask({
      variables: {
        data: {
          investmentId: currentRow.id,
          workflowTaskId: newStatus.id
        }
      }
    });

    await updateInvestment({
      variables: {
        data: {
          id: currentRow.id,
          securityStatus: 'ACTIVE',
          nextClose: currentRow.nextClose,
          finalClose: currentRow.finalClose
        }
      },
      onCompleted: () => {
        refetch();
        onClose();
      }
    });
  };

  return (
    <ModalWrappedContent
      isOpen={isOpen}
      onClose={onClose}
      title={`Please confirm you want to close ${currentRow.name}`}
      confirmButton={{
        name: 'Confirm',
        onClick: saveValue
      }}
    >
      <ModalBody>
        <Text>This action will close this fund to new allocations.</Text>
        <Text>
          <CountWrap>
            <Count>{currentRow.pendingCommitmentCount}</Count>
          </CountWrap>
          Commitments are still pending.
        </Text>
        <Text>You can complete or terminate these commitments under the Pending Commitments Tab.</Text>
      </ModalBody>
    </ModalWrappedContent>
  );
};

export default CloseFund;

const ModalBody = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
`;

const Text = styled.span`
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;
  color: ${({ theme }) => theme.font.base};
`;

const CountWrap = styled.div`
  background-color: ${({ theme }) => theme.footer.base};
  display: inline-block;
  border-radius: 12px;
  color: ${({ theme }) => theme.context.light};
  width: 30px;
  font-size: 13px;
  margin-right: 6px;
`;

const Count = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 13px;
  font-weight: 700;
  line-height: 18.2px;
`;
