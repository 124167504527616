import * as React from 'react';

export const EditIcon = ({ ...props }: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={props.width || 19}
      height={props.height || 18}
      style={props?.style}
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.0002 1.29429L14.924 0.378317C15.0437 0.258382 15.1858 0.163233 15.3423 0.0983103C15.4989 0.0333881 15.6666 0 15.8361 0C16.0055 0 16.1733 0.0333881 16.3298 0.0983103C16.4863 0.163233 16.6285 0.258382 16.7482 0.378317L17.6641 1.29429C17.7841 1.41398 17.8792 1.55618 17.9441 1.71268C18.0091 1.86919 18.0425 2.03698 18.0425 2.20642C18.0425 2.37586 18.0091 2.54364 17.9441 2.70015C17.8792 2.85666 17.7841 2.99881 17.6641 3.1185L16.7482 4.04224L14.0002 1.29429ZM13.0843 2.21802L6.22998 9.07232V11.8125H8.97015L15.8244 4.95821L13.0843 2.21802Z"
        fill={props.fill || '#4587EC'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.5343 3.00373H1.91562V16.1269H15.0387V7.50171L16.9175 5.6143V16.1269C16.916 16.6242 16.7174 17.1006 16.3652 17.4517C16.0131 17.8028 15.5361 18 15.0387 18H1.91562C1.41929 17.9985 0.943716 17.8007 0.592758 17.4497C0.2418 17.0988 0.0439643 16.6232 0.0424805 16.1269V3.00373C0.0424782 2.50643 0.239649 2.02941 0.590767 1.67723C0.941885 1.32506 1.41831 1.12649 1.91562 1.125H12.4056L10.5343 3.00373Z"
        fill={props.fill || '#4587EC'}
      />
    </svg>
  );
};
