import ModalWrappedContent from '@/components/basicComponents/modal/modalWrappedContent';
import styled from 'styled-components';

interface SubmitToProps {
  onSubmit: () => void;
  isOpen: boolean;
  onClose: () => void;
}

const CancelChanges = ({ isOpen, onClose, onSubmit }: SubmitToProps) => {
  return (
    <ModalWrappedContent
      background
      isOpen={isOpen}
      onClose={onClose}
      title={'Cancel Changes'}
      confirmButton={{ name: 'Confirm', onClick: onSubmit }}
    >
      <ModalBody>
        <ModalText>
          Exit My Task will revert changes that you have not submitted to the
          next step. Do you want to proceed?
        </ModalText>
      </ModalBody>
    </ModalWrappedContent>
  );
};

const ModalBody = styled.div`
  text-align: center;
`;
const ModalText = styled.div<{ bold?: boolean }>`
  display: inline-block;
  text-align: center;
  font-size: 19px;
  font-weight: ${({ bold }) => (bold ? 600 : 400)};
  margin-left: ${({ bold }) => (bold ? '3px' : 0)};,
`;

export default CancelChanges;
