import ModalWrappedContent from '@/components/fat-basicComponents/modal/modalWrappedContent';
import { useGoBack } from '@/hooks/useGoBack';
import styled from 'styled-components';

interface DiscardChangesProps {
  onClose: () => void;
  isOpen: boolean;
}

const DiscardChanges = ({ onClose, isOpen }: DiscardChangesProps) => {
  const goBack = useGoBack();

  const onConfirm = () => {
    goBack({ fallBack: '/investors' });
  };

  return (
    <ModalWrappedContent
      isOpen={isOpen}
      onClose={onClose}
      confirmButton={{
        name: 'Confirm',
        onClick: onConfirm
      }}
    >
      <ModalBody>
        <ModalTitle>Discard Changes</ModalTitle>
        <SubTitle>Your changes will be lost if you cancel. Please confirm.</SubTitle>
      </ModalBody>
    </ModalWrappedContent>
  );
};

export default DiscardChanges;

const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 30px 0;
`;

const ModalTitle = styled.h2`
  text-align: center;
  color: ${({ theme }) => theme.font.strong};
  font-size: 33px;
  font-weight: 400;
  line-height: 46.2px;
`;

const SubTitle = styled.p`
  text-align: center;
  color: ${({ theme }) => theme.font.base};
  font-size: 19px;
  font-weight: 400;
  line-height: 26.6px;
`;
