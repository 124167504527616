import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import TableHeader from '@/components/table/fat-tableHeader';
import { useResponsive } from '../../../hooks/use-responsive';
import { IPaginationValues } from '../../clients-page/types';
import { IRecordData } from '../../dueDiligence-page/types';
import { Loader } from '../../loaders/loader';
import { TableWrapper } from '../../table/styles';
import TablePagination from '../../table/tablePagination';
import { ISort } from '../../table/types';
import { dueDiligenceColumns } from './../constatnts';
import { TableBody } from './tableBody';
import MobileTable from './tableMobile';

type TableDueDiligenceProps = {
  dueDiligenceRows: IRecordData[];
  savedSort?: ISort;
  savedSetSort?: (value: ISort) => void;
  refetch: any;
  loading: boolean;
  saveScrollPostion: () => void;
  paginationValues: IPaginationValues;
  savePagination: (limit: number, offset: number) => void;
};

const TableDueDiligence = ({
  dueDiligenceRows,
  savedSort,
  savedSetSort,
  refetch,
  loading,
  saveScrollPostion,
  paginationValues,
  savePagination
}: TableDueDiligenceProps) => {
  const { isMobile, isTablet } = useResponsive();
  const navigate = useNavigate();

  const handleClickInvestment = (row: IRecordData) => {
    saveScrollPostion();

    navigate(`/dueDiligence/${row.id}?backToTitle=Compliance`);
  };

  return (
    <TableWrapper>
      {isMobile ? (
        <>
          {loading ? (
            <Loader />
          ) : (
            <>
              <MobileTable columns={dueDiligenceColumns} dueDiligenceRows={dueDiligenceRows} handleClickInvestment={handleClickInvestment} />
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <TablePagination savePagination={savePagination} paginationValues={paginationValues} refetch={refetch} />
              </div>
            </>
          )}
        </>
      ) : (
        <>
          <CustomTableHeader
            isTablet={isTablet}
            savedSort={savedSort}
            savedSetSort={savedSetSort}
            columns={dueDiligenceColumns}
            refetch={(): void => null}
          />
          {loading ? (
            <Loader />
          ) : (
            <>
              <TableBody dueDiligenceRows={dueDiligenceRows} handleClickInvestment={handleClickInvestment} />
              <PaginationWrap>
                <TablePagination paginationValues={paginationValues} refetch={refetch} savePagination={savePagination} />
              </PaginationWrap>
            </>
          )}
        </>
      )}
    </TableWrapper>
  );
};

const PaginationWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

const CustomTableHeader = styled(TableHeader)<{ isTablet: boolean }>`
  position: sticky;
  top: ${({ isTablet }) => (isTablet ? '130px' : '80px')};
  z-index: 8;
  padding-top: 0;
`;

export default TableDueDiligence;
