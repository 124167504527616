import { EditIcon } from '@/assets/icons/edit';
import { AddButtonIcon } from '@/assets/static/icons/add-button-icon';
import { DeleteItemIcon } from '@/assets/static/icons/delete-item';
import { INotesData } from '@/components/client-details/types';
import Button from '@/components/fat-basicComponents/button';
import guid from '@/utils/guid';
import { format } from 'date-fns';
import React from 'react';
import styled, { useTheme } from 'styled-components';

interface InfoListClientProps {
  data: INotesData[] | undefined;
  entityName: string;
  openNoteModalWindow: (type: string, note?: INotesData) => void;
}

export const MobileNotes = ({ data, entityName, openNoteModalWindow }: InfoListClientProps) => {
  const theme = useTheme();

  return (
    <MoreDataWrap id="modal">
      <NotesBody id="notes">
        {data &&
          data
            .sort((a, b) => {
              const dateA = a.notesDate ? new Date(a.notesDate).getTime() : 0;
              const dateB = b.notesDate ? new Date(b.notesDate).getTime() : 0;
              return dateB - dateA;
            })
            .map((el) => (
              <React.Fragment key={guid()}>
                <NoteItem id="notes">
                  <NoteItemTitleWrap>
                    <NoteItemTitle id="notes">{entityName}</NoteItemTitle>
                    <ActionIconsWrap>
                      <EditIcon fill={theme.action.primary} onClick={() => openNoteModalWindow('edit-note', el)} />
                      <DeleteItemIcon fill={theme.action.primary} width={28} height={28} onClick={() => openNoteModalWindow('delete-note', el)} />
                    </ActionIconsWrap>
                  </NoteItemTitleWrap>
                  <NoteItemDate id="notes">{el.notesDate ? format(new Date(el.notesDate), 'LLL d, yyyy') : 'No date'}</NoteItemDate>
                  <NoteItemDesc id="notes">{el.notes}</NoteItemDesc>
                </NoteItem>
                <Divider />
              </React.Fragment>
            ))}
        <AddButtonWrapper>
          <Button size="md" onClick={() => openNoteModalWindow('edit-note')} icon={<AddButtonIcon />}>
            Add New Note
          </Button>
        </AddButtonWrapper>
      </NotesBody>
    </MoreDataWrap>
  );
};

const MoreDataWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3px;
`;

const Divider = styled.div`
  width: 100%;
  min-height: 1px;
  background: ${({ theme }) => theme.border.base};
  margin: 5px 0;
`;

const AddButtonWrapper = styled.div`
  margin-top: 10px;
`;

const NoteItemDesc = styled.div`
  font-family: Blinker, serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  color: ${({ theme }) => theme.font.base};
  margin-top: 10px;
`;

const NoteItemDate = styled.div`
  font-family: Blinker, serif;
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  color: ${({ theme }) => theme.font.weak};
`;

const NoteItemTitle = styled.div`
  font-family: Blinker, serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 18px;
  color: ${({ theme }) => theme.font.base};
`;

const NoteItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  transition: 0.3s;
  padding: 30px 20px;
  border-radius: 8px;

  &:hover {
    background: ${({ theme }) => theme.layer[2]};
  }
`;

const NotesBody = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
`;

const NoteItemTitleWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ActionIconsWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  cursor: pointer;
`;
