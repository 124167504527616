import FormattingTooltip from '@/components/basicComponents/tooltip/dollarsTooltip';
import DropdownActions from '@/components/table/fat-dropdownActions';
import NoResults from '@/components/table/noResults';
import RedirectTitleCell from '@/components/table/redirectTitleCell';
import { TableRowCell, TableRowWrapper, TextCell } from '@/components/table/styles';
import { format } from 'date-fns';
import { IInvestmentEntityByAdvisoryFirm } from '../../types';
import { advisorColumns } from '../constatnts';

interface TableBodyAdvisorProps {
  listInvestmentEntityByAdvisoryFirm: IInvestmentEntityByAdvisoryFirm[];
  openAdvisorFirmDetails: (id: string) => void;
}

const TableBodyAdvisor = ({ listInvestmentEntityByAdvisoryFirm, openAdvisorFirmDetails }: TableBodyAdvisorProps) => {
  if (!listInvestmentEntityByAdvisoryFirm?.length) {
    return <NoResults />;
  }

  return (
    <>
      {listInvestmentEntityByAdvisoryFirm.map((row) => (
        <TableRowWrapper key={row.id}>
          <TableRowCell width={advisorColumns[0].width}>
            <RedirectTitleCell id="actionCurioText" onClick={() => openAdvisorFirmDetails(row.id)}>
              {row.name}
            </RedirectTitleCell>
          </TableRowCell>
          <TableRowCell width={advisorColumns[1].width}>
            <TextCell>
              {row.estimatedTaxableCommitment ? <FormattingTooltip zIndex={1000}>{row.estimatedTaxableCommitment}</FormattingTooltip> : <></>}
            </TextCell>
          </TableRowCell>
          <TableRowCell width={advisorColumns[2].width}>
            <TextCell>
              {row.estimatedExemptCommitment ? <FormattingTooltip zIndex={1000}>{row.estimatedExemptCommitment}</FormattingTooltip> : <></>}
            </TextCell>
          </TableRowCell>
          <TableRowCell width={advisorColumns[3].width}>
            <TextCell>
              <FormattingTooltip zIndex={1000}>{row.estimatedCommitment}</FormattingTooltip>
            </TextCell>
          </TableRowCell>
          <TableRowCell width={advisorColumns[4].width}>
            <TextCell>{row.investorCount}</TextCell>
          </TableRowCell>
          <TableRowCell width={advisorColumns[5].width}>
            <TextCell>{row.updatedAt && format(new Date(row.updatedAt), 'LLL d, yyyy')}</TextCell>
          </TableRowCell>
          <TableRowCell width={advisorColumns[6].width}>
            <DropdownActions
              items={[
                {
                  name: 'View Investors',
                  onClick: () => openAdvisorFirmDetails(row.id)
                }
              ]}
            />
          </TableRowCell>
        </TableRowWrapper>
      ))}
    </>
  );
};

export default TableBodyAdvisor;
