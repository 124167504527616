import { gql } from '@apollo/client';

export const LIST_TENANTS = gql`
  query allAdvisoryFirmList($data: AdvisoryFirmListDto) {
    allAdvisoryFirmList(data: $data) {
      id
      name
      type
      deletedAt
      primaryAdmin {
        id
        firstName
        lastName
        email
      }
    }
  }
`;

export const CREATE_TENANT = gql`
  mutation createTenant($data: CreateTenantDto!) {
    createTenant (data: $data) {
      id
      name
      type
      deletedAt
      primaryAdmin {
        id
        firstName
        lastName
        email
      }
    }
  }
`;

export const EDIT_TENANT = gql`
  mutation updateTenant($data: UpdateTenantDto!) {
    updateTenant (data: $data) {
      id
      name
      type
      deletedAt
      primaryAdmin {
        id
        firstName
        lastName
        email
      }
    }
  }
`;

export const DELETE_TENANT = gql`
  mutation deleteTenant($data: DeleteTenantDto!) {
    deleteTenant (data: $data) {
      id
      name
      type
      deletedAt
      primaryAdmin {
        id
        firstName
        lastName
        email
      }
    }
  }
`;