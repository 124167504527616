import { DeleteBucketIcon } from '@/assets/static/icons/delete-bucket';
import { INotesData } from '@/components/client-details/types';
import ModalWrappedContent from '@/components/fat-basicComponents/modal/modalWrappedContent';
import { updateLegalEntityMutation } from '@/components/fat-investors-page/fat-addInvestors/queries';
import { useMutation } from '@apollo/client';
import { useParams } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';

interface DeleteNoteProps {
  currentNote: INotesData | null;
  notes: INotesData[] | null;
  onClose: () => void;
  isOpen: boolean;
  refetch: () => void;
}

const DeleteNote = ({ currentNote, notes, onClose, isOpen, refetch }: DeleteNoteProps) => {
  const params = useParams();
  const theme = useTheme();

  const [updateEntity] = useMutation(updateLegalEntityMutation);

  const saveValue = () => {
    const newNotes = notes
      .filter((note) => note !== currentNote)
      .map((note) => ({
        title: {
          note: note.notes,
          date: note.notesDate
        }
      }));

    updateEntity({
      variables: {
        data: {
          id: params?.entityId,
          notes: JSON.stringify(newNotes)
        }
      },
      onCompleted: () => {
        refetch();
        onClose();
      }
    });
  };

  if (!currentNote || !notes?.length) return <></>;

  return (
    <ModalWrappedContent
      isOpen={isOpen}
      onClose={onClose}
      confirmButton={{
        name: 'Confirm',
        onClick: saveValue
      }}
    >
      <ModalBody>
        <DeleteBucketIcon fill={theme.font.disabled} />
        <ModalTitle>Please confirm you want to delete this Note.</ModalTitle>
        <ModalDescription>This cannot be undone.</ModalDescription>
      </ModalBody>
    </ModalWrappedContent>
  );
};

export default DeleteNote;

const ModalBody = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
  text-align: center;
`;

const ModalTitle = styled.div`
  font-family: Blinker;
  font-size: 33px;
  font-style: normal;
  font-weight: 400;
  line-height: 46.2px;
  color: ${({ theme }) => theme.font.strong};
`;

const ModalDescription = styled.div`
  font-size: 16px;
  font-style: normal;
  color: ${({ theme }) => theme.font.base};
`;
