import ModalWrappedContent from '@/components/fat-basicComponents/modal/modalWrappedContent';
import styled from 'styled-components';

interface FileNotSupportedProps {
  onClose: () => void;
  isOpen: boolean;
}

const FileNotSupported = ({ onClose, isOpen }: FileNotSupportedProps) => {
  return (
    <ModalWrappedContent isOpen={isOpen} onClose={onClose} showCancelButton={false} confirmButton={{ name: 'OK', onClick: onClose }}>
      <ModalTitel>File Not Supported</ModalTitel>
      <ModalText>Please check the supported file types.</ModalText>
    </ModalWrappedContent>
  );
};

export default FileNotSupported;

const ModalTitel = styled.div`
  font-size: 33px;
  text-align: center;
  color: ${({ theme }) => theme.font.strong};
  margin-top: 20px;
`;

const ModalText = styled.p`
  font-weight: 400;
  font-size: 19px;
  line-height: 27px;
  text-align: center;
  color: ${({ theme }) => theme.font.base};
  margin: 20px 0px;
`;
