import { format } from 'date-fns';

import { getUTCDate, isValidDate } from '@/components/allocations-page/utils';
import BasicTooltip from '@/components/basicComponents/tooltip';
import DropdownActions from '@/components/table/fat-dropdownActions';
import {
  MobileRow,
  MobileRowTitle,
  MobileRowValue,
  MobileTableRowWrap,
  MobileTableWrapper,
  NoResults
} from '@/components/table/mobileTable/mobileTable';
import guid from '@/utils/guid';
import styled from 'styled-components';
import { ROLE, tenantsTableColumns } from '../../constants';
import { IUser } from '../../types';

interface MobileTableProps {
  tenantsTableTableData?: IUser[];
  openModalWindow: (user: IUser, type: string) => void;
}

const MobileTable = ({ tenantsTableTableData, openModalWindow }: MobileTableProps) => {
  const TableRow = ({ el }: { el: IUser }) => {
    return (
      <MobileTableRowWrap key={guid()}>
        <MobileRow>
          <MobileRowTitle>{tenantsTableColumns[0].title}</MobileRowTitle>
          <MobileRowValue>{el.email}</MobileRowValue>
        </MobileRow>

        <MobileRow>
          <MobileRowTitle>{tenantsTableColumns[1].title}</MobileRowTitle>
          <MobileRowValue>
            {el.lastLogin && isValidDate(new Date(el.lastLogin)) && (
              <BasicTooltip tooltipContent={`${getUTCDate(new Date(el.lastLogin))} ${format(new Date(el.lastLogin), 'h:mm a')}`} zIndex={1000}>
                {getUTCDate(new Date(el.lastLogin))}
              </BasicTooltip>
            )}
          </MobileRowValue>
        </MobileRow>

        <MobileRow>
          <MobileRowTitle>{tenantsTableColumns[2].title}</MobileRowTitle>
          <MobileRowValue>{el.firstName}</MobileRowValue>
        </MobileRow>

        <MobileRow>
          <MobileRowTitle>{tenantsTableColumns[3].title}</MobileRowTitle>
          <MobileRowValue>{el.lastName}</MobileRowValue>
        </MobileRow>

        <MobileRow>
          <MobileRowTitle>{tenantsTableColumns[4].title}</MobileRowTitle>
          <MobileRowValue>{el.mobileNumber}</MobileRowValue>
        </MobileRow>

        <MobileRow>
          <MobileRowTitle>{tenantsTableColumns[5].title}</MobileRowTitle>
          <MobileRowValue>
            {el?.roles?.length > 1 ? (
              <BasicTooltip
                tooltipWidth={250}
                tooltipContent={el.roles
                  .map((role) => ROLE[role])
                  .toString()
                  .replace(/,/g, ', ')}
                zIndex={1000}
              >
                Multiple ({el.roles.length})
              </BasicTooltip>
            ) : (
              el?.roles && ROLE[el.roles[0]]
            )}
          </MobileRowValue>
        </MobileRow>

        <MobileRow>
          <MobileRowTitle>{tenantsTableColumns[6].title}</MobileRowTitle>
          <MobileRowValue>{el.tenant.name}</MobileRowValue>
        </MobileRow>

        <DropdownActionsWrapper>
          <DropdownActions
            isMobile
            items={[
              {
                name: 'Impersonate',
                onClick: () => openModalWindow(el, 'impersonate')
              }
            ]}
            isListCenter
          />
        </DropdownActionsWrapper>
      </MobileTableRowWrap>
    );
  };

  return (
    <MobileTableWrapper>
      {tenantsTableTableData?.length ? (
        tenantsTableTableData?.map((el: any, index: number) => {
          return <TableRow el={el} key={index} />;
        })
      ) : (
        <NoResults>No Results</NoResults>
      )}
    </MobileTableWrapper>
  );
};

export default MobileTable;

const DropdownActionsWrapper = styled.div`
  margin: 0 auto;
`;
