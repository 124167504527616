import { formatterClient } from '../../../clients-page/utils';
import { FormatByType } from './types';

export const formatByType: FormatByType = {
  number: (value: number) => formatterClient.format(value),
  percentage: (value: number) => value.toFixed(2) + '%',
  x: (value: number) => `${value.toFixed(2)}X`,
  yesNo: (value: boolean) => (value ? 'YES' : 'NO'),
  string: (value: string) => value,
  date: (value: string) => value,
  JSX: (value: JSX.Element) => value
};
