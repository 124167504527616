import styled from 'styled-components';

import { InvestmentMoreInfoType, MoreInfoEntity } from './types';
import { formatterClient } from './utils';

interface InfoListProps {
  data: MoreInfoEntity;
  investment: InvestmentMoreInfoType;
}

export const ComplianceEntityInfoList = ({ data, investment }: InfoListProps) => {
  const getBoolean = (value: boolean) => (value ? 'YES' : 'NO');
  const biteSizeLabel = investment?.type ? `Bite Size (${investment?.type?.toLowerCase()})` : 'Bite Size';
  return (
    <MoreDataWrap id="modal">
      <MoreDataItem id="modal">
        <MoreDataItemTitle id="modal">Always Show</MoreDataItemTitle>
        <MoreDataItemValue id="modal">{getBoolean(data.alwaysShow)}</MoreDataItemValue>
      </MoreDataItem>

      <MoreDataItem id="modal">
        <MoreDataItemTitle id="modal">Entity Value</MoreDataItemTitle>
        <MoreDataItemValue id="modal">{formatterClient.format(data.entityValue)}</MoreDataItemValue>
      </MoreDataItem>

      <MoreDataItem id="modal">
        <MoreDataItemTitle id="modal">On Hold</MoreDataItemTitle>
        <MoreDataItemValue id="modal">{getBoolean(data.onHold)}</MoreDataItemValue>
      </MoreDataItem>

      <MoreDataItem id="modal">
        <MoreDataItemTitle id="modal">{biteSizeLabel}</MoreDataItemTitle>
        <MoreDataItemValue id="modal">{formatterClient.format(data.biteSize)}</MoreDataItemValue>
      </MoreDataItem>
    </MoreDataWrap>
  );
};

const MoreDataItemValue = styled.div`
  font-family: Blinker, serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0;
  text-align: left;
  width: 200px;
  color: ${({ theme }) => theme.font.base};
`;

const MoreDataItemTitle = styled.div`
  font-family: Blinker, serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0;
  text-align: left;
  width: 150px;
  min-width: 150px;
  color: ${({ theme }) => theme.font.base};
`;

const MoreDataItem = styled.div`
  display: flex;
  align-items: center;
  padding: 5px 20px;
  background-color: ${({ theme }) => theme.layer[1]};
  gap: 10px;
  border-radius: 4px;
`;

const MoreDataWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3px;
`;
