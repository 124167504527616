import { Dispatch, SetStateAction } from 'react';
import { useTheme } from 'styled-components';

interface PasswordToggleButtonProps {
  isPasswordMasked: boolean;
  setIsPasswordMasked: Dispatch<SetStateAction<boolean>>;
}

export const PasswordToggleButton = ({ isPasswordMasked = false, setIsPasswordMasked = () => {} }: PasswordToggleButtonProps) => {
  const theme = useTheme();

  return (
    <div style={{ cursor: 'pointer', display: 'inline' }} onClick={() => setIsPasswordMasked(!isPasswordMasked)}>
      {isPasswordMasked ? (
        <svg xmlns="http://www.w3.org/2000/svg" width="19" height="16" style={{ position: 'relative', top: '2px' }} viewBox="0 0 19 16" fill="none">
          <g clipPath="url(#clip0_18_244)">
            <path
              d="M2.40421 0C7.38237 4.96479 12.3567 9.92959 17.3273 14.8944L16.2359 15.9972C16.1443 15.8969 16.0469 15.788 15.9495 15.6849L13.5693 13.3018C13.5409 13.2615 13.499 13.2327 13.4512 13.2206C13.4033 13.2085 13.3527 13.214 13.3086 13.236C11.7673 13.8281 10.1024 14.0258 8.46526 13.8112C6.82809 13.5967 5.27043 12.9765 3.93374 12.0072C2.61138 11.0536 1.54246 9.79101 0.820245 8.32942C0.705673 8.10027 0.59682 7.86825 0.487976 7.63911V7.53027C0.508026 7.49589 0.536699 7.46439 0.553884 7.42715C1.18873 5.88691 2.20137 4.53143 3.49836 3.48587C3.53846 3.45436 3.57573 3.41715 3.63588 3.36272L1.34444 1.07128C1.53634 0.882238 1.72539 0.701771 1.9173 0.518456C2.1092 0.33514 2.23521 0.18046 2.39561 0.0086013L2.40421 0ZM11.5986 11.3398C11.2664 11.0104 10.9398 10.7068 10.6391 10.3774C10.5532 10.272 10.4418 10.1903 10.3155 10.1402C10.1891 10.0901 10.052 10.0731 9.91728 10.091C9.53706 10.1207 9.15507 10.0631 8.80054 9.92251C8.44601 9.78194 8.12833 9.56212 7.87181 9.2799C7.61529 8.99767 7.42674 8.66051 7.32056 8.29421C7.21438 7.9279 7.19338 7.54215 7.25919 7.16649C7.26219 7.12507 7.25691 7.0835 7.24363 7.04415C7.23036 7.00481 7.20933 6.9685 7.18185 6.93737C6.82668 6.56787 6.46007 6.20982 6.09917 5.84605L5.99319 5.75728C5.60198 6.54099 5.46743 7.42796 5.60869 8.29242C5.74994 9.15689 6.15982 9.95492 6.78014 10.5733C7.40047 11.1918 8.19977 11.5992 9.06466 11.7378C9.92956 11.8764 10.8161 11.7392 11.5986 11.3455V11.3398Z"
              fill={theme.font.action}
            />
            <path
              d="M6.41711 1.80454C7.03294 1.66419 7.64016 1.48089 8.26171 1.38636C10.1483 1.09486 12.079 1.35507 13.8211 2.13566C15.5632 2.91625 17.0424 4.18391 18.0806 5.78593C18.4185 6.31583 18.6792 6.89727 18.9685 7.45867C18.9964 7.52757 18.9964 7.6046 18.9685 7.6735C18.3647 9.16724 17.4062 10.4915 16.1758 11.5317L16.1156 11.5689C16.0755 11.5317 16.0297 11.4945 15.9896 11.4515C15.2506 10.7154 14.5145 9.97352 13.7726 9.24026C13.7289 9.20856 13.6981 9.16209 13.686 9.10942C13.6739 9.05676 13.6812 9.00147 13.7068 8.95383C13.9058 8.38751 13.9812 7.78508 13.9277 7.18717C13.8742 6.58926 13.6931 6.00979 13.3967 5.48779C13.1003 4.96578 12.6954 4.51341 12.2093 4.16116C11.7232 3.80891 11.1673 3.56495 10.579 3.44578C9.84748 3.29184 9.08753 3.34542 8.38489 3.60046C8.32985 3.62697 8.26735 3.63374 8.2079 3.61966C8.14845 3.60558 8.09566 3.5715 8.05835 3.52312C7.517 2.95026 6.9642 2.41749 6.41711 1.86468V1.80454Z"
              fill={theme.font.action}
            />
            <path
              d="M12.2116 7.69353L9.61081 5.06695C9.93055 5.04485 10.2515 5.08611 10.5553 5.18835C10.859 5.29059 11.1396 5.45178 11.3809 5.66271C11.6726 5.90679 11.9045 6.2144 12.0589 6.56193C12.2133 6.90947 12.2861 7.28771 12.2717 7.66772L12.2116 7.69353Z"
              fill={theme.font.action}
            />
          </g>
          <defs>
            <clipPath id="clip0_18_244">
              <rect width="18.5149" height="16" fill="white" transform="translate(0.485138)" />
            </clipPath>
          </defs>
        </svg>
      ) : (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="14" viewBox="0 0 20 14" fill="none">
          <g clipPath="url(#clip0_18_254)">
            <path
              d="M20 6.80722V7.18349C19.9234 7.34876 19.8337 7.50761 19.7317 7.65847C19.0315 8.53496 18.2632 9.35481 17.4339 10.1104C16.0836 11.3932 14.488 12.3898 12.7428 13.0404C11.5129 13.4847 10.1886 13.6016 8.89989 13.3797C7.3811 13.0821 5.94547 12.4568 4.693 11.5476C3.13762 10.4467 1.74794 9.12875 0.566311 7.63377C0.39863 7.45676 0.305176 7.2222 0.305176 6.97838C0.305176 6.73455 0.39863 6.5 0.566311 6.32298C1.27895 5.44278 2.0574 4.61795 2.89491 3.8556C4.24218 2.57874 5.83361 1.58738 7.57366 0.941026C8.80503 0.502778 10.1286 0.389101 11.4166 0.610993C12.9358 0.907306 14.3717 1.5326 15.6235 2.443C16.9473 3.37163 18.1475 4.46506 19.195 5.69687C19.5345 6.00979 19.8081 6.38729 20 6.80722ZM14.069 6.99223C14.0721 6.22024 13.8462 5.46474 13.42 4.82108C12.9937 4.17742 12.3862 3.67455 11.6743 3.37603C10.9623 3.07751 10.1779 2.99671 9.42005 3.14388C8.66221 3.29105 7.96501 3.65959 7.41655 4.20288C6.86808 4.74617 6.49299 5.43984 6.33865 6.19625C6.1843 6.95266 6.25765 7.73783 6.54941 8.45257C6.84117 9.16731 7.33826 9.77952 7.97786 10.2118C8.61745 10.6442 9.37085 10.8772 10.1428 10.8815C11.179 10.8856 12.1744 10.4783 12.9105 9.74913C13.6467 9.01994 14.0633 8.02837 14.069 6.99223Z"
              fill={theme.font.action}
            />
            <path
              d="M10.1706 8.92909C9.78543 8.9297 9.40874 8.81606 9.08822 8.60245C8.76771 8.38884 8.51778 8.08488 8.3701 7.72914C8.22242 7.3734 8.18363 6.98183 8.25862 6.60403C8.33362 6.22623 8.51904 5.87921 8.7914 5.60685C9.06375 5.33449 9.41081 5.14904 9.78861 5.07404C10.1664 4.99905 10.5579 5.03788 10.9137 5.18556C11.2694 5.33324 11.5733 5.58317 11.787 5.90368C12.0006 6.22419 12.1143 6.60092 12.1137 6.98609C12.1096 7.50018 11.9036 7.99202 11.5401 8.35555C11.1766 8.71907 10.6847 8.92506 10.1706 8.92909Z"
              fill={theme.font.action}
            />
          </g>
          <defs>
            <clipPath id="clip0_18_254">
              <rect width="19.6928" height="13" fill="white" transform="translate(0.307236 0.5)" />
            </clipPath>
          </defs>
        </svg>
      )}
    </div>
  );
};
