import styled from 'styled-components';

export interface IFilterBarData {
  type: string;
  name: string;
  value: string | number | boolean;
  enabled: boolean;
  count?: number;
}

interface FilterBarProps {
  filterBar: IFilterBarData[];
  onChangeFilterBar: (filterBarItems: IFilterBarData[]) => void;
}

const FilterBar = ({ filterBar, onChangeFilterBar }: FilterBarProps) => {
  const handleSelect = (id: number) => {
    const newData = [...filterBar];
    newData[id].enabled = !newData[id].enabled;
    onChangeFilterBar(newData);
  };

  return (
    <Container>
      {filterBar.map((item, index) => (
        <FilterItem key={item.name} isActive={item.enabled} onClick={() => handleSelect(index)}>
          {item.enabled ? (
            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
              <path
                d="M6.00359 1.07942e-06C4.8166 -0.000710761 3.65608 0.350669 2.66883 1.00968C1.68159 1.6687 0.911993 2.60574 0.457422 3.70224C0.00285038 4.79875 -0.116284 6.00544 0.115113 7.16966C0.346509 8.33388 0.918018 9.40331 1.75735 10.2426C2.59668 11.082 3.66611 11.6535 4.83033 11.8849C5.99455 12.1163 7.20125 11.9972 8.29776 11.5426C9.39426 11.088 10.3313 10.3184 10.9903 9.33118C11.6493 8.34393 12.0007 7.18339 12 5.9964C11.999 4.40635 11.367 2.8817 10.2426 1.75736C9.11829 0.633026 7.59364 0.000954633 6.00359 1.07942e-06ZM6.00359 10.7978C5.0538 10.7985 4.12515 10.5176 3.33509 9.9904C2.54502 9.46325 1.92904 8.71361 1.56508 7.83633C1.20112 6.95904 1.10551 5.99353 1.29037 5.0619C1.47523 4.13028 1.93224 3.2744 2.60359 2.60255C3.27494 1.93069 4.13048 1.47305 5.06197 1.28749C5.99345 1.10194 6.95904 1.19681 7.8366 1.56011C8.71416 1.92341 9.46424 2.53883 9.99199 3.3285C10.5197 4.11817 10.8014 5.04661 10.8014 5.9964C10.7995 7.26861 10.2936 8.48823 9.3943 9.38816C8.49505 10.2881 7.2758 10.795 6.00359 10.7978ZM8.75705 3.34734L4.79784 7.30654L3.24655 5.75884L2.40071 6.60468L4.79784 9.0054L9.59927 4.20397L8.75705 3.34734Z"
                fill="white"
              />
            </svg>
          ) : (
            <UnSelected />
          )}
          {item.name}
          {item.count !== null && (
            <CountWrap>
              <Count>{item.count}</Count>
            </CountWrap>
          )}
        </FilterItem>
      ))}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  border-radius: 8px;
  background: ${({ theme }) => theme.layer[1]};
  padding: 10px;
`;

const FilterItem = styled.div<{ isActive: boolean }>`
  display: flex;
  align-items: center;
  gap: 6px;
  border-radius: 12px;
  border: 1px solid ${({ isActive, theme }) => (isActive ? theme.action.hover : theme.border.base)};
  background: ${({ isActive, theme }) => (isActive ? theme.action.hover : theme.layer.base)};
  overflow: hidden;
  color: ${({ isActive, theme }) => (isActive ? theme.layer[1] : theme.action.primary)};
  text-overflow: ellipsis;
  font-family: Blinker;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 18.2px;
  padding: 3px 3px 3px 8px;
  cursor: pointer;
  transition: 0.3s ease-in-out;
`;

const CountWrap = styled.div`
  background-color: ${({ theme }) => theme.footer.base};
  display: inline-block;
  border-radius: 12px;
  color: ${({ theme }) => theme.context.light};
  font-size: 13px;
  padding: 1px 8px;
`;

const Count = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #f4f5f6;
  text-align: center;
  font-family: Blinker;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: 18.2px;
`;

const UnSelected = styled.div`
  width: 12px;
  height: 12px;
  border: 1px solid #ababab;
  border-radius: 50%;
`;

export default FilterBar;
