import { useQuery } from '@apollo/client';
import styled from 'styled-components';

import FormattingTooltip from '../../basicComponents/tooltip/dollarsTooltip';
import { columns } from '../constatnts';
import { INVESTMENT_MANAGERS_QUERY } from '../queries';

type RowSumType = {
  countOfFunds: number;
  countOfInvestors: number;
  committedCapital: number;
  percentOfTotalActiveCommmitments: number;
};

const TableSumFooter = () => {
  const { data } = useQuery(INVESTMENT_MANAGERS_QUERY, {
    variables: { page: 0, rowsPerPage: 500 }
  });
  const totalFunds = data?.InvestmentManagers.managers.reduce((sum: number, value: RowSumType) => (sum += Number(value.countOfFunds)), 0);
  const totalInvestors = data?.InvestmentManagers.managers.reduce((sum: number, value: RowSumType) => (sum += Number(value.countOfInvestors)), 0);
  const totalCommittedCapital = data?.InvestmentManagers.managers.reduce(
    (sum: number, value: RowSumType) => (sum += Number(value.committedCapital)),
    0
  );
  const totalActiveCommitments = data?.InvestmentManagers.managers.reduce(
    (sum: number, value: RowSumType) => (sum += Number(value.percentOfTotalActiveCommmitments) / 100),
    0
  );

  return (
    <TableSumWrap>
      <TableSumItem width={columns[0].width}>Total</TableSumItem>
      <TableSumItem width={columns[1].width}></TableSumItem>
      <TableSumItem width={columns[2].width}>{totalFunds}</TableSumItem>
      <TableSumItem width={columns[3].width}>{totalInvestors}</TableSumItem>
      <TableSumItem width={columns[4].width}>
        <FormattingTooltip>{totalCommittedCapital}</FormattingTooltip>
      </TableSumItem>
      <TableSumItem width={columns[5].width}></TableSumItem>
    </TableSumWrap>
  );
};

const TableSumWrap = styled.div`
  display: flex;
  align-items: center;
  padding: 0 13px 0 13px;
  width: 100%;
  min-height: 60px;
  background-color: #f0f1f3;
  border-radius: 8px;
  margin-bottom: 30px;
`;

const TableSumItem = styled.div<{ width: number; justifyContent?: string }>`
  display: flex;
  justify-content: ${({ justifyContent }) => `${justifyContent}`};
  width: ${({ width }) => `${width}%`};
  font-family: Blinker, serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0;
`;

export default TableSumFooter;
