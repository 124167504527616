import FormattingTooltip from '@/components/basicComponents/tooltip/dollarsTooltip';
import NoResults from '@/components/table/noResults';
import RedirectTitleCell from '@/components/table/redirectTitleCell';
import { TableRowCell, TableRowWrapper, TextCell } from '@/components/table/styles';
import { TableColumns } from '@/components/table/types';
import { useAuth } from '@/hooks/use-auth';
import { formatPercent } from '@/utils/currency';
import guid from '@/utils/guid';
import styled from 'styled-components';
import { InvestmentEntity } from '../types';

interface TabelBodyEntityProps {
  entityTableRows: InvestmentEntity[];
  columns: TableColumns[];
  openDetailsPage: (investorId: string, entityId?: string) => void;
}

const TabelBodyEntity = ({ entityTableRows, columns, openDetailsPage }: TabelBodyEntityProps) => {
  const { user } = useAuth();

  if (!entityTableRows?.length) {
    return <NoResults />;
  }

  return (
    <>
      {entityTableRows.map((row: InvestmentEntity) => (
        <CustomTableRowWrapper key={row.id}>
          <TableRowCell width={columns[0].width}>
            <RedirectTitleCell id="actionCurioText" onClick={() => openDetailsPage(row.familyId, row.id)}>
              {row.name}
            </RedirectTitleCell>
          </TableRowCell>
          <TableRowCell width={columns[1].width}>
            <RedirectTitleCell id="actionCurioText" onClick={() => openDetailsPage(row.familyId)}>
              {row.family}
            </RedirectTitleCell>
          </TableRowCell>
          <TableRowCell width={columns[2].width}>{user && user.tenant.type === 'fundManager' && <TextCell>{row.tenant.name}</TextCell>}</TableRowCell>
          <TableRowCell width={columns[3].width}>
            <TextCell>{row.vehicle}</TextCell>
          </TableRowCell>
          <TableRowCell width={columns[4].width}>
            <TextCell>
              <FormattingTooltip zIndex={1000} key={guid()}>
                {row.committedCapital}
              </FormattingTooltip>
            </TextCell>
          </TableRowCell>
          <TableRowCell width={columns[5].width}>
            <TextCell>
              <FormattingTooltip zIndex={1000} key={guid()}>
                {row.capitalCalled}
              </FormattingTooltip>
            </TextCell>
          </TableRowCell>
          <TableRowCell width={columns[6].width}>
            <TextCell>{formatPercent(row.percentOfCapitalCalled)}</TextCell>
          </TableRowCell>
          {/* <TableRowCell width={columns[6].width}>
            <TextCell >{formatPercent(row.IRR)}</TextCell>
          </TableRowCell>
          <TableRowCell width={columns[7].width}>
            <TextCell >{row.TVPI}x</TextCell>
          </TableRowCell> */}
        </CustomTableRowWrapper>
      ))}
    </>
  );
};

export default TabelBodyEntity;

const CustomTableRowWrapper = styled(TableRowWrapper)`
  justify-content: space-between;
  gap: 16px;
`;
