import { useState } from 'react';

import Input from '@/components/fat-basicComponents/input';
import ModalWrappedContent from '@/components/fat-basicComponents/modal/modalWrappedContent';
import { UseFormSetValue } from 'react-hook-form';
import styled from 'styled-components';
import { CreateInvestorDto } from '../types';

interface AddInvestorHouseholdProps {
  isOpen: boolean;
  getNewFamilyName: (newFamilyName: string) => void;
  onClose: () => void;
  setValue: UseFormSetValue<CreateInvestorDto>;
}

const AddInvestorHousehold = ({ isOpen, getNewFamilyName, onClose, setValue }: AddInvestorHouseholdProps) => {
  const [newInvestorHouseholdName, setNewInvestorHouseholdName] = useState('');

  const saveValue = () => {
    getNewFamilyName(newInvestorHouseholdName);
    onClose();
  };

  return (
    <ModalWrappedContent
      isOpen={isOpen}
      onClose={onClose}
      title="Add New Investor Household"
      confirmButton={{
        name: 'Save',
        onClick: saveValue,
        disabled: !newInvestorHouseholdName.length
      }}
      showRequiredFields
    >
      <ModalDescription>Investor households organize entities into a single client relationship.</ModalDescription>
      <ManagerInputWrap>
        <Input
          label="Investor Household Name"
          placeholder="Provide a name"
          value={newInvestorHouseholdName}
          onChange={(e) => {
            setNewInvestorHouseholdName(e.target.value);
          }}
          isRequiredField
          autoFocus
        />
      </ManagerInputWrap>
    </ModalWrappedContent>
  );
};

export default AddInvestorHousehold;

const ModalDescription = styled.div`
  font-size: 19px;
  line-height: 27px;
  text-align: center;
  color: ${({ theme }) => theme.font.base};
`;

const ManagerInputWrap = styled.div`
  padding-top: 35px;
`;
