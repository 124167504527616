import * as React from 'react';

export const GoodSuitabilityIcon = ({ ...props }: React.SVGProps<SVGSVGElement>) => (
  <svg width="19" height="19" style={{ minWidth: '19px' }} viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.50539 0.500002C7.7249 0.498934 5.98412 1.026 4.50325 2.01453C3.02238 3.00305 1.86799 4.40861 1.18613 6.05337C0.504276 7.69812 0.325575 9.50815 0.672669 11.2545C1.01976 13.0008 1.87703 14.605 3.13602 15.864C4.39502 17.123 5.99917 17.9802 7.7455 18.3273C9.49183 18.6744 11.3019 18.4957 12.9466 17.8139C14.5914 17.132 15.997 15.9776 16.9855 14.4968C17.974 13.0159 18.5011 11.2751 18.5 9.4946C18.4986 7.10952 17.5504 4.82255 15.8639 3.13604C14.1774 1.44954 11.8905 0.501432 9.50539 0.500002ZM9.50539 16.6968C8.08071 16.6978 6.68773 16.2763 5.50263 15.4856C4.31753 14.6949 3.39357 13.5704 2.84762 12.2545C2.30168 10.9386 2.15827 9.49029 2.43555 8.09285C2.71284 6.69542 3.39836 5.4116 4.40538 4.40382C5.41241 3.39604 6.69572 2.70958 8.09295 2.43124C9.49018 2.15291 10.9386 2.29521 12.2549 2.84016C13.5712 3.38512 14.6964 4.30824 15.488 5.49275C16.2796 6.67725 16.7022 8.06992 16.7022 9.4946C16.6993 11.4029 15.9403 13.2324 14.5915 14.5822C13.2426 15.9321 11.4137 16.6925 9.50539 16.6968ZM13.6356 5.521L7.69676 11.4598L5.36983 9.13827L4.10107 10.407L7.69676 14.0081L14.8989 6.80595L13.6356 5.521Z"
      fill={props?.fill || '#3DBD4A'}
    />
  </svg>
);
