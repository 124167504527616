// @ts-nocheck
import client from '@/apollo-client';
import { InfoIcon } from '@/assets/icons/info-icons/info';
import { Banner } from '@/components/basicComponents/banner';
import CheckBox from '@/components/basicComponents/checkbox';
import FilterSelect from '@/components/basicComponents/select';
import Button from '@/components/fat-basicComponents/button';
import ModalWrappedContent from '@/components/fat-basicComponents/modal/modalWrappedContent';
import { GET_SETTINGS } from '@/components/settings-page/queries';
import { useQuery } from '@apollo/client';
import { PDFDownloadLink } from '@react-pdf/renderer';
import * as docx from 'docx';
import fileDownload from 'js-file-download';
import { MouseEvent, useEffect, useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { getDocxFooter } from '../DDR-DOCX/footer';
import { getDocxHeader } from '../DDR-DOCX/header';
import { getOneColumnSection } from '../DDR-DOCX/oneColumnSection';
import { getThreeColumnSection } from '../DDR-DOCX/threeColumnSection';
import { getTwoColumnSection } from '../DDR-DOCX/twoColumnSection';
import { transparentDocxTableBorders } from '../DDR-DOCX/utils';
import { PDFTemplate } from '../DDR-PDF/PDFTemplate';
import { getFontByKey } from '../DDR-PDF/utils';
import { ISection, ITier } from '../dueDiligenceRecord/types';
import { DUE_DILIGENCE_RECORD_QUERY, EXPORT_DUE_DILIGENCE_CSV, GET_DUE_DILIGENCE_FUNNEL } from '../queries';

interface DownloadPDFProps {
  onClose: () => void;
  isOpen: boolean;
  activeTab?: string;
  recordId: string | null;
}

const DEFAULT_ERROR_MESSAGE = `We're sorry. This process did not complete. Please try again.`;

const DownloadPDF = ({ isOpen, onClose, activeTab, recordId }: DownloadPDFProps) => {
  const theme = useTheme();

  const [selectedTemplate, setSelectedTempalte] = useState('Select');
  const [includeAiSuggested, setIncludeAiSuggested] = useState(true);
  const [isPdfReady, setIsPdfReady] = useState(false);
  const [isDocxGenerating, setIsDocxGenerating] = useState(false);
  const [isCSVGenerating, setIsCSVGenerating] = useState(false);
  const [tiers, setTiers] = useState<ITier[]>([]);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  useEffect(() => {
    if (activeTab) {
      setSelectedTempalte(activeTab);
      return;
    }
    setSelectedTempalte('Select');
    return () => {
      setSelectedTempalte('Select');
    };
  }, [isOpen]);

  const { data } = useQuery(DUE_DILIGENCE_RECORD_QUERY, {
    fetchPolicy: 'network-only',
    variables: {
      id: recordId
    },
    skip: !recordId,
    onCompleted: async (data) => {
      const { data: funnelData } = await client.query({
        fetchPolicy: 'network-only',
        query: GET_DUE_DILIGENCE_FUNNEL,
        variables: {
          id: data?.getDueDiligenceRecord.funnel.id
        }
      });

      const updatedTiers = data.getDueDiligenceRecord.funnel.tiers.map((oldTier: ITier) => {
        const matchingTier = funnelData.getDueDiligenceFunnel.latestFunnel.tiers.find((tier: ITier) => tier.order === oldTier.order);

        if (matchingTier) {
          return {
            ...oldTier,
            name: matchingTier.name
          };
        }
        return oldTier;
      });

      setTiers(updatedTiers);
    }
  });

  const { data: firmSettingsData } = useQuery(GET_SETTINGS, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    variables: {
      type: 'firmSetting'
    }
  });

  const { data: templateStylingData } = useQuery(GET_SETTINGS, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    variables: {
      type: 'templateStyling'
    }
  });

  const getScoreProperties = (score: number) => {
    if (score > 0) {
      return {
        color: 'F4F5F6',
        fill: '3DBD4A',
        text: `+${score}`
      };
    } else if (score === 0) {
      return {
        color: '474747',
        fill: 'F4F5F6',
        text: `${score}`
      };
    } else {
      return {
        color: 'F4F5F6',
        fill: 'D63B4B',
        text: `${score}`
      };
    }
  };

  const createInvestmentParagraphs = (
    investmentName: string,
    investmentDescription: string,
    headerFontColor: string,
    bodyFontColor: string,
    score: number,
    displayScore: boolean
  ) => {
    const paragraph1 = new docx.Paragraph({
      heading: docx.HeadingLevel.HEADING_1,
      spacing: { before: 250, after: 250 },
      children: [
        new docx.TextRun({
          text: investmentName,
          bold: true,
          color: headerFontColor
        })
      ]
    });

    const scoreParagraph = new docx.Paragraph({
      children: [
        new docx.TextRun({
          text: displayScore ? `Total score ${getScoreProperties(score).text}` : '',
          size: 30,
          color: getScoreProperties(score).color,
          bold: true
        })
      ],
      alignment: docx.AlignmentType.CENTER
    });

    const table = new docx.Table({
      rows: [
        new docx.TableRow({
          children: [
            new docx.TableCell({
              children: [paragraph1],
              width: {
                size: 7500,
                type: docx.WidthType.DXA
              },
              borders: transparentDocxTableBorders
            }),
            new docx.TableCell({
              children: [scoreParagraph],
              width: {
                size: 2500,
                type: docx.WidthType.DXA
              },
              shading: { fill: displayScore ? getScoreProperties(score).fill : 'FFFFFF' },
              verticalAlign: docx.VerticalAlign.CENTER,
              borders: transparentDocxTableBorders
            })
          ]
        })
      ]
    });

    const paragraph2 = new docx.Paragraph({
      spacing: { before: 150, after: 200 },
      children: [
        new docx.TextRun({
          text: investmentDescription,
          color: bodyFontColor
        })
      ]
    });

    if (investmentDescription) {
      return [table, paragraph2];
    }

    return [table];
  };

  const shouldDisplaySection = (section: ISection) => {
    return section.fields.some((field) => {
      const hasValueDifferentFromAI = field.data.value && field.data.value !== field.AI?.answers[0];
      const hasAiSuggestedIncluded =
        field.AI?.answers.length && includeAiSuggested && (field.data.value === null || field.data.value === field.AI?.answers[0]);
      const hasIncludedAttachments = field.data.attachments.some((attachment) => attachment.includeInAutomatedContent);

      return hasValueDifferentFromAI || hasAiSuggestedIncluded || hasIncludedAttachments;
    });
  };

  const createSectionHeader = (section: ISection, headerFontColor: string) => {
    const sectionHeaderTable = new docx.Table({
      rows: [
        new docx.TableRow({
          children: [
            new docx.TableCell({
              children: [
                new docx.Paragraph({
                  children: [
                    new docx.TextRun({
                      text: section.name,
                      color: headerFontColor
                    })
                  ],
                  spacing: { after: 100 },
                  heading: docx.HeadingLevel.HEADING_1
                })
              ],
              borders: {
                ...transparentDocxTableBorders,
                bottom: { style: docx.BorderStyle.SINGLE, size: 6, color: 'F0F1F3' }
              },
              width: {
                size: 8600,
                type: docx.WidthType.DXA
              }
            }),
            new docx.TableCell({
              children: [
                new docx.Paragraph({
                  children: [
                    new docx.TextRun({
                      text: section.userCanScore ? 'Score\t' : '',
                      size: 24,
                      color: headerFontColor
                    })
                  ]
                })
              ],
              borders: {
                ...transparentDocxTableBorders,
                bottom: { style: docx.BorderStyle.SINGLE, size: 6, color: 'F0F1F3' }
              },
              width: {
                size: 750,
                type: docx.WidthType.DXA
              },
              verticalAlign: docx.VerticalAlign.CENTER
            }),
            new docx.TableCell({
              children: [
                new docx.Paragraph({
                  children: [
                    new docx.TextRun({
                      text: section.userCanScore ? getScoreProperties(section.data.score).text : '',
                      size: 24,
                      color: getScoreProperties(section.data.score).color,
                      bold: true
                    })
                  ],
                  alignment: docx.AlignmentType.CENTER
                })
              ],
              borders: {
                ...transparentDocxTableBorders,
                bottom: { style: docx.BorderStyle.SINGLE, size: 6, color: 'F0F1F3' }
              },
              width: {
                size: 625,
                type: docx.WidthType.DXA
              },
              shading: { fill: section.userCanScore ? getScoreProperties(section.data.score).fill : 'FFFFFF' },
              verticalAlign: docx.VerticalAlign.CENTER
            })
          ]
        })
      ]
    });

    if (section.pdfLayout === 'TWO_COLUMN_GRID') {
      return [sectionHeaderTable, new docx.Paragraph({})];
    }

    const beforeSectionHeaderSpacing = new docx.Paragraph({
      spacing: { before: 100 }
    });

    return [beforeSectionHeaderSpacing, sectionHeaderTable, new docx.Paragraph({})];
  };

  const formattingDataForDocx = async (selectedTier: ITier) => {
    const investmentName = data?.getDueDiligenceRecord.investment.name ?? '';
    const investmentDescription = data?.getDueDiligenceRecord.investment.description ?? '';
    const totalScore = selectedTier.sections.reduce((accumulator, currentValue) => accumulator + currentValue.data.score, 0);
    const displayScore = selectedTier.sections.some((section) => section.userCanScore);

    const headerFontColor = Boolean(getFontByKey(templateStylingData?.Settings ?? [], 'headerFontStyle').fontColor)
      ? `#${getFontByKey(templateStylingData?.Settings ?? [], 'headerFontStyle').fontColor}`
      : '#35739C';

    const bodyFontColor = Boolean(getFontByKey(templateStylingData?.Settings ?? [], 'bodyFontStyle').fontColor)
      ? `#${getFontByKey(templateStylingData?.Settings ?? [], 'bodyFontStyle').fontColor}`
      : '#757575';

    let docxData = [];

    docxData.push(...createInvestmentParagraphs(investmentName, investmentDescription, headerFontColor, bodyFontColor, totalScore, displayScore));

    for await (const section of selectedTier.sections) {
      if (!shouldDisplaySection(section)) continue;

      if (section.pdfLayout === 'TWO_COLUMN_GRID') {
        docxData.push(new docx.Paragraph({ pageBreakBefore: true }));
      }

      docxData.push(...createSectionHeader(section, headerFontColor));

      let sectionData;
      switch (section.pdfLayout) {
        case 'THREE_COLUMN':
          sectionData = await getThreeColumnSection(section, includeAiSuggested, bodyFontColor);
          break;
        case 'TWO_COLUMN_GRID':
          sectionData = await getTwoColumnSection(section, includeAiSuggested, bodyFontColor);
          break;
        default:
          sectionData = await getOneColumnSection(section, includeAiSuggested, bodyFontColor);
          break;
      }
      docxData.push(...sectionData);
    }

    return docxData;
  };

  const generateDocx = async () => {
    try {
      setIsDocxGenerating(true);
      setErrorMessage(null);

      const selectedTier = tiers.find((tier) => tier.name === selectedTemplate);

      if (!selectedTier) return;
      const formattedData = await formattingDataForDocx(selectedTier);

      const doc = new docx.Document({
        sections: [
          {
            properties: {
              page: {
                margin: {
                  right: 1000,
                  left: 1000
                }
              }
            },
            children: formattedData,
            headers: {
              default: getDocxHeader(firmSettingsData, selectedTier.name)
            },
            footers: {
              default: await getDocxFooter(firmSettingsData)
            }
          }
        ]
      });

      docx.Packer.toBlob(doc).then((blob) => {
        fileDownload(blob, `${selectedTier.name}.docx`);
      });
    } catch (error) {
      console.error(error);
      setErrorMessage(DEFAULT_ERROR_MESSAGE);
    } finally {
      setIsDocxGenerating(false);
    }
  };

  const generateCSV = async () => {
    try {
      setIsCSVGenerating(true);
      setErrorMessage(null);
      const { data } = await client.query({
        fetchPolicy: 'network-only',
        query: EXPORT_DUE_DILIGENCE_CSV,
        variables: {
          id: recordId
        }
      });

      const blob = new Blob([data?.exportDueDiligenceCSV], { type: 'text/csv' });
      fileDownload(blob, `${recordId}.csv`);
    } catch (error) {
      console.error(error);
      setErrorMessage(DEFAULT_ERROR_MESSAGE);
    } finally {
      setIsCSVGenerating(false);
    }
  };

  const changeSelectedTemplate = (template: string) => {
    setIsPdfReady(false);
    setSelectedTempalte(template);
  };

  const changeCheckboxValue = () => {
    setIsPdfReady(false);
    setIncludeAiSuggested((prev) => !prev);
  };

  const generatingPdf = (isPdfReady: boolean) => {
    if (isPdfReady) {
      setTimeout(() => {
        setIsPdfReady(isPdfReady);
      }, 2000);
    }
  };

  const handleCloseModal = () => {
    onClose();
    setIsPdfReady(false);
  };

  const openUrlInNewTab = (event: MouseEvent<HTMLButtonElement>, blob: Blob | null) => {
    event.preventDefault();
    setErrorMessage(null);

    if (blob) {
      const url = URL.createObjectURL(blob);
      window.open(url, '_blank');
    }
  };

  if (!tiers.length) return <></>;

  return (
    <ModalWrappedContent isOpen={isOpen} onClose={handleCloseModal} title={'Download PDF'} showCancelButton={false} width="650px" showRequiredFields>
      {errorMessage && (
        <ErrorBannerWrapper>
          <Banner icon={<InfoIcon width={26} height={26} />} title="Error" description={errorMessage} bgColor={theme.context.error} />
        </ErrorBannerWrapper>
      )}
      <Container>
        <CustomFilterSelect
          data={tiers.map((tier) => tier.name)}
          selected={selectedTemplate}
          setSelected={(value) => changeSelectedTemplate(value)}
          required
          label={'Select Tier'}
          width="100%"
          minHeight="50px"
          fontSize="19px"
          fontWeight="300"
        />
        <CheckboxWrapper id={'input'} onClick={changeCheckboxValue}>
          <CheckBox id={'input'} isActive={includeAiSuggested} /> Include AI Suggested Content
        </CheckboxWrapper>
        <DownloadWrappper>
          {selectedTemplate === 'Select' ? (
            <Button styleType="disabled" size="md">
              Download
            </Button>
          ) : (
            <PDFDownloadLink
              document={
                <PDFTemplate
                  firmSettings={firmSettingsData?.Settings ?? []}
                  templateStylingData={templateStylingData?.Settings ?? []}
                  tier={tiers.find((tier) => tier.name === selectedTemplate) as ITier}
                  investmentName={data?.getDueDiligenceRecord.investment.name}
                  investmentDescription={data?.getDueDiligenceRecord.investment.description}
                  includeAiSuggested={includeAiSuggested}
                  generatingPdf={generatingPdf}
                />
              }
              style={{ width: 'max-content' }}
              fileName="test.pdf"
            >
              {({ blob }) => {
                return !isPdfReady ? (
                  <Button styleType="disabled" size="md">
                    Loading PDF Template ...
                  </Button>
                ) : (
                  <Button
                    onClick={(event: React.MouseEvent<HTMLButtonElement>) => openUrlInNewTab(event, blob ?? null)}
                    styleType={selectedTemplate === 'Select' ? 'disabled' : 'default'}
                    size="md"
                  >
                    Download
                  </Button>
                );
              }}
            </PDFDownloadLink>
          )}
          <Button
            onClick={generateDocx}
            styleType={selectedTemplate === 'Select' || isDocxGenerating ? 'disabled' : 'default'}
            isLoading={isDocxGenerating}
            size="md"
          >
            {isDocxGenerating ? 'Generating Docx ...' : 'Generate Docx'}
          </Button>
          <Button onClick={generateCSV} styleType={isCSVGenerating ? 'disabled' : 'default'} isLoading={isCSVGenerating} size="md">
            {isCSVGenerating ? 'Generating CSV ...' : 'Generate CSV'}
          </Button>
        </DownloadWrappper>
      </Container>
    </ModalWrappedContent>
  );
};

export default DownloadPDF;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const CustomFilterSelect = styled(FilterSelect)`
  #titleSelect {
    text-transform: none;
  }
  #dropDownSelect {
    margin-top: 15px;
  }
`;

const CheckboxWrapper = styled.div`
  cursor: pointer;
  display: flex;
  gap: 9px;
  margin-top: 20px;
  padding: 18px 0 30px 0;
  border-top: 1px solid ${({ theme }) => theme.border.base};
  color: ${({ theme }) => theme.font.base};
  font-size: 16px;
  font-weight: 400;
`;

const DownloadWrappper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;

const ErrorBannerWrapper = styled.div`
  margin-bottom: 20px;
`;
