import * as React from 'react';

export const DueDiligenceMenuIcon = ({ ...props }: React.SVGProps<SVGSVGElement>) => (
  <svg
    style={props.style}
    width={props.width || '28'}
    height={props.height || '28'}
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.5507 21.3334H16.442V18.8913H11.5507V21.3334ZM3 6.66669V9.116H25V6.66669H3ZM6.66667 15.2247H21.3333V12.7827H6.66667V15.2247Z"
      fill={props.fill || '#FAFAFA'}
    />
  </svg>
);
