import styled, { useTheme } from 'styled-components';

import { format } from 'date-fns';

import { TerminatedIcon } from '@/assets/icons/investment-status/treminated';
import { Status, StatusIconWrap } from './index';

export const TerminatedStatusRow = ({ status, date, isMobile = false }: { status: string; date: Date; isMobile?: boolean }) => {
  const theme = useTheme();

  return (
    <Row>
      <StatusIconWrap>
        <TerminatedIcon width={18} height={18} fill={theme.context.error} />
      </StatusIconWrap>
      <Status isMobile={isMobile} color={theme.context.error}>
        {status}
      </Status>
      <DateBlock>{format(new Date(date), 'LLL d, yyyy')}</DateBlock>
    </Row>
  );
};

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${({ theme }) => theme.layer[1]};
  border-radius: 4px;
  padding: 7px 12px;
`;

const DateBlock = styled.span`
  color: ${({ theme }) => theme.font.base};
  font-family: 'Blinker';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  padding-left: 20px;
`;
