import { useAuth } from '@/hooks/use-auth';
import { User } from '@/types/user';
import { Helmet as Head } from 'react-helmet';
import styled from 'styled-components';
import { ChangePassword } from './ChangePassword';
import { Profile } from './Profile';

const ProfilePage = () => {
  const { user, isAuthenticated } = useAuth();

  return (
    isAuthenticated && (
      <>
        <Head>
          <title>Profile</title>
        </Head>
        <ProfilePageWrapper>
          <ProfileContainer>
            <Profile user={user as User} />
            <Divider />
            <ChangePassword />
          </ProfileContainer>
        </ProfilePageWrapper>
      </>
    )
  );
};

export default ProfilePage;

const ProfilePageWrapper = styled.div`
  min-height: 100vh;
  overflow: auto;
  width: 100%;
`;

const ProfileContainer = styled.div`
  height: 100%;
  display: flex;
  padding: 100px;
`;

const Divider = styled.div`
  width: 1px;
  background-color: ${({ theme }) => theme.divider};
  margin: 0 50px;
`;
