import TableHeader from '@/components/table/fat-tableHeader';
import { ISort } from '@/components/table/types';
import { useResponsive } from '@/hooks/use-responsive';
import styled from 'styled-components';

import { Loader } from '@/components/loaders/loader';
import { TableBodyWrapper, TableWrapper } from '@/components/table/styles';
import { allocationsTablecolumns } from '../constatnts';
import { ClientEntityAllocationsTableType } from '../types';
import MobileTable from './mobileTable';
import TableBody from './tableBody';

interface TableAllocationsProps {
  investorAllocationRows: ClientEntityAllocationsTableType[] | undefined;
  sort: ISort;
  setSort: (value: ISort) => void;
  refetch: () => void;
  loading: boolean;
  openModalWindow: (type: string, row: ClientEntityAllocationsTableType) => void;
  openOpportunityDetailsPage: (id: string) => void;
}

const TableAllocations = ({
  investorAllocationRows,
  sort,
  setSort,
  refetch,
  loading,
  openModalWindow,
  openOpportunityDetailsPage
}: TableAllocationsProps) => {
  const { isMobile, isTablet } = useResponsive();

  return (
    <TableAllocationsWrapper>
      {isMobile ? (
        <>
          {loading ? (
            <Loader />
          ) : (
            <MobileTable investorAllocationRows={investorAllocationRows} openOpportunityDetailsPage={openOpportunityDetailsPage} />
          )}
        </>
      ) : (
        <TableWrapper padding="0">
          <CustomTableHeader isTablet={isTablet} refetch={refetch} columns={allocationsTablecolumns} savedSort={sort} savedSetSort={setSort} />
          <TableBodyWrapper>
            {loading ? (
              <Loader />
            ) : investorAllocationRows ? (
              <>
                <TableBody
                  investorAllocationRows={investorAllocationRows}
                  openModalWindow={openModalWindow}
                  openOpportunityDetailsPage={openOpportunityDetailsPage}
                />
              </>
            ) : null}
          </TableBodyWrapper>
        </TableWrapper>
      )}
    </TableAllocationsWrapper>
  );
};

export default TableAllocations;

const TableAllocationsWrapper = styled.div`
  margin-bottom: 100px;
`;

const CustomTableHeader = styled(TableHeader)<{ isTablet: boolean }>`
  position: sticky;
  top: ${({ isTablet }) => (isTablet ? '86px' : '36px')};
  z-index: 8;
  padding-top: 0;
`;
