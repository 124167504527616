import { DocumentIcon } from '@/assets/static/icons/document';
import RadioButton from '@/components/basicComponents/radioButton';
import { GET_AI_ASSETS } from '@/components/dueDiligence-page/queries';
import { IRecordData } from '@/components/dueDiligence-page/types';
import ModalWrappedContent from '@/components/fat-basicComponents/modal/modalWrappedContent';
import { useQuery } from '@apollo/client';
import { ChangeEvent, useState } from 'react';
import styled, { useTheme } from 'styled-components';

interface AiSuggestionsProps {
  onClose: () => void;
  isOpen: boolean;
  currentRow: IRecordData | null;
  handleClickDeal: (row: IRecordData, generateAnswers?: string[]) => void;
}

type File = {
  name: string;
  checkboxValue?: boolean;
};

const AiSuggestions = ({ onClose, isOpen, currentRow, handleClickDeal }: AiSuggestionsProps) => {
  const theme = useTheme();
  if (!currentRow) return <></>;

  const [files, setFiles] = useState<File[]>([]);
  const [selectedFile, setSelectedFile] = useState('');

  useQuery(GET_AI_ASSETS, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    variables: {
      data: {
        investmentId: currentRow.investment.id
      }
    },
    onCompleted: (data) => {
      setFiles(
        data.getDealAssistentAIAssets.map((file: any) => ({
          ...file,
          name: file.filename,
          checkboxValue: true
        }))
      );
      if (data.getDealAssistentAIAssets.length) {
        setSelectedFile(data.getDealAssistentAIAssets[0].filename);
      }
    }
  });

  const handleRadioChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSelectedFile(event.target.value);
  };

  return (
    <ModalWrappedContent
      isOpen={isOpen}
      onClose={onClose}
      confirmButton={{
        name: selectedFile ? 'Confirm' : 'Open',
        onClick: selectedFile ? () => handleClickDeal(currentRow, [selectedFile]) : () => handleClickDeal(currentRow)
      }}
    >
      <ModalContainer>
        <ModalTitel>Generate AI Suggestions</ModalTitel>
        <ModalContentWrap>
          <ModalDescription>
            The AI Assistant will suggest answers to the due diligence questions based on this deal’s source material. View this deal on the Due
            Diligence Page to review the suggestions once the process is finished.
          </ModalDescription>
          <ModalDescription>
            {selectedFile
              ? 'Documents selected for AI Assistant:'
              : 'No documents have been uploaded for this deal.  Open this deal to add a document before generating answers.'}
          </ModalDescription>
          <UploadedDocumnts>
            {files.map((file, index) => (
              <UploadedDocumentItem key={file.name + index}>
                <RadioButtonContainer>
                  <RadioButtonWrapper>
                    <RadioButton
                      name="option"
                      radioButtonText={file.name}
                      selectedOption={selectedFile}
                      handleRadioChange={handleRadioChange}
                      icon={<DocumentIcon fill={theme.font.base} />}
                    />
                  </RadioButtonWrapper>
                </RadioButtonContainer>
              </UploadedDocumentItem>
            ))}
          </UploadedDocumnts>
        </ModalContentWrap>
      </ModalContainer>
    </ModalWrappedContent>
  );
};

export default AiSuggestions;

const ModalContainer = styled.div`
  font-weight: 400;
  padding: 50px 0;
`;

const ModalTitel = styled.div`
  font-size: 33px;
  text-align: center;
  color: ${({ theme }) => theme.font.strong};
`;

const ModalDescription = styled.div`
  font-size: 19px;
  line-height: 27px;
  text-align: center;
  color: ${({ theme }) => theme.font.base};
`;

const ModalContentWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const UploadedDocumnts = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5px 0 0 79.5px;
  max-height: 100px;
  overflow-y: auto;
`;

export const DocumentIconWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
`;

const UploadedDocumentItem = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  font-weight: 600;
  font-size: 16px;
  color: ${({ theme }) => theme.font.base};
  cursor: pointer;
`;

export const RadioButtonContainer = styled.label`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  cursor: pointer;
`;

export const RadioButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
