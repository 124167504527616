import styled from 'styled-components';

import { IRawAnswers } from '@/components/dueDiligence-page/dueDiligenceRecord/types';
import TableHeader from '@/components/table/fat-tableHeader';
import { TableBodyWrapper, TableWrapper } from '@/components/table/styles';
import { useResponsive } from '@/hooks/use-responsive';
import { memo } from 'react';
import { columns } from './constatnts';
import TableRow from './tableRow';

type TableProp = {
  rows: IRawAnswers[];
  changeField: (id: string, value: string, fieldName: string) => void;
  openPdf: (pageNumber: number) => void;
};

const Table = memo(({ rows, changeField, openPdf }: TableProp) => {
  const { isTablet } = useResponsive();

  return (
    <CustomTableWrapper>
      <CustomTableHeader isTablet={isTablet} refetch={(): void => null} columns={columns} withOutSort />
      <TableBodyWrapper>
        {rows.map((row) => (
          <TableRow key={row.id} row={row} changeField={changeField} openPdf={openPdf} />
        ))}
      </TableBodyWrapper>
    </CustomTableWrapper>
  );
});

export default Table;

const CustomTableWrapper = styled(TableWrapper)`
  padding: 20px 0 0 0;
`;

const CustomTableHeader = styled(TableHeader)<{ isTablet: boolean }>`
  position: sticky;
  top: ${({ isTablet }) => (isTablet ? '110px' : '60px')};
  z-index: 8;
  padding-top: 0;
`;
