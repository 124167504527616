import { AnimatePresence, motion } from 'framer-motion';

import { RejectedBannerIcon } from '@/assets/icons/info-icons/rejectedBanner';
import RadioButton from '@/components/basicComponents/radioButton';
import { useResponsive } from '@/hooks/use-responsive';
import { ChangeEvent, useEffect, useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { EntityInput } from '../input';
import { Section } from '../section';
import { IDataFields } from '../types';

interface DefaultBiteSizeSectionProps {
  fundBiteSize: string;
  directBiteSize: string;
  ignoreBiteSize: boolean;
  changeProperties: (field: keyof IDataFields) => (value: string | number | boolean) => void;
}

const defaultBiteSizeOptions = ['Default to minimum required commitment', 'Set a default bite size for this entity'];

export const DefaultBiteSizeSection = ({ fundBiteSize, directBiteSize, ignoreBiteSize, changeProperties }: DefaultBiteSizeSectionProps) => {
  const { isMobile } = useResponsive();
  const theme = useTheme();

  const [selectedDefaultBiteSizeOptions, setSelectedDefaultBiteSizeOptions] = useState(defaultBiteSizeOptions[0]);

  useEffect(() => {
    setSelectedDefaultBiteSizeOptions(ignoreBiteSize ? defaultBiteSizeOptions[0] : defaultBiteSizeOptions[1]);
  }, [ignoreBiteSize]);

  const handleRadioChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    setSelectedDefaultBiteSizeOptions(newValue);
    changeProperties('ignoreBiteSize')(newValue === defaultBiteSizeOptions[0]);
  };

  const handleInputChange = (value: string, type: 'fundBiteSize' | 'directBiteSize') => {
    const cleanValue = value.replace(/\D/g, '');
    if (!cleanValue) {
      changeProperties(type)('');
      return;
    }
    const formattedValue = parseFloat(cleanValue).toLocaleString('en-US');

    changeProperties(type)(formattedValue);
  };

  return (
    <Section title="Default Bite Size">
      <DefaultBiteSize isMobile={isMobile}>
        <OptionsWrapper>
          Set the default amount for initial commitments:
          {defaultBiteSizeOptions.map((option, index) => (
            <RadioButton
              key={option + index}
              name="defaultBiteSize"
              radioButtonText={option}
              selectedOption={selectedDefaultBiteSizeOptions}
              handleRadioChange={(event) => handleRadioChange(event)}
            />
          ))}
        </OptionsWrapper>
        <BannerWrap>
          <BannerIconWrap>
            <RejectedBannerIcon fill={theme.header.base} />
          </BannerIconWrap>
          <BannerText>
            The commitment amount will be set to the minimum required for any deal unless a specific default bite size is set here. This amount can be
            overridden when making an allocation.
          </BannerText>
        </BannerWrap>
      </DefaultBiteSize>
      <AnimatePresence>
        {selectedDefaultBiteSizeOptions === defaultBiteSizeOptions[1] && (
          <motion.div
            initial={{
              opacity: 0
            }}
            animate={{
              opacity: 1
            }}
            exit={{
              opacity: 0
            }}
          >
            <BiteSizeWrap isMobile={isMobile}>
              <EntityInput
                value={fundBiteSize ?? ''}
                setValue={(value) => handleInputChange(value, 'fundBiteSize')}
                type="text"
                label="Illiquid Alternative"
                required
                tooltip="The default commitment amount for corresponding strategies"
                withDollarSign
              />
              <EntityInput
                value={directBiteSize ?? ''}
                setValue={(value) => handleInputChange(value, 'directBiteSize')}
                type="text"
                label="Restricted Alternative"
                required
                tooltip="The default commitment amount for corresponding strategies"
                withDollarSign
              />
            </BiteSizeWrap>
          </motion.div>
        )}
      </AnimatePresence>
    </Section>
  );
};

const OptionsWrapper = styled.div`
  max-width: 343px;
  width: 100%;
  color: ${({ theme }) => theme.font.base};
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;
`;

const DefaultBiteSize = styled.div<{ isMobile: boolean }>`
  display: flex;
  align-items: ${({ isMobile }) => (isMobile ? 'flex-start' : 'flex-end')};
  flex-direction: ${({ isMobile }) => (isMobile ? 'column-reverse' : 'row')};
  gap: ${({ isMobile }) => (isMobile ? '15px' : '0')};
`;

const BannerWrap = styled.span`
  display: flex;
  align-items: center;
  gap: 20px;
  background-color: ${({ theme }) => theme.layer[1]};
  height: 76px;
  padding: 10px 20px;
`;

const BannerIconWrap = styled.span`
  display: flex;
  align-items: center;
  height: 100%;
  padding-right: 20px;
  border-right: 1px solid ${({ theme }) => theme.border.base};
`;

const BannerText = styled.span`
  font-size: 13px;
  font-weight: 400;
  line-height: 18.2px;
  color: ${({ theme }) => theme.font.base};
`;

const BiteSizeWrap = styled.div<{ isMobile: boolean }>`
  display: flex;
  align-items: center;
  gap: ${({ isMobile }) => (isMobile ? '30px' : '15px')};
  flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};
`;
