import { useMutation, useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import styled, { useTheme } from 'styled-components';

import { InfoIcon } from '@/assets/icons/info-icons/info';
import { Banner } from '@/components/basicComponents/banner';
import FilterSelect from '@/components/basicComponents/select';
import { LIST_USERS_QUERY } from '@/components/dueDiligence-page/queries';
import ModalLoader from '@/components/fat-basicComponents/modal/modalLoader';
import ModalWrappedContent from '@/components/fat-basicComponents/modal/modalWrappedContent';
import { User } from '@/types/user';
import { updateDocumentRecordMutation } from '../queries';
import { IDocumentRecordEntity } from '../types';
import { useAuth } from '@/hooks/use-auth';
import { RejectedBannerIcon } from '@/assets/icons/info-icons/rejectedBanner';

interface ChangeAssigneeProps {
  onClose: () => void;
  isOpen: boolean;
  currentRow: IDocumentRecordEntity | null;
  listDocumentRecordsRefetch: () => void;
}

const DEFAULT_ERROR_MESSAGE = `We're sorry. This process did not complete. Please try again.`;

const ChangeOwner = ({ isOpen, onClose, currentRow, listDocumentRecordsRefetch }: ChangeAssigneeProps) => {
  const theme = useTheme();
  const [selectedOwner, setSelectedOwner] = useState('');
  const [usersList, setUsersList] = useState([]);
  const { user } = useAuth();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  useEffect(() => {
    if (!currentRow) return;
    const { owner } = currentRow;
    setSelectedOwner(owner ? `${owner?.firstName} ${owner?.lastName}` : 'Select');
  }, [currentRow]);

  const [updateDocumentRecord, { loading: updateDocumentRecordLoading }] = useMutation(updateDocumentRecordMutation, {
    onError: (error) => {
      console.error(error);
      setErrorMessage(DEFAULT_ERROR_MESSAGE);
    }
  });

  const { data: listUsersData, loading: listUsersLoading } = useQuery(LIST_USERS_QUERY, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
    variables: {
      filters: {
        roles: ['INVESTMENT_LEAD', 'INVESTMENT_MEMBER'],
        tenantId: user.tenant.id
      }
    },
    onCompleted: (data) => {
      if (!data?.listUsers?.length) return;
      setUsersList(data.listUsers.map((user: User) => `${user.firstName} ${user.lastName}`));
    },
    onError: (error) => {
      console.error(error);
    }
  });

  const changeOwner = () => {
    setErrorMessage(null);
    const selectedOwnerObject = listUsersData?.listUsers?.find((item: User) => `${item.firstName} ${item.lastName}` === selectedOwner);

    if (!selectedOwnerObject) {
      setErrorMessage(DEFAULT_ERROR_MESSAGE);
      return;
    }

    updateDocumentRecord({
      variables: {
        data: {
          id: currentRow.id,
          ownerEmail: selectedOwnerObject.email
        }
      },
      onCompleted: () => {
        listDocumentRecordsRefetch();
        onClose();
      }
    });
  };

  if (!currentRow) return <></>;

  return (
    <ModalWrappedContent
      isOpen={isOpen}
      onClose={onClose}
      title={'Change Owner'}
      confirmButton={{ name: 'Save', onClick: changeOwner, disabled: listUsersLoading || updateDocumentRecordLoading || selectedOwner === 'Select' }}
      showRequiredFields={!updateDocumentRecordLoading}
      showCloseIcon={!updateDocumentRecordLoading}
      showCancelButton={!updateDocumentRecordLoading}
      isUseOutsideClick={!updateDocumentRecordLoading}
    >
      {updateDocumentRecordLoading ? (
        <ModalLoader />
      ) : (
        <>
          {errorMessage && (
            <Banner icon={<RejectedBannerIcon fill={theme.context.light} />} title="Error" description={errorMessage} bgColor={theme.context.error} />
          )}
          <OwnerSelectWrapper>
            <OwnerSelect
              width={'auto'}
              label={'Select An Owner'}
              selected={selectedOwner}
              setSelected={(value) => setSelectedOwner(value)}
              data={usersList}
              loading={listUsersLoading}
              required
            />
          </OwnerSelectWrapper>
        </>
      )}
    </ModalWrappedContent>
  );
};

export default ChangeOwner;

const OwnerSelectWrapper = styled.div`
  margin-top: 20px;
`;

const OwnerSelect = styled(FilterSelect)`
  border-radius: 5px;
  padding: 10px 12px;

  #titleSelect {
    font-family: Blinker;
    color: ${({ theme }) => theme.font.strong};
    font-size: 19px;
    font-weight: 400;
    line-height: 26.6px;
  }

  #dropDownSelect {
    top: 50px;
  }
`;
