import { InfoIcon } from '@/assets/icons/info-icons/info';
import { DeleteBucketIcon } from '@/assets/static/icons/delete-bucket';
import { Banner } from '@/components/basicComponents/banner';
import ModalWrappedContent from '@/components/fat-basicComponents/modal/modalWrappedContent';
import { Investment } from '@/components/fat-dealCatalog-page/types';
import { useMutation } from '@apollo/client';
import { useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { deleteInvestmentMutation } from '../queries';

interface DeleteInvestmentProps {
  onClose: () => void;
  isOpen: boolean;
  currentRow: Investment | null;
  refetch: () => void;
}

const DeleteInvestment = ({ onClose, isOpen, currentRow, refetch }: DeleteInvestmentProps) => {
  const theme = useTheme();

  if (!currentRow) return <></>;

  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const [deleteInvestment] = useMutation(deleteInvestmentMutation);

  const saveValue = () => {
    setErrorMessage(null);
    deleteInvestment({
      variables: {
        id: currentRow.id
      },
      onError: (error) => {
        setErrorMessage(error.message);
      },
      onCompleted: () => {
        setErrorMessage(null);
        refetch();
        onClose();
      }
    });
  };

  return (
    <ModalWrappedContent
      isOpen={isOpen}
      onClose={onClose}
      confirmButton={{
        name: 'Yes, Delete',
        onClick: saveValue
      }}
    >
      <ModalContainer>
        <DeleteBucketIcon fill={theme.font.disabled} />
        <ModalTitel>Please confirm you want to delete this strategy.</ModalTitel>
        <ModalDescription>This strategy will be permanently deleted. This action cannot be undone.</ModalDescription>
        {errorMessage && <Banner icon={<InfoIcon width={26} height={26} />} title="Error" description={errorMessage} bgColor="#D63B4B" />}
      </ModalContainer>
    </ModalWrappedContent>
  );
};

export default DeleteInvestment;

const ModalContainer = styled.div`
  font-weight: 400;
  padding: 10px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;

const ModalTitel = styled.div`
  font-size: 33px;
  text-align: center;
  color: ${({ theme }) => theme.font.strong};
`;

const ModalDescription = styled.div`
  font-size: 16px;
  line-height: 27px;
  text-align: center;
  color: ${({ theme }) => theme.font.base};
`;
