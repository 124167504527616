import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';

import { LIST_DUE_DILIGENCE_RECORD } from '@/components/dealRegistry-page/queries';
import { IRecordData } from '@/components/dueDiligence-page/types';
import MobileModal from '@/components/mobileModal';
import { DATA_TYPE } from '@/components/table/filterPage';
import { ISort, SORT_ORDER } from '@/components/table/types';
import { useResponsive } from '@/hooks/use-responsive';
import { MainWrap, PaddingWrap } from '@/styles/common';
import { SELECTORS } from '@/types/enums';
import { TabsLine, TabsTitle } from '../basicComponents/fat-tabs';
import Header from '../fat-header';
import { initialValuesFromTable } from '../table/constants';
import FilterPage from '../table/fat-filterPage';
import { complianceSort, dueDiligenceSortValues } from './constatnts';
import { ComplianceEntityInfoList } from './mobileEntityInfoList';
import { MobileComplianceInvestmentInfoList } from './mobileInvestmentInfoList';
import { MobileComplianceInfoList } from './mobileStatusInfoList';
import { COMPLIANCE_QUERY } from './queries';
import Table from './table';
import TableDueDiligence from './tableDueDiligence';
import { COMPLIANCE, IRowData } from './types';

export type EntityType = {
  id: string;
  name: string;
  familyId: string;
};

const tabs = ['Allocation', 'Due Diligence'];

export const Compliance = () => {
  const { isMobile, isTablet, isPortrait } = useResponsive();
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();

  const activeTab = searchParams.get('tab') || tabs[0];
  const allocationSearch = searchParams.get('allocationSearch') || '';
  const dueDiligenceSearch = searchParams.get('dueDiligenceSearch') || '';
  const allocationPrimaryFilter = searchParams.get('allocationPrimaryFilter') || SELECTORS.ALL_ALLOCATION_STATUSES;
  const dueDiligencePrimaryFilter = searchParams.get('dueDiligencePrimaryFilter') || 'All Statuses';
  const allocationKey = searchParams.get('allocationKey') || 'statusDate';
  const allocationAsc = searchParams.get('allocationAsc') === 'true' || false;
  const dueDiligenceKey = searchParams.get('dueDiligenceKey') || 'CREATED_DATE';
  const dueDiligenceAsc = searchParams.get('dueDiligenceAsc') === 'true' || false;
  const allocationLimit = searchParams.get('allocationLimit') || initialValuesFromTable.initialAmountRowsPerPage[0];
  const allocationOffset = searchParams.get('allocationOffset') || 0;
  const dueDiligenceLimit = searchParams.get('dueDiligenceLimit') || initialValuesFromTable.initialAmountRowsPerPage[0];
  const dueDiligenceOffset = searchParams.get('dueDiligenceOffset') || 0;
  const position = searchParams.get('position') || 0;

  const selectors = ['All Allocation Statuses', 'Committed', 'Internal Review Rejected', 'Terminated'];
  const dueDiligenceSelectors = ['All Statuses', 'Approved', 'Rejected'];

  const [mobileSort, setMobileSort] = useState(complianceSort[9].title);
  const sortItem = complianceSort.find((item) => item.title === mobileSort);
  const mobileSortList = complianceSort.map((item) => item.title);

  const [dueDiligenceMobileSort, setDueDiligenceMobileSort] = useState(dueDiligenceSortValues[9].title);
  const dueDiligenceSortItem = dueDiligenceSortValues.find((item) => item.title === dueDiligenceMobileSort);
  const dueDiligenceMobileSortList = dueDiligenceSortValues.map((item) => item.title);

  const [complianceTotal, setComplianceTotal] = useState({
    total: 0,
    isFirstLoading: true
  });
  const [dueDiligenceTotal, setDueDiligenceTotal] = useState({
    total: 0,
    isFirstLoading: true
  });

  const [isEntityModalOpen, setIsEntityModalOpen] = useState(false);
  const [entityModalData, setEntityModalData] = useState<IRowData>();

  const [isInvestmentModalOpen, setIsInvestmentModalOpen] = useState(false);
  const [investmentModalData, setInvestmentModalData] = useState<IRowData>();

  const [isStatusModalOpen, setIsStatusModalOpen] = useState(false);
  const [statusModalData, setStatusModalData] = useState<IRowData>();

  const onEntityClick = (data: IRowData) => {
    setEntityModalData(data);
    setIsEntityModalOpen(true);
  };

  const onInvestmentClick = (data: IRowData) => {
    setInvestmentModalData(data);
    setIsInvestmentModalOpen(true);
  };

  const onStatusClick = (data: IRowData) => {
    setStatusModalData(data);
    setIsStatusModalOpen(true);
  };

  const handleFamilyClick = (id: string) => {
    saveScrollPostion();
    navigate(`/clients/${id}?backToTitle=Compliance`);
  };

  const handleEntityClick = (ids: EntityType) => {
    saveScrollPostion();
    const { familyId, id: entityId } = ids;

    navigate(`/clients/${familyId}/${entityId}?backToTitle=Compliance`);
  };

  const dueDiligenceFilterStatus = (status: string) => {
    switch (status) {
      case 'Approved':
        return ['APPROVED', 'OPEN', 'ACTIVE', 'REALIZED', 'REDEEMED'];
      case 'Rejected':
        return 'REJECTED';
      default:
        return '';
    }
  };

  const [complianceRows, setComplianceRows] = useState<IRowData[]>([]);
  const [dueDiligenceRows, setDueDiligenceRows] = useState<IRecordData[]>([]);

  const { data, loading, refetch } = useQuery<{ Compliance: COMPLIANCE }>(COMPLIANCE_QUERY, {
    notifyOnNetworkStatusChange: true,
    variables: {
      allocationFilter: allocationPrimaryFilter,
      order: allocationAsc ? SORT_ORDER.ASC : SORT_ORDER.DESC,
      sort: allocationKey,
      limit: Number(allocationLimit),
      offset: Number(allocationOffset),
      search: allocationSearch
    },
    onCompleted: (data) => {
      setComplianceRows(data.Compliance.allocations);
      if (complianceTotal.isFirstLoading) {
        setComplianceTotal({
          total: data.Compliance.total,
          isFirstLoading: false
        });
      }
    }
  });

  const {
    data: dueDiligenceData,
    loading: dueDiligenceLoading,
    refetch: dueDiligenceRefetch
  } = useQuery(LIST_DUE_DILIGENCE_RECORD, {
    notifyOnNetworkStatusChange: true,
    // fetchPolicy: 'network-only',
    variables: {
      data: {
        search: dueDiligenceSearch,
        sortBy: isMobile ? dueDiligenceSortItem?.key : dueDiligenceKey,
        sortDirection: isMobile ? (dueDiligenceSortItem?.asc ? SORT_ORDER.ASC : SORT_ORDER.DESC) : dueDiligenceAsc ? SORT_ORDER.ASC : SORT_ORDER.DESC,
        statusFilter:
          dueDiligencePrimaryFilter === 'All Statuses'
            ? ['APPROVED', 'REJECTED', 'OPEN', 'ACTIVE', 'REALIZED', 'REDEEMED']
            : dueDiligenceFilterStatus(dueDiligencePrimaryFilter),
        offset: Number(dueDiligenceOffset),
        limit: Number(dueDiligenceLimit)
      }
    },
    onCompleted: (data) => {
      setDueDiligenceRows(data.listDueDiligenceRecords.records);
      if (dueDiligenceTotal.isFirstLoading) {
        setDueDiligenceTotal({
          total: data.listDueDiligenceRecords.total,
          isFirstLoading: false
        });
      }
    }
  });

  useEffect(() => {
    if (activeTab === 'Allocation' && position && complianceRows.length) {
      makeScroll(position);
      return;
    }
    if (activeTab === 'Due Diligence' && position && dueDiligenceRows.length) {
      makeScroll(position);
      return;
    }
  }, [complianceRows, dueDiligenceRows]);

  const modalTitle = (allocationStatus: string) => {
    switch (allocationStatus) {
      case 'Internal Review Rejected':
        return 'Internal Review Rejected';
      case 'Committed':
        return 'Committed';
      case 'Terminated':
        return 'Terminated';
      default:
        return '';
    }
  };

  useEffect(() => {
    if (!isMobile) return;
    refetch({
      order: sortItem?.asc ? SORT_ORDER.ASC : SORT_ORDER.DESC,
      sort: sortItem?.key
    });
  }, [mobileSort]);

  useEffect(() => {
    if (activeTab) return;
    if (complianceTotal.total) {
      onChangeActiveTab(tabs[0]);
      return;
    }
    if (dueDiligenceTotal.total) {
      onChangeActiveTab(tabs[1]);
      return;
    }
  }, [complianceTotal.isFirstLoading, dueDiligenceTotal.isFirstLoading]);

  const onChangeActiveTab = (value: string) => {
    setSearchParams({ tab: value }, { replace: true });
  };

  const onChangeSearch = (searchValue: string) => {
    switch (activeTab) {
      case 'Allocation':
        setSearchParams(
          (prev) => {
            if (!searchValue) {
              prev.delete('allocationSearch');
              return prev;
            }
            prev.set('allocationSearch', searchValue);
            return prev;
          },
          { replace: true }
        );
        break;
      case 'Due Diligence':
        setSearchParams(
          (prev) => {
            if (!searchValue) {
              prev.delete('dueDiligenceSearch');
              return prev;
            }
            prev.set('dueDiligenceSearch', searchValue);
            return prev;
          },
          { replace: true }
        );
        break;
      default:
        break;
    }
  };

  const onChangePrimaryFilter = (filter: string) => {
    switch (activeTab) {
      case 'Allocation':
        setSearchParams(
          (prev) => {
            prev.set('allocationPrimaryFilter', filter);
            return prev;
          },
          { replace: true }
        );
        break;
      case 'Due Diligence':
        setSearchParams(
          (prev) => {
            prev.set('dueDiligencePrimaryFilter', filter);
            return prev;
          },
          { replace: true }
        );
        break;
      default:
        break;
    }
  };

  const onChangeSort = (value: ISort) => {
    switch (activeTab) {
      case 'Allocation':
        setSearchParams(
          (prev) => {
            prev.set('allocationKey', value.key);
            prev.set('allocationAsc', value.asc.toString());
            return prev;
          },
          { replace: true }
        );
        break;
      case 'Due Diligence':
        setSearchParams(
          (prev) => {
            prev.set('dueDiligenceKey', value.key);
            prev.set('dueDiligenceAsc', value.asc.toString());
            return prev;
          },
          { replace: true }
        );
        break;
      default:
        break;
    }
  };

  const onChangePaginationData = (limit: number, offset: number) => {
    switch (activeTab) {
      case 'Allocation':
        setSearchParams(
          (prev) => {
            prev.set('allocationLimit', limit.toString());
            prev.set('allocationOffset', offset.toString());
            return prev;
          },
          { replace: true }
        );
        break;
      case 'Due Diligence':
        setSearchParams(
          (prev) => {
            prev.set('dueDiligenceLimit', limit.toString());
            prev.set('dueDiligenceOffset', offset.toString());
            return prev;
          },
          { replace: true }
        );
        break;
      default:
        break;
    }
  };

  const saveScrollPostion = () => {
    setSearchParams(
      (prev) => {
        prev.set('position', window.scrollY.toString());
        return prev;
      },
      { replace: true }
    );
  };

  const makeScroll = (position: string | number) => {
    if (!position) return;
    setTimeout(() => window.scrollTo({ top: Number(position), behavior: 'smooth' }), 0);
    setSearchParams(
      (prev) => {
        prev.delete('position');
        return prev;
      },
      { replace: true }
    );
  };

  return (
    <MainWrap>
      {isEntityModalOpen && entityModalData ? (
        <MobileModal
          onClick={() =>
            handleEntityClick({
              id: entityModalData.legalEntity.id,
              name: entityModalData.legalEntity.entityName,
              familyId: entityModalData.family.name
            })
          }
          title={entityModalData.legalEntity.entityName}
          onClose={() => setIsEntityModalOpen(false)}
        >
          <ComplianceEntityInfoList
            onRedirect={() =>
              handleEntityClick({
                id: entityModalData.legalEntity.id,
                name: entityModalData.legalEntity.entityName,
                familyId: entityModalData.family.name
              })
            }
            data={entityModalData}
          />
        </MobileModal>
      ) : (
        <></>
      )}

      {isInvestmentModalOpen && investmentModalData ? (
        <MobileModal
          onClick={() =>
            navigate(`/investments/manager/investment/?mid=${investmentModalData.investment.name}&id=${investmentModalData.investment.id}`)
          }
          title={investmentModalData?.investment.name}
          onClose={() => setIsInvestmentModalOpen(false)}
        >
          <MobileComplianceInvestmentInfoList data={investmentModalData.investment} />
        </MobileModal>
      ) : (
        <></>
      )}

      {isStatusModalOpen && statusModalData ? (
        <MobileModal onClick={() => null} title={modalTitle(statusModalData.allocationStatus)} onClose={() => setIsStatusModalOpen(false)}>
          <MobileComplianceInfoList data={statusModalData} />
        </MobileModal>
      ) : (
        <></>
      )}
      <MainWrap>
        <Header />
        <PaddingWrap>
          <Tabs>
            {/* {tabs.map((tab, index) => (
            <TabsTitle key={tab + index} onClick={() => setActiveTab(tab)} activeTab={activeTab === tab}>
              {tab}
            </TabsTitle>
          ))} */}
            {complianceTotal.total || activeTab === 'Allocation' ? (
              <TabsTitle onClick={() => onChangeActiveTab(tabs[0])} activeTab={activeTab === tabs[0]}>
                {tabs[0]}
              </TabsTitle>
            ) : (
              <></>
            )}
            {dueDiligenceTotal.total || activeTab === 'Due Diligence' ? (
              <TabsTitle onClick={() => onChangeActiveTab(tabs[1])} activeTab={activeTab === tabs[1]}>
                {tabs[1]}
              </TabsTitle>
            ) : (
              <></>
            )}
          </Tabs>
        </PaddingWrap>
        <TabsLine />
      </MainWrap>
      <PaddingWrap>
        <CustomFilterPage
          isMobile={isMobile}
          isTablet={isTablet}
          withExportData
          search={{
            value: activeTab === 'Allocation' ? allocationSearch : dueDiligenceSearch,
            onChange: onChangeSearch,
            placeholder: activeTab === 'Allocation' ? 'Search entity, investment, client' : 'Search for an investment'
          }}
          primaryFilter={{
            value: activeTab === 'Allocation' ? allocationPrimaryFilter : dueDiligencePrimaryFilter,
            onChange: onChangePrimaryFilter,
            selects: activeTab === 'Allocation' ? selectors : dueDiligenceSelectors
          }}
          dataType={activeTab === 'Allocation' ? DATA_TYPE.COMPLIANCE : DATA_TYPE.DUE_DILIGENCE_RECORDS}
          resultsValue={activeTab === 'Allocation' ? data?.Compliance.total ?? 0 : dueDiligenceData?.listDueDiligenceRecords.total ?? 0}
          refetch={activeTab === 'Allocation' ? refetch : dueDiligenceRefetch}
          mobileSort={{
            value: activeTab === 'Allocation' ? mobileSort : dueDiligenceMobileSort,
            onChange: activeTab === 'Allocation' ? setMobileSort : setDueDiligenceMobileSort,
            selects: activeTab === 'Allocation' ? mobileSortList : dueDiligenceMobileSortList
          }}
        />
        {activeTab === 'Allocation' && (
          <Table
            onStatusClick={onStatusClick}
            onInvestmentClick={onInvestmentClick}
            onEntityClick={onEntityClick}
            handleFamilyClick={handleFamilyClick}
            handleEntityClick={handleEntityClick}
            complianceRows={complianceRows}
            loading={loading}
            refetch={refetch}
            saveScrollPostion={saveScrollPostion}
            savePagination={onChangePaginationData}
            paginationValues={{
              limit: Number(allocationLimit),
              offset: Number(allocationOffset),
              total: data?.Compliance.total ?? 0
            }}
            savedSort={{ key: allocationKey, asc: allocationAsc }}
            savedSetSort={onChangeSort}
          />
        )}
        {activeTab === 'Due Diligence' && (
          <TableDueDiligence
            dueDiligenceRows={dueDiligenceRows}
            savedSort={{ key: dueDiligenceKey, asc: dueDiligenceAsc }}
            savedSetSort={onChangeSort}
            refetch={dueDiligenceRefetch}
            loading={dueDiligenceLoading}
            saveScrollPostion={saveScrollPostion}
            savePagination={onChangePaginationData}
            paginationValues={{
              limit: Number(dueDiligenceLimit),
              offset: Number(dueDiligenceOffset),
              total: dueDiligenceData?.listDueDiligenceRecords.total ?? 0
            }}
          />
        )}
      </PaddingWrap>
    </MainWrap>
  );
};

const Tabs = styled.div`
  padding-top: 21px;
  padding-left: 30px;
  display: flex;
  gap: 5px;
`;

const CustomFilterPage = styled(FilterPage)<{ isMobile: boolean; isTablet: boolean }>`
  position: sticky;
  top: ${({ isMobile, isTablet }) => (isMobile || isTablet ? '50px' : '0')};
  z-index: 9;
  margin-top: 0;
  margin-left: -1px;
  width: calc(100% + 2px);
  padding: ${({ isMobile }) => (isMobile ? '20px 0 0 0' : '20px 0 10px 0')};
`;
