import { User } from '@/types/user';
import { ITier } from './dueDiligenceRecord/types';

export interface ITiersQuery {
  getDueDiligenceFunnel: {
    tiers: ITierData;
  };
}

export interface ITierData {
  records: IRecordData[];
  name: string;
  tasksCount: number;
  order: number;
  id: string;
}

export interface IRecordData {
  id: string;
  name: string;
  assetClass: string;
  funnel: {
    id: string;
    name: string;
  };
  investment: {
    id: string;
    name: string;
    finalClose: string;
    assetClass: string;
    managerId: string;
    targetIRR: number;
    targetYield: number;
    subAssetClasses: string[];
    minimum: number;
    vintageYear: number;
    nextClose: string;
    custodied: boolean;
    ubtiBlocker: boolean;
    sri: boolean;
    tags: string[];
    strategies: string[];
    securityStatus: string;
  };
  closeDate: string;
  owner: {
    firstName: string;
    lastName: string;
  };
  activeTier: {
    id: string;
    name: string;
    order: number;
    funnel: IFunnelData;
    tasksCount: number;
  };
  dealAssistantAIRuns: any[];
  tags: string[];
  manager: string;
  approver: User;
  updatedAt: Date;
  createdAt: Date;
  status: string;
  statusUpdatedAt: Date;
  note: string;
  activeTierOpenTasksCount: number;
  [key: string]: any;
}

export interface IFunnelData {
  funnelStaticId: string;
  externalId: string;
  id: string;
  name: string;
  createdAt: Date;
  status: string;
  draftFunnel: IFunnelData;
  archivedFunnels: IFunnelData[];
  records: IRecordData[];
  tiers: ITier[];
  latestFunnel: IFunnelData;
}

export enum AssetClass {
  PRIVATE_REAL_ASSETS = 'Private Real Assets',
  PRIVATE_CREDIT = 'Private Credit',
  PRIVATE_EQUITY = 'Private Equity'
}
