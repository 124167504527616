import styled, { useTheme } from 'styled-components';

import { NewIcon } from '@/assets/static/icons/todo-page/new';
import { OlderIcon } from '@/assets/static/icons/todo-page/older';
import { Loader } from '@/components/loaders/loader';
import guid from '@/utils/guid';
import { Container } from '../styles';

export const createList = (data: any) => {
  return [
    {
      title: 'Assigned To Me',
      subTitle: 'New',
      icon: '/static/icons/dashboard-page/email.svg',
      newToDo: true,
      value: data.newTaskCount || 0,
      bgColor: '#3dbd4a'
    },
    {
      title: 'Assigned  To Me',
      subTitle: 'Older than 2 Weeks',
      icon: '/static/icons/dashboard-page/tick.svg',
      older: true,
      value: data.oldTaskCount || 0,
      bgColor: '#EF9208'
    },
    {
      title: 'Total\n' + 'Assigned To Me',
      icon: '/static/icons/dashboard-page/cross.svg',
      value: data.assignedToMeTaskCount || 0
    },
    {
      title: 'Owned By Me/\n' + 'Assigned To Someone Else',
      icon: '/static/icons/dashboard-page/armor-tick.svg',
      value: data.ownedByMeNotAssignedToMeTaskCount || 0
    }
  ];
};

interface RecommendationsProps {
  data?: any;
  loading?: boolean;
  refetch?: any;
}

export const ToDoInfo = ({ data, loading, refetch }: RecommendationsProps) => {
  const theme = useTheme();

  return (
    <Container>
      {loading ? (
        <CustomLoader />
      ) : (
        <Cards>
          {createList(data).map(({ title, subTitle, icon, newToDo, older, value, bgColor }) => (
            <Card key={guid()}>
              <ImageContainer>
                <TitleContainer isOwned={title.startsWith('Owned') ? 1 : 0}>{value}</TitleContainer>
                <Notification>
                  {newToDo ? <NewIcon width={30} height={30} /> : null}
                  {older ? <OlderIcon /> : null}
                </Notification>
              </ImageContainer>
              {subTitle && <SubTitle bgColor={bgColor}>{subTitle}</SubTitle>}
              <CardTitle>{title}</CardTitle>
            </Card>
          ))}
        </Cards>
      )}
    </Container>
  );
};

const CustomLoader = styled(Loader)`
  min-height: 230px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SubTitle = styled.div<{ bgColor?: string }>`
  border: ${({ bgColor }) => `1px solid ${bgColor}`};
  border-radius: 5px;
  padding: 4px;
  font-family: 'Blinker', serif;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  text-align: center;
  color: ${({ bgColor }) => bgColor};
`;

const TitleContainer = styled.div<{ isOwned?: number }>`
  display: flex;
  align-items: center;
  column-gap: 10px;
  font-family: 'Blinker', serif;
  font-style: normal;
  font-weight: 600;
  font-size: 80px;
  line-height: 70px;
  text-align: center;
  color: ${({ isOwned, theme }) => (isOwned ? theme.font.weak : theme.font.base)};
`;

const Cards = styled.div`
  width: 100%;
  display: flex;
  padding-top: 20px;
  gap: 30px 20px;
  @media (max-width: 1200px) {
    flex-wrap: wrap;
  }
`;
const Card = styled.div`
  width: 23%;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 8px;
  @media (max-width: 1200px) {
    width: 47%;
  }
  @media (max-width: 768px) {
    width: 100%;
  }
`;
const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 120px;
  //min-height: 80px;
  position: relative;
  border-radius: 50%;
`;
const Notification = styled.div`
  border-radius: 14px;
  font-weight: 700;
  font-size: 16px;
`;
const CardTitle = styled.span`
  font-family: 'Blinker', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: ${({ theme }) => theme.font.weak};
  white-space: pre-line;
`;
