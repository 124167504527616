import Label from '@/components/basicComponents/label';
import FilterSelect from '@/components/basicComponents/select';
import Textarea from '@/components/basicComponents/textarea';
import { AssetClass } from '@/components/dueDiligence-page/types';
import { AddButton } from '@/components/fat-dealPipeline-page/fat-modify/addButton';
import { Section } from '@/components/fat-dealPipeline-page/fat-modify/section';
import { IListData } from '@/components/fat-dealPipeline-page/fat-modify/types';
import { addStrategies, delStrategies, onChangeStrategies } from '@/components/fat-dealPipeline-page/fat-modify/utils';
import { DatePicker, Input, Select } from '@/components/fat-form-field';
import { defaultAssetClassValue, defaultSelectValue } from '@/components/opportunities-entity/modify/constants';
import { blinkingFrame } from '@/components/table/styles';
import { useResponsive } from '@/hooks/use-responsive';
import { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';

interface DetailsSectionProps {
  control: any;
  onBlur?: any;
  errors: any;
  setError: any;
  managerList: string[];
  openModalWindow: (type: string) => void;
  strategies: IListData;
  setStrategies: Dispatch<SetStateAction<IListData>>;
  tags: IListData;
  setTags: Dispatch<SetStateAction<IListData>>;
  descriptionValue: string;
  setDescriptionValue: Dispatch<SetStateAction<string>>;
  userList: string[];
  funnelList: string[];
}

export const DetailsSection = ({
  control,
  onBlur,
  errors,
  setError,
  managerList,
  openModalWindow,
  strategies,
  setStrategies,
  tags,
  setTags,
  descriptionValue,
  setDescriptionValue,
  userList,
  funnelList
}: DetailsSectionProps) => {
  const { isMobile } = useResponsive();

  return (
    <Section title="Details">
      <DetailsSectionWrap>
        <Line isMobile={isMobile}>
          <Input
            title={'Name'}
            nameField={'name'}
            rules={{
              required: {
                value: true,
                message: 'Name is required'
              }
            }}
            control={control}
            placeHolder={'Friendly name for use internally and with clients'}
            isWarning={true}
          />
          <Select
            title={'Asset Class'}
            nameField={'assetClass'}
            control={control}
            defaultValue={defaultAssetClassValue}
            rules={{
              value: defaultAssetClassValue
            }}
            selects={[defaultAssetClassValue, AssetClass.PRIVATE_REAL_ASSETS, AssetClass.PRIVATE_CREDIT, AssetClass.PRIVATE_EQUITY]}
            isWarning={false}
          />
        </Line>
        <Line isMobile={isMobile}>
          <ManagerWrapper>
            <Select
              title={'Manager'}
              nameField={'manager'}
              control={control}
              rules={{
                value: defaultAssetClassValue
              }}
              defaultValue={defaultSelectValue}
              selects={[defaultSelectValue, ...managerList]}
              onBlur={onBlur}
              withSearch
            />
            <AddButton text="Create New" onClick={() => openModalWindow('create-manager')} />
          </ManagerWrapper>
          <DatePicker
            title={'Close Date'}
            nameField={'closeDate'}
            control={control}
            errors={errors}
            tooltip={'The date the investment period is expected to end.'}
            tooltipWidth={250}
          />
        </Line>
        <Line isMobile={isMobile}>
          <StrategiesWrap>
            <Label required={false} tooltip={'Used to categorize investments and useful for portfolio construction.'} tooltipWidth={300}>
              Strategies
            </Label>
            {strategies.chosen.map((el, index) => (
              <StrategiesItemWrap key={`strategies-${index}`} loading={index === strategies.chosen.length - 1 && strategies.duplicate ? 1 : 0}>
                <CustomFilterSelect
                  data={strategies.list}
                  selected={strategies.chosen[index]}
                  setSelected={(value) => onChangeStrategies(value, index, strategies, setStrategies)}
                  disabled={
                    strategies.chosen[strategies.chosen.length - 1] !== el || strategies.chosen[strategies.chosen.length - 1] === strategies.duplicate
                  }
                  width="100%"
                  withSearch
                  fontSize="16px"
                />
                <DelItemWrap onClick={() => delStrategies(el, index, strategies, setStrategies)}>
                  <img src="/static/icons/blue-cross.svg" alt="cross" />
                </DelItemWrap>
              </StrategiesItemWrap>
            ))}
            {strategies.list.length && strategies.chosen[strategies.chosen.length - 1] !== 'None' ? (
              <AddButton text="Add" onClick={() => addStrategies(strategies, setStrategies)} />
            ) : (
              <AddButton text="Create New" onClick={() => openModalWindow('create-strategy')} />
            )}
          </StrategiesWrap>

          <StrategiesWrap>
            <Label
              required={false}
              tooltip={'Call out fund structure, ESG focus, or any other common attributes across different funds.'}
              tooltipWidth={300}
            >
              Tags
            </Label>
            {tags.chosen.map((el, index) => (
              <StrategiesItemWrap key={`tags-${index}`} loading={index === tags.chosen.length - 1 && tags.duplicate ? 1 : 0}>
                <CustomFilterSelect
                  data={tags.list}
                  selected={tags.chosen[index]}
                  setSelected={(value) => onChangeStrategies(value, index, tags, setTags)}
                  disabled={tags.chosen[tags.chosen.length - 1] !== el || tags.chosen[tags.chosen.length - 1] === tags.duplicate}
                  width="100%"
                  withSearch
                  fontSize="16px"
                />
                <DelItemWrap onClick={() => delStrategies(el, index, tags, setTags)}>
                  <img src="/static/icons/blue-cross.svg" alt="cross" />
                </DelItemWrap>
              </StrategiesItemWrap>
            ))}
            {tags.list.length && tags.chosen[tags.chosen.length - 1] !== 'None' ? (
              <AddButton text="Add" onClick={() => addStrategies(tags, setTags)} />
            ) : (
              <AddButton text="Create New" onClick={() => openModalWindow('create-tag')} />
            )}
          </StrategiesWrap>
        </Line>
        <Line isMobile={isMobile}>
          <Input
            title={'Target IRR'}
            nameField={'targetIRR'}
            control={control}
            rules={{
              pattern: {
                value: /^(\d+(?:[\.\,]\d{1,2})?)$/,
                message: 'Only numbers are allowed'
              }
            }}
            placeHolder={'0%'}
          />
          <Input
            title={'Target Yield'}
            nameField={'targetYield'}
            control={control}
            rules={{
              pattern: {
                value: /^(\d+(?:[\.\,]\d{1,2})?)$/,
                message: 'Only numbers are allowed'
              }
            }}
            placeHolder={'0%'}
          />
          <Input
            title={'Vintage'}
            nameField={'vintage'}
            control={control}
            rules={{
              pattern: {
                value: /^\d{4}$/,
                message: 'Enter a year using four digits'
              }
            }}
            placeHolder={'Enter a year using four digits'}
            tooltip={'For the purposes of portfolio diversification.'}
          />
        </Line>
        <Line isMobile={isMobile}>
          <Select
            title={'Funnel'}
            nameField={'funnel'}
            control={control}
            defaultValue={defaultSelectValue}
            rules={{
              required: {
                value: true,
                message: 'Funnel is required'
              },
              value: defaultSelectValue
            }}
            selects={funnelList}
            isWarning={true}
            setError={setError}
            // disabled={Boolean(router.query.record)}
          />
          <Select
            title={'Due Diligence Owner'}
            nameField={'owner'}
            control={control}
            defaultValue={defaultSelectValue}
            rules={{
              required: {
                value: true,
                message: 'Due Diligence Owner is required'
              },
              value: defaultSelectValue
            }}
            selects={userList}
            isWarning={true}
            setError={setError}
          />
        </Line>
        <Line isMobile={isMobile}>
          <Input
            title={'Contact'}
            nameField={'contact'}
            rules={{
              required: {
                value: false,
                message: 'Contact is required'
              }
            }}
            control={control}
            placeHolder={'Representative name'}
            isWarning={false}
          />
          <Input
            title={'Referral'}
            nameField={'referral'}
            rules={{
              required: {
                value: false,
                message: 'Referral is required'
              }
            }}
            control={control}
            placeHolder={'Name of individual who referred you to the opportunity '}
            isWarning={false}
          />
        </Line>
        <Line isMobile={isMobile}>
          <DescriptionWrapper>
            <Label required={false} tooltip="This appears on content generated for your internal team or investors." tooltipWidth={250}>
              Description
            </Label>
            <Textarea value={descriptionValue} setValue={setDescriptionValue} maxLength={250} textareaHeight={isMobile ? '110px' : '66px'} />
          </DescriptionWrapper>
        </Line>
      </DetailsSectionWrap>
    </Section>
  );
};

const DetailsSectionWrap = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 20px;
`;

const Line = styled.span<{ isMobile: boolean; width50?: boolean }>`
  display: flex;
  justify-content: space-between;
  flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};
  width: ${({ width50, isMobile }) => (width50 && !isMobile ? '50%' : '100%')};
  gap: 30px;
  padding-right: ${({ width50, isMobile }) => (width50 && !isMobile ? '15px' : '0')};
  margin-top: 4px;
`;

const ManagerWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const StrategiesWrap = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

const CustomFilterSelect = styled(FilterSelect)`
  padding: 10px 12px;
  border-radius: 5px;

  #titleSelect {
    text-transform: none;
    font-family: Blinker;
    font-size: 16px;
    font-weight: 400;
    line-height: 22.4px;
  }
`;

const StrategiesItemWrap = styled.div<{ loading?: boolean | number }>`
  display: flex;
  width: 100%;
  align-items: center;
  margin-bottom: 10px;
  position: relative;

  opacity: ${({ loading }) => (loading ? 0.5 : 1)};
  pointer-events: ${({ loading }) => (loading ? 'none' : 'auto')};
  animation: ${({ loading }) => (loading ? blinkingFrame : null)} 1.5s infinite;
`;

const DelItemWrap = styled.div`
  position: relative;
  top: 3px;
  padding-left: 15px;
  cursor: pointer;
`;

const DescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
