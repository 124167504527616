import styled, { useTheme } from 'styled-components';

import BasicTooltip from '@/components/basicComponents/tooltip';
import { Status } from './index';

interface NextStepStatusRowProps {
  status: string;
  isMobile?: boolean;
  nextStepTitle?: string;
}

export const NextStepStatusRow = ({ status, isMobile = false, nextStepTitle = 'Next step:' }: NextStepStatusRowProps) => {
  const theme = useTheme();

  if (isMobile) {
    return (
      <BasicTooltip tooltipContent={status} shouldShowContent={isMobile}>
        <Row>
          <NextStep>{nextStepTitle}</NextStep>
          <Status isMobile={isMobile} color={theme.context.light}>
            {status}
          </Status>
        </Row>
      </BasicTooltip>
    );
  }

  return (
    <Row>
      <NextStep>{nextStepTitle}</NextStep>
      <Status isMobile={isMobile} color={theme.context.light}>
        {status}
      </Status>
    </Row>
  );
};
const Row = styled.div`
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  border-radius: 4px;
  padding: 7px 12px;
  background: ${({ theme }) => theme.action.active};
`;

const NextStep = styled.span`
  font-family: 'Blinker';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: ${({ theme }) => theme.context.light};
  text-transform: uppercase;
`;
