import * as React from 'react';

export const RejectedIcon = ({ ...props }: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.90462 14.2993H12.102V16.5033H9.90462V14.2993ZM9.90462 5.5033H12.102V12.102H9.90462V5.5033ZM10.9934 7.92485e-06C8.81811 0.00131285 6.69199 0.647565 4.88392 1.85705C3.07585 3.06654 1.66698 4.78494 0.835431 6.79504C0.0038797 8.80514 -0.213008 11.0167 0.212174 13.15C0.637356 15.2834 1.68551 17.2428 3.22415 18.7805C4.76279 20.3182 6.72281 21.3652 8.85642 21.7891C10.99 22.213 13.2014 21.9948 15.211 21.162C17.2206 20.3293 18.9382 18.9194 20.1466 17.1106C21.355 15.3018 22 13.1753 22 11C22.0009 9.55464 21.7167 8.12334 21.1638 6.78793C20.6109 5.45253 19.8001 4.23925 18.7777 3.21755C17.7554 2.19584 16.5417 1.38577 15.206 0.833662C13.8702 0.281557 12.4388 -0.00172744 10.9934 7.92485e-06ZM10.9934 19.796C9.25214 19.7973 7.54957 19.2822 6.10112 18.3157C4.65267 17.3493 3.52338 15.9749 2.85611 14.3666C2.18885 12.7582 2.01361 10.9881 2.35251 9.28014C2.69142 7.57216 3.52927 6.00307 4.76008 4.77134C5.99089 3.53961 7.55934 2.70058 9.26706 2.36039C10.9748 2.0202 12.745 2.19413 14.3539 2.86018C15.9627 3.52624 17.3379 4.6545 18.3054 6.10223C19.273 7.54996 19.7894 9.25212 19.7894 10.9934C19.7912 13.3257 18.8673 15.5634 17.2206 17.2151C15.5738 18.8667 13.3389 19.7974 11.0066 19.8026L10.9934 19.796Z"
        fill="#ABABAB"
      />
    </svg>
  );
};
