import ModalWrappedContent from '@/components/fat-basicComponents/modal/modalWrappedContent';
import { Loader } from '@/components/loaders/loader';
import styled from 'styled-components';

interface LoadingProps {
  isOpen: boolean;
  title: string;
  description: string;
}

const Loading = ({ isOpen, title, description }: LoadingProps) => {
  return (
    <ModalWrappedContent isOpen={isOpen} onClose={() => null} showCloseIcon={false} showCancelButton={false}>
      <ModalBody>
        <Title>{title}</Title>
        <ModalText>{description}</ModalText>
        <Loader />
      </ModalBody>
    </ModalWrappedContent>
  );
};

export default Loading;

const ModalBody = styled.div`
  text-align: center;
`;

const Title = styled.h1`
  font-family: 'Blinker', serif;
  font-weight: 400;
  font-size: 33px;
  text-align: center;
  color: ${({ theme }) => theme.font.strong};
  margin-bottom: 10px;
`;

const ModalText = styled.div<{ bold?: boolean }>`
  display: inline-block;
  text-align: center;
  font-size: 19px;
  font-weight: ${({ bold }) => (bold ? 600 : 400)};
  margin-left: ${({ bold }) => (bold ? '3px' : 0)};
  color: ${({ theme }) => theme.font.base};
`;
