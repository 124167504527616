import { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';

import { getUTCDate } from '@/components/allocations-page/utils';
import { Loader } from '@/components/loaders/loader';
import DropdownActions from '@/components/table/fat-dropdownActions';
import TableHeader from '@/components/table/fat-tableHeader';
import RedirectTitleCell from '@/components/table/redirectTitleCell';
import { TableBodyWrapper, TableRowCell, TableRowWrapper, TableWrapper, TextCell } from '@/components/table/styles';
import { ISort } from '@/components/table/types';
import guid from '@/utils/guid';
import { IRecordData } from '../types';
import { columns } from './../constatnts';

type TableProp = {
  sort: ISort;
  setSort: Dispatch<SetStateAction<ISort>>;
  tasksCount: number;
  records: IRecordData[];
  handleTierClick: (row: IRecordData) => void;
  openModalWindow: (type: string, row: IRecordData) => void;
  handleModifyDiligenceClick: (row: IRecordData) => void;
  updateDueDiligenceRecordLoading: boolean;
  currentRow: IRecordData | null;
  loading: boolean;
};

export const Table = ({
  records,
  handleTierClick,
  tasksCount,
  sort,
  setSort,
  openModalWindow,
  handleModifyDiligenceClick,
  updateDueDiligenceRecordLoading,
  currentRow,
  loading
}: TableProp) => {
  return (
    <CustomTableWrapper>
      <TableHeader refetch={(): void => null} columns={columns} savedSort={sort} savedSetSort={setSort} />
      <TableBodyWrapper>
        {loading ? (
          <Loader />
        ) : (
          <>
            {records.map((row, index) => (
              <TableRowWrapper key={guid()} loading={updateDueDiligenceRecordLoading && row.id === currentRow?.id ? 1 : 0}>
                <TableRowCell width={columns[0].width} onClick={() => handleTierClick(row)}>
                  <RedirectTitleCell>{row.investment.name}</RedirectTitleCell>
                </TableRowCell>
                <TableRowCell width={columns[1].width}>
                  <TextCell>{row.owner.firstName + ' ' + row.owner.lastName}</TextCell>
                </TableRowCell>
                <TableRowCell width={columns[2].width}>
                  <TextCell>{row.investment?.finalClose && getUTCDate(new Date(row.investment.finalClose))}</TextCell>
                </TableRowCell>
                <TableRowCell width={columns[3].width}>
                  <OpenTasks>
                    <CountTasks>{row.activeTierOpenTasksCount}</CountTasks>
                    <ProgressWrap>
                      <Progress
                        isFull={Boolean(
                          100 - (row.activeTierOpenTasksCount * 100) / (row.activeTier?.tasksCount ? row.activeTier.tasksCount : tasksCount)
                        )}
                        width={100 - (row.activeTierOpenTasksCount * 100) / (row.activeTier?.tasksCount ? row.activeTier.tasksCount : tasksCount)}
                      />
                    </ProgressWrap>
                  </OpenTasks>
                </TableRowCell>
                <TableRowCell width={columns[4].width}>
                  <DropdownActions
                    items={[
                      {
                        name: 'View Tier',
                        onClick: () => handleTierClick(row)
                      },
                      {
                        name: 'Change Owner',
                        onClick: () => openModalWindow('change-owner', row)
                      },
                      {
                        name: 'Edit',
                        onClick: () => handleModifyDiligenceClick(row)
                      },
                      {
                        name: 'Download PDF',
                        onClick: () => openModalWindow('download-pdf', row)
                      }
                    ]}
                  />
                </TableRowCell>
              </TableRowWrapper>
            ))}
          </>
        )}
      </TableBodyWrapper>
    </CustomTableWrapper>
  );
};

const CustomTableWrapper = styled(TableWrapper)`
  padding: 20px 0 0 0;
`;

const ProgressWrap = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-start;
  overflow: hidden;
  max-width: 198px;
  width: 100%;
  height: 20px;
  border-radius: 10px;
  background: ${({ theme }) => theme.layer[2]};
  max-height: 20px;
`;

const Progress = styled.div<{ width: number | string; isFull: boolean }>`
  display: flex;
  width: ${({ width }) => (width ? `${width}%` : '100%')};
  height: 20px;
  background: ${({ isFull, theme }) => (isFull ? theme.context.success : theme.layer[2])};
  transition: 0.3s ease-in-out;
`;

const OpenTasks = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 30px;
`;

const CountTasks = styled(TextCell)`
  font-weight: 700;
  font-size: 28px;
  line-height: 39px;
`;
