import { FilterSettingIcon } from '@/assets/icons/filterSetting';
import Button from '@/components/fat-basicComponents/button';
import ModalWrappedContent from '@/components/fat-basicComponents/modal/modalWrappedContent';
import { Dispatch, SetStateAction, useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { ColumnsDisplayed } from '../columnsDisplayed';
import { SelectedColumnsList } from '../selectedColumnsList';
import { ColumnList } from '../types';

interface EditTableSettingsProps {
  onClose: () => void;
  isOpen: boolean;
  columnsList: ColumnList[];
  setColumnsList: Dispatch<SetStateAction<ColumnList[]>>;
}

const EditTableSettings = ({ onClose, isOpen, columnsList, setColumnsList }: EditTableSettingsProps) => {
  const theme = useTheme();

  const [search, setSearch] = useState('');
  const [newColumns, setNewColumns] = useState(columnsList.map((column) => ({ ...column })));

  const saveNewColumns = () => {
    setColumnsList(newColumns);
    const updatedOrder = newColumns.map((column) => {
      return { rowKey: column.rowKey, value: column.value };
    });
    localStorage.setItem('dealRegistryColumnsOrder', JSON.stringify(updatedOrder));
    onClose();
  };

  return (
    <ModalWrappedContent width="1400px" isOpen={isOpen} onClose={onClose} title={''} showCancelButton={false}>
      <ModalTitle>
        <FilterSettingIcon fill={theme.font.strong} width={29} height={29} />
        Choose Which Columns You See
      </ModalTitle>
      <ModalContentWrapper>
        <ColumnsDisplayed newColumns={newColumns} setNewColumns={setNewColumns} />
        <SelectedColumnsList newColumns={newColumns} setNewColumns={setNewColumns} />
      </ModalContentWrapper>
      <ActionButtons>
        <Button onClick={onClose} styleType="outline" size="md">
          Cancel
        </Button>
        <Button onClick={saveNewColumns} size="md">
          Apply
        </Button>
      </ActionButtons>
    </ModalWrappedContent>
  );
};

export default EditTableSettings;

const ModalTitle = styled.div`
  position: absolute;
  top: 28px;
  left: 65px;
  font-weight: 600;
  font-size: 22px;
  display: flex;
  align-items: center;
  gap: 6px;
  color: ${({ theme }) => theme.font.strong};
`;

export const ModalContentWrapper = styled.div`
  display: flex;
  gap: 30px;
`;

const ActionButtons = styled.div`
  display: flex;
  gap: 10px;
  position: absolute;
  right: 60px;
  bottom: 25px;
`;
