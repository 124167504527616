import { useAuth } from '@/hooks/use-auth';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export function PrivateRoute({ children }: React.PropsWithChildren): JSX.Element {
  const auth = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [checked, setChecked] = useState(false);

  useEffect(
    () => {
      if (!auth.isInitialized) {
        return;
      }

      if (!auth.isAuthenticated) {
        navigate('/login', {
          state: { returnUrl: location }
        });
      } else {
        setChecked(true);
      }
    },
    // eslint-disable-next-line
    [auth.isInitialized, auth.isAuthenticated]
  );

  if (!checked) {
    return null;
  }

  // If got here, it means that the redirect did not occur, and that tells us that the user is
  // authenticated / authorized.

  return children as JSX.Element;
}
