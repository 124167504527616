import FilterSelect from '@/components/basicComponents/select';
import ModalWrappedContent from '@/components/fat-basicComponents/modal/modalWrappedContent';
import { useMutation, useQuery } from '@apollo/client';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { ICommitmentPlan, PaginatedCommitmentPlans } from '../../fat-pacingTab/types';
import { COMMITMENT_PLANS_MIN_QUERY, deleteCommitmentPlanMutation, updateCommitmentPlanMutation } from '../../queries';

interface DeletePlanProps {
  onClose: () => void;
  isOpen: boolean;
  currentRow: ICommitmentPlan | null;
  refetch: () => void;
}

const DeletePlan = ({ onClose, isOpen, currentRow, refetch }: DeletePlanProps) => {
  const params = useParams();

  const [selectedPlan, setSelectedPlan] = useState<string>('Select another plan');

  const [deleteCommitmentPlan, { loading: deleteCommitmentPlanLoading }] = useMutation(deleteCommitmentPlanMutation, {
    onError: (error) => {
      console.error(error);
    }
  });

  const [updateCommitmentPlan, { loading: updateCommitmentPlanLoading }] = useMutation(updateCommitmentPlanMutation, {
    onError: (error) => {
      console.error(error);
    }
  });

  const { data: commitmentPlansData, loading: commitmentPlansLoading } = useQuery<{ commitmentPlans: PaginatedCommitmentPlans }>(
    COMMITMENT_PLANS_MIN_QUERY,
    {
      notifyOnNetworkStatusChange: true,
      skip: !params?.entityId,
      variables: {
        input: {
          legalEntityId: params?.entityId,
          limit: 1000
        }
      }
    }
  );

  const deletePlan = () => {
    if (commitmentPlansData?.commitmentPlans.total > 1 && currentRow.active) {
      const selectedPlanId = commitmentPlansData.commitmentPlans.data.find((plan) => plan.name === selectedPlan)?.id;
      if (!selectedPlanId) return;
      updateCommitmentPlan({
        variables: {
          input: {
            id: selectedPlanId,
            active: true
          }
        },
        onCompleted: () => {
          deleteCommitmentPlan({
            variables: {
              id: currentRow.id
            },
            onCompleted: () => {
              refetch();
              onClose();
            }
          });
        }
      });
      return;
    }
    deleteCommitmentPlan({
      variables: {
        id: currentRow.id
      },
      onCompleted: () => {
        refetch();
        onClose();
      }
    });
  };

  if (!currentRow) return <></>;

  return (
    <ModalWrappedContent
      isOpen={isOpen}
      onClose={onClose}
      title="Delete Commitment Plan"
      confirmButton={{
        name: 'Confirm',
        disabled:
          (commitmentPlansData?.commitmentPlans.total > 1 && currentRow.active && selectedPlan === 'Select another plan') || commitmentPlansLoading,
        onClick: deletePlan
      }}
    >
      <ModalBody>
        <SubTitle>
          Please confirm that you want to permanently delete this commitment Plan (<span style={{ fontWeight: 700 }}>{currentRow.name}</span>). This
          action cannot be undone.
        </SubTitle>
        {commitmentPlansData?.commitmentPlans.total > 1 && currentRow.active && (
          <CommitmentPlansSelect
            data={commitmentPlansData.commitmentPlans.data.map((plan) => plan.name).filter((name) => name !== currentRow.name)}
            selected={selectedPlan}
            setSelected={setSelectedPlan}
            width="100%"
            fontSize="16px"
            label="Select one of the remaining plans to make it the active commitment plan"
            required
          />
        )}
      </ModalBody>
    </ModalWrappedContent>
  );
};

export default DeletePlan;

const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 35px;
`;

const SubTitle = styled.p`
  text-align: center;
  font-size: 19px;
  font-weight: 400;
  line-height: 26.6px;
  color: ${({ theme }) => theme.font.base};
`;

const CommitmentPlansSelect = styled(FilterSelect)`
  padding: 10px 12px;
  border-radius: 5px;

  #titleSelect {
    text-transform: none;
    line-height: 22.4px;
  }
`;
