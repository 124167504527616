export const columns = [
  { title: 'Investment', key: 'INVESTMENT_NAME', width: 25, keySort: 'investment' },
  { title: 'Owner', key: 'OWNER_NAME', width: 25, keySort: 'owner' },
  { title: 'Close Date', key: 'CLOSE_DATE', width: 25, keySort: 'final close' },
  { title: 'Open Tasks', key: 'OPEN_TASKS', width: 25, keySort: 'open tasks' },
  { title: ' ', key: ' ', width: 4 },
];

export const dueDiligenceFunnelSort = [
  {
    title: 'Investment • A -> Z',
    key: 'INVESTMENT_NAME',
    asc: true,
  },
  {
    title: 'Investment • Z -> A',
    key: 'INVESTMENT_NAME',
    asc: false,
  },
  {
    title: 'Owner • Max -> Min',
    key: 'OWNER_NAME',
    asc: false,
  },
  {
    title: 'Owner • Min -> Max',
    key: 'OWNER_NAME',
    asc: true,
  },
  {
    title: 'Close Date • Max -> Min',
    key: 'CLOSE_DATE',
    asc: false,
  },
  {
    title: 'Close Date • Min -> Max',
    key: 'CLOSE_DATE',
    asc: true,
  },
  {
    title: 'Open Tasks • Max -> Min',
    key: 'OPEN_TASKS',
    asc: false,
  },
  {
    title: 'Open Tasks • Min -> Max',
    key: 'OPEN_TASKS',
    asc: true,
  },
];

export const dataRoomColumns = [
  { title: 'Name', key: 'NAME', width: 33, keySort: 'name' },
  { title: 'Last Modified', key: 'LAST_MODIFIED', width: 33, keySort: 'nextClose' },
  { title: 'File Size', key: 'FILE_SIZE', width: 33, keySort: 'size' },
  { title: ' ', key: ' ', width: 4 },
];
