import { AddButtonIcon } from '@/assets/static/icons/add-button-icon';
import { IFunnelData, IRecordData } from '@/components/dueDiligence-page/types';
import Button from '@/components/fat-basicComponents/button';
import { Loader } from '@/components/loaders/loader';
import { GET_SETTINGS } from '@/components/settings-page/queries';
import { AdditionalFilter, FiltersItems } from '@/components/table/filterPage/types';
import { createNewFilter } from '@/components/table/filterPage/utils';
import TablePagination from '@/components/table/tablePagination';
import { ISort, SORT_ORDER } from '@/components/table/types';
import { useResponsive } from '@/hooks/use-responsive';
import { MainWrap, PaddingWrap } from '@/styles/common';
import { NetworkStatus, useMutation, useQuery } from '@apollo/client';
import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import { GET_DUE_DILIGENCE_LIMITS, UPDATE_INVESTMENT, UPDATE_RECORD_STATUS_MUTATION } from '../dueDiligence-page/queries';
import Header from '../fat-header';
import { initialValuesFromTable } from '../table/constants';
import FilterPage from '../table/fat-filterPage';
import { columns, dealRegistrySelectors, dealRegistrySort } from './constatnts';
import AiSuggestions from './modals/aiSuggestions';
import DeleteDeal from './modals/deleteDeal';
import EditTableSettings from './modals/editTableSettings';
import EditTableSettingsMobile from './modals/editTableSettingsMobile';
import EditTableSettingsPage from './modals/editTableSettingsPage';
import StartDueDiligence from './modals/startDueDiligence';
import { DELETE_DUE_DILIGENCE_RECORD_MUTATION, LIST_DUE_DILIGENCE_FUNNELS_DISTINCT_STATIC_ID_QUERY, LIST_DUE_DILIGENCE_RECORD } from './queries';
import { TableBody } from './table/tableBody';
import TableHeader from './table/tableHeader';
import MobileTable from './table/tableMobile';
import { ASSET_CLASS, ColumnList, DealRegistryFiltersStructure } from './types';
import { dealRegistryFilterStatus } from './utils';

export const DealRegistry = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { isMobile, isTablet, isPortrait } = useResponsive();
  const [searchParams, setSearchParams] = useSearchParams();

  const q = searchParams.get('q') || '';
  const key = searchParams.get('key') || 'CREATED_DATE';
  const asc = searchParams.get('asc') === 'true' || false;
  const primaryFilter = searchParams.get('primaryFilter') || 'All Statuses';
  const limit = searchParams.get('limit') || initialValuesFromTable.initialAmountRowsPerPage[0];
  const offset = searchParams.get('offset') || 0;
  const assetClassParams = searchParams.get('assetClass') || '';
  const funnelParams = searchParams.get('funnel') || '';

  const [dealRegistryRows, setDealRegistryRows] = useState<IRecordData[]>([]);
  const [isModalWindowOpen, setIsModalWindowOpen] = useState({
    isOpen: false,
    type: 'edit-table'
  });
  const [windowType, setWindowType] = useState({
    isOpen: false,
    typeWindow: 'default'
  });
  const [columnsList, setColumnsList] = useState<ColumnList[]>(columns);
  const [currentRow, setCurrentRow] = useState<IRecordData | null>(null);
  const [windowWidth, setWindowWidth] = useState(0);
  const [funnelList, setFunnelList] = useState<string[]>([]);
  const [additionalFilters, setAdditionalFilters] = useState<AdditionalFilter[]>(() => getAdditionalFilters());
  const [filtersItems, setFiltersItems] = useState<FiltersItems>({
    assetClass: ['All Asset Classes', ...Object.values(ASSET_CLASS)],
    funnel: ['All Funnels']
  });
  const [mobileSort, setMobileSort] = useState(dealRegistrySort[2].title);
  const sortItem = dealRegistrySort.find((item) => item.title === mobileSort);
  const mobileSortList = dealRegistrySort.map((item) => item.title);
  const [isFirstLoading, setIsFirstLoading] = useState(false);
  const [trialLimits, setTrialLimits] = useState({
    trialMode: false,
    canCreateDeal: true
  });
  const [selectedFunnelsFilter, setSelectedFunnelsFilter] = useState<string[]>([]);
  const [total, setTotal] = useState(0);
  const containerRef = useRef<HTMLDivElement | null>(null);

  function getAdditionalFilters() {
    const matchedAdditionalFilters = [];

    if (!assetClassParams && !funnelParams) {
      return [createNewFilter('Select A Filter Option')];
    }

    if (assetClassParams) {
      for (const filterItem of JSON.parse(assetClassParams)) {
        matchedAdditionalFilters.push({ ...createNewFilter('assetClass', filterItem), listOfItems: Object.values(ASSET_CLASS) });
      }
    }
    if (funnelParams) {
      for (const filterItem of JSON.parse(funnelParams)) {
        matchedAdditionalFilters.push(createNewFilter('funnel', filterItem));
      }
    }

    return matchedAdditionalFilters;
  }

  useEffect(() => {
    if (!isMobile || window.innerHeight > 890) {
      setWindowType({ ...windowType, typeWindow: 'default' });
    }
  }, [isMobile, window.innerHeight]);

  useEffect(() => {
    if (!containerRef.current) return;
    setWindowWidth(containerRef.current.clientWidth);

    const handleResize = () => {
      if (!containerRef.current) return;
      setWindowWidth(containerRef.current.clientWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    setFiltersItems({
      ...filtersItems,
      funnel: ['All Funnels', ...funnelList]
    });
  }, [funnelList]);

  useEffect(() => {
    const newColumnsList = localStorage.getItem('dealRegistryColumnsOrder');
    if (newColumnsList) {
      const parsedColumnsList = JSON.parse(newColumnsList);

      const updatedColumns = parsedColumnsList.map((storedColumn: { [key: string]: string | boolean }) => {
        const matchingStoredColumn = columns.find((column) => column.rowKey === storedColumn.rowKey);
        if (matchingStoredColumn) {
          return { ...matchingStoredColumn, value: storedColumn.value };
        }
      });

      setColumnsList(updatedColumns);
    }
  }, []);

  const unitedFilters = additionalFilters.reduce<DealRegistryFiltersStructure>(
    (acc, { selected, typeFilter }) => {
      return acc[typeFilter as keyof DealRegistryFiltersStructure]
        ? {
            ...acc,
            [typeFilter]: [...acc[typeFilter as keyof DealRegistryFiltersStructure], selected]
          }
        : acc;
    },
    {
      assetClass: [],
      funnel: []
    }
  );

  const { data: funnelListData } = useQuery(LIST_DUE_DILIGENCE_FUNNELS_DISTINCT_STATIC_ID_QUERY, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setFunnelList(data.listDueDiligenceFunnelsDistinctStaticId.map((funnel: IFunnelData) => funnel.name));
    }
  });

  useEffect(() => {
    if (funnelListData) {
      const selectedFunnels = unitedFilters.funnel
        .map((funnelName) => {
          const foundFunnel = funnelListData.listDueDiligenceFunnelsDistinctStaticId.find((funnel: IFunnelData) => {
            return funnelName === funnel.name;
          });

          if (foundFunnel) {
            return foundFunnel.funnelStaticId;
          }
          return null;
        })
        .filter((funnelStaticId) => funnelStaticId !== null);

      setSelectedFunnelsFilter(selectedFunnels);
    }
  }, [additionalFilters, funnelListData]);

  const [moduleConfig, setModuleConfig] = useState(undefined);
  const { data: settingsData } = useQuery(GET_SETTINGS, {
    variables: {
      type: 'appSetting'
    },
    onCompleted: (data) => {
      setModuleConfig(JSON.parse(settingsData.Settings[0].value));
    }
  });

  useQuery(GET_DUE_DILIGENCE_LIMITS, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      const { trialMode, canCreateDeal } = data.getDueDiligenceLimits;

      setTrialLimits({ trialMode, canCreateDeal });
    }
  });

  const {
    data: dealRegistryData,
    loading: dealRegistryLoading,
    refetch: dealRegistryRefetch,
    networkStatus: dealRegistryNetworkStatus
  } = useQuery(LIST_DUE_DILIGENCE_RECORD, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    variables: {
      data: {
        sortBy: isMobile ? sortItem?.key : key,
        sortDirection: isMobile ? (sortItem?.asc ? SORT_ORDER.ASC : SORT_ORDER.DESC) : asc ? SORT_ORDER.ASC : SORT_ORDER.DESC,
        statusFilter: dealRegistryFilterStatus(primaryFilter),
        assetClassFilter: unitedFilters.assetClass.map((item) =>
          item === ASSET_CLASS.PRIVATE_REAL_ASSETS ? 'PRIVATE_REAL_ESTATE' : item.toUpperCase().replace(/\s/g, '_')
        ),
        funnelStaticIdFilter: selectedFunnelsFilter,
        search: q.toLowerCase(),
        offset: Number(offset),
        limit: Number(limit)
      }
    },
    onCompleted: (data) => {
      const updatedRecords = data.listDueDiligenceRecords.records.map((record: IRecordData) => {
        const newActiveTier = record.activeTier.funnel.latestFunnel.tiers.find((tier) => {
          if (tier.order === record.activeTier.order) {
            return tier;
          }
        });

        if (newActiveTier) {
          return {
            ...record,
            activeTier: { ...record.activeTier, name: newActiveTier.name }
          };
        }
        return record;
      });

      setDealRegistryRows(updatedRecords);
      setTotal(data.listDueDiligenceRecords.total);
      setIsFirstLoading(true);
    }
  });

  useEffect(() => {
    const scrollPosition = 0;

    if (scrollPosition) {
      window.scrollTo(0, Number(scrollPosition));
    }
  }, [isFirstLoading]);

  const [deleteRecord, { loading: deleteRecordLoading }] = useMutation(DELETE_DUE_DILIGENCE_RECORD_MUTATION);

  const [updateRecordStatus, { loading: updateRecordStatusLoading }] = useMutation(UPDATE_RECORD_STATUS_MUTATION);

  const [updateInvestment, { loading: updateInvestmentStatusLoading }] = useMutation(UPDATE_INVESTMENT);

  const handleUpdateInvestmentStatus = (row: IRecordData, status: string, withRedirect?: boolean) => {
    setCurrentRow(row);
    updateInvestment({
      variables: {
        data: {
          id: row.investment.id,
          securityStatus: status
        }
      },
      onCompleted: () => {
        setDealRegistryRows((prevDealRegistryRow) =>
          prevDealRegistryRow.map((item) => {
            if (row.id === item.id) {
              return { ...item, investment: { ...item.investment, securityStatus: status } };
            }
            return item;
          })
        );
        setCurrentRow(null);
        withRedirect && handleClickDeal(row);
      }
    });
  };

  const startDueDiligence = (row: IRecordData, status: string, withRedirect?: boolean) => {
    setCurrentRow(row);
    updateRecordStatus({
      variables: {
        data: {
          id: row.id,
          status: status
        }
      },
      onCompleted: () => {
        setDealRegistryRows((prevDealRegistryRow) =>
          prevDealRegistryRow.map((item) => {
            if (row.id === item.id) {
              return { ...item, status: status };
            }
            return item;
          })
        );
        setCurrentRow(null);
        withRedirect && handleClickDeal(row);
      }
    });
  };

  const handleDeleteDeal = (id: string) => {
    deleteRecord({
      variables: {
        id
      },
      onCompleted: () => {
        setDealRegistryRows((prevDealRegistryRow) => prevDealRegistryRow.filter((item) => item.id !== id));
      }
    });
  };

  const openInvestmentForAllocations = (row: IRecordData, isEdit?: boolean) => {
    navigate('/allocations/modify/', {
      state: {
        investment: row.investment.id,
        isDealRegistry: true,
        openInvestment: isEdit ? '' : 'Open Investment for Allocations'
      }
    });
  };

  const openModalWindow = (row: IRecordData, type: string) => {
    setIsModalWindowOpen({ isOpen: true, type: type });
    setCurrentRow(row);
  };

  const openWindow = (type: string) => {
    setWindowType({ ...windowType, typeWindow: type });
  };

  const totalWidth = columnsList.reduce((width, column) => (column.value ? width + column.width : width), 0);

  const isFixedChosen = columnsList.filter((item) => {
    if (item.value) {
      return item;
    }
  });

  const handleAddDealClick = () => {
    navigate('/dueDiligence/modify/?status=LEAD&backToTitle=Deal Registry', {
      state: {
        status: 'LEAD'
      }
    });
  };

  const handleModifyDealClick = (row: IRecordData) => {
    navigate(`/dueDiligence/modify/?record=${row.id}&status=LEAD&backToTitle=Deal Registry`, {
      state: {
        record: row.id,
        status: 'LEAD'
      }
    });
  };

  const handleClickDeal = (row: IRecordData, generateAnswers?: string[]) => {
    const generateAnswersParams = generateAnswers?.length
      ? `?generateAnswers=${JSON.stringify(generateAnswers)}&backToTitle=Deal Registry`
      : '?backToTitle=Deal Registry';

    navigate(`/dueDiligence/${row.id}${generateAnswersParams}`);
  };

  const openManagerUpdates = (row: IRecordData) => {
    navigate(`/managerUpdates/?recordId=${row.id}`, {
      state: {
        recordId: row.id
      }
    });
  };

  const onChangeSearch = (searchValue: string) => {
    setSearchParams(
      (prev) => {
        if (!searchValue) {
          prev.delete('q');
          return prev;
        }
        prev.set('q', searchValue);
        return prev;
      },
      { replace: true }
    );
  };

  const onChangeSort = (value: ISort) => {
    setSearchParams(
      (prev) => {
        prev.set('key', value.key);
        prev.set('asc', value.asc.toString());
        return prev;
      },
      { replace: true }
    );
  };

  const onChangePrimaryFilter = (filter: string) => {
    setSearchParams(
      (prev) => {
        prev.set('primaryFilter', filter);
        return prev;
      },
      { replace: true }
    );
  };

  const onChangePaginationData = (limit: number, offset: number) => {
    setSearchParams(
      (prev) => {
        prev.set('limit', limit.toString());
        prev.set('offset', offset.toString());
        return prev;
      },
      { replace: true }
    );
  };

  const onChangeAdditionalFilters = (filters: AdditionalFilter[]) => {
    setAdditionalFilters(filters);

    const matchedAdditionalFilters = filters.reduce<DealRegistryFiltersStructure>(
      (acc, { selected, operator, typeFilter }) => {
        return acc[typeFilter as keyof DealRegistryFiltersStructure]
          ? {
              ...acc,
              [typeFilter]: [...acc[typeFilter as keyof DealRegistryFiltersStructure], selected]
            }
          : acc;
      },
      {
        assetClass: [],
        funnel: []
      }
    );

    const { assetClass, funnel } = matchedAdditionalFilters;

    setSearchParams(
      (prev) => {
        if (!assetClass.length) {
          prev.delete('assetClass');
          return prev;
        }
        prev.set('assetClass', JSON.stringify(assetClass));
        return prev;
      },
      { replace: true }
    );

    setSearchParams(
      (prev) => {
        if (!funnel.length) {
          prev.delete('funnel');
          return prev;
        }

        prev.set('funnel', JSON.stringify(funnel));
        return prev;
      },
      { replace: true }
    );
  };

  return (
    <>
      {isModalWindowOpen.isOpen && isModalWindowOpen.type === 'delete-deal' && (
        <DeleteDeal
          onClose={() => setIsModalWindowOpen({ type: 'delete-deal', isOpen: false })}
          isOpen={isModalWindowOpen.isOpen}
          handleDeleteDeal={handleDeleteDeal}
          row={currentRow}
        />
      )}
      {isModalWindowOpen.isOpen && isModalWindowOpen.type === 'start-diligence' && (
        <StartDueDiligence
          onClose={() => setIsModalWindowOpen({ type: 'start-diligence', isOpen: false })}
          isOpen={isModalWindowOpen.isOpen}
          startDueDiligence={startDueDiligence}
          currentRow={currentRow}
        />
      )}
      {isModalWindowOpen.isOpen && isModalWindowOpen.type === 'ai-suggestions' && (
        <AiSuggestions
          onClose={() => setIsModalWindowOpen({ type: 'ai-suggestions', isOpen: false })}
          isOpen={isModalWindowOpen.isOpen}
          currentRow={currentRow}
          handleClickDeal={handleClickDeal}
        />
      )}
      {isModalWindowOpen.isOpen && isModalWindowOpen.type === 'edit-table' && !isMobile && window.innerHeight > 890 && (
        <EditTableSettings
          columnsList={columnsList}
          setColumnsList={setColumnsList}
          onClose={() => setIsModalWindowOpen({ type: 'edit-table', isOpen: false })}
          isOpen={isModalWindowOpen.isOpen}
        />
      )}
      {windowType.typeWindow === 'column-control' && isMobile && (
        <EditTableSettingsMobile
          columnsList={columnsList}
          setColumnsList={setColumnsList}
          onClose={() => setWindowType({ ...windowType, typeWindow: 'default' })}
        />
      )}
      {windowType.typeWindow === 'column-control' && !isMobile && window.innerHeight < 890 && (
        <EditTableSettingsPage
          columnsList={columnsList}
          setColumnsList={setColumnsList}
          onClose={() => setWindowType({ ...windowType, typeWindow: 'default' })}
        />
      )}
      {windowType.typeWindow === 'default' && (
        <>
          <MainWrap>
            <Header />
            <PaddingWrap>
              {(!trialLimits.trialMode || (trialLimits.trialMode && trialLimits.canCreateDeal)) && (
                <AddButtonWrapper>
                  <Button size="md" onClick={handleAddDealClick} icon={<AddButtonIcon />}>
                    Add New Deal
                  </Button>
                </AddButtonWrapper>
              )}
              <div ref={containerRef}></div>
              <FilterPage
                search={{
                  value: q,
                  onChange: onChangeSearch,
                  placeholder: 'Search for a deal'
                }}
                additionalFilters={{
                  filtersItems,
                  filters: additionalFilters,
                  applyFilters: onChangeAdditionalFilters,
                  amountResults: 0,
                  showChosenFilters: additionalFilters[0].typeFilter !== 'Select A Filter Option',
                  onRefetch: () => null
                }}
                isDealRegistryFilter
                primaryFilter={{
                  value: primaryFilter,
                  onChange: onChangePrimaryFilter,
                  selects: dealRegistrySelectors
                }}
                resultsValue={total}
                refetch={dealRegistryRefetch}
                mobileSort={{
                  value: mobileSort,
                  onChange: setMobileSort,
                  selects: mobileSortList
                }}
                tableColumnControl={
                  isMobile || window.innerHeight < 890
                    ? () => openWindow('column-control')
                    : () =>
                        setIsModalWindowOpen({
                          type: 'edit-table',
                          isOpen: !isModalWindowOpen.isOpen
                        })
                }
              />
              {!isMobile ? (
                <>
                  <TableWrapper width={windowWidth} isTablet={isTablet}>
                    <Table totalWidth={isFixedChosen.length ? (totalWidth < windowWidth ? `${100}%` : `${totalWidth}px`) : `${100}%`}>
                      <TableHeader columnsList={columnsList} savedSetSort={onChangeSort} savedSort={{ key, asc }} refetch={(): void => null} />
                      {dealRegistryNetworkStatus === NetworkStatus.loading || dealRegistryNetworkStatus === NetworkStatus.setVariables ? (
                        <LoaderWrapper>
                          <Loader />
                        </LoaderWrapper>
                      ) : (
                        <TableBody
                          moduleConfig={moduleConfig}
                          dealRegistryRows={dealRegistryRows}
                          columnsList={columnsList}
                          openModalWindow={openModalWindow}
                          handleModifyDealClick={handleModifyDealClick}
                          handleClickDeal={handleClickDeal}
                          dealRegistryLoading={dealRegistryLoading || updateInvestmentStatusLoading || deleteRecordLoading}
                          currentRow={currentRow}
                          handleUpdateInvestmentStatus={handleUpdateInvestmentStatus}
                          openInvestmentForAllocations={openInvestmentForAllocations}
                          openManagerUpdates={openManagerUpdates}
                        />
                      )}
                    </Table>
                  </TableWrapper>
                  <PaginationWrap>
                    <TablePagination
                      paginationValues={{
                        limit: Number(limit),
                        offset: Number(offset),
                        total: total
                      }}
                      refetch={dealRegistryRefetch}
                      savePagination={onChangePaginationData}
                    />
                  </PaginationWrap>
                </>
              ) : (
                <>
                  {dealRegistryNetworkStatus === NetworkStatus.loading || dealRegistryNetworkStatus === NetworkStatus.setVariables ? (
                    <Loader />
                  ) : (
                    <MobileTable
                      moduleConfig={moduleConfig}
                      columnsList={columnsList}
                      dealRegistryRows={dealRegistryRows}
                      openModalWindow={openModalWindow}
                      handleModifyDealClick={handleModifyDealClick}
                      handleClickDeal={handleClickDeal}
                      handleUpdateInvestmentStatus={handleUpdateInvestmentStatus}
                      openInvestmentForAllocations={openInvestmentForAllocations}
                      openManagerUpdates={openManagerUpdates}
                    />
                  )}

                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <TablePagination
                      paginationValues={{
                        limit: Number(limit),
                        offset: Number(offset),
                        total: total
                      }}
                      refetch={dealRegistryRefetch}
                      savePagination={onChangePaginationData}
                    />
                  </div>
                </>
              )}
            </PaddingWrap>
          </MainWrap>
        </>
      )}
    </>
  );
};

const AddButtonWrapper = styled.div`
  margin-top: 20px;
`;

const TableWrapper = styled.div<{ width: number; isTablet: boolean }>`
  width: ${({ isTablet }) => (isTablet ? '93vw' : 'calc(93vw - 80px)')};
  max-width: 1820px;
  overflow-x: auto;
  overflow-y: hidden;
  margin-top: 18px;
  -webkit-overflow-scrolling: touch;

  &::-webkit-scrollbar {
    height: 6px;
  }
`;

const Table = styled.div<{ totalWidth: string }>`
  width: ${({ totalWidth }) => totalWidth};
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

const PaginationWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

const LoaderWrapper = styled.div`
  position: absolute;
  left: 50%;
  margin-top: 80px;
`;
