import * as React from 'react';

export const TimeframeIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || 58}
    height={props.height || 49}
    onClick={props.onClick || undefined}
    style={props?.style}
    viewBox="0 0 58 49"
    fill="none"
  >
    <g clipPath="url(#clip0_8655_69043)">
      <path
        d="M19.365 16.403C19.365 14.9838 19.3531 13.5761 19.365 12.1568C19.3769 10.8655 20.553 10.0395 21.7292 10.4584C22.4539 10.7143 22.8816 11.3425 22.8816 12.1917C22.8816 13.6575 22.8816 15.135 22.8816 16.6008C22.8816 17.5896 22.8697 18.5669 22.8935 19.5557C22.8935 19.7535 23.0004 19.9978 23.143 20.149C24.6161 21.6148 26.1249 23.069 27.5981 24.5348C28.5248 25.4538 28.2872 26.908 27.1348 27.4548C26.4457 27.7805 25.626 27.6758 25.0676 27.1291C23.3212 25.4306 21.5866 23.7205 19.8521 22.0103C19.4719 21.6381 19.3412 21.1727 19.3412 20.6492C19.3412 19.23 19.3412 17.8223 19.3412 16.403H19.365Z"
        fill="#2B2B2B"
      />
      <path
        d="M30.2359 37.9599C30.2359 37.0525 30.3309 36.1683 30.4854 35.3075C27.7648 36.9827 24.5571 37.9715 21.0999 37.9599C11.3342 37.9366 3.50503 30.2353 3.50503 20.6959C3.50503 11.1681 11.4055 3.45513 21.1355 3.46676C30.8893 3.4784 38.7303 11.1797 38.7303 20.7308C38.7303 22.1151 38.5403 23.453 38.2314 24.7443C39.4194 24.1045 40.7144 23.6275 42.0806 23.3134C42.1163 23.0342 42.1519 22.755 42.1994 22.4758C42.2113 22.3711 42.2469 22.2664 42.2826 22.1733V19.265C42.1757 18.5321 42.0925 17.7991 41.9499 17.0779C40.7263 10.8075 37.2809 6.03775 31.6972 2.78039C28.9884 1.18661 26.0302 0.314103 22.8819 0.0698006C22.7869 0.0698006 22.7037 0.0232669 22.6087 0H19.6386C18.902 0.104701 18.1773 0.197768 17.4407 0.325736C12.4866 1.18661 8.36411 3.5133 5.07324 7.22436C2.3764 10.284 0.653743 13.8321 0.178528 17.8457C-0.783782 26.0007 2.11503 32.5969 8.81556 37.5411C11.7857 39.7398 15.2072 40.9264 18.902 41.3219C22.9176 41.764 26.85 41.0195 30.3072 39.3675C30.2596 38.9022 30.2359 38.4252 30.2359 37.9482V37.9599Z"
        fill="#575757"
      />
      <path
        d="M45.5969 27.8038C45.5969 27.9318 45.5969 28.0365 45.5969 28.1296C45.5969 31.4567 45.5969 34.7723 45.6088 38.0994C45.6088 38.2739 45.7038 38.4833 45.8226 38.5997C48.1868 40.9263 50.5629 43.253 52.939 45.5797C53.034 45.6728 53.1291 45.7775 53.2241 45.8822C50.456 48.7789 45.3355 50.1051 40.8091 47.8948C36.0094 45.5564 33.6809 40.2632 35.2372 35.2725C36.7579 30.3748 41.4744 27.6526 45.5969 27.8038Z"
        fill="#8F8F8F"
      />
      <path
        d="M47.3077 37.5301C50.4322 35.8898 53.5805 34.2379 56.7169 32.5859C58.9623 36.4715 58.2139 41.8694 54.8874 44.9523C52.3687 42.486 49.8382 40.0197 47.3077 37.5301Z"
        fill="#8F8F8F"
      />
      <path
        d="M47.1889 35.8196V25.2565C50.7174 25.0587 54.8399 27.2574 56.7051 30.8289C53.5449 32.4808 50.3847 34.1444 47.1889 35.8196Z"
        fill="#8F8F8F"
      />
    </g>
    <defs>
      <clipPath id="clip0_8655_69043">
        <rect width="58" height="49" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
