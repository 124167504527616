import FormattingTooltip from '@/components/basicComponents/tooltip/dollarsTooltip';
import NoResults from '@/components/table/noResults';
import RedirectTitleCell from '@/components/table/redirectTitleCell';
import { TableRowCell, TableRowWrapper, TextCell } from '@/components/table/styles';
import { formatPercent } from '@/utils/currency';
import { investmentColumns } from '../constatnts';
import { IManagerInvestment } from '../types';

interface TableBodyProps {
  tableData: IManagerInvestment[];
  openInvestmentDetails: (id: string) => void;
}

const TableBody = ({ tableData, openInvestmentDetails }: TableBodyProps) => {
  if (!tableData?.length) {
    return <NoResults />;
  }

  return (
    <>
      {tableData.map((row: IManagerInvestment) => (
        <TableRowWrapper key={row.id}>
          <TableRowCell width={investmentColumns[0].width}>
            <RedirectTitleCell id="actionCurioText" onClick={() => openInvestmentDetails(row.id)}>
              {row.name}
            </RedirectTitleCell>
          </TableRowCell>
          <TableRowCell width={investmentColumns[1].width}>
            <TextCell>{row.managerId && row.managerId}</TextCell>
          </TableRowCell>
          <TableRowCell width={investmentColumns[2].width}>
            <TextCell>{formatPercent(row.maxCalledPercent)}</TextCell>
          </TableRowCell>
          <TableRowCell width={investmentColumns[3].width}>
            <TextCell>{row.countOfInvestors}</TextCell>
          </TableRowCell>
          <TableRowCell width={investmentColumns[4].width}>
            <TextCell>
              <FormattingTooltip zIndex={1000}>{row.committedCapital}</FormattingTooltip>
            </TextCell>
          </TableRowCell>
        </TableRowWrapper>
      ))}
    </>
  );
};

export default TableBody;
