import * as React from 'react';

export const WrongSuitabilityIcon = ({ ...props }: React.SVGProps<SVGSVGElement>) => (
  <svg width="19" height="19" style={{ minWidth: '19px' }} viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.00271 8.59838V10.3962H14.0027V8.59838M9.5054 0.500002C7.72491 0.498934 5.98411 1.026 4.50324 2.01453C3.02237 3.00305 1.86798 4.40861 1.18612 6.05337C0.504263 7.69812 0.325579 9.50816 0.672673 11.2545C1.01977 13.0008 1.87705 14.605 3.13604 15.864C4.39504 17.123 5.99918 17.9802 7.74551 18.3273C9.49185 18.6744 11.3019 18.4957 12.9466 17.8139C14.5914 17.132 15.997 15.9776 16.9855 14.4968C17.974 13.0159 18.5011 11.2751 18.5 9.4946C18.4986 7.10952 17.5505 4.82255 15.864 3.13604C14.1775 1.44954 11.8905 0.501432 9.5054 0.500002ZM9.5054 16.6968C8.08072 16.6978 6.68773 16.2763 5.50263 15.4856C4.31753 14.6949 3.39357 13.5704 2.84763 12.2545C2.30169 10.9386 2.15829 9.49029 2.43558 8.09285C2.71286 6.69542 3.39837 5.4116 4.40539 4.40382C5.41242 3.39604 6.69571 2.70958 8.09294 2.43124C9.49017 2.15291 10.9386 2.29521 12.2549 2.84016C13.5712 3.38512 14.6964 4.30824 15.488 5.49275C16.2796 6.67725 16.7022 8.06992 16.7022 9.4946C16.6993 11.4029 15.9403 13.2324 14.5915 14.5822C13.2426 15.9321 11.4137 16.6925 9.5054 16.6968Z"
      fill={props?.fill || '#D63B4B'}
    />
  </svg>
);
