import { ApprovedIcon } from '@/assets/icons/info-icons/approved';
import { InfoIcon } from '@/assets/icons/info-icons/info';
import FormattingTooltip from '@/components/basicComponents/tooltip/dollarsTooltip';
import Button from '@/components/fat-basicComponents/button';
import Header from '@/components/fat-header';
import { GoBackButton } from '@/components/fat-header/goBackButton';
import { PageTitle } from '@/components/fat-header/pageTitle';
import { useResponsive } from '@/hooks/use-responsive';
import { MainWrap } from '@/styles/common';
import { Allocation } from '@/types/investments';
import React, { useEffect, useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { ButtonsControl, ImportText, ImportWrapper } from '../../styles';
import { IAllocationConflictsTableData, InitialImportCommitment } from '../types';
import Confirmation from './fat-modals/confirmation';
import DiscardChanges from './fat-modals/discardChanges';

interface ResolveAllocationConflictsProps {
  resolveConflictsData: IAllocationConflictsTableData | null;
  updateResolveConflictsData: (data: any) => void;
  handleClose: () => void;
  refetchAllocationConflicts: () => void;
  backToTitle: string;
}

export const ResolveAllocationConflicts = ({
  resolveConflictsData,
  updateResolveConflictsData,
  handleClose,
  refetchAllocationConflicts,
  backToTitle
}: ResolveAllocationConflictsProps) => {
  const { isMobile, isTablet } = useResponsive();
  const theme = useTheme();

  const [modalWindow, setModalWindow] = useState({ isOpen: false, type: 'discard-changes' });
  const [createdAllocations, setCreatedAllocations] = useState<any>([]);
  const [deletedCommittedAllocations, setDeletedCommittedAllocations] = useState<Allocation[]>([]);
  const [deletedPendingAllocations, setDeletedPendingAllocations] = useState<Allocation[]>([]);
  const [totalDeletedAllocations, setTotalDeletedAllocations] = useState({ committedAllocations: 0, pendingAllocations: 0 });

  const [matchingAllocations, setMatchingAllocations] = useState<Allocation[]>([]);
  const [allocationsThatNeedToBeDeleted, setAllocationsThatNeedToBeDeleted] = useState<Allocation[]>([]);
  const [allocationsThatNeedToBeAdded, setAllocationsThatNeedToBeAdded] = useState<InitialImportCommitment[]>([]);
  const [matchingPendingAllocations, setMatchingPendingAllocations] = useState<Allocation[]>([]);

  useEffect(() => {
    if (resolveConflictsData) {
      let tempImportAllocations = [...resolveConflictsData.importAllocations];
      let tempCommittedAllocations = [...resolveConflictsData.committedAllocations];
      let tempPendingAllocations = [...resolveConflictsData.pendingAllocations];
      let tempMatchingAllocations: Allocation[] = [];
      let tempAllocationsThatNeedToBeDeleted: Allocation[] = [];
      let tempAllocationsThatNeedToBeAdded: InitialImportCommitment[] = [];
      let tempMatchingPendingAllocations: Allocation[] = [];

      tempImportAllocations.forEach((importAllocation) => {
        const foundCommittedAllocationIndex = tempCommittedAllocations.findIndex(
          (allocation) => allocation.committedCapital === importAllocation.netAmount
        );
        if (foundCommittedAllocationIndex != -1) {
          const committedAllocation = tempCommittedAllocations.splice(foundCommittedAllocationIndex, 1);
          tempMatchingAllocations.push(committedAllocation[0]);
        } else {
          const foundPendingAllocationIndex = tempPendingAllocations.findIndex(
            (allocation) => allocation.committedCapital === importAllocation.netAmount
          );
          if (foundPendingAllocationIndex != -1) {
            tempMatchingPendingAllocations.push(tempPendingAllocations.splice(foundPendingAllocationIndex, 1)[0]);
          } else {
            tempAllocationsThatNeedToBeAdded.push({ ...importAllocation, isAdded: false });
          }
        }
      });

      tempAllocationsThatNeedToBeDeleted.push(...tempCommittedAllocations);

      setMatchingAllocations(tempMatchingAllocations);
      setAllocationsThatNeedToBeDeleted(tempAllocationsThatNeedToBeDeleted);
      setAllocationsThatNeedToBeAdded(tempAllocationsThatNeedToBeAdded);
      setMatchingPendingAllocations(tempMatchingPendingAllocations);
    }
  }, []);

  const addAllocationHandler = (row: InitialImportCommitment, rowIndex: number, status: string) => {
    if (!resolveConflictsData) {
      return;
    }
    let isRowUpdated = false;
    const updatedAllocationsThatNeedToBeAdded: InitialImportCommitment[] = [];

    allocationsThatNeedToBeAdded.forEach((allocation, index) => {
      if (allocation.netAmount === row.netAmount && rowIndex === index && !isRowUpdated) {
        updatedAllocationsThatNeedToBeAdded.push({ ...allocation, isAdded: true });
        isRowUpdated = true;
        return;
      }
      updatedAllocationsThatNeedToBeAdded.push(allocation);
    });

    setAllocationsThatNeedToBeAdded(updatedAllocationsThatNeedToBeAdded);

    const allocationData = {
      investmentId: resolveConflictsData.investmentId,
      legalEntityId: resolveConflictsData.legalEntityId,
      committedCapital: row.netAmount,
      allocationStatus: status,
      proposalType: resolveConflictsData.committedAllocations?.length > 0 ? 'Increase' : 'Initial',
      reconciled: true,
      comparisonIndex: rowIndex
    };

    setCreatedAllocations([...createdAllocations, allocationData]);
    return;
  };

  const deleteAllocationHandler = (row: Allocation) => {
    const updatedDeletedCommittedAllocations = [...deletedCommittedAllocations, row];
    setDeletedCommittedAllocations(updatedDeletedCommittedAllocations);

    const updatedCommittedAllocations = allocationsThatNeedToBeDeleted.filter((item) => item.id !== row.id);
    setAllocationsThatNeedToBeDeleted(updatedCommittedAllocations);

    const total = updatedDeletedCommittedAllocations.reduce((sum, value) => (sum += Number(value.committedCapital)), 0);
    setTotalDeletedAllocations({ ...totalDeletedAllocations, committedAllocations: total });
    return;
  };

  const deletePendingAllocationHandler = (row: Allocation) => {
    const updatedDeletedPendingAllocations = [...deletedPendingAllocations, row];
    setDeletedPendingAllocations(updatedDeletedPendingAllocations);

    const updatedPendingAllocations = resolveConflictsData?.pendingAllocations.filter((item) => item.id !== row.id);

    const updatedResolveConflictsData = {
      ...resolveConflictsData,
      pendingAllocations: updatedPendingAllocations ?? []
    };

    updateResolveConflictsData(updatedResolveConflictsData);

    const total = updatedDeletedPendingAllocations.reduce((sum, value) => (sum += Number(value.committedCapital)), 0);
    setTotalDeletedAllocations({ ...totalDeletedAllocations, pendingAllocations: total });
    return;
  };

  const undoAddAction = (row: InitialImportCommitment, rowIndex: number) => {
    let isRowUpdated = false;
    const updatedAllocationsThatNeedToBeAdded: InitialImportCommitment[] = [];

    allocationsThatNeedToBeAdded.forEach((allocation, index) => {
      if (allocation.netAmount === row.netAmount && rowIndex === index && !isRowUpdated) {
        updatedAllocationsThatNeedToBeAdded.push({ ...allocation, isAdded: false });
        isRowUpdated = true;
        return;
      }
      updatedAllocationsThatNeedToBeAdded.push(allocation);
    });

    setAllocationsThatNeedToBeAdded(updatedAllocationsThatNeedToBeAdded);

    const updatedCreatedAllocations = createdAllocations.filter(
      (item: any) => item.comparisonIndex !== rowIndex || item.committedCapital !== row.netAmount
    );

    setCreatedAllocations(updatedCreatedAllocations);
  };

  const undoDeleteAction = () => {
    const updatedNoMatchedCommittedAllocations = [...allocationsThatNeedToBeDeleted, ...deletedCommittedAllocations];

    setAllocationsThatNeedToBeDeleted(updatedNoMatchedCommittedAllocations);
    setDeletedCommittedAllocations([]);
  };

  const undoDeletePendingAllocationsAction = () => {
    const updatedResolveConflictsData = {
      ...resolveConflictsData,
      pendingAllocations: resolveConflictsData?.pendingAllocations
        ? [...resolveConflictsData?.pendingAllocations, ...deletedPendingAllocations]
        : [...deletedPendingAllocations]
    };

    updateResolveConflictsData(updatedResolveConflictsData);

    setDeletedPendingAllocations([]);
  };

  const isSaveDisabled = () => {
    const isAllAllocationsAdded = allocationsThatNeedToBeAdded.filter((allocaion) => !allocaion.isAdded);
    return !!(isAllAllocationsAdded.length || allocationsThatNeedToBeDeleted.length);

    // return !(createdAllocations.length > 0 || deletedCommittedAllocations.length > 0 || deletedPendingAllocations.length > 0);
  };
  if (!resolveConflictsData) {
    return <></>;
  }
  return (
    <>
      {modalWindow.type === 'discard-changes' && (
        <DiscardChanges isOpen={modalWindow.isOpen} onClose={() => setModalWindow({ ...modalWindow, isOpen: false })} onSave={handleClose} />
      )}
      {modalWindow.type === 'confirmation' && (
        <Confirmation
          isOpen={modalWindow.isOpen}
          onClose={() => setModalWindow({ ...modalWindow, isOpen: false })}
          createdAllocations={createdAllocations}
          deletedCommittedAllocations={deletedCommittedAllocations}
          deletedPendingAllocations={deletedPendingAllocations}
          totalDeletedAllocations={totalDeletedAllocations}
          refetchAllocationConflicts={refetchAllocationConflicts}
          closeResolveConflictsPage={handleClose}
        />
      )}
      <MainWrap>
        <Header modalControl={<GoBackButton handleClose={handleClose} backToTitle={backToTitle} />} />
        <PageTitle title="Resolve Allocation Conflicts" />
        <PaddingWrap>
          <ImportWrapper>
            <TitleWrap>
              {resolveConflictsData?.validated ? (
                <>
                  <ApprovedIcon width={30} height={30} fill={theme.context.success} />
                  <ImportText fontWeight="700" color={theme.context.success}>
                    No Allocation Conflicts
                  </ImportText>
                </>
              ) : (
                <>
                  <InfoIcon width={30} height={30} fill={theme.context.warning} />
                  <ImportText fontWeight="700">Allocation Conflicts</ImportText>
                </>
              )}
            </TitleWrap>
            <AllocationConflicts>
              <ColumnsWrap isMobile={isMobile}>
                <Column isMobile={isMobile}>
                  <RowTitle>INVESTOR ENTITY</RowTitle>
                  <RowValue>
                    <ImportText fontWeight="600" fontSize="16px">
                      {`${resolveConflictsData.entityName} (${resolveConflictsData.advisoryFirm})`}
                    </ImportText>
                  </RowValue>
                </Column>
                <Column isMobile={isMobile}>
                  <RowTitle>STRATEGY</RowTitle>
                  <RowValue>
                    <ImportText fontWeight="600" fontSize="16px">
                      {resolveConflictsData.investmentName}
                    </ImportText>
                  </RowValue>
                </Column>
              </ColumnsWrap>
              <ColumnsWrap isMobile={isMobile}>
                <Column isMobile={isMobile}>
                  <RowTitle>IMPORTED ROWS</RowTitle>
                  {matchingAllocations.map((row, index) => {
                    return (
                      <React.Fragment key={row.id + index}>
                        <>
                          <RowValue>
                            <AmountWrap bgColor="#efffea">
                              {row?.committedCapital && (
                                <FormattingTooltip zIndex={1000} showDecimal={true}>
                                  {row.committedCapital}
                                </FormattingTooltip>
                              )}
                            </AmountWrap>
                          </RowValue>
                          <Divider />
                        </>
                      </React.Fragment>
                    );
                  })}
                  {allocationsThatNeedToBeAdded.map((row, index) => (
                    <React.Fragment key={row.entityCode + index}>
                      <RowValue>
                        <AmountWrap bgColor={row.isAdded ? '#efffea' : '#f0f1f3'}>
                          <FormattingTooltip zIndex={1000} showDecimal={true}>
                            {row.netAmount}
                          </FormattingTooltip>
                        </AmountWrap>
                      </RowValue>
                      <Divider />
                    </React.Fragment>
                  ))}
                </Column>
                <Column isMobile={isMobile}>
                  <RowTitle>COMMITTED ALLOCATIONS</RowTitle>
                  {matchingAllocations.map((row, index) => {
                    return (
                      <React.Fragment key={row.id + index}>
                        <RowValue>
                          <AmountWrap bgColor="#efffea">
                            {row?.committedCapital && (
                              <FormattingTooltip zIndex={1000} showDecimal={true}>
                                {row.committedCapital}
                              </FormattingTooltip>
                            )}
                          </AmountWrap>
                        </RowValue>
                        <Divider />
                      </React.Fragment>
                    );
                  })}
                  {allocationsThatNeedToBeAdded.map((row, index) => (
                    <React.Fragment key={row.entityCode + index}>
                      {row.isAdded ? (
                        <>
                          <RowValue>
                            <AmountWrap bgColor="#efffea">
                              <FormattingTooltip zIndex={1000} showDecimal={true}>
                                {row.netAmount}
                              </FormattingTooltip>
                            </AmountWrap>
                            <Action>added</Action>{' '}
                            <Button size="sm" styleType="outline" onClick={() => undoAddAction(row, index)}>
                              Undo
                            </Button>
                          </RowValue>
                          <Divider />
                        </>
                      ) : (
                        <>
                          <RowValue>
                            <Button size="sm" onClick={() => addAllocationHandler(row, index, 'Committed')}>
                              Add
                            </Button>
                          </RowValue>
                          <Divider />
                        </>
                      )}
                    </React.Fragment>
                  ))}
                  {allocationsThatNeedToBeDeleted.map((row, index) => (
                    <React.Fragment key={row.id}>
                      <RowValue>
                        <AmountWrap bgColor="#FFEAEC">
                          {row?.committedCapital && (
                            <FormattingTooltip zIndex={1000} showDecimal={true}>
                              {row.committedCapital}
                            </FormattingTooltip>
                          )}
                        </AmountWrap>
                        <Button size="sm" onClick={() => deleteAllocationHandler(row)}>
                          Delete
                        </Button>
                      </RowValue>
                      <Divider />
                    </React.Fragment>
                  ))}
                  {deletedCommittedAllocations.length ? (
                    <>
                      <RowValue>
                        <Action>{deletedCommittedAllocations.length} deleted</Action>
                        <FormattingTooltip zIndex={1000} showDecimal={true}>
                          {totalDeletedAllocations.committedAllocations}
                        </FormattingTooltip>
                        <Button size="sm" styleType="outline" onClick={undoDeleteAction}>
                          Undo
                        </Button>
                      </RowValue>
                      <Divider />
                    </>
                  ) : (
                    <></>
                  )}
                </Column>
              </ColumnsWrap>
              <ColumnsWrap isMobile={isMobile}>
                <Column isMobile={isMobile}>
                  <RowTitleSpacer></RowTitleSpacer>
                  {matchingPendingAllocations.map((row, index) => {
                    return (
                      <React.Fragment key={row.id + index}>
                        <>
                          <RowValue>
                            <AmountWrap bgColor="#efffea">
                              {row?.committedCapital && (
                                <FormattingTooltip zIndex={1000} showDecimal={true}>
                                  {row.committedCapital}
                                </FormattingTooltip>
                              )}
                            </AmountWrap>
                          </RowValue>
                          <Divider />
                        </>
                      </React.Fragment>
                    );
                  })}
                </Column>
                <Column isMobile={isMobile}>
                  <RowTitle>PENDING ALLOCATION</RowTitle>
                  {resolveConflictsData.pendingAllocations.map((row) => (
                    <React.Fragment key={row.id}>
                      <RowValue>
                        <AmountWrap bgColor="#f0f1f3">
                          {row?.committedCapital && (
                            <FormattingTooltip zIndex={1000} showDecimal={true}>
                              {row.committedCapital}
                            </FormattingTooltip>
                          )}
                        </AmountWrap>
                        <Button size="sm" onClick={() => deletePendingAllocationHandler(row)}>
                          Delete
                        </Button>
                      </RowValue>
                      <Divider />
                    </React.Fragment>
                  ))}
                  {deletedPendingAllocations.length ? (
                    <>
                      <RowValue>
                        <Action>{deletedPendingAllocations.length} deleted</Action>
                        <FormattingTooltip zIndex={1000} showDecimal={true}>
                          {totalDeletedAllocations.pendingAllocations}
                        </FormattingTooltip>
                        <Button size="sm" styleType="outline" onClick={undoDeletePendingAllocationsAction}>
                          Undo
                        </Button>
                      </RowValue>
                      <Divider />
                    </>
                  ) : (
                    <></>
                  )}
                </Column>
              </ColumnsWrap>
            </AllocationConflicts>
            <ButtonsControl>
              {resolveConflictsData.validated && !deletedPendingAllocations.length ? (
                <Button size="lg" onClick={handleClose}>
                  Done
                </Button>
              ) : (
                <>
                  <Button
                    styleType="outline"
                    size="lg"
                    onClick={() => (isSaveDisabled() ? handleClose() : setModalWindow({ type: 'discard-changes', isOpen: true }))}
                  >
                    Cancel
                  </Button>
                  <Button
                    size="lg"
                    styleType={isSaveDisabled() ? 'disabled' : 'default'}
                    onClick={() => setModalWindow({ type: 'confirmation', isOpen: true })}
                  >
                    Save
                  </Button>
                </>
              )}
            </ButtonsControl>
          </ImportWrapper>
        </PaddingWrap>
      </MainWrap>
    </>
  );
};

const PaddingWrap = styled.div`
  padding-left: 16px;
  padding-right: 16px;
  @media (min-width: 600px) {
    padding-left: 50px;
    padding-right: 50px;
  }
`;

const TitleWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  padding-bottom: 25px;
`;

const AllocationConflicts = styled.div`
  display: flex;
  flex-direction: column;
  gap: 50px;
`;

const ColumnsWrap = styled.div<{ isMobile: boolean }>`
  display: flex;
  flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};
  gap: 10px;
`;

const Column = styled.div<{ isMobile: boolean }>`
  width: ${({ isMobile }) => (isMobile ? '100%' : '50%')};
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const RowTitle = styled.div`
  background: ${({ theme }) => theme.layer[2]};
  padding: 10px 30px;
  color: ${({ theme }) => theme.font.base};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22.4px;
`;

const RowTitleSpacer = styled.div`
  padding: 22px 30px;
  color: ${({ theme }) => theme.font.base};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22.4px;
`;

const RowValue = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px 30px;
`;

const AmountWrap = styled.div<{ bgColor: string }>`
  padding: 8px 20px;
  border-radius: 4px;
  background: ${({ bgColor }) => bgColor};
  width: max-content;
  line-height: 19.1px;
`;

const Divider = styled.div`
  background: ${({ theme }) => theme.border.base};
  height: 1px;
  margin: 0 30px;
`;

const Action = styled.div`
  font-weight: 700;
  color: ${({ theme }) => theme.font.base};
`;
