import { CreateAllocationsByDateDto, CreateAssetClassAllocationDto, ITransformedAllocation, ITransformedAssetClass } from './types';

export const transformData = (data: CreateAllocationsByDateDto[]): ITransformedAssetClass[] => {
  const result: ITransformedAssetClass[] = [];

  data.forEach((item) => {
    item.assetClassAllocations.forEach((assetClass) => {
      let existingAssetClass = result.find((ac) => ac.assetClassId === assetClass.assetClassId);

      if (!existingAssetClass) {
        existingAssetClass = {
          assetClassId: assetClass.assetClassId,
          name: assetClass.name,
          allocations: [],
          subAssetClasses: []
        };
        result.push(existingAssetClass);
      }

      existingAssetClass.allocations.push({
        id: crypto.randomUUID(),
        date: item.date,
        allocation: assetClass.allocation ? assetClass.allocation.toString() : '-',
        updated: false
      });

      assetClass.subAssetClasses.forEach((subAssetClass) => {
        let existingSubAssetClass = existingAssetClass.subAssetClasses.find((sac) => sac.subAssetClassId === subAssetClass.subAssetClassId);

        if (!existingSubAssetClass) {
          existingSubAssetClass = {
            subAssetClassId: subAssetClass.subAssetClassId,
            name: subAssetClass.name,
            allocations: []
          };
          existingAssetClass.subAssetClasses.push(existingSubAssetClass);
        }

        existingSubAssetClass.allocations.push({
          id: crypto.randomUUID(),
          date: item.date,
          allocation: subAssetClass.allocation ? subAssetClass.allocation.toString() : '-',
          updated: false
        });
      });
    });
  });

  return result;
};

export const transformBackData = (transformedData: ITransformedAssetClass[]): CreateAllocationsByDateDto[] => {
  const result: CreateAllocationsByDateDto[] = [];

  transformedData.forEach((assetClass) => {
    assetClass.allocations.forEach((allocation) => {
      let existingDateEntry = result.find((item) => item.date === allocation.date);

      if (!existingDateEntry) {
        existingDateEntry = {
          date: allocation.date,
          assetClassAllocations: []
        };
        result.push(existingDateEntry);
      }

      const newAssetClass: CreateAssetClassAllocationDto = {
        assetClassId: assetClass.assetClassId,
        name: assetClass.name,
        allocation: allocation.allocation === '' || allocation.allocation === '-' ? 0 : parseFloat(allocation.allocation),
        subAssetClasses: []
      };

      assetClass.subAssetClasses.forEach((subAssetClass) => {
        const subAllocation = subAssetClass.allocations.find((subAlloc) => subAlloc.date === allocation.date);
        if (subAllocation) {
          newAssetClass.subAssetClasses.push({
            subAssetClassId: subAssetClass.subAssetClassId,
            name: subAssetClass.name,
            allocation: subAllocation.allocation === '' || subAllocation.allocation === '-' ? 0 : parseFloat(subAllocation.allocation)
          });
        }
      });

      existingDateEntry.assetClassAllocations.push(newAssetClass);
    });
  });

  return result;
};

export const updateAssetClassAllocationValue = (
  tableData: ITransformedAssetClass[],
  assetClassId: string,
  date: string,
  newValue: string
): ITransformedAssetClass[] => {
  return tableData.map((assetClass) => {
    if (assetClass.assetClassId === assetClassId) {
      return {
        ...assetClass,
        allocations: assetClass.allocations.map((item) => {
          if (item.date === date) {
            return { ...item, allocation: newValue };
          }
          return item;
        })
      };
    }
    return assetClass;
  });
};

export const updateSubAssetClassAllocationValue = (
  tableData: ITransformedAssetClass[],
  assetClassId: string,
  subAssetClassId: string,
  sacAllocation: ITransformedAllocation,
  newValue: string
): ITransformedAssetClass[] => {
  return tableData.map((assetClass) => {
    if (assetClass.assetClassId === assetClassId) {
      const updatedAllocations = assetClass.allocations.map((item) => {
        if (item.date === sacAllocation.date) {
          return { ...item, allocation: newValue };
        }
        return item;
      });

      const updatedSubAssetClasses = assetClass.subAssetClasses.map((subAssetClass) => {
        if (subAssetClass.subAssetClassId === subAssetClassId) {
          const updatedSubAllocations = subAssetClass.allocations.map((item) => {
            if (item.id === sacAllocation.id) {
              return { ...item, allocation: newValue };
            }
            return item;
          });

          return {
            ...subAssetClass,
            allocations: updatedSubAllocations
          };
        }
        return subAssetClass;
      });

      const sumSubAssetClassesValues = updatedSubAssetClasses.reduce((acc, subAssetClass) => {
        const subAssetClassValue = subAssetClass.allocations
          .filter((item) => item.allocation !== '-' && item.allocation !== '' && item.date === sacAllocation.date)
          .reduce((acc, item) => acc + (parseFloat(item.allocation) || 0), 0);
        return acc + subAssetClassValue;
      }, 0);

      return {
        ...assetClass,
        allocations: updatedAllocations.map((item) => {
          if (item.date === sacAllocation.date) {
            return { ...item, allocation: sumSubAssetClassesValues === 0 ? '-' : sumSubAssetClassesValues.toString() };
          }
          return item;
        }),
        subAssetClasses: updatedSubAssetClasses
      };
    }
    return assetClass;
  });
};

export const resetUpdatedFlag = (prevTableData: ITransformedAssetClass[], assetClassId: string, allocationId: string, subAssetClassId?: string) => {
  return prevTableData.map((prevData) => {
    if (prevData.assetClassId === assetClassId) {
      const resetAllocations = prevData.allocations.map((prevAllocationItem) => {
        if (prevAllocationItem.id === allocationId) {
          return { ...prevAllocationItem, updated: false };
        }
        return prevAllocationItem;
      });

      const resetSubAssetClasses = prevData.subAssetClasses.map((prevSubAssetClass) => {
        if (!subAssetClassId || prevSubAssetClass.subAssetClassId === subAssetClassId) {
          const resetSubAllocations = prevSubAssetClass.allocations.map((prevSubAllocationItem) => {
            if (prevSubAllocationItem.id === allocationId) {
              return { ...prevSubAllocationItem, updated: false };
            }
            return prevSubAllocationItem;
          });

          return { ...prevSubAssetClass, allocations: resetSubAllocations };
        }
        return prevSubAssetClass;
      });

      return { ...prevData, allocations: resetAllocations, subAssetClasses: resetSubAssetClasses };
    }
    return prevData;
  });
};
