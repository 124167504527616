import {
  MobileRow,
  MobileRowTitle,
  MobileRowValue,
  MobileTableRowWrap,
  MobileTableWrapper,
  NoResults
} from '@/components/table/mobileTable/mobileTable';

import { getUTCDate } from '@/components/allocations-page/utils';
import FormattingTooltip from '@/components/basicComponents/tooltip/dollarsTooltip';
import { allocationsTablecolumns } from '../../constatnts';
import { ClientEntityAllocationsTableType } from '../../types';
import Suitability from '../suitability';

interface MobileTableProps {
  investorAllocationRows: ClientEntityAllocationsTableType[] | undefined;
  openOpportunityDetailsPage: (id: string) => void;
}

const MobileTable = ({ investorAllocationRows, openOpportunityDetailsPage }: MobileTableProps) => {
  const TableRow = ({ el }: { el: ClientEntityAllocationsTableType }) => {
    return (
      <MobileTableRowWrap key={el.investmentId}>
        <MobileRow>
          <MobileRowTitle>{allocationsTablecolumns[0].title}</MobileRowTitle>
          <MobileRowValue isRedirect onClick={() => openOpportunityDetailsPage(el.investmentId)}>
            {el.name}
          </MobileRowValue>
        </MobileRow>

        <MobileRow>
          <MobileRowTitle>{allocationsTablecolumns[1].title}</MobileRowTitle>
          <MobileRowValue>{el.status === 'Outstanding' ? 'Pending' : el.status}</MobileRowValue>
        </MobileRow>

        <MobileRow>
          <MobileRowTitle>{allocationsTablecolumns[2].title}</MobileRowTitle>
          <MobileRowValue>{el.subAssetClass ? `${el.assetClass?.name} - ${el.subAssetClass.name}` : el.assetClass?.name}</MobileRowValue>
        </MobileRow>

        <MobileRow>
          <MobileRowTitle>{allocationsTablecolumns[3].title}</MobileRowTitle>
          {el.investorDocsDue && <MobileRowValue>{getUTCDate(new Date(el.investorDocsDue))}</MobileRowValue>}
        </MobileRow>

        <MobileRow>
          <MobileRowTitle>{allocationsTablecolumns[4].title}</MobileRowTitle>
          <MobileRowValue>
            <FormattingTooltip>{el.minimum}</FormattingTooltip>
          </MobileRowValue>
        </MobileRow>
        <MobileRow>
          <MobileRowTitle>{allocationsTablecolumns[5].title}</MobileRowTitle>
          <MobileRowValue>
            <Suitability mr="25" suitability={el.suitability} />
          </MobileRowValue>
        </MobileRow>
      </MobileTableRowWrap>
    );
  };

  return (
    <MobileTableWrapper>
      {investorAllocationRows?.length ? (
        investorAllocationRows.map((el, index) => {
          return <TableRow el={el} key={index} />;
        })
      ) : (
        <NoResults>No Results</NoResults>
      )}
    </MobileTableWrapper>
  );
};

export default MobileTable;
