import { getOwnerName } from '@/components/allocations-page/modify/utils';
import { IOwner, Investment } from '@/components/allocations-page/types';
import { getUTCDate } from '@/components/allocations-page/utils';
import { defaultAssetClassValue, defaultSelectValue } from '@/components/opportunities-entity/modify/constants';
import { IRecordData } from '../types';

export const getDefaultFormValues = (investment: Investment, record: IRecordData) => {
  return {
    name: investment?.name || '',
    manager: record?.manager || defaultSelectValue,
    assetClass: investment?.assetClass || defaultAssetClassValue,
    targetIRR: investment?.targetIRR || '',
    targetYield: investment?.targetYield || '',
    vintage: investment?.vintageYear,
    closeDate: investment?.finalClose ? getUTCDate(new Date(investment.finalClose)) : null,
    referral: investment?.referral ?? '',
    contact: investment?.contact ?? '',
    owner: getOwnerName(record?.owner as IOwner) ?? defaultSelectValue,
    funnel: record?.funnel?.name ?? defaultSelectValue
  };
};
