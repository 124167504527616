import { gql } from '@apollo/client';

export const InvestmentFragment = gql(`
  fragment InvestmentFragment on InvestmentDto {
        id
        subAssetClasses
        managerId
        custodied
        ubtiBlocker
        nextClose
        finalClose
        minimum
        targetIRR
        targetYield
        sri
        name
        vintageYear
        targetIRR
        targetYield
        type
        securityStatus
  }
`);

export const AssigneeFragment = gql(`
  fragment AssigneeFragment on UserDto {
        id
  }
`);

export const AdvisorOwnerFragment = gql(`
  fragment AdvisorOwnerFragment on UserDto {
        id
  }
`);

export const FamilyFragment = gql(`
  fragment FamilyFragment on FamilyDto {
        id
        name
  }
`);

export const AdvisorFragment = gql(`
  fragment AdvisorFragment on UserDto {
        firstName
        lastName
        initials
  }
`);

export const SuitabilityCriteriaFragment = gql(`
  fragment SuitabilityCriteriaFragment on SuitabilityCriterion {
        label
        value
  }
`);

export const SelectedGroupLegalEntityFragment = gql(`
  fragment SelectedGroupLegalEntityFragment on LegalEntityDto {
          id
          entityName
  }
`);

export const AllocationFragment = gql(`
  fragment AllocationFragment on AllocationEntityDto {
        id
        allocationStatus
        committedCapital
        decision
        reason
        override
        suitability
        proposalType
        editable
  }
`);

export const EntitiesFragment = gql(`
fragment EntitiesFragment on LegalEntityDto {
            id
            entityName
        }`);

export const OutstandingAllocationsFragment = gql(`
fragment OutstandingAllocationsFragment on AllocationEntityDto {
              allocationStatus
              committedCapital
            }
`);

export const PreAllocationStatusFragment = gql(`
fragment PreAllocationStatusFragment on PreAllocationStatus {
          alreadyCommitted
          committedAt
          terminated
          terminatedAt
          internalReviewRejections
          internalReviewRejectionAt
        }
`);

export const LegalEntityFragment = gql(`
fragment LegalEntityFragment on LegalEntityDto {
          id
          entityName
          alwaysShow
          isGroupEntity
          grouped
          overallCapacity
          assetClassCapacity
          totalEntityValue
          fundBiteSize
          directBiteSize
        }`);

export const OwnersFragment = gql(`
  fragment OwnersFragment on UserDto {
      id
      firstName
      lastName
      email
  }
`);
