import styled, { useTheme } from 'styled-components';

import { FilterSearchIcon } from '@/assets/icons/filterSearch';
import Label from '../label';

type FilterSearchProps = {
  onChange: (value: string) => void;
  value: string;
  placeholder?: string;
  label?: string;
  required?: boolean;
  autoFocus?: boolean;
  description?: string;
  showSearchBtn?: boolean;
  width?: string;
  type?: 'number' | 'password' | 'text';
  className?: string;
};
const FilterSearch = ({
  onChange,
  value,
  width = '250px',
  placeholder = '',
  description = '',
  label = '',
  required = false,
  autoFocus = false,
  showSearchBtn = true,
  type = 'text',
  className
}: FilterSearchProps) => {
  const theme = useTheme();

  return (
    <>
      {label && <Label required={required}>{label}</Label>}
      <InputWrap width={width}>
        <MainSearch
          type={type}
          value={value}
          onChange={(event) => onChange(event.target.value)}
          autoFocus={autoFocus}
          placeholder={placeholder}
          showSearchBtn={showSearchBtn}
          className={className}
        />
        {showSearchBtn && (
          <SearchBtn>
            <FilterSearchIcon fill={theme.font.action} />
          </SearchBtn>
        )}
      </InputWrap>
      {description && <Description>{description}</Description>}
    </>
  );
};

const InputWrap = styled.div<{ width: string }>`
  display: flex;
  width: ${({ width }) => width};
  height: 90%;
`;

const MainSearch = styled.input<{ showSearchBtn: boolean }>`
  width: 100%;
  border: 1px solid ${({ theme }) => theme.border.base};
  border-radius: 3px 0 0 3px;
  border-right: ${({ showSearchBtn }) => (showSearchBtn ? 'none' : 'auto')};
  padding: 6.5px 8px;
  background: ${({ theme }) => theme.layer[1]};
  color: ${({ theme }) => theme.font.strong};

  &:focus {
    outline: none;
  }
`;

const SearchBtn = styled.div`
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  padding: 10px;
  background: ${({ theme }) => theme.layer.base};
  border: 1px solid ${({ theme }) => theme.font.action};
  border-radius: 0 3px 3px 0;
`;

const Description = styled.span`
  padding-top: 10px;
  font-weight: 300;
  font-size: 13px;
  line-height: 18px;
  color: #7f7f7f;
`;

export default FilterSearch;
