import Button from '@/components/fat-basicComponents/button';
import Header from '@/components/fat-header';
import { GoBackButton } from '@/components/fat-header/goBackButton';
import { PageTitle } from '@/components/fat-header/pageTitle';
import { useResponsive } from '@/hooks/use-responsive';
import { MainWrap } from '@/styles/common';
import { useNavigate, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import { importDataItems } from './constants';
import { ImportAllocations } from './importAllocations';
import { ImportInvestorEntities } from './importInvestorEntities';
import { ImportTransactions } from './importTransactions';
import { ImportValuations } from './importValuations';

interface ImportDataProps {
  handleCloseImportData: () => void;
  backToTitle?: string;
}

export const ImportData = ({ handleCloseImportData, backToTitle }: ImportDataProps) => {
  const { isMobile, isTablet } = useResponsive();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const action = searchParams.get('action') || 'default';
  const type = searchParams.get('type') || 'default';

  const handleRedirect = (path: string) => {
    navigate(`/settings?action=${action}&type=${path}`);
  };

  const handleClose = () => {
    navigate(`/settings?action=${action}`);
  };

  return (
    <>
      {type === 'investorEntities' && <ImportInvestorEntities handleCloseImportInvestorEntities={handleClose} backToTitle="Import Data" />}
      {type === 'allocations' && <ImportAllocations handleCloseImportAllocations={handleClose} backToTitle="Import Data" />}
      {type === 'transactions' && <ImportTransactions handleCloseImportTransactions={handleClose} backToTitle="Import Data" />}
      {type === 'valuations' && <ImportValuations handleCloseImportValuations={handleClose} backToTitle="Import Data" />}
      {type === 'default' && (
        <MainWrap>
          <Header modalControl={<GoBackButton handleClose={handleCloseImportData} backToTitle={backToTitle} />} />
          <PageTitle title="Import Data" />
          <PaddingWrap>
            <SettingsWrapper>
              {importDataItems.map((item, index) => (
                <SettingsItem key={item.path + index}>
                  <ItemTitle id="itemTitle" onClick={() => handleRedirect(item.path)}>
                    {item.title}
                  </ItemTitle>
                  <ImportButtonWrapper id="openButton">
                    <Button onClick={() => handleRedirect(item.path)}>Import</Button>
                  </ImportButtonWrapper>
                </SettingsItem>
              ))}
            </SettingsWrapper>
          </PaddingWrap>
        </MainWrap>
      )}
    </>
  );
};

const PaddingWrap = styled.div`
  padding-left: 16px;
  padding-right: 16px;
  @media (min-width: 600px) {
    padding-left: 50px;
    padding-right: 50px;
  }
`;

const SettingsWrapper = styled.div`
  margin-top: 30px;
  background: ${({ theme }) => theme.layer[1]};
  padding: 40px 60px;
  border: 1px solid ${({ theme }) => theme.border.base};
  border-radius: 10px;
`;

const SettingsItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 21px 14px;
  border-bottom: 1px solid ${({ theme }) => theme.border.base};

  &:hover #openButton {
    opacity: 1;
    transition: background-color 100ms ease;
  }
  &:hover #itemTitle {
    color: ${({ theme }) => theme.font.action};
  }
  &:hover {
    background-color: ${({ theme }) => theme.layer[2]};
    transition: background-color 100ms ease;
  }
`;

const ItemTitle = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: ${({ theme }) => theme.font.strong};
  cursor: pointer;
`;

const ImportButtonWrapper = styled.div`
  opacity: 0;
`;
