import * as React from 'react';

export const ExportDataIcon = ({ ...props }: React.SVGProps<SVGSVGElement>) => (
  <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.5652 8.24746V10.4972H1.56629V8.24746H0.0634766V10.4972C0.0646635 10.8954 0.223387 11.2769 0.504962 11.5585C0.786537 11.8401 1.16808 11.9988 1.56629 12H10.5652C10.9629 11.9988 11.344 11.8399 11.6249 11.5582C11.9058 11.2765 12.0635 10.895 12.0635 10.4972V8.24746H10.5652ZM9.81376 5.25083L8.75639 4.19348L6.81263 6.12823V0H5.31432V6.12823L3.37056 4.19348L2.31319 5.25083L6.06572 8.99887L9.81376 5.25083Z"
      fill={props.fill || '#4587EC'}
    />
  </svg>
);
