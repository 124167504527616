import { ApprovedIcon } from '@/assets/icons/info-icons/approved';
import { InfoIcon } from '@/assets/icons/info-icons/info';
import { Banner } from '@/components/basicComponents/banner';
import RadioButton from '@/components/basicComponents/radioButton';
import Input from '@/components/fat-basicComponents/input';
import { formatDollarInputValue, formatMaskedDollarInputValue } from '@/components/fat-basicComponents/input/utils';
import ModalWrappedContent from '@/components/fat-basicComponents/modal/modalWrappedContent';
import { formatCash } from '@/utils/formatCash';
import { useMutation } from '@apollo/client';
import { ChangeEvent, useEffect, useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { createInvestorInterests, deleteInvestorInterestAmount, updateInvestorInterestAmount } from '../queries';
import { IInvestorInterestList, TypeWindow } from '../types';

interface EditToWatchlistProps {
  onClose: () => void;
  isOpen: boolean;
  currentRow: IInvestorInterestList | null;
  refetch: () => void;
  openPage: (id: string, type: TypeWindow) => void;
}

const radioButtonItems = ['Aggregate Commitment for Your Firm', 'Individual Investor Commitments', 'Remove Estimate'];

const EditToWatchlist = ({ onClose, isOpen, currentRow, refetch, openPage }: EditToWatchlistProps) => {
  const theme = useTheme();

  const [selectedOption, setSelectedOption] = useState(radioButtonItems[0]);
  const [taxableInvestorsValue, setTaxableInvestorsValue] = useState('');
  const [exemptInvestorsValue, setExemptInvestorsValue] = useState('');

  const [individualInvestorCommitments, setIndividualInvestorCommitments] = useState({ number: 0, amount: 0 });

  const [createInvestorInterest] = useMutation(createInvestorInterests);

  const [updateInvestorInterest] = useMutation(updateInvestorInterestAmount);

  const [deleteInvestorInterest] = useMutation(deleteInvestorInterestAmount, {
    onCompleted: () => {
      refetch();
    }
  });

  useEffect(() => {
    if (!currentRow) return;
    const isLegalEntity = currentRow.investorInterests.filter((interest) => interest.legal_entity);

    if (!isLegalEntity.length) {
      if (currentRow.investorInterestsExempt.length && currentRow.investorInterestsExempt[0].amount) {
        setExemptInvestorsValue(currentRow?.investorInterestsExempt[0]?.amount?.toString() ?? '');
      }
      if (currentRow.investorInterestsTaxable.length && currentRow.investorInterestsTaxable[0].amount) {
        setTaxableInvestorsValue(currentRow?.investorInterestsTaxable[0]?.amount?.toString() ?? '');
      }
      return;
    }

    if (isLegalEntity.length) {
      setSelectedOption(radioButtonItems[1]);
      setIndividualInvestorCommitments({
        number: currentRow.investorInterests.length,
        amount: currentRow.totalInvestmentAmount
      });
    }
  }, [currentRow]);

  const deleteAmount = async () => {
    const ids = currentRow.investorInterests.map((item) => item.id);
    deleteInvestorInterest({
      variables: {
        ids
      }
    });
  };

  const saveValue = async () => {
    if (selectedOption === radioButtonItems[0]) {
      const isLegalEntity = currentRow.investorInterests.filter((interest) => interest.legal_entity);
      const taxableInvestorsNumberValue = parseInt(taxableInvestorsValue) || 0;
      const exemptInvestorsNumberValue = parseInt(exemptInvestorsValue) || 0;
      if (isLegalEntity.length) {
        await deleteAmount();
        await createInvestorInterest({
          variables: {
            data: [
              {
                amount: taxableInvestorsNumberValue,
                investmentId: currentRow.investment.id,
                exempt: false
              },
              {
                amount: exemptInvestorsNumberValue,
                investmentId: currentRow.investment.id,
                exempt: true
              }
            ]
          },
          onCompleted: () => {
            refetch();
            onClose();
          }
        });
        return;
      }

      updateInvestorInterest({
        variables: {
          data: [
            {
              id: currentRow.investorInterestsTaxable[0].id,
              amount: taxableInvestorsNumberValue
            },
            {
              id: currentRow.investorInterestsExempt[0].id,
              amount: exemptInvestorsNumberValue
            }
          ]
        },
        onCompleted: () => {
          refetch();
          onClose();
        }
      });
      return;
    }
    if (selectedOption === radioButtonItems[1]) {
      openPage(currentRow.investment.id, 'seekingInterest');
      onClose();
      return;
    }
    if (selectedOption === radioButtonItems[2]) {
      await deleteAmount();
      onClose();

      return;
    }
  };

  const handleRadioChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSelectedOption(event.target.value);
  };

  if (!currentRow) return <></>;

  return (
    <ModalWrappedContent
      isOpen={isOpen}
      onClose={onClose}
      width="677px"
      confirmButton={{
        name: selectedOption === radioButtonItems[1] ? 'View Investors' : 'Confirm',
        onClick: saveValue,
        disabled: selectedOption === radioButtonItems[0] && !taxableInvestorsValue && !exemptInvestorsValue
      }}
    >
      <ModalContainer>
        <IconWrap>
          <ApprovedIcon width={60} height={60} fill={theme.context.success} />
        </IconWrap>
        <ModalTitel>Edit watchlist</ModalTitel>
        <ModalDescription>You will receive updates related to this manager.</ModalDescription>
        <ModalBody>
          <OptionsWrapper>
            Enter an estimated commitment:
            <RadioButtonsWrap>
              {radioButtonItems.map((item, index) => (
                <RadioButton
                  key={item + index}
                  name="editFunnel"
                  radioButtonText={item}
                  selectedOption={selectedOption}
                  handleRadioChange={handleRadioChange}
                />
              ))}
            </RadioButtonsWrap>
          </OptionsWrapper>
          <>
            {selectedOption === radioButtonItems[0] && (
              <InputsWrap>
                <Input
                  value={taxableInvestorsValue ? formatMaskedDollarInputValue(taxableInvestorsValue) : ''}
                  onChange={(e) => {
                    const formattedValue = formatDollarInputValue(e.target.value);
                    setTaxableInvestorsValue(formattedValue);
                  }}
                  label="Taxable Investors:"
                  size="md"
                  withDollarSign
                />
                <Input
                  value={exemptInvestorsValue ? formatMaskedDollarInputValue(exemptInvestorsValue) : ''}
                  onChange={(e) => {
                    const formattedValue = formatDollarInputValue(e.target.value);
                    setExemptInvestorsValue(formattedValue);
                  }}
                  label="Exempt Investors:"
                  size="md"
                  withDollarSign
                />
              </InputsWrap>
            )}
            {selectedOption === radioButtonItems[1] && (
              <BannerWrapper>
                <Banner
                  title=""
                  description={`${individualInvestorCommitments.number} Investors currently selected totaling ${formatCash(
                    individualInvestorCommitments.amount
                  )}`}
                  icon={<InfoIcon width={26} height={26} fill={theme.header.base} />}
                  bgColor={theme.layer[1]}
                  color={theme.font.base}
                />
              </BannerWrapper>
            )}
          </>
        </ModalBody>
      </ModalContainer>
    </ModalWrappedContent>
  );
};

export default EditToWatchlist;

const ModalContainer = styled.div`
  font-weight: 400;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const IconWrap = styled.div`
  display: flex;
  justify-content: center;
  justify-content: center;
`;

const ModalTitel = styled.div`
  font-size: 33px;
  text-align: center;
  color: ${({ theme }) => theme.font.strong};
`;

const ModalDescription = styled.div`
  font-size: 19px;
  line-height: 27px;
  text-align: center;
  color: ${({ theme }) => theme.font.base};
`;

const ModalBody = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 35px;
`;

const OptionsWrapper = styled.div`
  width: 55%;
  color: ${({ theme }) => theme.font.base};
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;
`;

const RadioButtonsWrap = styled.label`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
`;

const InputsWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 40%;
`;

const BannerWrapper = styled.div`
  width: 45%;
`;
