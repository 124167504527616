import ModifyDiligence from '@/components/dueDiligence-page/modifyDiligence';
import { Helmet as Head } from 'react-helmet';

const ModifyDiligencePage = () => {
  return (
    <>
      <Head>
        <title>Modify Diligence</title>
      </Head>
      <ModifyDiligence />
    </>
  );
};

export default ModifyDiligencePage;
