import { TooltipProps } from 'recharts';
import styled from 'styled-components';

interface CustomTooltipProps extends TooltipProps<number, string> {
  formattingType?: 'currency' | 'percentage';
}

export const ChartTooltip = ({ active, payload, label, formattingType = 'currency' }: CustomTooltipProps) => {
  if (active && payload && payload.length) {
    const formatValue = (value: number) => {
      switch (formattingType) {
        case 'currency':
          return typeof value === 'number' ? `$${value.toLocaleString('en-US')}` : '$0';
        case 'percentage':
          return typeof value === 'number' ? `${value.toFixed(2)}%` : '0.00%';
        default:
          return '0';
      }
    };

    return (
      <CustomChartTooltip>
        <p>{`Date: ${label}`}</p>
        {payload.map((entry, index) => (
          <p key={`item-${index}`} style={{ color: entry.color }}>
            {`${entry.name}: ${formatValue(entry.value)}`}
          </p>
        ))}
      </CustomChartTooltip>
    );
  }

  return null;
};

const CustomChartTooltip = styled.div`
  padding: 15px 10px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  background: ${({ theme }) => theme.context.light};
`;
