import * as React from 'react';

export const FolderIcon = ({ ...props }: React.SVGProps<SVGSVGElement>) => (
  <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_5157_392769)">
      <path
        d="M18.2407 16.0001H1.74779C1.7201 15.987 1.69428 15.9677 1.66517 15.9616C0.638502 15.7424 0.00522445 14.9777 0.00381613 13.9177C-0.000878295 9.97058 -0.00181718 6.02351 0.00428557 2.07597C0.00616334 0.86528 0.879795 0.00666999 2.09283 0.00291446C3.66218 -0.00177997 5.23106 -0.00131052 6.8004 0.00807832C6.91542 0.00854776 7.05625 0.0738002 7.13981 0.155483C7.69469 0.692995 8.24206 1.23849 8.78004 1.7929C8.92698 1.94406 9.07203 2.00837 9.28657 2.00743C12.1596 1.99992 15.0321 1.99945 17.9051 2.00415C19.104 2.00602 19.9899 2.84867 19.9941 4.02603C20.0058 7.34124 19.9997 10.6564 19.9969 13.9721C19.9964 14.6199 19.75 15.172 19.2223 15.5447C18.9327 15.7494 18.5703 15.8522 18.2407 16.0006V16.0001Z"
        fill="#4587EC"
      />
    </g>
    <defs>
      <clipPath id="clip0_5157_392769">
        <rect width="20.0006" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
