import * as docx from 'docx';
import { aiFieldValue, fieldValue, matchFieldWithAI, truncateString } from '../DDR-PDF/utils';
import { ISection, ISectionFields } from '../dueDiligenceRecord/types';
import { getAiSuggestedSection, transparentDocxTableBorders } from './utils';

export const getThreeColumnSection = async (section: ISection, includeAiSuggested: boolean, bodyFontColor: string) => {
  const createCell = async (field: ISectionFields) => {
    const isMatchedWithAI = matchFieldWithAI(field);
    const aiIncluded = isMatchedWithAI && field.AI?.answers.length && includeAiSuggested;
    const text = aiIncluded
      ? truncateString(aiFieldValue(field.type, field.AI.answers[0]), 250)
      : truncateString(fieldValue(field.type, field.data.value, field.data.commentary), 250) || 'Not specified';
    const isNotSpecified = text === 'Not specified';

    const formattedText = text.split('\n').flatMap((line, index, array) => {
      return index < array.length - 1
        ? [
            new docx.TextRun({
              text: line,
              color: bodyFontColor,
              italics: isNotSpecified,
            }),
            new docx.TextRun({ break: 1 }),
          ]
        : new docx.TextRun({
            text: line,
            color: bodyFontColor,
            italics: isNotSpecified,
          });
    });

    return new docx.TableCell({
      children: [
        new docx.Paragraph({
          children: [
            new docx.TextRun({
              text: aiIncluded ? `* ${field.name}: ` : `${field.name}: `,
              bold: true,
              color: bodyFontColor,
            }),
            ...formattedText,
          ],
          indent: {
            left: 100,
            right: 100,
          },
          spacing: {
            before: 100,
            after: 100,
          },
        }),
      ],
      borders: transparentDocxTableBorders,
      width: {
        size: 6000,
        type: docx.WidthType.DXA,
      },
    });
  };

  const cells = [];
  for await (const field of section.fields) {
    const cell = await createCell(field);
    cells.push(cell);
  }

  const rows = [];
  for (let i = 0; i < cells.length; i += 3) {
    const row = new docx.TableRow({
      children: [
        cells[i],
        cells[i + 1] ? cells[i + 1] : new docx.TableCell({ children: [], borders: transparentDocxTableBorders }),
        cells[i + 2] ? cells[i + 2] : new docx.TableCell({ children: [], borders: transparentDocxTableBorders }),
      ],
    });
    rows.push(row);
  }

  const isAiSuggestedIncluded = (section: ISection, includeAiSuggested: boolean) => {
    return (
      section.fields.some(field => (!field.data.value || field.data.value === field.AI?.answers[0]) && field.AI?.answers.length) && includeAiSuggested
    );
  };

  if (isAiSuggestedIncluded(section, includeAiSuggested)) {
    const aiSuggestedSection = await getAiSuggestedSection(section, bodyFontColor);
    return [new docx.Table({ rows }), ...aiSuggestedSection];
  }

  return [new docx.Table({ rows })];
};
