import { ReactNode } from 'react';
import styled, { useTheme } from 'styled-components';

import { InfoCircleIcon } from '@/assets/static/icons/info-circle';
import { useResponsive } from '@/hooks/use-responsive';

interface BasicTooltipProps {
  children?: ReactNode;
  tooltipContent: ReactNode;
  translateX?: number; // in %
  zIndex?: number;
  shouldShowContent?: boolean;
  tooltipWidth?: number;
  withTooltipIcon?: boolean;
  position?: 'top' | 'bottom';
}

const DEFAULT_TRANSLATE_X = -50;

const BasicTooltip = ({
  children,
  tooltipContent,
  translateX = DEFAULT_TRANSLATE_X,
  zIndex = 1,
  shouldShowContent = true,
  tooltipWidth = 0,
  withTooltipIcon = false,
  position = 'top'
}: BasicTooltipProps) => {
  const { isMobile } = useResponsive();
  const theme = useTheme();

  return (
    <Tooltip isMobile={isMobile} id="basicTooltip">
      {shouldShowContent ? (
        <TooltipText tooltipWidth={tooltipWidth} translateX={translateX} zIndex={zIndex} position={position}>
          {tooltipContent}
          {/*   if you need to split text on lines, use \n in tooltipContent */}
        </TooltipText>
      ) : (
        <></>
      )}
      {children}
      {withTooltipIcon && <InfoCircleIcon fill={theme.font.disabled} style={{ position: 'relative', top: '3px' }} />}
    </Tooltip>
  );
};

const TooltipText = styled.div<{ translateX: number; zIndex: number; tooltipWidth: number; position: 'top' | 'bottom' }>`
  display: flex;
  width: ${({ tooltipWidth }) => (tooltipWidth ? `${tooltipWidth}px` : 'max-content')};
  background-color: ${({ theme }) => theme.layer[2]};
  border: 1px solid ${({ theme }) => theme.border.base};
  border-radius: 6px;
  position: absolute;
  ${({ position }) => (position === 'top' ? 'bottom: 125%;' : 'top: 125%;')}
  z-index: ${({ zIndex }) => zIndex};
  left: ${({ translateX }) => (translateX === DEFAULT_TRANSLATE_X ? '50%' : 0)};
  transform: translateX(${({ translateX }) => translateX}%);
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s;
  padding: 10px 20px;

  font-family: Blinker, serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0;
  text-align: center;
  color: ${({ theme }) => theme.font.base};
  white-space: pre-line;

  &::after {
    content: '';
    position: absolute;
    ${({ position }) => (position === 'top' ? 'top: 100%;' : 'bottom: 100%;')}
    left: ${({ translateX }) =>
      translateX === DEFAULT_TRANSLATE_X ? 'calc(50% - 6px)' : `${Math.abs(translateX)}%`}; //6px is half of triangle width
    border-style: solid;
    height: 0;
    width: 0;
    border-width: ${({ position }) => (position === 'top' ? '6px 6px 0 6px' : '0 6px 6px 6px')};
    border-color: ${({ theme, position }) =>
      position === 'top' ? `${theme.border.base} transparent transparent transparent` : `transparent transparent ${theme.border.base} transparent`};
  }
`;

const Tooltip = styled.div<{ isMobile: boolean }>`
  white-space: nowrap;
  position: relative;
  display: ${({ isMobile }) => (isMobile ? 'block' : 'inline-block')};
  width: ${({ isMobile }) => (isMobile ? 'auto' : 'min-content')};

  &:hover ${TooltipText} {
    visibility: visible;
    opacity: 1;
  }
`;

export default BasicTooltip;
