import FormattingTooltip from '@/components/basicComponents/tooltip/dollarsTooltip';
import { MobileRow, MobileRowTitle, MobileRowValue, MobileTableRowWrap } from '@/components/table/mobileTable/mobileTable';
import { InvestmentManager } from '@/types/investments';
import styled from 'styled-components';
import { managersColumns } from '../constatnts';

interface MobileSumFooterProps {
  tableData: InvestmentManager[];
}

const MobileSumFooter = ({ tableData }: MobileSumFooterProps) => {
  const totalFunds = tableData.reduce((sum: number, value: InvestmentManager) => (sum += Number(value.countOfFunds)), 0);
  const totalInvestors = tableData.reduce((sum: number, value: InvestmentManager) => (sum += Number(value.countOfInvestors)), 0);
  const totalCommittedCapital = tableData.reduce((sum: number, value: InvestmentManager) => (sum += Number(value.committedCapital)), 0);

  return (
    <>
      <MobileSumFooterWrap>
        <MobileRow>
          <MobileRowTitle>Total</MobileRowTitle>
        </MobileRow>

        <MobileRow>
          <MobileRowTitle>{managersColumns[1].title}</MobileRowTitle>
          <MobileRowValue>{totalFunds}</MobileRowValue>
        </MobileRow>

        <MobileRow>
          <MobileRowTitle>{managersColumns[2].title}</MobileRowTitle>
          <MobileRowValue>{totalInvestors}</MobileRowValue>
        </MobileRow>

        <MobileRow>
          <MobileRowTitle>{managersColumns[3].title}</MobileRowTitle>
          <MobileRowValue>
            <FormattingTooltip zIndex={1000}>{totalCommittedCapital}</FormattingTooltip>
          </MobileRowValue>
        </MobileRow>
      </MobileSumFooterWrap>
    </>
  );
};

const MobileSumFooterWrap = styled(MobileTableRowWrap)`
  margin-top: 6px;
  background-color: ${({ theme }) => theme.layer[2]};
`;

export default MobileSumFooter;
