import styled, { css } from 'styled-components';

import { blinkingFrame } from '../styles';

export const MobileTableWrapper = styled.div`
  margin-top: 6px;
  padding-top: 6px;
  border-top: 1px solid ${({ theme }) => theme.border.base};
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

export const MobileTableRowWrap = styled.div<{ zIndex?: number; loading?: boolean | number }>`
  //z-index: 30;
  width: 100%;
  padding: 14px 24px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  background-color: ${({ theme }) => theme.layer[1]};
  border-radius: 8px;
  opacity: ${({ loading }) => (loading ? 0.5 : 1)};
  pointer-events: ${({ loading }) => (loading ? 'none' : 'auto')};
  animation: ${({ loading }) => (loading ? blinkingFrame : null)} 1.5s infinite;
  ${({ zIndex }) =>
    zIndex &&
    css`
      z-index: ${zIndex};
    `}
`;

export const MobileRow = styled.div`
  display: flex;
  align-items: center;
`;

export const MobileRowTitle = styled.div`
  padding-right: 18px;
  display: flex;
  width: 50%;
  justify-content: flex-start;
  font-family: Blinker, serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0;
  color: ${({ theme }) => theme.font.strong};
`;

export const MobileRowValue = styled.div<{ isRedirect?: boolean }>`
  display: flex;
  width: 50%;
  justify-content: flex-start;
  color: ${({ isRedirect, theme }) => (isRedirect ? theme.font.action : theme.font.base)};
  font-weight: ${({ isRedirect }) => (isRedirect ? 600 : 400)};
  //word-break: break-word;
`;

export const NoResults = styled.div`
  font-family: Blinker, serif;
  font-size: 16px;
  font-weight: 600;
  color: ${({ theme }) => theme.font.base};
  display: flex;
  justify-content: center;
  padding-top: 10px;
`;
