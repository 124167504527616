import { compareDesc, differenceInDays, format, isPast } from 'date-fns';

import { IClose, ITypeClose } from '@/components/dashboard-page/types/funds-closing-soon';
import { dateFormatter } from '@/utils/dateFormatter';
import { ToDoList } from './index';

export const getDateTypeDescription = (info: any) => {
  const upcomingDisplayCloseDate = getClose(info.daysToClose, info.dateObject);
  if (info.type === 'toTwoWeeks') {
    if (upcomingDisplayCloseDate === 'Today') {
      return `${info.dateType} is ${upcomingDisplayCloseDate}`;
    }
    return `${info.dateType} in ${upcomingDisplayCloseDate}`;
  }
  return `${info.dateType}: ${upcomingDisplayCloseDate}`;
};

export const updateListOfClose = (list: ToDoList[] | any) => {
  return list.map((close: ToDoList) => {
    let shouldHideDateType = false;
    const nextCloseTimestamp = Number(close.investment.nextClose);
    const finalCloseTimestamp = Number(close.investment.finalClose);
    let nearestDate = {
      date: new Date(nextCloseTimestamp),
      type: 'Next Close'
    };

    // Both in the past
    if (isPast(nextCloseTimestamp) && isPast(finalCloseTimestamp)) {
      shouldHideDateType = true;
    } else if (isPast(nextCloseTimestamp) && !isPast(finalCloseTimestamp)) {
      nearestDate = {
        date: new Date(finalCloseTimestamp),
        type: 'Final Close'
      };
    } else if (!isPast(nextCloseTimestamp) && isPast(finalCloseTimestamp)) {
      nearestDate = { date: new Date(nextCloseTimestamp), type: 'Next Close' };
      // nextClose after finalClose
    } else if (compareDesc(nextCloseTimestamp, finalCloseTimestamp) === -1) {
      nearestDate = {
        date: new Date(finalCloseTimestamp),
        type: 'Final Close'
      };
      // nextClose before finalClose
    } else if (compareDesc(nextCloseTimestamp, finalCloseTimestamp) === 1) {
      nearestDate = { date: new Date(nextCloseTimestamp), type: 'Next Close' };
      // nextClose equal finalClose
    } else {
      nearestDate = {
        date: new Date(finalCloseTimestamp),
        type: 'Final Close'
      };
    }

    const dateObject = new Date(nearestDate.date);
    const daysToClose = Math.abs(differenceInDays(new Date(), dateObject));
    const type = findType(daysToClose);
    return {
      ...close,
      daysToClose,
      dateObject,
      type,
      dateType: nearestDate.type,
      shouldHideDateType
    };
  });
};

const findType = (days: number): ITypeClose => {
  if (days < 15) return 'toTwoWeeks';
  if (days >= 15 && days <= 30) return 'fromTwoWeeksToMonth';
  return 'fromMonth';
};

const getClose = (days: IClose['daysToClose'], date: IClose['dateObject']) => {
  if (days === 0) return 'Today';
  const amountDays = `${days} Day${days === 1 ? '' : 's'}`;
  if (days < 15) {
    return amountDays;
  } else {
    return dateFormatter(format(new Date(date), 'MM/dd/yyyy'), 'MM/dd/yyyy');
  }
};
