import { SelectIcon } from '@/assets/icons/selectIcon';
import Label from '@/components/basicComponents/label';
import { useResponsive } from '@/hooks/use-responsive';
import { useClickOutside } from '@/hooks/useClickOutside';
import { FadeAnimation } from '@/styles/animations';
import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useController } from 'react-hook-form';

import styled from 'styled-components';

interface SelectProps {
  title: string;
  nameField: string;
  control: any;
  rules: any;
  selects: string[];
  defaultValue?: string;
  setError?: any;
  className?: string;
  isWarning?: boolean;
  tooltip?: string;
  tooltipWidth?: number;
  disabled?: boolean;
  onBlur?: () => void;
  errorMessage?: string;
  width?: string; // default width = 204px
  withSearch?: boolean;
  arrow?: React.ReactNode;
  id?: string;
  required?: boolean;
  longTitle?: boolean;
  additionalOption?: React.ReactNode;
}

export const Select: React.FC<SelectProps> = ({
  title,
  nameField,
  control,
  selects,
  defaultValue = '',
  rules,
  className,
  isWarning,
  tooltip,
  tooltipWidth,
  setError,
  disabled = false,
  onBlur = () => {},
  errorMessage,
  width = '100%',
  withSearch,
  arrow,
  id,
  required = false,
  longTitle,
  additionalOption
}) => {
  const { isMobile } = useResponsive();

  const {
    field: { value, ...filedProperties },
    fieldState: { invalid },
    formState: { errors, isSubmitted }
  } = useController({
    name: nameField,
    control,
    rules,
    defaultValue
  });

  const ref = useRef<HTMLDivElement | null>(null);
  const [isOpenSelect, setIsOpenSelect] = useState(false);
  const [search, setSearch] = useState('');
  const [list, setList] = useState<string[]>(selects);

  useEffect(() => {
    setList(selects);
  }, [selects]);

  useEffect(() => {
    if (!isSubmitted) return;
    if (setError && value === 'Select') {
      setError(nameField, {
        type: 'chosenItem',
        message: errorMessage ? errorMessage : 'Select items'
      });
    }
  }, [isSubmitted]);

  const handleSelectCLick = (event: any) => {
    if (disabled) return;
    if (event.target.id !== 'input') {
      setIsOpenSelect(!isOpenSelect);
    }
  };

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearch(value);
    const newList = [...selects];
    const filteredList = newList.filter((item) => item.toLowerCase().indexOf(event.target.value.toLowerCase()) !== -1);
    if (value === '' && filteredList.length === 0) {
      setList(selects);
    } else {
      setList(filteredList);
    }
  };

  useClickOutside(ref, () => setIsOpenSelect(false));
  return (
    <Container width={width}>
      <Label required={isWarning} tooltip={tooltip} tooltipWidth={tooltipWidth}>
        {title}
      </Label>
      <FilterWrap className={className} id={id} disabled={disabled} onClick={(event) => handleSelectCLick(event)} ref={ref}>
        {longTitle && value.length > 23 ? (
          <FilterLongTitle id={'titleSelect'} isDefaultValue={value === defaultValue}>
            {value}
          </FilterLongTitle>
        ) : (
          <FilterTitle id={'titleSelect'} isDefaultValue={value === defaultValue}>
            {value}
          </FilterTitle>
        )}
        <SelectIconWrap id={'selectIcon'} isOpen={isOpenSelect}>
          {arrow ?? <SelectIcon />}
        </SelectIconWrap>
        <FilterItems isOpen={isOpenSelect} id={'dropDownSelect'}>
          {withSearch && (
            <SearchWrap id="input">
              <SearchInput id="input" placeholder={'Type Here To Filter Results'} value={search} onChange={onChange} />
              <Divider />
            </SearchWrap>
          )}
          {list?.map((el, index) => (
            <PrimaryFilterItem isActive={el === value} key={el + index} onClick={() => filedProperties.onChange(el)} {...filedProperties}>
              {el}
            </PrimaryFilterItem>
          ))}
          {additionalOption && (
            <AdditionalOptionWrapper>
              <AdditionalOption>{additionalOption}</AdditionalOption>
            </AdditionalOptionWrapper>
          )}
        </FilterItems>
      </FilterWrap>
      {invalid && <ErrorText>{String(errors?.[nameField]?.message)}</ErrorText>}
    </Container>
  );
};

const Container = styled.div<{ width: string }>`
  display: flex;
  flex-direction: column;
  width: ${({ width }) => width};
`;

const SearchWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 11px 10px 0;
  gap: 11px;
`;

const SearchInput = styled.input`
  width: 100%;
  border: 1px solid ${({ theme }) => theme.border.base};
  border-radius: 3px;
  padding: 6.5px 8px;
  background-color: ${({ theme }) => theme.layer[1]};
  color: ${({ theme }) => theme.font.strong};

  &:focus {
    outline: none;
  }
`;

const Divider = styled.div`
  display: flex;
  background: ${({ theme }) => theme.border.base};
  height: 1px;
  width: 110%;
  margin-left: -20px;
  margin-right: -20px;
`;

const SelectIconWrap = styled.div<{ isOpen: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  transform-origin: center;
  transform: ${({ isOpen }) => (isOpen ? 'rotate(180deg)' : 'rotate(0deg)')};
  transition: all 0.2s linear;
`;

const FilterItems = styled(FadeAnimation)<{ isOpen: boolean }>`
  max-height: 260px;
  display: flex;
  flex-direction: column;
  position: absolute;
  z-index: 100;
  top: 42px;
  right: 0;
  width: 100%;
  border: ${({ isOpen, theme }) => `${isOpen ? 1 : 0}px solid ${theme.border.base}`};
  background: ${({ theme }) => theme.layer[1]};
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.15);
  border-radius: 3px;
  overflow-y: auto;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    width: 10px;
    left: -100px;
  }

  &::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.layer[2]};
    border: 1px solid ${({ theme }) => theme.border.base};
    padding: 2px;
    border-radius: 10px;
    margin: 6px 0;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.layer[4]};
    background-clip: padding-box;
    border-radius: 10px;
  }
`;

const FilterWrap = styled.div<{ disabled: boolean; minHeight?: string }>`
  display: flex;
  position: relative;
  opacity: ${({ disabled }) => (disabled ? 0.4 : 1)};
  justify-content: space-between;
  align-items: center;
  background: ${({ disabled, theme }) => (disabled ? theme.action.disabled : theme.layer[2])};
  border: 1px solid ${({ theme }) => theme.border.base};
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  min-height: ${({ minHeight }) => (minHeight ? minHeight : '10px')};
  padding: 10px 12px;
  border-radius: 5px;
  color: ${({ theme }) => theme.font.strong};
`;

const FilterTitle = styled.div<{ isDefaultValue: boolean }>`
  color: ${({ isDefaultValue, theme }) => (isDefaultValue ? theme.font.weak : theme.font.strong)};
  font-family: Blinker;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22.4px;
  text-align: left;
  text-transform: capitalize;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
`;

const FilterLongTitle = styled(FilterTitle)`
  max-width: 65px;
`;

const PrimaryFilterItem = styled.div<{ isActive?: boolean; textTransform?: string }>`
  color: ${({ theme }) => theme.font.strong};
  font-family: Blinker, serif;
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;

  width: 100%;
  cursor: pointer;
  padding: 6.5px 8px;
  background: ${({ isActive, theme }) => (isActive ? theme.layer[12] : theme.layer[1])};
  text-transform: ${({ textTransform }) => (textTransform ? textTransform : 'capitalize')};

  &:hover {
    background: ${({ theme }) => theme.layer[12]};
  }
`;

const AdditionalOptionWrapper = styled.div`
  position: sticky;
  bottom: 0;
  background-color: ${({ theme }) => theme.layer[1]};
`;

const AdditionalOption = styled.div`
  padding: 10px 0;
  margin: 0 10px;
  border-top: 1px solid ${({ theme }) => theme.border.base};
`;

const ErrorText = styled.p`
  font-family: Blinker;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22.4px;
  color: ${({ theme }) => theme.context.error};
`;
