import { ISort, SORT_ORDER } from '@/components/table/types';
import { useResponsive } from '@/hooks/use-responsive';
import { MainWrap, PaddingWrap } from '@/styles/common';
import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import { IFilterBarData } from '../basicComponents/filterBar';
import { Investment } from '../fat-dealCatalog-page/types';
import Header from '../fat-header';
import { Loader } from '../loaders/loader';
import { LoaderOnWholeScreen } from '../loaders/loader-on-whole-screen';
import { initialValuesFromTable } from '../table/constants';
import FilterPage from '../table/fat-filterPage';
import TablePagination from '../table/tablePagination';
import { initialFilterBarValues, managerUpdatesSort } from './constatnts';
import { LIST_INVESTMENTS_FOR_MANAGER_UPDATE_QUERY } from './queries';
import { Table } from './table/table';
import MobileTable from './table/tableMobile';
import { IListInvestmentsForManagerUpdateQuery, SECURITY_STATUS } from './types';
import { ManagerUpdatesZeroState } from './zeroState';

export const ManagerUpdates = () => {
  const navigate = useNavigate();
  const { isMobile, isTablet } = useResponsive();
  const [searchParams, setSearchParams] = useSearchParams();

  const limit = searchParams.get('limit') || initialValuesFromTable.initialAmountRowsPerPage[0];
  const offset = searchParams.get('offset') || 0;
  const q = searchParams.get('q') || '';
  const key = searchParams.get('key') || 'STRATEGY';
  const asc = searchParams.get('asc') === null || searchParams.get('asc') === 'true';
  const filterBarParams = searchParams.get('filterBar');
  const primaryFilter = searchParams.get('primaryFilter') || 'All Statuses';
  const position = searchParams.get('position') || 0;

  const [managerUpdatesRows, setManagerUpdatesRows] = useState<Investment[]>([]);
  const [filterBar, setFilterBar] = useState<IFilterBarData[]>(initialFilterBarValues);
  const [showZeroState, setShowZeroState] = useState(false);
  const [mobileSort, setMobileSort] = useState(managerUpdatesSort[0].title);
  const sortItem = managerUpdatesSort.find((item) => item.title === mobileSort);
  const mobileSortList = managerUpdatesSort.map((item) => item.title);

  useEffect(() => {
    if (!filterBarParams) return;

    const matchedFilterBarValues = initialFilterBarValues.map((item) => {
      const matchedFilterItem = JSON.parse(filterBarParams).find((filterItem: string) => filterItem === item.value);

      return {
        ...item,
        enabled: Boolean(matchedFilterItem)
      };
    });
    setFilterBar(matchedFilterBarValues);
  }, []);

  const { loading: strategiesWithUpdatesLoading } = useQuery<{ listInvestmentsForManagerUpdate: IListInvestmentsForManagerUpdateQuery }>(
    LIST_INVESTMENTS_FOR_MANAGER_UPDATE_QUERY,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'cache-and-network',
      variables: {
        sortBy: 'STRATEGY',
        order: SORT_ORDER.ASC,
        rowsPerPage: 1,
        page: 1,
        managerUpdateFilter: ['Strategies with Updates']
      },
      onCompleted: (data) => {
        const { data: tableData } = data.listInvestmentsForManagerUpdate;
        if (!tableData.length) {
          setShowZeroState(true);
        }
      },
      onError: (error) => {
        console.error(error);
      }
    }
  );

  const convertWorkflowStatusToSecurityStatusFilter = (status: string) => {
    switch (status) {
      case 'Recommended':
        return 'Other Recommended';
      case 'Seeking Interest':
        return 'Approved';
      case 'Accepting Commitments':
        return 'Open';
      case 'Closed':
        return 'Active';
      case 'Fully Realized':
        return 'Realized';
      default:
        return status;
    }
  };

  const {
    data: listInvestmentsData,
    refetch: listInvestmentsRefetch,
    loading: listInvestmentsLoading
  } = useQuery<{ listInvestmentsForManagerUpdate: IListInvestmentsForManagerUpdateQuery }>(LIST_INVESTMENTS_FOR_MANAGER_UPDATE_QUERY, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
    variables: {
      sortBy: key,
      order: asc ? SORT_ORDER.ASC : SORT_ORDER.DESC,
      search: q.toLowerCase().trim(),
      rowsPerPage: Number(limit),
      page: Math.floor(Number(offset) / Number(limit)) + 1,
      managerUpdateFilter: filterBar.filter((item) => item.enabled).map((item) => item.name),
      quickStatusFilter: primaryFilter !== 'All Statuses' ? convertWorkflowStatusToSecurityStatusFilter(primaryFilter) : ''
    },
    onCompleted: (data) => {
      const { filters, data: tableData } = data.listInvestmentsForManagerUpdate;

      setManagerUpdatesRows(tableData);

      const filterBarWithCounts = filterBar.map((filterBarItem) => {
        const matchedFilterItem = filters.find((filterItem) => filterItem.name === filterBarItem.value);

        return { ...filterBarItem, count: matchedFilterItem?.count ?? 0 };
      });
      setFilterBar(filterBarWithCounts);
      makeScroll(position);
    },
    onError: (error) => {
      console.error(error);
    }
  });

  useEffect(() => {
    if (!isMobile) return;

    listInvestmentsRefetch({
      sort: sortItem?.key,
      order: sortItem?.asc ? SORT_ORDER.ASC : SORT_ORDER.DESC,
      limit: Number(limit),
      offset: Number(offset)
    });
  }, [mobileSort]);

  const onChangeSearch = (searchValue: string) => {
    setSearchParams(
      (prev) => {
        if (!searchValue) {
          prev.delete('q');
          return prev;
        }
        prev.delete('limit');
        prev.delete('offset');
        prev.set('q', searchValue);
        return prev;
      },
      { replace: true }
    );
  };

  const onChangeSort = (value: ISort) => {
    setSearchParams(
      (prev) => {
        prev.set('key', value.key);
        prev.set('asc', value.asc.toString());
        return prev;
      },
      { replace: true }
    );
  };

  const onChangeFilterBar = (filterBarItems: IFilterBarData[]) => {
    setFilterBar(filterBarItems);
    const filterBarValues = filterBarItems.filter((item) => item.enabled).map((item) => item.value);
    setSearchParams(
      (prev) => {
        if (!filterBarValues.length) {
          prev.delete('filterBar');
          return prev;
        }
        prev.delete('limit');
        prev.delete('offset');
        prev.set('filterBar', JSON.stringify(filterBarValues));
        return prev;
      },
      { replace: true }
    );
  };

  const onChangePaginationData = (limit: number, offset: number) => {
    setSearchParams(
      (prev) => {
        prev.set('limit', limit.toString());
        prev.set('offset', offset.toString());
        return prev;
      },
      { replace: true }
    );
  };

  const onChangePrimaryFilter = (filter: string) => {
    setSearchParams(
      (prev) => {
        prev.set('primaryFilter', filter);
        prev.delete('limit');
        prev.delete('offset');
        return prev;
      },
      { replace: true }
    );
  };

  const saveScrollPostion = () => {
    setSearchParams(
      (prev) => {
        prev.set('position', window.scrollY.toString());
        return prev;
      },
      { replace: true }
    );
  };

  const makeScroll = (position: string | number) => {
    if (!position) return;
    setTimeout(() => window.scrollTo({ top: Number(position), behavior: 'smooth' }), 0);
    setSearchParams(
      (prev) => {
        prev.delete('position');
        return prev;
      },
      { replace: true }
    );
  };

  const openManagerUpdateDetailsPage = (strategyId: string) => {
    saveScrollPostion();
    navigate(`${strategyId}?tab=Updates&backToTitle=Manager Updates`);
  };

  const onViewAllStrategiesButtonClick = () => {
    const filterBarValues = filterBar.map((item) => {
      if (item.value === 'OTHER_STRATEGIES') {
        return { ...item, enabled: true };
      }
      return { ...item, enabled: false };
    });
    onChangeFilterBar(filterBarValues);
    setShowZeroState(false);
  };

  if (strategiesWithUpdatesLoading) {
    return <LoaderOnWholeScreen />;
  }

  if (showZeroState) {
    return <ManagerUpdatesZeroState onViewAllStrategiesButtonClick={onViewAllStrategiesButtonClick} />;
  }

  const convertSecurityStatusToWorkflowStatus = (status: string) => {
    switch (status) {
      case 'Lead':
        return null;
      case 'Other Recommended':
        return 'Recommended';
      case 'Approved':
        return 'Seeking Interest';
      case 'Open':
        return 'Accepting Commitments';
      case 'Active':
        return 'Closed';
      case 'Realized':
        return 'Fully Realized';
      default:
        return status;
    }
  };

  const primaryFilterSelects = [
    'All Statuses',
    ...(listInvestmentsData?.listInvestmentsForManagerUpdate?.filters
      .map((item) =>
        Object.keys(SECURITY_STATUS).includes(item.name) && item.count > 0 ? convertSecurityStatusToWorkflowStatus(String(item.value)) : null
      )
      .filter(Boolean) ?? [])
  ];

  return (
    <>
      <MainWrap>
        <Header />
      </MainWrap>
      <MainWrap>
        <PaddingWrap>
          <CustomFilterPage
            isMobile={isMobile}
            isTablet={isTablet}
            search={{
              value: q,
              onChange: onChangeSearch,
              placeholder: 'Search'
            }}
            primaryFilter={{
              value: primaryFilter,
              onChange: onChangePrimaryFilter,
              selects: primaryFilterSelects
            }}
            mobileSort={{
              value: mobileSort,
              onChange: setMobileSort,
              selects: mobileSortList
            }}
            filterBar={filterBar}
            onChangeFilterBar={onChangeFilterBar}
            resultsValue={listInvestmentsData?.listInvestmentsForManagerUpdate?.total ?? 0}
            refetch={listInvestmentsRefetch}
          />
          {isMobile ? (
            <>
              {listInvestmentsLoading ? (
                <Loader />
              ) : (
                <MobileTable tableData={managerUpdatesRows} openManagerUpdateDetailsPage={openManagerUpdateDetailsPage} />
              )}
            </>
          ) : (
            <Table
              sort={{ key, asc }}
              setSort={onChangeSort}
              tableData={managerUpdatesRows}
              loading={listInvestmentsLoading}
              openManagerUpdateDetailsPage={openManagerUpdateDetailsPage}
            />
          )}
          <PaginationWrap>
            <TablePagination
              savePagination={onChangePaginationData}
              paginationValues={{
                limit: Number(limit),
                offset: Number(offset),
                total: listInvestmentsData?.listInvestmentsForManagerUpdate?.total ?? 0
              }}
              refetch={() => null as any}
            />
          </PaginationWrap>
        </PaddingWrap>
      </MainWrap>
    </>
  );
};

const CustomFilterPage = styled(FilterPage)<{ isMobile: boolean; isTablet: boolean }>`
  margin-top: 0;
  margin-left: -1px;
  width: calc(100% + 2px);
  padding: ${({ isMobile }) => (isMobile ? '20px 0 0 0' : '20px 0 10px 0')};
  position: sticky;
  top: ${({ isMobile, isTablet }) => (isMobile || isTablet ? '50px' : '0')};
  z-index: 9;
`;

const PaginationWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;
