import { ApprovedIcon } from '@/assets/icons/info-icons/approved';
import { InfoIcon } from '@/assets/icons/info-icons/info';
import { Banner } from '@/components/basicComponents/banner';
import RadioButton from '@/components/basicComponents/radioButton';
import Input from '@/components/fat-basicComponents/input';
import { formatDollarInputValue, formatMaskedDollarInputValue } from '@/components/fat-basicComponents/input/utils';
import ModalWrappedContent from '@/components/fat-basicComponents/modal/modalWrappedContent';
import { useMutation } from '@apollo/client';
import { ChangeEvent, useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { createInvestorInterests } from '../queries';
import { IInvestorInterestList, TypeWindow } from '../types';

interface AddToWatchlistProps {
  onClose: () => void;
  isOpen: boolean;
  currentRow: IInvestorInterestList | null;
  refetch: () => void;
  openPage: (id: string, type: TypeWindow) => void;
}

const radioButtonItems = ['Aggregate Commitment for Your Firm', 'Individual Investor Commitments'];

const AddToWatchlist = ({ onClose, isOpen, currentRow, refetch, openPage }: AddToWatchlistProps) => {
  const theme = useTheme();

  const [selectedOption, setSelectedOption] = useState(radioButtonItems[0]);
  const [taxableInvestorsValue, setTaxableInvestorsValue] = useState('');
  const [exemptInvestorsValue, setExemptInvestorsValue] = useState('');

  const [createInvestorInterest] = useMutation(createInvestorInterests, {
    onCompleted: () => {
      refetch();
    }
  });

  const saveValue = () => {
    if (selectedOption === radioButtonItems[0]) {
      createInvestorInterest({
        variables: {
          data: [
            {
              investmentId: currentRow.investment.id,
              amount: parseInt(taxableInvestorsValue) || 0,
              exempt: false
            },
            {
              investmentId: currentRow.investment.id,
              amount: parseInt(exemptInvestorsValue) || 0,
              exempt: true
            }
          ]
        },
        onCompleted: () => {
          refetch();
          onClose();
        }
      });
      return;
    }
    if (selectedOption === radioButtonItems[1]) {
      openPage(currentRow.investment.id, 'seekingInterest');
      onClose();
    }
  };

  const handleRadioChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSelectedOption(event.target.value);
  };

  if (!currentRow) return <></>;

  return (
    <ModalWrappedContent
      isOpen={isOpen}
      onClose={onClose}
      width="677px"
      confirmButton={{
        name: selectedOption === radioButtonItems[0] ? 'Confirm' : 'View Investors',
        onClick: saveValue,
        disabled: selectedOption === radioButtonItems[0] && !taxableInvestorsValue && !exemptInvestorsValue
      }}
    >
      <ModalContainer>
        <IconWrap>
          <ApprovedIcon width={60} height={60} fill={theme.context.success} />
        </IconWrap>
        <ModalTitel>Add to watchlist</ModalTitel>
        <ModalDescription>You will receive updates related to this manager.</ModalDescription>
        <ModalBody>
          <OptionsWrapper>
            Enter an estimated commitment:
            <RadioButtonsWrap>
              {radioButtonItems.map((item, index) => (
                <RadioButton
                  key={item + index}
                  name="editFunnel"
                  radioButtonText={item}
                  selectedOption={selectedOption}
                  handleRadioChange={handleRadioChange}
                />
              ))}
            </RadioButtonsWrap>
          </OptionsWrapper>
          <>
            {selectedOption === radioButtonItems[0] ? (
              <InputsWrap>
                <Input
                  value={taxableInvestorsValue ? formatMaskedDollarInputValue(taxableInvestorsValue) : ''}
                  onChange={(e) => {
                    const formattedValue = formatDollarInputValue(e.target.value);
                    setTaxableInvestorsValue(formattedValue);
                  }}
                  label="Taxable Investors:"
                  size="md"
                  withDollarSign
                />
                <Input
                  value={exemptInvestorsValue ? formatMaskedDollarInputValue(exemptInvestorsValue) : ''}
                  onChange={(e) => {
                    const formattedValue = formatDollarInputValue(e.target.value);
                    setExemptInvestorsValue(formattedValue);
                  }}
                  label="Exempt Investors:"
                  size="md"
                  withDollarSign
                />
              </InputsWrap>
            ) : (
              <BannerWrapper>
                <Banner
                  title=""
                  description="Enter individual investor commitments on the next screen."
                  icon={<InfoIcon width={26} height={26} fill={theme.header.base} />}
                  bgColor={theme.layer[1]}
                  color={theme.font.base}
                />
              </BannerWrapper>
            )}
          </>
        </ModalBody>
      </ModalContainer>
    </ModalWrappedContent>
  );
};

export default AddToWatchlist;

const ModalContainer = styled.div`
  font-weight: 400;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const IconWrap = styled.div`
  display: flex;
  justify-content: center;
  justify-content: center;
`;

const ModalTitel = styled.div`
  font-size: 33px;
  text-align: center;
  color: ${({ theme }) => theme.font.strong};
`;

const ModalDescription = styled.div`
  font-size: 19px;
  line-height: 27px;
  text-align: center;
  color: ${({ theme }) => theme.font.base};
`;

const ModalBody = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 35px;
`;

const OptionsWrapper = styled.div`
  width: 55%;
  color: ${({ theme }) => theme.font.base};
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;
`;

const RadioButtonsWrap = styled.label`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
`;

const InputsWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 40%;
`;

const BannerWrapper = styled.div`
  width: 45%;
`;
