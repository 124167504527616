import * as React from 'react';

export const RevertIcon = ({ ...props }: React.SVGProps<SVGSVGElement>) => (
  <svg width={props.width || 12} height={props.height || 12} style={props?.style} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_5249_415825)">
      <path
        d="M3.09369 5.27074H4.48537C3.72564 6.28712 2.99597 7.26322 2.24322 8.27047C1.50174 7.27987 0.771007 6.30376 0 5.27342C0.469262 5.27342 0.881611 5.26805 1.29342 5.27611C1.47168 5.27933 1.56134 5.26108 1.60537 5.03665C2.04242 2.79611 3.78148 1.13544 6.01557 0.79772C8.76725 0.382149 11.411 2.28389 11.9119 5.03879C12.4499 7.99665 10.4784 10.7515 7.49745 11.2026C7.2698 11.2369 7.03785 11.2407 6.78765 11.26V9.77812C7.96886 9.71799 8.95946 9.26269 9.70094 8.3349C10.3211 7.55853 10.5906 6.66027 10.5063 5.67128C10.3415 3.74322 8.67758 2.22054 6.76564 2.23718C4.69423 2.2549 3.30685 3.83504 3.09423 5.27074H3.09369Z"
        fill={props?.fill || '#FAFAFA'}
      />
    </g>
    <defs>
      <clipPath id="clip0_5249_415825">
        <rect width="12" height="10.5187" fill="white" transform="translate(0 0.740723)" />
      </clipPath>
    </defs>
  </svg>
);
