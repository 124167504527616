import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';

import MobileModal from '@/components/mobileModal';
import { InfoListClientMobile } from '@/components/table/anchor-drop-down/InfoListClientMobile';
import { initialValuesFromTable } from '@/components/table/constants';
import { ADVISORS_QUERY } from '@/components/table/queries';
import { ISort, SORT_ORDER } from '@/components/table/types';
import { useResponsive } from '@/hooks/use-responsive';
import { MainWrap, PaddingWrap } from '@/styles/common';
import { SELECTORS } from '@/types/enums';
import { Advisor } from '@/types/family';
import SpecialControlHeader from '../fat-dealCatalog-page/fat-switcher/specialControlHeader';
import Header from '../fat-header';
import FilterPage from '../table/fat-filterPage';
import { VIEW_BY_SELECTS, clientSort } from './constants';
import { CLIENTS_LIST_QUERY } from './queries';
import Table from './table/table';
import { IClientsQuery, IHandleEntityClick, IRowData } from './types';

export const Clients = () => {
  const navigate = useNavigate();
  const { isMobile, isTablet, isPortrait } = useResponsive();

  const [searchParams, setSearchParams] = useSearchParams();

  const q = searchParams.get('q') || '';
  const viewBy = searchParams.get('viewBy') || 'Clients';
  const key = searchParams.get('key') || getSortKey(viewBy);
  const asc = searchParams.get('asc') === null || searchParams.get('asc') === 'true';
  const primaryFilter = searchParams.get('primaryFilter') || SELECTORS.ALL_ADVISORS;
  const limit = searchParams.get('limit') || initialValuesFromTable.initialAmountRowsPerPage[0];
  const offset = searchParams.get('offset') || 0;
  const switcher = searchParams.get('switcher') === null || searchParams.get('switcher') === 'true';
  const position = searchParams.get('position') || 0;

  const { data } = useQuery<{ Advisors: Advisor[] }>(ADVISORS_QUERY);
  const [mobileSort, setMobileSort] = useState(clientSort[0].title);
  const [mobileSortList, setMobileSortList] = useState(clientSort.map((item) => item.title));
  const advisorFilterList = data ? [SELECTORS.ALL_ADVISORS, ...data.Advisors.map((el) => el.initials)] : [];
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalData, setModalData] = useState<IRowData>();

  function getSortKey(viewBy: string) {
    if (viewBy === 'Entities') {
      return 'entityName';
    }
    return 'name';
  }

  useEffect(() => {
    refetch({
      includeRecommendation: switcher,
      order: asc ? SORT_ORDER.ASC : SORT_ORDER.DESC,
      sort: key
    });
  }, [switcher, mobileSort]);

  const {
    data: dataClients,
    loading,
    refetch
  } = useQuery<IClientsQuery>(CLIENTS_LIST_QUERY, {
    notifyOnNetworkStatusChange: true,
    variables: {
      includeRecommendation: switcher,
      advisor: primaryFilter === SELECTORS.ALL_ADVISORS ? '' : primaryFilter,
      order: asc ? SORT_ORDER.ASC : SORT_ORDER.DESC,
      sort: key,
      sortFor: viewBy,
      limit: Number(limit),
      offset: Number(offset),
      search: q
    },
    onCompleted: () => {
      makeScroll(position);
    }
  });

  const onRowClick = (data: IRowData) => {
    setModalData(data);
    setIsModalOpen(true);
  };

  const handleFamilyClick = (id: string) => {
    saveScrollPostion();
    navigate(`${id}?backToTitle=Clients`);
  };

  const handleEntityClick = ({ familyId, entityId }: IHandleEntityClick) => {
    saveScrollPostion();
    navigate(`${familyId}/${entityId}?backToTitle=Clients`);
  };

  const onChangeSearch = (searchValue: string) => {
    setSearchParams(
      (prev) => {
        if (!searchValue) {
          prev.delete('q');
          return prev;
        }
        prev.set('q', searchValue);
        return prev;
      },
      { replace: true }
    );
  };

  const onChangeSort = (value: ISort) => {
    setSearchParams(
      (prev) => {
        prev.set('key', value.key);
        prev.set('asc', value.asc.toString());
        return prev;
      },
      { replace: true }
    );
  };

  const onChangeViewBy = (viewByValue: string) => {
    setSearchParams(
      (prev) => {
        prev.set('viewBy', viewByValue);
        return prev;
      },
      { replace: true }
    );
  };

  const onChangePrimaryFilter = (filter: string) => {
    setSearchParams(
      (prev) => {
        prev.set('primaryFilter', filter);
        return prev;
      },
      { replace: true }
    );
  };

  const onChangePaginationData = (limit: number, offset: number) => {
    setSearchParams(
      (prev) => {
        prev.set('limit', limit.toString());
        prev.set('offset', offset.toString());
        return prev;
      },
      { replace: true }
    );
  };

  const toggleSwitcher = () => {
    setSearchParams(
      (prev) => {
        prev.set('switcher', Boolean(!switcher).toString());
        return prev;
      },
      { replace: true }
    );
    saveScrollPostion();
  };

  const saveScrollPostion = () => {
    setSearchParams(
      (prev) => {
        prev.set('position', window.scrollY.toString());
        return prev;
      },
      { replace: true }
    );
  };

  const makeScroll = (position: string | number) => {
    if (!position) return;
    setTimeout(() => window.scrollTo({ top: Number(position), behavior: 'smooth' }), 0);
    setSearchParams(
      (prev) => {
        prev.delete('position');
        return prev;
      },
      { replace: true }
    );
  };

  return (
    <ClientsWrap>
      {isModalOpen && modalData ? (
        <MobileModal onClose={() => setIsModalOpen(false)} title={modalData.name} onClick={() => handleFamilyClick(modalData.name)}>
          <InfoListClientMobile onRedirect={() => handleFamilyClick(modalData.name)} data={modalData} />
        </MobileModal>
      ) : (
        <></>
      )}
      <MainWrap>
        <Header specialControl={<SpecialControlHeader switcher={switcher} setSwitcher={toggleSwitcher} title="Include Recommendations" />} />
      </MainWrap>
      <PaddingWrap>
        <CustomFilterPage
          isMobile={isMobile}
          isTablet={isTablet}
          search={{
            value: q,
            onChange: onChangeSearch,
            placeholder: 'Search for a client'
          }}
          primaryFilter={{
            value: primaryFilter,
            onChange: onChangePrimaryFilter,
            selects: advisorFilterList
          }}
          resultsValue={viewBy === 'Entities' ? dataClients?.ClientsEntities?.totalEntities : dataClients?.ClientsEntities.total}
          refetch={refetch}
          viewBySelect={{
            value: viewBy,
            onChange: onChangeViewBy,
            selects: VIEW_BY_SELECTS
          }}
          mobileSort={{
            value: mobileSort,
            onChange: setMobileSort,
            selects: mobileSortList
          }}
        />
        <Table
          onRowClick={onRowClick}
          refetch={refetch}
          dataClients={dataClients}
          loading={loading}
          savedSort={{ key, asc }}
          savedSetSort={onChangeSort}
          handleFamilyClick={handleFamilyClick}
          handleEntityClick={handleEntityClick}
          advisorFilter={primaryFilter}
          paginationSaver={onChangePaginationData}
          viewByValue={viewBy}
        />
      </PaddingWrap>
    </ClientsWrap>
  );
};

const ClientsWrap = styled.div`
  width: 100%;
  margin-left: auto;
  box-sizing: border-box;
  margin-right: auto;
  display: block;
  padding: 0;
`;

const CustomFilterPage = styled(FilterPage)<{ isMobile: boolean; isTablet: boolean }>`
  position: sticky;
  top: ${({ isMobile, isTablet }) => (isMobile || isTablet ? '50px' : '0')};
  z-index: 9;
  margin-top: 0;
  margin-left: -1px;
  width: calc(100% + 2px);
  padding: ${({ isMobile }) => (isMobile ? '20px 0 0 0' : '20px 0 10px 0')};
`;
