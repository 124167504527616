import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';

import { LIST_DUE_DILIGENCE_RECORD_BASIC } from '@/components/allocations-page/queries';
import { COMPLIANCE_QUERY } from '@/components/compliance-page/queries';
import { COMPLIANCE } from '@/components/compliance-page/types';
import { GET_DUE_DILIGENCE_LIMITS } from '@/components/dueDiligence-page/queries';
import { GET_SETTINGS } from '@/components/settings-page/queries';
import { TASKS_QUERY } from '@/components/todo-page/queries';
import { Outlet } from 'react-router-dom';
import styled from 'styled-components';
import { useAuth } from '../../hooks/use-auth';
import { useResponsive } from '../../hooks/use-responsive';
import { DashboardNavbar } from './dashboard-navbar';
import { DashboardNavbarMobile } from './navbar-mobile';
import TrialLimitBanner from './trialLimitBanner';

export const DashboardLayout = () => {
  const { isMobile, isDesktop, isTablet, isLargeDesktop } = useResponsive();

  const { setUserTasks } = useAuth();

  const { data: taskData, refetch } = useQuery(TASKS_QUERY, {
    variables: {},
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      setUserTasks(data?.AllocationTasks?.assignedToMeTaskCount || 0);
    }
  });

  const [moduleConfig, setModuleConfig] = useState(undefined);

  const { data: settingsData } = useQuery(GET_SETTINGS, {
    variables: {
      type: 'appSetting'
    },
    onCompleted: (data) => {
      setModuleConfig(JSON.parse(data.Settings[0].value));
    }
  });

  const [showComplianceTab, setShowComplianceTab] = useState(false);

  const { data: dueDiligenceData } = useQuery(LIST_DUE_DILIGENCE_RECORD_BASIC, {
    notifyOnNetworkStatusChange: true,
    variables: {
      data: {
        sortBy: 'INVESTMENT_NAME',
        sortDirection: 'ASC',
        statusFilter: ['APPROVED', 'REJECTED', 'OPEN', 'ACTIVE', 'REALIZED', 'REDEEMED']
      }
    }
  });

  const { data: complianceData } = useQuery<{ Compliance: COMPLIANCE }>(COMPLIANCE_QUERY, {
    notifyOnNetworkStatusChange: true
  });

  useEffect(() => {
    if (dueDiligenceData?.listDueDiligenceRecords.records.length || complianceData?.Compliance.allocations.length) {
      setShowComplianceTab(true);
    }
  }, [dueDiligenceData, complianceData]);

  const [trialLimits, setTrialLimits] = useState({ trialMode: false, currentDDRRecordCount: 0, maxDeals: 0 });

  useQuery(GET_DUE_DILIGENCE_LIMITS, {
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      const { trialMode, currentDDRRecordCount, maxDeals } = data.getDueDiligenceLimits;

      setTrialLimits({ trialMode, currentDDRRecordCount, maxDeals });
    }
  });

  return !!moduleConfig ? (
    <DashboardLayoutWrap>
      {isDesktop || isLargeDesktop ? (
        <DashboardNavbar refetch={refetch} moduleConfig={moduleConfig} showComplianceTab={showComplianceTab} />
      ) : (
        <DashboardNavbarMobile refetch={refetch} moduleConfig={moduleConfig} showComplianceTab={showComplianceTab} />
      )}
      <DashboardLayoutRoot isMobile={isMobile} isTablet={isTablet}>
        {trialLimits.trialMode && <TrialLimitBanner currentDDRRecordCount={trialLimits.currentDDRRecordCount} maxDeals={trialLimits.maxDeals} />}
        <Outlet />
      </DashboardLayoutRoot>
    </DashboardLayoutWrap>
  ) : (
    <></>
  );
};

const DashboardLayoutRoot = styled.div<{ isMobile: boolean; isTablet: boolean }>`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  padding-left: 0;
  transition: 0.3s;
  margin-left: ${({ isMobile, isTablet }) => (isMobile || isTablet ? 0 : '80px')};
  margin-top: ${({ isMobile, isTablet }) => (isMobile || isTablet ? '50px' : 0)};
  min-height: 100vh;
  flex: 1 1 auto;
`;

const DashboardLayoutWrap = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  overflow-x: clip;
`;
