import styled from 'styled-components';

export interface ITab {
  value: string;
  amount: number | null;
  disabled?: boolean;
}

interface TabsProps {
  tabs: ITab[];
  activeTab: string;
  setActiveTab: (value: string) => void;
  className?: string;
}

const Tabs = ({ tabs, activeTab, setActiveTab, className }: TabsProps) => {
  const onChangeActiveTab = (item: ITab) => {
    if (item.disabled) return;
    setActiveTab(item.value);
  };

  return (
    <TabsWrapper className={className}>
      {tabs.map((item, index) => (
        <TabsTitle key={item.value + index} onClick={() => onChangeActiveTab(item)} activeTab={activeTab === item.value} disabled={item?.disabled}>
          {item.amount !== null && <CounterTitle isActive={activeTab === item.value}>{item.amount}</CounterTitle>}
          {item.value}
        </TabsTitle>
      ))}
    </TabsWrapper>
  );
};

const TabsWrapper = styled.div`
  padding-top: 21px;
  display: flex;
  gap: 5px;
`;

export const TabsTitle = styled.div<{ activeTab: boolean; disabled?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  min-width: max-content;
  padding: 9px 14px;
  background: ${({ activeTab, disabled, theme }) => (disabled ? theme.action.disabled : activeTab ? theme.action.hover : 'transparent')};
  color: ${({ activeTab, disabled, theme }) => (disabled ? theme.font.disabled : activeTab ? theme.header.action : theme.action.primary)};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  font-family: Blinker;
  font-size: 16px;
  font-weight: 600;
  line-height: 22.4px;
  border-radius: 10px 10px 0 0;
  margin-top: 15px;
  transition: 0.3s ease-in-out;
`;

export const CounterTitle = styled.div<{ isActive: boolean; isMobile?: boolean }>`
  font-family: Blinker, serif;
  display: flex;
  color: ${({ isActive, theme }) => (isActive ? theme.font.strong : theme.context.light)};
  font-size: 13px;
  font-weight: 700;
  line-height: 18.2px;
  border-radius: 10px;
  background: ${({ isActive, theme }) => (isActive ? theme.layer[1] : theme.action.primary)};
  height: ${({ isMobile }) => (isMobile ? '14px' : '21px')};
  width: ${({ isMobile }) => (isMobile ? '20px' : '34px')};
  justify-content: center;
  align-items: center;
  transition: 0.3s ease-in-out;
`;

export const TabsLine = styled.div`
  border-bottom: 1px ${({ theme }) => theme.border.base} solid;
`;

export const MainTabsMobile = styled.div`
  height: 90vh;
  padding-top: 20px;
`;

export const TabsWrapMobile = styled.div<{ isActive: boolean }>`
  display: flex;
  align-items: center;
  gap: 10px;
  width: auto;
  background: ${({ isActive, theme }) => (isActive ? theme.action.hover : 'transparent')};
  color: ${({ isActive, theme }) => (isActive ? theme.header.action : theme.action.primary)};
  padding: 13px 20px;
  border-radius: 4px;
  cursor: pointer;
`;

export const TabsDetailsTitleMobile = styled.div<{ isActive: boolean }>`
  cursor: pointer;
  font-family: Lato;
  font-weight: 700;
`;

export const TabTitle = styled.div<{ isActive: boolean; isMobile?: boolean }>`
  font-family: Lato, serif;
  font-size: ${({ isMobile }) => (isMobile ? '13px' : '16px')};
  font-weight: ${({ isMobile }) => (isMobile ? '600' : '700')};
  line-height: 22px;
  text-align: center;
  color: ${({ isActive, theme }) => (isActive ? theme.header.action : theme.action.primary)};
  transition: all 0.3s ease-in-out;
`;

export const TabMobileWrap = styled.div<{ isActive: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 17px;
  border-radius: 4px;
  background: ${({ isActive, theme }) => (isActive ? theme.action.hover : 'transparent')};

  transition: all 0.3s ease-in-out;
`;

export const TabsControl = styled.div<{ isMobile?: boolean }>`
  display: flex;
  align-items: ${({ isMobile }) => (isMobile ? 'center' : 'stretch')};
  gap: 13px;
`;

export default Tabs;
