import * as React from 'react';

export const ForecastIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || 50}
    height={props.height || 45}
    onClick={props.onClick || undefined}
    style={props?.style}
    viewBox="0 0 50 45"
    fill="none"
  >
    <g clipPath="url(#clip0_8655_69073)">
      <path
        d="M2.19243 44.9996C0.882812 44.9996 0.5 44.6049 0.5 43.2752C0.5 29.1478 0.5 16.3916 0.5 3.55223C0.5 3.26138 0.5 2.88741 0.620888 2.59656C0.80222 2.13949 1.24548 1.84863 1.74918 1.84863C1.82977 1.84863 1.93051 1.84863 2.0111 1.86941C2.65584 2.01484 3.0185 2.51345 3.03865 3.28215C3.03865 5.42204 3.03865 7.58271 3.03865 9.68104C3.03865 9.68104 3.03865 11.6963 3.03865 12.5689C3.03865 22.001 3.03865 31.4331 3.03865 40.886C3.03865 41.0938 3.03865 41.2808 3.0588 41.5093C3.0588 41.634 3.0588 41.7586 3.07895 41.8833V42.3819H42.1258C42.6497 42.3819 43.1735 42.3819 43.6974 42.3819C44.2212 42.3819 44.7451 42.3819 45.2689 42.3819C46.3569 42.3819 47.183 42.3819 47.9688 42.4234C48.3717 42.4234 48.8553 42.6312 49.1978 42.922C49.5201 43.2129 49.5806 43.67 49.3791 44.1894C49.1575 44.7503 48.7747 44.9996 48.1702 44.9996C46.2763 44.9996 44.3623 44.9996 42.4683 44.9996H24.0329C16.7595 44.9996 9.48602 44.9996 2.19243 44.9996Z"
        fill="#575757"
      />
      <path
        d="M2.19243 44.7299C1.0037 44.7299 0.761925 44.4806 0.761925 43.2964C0.761925 29.1482 0.761925 16.392 0.761925 3.55266C0.761925 3.30335 0.761925 2.95017 0.862665 2.70086C1.0037 2.34767 1.34622 2.11914 1.72903 2.11914C1.78947 2.11914 1.87007 2.11914 1.93051 2.13992C2.47451 2.26457 2.73643 2.63853 2.73643 3.28258C2.73643 5.44324 2.73643 7.62468 2.73643 9.74379C2.73643 10.6787 2.73643 11.6344 2.73643 12.5693C2.73643 22.0014 2.73643 31.4335 2.73643 40.8865C2.73643 41.0942 2.73643 41.302 2.75658 41.5305C2.75658 41.6344 2.75658 41.759 2.77673 41.9045L2.81702 42.6732H42.0855C42.6094 42.6732 43.1332 42.6732 43.6772 42.6732C44.2011 42.6732 44.7249 42.6732 45.2488 42.6732C46.3166 42.6732 47.1427 42.6732 47.9285 42.7147C48.271 42.7147 48.7142 42.9017 48.9963 43.151C49.3187 43.4211 49.1978 43.8574 49.097 44.1067C48.9157 44.5637 48.6336 44.7507 48.1299 44.7507C46.236 44.7507 44.322 44.7507 42.428 44.7507H23.9926C16.7192 44.7507 9.44572 44.7507 2.15214 44.7507L2.19243 44.7299Z"
        fill="#575757"
      />
      <path
        d="M9.02261 21.046C8.29729 21.046 7.79358 20.672 7.65255 20.0072C7.53166 19.4255 7.81373 18.7814 8.33758 18.5737C8.65995 18.449 9.08306 18.4282 9.44572 18.4075C16.699 18.3036 23.509 16.7039 29.6542 13.6706C33.9054 11.5723 37.3507 8.97534 40.1513 5.73434C40.1513 5.73434 40.8968 4.86176 41.7833 3.61523L40.1916 4.09307C39.7484 4.21772 39.3252 4.36315 38.9223 4.4878C38.0156 4.75789 37.1896 5.02797 36.3635 5.2565C36.2225 5.29805 36.0814 5.31883 35.9404 5.31883C35.5173 5.31883 35.1546 5.11107 34.9128 4.73711C34.6912 4.4047 34.6509 3.96841 34.7718 3.57368C34.8524 3.36592 35.0337 2.97118 35.5173 2.80498C38.3178 1.87007 41.2998 0.935171 44.3623 0.0418191C44.4831 0.000267811 44.604 -0.0205078 44.7249 -0.0205078C45.3898 -0.0205078 45.9338 0.540434 45.9338 1.26758C45.9741 4.21772 45.9741 7.35484 45.9338 10.5543C45.9338 11.3645 45.2689 11.8008 44.6443 11.8008H44.6242C43.9593 11.8008 43.4354 11.2191 43.4153 10.492C43.3951 9.84791 43.4153 9.18309 43.4153 8.4975C43.4153 8.16509 43.4153 7.85345 43.4153 7.52104V5.65124C42.7504 6.60692 42.146 7.37561 42.146 7.37561C37.653 12.4449 31.8705 16.1014 24.456 18.5529C19.6003 20.1526 14.4223 20.9836 9.06291 21.0044L9.02261 21.046Z"
        fill="#8F8F8F"
      />
      <path
        d="M42.428 3.13754L40.111 3.84391C39.6678 3.96857 39.2447 4.114 38.8417 4.23865C37.9552 4.50873 37.109 4.77882 36.2829 5.00735C36.162 5.0489 36.0411 5.06968 35.9202 5.06968C35.5777 5.06968 35.2956 4.90347 35.1143 4.61261C34.933 4.34253 34.8927 3.98934 35.0136 3.67771C35.074 3.51151 35.215 3.19987 35.5979 3.07522C38.3984 2.14031 41.3803 1.20541 44.4428 0.312059C44.5436 0.291283 44.6242 0.270508 44.7249 0.270508C45.2488 0.270508 45.6719 0.727572 45.6719 1.30929C45.7122 4.25943 45.7122 7.37577 45.6719 10.596C45.6719 11.24 45.148 11.5932 44.6443 11.5932H44.6242C44.1003 11.5932 43.6772 11.1362 43.6571 10.5544C43.6369 9.9104 43.6571 9.24558 43.6571 8.55998C43.6571 8.24835 43.6571 7.91594 43.6571 7.58353V4.86192C42.8109 6.12923 41.9243 7.29267 41.9243 7.29267C37.4716 12.3204 31.7294 15.9561 24.3553 18.3869C19.5197 19.9866 14.3618 20.7968 9.04276 20.8384C8.43832 20.8384 8.05551 20.5475 7.93462 20.0074C7.83388 19.5503 8.07566 19.0517 8.45847 18.8855C8.74054 18.7816 9.12335 18.7608 9.46587 18.74C16.7595 18.6362 23.5896 17.0157 29.7952 13.9616C34.0868 11.8425 37.5522 9.2248 40.3729 5.96303C40.3729 5.96303 41.3803 4.77882 42.4482 3.1791L42.428 3.13754Z"
        fill="#8F8F8F"
      />
      <path
        d="M42.831 37.6248C42.146 37.6248 41.6221 37.0431 41.602 36.2328C41.5818 35.4226 41.5818 34.5708 41.5818 33.7605C41.5818 33.4697 41.5818 33.1996 41.5818 32.9295V30.4572C41.5818 26.406 41.5818 22.2093 41.5818 18.0957C41.5818 17.2439 41.9646 16.7038 42.6497 16.5791C42.7101 16.5791 42.7706 16.5791 42.831 16.5791C43.375 16.5791 43.8787 16.9323 44.0399 17.4517C44.1205 17.7218 44.1205 18.0957 44.1205 18.3866C44.1205 20.4641 44.1205 22.5209 44.1205 24.5777V29.6054C44.1205 31.7453 44.1205 33.8852 44.1205 36.0251C44.1205 36.8353 43.8384 37.334 43.2541 37.5417C43.0929 37.604 42.9519 37.6248 42.7907 37.6248H42.831Z"
        fill="#2B2B2B"
      />
      <path
        d="M42.831 37.3549C42.287 37.3549 41.884 36.8978 41.8639 36.233C41.8438 35.4228 41.8438 34.5917 41.8438 33.7815C41.8438 33.5114 41.8438 33.2205 41.8438 32.9504V30.4989C41.8438 26.4477 41.8438 22.251 41.8438 18.1167C41.8438 17.618 41.9848 16.9948 42.69 16.8701C42.7303 16.8701 42.7706 16.8701 42.831 16.8701C43.2541 16.8701 43.6772 17.161 43.7981 17.5557C43.8787 17.7842 43.8787 18.1374 43.8787 18.4075C43.8787 20.5266 43.8787 22.6457 43.8787 24.7649V29.6264C43.8787 31.7662 43.8787 33.9061 43.8787 36.046C43.8787 36.9601 43.4757 37.2095 43.1937 37.3133C43.0728 37.3549 42.9519 37.3757 42.831 37.3757V37.3549Z"
        fill="#2B2B2B"
      />
      <path
        d="M32.1324 37.6249C31.3265 37.6249 30.8631 37.0224 30.8631 36.0044C30.8631 32.0778 30.8631 28.0681 30.8631 23.7467C30.8631 22.2717 31.7697 22.1055 32.1525 22.1055C32.9585 22.1055 33.4219 22.708 33.4219 23.7675C33.4219 25.1387 33.4219 26.4891 33.4219 27.8603V29.0861V29.8963V31.8285C33.4219 33.2204 33.4219 34.6332 33.4219 36.0251C33.4219 37.0224 32.9585 37.6041 32.1727 37.6249H32.1525H32.1324Z"
        fill="#2B2B2B"
      />
      <path
        d="M32.1324 37.3542C31.2257 37.3542 31.125 36.4193 31.125 36.0038C31.125 32.098 31.125 28.0883 31.125 23.7462C31.125 22.6243 31.6891 22.375 32.1525 22.375C33.0189 22.375 33.1599 23.2476 33.1599 23.767C33.1599 25.2005 33.1599 26.634 33.1599 28.0675V29.1063V29.9165V31.7448C33.1599 33.1783 33.1599 34.6118 33.1599 36.0454C33.1599 36.6479 32.9786 37.3542 32.1727 37.375H32.1525L32.1324 37.3542Z"
        fill="#2B2B2B"
      />
      <path
        d="M21.4137 37.6243C20.6682 37.6243 20.1645 37.0842 20.1443 36.2531C20.1242 33.1991 20.1242 30.1451 20.1443 27.1326C20.1443 26.3432 20.6682 25.7822 21.4137 25.7822H21.4539C22.1793 25.7822 22.6628 26.3432 22.6628 27.1534C22.6628 28.2545 22.6628 29.3556 22.6628 30.436V30.5191C22.6628 30.9346 22.6628 31.3293 22.6628 31.7448C22.6628 32.1811 22.6628 32.6382 22.6628 33.0745C22.6628 34.0925 22.6628 35.1313 22.6628 36.17C22.6628 37.0218 22.1793 37.5828 21.4338 37.6036L21.4137 37.6243Z"
        fill="#2B2B2B"
      />
      <path
        d="M21.4137 37.3547C20.8092 37.3547 20.4063 36.9184 20.4063 36.2536C20.3861 33.1996 20.3861 30.1455 20.4063 27.1331C20.4063 26.489 20.8092 26.0527 21.4137 26.0527H21.4338C22.0181 26.0527 22.3808 26.489 22.3808 27.1538C22.3808 28.2549 22.3808 29.3768 22.3808 30.4572C22.3808 30.8935 22.3808 31.3297 22.3808 31.7453C22.3808 32.2023 22.3808 32.6386 22.3808 33.0749C22.3808 34.0929 22.3808 35.1317 22.3808 36.1705C22.3808 36.8768 21.9979 37.3339 21.4137 37.3339V37.3547Z"
        fill="#2B2B2B"
      />
      <path
        d="M10.6748 37.6242C9.92928 37.6242 9.44573 37.0217 9.44573 36.1076V35.048C9.44573 33.0743 9.40543 31.0383 9.44573 29.0439C9.44573 28.0882 10.0703 27.6104 10.6748 27.6104C10.9165 27.6104 11.1382 27.6727 11.3598 27.8181C11.7829 28.0674 11.9642 28.4622 11.9642 29.0023C11.9642 29.8333 11.9642 30.6851 11.9642 31.5162V33.1367V33.386V33.6768C11.9642 34.4663 11.9642 35.2766 11.9642 36.066C11.9642 37.0217 11.4605 37.6242 10.6748 37.6242Z"
        fill="#2B2B2B"
      />
      <path
        d="M10.6748 37.3548C9.96958 37.3548 9.70765 36.7108 9.70765 36.1083V35.0487C9.70765 33.075 9.66736 31.039 9.70765 29.0446C9.70765 28.2759 10.2114 27.8604 10.6748 27.8604C10.8561 27.8604 11.0374 27.9227 11.2188 28.0266C11.5613 28.2343 11.7023 28.5252 11.7023 28.9822C11.7023 29.834 11.7023 30.6858 11.7023 31.5169V33.3867C11.7023 33.3867 11.7023 33.5529 11.7023 33.6568C11.7023 34.4462 11.7023 35.2773 11.7023 36.0667C11.7023 36.8562 11.3195 37.3548 10.6748 37.3548Z"
        fill="#2B2B2B"
      />
    </g>
    <defs>
      <clipPath id="clip0_8655_69073">
        <rect width="49" height="45" fill="white" transform="translate(0.5)" />
      </clipPath>
    </defs>
  </svg>
);
