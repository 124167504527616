import { Dispatch, SetStateAction, useEffect, useState } from 'react';

import { CloseInfoIcon } from '@/assets/icons/info-icons/closeInfo';
import Label from '@/components/basicComponents/label';
import FilterSelect from '@/components/basicComponents/select';
import { AddButton } from '@/components/fat-dealPipeline-page/fat-modify/addButton';
import { useResponsive } from '@/hooks/use-responsive';
import styled, { useTheme } from 'styled-components';
import { LabelWrap } from '../input';
import { Section } from '../section';
import { ToggleCircle } from '../toggleCircle';
import { IDataFields } from '../types';

interface AllocationRestrictionsSectionProps {
  dataFields: IDataFields;
  changeProperties: (field: keyof IDataFields) => (value: string | string[] | number) => void;
  listSectorsToAvoid: string[];
}

export const AllocationRestrictionsSection = ({ dataFields, changeProperties, listSectorsToAvoid }: AllocationRestrictionsSectionProps) => {
  const { isMobile, isTablet } = useResponsive();
  const theme = useTheme();

  const [strategiesList, setStrategiesList] = useState<string[]>(['None']);
  const [chosenStrategies, setChosenStrategies] = useState<string[]>([]);

  useEffect(() => {
    if (dataFields?.sectorsToAvoid) {
      setChosenStrategies(dataFields.sectorsToAvoid);
    }
  }, [dataFields.sectorsToAvoid]);

  useEffect(() => {
    if (listSectorsToAvoid.length && dataFields.sectorsToAvoid) {
      setStrategiesList(listSectorsToAvoid.filter((strategies) => !dataFields.sectorsToAvoid.includes(strategies)));
    }
  }, [listSectorsToAvoid, dataFields?.sectorsToAvoid]);

  const addStrategies = (chosen: string[], setChosen: Dispatch<SetStateAction<string[]>>) => {
    const newStrategies = [...chosen];
    newStrategies.push('None');
    setChosen(newStrategies as string[]);
    changeProperties('sectorsToAvoid')(newStrategies);
  };

  const delStrategies = (
    el: string,
    index: number,
    chosen: string[],
    setChosen: Dispatch<SetStateAction<string[]>>,
    list: string[],
    setList: Dispatch<SetStateAction<string[]>>
  ) => {
    const newStrategies = [...chosen];
    newStrategies.splice(index, 1);
    setChosen(newStrategies as string[]);
    changeProperties('sectorsToAvoid')(newStrategies);
    if (el !== 'None') {
      setList([...list, el]);
    }
  };

  const onChangeStrategies = (
    value: string,
    index: number,
    chosen: string[],
    setChosen: Dispatch<SetStateAction<string[]>>,
    list: string[],
    setList: Dispatch<SetStateAction<string[]>>
  ) => {
    const newStrategies = [...chosen];
    newStrategies[index] = value;
    setChosen(newStrategies);
    changeProperties('sectorsToAvoid')(newStrategies);
    const filteredList = list.filter((item) => item !== value);
    if (chosen[index] !== 'None') {
      setList([chosen[index], ...filteredList]);
      return;
    }
    setList(filteredList);
  };

  return (
    <Section title="Allocation Restrictions">
      <TogglesWrap isMobile={isMobile}>
        <ToggleCircle
          data={dataFields.onHold}
          label="On Hold"
          tooltip="Flag this entity for no new commitments."
          name="onHold"
          handleRadioChange={(value) => changeProperties('onHold')(value)}
        />
        {/* <ToggleCircle
          data={dataFields.custodied}
          label="Custodial Requirement"
          tooltip="Flag this entity as unsuitable for opportunities that do not use a custodian."
          name="custodialRequirement"
          handleRadioChange={value => changeProperties('custodied')(value)}
        /> */}
        <ToggleCircle
          data={dataFields.ubtiBlocker}
          label="Tax Exempt"
          tooltip="Flag this entity as unsuitable for opportunities that do not provide a UBTI Blocker."
          name="blockerNeeded"
          handleRadioChange={(value) => changeProperties('ubtiBlocker')(value)}
        />
      </TogglesWrap>
      <StrategiesWrap isMobile={isMobile}>
        <LabelWrap>
          <Label fontSize="16px" required={false} tooltip="Add any Sub Asset Classes that should be flagged as unsuitable for this entity.">
            Excluded Sub Asset Classes
          </Label>
        </LabelWrap>
        {chosenStrategies.map((el, index) => (
          <StrategiesItemWrap key={`strategies-${index}`}>
            <StrategiesFilterSelect
              data={strategiesList}
              selected={chosenStrategies[index]}
              setSelected={(value) => onChangeStrategies(value, index, chosenStrategies, setChosenStrategies, strategiesList, setStrategiesList)}
              disabled={chosenStrategies[chosenStrategies.length - 1] !== el}
              width="100%"
              withSearch
              fontSize="16px"
            />
            <DelItemWrap onClick={() => delStrategies(el, index, chosenStrategies, setChosenStrategies, strategiesList, setStrategiesList)}>
              <CloseInfoIcon width={21} height={20} fill={theme.font.action} />
            </DelItemWrap>
          </StrategiesItemWrap>
        ))}
        {strategiesList.length && chosenStrategies[chosenStrategies.length - 1] !== 'None' ? (
          <AddButton text="Add" onClick={() => addStrategies(chosenStrategies, setChosenStrategies)} />
        ) : (
          <></>
        )}
      </StrategiesWrap>
    </Section>
  );
};

const TogglesWrap = styled.div<{ isMobile: boolean }>`
  display: flex;
  flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};
  gap: ${({ isMobile }) => (isMobile ? '15px' : '0')};
`;

const StrategiesWrap = styled.div<{ isMobile: boolean }>`
  display: flex;
  width: ${({ isMobile }) => (isMobile ? '100%' : '50%')};
  flex-direction: column;
`;

const StrategiesFilterSelect = styled(FilterSelect)`
  background: ${({ disabled, theme }) => (disabled ? theme.action.disabled : theme.layer[2])};
  border: 1px solid ${({ theme }) => theme.border.base};
  padding: 10px 12px;
  border-radius: 5px;

  #titleSelect {
    text-transform: none;
    line-height: 22.4px;
  }
`;

const StrategiesItemWrap = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  margin-bottom: 10px;
  position: relative;
`;

const DelItemWrap = styled.div`
  position: relative;
  top: 3px;
  padding-left: 15px;
  cursor: pointer;
`;
