import * as React from 'react';

export const NotesIcon = ({ ...props }: React.SVGProps<SVGSVGElement>) => (
  <svg width={props.width || '30'} height={props.height || '30'} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M27.0027 22.7543L25.2475 20.9991H2.9973V2.99731H27.0027V22.7543ZM27.0027 0H2.9973C2.2031 0.00237431 1.4421 0.318943 0.880512 0.880532C0.318923 1.44212 0.00237431 2.20311 0 2.99731V20.9991C0.00237431 21.7933 0.318923 22.5543 0.880512 23.1159C1.4421 23.6775 2.2031 23.9941 2.9973 23.9964H24.0054L30 30V2.99731C29.9976 2.20311 29.6811 1.44212 29.1195 0.880532C28.5579 0.318943 27.7969 0.00237431 27.0027 0Z"
      fill={props.fill || '#2E2E2E'}
    />
  </svg>
);
