export const formatProgressCash = (n: number) => {
  if (n < 1e3) return n;
  if (n >= 1e3 && n < 0.6e6) return +(n / 1e3).toFixed(1) + 'K';
  if (n >= 0.6e6 && n < 1e9) return +(n / 1e6).toFixed(1) + 'M';
  if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + 'B';
  if (n >= 1e12) return +(n / 1e12).toFixed(1) + 'T';
};

export const formatCash = (n: number, showDecimal: boolean = false) => {
  if (n === null) return '--';
  if (n < 0.99999999e6 && n > -0.99999999e6)
    return n.toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: showDecimal ? 2 : 0,
      maximumFractionDigits: showDecimal ? 2 : 0
    });
  if ((n >= 0.999995e6 && n <= 0.999995e9) || (n <= -1e6 && n > -1e9))
    return (
      (n / 1e6).toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }) + 'M'
    );
  if ((n >= 0.999995e9 && n <= 0.999995e12) || (n <= -1e9 && n > -1e12))
    return (
      (n / 1e9).toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }) + 'B'
    );
  if (n >= 0.999995e12 || n <= -1e12)
    return (
      (n / 1e12).toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }) + 'T'
    );
};
