import { formatCash } from '@/utils/formatCash';
import styled, { useTheme } from 'styled-components';

import { FilledIcon } from '@/assets/icons/info-icons/filledIcon';
import { ProgressBarCursorIcon } from '@/assets/icons/info-icons/progressBarCursor';
import { getUTCDate } from '@/components/allocations-page/utils';
import BasicTooltip from '@/components/basicComponents/tooltip';
import { IPacingProgressBarValues } from '@/components/client-entity-details/types';
import { useResponsive } from '@/hooks/use-responsive';

interface PacingProgressBarProps {
  progressBarData: IPacingProgressBarValues | null;
}

const PacingProgressBar = ({ progressBarData }: PacingProgressBarProps) => {
  const { isMobile } = useResponsive();
  const theme = useTheme();

  if (!progressBarData) return <></>;

  return (
    <ProgressBarContainer>
      <Title>
        {`Commitment Plan to Date Through ${getUTCDate(new Date(progressBarData.currentQuarterEnd)).toUpperCase()}`}
        <BasicTooltip
          translateX={isMobile ? -68 : -70}
          tooltipContent={
            <TooltipBody>
              <TooltipItem>{`${progressBarData?.totalCommitments.toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD',
                minimumFractionDigits: 0,
                maximumFractionDigits: 0
              })}: Total committed capital for all commitments since plan start`}</TooltipItem>
              <TooltipItem>{`${progressBarData?.planGoal.toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD',
                minimumFractionDigits: 0,
                maximumFractionDigits: 0
              })}: Pacing plan goal since start`}</TooltipItem>
              <TooltipItem>{`${progressBarData?.totalGoal.toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD',
                minimumFractionDigits: 0,
                maximumFractionDigits: 0
              })}: Pacing plan plus total evergreen target allocation`}</TooltipItem>
            </TooltipBody>
          }
        >
          <FilledIcon />
        </BasicTooltip>
      </Title>
      <ProgressBarWrap>
        <ProgressCursor margin={(progressBarData?.planGoal * 100) / (progressBarData?.totalGoal ?? 0)}>
          <CursorTitle>{formatCash(progressBarData.planGoal)}</CursorTitle>
          <ProgressBarCursorIcon fill={theme.font.weak} />
        </ProgressCursor>
        <ProgressWrap>
          <ProgressTitle>{formatCash(progressBarData.totalCommitments)}</ProgressTitle>
          <Progress
            isFull={!!progressBarData?.isGoalAchieved}
            width={(progressBarData?.totalCommitments * 100) / (progressBarData?.totalGoal ?? 0)}
          />
        </ProgressWrap>
      </ProgressBarWrap>
      <StartEnd>
        <StartEndTitle>{formatCash(0)}</StartEndTitle>
        <StartEndTitle>{formatCash(progressBarData.totalGoal)}</StartEndTitle>
      </StartEnd>
    </ProgressBarContainer>
  );
};

export default PacingProgressBar;

const TooltipBody = styled.div`
  display: flex;
  flex-direction: column;
`;

const TooltipItem = styled.div`
  font-family: 'Blinker', serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0;
  text-align: left;
`;

const StartEndTitle = styled.div`
  font-family: 'Blinker', serif;
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0;
  color: ${({ theme }) => theme.font.weak};
`;

const StartEnd = styled.div`
  margin-top: -12px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ProgressTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  font-family: 'Blinker', serif;
  font-size: 13px;
  font-weight: 600;
  line-height: 12px;
  text-align: center;
  color: ${({ theme }) => theme.context.light};
`;

const Progress = styled.div<{ width: number | string; isFull: boolean }>`
  display: flex;
  width: ${({ width }) => `${width}%`};
  height: 20px;
  background: ${({ isFull, theme }) => (isFull ? theme.context.error : theme.context.success)};
  transition: 0.3s ease-in-out;
`;

const ProgressWrap = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-start;
  overflow: hidden;
  width: 100%;
  height: 20px;
  border-radius: 10px;
  background: #94e3a1;
  max-height: 20px;
`;

const CursorTitle = styled.div`
  font-family: Blinker, serif;
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0;
  text-align: left;
  margin-bottom: -7px;
  color: ${({ theme }) => theme.font.weak};
`;

const ProgressCursor = styled.div<{ margin: number }>`
  display: flex;
  margin-left: ${({ margin }) => `calc(${margin}% - 14px)`};
  align-items: center;
  align-self: flex-start;
  flex-direction: column;
  gap: 4px;
  width: 28px;
  min-width: 28px;
  max-width: 28px;
  transition: 0.3s ease-in-out;
`;

const ProgressBarWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  font-family: Blinker, serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0;
  color: ${({ theme }) => theme.font.strong};
`;

const ProgressBarContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 12px;
  padding: 20px 30px;
`;
