import { format, parse } from 'date-fns';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import FormattingTooltip from '@/components/basicComponents/tooltip/dollarsTooltip';
import Button from '../fat-basicComponents/button';
import { InvestmentMoreInfoType } from './types';

interface InfoListProps {
  data: InvestmentMoreInfoType;
}

export const ComplianceInvestmentInfoList = ({ data }: InfoListProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const handleClick = (managerId: string, id: string) => {
    const newPath = `/investments/manager/investment/?mid=${managerId}&id=${id}`;
    const state = { prevPath: location.pathname };
    return navigate(newPath, { state });
  };

  const getBoolean = (value: boolean) => (value ? 'YES' : 'NO');
  return (
    <MoreDataWrap id="modal">
      <MoreDataItem id="modal">
        <MoreDataItemTitle id="modal">Manager</MoreDataItemTitle>
        <MoreDataItemValue id="modal">{data.managerId}</MoreDataItemValue>
      </MoreDataItem>

      <MoreDataItem id="modal">
        <MoreDataItemTitle id="modal">Asset Class</MoreDataItemTitle>
        <MoreDataItemValue id="modal">{data.assetClass}</MoreDataItemValue>
      </MoreDataItem>

      <MoreDataItem id="modal">
        <MoreDataItemTitle id="modal">Next Close</MoreDataItemTitle>
        <MoreDataItemValue id="modal">
          {data.nextClose ? format(parse(data.nextClose, 'yyyy-MM-dd', new Date()), 'LLL d, yyyy').toUpperCase() : 'No date'}
        </MoreDataItemValue>
      </MoreDataItem>

      <MoreDataItem id="modal">
        <MoreDataItemTitle id="modal">Final Close</MoreDataItemTitle>
        <MoreDataItemValue id="modal">
          {data.finalClose ? format(parse(data.finalClose, 'yyyy-MM-dd', new Date()), 'LLL d, yyyy').toUpperCase() : 'No date'}
        </MoreDataItemValue>
      </MoreDataItem>

      <MoreDataItem id="modal">
        <MoreDataItemTitle id="modal">Minimum</MoreDataItemTitle>
        <MoreDataItemValue id="modal">
          <FormattingTooltip>{data.minimum}</FormattingTooltip>
        </MoreDataItemValue>
      </MoreDataItem>

      <MoreDataItem id="modal">
        <MoreDataItemTitle id="modal">UBTI Blocker</MoreDataItemTitle>
        <MoreDataItemValue id="modal">{getBoolean(data?.ubtiBlocker)}</MoreDataItemValue>
      </MoreDataItem>

      <MoreDataItem id="modal">
        <MoreDataItemTitle id="modal">SRI</MoreDataItemTitle>
        <MoreDataItemValue id="modal">{getBoolean(data?.sri)}</MoreDataItemValue>
      </MoreDataItem>

      <MoreDataItem id="modal">
        <MoreDataItemTitle id="modal">Target IRR</MoreDataItemTitle>
        <MoreDataItemValue id="modal">{data.targetIRR}%</MoreDataItemValue>
      </MoreDataItem>

      <MoreDataItem id="modal">
        <MoreDataItemTitle id="modal">Target Yield</MoreDataItemTitle>
        <MoreDataItemValue id="modal">{data.targetYield}%</MoreDataItemValue>
      </MoreDataItem>
      {data.id && (
        <RedirectButton styleType="outline" size="md" onClick={() => handleClick(data.managerId, data.id)}>
          View {data.name}
        </RedirectButton>
      )}
    </MoreDataWrap>
  );
};

const MoreDataItemValue = styled.div`
  font-family: 'Blinker', serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0;
  text-align: left;
  width: 200px;
  color: ${({ theme }) => theme.font.base};
`;

const MoreDataItemTitle = styled.div`
  font-family: 'Blinker', serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0;
  text-align: left;
  width: 150px;
  min-width: 150px;
  color: ${({ theme }) => theme.font.base};
`;

const MoreDataItem = styled.div`
  display: flex;
  align-items: center;
  padding: 5px 20px;
  background-color: ${({ theme }) => theme.layer[1]};
  gap: 10px;
  border-radius: 4px;
`;

const MoreDataWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3px;
`;

const RedirectButton = styled(Button)`
  margin-top: 13px;
`;
