import { useMutation } from '@apollo/client';
import { useNavigate, useLocation } from 'react-router-dom';
import { DeleteBucketIcon } from '@/assets/static/icons/delete-bucket';
import { useEffect, useState } from 'react';
import ModalWrappedContent from '@/components/basicComponents/modal/modalWrappedContent';
import {
  ISection,
  ITier
} from '@/components/dueDiligence-page/dueDiligenceRecord/types';
import {
  DELETE_DUE_DILIGENCE_FIELD,
  GET_FUNNEL_QUERY,
  UPDATE_DRAFT_DUE_DILIGENCE_FIELD
} from '@/components/settings-page/queries';
import styled from 'styled-components';

interface DeleteFieldProps {
  onClose: () => void;
  isOpen: boolean;
  currentId: string;
  tiersData: ITier[];
  currentSection: ISection | null;
  funnelRefetch: () => void;
}

const DeleteField = ({
  onClose,
  isOpen,
  currentId,
  tiersData,
  currentSection,
  funnelRefetch
}: DeleteFieldProps) => {
  if (!currentSection) return <></>;
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [linkedFieldsError, setLinkedFieldsError] = useState<
    { tierName: string; fieldName: string }[]
  >([]);

  const [fieldName, setFieldName] = useState('');

  useEffect(() => {
    const currentField = currentSection.fields.find(
      (field) => field.id === currentId
    );
    if (currentField) {
      setFieldName(currentField.name);
    }
  }, [currentSection]);

  useEffect(() => {
    tiersData.forEach((tier) => {
      tier.sections.forEach((section) => {
        section.fields.forEach((field) => {
          if (currentId === field.linkedFieldId) {
            setLinkedFieldsError([
              { tierName: tier.name, fieldName: field.name }
            ]);
          }
        });
      });
    });
  }, []);

  const [deleteField] = useMutation(DELETE_DUE_DILIGENCE_FIELD);
  const [updateDraftField] = useMutation(UPDATE_DRAFT_DUE_DILIGENCE_FIELD, {
    refetchQueries: () => [
      {
        query: GET_FUNNEL_QUERY,
        variables: {
          id: queryParams.get('funnelId')
        }
      }
    ]
  });

  const saveValue = () => {
    deleteField({
      variables: {
        id: currentId
      },
      onCompleted: () => {
        const filteredFields = currentSection.fields.filter(
          (field) => field.id !== currentId
        );
        if (!filteredFields.length) {
          funnelRefetch();
          return;
        }
        for (const [index, field] of filteredFields.entries()) {
          updateDraftField({
            variables: {
              data: {
                id: field.id,
                order: index + 1
              }
            }
          });
        }
      }
    });
    onClose();
  };

  return (
    <ModalWrappedContent
      isOpen={isOpen}
      onClose={onClose}
      showCancelButton={!linkedFieldsError.length}
      confirmButton={{
        name: linkedFieldsError.length ? 'OK' : 'Confirm',
        onClick: linkedFieldsError.length ? onClose : saveValue
      }}
    >
      <ModalContainer>
        {!linkedFieldsError.length ? (
          <>
            <DeleteBucketIcon width={46} height={60} />
            <ModalTitle>
              Please confirm you want to delete the{' '}
              <span style={{ fontWeight: 700 }}>{fieldName}</span> field.
            </ModalTitle>
            <ModalDescription>
              This action deletes the selected field and the associated prompts
              for the AI Assistant. There is no undo for this operation. Changes
              to this draft funnel are saved immediately. Once you publish your
              draft, existing diligence records will be migrated to the new
              version, which may result in a loss of data.
            </ModalDescription>
          </>
        ) : (
          <>
            <ModalTitle>Cannot Delete Field</ModalTitle>
            You cannot delete this field because there are other fields linked
            to it. Please delete or unlink the following fields before deleting
            this one.
            <LinkedFieldsList>
              {linkedFieldsError.map((linkedField, index) => (
                <LinkedFieldItem key={linkedField.fieldName + index}>
                  {linkedField.tierName} - {linkedField.fieldName}
                </LinkedFieldItem>
              ))}
            </LinkedFieldsList>
          </>
        )}
      </ModalContainer>
    </ModalWrappedContent>
  );
};

export default DeleteField;

const ModalContainer = styled.div`
  font-weight: 400;
  padding: 10px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  text-align: center;
`;

const ModalTitle = styled.div`
  font-size: 33px;
  font-weight: 400;
  text-align: center;
  color: #2e2e2e;
`;

const ModalDescription = styled.div`
  font-size: 16px;
  line-height: 27px;
  text-align: center;
  color: #373f4e;
  font-weight: 400;
`;

const LinkedFieldsList = styled.ul``;
const LinkedFieldItem = styled.li``;
