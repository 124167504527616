export const valuationsSteps = [
  { name: 'Upload Data File', status: 'IN_PROGRESS' },
  { name: 'Entity Codes', status: 'NOT_STARTED' },
  { name: 'Series Codes', status: 'NOT_STARTED' },
  { name: 'Commitments', status: 'NOT_STARTED' },
];

export const valuationsDescriptionSectionData = {
  title: 'Import Valuations  performs the following actions',
  description: [
    'Adds the valuations listed in the import file to those added previously (rather than doing a full replace).',
    'Aborts the import and shows an error if there is no commitment (entity x series ) that corresponds to each valuation.',
    'Regenerates aggregate values for each commitment, i.e., market value.',
  ],
};

export const valuationsColumnsSectionData = {
  title: 'Provide the following columns in the upload file',
  columns: [
    { name: 'Entity Code', isRequired: true },
    { name: 'Series Code', isRequired: true },
    { name: 'Valuation Update Date', isRequired: true },
    { name: 'Entity Name', isRequired: false },
    { name: 'Series Name', isRequired: false },
    { name: 'Amount', isRequired: true },
    { name: 'Advisory Firm', isRequired: false },
  ],
};
