import { useRef, useState } from 'react';
import styled, { css, useTheme } from 'styled-components';

import { ArrowDown } from '@/assets/icons/info-icons/arrowDown';
import { useResponsive } from '../../hooks/use-responsive';
import { useClickOutside } from '../../hooks/useClickOutside';
import { FadeAnimation } from '../../styles/animations';
import { SIZE_DROPDOWN_ACTIONS } from './constants';
import { IItems } from './types';

interface ClientDropdownProps {
  items: IItems[];
  disabled?: boolean;
  isMobile?: boolean;
  selected?: boolean;
  isListCenter?: boolean;
  showListItems?: boolean;
}

const DropdownActions = ({
  items,
  disabled = false,
  isMobile = false,
  selected = false,
  isListCenter = false,
  showListItems = true
}: ClientDropdownProps) => {
  const { isTablet } = useResponsive();
  const theme = useTheme();

  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const handleClick = (event: any) => {
    if (event.target.id !== 'modal') {
      setIsOpen(!isOpen);
    }
  };
  useClickOutside(ref, () => setIsOpen(false));

  if (isMobile) {
    return (
      <ClientDropdownMobileWrap isOpen={isOpen} onClick={handleClick} id="infoWrap" ref={ref} disabled={disabled}>
        <ArrowDown fill={theme.context.light} />
        {(isOpen || showListItems) && (
          <ClientDropdownModal isOpen={isOpen} isListCenter={isListCenter}>
            {items.map(({ name, onClick }) => (
              <ClientDropdownItem key={name} onClick={onClick}>
                {name}
              </ClientDropdownItem>
            ))}
          </ClientDropdownModal>
        )}
      </ClientDropdownMobileWrap>
    );
  }

  return (
    <ClientDropdownWrap isOpen={isOpen} onClick={handleClick} id="infoWrap" ref={ref} opacityTime={0} removepointerevents disabled={disabled}>
      <ArrowDown fill={theme.context.light} />
      {(isOpen || showListItems) && (
        <ClientDropdownModal isOpen={isOpen}>
          {items.map(({ name, onClick }) => (
            <ClientDropdownItem key={name} onClick={onClick}>
              {name}
            </ClientDropdownItem>
          ))}
        </ClientDropdownModal>
      )}
    </ClientDropdownWrap>
  );
};

export const EmptyDropdownActions = () => {
  const { isTablet } = useResponsive();

  return (
    <ClientDropdownWrap isTablet={isTablet} disabled={false} isOpen={false}>
      <ArrowDown />
      <ClientDropdownModal isOpen={false}></ClientDropdownModal>
    </ClientDropdownWrap>
  );
};

const ClientDropdownItem = styled.div`
  display: flex;
  cursor: pointer;
  padding: 9px 11px;
  color: ${({ theme }) => theme.font.action};
  background: ${({ theme }) => theme.layer[1]};
  width: 285px;
  text-transform: capitalize;

  &:hover {
    color: ${({ theme }) => theme.layer.base};
    background: ${({ theme }) => theme.font.action};
  }

  font-family: Blinker, serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0;
  text-align: left;
`;

const ClientDropdownMobileWrap = styled.div<{
  disabled: boolean;
  isOpen: boolean;
}>`
  cursor: pointer;
  border-radius: 4px;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background: ${({ theme }) => theme.action.primary};
  margin-left: auto;
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
`;

const ClientDropdownWrap = styled(FadeAnimation)<{
  isTablet?: boolean;
  disabled?: boolean;
  selected?: boolean;
}>`
  cursor: pointer;
  border-radius: 4px;
  width: ${SIZE_DROPDOWN_ACTIONS}px;
  height: ${SIZE_DROPDOWN_ACTIONS}px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  ${({ isTablet, selected, isOpen }) =>
    isTablet
      ? css`
          ${selected
            ? css`
                opacity: 1;
              `
            : css`
                background: ${({ theme }) => theme.action.primary};
              `}
        `
      : css`
          background: ${({ theme }) => theme.action.primary};
        `};
  margin-left: auto;
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
`;

const ClientDropdownModal = styled(FadeAnimation)<{ isListCenter?: boolean }>`
  z-index: 10;
  cursor: default;
  top: 45px;
  ${({ isListCenter }) =>
    !isListCenter &&
    css`
      right: 0;
    `};
  display: flex;
  flex-direction: column;
  position: absolute;
  background: ${({ theme }) => theme.layer[1]};
  border: 1px solid ${({ theme }) => theme.border.base};
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  padding: 10px 0;
`;

export default DropdownActions;
