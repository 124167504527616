import { ApprovedIcon } from '@/assets/icons/info-icons/approved';
import { RedStarIcon } from '@/assets/icons/info-icons/redStar';
import { getUTCDate } from '@/components/allocations-page/utils';
import FormattingTooltip from '@/components/basicComponents/tooltip/dollarsTooltip';
import {
  MobileRow,
  MobileRowTitle,
  MobileRowValue,
  MobileTableRowWrap,
  MobileTableWrapper,
  NoResults
} from '@/components/table/mobileTable/mobileTable';
import guid from '@/utils/guid';
import styled, { useTheme } from 'styled-components';
import { columns } from '../constants';

interface MobileTableProps {
  tableData: any;
}

const MobileTable = ({ tableData }: MobileTableProps) => {
  const theme = useTheme();

  const getRowStatus = (status: string) => {
    switch (status) {
      case 'Missing Commitment':
      case 'Unrecognized Transaction Type':
        return (
          <RequiredStatusCell>
            <div style={{ padding: '5px' }}>
              <RedStarIcon width={9} height={11} fill={theme.context.error} />
            </div>
            {status}
          </RequiredStatusCell>
        );
      case 'Validated':
        return (
          <CompleteStatusCell>
            <ApprovedIcon width={23} height={23} fill={theme.context.success} />
            Validated
          </CompleteStatusCell>
        );
      default:
        return <></>;
    }
  };

  const TableRow = ({ el }: { el: any }) => {
    return (
      <MobileTableRowWrap key={guid()}>
        <MobileRow>
          <MobileRowTitle>{columns[0].title}</MobileRowTitle>
          <MobileRowValue>{el.entityName}</MobileRowValue>
        </MobileRow>

        <MobileRow>
          <MobileRowTitle>{columns[1].title}</MobileRowTitle>
          <MobileRowValue>{el.entityCode}</MobileRowValue>
        </MobileRow>

        <MobileRow>
          <MobileRowTitle>{columns[2].title}</MobileRowTitle>
          <MobileRowValue>{el.seriesName}</MobileRowValue>
        </MobileRow>

        <MobileRow>
          <MobileRowTitle>{columns[3].title}</MobileRowTitle>
          <MobileRowValue>{el.investmentCode}</MobileRowValue>
        </MobileRow>

        <MobileRow>
          <MobileRowTitle>{columns[4].title}</MobileRowTitle>
          <MobileRowValue>{el.transactionType}</MobileRowValue>
        </MobileRow>

        <MobileRow>
          <MobileRowTitle>{columns[5].title}</MobileRowTitle>
          <MobileRowValue>{el.tradeDate && getUTCDate(new Date(el.tradeDate))}</MobileRowValue>
        </MobileRow>

        <MobileRow>
          <MobileRowTitle>{columns[6].title}</MobileRowTitle>
          <MobileRowValue>
            <FormattingTooltip zIndex={1000}>{el.netAmount}</FormattingTooltip>
          </MobileRowValue>
        </MobileRow>

        <MobileRow>
          <MobileRowTitle>{columns[7].title}</MobileRowTitle>
          <MobileRowValue>
            <FormattingTooltip zIndex={1000}>{el.totalAmount}</FormattingTooltip>
          </MobileRowValue>
        </MobileRow>

        <MobileRow>
          <MobileRowTitle>{columns[8].title}</MobileRowTitle>
          <MobileRowValue>{getRowStatus(el.commitmentStatus)}</MobileRowValue>
        </MobileRow>
      </MobileTableRowWrap>
    );
  };

  return (
    <MobileTableWrapper>
      {tableData.length ? (
        tableData.map((el: any, index: number) => {
          return <TableRow el={el} key={index} />;
        })
      ) : (
        <NoResults>No Results</NoResults>
      )}
    </MobileTableWrapper>
  );
};

export default MobileTable;

const StatusCell = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
  line-height: 22.4px;
`;

const RequiredStatusCell = styled(StatusCell)`
  color: ${({ theme }) => theme.context.error};
`;

const CompleteStatusCell = styled(StatusCell)`
  color: ${({ theme }) => theme.context.success};
`;
