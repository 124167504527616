import React, {
  ChangeEvent,
  KeyboardEvent,
  ReactNode,
  CSSProperties,
  FocusEventHandler
} from 'react';
import styled from 'styled-components';

const StyledInputWrapper = styled.div<{
  fullWidth: boolean;
  sx?: CSSProperties;
  margin?: 'normal' | 'none' | 'dense';
  color?: string;
}>`
  width: ${(props) => (props.fullWidth ? '100%' : 'auto')};
  display: flex;
  align-items: center;
  border-radius: 1px;
  border: 1px solid grey;
  padding: 10px;
  ${(props) => props.sx && { ...props.sx }};
`;

const StyledInput = styled.input<{ error: boolean }>`
  flex-grow: 1;
  border: none;
  outline: none;
  padding: 10px;
  border-radius: 1px;
  border: 1px solid ${(props) => (props.error ? 'red' : 'transparent')};
`;

type CustomTextFieldProps = {
  fullWidth?: boolean;
  error?: boolean;
  type?: React.HTMLInputTypeAttribute;
  value?: any;
  autoFocus?: boolean;
  placeholder?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onKeyDown?: (e: KeyboardEvent<HTMLInputElement>) => void;
  InputProps?: {
    endAdornment?: ReactNode;
  };
  sx?: CSSProperties;
  id?: string;
  label?: string;
  margin?: 'normal' | 'none' | 'dense';
  name?: string;
  required?: boolean;
  color?: string;
  onBlur?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
};

const CustomTextField: React.FC<CustomTextFieldProps> = ({
  fullWidth = false,
  error = false,
  type = 'text',
  InputProps,
  sx,
  onChange,
  onKeyDown,
  id,
  value,
  margin = 'none',
  name,
  required = false,
  color,
  ...props
}) => {
  return (
    <StyledInputWrapper
      fullWidth={fullWidth}
      sx={sx}
      margin={margin}
      color={color}
    >
      <StyledInput
        error={error}
        type={type}
        onChange={onChange}
        onKeyDown={onKeyDown}
        value={value}
        id={id}
        name={name}
        required={required}
        {...props}
      />
      {InputProps?.endAdornment}
    </StyledInputWrapper>
  );
};

export default CustomTextField;
