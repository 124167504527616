import { gql } from '@apollo/client';

export const ADVISORS_QUERY = gql`
  query Advisors {
    Advisors {
      firstName
      lastName
      initials
    }
  }
`;

export enum SUITABILITY {
  ALL_SUITABILITY = 'All Suitability',
  SUITABLE = 'Suitable',
  ON_HOLD = 'On hold',
  NO_CAPATICY = 'No capacity',
  NO_CAPACITY_PENDING_PROPOSAL = 'No capacity pending proposal',
  EXCLUDED_STRATEGY = 'Excluded strategy',
  CUSTODIED_REQUIREMENT = 'Custodied requirement',
  UBTI_REQUIREMENT = 'UBTI requirement',
  ASSET_CLASS_FULL = 'Asset class full',
  ASSET_CLASS_FULL_PENDING_PROPOSAL = 'Asset class full pending proposal'
}
