import styled from 'styled-components';

import FormattingTooltip from '@/components/basicComponents/tooltip/dollarsTooltip';
import { TableColumns } from '@/components/table/types';
import { InvestmentEntityByAdvisoryFirm } from '../types';

interface TableSumFooterProps {
  columns: TableColumns[];
  tableData: InvestmentEntityByAdvisoryFirm[];
}

const TableSumFooterAdvisoryFirm = ({ columns, tableData }: TableSumFooterProps) => {
  const total = tableData.reduce<{ committedCapital: number; capitalCalled: number; percentOfCapitalCalled: number }>(
    (sum, value: InvestmentEntityByAdvisoryFirm) => {
      return {
        committedCapital: (sum.committedCapital += value.committedCapital),
        capitalCalled: (sum.capitalCalled += value.capitalCalled),
        percentOfCapitalCalled: (sum.percentOfCapitalCalled += value.percentOfCapitalCalled)
      };
    },
    { committedCapital: 0, capitalCalled: 0, percentOfCapitalCalled: 0 }
  );

  return (
    <TableSumWrap>
      <TableSumItem width={columns[0].width}>Total</TableSumItem>
      <TableSumItem width={columns[1].width}></TableSumItem>
      <TableSumItem width={columns[2].width}>
        <FormattingTooltip zIndex={1000}>{total.committedCapital}</FormattingTooltip>
      </TableSumItem>
      <TableSumItem width={columns[3].width}>
        <FormattingTooltip zIndex={1000}>{total.capitalCalled}</FormattingTooltip>
      </TableSumItem>
      <TableSumItem width={columns[4].width}>
        {total.capitalCalled === 0 || total.committedCapital === 0 ? '0%' : ((total.capitalCalled / total.committedCapital) * 100).toFixed(2) + '%'}
      </TableSumItem>
    </TableSumWrap>
  );
};

const TableSumWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
  padding: 0 24px;
  width: 100%;
  min-height: 60px;
  background-color: ${({ theme }) => theme.layer[2]};
  border-radius: 8px;
  margin-top: 6px;
`;

const TableSumItem = styled.div<{ width: number; justifyContent?: string }>`
  display: flex;
  justify-content: ${({ justifyContent }) => `${justifyContent}`};
  width: ${({ width }) => `${width}%`};
  color: ${({ theme }) => theme.font.base};
  font-family: Blinker, serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0;
`;

export default TableSumFooterAdvisoryFirm;
