import ModalWrappedContent from '@/components/fat-basicComponents/modal/modalWrappedContent';
import { Loader } from '@/components/loaders/loader';
import { User } from '@/types/user';
import { useState } from 'react';
import styled from 'styled-components';

interface EndImpersonationProps {
  onCancel: () => void;
  onConfirm: () => void;
  isOpen: boolean;
  user?: User | null;
  signingIn: boolean;
}

const EndImpersonation = ({ onCancel, onConfirm, isOpen, user, signingIn }: EndImpersonationProps) => {
  const [userEmail] = useState<string>(user?.email);
  const [originalUserEmail] = useState<string | null>(user?.impersonated?.originalUser.email);
  const [originalUserTenantName] = useState<string | null>(user?.impersonated?.originalUser.tenantName);

  if (!user) return <></>;

  return (
    <ModalWrappedContent
      isOpen={isOpen}
      onClose={onCancel}
      confirmButton={
        !signingIn
          ? {
              name: 'End Impersonation',
              onClick: onConfirm
            }
          : undefined
      }
      showCancelButton={!signingIn}
    >
      <ModalContainer>
        {signingIn && (
          <>
            <ModalTitel>Ending Impersonation</ModalTitel>
            <ModalText fontWeight="400">
              Returning to session for {originalUserTenantName} ({originalUserEmail})...
              <br /> <br />
              <Loader size={30} />
            </ModalText>
          </>
        )}
        {!signingIn && (
          <>
            <ModalTitel>End Impersonation?</ModalTitel>
            <ModalText fontWeight="400">
              This action will end your impersonation of <ModalText fontWeight="700">{userEmail}</ModalText>. Your session will switch back to{' '}
              <ModalText fontWeight="700">{originalUserTenantName}</ModalText> and your level of access will revert to your default role.
            </ModalText>
          </>
        )}
      </ModalContainer>
    </ModalWrappedContent>
  );
};

export default EndImpersonation;

const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  padding: 30px 0;
`;

const ModalTitel = styled.div`
  color: ${({ theme }) => theme.font.strong};
  text-align: center;
  font-family: Blinker;
  font-size: 33px;
  font-style: normal;
  font-weight: 400;
  line-height: 46.2px;
`;

const ModalText = styled.span<{ fontWeight: string }>`
  color: ${({ theme }) => theme.font.base};
  text-align: center;
  font-family: Blinker;
  font-size: 19px;
  font-style: normal;
  font-weight: ${({ fontWeight }) => fontWeight};
  line-height: 26.6px;
`;
