import * as React from 'react';

export const PlusIcon = ({ ...props }: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width={props.width || 12} height={props.height || 12} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.60108 2.99821H5.40252V5.39892H3.0018V6.59749H5.40252V8.9982H6.60108V6.59749H9.0018V5.39892H6.60108V2.99821ZM6.0036 1.07942e-06C4.81661 -0.000710761 3.65607 0.35067 2.66882 1.00968C1.68158 1.6687 0.911986 2.60574 0.457414 3.70225C0.00284223 4.79875 -0.116281 6.00544 0.115116 7.16966C0.346512 8.33388 0.918029 9.40332 1.75736 10.2426C2.59669 11.082 3.66612 11.6535 4.83034 11.8849C5.99456 12.1163 7.20126 11.9972 8.29776 11.5426C9.39427 11.088 10.3313 10.3184 10.9903 9.33118C11.6493 8.34394 12.0007 7.1834 12 5.9964C11.999 4.40635 11.367 2.8817 10.2426 1.75736C9.11831 0.633026 7.59365 0.000954634 6.0036 1.07942e-06ZM6.0036 10.7978C5.05381 10.7986 4.12515 10.5176 3.33508 9.99041C2.54502 9.46325 1.92905 8.71362 1.56508 7.83633C1.20112 6.95905 1.10552 5.99353 1.29038 5.06191C1.47524 4.13028 1.93225 3.2744 2.6036 2.60255C3.27495 1.93069 4.13048 1.47305 5.06196 1.2875C5.99345 1.10194 6.95904 1.19681 7.8366 1.56011C8.71416 1.92341 9.46425 2.53883 9.992 3.3285C10.5197 4.11817 10.8014 5.04661 10.8014 5.9964C10.7995 7.26862 10.2936 8.48824 9.3943 9.38817C8.49505 10.2881 7.27581 10.795 6.0036 10.7978Z"
        fill={props.fill || '#4587EC'}
      />
    </svg>
  );
};
