import { EditIcon } from '@/assets/icons/edit';
import Label from '@/components/basicComponents/label';
import FilterSelect from '@/components/basicComponents/select';
import Textarea from '@/components/basicComponents/textarea';
import { useResponsive } from '@/hooks/use-responsive';
import { memo } from 'react';
import { Control, Controller } from 'react-hook-form';
import styled, { useTheme } from 'styled-components';
import { IDefaultLabelerToolFormData } from '.';

interface AILabelerSectionProps {
  aiAnswer: string;
  control: Control<IDefaultLabelerToolFormData, any>;
  rubricScore: number | string;
  filename: string;
  watchDocumentContainsAnswer: string;
  openPdf: (pageNumber: number) => void;
}

const AiLabelerSection = memo(({ aiAnswer, control, rubricScore, filename, watchDocumentContainsAnswer, openPdf }: AILabelerSectionProps) => {
  const { isMobile } = useResponsive();
  const theme = useTheme();

  return (
    <>
      <AiAnswer>{aiAnswer}</AiAnswer>
      <ScoreWrapper isMobile={isMobile}>
        <HumanScore>
          <CustomLabel required>Human Score</CustomLabel>
          <Controller
            name="humanScore"
            control={control}
            render={({ field: { onChange, value } }) => (
              <CustomFilterSelect
                data={['Select', ...Array(11).keys()].map(String)}
                setSelected={onChange}
                selected={value}
                width={isMobile ? '100%' : '279px'}
                fontSize="16px"
                fontWeight="400"
                minHeight="40px"
                isBackground={value !== 'Select'}
              />
            )}
          />
        </HumanScore>
        <RubricScore isMobile={isMobile}>
          <CustomLabel required={false}>
            Rubric Score
            {/* <EditButton>
                <EditIcon width={16} height={16} /> Edit Rubric
              </EditButton> */}
          </CustomLabel>
          {rubricScore}
        </RubricScore>
      </ScoreWrapper>
      <DocumentItem>
        <Text fontWeight="400">
          Document: <Text fontWeight="700">{filename}</Text>
        </Text>
        <EditButton onClick={() => openPdf(1)}>
          <EditIcon width={16} height={16} fill={theme.action.primary} /> View
        </EditButton>
      </DocumentItem>
      <ContainsAnswerWrapper isMobile={isMobile}>
        <ContainsAnswer>
          <div>
            <CustomLabel required={false}>Document Contains Answer</CustomLabel>
            <Controller
              name="documentContainsAnswer"
              control={control}
              render={({ field: { onChange, value } }) => (
                <CustomFilterSelect
                  data={['Select', 'true', 'false']}
                  setSelected={onChange}
                  selected={value}
                  width={isMobile ? '100%' : '279px'}
                  fontSize="16px"
                  fontWeight="400"
                  minHeight="40px"
                  isBackground={value !== 'Select'}
                />
              )}
            />
          </div>
          {watchDocumentContainsAnswer === 'true' && (
            <div>
              <CustomLabel required={false}>True Page Numbers</CustomLabel>
              <Controller
                name="truePageNumbers"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Input
                    isBackground={!!value}
                    width={isMobile ? '100%' : '279px'}
                    type="text"
                    value={value}
                    onChange={(event) => onChange(event.target.value)}
                  />
                )}
              />
            </div>
          )}
          <div>
            <CustomLabel required={false}>False Page Numbers</CustomLabel>
            <Controller
              name="falsePageNumbers"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Input
                  isBackground={!!value}
                  width={isMobile ? '100%' : '279px'}
                  type="text"
                  value={value}
                  onChange={(event) => onChange(event.target.value)}
                />
              )}
            />
          </div>
        </ContainsAnswer>
        {watchDocumentContainsAnswer === 'true' && (
          <GroundtruthAnswer>
            <CustomLabel required>Groundtruth Answer</CustomLabel>
            <Controller
              name="groundtruthAnswer"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Textarea value={value} textareaHeight="125px" isValidValue={!!value} setValue={onChange} />
              )}
            />
          </GroundtruthAnswer>
        )}
      </ContainsAnswerWrapper>
      <NoteWrapper>
        <CustomLabel required={false}>Notes</CustomLabel>
        <Controller
          name="notes"
          control={control}
          render={({ field: { onChange, value } }) => (
            <Input isBackground={!!value} width="100%" type="text" value={value} onChange={(event) => onChange(event.target.value)} />
          )}
        />
      </NoteWrapper>
    </>
  );
});

export default AiLabelerSection;

const AiAnswer = styled.div`
  text-align: justify;
`;

const EditButton = styled.div`
  color: ${({ theme }) => theme.action.primary};
  cursor: pointer;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 4px;
`;

const ScoreWrapper = styled.div<{ isMobile: boolean }>`
  padding: 20px 0;
  border-bottom: 1px solid ${({ theme }) => theme.border.base};
  display: flex;
  flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};
  gap: 30px;
`;

const HumanScore = styled.div``;

const RubricScore = styled.div<{ isMobile: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 10px;
  order: ${({ isMobile }) => (isMobile ? '-1' : '1')};
  font-size: 20px;
`;

const CustomFilterSelect = styled(FilterSelect)<{ isBackground?: boolean }>`
  border-radius: 5px;
  border: ${({ isBackground, theme }) => (isBackground ? `1px solid ${theme.context.success}` : `1px solid ${theme.border.base}`)};
  background-color: ${({ isBackground, theme }) => (isBackground ? '#efffea' : theme.layer[1])};

  #titleSelect {
    color: ${({ isBackground, theme }) => (isBackground ? '#2E2E2E' : theme.font.strong)};
  }

  #dropDownSelect {
    margin-top: 10px;
  }
`;

const CustomLabel = styled(Label)`
  font-size: 16px;
  display: flex;
  gap: 6px;
  padding-bottom: 4px;
`;

const DocumentItem = styled.div`
  display: flex;
  gap: 6px;
  margin-top: 20px;
`;

const Text = styled.span<{ fontWeight: string }>`
  font-weight: ${({ fontWeight }) => fontWeight};
`;

const ContainsAnswerWrapper = styled.div<{ isMobile: boolean }>`
  margin-top: 30px;
  display: flex;
  flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};
  gap: 30px;
`;

const GroundtruthAnswer = styled.div`
  width: 100%;
`;

const ContainsAnswer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const Input = styled.input<{ width: string; isBackground: boolean }>`
  width: ${({ width }) => width};
  border-radius: 4px;
  border: ${({ isBackground, theme }) => (isBackground ? `1px solid ${theme.context.success}` : `1px solid ${theme.border.base}`)};
  background-color: ${({ isBackground, theme }) => (isBackground ? '#efffea' : theme.layer[1])};

  padding: 10px;
  font-size: 16px;
  font-family: Blinker;
  font-weight: 400;

  &:focus {
    outline: none;
  }
`;

const NoteWrapper = styled.div`
  margin-top: 15px;
`;
