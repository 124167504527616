import Trace8Logo from '@/assets/icons/trace8';
import { JfgLogo } from '@/assets/static/logo/Logo';
import { useResponsive } from '@/hooks/use-responsive';
import React from 'react';
import styled from 'styled-components';

const LoginHero: React.FC = () => {
  const { isMobile, isTablet, isPortrait } = useResponsive();
  return (
    <>
      {isMobile || (isPortrait && isTablet) ? (
        <>
          <Container>
            <Trace8Logo />
          </Container>
        </>
      ) : (
        <>
          <Container>
            <DesktopLogoWrapper>
              <JfgLogo width={588} />
            </DesktopLogoWrapper>
            <Line />
            <Title>Private Capital Platform</Title>
          </Container>
          <Annotation>{`@${new Date().getFullYear()} WECtec Enterprises, LLC`}</Annotation>
        </>
      )}
    </>
  );
};

export default LoginHero;

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const DesktopLogoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Annotation = styled.span`
  text-align: center;
  color: #ffffff;
  font-size: 14px;
  position: absolute;
  bottom: 80px;
  font-family: Inter;
`;

const Line = styled.div`
  background: #76e7cd;
  height: 2px;
  margin: 20px 0;
  width: 100%;
  border-color: transparent;
`;

const Title = styled.span`
  display: flex;
  justify-content: center;
  color: #fff;
  font-size: 22px;
  font-family: Inter;
`;
