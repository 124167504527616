import React, { forwardRef } from 'react';
import styled, { css, CSSObject } from 'styled-components';

type SxStyleProp = CSSObject;

type BoxProps = {
  component?: React.ElementType;
  sx?: SxStyleProp;
  children?: React.ReactNode;
} & React.HTMLAttributes<HTMLElement>;

const StyledBox = styled.div<BoxProps>`
  ${(props) => props.sx && css(props.sx)};
`;

export const Box = forwardRef<HTMLElement, BoxProps>(
  ({ component: Component = 'div', sx, ...props }, ref) => {
    return <StyledBox as={Component} ref={ref} sx={sx} {...props} />;
  }
);
