export const columns = [
  { title: 'Entity', key: 'ENTITY', width: 15, keySort: 'entity' },
  { title: 'Entity Code', key: 'ENTITY_CODE', width: 15, keySort: 'entity code' },
  { title: 'Series', key: 'SERIES', width: 14, keySort: 'series' },
  { title: 'Series Code', key: 'SERIES_CODE', width: 14, keySort: 'series code' },
  { title: 'Valuation Date', key: 'VALUATION_DATE', width: 14, keySort: 'valuation date' },
  { title: 'Amount', key: 'AMOUNT', width: 14, keySort: 'amount' },
  { title: 'Status', key: 'STATUS', width: 14, keySort: 'status' },
];

export const commitmentsStatusSuccess = {
  allMatched: 'All Valuations Have Matching Commitments',
  continue: 'Your valuations are ready to be saved',
  orContinue: 'Your valuations are ready to be saved',
  viewAll: 'View All Valuations',
};

export const commitmentsCodesStatusError = {
  text: 'Missing Commitments',
  description:
    'Valuations cannot be uploaded without a matching commitment. Either remove the following rows from your import file or ensure that committed (not pending) allocations exist for each valuation.',
};
