import { format } from 'date-fns';
import { useMemo } from 'react';
import styled from 'styled-components';

import guid from '../../utils/guid';
import { INotesData } from './types';

interface InfoListClientProps {
  data: INotesData[] | undefined;
}

export const MobileNotes = ({ data }: InfoListClientProps) => {
  const dataFiltered = useMemo(() => {
    return data?.filter((el) => el.title && el.notes);
  }, [data]);

  return (
    <MoreDataWrap id="modal">
      <NotesBody id="notes">
        {dataFiltered &&
          dataFiltered.map((el) => (
            <NoteItem id="notes" key={guid()}>
              <NoteItemTitle id="notes">{el.title}</NoteItemTitle>
              <NoteItemDate id="notes">{el.notesDate ? format(new Date(el.notesDate), 'LLL d, yyyy') : 'No date'}</NoteItemDate>
              <NoteItemDesc id="notes">{el.notes}</NoteItemDesc>
              <Divider />
            </NoteItem>
          ))}
      </NotesBody>
    </MoreDataWrap>
  );
};

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: ${({ theme }) => theme.border.base};
  margin: 30px 0;
`;

const NoteItemDesc = styled.div`
  font-family: 'Blinker', serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  color: ${({ theme }) => theme.font.base};
  margin-top: 10px;
`;

const NoteItemDate = styled.div`
  font-family: 'Blinker', serif;
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  color: ${({ theme }) => theme.font.weak};
`;

const NoteItemTitle = styled.div`
  font-family: 'Blinker', serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 18px;
  color: ${({ theme }) => theme.font.base};
`;

const NoteItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const NotesBody = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  overflow: auto;
`;

const MoreDataWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3px;
`;
