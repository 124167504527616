import * as docx from 'docx';
import { aiFieldValue, fieldValue, matchFieldWithAI } from '../DDR-PDF/utils';
import { IAttachment, ISection, ISectionFields } from '../dueDiligenceRecord/types';
import { getAiSuggestedSection, getDocxImage } from './utils';

export const getOneColumnSection = async (section: ISection, includeAiSuggested: boolean, bodyFontColor: string) => {
  const maxImageWidth = 665;
  const docxData: (docx.Paragraph | docx.Table)[] = [];

  const createParagraph = (text: string, bold: boolean, color: string, beforeSpacing: number, afterSpacing: number) => {
    const formattedText = text?.split('\n').flatMap((line, index, array) => {
      return index < array.length - 1
        ? [
            new docx.TextRun({
              text: line,
              bold,
              color,
            }),
            new docx.TextRun({ break: 1 }),
          ]
        : new docx.TextRun({
            text: line,
            bold,
            color,
          });
    });

    return new docx.Paragraph({
      children: formattedText,
      spacing: { before: beforeSpacing, after: afterSpacing },
    });
  };

  const getFieldData = async (field: ISectionFields) => {
    const fieldData = [];
    const { attachments, value, commentary } = field.data;
    const hasIncludedAttachments = attachments.some(attachment => attachment.includeInAutomatedContent);
    const isMatchedWithAI = matchFieldWithAI(field) && field.AI?.answers.length && includeAiSuggested;

    if ((value || commentary || hasIncludedAttachments) && value !== field.AI?.answers[0]) {
      if (section.fields.length > 1) {
        fieldData.push(createParagraph(field.name, true, bodyFontColor, 300, 100));
      }
      fieldData.push(createParagraph(fieldValue(field.type, value, commentary), false, bodyFontColor, 0, 0));
    }

    if (isMatchedWithAI) {
      if (section.fields.length > 1) {
        fieldData.push(createParagraph(`* ${field.name}`, true, bodyFontColor, 300, 100));
      }
      fieldData.push(createParagraph(aiFieldValue(field.type, field.AI.answers[0]), false, bodyFontColor, 0, 0));
      const aiSuggestedSection = await getAiSuggestedSection(section, bodyFontColor);
      fieldData.push(...aiSuggestedSection);
    }

    return fieldData;
  };

  const getImageData = async (attachment: IAttachment) => {
    if (!attachment.includeInAutomatedContent) return;

    const imageData = await getDocxImage(attachment.asset.url, attachment.asset.assetKey);
    if (!imageData) return;

    const img = new Image();
    img.src = URL.createObjectURL(new Blob([imageData]));
    await new Promise(resolve => (img.onload = resolve));

    let dimensions = { width: img.naturalWidth, height: img.naturalHeight };
    if (dimensions.width > maxImageWidth) {
      const aspectRatio = dimensions.height / dimensions.width;
      dimensions = { width: maxImageWidth, height: Math.round(maxImageWidth * aspectRatio) };
    }

    return new docx.Paragraph({
      children: [
        new docx.ImageRun({
          data: imageData,
          transformation: dimensions,
        }),
      ],
      spacing: { before: 200, after: 200 },
    });
  };

  for await (const field of section.fields) {
    const fieldData = await getFieldData(field);
    docxData.push(...fieldData);

    const imagesData = await Promise.all(field.data.attachments.map(getImageData));
    const validImagesData = imagesData.filter((item): item is docx.Paragraph => item !== undefined);
    docxData.push(...validImagesData);
  }

  return docxData;
};
