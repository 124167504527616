import { ActiveInvestments } from '@/components/fat-activeInvestments-page';
import { Helmet as Head } from 'react-helmet';
import useRefresh from '../../hooks/use-refresh';

const ActiveInvestmentsPage = () => {
  useRefresh();
  return (
    <>
      <Head>
        <title>Active Commitments</title>
      </Head>
      <ActiveInvestments />
    </>
  );
};

export default ActiveInvestmentsPage;
