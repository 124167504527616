import { ISort } from '@/components/table/types';
import { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';
import FooterModal from '../../../footer';
import { ProgressBar } from '../../../progressBar';
import { Status } from '../../../status';
import { ImportWrapper } from '../../../styles';
import { investorEntitiesSteps } from '../../constants';
import { IGroupImportComparison, IGroupResponse, TypeWindow } from '../../types';
import { groupsStatusError, groupsStatusSuccess } from './constants';
import Table from './table/table';

interface GroupsProps {
  importSteps: typeof investorEntitiesSteps;
  startOver: () => void;
  nextStep: () => void;
  groupData: IGroupResponse | null;
  errors: IGroupImportComparison[];
  sort: ISort;
  setSort: Dispatch<SetStateAction<ISort>>;
  search: string;
  onChangeSearch: (value: string) => void;
  primaryFilter: string;
  showTable: boolean;
  setShowTable: Dispatch<SetStateAction<boolean>>;
  changePrimaryFilterValue: (filter: string) => void;
  openGroupImportDetailPage: (type: TypeWindow, row: IGroupImportComparison) => void;
}

export const Groups = ({
  importSteps,
  startOver,
  nextStep,
  groupData,
  errors,
  sort,
  setSort,
  search,
  onChangeSearch,
  primaryFilter,
  showTable,
  setShowTable,
  changePrimaryFilterValue,
  openGroupImportDetailPage
}: GroupsProps) => {
  if (!groupData) {
    return <></>;
  }

  return (
    <>
      <ImportWrapper>
        <ProgressBar importSteps={importSteps} />
        <Status
          errorsCount={errors.length || null}
          matched={[
            { count: groupData.newGroupsCount, text: 'New Groups' },
            { count: groupData.updateGroupsCount, text: 'Updated Groups' },
            { count: groupData.groupsToDeleteCount, text: 'Groups To Delete' }
          ]}
          showTable={showTable}
          setShowTable={setShowTable}
          startOver={startOver}
          confirmButton={{ name: 'Save and Complete', onClick: nextStep, disabled: Boolean(errors.length) }}
          success={groupsStatusSuccess}
          error={groupsStatusError}
        />
      </ImportWrapper>
      {showTable || errors?.length ? (
        <TableWrapper>
          <Table
            tableData={groupData.groupImportComparisons}
            openGroupImportDetailPage={openGroupImportDetailPage}
            sort={sort}
            setSort={setSort}
            search={search}
            onChangeSearch={onChangeSearch}
            primaryFilter={primaryFilter}
            changePrimaryFilterValue={changePrimaryFilterValue}
          />
        </TableWrapper>
      ) : (
        <></>
      )}
      {errors?.length || showTable ? (
        <FooterModal startOver={startOver} confirmButton={{ name: 'Save and Complete', onClick: nextStep, disabled: Boolean(errors.length) }} />
      ) : (
        <></>
      )}
    </>
  );
};

const TableWrapper = styled.div`
  margin-bottom: 100px;
`;
