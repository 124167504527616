import * as docx from 'docx';
import { getValueByKey } from '../DDR-PDF/utils';
import { transparentDocxTableBorders } from './utils';

export const getDocxHeader = (firmSettingsData: any, selectedTierName: string) => {
  const header = new docx.Header({
    children: [
      new docx.Table({
        rows: [
          new docx.TableRow({
            children: [
              new docx.TableCell({
                children: [
                  new docx.Paragraph({
                    children: [
                      new docx.TextRun({
                        text: getValueByKey(firmSettingsData?.Settings, 'firmName'),
                        color: '474747',
                        bold: true,
                      }),
                    ],
                  }),
                ],
                borders: transparentDocxTableBorders,
                width: {
                  size: 6000,
                  type: docx.WidthType.DXA,
                },
              }),
              new docx.TableCell({
                children: [
                  new docx.Paragraph({
                    alignment: docx.AlignmentType.RIGHT,
                    children: [
                      new docx.TextRun({
                        text: `${selectedTierName}`,
                        color: '5E7681',
                        italics: true,
                      }),
                    ],
                  }),
                ],
                borders: transparentDocxTableBorders,
                width: {
                  size: 6000,
                  type: docx.WidthType.DXA,
                },
              }),
            ],
          }),
        ],
      }),
    ],
  });

  return header;
};
