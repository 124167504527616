import ModalWrappedContent from '@/components/basicComponents/modal/modalWrappedContent';
import ErrorNotification from '@/components/recommendations-page/error-notification';
import { ChangeEvent, useState } from 'react';
import styled from 'styled-components';

interface EnterCodeProps {
  onClose: () => void;
  isOpen: boolean;
  updateCodeInState: (value: string) => void;
}

interface ErrorMessage {
  title: string;
  message: string;
}

const EnterCode = ({ onClose, isOpen, updateCodeInState }: EnterCodeProps) => {
  const [value, setValue] = useState('');
  const [errorText, setErrorText] = useState<ErrorMessage | null>(null);

  const validateAmount = (amount: number) => {
    let errorMessage: ErrorMessage | null = null;
    setErrorText(errorMessage);
    return errorMessage;
  };

  /*
  useEffect(() => {
    setValue(row.investor_interest[0].amount.toString());
  }, [row]);
*/
  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setValue(value);
  };

  const saveValue = () => {
    const errorMessage = ''; //validateAmount(Number(value));
    if (errorMessage) return;

    updateCodeInState(value);
    onClose();
  };

  return (
    <ModalWrappedContent
      isOpen={isOpen}
      onClose={onClose}
      confirmButton={{
        name: 'Save',
        onClick: saveValue,
        bgColor: '#00AEEF',
        disabled: !value
      }}
    >
      <ModalTitle>SMS code verification</ModalTitle>
      {errorText && (
        <ErrorNotification
          title={errorText?.title ?? ''}
          message={errorText?.message ?? ''}
        />
      )}
      <Input
        isBackground={!!value}
        width="100%"
        type="text"
        value={value}
        onChange={handleInputChange}
        autoFocus
      />
      <Description>Enter your SMS sode</Description>
    </ModalWrappedContent>
  );
};

export default EnterCode;

const ModalTitle = styled.div`
  color: #2e2e2e;
  font-size: 33px;
  font-weight: 400;
  line-height: 46.2px;
  text-align: center;
  padding-bottom: 35px;
`;

const Input = styled.input<{ width: string; isBackground: boolean }>`
  width: ${({ width }) => width};
  border-radius: 4px;
  border: ${({ isBackground }) =>
    isBackground ? '1px solid #3dbd4a' : '1px solid #c7cbd2'};
  background-color: ${({ isBackground }) =>
    isBackground ? '#efffea' : '#FFFFFF'};

  padding: 10px;
  font-size: 16px;
  font-family: Blinker;
  font-weight: 400;

  &:focus {
    outline: none;
  }
`;

const Description = styled.span`
  padding-top: 10px;
  font-weight: 300;
  font-size: 13px;
  line-height: 18px;
  color: #7f7f7f;
`;
