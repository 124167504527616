export const formatDollarInputValue = (value: string): string => {
  let inputValue = value;
  inputValue = inputValue.replace(/[^0-9.]/g, '');

  const parts = inputValue.split('.');
  if (parts.length > 2) {
    inputValue = parts[0] + '.' + parts.slice(1).join('');
  }
  if (parts[1] && parts[1].length > 2) {
    inputValue = `${parts[0]}.${parts[1].substring(0, 2)}`;
  }
  return inputValue;
};

export const formatMaskedDollarInputValue = (value: string) => {
  const [integerPart, decimalPart] = value.split('.');
  const formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return decimalPart !== undefined ? `${formattedIntegerPart}.${decimalPart}` : formattedIntegerPart;
};

export const handleCursorPositionInPercentageInput = (e: React.SyntheticEvent<HTMLInputElement>) => {
  const input = e.target as HTMLInputElement;
  const cursorPosition = input.selectionStart;
  const valueLength = input.value.length;

  if (cursorPosition !== null && cursorPosition >= valueLength - 1) {
    input.setSelectionRange(valueLength - 1, valueLength - 1);
  }
};

export const formatPercentageInputValue = (e: React.ChangeEvent<HTMLInputElement>): string => {
  handleCursorPositionInPercentageInput(e);
  let inputValue = e.target.value.replace('%', '');
  inputValue = inputValue.replace(/[^0-9.]/g, '');
  const parts = inputValue.split('.');
  if (parts.length > 2) {
    inputValue = parts[0] + '.' + parts.slice(1).join('');
  }
  if (!inputValue) {
    return '';
  }
  if (parts[1] && parts[1].length > 2) {
    inputValue = `${parts[0]}.${parts[1].substring(0, 2)}`;
  }
  return inputValue + '%';
};

export const validatePercentageInputValue = (value: string) => {
  const numericValue = parseFloat(value.replace('%', ''));
  if (isNaN(numericValue)) {
    return 'Invalid number';
  }
  if (numericValue < 0) {
    return 'Value must be at least 0';
  }
  if (numericValue > 100) {
    return 'Value must be at most 100';
  }
  return true;
};
