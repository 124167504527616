import FormattingTooltip from '@/components/basicComponents/tooltip/dollarsTooltip';
import Button from '@/components/fat-basicComponents/button';
import guid from '@/utils/guid';
import styled from 'styled-components';
import { ILegalEntity } from './types';

type InfoListProps = {
  data: ILegalEntity;
  onRedirect: () => void;
  title: string;
};

export const InfoList = ({ data, onRedirect, title }: InfoListProps) => {
  const getBoolean = (value: boolean) => (value ? 'YES' : 'NO');

  return (
    <MoreDataWrap id="modal">
      <MoreDataItem id="modal">
        <MoreDataItemTitle id="modal">Overall Capacity</MoreDataItemTitle>
        <MoreDataItemValue id="modal">
          <FormattingTooltip zIndex={1000}>{data.overallCapacity}</FormattingTooltip>
        </MoreDataItemValue>
      </MoreDataItem>

      <MoreDataItem id="modal">
        <MoreDataItemTitle id="modal">Private Equity Capacity</MoreDataItemTitle>
        <MoreDataItemValue id="modal">
          <FormattingTooltip zIndex={1000}>{data.privateEquityCapacity}</FormattingTooltip>
        </MoreDataItemValue>
      </MoreDataItem>

      <MoreDataItem id="modal">
        <MoreDataItemTitle id="modal">Private Credit Capacity</MoreDataItemTitle>
        <MoreDataItemValue id="modal">
          <FormattingTooltip zIndex={1000}>{data.privateCreditCapacity}</FormattingTooltip>
        </MoreDataItemValue>
      </MoreDataItem>

      <MoreDataItem id="modal">
        <MoreDataItemTitle id="modal">Real Assets Capacity</MoreDataItemTitle>
        <MoreDataItemValue id="modal">
          <FormattingTooltip zIndex={1000}>{data.privateRealAssetCapacity}</FormattingTooltip>
        </MoreDataItemValue>
      </MoreDataItem>

      <MoreDataItem id="modal">
        <MoreDataItemTitle id="modal">On Hold</MoreDataItemTitle>
        <MoreDataItemValue id="modal">{getBoolean(data.onHold)}</MoreDataItemValue>
      </MoreDataItem>

      <MoreDataItem id="modal">
        <MoreDataItemTitle id="modal">Sectors To Avoid</MoreDataItemTitle>
        <SectorsWrap id="modal">
          {data?.sectorsToAvoid.filter((sector) => sector !== '').length ? (
            data.sectorsToAvoid
              .filter((x, i, a) => a.indexOf(x) == i)
              .map((el) => {
                if (el.length) {
                  return <SectorsItem key={guid()}>{el.trim()}</SectorsItem>;
                }
              })
          ) : (
            <MoreDataItemValue>N/A</MoreDataItemValue>
          )}
        </SectorsWrap>
      </MoreDataItem>
      {onRedirect && (
        <RedirectButton styleType="outline" size="md" onClick={onRedirect}>
          View {title}
        </RedirectButton>
      )}
    </MoreDataWrap>
  );
};

const SectorsWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  width: 100%;
`;

const SectorsItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px 20px;
  background-color: ${({ theme }) => theme.layer[2]};
  color: ${({ theme }) => theme.font.base};
  border-radius: 12px;
  font-family: Blinker, serif;
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0;
`;

const MoreDataItemValue = styled.div`
  font-family: Blinker, serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0;
  text-align: left;
  width: 200px;
  color: ${({ theme }) => theme.font.base};
`;

const MoreDataItemTitle = styled.div`
  font-family: Blinker, serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0;
  text-align: left;
  width: 150px;
  min-width: 150px;
  color: ${({ theme }) => theme.font.base};
`;

const MoreDataItem = styled.div`
  display: flex;
  align-items: center;
  padding: 5px 20px;
  background-color: ${({ theme }) => theme.layer[1]};
  gap: 10px;
  border-radius: 4px;
`;

const MoreDataWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3px;
`;

const RedirectButton = styled(Button)`
  margin-top: 13px;
`;
