import { Box } from '@mui/material';
import { styled } from 'styled-components';
import { positionTriangle } from '../triangle';
import { SortIcon } from '@/assets/icons/sortIcon';

export const CustomTriangle = styled(SortIcon)<{
  active: boolean;
  isSideTop: boolean;
  side: keyof typeof positionTriangle;
}>`
  margin: ${(props) =>
    props.active
      ? `${props.isSideTop ? 2 : 0}px 0px ${props.isSideTop ? 0 : 1}px ${
          props.isSideTop ? 0 : 1
        }px`
      : '2px 0 0 0'};
  transform: rotate(${(props) => positionTriangle[props.side]}deg);

  path {
    fill: ${(props) => (props.active ? '#FAFAFA' : '#4587EC')};
  }
`;

export const GreenCircle = styled(Box)<{ active: boolean }>`
  cursor: pointer;
  margin-left: 8px;
  height: 24px;
  width: 24px;
  background-color: ${(props) =>
    props.active ? props.theme.action.active : 'transparent'};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  aspect-ratio: 1 / 1;
`;
