import { useQuery } from '@apollo/client';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import { ArrowDown } from '@/assets/icons/info-icons/arrowDown';
import { CloseInfoIcon } from '@/assets/icons/info-icons/closeInfo';
import FilterSelect, { Divider, FilterItems, PrimaryFilterItem, SearchInput, SearchWrap } from '@/components/basicComponents/select';
import { CloseBtn, ModalControlWrap, ModalPageTitle } from '@/components/client-details';
import Header from '@/components/header';
import { initialValuesFromTable } from '@/components/table/constants';
import FilterPage from '@/components/table/filterPage';
import { Results, TopWrapper } from '@/components/table/filterPage/desktop-filter';
import TablePagination from '@/components/table/tablePagination';
import { ISort, SORT_ORDER } from '@/components/table/types';
import { useResponsive } from '@/hooks/use-responsive';
import { useClickOutside } from '@/hooks/useClickOutside';
import { useGoBack } from '@/hooks/useGoBack';
import { MainWrap } from '@/styles/common';
import { Investment, InvestmentEntity } from '@/types/investments';
import guid from '@/utils/guid';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { ENTITIES_QUERY, INVESTMENTS_QUERY, INVESTMENT_QUERY } from '../../queries';
import { managerInvestmentSort } from './constants';
import Details from './details';
import Table from './table';

const ManagerInvestmentDetails = () => {
  const params = useParams();
  const goBack = useGoBack();
  const navigate = useNavigate();
  const location = useLocation();

  const [searchParams, setSearchParams] = useSearchParams();

  const q = searchParams.get('q') || '';
  const key = searchParams.get('key') || 'name';
  const asc = searchParams.get('asc') === null || searchParams.get('asc') === 'true';
  const limit = searchParams.get('limit') || initialValuesFromTable.initialAmountRowsPerPage[0];
  const offset = searchParams.get('offset') || 0;

  const ref = useRef<HTMLDivElement | null>(null);
  const { isMobile, isTablet } = useResponsive();
  const [isShowDetails, setIsShowDetails] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [isNotesOpen, setIsNotesOpen] = useState(false);
  const [selected, setSelected] = useState('');
  const [investment, setInvestment] = useState<Investment | null>(null);
  // const [manager, setManager] = useState<InvestmentManager | null>(null);
  const [entities, setEntities] = useState<InvestmentEntity[]>([]);
  const [mobileSort, setMobileSort] = useState(managerInvestmentSort[0].title);
  const sortItem = managerInvestmentSort.find((item) => item.title === mobileSort);
  const mobileSortList = managerInvestmentSort.map((item) => item.title);
  const [entityList, setEntityList] = useState([]);
  const [list, setList] = useState([]);
  const [search, setSearch] = useState('');

  useClickOutside(ref, () => setIsOpen(false));

  const { data: investmentsMenuData } = useQuery(INVESTMENTS_QUERY, {
    notifyOnNetworkStatusChange: true,
    variables: {
      managerId: params?.mid,
      page: 1,
      rowsPerPage: 1000
    }
  });

  const {
    data: investmentData,
    loading: loadingInvestment,
    error: errorInvestment
  } = useQuery(INVESTMENT_QUERY, {
    notifyOnNetworkStatusChange: true,
    variables: { id: params?.id }
  });

  useEffect(() => {
    setEntityList(investmentsMenuData?.ManagerInvestments?.investments);
    setList(investmentsMenuData?.ManagerInvestments?.investments);
  }, [investmentsMenuData]);

  const { data, loading, error, refetch } = useQuery(ENTITIES_QUERY, {
    notifyOnNetworkStatusChange: true,
    variables: {
      id: params?.id,
      sort: key,
      order: asc ? SORT_ORDER.ASC : SORT_ORDER.DESC,
      search: q,
      page: Math.floor(Number(offset) / Number(limit)) + 1,
      rowsPerPage: Number(limit)
    },
    onCompleted: (entities) => {
      setEntities(entities.InvestmentEntities);
    }
  });

  const getCalculatedValues = (entities: InvestmentEntity[]) => {
    const capitalCalled = entities.reduce((sum, val) => sum + Number(val.capitalCalled), 0);
    return {
      capitalCalled
    };
  };

  const toggleNotes = () => {
    setIsNotesOpen(!isNotesOpen);
    const html = document.querySelector('html');
    if (html) {
      html.style.overflowY = isNotesOpen ? 'visible' : 'hidden';
    }
  };

  useEffect(() => {
    if (!loadingInvestment && !errorInvestment && investmentData) {
      setInvestment(investmentData.Investment);
    }
  }, [investmentData, loadingInvestment, errorInvestment]);

  useEffect(() => {
    if (!isMobile) return;

    refetch({
      order: sortItem?.asc ? SORT_ORDER.ASC : SORT_ORDER.DESC,
      sort: sortItem?.key
    });
  }, [mobileSort]);

  const handleSelectCLick = (event: any) => {
    if (event.target.id !== 'input') {
      setIsOpen(!isOpen);
    }
  };

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (entityList) {
      const value = event.target.value;
      setSearch(value);
      const newList = [...entityList];
      const filteredList = newList.filter((item) => item.name.toLowerCase().indexOf(event.target.value.toLowerCase()) !== -1);
      if (value === '' && filteredList.length === 0) {
        setList(entityList);
      } else {
        setList(filteredList);
      }
    }
  };

  const onItemClick = (id: string) => {
    navigate(`/investments/${params?.mid}/${encodeURIComponent(id)}`, { replace: true });
  };

  const handleEntityClose = () => {
    if (location.state?.prevPath) {
      return navigate(`/investments/${params?.mid}`, { replace: true });
    }
    goBack({ fallBack: '/investments' });
  };

  const onChangeSearch = (searchValue: string) => {
    setSearchParams(
      (prev) => {
        if (!searchValue) {
          prev.delete('q');
          return prev;
        }
        prev.set('q', searchValue);
        return prev;
      },
      { replace: true }
    );
  };

  const onChangeSort = (value: ISort) => {
    setSearchParams(
      (prev) => {
        prev.set('key', value.key);
        prev.set('asc', value.asc.toString());
        return prev;
      },
      { replace: true }
    );
  };

  const onChangePaginationData = (limit: number, offset: number) => {
    setSearchParams(
      (prev) => {
        prev.set('limit', limit.toString());
        prev.set('offset', offset.toString());
        return prev;
      },
      { replace: true }
    );
  };

  return (
    <ClientDetailsWrap>
      <MainWrap>
        <Header
          modalControl={
            <ModalControlWrap>
              <Buttons>
                <CloseBtn onClick={() => goBack({ fallBack: '/investments' })}>
                  <CloseInfoIcon width={18} height={18} />
                </CloseBtn>
                {entityList?.length ? (
                  <CloseBtn ref={ref} onClick={(event) => handleSelectCLick(event)}>
                    <ArrowDown fill="#4587EC" width={25} height={12} />
                    <SelectWrap openZIndex={10} onClick={(event) => handleSelectCLick(event)} width={'200px'} isOpen={isOpen} isMobile={isMobile}>
                      <SearchWrap id="input">
                        <SearchInput id="input" placeholder={'Type Here To Filter Results'} value={search} onChange={onChange} />
                        <Divider />
                      </SearchWrap>
                      <>
                        <SelectItem key={guid()} onClick={handleEntityClose}>
                          {params?.mid}
                        </SelectItem>
                      </>
                      {list.map((el: any) => (
                        <SelectItem
                          isActive={el.id === selected}
                          key={guid()}
                          onClick={() => {
                            onItemClick(el.id);
                            setSelected(el.id);
                          }}
                        >
                          {'- ' + el.name}
                        </SelectItem>
                      ))}
                    </SelectWrap>
                  </CloseBtn>
                ) : (
                  <></>
                )}
              </Buttons>
              <ModalPageTitle isMobile={isMobile} isTablet={isTablet}>
                {investment?.name}
              </ModalPageTitle>
            </ModalControlWrap>
          }
        />
        <Details
          data={investment}
          isOpen={isShowDetails}
          setOpen={() => setIsShowDetails(!isShowDetails)}
          setIsNotesOpen={toggleNotes}
          capitalCalled={getCalculatedValues(entities).capitalCalled}
          isMobile={isMobile}
        />
      </MainWrap>
      <ContainerPage>
        <CustomFilterPage
          isMobile={isMobile}
          isTablet={isTablet}
          search={{
            value: q,
            onChange: onChangeSearch,
            placeholder: 'Search for an investor'
          }}
          resultsValue={entities?.length}
          refetch={refetch}
        />
        {isMobile && (
          <SortSelectWrapper>
            <FilterSelect
              isMobile
              label={'Sort By'}
              paddingContainer={'0 20px'}
              width={'100%'}
              data={mobileSortList}
              selected={mobileSort}
              setSelected={setMobileSort}
            />
            <CustomRefetch>
              <Results>{entities?.length || 0} Results</Results>
            </CustomRefetch>
          </SortSelectWrapper>
        )}
        <Table managersInvestmentRows={entities} savedSort={{ key, asc }} onChangeSort={onChangeSort} refetch={refetch} loading={loading} />
        <TableFooter>
          <TablePagination
            paginationValues={{
              limit: Number(limit),
              offset: Number(offset),
              total: entities?.length
            }}
            savePagination={onChangePaginationData}
            refetch={refetch}
            usePage
          />
        </TableFooter>
      </ContainerPage>
    </ClientDetailsWrap>
  );
};

const TableFooter = styled('div')`
  display: flex;
  justify-content: flex-end;
`;

const CustomFilterPage = styled(FilterPage)<{ isMobile: boolean; isTablet: boolean }>`
  position: sticky;
  top: ${({ isMobile, isTablet }) => (isMobile || isTablet ? '50px' : '0')};
  z-index: 12;
  background-color: #fafafa;
  margin-top: 0;
  margin-left: -1px;
  width: calc(100% + 2px);
  padding: ${({ isMobile }) => (isMobile ? '20px 0 0 0' : '20px 0 10px 0')};
`;

const SortSelectWrapper = styled.div`
  position: sticky;
  top: 120px;
  z-index: 11;
  background-color: #fafafa;
  margin-top: 0;
  padding-bottom: 10px;
`;

const SelectWrap = styled(FilterItems)<{ isMobile: boolean; isOpen: boolean; width: string; openZIndex: number }>`
  width: 245px;
  top: 70px;
  left: ${({ isMobile }) => (isMobile ? '95px' : '170px')};
  overflow-x: hidden;
`;

const CustomRefetch = styled(TopWrapper)`
  padding: 25px 0 0 20px;
`;

const Buttons = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 10px;
`;

const ClientDetailsWrap = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 20;
  //overflow: hidden;
`;

const SelectItem = styled(PrimaryFilterItem)`
  padding: 11px 9.5px;
  font-size: 16px;
  font-weight: 600;
  color: #4587ec;
`;

export const ContainerPage = styled.div`
  width: 100%;
  margin-left: auto;
  box-sizing: border-box;
  margin-right: auto;
  display: block;
  padding-left: 16px;
  padding-right: 16px;
  @media (min-width: 600px) {
    padding-left: 60px;
    padding-right: 60px;
  }
  @media (min-width: 1920px) {
    max-width: 1920px;
  }
`;

export default ManagerInvestmentDetails;
