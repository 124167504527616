import { ArrowDown } from '@/assets/icons/info-icons/arrowDown';
import { IRecordData } from '@/components/dueDiligence-page/types';
import { SIZE_DROPDOWN_ACTIONS } from '@/components/table/constants';
import RedirectTitleCell from '@/components/table/redirectTitleCell';
import { IItems } from '@/components/table/types';
import { useClickOutside } from '@/hooks/useClickOutside';
import { FadeAnimation } from '@/styles/animations';
import { useRef, useState } from 'react';
import styled, { css } from 'styled-components';

interface FixedTableColumnProps {
  items: IItems[];
  row: IRecordData;
  disabled?: boolean;
  selected?: boolean;
  handleClickDeal: (row: IRecordData) => void;
}

const FixedTableColumn = ({ items, row, disabled = false, selected = false, handleClickDeal }: FixedTableColumnProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const handleClick = (event: any) => {
    if (event.target.id !== 'modal') {
      setIsOpen(!isOpen);
    }
  };
  useClickOutside(ref, () => setIsOpen(false));

  return (
    <>
      <FixedColumn>
        <FixedColumnValue>
          <RedirectTitleCell onClick={() => handleClickDeal(row)}>{row.investment.name}</RedirectTitleCell>
          {row.manager && <ManagerWrapper>{row.manager}</ManagerWrapper>}
        </FixedColumnValue>

        <div>
          <ClientDropdownWrap isOpen={isOpen} onClick={handleClick} id="infoWrap" ref={ref} opacityTime={0} disabled={disabled} removepointerevents>
            <ArrowDown />
          </ClientDropdownWrap>
        </div>
      </FixedColumn>
      <ClientDropdownModalWrapper ref={ref}>
        <ClientDropdownModal isOpen={isOpen}>
          {items.map(({ name, onClick }) => (
            <ClientDropdownItem key={name} onClick={onClick}>
              {name}
            </ClientDropdownItem>
          ))}
        </ClientDropdownModal>
      </ClientDropdownModalWrapper>
    </>
  );
};

const ClientDropdownItem = styled.div`
  display: flex;
  cursor: pointer;
  padding: 9px 11px;
  color: ${({ theme }) => theme.font.action};
  background: ${({ theme }) => theme.layer[1]};
  width: 285px;
  text-transform: capitalize;

  &:hover {
    color: ${({ theme }) => theme.layer.base};
    background: ${({ theme }) => theme.font.action};
  }

  font-family: Blinker, serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0;
  text-align: left;
`;

const FixedColumnValue = styled.div`
  display: flex;
  flex-direction: column;
`;

const ManagerWrapper = styled.div`
  font-weight: 400;
  font-size: 13px;
  color: ${({ theme }) => theme.font.base};
`;

const ClientDropdownWrap = styled(FadeAnimation)<{
  isTablet?: boolean;
  disabled?: boolean;
  selected?: boolean;
}>`
  cursor: pointer;
  border-radius: 4px;
  width: ${SIZE_DROPDOWN_ACTIONS}px;
  height: ${SIZE_DROPDOWN_ACTIONS}px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  ${({ isTablet, selected, isOpen }) =>
    isTablet
      ? css`
          ${selected
            ? css`
                opacity: 1;
              `
            : css`
                background: ${({ theme }) => theme.action.primary};
              `}
        `
      : css`
          background: ${({ theme }) => theme.action.primary};
        `};
  margin-left: auto;
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
`;

const ClientDropdownModalWrapper = styled.div`
  position: absolute;
`;

const ClientDropdownModal = styled(FadeAnimation)`
  z-index: 10;
  cursor: default;
  top: 55px;
  left: 18px;
  display: flex;
  flex-direction: column;
  position: absolute;
  background: ${({ theme }) => theme.layer[1]};
  border: 1px solid ${({ theme }) => theme.border.base};
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  padding: 10px 0;
`;

const FixedColumn = styled.div`
  padding: 10px 24px;
  position: sticky;
  left: 0;
  z-index: 1;
  width: 390px;
  border-right: 1px solid ${({ theme }) => theme.border.base};
  border-radius: 8px 0 0 8px;

  background-color: ${({ theme }) => theme.layer[1]};
  font-family: 'Blinker';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  &:hover {
    background-color: ${({ theme }) => theme.layer[12]};
    transition: background-color 100ms ease;
  }
`;

export default FixedTableColumn;
