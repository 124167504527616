import FormattingTooltip from '@/components/basicComponents/tooltip/dollarsTooltip';
import NoResults from '@/components/table/noResults';
import RedirectTitleCell from '@/components/table/redirectTitleCell';
import { TableRowCell, TableRowWrapper, TextCell } from '@/components/table/styles';
import { TableColumns } from '@/components/table/types';
import guid from '@/utils/guid';
import styled from 'styled-components';
import { IListAdvisor } from '../types';

interface TableBodyAdvisorProps {
  columns: TableColumns[];
  listAdvisers: IListAdvisor[];
  changeViewBy: (veiw: string) => void;
}

const TableBodyAdvisor = ({ columns, listAdvisers, changeViewBy }: TableBodyAdvisorProps) => {
  if (listAdvisers.length > 0) {
    return (
      <>
        {listAdvisers.map((row) => (
          <CustomTableRowWrapper key={row.id}>
            <TableRowCell width={columns[0].width}>
              <RedirectTitleCell id="actionCurioText" onClick={() => changeViewBy(row.id)}>
                {row.name}
              </RedirectTitleCell>
            </TableRowCell>
            <TableRowCell width={columns[1].width}>
              <TextCell>{row.investorCount}</TextCell>
            </TableRowCell>
            <TableRowCell width={columns[2].width}>
              <TextCell>{row.investmentCount}</TextCell>
            </TableRowCell>
            <TableRowCell width={columns[3].width}>
              <TextCell>
                <FormattingTooltip zIndex={1000} key={guid()}>
                  {row.totalValue}
                </FormattingTooltip>
              </TextCell>
            </TableRowCell>
            <TableRowCell width={columns[4].width}>
              <TextCell>
                <FormattingTooltip zIndex={1000} key={guid()}>
                  {row.totalCommitments}
                </FormattingTooltip>
              </TextCell>
            </TableRowCell>
          </CustomTableRowWrapper>
        ))}
      </>
    );
  }

  return <NoResults />;
};

export default TableBodyAdvisor;

const CustomTableRowWrapper = styled(TableRowWrapper)`
  justify-content: space-between;
`;
