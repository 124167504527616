/*
<svg xmlns="http://www.w3.org/2000/svg" width="60" height="61" viewBox="0 0 60 61" fill="none">
<path d="M27.0126 39.4981H33.0054V45.5089H27.0126V39.4981ZM27.0126 15.509H33.0054V33.5054H27.0126V15.509ZM29.982 0.500022C24.0494 0.503581 18.2509 2.26609 13.3198 5.56469C8.38869 8.86329 4.54632 13.5498 2.27845 19.0319C0.010581 24.514 -0.580931 30.5455 0.578656 36.3637C1.73824 42.1819 4.59684 47.5257 8.79313 51.7195C12.9894 55.9133 18.3349 58.7687 24.1539 59.9248C29.9728 61.0809 36.0039 60.4857 41.4846 58.2146C46.9653 55.9434 51.6497 52.0982 54.9453 47.1652C58.241 42.2321 60 36.4326 60 30.5C60.0024 26.5581 59.2274 22.6546 57.7195 19.0125C56.2115 15.3705 54.0002 12.0616 51.212 9.27512C48.4239 6.48866 45.1137 4.27937 41.4708 2.77362C37.8279 1.26788 33.9239 0.495289 29.982 0.500022ZM29.982 54.4892C25.2331 54.4927 20.5897 53.0877 16.6394 50.4519C12.6891 47.8162 9.60921 44.068 7.7894 39.6816C5.96959 35.2952 5.49166 30.4676 6.41595 25.8095C7.34023 21.1513 9.62529 16.872 12.982 13.5127C16.3388 10.1535 20.6164 7.86521 25.2738 6.93743C29.9312 6.00965 34.7592 6.48399 39.147 8.3005C43.5348 10.117 47.2852 13.1941 49.9239 17.1424C52.5627 21.0908 53.9711 25.7331 53.9711 30.482C53.9759 36.8429 51.4562 42.9456 46.9651 47.4501C42.4741 51.9547 36.3788 54.4928 30.018 54.5071L29.982 54.4892Z" fill="#ABABAB"/>
</svg>
*/

import * as React from 'react';

export const WarningModalIcon = ({ ...props }: React.SVGProps<SVGSVGElement>) => (
  <svg 
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || 60}
    height={props.height || 61}
    viewBox="0 0 ${props.width || 60} ${props.height || 61}"
    fill="none">
    <path 
      d="M27.0126 39.4981H33.0054V45.5089H27.0126V39.4981ZM27.0126 15.509H33.0054V33.5054H27.0126V15.509ZM29.982 0.500022C24.0494 0.503581 18.2509 2.26609 13.3198 5.56469C8.38869 8.86329 4.54632 13.5498 2.27845 19.0319C0.010581 24.514 -0.580931 30.5455 0.578656 36.3637C1.73824 42.1819 4.59684 47.5257 8.79313 51.7195C12.9894 55.9133 18.3349 58.7687 24.1539 59.9248C29.9728 61.0809 36.0039 60.4857 41.4846 58.2146C46.9653 55.9434 51.6497 52.0982 54.9453 47.1652C58.241 42.2321 60 36.4326 60 30.5C60.0024 26.5581 59.2274 22.6546 57.7195 19.0125C56.2115 15.3705 54.0002 12.0616 51.212 9.27512C48.4239 6.48866 45.1137 4.27937 41.4708 2.77362C37.8279 1.26788 33.9239 0.495289 29.982 0.500022ZM29.982 54.4892C25.2331 54.4927 20.5897 53.0877 16.6394 50.4519C12.6891 47.8162 9.60921 44.068 7.7894 39.6816C5.96959 35.2952 5.49166 30.4676 6.41595 25.8095C7.34023 21.1513 9.62529 16.872 12.982 13.5127C16.3388 10.1535 20.6164 7.86521 25.2738 6.93743C29.9312 6.00965 34.7592 6.48399 39.147 8.3005C43.5348 10.117 47.2852 13.1941 49.9239 17.1424C52.5627 21.0908 53.9711 25.7331 53.9711 30.482C53.9759 36.8429 51.4562 42.9456 46.9651 47.4501C42.4741 51.9547 36.3788 54.4928 30.018 54.5071L29.982 54.4892Z"
      fill={props.fill || 'ABABAB'}
    />
  </svg>
);
