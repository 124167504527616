import React from 'react';
import styled, { useTheme } from 'styled-components';

import jfgTabs from '@/constants/tabs/jfgTabs';
import { useLocation, useNavigate } from 'react-router-dom';
import tabs from '../../constants/tabs/tabs';
import { useAuth } from '../../hooks/use-auth';
import { useResponsive } from '../../hooks/use-responsive';

type HeaderProps = {
  modalControl?: JSX.Element;
  specialControl?: JSX.Element;
  title?: string;
};

const Header = ({ specialControl, modalControl, title }: HeaderProps) => {
  const { user, isAuthenticated } = useAuth();
  const navigate = useNavigate();
  const theme = useTheme();

  const project: string = 'curio';

  const currentTabs = project === 'jfg' ? jfgTabs : tabs;

  const { pathname } = useLocation();
  const selectedTabIndex = currentTabs.findIndex((tab) => pathname.includes(tab.path.split('/')[0])) ?? 0;
  const { isMobile, isDesktop, isTablet, isLargeDesktop } = useResponsive();

  const styledImage = currentTabs[selectedTabIndex]?.icon
    ? React.cloneElement(currentTabs[selectedTabIndex].icon, {
        fill: theme.font.strong,
        width: isMobile ? 33 : 40,
        height: isMobile ? 33 : 40
      })
    : null;

  return (
    <HeaderWrap modalControl={!!modalControl} isMobile={isMobile} isTablet={isTablet}>
      {modalControl ? (
        modalControl
      ) : (
        <HeaderTitleWrap>
          <HeaderIconWrap>
            {styledImage}
            <HeaderTitle isTablet={isTablet} isMobile={isMobile}>
              {title ? title : currentTabs[selectedTabIndex].label}
            </HeaderTitle>
          </HeaderIconWrap>
          {currentTabs[selectedTabIndex]?.subTitle && !isMobile && <HeaderSubTitle>{currentTabs[selectedTabIndex].subTitle}</HeaderSubTitle>}
        </HeaderTitleWrap>
      )}
      <SpecialControlWrap>
        {(isDesktop || isLargeDesktop || isTablet) && specialControl}
        <HeaderAccountWrap onClick={() => navigate('/profile')}>
          {isAuthenticated && (isDesktop || isLargeDesktop) && (
            <AboutUserWrap>
              <AccountInfoWrap>
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="20" viewBox="0 0 18 20" fill="none">
                  <path
                    d="M17.0592 16.8776C17.0821 17.2984 17.0158 17.7193 16.8644 18.1129C16.7131 18.5064 16.4801 18.8638 16.1807 19.1617C15.5406 19.737 14.698 20.037 13.8366 19.9963H3.29541C2.43402 20.037 1.59141 19.737 0.951244 19.1617C0.650954 18.8642 0.417062 18.507 0.264832 18.1135C0.112602 17.7199 0.0454521 17.2987 0.0677888 16.8776C0.0677888 16.4528 0.0678023 16.0429 0.112979 15.6381C0.14399 15.197 0.200975 14.7581 0.283643 14.3237C0.35959 13.8823 0.465226 13.4465 0.599866 13.0192C0.725541 12.6129 0.893786 12.2209 1.10183 11.8497C1.29953 11.4811 1.55166 11.1442 1.84976 10.8501C2.15011 10.5729 2.50115 10.3557 2.88379 10.2104C3.31288 10.0462 3.7694 9.96477 4.22905 9.97048C4.41178 10.0237 4.5823 10.112 4.73101 10.2304L5.62952 10.8052C6.03881 11.0501 6.47724 11.2432 6.93462 11.3799C7.98235 11.7263 9.11449 11.7263 10.1622 11.3799C10.618 11.2432 11.0548 11.0502 11.4623 10.8052L12.3658 10.2304C12.5139 10.1111 12.6847 10.0227 12.8678 9.97048C13.3274 9.96477 13.784 10.0462 14.213 10.2104C14.5957 10.3557 14.9467 10.5729 15.2471 10.8501C15.5452 11.1442 15.7973 11.4811 15.995 11.8497C16.2032 12.2209 16.3715 12.6129 16.497 13.0192C16.6334 13.4463 16.7407 13.8821 16.8182 14.3237C16.8984 14.7585 16.9554 15.1973 16.9889 15.6381C17.0491 16.0429 17.0592 16.4528 17.0592 16.8776ZM11.6129 1.38401C12.428 2.31078 12.8773 3.50077 12.8773 4.73266C12.8773 5.96454 12.428 7.15448 11.6129 8.08124C11.2326 8.51585 10.7633 8.86429 10.2364 9.10304C9.70951 9.34179 9.13734 9.46531 8.55845 9.46531C7.97957 9.46531 7.40742 9.34179 6.88053 9.10304C6.35364 8.86429 5.88427 8.51585 5.50403 8.08124C4.68594 7.15591 4.23464 5.96543 4.23464 4.73266C4.23464 3.49988 4.68594 2.30934 5.50403 1.38401C5.88427 0.949399 6.35364 0.601027 6.88053 0.362277C7.40742 0.123526 7.97957 0 8.55845 0C9.13734 0 9.70951 0.123526 10.2364 0.362277C10.7633 0.601027 11.2326 0.949399 11.6129 1.38401Z"
                    fill={theme.layer[3]}
                  />
                </svg>
                <NameText>{user.firstName}</NameText>
              </AccountInfoWrap>
            </AboutUserWrap>
          )}
        </HeaderAccountWrap>
      </SpecialControlWrap>
    </HeaderWrap>
  );
};

const AboutUserWrap = styled.div`
  display: flex;
  padding: 8px 24px;
  border-radius: 30px;
  border: 1px solid ${({ theme }) => theme.border.base};
  background: ${({ theme }) => theme.layer.base};
`;

const SpecialControlWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 38px;
`;

const AccountInfoWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

const HeaderAccountWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
`;

export const HeaderSubTitle = styled.div`
  display: flex;
  font-family: Blinker, serif;
  font-size: 13px;
  line-height: 18px;
  font-weight: 400;
  color: ${({ theme }) => theme.font.weak};
  padding: 4px 0;
`;

export const HeaderTitle = styled.div<{ isMobile: boolean; isTablet: boolean }>`
  font-family: Blinker, serif;
  font-size: ${({ isMobile, isTablet }) => (isTablet ? '24px' : isMobile ? '20px' : '33px')};
  line-height: 33px;
  font-weight: 700;
  color: ${({ theme }) => theme.font.strong};
`;

export const HeaderIconWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const HeaderTitleWrap = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 20px;
`;

const HeaderWrap = styled.div<{ isMobile: boolean; isTablet: boolean; modalControl: boolean }>`
  display: flex;
  margin: ${({ isMobile }) => (isMobile ? '0' : '0 -24px')};
  justify-content: space-between;
  align-items: center;
  // width: ${({ isMobile, modalControl }) => (modalControl && isMobile ? 'calc(100% + 44px)' : 'calc(100% + 48px)')};
  width: ${({ isMobile, modalControl }) => (modalControl && isMobile ? '100%' : 'calc(100% + 48px)')};
  top: ${({ isMobile, isTablet }) => (isMobile || isTablet ? '50px' : 0)};
  position: sticky;
  height: 60px;
  background: ${({ theme }) => theme.layer[12]};
  z-index: 101;
  padding: ${({ isMobile }) => (isMobile ? '0 30px' : '0px 50px')};
  box-shadow:
    0 12px 16px rgba(0, 0, 0, 0.04),
    0 2px 8px rgba(0, 0, 0, 0.03),
    0 1.12694px 2.75474px rgba(0, 0, 0, 0.01);
  transition: 0.3s;
`;

const NameText = styled.div`
  color: ${({ theme }) => theme.action.primary};
  font-family: Blinker;
  font-size: 13px;
  font-weight: 600;
  line-height: 18.2px;
`;

export default Header;
