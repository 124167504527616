import { useMutation } from '@apollo/client';
import { useNavigate, useLocation } from 'react-router-dom';
import { useState } from 'react';
import Label from '@/components/basicComponents/label';
import ModalWrappedContent from '@/components/basicComponents/modal/modalWrappedContent';
import { ITier } from '@/components/dueDiligence-page/dueDiligenceRecord/types';
import {
  CREATE_DUE_DILIGENCE_TIER,
  GET_FUNNEL_QUERY
} from '@/components/settings-page/queries';
import { MainSearch, ModalContainer, ModalTitle } from './addFunnel';

interface DuplicateTierProps {
  onClose: () => void;
  isOpen: boolean;
  currentTier: ITier | null;
  funnelId: string | null;
  numberOfTiers: number;
}

const DuplicateTier = ({
  onClose,
  isOpen,
  currentTier,
  funnelId,
  numberOfTiers
}: DuplicateTierProps) => {
  if (!currentTier || !funnelId) return <></>;
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const [value, setValue] = useState('');

  const [createTier] = useMutation(CREATE_DUE_DILIGENCE_TIER, {
    refetchQueries: () => [
      {
        query: GET_FUNNEL_QUERY,
        variables: {
          id: queryParams.get('funnelId')
        }
      }
    ]
  });

  const saveValue = () => {
    const updatedSections = currentTier.sections.map((section) => {
      const updatedFields = section.fields.map((field) => {
        return {
          name: field.name,
          required: field.required,
          type: field.type,
          linkedFieldId: null,
          promptGroup: field.promptGroup
            ? {
                prompts: field.promptGroup.prompts,
                summaryPrompt: field.promptGroup.summaryPrompt,
                contextPrompt: field.promptGroup.contextPrompt,
                rubricTemplates: field.promptGroup.rubricTemplates
              }
            : null
        };
      });
      return {
        name: section.name,
        userCanScore: section.userCanScore,
        pdfLayout: section.pdfLayout,
        fields: updatedFields
      };
    });

    const updatedChecklistItems = currentTier.checklistItems.map((item) => {
      return { name: item.name, required: item.required };
    });

    createTier({
      variables: {
        data: {
          funnelId: funnelId,
          name: value,
          order: numberOfTiers + 1,
          sections: updatedSections,
          checklistItems: updatedChecklistItems
        }
      }
    });
    onClose();
  };

  return (
    <ModalWrappedContent
      isOpen={isOpen}
      onClose={onClose}
      confirmButton={{ name: 'Save', onClick: saveValue, disabled: !value }}
    >
      <ModalTitle>Duplicate Tier</ModalTitle>
      <ModalContainer>
        <Label required>Name</Label>
        <MainSearch
          type="text"
          placeholder="Give this Section a unique name"
          value={value}
          onChange={(event) => setValue(event.target.value)}
          autoFocus
        />
      </ModalContainer>
    </ModalWrappedContent>
  );
};

export default DuplicateTier;
