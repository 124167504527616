import { AiAssistantIcon } from '@/assets/static/icons/ai-assistant';
import Textarea from '@/components/basicComponents/textarea';
import { LoaderOnWholeScreen } from '@/components/loaders/loader-on-whole-screen';
import { formatCash } from '@/utils/formatCash';
import { useMutation } from '@apollo/client';
import { ChangeEvent, useEffect, useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { updateDocumentFieldAnswerMutation } from '../../queries';
import { IDocumentRecordFieldEntity } from '../../types';
import { EditWrapper } from './editWrapper';

interface DollarProps {
  handleClose: () => void;
  row: IDocumentRecordFieldEntity | null;
  investmentId: string | null;
  refetch: () => void;
}

type AmountUnit = 'thousand' | 'million' | 'billion';

export function extractAmount(input: string): string {
  if (!input) return '';

  const regex = /(\$?[\d.,]+)\s*(thousand|million|billion)?/gi;
  const matches = input.match(regex);

  if (!matches) {
    return '';
  }

  let totalAmount = 0;
  const [, amountStr, unit] = matches[0].match(/(\$?[\d.,]+)\s*(thousand|million|billion)?/i) as [string, string, AmountUnit];
  const amount = parseFloat(amountStr.replace(/[\$?,]/g, ''));

  if (!unit || unit.toLowerCase() === 'thousand') {
    totalAmount += amount;
  } else if (unit.toLowerCase() === 'million') {
    totalAmount += amount * 1000000;
  } else if (unit.toLowerCase() === 'billion') {
    totalAmount += amount * 1000000000;
  }

  if (totalAmount) {
    return totalAmount.toFixed(0);
  }
  return '';
}

const DEFAULT_ERROR_MESSAGE = `We're sorry. This process did not complete. Please try again.`;

export const DollarPage = ({ handleClose, row, investmentId, refetch }: DollarProps) => {
  const theme = useTheme();

  const [dollarValue, setDollarValue] = useState({
    fieldValue: '',
    commentary: ''
  });
  const [isEditMode, setIsEditMode] = useState(true);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const [updateDocumentFieldAnswer, { loading: updateDocumentFieldAnswerLoading }] = useMutation(updateDocumentFieldAnswerMutation, {
    onError: (error) => {
      console.error(error);
      setErrorMessage(DEFAULT_ERROR_MESSAGE);
    }
  });

  useEffect(() => {
    if (!row) return;

    if (row?.value !== null || row?.commentary !== null) {
      setIsEditMode(true);
      setDollarValue({
        ...dollarValue,
        fieldValue: row?.value || dollarValue.fieldValue,
        commentary: row?.commentary || dollarValue.commentary
      });
      return;
    }
    if (row.AI?.answers[0]) {
      setIsEditMode(false);
      setDollarValue({
        ...dollarValue,
        fieldValue: extractAmount(row.AI?.answers[0]),
        commentary: row.AI.answers[0]
      });
      return;
    }
    return () => {
      setDollarValue({ fieldValue: '', commentary: '' });
    };
  }, [row]);

  const saveValue = () => {
    setErrorMessage(null);

    updateDocumentFieldAnswer({
      variables: {
        data: {
          fieldId: row.fieldId,
          recordId: row.recordId,
          value: isEditMode ? dollarValue.fieldValue : null,
          commentary: isEditMode ? dollarValue.commentary : null
        }
      },
      onCompleted: () => {
        refetch();
        setDollarValue({ fieldValue: '', commentary: '' });
        handleClose();
      }
    });
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    const isValidInput = /^([0-9]+(\.[0-9]*)?|\.[0-9]+)$/.test(inputValue);

    if (isValidInput || inputValue === '') {
      setDollarValue({ ...dollarValue, fieldValue: inputValue });
    }
  };

  const editSuggested = (suggestion: string) => {
    setDollarValue({
      fieldValue: extractAmount(suggestion),
      commentary: suggestion
    });
    setIsEditMode(true);
  };

  const clearSuggestion = () => {
    setDollarValue({ fieldValue: '', commentary: '' });
    setIsEditMode(true);
  };

  const startWithAISuggestion = () => {
    setDollarValue({
      fieldValue: extractAmount(row.AI?.answers[0]),
      commentary: row.AI?.answers[0]
    });
    setIsEditMode(false);
  };

  const revertPriorDraft = () => {
    if (row?.value || row?.commentary) {
      setDollarValue({
        ...dollarValue,
        fieldValue: row?.value,
        commentary: row?.commentary
      });
      setIsEditMode(true);
    }
  };

  if (!row) return <></>;

  if (updateDocumentFieldAnswerLoading) {
    return <LoaderOnWholeScreen size={60} />;
  }

  return (
    <EditWrapper
      showAiSuggestion={Boolean(row.AI?.answers.length > 0)}
      isEditMode={isEditMode}
      startWithAISuggestion={startWithAISuggestion}
      editSuggested={editSuggested}
      clearSuggestion={clearSuggestion}
      revertPriorDraft={revertPriorDraft}
      showRevertPriorDraft={
        (Boolean(row?.value) && row?.value !== dollarValue.fieldValue) || (Boolean(row?.commentary) && row?.commentary !== dollarValue.commentary)
      }
      handleClose={handleClose}
      row={row}
      investmentId={investmentId}
      recordId={row.recordId}
      confirmButton={{ name: 'Save', onClick: saveValue }}
      errorMessage={errorMessage && 'Error'}
      errorDescription={errorMessage || null}
    >
      <div>
        {isEditMode ? (
          <>
            <FieldName>{row.documentField.name}</FieldName>
            <FieldInput isBackground={!!dollarValue.fieldValue} type={'text'} value={dollarValue.fieldValue} onChange={handleInputChange} autoFocus />
          </>
        ) : (
          <>
            <FieldName>{row.documentField.name}</FieldName>
            <AutoGeneratedField>
              <AiIconWrap>
                <AiAssistantIcon width={28} height={28} fill={theme.font.weak} />
              </AiIconWrap>
              <div>
                <span style={{ fontWeight: 700 }}>Suggested {row.AI?.citations.length >= 2 && `(${row.AI?.citations.length - 1} more)`}:</span>{' '}
                {dollarValue.fieldValue && formatCash(+dollarValue.fieldValue)}
              </div>
            </AutoGeneratedField>
          </>
        )}
      </div>
      <CommentaryWrap>
        <FieldName>Commentary</FieldName>
        {isEditMode ? (
          <Textarea
            value={dollarValue.commentary}
            textareaHeight="80px"
            isValidValue={!!dollarValue.commentary}
            maxLength={250}
            setValue={(value) =>
              setDollarValue({
                ...dollarValue,
                commentary: value
              })
            }
          />
        ) : (
          <AnswerWrap>
            <AiIconWrap>
              <AiAssistantIcon width={28} height={28} fill={theme.font.weak} />
            </AiIconWrap>
            <Answer>
              <span style={{ fontWeight: 700 }}>Suggested {row.AI?.citations.length >= 2 && `(${row.AI?.citations.length - 1} more)`}:</span>{' '}
              {row.AI?.answers[0]}
            </Answer>
          </AnswerWrap>
        )}
      </CommentaryWrap>
    </EditWrapper>
  );
};

export const FieldInput = styled.input<{ isBackground?: boolean }>`
  width: 100%;
  border: ${({ theme, isBackground }) => (isBackground ? `1px solid ${theme.context.success}` : `1px solid ${theme.border.base}`)};
  background-color: ${({ theme, isBackground }) => (isBackground ? '#EFFFEA' : theme.layer[1])};
  color: ${({ theme, isBackground }) => (isBackground ? '#2E2E2E' : theme.font.strong)};
  border-radius: 5px;
  padding: 6.5px 8px;
  font-size: 19px;
  font-weight: 400;
  padding: 10px;

  &:focus {
    outline: none;
  }
`;

export const CommentaryWrap = styled.div`
  font-weight: 400;
  font-size: 13px;
  margin-top: 23px;
`;

export const AnswerWrap = styled.div`
  display: flex;
  font-size: 16px;
  background: ${({ theme }) => theme.action.disabled};
  height: 80px;
  padding: 5px 8px;
  border: 1px solid ${({ theme }) => theme.border.base};
  border-radius: 3px;
`;

export const AiIconWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border-right: 1px solid ${({ theme }) => theme.border.base};
`;

export const Answer = styled.div`
  padding-left: 10px;
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;
  color: ${({ theme }) => theme.font.base};
  overflow-y: auto;
`;

export const FieldName = styled.p`
  display: inline;
  font-weight: 400;
  font-size: 16px;
  font-family: Blinker;
  color: ${({ theme }) => theme.font.base};
`;

export const AutoGeneratedField = styled.div`
  background: ${({ theme }) => theme.action.disabled};
  border: 1px solid ${({ theme }) => theme.border.base};
  color: ${({ theme }) => theme.font.base};
  border-radius: 4px;
  min-height: 48px;
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 5px;
`;
