import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

import { useSearchParams } from 'react-router-dom';
import Header from '../../components/header';
import { useResponsive } from '../../hooks/use-responsive';
import { MainWrap, PaddingWrap } from '../../styles/common';
import { InvestmentManager } from '../../types/investments';
import FilterSelect from '../basicComponents/select';
import { initialValuesFromTable } from '../table/constants';
import FilterPage from '../table/filterPage';
import { Results, TopWrapper } from '../table/filterPage/desktop-filter';
import TablePagination from '../table/tablePagination';
import { ISort, SORT_ORDER } from '../table/types';
import { investmentsSort } from './constatnts';
import { INVESTMENT_MANAGERS_QUERY } from './queries';
import Table from './table/table';

const Investments = () => {
  const { isMobile, isTablet } = useResponsive();
  const [searchParams, setSearchParams] = useSearchParams();

  const q = searchParams.get('q') || '';
  const key = searchParams.get('key') || 'countOfInvestors';
  const asc = searchParams.get('asc') === 'true' || false;
  const limit = searchParams.get('limit') || initialValuesFromTable.initialAmountRowsPerPage[0];
  const offset = searchParams.get('offset') || 0;

  const [mobileSort, setMobileSort] = useState(investmentsSort[8].title);
  const sortItem = investmentsSort.find((item) => item.title === mobileSort);
  const mobileSortList = investmentsSort.map((item) => item.title);
  const [managers, setManagers] = useState<InvestmentManager[]>([]);

  const { data, loading, error, refetch } = useQuery(INVESTMENT_MANAGERS_QUERY, {
    notifyOnNetworkStatusChange: true,
    variables: {
      page: Math.floor(Number(offset) / Number(limit)) + 1,
      rowsPerPage: Number(limit),
      sort: key,
      order: asc ? SORT_ORDER.ASC : SORT_ORDER.DESC,
      search: q
    }
  });

  useEffect(() => {
    if (!error && !loading && data) {
      setManagers(data.InvestmentManagers.managers);
    }
  }, [data, loading, error]);

  useEffect(() => {
    if (!isMobile) return;
    refetch({
      order: sortItem?.asc ? SORT_ORDER.ASC : SORT_ORDER.DESC,
      sort: sortItem?.key,
      search: q
    });
  }, [mobileSort]);

  const onChangeSearch = (searchValue: string) => {
    setSearchParams(
      (prev) => {
        if (!searchValue) {
          prev.delete('q');
          return prev;
        }
        prev.set('q', searchValue);
        return prev;
      },
      { replace: true }
    );
  };

  const onChangeSort = (value: ISort) => {
    setSearchParams(
      (prev) => {
        prev.set('key', value.key);
        prev.set('asc', value.asc.toString());
        return prev;
      },
      { replace: true }
    );
  };

  const onChangePaginationData = (limit: number, offset: number) => {
    setSearchParams(
      (prev) => {
        prev.set('limit', limit.toString());
        prev.set('offset', offset.toString());
        return prev;
      },
      { replace: true }
    );
  };

  return (
    <>
      <MainWrap>
        <Header />
      </MainWrap>
      <PaddingWrap>
        <CustomFilterPage
          isMobile={isMobile}
          isTablet={isTablet}
          search={{
            value: q,
            onChange: onChangeSearch,
            placeholder: 'Search Managers'
          }}
          resultsValue={data?.InvestmentManagers.total}
          refetch={refetch}
        />
        {isMobile && (
          <SortSelectWrapper>
            <FilterSelect
              isMobile
              label={'Sort By'}
              paddingContainer={'0 20px'}
              width={'100%'}
              data={mobileSortList}
              selected={mobileSort}
              setSelected={setMobileSort}
            />
            <CustomRefetch>
              <Results>{data?.InvestmentManagers.total || 0} Results</Results>
            </CustomRefetch>
          </SortSelectWrapper>
        )}
        <Table loading={loading} managers={managers} refetch={refetch} sort={{ key, asc }} setSort={onChangeSort} />
        {!loading ? (
          <TableFooter>
            <TablePagination
              paginationValues={{
                limit: Number(limit),
                offset: Number(offset),
                total: data?.InvestmentManagers.total
              }}
              savePagination={onChangePaginationData}
              refetch={refetch}
              usePage
            />
          </TableFooter>
        ) : (
          <></>
        )}
      </PaddingWrap>
    </>
  );
};

export default Investments;

const CustomRefetch = styled(TopWrapper)`
  padding: 25px 0 0 20px;
`;

const TableFooter = styled('div')`
  display: flex;
  justify-content: flex-end;
`;

const CustomFilterPage = styled(FilterPage)<{ isMobile: boolean; isTablet: boolean }>`
  position: sticky;
  top: ${({ isMobile, isTablet }) => (isMobile || isTablet ? '50px' : '0')};
  z-index: 12;
  background-color: #fafafa;
  margin-top: 0;
  margin-left: -1px;
  width: calc(100% + 2px);
  padding: ${({ isMobile }) => (isMobile ? '20px 0 0 0' : '20px 0 10px 0')};
`;

const SortSelectWrapper = styled.div`
  position: sticky;
  top: 120px;
  z-index: 11;
  background-color: #fafafa;
  margin-top: 0;
  padding-bottom: 10px;
`;
