import styled from 'styled-components';

export const FutureStatusRow = ({
  status,
  index,
  isMobile = false
}: {
  status: string;
  index: number;
  isMobile?: boolean;
}) => {
  return (
    <Row isMobile={isMobile}>
      <Number>{index + 1}</Number>
      <FutureStatus isMobile={isMobile} color={'#373F4E'}>
        {status}
      </FutureStatus>
    </Row>
  );
};
const Row = styled.div<{ background?: string; isMobile: boolean }>`
  display: flex;
  justify-content: ${({ isMobile }) =>
    isMobile ? 'flex-start' : 'space-between'};
  align-items: center;
  background: #ffffff;
  border-radius: 4px;
  padding: 7px 12px;
  background: ${({ background }) => background ?? '#fff'};
`;

const FutureStatus = styled.div<{ isMobile: boolean }>`
  width: 100%;
  overflow: ${({ isMobile }) => (isMobile ? 'hidden' : 'none')};
  text-overflow: ${({ isMobile }) => (isMobile ? 'ellipsis' : 'none')};
  user-select: ${({ isMobile }) => (isMobile ? 'none' : 'auto')};
  display: inline-block;
  color: ${({ color }) => color};
  padding-left: 10px;
  font-family: 'Blinker';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
`;

const Number = styled.span`
  color: #373f4e;
  font-family: 'Blinker', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
`;
