import FormattingTooltip from '@/components/basicComponents/tooltip/dollarsTooltip';
import Suitability from '@/components/client-entity-details/tableAllocations/suitability';
import { SuitabilityEnum } from '@/components/client-entity-details/types';
import { SuitabilityWrapper } from '@/components/opportunities-entity/pendingAllocation/suitabilityDetails';
import {
  MobileRow,
  MobileRowTitle,
  MobileRowValue,
  MobileTableRowWrap,
  MobileTableWrapper,
  NoResults
} from '@/components/table/mobileTable/mobileTable';
import guid from '@/utils/guid';
import { columns } from '../../constatnts';
import { IInvestmentEntity } from '../../types';

interface MobileTableProps {
  listInvestmentEntity: IInvestmentEntity[];
  openInvestorDetails: (id: string) => void;
  openMobileModal: (data: IInvestmentEntity, modalType: string) => void;
  openEntityDetails: (investorId: string, entityId: string) => void;
}

const MobileTable = ({ listInvestmentEntity, openInvestorDetails, openMobileModal, openEntityDetails }: MobileTableProps) => {
  const TableRow = ({ el }: { el: IInvestmentEntity }) => {
    return (
      <MobileTableRowWrap key={guid()}>
        <MobileRow>
          <MobileRowTitle>{columns[0].title}</MobileRowTitle>
          <MobileRowValue isRedirect onClick={() => openEntityDetails(el.family.id, el.id)}>
            {el.entityName}
          </MobileRowValue>
        </MobileRow>

        <MobileRow>
          <MobileRowTitle>{columns[1].title}</MobileRowTitle>
          <MobileRowValue onClick={() => openInvestorDetails(el.family.name)}>{el.family.name}</MobileRowValue>
        </MobileRow>

        <MobileRow>
          <MobileRowTitle>{columns[2].title}</MobileRowTitle>
          <MobileRowValue>
            <FormattingTooltip zIndex={1000}>{el.overallCapacity}</FormattingTooltip>
          </MobileRowValue>
        </MobileRow>

        <MobileRow>
          <MobileRowTitle>{columns[3].title}</MobileRowTitle>
          <MobileRowValue>
            <FormattingTooltip zIndex={1000}>{el.assetClassCapacity}</FormattingTooltip>
          </MobileRowValue>
        </MobileRow>

        <MobileRow>
          <MobileRowTitle>{columns[4].title}</MobileRowTitle>
          <MobileRowValue onClick={() => openMobileModal(el, 'suitability')}>
            <SuitabilityWrapper>
              <Suitability suitability={el.suitability as SuitabilityEnum} width="100" />
            </SuitabilityWrapper>
          </MobileRowValue>
        </MobileRow>

        <MobileRow>
          <MobileRowTitle>{columns[5].title}</MobileRowTitle>
          <MobileRowValue>
            <FormattingTooltip zIndex={1000}>{el.investor_interest[0]?.amount}</FormattingTooltip>
          </MobileRowValue>
        </MobileRow>
      </MobileTableRowWrap>
    );
  };

  return (
    <MobileTableWrapper>
      {listInvestmentEntity.length ? (
        listInvestmentEntity.map((el, index) => {
          return <TableRow el={el} key={index} />;
        })
      ) : (
        <NoResults>No Results</NoResults>
      )}
    </MobileTableWrapper>
  );
};

export default MobileTable;
