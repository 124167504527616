import Button from '@/components/fat-basicComponents/button';
import { NetworkStatus, useMutation, useQuery } from '@apollo/client';
import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';

import client from '@/apollo-client';
import { AddButtonIcon } from '@/assets/static/icons/add-button-icon';
import { BurgerMenuIcon } from '@/assets/static/icons/burger-menu';
import { CloseMenuIcon } from '@/assets/static/icons/close-menu';
import FilterSelect from '@/components/basicComponents/select';
import { Loader } from '@/components/loaders/loader';
import { ISort, SORT_ORDER } from '@/components/table/types';
import { useResponsive } from '@/hooks/use-responsive';
import { useClickOutside } from '@/hooks/useClickOutside';
import { MainWrap, PaddingWrap } from '@/styles/common';
import { TabsTitle } from '../basicComponents/fat-tabs';
import { LIST_DUE_DILIGENCE_RECORD } from '../dealRegistry-page/queries';
import Header from '../fat-header';
import { dueDiligenceFunnelSort } from './constatnts';
import EditOwner from './editOwner';
import DownloadPDF from './modals/downloadPDF';
import { DUE_DILIGENCE_FUNNEL_LIST_QUERY, DUE_DILIGENCE_FUNNEL_QUERY, GET_DUE_DILIGENCE_LIMITS, updateDueDiligenceRecordMutation } from './queries';
import { TierItem } from './tierItem';
import { IFunnelData, IRecordData, ITierData } from './types';

export const DueDiligenceFunnel = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { isMobile } = useResponsive();
  const theme = useTheme();

  const [searchParams, setSearchParams] = useSearchParams();

  const activeFunnel = searchParams.get('activeFunnel') || '';

  const [funnel, setFunnel] = useState<IFunnelData[]>([]);
  const [search, setSearch] = useState('');
  const [sort, setSort] = useState<ISort>({
    key: 'CLOSE_DATE',
    asc: false
  });
  const [mobileSort, setMobileSort] = useState(dueDiligenceFunnelSort[5].title);
  const sortItem = dueDiligenceFunnelSort.find((item) => item.title === mobileSort);
  const mobileSortList = dueDiligenceFunnelSort.map((item) => item.title);
  const [tiers, setTiers] = useState<ITierData[]>([]);
  const [isTiersReady, setIsTiersReady] = useState(false);
  const [modalWindow, setModalWindow] = useState({
    isOpen: false,
    type: 'change-owner'
  });
  const [currentRow, setCurrentRow] = useState<IRecordData | null>(null);
  const [isTabMenuOpen, setIsTabMenuOpen] = useState(false);
  const [showTabs, setShowTabs] = useState(true);
  const [trialLimits, setTrialLimits] = useState({
    trialMode: false,
    canCreateDeal: true
  });
  const [records, setRecords] = useState<IRecordData[] | null>(null);

  const tabsContainerRef = useRef<HTMLDivElement | null>(null);
  const burgerMenuRef = useRef<HTMLDivElement | null>(null);

  useClickOutside<HTMLDivElement>(burgerMenuRef, () => setIsTabMenuOpen(false));

  const {
    data: funnelListData,
    loading: funnelListLoading,
    error: funnelListError
  } = useQuery(DUE_DILIGENCE_FUNNEL_LIST_QUERY, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
    variables: {
      data: {
        statusFilter: 'PUBLISHED',
        sortDirection: SORT_ORDER.ASC,
        sortBy: 'ORDER'
      }
    },
    onCompleted: (data) => {
      setFunnel(data.listDueDiligenceFunnels);
      if (!activeFunnel) {
        onChangeActiveFunnel(data.listDueDiligenceFunnels[0].id);
      }
    }
  });

  const { data, loading, error, refetch, networkStatus } = useQuery(DUE_DILIGENCE_FUNNEL_QUERY, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
    skip: !activeFunnel,
    variables: {
      id: activeFunnel,
      order: SORT_ORDER.DESC,
      sort: 'CLOSE_DATE'
    },
    onCompleted: async (data) => {
      setTiers(
        data.getDueDiligenceFunnel.tiers.map((tier: ITierData) => {
          return {
            ...tier,
            records: tier.records.filter(
              (record) => record.status !== 'APPROVED' && record.status !== 'REJECTED' && record.investment.securityStatus === 'DUE_DILIGENCE'
            )
          };
        })
      );
      const { data: recordsList } = await client.query({
        fetchPolicy: 'network-only',
        query: LIST_DUE_DILIGENCE_RECORD,
        variables: {
          data: {
            sortBy: 'INVESTMENT_NAME',
            sortDirection: SORT_ORDER.ASC,
            statusFilter: ['DUE_DILIGENCE'],
            funnelStaticIdFilter: data.getDueDiligenceFunnel.funnelStaticId
          }
        }
      });

      setRecords(recordsList.listDueDiligenceRecords.records);
    }
  });

  useEffect(() => {
    if (records) {
      setTiers((prevTiers) => {
        const updatedTiers = [...prevTiers];

        records.forEach((record: IRecordData) => {
          const targetTier = updatedTiers.find((tier) => tier.order === record.activeTier.order);

          if (targetTier && targetTier.id !== record.activeTier.id) {
            const recordAlreadyExists = targetTier.records.some((existingRecord) => existingRecord.id === record.id);

            if (!recordAlreadyExists) {
              const updatedTier = {
                ...targetTier,
                records: [...targetTier.records, record].sort((a, b) => {
                  return new Date(b.investment.finalClose).getTime() - new Date(a.investment.finalClose).getTime();
                })
              };

              const tierIndex = updatedTiers.findIndex((tier) => tier.order === targetTier.order);
              updatedTiers[tierIndex] = updatedTier;
            }
          }
        });

        return updatedTiers;
      });
      setIsTiersReady(true);
    }
  }, [records]);

  useQuery(GET_DUE_DILIGENCE_LIMITS, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      const { trialMode, canCreateDeal } = data.getDueDiligenceLimits;

      setTrialLimits({ trialMode, canCreateDeal });
    }
  });

  const [updateDueDiligenceRecord, { data: updateDueDiligenceRecordData, loading: updateDueDiligenceRecordLoading }] = useMutation(
    updateDueDiligenceRecordMutation,
    {
      onCompleted: () => {
        refetch();
      }
    }
  );

  useEffect(() => {
    if (isMobile) {
      setSort({
        key: sortItem?.key ?? 'CLOSE_DATE',
        asc: sortItem?.asc ?? false
      });
    }
  }, [activeFunnel, mobileSort]);

  useEffect(() => {
    const checkScreenWidth = () => {
      const tabsContainer = tabsContainerRef.current;

      if (tabsContainer) {
        const tabElements = Array.from(tabsContainer.children) as HTMLDivElement[];

        let totalTabWidth = 0;
        tabElements.forEach((tabElement) => {
          totalTabWidth += tabElement.offsetWidth;
        });

        const screenWidth = window.innerWidth;

        setShowTabs(totalTabWidth <= screenWidth - 200);
      }
    };

    if (tabsContainerRef.current) {
      checkScreenWidth();
    }

    window.addEventListener('resize', checkScreenWidth);

    return () => {
      window.removeEventListener('resize', checkScreenWidth);
    };
  }, [funnel, tabsContainerRef]);

  const handleTierClick = (row: IRecordData) => {
    navigate(`${row.id}?backToTitle=Due Diligence Funnel`);
  };

  const handleModifyDiligenceClick = (row: IRecordData) => {
    navigate(`modify?record=${row.id}&status=DUE_DILIGENCE&backToTitle=Due Diligence`);
  };

  const handleAddDiligenceClick = () => {
    navigate(`modify?status=DUE_DILIGENCE&backToTitle=Due Diligence`);
  };

  const openModalWindow = (type: string, row: IRecordData) => {
    setModalWindow({ ...modalWindow, isOpen: true, type });
    setCurrentRow(row);
  };

  const closeModalWindow = () => setModalWindow({ ...modalWindow, isOpen: false });

  useEffect(() => {
    if (!isTiersReady) return;

    const sortedData = [...tiers].map((tier) => ({
      ...tier,
      records: tier.records.sort((a, b) => {
        if (sort.key === 'INVESTMENT_NAME') {
          return sort.asc ? a.investment.name.localeCompare(b.investment.name) : b.investment.name.localeCompare(a.investment.name);
        } else if (sort.key === 'CLOSE_DATE' && (a.investment.finalClose || b.investment.finalClose)) {
          return sort.asc
            ? new Date(a.investment.finalClose).getTime() - new Date(b.investment.finalClose).getTime()
            : new Date(b.investment.finalClose).getTime() - new Date(a.investment.finalClose).getTime();
        } else if (sort.key === 'OWNER_NAME') {
          return sort.asc ? a.owner.firstName.localeCompare(b.owner.firstName) : b.owner.firstName.localeCompare(a.owner.firstName);
        } else if (sort.key === 'OPEN_TASKS') {
          return sort.asc ? a.activeTierOpenTasksCount - b.activeTierOpenTasksCount : b.activeTierOpenTasksCount - a.activeTierOpenTasksCount;
        }
        return 0;
      })
    }));

    setTiers(sortedData);
  }, [sort, isTiersReady]);

  const onChangeActiveFunnel = (funnelId: string, isMobile?: boolean) => {
    if (isMobile) {
      setIsTabMenuOpen(false);
    }
    setSearchParams(
      (prev) => {
        prev.set('activeFunnel', funnelId);
        return prev;
      },
      { replace: true }
    );
  };

  if (funnelListLoading) {
    return <CustomLoader size={60} />;
  }

  return (
    <>
      {modalWindow.type === 'change-owner' && (
        <EditOwner row={currentRow} isOpen={modalWindow.isOpen} onClose={closeModalWindow} updateOwner={updateDueDiligenceRecord} />
      )}
      {modalWindow.type === 'download-pdf' && (
        <DownloadPDF isOpen={modalWindow.isOpen} onClose={closeModalWindow} recordId={currentRow?.id ?? null} />
      )}
      <MainWrap>
        <Header />
        <PaddingWrap>
          {showTabs ? (
            <Tabs ref={tabsContainerRef}>
              {funnel.map((item: IFunnelData) => (
                <TabsTitle key={item.id} onClick={() => onChangeActiveFunnel(item.id)} activeTab={activeFunnel === item.id}>
                  {item.name}
                </TabsTitle>
              ))}
            </Tabs>
          ) : (
            <BurgerMenuWrapper ref={burgerMenuRef}>
              <ActiveTabWrapper>
                {isTabMenuOpen ? (
                  <CloseMenuIcon onClick={() => setIsTabMenuOpen(!isTabMenuOpen)} style={{ cursor: 'pointer' }} fill={theme.action.hover} />
                ) : (
                  <BurgerMenuIcon onClick={() => setIsTabMenuOpen(!isTabMenuOpen)} style={{ cursor: 'pointer' }} fill={theme.action.hover} />
                )}
                <ActiveTab activeTab>{funnel.find((item) => item.id === activeFunnel)?.name}</ActiveTab>
              </ActiveTabWrapper>
              {isTabMenuOpen && (
                <BurgerMenu>
                  {funnel.map((item: IFunnelData) => (
                    <MenuItem key={item.id} onClick={() => onChangeActiveFunnel(item.id, true)} activeTab={activeFunnel === item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
                </BurgerMenu>
              )}
            </BurgerMenuWrapper>
          )}

          <TabsLine />
          <FilterBoxWrapper isMobile={isMobile}>
            {isMobile && (
              <SearchBoxWrapper>
                {/* <FilterSearch value={search} onChange={setSearch} placeholder={'Search for an investment'} /> */}

                <FilterSelect isMobile label={'Sort By'} width={'100%'} data={mobileSortList} selected={mobileSort} setSelected={setMobileSort} />
              </SearchBoxWrapper>
            )}
            {(!trialLimits.trialMode || (trialLimits.trialMode && trialLimits.canCreateDeal)) && (
              <Button size="md" onClick={handleAddDiligenceClick} icon={<AddButtonIcon />}>
                Add Diligence
              </Button>
            )}
          </FilterBoxWrapper>
          {loading && !tiers.length ? (
            <CustomLoader size={60} />
          ) : (
            <TiersWrapper>
              {tiers?.map((item) => (
                <TierItem
                  openModalWindow={openModalWindow}
                  handleTierClick={handleTierClick}
                  key={item.id}
                  sort={sort}
                  setSort={setSort}
                  handleModifyDiligenceClick={handleModifyDiligenceClick}
                  updateDueDiligenceRecordLoading={updateDueDiligenceRecordLoading}
                  currentRow={currentRow}
                  loading={networkStatus === NetworkStatus.setVariables || networkStatus === NetworkStatus.loading}
                  {...item}
                />
              ))}
            </TiersWrapper>
          )}
        </PaddingWrap>
      </MainWrap>
    </>
  );
};

const CustomLoader = styled(Loader)`
  height: 100%;
  align-items: center;
`;

const Tabs = styled.div`
  padding-top: 21px;
  display: flex;
  gap: 5px;
`;

const TabsLine = styled.div`
  border-bottom: 1px ${({ theme }) => theme.border.base} solid;
  margin-left: -50px;
  margin-right: -50px;
`;

const BurgerMenuWrapper = styled.div`
  margin-top: 15px;
  position: relative;
`;

const ActiveTabWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 15px;
`;

const ActiveTab = styled(TabsTitle)`
  width: min-content;
  margin: 0;
`;

const BurgerMenu = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: absolute;
  top: 50px;
  z-index: 10;
  background: ${({ theme }) => theme.layer[1]};
  width: 30%;
`;

const MenuItem = styled(TabsTitle)`
  margin: 0;
  border-radius: 0;
`;

const SearchBoxWrapper = styled.div`
  background: ${({ theme }) => theme.layer[1]};
  padding: 8px 20px;
  border-radius: 8px;
  width: 290px;
`;

const FilterBoxWrapper = styled.div<{ isMobile?: boolean }>`
  display: flex;
  justify-content: ${({ isMobile }) => (isMobile ? 'center' : 'flex-end')};
  flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};
  align-items: center;
  gap: ${({ isMobile }) => (isMobile ? '10px' : '20px')};
  padding: 78px 0 10px 0;
`;

const TiersWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-bottom: 100px;
`;
