import styled, { useTheme } from 'styled-components';

import { format } from 'date-fns';

import { TickIcon } from '@/assets/icons/info-icons/tick';
import BasicTooltip from '@/components/basicComponents/tooltip';
import { Status, StatusIconWrap } from './index';

export const CompletedStatusRow = ({ status, date, isMobile = false }: { status: string; date: Date | null; isMobile?: boolean }) => {
  const theme = useTheme();

  if (isMobile) {
    return (
      <BasicTooltip tooltipContent={status} shouldShowContent={isMobile}>
        <Row>
          <StatusIconWrap>
            <TickIcon width={18} height={18} fill={theme.context.success} />
          </StatusIconWrap>
          <Status isMobile={isMobile} color={theme.context.success}>
            {status}
          </Status>
          {date && <DateBlock>{format(new Date(date), 'LLL d, yyyy')}</DateBlock>}
        </Row>
      </BasicTooltip>
    );
  }

  return (
    <Row>
      <StatusIconWrap>
        <TickIcon width={18} height={18} fill={theme.context.success} />
      </StatusIconWrap>
      <Status isMobile={isMobile} color={theme.context.success}>
        {status}
      </Status>
      {date && <DateBlock>{format(new Date(date), 'LLL d, yyyy')}</DateBlock>}
    </Row>
  );
};

const Row = styled.div`
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  background: ${({ theme }) => theme.layer[1]};
  border-radius: 4px;
  padding: 7px 12px;
`;

const DateBlock = styled.span`
  color: ${({ theme }) => theme.font.base};
  font-family: 'Blinker';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  padding-left: 20px;
  margin-left: auto;
`;
