import React from 'react';
import styled from 'styled-components';

interface ButtonProps {
  children: React.ReactNode;
  onClick?: () => void;
  className?: string;
  disabled?: boolean;
  type?: 'button' | 'reset' | 'submit';
  id?: string;
  bgColor?: string;
}

const Button = ({
  children,
  onClick,
  disabled = false,
  type = 'button',
  className,
  id,
  bgColor = '#4587ec'
}: ButtonProps) => {
  return (
    <ButtonWrapper
      className={className}
      id={id}
      type={type}
      onClick={onClick}
      disabled={disabled}
      bgcolor={bgColor}
    >
      {children}
    </ButtonWrapper>
  );
};

const ButtonWrapper = styled.button<{ disabled: boolean; bgcolor: string }>`
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  background: ${({ disabled, bgcolor }) => (disabled ? '#dedede' : bgcolor)};
  color: ${({ disabled }) => (disabled ? '#ABABAB' : '#fff')};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 30px;
  font-size: 16px;
  font-weight: 600;
  border-radius: 4px;
  outline: none;
  border: none;
  text-transform: capitalize;
`;

export default Button;
