import FilterSelect from '@/components/basicComponents/select';
import ModalWrappedContent from '@/components/fat-basicComponents/modal/modalWrappedContent';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { ITenant } from '../types';

interface AddNewInvestorEntityProps {
  onClose: () => void;
  isOpen: boolean;
  advisoryFirmList: ITenant[];
}

const AddNewInvestorEntity = ({ onClose, isOpen, advisoryFirmList }: AddNewInvestorEntityProps) => {
  const navigate = useNavigate();

  const [selectListData, setSelectListData] = useState<string[]>([]);
  const [selectedAdvisoryFirm, setSelectedAdvisoryFirm] = useState('Select');

  useEffect(() => {
    setSelectListData([...new Set(advisoryFirmList.map((el) => el.name))].sort((a: string, b: string) => a.localeCompare(b)));
  }, [advisoryFirmList]);

  const saveValue = () => {
    const selectedAdvisoryFirmObject = advisoryFirmList.find((el) => el.name === selectedAdvisoryFirm);
    navigate(`create-investor?tenantId=${selectedAdvisoryFirmObject?.id}&tenantName=${selectedAdvisoryFirmObject?.name}&backToTitle=Investors`);
  };

  return (
    <ModalWrappedContent
      isOpen={isOpen}
      onClose={onClose}
      title="Add New Investor Entity"
      confirmButton={{
        name: 'Confirm',
        disabled: selectedAdvisoryFirm === 'Select',
        onClick: saveValue
      }}
    >
      <ModalBody>
        <ModalDescription>Select an Advisory Firm</ModalDescription>
        <FilterSelect
          selected={selectedAdvisoryFirm}
          setSelected={setSelectedAdvisoryFirm}
          data={selectListData}
          label="Advisory Firm"
          required
          width="100%"
          fontSize="16px"
          minHeight="43px"
        />
      </ModalBody>
    </ModalWrappedContent>
  );
};

export default AddNewInvestorEntity;

const ModalBody = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  gap: 25px;
  text-align: center;
`;

const ModalDescription = styled.div`
  font-size: 16px;
  font-style: normal;
  color: ${({ theme }) => theme.font.base};
`;
