import { useQuery } from '@apollo/client';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';

import { useGoBack } from '@/hooks/useGoBack';
import { useResponsive } from '../../hooks/use-responsive';
import { useClickOutside } from '../../hooks/useClickOutside';
import { MainWrap, PaddingWrap } from '../../styles/common';
import guid from '../../utils/guid';
import FilterSelect from '../basicComponents/select';
import SpecialControlHeader from '../fat-dealCatalog-page/fat-switcher/specialControlHeader';
import Header from '../fat-header';
import { GoBackButton } from '../fat-header/goBackButton';
import { PageTitle } from '../fat-header/pageTitle';
import MobileModal from '../mobileModal';
import { initialValuesFromTable } from '../table/constants';
import { ISort, SORT_ORDER } from '../table/types';
import { clientDetailsSort } from './constants';
import Details from './details';
import { MobileInfoList } from './mobileInfoList';
import { MobileNotes } from './mobileNotes';
import Notes from './notes';
import { CLIENT_DETAILS_QUERY } from './queries';
import Table from './table/table';
import { IClientDetails, IClientEntitiesWithGuid, INotesData } from './types';

export type EntityListFilterType = {
  id: string;
  name: string;
  grouped: boolean;
};

const ClientDetails = () => {
  const navigate = useNavigate();
  const params = useParams();
  const goBack = useGoBack();

  const [searchParams, setSearchParams] = useSearchParams();

  const switcher = searchParams.get('switcher') === null || searchParams.get('switcher') === 'true';
  const key = searchParams.get('key') || 'entityName';
  const asc = searchParams.get('asc') === null || searchParams.get('asc') === 'true';
  const limit = searchParams.get('limit') || initialValuesFromTable.initialAmountRowsPerPage[0];
  const offset = searchParams.get('offset') || 0;
  const position = searchParams.get('position') || 0;
  const backToTitle = searchParams.get('backToTitle') || '';

  const { isMobile, isTablet } = useResponsive();
  const ref = useRef<HTMLDivElement>(null);
  const [isShowDetails, setIsShowDetails] = useState(true);
  const [isNotesOpen, setIsNotesOpen] = useState(false);
  const [rows, setRows] = useState<IClientEntitiesWithGuid[]>();
  const [entityList, setEntityList] = useState<EntityListFilterType[]>([]);
  const [modalTitle, setModalTitle] = useState<string>();
  const [mobileSort, setMobileSort] = useState(clientDetailsSort[0].title);
  const sortItem = clientDetailsSort.find((item) => item.title === mobileSort);
  const mobileSortList = clientDetailsSort.map((item) => item.title);

  const [isModalOpen, setIsOpen] = useState(false);
  const [modalData, setModalData] = useState<IClientEntitiesWithGuid>();
  const [isModalNotesOpen, setIsModalNotesOpen] = useState(false);
  const [modalNotesData, setModalNotesData] = useState<INotesData[]>();

  useClickOutside(ref, () => setIsNotesOpen(false));

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const onRowClick = (data: IClientEntitiesWithGuid) => {
    setModalData(data);
    setIsOpen(true);
  };

  const onNotesClick = (data: INotesData[] | undefined) => {
    setModalNotesData(data);
    setIsModalNotesOpen(true);
  };

  const handleClickClient = (id: string, name: string) => {
    saveScrollPostion();
    navigate(`${id}`);
  };

  const { data, loading, refetch } = useQuery<IClientDetails>(CLIENT_DETAILS_QUERY, {
    notifyOnNetworkStatusChange: true,
    variables: {
      includeRecommendation: switcher,
      id: params?.clientId,
      sort: key,
      order: asc ? SORT_ORDER.ASC : SORT_ORDER.DESC,
      limit: Number(limit),
      offset: Number(offset)
    },
    onCompleted: (data) => {
      const rowsWithId = data.ClientEntity.clientEntities.map((el) => {
        if (el.entities !== null && el.entities.length > 0) {
          const newGroupedEntities = el.entities.map((el) => {
            return {
              guid: guid(),
              id: el.id,
              capacity: el.capacity,
              entityName: el.entityName,
              moreInfo: el.moreInfo,
              overCommitRatio: el.overCommitRatio,
              privatePercentage: el.privatePercentage,
              totalEntityValue: el.totalEntityValue
            };
          });
          return {
            guid: guid(),
            id: el.id,
            entityName: el.entityName,
            totalEntityValue: el.totalEntityValue,
            privatePercentage: el.privatePercentage,
            overCommitRatio: el.overCommitRatio,
            capacity: el.capacity,
            moreInfo: el.moreInfo,
            entities: newGroupedEntities
          };
        }
        return {
          guid: guid(),
          id: el.id,
          entityName: el.entityName,
          totalEntityValue: el.totalEntityValue,
          privatePercentage: el.privatePercentage,
          overCommitRatio: el.overCommitRatio,
          capacity: el.capacity,
          moreInfo: el.moreInfo,
          entities: el.entities
        };
      });
      setRows(rowsWithId as IClientEntitiesWithGuid[]);
      const entityListFilter: EntityListFilterType[] = [];
      rowsWithId.forEach((el) => {
        if (el.entities?.length) {
          entityListFilter.push({
            id: el.id,
            name: el.entityName,
            grouped: false
          });
          el.entities.forEach((el) => {
            entityListFilter.push({
              id: el.id,
              name: el.entityName,
              grouped: true
            });
          });
        } else {
          entityListFilter.push({
            id: el.id,
            name: el.entityName,
            grouped: false
          });
        }
      });
      setEntityList(entityListFilter);
      makeScroll(position);
    }
  });

  const modalTitleMemo = useMemo(() => {
    return data ? data?.ClientEntity.name : modalTitle;
  }, [data]);

  useEffect(() => {
    if (data) {
      setModalTitle(data?.ClientEntity.name);
    }
  }, [data]);

  useEffect(() => {
    refetch({
      order: sortItem?.asc ? SORT_ORDER.ASC : SORT_ORDER.DESC,
      sort: sortItem?.key
    });
  }, [switcher, mobileSort]);

  const toggleNotes = () => {
    setIsNotesOpen(!isNotesOpen);
    const html = document.querySelector('html');
    if (html) {
      html.style.overflowY = isNotesOpen ? 'visible' : 'hidden';
    }
  };

  const clickOutside = (event: any) => {
    if (event.target.id !== 'notes' && event.target.id !== 'openNotes' && isNotesOpen) {
      toggleNotes();
    }
  };

  const onChangeSort = (value: ISort) => {
    setSearchParams(
      (prev) => {
        prev.set('key', value.key);
        prev.set('asc', value.asc.toString());
        return prev;
      },
      { replace: true }
    );
  };

  const toggleSwitcher = () => {
    setSearchParams(
      (prev) => {
        prev.set('switcher', Boolean(!switcher).toString());
        return prev;
      },
      { replace: true }
    );
    saveScrollPostion();
  };

  const saveScrollPostion = () => {
    setSearchParams(
      (prev) => {
        prev.set('position', window.scrollY.toString());
        return prev;
      },
      { replace: true }
    );
  };

  const makeScroll = (position: string | number) => {
    if (!position) return;
    setTimeout(() => window.scrollTo({ top: Number(position), behavior: 'smooth' }), 0);
    setSearchParams(
      (prev) => {
        prev.delete('position');
        return prev;
      },
      { replace: true }
    );
  };

  const onChangePaginationData = (limit: number, offset: number) => {
    setSearchParams(
      (prev) => {
        prev.set('limit', limit.toString());
        prev.set('offset', offset.toString());
        return prev;
      },
      { replace: true }
    );
  };

  return (
    <ClientDetailsWrap onClick={clickOutside}>
      {isModalOpen && modalData ? (
        <MobileModal
          onClose={() => setIsOpen(false)}
          title={modalData.entityName}
          onClick={() => handleClickClient(modalData.id, modalData.entityName)}
        >
          <MobileInfoList onRedirect={() => handleClickClient(modalData.id, modalData.entityName)} data={modalData} />
        </MobileModal>
      ) : (
        <></>
      )}

      {isModalNotesOpen && modalNotesData ? (
        <MobileModal onClose={() => setIsModalNotesOpen(false)} title="Notes" onClick={() => null}>
          <MobileNotes data={modalNotesData} />
        </MobileModal>
      ) : (
        <></>
      )}
      <MainWrap>
        <Header
          modalControl={<GoBackButton handleClose={() => goBack({ fallBack: '/clients' })} backToTitle={backToTitle} />}
          specialControl={<SpecialControlHeader switcher={switcher} setSwitcher={toggleSwitcher} title="Include Recommendations" />}
        />
        <PageTitle title={modalTitleMemo} />
        <Notes data={data?.ClientEntity.notes} onClick={toggleNotes} isOpen={isNotesOpen} />
        <Details
          onNotesClick={onNotesClick}
          isMobile={isMobile}
          data={data?.ClientEntity}
          isOpen={isShowDetails}
          setOpen={() => setIsShowDetails(!isShowDetails)}
          setIsNotesOpen={toggleNotes}
        />
      </MainWrap>
      <MainWrap>
        <PaddingWrap>
          {isMobile && (
            <SortSelectWrapper>
              <FilterSelect
                isMobile
                label={'Sort By'}
                paddingContainer={isShowDetails ? '20px 20px 0 20px' : '0 20px 0 20px'}
                width={'100%'}
                data={mobileSortList}
                selected={mobileSort}
                setSelected={setMobileSort}
              />
            </SortSelectWrapper>
          )}
          <Table
            onRowClick={onRowClick}
            handleClickClient={handleClickClient}
            paginationSaver={onChangePaginationData}
            paginationValues={{
              limit: Number(limit),
              offset: Number(offset),
              total: data?.ClientEntity.clientEntities.length as number
            }}
            refetch={refetch}
            loading={loading}
            clientsRows={rows}
            savedSort={{ key, asc }}
            savedSetSort={onChangeSort}
          />
        </PaddingWrap>
      </MainWrap>
      {/* <Notes data={data?.ClientEntity.notes} onClick={() => setIsNotesOpen(!isNotesOpen)} isOpen={isNotesOpen} />*/}
    </ClientDetailsWrap>
  );
};

export const ModalPageTitle = styled.div<{ isMobile: boolean; isTablet: boolean }>`
  display: flex;
  align-items: center;
  gap: 10px;
  font-family: Blinker, serif;
  font-size: ${({ isMobile, isTablet }) => (isTablet ? '24px' : isMobile ? '20px' : '33px')};
  font-weight: 700;
  line-height: ${({ isMobile, isTablet }) => (isTablet ? '28px' : isMobile ? '28px' : '46px')};
`;

export const ModalControlWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;
`;

const ClientDetailsWrap = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 20;
  //overflow: hidden;
`;

export const CloseBtn = styled.div`
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  min-width: 50px;
  background: #fafafa;
  border-radius: 50%;
`;

const SortSelectWrapper = styled.div`
  position: sticky;
  top: 50px;
  z-index: 8;
  background-color: ${({ theme }) => theme.layer.base};
  margin-top: 0;
  padding-bottom: 10px;
`;

export default ClientDetails;
