import { useEffect, useState } from 'react';
import styled from 'styled-components';

import { GroupedIcon } from '@/assets/icons/info-icons/groupedIcon';
import { MinusIcon } from '@/assets/icons/info-icons/minusIcon';
import { PlusIcon } from '@/assets/icons/info-icons/plusIcon';
import { AnchorPopover } from '@/components/table/anchor-drop-down/fat-index';
import guid from '../../../utils/guid';
import FormattingTooltip from '../../basicComponents/tooltip/dollarsTooltip';
import { columns } from '../constants';
import { InfoList } from '../infoList';
import { IClientEntitiesWithGuid } from '../types';

interface BodyRowProps {
  clientsRows: IClientEntitiesWithGuid[] | undefined;
  handleClickClient: (value: string, name: string) => void;
}

export const TableBody = ({ clientsRows, handleClickClient }: BodyRowProps) => {
  const [rows, setRows] = useState<IClientEntitiesWithGuid[] | undefined>(clientsRows);
  const [openedGroup, setOpenedGroup] = useState<string[]>([]);

  useEffect(() => {
    setRows(clientsRows);
  }, [clientsRows]);

  const handleClickGroup = (index: number) => {
    if (clientsRows && rows) {
      const newOpenedGroup = [...openedGroup];
      const isExist = !!newOpenedGroup.find((el) => el === clientsRows[index].guid);

      if (isExist) {
        const allGroupedId = clientsRows[index].entities?.map((el) => el.guid);
        const newRows = [...rows];
        const filteredRows = newRows.filter((el) => !allGroupedId?.includes(el.guid));
        setRows(filteredRows);
        const filteredOpened = newOpenedGroup.filter((el) => el !== clientsRows[index].guid);
        setOpenedGroup(filteredOpened);
      } else {
        const newRows = [...clientsRows];
        const groupedList = clientsRows[index].entities?.map((el) => {
          return {
            guid: el.guid,
            id: el.id,
            capacity: el.capacity,
            entityName: el.entityName,
            overCommitRatio: el.overCommitRatio,
            privatePercentage: el.privatePercentage,
            totalEntityValue: el.totalEntityValue,
            moreInfo: el.moreInfo,
            isGrouped: true
          };
        });
        groupedList?.map((el) => {
          newRows.splice(index + 1, 0, el);
        });
        setRows(newRows);
        newOpenedGroup.push(clientsRows[index].guid);
        setOpenedGroup(newOpenedGroup);
      }
    }
  };

  return (
    <TableWrapper>
      {rows?.map((row, index) => (
        <RowWrap key={row.guid}>
          {row.isGrouped && <GroupedIcon />}
          <TableRowWrap isGrouped={!!row.isGrouped} key={guid()}>
            <TableRowItem isGrouped={!!row.isGrouped} width={columns[0].width}>
              <PlusBtn
                isActive={!!openedGroup.find((el) => el === row.guid)}
                onClick={() => handleClickGroup(index)}
                isVisible={!!row.entities?.length}
              >
                {openedGroup.find((el) => el === row.guid) ? <MinusIcon /> : <PlusIcon />}
              </PlusBtn>
              <EntityTitle onClick={() => handleClickClient(row.id, row.entityName)}>{row.entityName}</EntityTitle>
            </TableRowItem>
            <TableRowItem width={columns[1].width}>
              <BasicTitle isBold={!!row.entities?.length}>
                <FormattingTooltip zIndex={1000}>{+row.totalEntityValue}</FormattingTooltip>
              </BasicTitle>
            </TableRowItem>
            <TableRowItem width={columns[2].width}>
              <BasicTitle isBold={!!row.entities?.length}>{row.privatePercentage.toFixed(2)}%</BasicTitle>
            </TableRowItem>
            <TableRowItem width={columns[3].width}>
              <BasicTitle isBold={!!row.entities?.length}>{row.overCommitRatio.toFixed(2)}</BasicTitle>
            </TableRowItem>
            <TableRowItem width={columns[4].width}>
              <BasicTitle isBold={!!row.entities?.length}>
                <ClientEntityWrap>
                  <AnchorPopover
                    title={row.entityName}
                    showLeft
                    hyperTitle={() => handleClickClient(row.id, row.entityName)}
                    rowBody={
                      <EntityTitle>
                        <FormattingTooltip zIndex={11}>{+row.capacity}</FormattingTooltip>
                      </EntityTitle>
                    }
                    onRedirect={() => handleClickClient(row.id, row.entityName)}
                  >
                    <InfoList onRedirect={() => handleClickClient(row.id, row.entityName)} title={row.entityName} data={row.moreInfo} />
                  </AnchorPopover>
                </ClientEntityWrap>
              </BasicTitle>
            </TableRowItem>
          </TableRowWrap>
        </RowWrap>
      ))}
    </TableWrapper>
  );
};

const EntityTitle = styled.div`
  cursor: pointer;
  font-family: 'Blinker', serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  color: ${({ theme }) => theme.font.base};
`;

const RowWrap = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  gap: 4px;

  &:hover ${EntityTitle} {
    transition: color 0.1s ease;
    color: ${({ theme }) => theme.font.action};
  }
`;

const PlusBtn = styled.div<{ isVisible: boolean; isActive: boolean }>`
  display: flex;
  cursor: ${({ isVisible }) => (isVisible ? 'pointer' : 'default')};
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  min-width: 24px;
  background: ${({ theme, isActive }) => (isActive ? theme.action.active : theme.layer.base)};
  border-radius: 50%;
  margin-right: 6px;
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};

  &:hover {
    background: ${({ theme }) => theme.action.hover};

    path {
      fill: ${({ theme }) => theme.context.light};
    }
  }
`;

const ClientEntityWrap = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  padding-right: 25px;
`;

const TableWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 6px;
`;

const BasicTitle = styled.div<{ isBold: boolean }>`
  font-family: 'Blinker', serif;
  font-size: 16px;
  font-weight: ${({ isBold }) => (isBold ? 700 : 400)};
  line-height: 22px;
  letter-spacing: 0;
  text-align: left;
  color: ${({ theme }) => theme.font.base};
`;
const TableRowWrap = styled.div<{ isGrouped?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: flex-end;
  width: ${({ isGrouped }) => (isGrouped ? 'calc(100% - 60px)' : '100%')};
  min-height: 60px;
  background-color: ${({ theme }) => theme.layer[1]};
  border-radius: 8px;
  padding: 0 24px;

  @media (max-width: 1100px) {
    width: ${({ isGrouped }) => (isGrouped ? 'calc(100% - 20px)' : '100%')};
  }

  &:hover #infoWrap {
    opacity: 1;
  }

  &:hover #tempInfoWrap {
    display: none;
  }

  &:hover {
    background-color: ${({ theme }) => theme.layer[12]};
    transition: background-color 100ms ease;
  }
`;

const TableRowItem = styled.div<{ width: number; isGrouped?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: ${({ width, isGrouped }) => (isGrouped ? `calc(${width}% - 60px)` : `${width}%`)};
  @media (max-width: 1100px) {
    width: ${({ width, isGrouped }) => (isGrouped ? `calc(${width}% - 20px)` : `${width}%`)};
  }
`;
