import { format } from 'date-fns';
import React from 'react';
import {
  MobileRow,
  MobileRowTitle,
  MobileRowValue,
  MobileTableRowWrap,
  NoResults
} from '@/components/table/mobileTable/mobileTable';
import styled from 'styled-components';

import { IInfoIcon } from '@/assets/icons/info-icons/i-info';
import { IAllocationRow } from '../../../../../types/opportunity/pending-allocation';
import guid from '../../../../../utils/guid';
import { PreAllocationStatusType } from '../../../../allocations-page/types';
import { FilterTitle } from '../../../../basicComponents/select';
import BasicTooltip from '../../../../basicComponents/tooltip';
import FormattingTooltip from '../../../../basicComponents/tooltip/dollarsTooltip';
import Suitability from '../../../../client-entity-details/tableAllocations/suitability';
import ClientsAdvisor from '../../../../recommendations-page/cell/clientsAdvisor';
import {
  FieldsForRedirectWindows,
  TypeWindow
} from '../../../../recommendations-page/types';
import { TableColumns } from '../../../../table/types';
import { SuitabilityWrapper } from '../../suitabilityDetails';

type MobileTableProps = {
  columns: TableColumns[];
  potentialAllocations: IAllocationRow[];
  detailsClick: (type: TypeWindow, id: FieldsForRedirectWindows) => void;
  openMobileModal: (data: IAllocationRow, modalType: string) => void;
  refetch: any;
  currentTab: string;
};

const MobileTable = ({
  columns,
  potentialAllocations,
  detailsClick,
  openMobileModal,
  currentTab,
  refetch
}: MobileTableProps) => {
  const TableRow = ({ el }: { el: IAllocationRow }) => {
    const getPreAllocationStatus = (value: PreAllocationStatusType) => {
      if (value?.alreadyCommitted) {
        return `Committed,  ${
          value.committedAt
            ? format(new Date(value.committedAt), 'LLL d, yyyy').toUpperCase()
            : ''
        }`;
      } else if (value?.internalReviewRejections) {
        return `Internal Review Rejected,  ${
          value.internalReviewRejectionAt
            ? format(
                new Date(value.internalReviewRejectionAt),
                'LLL d, yyyy'
              ).toUpperCase()
            : ''
        }`;
      } else if (value?.terminated) {
        return `Terminated,  ${
          value.terminatedAt
            ? format(new Date(value.terminatedAt), 'LLL d, yyyy').toUpperCase()
            : ''
        }`;
      } else {
        return '';
      }
    };

    return (
      <MobileTableRowWrap key={guid()}>
        <MobileRow>
          <MobileRowTitle>{columns[0].title}</MobileRowTitle>
          <EntityMobileRowValue
            isRedirect
            onClick={() => openMobileModal(el, 'entity')}
          >
            {el.legalEntity?.entityName}
            {el.legalEntity.isGroupEntity &&
              el.selectedGroupLegalEntity?.entityName && (
                <GroupEntity>
                  {el.selectedGroupLegalEntity?.entityName ||
                    el.legalEntity?.entities[0].entityName}
                </GroupEntity>
              )}
          </EntityMobileRowValue>
        </MobileRow>

        <MobileRow>
          <MobileRowTitle>{columns[1].title}</MobileRowTitle>
          <MobileRowValue>
            <ClientsAdvisor
              onRedirect={() =>
                detailsClick('details', {
                  familyName: el.legalEntity.family.name
                })
              }
              familyName={el.legalEntity?.family.name}
              advisor={el.legalEntity?.advisor?.initials}
            />
          </MobileRowValue>
        </MobileRow>

        <MobileRow>
          <MobileRowTitle>{columns[2].title}</MobileRowTitle>
          <MobileRowValue>
            <FormattingTooltip zIndex={1000}>
              {+el.legalEntity?.overallCapacity}
            </FormattingTooltip>
          </MobileRowValue>
        </MobileRow>

        <MobileRow>
          <MobileRowTitle>{columns[3].title}</MobileRowTitle>
          <MobileRowValue>
            <FormattingTooltip zIndex={1000}>
              {+el.legalEntity?.assetClassCapacity}
            </FormattingTooltip>
          </MobileRowValue>
        </MobileRow>

        <MobileRow>
          <MobileRowTitle>{columns[4].title}</MobileRowTitle>
          <MobileRowValue onClick={() => openMobileModal(el, 'suitability')}>
            <SuitabilityWrapper>
              <Suitability suitability={el.suitability} width="100" />
            </SuitabilityWrapper>
          </MobileRowValue>
        </MobileRow>

        <MobileRow>
          <MobileRowTitle>{columns[5].title}</MobileRowTitle>
          <AmountMobileRowValue>
            {el.committedCapital !== el.legalEntity.fundBiteSize && (
              <BasicTooltip tooltipContent={'Differs from default bite size'}>
                <IInfoIcon
                  style={{ display: 'flex', alignItems: 'center' }}
                  fill={'#EF9208'}
                />
              </BasicTooltip>
            )}
            <FormattingTooltip zIndex={1000}>
              {+el.committedCapital}
            </FormattingTooltip>
          </AmountMobileRowValue>
        </MobileRow>

        <MobileRow>
          <MobileRowTitle>{columns[6].title}</MobileRowTitle>
          <MobileRowValue>{el.decisionReason}</MobileRowValue>
        </MobileRow>

        {currentTab === 'pending' && el.preAllocationStatus && (
          <MobileRow>
            <MobileRowTitle>History</MobileRowTitle>
            <MobileRowValue>
              {getPreAllocationStatus(el.preAllocationStatus)}
            </MobileRowValue>
          </MobileRow>
        )}
      </MobileTableRowWrap>
    );
  };

  return (
    <>
      {potentialAllocations.length ? (
        potentialAllocations.map((el, index) => {
          return (
            <>
              <TableRow el={el} key={el.legalEntity?.id + index} />
              {/* <TablePagination paginationValues={paginationValues} refetch={refetch} />*/}
            </>
          );
        })
      ) : (
        <NoResults>0 Results</NoResults>
      )}
    </>
  );
};

export default MobileTable;

const EntityMobileRowValue = styled(MobileRowValue)`
  flex-direction: column;
`;

const GroupEntity = styled(FilterTitle)`
  color: #ef9208;
`;

const AmountMobileRowValue = styled(MobileRowValue)`
  gap: 5px;
`;
