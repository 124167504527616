import * as React from 'react';

export const CopyIcon = ({ ...props }: React.SVGProps<SVGSVGElement>) => (
  <svg width="23" height="24" onClick={props.onClick || undefined} viewBox="0 0 23 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18.83 7.67V19.335H7.16501V7.67H18.83ZM18.83 6H7.16501C6.72296 6.00132 6.29947 6.17785 5.98737 6.49089C5.67526 6.80393 5.5 7.22795 5.5 7.67V19.335C5.50132 19.7762 5.67717 20.1989 5.98914 20.5109C6.3011 20.8228 6.72383 20.9987 7.16501 21H18.83C19.272 21 19.6961 20.8247 20.0091 20.5126C20.3222 20.2005 20.4987 19.777 20.5 19.335V7.67C20.4987 7.22749 20.3223 6.80349 20.0094 6.49059C19.6965 6.17769 19.2725 6.00132 18.83 6Z"
      fill="#4587EC"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.83 6V4.67H4.16501V16.335H5.5V18H4.16501C3.72383 17.9987 3.3011 17.8228 2.98914 17.5109C2.67717 17.1989 2.50132 16.7762 2.5 16.335V4.67C2.5 4.22795 2.67526 3.80393 2.98737 3.49089C3.29947 3.17785 3.72296 3.00132 4.16501 3H15.83C16.2725 3.00132 16.6965 3.17769 17.0094 3.49059C17.3223 3.80349 17.4987 4.22749 17.5 4.67V6H15.83Z"
      fill="#4587EC"
    />
  </svg>
);
