import { AiAssistantIcon } from '@/assets/static/icons/ai-assistant';
import Textarea from '@/components/basicComponents/textarea';
import { ChangeEvent, useEffect, useState } from 'react';
import { useTheme } from 'styled-components';
import { ISectionFields } from '../types';
import { AiIconWrap, Answer, AnswerWrap, AutoGeneratedField, CommentaryWrap, FieldInput, FieldName } from './dollarContent';
import { EditWrapper } from './editWrapper';

interface PercentageProps {
  handleClose: () => void;
  row: ISectionFields | null;
  updateFieldAnswer: (variables: any) => void;
  investmentId: string | null;
}

export function extractPercentage(input: string): string {
  if (!input) return '';
  const percentageRegex = /(\d+(\.\d+)?)%/;

  const match = input.match(percentageRegex);

  if (match && match[1]) {
    return match[1];
  } else {
    return '';
  }
}

export const PercentagePage = ({ handleClose, row, updateFieldAnswer, investmentId }: PercentageProps) => {
  const theme = useTheme();

  const [percentageValue, setPercentageValue] = useState({
    fieldValue: '',
    commentary: ''
  });
  const [isEditMode, setIsEditMode] = useState(true);

  useEffect(() => {
    if (!row) return;

    if (row?.data.value !== null || row?.data.commentary !== null) {
      setIsEditMode(true);
      setPercentageValue({
        ...percentageValue,
        fieldValue: row?.data.value || percentageValue.fieldValue,
        commentary: row?.data.commentary || percentageValue.commentary
      });
      return;
    }
    if (row.AI?.answers[0]) {
      setIsEditMode(false);
      setPercentageValue({
        ...percentageValue,
        ...(extractPercentage(row.AI?.answers[0]) && {
          fieldValue: `${extractPercentage(row.AI?.answers[0])}`
        }),
        commentary: row.AI.answers[0]
      });
      return;
    }
    return () => {
      setPercentageValue({ fieldValue: '', commentary: '' });
    };
  }, [row]);

  const saveValue = () => {
    updateFieldAnswer({
      variables: {
        data: {
          id: row.data.id,
          value: isEditMode ? percentageValue.fieldValue : null,
          commentary: isEditMode ? percentageValue.commentary : null
        }
      },
      onCompleted: () => {
        setPercentageValue({ fieldValue: '', commentary: '' });
        handleClose();
      }
    });
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    const isValidInput = /^(0*([0-9]*(\.[0-9]*)?|\.[0-9]+))$/.test(inputValue);

    if (isValidInput || inputValue === '') {
      setPercentageValue({ ...percentageValue, fieldValue: inputValue });
    }
  };

  const editSuggested = (suggestion: string) => {
    setPercentageValue({
      fieldValue: extractPercentage(suggestion),
      commentary: suggestion
    });
    setIsEditMode(true);
  };

  const clearSuggestion = () => {
    setPercentageValue({ fieldValue: '', commentary: '' });
    setIsEditMode(true);
  };

  const startWithAISuggestion = () => {
    setPercentageValue({
      fieldValue: `${extractPercentage(row.AI?.answers[0])}` || '',
      commentary: row.AI?.answers[0]
    });
    setIsEditMode(false);
  };

  const revertPriorDraft = () => {
    if (row?.data.value || row?.data.commentary) {
      setPercentageValue({
        ...percentageValue,
        fieldValue: row?.data.value,
        commentary: row?.data.commentary
      });
      setIsEditMode(true);
    }
  };

  if (!row) return <></>;

  return (
    <EditWrapper
      showAiSuggestion={Boolean(row.AI?.answers.length > 0)}
      isEditMode={isEditMode}
      startWithAISuggestion={startWithAISuggestion}
      editSuggested={editSuggested}
      clearSuggestion={clearSuggestion}
      revertPriorDraft={revertPriorDraft}
      showRevertPriorDraft={
        (Boolean(row?.data.value) && row?.data.value !== percentageValue.fieldValue) ||
        (Boolean(row?.data.commentary) && row?.data.commentary !== percentageValue.commentary)
      }
      handleClose={handleClose}
      row={row}
      investmentId={investmentId}
      confirmButton={{ name: 'Save', onClick: saveValue }}
    >
      <div>
        {isEditMode ? (
          <>
            <FieldName>{row.name}</FieldName>
            <FieldInput
              isBackground={!!percentageValue.fieldValue}
              type={'text'}
              value={percentageValue.fieldValue}
              onChange={handleInputChange}
              autoFocus
            />
          </>
        ) : (
          <>
            <FieldName>{row.name}</FieldName>
            <AutoGeneratedField>
              <AiIconWrap>
                <AiAssistantIcon width={28} height={28} fill={theme.font.weak} />
              </AiIconWrap>
              <div>
                <span style={{ fontWeight: 700 }}>Suggested {row.AI?.citations.length >= 2 && `(${row.AI?.citations.length - 1} more)`}:</span>{' '}
                {percentageValue.fieldValue && `${percentageValue.fieldValue}%`}
              </div>
            </AutoGeneratedField>
          </>
        )}
      </div>
      <CommentaryWrap>
        <FieldName>Commentary</FieldName>
        {isEditMode ? (
          <Textarea
            value={percentageValue.commentary}
            textareaHeight="80px"
            isValidValue={!!percentageValue.commentary}
            maxLength={250}
            setValue={(value) =>
              setPercentageValue({
                ...percentageValue,
                commentary: value
              })
            }
          />
        ) : (
          <AnswerWrap>
            <AiIconWrap>
              <AiAssistantIcon width={28} height={28} fill={theme.font.weak} />
            </AiIconWrap>
            <Answer>
              <span style={{ fontWeight: 700 }}>Suggested {row.AI?.citations.length >= 2 && `(${row.AI?.citations.length - 1} more)`}:</span>{' '}
              {row.AI?.answers[0]}
            </Answer>
          </AnswerWrap>
        )}
      </CommentaryWrap>
    </EditWrapper>
  );
};
