import { gql } from '@apollo/client';

export const INVESTMENT_MANAGERS_QUERY = gql`
  query InvestmentManagers($page: Int, $rowsPerPage: Int, $sort: String, $order: String, $search: String) {
    InvestmentManagers(page: $page, rowsPerPage: $rowsPerPage, sort: $sort, order: $order, search: $search) {
      total
      page
      perPage
      managers {
        name
        assetClasses
        countOfFunds
        countOfInvestors
        committedCapital
        percentOfTotalActiveCommmitments
      }
    }
  }
`;

export const INVESTMENTS_QUERY = gql`
  query ManagerInvestments($managerId: String!, $page: Int, $rowsPerPage: Int, $sort: String, $order: String, $search: String) {
    ManagerInvestments(managerId: $managerId, page: $page, rowsPerPage: $rowsPerPage, sort: $sort, order: $order, search: $search) {
      total
      page
      perPage
      totalInvestors
      totalCommittedCapital
      investments {
        id
        name
        assetClass
        sectors
        vintageYear
        maxCalledPercent
        countOfInvestors
        committedCapital
      }
    }
  }
`;

export const MANAGER_QUERY = gql`
  query InvestmentManager($managerId: String!) {
    InvestmentManager(managerId: $managerId) {
      name
      assetClasses
      sectors
      economicExposure
      totalActiveCommitments
    }
  }
`;

export const ENTITIES_QUERY = gql`
  query InvestmentEntities($id: String!, $sort: String, $order: String, $search: String) {
    InvestmentEntities(id: $id, sort: $sort, order: $order, search: $search) {
      id
      name
      family
      committedCapital
      capitalCalled
      percentOfCapitalCalled
      IRR
      TVPI
    }
  }
`;

export const INVESTMENT_QUERY = gql`
  query Investment($id: String!) {
    Investment(id: $id) {
      id
      symbol
      name
      type
      managerId
      assetClass
      sectors
      averageCalledPercent
      countOfInvestors
      committedCapital
      economicExposure
      targetReturn
      targetYield
      vintageYear
      nextClose
      finalClose
      minimum
      sri
      subAssetClasses
      tags
      description
      custodied
      ubtiBlocker
      securityStatus
      investmentOwner {
        firstName
        lastName
        email
      }
      operationsOwner {
        firstName
        lastName
        email
      }
      complianceOwner {
        firstName
        lastName
        email
      }
    }
  }
`;
