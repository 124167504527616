import {
  MobileRow,
  MobileRowTitle,
  MobileRowValue,
  MobileTableRowWrap,
  MobileTableWrapper,
  NoResults
} from '@/components/table/mobileTable/mobileTable';
import styled from 'styled-components';

import FormattingTooltip from '@/components/basicComponents/tooltip/dollarsTooltip';
import { TextCell } from '@/components/table/styles';
import { TableColumns } from '@/components/table/types';
import guid from '@/utils/guid';
import { IHandleEntityClick, IRowEntityData } from '../../types';

type MobileTableProps = {
  columns: TableColumns[];
  clientEntityRows: IRowEntityData[];
  handleEntityClick: ({ familyId, entityId, entityName }: IHandleEntityClick) => void;
  handleFamilyClick: (id: string) => void;
};

const MobileTableEntity = ({ columns, clientEntityRows, handleEntityClick, handleFamilyClick }: MobileTableProps) => {
  const TableRow = ({ el }: { el: IRowEntityData }) => {
    return (
      <MobileTableRowWrap key={guid()}>
        <MobileRow>
          <MobileRowTitle>{columns[0].title}</MobileRowTitle>
          <MobileRowValue
            onClick={() =>
              handleEntityClick({
                familyId: el.clientName,
                entityId: el.id,
                entityName: el.entityName
              })
            }
            isRedirect
          >
            {el.entityName}
          </MobileRowValue>
        </MobileRow>

        <MobileRow>
          <MobileRowTitle>{columns[1].title}</MobileRowTitle>
          <WrapperClient onClick={() => handleFamilyClick(el.clientName)} isRedirect>
            <ClientText> {el.clientName}</ClientText>
            <TextCell>{el.clientAdvisor?.initials}</TextCell>
          </WrapperClient>
        </MobileRow>

        <MobileRow>
          <MobileRowTitle>{columns[2].title}</MobileRowTitle>
          <MobileRowValue>
            <FormattingTooltip zIndex={1000}>{+el.totalEntityValue}</FormattingTooltip>
          </MobileRowValue>
        </MobileRow>

        <MobileRow>
          <MobileRowTitle>{columns[3].title}</MobileRowTitle>
          <MobileRowValue>
            <FormattingTooltip zIndex={1000}>{+el.privateEquityCapacity}</FormattingTooltip>
          </MobileRowValue>
        </MobileRow>

        <MobileRow>
          <MobileRowTitle>{columns[4].title}</MobileRowTitle>
          <MobileRowValue>
            <FormattingTooltip zIndex={1000}>{+el.privateCreditCapacity}</FormattingTooltip>
          </MobileRowValue>
        </MobileRow>

        <MobileRow>
          <MobileRowTitle>{columns[5].title}</MobileRowTitle>
          <MobileRowValue>
            <FormattingTooltip zIndex={1000}>{+el.privateRealAssetsCapacity}</FormattingTooltip>
          </MobileRowValue>
        </MobileRow>

        <MobileRow>
          <MobileRowTitle>{columns[6].title}</MobileRowTitle>
          <MobileRowValue>
            <FormattingTooltip zIndex={1000}>{+el.overallCapacity}</FormattingTooltip>
          </MobileRowValue>
        </MobileRow>
      </MobileTableRowWrap>
    );
  };

  return (
    <MobileTableWrapper>
      {clientEntityRows.length ? (
        clientEntityRows.map((el) => {
          return <TableRow el={el} key={guid()} />;
        })
      ) : (
        <NoResults>No Results</NoResults>
      )}
    </MobileTableWrapper>
  );
};

export default MobileTableEntity;

const WrapperClient = styled(MobileRowValue)`
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

const ClientText = styled.span`
  :hover {
    cursor: pointer;
  }
`;
