import { styled } from 'styled-components';

import { User } from '@/types/user';

interface AvatarProps {
  user: User;
  size: number;
  className?: string;
}

export const LetterAvatars = (props: AvatarProps) => {
  const { firstName, lastName, avatar } = props.user;
  return (
    <>
      {avatar === undefined ? (
        <CustomAvatar className={props.className} size={props.size}>
          {!!(firstName && lastName) && (firstName[0] + lastName[0]).toUpperCase().split(' ').join('')}
        </CustomAvatar>
      ) : (
        <Avatar className={props.className} size={props.size} src={avatar} alt={'Ava'} />
      )}
    </>
  );
};
const Avatar = styled.img<{ size: number }>`
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  border-radius: 50%;
  object-fit: cover;
`;

const CustomAvatar = styled.div<{ size: number }>`
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  font-weight: 300;
  font-family: Blinker;
  font-style: normal;
  font-size: 23px;
  letter-spacing: 0;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.layer[2]};
`;
