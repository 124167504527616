import { Family, FamilyEntity } from './family';
import { User } from './user';

export enum PROPOSAL_TYPE {
  INCREASE = 'Increase',
  PROPOSAL = 'Proposal',
  SECONDARY = 'Secondary',
  INITIAL = 'Initial'
}

export interface InvestmentManager {
  id: string;
  name: string;
  assetClasses: string[];
  countOfFunds: number;
  countOfInvestors: number;
  committedCapital: number;
  percentOfTotalActiveCommmitments: number;
}

export interface Fund {
  id: string;
}

export interface Investor {
  id: string;
  family: string;
  entity: string;
  overallCapacity: number;
  assetClassCapacity: number;
  suitability: string;
  committedCapital: number;
  status: string;
}

export interface Sector {
  id: string;
  name: string;
}

export interface AssetClass {
  id: string;
  name: string;
}

export interface Commitment {
  id?: string;
  commitmentStatus: string;
  investment: Investment;
  legalEntity: FamilyEntity;
  committedCapital: number;
  capitalCalled: number;
  remainingCapital: number;
  distributionsToDate: number;
  currentValue: number;
}

export class Allocation {
  id?: string;
  allocationStatus?: string;
  averageCalledPercent?: number;
  committedCapital?: number;
  capitalCalled?: number;
  remainingCapital?: number;
  distributionsToDate?: number;
  currentValue?: number;
  status?: string;
  investment!: Investment;
  legalEntity!: FamilyEntity;
  assignee?: any;
  advisorOwner?: User;
  investmentOwner?: User;
  complianceOwner?: User;
  operationsOwner?: User;
  proposalType?: PROPOSAL_TYPE;
}

export interface Investment {
  id: string;
  name: string;
  description: string;
  managerId: string | number | boolean | string[] | Date;
  securityStatus: string;
  returnStatus: string;
  type: string;
  assetClass: string;
  subAssetClasses: string[];
  sectors: string[];
  vintageYear: string;
  targetReturn: number;
  ubtiBlocker: boolean;
  sri: boolean;
  finalClose: Date;
  nextClose: Date;
  investorDocsDue: Date;
  targetIRR: number;
  targetYield: number;
  minimum: number;
  maxCalledPercent?: number;
  countOfInvestors?: number;
  committedCapital?: number;
  economicExposure?: number;
}

export interface InvestmentEntity {
  id: string;
  name: string;
  family: Family;
  committedCapital: number;
  capitalCalled: number;
  percentOfCapitalCalled: number;
  IRR: number;
  TVPI: number;
}

export interface EntityOpportunity {
  investment: {
    id: string;
    symbol: string;
    name: string;
    assetClass: string;
    subAssetClass: string;
    managerId: string;
    custodied: boolean;
    ubtiBlocker: boolean;
    nextClose: string;
    finalClose: string;
    minimum: number;
    sri: boolean;
    targetIRR: number;
    targetYield: number;
    securityStatus: string;
  };
  allocationStatus: string;
  suitability: string;
  status: string;
}
