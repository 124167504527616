import guid from '@/utils/guid';
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import styled, { useTheme } from 'styled-components';

import { NotesIcon } from '@/assets/icons/menu-icons/notes';
import { SortIcon } from '@/assets/icons/sortIcon';
import { BurgerMenuIcon } from '@/assets/static/icons/burger-menu';
import { CloseMenuIcon } from '@/assets/static/icons/close-menu';
import { useResponsive } from '../../hooks/use-responsive';
import { formatCash } from '../../utils/formatCash';
import Tabs, { ITab, TabMobileWrap, TabTitle, TabsControl, TabsLine } from '../basicComponents/fat-tabs';
import FormattingTooltip from '../basicComponents/tooltip/dollarsTooltip';
import { INotesData } from '../client-details/types';
import ProgressBar from './progressBar';
import SimpleTable from './simpleTable';
import { ClientEntityAllocations, ClientEntityCommitments, ClientEntitySummary, ProgressBarValuesType, TabsEnum } from './types';

type DetailsProps = {
  tabs: ITab[];
  currentTab: TabsEnum;
  setCurrentTab: (value: TabsEnum) => void;
  isOpen: boolean;
  setOpen: () => void;
  setIsNotesOpen: () => void;
  data: ClientEntitySummary | ClientEntityCommitments | ClientEntityAllocations | undefined;
  setIsTabMenuOpen: Dispatch<SetStateAction<boolean>>;
  isTabMenuOpen: boolean;
  isGrouped?: boolean;
  onNotesClick: (data: INotesData[] | undefined) => void;
};

const Details = ({
  tabs,
  data,
  isOpen,
  setOpen,
  setIsNotesOpen,
  currentTab,
  setCurrentTab,
  isTabMenuOpen,
  setIsTabMenuOpen,
  isGrouped,
  onNotesClick
}: DetailsProps) => {
  const theme = useTheme();

  const [detailsData, setDetailsData] = useState<ClientEntitySummary | ClientEntityCommitments | ClientEntityAllocations>();
  const { isMobile, isDesktop, isTablet, isLargeDesktop } = useResponsive();
  const detailsDataMemo = useMemo(() => {
    return data
      ? {
          ...data,
          privatePercentage: currentTab === 'Allocations' && isGrouped ? data.privatePercentage * 100 : data.privatePercentage
        }
      : detailsData;
  }, [data]);

  useEffect(() => {
    if (data) {
      setDetailsData(data);
    }
  }, [data]);

  const notesLength = useMemo(() => {
    return detailsDataMemo?.notes.filter((el) => el.title && el.notes).length;
  }, [data]);

  return (
    <DetailsWrap isMobile={isMobile}>
      <CardsContainer isMobile={isMobile} isOpen={isOpen}>
        <Card isMobile={isMobile}>
          <CardTitle>
            <FormattingTooltip zIndex={120}>{detailsDataMemo?.totalEntitiesValue as number}</FormattingTooltip>
          </CardTitle>
          <CardDesc>TOTAL ENTITY VALUE</CardDesc>
        </Card>
        <Card isMobile={isMobile}>
          <CardTitle>{detailsDataMemo?.privatePercentage.toFixed(2)}%</CardTitle>
          <CardDesc>PRIVATE CAPITAL</CardDesc>
        </Card>
        <Card isMobile={isMobile}>
          <CardTitle>{detailsDataMemo?.targetOCRatio.toFixed(2)}x</CardTitle>
          <CardDesc>TARGET OC RATIO</CardDesc>
        </Card>
        <Card isMobile={isMobile}>
          <CardTitle>{detailsDataMemo?.currentOCRatio === -1 ? '-' : `${detailsDataMemo?.currentOCRatio.toFixed(2)}x`}</CardTitle>
          <CardDesc>CURRENT OC RATIO</CardDesc>
        </Card>
        <Card
          isMobile={isMobile}
          id="openNotes"
          onClick={isOpen && notesLength && !isMobile ? setIsNotesOpen : () => null}
          short
          isOpen={isOpen}
          notesLength={notesLength}
        >
          <NotesCounterWrap onClick={isMobile ? () => onNotesClick(data?.notes) : () => null} id="openNotes">
            <NotesIcon id="openNotes" width={24} height={24} fill={theme.action.primary} />
            <NotesCounter id="openNotes">{notesLength}</NotesCounter>
          </NotesCounterWrap>
          <NotesTitle id="openNotes">Notes</NotesTitle>
        </Card>
      </CardsContainer>

      <MoreDetails isMobile={isMobile} isOpen={isOpen}>
        <FlexWrap>
          <MoreDetailItem isMobile={isMobile}>
            <ItemRow>
              <ItemTitle>On Hold</ItemTitle>
              <ItemValue>{detailsDataMemo?.onHold ? 'Yes' : 'No'}</ItemValue>
            </ItemRow>

            <ItemRow>
              <ItemTitle>Always Show</ItemTitle>
              <ItemValue>{detailsDataMemo?.alwaysShow ? 'Yes' : 'No'}</ItemValue>
            </ItemRow>

            <ItemRow>
              <ItemTitle>Impact</ItemTitle>
              <ItemValue>{detailsDataMemo?.impact}</ItemValue>
            </ItemRow>

            {detailsDataMemo?.biteSize && (
              <ItemRow>
                <ItemTitle>Bite Size (fund/direct)</ItemTitle>
                <ItemValue>
                  {detailsDataMemo.biteSize.fundBiteSize && !isNaN(detailsDataMemo.biteSize.fundBiteSize) && detailsDataMemo.biteSize.fundBiteSize > 0
                    ? formatCash(detailsDataMemo.biteSize.fundBiteSize)
                    : '$0'}
                  {' / '}
                  {detailsDataMemo.biteSize.directBiteSize &&
                  !isNaN(detailsDataMemo.biteSize.directBiteSize) &&
                  detailsDataMemo.biteSize.directBiteSize > 0
                    ? formatCash(detailsDataMemo.biteSize.directBiteSize)
                    : '$0'}
                </ItemValue>
              </ItemRow>
            )}
          </MoreDetailItem>

          <MoreDetailItem isMobile={isMobile}>
            <ItemRow>
              <ItemTitle>Custody Requirement</ItemTitle>
              <ItemValue>{detailsDataMemo?.custodied ? 'Yes' : 'No'}</ItemValue>
            </ItemRow>

            <ItemRow>
              <ItemTitle>UBTI Blocker Needed</ItemTitle>
              <ItemValue>{detailsDataMemo?.ubtiBlocker ? 'Yes' : 'No'}</ItemValue>
            </ItemRow>

            <ItemRow>
              <ItemTitle>Sectors To Avoid</ItemTitle>
              <SectorsWrap>
                {detailsDataMemo?.sectorsToAvoid.filter((sector) => sector !== '').length ? (
                  detailsDataMemo?.sectorsToAvoid
                    .filter((x, i, a) => a.indexOf(x) == i)
                    .map((el) => {
                      if (el.length) {
                        return <SectorsItem key={guid()}>{el.trim()}</SectorsItem>;
                      }
                    })
                ) : (
                  <ItemValue>N/A</ItemValue>
                )}
              </SectorsWrap>
            </ItemRow>
          </MoreDetailItem>
        </FlexWrap>
        <MoreDetailItem isMobile={isMobile}>
          {currentTab === TabsEnum.summary ? (
            <ProgressBar data={detailsDataMemo?.progressBarValues as ProgressBarValuesType | undefined} title={'Private Capital Target'} />
          ) : (
            <SimpleTable data={detailsDataMemo as ClientEntityCommitments | ClientEntityAllocations | undefined} />
          )}
        </MoreDetailItem>
      </MoreDetails>

      <ControlDetails isMobile={isMobile}>
        {tabs && currentTab && setCurrentTab && setIsTabMenuOpen && (
          <>
            {isDesktop || isTablet || isLargeDesktop ? (
              <>
                <Tabs tabs={tabs} activeTab={currentTab} setActiveTab={(tab) => setCurrentTab(tab as TabsEnum)} />
                <TabsLine />
              </>
            ) : (
              <TabsControl isMobile={isMobile}>
                {tabs.length !== 1 && (
                  <>
                    {isTabMenuOpen ? (
                      <CloseMenuIcon fill={theme.action.primary} onClick={() => setIsTabMenuOpen(!isTabMenuOpen)} style={{ cursor: 'pointer' }} />
                    ) : (
                      <BurgerMenuIcon fill={theme.action.primary} onClick={() => setIsTabMenuOpen(!isTabMenuOpen)} style={{ cursor: 'pointer' }} />
                    )}
                  </>
                )}
                <TabMobileWrap onClick={() => setCurrentTab(currentTab)} isActive>
                  <TabTitle isActive isMobile={isMobile}>
                    {currentTab}
                  </TabTitle>
                </TabMobileWrap>
              </TabsControl>
            )}
          </>
        )}

        <HideDetailsWrap withoutTabs={!tabs.length} isMobile={isMobile} onClick={setOpen}>
          <ArrowBtn isRotate={isOpen}>
            <SortIcon fill={theme.action.primary} />
          </ArrowBtn>
          <ControlDetailsTitle isMobile={isMobile}>{isOpen ? 'Hide Details' : 'Show Details'}</ControlDetailsTitle>
        </HideDetailsWrap>
      </ControlDetails>
    </DetailsWrap>
  );
};

const FlexWrap = styled.div`
  display: flex;
  flex-direction: row;
  width: 60%;
  justify-content: space-between;

  @media (max-width: 1100px) {
    flex-direction: column;
    width: 50%;
    gap: 14px;
  }
`;

const HideDetailsWrap = styled.div<{ isMobile: boolean; withoutTabs: boolean }>`
  position: relative;
  top: ${({ isMobile, withoutTabs }) => (isMobile || withoutTabs ? '0' : '15px')};
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 10px 0;
  gap: 12.5px;
`;

const NotesTitle = styled.div`
  font-family: 'Blinker', serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 16px;
  color: ${({ theme }) => theme.action.primary};
  margin-top: 3.5px;
`;

const NotesCounterWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const NotesCounter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -5px;
  bottom: 0;
  right: 0;
  left: 0;
  font-family: Blinker, serif;
  font-size: 13px;
  font-weight: 700;
  line-height: 18px;
  text-align: center;
  color: ${({ theme }) => theme.action.primary};
`;

const ArrowBtn = styled.div<{ isRotate: boolean }>`
  transform: ${({ isRotate }) => (isRotate ? 'rotate(180deg)' : 'rotate(0deg)')};
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.3s;
`;

const ControlDetailsTitle = styled.div<{ isMobile: boolean }>`
  font-family: 'Blinker', serif;
  font-size: ${({ isMobile }) => (isMobile ? '13px' : '16px')};
  font-weight: 600;
  line-height: 22px;
  color: ${({ theme }) => theme.action.primary};
`;

const ControlDetails = styled.div<{ isMobile: boolean }>`
  display: flex;
  justify-content: space-between;
  height: ${({ isMobile }) => (isMobile ? '30px' : 'auto')};
  //z-index: 100;
`;

const SectorsWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
`;

const SectorsItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px 20px;
  background-color: ${({ theme }) => theme.layer[2]};
  color: ${({ theme }) => theme.font.base};
  border-radius: 12px;
  font-family: 'Blinker', serif;
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0;
`;

const ItemValue = styled.div`
  font-family: 'Blinker', serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  color: ${({ theme }) => theme.font.base};
`;

const ItemTitle = styled.div`
  font-family: 'Blinker', serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  color: ${({ theme }) => theme.font.base};
  width: 150px;
`;

const ItemRow = styled.div`
  display: flex;
  width: 310px;
  gap: 10px;
`;

const MoreDetailItem = styled.div<{ isMobile: boolean }>`
  display: flex;
  flex-direction: column;
  width: ${({ isMobile }) => (isMobile ? '100%' : '33%')};
  gap: 14px;
  @media (max-width: 1100px) {
    //width: 50%;
    justify-content: center;
  }
`;

const CardsContainer = styled.div<{ isOpen: boolean; isMobile: boolean }>`
  pointer-events: ${({ isOpen }) => (isOpen ? 'auto' : 'none')};
  display: flex;
  flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};
  gap: 10px;
  height: ${({ isOpen, isMobile }) => (isMobile && isOpen ? '100%' : isOpen ? '80px' : '0px')};
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
  transition: ${({ isMobile }) => (isMobile ? 'none' : 'height, opacity 0.5s ease-in-out')};
`;

const MoreDetails = styled.div<{ isOpen: boolean; isMobile: boolean }>`
  pointer-events: ${({ isOpen }) => (isOpen ? 'auto' : 'none')};
  display: flex;
  flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};
  gap: ${({ isMobile }) => (isMobile ? '14px' : 0)};
  justify-content: space-between;
  padding: ${({ isOpen }) => (isOpen ? '25px 0' : 0)};
  height: ${({ isOpen, isMobile }) => (isMobile && isOpen ? '100%' : isOpen ? '286px' : '0px')};
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
  transition: ${({ isMobile }) => (isMobile ? 'none' : 'height, opacity 0.5s ease-in-out')};
`;

const CardTitle = styled.div`
  color: ${({ theme }) => theme.font.strong};
  font-family: Blinker, serif;
  font-size: 23px;
  font-weight: 700;
  line-height: 32px;
  text-align: center;
`;

const CardDesc = styled.div`
  color: ${({ theme }) => theme.font.weak};
  font-family: 'Blinker', serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0;
  text-align: center;
`;

const Card = styled.div<{ short?: boolean; isOpen?: boolean; notesLength?: number; isMobile: boolean }>`
  display: flex;
  cursor: ${({ short, isOpen, notesLength }) => (short && isOpen && notesLength ? 'pointer' : 'default')};
  width: ${({ short, isMobile }) => (isMobile ? '100%' : short ? '5.3%' : '30.9%')};
  height: 80px;
  min-height: 80px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.layer.base};
  border-radius: 10px;
`;

const DetailsWrap = styled.div<{ isMobile: boolean }>`
  padding: ${({ isMobile }) => (isMobile ? '10px 20px 0 20px' : '20px 60px 0 60px')};
  background-color: ${({ theme }) => theme.layer[1]};
  border-bottom: 1px solid ${({ theme }) => theme.border.base};
`;

export default Details;
