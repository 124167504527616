import { Tooltip } from '@mui/material';
import { tooltipClasses, TooltipProps } from '@mui/material/Tooltip';
import { styled } from 'styled-components';

export const StyledTooltip = styled(({ className, ...props }: TooltipProps) => <Tooltip {...props} classes={{ popper: className }} />)`
  & .${tooltipClasses.tooltip} {
    opacity: 1;
    display: flex;
    align-items: center;
    padding: 20px;
    font-size: 12px;
    font-family: 'Inter';
    font-weight: 400;
    height: 20px;
  }

  & .${tooltipClasses.arrow} {
    // color: '#C7CBD2',
  }
`;
