import { Dispatch, SetStateAction } from 'react';
import { EntityInput } from '../../fat-entityDetails-page/edit-entity/input';
import { Section } from '../../fat-entityDetails-page/edit-entity/section';

interface InvestorDetailsSectionProps {
  investorName: string;
  setInvestorName: Dispatch<SetStateAction<string>>;
}

export const InvestorDetailsSection = ({ investorName, setInvestorName }: InvestorDetailsSectionProps) => {
  return (
    <Section title="Investor Details">
      <EntityInput
        value={investorName}
        setValue={setInvestorName}
        label="Investor Name"
        required
        tooltipWidth={400}
        tooltip="Investors can hold one or more entities and are useful for householding. Give this investor a user-friendly name."
      />
    </Section>
  );
};
