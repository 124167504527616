import { ColumnList } from '@/components/dealRegistry-page/types';
import { Dispatch, SetStateAction } from 'react';
import { IRecordData } from '../dueDiligence-page/types';

export const selectColumns = (key: string, newColumns: ColumnList[], setNewColumns: Dispatch<SetStateAction<ColumnList[]>>) => {
  const newColumnsList = newColumns.map((element: ColumnList) => {
    if (key === element.keySort) {
      element.value = !element.value;
    }
    return element;
  });
  setNewColumns(newColumnsList);
};

export const handleRemoveColumn = (key: string, newColumns: ColumnList[], setNewColumns: Dispatch<SetStateAction<ColumnList[]>>) => {
  const newColumnsList = newColumns.map((element: ColumnList) => {
    if (key === element.keySort) {
      element.value = false;
    }
    return element;
  });

  setNewColumns(newColumnsList);
};

export const handleSelect = (action: boolean, newColumns: ColumnList[], setNewColumns: Dispatch<SetStateAction<ColumnList[]>>) => {
  const newColumnsList = newColumns.map((element: ColumnList) => {
    element.value = action;
    return element;
  });

  setNewColumns(newColumnsList);
};

export const dealRegistryFilterStatus = (status: string) => {
  switch (status) {
    case 'Lead':
      return 'LEAD';
    case 'Declined':
      return 'DECLINED';
    case 'Due Diligence':
      return 'DUE_DILIGENCE';
    case 'Approved':
      return 'APPROVED';
    case 'Rejected':
      return 'REJECTED';
    case 'Open':
      return 'OPEN';
    case 'Active':
      return 'ACTIVE';
    case 'Realized':
      return 'REALIZED';
    case 'Redeemed':
      return 'REDEEMED';
    case 'All Statuses':
      return ['LEAD', 'DECLINED', 'DUE_DILIGENCE', 'APPROVED', 'REJECTED', 'OPEN', 'ACTIVE', 'REALIZED', 'REDEEMED'];
    default:
      return '';
  }
};

export const actionDropdownItems = (
  row: IRecordData,
  moduleConfig: any,
  handleClickDeal: (row: IRecordData) => void,
  handleModifyDealClick: (row: IRecordData) => void,
  openModalWindow: (row: IRecordData, type: string) => void,
  handleUpdateInvestmentStatus: (row: IRecordData, status: string) => void,
  openInvestmentForAllocations: (row: IRecordData, isEdit?: boolean) => void,
  openManagerUpdates: (row: IRecordData) => void
) => {
  const commonDropdownAction = [
    {
      name: 'View Due Diligence',
      onClick: () => handleClickDeal(row)
    },
    {
      name: 'Edit Deal',
      onClick: () => handleModifyDealClick(row)
    }
  ];
  switch (row.investment.securityStatus) {
    case 'LEAD':
      return [
        {
          name: 'Start Due Diligence',
          onClick: () => openModalWindow(row, 'start-diligence')
        },
        {
          name: 'Decline',
          onClick: () => handleUpdateInvestmentStatus(row, 'DECLINED')
        },
        ...commonDropdownAction,
        ...(row.dealAssistantAIRuns.length === 0
          ? [
              {
                name: 'Generate AI Suggestions',
                onClick: () => openModalWindow(row, 'ai-suggestions')
              }
            ]
          : []),
        {
          name: 'Delete',
          onClick: () => openModalWindow(row, 'delete-deal')
        }
      ];
    case 'DECLINED':
      return [
        ...commonDropdownAction,
        {
          name: 'Reopen as Lead',
          onClick: () => handleUpdateInvestmentStatus(row, 'LEAD')
        },
        {
          name: 'Delete',
          onClick: () => openModalWindow(row, 'delete-deal')
        }
      ];
    case 'DUE_DILIGENCE':
      return [
        ...commonDropdownAction,
        ...(row.dealAssistantAIRuns.length === 0
          ? [
              {
                name: 'Generate AI Suggestions',
                onClick: () => openModalWindow(row, 'ai-suggestions')
              }
            ]
          : [])
      ];
    case 'APPROVED':
      return commonDropdownAction;
    case 'REJECTED':
      return commonDropdownAction;
    case 'OPEN':
      return [
        // {
        //   name: 'View Manager Updates',
        //   onClick: () => openManagerUpdates(row),
        // },
        {
          name: 'View Due Diligence',
          onClick: () => handleClickDeal(row)
        },
        {
          name: 'Edit Investment Details',
          onClick: () => openInvestmentForAllocations(row, true)
        }
      ];
    case 'ACTIVE':
      return [
        // {
        //   name: 'View Manager Updates',
        //   onClick: () => openManagerUpdates(row),
        // },
        {
          name: 'View Due Diligence',
          onClick: () => handleClickDeal(row)
        },
        {
          name: 'Edit Investment Details',
          onClick: () => openInvestmentForAllocations(row, true)
        }
      ];
    case 'CLOSED':
      return [
        // {
        //   name: 'View Manager Updates',
        //   onClick: () => openManagerUpdates(row),
        // },
        ...commonDropdownAction
      ];
    case 'REALIZED':
      return [...commonDropdownAction];
    case 'REDEEMED':
      return [...commonDropdownAction];
    default:
      return [];
  }
};
