import FilterPage from '@/components/table/fat-filterPage';
import TableHeader from '@/components/table/fat-tableHeader';
import { TableBodyWrapper, TableWrapper } from '@/components/table/styles';
import { ISort } from '@/components/table/types';
import { useResponsive } from '@/hooks/use-responsive';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import styled from 'styled-components';
import { IAllocationConflictsTableData, MismatchedAllocationError } from '../../../types';
import { columns } from '../constants';
import MobileTable from './mobileTable';
import TableBody from './tableBody';

interface TableProps {
  tableData: IAllocationConflictsTableData[];
  openResolveAllocationConflicts: (row: IAllocationConflictsTableData) => void;
  allocationConflictsErrors: MismatchedAllocationError[];
  sort: ISort;
  setSort: Dispatch<SetStateAction<ISort>>;
  search: string;
  setSearch: Dispatch<SetStateAction<string>>;
  primaryFilter: string;
  setPrimaryFilter: Dispatch<SetStateAction<string>>;
}

const Table = ({
  tableData,
  openResolveAllocationConflicts,
  allocationConflictsErrors,
  sort,
  setSort,
  search,
  setSearch,
  primaryFilter,
  setPrimaryFilter
}: TableProps) => {
  const { isMobile, isTablet } = useResponsive();

  const [sortedTableData, setSortedTableData] = useState<IAllocationConflictsTableData[]>([]);

  useEffect(() => {
    if (!allocationConflictsErrors?.length) {
      setPrimaryFilter('All Allocations');
    }
  }, []);

  useEffect(() => {
    const sortedData = [...tableData].sort((a, b) => {
      switch (sort.key) {
        case 'ENTITY':
          return sort.asc ? a.entityName.localeCompare(b.entityName) : b.entityName.localeCompare(a.entityName);
        case 'STRATEGY':
          return sort.asc ? a.investmentName.localeCompare(b.investmentName) : b.investmentName.localeCompare(a.investmentName);
        case 'IMPORT_TOTAL':
          return sort.asc ? a.importTotal - b.importTotal : b.importTotal - a.importTotal;
        case 'IMPORT_COUNT':
          return sort.asc ? a.importCount - b.importCount : b.importCount - a.importCount;
        case 'COMMITTED_TOTAL':
          return sort.asc ? a.committedTotal - b.committedTotal : b.committedTotal - a.committedTotal;
        case 'PENDING_AMOUNT':
          return sort.asc ? a.pendingAmount - b.pendingAmount : b.pendingAmount - a.pendingAmount;
        default:
          return 0;
      }
    });

    setSortedTableData(sortedData);
  }, [sort, tableData]);

  const filteredTableData = () => {
    const newTableData = [...sortedTableData];
    const filteredTableData = newTableData.filter(
      (item) => item.entityName?.toLowerCase().includes(search.toLowerCase()) || item.investmentName?.toLowerCase().includes(search.toLowerCase())
    );

    if (primaryFilter !== 'Showing Conflict Only') return filteredTableData;

    return filteredTableData.filter((item) => !item.validated);
  };

  return (
    <>
      <CustomFilterPage
        isMobile={isMobile}
        isTablet={isTablet}
        search={{
          value: search,
          onChange: setSearch,
          placeholder: 'Search'
        }}
        primaryFilter={{
          value: primaryFilter,
          onChange: setPrimaryFilter,
          selects: ['All Allocations', 'Showing Conflict Only']
        }}
        resultsValue={filteredTableData().length}
        refetch={() => null as any}
      />
      <TableWrapper padding="0">
        {isMobile ? (
          <MobileTable tableData={filteredTableData()} openResolveAllocationConflicts={openResolveAllocationConflicts} />
        ) : (
          <>
            <CustomTableHeader isTablet={isTablet} refetch={() => null as any} columns={columns} savedSort={sort} savedSetSort={setSort} />
            <TableBodyWrapper>
              <TableBody tableData={filteredTableData()} openResolveAllocationConflicts={openResolveAllocationConflicts} />
            </TableBodyWrapper>
          </>
        )}
      </TableWrapper>
    </>
  );
};

export default Table;

const CustomFilterPage = styled(FilterPage)<{ isMobile: boolean; isTablet: boolean }>`
  position: sticky;
  top: ${({ isMobile, isTablet }) => (isMobile || isTablet ? '50px' : '0')};
  z-index: 9;
  margin-top: 0;
  margin-left: -1px;
  width: calc(100% + 2px);
  padding: ${({ isMobile }) => (isMobile ? '20px 0 0 0' : '20px 0 10px 0')};
`;

const CustomTableHeader = styled(TableHeader)<{ isTablet: boolean }>`
  position: sticky;
  top: ${({ isTablet }) => (isTablet ? '130px' : '80px')};
  z-index: 8;
  padding-top: 0;
`;
