import styled from 'styled-components';

import BasicTooltip from '@/components/basicComponents/tooltip';
import { CompletedStatusRow } from '@/components/table/anchor-drop-down/stepLists/CompletedStatusRow';
import { FutureStatusRow } from '@/components/table/anchor-drop-down/stepLists/FutureStatusRow';
import { NextStepStatusRow } from '@/components/table/anchor-drop-down/stepLists/NextStepStatusRow';
import { Step } from '@/components/table/anchor-drop-down/stepLists/types';

interface StepsListProps {
  list: Step[];
}

export const LastUpdateInfoList = ({ list }: StepsListProps) => {
  if (!list.length) return <NoStatuses>There is no statuses</NoStatuses>;
  return (
    <Container>
      {list.map(({ status, date }, index) => (
        <WrapperRow key={status}>
          {date && (
            <BasicTooltip tooltipContent={status}>
              <CompletedStatusRow
                isMobile
                status={status}
                date={date as string}
              />
            </BasicTooltip>
          )}
          {list[index - 1]?.date && !date && (
            <BasicTooltip tooltipContent={status}>
              <NextStepStatusRow status={status} />
            </BasicTooltip>
          )}
          {!list[index - 1]?.date && !date && (
            <BasicTooltip tooltipContent={status}>
              <FutureStatusRow isMobile index={index} status={status} />
            </BasicTooltip>
          )}
        </WrapperRow>
      ))}
    </Container>
  );
};

const NoStatuses = styled.span`
  text-align: left;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 3px;

  * {
    white-space: nowrap;
  }
`;
const WrapperRow = styled.div<{ background?: string }>`
  width: 100%;
`;
