import { SortIcon } from '@/assets/icons/sortIcon';
import { StyledTooltip } from '@/components/recommendations-page/styles';
import { initialSort } from '@/components/table/constants';
import { SortBtn } from '@/components/table/tableHeader';
import { ISort, SORT_ORDER } from '@/components/table/types';
import { useEffect, useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { fixedColumns } from '../constatnts';
import { ColumnList } from '../types';

interface TableHeaderProps {
  columnsList: ColumnList[];
  withOutSort?: boolean;
  refetch: any;
  savedSort?: ISort;
  savedSetSort?: (value: ISort) => void;
  className?: string;
}

const TableHeader = ({ refetch, columnsList, withOutSort = false, savedSort, savedSetSort, className }: TableHeaderProps) => {
  const theme = useTheme();

  const [sort, setSort] = useState<ISort>(initialSort);
  const [isHidden, setIsHidden] = useState(true);
  const [sortKey, setSortKey] = useState('');

  useEffect(() => {
    if (savedSort) {
      setSort(savedSort);
    }
  }, [savedSort]);

  const getStatusSort = (keySort: string) => {
    if (sort.asc) {
      return {
        forBoolean: `Sort ${keySort}  • No -> Yes`,
        forStatuses: `Sort  ${keySort} • First -> Last`,
        forNumbers: `Sort ${keySort} • Min -> Max`,
        forDates: `Sort ${keySort} • Oldest -> Newest`,
        forStrings: `Sort ${keySort} • A -> Z`
      };
    } else {
      return {
        forBoolean: `Sort ${keySort} • Yes -> No`,
        forStatuses: `Sort  ${keySort} • Last -> First`,
        forNumbers: `Sort ${keySort} • Max -> Min`,
        forDates: `Sort ${keySort} • Newest -> Oldest`,
        forStrings: `Sort ${keySort} • Z -> A`
      };
    }
  };

  const getToolTipTitle = (keySort: string) => {
    if (['Tier', 'Asset Class', 'deal'].includes(keySort)) {
      return getStatusSort(keySort).forStrings;
    } else if (['Status'].includes(keySort)) {
      return getStatusSort(keySort).forStatuses;
    } else if (['Date Added'].includes(keySort)) {
      return getStatusSort(keySort).forDates;
    } else if (['custodied', 'ubti blocker', 'sri', 'decision'].includes(keySort)) {
      return getStatusSort(keySort).forBoolean;
    } else {
      return getStatusSort(keySort).forNumbers;
    }
  };

  const handleSort = (key: string, order: boolean) => {
    setSort({
      key,
      asc: order
    });
    refetch({
      order: order ? SORT_ORDER.ASC : SORT_ORDER.DESC,
      sort: key
    });
    if (savedSetSort) {
      savedSetSort({
        key,
        asc: order
      });
    }
  };

  return (
    <TableHeaderWrap className={className}>
      <FixedColumnHeader>
        {fixedColumns[0].title}
        {fixedColumns[0].key ? (
          <StyledTooltip placement={'bottom'} title={getToolTipTitle(fixedColumns[0].keySort as string)} arrow>
            <SortBtn
              onClick={() => handleSort(fixedColumns[0].key as string, !sort.asc)}
              isRotate={fixedColumns[0].key === sort.key && sort.asc}
              isActive={fixedColumns[0].key === sort.key}
            >
              <SortIcon
                style={{
                  marginBottom: -3,
                  marginTop: fixedColumns[0].key === sort.key && sort.asc ? -2 : 0
                }}
                fill={fixedColumns[0].key === sort.key ? theme.header.action : theme.font.action}
              />
            </SortBtn>
          </StyledTooltip>
        ) : !isHidden && fixedColumns[0].keySort === sortKey ? (
          <StyledTooltip placement={'bottom'} title={getToolTipTitle(fixedColumns[0].keySort as string)} arrow>
            <SortBtn
              onClick={() => handleSort(fixedColumns[0].key as string, !sort.asc)}
              isRotate={fixedColumns[0].key === sort.key && sort.asc}
              isActive={fixedColumns[0].key === sort.key}
            >
              <SortIcon
                style={{
                  marginBottom: -3,
                  marginTop: fixedColumns[0].key === sort.key && sort.asc ? -2 : 0
                }}
                fill={fixedColumns[0].key === sort.key ? theme.header.action : theme.font.action}
              />
            </SortBtn>
          </StyledTooltip>
        ) : (
          <></>
        )}
      </FixedColumnHeader>
      {columnsList.map((column) => {
        return (
          column.value && (
            <TableHeaderItem key={column.keySort} style={{ width: `${column.width}px` }}>
              {column.title}
              {column.key ? (
                <StyledTooltip placement={'bottom'} title={getToolTipTitle(column.keySort as string)} arrow>
                  <SortBtn
                    onClick={() => handleSort(column.key as string, !sort.asc)}
                    isRotate={column.key === sort.key && sort.asc}
                    isActive={column.key === sort.key}
                  >
                    <SortIcon
                      style={{
                        marginBottom: -3,
                        marginTop: column.key === sort.key && sort.asc ? -2 : 0
                      }}
                      fill={column.key === sort.key ? theme.header.action : theme.font.action}
                    />
                  </SortBtn>
                </StyledTooltip>
              ) : !isHidden && column.keySort === sortKey ? (
                <StyledTooltip placement={'bottom'} title={getToolTipTitle(column.keySort as string)} arrow>
                  <SortBtn
                    onClick={() => handleSort(column.key as string, !sort.asc)}
                    isRotate={column.key === sort.key && sort.asc}
                    isActive={column.key === sort.key}
                  >
                    <SortIcon
                      style={{
                        marginBottom: -3,
                        marginTop: column.key === sort.key && sort.asc ? -2 : 0
                      }}
                      fill={column.key === sort.key ? theme.header.action : theme.font.action}
                    />
                  </SortBtn>
                </StyledTooltip>
              ) : (
                <></>
              )}
            </TableHeaderItem>
          )
        );
      })}
    </TableHeaderWrap>
  );
};

const TableHeaderWrap = styled.div`
  background-color: ${({ theme }) => theme.header.base};
  border-radius: 8px;
  display: flex;
  align-items: center;
`;

const TableHeaderItem = styled.div`
  color: ${({ theme }) => theme.header.action};
  padding: 8px;
  text-align: inherit;
  height: 70px;
  padding: 0 24px;
  display: flex;
  align-items: center;
  gap: 10px;
  font-family: 'Blinker', serif;
  font-weight: 600;
  font-size: 12px;
  line-height: 22.4px;
  text-transform: uppercase;

  &:hover ${SortBtn} {
    display: flex;
  }
`;

const FixedColumnHeader = styled(TableHeaderItem)`
  position: sticky;
  left: 0;
  z-index: 1;
  border-right: 1px solid ${({ theme }) => theme.border.base};
  background-color: ${({ theme }) => theme.header.base};
  width: 390px;
  display: flex;
  align-items: center;

  border-radius: 8px 0 0 8px;
`;

export default TableHeader;
