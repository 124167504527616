import axios from 'axios';
import { Auth, getAuth, signInWithCustomToken, signInWithEmailAndPassword, signOut, updatePassword } from 'firebase/auth';
import PropTypes from 'prop-types';
import type { FC, ReactNode } from 'react';
import { createContext, useEffect, useReducer, useState } from 'react';
import { firebaseApp } from '../lib/firebase';
import type { User } from '../types/user';

const auth = getAuth(firebaseApp);
auth.tenantId = process.env.REACT_APP_FIREBASE_TENANT_ID ?? null;

interface State {
  isInitialized: boolean;
  isAuthenticated: boolean;
  user: User | null;
  userTasks: number;
  auth: Auth;
}

export interface AuthContextValue extends State {
  platform: 'Firebase';
  signInWithEmailAndPassword: (email: string, password: string) => Promise<any>;
  signInAs: (email: string) => Promise<any>;
  signOut: () => Promise<void>;
  changePassword: (newPassword: string) => Promise<void>;
  resetPassword: (email: string) => Promise<void>;
  userTasks: number;
  setUserTasks: (value: number) => void;
}

interface AuthProviderProps {
  children: ReactNode;
}

enum ActionType {
  AUTH_STATE_CHANGED = 'AUTH_STATE_CHANGED'
}

type AuthStateChangedAction = {
  type: ActionType.AUTH_STATE_CHANGED;
  payload: {
    isAuthenticated: boolean;
    user: User | null;
  };
};

type Action = AuthStateChangedAction;

const initialState: State = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
  userTasks: 0,
  auth
};

const reducer = (state: State, action: Action): State => {
  if (action.type === 'AUTH_STATE_CHANGED') {
    const { isAuthenticated, user } = action.payload;

    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user
    };
  }

  return state;
};

export const AuthContext = createContext<AuthContextValue>({
  ...initialState,
  platform: 'Firebase',
  signInWithEmailAndPassword: () => Promise.resolve(),
  signInAs: () => Promise.resolve(),
  signOut: () => Promise.resolve(),
  changePassword: (newPassword: string) => Promise.resolve(),
  resetPassword: (email: string) => Promise.resolve(),
  setUserTasks: (value: number) => Promise.resolve()
});

export const AuthProvider: FC<AuthProviderProps> = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);
  const [timerRunning, setTimerRunning] = useState(false);
  const [userTasks, setUserTasks] = useState(0);

  useEffect(() => {
    getAuth().onIdTokenChanged(async function (user) {
      if (user) {
        const token = await user.getIdToken();
        await _getUserProfile(token);
      } else {
        dispatch({
          type: ActionType.AUTH_STATE_CHANGED,
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
      }
    });
  }, []);

  useEffect(() => {
    if (state.isInitialized && !timerRunning) {
      setTimerRunning(true);
      const interval = setInterval(async () => {
        try {
          const token = await getAuth().currentUser?.getIdToken();
        } catch (error) {
          console.log(error);
        }
      }, 60000);
      return () => clearInterval(interval);
    }
  }, [state.isInitialized]);

  const _getUserProfile = async (token: string) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_BASE_URL}/users/me`, { token });
      const { firstName, lastName, permissions, roles, email, id, initials, lastLogin, supervisor, tenant, impersonated } = response.data;
      localStorage.setItem('token', token);
      dispatch({
        type: ActionType.AUTH_STATE_CHANGED,
        payload: {
          isAuthenticated: true,
          user: {
            id,
            email,
            firstName,
            lastName,
            roles,
            initials,
            lastLogin,
            permissions,
            supervisor,
            tenant,
            impersonated
          }
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const _getAppSettings = async (token: string) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_BASE_URL}/graphql`,
        {
          query:
            'query ($ids: [String!], $type: String) {  Settings(ids: $ids, type: $type) { \
            id key description type value } }',
          variables: { type: 'appSetting' }
        },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      return JSON.parse(response.data.data.Settings[0].value);
      //const { firstName, lastName, permissions, roles, email, id, initials, lastLogin, supervisor } = response.data;
    } catch (error) {
      console.log(error);
    }
  };

  const _signInWithEmailAndPassword = async (email: string, password: string): Promise<any> => {
    auth.tenantId = process.env.REACT_APP_FIREBASE_TENANT_ID || '';
    const { user } = await signInWithEmailAndPassword(auth, email, password);
    if (user) {
      const token = await user.getIdToken();
      await _getUserProfile(token);
      const appSetting = await _getAppSettings(token);
      return {
        appSetting
      };
    }
  };

  const _signInAs = async (email: string): Promise<any> => {
    auth.tenantId = process.env.REACT_APP_FIREBASE_TENANT_ID || '';

    const authJWT = localStorage.getItem('token');
    // use the API to get a custom token for the user
    const response = await axios.post(
      `${process.env.REACT_APP_BACKEND_BASE_URL}/users/impersonate`,
      { email },
      {
        headers: {
          Authorization: `Bearer ${authJWT}`
        }
      }
    );
    const customToken = response.data;

    const { user } = await signInWithCustomToken(auth, customToken);
    if (user) {
      const token = await user.getIdToken();
      await _getUserProfile(token);
      const appSetting = await _getAppSettings(token);
      return {
        appSetting
      };
    }
  };

  const _signOut = async (): Promise<void> => {
    localStorage.setItem('token', '');
    dispatch({
      type: ActionType.AUTH_STATE_CHANGED,
      payload: {
        isAuthenticated: false,
        user: null
      }
    });
    await signOut(auth);
  };

  const _changePassword = async (newPassword: string): Promise<void> => {
    const currentUser = getAuth().currentUser;
    if (currentUser) {
      try {
        return await updatePassword(currentUser, newPassword);
      } catch (error: any) {
        return error;
      }
    } else {
      return;
    }
  };

  const _resetPassword = async (email: string): Promise<void> => {
    return await axios.post(`${process.env.REACT_APP_BACKEND_BASE_URL}/users/forgotPassword`, { email });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        userTasks,
        setUserTasks,
        platform: 'Firebase',
        signInWithEmailAndPassword: _signInWithEmailAndPassword,
        signInAs: _signInAs,
        signOut: _signOut,
        changePassword: _changePassword,
        resetPassword: _resetPassword
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export const AuthConsumer = AuthContext.Consumer;
