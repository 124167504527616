import { forwardRef } from 'react';
import { Link } from 'react-router-dom';

// Define the interface for props
interface LinksProps {
  color?: 'primary' | 'secondary' | string;
  textColor?: 'primary' | 'secondary' | string;
  type?: string;
  url: string;
  children?: any;
  underline?: 'none' | 'hover' | 'always' | undefined;
  className?: string;
}

// Primary UI component for user interaction
export const LinksProps = forwardRef(
  (
    {
      textColor,
      underline,
      url,
      type,
      children,
      className,
      ...props
    }: LinksProps,
    ref: any
  ) => {
    // Custom style for the link
    const customStyle = {
      color: textColor, // Use textColor for the color
      cursor: 'pointer',
      textDecoration: underline !== 'none' ? 'underline' : 'none'
    };

    return (
      <Link
        to={url}
        style={customStyle}
        className={className}
        ref={ref}
        {...props}
      >
        {children}
      </Link>
    );
  }
);
