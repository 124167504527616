import { useLocation, useNavigate } from 'react-router-dom';
import styled, { keyframes, useTheme } from 'styled-components';

import { CURIOLogoOnly } from '@/assets/icons/fat-logo/CURIOLogoOnly';
import { BurgerMenuIcon } from '@/assets/static/icons/burger-menu';
import { CloseMenuIcon } from '@/assets/static/icons/close-menu';
import { LogOutBurgerIcon } from '@/assets/static/icons/log-out-burger';
import { useAuth } from '@/hooks/use-auth';
import { useState } from 'react';
import { Tabs } from '../../constants/tabs/tabs';
import { useResponsive } from '../../hooks/use-responsive';

interface DashboardNavbarProps {
  tabs: Tabs[];
}

export const DashboardNavbarMobile = ({ tabs }: DashboardNavbarProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { isMobile } = useResponsive();
  const { signOut } = useAuth();
  const theme = useTheme();

  const [isNavOpen, setIsNavOpen] = useState(false);

  const onLogoClick = () => {
    setIsNavOpen(!isNavOpen);
  };

  const selectedTabIndex = tabs.findIndex((tab) => location.pathname.includes(tab.path.split('/')[0])) ?? 0;

  return (
    <NavBarWrap isNavOpen={isNavOpen}>
      <DashboardNavbarWrap isNavOpen={isNavOpen}>
        <ActionWrap isNavOpen={isNavOpen}>
          {isNavOpen ? (
            <CloseMenuWrapper>
              <CloseIconWrapper style={{ cursor: 'pointer' }} onClick={onLogoClick}>
                <CloseMenuIcon width={16} height={16} style={{ cursor: 'pointer' }} fill={theme.header.action} />
              </CloseIconWrapper>
              <LogOutBtn
                onClick={() => {
                  signOut();
                  setIsNavOpen(!isNavOpen);
                  navigate('/');
                }}
              >
                <LogOutBurgerIcon width={16} height={16} />
              </LogOutBtn>
            </CloseMenuWrapper>
          ) : (
            <BurgerMenuIcon width={24} height={18} onClick={onLogoClick} style={{ cursor: 'pointer' }} fill={theme.header.action} />
          )}
        </ActionWrap>
        <MenuItemsWrap isMobile={isMobile} isNavOpen={isNavOpen}>
          {isNavOpen && (
            <>
              <LogoWrap>
                <CURIOLogoOnly onClick={onLogoClick} />
              </LogoWrap>
              <MenuWrap>
                {tabs?.map((el, index) => {
                  return (
                    !el?.disabled && (
                      <MenuItemWrap
                        active={index === selectedTabIndex}
                        isMobile={isMobile}
                        onClick={(event) => {
                          event.preventDefault();
                          const returnUrl = location.state?.returnUrl || `/${el.path}`;
                          navigate(returnUrl, { replace: true });
                          setIsNavOpen(false);
                        }}
                        key={el.path}
                      >
                        {el.icon}
                        <MenuItemTitle>{el.label}</MenuItemTitle>
                      </MenuItemWrap>
                    )
                  );
                })}
              </MenuWrap>
            </>
          )}
        </MenuItemsWrap>
      </DashboardNavbarWrap>
    </NavBarWrap>
  );
};

const NavBarWrap = styled.div<{ isNavOpen: boolean }>`
  display: flex;
  position: fixed;
  width: 100%;
  height: ${({ isNavOpen }) => (isNavOpen ? '100vh' : 0)};
  transition: 0.3s;
  z-index: 1000;
`;

const CloseIconWrapper = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CloseMenuWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const LogOutBtn = styled.div`
  background-color: ${({ theme }) => theme.layer.base};
  border-radius: 50%;
  border: 1px solid ${({ theme }) => theme.border.base};
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 6px;
  font-size: 13px;
  font-weight: 400;
  padding: 6px;
  cursor: pointer;
  color: ${({ theme }) => theme.action.primary};
`;

const displayToDoTitle = keyframes`
  to {
    visibility: visible;
  }
`;

const MenuItemTitle = styled.div`
  font-family: Lato;
  color: ${({ theme }) => theme.header.action};
  font-weight: 700;
  visibility: hidden;
  animation: 0s linear 0.1s forwards ${displayToDoTitle};
`;

const MenuItemWrap = styled.div<{ active: boolean; isMobile: boolean }>`
  cursor: pointer;
  background-color: ${({ active, theme }) => (active ? theme.action.active : 'transparent')};
  width: ${({ isMobile }) => (isMobile ? '100%' : '260px')};
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 30px;
  padding: 20px 25px;
`;

const MenuWrap = styled.div`
  height: calc(100vh - 110px);
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  margin: 0px -10px;
  &::before {
    content: none;
  }
  &::-webkit-scrollbar {
    background-color: transparent;
    width: 6px;
  }
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
  &::-webkit-scrollbar-track:hover {
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.layer[4]};
    border-radius: 6px;
    border: 2px solid transparent;
  }
  &::-webkit-scrollbar-thumb:hover {
    background-color: ${({ theme }) => theme.layer[1]};
    border: 2px solid transparent;
  }
  &::-webkit-scrollbar-button {
    display: none;
  }
`;

const ActionWrap = styled.div<{ isNavOpen: boolean }>`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${({ isNavOpen }) => (isNavOpen ? '10px 20px' : '16px 20px')};
  box-shadow: 4px 0px 8px 0px rgba(0, 0, 0, 0.25);
  background-color: ${({ theme }) => theme.header.base};
`;

const LogoWrap = styled.div`
  display: flex;
  justify-content: center;
  padding: 10px 0;
`;

const DashboardNavbarWrap = styled.div<{ isNavOpen: boolean }>`
  box-sizing: border-box;
  height: ${({ isNavOpen }) => (isNavOpen ? '100vh' : '50px')};
  overflow: hidden;
  position: ${({ isNavOpen }) => (isNavOpen ? 'absolute' : 'fixed')};
  width: 100%;
  transition: 0.3s;
  padding-bottom: 0;
  z-index: 400;
  top: 0;
  bottom: 0;
  left: 0;
`;

const MenuItemsWrap = styled.div<{ isMobile: boolean; isNavOpen: boolean }>`
  width: ${({ isMobile }) => (isMobile ? '100%' : '250px')};
  height: 100%;
  background-color: ${({ theme }) => theme.menu.tab};
  margin-top: ${({ isNavOpen }) => (isNavOpen ? '-1px' : 0)};
`;
