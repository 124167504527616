import React, { useEffect } from 'react';
import styled from 'styled-components';

import { ITypeModalWindow } from '../../../../types/opportunity/proposed';

interface CompleteModalWindowProps {
  setConfirmTrue?: () => void;
  setConfirmFalse?: () => void;
  refetch?: () => void;
  changeDropDownOptions?: () => void;
  changeField: (
    field: string
  ) => (value: string | boolean | ITypeModalWindow) => void;
}

const Complete: React.FC<CompleteModalWindowProps> = ({
  changeField,
  refetch,
  changeDropDownOptions,
  setConfirmTrue,
  setConfirmFalse
}) => {
  useEffect(() => {
    setConfirmFalse && setConfirmFalse();
  }, []);

  const closeModal = () => {
    changeField('isOpen')(false);
  };

  const confirmModal = () => {
    setConfirmTrue && setConfirmTrue();
    closeModal();
    changeDropDownOptions && changeDropDownOptions();
  };

  return (
    <>
      <ModalWindowText fontWeight={600} fontSize={32} padding={'15px 0 0 0'}>
        Remove allocation from list
      </ModalWindowText>
      <ModalWindowText fontWeight={400} fontSize={22} padding={'10px 0 30px'}>
        This action will remove the confirmed allocation from this list. It will
        appear under the Commitments Tab once it is included in the reporting
        feed.
      </ModalWindowText>
      <ModalWindowButton>
        <ModalButton type="Close" onClick={closeModal}>
          Cancel
        </ModalButton>
        <ModalButton type="Submit" onClick={confirmModal}>
          Confirm
        </ModalButton>
      </ModalWindowButton>
    </>
  );
};

export default Complete;

type ModalWindowTextProps = {
  fontWeight: number;
  fontSize: number;
  padding: string;
};

type ModalButtonProps = {
  type: 'Submit' | 'Close';
};

export const ModalButton = styled('div')<ModalButtonProps>(
  ({ type, theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '10px 16px',
    cursor: 'pointer',
    borderRadius: 5,
    fontWeight: 600,
    minWidth: 118,
    fontSize: 18,
    color: type === 'Submit' ? '#fff' : 'rgb(87, 143, 228)',
    background: type === 'Submit' ? '#172D4D' : 'transparent',
    border: type === 'Close' ? '1px solid #578FE4' : 'none'
  })
);

const ModalWindowText = styled.span<ModalWindowTextProps>`
  text-align: center;
  margin: 0;
  font-size: ${({ fontSize }: ModalWindowTextProps) => fontSize}px;
  font-weight: ${({ fontWeight }: ModalWindowTextProps) => fontWeight};
  padding: ${({ padding }: ModalWindowTextProps) => padding};
`;

const ModalWindowButton = styled.div`
  display: flex;
  gap: 15px;
  margin: 48px auto 0;
  border-radius: 8px;
`;
