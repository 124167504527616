import { useMutation } from '@apollo/client';
import { useState } from 'react';
import Label from '@/components/basicComponents/label';
import ModalWrappedContent from '@/components/basicComponents/modal/modalWrappedContent';
import { IFunnelData } from '@/components/dueDiligence-page/types';
import {
  CREATE_DUE_DILIGENCE_FUNNEL,
  DUE_DILIGENCE_FUNNEL_LIST_SETTINGS_QUERY
} from '@/components/settings-page/queries';
import { SORT_ORDER } from '@/components/table/types';
import styled from 'styled-components';
import { saveOnEnter } from '../utils';

interface AddFunnelProps {
  onClose: () => void;
  isOpen: boolean;
  openFunnelEditor: (funnelId: string, funnelName: string) => void;
  currentFunnel: IFunnelData | null;
}

const AddFunnel = ({
  onClose,
  isOpen,
  openFunnelEditor,
  currentFunnel
}: AddFunnelProps) => {
  const [value, setValue] = useState('');

  const [createFunnel] = useMutation(CREATE_DUE_DILIGENCE_FUNNEL, {
    refetchQueries: () => [
      {
        query: DUE_DILIGENCE_FUNNEL_LIST_SETTINGS_QUERY,
        variables: {
          data: {
            statusFilter: ['PUBLISHED', 'DRAFT_WITHOUT_PUBLISHED'],
            sortDirection: SORT_ORDER.ASC,
            sortBy: 'ORDER'
          },
          archivedFunnelData: {
            sortDirection: SORT_ORDER.ASC,
            sortBy: 'ORDER'
          }
        }
      }
    ]
  });

  const saveValue = () => {
    createFunnel({
      variables: {
        data: {
          name: value,
          funnelStaticId: currentFunnel
            ? currentFunnel.funnelStaticId
            : crypto.randomUUID(),
          externalId: 'test-3',
          status: 'DRAFT',
          tiers: []
        }
      },
      onCompleted: (data) => {
        const { id, name } = data.createDueDiligenceFunnel;
        openFunnelEditor(id, name);
      }
    });
    onClose();
  };

  return (
    <ModalWrappedContent
      isOpen={isOpen}
      onClose={onClose}
      confirmButton={{ name: 'Save', onClick: saveValue, disabled: !value }}
      showRequiredFields
    >
      <ModalTitle>Add New Funnel</ModalTitle>
      <ModalContainer
        onKeyDown={(event) => saveOnEnter(event, saveValue, !value)}
      >
        <Label required>Give this funnel a name</Label>
        <MainSearch
          type="text"
          placeholder="Give this Funnel a unique name"
          value={value}
          onChange={(event) => setValue(event.target.value)}
          autoFocus
        />
      </ModalContainer>
    </ModalWrappedContent>
  );
};

export default AddFunnel;

export const ModalContainer = styled.div`
  margin-top: 20px;
  outline: none;
`;

export const ModalTitle = styled.div`
  font-size: 33px;
  text-align: center;
  color: #2e2e2e;
`;

export const MainSearch = styled.input`
  width: 100%;
  border: 1px solid #c7cbd2;
  border-radius: 5px;
  padding: 10px 12px;
  font-size: 19px;
  font-family: Blinker;
  font-weight: 400;

  &:focus {
    outline: none;
  }
`;
