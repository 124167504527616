import styled from 'styled-components';

import ModalWrappedContent from '@/components/fat-basicComponents/modal/modalWrappedContent';
import { useResponsive } from '@/hooks/use-responsive';
import { useParams } from 'react-router-dom';
import { TierStatusEnum } from '../dueDiligenceRecord/types';
import { DUE_DILIGENCE_RECORD_QUERY } from '../queries';

interface AdvanceToProps {
  onClose: () => void;
  isOpen: boolean;
  updateTierStatus: (variables: any) => void;
  currentRow: any;
  advanceToNexTier: () => void;
}

const AdvanceTo = ({ onClose, isOpen, currentRow, updateTierStatus, advanceToNexTier }: AdvanceToProps) => {
  const { isMobile } = useResponsive();
  const params = useParams();

  const saveValue = () => {
    updateTierStatus({
      variables: {
        data: {
          id: currentRow.data.id,
          status: TierStatusEnum.COMPLETED
        }
      },
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: DUE_DILIGENCE_RECORD_QUERY,
          variables: {
            id: params?.recordId
          }
        }
      ],
      onCompleted: () => {
        onClose();
        advanceToNexTier();
      }
    });
  };

  return (
    <ModalWrappedContent
      isOpen={isOpen}
      onClose={onClose}
      title={'Advance To Next Tier'}
      confirmButton={{
        name: `${isMobile ? 'Yes' : 'Yes, Advance Investment'}`,
        onClick: saveValue
      }}
    >
      <ModalText>Are you sure you want to advance this investment to the next tier?</ModalText>
    </ModalWrappedContent>
  );
};

export default AdvanceTo;

const ModalText = styled.p`
  font-weight: 400;
  font-size: 19px;
  line-height: 27px;
  text-align: center;
  color: ${({ theme }) => theme.font.base};
`;
