import { getUTCDate } from '@/components/allocations-page/utils';
import FormattingTooltip from '@/components/basicComponents/tooltip/dollarsTooltip';
import { MobileRow, MobileRowTitle, MobileRowValue, MobileTableRowWrap } from '@/components/table/mobileTable/mobileTable';
import styled from 'styled-components';
import { CommitmentForecastValueDto } from '../../../../types';

interface MobileSubTotalRowProps {
  tableData: CommitmentForecastValueDto[];
}

interface CommitmentSummary {
  [year: string]: number;
}

const MobileSubTotalRow = ({ tableData }: MobileSubTotalRowProps) => {
  const dateCommitmentSummary: CommitmentSummary = {};

  const addItemValue = (date: string, allocation: number) => {
    const year = getUTCDate(new Date(date)).toUpperCase();

    if (!dateCommitmentSummary[year]) {
      dateCommitmentSummary[year] = 0;
    }
    dateCommitmentSummary[year] += allocation;
  };

  tableData.forEach((commitment) => {
    commitment.data.forEach((item) => {
      addItemValue(item.date, item.value);
    });
  });

  return (
    <MobileSumFooterWrap>
      <MobileRow>
        <MobileRowTitle>Subtotal</MobileRowTitle>
      </MobileRow>

      {Object.entries(dateCommitmentSummary).map(([year, value]) => (
        <MobileRow key={year}>
          <MobileRowTitle>{year}</MobileRowTitle>
          <MobileRowValue>
            <FormattingTooltip zIndex={1000}>{value}</FormattingTooltip>
          </MobileRowValue>
        </MobileRow>
      ))}
    </MobileSumFooterWrap>
  );
};

const MobileSumFooterWrap = styled(MobileTableRowWrap)`
  background-color: ${({ theme }) => theme.layer[2]};
`;

export default MobileSubTotalRow;
