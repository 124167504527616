import styled from 'styled-components';
import guid from '../../../utils/guid';
import FormattingTooltip from '../../basicComponents/tooltip/dollarsTooltip';
import { SummaryTableData } from '../types';

interface BodyRowProps {
  clientsRows: SummaryTableData[];
  amountColumns: number;
}

export const TableBody = ({ clientsRows, amountColumns }: BodyRowProps) => {
  const WIDTH = 100 / amountColumns;

  return (
    <TableWrapper>
      {clientsRows.map((row) => (
        <TableRowWrap key={guid()}>
          <TableRowItem width={WIDTH}>
            <BasicTitle>{row.asset}</BasicTitle>
          </TableRowItem>
          <TableRowItem width={WIDTH}>
            <BasicTitle>{row.target.toFixed(0)}%</BasicTitle>
          </TableRowItem>
          <TableRowItem width={WIDTH}>
            <BasicTitle>
              <FormattingTooltip zIndex={1000}>{row.targetAllocations}</FormattingTooltip>
            </BasicTitle>
          </TableRowItem>
          <TableRowItem width={WIDTH}>
            <BasicTitle>
              <FormattingTooltip zIndex={1000}>{row.exposure}</FormattingTooltip>
            </BasicTitle>
          </TableRowItem>
          <TableRowItem width={WIDTH}>
            <BasicTitle>
              <FormattingTooltip zIndex={1000}>{row.capacity}</FormattingTooltip>
            </BasicTitle>
          </TableRowItem>
        </TableRowWrap>
      ))}
    </TableWrapper>
  );
};

const TableWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 6px;
`;

const BasicTitle = styled.div`
  font-family: 'Blinker', serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0;
  text-align: left;
  color: ${({ theme }) => theme.font.base};
`;

const TableRowWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  min-height: 60px;
  background-color: ${({ theme }) => theme.layer[1]};
  border-radius: 8px;
  padding: 0 24px;

  &:hover #infoWrap {
    opacity: 1;
  }

  &:hover #tempInfoWrap {
    display: none;
  }

  &:hover {
    background-color: ${({ theme }) => theme.layer[12]};
    transition: background-color 100ms ease;
  }
`;

const TableRowItem = styled.div<{ width: number }>`
  display: flex;
  justify-content: flex-start;
  width: ${({ width }) => `${width}%`};
`;
