import { InvestorInterestViewType } from '../types';

export const VIEW_BY_SELECTS: InvestorInterestViewType[] = ['Advisory Firm', 'Investor'];

export const advisorColumns = [
  { title: 'Advisory Firms', key: 'ADVISORY_FIRMS', width: 16, keySort: 'advisory firms' },
  { title: 'Taxable Estimate', key: 'TAXABLE_ESTIMATE', width: 16, keySort: 'taxable estimate' },
  { title: 'Exempt Estimate', key: 'EXEMPT_ESTIMATE', width: 16, keySort: 'exempt estimate' },
  { title: 'Combined Estimate', key: 'ESTIMATED_COMMITMENT', width: 16, keySort: 'combined estimate' },
  { title: 'Investors', key: 'INVESTORS', width: 16, keySort: 'aggregate interest' },
  { title: 'Update', key: 'UPDATED', width: 16, keySort: 'update' },
  { title: ' ', key: ' ', width: 4 }
];

export const investorColumns = [
  { title: 'Entity', key: 'ENTITY_NAME', width: 15, keySort: 'entity' },
  { title: 'Investor', key: 'INVESTOR', width: 14, keySort: 'investor' },
  { title: 'Advisor', key: 'ADVISORY_FIRMS', width: 15, keySort: 'advisor' },
  { title: 'Taxable Estimate', key: 'TAXABLE_ESTIMATE', width: 14, keySort: 'taxable estimate' },
  { title: 'Exempt Estimate', key: 'EXEMPT_ESTIMATE', width: 14, keySort: 'exempt estimate' },
  { title: 'Combined Estimate', key: 'ESTIMATED_COMMITMENT', width: 14, keySort: 'combined estimate' },
  { title: 'Update', key: 'UPDATED', width: 14, keySort: 'update' }
];

export const advisorTableSort = [
  {
    title: 'Advisory Firms • A -> Z',
    key: 'ADVISORY_FIRMS',
    asc: true
  },
  {
    title: 'Advisory Firms • Z -> A',
    key: 'ADVISORY_FIRMS',
    asc: false
  },
  {
    title: 'Taxable Estimate • Max -> Min',
    key: 'TAXABLE_ESTIMATE',
    asc: false
  },
  {
    title: 'Taxable Estimate • Min -> Max',
    key: 'TAXABLE_ESTIMATE',
    asc: true
  },
  {
    title: 'Exempt Estimate • Max -> Min',
    key: 'EXEMPT_ESTIMATE',
    asc: false
  },
  {
    title: 'Exempt Estimate • Min -> Max',
    key: 'EXEMPT_ESTIMATE',
    asc: true
  },
  {
    title: 'Combined Estimate • Max -> Min',
    key: 'ESTIMATED_COMMITMENT',
    asc: false
  },
  {
    title: 'Combined Estimate • Min -> Max',
    key: 'ESTIMATED_COMMITMENT',
    asc: true
  },
  {
    title: 'Investors • Max -> Min',
    key: 'INVESTORS',
    asc: false
  },
  {
    title: 'Investors • Min -> Max',
    key: 'INVESTORS',
    asc: true
  },
  {
    title: 'Update • Newest -> Oldest',
    key: 'UPDATED',
    asc: false
  },
  {
    title: 'Update • Oldest -> Newest',
    key: 'UPDATED',
    asc: true
  }
];

export const investorTableSort = [
  {
    title: 'Entity • A -> Z',
    key: 'ENTITY_NAME',
    asc: true
  },
  {
    title: 'Entity • Z -> A',
    key: 'ENTITY_NAME',
    asc: false
  },
  {
    title: 'Investor • A -> Z',
    key: 'INVESTOR',
    asc: true
  },
  {
    title: 'Investor • Z -> A',
    key: 'INVESTOR',
    asc: false
  },
  {
    title: 'Advisor • A -> Z',
    key: 'ADVISORY_FIRMS',
    asc: false
  },
  {
    title: 'Advisor • Z -> A',
    key: 'ADVISORY_FIRMS',
    asc: true
  },
  {
    title: 'Taxable Estimate • Max -> Min',
    key: 'TAXABLE_ESTIMATE',
    asc: false
  },
  {
    title: 'Taxable Estimate • Min -> Max',
    key: 'TAXABLE_ESTIMATE',
    asc: true
  },
  {
    title: 'Exempt Estimate • Max -> Min',
    key: 'EXEMPT_ESTIMATE',
    asc: false
  },
  {
    title: 'Exempt Estimate • Min -> Max',
    key: 'EXEMPT_ESTIMATE',
    asc: true
  },
  {
    title: 'Combined Estimate • Max -> Min',
    key: 'ESTIMATED_COMMITMENT',
    asc: false
  },
  {
    title: 'Combined Estimate • Min -> Max',
    key: 'ESTIMATED_COMMITMENT',
    asc: true
  },
  {
    title: 'Update • Newest -> Oldest',
    key: 'UPDATED',
    asc: false
  },
  {
    title: 'Update • Oldest -> Newest',
    key: 'UPDATED',
    asc: true
  }
];
