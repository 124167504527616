import ModifyStrategy from '@/components/fat-dealPipeline-page/fat-modify';
import { Helmet as Head } from 'react-helmet';
import useRefresh from '../../../hooks/use-refresh';

const ModifyStrategyPage = () => {
  useRefresh();
  return (
    <>
      <Head>
        <title>Opportunities dev</title>
      </Head>
      <ModifyStrategy />
    </>
  );
};

export default ModifyStrategyPage;
