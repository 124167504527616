import styled from 'styled-components';

const StyledButton = styled.button`
  background: ${(props) => props.theme.primary.main};
  border-radius: 10px;
  border: none;
  color: white;
  padding: 0.7rem 1rem;
`;

export default StyledButton;
