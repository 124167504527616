import styled from 'styled-components';

import { Dispatch, SetStateAction } from 'react';
import { ApprovedIcon } from '@/assets/icons/info-icons/approved';
import { CloseInfoIcon } from '@/assets/icons/info-icons/closeInfo';

interface SuccessToastProps {
  setShowSuccessBanner: Dispatch<SetStateAction<boolean>>;
  numberFields: number;
}

export const SuccessToast = ({
  setShowSuccessBanner,
  numberFields
}: SuccessToastProps) => {
  return (
    <StatusBannerWrapper>
      <ApprovedIcon fill="#F4F5F6" />
      <SeparateStick />
      <StatusWrapper>
        <Status>Success!</Status>
        {/* <Description>Suggestions generated for {numberFields} fields!</Description> */}
        <Description>Suggestions ready for review.</Description>
      </StatusWrapper>
      <CloseBannerWrap onClick={() => setShowSuccessBanner(false)}>
        <CloseInfoIcon width={14} height={14} fill="#F4F5F6" />
      </CloseBannerWrap>
    </StatusBannerWrapper>
  );
};

const StatusBannerWrapper = styled.div`
  margin: 10px 0px;
  background: #3dbd4a;
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 10px 20px;
`;

const SeparateStick = styled.div`
  width: 1px;
  height: 46px;
  background-color: #f4f5f6;
`;

const StatusWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Status = styled.span`
  color: #f4f5f6;
  font-weight: 700;
  font-size: 16px;
`;

const Description = styled.span`
  color: #f4f5f6;
  font-weight: 400;
  font-size: 13px;
`;

const CloseBannerWrap = styled.div`
  margin-left: auto;
  cursor: pointer;
`;
