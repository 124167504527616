import { gql } from '@apollo/client';

export const updateCalculations = gql`
  mutation UpdateCalculations {
    updateCalculations
  }
`;

export const updateAggregateInterest = gql`
  mutation CalculateAndUpdateAggregateInterest {
    calculateAndUpdateAggregateInterest
  }
`;

export const updateAggregateCommitment = gql`
  mutation CalculateAndUpdateAggregateCommitment {
    calculateAndUpdateAggregateCommitment
  }
`;
