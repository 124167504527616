import { IOwner } from '@/components/allocations-page/types';
import { AddButton } from '@/components/fat-dealPipeline-page/fat-modify/addButton';
import { Section } from '@/components/fat-dealPipeline-page/fat-modify/section';
import { DatePicker, Input, Select } from '@/components/fat-form-field';
import { defaultSelectValue } from '@/components/opportunities-entity/modify/constants';
import { useResponsive } from '@/hooks/use-responsive';
import styled from 'styled-components';
import { getOwnerValues } from '../../utils';

interface DetailsSectionProps {
  control: any;
  onBlur?: any;
  errors: any;
  setError: any;
  managerList: string[];
  investmentOwners: IOwner[];
  operationsOwners: IOwner[];
  complianceOwners: IOwner[];
  openModalWindow: (type: string) => void;
  isModify: boolean;
  openInvestment: string;
  hasActiveAllocation: boolean;
}

const OWNER_TOOL_TIP = 'Each owner will be the default assignee for relevant tasks associated with this investment.';

export const DetailsSection = ({
  control,
  onBlur,
  errors,
  setError,
  managerList,
  investmentOwners,
  operationsOwners,
  complianceOwners,
  openModalWindow,
  isModify,
  openInvestment,
  hasActiveAllocation
}: DetailsSectionProps) => {
  const { isMobile } = useResponsive();

  return (
    <Section title="Investment Details">
      <DetailsSectionWrap>
        <Line isMobile={isMobile}>
          <Input
            title={'Name'}
            nameField={'name'}
            rules={{
              required: {
                value: true,
                message: 'Name is required'
              }
            }}
            control={control}
            placeHolder={'Friendly name for use internally and with clients'}
            isWarning={true}
            onBlur={onBlur}
          />
          <ManagerWrapper>
            <Select
              title={'Manager'}
              nameField={'manager'}
              control={control}
              rules={{
                required: true,
                pattern: {
                  value: /^(?!Select$)/
                }
              }}
              defaultValue={defaultSelectValue}
              selects={[defaultSelectValue, ...managerList]}
              isWarning={true}
              setError={setError}
              errorMessage="Manager is required"
              onBlur={onBlur}
              withSearch
            />
            <AddButton text="Create New" onClick={() => openModalWindow('create-manager')} />
          </ManagerWrapper>
        </Line>
        <Line isMobile={isMobile}>
          <Select
            title={'Investment Type'}
            nameField={'type'}
            control={control}
            rules={{
              required: true,
              pattern: {
                value: /^(?!Select$)/
              }
            }}
            defaultValue={defaultSelectValue}
            selects={[defaultSelectValue, 'Fund', 'Direct']}
            isWarning={true}
            setError={setError}
            errorMessage="Investment Type is required"
            onBlur={onBlur}
            tooltip="Investment Type Fund or Direct"
          />
          <Select
            title={'Asset Class'}
            nameField={'assetClass'}
            control={control}
            rules={{
              required: true,
              pattern: {
                value: /^(?!Select$)/
              }
            }}
            defaultValue={defaultSelectValue}
            selects={[defaultSelectValue, 'Private Equity', 'Private Credit', 'Private Real Assets']}
            isWarning={true}
            setError={setError}
            errorMessage="Asset Class is required"
            disabled={Boolean((isModify && openInvestment) || hasActiveAllocation)}
            onBlur={onBlur}
          />
        </Line>
        <Line isMobile={isMobile}>
          <Input
            title={'Investment ID'}
            nameField={'symbol'}
            control={control}
            placeHolder={'Create an internal ID for your own needs'}
            tooltip={'Create an identifier to map this investment to commitments in your reporting system or other integrations'}
            tooltipWidth={300}
            isWarning={true}
            rules={{
              pattern: {
                value: isModify ? null : /^[A-Za-z0-9]+$/i,
                message: 'Only numbers and letters are allowed'
              },
              required: {
                value: true,
                message: 'Identifier is required'
              }
            }}
            onBlur={onBlur}
            disabled={(isModify && !openInvestment) || hasActiveAllocation}
          />
          <Input
            title={'Minimum Investor Commitment'}
            nameField={'minimum'}
            rules={{
              required: {
                value: true,
                message: 'Minimum is required'
              },
              pattern: {
                value: /^\d*$/,
                message: 'Enter only numbers'
              }
            }}
            isWarning={true}
            control={control}
            disabled={isModify && hasActiveAllocation}
            tooltip={'Provide the investment minimum. Entities with insufficient capacity will be flagged based on this value.'}
            tooltipWidth={300}
            onBlur={onBlur}
          />
        </Line>
        <Line isMobile={isMobile}>
          <DatePicker
            title={'Next Close'}
            nameField={'nextClose'}
            control={control}
            errors={errors}
            tooltip={'Set the Next Close date that you wish to track.  You can edit this date at any time.'}
            tooltipWidth={250}
          />
          <DatePicker title={'Final Close'} nameField={'finalClose'} control={control} errors={errors} tooltip={'Date the fund closes.'} />
          <Space />
        </Line>
        <Line isMobile={isMobile}>
          <Input
            title={'Target IRR'}
            nameField={'targetIRR'}
            control={control}
            rules={{
              pattern: {
                value: /^(\d+(?:[\.\,]\d{1,2})?)$/,
                message: 'Only numbers are allowed'
              }
            }}
            placeHolder={'0%'}
            onBlur={onBlur}
          />
          <Input
            title={'Target Yield'}
            nameField={'targetYield'}
            control={control}
            rules={{
              pattern: {
                value: /^(\d+(?:[\.\,]\d{1,2})?)$/,
                message: 'Only numbers are allowed'
              }
            }}
            placeHolder={'0%'}
            onBlur={onBlur}
          />
          <Input
            title={'Vintage'}
            nameField={'vintage'}
            control={control}
            rules={{
              pattern: {
                value: /^\d{4}$/,
                message: 'Enter a year using four digits'
              }
            }}
            placeHolder={'Enter a year using four digits'}
            tooltip={'Vintage aids in portfolio diversification.'}
            onBlur={onBlur}
          />
        </Line>
        <Line isMobile={isMobile}>
          <Select
            title={'Investment Owner'}
            nameField={'investmentOwner'}
            tooltip={OWNER_TOOL_TIP}
            tooltipWidth={300}
            control={control}
            rules={{
              required: true,
              pattern: {
                value: /^(?!Select$)/
              }
            }}
            defaultValue={defaultSelectValue}
            selects={[defaultSelectValue, ...getOwnerValues(investmentOwners)]}
            isWarning={true}
            setError={setError}
            errorMessage="Operations Owner is a required field"
            onBlur={onBlur}
          />
          <Select
            title={'Operations Owner'}
            nameField={'operationsOwner'}
            tooltip={OWNER_TOOL_TIP}
            tooltipWidth={300}
            control={control}
            rules={{
              required: true,
              pattern: {
                value: /^(?!Select$)/
              }
            }}
            defaultValue={defaultSelectValue}
            selects={[defaultSelectValue, ...getOwnerValues(operationsOwners)]}
            isWarning={true}
            setError={setError}
            errorMessage="Operations Owner is a required field"
            onBlur={onBlur}
          />
          <Select
            title={'Compliance Owner'}
            nameField={'complianceOwner'}
            tooltip={OWNER_TOOL_TIP}
            tooltipWidth={300}
            control={control}
            rules={{
              required: true,
              pattern: {
                value: /^(?!Select$)/
              }
            }}
            defaultValue={defaultSelectValue}
            selects={[defaultSelectValue, ...getOwnerValues(complianceOwners)]}
            isWarning={true}
            setError={setError}
            errorMessage="Compliance Owner is a required field"
            onBlur={onBlur}
          />
        </Line>
      </DetailsSectionWrap>
    </Section>
  );
};

const DetailsSectionWrap = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 20px;
`;

const Line = styled.span<{ isMobile: boolean; width50?: boolean }>`
  display: flex;
  justify-content: space-between;
  flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};
  width: ${({ width50, isMobile }) => (width50 && !isMobile ? '50%' : '100%')};
  gap: 30px;
  padding-right: ${({ width50, isMobile }) => (width50 && !isMobile ? '15px' : '0')};
  margin-top: 4px;
`;

const ManagerWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const Space = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
`;
