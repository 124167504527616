import { AiAssistantIcon } from '@/assets/static/icons/ai-assistant';
import { DragAndDropBigIcon } from '@/assets/static/icons/drag-and-drop-icon';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { NoRequired } from '@/components/dueDiligence-page/dueDiligenceRecord/tierSection';
import {
  ISection,
  ISectionFields
} from '@/components/dueDiligence-page/dueDiligenceRecord/types';
import DropdownActions from '@/components/table/dropdownActions';
import { blinkingFrame } from '@/components/table/styles';
import { useResponsive } from '@/hooks/use-responsive';
import { RedStarIcon } from '@/assets/icons/info-icons/redStar';
import styled, { css } from 'styled-components';
import { fieldsType } from './modals/addField';

interface SectionFieldsProps {
  fields: ISectionFields[];
  isLimitedEdits: boolean;
  section: ISection;
  isDragDisabled: boolean;
  canDragging: { action: boolean; id: string };
  openPromptEditor: (field: ISectionFields) => void;
  openModalWindow: (
    type: string,
    id: string,
    row: ISectionFields | ISection,
    rowType: string
  ) => void;
}

export const SectionFields = ({
  fields,
  isLimitedEdits,
  section,
  isDragDisabled,
  canDragging,
  openPromptEditor,
  openModalWindow
}: SectionFieldsProps) => {
  const { isMobile } = useResponsive();

  return (
    <Droppable droppableId={section.id} type="FIELDS">
      {(provided) => (
        <div ref={provided.innerRef} {...provided.droppableProps}>
          {fields.map((field, fieldIndex) => (
            <Draggable
              key={field.id}
              draggableId={field.id}
              index={fieldIndex}
              isDragDisabled={isLimitedEdits || isDragDisabled}
            >
              {(provided, snapshot) => (
                <div
                  {...provided.dragHandleProps}
                  {...provided.draggableProps}
                  ref={provided.innerRef}
                >
                  <RowWrapper
                    isDragging={snapshot.isDragging}
                    disabled={false}
                    isMobile={isMobile}
                    loading={
                      !canDragging.action && canDragging.id === field.id ? 1 : 0
                    }
                  >
                    <InfoRowCell>
                      {!isLimitedEdits && (
                        <DragAndDropIconWrap id="dragIcon">
                          <DragAndDropBigIcon
                            style={{ position: 'relative', top: '3px' }}
                          />
                        </DragAndDropIconWrap>
                      )}
                      {field.required ? (
                        <RedStarIcon width={10} height={10} />
                      ) : (
                        <NoRequired />
                      )}
                      {field.name}
                    </InfoRowCell>
                    <LinkedFieldWrap>
                      {fieldsType[field.type as keyof typeof fieldsType]}
                      {field.linkedField && (
                        <LinkedField>
                          (Linked Field:{' '}
                          {field.linkedField.funnelTierSection.funnelTier.name}{' '}
                          - {field.linkedField.funnelTierSection.name} -{' '}
                          {field.linkedField.name})
                        </LinkedField>
                      )}
                    </LinkedFieldWrap>
                    <ActionWrap isMobile={isMobile}>
                      <PromptWrap onClick={() => openPromptEditor(field)}>
                        <CountWrap>
                          {field.promptGroup ? (
                            <AiAssistantIcon
                              width={18}
                              height={17}
                              fill="#373F4E"
                            />
                          ) : (
                            <>-</>
                          )}
                        </CountWrap>
                      </PromptWrap>
                      <ActionDropdownWrapper isMobile={isMobile}>
                        <DropdownActions
                          showListItems={false}
                          isMobile={isMobile}
                          isListCenter
                          items={
                            isLimitedEdits
                              ? [
                                  {
                                    name: 'Configure AI Assistant',
                                    onClick: () => openPromptEditor(field)
                                  },
                                  {
                                    name: 'Edit',
                                    onClick: () =>
                                      openModalWindow(
                                        'add-field',
                                        field.id,
                                        field,
                                        'field'
                                      )
                                  }
                                ]
                              : [
                                  {
                                    name: 'Configure AI Assistant',
                                    onClick: () => openPromptEditor(field)
                                  },
                                  {
                                    name: 'Edit',
                                    onClick: () =>
                                      openModalWindow(
                                        'add-field',
                                        field.id,
                                        field,
                                        'field'
                                      )
                                  },
                                  {
                                    name: 'Delete',
                                    onClick: () =>
                                      openModalWindow(
                                        'delete-field',
                                        field.id,
                                        section,
                                        'section'
                                      )
                                  }
                                ]
                          }
                        />
                      </ActionDropdownWrapper>
                    </ActionWrap>
                  </RowWrapper>
                </div>
              )}
            </Draggable>
          ))}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
};

export const RowWrapper = styled.div<{
  disabled: boolean;
  isDragging: boolean;
  isMobile: boolean;
  loading?: boolean | number;
}>`
  display: flex;
  align-items: ${({ isMobile }) => (isMobile ? 'flex-start' : 'center')};
  flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};
  gap: 20px;
  padding: 20px 10px;
  border-bottom: 1px solid #c7cbd2;
  transition: 0.2s;
  background-color: #ffffff;
  box-shadow: ${({ isDragging }) =>
    isDragging ? '4px 0px 8px 0px rgba(0, 0, 0, 0.4)' : 'none'};

  opacity: ${({ loading }) => (loading ? 0.5 : 1)};
  pointer-events: ${({ loading }) => (loading ? 'none' : 'auto')};
  animation: ${({ loading }) => (loading ? blinkingFrame : null)} 1.5s infinite;

  &:hover #dragIcon {
    opacity: 1;
  }

  &:hover #aiIcon {
    opacity: 1;
  }

  &:hover #editTodoButton {
    opacity: 1;
  }

  &:hover #infoWrap {
    opacity: 1;
  }

  &:hover {
    background-color: ${({ disabled }) => (disabled ? 'none' : '#f0f1f3')};
    transition: background-color 100ms ease;
  }
`;

export const InfoRowCell = styled.div`
  width: 40%;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #2e2e2e;
  display: flex;
  gap: 10px;
  align-items: center;
`;

export const DragAndDropIconWrap = styled.div`
  opacity: 0;
`;

const CountWrap = styled.div`
  background-color: #f4f5f6;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  color: #2e2e2e;
  width: 53px;
  height: 28px;
  font-size: 13px;
  margin-right: 6px;
`;

const LinkedFieldWrap = styled.div`
  display: flex;
  gap: 5px;
  font-size: 13px;
`;

const LinkedField = styled.div``;

export const ActionWrap = styled.div<{ isMobile: boolean }>`
  display: flex;
  align-items: center;
  gap: 15px;
  margin-left: auto;
`;

const PromptWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  cursor: pointer;
`;

export const ActionDropdownWrapper = styled.div<{ isMobile: boolean }>`
  width: auto;

  ${({ isMobile }) =>
    isMobile &&
    css`
      margin: 0 auto;
    `};
`;
