import TableHeader from '@/components/table/fat-tableHeader';
import { ISort } from '@/components/table/types';
import { useResponsive } from '@/hooks/use-responsive';
import styled from 'styled-components';

import { Loader } from '@/components/loaders/loader';
import { TableBodyWrapper, TableWrapper } from '@/components/table/styles';
import { transactionsTableColumns } from '../constatnts';
import { IListEntityTransactionsData } from '../types';
import MobileTable from './mobileTable';
import TableBody from './tableBody';

interface TableTransactionsProps {
  tableRows: IListEntityTransactionsData[];
  sort: ISort;
  setSort: (value: ISort) => void;
  loading: boolean;
}

const TableTransactions = ({ tableRows, sort, setSort, loading }: TableTransactionsProps) => {
  const { isMobile, isTablet } = useResponsive();

  return (
    <>
      {isMobile ? (
        <>{loading ? <Loader /> : <MobileTable tableRows={tableRows} />}</>
      ) : (
        <TableWrapper padding="0">
          <CustomTableHeader isTablet={isTablet} refetch={() => {}} columns={transactionsTableColumns} savedSort={sort} savedSetSort={setSort} />
          <TableBodyWrapper>
            {loading ? (
              <Loader />
            ) : tableRows ? (
              <>
                <TableBody tableRows={tableRows} />
              </>
            ) : null}
          </TableBodyWrapper>
        </TableWrapper>
      )}
    </>
  );
};

export default TableTransactions;

const CustomTableHeader = styled(TableHeader)<{ isTablet: boolean }>`
  position: sticky;
  top: ${({ isTablet }) => (isTablet ? '187px' : '137px')};
  z-index: 8;
  padding-top: 0;
`;
