export function removeDuplicates(array: any, key: string) {
  let seen = new Set();
  return array.filter((obj: any) => {
    let value = obj[key];
    if (seen.has(value)) {
      return false;
    } else {
      seen.add(value);
      return true;
    }
  });
}

export const allowFileExtensions = (files: FileList | null, fileExtensions: string[]) => {
  return Array.from(files || []).filter((file: File) => {
    const fileExt = file.name.split('.').pop()?.toLowerCase();
    if ([...fileExtensions.map(item => item.toLowerCase())].includes(fileExt || '')) {
      return file;
    }
  });
};
