import Input from '@/components/fat-basicComponents/input';
import { useResponsive } from '@/hooks/use-responsive';
import { Control, Controller } from 'react-hook-form';
import { TwoColumnEntityWrapper } from '..';
import { Section } from '../../fat-investorDetails-page/fat-entityDetails-page/edit-entity/section';
import { CreateInvestorDto } from '../types';

interface DataIntegrationProps {
  control: Control<CreateInvestorDto, any>;
  isSectionOpen: boolean;
  saveSectionState: (id: string, state: { isSectionOpen: boolean }) => void;
}

export const DataIntegration = ({ control, isSectionOpen, saveSectionState }: DataIntegrationProps) => {
  const { isMobile } = useResponsive();

  return (
    <Section title="Data Integration" id="dataIntegrationSection" openSection={isSectionOpen} saveSectionState={saveSectionState} collapsable>
      <TwoColumnEntityWrapper isMobile={isMobile}>
        <Controller
          name="advisorCRMId"
          control={control}
          render={({ field: { onChange, value } }) => (
            <Input
              label="Advisor CRM ID"
              value={value}
              onChange={(e) => {
                onChange(e.target.value);
              }}
              tooltipWidth={400}
              tooltip="This identifier is used when bulk updating entities using the investor entity upload file or through an integration with an external CRM."
              styleType="form"
            />
          )}
        />
        <Controller
          name="advisorReportingAccountNumber"
          control={control}
          render={({ field: { onChange, value } }) => (
            <Input
              label="Advisor Reporting Account Number"
              value={value}
              onChange={(e) => {
                onChange(e.target.value);
              }}
              tooltipWidth={400}
              tooltip="This identifier is used when you export reporting data for upload into your reporting system. If you do not provide an ID reporting data for this entity will not be included in the reporting data export."
              styleType="form"
            />
          )}
        />
      </TwoColumnEntityWrapper>
      <TwoColumnEntityWrapper isMobile={isMobile}>
        <Input
          label="Administrator Code"
          tooltipWidth={400}
          tooltip="This is a static identifier assigned by the fund administrator"
          styleType="disabled"
        />
      </TwoColumnEntityWrapper>
    </Section>
  );
};
