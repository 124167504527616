import { Loader } from '@/components/loaders/loader';
import TableHeader from '@/components/table/fat-tableHeader';
import { TableBodyWrapper, TableWrapper } from '@/components/table/styles';
import { ISort } from '@/components/table/types';
import { useResponsive } from '@/hooks/use-responsive';
import { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';
import { tenantsTableColumns } from '../constants';
import { IUser } from '../types';
import MobileTable from './mobileTable';
import TableBody from './tableBody';

interface TenantsTableProps {
  tenantsTableTableData: any;
  sort: ISort;
  setSort: Dispatch<SetStateAction<ISort>>;
  refetch: () => void;
  loading: boolean;
  openModalWindow: (user: IUser, type: string) => void;
}

const TenantsTable = ({ sort, setSort, refetch, loading, tenantsTableTableData, openModalWindow }: TenantsTableProps) => {
  const { isMobile, isTablet } = useResponsive();

  return (
    <TableWrapper padding="0">
      {isMobile ? (
        <>{loading ? <Loader /> : <MobileTable tenantsTableTableData={tenantsTableTableData} openModalWindow={openModalWindow} />}</>
      ) : (
        <>
          <CustomTableHeader isTablet={isTablet} refetch={refetch} columns={tenantsTableColumns} savedSort={sort} savedSetSort={setSort} />
          <TableBodyWrapper>
            {loading ? <Loader /> : <TableBody tenantsTableTableData={tenantsTableTableData} openModalWindow={openModalWindow} />}
          </TableBodyWrapper>
        </>
      )}
    </TableWrapper>
  );
};

export default TenantsTable;

const CustomTableHeader = styled(TableHeader)<{ isTablet: boolean }>`
  position: sticky;
  top: ${({ isTablet }) => (isTablet ? '130px' : '80px')};
  z-index: 8;
  padding-top: 0;
`;
