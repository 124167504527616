export const columns = [
  { title: 'Entity Code', key: 'ENTITY_CODE', width: 20, keySort: 'entity code' },
  { title: 'Entity Name', key: 'ENTITY_NAME', width: 30, keySort: 'entity name' },
  { title: 'Assignment', key: 'ASSIGNMENT', width: 25, keySort: 'assignment' },
  { title: 'Status', key: 'STATUS', width: 20, keySort: 'status' },
];

export const entityCodesStatusSuccess = {
  allMatched: 'All Entity Codes Matched',
  continue: 'Please continue to the next step',
  orContinue: 'Or continue to next step',
  viewAll: 'View All Entity Codes',
};

export const entityCodesStatusError = {
  text: 'Unrecognized Entity Codes',
  description:
    'This file contains unrecognized Entity Codes that need to be mapped. Please assign each Entity Code to an Investor Entity before proceeding.',
};
