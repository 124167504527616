import { CloseInfoIcon } from '@/assets/icons/info-icons/closeInfo';
import { NotesIcon } from '@/assets/icons/notes';
import { NoteDataType } from '@/components/dueDiligence-page/dueDiligenceRecord/types';
import { useResponsive } from '@/hooks/use-responsive';
import { useClickOutside } from '@/hooks/useClickOutside';
import { format } from 'date-fns';
import { useRef } from 'react';
import styled, { useTheme } from 'styled-components';

type NotesProps = {
  data: NoteDataType | null;
  isOpen: boolean;
  onClick: () => void;
};

const Notes = ({ data, isOpen, onClick }: NotesProps) => {
  const theme = useTheme();
  const { isMobile } = useResponsive();

  const ref = useRef<HTMLDivElement | null>(null);

  useClickOutside<HTMLDivElement>(ref, () => onClick());

  if (!data || isMobile) return <></>;
  return (
    <>
      <NotesWrap id="notes" ref={ref} isOpen={isOpen}>
        <NotesTitleWrap id="notes">
          <TitleWrap id="notes">
            <NotesIcon id="notes" fill={theme.font.strong} />
            <Title id="notes">Notes</Title>
          </TitleWrap>
          <CloseBtn onClick={onClick}>
            <CloseInfoIcon width={14} height={14} fill={theme.action.primary} />
          </CloseBtn>
        </NotesTitleWrap>
        <NotesBody id="notes">
          <NoteDetails>
            <NoteTask>Task: {data.task}</NoteTask>
            <NoteTask>{`Assigned To ${data.assignedTo?.firstName + ' ' + data?.assignedTo?.lastName} by ${
              data.assignedBy?.firstName + ' ' + data.assignedBy?.lastName
            }`}</NoteTask>
            <NoteUpdateAt>{data.updateAt ? format(new Date(data.updateAt), 'LLL d, yyyy') : 'No date'}</NoteUpdateAt>
          </NoteDetails>
          <NoteText>{data.note}</NoteText>
        </NotesBody>
      </NotesWrap>
    </>
  );
};

const NoteDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const NoteTask = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  color: ${({ theme }) => theme.font.base};
`;

const NoteUpdateAt = styled.div`
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  color: ${({ theme }) => theme.font.weak};
`;

const NoteText = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: ${({ theme }) => theme.font.base};
  padding: 15px 0;
  border-bottom: 1px solid ${({ theme }) => theme.border.base};
`;

const NotesBody = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  overflow: auto;
`;

const Title = styled.div`
  font-family: Blinker, serif;
  font-size: 28px;
  font-weight: 600;
  line-height: 39px;
  color: ${({ theme }) => theme.font.strong};
`;

const TitleWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 9px;
`;

const CloseBtn = styled.div`
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  min-width: 40px;
  border-radius: 50%;
  border: 1px solid ${({ theme }) => theme.action.primary};
  background: ${({ theme }) => theme.layer.base};
`;

const NotesTitleWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const NotesWrap = styled.div<{ isOpen: boolean }>`
  display: flex;
  overflow: ${({ isOpen }) => (isOpen ? 'visible' : 'hidden')};
  flex-direction: column;
  padding: ${({ isOpen }) => (isOpen ? '20px 40px' : 0)};
  position: fixed;
  top: 60px;
  right: 0;
  width: ${({ isOpen }) => (isOpen ? '600px' : 0)};
  height: calc(100vh - 60px);
  background: ${({ theme }) => theme.layer[1]};
  z-index: 125;
  box-shadow: -14px 0 12px -10px rgba(0, 0, 0, 0.15);
  transition:
    all 0.3s,
    height 1ms;
`;

export default Notes;
