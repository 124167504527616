import { ApprovedIcon } from '@/assets/icons/info-icons/approved';
import ModalWrappedContent from '@/components/fat-basicComponents/modal/modalWrappedContent';
import styled, { useTheme } from 'styled-components';

interface SuccessProps {
  onClose: () => void;
  isOpen: boolean;
}

const Success = ({ onClose, isOpen }: SuccessProps) => {
  const theme = useTheme();

  return (
    <ModalWrappedContent
      isOpen={isOpen}
      onClose={onClose}
      showCancelButton={false}
      confirmButton={{
        name: 'OK',
        onClick: onClose
      }}
    >
      <ModalBody>
        <ApprovedIcon width={75} height={75} fill={theme.context.success} />
        <ModalTitle>Update Successful</ModalTitle>
      </ModalBody>
    </ModalWrappedContent>
  );
};

export default Success;

const ModalTitle = styled.div`
  color: ${({ theme }) => theme.font.strong};
  text-align: center;
  font-size: 33px;
  font-weight: 400;
  line-height: 46.2px;
`;

const ModalBody = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  gap: 10px;
  margin: 30px 0 30px 0;
`;
