import { useQuery } from '@apollo/client';
import { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';

import { LoaderOnWholeScreen } from '@/components/loaders/loader-on-whole-screen';
import { ToDoInfo } from '@/components/todo-page/todo-info';
import ToDoList from '@/components/todo-page/todo-list';
import guid from '@/utils/guid';
import Header from '../fat-header';
import { TASKS_QUERY, TASKS_QUERY_FOR_REDIRECT } from './queries';
import EmptyToDo from './todo-list/empty-todo';

export const ToDo = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const isFirstOpen = searchParams.get('isFirstOpen') === 'true' || false;

  const [todoPageLoading, setTodoPageLoading] = useState(true);

  const { loading: assignedToMeTaskLoading } = useQuery(TASKS_QUERY_FOR_REDIRECT, {
    variables: {},
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      if (isFirstOpen && data.AllocationTasks.assignedToMeTaskCount <= 0) {
        setSearchParams(
          (prev) => {
            prev.delete('isFirstOpen');
            return prev;
          },
          { replace: true }
        );
        navigate('/dashboard', { replace: true });
        setTodoPageLoading(false);
      } else {
        setTodoPageLoading(false);
      }
    }
  });

  const { data, loading, refetch, previousData, networkStatus } = useQuery(TASKS_QUERY, {
    variables: {},
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network'
  });

  return (
    <Container>
      {todoPageLoading || loading || assignedToMeTaskLoading || data === undefined ? (
        <LoaderOnWholeScreen size={60} />
      ) : (
        <>
          <Header title={'To Do List'} />
          <ToDoInfo data={data.AllocationTasks} key={guid()} />
          {data.AllocationTasks.assignedToMeTaskCount === 0 && data.AllocationTasks.ownedByMeNotAssignedToMeTaskCount === 0 ? (
            <EmptyToDo />
          ) : (
            <ToDoList data={JSON.parse(data.AllocationTasks.investmentsSummary)} key={guid()} />
          )}
        </>
      )}
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 48px;
  overflow-x: clip;
`;
