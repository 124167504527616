import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';

import { DeleteItemIcon } from '@/assets/static/icons/delete-item';
import { DocumentIcon } from '@/assets/static/icons/document';
import { OpenFileIcon } from '@/assets/static/icons/open-file';
import Label from '@/components/basicComponents/label';
import { DropFileArea } from '@/components/dueDiligence-page/dueDiligenceRecord/dropFileArea';
import { isImageExtension } from '@/components/dueDiligence-page/dueDiligenceRecord/utils';
import { IOfferingMaterialsAttachments } from '@/components/fat-dealCatalog-page/types';
import {
  BlockTypeSelect,
  BoldItalicUnderlineToggles,
  CreateLink,
  headingsPlugin,
  linkDialogPlugin,
  linkPlugin,
  listsPlugin,
  ListsToggle,
  MDXEditor,
  MDXEditorMethods,
  Separator,
  toolbarPlugin
} from '@mdxeditor/editor';
import '@mdxeditor/editor/style.css';
import { useController } from 'react-hook-form';
import styled, { useTheme } from 'styled-components';
import { Section } from '../../section';

interface OfferingMaterialsSectionProps {
  control: any;
  nameField: string;
  files: File[];
  setFiles: Dispatch<SetStateAction<File[]>>;
  uploadedFiles: IOfferingMaterialsAttachments[] | [];
  setUploadedFiles: Dispatch<SetStateAction<IOfferingMaterialsAttachments[] | []>>;
  deletedFiles: string[];
  setDeletedFiles: Dispatch<SetStateAction<string[]>>;
  setModalWindow: Dispatch<
    SetStateAction<{
      isOpen: boolean;
      type: string;
    }>
  >;
}

const fileExtensions = ['PDF', 'DOC', 'DOCX', 'XLS', 'XLSX', 'CSV', 'JPEG', 'PNG', 'GIF'];

export const OfferingMaterialsSection = ({
  control,
  nameField,
  files,
  setFiles,
  uploadedFiles,
  setUploadedFiles,
  deletedFiles,
  setDeletedFiles,
  setModalWindow
}: OfferingMaterialsSectionProps) => {
  const theme = useTheme();
  const {
    field: { value, ...filedProperties }
  } = useController({ name: nameField, control });

  const markdownEditorRef = useRef<MDXEditorMethods>(null);
  const [drag, setDrag] = useState(false);

  useEffect(() => {
    if (markdownEditorRef?.current && !markdownEditorRef.current.getMarkdown()) {
      markdownEditorRef.current.setMarkdown(value);
    }
  }, [value]);

  const allowFileExtensions = (files: FileList | null) => {
    return Array.from(files || []).filter((file: File) => {
      const fileExt = file.name.split('.').pop()?.toLowerCase();
      if ([...fileExtensions.map((item) => item.toLowerCase())].includes(fileExt || '')) {
        return file;
      }
    });
  };

  const onDropHandler = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const uploadFiles = e.dataTransfer.files;

    setDrag(false);
    if (allowFileExtensions(uploadFiles).length) {
      setFiles([...(files || []), ...allowFileExtensions(uploadFiles)]);
      return;
    }
    setModalWindow({ isOpen: true, type: 'not-supported' });
  };

  const handleUploadFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const uploadFiles = e.target.files;

    if (allowFileExtensions(uploadFiles).length) {
      setFiles([...(files || []), ...(allowFileExtensions(uploadFiles) || [])]);
      return;
    }
    setModalWindow({ isOpen: true, type: 'not-supported' });
  };

  const deleteUploadedFile = (id: string) => {
    setUploadedFiles(uploadedFiles.filter((item) => item.id !== id));
    setDeletedFiles([...deletedFiles, id]);
  };

  const deletePreviewItem = (file: File, fileIndex: number) => {
    setFiles(files.filter((item: File, index) => index !== fileIndex));
  };

  return (
    <Section title="Offering Materials">
      <div>
        <OfferingMaterials>
          <div>
            <Label required={false}>Description</Label>
            <div>
              <CustomMDXEditor
                markdown={value}
                onChange={filedProperties.onChange}
                ref={markdownEditorRef}
                plugins={[
                  listsPlugin(),
                  headingsPlugin(),
                  linkPlugin(),
                  linkDialogPlugin(),
                  toolbarPlugin({
                    toolbarContents: () => (
                      <>
                        <BoldItalicUnderlineToggles />
                        <CreateLink />
                        <Separator />
                        <ListsToggle options={['bullet', 'number']} />
                        <Separator />
                        <BlockTypeSelect />
                      </>
                    )
                  })
                ]}
              />
            </div>
          </div>
          {uploadedFiles.length || files.length ? (
            <>
              <PreviewWrapper>
                {uploadedFiles.map((item: IOfferingMaterialsAttachments, index: number) => (
                  <PreviewItemWrap key={item.asset.assetKey + index}>
                    <PreviewItem>
                      <DocumentIcon width="25" height="24" fill={theme.font.base} />
                      {isImageExtension(item.asset.assetKey) ? (
                        <ImageWrapper href={item.asset.url} target="_blank">
                          <ImageItem src={item.asset.url} alt={item.asset.assetKey.split('/').pop()} />
                        </ImageWrapper>
                      ) : (
                        <FileWrapper>
                          <FileItem href={item.asset.url} target="_blank">
                            {item.asset.assetKey.split('/').pop()}
                          </FileItem>
                        </FileWrapper>
                      )}
                      <ControlButtons>
                        <OpenButton href={item.asset.url} target="_blank">
                          <OpenFileIcon fill={theme.font.action} />
                        </OpenButton>
                        <DeleteButton onClick={() => deleteUploadedFile(item.id)}>
                          <DeleteItemIcon fill={theme.context.error} />
                        </DeleteButton>
                      </ControlButtons>
                    </PreviewItem>
                  </PreviewItemWrap>
                ))}
                {files
                  ?.map((file, index) => (
                    <PreviewItemWrap key={file.lastModified + index}>
                      <PreviewItem>
                        <DocumentIcon width="25" height="24" fill={theme.font.base} />
                        {file.type.includes('image') ? (
                          <ImageWrapper href={URL.createObjectURL(file)} target="_blank">
                            <ImageItem src={URL.createObjectURL(file)} alt={file.name} />
                          </ImageWrapper>
                        ) : (
                          <FileWrapper>
                            <FileItem href={URL.createObjectURL(file)} target="_blank">
                              {file.name}
                            </FileItem>
                          </FileWrapper>
                        )}
                        <ControlButtons>
                          <OpenButton href={URL.createObjectURL(file)} target="_blank">
                            <OpenFileIcon fill={theme.font.action} />
                          </OpenButton>
                          <DeleteButton onClick={() => deletePreviewItem(file, index)}>
                            <DeleteItemIcon fill={theme.context.error} />
                          </DeleteButton>
                        </ControlButtons>
                      </PreviewItem>
                    </PreviewItemWrap>
                  ))
                  .reverse()}
              </PreviewWrapper>
            </>
          ) : (
            <></>
          )}
        </OfferingMaterials>
        <DropFileArea
          id="offeringMaterials"
          fileExtensions={fileExtensions}
          handleUploadFile={handleUploadFile}
          onDropHandler={onDropHandler}
          drag={drag}
          setDrag={setDrag}
        />
      </div>
    </Section>
  );
};

const OfferingMaterials = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const CustomMDXEditor = styled(MDXEditor)`
  border: 1px solid ${({ theme }) => theme.border.base};
  border-radius: 7px;

  .mdxeditor-toolbar {
    top: 59px;
  }

  &.mdxeditor-popup-container {
    z-index: 1000;
  }

  .mdxeditor-root-contenteditable > div {
    cursor: text;
    min-height: 240px;
    max-height: 600px;
    overflow: auto;
  }

  .mdxeditor-root-contenteditable ul,
  .mdxeditor-root-contenteditable ol {
    padding-left: 26px;
  }
`;

const PreviewWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-height: 200px;
  overflow: auto;
`;

const PreviewItemWrap = styled.div`
  width: 100%;
  height: 100%;
  padding: 10px 20px;
  border: 1px solid ${({ theme }) => theme.context.success};
  border-radius: 5px;
  background-color: ${({ theme }) => theme.layer[1]};
`;

const PreviewItem = styled.div`
  border-left: 5px solid ${({ theme }) => theme.context.success};
  padding-left: 7.5px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 7.5px;
`;

const ControlButtons = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
`;

const OpenButton = styled.a`
  border: 1px solid ${({ theme }) => theme.font.action};
  border-radius: 20px;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const DeleteButton = styled(OpenButton)`
  border: 1px solid ${({ theme }) => theme.context.error};
`;

const ImageWrapper = styled.a`
  display: block;
  width: 163px;
  height: 100px;
  overflow: hidden;
`;

const ImageItem = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const FileWrapper = styled.div``;

const FileItem = styled.a`
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  text-decoration: none;
  color: inherit;
  cursor: pointer;
`;
