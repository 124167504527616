import { gql } from '@apollo/client';

export const TASKS_QUERY = gql`
  query AllocationTasks {
    AllocationTasks {
      newTaskCount
      oldTaskCount
      assignedToMeTaskCount
      ownedByMeNotAssignedToMeTaskCount
      investmentsSummary
    }
  }
`;

export const TASKS_QUERY_FOR_REDIRECT = gql`
  query AllocationTasks {
    AllocationTasks {
      assignedToMeTaskCount
    }
  }
`;
