import { DeleteBucketIcon } from '@/assets/static/icons/delete-bucket';
import { IRecordData } from '@/components/dueDiligence-page/types';
import ModalWrappedContent from '@/components/fat-basicComponents/modal/modalWrappedContent';
import styled, { useTheme } from 'styled-components';

interface DeleteDealProps {
  onClose: () => void;
  isOpen: boolean;
  handleDeleteDeal: (id: string) => void;
  row: IRecordData | null;
}

const DeleteDeal = ({ onClose, isOpen, handleDeleteDeal, row }: DeleteDealProps) => {
  const theme = useTheme();

  const saveValue = () => {
    if (!row) return;
    handleDeleteDeal(row.id);
    onClose();
  };

  return (
    <ModalWrappedContent isOpen={isOpen} onClose={onClose} confirmButton={{ name: 'Yes, Delete', onClick: saveValue }}>
      <ModalContainer>
        <DeleteBucketIcon fill={theme.font.disabled} />
        <ModalTitel>Please confirm you want to delete this deal.</ModalTitel>
        <ModalDescription>This deal will be permanently deleted. This action cannot be undone.</ModalDescription>
      </ModalContainer>
    </ModalWrappedContent>
  );
};

export default DeleteDeal;

const ModalContainer = styled.div`
  font-weight: 400;
  padding: 10px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;

const ModalTitel = styled.div`
  font-size: 33px;
  text-align: center;
  color: ${({ theme }) => theme.font.strong};
`;

const ModalDescription = styled.div`
  font-size: 16px;
  line-height: 27px;
  text-align: center;
  color: ${({ theme }) => theme.font.base};
`;
