import * as React from 'react';

export const ArrowDown = ({ ...props }: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      onClick={props.onClick || undefined}
      width={props.width || 11}
      height={props.height || 8}
      style={props?.style}
      viewBox="0 0 11 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.88847 0.912598L5.06348 4.7276L1.23848 0.912598L0.0634766 2.0876L5.06348 7.0876L10.0635 2.0876L8.88847 0.912598Z"
        fill={props.fill || '#F4F5F6'}
      />
    </svg>
  );
};
