import { format } from 'date-fns';
import styled, { useTheme } from 'styled-components';

import { ApprovedIcon } from '@/assets/icons/info-icons/approved';
import { ClockIcon } from '@/assets/icons/info-icons/clock';
import { FlagIcon } from '@/assets/icons/info-icons/flag';
import { RejectedIcon } from '@/assets/icons/info-icons/rejected';
import DropdownActions from '@/components/table/fat-dropdownActions';
import { useResponsive } from '../../../hooks/use-responsive';
import { blinkingFrame } from '../../table/styles';
import { ITier, RecordStatusEnum, TierStatusEnum } from './types';

type TierStatusProps = {
  activeTab: string;
  tierData: ITier;
  openModalWindow: (type: string, row: ITier) => void;
  updateRecordStatus: (variables: any) => void;
  updateTierStatus: (variables: any) => void;
  recordId: string | null;
  isAllDone: boolean;
  isLastTier: boolean;
  recordStatus: RecordStatusEnum | null;
  loading: boolean;
  firstTierName: string;
};

const TierStatus = ({
  activeTab,
  tierData,
  updateRecordStatus,
  updateTierStatus,
  recordId,
  openModalWindow,
  isAllDone,
  isLastTier,
  recordStatus,
  loading = false,
  firstTierName
}: TierStatusProps) => {
  const { isMobile } = useResponsive();
  const theme = useTheme();

  const handleUpdateTierStatus = () => {
    updateRecordStatus({
      variables: {
        data: {
          id: recordId,
          status: RecordStatusEnum.ACTIVE
        }
      }
    });
    updateTierStatus({
      variables: {
        data: {
          id: tierData.data.id,
          status: TierStatusEnum.IN_PROGRESS
        }
      }
    });
  };

  const getDropdownInProgress = () => {
    const options = [];

    if (activeTab !== firstTierName) {
      if (isAllDone) {
        options.push(
          isLastTier
            ? {
                name: 'Approve Diligence',
                onClick: () => openModalWindow('approve-diligence', tierData)
              }
            : {
                name: 'Advance to Next Tier',
                onClick: () => openModalWindow('advance-to', tierData)
              }
        );

        options.push(
          {
            name: 'Reject',
            onClick: () => openModalWindow('reject-investment', tierData)
          },
          {
            name: 'Send Back To Previous Tier',
            onClick: () => openModalWindow('back-to', tierData)
          }
        );
      } else {
        options.push({
          name: 'Send Back To Previous Tier',
          onClick: () => openModalWindow('back-to', tierData)
        });
      }
    } else if (isAllDone) {
      options.push(
        isLastTier
          ? {
              name: 'Approve Diligence',
              onClick: () => openModalWindow('approve-diligence', tierData)
            }
          : {
              name: 'Advance to Next Tier',
              onClick: () => openModalWindow('advance-to', tierData)
            },
        {
          name: 'Reject',
          onClick: () => openModalWindow('reject-investment', tierData)
        }
      );
    }

    return options;
  };

  switch (tierData.data.status) {
    case TierStatusEnum.NOT_STARTED:
      return (
        <TierStatusWrap isMobile={isMobile}>
          <ClockIcon fill={theme.font.base} />
          <SeparateStick />
          <StatusText>
            <StatusText bold>{`${tierData.name}: `}</StatusText>Not Started
          </StatusText>
        </TierStatusWrap>
      );
    case TierStatusEnum.IN_PROGRESS:
      return (
        <TierStatusWrap isMobile={isMobile} loading={loading ? 1 : 0}>
          <FlagIcon fill={theme.font.base} />
          <SeparateStick />
          <StatusText>
            <StatusText bold>{`${tierData.name}: `}</StatusText>In Progress
          </StatusText>
          {getDropdownInProgress().length ? (
            <DropDownWrapper isMobile={isMobile}>
              <DropdownActions isMobile items={getDropdownInProgress()} />
            </DropDownWrapper>
          ) : (
            <></>
          )}
        </TierStatusWrap>
      );
    case TierStatusEnum.COMPLETED:
      return (
        <TierStatusWrap isMobile={isMobile} loading={loading ? 1 : 0}>
          <div>
            <ApprovedIcon fill={theme.context.success} />
          </div>
          <SeparateStick />
          <ApprovedStatusText>
            <ApprovedStatusText bold>{`${tierData.name}: `}</ApprovedStatusText>
            {`Completed By ${tierData.data.approver?.firstName} ${tierData.data.approver?.lastName} on 
            ${format(new Date(tierData.data.statusUpdatedAt), 'LLL d, yyyy')} `}
          </ApprovedStatusText>
          {recordStatus === RecordStatusEnum.APPROVED && isLastTier ? (
            <DropDownWrapper isMobile={isMobile}>
              <DropdownActions
                isMobile
                items={[
                  // { name: 'View Tier Report', onClick: () => null },
                  { name: 'Reopen Diligence', onClick: handleUpdateTierStatus }
                ]}
              />
            </DropDownWrapper>
          ) : (
            <></>
          )}
        </TierStatusWrap>
      );
    case TierStatusEnum.REJECTED:
      return (
        <TierStatusWrap isMobile={isMobile}>
          <div>
            <RejectedIcon fill={theme.context.error} />
          </div>
          <SeparateStick />
          <RejectedStatusText>
            <RejectedStatusText bold>{`${tierData.name}: `}</RejectedStatusText>
            {`Rejected By ${tierData.data.approver?.firstName} ${tierData.data.approver?.lastName} on 
            ${format(new Date(tierData.data.statusUpdatedAt), 'LLL d, yyyy')} `}
          </RejectedStatusText>
          {recordStatus === RecordStatusEnum.REJECTED ? (
            <DropDownWrapper isMobile={isMobile}>
              <DropdownActions
                isMobile
                items={[
                  // {
                  //   name: 'View Tier Report',
                  //   onClick: () => null,
                  // },
                  { name: 'Reopen Diligence', onClick: handleUpdateTierStatus }
                ]}
              />
            </DropDownWrapper>
          ) : (
            <></>
          )}
        </TierStatusWrap>
      );
    default:
      return <></>;
  }
};

export default TierStatus;

const TierStatusWrap = styled.div<{ isMobile: boolean; loading?: boolean | number }>`
  display: flex;
  align-items: center;
  width: ${({ isMobile }) => (isMobile ? '100%' : 'auto')};
  gap: ${({ isMobile }) => (isMobile ? '5px' : '20px')};

  opacity: ${({ loading }) => (loading ? 0.5 : 1)};
  pointer-events: ${({ loading }) => (loading ? 'none' : 'auto')};
  animation: ${({ loading }) => (loading ? blinkingFrame : null)} 1.5s infinite;
`;

const DropDownWrapper = styled.div<{ isMobile: boolean }>`
  padding-left: 10px;
  margin-left: ${({ isMobile }) => (isMobile ? 'auto' : '0')};
`;

const SeparateStick = styled.div`
  width: 1px;
  height: 33px;
  background-color: ${({ theme }) => theme.border.base};
`;

const StatusText = styled.span<{ bold?: boolean }>`
  font-weight: ${({ bold }) => (bold ? '700' : '600')};
  font-size: 16px;
  line-height: 22px;
  color: ${({ theme }) => theme.font.base};
`;

const ApprovedStatusText = styled(StatusText)`
  color: ${({ theme }) => theme.context.success};
`;

const RejectedStatusText = styled(StatusText)`
  color: ${({ theme }) => theme.context.error};
`;
