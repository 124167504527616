import * as React from 'react';

export const RemoveIcon = ({ ...props }: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.00271 8.09838V9.89623H14.0027V8.09838M9.5054 1.61912e-06C7.72491 -0.00106614 5.98411 0.526004 4.50324 1.51453C3.02237 2.50305 1.86798 3.90861 1.18612 5.55337C0.504263 7.19812 0.325579 9.00816 0.672673 10.7545C1.01977 12.5008 1.87705 14.105 3.13604 15.364C4.39504 16.623 5.99918 17.4802 7.74551 17.8273C9.49185 18.1744 11.3019 17.9957 12.9466 17.3139C14.5914 16.632 15.997 15.4776 16.9855 13.9968C17.974 12.5159 18.5011 10.7751 18.5 8.9946C18.4986 6.60952 17.5505 4.32255 15.864 2.63604C14.1775 0.949539 11.8905 0.00143195 9.5054 1.61912e-06ZM9.5054 16.1968C8.08072 16.1978 6.68773 15.7763 5.50263 14.9856C4.31753 14.1949 3.39357 13.0704 2.84763 11.7545C2.30169 10.4386 2.15829 8.99029 2.43558 7.59285C2.71286 6.19542 3.39837 4.9116 4.40539 3.90382C5.41242 2.89604 6.69571 2.20958 8.09294 1.93124C9.49017 1.65291 10.9386 1.79521 12.2549 2.34016C13.5712 2.88512 14.6964 3.80824 15.488 4.99275C16.2796 6.17725 16.7022 7.56992 16.7022 8.9946C16.6993 10.9029 15.9403 12.7324 14.5915 14.0822C13.2426 15.4321 11.4137 16.1925 9.5054 16.1968Z"
        fill={props.fill || '#4587EC'}
      />
    </svg>
  );
};
