import BasicTooltip from '@/components/basicComponents/tooltip';
import { Investment } from '@/components/fat-dealCatalog-page/types';
import { Loader } from '@/components/loaders/loader';
import TableHeader from '@/components/table/fat-tableHeader';
import NoResults from '@/components/table/noResults';
import RedirectTitleCell from '@/components/table/redirectTitleCell';
import { TableBodyWrapper, TableRowCell, TableRowWrapper, TableWrapper, TextCell } from '@/components/table/styles';
import { ISort } from '@/components/table/types';
import { useResponsive } from '@/hooks/use-responsive';
import { dateFormatter } from '@/utils/dateFormatter';
import { format } from 'date-fns';
import styled from 'styled-components';
import { isMoreThan90DaysInPast } from '../utils';
import { columns } from './../constatnts';

type TableProp = {
  sort: ISort;
  setSort: (value: ISort) => void;
  tableData: Investment[];
  loading: boolean;
  openManagerUpdateDetailsPage: (strategyId: string) => void;
};

const tooltipContent = (row: Investment) => {
  return (
    <TableBodyWrapper>
      <TableRowCell width={100}>
        <TextCell>Draft: {row.documentRecordMetadata.num_of_mu_draft}</TextCell>
      </TableRowCell>
      <TableRowCell width={100}>
        <TextCell>Published: {row.documentRecordMetadata.num_of_mu_published}</TextCell>
      </TableRowCell>
    </TableBodyWrapper>
  );
};

export const Table = ({ tableData, sort, setSort, loading, openManagerUpdateDetailsPage }: TableProp) => {
  const { isTablet } = useResponsive();

  return (
    <TableWrapper>
      <CustomTableHeader isTablet={isTablet} refetch={() => {}} columns={columns} savedSort={sort} savedSetSort={setSort} />
      <TableBodyWrapper>
        {loading ? (
          <Loader />
        ) : (
          <>
            {!tableData.length ? (
              <NoResults />
            ) : (
              <>
                {tableData.map((row) => (
                  <TableRowWrapper key={row.id}>
                    <TableRowCell width={columns[0].width} onClick={() => null}>
                      <RedirectTitleCell onClick={() => openManagerUpdateDetailsPage(row.id)}>{row.name}</RedirectTitleCell>
                    </TableRowCell>
                    <TableRowCell width={columns[1].width}>
                      <TextCell>{row.managerId}</TextCell>
                    </TableRowCell>
                    <TableRowCell width={columns[2].width}>
                      <TextCell>{row.securityStatus === 'Terminated' ? 'Terminated' : row.investmentWorkflowTransitionTask.name}</TextCell>
                    </TableRowCell>
                    <TableRowCell width={columns[3].width}>
                      <TextCell>
                        <CellWithTooltip>
                          {row.documentRecordMetadata.num_of_mu}
                          <TooltipWrapper id="infoWrap">
                            <BasicTooltip tooltipContent={tooltipContent(row)} withTooltipIcon zIndex={1000} />
                          </TooltipWrapper>
                        </CellWithTooltip>
                      </TextCell>
                    </TableRowCell>
                    <TableRowCell width={columns[4].width}>
                      <LastPublishDateCell isMoreThan90DaysInPast={isMoreThan90DaysInPast(row.documentRecordMetadata.last_mu_publish_date)}>
                        {row.documentRecordMetadata.last_mu_publish_date &&
                          dateFormatter(format(new Date(row.documentRecordMetadata.last_mu_publish_date), 'yyyy-MM-dd'), 'yyyy-MM-dd')}
                      </LastPublishDateCell>
                    </TableRowCell>
                  </TableRowWrapper>
                ))}
              </>
            )}
          </>
        )}
      </TableBodyWrapper>
    </TableWrapper>
  );
};

const CustomTableHeader = styled(TableHeader)<{ isTablet: boolean }>`
  position: sticky;
  top: ${({ isTablet }) => (isTablet ? '187px' : '137px')};
  z-index: 8;
  padding-top: 0;
`;

const LastPublishDateCell = styled(TextCell)<{ isMoreThan90DaysInPast: boolean }>`
  font-weight: 600;
  color: ${({ isMoreThan90DaysInPast, theme }) => (isMoreThan90DaysInPast ? theme.context.error : theme.font.base)};
`;

const CellWithTooltip = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const TooltipWrapper = styled.div`
  opacity: 0;
`;
