import {
  MobileRow,
  MobileRowTitle,
  MobileRowValue,
  MobileTableRowWrap,
  MobileTableWrapper,
  NoResults
} from '@/components/table/mobileTable/mobileTable';

import BasicTooltip from '@/components/basicComponents/tooltip';
import FormattingTooltip from '@/components/basicComponents/tooltip/dollarsTooltip';
import { formatterClient } from '@/components/clients-page/utils';
import { TableBodyWrapper, TableRowCell, TextCell } from '@/components/table/styles';
import { formatCash } from '@/utils/formatCash';
import styled from 'styled-components';
import {
  overCommitmentRatioModeColumns,
  overCommitmentRatioModeSimplifiedColumns,
  pacingModelModeColumns,
  pacingModelModeSimplifiedColumns
} from '../../constatnts';
import { AssetClassTargetAllocationDto, SubAssetClassTargetAllocationDto } from '../../types';
import CapacityCell from '../cell/capacityCell';
import MobileSumFooter from './mobileSumFooter';

interface MobileTableProps {
  investorSummaryRows: AssetClassTargetAllocationDto[] | undefined;
  loading: boolean;
  ignoreAllocationTargets: boolean;
  commitmentPlansTotal: number | null;
  targetOCRatio: number | null;
}

const economicExposureToolTipContent = (row: AssetClassTargetAllocationDto | SubAssetClassTargetAllocationDto) => {
  return (
    <TableBodyWrapper>
      <TableRowCell width={100}>
        <TextCell>Current NAV: {formatterClient.format(row.currentNAV)}</TextCell>
      </TableRowCell>
      <TableRowCell width={100}>
        <TextCell>Unfunded Commitment: {formatterClient.format(row.unfundedCommitments)}</TextCell>
      </TableRowCell>
      <TableRowCell width={100}>
        <TextCell>Economic Exposure: {formatterClient.format(row.economicExposure)}</TextCell>
      </TableRowCell>
    </TableBodyWrapper>
  );
};

const MobileTable = ({ investorSummaryRows, loading, ignoreAllocationTargets, commitmentPlansTotal, targetOCRatio }: MobileTableProps) => {
  const TableRow = ({ el }: { el: AssetClassTargetAllocationDto }) => {
    if (ignoreAllocationTargets && targetOCRatio !== null) {
      return (
        <MobileTableRowWrap key={el.id}>
          <MobileRow>
            <MobileRowTitle>{overCommitmentRatioModeSimplifiedColumns[0].title}</MobileRowTitle>
            <MobileRowValue>{el.name}</MobileRowValue>
          </MobileRow>

          <MobileRow>
            <MobileRowTitle>{overCommitmentRatioModeSimplifiedColumns[1].title}</MobileRowTitle>
            <MobileRowValue>
              <FormattingTooltip zIndex={1000}>{el.economicExposure}</FormattingTooltip>
            </MobileRowValue>
          </MobileRow>
        </MobileTableRowWrap>
      );
    }

    if (ignoreAllocationTargets) {
      return (
        <MobileTableRowWrap key={el.id}>
          <MobileRow>
            <MobileRowTitle>{pacingModelModeSimplifiedColumns[0].title}</MobileRowTitle>
            <MobileRowValue>{el.name}</MobileRowValue>
          </MobileRow>

          <MobileRow>
            <MobileRowTitle>{pacingModelModeSimplifiedColumns[1].title}</MobileRowTitle>
            <MobileRowValue>
              <FormattingTooltip zIndex={1000}>{el.currentNAV}</FormattingTooltip>
            </MobileRowValue>
          </MobileRow>

          <MobileRow>
            <MobileRowTitle>{pacingModelModeSimplifiedColumns[2].title}</MobileRowTitle>
            <MobileRowValue>
              <FormattingTooltip zIndex={1000}>{el.unfundedCommitments}</FormattingTooltip>
            </MobileRowValue>
          </MobileRow>
        </MobileTableRowWrap>
      );
    }

    if (targetOCRatio === null) {
      return (
        <MobileTableRowWrap key={el.id}>
          <MobileRow>
            <MobileRowTitle>{pacingModelModeColumns[0].title}</MobileRowTitle>
            <MobileRowValue>{el.name}</MobileRowValue>
          </MobileRow>
          <MobileRow>
            <MobileRowTitle>{pacingModelModeColumns[1].title}</MobileRowTitle>
            <MobileRowValue>{el.percentage === null ? '--' : `${el.percentage.toFixed(0)}%`}</MobileRowValue>
          </MobileRow>
          <MobileRow>
            <MobileRowTitle>{pacingModelModeColumns[2].title}</MobileRowTitle>
            <MobileRowValue>
              <FormattingTooltip zIndex={1000}>{el.targetAllocation}</FormattingTooltip>
            </MobileRowValue>
          </MobileRow>
          <MobileRow>
            <MobileRowTitle>{pacingModelModeColumns[3].title}</MobileRowTitle>
            <MobileRowValue>
              <FormattingTooltip zIndex={1000}>{el.currentNAV}</FormattingTooltip>
            </MobileRowValue>
          </MobileRow>
          <MobileRow>
            <MobileRowTitle>{pacingModelModeColumns[4].title}</MobileRowTitle>
            <MobileRowValue>
              <FormattingTooltip zIndex={1000}>{el.unfundedCommitments}</FormattingTooltip>
            </MobileRowValue>
          </MobileRow>
          <MobileRow>
            <MobileRowTitle>{pacingModelModeColumns[5].title}</MobileRowTitle>
            <MobileRowValue>
              <CapacityCell row={el} commitmentPlansTotal={commitmentPlansTotal} targetOCRatio={targetOCRatio} isMobile />
            </MobileRowValue>
          </MobileRow>
        </MobileTableRowWrap>
      );
    }

    return (
      <MobileTableRowWrap key={el.id}>
        <MobileRow>
          <MobileRowTitle>{overCommitmentRatioModeColumns[0].title}</MobileRowTitle>
          <MobileRowValue>{el.name}</MobileRowValue>
        </MobileRow>
        <MobileRow>
          <MobileRowTitle>{overCommitmentRatioModeColumns[1].title}</MobileRowTitle>
          <MobileRowValue>{el.percentage === null ? '--' : `${el.percentage.toFixed(0)}%`}</MobileRowValue>
        </MobileRow>
        <MobileRow>
          <MobileRowTitle>{overCommitmentRatioModeColumns[2].title}</MobileRowTitle>
          <MobileRowValue>
            <FormattingTooltip zIndex={1000}>{el.targetAllocation}</FormattingTooltip>
          </MobileRowValue>
        </MobileRow>
        <MobileRow>
          <MobileRowTitle>{overCommitmentRatioModeColumns[3].title}</MobileRowTitle>
          <MobileRowValue>
            <CellWithTooltip>
              {formatCash(el.economicExposure)}
              <BasicTooltip tooltipContent={economicExposureToolTipContent(el)} withTooltipIcon zIndex={1000} />
            </CellWithTooltip>
          </MobileRowValue>
        </MobileRow>
        <MobileRow>
          <MobileRowTitle>{overCommitmentRatioModeColumns[4].title}</MobileRowTitle>
          <MobileRowValue>
            <CapacityCell row={el} commitmentPlansTotal={commitmentPlansTotal} targetOCRatio={targetOCRatio} isMobile />
          </MobileRowValue>
        </MobileRow>
      </MobileTableRowWrap>
    );
  };

  return (
    <MobileTableWrapper>
      {investorSummaryRows?.length ? (
        <>
          {investorSummaryRows.map((el, index) => {
            return <TableRow el={el} key={index} />;
          })}
          <MobileSumFooter
            investorSummaryRows={investorSummaryRows}
            loading={loading}
            ignoreAllocationTargets={ignoreAllocationTargets}
            commitmentPlansTotal={commitmentPlansTotal}
            targetOCRatio={targetOCRatio}
          />
        </>
      ) : (
        <NoResults>No Results</NoResults>
      )}
    </MobileTableWrapper>
  );
};

export default MobileTable;

const CellWithTooltip = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;
