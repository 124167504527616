import { IDateFields } from '../../../types/opportunity/modify-opportunity';
import { defaultSelectValue, defaultToggleCircle, managerSelects, subAssetClasses } from '../../opportunities-entity/modify/constants';
import type { IForm, IOwner, Investment } from '../types';
import { getUTCDate } from '../utils';

export const managerSelectsChecked = managerSelects.map((select) => ({
  text: select,
  checked: false
}));

export const subAssetClassesChecked = subAssetClasses.map((select) => ({
  text: select,
  checked: false
}));

export const getOwnerValues = (ownerData: Array<IOwner>) => {
  return ownerData?.map((owner) => `${owner.firstName} ${owner.lastName}`) || [];
};

export const getOwnerName = (ownerData: IOwner | undefined) => {
  return ownerData ? `${ownerData?.firstName} ${ownerData?.lastName}` : defaultSelectValue;
};

export const getOwnerEmailFromName = (OwnerName: string, owners: Array<IOwner>) => {
  const [firstName, lastName] = OwnerName.split(' ');
  const owner = owners.filter((owner: IOwner) => owner.firstName === firstName && owner.lastName === lastName);
  return owner[0]?.email || null;
};

export const getDefaultFormValues = (investment: Investment) => {
  return {
    name: investment?.name || '',
    manager: investment?.managerId || defaultSelectValue,
    assetClass: investment?.assetClass || defaultSelectValue,
    symbol: investment?.symbol ? investment?.symbol : investment?.id ?? '',
    minimum: investment?.minimum?.toString() || '',
    targetIRR: investment?.targetReturn || '',
    targetYield: investment?.targetYield || '',
    vintage: investment?.vintageYear,
    type: investment?.type || defaultSelectValue,
    status: 'Open',
    custodied: investment?.custodied ? 'Yes' : 'No',
    ubtiBlocker: investment?.ubtiBlocker ? 'Yes' : 'No',
    sri: investment?.sri === undefined ? defaultSelectValue : investment.sri ? 'Yes' : 'No',
    nextClose: investment?.nextClose ? getUTCDate(new Date(investment.nextClose)) : null,
    finalClose: investment?.finalClose ? getUTCDate(new Date(investment.finalClose)) : null,
    investmentOwner: getOwnerName(investment?.investmentOwner),
    operationsOwner: getOwnerName(investment?.operationsOwner),
    complianceOwner: getOwnerName(investment?.complianceOwner)
    // strategies: investment?.subAssetClasses || [],
  };
};

export const isSelectorsValid = (data: IForm) => {
  return (
    data?.type !== defaultSelectValue &&
    data?.assetClass !== defaultSelectValue &&
    data?.investmentOwner !== defaultSelectValue &&
    data?.operationsOwner !== defaultSelectValue &&
    data?.complianceOwner !== defaultSelectValue
  );
};

export const isReadyToSave = (formData: IForm, chosenManager: string) => {
  return (
    formData.name !== '' &&
    formData.manager !== '' &&
    formData.type !== defaultSelectValue &&
    formData.symbol !== '' &&
    formData.minimum !== '' &&
    chosenManager !== defaultSelectValue &&
    isSelectorsValid(formData)
  );
};

interface IBuildData {
  id?: string;
  data: IForm;
  dataFields: IDateFields;
  investmentStrategies: string[];
  investmentTags: string[];
  allPossibleOwners: IOwner[] | [];
  descriptionValue: string;
  securityStatus?: string;
  openIvestment?: boolean;
}

export const buildData = ({
  id,
  data,
  dataFields,
  investmentStrategies,
  investmentTags,
  allPossibleOwners,
  descriptionValue,
  securityStatus,
  openIvestment
}: IBuildData) => ({
  ...(id && { id }),
  name: data.name,
  managerId: data.manager === defaultSelectValue ? '' : data.manager,
  custodied: dataFields.custodied.find(({ checked }) => checked)?.label === 'Yes',
  ubtiBlocker: dataFields.ubtiBlocker.find(({ checked }) => checked)?.label === 'Yes',
  sri: data.sri === 'Yes' ? true : false,
  type: data.type === defaultSelectValue ? '' : data.type,
  subAssetClasses: investmentStrategies ?? [],
  ...(descriptionValue && { description: descriptionValue }),
  investmentOwnerEmail: getOwnerEmailFromName(data.investmentOwner, allPossibleOwners),
  operationsOwnerEmail: getOwnerEmailFromName(data.operationsOwner, allPossibleOwners),
  complianceOwnerEmail: getOwnerEmailFromName(data.complianceOwner, allPossibleOwners),
  minimum: Number(data.minimum),
  ...(securityStatus !== 'OPEN' && openIvestment && { symbol: data.symbol }),
  ...(securityStatus !== 'OPEN' && openIvestment && { assetClass: data.assetClass }),
  ...(securityStatus !== 'OPEN' && openIvestment && { securityStatus: 'OPEN' }),
  ...(dataFields.nextClose && { nextClose: [dataFields.nextClose] }),
  ...(dataFields.finalClose && { finalClose: dataFields.finalClose }),
  ...(data.targetIRR && {
    targetIRR: typeof data.targetIRR === 'string' ? +data.targetIRR.replace(',', '.') : +data.targetIRR
  }),
  ...(data.targetYield && {
    targetYield: typeof data.targetYield === 'string' ? +data.targetYield.replace(',', '.') : +data.targetYield
  }),
  ...(data.vintage && { vintageYear: Number(data.vintage) }),
  ...(investmentTags && investmentTags.length > 0 && { tags: investmentTags ?? [] })
});

export const getDefaultDataFields = (investment: Investment) => ({
  nextClose: investment ? investment.nextClose : null,
  finalClose: investment ? investment.finalClose : null,
  manager: managerSelectsChecked,
  strategies: subAssetClassesChecked,
  custodied: investment
    ? [
        { label: 'No', checked: !investment.custodied },
        { label: 'Yes', checked: investment.custodied }
      ]
    : defaultToggleCircle,
  ubtiBlocker: investment
    ? [
        { label: 'No', checked: !investment.ubtiBlocker },
        { label: 'Yes', checked: investment.ubtiBlocker }
      ]
    : defaultToggleCircle,
  sri: defaultToggleCircle
});

interface IGetAllPossibleOwners {
  investmentOwners: IOwner[];
  operationsOwners: IOwner[];
  complianceOwners: IOwner[];
}

export const getAllPossibleOwners = ({ investmentOwners, operationsOwners, complianceOwners }: IGetAllPossibleOwners): IOwner[] => {
  return [...investmentOwners, ...operationsOwners, ...complianceOwners].filter(Boolean);
};
