import RadioButton from '@/components/basicComponents/radioButton';
import Textarea from '@/components/basicComponents/textarea';
import { FormFooter } from '@/components/fat-basicComponents/formFooter';
import Label from '@/components/fat-basicComponents/label';
import Header from '@/components/fat-header';
import { GoBackButton } from '@/components/fat-header/goBackButton';
import { PageTitle } from '@/components/fat-header/pageTitle';
import { useResponsive } from '@/hooks/use-responsive';
import { useGoBack } from '@/hooks/useGoBack';
import { MainWrap, PaddingWrap } from '@/styles/common';
import { Controller, useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';
import { CheckboxSection } from '../eligibility-page/checkboxSection';

const suitabilityCheckboxData: any = [
  {
    title: 'Disqualification Events',
    subTitle: null,
    checkboxesData: [
      {
        title: 'Felony or misdemeanor conviction',
        description:
          'Has been convicted, within the past 10 years, of any felony or misdemeanor within the United States: (A) in connection with the purchase or sale of any security; (B) involving the making of any false filing with the SEC; or (C) arising out of the conduct of the business of an underwriter, broker, dealer, municipal securities dealer, investment adviser or paid solicitor of purchasers of securities.'
      },
      {
        title: 'Subject to court decree',
        description:
          'Is subject to any order, judgment or decree of any court of competent jurisdiction, entered within the past 5 years, that restrains or enjoins the Investor from engaging or continuing to engage in any conduct or practice: (A) in connection with the purchase or sale of any security; (B) involving the making of any false filing with the SEC; or (C) arising out of the conduct of the business of an underwriter, broker, dealer, municipal securities dealer, investment adviser or paid solicitor of purchasers of securities.'
      },
      {
        title: 'Subject to final order',
        description:
          'Is subject to a final order of a state securities commission (or an agency or officer of a state performing like functions); a state authority that supervises or examines banks, savings associations, or credit unions; a state insurance commission (or an agency or officer of a state performing like functions); an appropriate federal banking agency; the U.S. Commodity Futures Trading Commission; or the National Credit Union Administration that: (A) bars the Investor from: (1) association with an entity regulated by such commission, authority, agency or officer; (2) engaging in the business of securities, insurance or banking; or (3) engaging in savings association or credit union activities; or (B) constitutes a final order based on a violation of any law or regulation that prohibits fraudulent, manipulative, or deceptive conduct entere within the past 10 years.'
      },
      {
        title: 'Subject to an SEC order relating to the 1934 Act or the Advisor Act',
        description:
          'Is subject to an order of the SEC entered pursuant to Section 15(b) or 15B(c) of the Exchange Act or Section 203(e) or (f) of the Investment Advisers Act of 1940 (the “Advisers Act”) that: (A) suspends or revokes the Investor’s registration as a broker, dealer, municipal securities dealer or investment adviser; (B) places limitations on the Investor’s activities, functions or operations; (C) bars the Investor from being associated with any entity or from participating in the offering of any penny stock.'
      },
      {
        title: 'Subject to an SEC order (cease and desist)',
        description:
          'Is subject to any order of the SEC entered within the past 5 years that orders the Investor to cease and desist from committing or causing a violation or future violation of: (A) any scienter-based anti-fraud provision of the federal securities laws, including without limitation Section 17(a)(1) of the Securities Act, Section 10(b) of the Exchange Act and 17 CFR 240.10b-5, Section 15(c)(1) of the Exchange Act and Section 206(1) of the Advisers Act, or any other rule or regulation thereunder; or (B) Section 5 of the Securities Act.'
      },
      {
        title: 'Suspension, expulsion, or barring',
        description:
          'Is suspended or expelled from membership in, or suspended or barred from association with a member of, a registered national securities exchange or a registered national or affiliated securities association for any act or omission to act constituting conduct inconsistent with just and equitable principles of trade.'
      },

      {
        title: 'Registrant, issuer, or participant in a Reg A filing',
        description:
          'Has filed (as a registrant or issuer), or was named as an underwriter in, any registration statement or Regulation A offering statement filed with the SEC that, within the past 5 years, was the subject of a refusal order, stop order, or order suspending the Regulation A exemption, or is the subject of an investigation or proceeding to determine whether a stop order or suspension order should be issued.'
      },
      {
        title: 'Subject to United States Postal Service action',
        description:
          'Is subject to a United States Postal Service false representation order entered within the past 5 years, or is subject to temporary restraining order or preliminary injunction with respect to conduct alleged by the United States Postal Service to constitute a scheme or device for obtaining money or property through the mail by means of false representation.'
      },
      {
        title: 'None of the above',
        description: null
      }
    ]
  }
];

const waivers = [
  'Any conviction, order, judgment, decree, suspension, expulsion or bar occurred or was issued before September 23, 2013;',
  'Upon showing of good cause and without prejudice to any other action by the SEC, the SEC determined that it was not necessary under the circumstances that an exemption be denied; or',
  'A court or regulatory authority that entered the relevant order, judgment or decree has advised in writing that a Disqualifying Event should not arise as a consequence of such order, judgment or decree.'
];

export const SuitabilityPage = () => {
  const { isMobile } = useResponsive();
  const theme = useTheme();
  const goBack = useGoBack();
  const {
    handleSubmit,
    getValues,
    setValue,
    control,
    setError,
    formState: { errors, isValid },
    reset,
    watch
  } = useForm();

  const [searchParams, setSearchParams] = useSearchParams();

  const backToTitle = searchParams.get('backToTitle') || '';

  const onSubmit = (data: any) => {
    console.log(data);
  };

  return (
    <MainWrap>
      <Header modalControl={<GoBackButton handleClose={() => goBack({ fallBack: '/investors' })} backToTitle={backToTitle} />} />
      <PageTitle title="Suitability" />
      <PaddingWrap>
        <form id="suitabilityForm" onSubmit={handleSubmit(onSubmit, () => window.scrollTo(0, 0))}>
          <SuitabilityWrapper isMobile={isMobile}>
            <FormTitle isMobile={isMobile}>Suitability Representations</FormTitle>
            <div>
              <Label size="md">Is Subscriber subject to the U.S. Freedom of Information Act, 5 U.S.C. § 552, (“FOIA”)?</Label>
              <RadioButtonsWrapper>
                <Controller
                  name="radioButton"
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <>
                      {['Yes', 'No'].map((option, index) => (
                        <RadioButton
                          key={option + index}
                          name="capacityCalculation"
                          radioButtonText={option}
                          selectedOption={value}
                          handleRadioChange={(event) => onChange(event.target.value)}
                        />
                      ))}
                    </>
                  )}
                />
              </RadioButtonsWrapper>
            </div>
            <div>
              <Label size="md" required>
                Please Explain
              </Label>
              <Textarea value="" setValue={() => {}} />
            </div>
            <div>
              <Label size="md">The investment entity is is exempt from registering under the Investment Company Act based on Section 3(c)(1)</Label>
              <RadioButtonsWrapper>
                <Controller
                  name="radioButton"
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <>
                      {['Yes', 'No'].map((option, index) => (
                        <RadioButton
                          key={option + index}
                          name="capacityCalculation"
                          radioButtonText={option}
                          selectedOption={value}
                          handleRadioChange={(event) => onChange(event.target.value)}
                        />
                      ))}
                    </>
                  )}
                />
              </RadioButtonsWrapper>
            </div>
            <div>
              <Label size="md">The investment entity is is exempt from registering under the Investment Company Act based on Section 3(c)(7)</Label>
              <RadioButtonsWrapper>
                <Controller
                  name="radioButton"
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <>
                      {['Yes', 'No'].map((option, index) => (
                        <RadioButton
                          key={option + index}
                          name="capacityCalculation"
                          radioButtonText={option}
                          selectedOption={value}
                          handleRadioChange={(event) => onChange(event.target.value)}
                        />
                      ))}
                    </>
                  )}
                />
              </RadioButtonsWrapper>
            </div>
            {suitabilityCheckboxData.map((mode: any, index: number) => (
              <div key={index}>
                {mode.title && <FormTitle isMobile={isMobile}>{mode.title}</FormTitle>}
                {mode.subTitle && <FormSubTitle>{mode.subTitle}</FormSubTitle>}
                <CheckboxesWrapper isMobile={isMobile}>
                  {mode.checkboxesData.map((checkboxData: any, index: number) => (
                    <CheckboxSection key={checkboxData.title} title={checkboxData.title} description={checkboxData.description} />
                  ))}
                </CheckboxesWrapper>
              </div>
            ))}
            <FormTitle isMobile={isMobile}>Disqualification Waiver</FormTitle>
            <DisqualificationWaiverDescription>
              <p>Please indicate below whether the Disqualifying Event(s) described above is/are subject to one of the following waivers:</p>
              <DisqualificationWaiverList type="i">
                {waivers.map((item, index) => (
                  <li key={index}>{item}</li>
                ))}
              </DisqualificationWaiverList>
              <RadioButtonsWrapper>
                <Controller
                  name="radioButton"
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <>
                      {['Yes', 'No'].map((option, index) => (
                        <RadioButton
                          key={option + index}
                          name="capacityCalculation"
                          radioButtonText={option}
                          selectedOption={value}
                          handleRadioChange={(event) => onChange(event.target.value)}
                        />
                      ))}
                    </>
                  )}
                />
              </RadioButtonsWrapper>
            </DisqualificationWaiverDescription>
            <div>
              <Label size="md" required>
                Please provide details of the disqualifying event
              </Label>
              <Textarea value="" setValue={() => {}} />
            </div>
            <div>
              <Label size="md" required>
                Please explain why a waiver applies
              </Label>
              <Textarea value="" setValue={() => {}} />
            </div>
          </SuitabilityWrapper>
          <FormFooter onCancel={() => goBack({ fallBack: '/investors' })} disableSaveButton={false} showRequiredFieldsWarning={false} />
        </form>
      </PaddingWrap>
    </MainWrap>
  );
};

const SuitabilityWrapper = styled.div<{ isMobile: boolean }>`
  margin-top: 50px;
  padding: ${({ isMobile }) => (isMobile ? '0 20px 20px' : '0 40px 40px')};
  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.border.base};
  background-color: ${({ theme }) => theme.layer[1]};
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const FormTitle = styled.div<{ isMobile: boolean }>`
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  color: ${({ theme }) => theme.font.strong};
  padding-bottom: 10px;
  margin-top: ${({ isMobile }) => (isMobile ? '20px' : '40px')};
  border-bottom: 1px solid ${({ theme }) => theme.border.base};
`;

const FormSubTitle = styled.div`
  color: ${({ theme }) => theme.font.base};
  font-size: 16px;
  font-weight: 600;
  line-height: 28px;
  padding: 20px 0 10px;
`;

const RadioButtonsWrapper = styled.div`
  display: flex;
`;

const CheckboxesWrapper = styled.div<{ isMobile: boolean }>`
  padding: ${({ isMobile }) => (isMobile ? '20px 10px 10px;' : '30px 20px 20px')};
`;

const DisqualificationWaiverDescription = styled.div`
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;
  color: ${({ theme }) => theme.font.base};
  padding: 4px;
`;

const DisqualificationWaiverList = styled.ol`
  list-style-type: none;
  counter-reset: list-counter;
  margin-left: 10px;

  li {
    counter-increment: list-counter;
  }

  li::before {
    content: '(' counter(list-counter, lower-roman) ')';
    margin-right: 8px;
  }
`;
