import styled from 'styled-components';

import { CompletedStatusRow } from './CompletedStatusRow';
import { FutureStatusRow } from './FutureStatusRow';
import { NextStepStatusRow } from './NextStepStatusRow';
import { Step } from './types';

interface StepsListProps {
  list: Step[];
  isMobile?: boolean;
}

export const StepsList = ({ list, isMobile = false }: StepsListProps) => {
  if (!list.length) return <NoStatuses>There is no statuses</NoStatuses>;
  return (
    <Container>
      {list.map(({ status, date }, index) => (
        <WrapperRow key={status}>
          {date && (
            <CompletedStatusRow
              isMobile={isMobile}
              status={status}
              date={date as string}
            />
          )}
          {list[index - 1]?.date && !date && (
            <NextStepStatusRow isMobile={isMobile} status={status} />
          )}
          {!list[index - 1]?.date && !date && (
            <FutureStatusRow
              isMobile={isMobile}
              index={index}
              status={status}
            />
          )}
        </WrapperRow>
      ))}
    </Container>
  );
};

const NoStatuses = styled.span`
  text-align: left;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 3px;

  * {
    white-space: nowrap;
  }
`;
const WrapperRow = styled.div<{ background?: string }>`
  width: 100%;
`;
export const Status = styled.span<{ color: string; isMobile?: boolean }>`
  display: flex;
  width: ${({ isMobile }) => (isMobile ? '70%' : '100%')};
  overflow: ${({ isMobile }) => (isMobile ? 'hidden' : 'none')};
  text-overflow: ${({ isMobile }) => (isMobile ? 'ellipsis' : 'none')};
  //white-space: nowrap;
  user-select: ${({ isMobile }) => (isMobile ? 'none' : 'auto')};
  color: ${({ color }) => color};
  padding-left: 10px;
  font-family: 'Blinker', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
`;
