import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { formatPercent, formatPrice } from '../../../utils/currency';
import guid from '../../../utils/guid';

interface BodyRowProps {
  managerRows: [] | undefined;
  columns: any;
  handleClickClient: (value: string) => void;
}

const TableBody = ({ managerRows, handleClickClient, columns }: BodyRowProps) => {
  const [rows, setRows] = useState<[] | undefined>(managerRows);

  useEffect(() => {
    setRows(managerRows);
  }, [managerRows]);

  return (
    <TableWrapper>
      {rows?.map((row: any) => (
        <RowWrap key={guid()}>
          <TableRowWrap key={guid()}>
            <TableRowItem width={columns[0].width}>
              <EntityTitle onClick={() => handleClickClient(row.id)}>{row.name}</EntityTitle>
            </TableRowItem>
            <TableRowItem width={columns[1].width}>
              <BasicTitle>{row.assetClass}</BasicTitle>
            </TableRowItem>
            <TableRowItem width={columns[2].width}>
              <BasicTitle>
                {row.vintageYear && row.vintageYear.length > 4 ? [...new Set(row.vintageYear)].join('').split(',') : row.vintageYear}
              </BasicTitle>
            </TableRowItem>
            <TableRowItem width={columns[3].width}>
              <BasicTitle>{formatPercent(row.maxCalledPercent)}</BasicTitle>
            </TableRowItem>
            <TableRowItem width={columns[4].width}>
              <BasicTitle>{row.countOfInvestors}</BasicTitle>
            </TableRowItem>
            <TableRowItem width={columns[5].width}>
              <BasicTitle>{formatPrice(row.committedCapital)}</BasicTitle>
            </TableRowItem>
          </TableRowWrap>
        </RowWrap>
      ))}
    </TableWrapper>
  );
};

const EntityTitle = styled.div`
  cursor: pointer;
  font-family: Blinker, serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  color: #373f4e;
`;

const RowWrap = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  gap: 4px;

  &:hover ${EntityTitle} {
    transition: color 0.1s ease;
    color: #4587ec;
  }
`;

const TableWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 6px;
`;

const BasicTitle = styled.div<{ isBold?: boolean; color?: string }>`
  font-family: Blinker, serif;
  font-size: 16px;
  font-weight: ${({ isBold }) => (isBold ? 700 : 400)};
  line-height: 22px;
  letter-spacing: 0;
  text-align: left;
  color: ${({ color }) => color};
`;

const TableRowWrap = styled.div<{ isGrouped?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: flex-end;
  width: ${({ isGrouped }) => (isGrouped ? 'calc(100% - 60px)' : '100%')};
  min-height: 60px;
  background-color: #ffffff;
  border-radius: 8px;
  padding: 0 24px;

  @media (max-width: 1100px) {
    width: ${({ isGrouped }) => (isGrouped ? 'calc(100% - 20px)' : '100%')};
  }

  &:hover #infoWrap {
    opacity: 1;
  }

  &:hover #tempInfoWrap {
    display: none;
  }

  &:hover {
    background-color: #f0f1f3;
    transition: background-color 100ms ease;
  }
`;

const TableRowItem = styled.div<{ width: number; isGrouped?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: ${({ width, isGrouped }) => (isGrouped ? `calc(${width}% - 60px)` : `${width}%`)};
  @media (max-width: 1100px) {
    width: ${({ width, isGrouped }) => (isGrouped ? `calc(${width}% - 20px)` : `${width}%`)};
  }
`;

export default TableBody;
