import { EditIcon } from '@/assets/icons/edit';
import { ArrowDown } from '@/assets/icons/info-icons/arrowDown';
import { CopyIcon } from '@/assets/static/icons/copy-icon';
import { DeleteBucketIcon } from '@/assets/static/icons/delete-bucket';
import { DragAndDropBigIcon } from '@/assets/static/icons/drag-and-drop-icon';
import { ArrowUp } from '@/assets/static/icons/todo-page/arrow-up';
import { ArrowWrapper, DropdownBody, DropdownWrapper, ExpandableWrapp, Title } from '@/components/dueDiligence-page/dueDiligenceRecord/tierDropdown';
import { useResponsive } from '@/hooks/use-responsive';
import { useState } from 'react';
import styled from 'styled-components';

interface DropdownProps {
  title: string;
  children: React.ReactNode;
  showDragAndDropIcon?: boolean;
  handleEdit?: () => void;
  handleDelete?: () => void;
  handleDuplicate?: () => void;
  isLimitedEdits?: boolean;
}

export const FunnelEditorDropdown = ({
  title,
  children,
  handleEdit,
  handleDelete,
  handleDuplicate,
  showDragAndDropIcon = true,
  isLimitedEdits = false
}: DropdownProps) => {
  const { isMobile } = useResponsive();

  const [isDropdown, setIsDropdown] = useState(true);
  const [arrowHover, setArrowHover] = useState(false);
  const [dropdownHover, setDropdownHover] = useState(false);

  return (
    <DropdownWrapper>
      <DropdownHeader
        isMobile={isMobile}
        isDropdown={isDropdown}
        onMouseEnter={() => setDropdownHover(true)}
        onMouseLeave={() => setDropdownHover(false)}
      >
        <HeaderInfo>
          <DragAndDropBigIcon
            style={{
              visibility: dropdownHover && !isLimitedEdits && showDragAndDropIcon ? 'visible' : 'hidden'
            }}
          />
          <Title isMobile={isMobile}>{title}</Title>
        </HeaderInfo>
        {dropdownHover && (
          <ActionWrap>
            {handleEdit && <EditIcon fill="#4587EC" onClick={handleEdit} />}
            {handleDuplicate && !isLimitedEdits && <CopyIcon onClick={handleDuplicate} />}
            {handleDelete && !isLimitedEdits && <DeleteBucketIcon width={17} height={22} fill="#4587EC" onClick={handleDelete} />}
          </ActionWrap>
        )}
        <ArrowWrapper
          isMobile={isMobile}
          onClick={() => setIsDropdown(!isDropdown)}
          onMouseEnter={() => setArrowHover(true)}
          onMouseLeave={() => setArrowHover(false)}
        >
          <>
            {isDropdown ? (
              <ArrowUp style={{ cursor: 'pointer' }} fill={arrowHover && !isMobile ? '#F4F5F6' : '#4587EC'} />
            ) : (
              <ArrowDown
                style={{
                  cursor: 'pointer',
                  width: '28px',
                  height: '28px',
                  padding: '7px'
                }}
                fill={arrowHover && !isMobile ? '#F4F5F6' : '#4587EC'}
              />
            )}
          </>
        </ArrowWrapper>
      </DropdownHeader>
      <DropdownBody isMobile={isMobile} isDropdown={isDropdown} bgColor="#fff">
        <ExpandableWrapp isMobile={isMobile} isDropdown={isDropdown}>
          {children}
        </ExpandableWrapp>
      </DropdownBody>
    </DropdownWrapper>
  );
};

const DropdownHeader = styled.div<{ isMobile: boolean; isDropdown: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: ${({ isMobile }) => (isMobile ? 'flex-start' : 'center')};
  flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};
  padding: ${({ isMobile }) => (isMobile ? '15px 20px' : '20px 40px')};
  position: relative;
  border-radius: 12px 12px 0 0;
  transition: 0.2s;
  background-color: #fafafa;

  &:hover {
    background-color: #f0f1f3;
    transition: background-color 100ms ease;
    border-radius: ${({ isDropdown }) => (isDropdown ? '12px 12px 0 0' : '12px')};
  }
`;

const HeaderInfo = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
`;

const ActionWrap = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  padding-right: 20px;
  margin-left: auto;
  cursor: pointer;
`;
