import styled, { useTheme } from 'styled-components';

import { RedStarIcon } from '@/assets/icons/info-icons/redStar';
import Button from '@/components/fat-basicComponents/button';
import { useResponsive } from '@/hooks/use-responsive';
import { User } from '@/types/user';
import { useMutation } from '@apollo/client';
import { UPDATE_CHECKLIST_ITEM_STATUS_MUTATION } from '../queries';
import SectionStatus from './sectionStatus';
import { IChecklistItem, ISection, RecordStatusEnum, TierStatusEnum } from './types';

interface TierCheckListProps {
  checkListData: IChecklistItem[];
  recordStatus: RecordStatusEnum | null;
  tierStatus: TierStatusEnum;
  openModalWindow: (type: string, row: ISection | IChecklistItem) => void;
  openNotes: (note: string, updateAt: Date, task: string) => void;
  owner: User;
  prevTierStatus: TierStatusEnum | null;
  isBackToLoading: boolean;
}

export const TierCheckList = ({
  checkListData,
  recordStatus,
  tierStatus,
  openModalWindow,
  openNotes,
  owner,
  prevTierStatus,
  isBackToLoading = false
}: TierCheckListProps) => {
  const { isMobile } = useResponsive();
  const theme = useTheme();

  const [updateCheckListItemStatus] = useMutation(UPDATE_CHECKLIST_ITEM_STATUS_MUTATION);

  const handleChangeCheckListStatus = (status: boolean, itemId: string) => {
    updateCheckListItemStatus({
      variables: {
        data: {
          id: itemId,
          completed: status
        }
      }
    });
  };

  const isHoverDisabled =
    tierStatus === TierStatusEnum.COMPLETED ||
    tierStatus === TierStatusEnum.REJECTED ||
    prevTierStatus === TierStatusEnum.IN_PROGRESS ||
    prevTierStatus === TierStatusEnum.NOT_STARTED ||
    prevTierStatus === TierStatusEnum.REJECTED;

  return (
    <>
      {checkListData.map((row) => (
        <CheckListRowWrapper key={row.id} disabled={isHoverDisabled} isMobile={isMobile}>
          <CheckListInfoRowCell>
            {row.required ? <RedStarIcon width={10} height={10} fill={theme.context.error} /> : <NoRequired />}
            {row.name}
          </CheckListInfoRowCell>
          <CheckListRowCell isMobile={isMobile}>
            <SectionStatus
              prevTierStatus={prevTierStatus}
              tierStatus={tierStatus}
              owner={owner}
              currentRow={row}
              openNotes={openNotes}
              openModalWindow={openModalWindow}
              sectionTitle={row.name}
              loading={isBackToLoading}
              recordStatus={recordStatus}
            />
            {tierStatus === TierStatusEnum.IN_PROGRESS && !row.data.completed && (
              <CompleteButton id="completeButton" isMobile={isMobile} onClick={() => handleChangeCheckListStatus(true, row.data.id)}>
                Mark As Complete
              </CompleteButton>
            )}
            {tierStatus === TierStatusEnum.IN_PROGRESS && row.data.completed && (
              <CompleteButton
                id="completeButton"
                isMobile={isMobile}
                onClick={() => handleChangeCheckListStatus(false, row.data.id)}
                styleType="outline"
              >
                Reopen
              </CompleteButton>
            )}
          </CheckListRowCell>
        </CheckListRowWrapper>
      ))}
    </>
  );
};

const CheckListRowWrapper = styled.div<{ disabled: boolean; isMobile: boolean }>`
  display: flex;
  align-items: ${({ isMobile }) => (isMobile ? 'flex-start' : 'center')};
  flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};
  gap: 20px;

  padding: 28px 12px;
  border-bottom: 1px solid ${({ theme }) => theme.border.base};

  &:hover #completeButton {
    opacity: 1;
  }

  &:hover {
    background-color: ${({ disabled, theme }) => (disabled ? 'none' : theme.layer[2])};
    transition: background-color 100ms ease;
  }
`;

const CheckListInfoRowCell = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: ${({ theme }) => theme.font.strong};
  display: flex;
  gap: 10px;
  align-items: center;
  width: 65%;

  @media (max-width: 1770px) {
    width: 60%;
  }
  @media (max-width: 1590px) {
    width: 55%;
  }
  @media (max-width: 1420px) {
    width: 50%;
  }
  @media (max-width: 1230px) {
    width: 42%;
  }
  @media (max-width: 1079px) {
    width: 100%;
  }
`;

const CheckListRowCell = styled.div<{ isMobile?: boolean }>`
  font-weight: 300;
  font-size: 16px;
  line-height: 22px;
  padding-right: ${({ isMobile }) => (isMobile ? '0' : '20px')};
  display: flex;
  justify-content: space-between;
  flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};
  gap: ${({ isMobile }) => (isMobile ? '10px' : '0')};
  width: 35%;

  @media (max-width: 1770px) {
    width: 40%;
  }
  @media (max-width: 1590px) {
    width: 45%;
  }
  @media (max-width: 1420px) {
    width: 50%;
  }
  @media (max-width: 1230px) {
    width: 58%;
  }
  @media (max-width: 1079px) {
    width: 100%;
  }
`;

const CompleteButton = styled(Button)<{ isMobile: boolean }>`
  opacity: ${({ isMobile }) => (isMobile ? '1' : '0')};
`;

const NoRequired = styled.div`
  width: 10px;
`;
