import styled from 'styled-components';

import FilterSelect from '@/components/basicComponents/select';
import ShowText from '@/components/dueDiligence-page/dueDiligenceRecord/showText';
import { IRawAnswers } from '@/components/dueDiligence-page/dueDiligenceRecord/types';
import { TableRowCell, TableRowWrapper, TextCell } from '@/components/table/styles';
import { memo } from 'react';
import { columns } from './constatnts';

interface ITableRowProp {
  row: IRawAnswers;
  changeField: (id: string, value: string, fieldName: string) => void;
  openPdf: (pageNumber: number) => void;
}

const TableRow = memo(({ row, changeField, openPdf }: ITableRowProp) => {
  return (
    <CustomTableRowWrapper key={row.id}>
      <TableRowCell width={columns[0].width}>
        <TextCell>
          {row.pageNumbers?.map((pageNumber, index) => (
            <PageNumber key={pageNumber + index} onClick={() => openPdf(pageNumber)}>{`${index ? ', ' : ''}${pageNumber}`}</PageNumber>
          ))}
        </TextCell>
      </TableRowCell>
      <TableRowCell width={columns[1].width}>
        <TextCell>
          <ShowText>{row.answer}</ShowText>
        </TextCell>
      </TableRowCell>
      <TableRowCell width={columns[2].width}>
        <FilterSelect
          data={['true', 'false']}
          setSelected={(value) => changeField(row.id, value, 'hasAnswer')}
          selected={row.hasAnswer === null ? '--' : row.hasAnswer.toString()}
          width="100%"
          fontSize="16px"
          fontWeight="400"
          minHeight="40px"
        />
      </TableRowCell>
      <TableRowCell width={columns[3].width}>
        <FilterSelect
          data={['--', ...Array(11).keys()].map(String)}
          setSelected={(value) => changeField(row.id, value, 'score')}
          selected={row.score === null ? '--' : row.score.toString()}
          width="100%"
          fontSize="16px"
          fontWeight="400"
          minHeight="40px"
        />
      </TableRowCell>
    </CustomTableRowWrapper>
  );
});

export default TableRow;

const CustomTableRowWrapper = styled(TableRowWrapper)`
  justify-content: space-between;
`;

const PageNumber = styled.span`
  cursor: pointer;
  color: ${({ theme }) => theme.font.action};
`;
