import { useMutation } from '@apollo/client';
import { useNavigate, useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import CheckBox from '@/components/basicComponents/checkbox';
import Label from '@/components/basicComponents/label';
import ModalWrappedContent from '@/components/basicComponents/modal/modalWrappedContent';
import FilterSelect from '@/components/basicComponents/select';
import {
  ISectionFields,
  ITier
} from '@/components/dueDiligence-page/dueDiligenceRecord/types';
import {
  CREATE_DUE_DILIGENCE_FIELD,
  GET_FUNNEL_QUERY,
  UPDATE_DRAFT_DUE_DILIGENCE_FIELD,
  UPDATE_DUE_DILIGENCE_FIELD
} from '@/components/settings-page/queries';
import styled from 'styled-components';
import { MainSearch, ModalContainer, ModalTitle } from './addFunnel';

interface AddFieldProps {
  onClose: () => void;
  isOpen: boolean;
  currentField: ISectionFields | null;
  tiersData: ITier[];
  currentId: string;
  sectionId: string | null;
  numberOfFields: number;
  isLimitedEdits: boolean;
}

export const fieldsType = {
  LONG_FORM_CONTENT: 'Long Form Content',
  SHORT_TEXT: 'Short Text',
  DATE: 'Date',
  DOLLAR: 'Dollar Amount',
  NUMBER: 'Number',
  PERCENTAGE: 'Percentage'
};

const initialValues = {
  value: '',
  selectedFieldType: 'Select',
  selectLinkedField: 'None',
  isFieldRequired: true
};

const AddField = ({
  onClose,
  isOpen,
  tiersData,
  currentField,
  currentId,
  sectionId,
  numberOfFields,
  isLimitedEdits
}: AddFieldProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const [formValues, setFormValues] = useState(initialValues);
  const [linkedFieldList, setLinkedFieldList] = useState<
    { value: string; id?: string }[]
  >([]);
  const [chosenLinkedFieldId, setChosenLinkedFieldId] = useState<string[]>([]);

  const [createField] = useMutation(CREATE_DUE_DILIGENCE_FIELD, {
    refetchQueries: () => [
      {
        query: GET_FUNNEL_QUERY,
        variables: {
          id: queryParams.get('funnelId')
        }
      }
    ]
  });
  const [updateField] = useMutation(UPDATE_DUE_DILIGENCE_FIELD, {
    refetchQueries: () => [
      {
        query: GET_FUNNEL_QUERY,
        variables: {
          id: queryParams.get('funnelId')
        }
      }
    ]
  });
  const [updateDraftField] = useMutation(UPDATE_DRAFT_DUE_DILIGENCE_FIELD, {
    refetchQueries: () => [
      {
        query: GET_FUNNEL_QUERY,
        variables: {
          id: queryParams.get('funnelId')
        }
      }
    ]
  });

  useEffect(() => {
    const newTiersData: { value: string; id?: string }[] = [];
    const newChosenLinedId: string[] = [];

    tiersData.forEach((tier) => {
      tier.sections.forEach((section) => {
        section.fields.forEach((field) => {
          if (
            getKeyByValue(fieldsType, formValues.selectedFieldType) ===
            field.type
          ) {
            const formattedString = {
              value: `${tier.name} - ${section.name} - ${field.name}`,
              id: field.id
            };
            newTiersData.push(formattedString);
          }
          if (field.linkedFieldId) {
            newChosenLinedId.push(field.linkedFieldId);
            newChosenLinedId.push(field.id);
          }
        });
      });
    });

    if (currentField) {
      newChosenLinedId.push(currentField.id);
      setFormValues({
        ...formValues,
        selectLinkedField:
          newTiersData.find(
            (linkedField) => linkedField.id === currentField.linkedFieldId
          )?.value || 'None'
      });
    }
    setChosenLinkedFieldId(newChosenLinedId);
    setLinkedFieldList(newTiersData);
  }, [tiersData, formValues.selectedFieldType]);

  useEffect(() => {
    if (currentField) {
      const { name, type, linkedFieldId, required } = currentField;
      setFormValues({
        value: name,
        selectedFieldType:
          fieldsType[type as keyof typeof fieldsType] || 'Select',
        selectLinkedField:
          linkedFieldList.find(
            (linkedField) => linkedField.id === linkedFieldId
          )?.value || 'None',
        isFieldRequired: required
      });
    } else {
      setFormValues(initialValues);
    }
  }, [currentField]);

  const getKeyByValue = (
    object: { [key in keyof typeof fieldsType]: string },
    value: string
  ) => {
    return Object.keys(object).find(
      (key) => object[key as keyof typeof fieldsType] === value
    );
  };

  const saveValue = () => {
    if (currentField && isLimitedEdits) {
      updateField({
        variables: {
          data: {
            id: currentId,
            name: formValues.value
          }
        },
        onCompleted: () => {
          onClose();
        }
      });

      return;
    }

    if (currentField) {
      updateDraftField({
        variables: {
          data: {
            id: currentId,
            name: formValues.value,
            type: getKeyByValue(fieldsType, formValues.selectedFieldType) || '',
            linkedFieldId:
              linkedFieldList.find(
                (linkedField) =>
                  linkedField.value === formValues.selectLinkedField
              )?.id ?? null,
            required: formValues.isFieldRequired
          }
        }
      });
    } else {
      createField({
        variables: {
          data: {
            funnelTierSectionId: sectionId,
            name: formValues.value,
            order: numberOfFields + 1,
            type: getKeyByValue(fieldsType, formValues.selectedFieldType) || '',
            linkedFieldId:
              linkedFieldList.find(
                (linkedField) =>
                  linkedField.value === formValues.selectLinkedField
              )?.id ?? null,
            required: formValues.isFieldRequired
          }
        }
      });
    }

    onClose();
  };

  return (
    <ModalWrappedContent
      isOpen={isOpen}
      onClose={onClose}
      confirmButton={{
        name: 'Save',
        onClick: saveValue,
        disabled: !formValues.value || formValues.selectedFieldType === 'Select'
      }}
      showRequiredFields
    >
      <ModalTitle>{currentField ? 'Edit Field' : 'Add New Field'}</ModalTitle>
      <ModalContainer>
        <Label required>Give this Field a name</Label>
        <MainSearch
          type="text"
          placeholder="Give this Section a unique name"
          value={formValues.value}
          onChange={(event) =>
            setFormValues({ ...formValues, value: event.target.value })
          }
          autoFocus
        />
        <CustomLabel required>Field Type</CustomLabel>
        <CustomFilterSelect
          data={Object.values(fieldsType)}
          setSelected={(value) =>
            setFormValues({ ...formValues, selectedFieldType: value })
          }
          selected={formValues.selectedFieldType}
          disabled={!!currentField}
          width="100%"
          fontSize="19px"
          fontWeight="400"
          minHeight="48px"
          // textTransform="none"
        />
        <CustomLabel required={false}>Linked Field</CustomLabel>
        <CustomFilterSelect
          data={[
            'None',
            ...linkedFieldList
              .filter(
                (item) => item.id && !chosenLinkedFieldId.includes(item.id)
              )
              .map((item) => item.value)
          ]}
          setSelected={(value) =>
            setFormValues({ ...formValues, selectLinkedField: value })
          }
          selected={formValues.selectLinkedField}
          disabled={isLimitedEdits}
          width="100%"
          fontSize="19px"
          fontWeight="400"
          minHeight="48px"
          // textTransform="none"
        />
        <CheckBoxWrap
          id="requiredField"
          onClick={
            isLimitedEdits
              ? () => null
              : () =>
                  setFormValues({
                    ...formValues,
                    isFieldRequired: !formValues.isFieldRequired
                  })
          }
          isDisabled={isLimitedEdits}
        >
          <CheckBox id="requiredField" isActive={formValues.isFieldRequired} />
          Make Field Required
        </CheckBoxWrap>
      </ModalContainer>
    </ModalWrappedContent>
  );
};

export default AddField;

const CustomLabel = styled(Label)`
  margin-top: 15px;
`;

export const CheckBoxWrap = styled.div<{ isDisabled: boolean }>`
  margin-top: 30px;
  padding: 18px;
  border-top: 1px solid #c7cbd2;
  display: flex;
  gap: 10px;
  color: #373f4e;
  font-size: 16px;
  font-weight: 400;
  cursor: ${({ isDisabled }) => (isDisabled ? 'auto' : 'pointer')};
  opacity: ${({ isDisabled }) => (isDisabled ? '.5' : '1')};

  &:hover #requiredField {
    cursor: ${({ isDisabled }) => (isDisabled ? 'auto' : 'pointer')};
  }
`;

const CustomFilterSelect = styled(FilterSelect)`
  border-radius: 5px;
  border: 1px solid #c7cbd2;
  background: #fff;

  #dropDownSelect {
    margin-top: 15px;
  }

  #titleSelect {
    text-transform: none;
  }
`;
