import ModalWrappedContent from '@/components/fat-basicComponents/modal/modalWrappedContent';
import { Loader } from '@/components/loaders/loader';
import styled from 'styled-components';

interface LoadingProps {
  isOpen: boolean;
}

const Loading = ({ isOpen }: LoadingProps) => {
  return (
    <ModalWrappedContent isOpen={isOpen} onClose={() => null} showCloseIcon={false} showCancelButton={false}>
      <ModalBody>
        <Loader size={70} />
        <ModalTitle>Processing File</ModalTitle>
        This may take a few moments depending on the size of your file.
      </ModalBody>
    </ModalWrappedContent>
  );
};

export default Loading;

const ModalTitle = styled.div`
  color: ${({ theme }) => theme.font.strong};
  text-align: center;
  font-size: 33px;
  font-weight: 400;
  line-height: 46.2px;
`;

const ModalBody = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  gap: 10px;
  margin: 23px 0 23px 0;
  font-size: 19px;
  font-weight: 400;
  line-height: 26.6px;
  color: ${({ theme }) => theme.font.base};
`;
