import { gql } from '@apollo/client';

export const INVESTMENTS_QUERY = gql`
  query Investments($page: Int, $rowsPerPage: Int, $sort: String, $order: String, $statusFilter: [String!], $search: String, $quickFilter: String) {
    Investments(
      page: $page
      rowsPerPage: $rowsPerPage
      sort: $sort
      order: $order
      statusFilter: $statusFilter
      search: $search
      quickFilter: $quickFilter
    ) {
      total
      page
      perPage
      filters {
        name
        type
        value
        enabled
        count
      }
      data {
        id
        managerId
        symbol
        name
        securityStatus
        nextClose
        finalClose
        investorDocsDue
        aggregateCommitment
        aggregateInterest
        assignee {
          firstName
          lastName
        }
        investmentWorkflowTransitionTask {
          id
          name
          transitionText
          lastUpdate
          lastUpdate
          transitionTemplate
          order
        }
        workflowTransitionTasksHistory {
          id
          taskId
          createdAt
        }
        createdAt
        updatedAt
        pendingCommitmentCount
        activeCommitmentCount
      }
    }
  }
`;

export const INVESTMENT_QUERY = gql`
  query Investment($id: String!) {
    Investment(id: $id) {
      id
      symbol
      name
      type
      managerId
      pacingType
      pacingStartingQuarter
      pacingStartingYear
      assetClass {
        id
        name
      }
      subAssetClass {
        id
        name
      }
      investmentVehicles {
        id
        name
        investmentId
        createdAt
        deletedAt
      }
      sectors
      averageCalledPercent
      countOfInvestors
      committedCapital
      economicExposure
      targetReturn
      targetYield
      vintageYear
      nextClose
      finalClose
      investorDocsDue
      minimum
      sri
      subAssetClasses
      tags
      description
      securityStatus
      investmentOwner {
        firstName
        lastName
        email
      }
      operationsOwner {
        firstName
        lastName
        email
      }
      complianceOwner {
        firstName
        lastName
        email
      }
      offeringMaterialsDescription
      offeringMaterialsAttachments {
        id
        asset {
          url
          assetKey
        }
      }
      advisoryFirms {
        id
        name
      }
      showToAllAdvisoryFirms
    }
  }
`;

export const LIST_INVESTMENT_WORKFLOW_TRANSITION_TASKS_QUERY = gql`
  query listInvestmentWorkflowTransitionTasks {
    listInvestmentWorkflowTransitionTasks {
      id
      name
      transitionText
      lastUpdate
      transitionTemplate
      order
    }
  }
`;

export const LIST_INVESTMENT_WORKFLOW_TRANSITION_TASKS_HISTORY_QUERY = gql`
  query listInvestmentWorkflowTransitionTasksHistory($id: String!, $sortDirection: PaginateSortDirection) {
    listInvestmentWorkflowTransitionTasksHistory(id: $id, sortDirection: $sortDirection) {
      id
      objectId
      taskId
      userId
      createdAt
    }
  }
`;

export const updateInvestmentWorkflowTransitionTaskMutation = gql`
  mutation updateInvestmentWorkflowTransitionTask($data: UpdateInvestmentWorkflowTransitionTaskDto!) {
    updateInvestmentWorkflowTransitionTask(data: $data) {
      id
    }
  }
`;

export const LIST_INVESTMENT_ASSIGNEES = gql`
  query ListInvestmentAssignees {
    listInvestmentAssignees {
      id
      firstName
      lastName
      initials
      email
      officeNumber
      mobileNumber
      lastLogin
      roles
    }
  }
`;

export const updateInvestmentRestrictionsMutation = gql`
  mutation updateInvestmentRestrictions($data: UpdateInvestmentRestrictionsDto!) {
    updateInvestmentRestrictions(data: $data) {
      id
    }
  }
`;

export const deleteInvestmentMutation = gql`
  mutation DeleteInvestment($id: String!) {
    DeleteInvestment(id: $id)
  }
`;
