import { IInvestmentEntity } from '@/components/fat-dealCatalog-page/fat-seekingInterest-page/types';
import { Loader } from '@/components/loaders/loader';
import TableHeader from '@/components/table/fat-tableHeader';
import { TableBodyWrapper, TableWrapper } from '@/components/table/styles';
import { ISort } from '@/components/table/types';
import { useResponsive } from '@/hooks/use-responsive';
import useStickyFooter from '@/hooks/useStickyFooter';
import { useRef } from 'react';
import styled from 'styled-components';
import { IInvestmentEntityByAdvisoryFirm, InvestorInterestViewType } from '../../types';
import { advisorColumns, investorColumns } from '../constatnts';
import MobileSumFooterAdvisor from './mobileSumFooterAdvisor';
import MobileSumFooterInvestor from './mobileSumFooterInvestor';
import { default as MobileTableAdvisor } from './mobileTable/mobileTableAdvisor';
import MobileTableInvestor from './mobileTable/mobileTableInvestor';
import TableBodyAdvisor from './tableBodyAdvisor';
import TableBodyInvestor from './tableBodyInvestor';
import TableSumFooterAdvisor from './tableSumFooterAdvisor';
import TableSumFooterInvestor from './tableSumFooterInvestor';

interface TableProps {
  listInvestmentEntity: IInvestmentEntity[];
  listInvestmentEntityByAdvisoryFirm: IInvestmentEntityByAdvisoryFirm[];
  sort: ISort;
  setSort: (value: ISort) => void;
  loading: boolean;
  viewByValue: InvestorInterestViewType;
  openAdvisorFirmDetails: (id: string) => void;
}

const Table = ({
  listInvestmentEntity,
  listInvestmentEntityByAdvisoryFirm,
  sort,
  setSort,
  loading,
  viewByValue,
  openAdvisorFirmDetails
}: TableProps) => {
  const { isMobile, isTablet } = useResponsive();

  const containerRef = useRef<HTMLDivElement | null>(null);
  const footerRef = useRef<HTMLDivElement | null>(null);

  useStickyFooter(containerRef, footerRef);

  const TableBody = () =>
    viewByValue === 'Advisory Firm' ? (
      <TableWrapper padding="0">
        {isMobile ? (
          <MobileTableAdvisor
            listInvestmentEntityByAdvisoryFirm={listInvestmentEntityByAdvisoryFirm}
            loading={loading}
            openAdvisorFirmDetails={openAdvisorFirmDetails}
          />
        ) : (
          <>
            <CustomTableHeader isTablet={isTablet} refetch={() => null as any} columns={advisorColumns} savedSort={sort} savedSetSort={setSort} />
            <TableBodyWrapper>
              {loading ? (
                <Loader />
              ) : (
                <TableBodyAdvisor
                  listInvestmentEntityByAdvisoryFirm={listInvestmentEntityByAdvisoryFirm}
                  openAdvisorFirmDetails={openAdvisorFirmDetails}
                />
              )}
            </TableBodyWrapper>
          </>
        )}
      </TableWrapper>
    ) : (
      <TableWrapper padding="0">
        {isMobile ? (
          <MobileTableInvestor listInvestmentEntity={listInvestmentEntity} loading={loading} />
        ) : (
          <>
            <CustomTableHeader isTablet={isTablet} refetch={() => null as any} columns={investorColumns} savedSort={sort} savedSetSort={setSort} />
            <TableBodyWrapper>{loading ? <Loader /> : <TableBodyInvestor listInvestmentEntity={listInvestmentEntity} />}</TableBodyWrapper>
          </>
        )}
      </TableWrapper>
    );

  return (
    <>
      <TableBody />
      {isMobile && !loading ? (
        <>
          {viewByValue === 'Advisory Firm' ? (
            <MobileSumFooterAdvisor listInvestmentEntityByAdvisoryFirm={listInvestmentEntityByAdvisoryFirm} />
          ) : (
            <MobileSumFooterInvestor listInvestmentEntity={listInvestmentEntity} />
          )}
        </>
      ) : !loading ? (
        <TableSumFooterWrapper ref={footerRef}>
          {viewByValue === 'Advisory Firm' ? (
            <TableSumFooterAdvisor listInvestmentEntityByAdvisoryFirm={listInvestmentEntityByAdvisoryFirm} />
          ) : (
            <TableSumFooterInvestor listInvestmentEntity={listInvestmentEntity} />
          )}
        </TableSumFooterWrapper>
      ) : (
        <></>
      )}
      <div ref={containerRef}></div>
    </>
  );
};

export default Table;

const CustomTableHeader = styled(TableHeader)<{ isTablet: boolean }>`
  position: sticky;
  top: ${({ isTablet }) => (isTablet ? '130px' : '80px')};
  z-index: 8;
  padding-top: 0;
`;

const TableSumFooterWrapper = styled.div`
  &.sticky {
    position: sticky;
    bottom: 0;
    z-index: 1;
  }
`;
