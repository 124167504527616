import { FilterSettingIcon } from '@/assets/icons/filterSetting';
import Tabs, { ITab } from '@/components/basicComponents/fat-tabs';
import Button from '@/components/fat-basicComponents/button';
import { MainWrap, PaddingWrap } from '@/styles/common';
import { Dispatch, SetStateAction, useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { ColumnsDisplayed } from '../columnsDisplayed';
import { SelectedColumnsList } from '../selectedColumnsList';
import { ColumnList } from '../types';

interface EditTableSettingsMobileProps {
  onClose: () => void;
  columnsList: ColumnList[];
  setColumnsList: Dispatch<SetStateAction<ColumnList[]>>;
}

const tabs: ITab[] = [
  { value: 'Columns Displayed', amount: null },
  { value: 'Display Order', amount: null }
];

const EditTableSettingsMobile = ({ onClose, columnsList, setColumnsList }: EditTableSettingsMobileProps) => {
  const theme = useTheme();

  const [activeTab, setActiveTab] = useState(tabs[0].value);
  const [newColumns, setNewColumns] = useState(columnsList.map((column) => ({ ...column })));

  const saveNewColumns = () => {
    setColumnsList(newColumns);
    const updatedOrder = newColumns.map((column) => {
      return { rowKey: column.rowKey, value: column.value };
    });
    localStorage.setItem('dealRegistryColumnsOrder', JSON.stringify(updatedOrder));
    onClose();
  };

  return (
    <MainWrap>
      <PaddingWrap>
        <MobileHeaderContainer>
          <MobileTitle>
            <FilterSettingIcon fill={theme.font.strong} width={29} height={29} />
            Choose Which Columns You See
          </MobileTitle>
          <Cross width="max-content" src="/static/icons/blue-cross.svg" alt="Cross" onClick={onClose} />
        </MobileHeaderContainer>
        <TabsWrapper>
          <Tabs tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab} />
        </TabsWrapper>
        {activeTab === 'Columns Displayed' && <ColumnsDisplayed newColumns={newColumns} setNewColumns={setNewColumns} />}
        {activeTab === 'Display Order' && <SelectedColumnsList newColumns={newColumns} setNewColumns={setNewColumns} />}
        <ActionButtons>
          <Button onClick={onClose} styleType="outline" size="md">
            Cancel
          </Button>
          <Button onClick={saveNewColumns} size="md">
            Apply
          </Button>
        </ActionButtons>
      </PaddingWrap>
    </MainWrap>
  );
};

export default EditTableSettingsMobile;

const MobileHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 20px;
`;

const MobileTitle = styled.div`
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 20px;
  gap: 6px;
  color: ${({ theme }) => theme.font.strong};
`;

const Cross = styled.img<{ width: string }>`
  cursor: pointer;
  margin-left: auto;
  width: ${({ width }) => width};
`;

const TabsWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 5px;
  padding-top: 30px;
`;

const ActionButtons = styled.div`
  display: flex;
  gap: 10px;
  justify-content: flex-end;
  padding: 30px 60px;
`;
