export const investorEntitiesSteps = [
  { name: 'Getting Started', status: 'IN_PROGRESS' },
  { name: 'Upload Data File', status: 'NOT_STARTED' },
  { name: 'Investor Entities', status: 'NOT_STARTED' },
  { name: 'Investor Households', status: 'NOT_STARTED' },
  { name: 'Groups', status: 'NOT_STARTED' }
];

export const startOverInvestorEntitiesSteps = [
  { name: 'Getting Started', status: 'COMPLETED' },
  { name: 'Upload Data File', status: 'IN_PROGRESS' },
  { name: 'Investor Entities', status: 'NOT_STARTED' },
  { name: 'Investor Households', status: 'NOT_STARTED' },
  { name: 'Groups', status: 'NOT_STARTED' }
];

export const investorEntitiesDescriptionSectionData = {
  title: 'Import Investor Entities performs the following actions',
  description: [
    'Validates investor house-holding.',
    'Updates existing entities or creates new entities based on the supplied identifier in the import file.',
    'Provides a preview of all changes before saving.'
  ]
};
export const investorEntitiesColumnsSectionData = {
  title: 'Provide the following columns in the upload file',
  columns: [
    { name: 'Entity CRM ID', isRequired: true },
    { name: 'Entity Name', isRequired: true },
    { name: 'Investor Name', isRequired: true },
    { name: 'Grouped', isRequired: false },
    { name: 'Primary Entity', isRequired: false },
    { name: 'Total Entity Value', isRequired: false },
    { name: 'Private Percentage', isRequired: false },
    { name: 'OC Ratio', isRequired: false },
    { name: 'Sectors to Avoid', isRequired: false },
    { name: 'SRI', isRequired: false },
    { name: 'Tax Exempt', isRequired: false },
    { name: 'Custodied', isRequired: false },
    { name: 'Unmanaged', isRequired: false },
    { name: 'Always Show', isRequired: false },
    { name: 'On Hold', isRequired: false },
    { name: 'Entity Inception Date', isRequired: false },
    { name: 'Default Bite Size 1', isRequired: false },
    { name: 'Default Bite Size 2', isRequired: false },
    { name: 'Client Advisor Owner', isRequired: false }
  ]
};
