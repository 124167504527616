import * as React from 'react';

export const AutoPlanIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || 23}
    height={props.height || 18}
    onClick={props.onClick || undefined}
    style={props?.style}
    viewBox="0 0 23 18"
    fill="none"
  >
    <path
      d="M20.4756 5.50781C20.1706 5.50897 19.8699 5.57984 19.5964 5.71501C19.323 5.85018 19.084 6.04607 18.8979 6.28771C18.7117 6.52936 18.5833 6.81038 18.5224 7.10926C18.4614 7.40815 18.4697 7.71699 18.5464 8.01221L14.9995 11.5711C14.6598 11.4812 14.3026 11.4812 13.963 11.5711L11.4166 9.02476C11.4964 8.72901 11.5071 8.41886 11.4477 8.11833C11.3883 7.81781 11.2606 7.535 11.0743 7.29181C10.888 7.04862 10.6483 6.85157 10.3736 6.71597C10.0989 6.58036 9.79673 6.50983 9.4904 6.50983C9.18408 6.50983 8.88184 6.58036 8.60716 6.71597C8.33249 6.85157 8.09274 7.04862 7.90647 7.29181C7.72021 7.535 7.59243 7.81781 7.53307 8.11833C7.47371 8.41886 7.48434 8.72901 7.56415 9.02476L3.0107 13.5663C2.58684 13.4529 2.13741 13.4824 1.73201 13.6502C1.32661 13.818 0.987838 14.1148 0.768135 14.4946C0.548432 14.8744 0.460063 15.316 0.516689 15.7511C0.573316 16.1862 0.771785 16.5905 1.08138 16.9014C1.39098 17.2123 1.79442 17.4125 2.22927 17.471C2.66412 17.5294 3.10611 17.4429 3.48683 17.2248C3.86755 17.0067 4.16576 16.6692 4.33529 16.2645C4.50483 15.8598 4.53625 15.4105 4.42467 14.9862L8.97212 10.4327C9.31177 10.5226 9.669 10.5226 10.0087 10.4327L12.555 12.9791C12.4752 13.2748 12.4646 13.585 12.5239 13.8855C12.5833 14.186 12.7111 14.4689 12.8973 14.7121C13.0836 14.9552 13.3233 15.1523 13.598 15.2879C13.8727 15.4235 14.1749 15.494 14.4812 15.494C14.7875 15.494 15.0898 15.4235 15.3645 15.2879C15.6391 15.1523 15.8789 14.9552 16.0651 14.7121C16.2514 14.4689 16.3792 14.186 16.4385 13.8855C16.4979 13.585 16.4873 13.2748 16.4075 12.9791L19.9963 9.43817C20.2706 9.51047 20.5571 9.52345 20.8368 9.47627C21.1164 9.42909 21.3828 9.32283 21.6182 9.16456C21.8536 9.00629 22.0525 8.79965 22.2017 8.55844C22.3509 8.31722 22.4469 8.04697 22.4834 7.7657C22.5199 7.48444 22.496 7.1986 22.4133 6.9273C22.3307 6.65599 22.1911 6.40544 22.0039 6.19234C21.8168 5.97924 21.5863 5.8085 21.3279 5.6915C21.0695 5.57449 20.7892 5.51391 20.5056 5.51381L20.4756 5.50781Z"
      fill={props.fill || '#F4F5F6'}
    />
    <path
      d="M14.4841 6.50214L15.4247 4.4351L17.4798 3.50644L15.4247 2.57778L14.4841 0.510742L13.5674 2.57778L11.4884 3.50644L13.5674 4.4351L14.4841 6.50214Z"
      fill={props.fill || '#F4F5F6'}
    />
    <path
      d="M2.99871 8.50364L3.50197 6.50253L5.49712 6.00524L3.50197 5.50794L2.99871 3.50684L2.50142 5.50794L0.506271 6.00524L2.50142 6.50253L2.99871 8.50364Z"
      fill={props.fill || '#F4F5F6'}
    />
  </svg>
);
