import { InfoIcon } from '@/assets/icons/info-icons/info';
import { Banner } from '@/components/basicComponents/banner';
import RadioButton from '@/components/basicComponents/radioButton';
import Input from '@/components/fat-basicComponents/input';
import { formatDollarInputValue, formatMaskedDollarInputValue } from '@/components/fat-basicComponents/input/utils';
import Label from '@/components/fat-basicComponents/label';
import { useResponsive } from '@/hooks/use-responsive';
import { useStatusData } from '@/hooks/useStatusData';
import { AnimatePresence, motion } from 'framer-motion';
import { Control, Controller, useWatch } from 'react-hook-form';
import styled, { useTheme } from 'styled-components';
import { capacityCalculationOptions, OptionsWrapper, RadioInputWrapper, RadioWrapper, TwoColumnEntityWrapper } from '..';
import { ChartWithTable } from '../../fat-investorDetails-page/fat-entityDetails-page/autoPlanWizardPage/steps/allocationTargets/chartWithTable';
import { Section } from '../../fat-investorDetails-page/fat-entityDetails-page/edit-entity/section';
import { DrawdownTargetsDto } from '../../fat-investorDetails-page/fat-entityDetails-page/fat-pacingTab/types';
import { CreateInvestorDto } from '../types';

const ignoreAllocationTargetsOptions = ['Disregard allocation targets', 'Set allocation targets for this entity'];
const pacingModelDescription =
  'Create a multi-year commitment plan that builds up to a private capital allocation with a total Net Asset Value equal to the above targets in each asset class.  The commitment plan is based on forecasted capital calls and distributions.  The timing of new commitments is calculated to achieve and maintain the targeted Net Asset Value in each asset class.';
const overCommitmentRatioDescription =
  'Capacity is calculated by deducting Economic Exposure (the current value of existing commitments plus the corresponding unfunded commitment) from the investor entity’s private capital target (Total Entity Value x Portfolio %). Use the Over Commitment Ratio to increase capacity for more aggressive portfolios.';

interface SetAllocationTargetsProps {
  control: Control<CreateInvestorDto, any>;
  isSectionOpen: boolean;
  assetClassChartWithTableData: DrawdownTargetsDto | null;
  openAssetClassTargetsPage: () => void;
  saveSectionState: (id: string, state: { isSectionOpen: boolean }) => void;
}

export const SetAllocationTargets = ({
  control,
  isSectionOpen,
  assetClassChartWithTableData,
  openAssetClassTargetsPage,
  saveSectionState
}: SetAllocationTargetsProps) => {
  const { data: statusData } = useStatusData();
  const { isMobile } = useResponsive();
  const theme = useTheme();
  const ignoreAllocationTargetsValue = useWatch({
    control,
    name: 'ignoreAllocationTargets'
  });
  const capacityCalculationOptionValue = useWatch({
    control,
    name: 'capacityCalculationOption'
  });

  return (
    <Section title="Allocation Targets" id="allocationTargetsSection" openSection={isSectionOpen} saveSectionState={saveSectionState} collapsable>
      <RadioWrapper>
        Set Allocation Targets for this entity:
        <RadioInputWrapper isMobile={isMobile}>
          <OptionsWrapper>
            <Controller
              name="ignoreAllocationTargets"
              control={control}
              render={({ field: { value, onChange } }) => (
                <>
                  {ignoreAllocationTargetsOptions.map((option, index) => (
                    <RadioButton
                      key={option + index}
                      name="ignoreAllocationTargets"
                      radioButtonText={option}
                      selectedOption={value ? ignoreAllocationTargetsOptions[0] : ignoreAllocationTargetsOptions[1]}
                      handleRadioChange={(event) => onChange(event.target.value === ignoreAllocationTargetsOptions[0])}
                    />
                  ))}
                </>
              )}
            />
          </OptionsWrapper>
          <Banner
            title=""
            description="When you provide Allocation Targets for this entity capacity calculations and allocation suitability checks are made available."
            icon={<InfoIcon width={26} height={26} fill={theme.header.base} />}
            bgColor={theme.layer[1]}
            color={theme.font.base}
          />
        </RadioInputWrapper>
      </RadioWrapper>
      <AnimatePresence>
        {!ignoreAllocationTargetsValue && (
          <motion.div
            initial={{
              opacity: 0
            }}
            animate={{
              opacity: 1
            }}
            exit={{
              opacity: 0
            }}
          >
            <TwoColumnEntityWrapper isMobile={isMobile}>
              <Controller
                name="totalEntityValue"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: 'Total Entity Value is required'
                  },
                  min: {
                    value: 1,
                    message: 'Total Entity Value must be greater than 0'
                  }
                }}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <Input
                    label="Total Entity Value"
                    value={value ? formatMaskedDollarInputValue(value) : ''}
                    onChange={(e) => {
                      const formattedValue = formatDollarInputValue(e.target.value);
                      onChange(formattedValue);
                    }}
                    errorText={error?.message}
                    tooltipWidth={400}
                    tooltip="The total investable assets for this entity.  This value is used to calculate target allocation amounts."
                    withDollarSign
                    isRequiredField
                    styleType="form"
                  />
                )}
              />
            </TwoColumnEntityWrapper>
            <DrawdownTargetWrapper>
              <ChartWithTable drawdownTargetDialogData={assetClassChartWithTableData} openAssetClassTargetsPage={openAssetClassTargetsPage} />
            </DrawdownTargetWrapper>
            <CapacityCalculationRadioWrapper>
              Capacity Calculation
              <RadioInputWrapper isMobile={isMobile}>
                {statusData?.pacing_model_enabled && (
                  <OptionsWrapper>
                    <Controller
                      name="capacityCalculationOption"
                      control={control}
                      render={({ field: { value, onChange } }) => (
                        <>
                          {capacityCalculationOptions.map((option, index) => (
                            <RadioButton
                              key={option + index}
                              name="capacityCalculation"
                              radioButtonText={option}
                              selectedOption={value}
                              handleRadioChange={(event) => onChange(event.target.value)}
                            />
                          ))}
                        </>
                      )}
                    />
                  </OptionsWrapper>
                )}
                {(capacityCalculationOptionValue === capacityCalculationOptions[1] || !statusData?.pacing_model_enabled) && (
                  <RatioWrap isMobile={isMobile}>
                    <Label size="lg" tooltip="Optionally set an over commitment ratio to over allocate slightly." tooltipWidth={300} required>
                      OC Ratio
                    </Label>
                    <RatioInputWrap>
                      <RatioValue>1.</RatioValue>
                      <Controller
                        name="overCommitRatio"
                        control={control}
                        rules={{
                          required: {
                            value: true,
                            message: 'OC Ratio is required'
                          }
                        }}
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                          <Input
                            value={value}
                            onChange={(e) => {
                              let inputValue = e.target.value;
                              inputValue = inputValue.replace(/[^0-9]/g, '');
                              if (inputValue === '00') {
                                inputValue = '0';
                              }
                              if (inputValue.length > 2) {
                                inputValue = inputValue.slice(0, 2);
                              }
                              onChange(inputValue);
                            }}
                            errorText={error?.message}
                            width="47px"
                            styleType="form"
                          />
                        )}
                      />
                    </RatioInputWrap>
                  </RatioWrap>
                )}
                <Banner
                  title=""
                  description={
                    capacityCalculationOptionValue === capacityCalculationOptions[0] ? pacingModelDescription : overCommitmentRatioDescription
                  }
                  icon={<InfoIcon width={26} height={26} fill={theme.header.base} />}
                  bgColor={theme.layer[1]}
                  color={theme.font.base}
                />
              </RadioInputWrapper>
            </CapacityCalculationRadioWrapper>
          </motion.div>
        )}
      </AnimatePresence>
    </Section>
  );
};

const DrawdownTargetWrapper = styled.div`
  margin-top: 60px;
`;

const CapacityCalculationRadioWrapper = styled.div`
  margin-top: 20px;
  color: ${({ theme }) => theme.font.base};
`;

const RatioWrap = styled.div<{ isMobile: boolean }>`
  min-width: 250px;
`;

const RatioInputWrap = styled.div`
  display: flex;
  font-size: 19px;
`;

const RatioValue = styled.div`
  padding: 10px 12px;
  color: ${({ theme }) => theme.font.strong};
`;
