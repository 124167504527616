import { FilledIcon } from '@/assets/icons/info-icons/filledIcon';
import { formatCash } from '@/utils/formatCash';
import { useEffect, useMemo, useState } from 'react';
import styled, { useTheme } from 'styled-components';

import { ProgressBarCursorIcon } from '@/assets/icons/info-icons/progressBarCursor';
import { useResponsive } from '../../hooks/use-responsive';
import BasicTooltip from '../basicComponents/tooltip';
import { ProgressBarValuesType } from './types';

type ProgressBarProps = {
  title: string;
  data: ProgressBarValuesType | undefined;
};

const ProgressBar = ({ title, data }: ProgressBarProps) => {
  const { isMobile } = useResponsive();
  const theme = useTheme();
  const [progressData, serProgressData] = useState<ProgressBarValuesType>();

  const progressDataMemo = useMemo(() => {
    return data ? data : progressData;
  }, [data]);

  useEffect(() => {
    if (data) {
      serProgressData(data);
    }
  }, [data]);

  return (
    <ProgressBarContainer>
      <Title>
        {title}
        <BasicTooltip
          translateX={isMobile ? -68 : -70}
          tooltipContent={
            <TooltipBody>
              <TooltipItem>{`${progressDataMemo?.economicExposure.toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD',
                minimumFractionDigits: 0,
                maximumFractionDigits: 0
              })}: Current Economic Exposure (Remaining Capital + Current Value)`}</TooltipItem>
              <TooltipItem>{`${progressDataMemo?.capitalTarget.toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD',
                minimumFractionDigits: 0,
                maximumFractionDigits: 0
              })}: Target Private Capital without Over-Commitment Ratio`}</TooltipItem>
              <TooltipItem>{`${progressDataMemo?.totalCapitalTarget.toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD',
                minimumFractionDigits: 0,
                maximumFractionDigits: 0
              })}: Target Private Capital with Over-Commitment Ratio`}</TooltipItem>
            </TooltipBody>
          }
        >
          <FilledIcon />
        </BasicTooltip>
      </Title>
      <ProgressBarWrap>
        <ProgressCursor
          margin={
            ((progressDataMemo?.capitalTarget as number) * 100) /
              (typeof progressDataMemo?.totalCapitalTarget === 'number' ? progressDataMemo?.totalCapitalTarget : 1) || 0
          }
        >
          <CursorTitle>{formatCash(progressDataMemo?.capitalTarget as number)}</CursorTitle>
          <ProgressBarCursorIcon fill={theme.font.weak} />
        </ProgressCursor>
        <ProgressWrap>
          <ProgressTitle>{formatCash(progressDataMemo?.economicExposure as number)}</ProgressTitle>
          <Progress
            isFull={!!progressDataMemo?.isGoalAchieved}
            width={
              ((progressDataMemo?.economicExposure as number) * 100) /
                (typeof progressDataMemo?.totalCapitalTarget === 'number' ? progressDataMemo?.totalCapitalTarget : 1) || 0
            }
          />
        </ProgressWrap>
      </ProgressBarWrap>
      <StartEnd>
        <StartEndTitle>{formatCash(0)}</StartEndTitle>
        <StartEndTitle>{formatCash(progressDataMemo?.totalCapitalTarget as number)}</StartEndTitle>
      </StartEnd>
    </ProgressBarContainer>
  );
};

const TooltipBody = styled.div`
  display: flex;
  flex-direction: column;
`;

const TooltipItem = styled.div`
  font-family: 'Blinker', serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0;
  text-align: left;
`;

const StartEndTitle = styled.div`
  font-family: 'Blinker', serif;
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0;
  color: ${({ theme }) => theme.font.weak};
`;

const StartEnd = styled.div`
  margin-top: -12px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ProgressTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  font-family: 'Blinker', serif;
  font-size: 13px;
  font-weight: 600;
  line-height: 12px;
  text-align: center;
  color: ${({ theme }) => theme.context.light};
`;

const Progress = styled.div<{ width: number | string; isFull: boolean }>`
  display: flex;
  width: ${({ width }) => `${width}%`};
  height: 20px;
  background: ${({ isFull, theme }) => (isFull ? theme.context.error : theme.context.success)};
  transition: 0.3s ease-in-out;
`;

const ProgressWrap = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-start;
  overflow: hidden;
  width: 100%;
  height: 20px;
  border-radius: 10px;
  background: #94e3a1;
  max-height: 20px;
`;

const CursorTitle = styled.div`
  font-family: Blinker, serif;
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0;
  text-align: left;
  margin-bottom: -7px;
  color: ${({ theme }) => theme.font.weak};
`;

const ProgressCursor = styled.div<{ margin: number }>`
  display: flex;
  margin-left: ${({ margin }) => `calc(${margin}% - 14px)`};
  align-items: center;
  align-self: flex-start;
  flex-direction: column;
  gap: 4px;
  width: 28px;
  min-width: 28px;
  max-width: 28px;
  transition: 0.3s ease-in-out;
`;

const ProgressBarWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  font-family: Blinker, serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0;
  color: ${({ theme }) => theme.font.strong};
`;

const ProgressBarContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 12px;
  padding: 20px 30px;
`;

export default ProgressBar;
