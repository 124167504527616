import { gql } from '@apollo/client';

export const LIST_INVESTMENT_ENTITY_INTERESTS_QUERY = gql`
  query listInvestmentEntityInterests($data: InvestmentEntityListDto!) {
    listInvestmentEntityInterests(data: $data) {
      total
      page
      perPage
      lastPage
      data {
        id
        entityName
        overallCapacity
        assetClassCapacity
        suitability
        totalEntityValue
        biteSize
        fundBiteSize
        directBiteSize
        alwaysShow
        ubtiBlocker
        ignoreAllocationTargets
        ignoreBiteSize
        tenant {
          name
        }
        family {
          id
          name
        }
        advisor {
          firstName
          lastName
          initials
        }
        suitabilityCriteria {
          label
          value
        }
        investor_interest {
          id
          amount
          legal_entity {
            id
            entityName
          }
        }
      }
    }
  }
`;

export const INVESTMENT_QUERY = gql`
  query Investment($id: String!) {
    Investment(id: $id) {
      id
      symbol
      name
      type
      managerId
      assetClass {
        id
        name
      }
      subAssetClass {
        id
        name
      }
      sectors
      averageCalledPercent
      countOfInvestors
      committedCapital
      economicExposure
      targetReturn
      targetYield
      vintageYear
      nextClose
      finalClose
      investorDocsDue
      minimum
      sri
      ubtiBlocker
      subAssetClasses
      tags
      description
      securityStatus
      investmentOwner {
        firstName
        lastName
        email
      }
      operationsOwner {
        firstName
        lastName
        email
      }
      complianceOwner {
        firstName
        lastName
        email
      }
      offeringMaterialsDescription
      offeringMaterialsAttachments {
        id
        asset {
          url
          assetKey
        }
      }
    }
  }
`;

export const GET_INVESTOR_INTERESTS_MIN_QUERY = gql`
  query getInvestorInterests($data: InvestorInterestListDto!) {
    getInvestorInterests(data: $data) {
      total
      page
      perPage
      data {
        investment {
          id
        }
        investorInterests {
          id
          amount
          legal_entity {
            id
            entityName
          }
        }
      }
    }
  }
`;
