import { useFormik } from 'formik';
import { FC, useState } from 'react';
import * as Yup from 'yup';

import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useAuth } from '../../hooks/use-auth';
import { useMounted } from '../../hooks/use-mounted';
import { useResponsive } from '../../hooks/use-responsive';
import Button from '../basicComponents/button';
import { InputFieldProps } from '../input-fields';
import { LinksProps } from '../links/index';
import { PasswordToggleButton } from '../passwordToggleButton';

export const FirebaseLogin: FC = (props) => {
  const { isMobile, isTablet, isPortrait } = useResponsive();
  const { signInWithEmailAndPassword } = useAuth();
  const isMounted = useMounted();
  const navigate = useNavigate();

  const [isPasswordMasked, setIsPasswordMasked] = useState(true);

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      submit: null,
      isSubmitting: true
    },
    validationSchema: Yup.object({
      email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
      password: Yup.string().required('Password is required')
    }),
    onSubmit: async (values, helpers): Promise<void> => {
      try {
        const { appSetting } = await signInWithEmailAndPassword(values.email, values.password);
        if (isMounted()) {
          const pathName = appSetting.DDR && !appSetting.Allocator ? '/dealRegistry' : '/todo';
          navigate(`${pathName}?isFirstOpen=true`);
        }
      } catch (err) {
        if (isMounted()) {
          helpers.setStatus({ success: false });
          helpers.setErrors({ password: 'We do not recognize your login credentials' });
          helpers.setSubmitting(true);
        }
      }
    }
  });

  return (
    <LoginWrapper isMobile={isMobile} isPortrait={isPortrait} isTablet={isTablet}>
      <LoginForm noValidate onSubmit={formik.handleSubmit} {...props}>
        <div>
          <InputLabelWrap>
            {/* <RedStarIcon width={12} /> */}
            <p>Enter Your Email</p>
          </InputLabelWrap>
          <InputFieldProps
            error={Boolean(formik.touched.email && formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
            name="email"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            type="email"
            value={formik.values.email}
          />
        </div>
        <div>
          <InputLabelWrap>
            {/* <RedStarIcon width={12} /> */}
            <p>Enter Your Password</p>
          </InputLabelWrap>
          <InputFieldProps
            error={Boolean(formik.touched.password && formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
            name="password"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            type={isPasswordMasked ? 'password' : 'text'}
            value={formik.values.password}
            icon={<PasswordToggleButton isPasswordMasked={isPasswordMasked} setIsPasswordMasked={setIsPasswordMasked} />}
          />
          <SignInButton type="submit" bgColor="#172d4d" disabled={formik.isSubmitting || !formik.isValid}>
            Sign In
          </SignInButton>
        </div>
        <CustomLinksProps underline="none" textColor="#578fe4" url={'/password-recovery'}>
          Forgot Password
        </CustomLinksProps>
      </LoginForm>
    </LoginWrapper>
  );
};

const LoginWrapper = styled.div<{ isMobile: boolean; isPortrait: boolean; isTablet: boolean }>`
  color: #373f4e;
  font-family: 'Blinker', serif;
  font-size: 19px;
  font-weight: 400;
  line-height: 26.6px;
  width: ${({ isMobile, isPortrait, isTablet }) => (isMobile || (isPortrait && isTablet) ? '70%' : '50%')};
`;

const LoginForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const InputLabelWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const SignInButton = styled(Button)`
  text-transform: uppercase;
  margin-top: 30px;
  width: 100%;
  line-height: 33.25px;
`;

const CustomLinksProps = styled(LinksProps)`
  display: flex;
  justify-content: center;
  font-size: 16px;
  font-weight: 400;
`;
