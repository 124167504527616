import { InfoIcon } from '@/assets/icons/info-icons/info';
import { Banner } from '@/components/basicComponents/banner';
import Button from '@/components/fat-basicComponents/button';
import ModalWrappedContent from '@/components/fat-basicComponents/modal/modalWrappedContent';
import axios from 'axios';
import fileDownload from 'js-file-download';
import { useState } from 'react';
import styled, { useTheme } from 'styled-components';

interface ComingSoonProps {
  onClose: () => void;
  isOpen: boolean;
  planId: string | null;
}

const DEFAULT_ERROR_MESSAGE = `We're sorry. This process did not complete. Please try again.`;

const ExportCSV = ({ onClose, isOpen, planId }: ComingSoonProps) => {
  const theme = useTheme();

  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const handleDownload = async () => {
    try {
      setLoading(true);
      setErrorMessage(null);
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_BASE_URL}/pacing-model/export`,
        {
          planId
        },
        {
          headers: { authorization: `Bearer ${localStorage.getItem('token')}` },
          responseType: 'blob'
        }
      );

      fileDownload(response.data, 'commitment-plan.csv');
    } catch (error) {
      console.error(error);
      setErrorMessage(DEFAULT_ERROR_MESSAGE);
    } finally {
      setLoading(false);
    }
  };

  if (!planId) return <></>;

  return (
    <ModalWrappedContent title="Export" isOpen={isOpen} onClose={onClose} showCancelButton={false}>
      {errorMessage && (
        <ErrorBannerWrapper>
          <Banner icon={<InfoIcon width={26} height={26} />} title="Error" description={errorMessage} bgColor={theme.context.error} />
        </ErrorBannerWrapper>
      )}
      <ModalBody>
        <Button styleType={loading ? 'disabled' : 'default'} size="md" onClick={handleDownload} isLoading={loading}>
          Generate CSV
        </Button>
      </ModalBody>
    </ModalWrappedContent>
  );
};

export default ExportCSV;

const ModalBody = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 30px;
`;

const ErrorBannerWrapper = styled.div`
  margin-bottom: 20px;
`;
