import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { useResponsive } from '../../hooks/use-responsive';
import { IPaginationValues } from '../clients-page/types';
import { Loader } from '../loaders/loader';
import TableHeader from '../table/fat-tableHeader';
import { TableWrapper } from '../table/styles';
import TablePagination from '../table/tablePagination';
import { ISort } from '../table/types';
import { columns } from './constatnts';
import { EntityType } from './index';
import { TableBody } from './tableBody';
import MobileTable from './tableMobile';
import { IRowData } from './types';

export type AdvisorTableProps = {
  handleFamilyClick: (id: string) => void;
  handleEntityClick: (ids: EntityType) => void;
  complianceRows: IRowData[];
  loading: boolean;
  refetch: any;
  saveScrollPostion: () => void;
  paginationValues: IPaginationValues;
  savePagination: (limit: number, offset: number) => void;
  savedSort?: ISort;
  savedSetSort?: (value: ISort) => void;
  onEntityClick: (data: IRowData) => void;
  onInvestmentClick: (data: IRowData) => void;
  onStatusClick: (data: IRowData) => void;
};

const Table = ({
  savedSort,
  savedSetSort,
  handleFamilyClick,
  handleEntityClick,
  complianceRows,
  loading,
  refetch,
  saveScrollPostion,
  paginationValues,
  savePagination,
  onEntityClick,
  onInvestmentClick,
  onStatusClick
}: AdvisorTableProps) => {
  const navigate = useNavigate();
  const { isMobile, isTablet } = useResponsive();

  const handleClickInvestment = (name: string, id: string) => {
    saveScrollPostion();
    navigate(`/investments/${name}/${id}?backToTitle=Compliance`);
  };

  return (
    <TableWrapper>
      {isMobile ? (
        <>
          {loading ? (
            <Loader />
          ) : (
            <MobileTable
              onStatusClick={onStatusClick}
              onInvestmentClick={onInvestmentClick}
              onEntityClick={onEntityClick}
              columns={columns}
              complianceRows={complianceRows}
              handleFamilyClick={handleFamilyClick}
              handleEntityClick={handleEntityClick}
              handleClickInvestment={handleClickInvestment}
            />
          )}
        </>
      ) : (
        <>
          <CustomTableHeader isTablet={isTablet} savedSort={savedSort} savedSetSort={savedSetSort} columns={columns} refetch={refetch} />
          {loading ? (
            <Loader />
          ) : (
            <>
              <TableBody
                handleFamilyClick={handleFamilyClick}
                handleEntityClick={handleEntityClick}
                handleClickInvestment={handleClickInvestment}
                complianceRows={complianceRows}
                amountColumns={columns.length}
              />
              <PaginationWrap>
                <TablePagination paginationValues={paginationValues} savePagination={savePagination} refetch={refetch} />
              </PaginationWrap>
            </>
          )}
        </>
      )}
    </TableWrapper>
  );
};

const PaginationWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

const CustomTableHeader = styled(TableHeader)<{ isTablet: boolean }>`
  position: sticky;
  top: ${({ isTablet }) => (isTablet ? '130px' : '80px')};
  z-index: 8;
  padding-top: 0;
`;

export default Table;
