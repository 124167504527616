import { subject } from '@casl/ability';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { buildAbilityFor } from '../../../config/ability';
import { useAuth } from '../../../hooks/use-auth';
import { ArrowDown } from '@/assets/icons/info-icons/arrowDown';
import { User } from '../../../types/user';
import FilterSelect from '../../basicComponents/select';
import { AnchorPopover } from '../../table/anchor-drop-down';
import { CellWrapper } from '../../table/styles';
import EntityPopover from '../popovers/entity';
import { ACTIONS, IRowData } from '../types';

interface EntityProps {
  row: IRowData;
  changeSelect: (value: string) => void;
  onRedirect: () => void;
  isMobile?: boolean;
  onClick?: (data: IRowData) => void;
}

const Entity = ({
  row,
  changeSelect,
  onRedirect,
  isMobile,
  onClick
}: EntityProps) => {
  const [switcher, setSwitcher] = useState(true);
  const {
    entityName,
    grouped,
    value,
    items,
    investmentType,
    alwaysShow,
    assetClass,
    investmentName
  } = {
    entityName: row.legalEntity.entityName,
    grouped: row.legalEntity.isGroupEntity,
    value: row.selectedGroupLegalEntity?.entityName,
    items: row.legalEntity.entities,

    investmentType: row.investment.type,
    alwaysShow: row.legalEntity.alwaysShow,
    assetClass: row.investment.assetClass,
    investmentName: row.investment.name
  };
  const windowHeight = window.innerHeight;
  const isTwoColumn = windowHeight - 215 <= 600;
  const ability = buildAbilityFor(useAuth().user as User);
  const currentUser = useAuth()?.user;

  const [canChange, setCanChange] = useState(
    ability?.can(
      ACTIONS.CHANGE_SELECTED_GROUPED_ENTITY,
      subject('Allocation', { ...row, user: currentUser })
    )
  );

  useEffect(() => {
    setCanChange(
      ability?.can(
        ACTIONS.CHANGE_SELECTED_GROUPED_ENTITY,
        subject('Allocation', {
          ...row,
          user: currentUser
        })
      )
    );
  }, []);

  const tooltipContent = `${assetClass} corresponds to\n${investmentName}.`;
  return (
    <CellWrapper isMobile={isMobile} width={'100%'} flexDirection={'column'}>
      <div onClick={() => (onClick ? onClick(row) : null)}>
        <CustomAnchorPopover
          isTwoColumn={isTwoColumn}
          isMobile={isMobile || false}
          title={entityName}
          onRedirect={onRedirect}
          nameCell={entityName}
          requestMakesInChildren
          hyperTitle={onRedirect}
        >
          <EntityPopover
            tooltipContent={tooltipContent}
            switcher={switcher}
            setSwitcher={setSwitcher}
            id={row.id}
            allocation={row}
            type={investmentType}
            alwaysShow={alwaysShow}
          />
        </CustomAnchorPopover>
      </div>
      {grouped && value && (
        <>
          {canChange ? (
            <Select
              arrow={<ArrowDown fill={'#EF9208'} />}
              width={'auto'}
              selected={value}
              setSelected={changeSelect}
              data={items.map((item) => item.entityName)}
            />
          ) : (
            <GroupedEntity>
              {row.selectedGroupLegalEntity.entityName}
            </GroupedEntity>
          )}
        </>
      )}
    </CellWrapper>
  );
};

export default Entity;

const CustomAnchorPopover = styled(AnchorPopover)<{ isTwoColumn: boolean }>`
  min-height: ${({ isTwoColumn }) => (isTwoColumn ? '300px' : '660px')};
`;
const Select = styled(FilterSelect)`
  padding: 0;
  background: transparent;
  border: 0;
  justify-content: flex-start;

  svg {
    margin: 0 6px;
  }

  #titleSelect {
    color: #ef9208;
    white-space: normal;
  }

  #dropDownSelect {
    top: 26px;
  }
`;

const GroupedEntity = styled.div`
  color: #ef9208;
  font-family: Blinker, serif;
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  text-transform: capitalize;

  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  white-space: nowrap;
`;
