import styled from 'styled-components';

import FormattingTooltip from '@/components/basicComponents/tooltip/dollarsTooltip';
import { investmentColumns } from '../constatnts';
import { IManagerInvestment } from '../types';

interface TableSumFooterProps {
  tableData: IManagerInvestment[];
}

const TableSumFooter = ({ tableData }: TableSumFooterProps) => {
  const totalCountOfInvestors = tableData.reduce((sum: number, value: IManagerInvestment) => (sum += Number(value.countOfInvestors)), 0);
  const totalCommittedCapital = tableData.reduce((sum: number, value: IManagerInvestment) => (sum += Number(value.committedCapital)), 0);

  return (
    <TableSumWrap>
      <TableSumItem width={investmentColumns[0].width}>Total</TableSumItem>
      <TableSumItem width={investmentColumns[1].width}></TableSumItem>
      <TableSumItem width={investmentColumns[2].width}></TableSumItem>
      <TableSumItem width={investmentColumns[3].width}>{totalCountOfInvestors}</TableSumItem>
      <TableSumItem width={investmentColumns[4].width}>
        <FormattingTooltip zIndex={1000}>{totalCommittedCapital}</FormattingTooltip>
      </TableSumItem>
    </TableSumWrap>
  );
};

const TableSumWrap = styled.div`
  display: flex;
  align-items: center;
  padding: 0 13px 0 13px;
  width: 100%;
  min-height: 60px;
  background-color: ${({ theme }) => theme.layer[2]};
  border-radius: 8px;
  margin-top: 6px;
`;

const TableSumItem = styled.div<{ width: number; justifyContent?: string }>`
  display: flex;
  justify-content: ${({ justifyContent }) => `${justifyContent}`};
  width: ${({ width }) => `${width}%`};
  color: ${({ theme }) => theme.font.base};
  font-family: Blinker, serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0;
`;

export default TableSumFooter;
