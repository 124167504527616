import { useMutation, useQuery } from '@apollo/client';
import { ChangeEvent, useState } from 'react';
import ModalWrappedContent from '@/components/basicComponents/modal/modalWrappedContent';
import RadioButton from '@/components/basicComponents/radioButton';
import { IFunnelData } from '@/components/dueDiligence-page/types';
import {
  CREATE_DUE_DILIGENCE_FUNNEL,
  DUE_DILIGENCE_FUNNEL_LIST_SETTINGS_QUERY,
  GET_FUNNEL_QUERY
} from '@/components/settings-page/queries';
import { SORT_ORDER } from '@/components/table/types';
import { RejectedBannerIcon } from '@/assets/icons/info-icons/rejectedBanner';
import styled from 'styled-components';
import { formatTiersData } from '../utils';

interface EditPublishedFunnelProps {
  onClose: () => void;
  isOpen: boolean;
  openFunnelEditor: (
    funnelId: string,
    funnelName: string,
    isViewMode?: boolean,
    isDraft?: boolean
  ) => void;
  currentFunnel: IFunnelData | null;
}

const radioButtonItems = ['Edit Funnel', 'Create New Draft Version'];

const EditPublishedFunnel = ({
  onClose,
  isOpen,
  openFunnelEditor,
  currentFunnel
}: EditPublishedFunnelProps) => {
  if (!currentFunnel) return <></>;

  const [selectedOption, setSelectedOption] = useState(radioButtonItems[0]);

  const { data } = useQuery<{ getDueDiligenceFunnel: IFunnelData }>(
    GET_FUNNEL_QUERY,
    {
      variables: {
        id: currentFunnel.id
      },
      skip: !currentFunnel.id
    }
  );

  const [createFunnel] = useMutation(CREATE_DUE_DILIGENCE_FUNNEL, {
    refetchQueries: () => [
      {
        query: DUE_DILIGENCE_FUNNEL_LIST_SETTINGS_QUERY,
        variables: {
          data: {
            statusFilter: ['PUBLISHED', 'DRAFT_WITHOUT_PUBLISHED'],
            sortDirection: SORT_ORDER.ASC,
            sortBy: 'ORDER'
          },
          archivedFunnelData: {
            sortDirection: SORT_ORDER.ASC,
            sortBy: 'ORDER'
          }
        }
      }
    ]
  });

  const saveValue = () => {
    if (selectedOption === 'Edit Funnel') {
      openFunnelEditor(currentFunnel.id, currentFunnel.name, true);

      onClose();
      return;
    }

    if (selectedOption === 'Create New Draft Version' && data) {
      const { tiers } = data.getDueDiligenceFunnel;

      const updatedTiers = formatTiersData(tiers);

      createFunnel({
        variables: {
          data: {
            name: currentFunnel.name,
            funnelStaticId: currentFunnel.funnelStaticId,
            externalId: 'test-3',
            status: 'DRAFT',
            tiers: updatedTiers
          }
        },
        onCompleted: (data) => {
          const { id, name } = data.createDueDiligenceFunnel;
          openFunnelEditor(id, name, false, true);
        }
      });
      onClose();
    }
  };

  const handleRadioChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSelectedOption(event.target.value);
  };

  return (
    <ModalWrappedContent
      isOpen={isOpen}
      onClose={onClose}
      confirmButton={{ name: 'Confirm', onClick: saveValue, disabled: !data }}
    >
      <ModalContainer>
        <ModalTitle>Edit Funnel</ModalTitle>
        <ModalDescription>
          There are two options for editing published funnels:
        </ModalDescription>
      </ModalContainer>
      <ModalBody>
        <OptionsWrapper>
          Select an option:
          <RadioButtonsWrap>
            {radioButtonItems.map((item, index) => (
              <RadioButton
                key={item + index}
                name="editFunnel"
                radioButtonText={item}
                selectedOption={selectedOption}
                handleRadioChange={handleRadioChange}
              />
            ))}
          </RadioButtonsWrap>
        </OptionsWrapper>
        <BannerWrap>
          <IconWrap>
            <RejectedBannerIcon fill="#36474F" />
          </IconWrap>
          <BannerText>
            {selectedOption === 'Edit Funnel'
              ? 'You can make limited changes to a funnel that is already published.'
              : 'Create a new version of this funnel to make structural changes and publish it to replace the current version.'}
          </BannerText>
        </BannerWrap>
      </ModalBody>
    </ModalWrappedContent>
  );
};

export default EditPublishedFunnel;

const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const ModalTitle = styled.div`
  font-size: 33px;
  text-align: center;
  color: #2e2e2e;
`;

const ModalDescription = styled.div`
  font-size: 16px;
  line-height: 27px;
  text-align: center;
  color: #373f4e;
  font-weight: 400;
`;

const ModalBody = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-top: 35px;
`;

const OptionsWrapper = styled.div``;

const RadioButtonsWrap = styled.label`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
`;

const BannerWrap = styled.span`
  display: flex;
  align-items: center;
  gap: 20px;
  background-color: #fff;
  width: 55%;
  height: 76px;
  padding: 10px 20px;
`;

const IconWrap = styled.span`
  display: flex;
  align-items: center;
  height: 100%;
  padding-right: 20px;
  border-right: 1px solid #c7cbd2;
`;

const BannerText = styled.span`
  font-size: 13px;
  font-weight: 400;
  line-height: 18.2px;
`;
