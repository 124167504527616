import { INotesData } from '@/components/client-details/types';
import guid from '@/utils/guid';
import { format } from 'date-fns';
import { useMemo } from 'react';
import styled from 'styled-components';

interface InfoListClientProps {
  data: INotesData[] | undefined;
  openNotesOnEntityDetails: (id: string) => void;
}

export const MobileNotes = ({ data, openNotesOnEntityDetails }: InfoListClientProps) => {
  const parseNotes = (notes: string) => {
    try {
      return JSON.parse(notes);
    } catch (error) {
      return null;
    }
  };

  const dataFiltered = useMemo(() => {
    return data
      ?.map((note) => {
        return {
          id: note.id,
          title: note.title,
          notes: parseNotes(note.notes)
        };
      })
      .filter((note) => note.notes);
  }, [data]);

  return (
    <MoreDataWrap id="modal">
      <NotesBody id="notes">
        {dataFiltered &&
          dataFiltered.map((el) => {
            return el.notes.map((note: any) => (
              <div key={guid()}>
                <NoteItem id="notes">
                  <NoteItemTitle id="notesTitle" onClick={() => openNotesOnEntityDetails(el.id)}>
                    {el.title}
                  </NoteItemTitle>
                  <NoteItemDate id="notes">{note.title.date ? format(new Date(note.title.date), 'LLL d, yyyy') : 'No date'}</NoteItemDate>
                  <NoteItemDesc id="notes">{note.title.note}</NoteItemDesc>
                </NoteItem>
                <Divider />
              </div>
            ));
          })}
      </NotesBody>
    </MoreDataWrap>
  );
};
const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: ${({ theme }) => theme.border.base};
  margin: 5px 0;
`;

const NoteItemDesc = styled.div`
  font-family: Blinker, serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  color: ${({ theme }) => theme.font.base};
  margin-top: 10px;
`;

const NoteItemDate = styled.div`
  font-family: Blinker, serif;
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  color: ${({ theme }) => theme.font.weak};
`;

const NoteItemTitle = styled.div`
  font-family: Blinker, serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 18px;
  color: ${({ theme }) => theme.action.primary};
  cursor: pointer;
`;

const NoteItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  transition: 0.3s;
  padding: 30px 20px;
  border-radius: 8px;

  &:hover {
    background: ${({ theme }) => theme.layer[2]};
  }
`;

const NotesBody = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
`;

const MoreDataWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3px;
`;
