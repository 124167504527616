import client from '@/apollo-client';
import { AssetClassWithPrevValues } from '@/components/fat-investors-page/fat-addInvestors';
import { AssetClassTargetsPage } from '@/components/fat-investors-page/fat-addInvestors/fat-assetClassTargets';
import { GET_ASSET_CLASSES } from '@/components/fat-investors-page/fat-addInvestors/queries';
import { IAssetClass } from '@/components/fat-investors-page/fat-addInvestors/types';
import { calculatePrivateCapitalPercentage } from '@/components/fat-investors-page/fat-addInvestors/utils';
import { LoaderOnWholeScreen } from '@/components/loaders/loader-on-whole-screen';
import { CREATE_SETTING, DELETE_SETTING, GET_SETTINGS, UPDATE_SETTING } from '@/components/settings-page/queries';
import { useStatusData } from '@/hooks/useStatusData';
import { useMutation, useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';

const calculatePortfolioPercentage = (percentage: number, privatePercentage: number): string => {
  const calculatePercentageValue = (percentage * privatePercentage) / 100;
  return `${calculatePercentageValue?.toFixed(2)}%` || '0%';
};

const structurAssetClassesWithPrevValues = (assetClasses: IAssetClass[], privatePercentage: number): AssetClassWithPrevValues[] => {
  const structuredAssetClassesWithPrevValues = assetClasses.map((assetClass) => {
    const structuredSubAssetClassesWithPrevValues = assetClass.subAssetClasses.map((subAssetClass) => {
      return {
        id: subAssetClass.id,
        name: subAssetClass.name,
        defaultPercentage: calculatePortfolioPercentage(subAssetClass?.defaultAllocationPercentage, privatePercentage),
        prevPercentage: calculatePortfolioPercentage(subAssetClass?.defaultAllocationPercentage, privatePercentage),
        percentage: calculatePortfolioPercentage(subAssetClass?.defaultAllocationPercentage, privatePercentage)
      };
    });

    return {
      id: assetClass.id,
      name: assetClass.name,
      defaultPercentage: calculatePortfolioPercentage(assetClass?.defaultAllocationPercentage, privatePercentage),
      prevPercentage: calculatePortfolioPercentage(assetClass?.defaultAllocationPercentage, privatePercentage),
      percentage: calculatePortfolioPercentage(assetClass?.defaultAllocationPercentage, privatePercentage),
      subAssetClasses: structuredSubAssetClassesWithPrevValues
    };
  });

  return structuredAssetClassesWithPrevValues;
};

interface AssetClassDefaultTargetsPageProps {
  closeAssetClassTargetsPage: () => void;
  backToTitle?: string;
}

const assetClassOptions = ['Use Curio default asset class targets', "Set my firm's default asset class targets"];
const defaultPrivatePercentage = 20;

export const AssetClassDefaultTargetsPage = ({ closeAssetClassTargetsPage, backToTitle }: AssetClassDefaultTargetsPageProps) => {
  const { data: statusData } = useStatusData();

  const [isEditMode, setIsEditMode] = useState(false);
  const [assetClassTargets, setAssetClassTargets] = useState<AssetClassWithPrevValues[]>([]);
  const [assetClassSelectedOption, setAssetClassSelectedOption] = useState(assetClassOptions[0]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [createSetting] = useMutation(CREATE_SETTING);
  const [updateSetting] = useMutation(UPDATE_SETTING);
  const [deleteSetting] = useMutation(DELETE_SETTING);

  const { data: settingsData, loading: settingsLoading } = useQuery(GET_SETTINGS, {
    fetchPolicy: 'cache-and-network',
    variables: {
      type: 'assetClassDefaultTargets'
    },
    onCompleted: async (data) => {
      if (!data.Settings.length) {
        const { data: defaultAssetClassesData } = await client.query<{ assetClasses: IAssetClass[] }>({
          query: GET_ASSET_CLASSES
        });

        const updatedAssetClasses = structurAssetClassesWithPrevValues(defaultAssetClassesData.assetClasses, defaultPrivatePercentage);
        setAssetClassTargets(updatedAssetClasses);
        setIsEditMode(false);
        setAssetClassSelectedOption(assetClassOptions[0]);
        return;
      }

      const assetClasses: IAssetClass[] = JSON.parse(data.Settings[0].value).assetClasses;
      const privatePercentage = JSON.parse(data.Settings[0].value)?.privatePercentage || defaultPrivatePercentage;

      const updatedAssetClasses = structurAssetClassesWithPrevValues(assetClasses, privatePercentage);
      setAssetClassTargets(updatedAssetClasses);
      setIsEditMode(true);
      setAssetClassSelectedOption(assetClassOptions[1]);
    },
    onError: (error) => {
      console.error(error);
    }
  });

  const saveAssetClassTargets = (assetClassTargets: AssetClassWithPrevValues[], privatePercentage: string) => {
    if (assetClassSelectedOption === assetClassOptions[0] && !settingsData.Settings.length) {
      closeAssetClassTargetsPage();
      return;
    }

    if (assetClassSelectedOption === assetClassOptions[0] && settingsData.Settings.length) {
      deleteSetting({
        variables: {
          id: settingsData.Settings[0].id
        },
        onCompleted: () => {
          closeAssetClassTargetsPage();
        }
      });
      return;
    }

    const hasSubAssetClasses = assetClassTargets.some((assetClass) => assetClass.subAssetClasses.length > 0);

    const updatedAssetClasses = assetClassTargets.map((assetClass) => {
      if (statusData?.target_sub_asset_classes && hasSubAssetClasses) {
        const updatedSubAssetClasses = assetClass.subAssetClasses?.map((subAssetClass) => {
          return {
            id: subAssetClass.id,
            name: subAssetClass.name,
            defaultAllocationPercentage: calculatePrivateCapitalPercentage(subAssetClass.percentage, privatePercentage)
          };
        });
        return {
          id: assetClass.id,
          name: assetClass.name,
          defaultAllocationPercentage: calculatePrivateCapitalPercentage(assetClass.percentage, privatePercentage),
          subAssetClasses: updatedSubAssetClasses
        };
      }

      return {
        id: assetClass.id,
        name: assetClass.name,
        defaultAllocationPercentage: calculatePrivateCapitalPercentage(assetClass.percentage, privatePercentage),
        subAssetClasses: []
      };
    });

    if (isEditMode && settingsData.Settings.length) {
      updateSetting({
        variables: {
          data: {
            id: settingsData.Settings[0].id,
            description: 'Asset class defaults',
            value: JSON.stringify({ assetClasses: updatedAssetClasses, privatePercentage: parseFloat(privatePercentage) || 0 })
          }
        },
        onCompleted: () => {
          closeAssetClassTargetsPage();
        }
      });
      return;
    }

    createSetting({
      variables: {
        data: {
          description: 'Asset class defaults',
          type: 'assetClassDefaultTargets',
          key: 'assetClassDefaultTargets',
          value: JSON.stringify({ assetClasses: updatedAssetClasses, privatePercentage: parseFloat(privatePercentage) || 0 })
        }
      },
      onCompleted: () => {
        closeAssetClassTargetsPage();
      }
    });
  };

  if (settingsLoading) {
    return <LoaderOnWholeScreen />;
  }

  return (
    <AssetClassTargetsPage
      assetClassTargets={assetClassTargets}
      targetSubAssetClasses={statusData?.target_sub_asset_classes}
      saveAssetClassTargets={saveAssetClassTargets}
      resetAssetClassTargetsToDefaultValues={saveAssetClassTargets}
      closeAssetClassTargetsPage={closeAssetClassTargetsPage}
      assetClassSelectedOption={assetClassSelectedOption}
      setAssetClassSelectedOption={setAssetClassSelectedOption}
      pageTitle="Asset Class Default Targets"
      mode="settings"
    />
  );
};
