import React from 'react';
import { styled } from 'styled-components';

export enum positionTriangle {
  top = 0,
  bottom = 180,
  left = 270,
  right = 90
}
export type IPositionTriangle = keyof typeof positionTriangle;

interface TriangleProps {
  handleClick?: () => void;
  side: IPositionTriangle;
  className?: string;
}

const Triangle: React.FC<TriangleProps> = ({
  handleClick,
  side,
  className
}) => {
  return (
    <TriangleStyled
      className={className}
      data-sort="triangle-for-sort"
      side={side}
      onClick={handleClick}
    />
  );
};

export default Triangle;

const TriangleStyled = styled.div<{ side: IPositionTriangle }>`
  cursor: pointer;
  width: 0;
  height: 0;
  border-style: solid;
  transform: ${({ side }) => `rotate(${positionTriangle[side]}deg)`};
  border-color: transparent transparent ${({ theme }) => theme.info.light}
    transparent;
`;
