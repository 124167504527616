import { styled } from 'styled-components';
import ModalWrappedContent from '@/components/basicComponents/modal/modalWrappedContent';
import { IAllocationRow } from '@/types/opportunity/pending-allocation';

interface SubmitToProps {
  submitToOutstanding: () => void;
  getCheckedAllocations: () => IAllocationRow[];
  isOpen: boolean;
  onClose: () => void;
}

const SubmitTo = ({
  isOpen,
  onClose,
  submitToOutstanding,
  getCheckedAllocations
}: SubmitToProps) => {
  const checkedAllocations = getCheckedAllocations();
  const recomendationAmount = checkedAllocations.filter(
    ({ decision }) => decision
  ).length;
  const terminatedAmount = checkedAllocations.length - recomendationAmount;

  return (
    <ModalWrappedContent
      background
      isOpen={isOpen}
      onClose={onClose}
      title={'Confirmation'}
      confirmButton={{ name: 'Confirm', onClick: submitToOutstanding }}
    >
      <ModalBody>
        <ModalText>
          {'Please review. This process has no undo operation.'}
        </ModalText>
        <ModalText>
          <BackCount>
            <Count>{`${recomendationAmount}`}</Count>
          </BackCount>
          will move to
          <ModalText bold>Pending Investments</ModalText>
        </ModalText>
        <ModalText>
          <BackCount>
            <Count>{`${terminatedAmount} `}</Count>
          </BackCount>
          will be marked as
          <ModalText bold>Internal Review Rejected</ModalText>
        </ModalText>
        <ModalText>
          You can see all past allocations under the allocation tab in
          <ModalText bold>Compliance.</ModalText>
        </ModalText>
      </ModalBody>
    </ModalWrappedContent>
  );
};

export default SubmitTo;

const ModalBody = styled.div`
  text-align: center;
`;
const ModalText = styled.div<{ bold?: boolean }>`
  display: inline-block;
  text-align: center;
  font-size: 19px;
  font-weight: ${({ bold }) => (bold ? 600 : 400)};
  margin-left: ${({ bold }) => (bold ? '3px' : 0)};
`;

const BackCount = styled.div`
  background-color: #36474f;
  display: inline-block;
  border-radius: 12px;
  color: #f4f5f6;
  padding: 2px 12px;
  font-size: 13px;
  margin-right: 6px;
`;

const Count = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
