import { useLocation, useNavigate } from 'react-router-dom';

export const useGoBack = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const thereIsAPrevPage = location.key !== 'default';
  if (thereIsAPrevPage) {
    return (_arg: { fallBack?: string }) => navigate(-1);
  } else {
    return ({ fallBack }: { fallBack?: string }) => navigate(fallBack || '/');
  }
};
