import client from '@/apollo-client';
import { getUTCDate } from '@/components/allocations-page/utils';
import ModalLoader from '@/components/fat-basicComponents/modal/modalLoader';
import ModalWrappedContent from '@/components/fat-basicComponents/modal/modalWrappedContent';
import Header from '@/components/fat-header';
import { GoBackButton } from '@/components/fat-header/goBackButton';
import { PageTitle } from '@/components/fat-header/pageTitle';
import { AssetClassWithPrevValues } from '@/components/fat-investors-page/fat-addInvestors';
import { AssetClassTargetsPage } from '@/components/fat-investors-page/fat-addInvestors/fat-assetClassTargets';
import { DEFAULT_DRAWDOWN_TARGETS, GET_ASSET_CLASSES, updateLegalEntityMutation } from '@/components/fat-investors-page/fat-addInvestors/queries';
import { IAssetClass, ILegalEntity } from '@/components/fat-investors-page/fat-addInvestors/types';
import {
  getAllocationTargetsToUpdate,
  getSumAssetClassPercentages,
  resetAssetClassTargets,
  structurAssetClassesWithPrevValues,
  updateAssetClassDrawdownTargets
} from '@/components/fat-investors-page/fat-addInvestors/utils';
import { LoaderOnWholeScreen } from '@/components/loaders/loader-on-whole-screen';
import { useStatusData } from '@/hooks/useStatusData';
import { MainWrap, PaddingWrap } from '@/styles/common';
import { gql, useMutation, useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { DrawdownTargetsDto } from '../fat-pacingTab/types';
import { autoPlanMutation } from '../queries';
import { TabsEnum } from '../types';
import { AllocationTargets } from './steps/allocationTargets';
import { AutoPlan } from './steps/autoPlan';
import { ForecastOptions } from './steps/forecastOptions';
import { PlanDetails } from './steps/planDetails';

const autoPlanWizardStepsInitial = [
  { name: 'Plan Details', status: 'IN_PROGRESS' },
  { name: 'Allocation Targets', status: 'NOT_STARTED' },
  { name: 'Forecast Options', status: 'NOT_STARTED' },
  { name: 'Auto Plan', status: 'NOT_STARTED' }
];

export interface ICreateAutoPlanForm {
  name: string;
  startDate: 'Today' | 'Next Quarter';
  yearsToAchieveAllocationTarget: string;
  biteSize: string;
  growthRate: string;
  totalEntityValue: string;
  privatePercentage: string;
  generateAllocations: boolean;
}

const defaultFormValues: ICreateAutoPlanForm = {
  name: '',
  startDate: 'Today',
  yearsToAchieveAllocationTarget: '5',
  biteSize: '',
  growthRate: '',
  totalEntityValue: '',
  privatePercentage: '',
  generateAllocations: true
};

const GET_LEGAL_ENTITY_MIN_QUERY = gql`
  query LegalEntity($id: String!) {
    legalEntity(id: $id) {
      entityName
      totalEntityValue
      tenant {
        id
      }
      allocationTargets {
        id
        percentage
        assetClass {
          id
          name
          subAssetClass {
            id
            name
          }
        }
      }
      drawdownTargets {
        actuals {
          drawdown {
            id
            name
            portfolioPercentage
            privateCapitalPercentage
            subAssetClassDrawdownTargets {
              id
              name
              portfolioPercentage
              privateCapitalPercentage
            }
          }
          evergreen {
            id
            name
            portfolioPercentage
            privateCapitalPercentage
            subAssetClassDrawdownTargets {
              id
              name
              portfolioPercentage
              privateCapitalPercentage
            }
          }
        }
        targets {
          drawdown {
            id
            name
            portfolioPercentage
            privateCapitalPercentage
            subAssetClassDrawdownTargets {
              id
              name
              portfolioPercentage
              privateCapitalPercentage
            }
          }
          evergreen {
            id
            name
            portfolioPercentage
            privateCapitalPercentage
            subAssetClassDrawdownTargets {
              id
              name
              portfolioPercentage
              privateCapitalPercentage
            }
          }
        }
      }
    }
  }
`;

const getDefaultPlanStart = (): 'Today' | 'Next Quarter' => {
  const today = new Date();
  const month = today.getMonth();
  const year = today.getFullYear();

  const quarterStartMonth = Math.floor(month / 3) * 3;
  const quarterEndMonth = quarterStartMonth + 2;

  const quarterStartDate = new Date(year, quarterStartMonth, 1);
  const quarterEndDate = new Date(year, quarterEndMonth + 1, 0);

  const quarterMidDate = new Date(quarterStartDate.getTime() + (quarterEndDate.getTime() - quarterStartDate.getTime()) / 2);

  if (today <= quarterMidDate) {
    return 'Today';
  }
  return 'Next Quarter';
};

const startDateValues = {
  Today: 'TODAY',
  'Next Quarter': 'NEXT_QUARTER'
};

const assetClassOptions = ['Use default asset class allocation targets', 'Customize targets for this investor entity'];

interface AutoPlanWizardModalPageProps {
  backToTitle: string;
  handleAutoPlanWizardPage: ({ open }: { open: boolean; withRefetch?: boolean }) => void;
}

const AutoPlanWizardModalPage = ({ backToTitle, handleAutoPlanWizardPage }: AutoPlanWizardModalPageProps) => {
  const { handleSubmit, control, reset, trigger, setValue } = useForm<ICreateAutoPlanForm>({ defaultValues: defaultFormValues });
  const params = useParams();
  const navigate = useNavigate();
  const { data: statusData } = useStatusData();

  const [searchParams, setSearchParams] = useSearchParams();

  const [autoPlanWizardSteps, setAutoPlanWizardSteps] = useState(autoPlanWizardStepsInitial);
  const [assetClassTargets, setAssetClassTargets] = useState<AssetClassWithPrevValues[]>([]);
  const [assetClassChartWithTableData, setAssetClassChartWithTableData] = useState<DrawdownTargetsDto | null>(null);
  const [isAssetClassTargetsPageOpen, setIsAssetClassTargetsPageOpen] = useState<boolean>(false);
  const [assetClassSelectedOption, setAssetClassSelectedOption] = useState(assetClassOptions[0]);
  const [scrollPosition, setScrollPosition] = useState<number>(0);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [createAutoPlan, { loading: createAutoPlanLoading }] = useMutation(autoPlanMutation, {
    onError: (error) => {
      window.scrollTo(0, 0);
      console.error(error);
    }
  });

  const [updateEntity, { loading: updateEntityLoading }] = useMutation(updateLegalEntityMutation, {
    onError: (error) => {
      window.scrollTo(0, 0);
      console.error(error);
    }
  });

  const { data: legalEntityData, loading: legalEntityLoading } = useQuery<{ legalEntity: ILegalEntity }>(GET_LEGAL_ENTITY_MIN_QUERY, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'no-cache',
    skip: !params?.entityId,
    variables: {
      id: params?.entityId
    },
    onCompleted: async (data) => {
      const { data: defaultAssetClasses } = await client.query<{ assetClasses: IAssetClass[] }>({
        query: GET_ASSET_CLASSES,
        variables: {
          tenantId: data.legalEntity.tenant.id
        }
      });

      if (!data.legalEntity.allocationTargets.length) {
        const { data: defaultDrawdownTargetsData } = await client.query<{ defaultDrawdownTargets: DrawdownTargetsDto }>({
          query: DEFAULT_DRAWDOWN_TARGETS,
          variables: {
            tenantId: data.legalEntity.tenant.id
          }
        });

        const drawdownTargets = structurAssetClassesWithPrevValues(
          defaultDrawdownTargetsData.defaultDrawdownTargets.targets.drawdown,
          defaultAssetClasses.assetClasses
        );
        const evergreenTargets = structurAssetClassesWithPrevValues(
          defaultDrawdownTargetsData.defaultDrawdownTargets.targets.evergreen,
          defaultAssetClasses.assetClasses
        );
        setAssetClassTargets([...drawdownTargets, ...evergreenTargets]);
        const sumAssetClassPercentages = getSumAssetClassPercentages([...drawdownTargets, ...evergreenTargets]);
        setValue('privatePercentage', sumAssetClassPercentages);
        setAssetClassChartWithTableData(defaultDrawdownTargetsData.defaultDrawdownTargets);
        setAssetClassSelectedOption(assetClassOptions[0]);
        return;
      }

      const drawdownTargets = structurAssetClassesWithPrevValues(data.legalEntity.drawdownTargets.targets.drawdown, defaultAssetClasses.assetClasses);
      const evergreenTargets = structurAssetClassesWithPrevValues(
        data.legalEntity.drawdownTargets.targets.evergreen,
        defaultAssetClasses.assetClasses
      );
      setAssetClassTargets([...drawdownTargets, ...evergreenTargets]);
      const sumAssetClassPercentages = getSumAssetClassPercentages([...drawdownTargets, ...evergreenTargets]);
      setValue('privatePercentage', sumAssetClassPercentages);
      setAssetClassChartWithTableData(data.legalEntity.drawdownTargets);
      setAssetClassSelectedOption(assetClassOptions[1]);
    }
  });

  useEffect(() => {
    if (legalEntityLoading) return;

    reset({
      ...defaultFormValues,
      name: `Plan for ${legalEntityData?.legalEntity?.entityName ?? ''} ${getUTCDate(new Date()).toUpperCase()}`,
      startDate: getDefaultPlanStart(),
      totalEntityValue: legalEntityData?.legalEntity?.totalEntityValue.toString() ?? ''
    });
  }, [legalEntityData, legalEntityLoading]);

  const nextStep = () => {
    const currentStepIndex = autoPlanWizardSteps.findIndex((step) => step.status === 'IN_PROGRESS');
    if (currentStepIndex === autoPlanWizardSteps.length - 1) return;

    const updatedAutoPlanWizardSteps = autoPlanWizardSteps.map((step, index) => {
      if (currentStepIndex === index) {
        return { ...step, status: 'COMPLETED' };
      }
      if (currentStepIndex + 1 === index) {
        return { ...step, status: 'IN_PROGRESS' };
      }

      return step;
    });

    setAutoPlanWizardSteps(updatedAutoPlanWizardSteps);
  };

  const startOver = () => {
    setAutoPlanWizardSteps(autoPlanWizardStepsInitial);
  };

  const openAssetClassTargetsPage = () => {
    setIsAssetClassTargetsPageOpen(true);
    setScrollPosition(window.scrollY);
  };

  const closeAssetClassTargetsPage = () => {
    setIsAssetClassTargetsPageOpen(false);
    setTimeout(() => {
      window.scrollTo(0, scrollPosition);
    }, 0);
    setScrollPosition(0);
  };

  const resetAssetClassTargetsToDefaultValues = (assetClassTargets: AssetClassWithPrevValues[]) => {
    const defaultPrivatePercentage = assetClassTargets
      .reduce((acc, assetClass) => {
        return acc + parseFloat(assetClass?.defaultPercentage ?? '0');
      }, 0)
      .toFixed(2);

    const defaultAssetClassTargets = resetAssetClassTargets(assetClassTargets);
    const updatedAssetClassDrawdownTargets = updateAssetClassDrawdownTargets(
      assetClassChartWithTableData.targets.drawdown,
      defaultAssetClassTargets,
      defaultPrivatePercentage
    );
    const updatedAssetClassEvergreen = updateAssetClassDrawdownTargets(
      assetClassChartWithTableData.targets.evergreen,
      defaultAssetClassTargets,
      defaultPrivatePercentage
    );

    setValue('privatePercentage', defaultPrivatePercentage);
    setAssetClassChartWithTableData({
      ...assetClassChartWithTableData,
      targets: { drawdown: updatedAssetClassDrawdownTargets, evergreen: updatedAssetClassEvergreen }
    });
    setAssetClassTargets(defaultAssetClassTargets);
    closeAssetClassTargetsPage();
  };

  const saveAssetClassTargets = (assetClassTargets: AssetClassWithPrevValues[], privatePercentage: string) => {
    const updatedAssetClassDrawdownTargets = updateAssetClassDrawdownTargets(
      assetClassChartWithTableData.targets.drawdown,
      assetClassTargets,
      privatePercentage
    );
    const updatedAssetClassEvergreen = updateAssetClassDrawdownTargets(
      assetClassChartWithTableData.targets.evergreen,
      assetClassTargets,
      privatePercentage
    );

    setValue('privatePercentage', privatePercentage);
    setAssetClassChartWithTableData({
      ...assetClassChartWithTableData,
      targets: { drawdown: updatedAssetClassDrawdownTargets, evergreen: updatedAssetClassEvergreen }
    });
    const updatedAssetClassTargets = assetClassTargets.map((assetClass) => {
      return {
        ...assetClass,
        prevPercentage: assetClass.percentage,
        subAssetClasses: assetClass.subAssetClasses.map((subAssetClass) => {
          return {
            ...subAssetClass,
            prevPercentage: subAssetClass.percentage
          };
        })
      };
    });
    setAssetClassTargets(updatedAssetClassTargets);
    closeAssetClassTargetsPage();
  };

  const onSave = async (data: ICreateAutoPlanForm) => {
    setIsSubmitting(true);
    const { name, growthRate, yearsToAchieveAllocationTarget, startDate, generateAllocations, totalEntityValue, privatePercentage } = data;
    const allocationTargets = getAllocationTargetsToUpdate(assetClassTargets, statusData?.target_sub_asset_classes, privatePercentage);

    try {
      await updateEntity({
        variables: {
          data: {
            id: params.entityId,
            totalEntityValue: parseFloat(totalEntityValue),
            allocationTargets: assetClassSelectedOption === assetClassOptions[0] ? [] : allocationTargets,
            privatePercentage: parseFloat(privatePercentage),
            ignoreAllocationTargets: false
          }
        }
      });

      const { data: createAutoPlanData } = await createAutoPlan({
        variables: {
          input: {
            legalEntityId: params.entityId,
            name: name.trim(),
            startDate: startDateValues[startDate],
            growthRate: parseFloat(growthRate),
            yearsToAchieveAllocationTarget: parseInt(yearsToAchieveAllocationTarget),
            generateAllocations: generateAllocations
          }
        }
      });

      const basePath = `/investors/${params.investorId}/${params.entityId}`;
      if (generateAllocations && createAutoPlanData?.autoPlan?.id) {
        if (backToTitle === 'All Commitment Plans') {
          navigate(
            `/investors/${params.investorId}/${params.entityId}?tab=Pacing&commitmentPlanId=${createAutoPlanData.autoPlan.id}&backToTitle=All Commitment Plans`
          );
          return;
        }
        setSearchParams(
          (prev) => {
            prev.set('commitmentPlanId', createAutoPlanData.autoPlan.id);
            prev.set('tab', TabsEnum.PACING);
            return prev;
          },
          { replace: true }
        );
        handleAutoPlanWizardPage({ open: false, withRefetch: true });
        return;
      }

      navigate(`${basePath}/editCommitmentPlan?backToTitle=${backToTitle}`, {
        state: { autoPlanData: JSON.stringify(createAutoPlanData.autoPlan) }
      });
    } catch (error) {
      console.error(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  if (legalEntityLoading) {
    return <LoaderOnWholeScreen />;
  }

  if (isAssetClassTargetsPageOpen) {
    return (
      <AssetClassTargetsPage
        assetClassTargets={assetClassTargets}
        targetSubAssetClasses={statusData?.target_sub_asset_classes}
        saveAssetClassTargets={saveAssetClassTargets}
        resetAssetClassTargetsToDefaultValues={resetAssetClassTargetsToDefaultValues}
        closeAssetClassTargetsPage={closeAssetClassTargetsPage}
        assetClassSelectedOption={assetClassSelectedOption}
        setAssetClassSelectedOption={setAssetClassSelectedOption}
      />
    );
  }

  return (
    <>
      {(isSubmitting || createAutoPlanLoading || updateEntityLoading) && (
        <ModalWrappedContent
          isOpen={true}
          onClose={() => {}}
          title="Please Wait"
          showCloseIcon={false}
          showCancelButton={false}
          isUseOutsideClick={false}
        >
          <ModalLoader />
        </ModalWrappedContent>
      )}
      <MainWrap>
        <Header modalControl={<GoBackButton handleClose={() => handleAutoPlanWizardPage({ open: false })} backToTitle={backToTitle} />} />
        <PageTitle title="Auto Plan" />
      </MainWrap>
      <MainWrap>
        <PaddingWrap>
          {autoPlanWizardSteps[0].status === 'IN_PROGRESS' && (
            <PlanDetails
              autoPlanWizardSteps={autoPlanWizardSteps}
              control={control}
              trigger={trigger}
              startOver={() => handleAutoPlanWizardPage({ open: false })}
              nextStep={nextStep}
            />
          )}
          {autoPlanWizardSteps[1].status === 'IN_PROGRESS' && (
            <AllocationTargets
              autoPlanWizardSteps={autoPlanWizardSteps}
              control={control}
              assetClassChartWithTableData={assetClassChartWithTableData}
              trigger={trigger}
              startOver={startOver}
              nextStep={nextStep}
              openAssetClassTargetsPage={openAssetClassTargetsPage}
            />
          )}
          {autoPlanWizardSteps[2].status === 'IN_PROGRESS' && (
            <ForecastOptions
              autoPlanWizardSteps={autoPlanWizardSteps}
              control={control}
              trigger={trigger}
              startOver={startOver}
              nextStep={nextStep}
            />
          )}
          {autoPlanWizardSteps[3].status === 'IN_PROGRESS' && (
            <AutoPlan
              autoPlanWizardSteps={autoPlanWizardSteps}
              control={control}
              isSubmitting={isSubmitting}
              startOver={startOver}
              nextStep={handleSubmit(onSave)}
            />
          )}
        </PaddingWrap>
      </MainWrap>
    </>
  );
};

export default AutoPlanWizardModalPage;
