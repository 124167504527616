import { ApprovedIcon } from '@/assets/icons/info-icons/approved';
import { CloseInfoIcon } from '@/assets/icons/info-icons/closeInfo';
import { AnimatePresence, motion } from 'framer-motion';
import styled, { useTheme } from 'styled-components';
import Button from '../button';

interface SuccessToastProps {
  showToast: boolean;
  undoAction?: () => void;
  closeToast?: () => void;
}

export const SuccessToast = ({ showToast, undoAction, closeToast }: SuccessToastProps) => {
  const theme = useTheme();

  return (
    <AnimatePresence>
      {showToast && (
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -20 }}
          transition={{ duration: 0.3, ease: 'easeInOut' }}
          style={{
            position: 'fixed',
            top: '10px',
            left: '0',
            right: '0',
            marginLeft: 'auto',
            marginRight: 'auto',
            zIndex: 1000,
            maxWidth: '680px',
            width: '100%'
          }}
        >
          <SuccessToastWrapper>
            <IconWrapper>
              <ApprovedIcon width={24} height={24} fill={theme.context.light} />
            </IconWrapper>
            <SeparateStick />
            <StatusWrapper>
              <Message>
                <Message bold>Success!</Message> All of your changes have been applied
              </Message>
            </StatusWrapper>
            <ToastControlWrapper>
              {undoAction && (
                <UndoButton onClick={undoAction} styleType="outline">
                  Undo
                </UndoButton>
              )}
              {closeToast && (
                <CloseIconWrapper onClick={closeToast}>
                  <CloseInfoIcon fill={theme.context.light} />
                </CloseIconWrapper>
              )}
            </ToastControlWrapper>
          </SuccessToastWrapper>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

const SuccessToastWrapper = styled.div`
  background: ${({ theme }) => theme.context.success};
  border-radius: 8px;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px;
  height: 40px;
`;

const SeparateStick = styled.div<{ color?: string }>`
  width: 1px;
  height: 20px;
  background-color: ${({ color, theme }) => color || theme.context.light};
`;

const StatusWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Message = styled.span<{ bold?: boolean }>`
  color: ${({ theme }) => theme.context.light};
  font-weight: ${({ bold }) => (bold ? '700' : '400')};
  font-size: 13px;
  line-height: 18.2px;
`;

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ToastControlWrapper = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
  gap: 10px;
`;

const UndoButton = styled(Button)`
  padding: 5px 10px;
`;

const CloseIconWrapper = styled.div`
  cursor: pointer;
  position: relative;
  top: 2px;
`;
