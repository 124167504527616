import {
  MobileRow,
  MobileRowTitle,
  MobileRowValue,
  MobileTableRowWrap,
  MobileTableWrapper,
  NoResults
} from '@/components/table/mobileTable/mobileTable';

import { MinusScoreIcon } from '@/assets/icons/minusScore';
import { getUTCDate } from '@/components/allocations-page/utils';
import BasicTooltip from '@/components/basicComponents/tooltip';
import FormattingTooltip from '@/components/basicComponents/tooltip/dollarsTooltip';
import styled, { useTheme } from 'styled-components';
import { seekingInterestTableColumns } from '../../constants';
import { IInvestorInterestList, TypeWindow } from '../../types';

interface MobileTableProps {
  investorInterestsData: IInvestorInterestList[];
  openPage: (id: string, type: TypeWindow) => void;
}

const MobileTable = ({ investorInterestsData, openPage }: MobileTableProps) => {
  const theme = useTheme();

  const TableRow = ({ el }: { el: IInvestorInterestList }) => {
    return (
      <MobileTableRowWrap key={el.investment.symbol}>
        <MobileRow>
          <MobileRowTitle>{seekingInterestTableColumns[0].title}</MobileRowTitle>
          <MobileRowValue isRedirect onClick={() => openPage(el.investment.id, 'opportunityDetails')}>
            {el.investment.name}
          </MobileRowValue>
        </MobileRow>

        <MobileRow>
          <MobileRowTitle>{seekingInterestTableColumns[1].title}</MobileRowTitle>
          <MobileRowValue>
            {el.investment.subAssetClass ? `${el.investment.assetClass.name} - ${el.investment.subAssetClass.name}` : el.investment.assetClass.name}
          </MobileRowValue>
        </MobileRow>

        <MobileRow>
          <MobileRowTitle>{seekingInterestTableColumns[2].title}</MobileRowTitle>
          {el.investment?.subAssetClasses?.length > 1 ? (
            <BasicTooltip tooltipWidth={300} tooltipContent={el.investment.subAssetClasses.toString().replace(/,/g, ', ')}>
              <MobileRowValue>Multiple ({el.investment.subAssetClasses.length})</MobileRowValue>
            </BasicTooltip>
          ) : (
            <MobileRowValue>{el.investment?.subAssetClasses && el.investment?.subAssetClasses[0]}</MobileRowValue>
          )}
        </MobileRow>

        <MobileRow>
          <MobileRowTitle>{seekingInterestTableColumns[3].title}</MobileRowTitle>
          <MobileRowValue>{el.investment.type}</MobileRowValue>
        </MobileRow>

        <MobileRow>
          <MobileRowTitle>{seekingInterestTableColumns[4].title}</MobileRowTitle>
          <MobileRowValue>{el.investment.investorDocsDue && getUTCDate(new Date(el.investment.investorDocsDue))}</MobileRowValue>
        </MobileRow>

        <MobileRow>
          <MobileRowTitle>{seekingInterestTableColumns[5].title}</MobileRowTitle>
          <MobileRowValue>
            {el.investorInterests.length ? <FormattingTooltip zIndex={1000}>{el.totalInvestmentAmount}</FormattingTooltip> : <></>}
          </MobileRowValue>
        </MobileRow>

        {el.investment.securityStatus !== 'Approved' && (
          <TooltipWrapper>
            <BasicTooltip tooltipContent="Passed On" zIndex={1000}>
              <MinusScoreIcon width={29} height={29} fill={theme.font.disabled} />
            </BasicTooltip>
          </TooltipWrapper>
        )}
      </MobileTableRowWrap>
    );
  };

  return (
    <MobileTableWrapper>
      {investorInterestsData.length ? (
        investorInterestsData.map((el, index) => {
          return <TableRow el={el} key={index} />;
        })
      ) : (
        <NoResults>No Results</NoResults>
      )}
    </MobileTableWrapper>
  );
};

export default MobileTable;

const TooltipWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
