import { subject } from '@casl/ability';
import React, { useMemo } from 'react';
import styled from 'styled-components';

import { buildAbilityFor } from '@/config/ability';
import { useAuth } from '@/hooks/use-auth';
import { useResponsive } from '@/hooks/use-responsive';
import { CloseInfoIcon } from '@/assets/icons/info-icons/closeInfo';
import { User } from '@/types/user';
import { formatCash } from '@/utils/formatCash';
import Button from '@/components/basicComponents/button';
import BasicTooltip from '@/components/basicComponents/tooltip';
import { ACTIONS } from '@/components/recommendations-page/types';
import { Mode, TotalAmountType } from '../types';

type FooterModalProps = {
  setMode: (value: Mode) => void;
  mode: Mode;
  getTotalViewMode: () => number;
  getTotalEditMode: () => number;
  updateAllocationRequest: () => void;
  getAmountCheckedRows: () => number;
  submitTo: () => string;
  totalAmount: TotalAmountType;
  editSum: number;
  editTotalYes: number;
  summary: any;
  currentTab: { value: string };
  handleExitMyTask: () => void;
};

const FooterModal = ({
  currentTab,
  handleExitMyTask,
  setMode,
  mode,
  getTotalEditMode,
  updateAllocationRequest,
  getAmountCheckedRows,
  submitTo,
  totalAmount,
  editSum,
  summary,
  editTotalYes
}: FooterModalProps) => {
  const ability = buildAbilityFor(useAuth().user as User);
  const { isMobile, isTablet } = useResponsive();

  const isBtnDisabled = useMemo(() => {
    if (
      currentTab.value === 'pending' &&
      ability?.can(ACTIONS.EDIT_INVESTMENT, subject('Investment', {}))
    ) {
      return !getTotalEditMode;
    } else if (
      currentTab.value === 'investmentReview' &&
      ability?.can(ACTIONS.EDIT_INVESTMENT, subject('Investment', {}))
    ) {
      return !getTotalEditMode;
    } else if (currentTab.value === 'advisorReview') {
      return !getTotalEditMode;
    } else if (currentTab.value === 'compliance') {
      return !getTotalEditMode;
    } else {
      return true;
    }
  }, [getTotalEditMode, currentTab]);

  const hideSummaryVisible = () => {
    if (currentTab.value === 'pending') {
      return true;
    } else if (
      currentTab.value === 'investmentReview' &&
      !ability?.can(ACTIONS.EDIT_INVESTMENT, subject('Investment', {}))
    ) {
      return true;
    } else if (
      currentTab.value === 'advisorReview' &&
      !ability?.can(ACTIONS.EDIT_ADVISOR_ALLOCATION, subject('Allocation', {}))
    ) {
      return true;
    } else if (
      currentTab.value === 'compliance' &&
      !ability?.can(
        ACTIONS.EDIT_COMPLIANCE_ALLOCATION,
        subject('Allocation', {})
      )
    ) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <FooterModalWrap isMobile={isMobile} isTablet={isTablet}>
      {mode === Mode.VIEW ? (
        <>
          <BasicTooltip
            tooltipWidth={320}
            shouldShowContent={isBtnDisabled}
            tooltipContent={
              'Your permissions do not allow you to edit any of the allocations shown here.'
            }
          >
            <EditButton
              onClick={() => setMode(Mode.EDIT)}
              disabled={isBtnDisabled}
            >
              Edit My Tasks
            </EditButton>
          </BasicTooltip>

          {hideSummaryVisible() ? null : (
            <FooterDescription>
              You have
              <BackWrap>
                <Count>{summary.count}</Count>
              </BackWrap>
              allocations to review (
              <BoldInfo>
                {summary.yesCount} marked Yes totaling{' '}
                {formatCash(summary.totalAmount || 0)}
              </BoldInfo>
              )
            </FooterDescription>
          )}
        </>
      ) : (
        <EditWrap>
          <BtnWrap>
            <ButtonSubmit
              onClick={updateAllocationRequest}
              disabled={!getAmountCheckedRows()}
            >
              <BackEditWrap disabled={!getAmountCheckedRows()}>
                <EditCount disabled={!getAmountCheckedRows()}>
                  {getAmountCheckedRows()}
                </EditCount>
              </BackEditWrap>
              {submitTo()}
            </ButtonSubmit>
            <EditDesc>
              (
              <BoldInfo>
                {editTotalYes} marked Yes totaling {formatCash(editSum || 0)}
              </BoldInfo>
              )
            </EditDesc>
          </BtnWrap>

          <ExitMyTask
            onClick={
              currentTab.value === 'pending'
                ? handleExitMyTask
                : () => setMode(Mode.VIEW)
            }
          >
            <CloseInfoIcon width={18} height={18} />
            Exit My Tasks
          </ExitMyTask>
        </EditWrap>
      )}
    </FooterModalWrap>
  );
};

const EditDesc = styled.div`
  font-family: Blinker, serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0;
  text-align: left;
`;

const BtnWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  @media (max-width: 990px) {
    flex-direction: column;
    gap: 10px;
  }
`;

const Count = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 13px;
`;

const EditCount = styled(Count)<{ disabled: boolean }>`
  color: ${({ disabled }) => (disabled ? '#ABABAB' : '#2E2E2E')};
`;

const BackWrap = styled.div`
  background-color: #36474f;
  display: inline-block;
  border-radius: 12px;
  color: #f4f5f6;
  padding: 2px 12px;
  font-size: 13px;
  margin: 0 5px;
`;

const BackEditWrap = styled(BackWrap)<{ disabled: boolean }>`
  background-color: ${({ disabled }) => (disabled ? '#F0F1F3' : '#F4F5F6')};
`;

const ExitMyTask = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 45px;
  border: 1px solid #4587ec;
  border-radius: 5px;
  font-family: Blinker, serif;
  font-size: 19px;
  font-weight: 600;
  line-height: 27px;
  letter-spacing: 0;
  color: #4587ec;
  gap: 13px;
`;

const EditWrap = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const FooterModalWrap = styled.div<{
  isMobile: boolean;
  isTablet: boolean;
}>`
  width: ${({ isMobile, isTablet }) =>
    isMobile || isTablet ? '100%' : 'calc(100% - 80px)'};
  display: flex;
  align-items: center;
  padding: 20px 80px;
  position: fixed;
  bottom: 0;
  clear: both;
  background-color: #fff;
  border-top: 1px solid #c7cbd2;
  max-height: 90px;
  z-index: 30;
`;

const EditButton = styled(Button)<{ disabled: boolean }>`
  background-color: ${({ disabled }) =>
    disabled ? '#DEDEDE !important' : '#1c488a !important'};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  font-family: 'Blinker', serif;
  font-style: normal;
  font-weight: 600 !important;
  font-size: 19px !important;
  padding: 11px 40px !important;
`;

const ButtonSubmit = styled(EditButton)`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const FooterDescription = styled.div`
  padding-left: 20px;
  font-family: Blinker, serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #373f4e;
`;

const BoldInfo = styled.div`
  display: inline-block;
  font-weight: 700;
  font-size: 16px;
  color: #373f4e;
`;

export default FooterModal;
