import axios from 'axios';
import fileDownload from 'js-file-download';
import { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';

import { IFilterBarData } from '@/components/basicComponents/filterBar';
import { useResponsive } from '../../../hooks/use-responsive';
import { TData } from '../../basicComponents/selectWithCheckBox';
import DesktopFilterPage from './desktop-filter';
import LandscapeFilterPage from './landscape-filter';
import PortraitFilterPage from './portrait-filter.jsx';
import { AdditionalFilters } from './types';
import { buildQueryString } from './utils';

export enum DATA_TYPE {
  COMPLIANCE = 'compliance',
  DUE_DILIGENCE_RECORDS = 'dueDiligenceRecords',
  COMMITMENTS = 'commitments',
  RECOMMENDATIONS = 'recommendations'
}

interface FilterPageProps {
  search: {
    value: string;
    onChange: any;
    placeholder: string;
  };
  primaryFilter?: {
    value: string;
    onChange: (value: string) => void;
    selects: string[];
    withSearch?: boolean;
  };
  withCheckBox?: boolean;
  additionalFilters?: AdditionalFilters;
  withExportData?: boolean;
  dataType?: DATA_TYPE;
  setAllocationStatusFilter?: any;
  allocationStatusFilter?: TData[];
  currentTab?: { value: string };
  className?: string;
  isResult?: boolean;
  resultsValue?: number;
  isDealRegistryFilter?: boolean;
  refetch?: () => void;
  setIsMoreFiltersOpen?: Dispatch<SetStateAction<boolean>>;
  queryParams?: { [key: string]: any };
  tableColumnControl?: () => void;
  viewBySelect?: {
    value: string;
    onChange: (value: string) => void;
    selects: string[];
  };
  filterColor?: string;
  filterBar?: IFilterBarData[];
  onChangeFilterBar?: (filterBarItems: IFilterBarData[]) => void;
  mobileSort?: {
    value: string;
    onChange: (value: string) => void;
    selects: string[];
  };
}

const FilterPage = ({
  search,
  primaryFilter,
  additionalFilters,
  withExportData = false,
  dataType,
  withCheckBox,
  setAllocationStatusFilter,
  allocationStatusFilter,
  currentTab,
  className,
  isResult,
  resultsValue,
  refetch,
  setIsMoreFiltersOpen,
  tableColumnControl,
  queryParams,
  viewBySelect,
  isDealRegistryFilter = false,
  filterColor = '#4587EC',
  filterBar,
  onChangeFilterBar,
  mobileSort
}: FilterPageProps) => {
  const { isPortrait, isMobile, isDesktop, isTablet, isLargeDesktop } = useResponsive();

  const getParams = (type?: DATA_TYPE) => {
    if (!type) return { path: '', filename: '' };

    const queryString = buildQueryString({
      ...queryParams,
      limit: 100000
    });
    const types = {
      [DATA_TYPE.COMPLIANCE]: {
        path: 'allocation/compliance',
        filename: 'compliance.csv'
      },
      [DATA_TYPE.DUE_DILIGENCE_RECORDS]: {
        path: 'due-diligence-records',
        filename: 'due-diligence-records.csv'
      },
      [DATA_TYPE.COMMITMENTS]: {
        path: `clients/commitments${queryString}`,
        filename: `commitments-${queryParams?.id}.csv`
      },
      [DATA_TYPE.RECOMMENDATIONS]: {
        path: `allocation/recommendations${queryString}`,
        filename: 'recommendations.csv'
      }
    };
    return types[type as keyof typeof types];
  };

  const handleDownload = async () => {
    const { path, filename } = getParams(dataType);
    const response = await axios.get(`${process.env.REACT_APP_BACKEND_BASE_URL}/${path}`, {
      headers: { authorization: `Bearer ${localStorage.getItem('token')}` },
      responseType: 'blob'
    });
    fileDownload(response.data, filename);
  };

  return (
    <FilterPageWrap className={className}>
      {isDesktop || isTablet || isLargeDesktop ? (
        <DesktopFilterPage
          search={search}
          primaryFilter={primaryFilter}
          isResult
          additionalFilters={additionalFilters}
          withCheckBox
          currentTab={currentTab}
          allocationStatusFilter={allocationStatusFilter}
          setAllocationStatusFilter={setAllocationStatusFilter}
          resultsValue={resultsValue}
          refetch={refetch}
          withExportData={withExportData}
          handleDownload={handleDownload}
          tableColumnControl={tableColumnControl}
          viewBySelect={viewBySelect}
          isDealRegistryFilter={isDealRegistryFilter}
          filterBar={filterBar}
          onChangeFilterBar={onChangeFilterBar}
        />
      ) : (
        (isPortrait && isMobile && (
          <PortraitFilterPage
            search={search}
            primaryFilter={primaryFilter}
            isResult
            additionalFilters={additionalFilters}
            withCheckBox
            currentTab={currentTab}
            allocationStatusFilter={allocationStatusFilter}
            setAllocationStatusFilter={setAllocationStatusFilter}
            resultsValue={resultsValue}
            refetch={refetch}
            setIsMoreFiltersOpen={setIsMoreFiltersOpen}
            handleDownload={handleDownload}
            tableColumnControl={tableColumnControl}
            filterBar={filterBar}
            onChangeFilterBar={onChangeFilterBar}
            mobileSort={mobileSort}
            viewBySelect={viewBySelect}
          />
        )) ||
        (!isPortrait && isMobile && (
          <LandscapeFilterPage
            search={search}
            primaryFilter={primaryFilter}
            isResult
            additionalFilters={additionalFilters}
            withCheckBox
            currentTab={currentTab}
            allocationStatusFilter={allocationStatusFilter}
            setAllocationStatusFilter={setAllocationStatusFilter}
            resultsValue={resultsValue}
            refetch={refetch}
            handleDownload={handleDownload}
            tableColumnControl={tableColumnControl}
            filterBar={filterBar}
            onChangeFilterBar={onChangeFilterBar}
            mobileSort={mobileSort}
            viewBySelect={viewBySelect}
          />
        ))
      )}
    </FilterPageWrap>
  );
};

const FilterPageWrap = styled.div`
  //width: 100%;
  //display: flex;
  //flex-direction: column;
  ////gap: 10px;
  //align-items: flex-end;
  margin-top: 20px;
  background-color: ${({ theme }) => theme.layer.base};
`;

export default FilterPage;
