import ModalWrappedContent from '@/components/fat-basicComponents/modal/modalWrappedContent';
import styled from 'styled-components';

interface FailureProps {
  errorMessage: string;
  onClose: () => void;
  isOpen: boolean;
}

const Failure = ({ errorMessage, onClose, isOpen }: FailureProps) => {
  return (
    <ModalWrappedContent
      isOpen={isOpen}
      onClose={onClose}
      showCancelButton={false}
      title="Something Went Wrong"
      confirmButton={{
        name: 'OK',
        onClick: onClose
      }}
    >
      <ModalBody>{errorMessage}</ModalBody>
    </ModalWrappedContent>
  );
};

export default Failure;

const ModalBody = styled.div`
  text-align: center;
  color: ${({ theme }) => theme.font.base};
`;
