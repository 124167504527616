import { CloseInfoIcon } from '@/assets/icons/info-icons/closeInfo';
import { NotesIcon } from '@/assets/icons/menu-icons/notes';
import { INotesData } from '@/components/client-details/types';
import { useResponsive } from '@/hooks/use-responsive';
import { useClickOutside } from '@/hooks/useClickOutside';
import guid from '@/utils/guid';
import { format } from 'date-fns';
import { useMemo, useRef } from 'react';
import styled, { useTheme } from 'styled-components';

type NotesProps = {
  data: INotesData[] | undefined;
  isOpen: boolean;
  onClick: () => void;
  openNotesOnEntityDetails: (id: string) => void;
};

const Notes = ({ data, isOpen, onClick, openNotesOnEntityDetails }: NotesProps) => {
  const { isMobile } = useResponsive();
  const theme = useTheme();

  const ref = useRef<HTMLDivElement | null>(null);

  useClickOutside<HTMLDivElement>(ref, isOpen ? () => onClick() : () => null);

  const parseNotes = (notes: string) => {
    try {
      return JSON.parse(notes);
    } catch (error) {
      return null;
    }
  };

  const dataFiltered = useMemo(() => {
    return data
      ?.map((note) => {
        return {
          id: note.id,
          title: note.title,
          notes: parseNotes(note.notes)
        };
      })
      .filter((note) => note.notes);
  }, [data]);

  return isMobile ? (
    <></>
  ) : (
    <NotesWrap id="notes" ref={ref} isOpen={isOpen}>
      <NotesTitleWrap id="notes">
        <TitleWrap id="notes">
          <NotesIcon id="notes" fill={theme.font.strong} />
          <Title id="notes">Notes</Title>
        </TitleWrap>
        <CloseBtn onClick={onClick}>
          <CloseInfoIcon fill={theme.action.primary} width={14} height={14} />
        </CloseBtn>
      </NotesTitleWrap>
      <NotesBody id="notes">
        {dataFiltered &&
          dataFiltered.map((el) => {
            return el.notes.map((note: any) => (
              <div key={guid()}>
                <NoteItem id="notes">
                  <NoteItemTitle id="notesTitle" onClick={() => openNotesOnEntityDetails(el.id)}>
                    {el.title}
                  </NoteItemTitle>
                  <NoteItemDate id="notes">{note.title.date ? format(new Date(note.title.date), 'LLL d, yyyy') : 'No date'}</NoteItemDate>
                  <NoteItemDesc id="notes">{note.title.note}</NoteItemDesc>
                </NoteItem>
                <Divider />
              </div>
            ));
          })}
      </NotesBody>
    </NotesWrap>
  );
};

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: ${({ theme }) => theme.border.base};
  margin: 5px 0;
`;

const NoteItemDesc = styled.div`
  font-family: Blinker, serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  color: ${({ theme }) => theme.font.base};
  margin-top: 10px;
`;

const NoteItemDate = styled.div`
  font-family: Blinker, serif;
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  color: ${({ theme }) => theme.font.weak};
`;

const NoteItemTitle = styled.div`
  font-family: Blinker, serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 18px;
  color: ${({ theme }) => theme.font.base};
  cursor: pointer;
`;

const NoteItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  transition: 0.3s;
  padding: 30px 20px;
  border-radius: 8px;

  &:hover {
    background: ${({ theme }) => theme.layer[2]};
  }

  &:hover #notesTitle {
    color: ${({ theme }) => theme.action.primary};
  }
`;

const NotesBody = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 10px;
    left: -100px;
  }

  &::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.layer[2]};
    border: 1px solid ${({ theme }) => theme.border.base};
    padding: 2px;
    border-radius: 10px;
    margin: 6px 0;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.layer[4]};
    background-clip: padding-box;
    border-radius: 10px;
  }
`;

const Title = styled.div`
  font-family: Blinker, serif;
  font-size: 28px;
  font-weight: 600;
  line-height: 39px;
  color: ${({ theme }) => theme.font.strong};
`;

const TitleWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 9px;
`;

const CloseBtn = styled.div`
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  min-width: 40px;
  border-radius: 50%;
  border: 1px solid ${({ theme }) => theme.action.primary};
  background: ${({ theme }) => theme.layer.base};
`;

const NotesTitleWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const NotesWrap = styled.div<{ isOpen: boolean }>`
  display: flex;
  flex-direction: column;
  padding: ${({ isOpen }) => (isOpen ? '20px 40px' : 0)};
  position: absolute;
  margin-right: -24px;
  top: 60px;
  bottom: 0;
  right: 0;
  width: ${({ isOpen }) => (isOpen ? '600px' : 0)};
  height: calc(100vh - 60px);
  background: ${({ theme }) => theme.layer[1]};
  z-index: 100;
  box-shadow: -14px 0 12px -10px rgba(0, 0, 0, 0.15);
  transition:
    all 0.3s,
    height 1ms;
`;

export default Notes;
