// @ts-nocheck
import { InfoIcon } from '@/assets/icons/info-icons/info';
import { Banner } from '@/components/basicComponents/banner';
import CheckBox from '@/components/basicComponents/checkbox';
import { getDocxFooter } from '@/components/dueDiligence-page/DDR-DOCX/footer';
import { getDocxHeader } from '@/components/dueDiligence-page/DDR-DOCX/header';
import { transparentDocxTableBorders } from '@/components/dueDiligence-page/DDR-DOCX/utils';
import { getFontByKey } from '@/components/dueDiligence-page/DDR-PDF/utils';
import Button from '@/components/fat-basicComponents/button';
import ModalWrappedContent from '@/components/fat-basicComponents/modal/modalWrappedContent';
import { Investment } from '@/components/fat-dealCatalog-page/types';
import { Loader } from '@/components/loaders/loader';
import { GET_SETTINGS } from '@/components/settings-page/queries';
import { gql, useQuery } from '@apollo/client';
import { PDFDownloadLink } from '@react-pdf/renderer';
import * as docx from 'docx';
import fileDownload from 'js-file-download';
import { MouseEvent, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';
import { getOneColumnSection } from '../editManagerUpdate/managerUpdateDOCX/oneColumnSection';
import { PDFTemplate } from '../editManagerUpdate/managerUpdatePDF/PDFTemplate';
import { GET_DOCUMENT_RECORD_QUERY } from '../queries';
import { IGetDocumentRecordQuery } from '../types';
import { RejectedBannerIcon } from '@/assets/icons/info-icons/rejectedBanner';

const INVESTMENT_QUERY = gql`
  query Investment($id: String!) {
    Investment(id: $id) {
      id
      name
      description
    }
  }
`;

interface DownloadPDFProps {
  onClose: () => void;
  isOpen: boolean;
  canUserEdit: boolean;
  updateId?: string | null;
  documentRecord?: IGetDocumentRecordQuery;
}

const DEFAULT_ERROR_MESSAGE = `We're sorry. This process did not complete. Please try again.`;

const DownloadPDF = ({ isOpen, onClose, updateId, documentRecord, canUserEdit }: DownloadPDFProps) => {
  const params = useParams();
  const theme = useTheme();

  const [includeAiSuggested, setIncludeAiSuggested] = useState(true);
  const [isPdfReady, setIsPdfReady] = useState(false);
  const [recordData, setRecordData] = useState<IGetDocumentRecordQuery | null>(null);
  const [isDocxGenerating, setIsDocxGenerating] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  useEffect(() => {
    setIncludeAiSuggested(canUserEdit);
  }, [canUserEdit]);

  const { data: investmentData, loading: loadingInvestment } = useQuery<{ Investment: Investment }>(INVESTMENT_QUERY, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
    variables: { id: params?.strategyId },
    skip: !params?.strategyId,
    onError: (error) => {
      console.error(error);
    }
  });

  const { data: documentRecordQueryData, loading: documentRecordQueryLoading } = useQuery<{ getDocumentRecord: IGetDocumentRecordQuery }>(
    GET_DOCUMENT_RECORD_QUERY,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'cache-and-network',
      skip: !updateId,
      variables: {
        id: updateId
      },
      onCompleted: (data) => {
        setRecordData(data.getDocumentRecord);
      },
      onError: (error) => {
        console.error(error);
      }
    }
  );

  const { data: firmSettingsData, loading: firmSettingsLoading } = useQuery(GET_SETTINGS, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    variables: {
      type: 'firmSetting'
    }
  });

  const { data: templateStylingData, loading: templateStylingLoading } = useQuery(GET_SETTINGS, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    variables: {
      type: 'templateStyling'
    }
  });

  useEffect(() => {
    if (documentRecord && !updateId) {
      setRecordData(documentRecord);
    }
  }, [documentRecord]);

  const changeCheckboxValue = () => {
    setIsPdfReady(false);
    setIncludeAiSuggested((prev) => !prev);
  };

  const generatingPdf = (isPdfReady: boolean) => {
    if (isPdfReady) {
      setTimeout(() => {
        setIsPdfReady(isPdfReady);
      }, 2000);
    }
  };

  const createInvestmentParagraphs = (investmentName: string, investmentDescription: string, headerFontColor: string, bodyFontColor: string) => {
    const paragraph1 = new docx.Paragraph({
      heading: docx.HeadingLevel.HEADING_1,
      spacing: { before: 250, after: 250 },
      children: [
        new docx.TextRun({
          text: investmentName,
          bold: true,
          color: headerFontColor
        })
      ]
    });

    const table = new docx.Table({
      rows: [
        new docx.TableRow({
          children: [
            new docx.TableCell({
              children: [paragraph1],
              width: {
                size: 7500,
                type: docx.WidthType.DXA
              },
              borders: transparentDocxTableBorders
            })
          ]
        })
      ]
    });

    const paragraph2 = new docx.Paragraph({
      spacing: { before: 150, after: 200 },
      children: [
        new docx.TextRun({
          text: investmentDescription,
          color: bodyFontColor
        })
      ]
    });

    if (investmentDescription) {
      return [table, paragraph2];
    }

    return [table];
  };

  const formattingDataForDocx = async (recordData: IGetDocumentRecordQuery) => {
    const investmentName = investmentData?.Investment?.name ?? '';
    const investmentDescription = investmentData?.Investment?.description ?? '';

    const headerFontColor = Boolean(getFontByKey(templateStylingData?.Settings ?? [], 'headerFontStyle').fontColor)
      ? `#${getFontByKey(templateStylingData?.Settings ?? [], 'headerFontStyle').fontColor}`
      : '#35739C';

    const bodyFontColor = Boolean(getFontByKey(templateStylingData?.Settings ?? [], 'bodyFontStyle').fontColor)
      ? `#${getFontByKey(templateStylingData?.Settings ?? [], 'bodyFontStyle').fontColor}`
      : '#757575';

    let docxData = [];

    docxData.push(...createInvestmentParagraphs(investmentName, investmentDescription, headerFontColor, bodyFontColor));

    const sectionData = await getOneColumnSection(recordData.fields, includeAiSuggested, bodyFontColor);
    docxData.push(...sectionData);

    return docxData;
  };

  const generateDocx = async () => {
    try {
      setIsDocxGenerating(true);
      setErrorMessage(null);

      if (!recordData) {
        setErrorMessage(DEFAULT_ERROR_MESSAGE);
        return;
      }

      const formattedData = await formattingDataForDocx(recordData);

      const doc = new docx.Document({
        sections: [
          {
            properties: {
              page: {
                margin: {
                  right: 1000,
                  left: 1000
                }
              }
            },
            children: formattedData,
            headers: {
              default: getDocxHeader(firmSettingsData, recordData.name)
            },
            footers: {
              default: await getDocxFooter(firmSettingsData)
            }
          }
        ]
      });

      docx.Packer.toBlob(doc).then((blob) => {
        fileDownload(blob, `${investmentData?.Investment?.name ?? ''} - ${recordData.name}.docx`);
      });
    } catch (error) {
      console.error(error);
      setErrorMessage(DEFAULT_ERROR_MESSAGE);
    } finally {
      setIsDocxGenerating(false);
    }
  };

  const handleCloseModal = () => {
    onClose();
    setIsPdfReady(false);
  };

  const openUrlInNewTab = (event: MouseEvent<HTMLButtonElement>, blob: Blob | null) => {
    event.preventDefault();
    setErrorMessage(null);

    if (blob) {
      const url = URL.createObjectURL(blob);
      window.open(url, '_blank');
    }
  };

  return (
    <ModalWrappedContent isOpen={isOpen} onClose={handleCloseModal} title={'Download'} showCancelButton={false}>
      {loadingInvestment || documentRecordQueryLoading || firmSettingsLoading || templateStylingLoading ? (
        <Loader />
      ) : (
        <>
          {errorMessage && (
            <ErrorBannerWrapper>
              <Banner
                icon={<RejectedBannerIcon fill={theme.context.light} />}
                title="Error"
                description={errorMessage}
                bgColor={theme.context.error}
              />
            </ErrorBannerWrapper>
          )}
          {recordData && (
            <Container>
              {canUserEdit && (
                <CheckboxWrapper id={'input'} onClick={changeCheckboxValue}>
                  <CheckBox id={'input'} isActive={includeAiSuggested} /> Include AI Suggested Content
                </CheckboxWrapper>
              )}
              <DownloadWrappper canUserEdit={canUserEdit}>
                <PDFDownloadLink
                  document={
                    <PDFTemplate
                      documentRecord={recordData}
                      firmSettings={firmSettingsData?.Settings ?? []}
                      templateStylingData={templateStylingData?.Settings ?? []}
                      includeAiSuggested={includeAiSuggested}
                      investmentName={investmentData?.Investment?.name ?? ''}
                      investmentDescription={investmentData?.Investment?.description ?? ''}
                      generatingPdf={generatingPdf}
                    />
                  }
                  style={{ width: 'max-content' }}
                  fileName="test.pdf"
                >
                  {({ blob }) => {
                    return !isPdfReady ? (
                      <Button styleType="disabled" size="md">
                        Loading PDF Template ...
                      </Button>
                    ) : (
                      <Button
                        onClick={(event: React.MouseEvent<HTMLButtonElement>) => openUrlInNewTab(event, blob ?? null)}
                        styleType={'default'}
                        size="md"
                      >
                        Download PDF
                      </Button>
                    );
                  }}
                </PDFDownloadLink>
                <Button onClick={generateDocx} styleType={isDocxGenerating ? 'disabled' : 'default'} isLoading={isDocxGenerating} size="md">
                  {isDocxGenerating ? 'Generating Docx ...' : 'Generate Docx'}
                </Button>
              </DownloadWrappper>
            </Container>
          )}
        </>
      )}
    </ModalWrappedContent>
  );
};

export default DownloadPDF;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const CheckboxWrapper = styled.div`
  cursor: pointer;
  display: flex;
  gap: 9px;
  margin-top: 20px;
  padding: 18px 0 30px 0;
  border-top: 1px solid ${({ theme }) => theme.border.base};
  color: ${({ theme }) => theme.font.base};
  font-size: 16px;
  font-weight: 400;
`;

const DownloadWrappper = styled.div<{ canUserEdit: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-top: ${({ canUserEdit }) => (canUserEdit ? '0' : '20px')};
`;

const ErrorBannerWrapper = styled.div`
  margin-bottom: 20px;
`;
