export const columns = [
  { title: 'Investor', key: 'INVESTOR', width: 15, keySort: 'investor' },
  { title: 'Total Grouped', key: 'TOTAL_GROUPED', width: 15, keySort: 'total grouped' },
  { title: 'Newly Grouped', key: 'NEWLY_GROUPED', width: 15, keySort: 'newly grouped' },
  { title: 'Ungrouped', key: 'UNGROUPED', width: 15, keySort: 'ungrouped' },
  { title: 'Primary Entity', key: 'PRIMARY_ENTITY', width: 15, keySort: 'primary entity' },
  { title: 'Status', key: 'STATUS', width: 15, keySort: 'status' },
  { title: ' ', key: ' ', width: 10 },
];

export const groupsStatusSuccess = {
  allMatched: 'No Conflicts',
  continue: 'Your entities are ready to be saved',
  orContinue: 'All updates are ready to be saved',
  viewAll: 'View All Updates',
};

export const groupsStatusError = {
  text: 'Conflicts',
  description: 'Please reconcile the conflicts found below by modifying the import file and uploading it again.',
};
