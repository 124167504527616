import { PlusIcon } from '@/assets/icons/info-icons/plus';
import styled, { useTheme } from 'styled-components';

interface AddButtonProps {
  text: string;
  onClick: () => void;
}

export const AddButton = ({ text, onClick }: AddButtonProps) => {
  const theme = useTheme();

  return (
    <Button onClick={onClick}>
      <PlusIcon width={20} height={20} fill={theme.font.action} />
      <AddText>{text}</AddText>
    </Button>
  );
};

const Button = styled.div`
  margin-top: 10px;
  display: flex;
  align-items: center;
  gap: 6px;
  cursor: pointer;
`;

const AddText = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: ${({ theme }) => theme.font.action};
`;
