import FilterSelect from '@/components/basicComponents/select';
import ShowText from '@/components/dueDiligence-page/dueDiligenceRecord/showText';
import { IRawAnswers } from '@/components/dueDiligence-page/dueDiligenceRecord/types';
import { MobileRow, MobileRowTitle, MobileRowValue, MobileTableRowWrap } from '@/components/table/mobileTable/mobileTable';
import React from 'react';
import { columns } from './constatnts';

type MobileTableProps = {
  rows: IRawAnswers[];
  changeField: (id: string, value: string, fieldName: string) => void;
  openPdf: (pageNumber: number) => void;
};

const MobileTable = ({ rows, changeField, openPdf }: MobileTableProps) => {
  const TableRow = ({ el }: { el: IRawAnswers }) => {
    return (
      <MobileTableRowWrap key={el.id}>
        <MobileRow>
          <MobileRowTitle>{columns[0].title}</MobileRowTitle>
          <MobileRowValue onClick={() => openPdf(el.pageNumbers[0])} isRedirect>
            {el?.pageNumbers.map((pageNumber: number, index: number) => (
              <React.Fragment key={pageNumber + index}>{`${index ? ' - ' : ''}${pageNumber}`}</React.Fragment>
            ))}
          </MobileRowValue>
        </MobileRow>

        <MobileRow>
          <MobileRowTitle>{columns[1].title}</MobileRowTitle>
          <MobileRowValue>
            <ShowText>{el.answer}</ShowText>
          </MobileRowValue>
        </MobileRow>

        <MobileRow>
          <MobileRowTitle>{columns[2].title}</MobileRowTitle>
          <MobileRowValue>
            <FilterSelect
              data={['true', 'false']}
              setSelected={(value) => changeField(el.id, value, 'hasAnswer')}
              selected={el.hasAnswer === null ? '--' : el.hasAnswer.toString()}
              width="100%"
              fontSize="16px"
              fontWeight="400"
              minHeight="40px"
            />
          </MobileRowValue>
        </MobileRow>

        <MobileRow>
          <MobileRowTitle>{columns[3].title}</MobileRowTitle>
          <MobileRowValue>
            <FilterSelect
              data={['--', ...Array(11).keys()].map(String)}
              setSelected={(value) => changeField(el.id, value, 'score')}
              selected={el.score === null ? '--' : el.score.toString()}
              width="100%"
              fontSize="16px"
              fontWeight="400"
              minHeight="40px"
            />
          </MobileRowValue>
        </MobileRow>
      </MobileTableRowWrap>
    );
  };

  return (
    <>
      {rows.length ? (
        rows.map((el, index) => {
          return <TableRow el={el} key={el.id + index} />;
        })
      ) : (
        <></>
      )}
    </>
  );
};

export default MobileTable;
