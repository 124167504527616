export const columns = [
  { title: 'Entity CRM ID', key: 'ENTITY_CRM_ID', width: 18, keySort: 'entity CRM ID' },
  { title: 'Entity', key: 'ENTITY', width: 18, keySort: 'entity' },
  { title: 'Investor', key: 'INVESTOR', width: 18, keySort: 'investor' },
  // { title: 'Fields Modified', key: 'FIELDS_MODIFIED', width: 18, keySort: 'fields modified' },
  { title: 'Status', key: 'STATUS', width: 18, keySort: 'status' },
  { title: ' ', key: ' ', width: 10 }
];

export const investorEntitiesStatusSuccess = {
  allMatched: 'No Errors',
  continue: 'Please continue to the next step',
  orContinue: 'Or continue to the next step',
  viewAll: 'View All Updates'
};

export const investorEntitiesStatusError = {
  text: 'Errors',
  description: 'There are errors that must be resolved.  Please edit your file and upload it again.'
};
