import { ApolloError, useMutation } from '@apollo/client';
import { useNavigate, useLocation } from 'react-router-dom';
import { DeleteItemIcon } from '@/assets/static/icons/delete-item';
import { DownloadItemIcon } from '@/assets/static/icons/download-item';
import { OpenFileIcon } from '@/assets/static/icons/open-file';
import { ChangeEvent, useEffect, useState } from 'react';
import client from '@/apollo-client';
import Button from '@/components/basicComponents/button';
import {
  CloseBtn,
  ModalControlWrap,
  ModalPageTitle
} from '@/components/client-details';
import { DropFileArea } from '@/components/dueDiligence-page/dueDiligenceRecord/dropFileArea';
import {
  ControlButtons,
  DecorStick,
  ImageItem,
  ImageWrapper,
  OpenButton,
  PreviewItem
} from '@/components/dueDiligence-page/dueDiligenceRecord/edit-pages/longFormContent';
import { ErrorBanner } from '@/components/dueDiligence-page/dueDiligenceRecord/errorBanner';
import {
  AmountCharacters,
  CharactersRemainder,
  StyledTextarea
} from '@/components/dueDiligence-page/modals/changeAssignee';
import FileNotSupported from '@/components/dueDiligence-page/modals/fileNotSupported';
import {
  ButtonWrapper,
  ControlWrapper
} from '@/components/dueDiligence-page/modifyDiligence';
import Header from '@/components/header';
import { useResponsive } from '@/hooks/use-responsive';
import { ApprovedIcon } from '@/assets/icons/info-icons/approved';
import { CloseInfoIcon } from '@/assets/icons/info-icons/closeInfo';
import { MainWrap, PaddingWrap } from '@/styles/common';
import styled from 'styled-components';
import { IFirmSettings } from '.';
import {
  createSettingAttachment,
  deleteSettingAttachment,
  getSettingAttachmentUploadUrl
} from '../../queries';
import { MarginTop, SettingsContainer } from '../importData';
import { fileExtensions } from './constants';

interface EditFirmFieldsProps {
  handleClose: () => void;
  createFirmSetting: (type: string, key: string, value: string) => void;
  updateFirmSetting: (id: string, value: string) => void;
  currentRow: IFirmSettings | null;
  logoImg: string;
}

export const EditFirmFields = ({
  handleClose,
  createFirmSetting,
  updateFirmSetting,
  currentRow,
  logoImg
}: EditFirmFieldsProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const { isMobile, isTablet } = useResponsive();

  const [value, setValue] = useState('');
  const [file, setFile] = useState<File | null>(null);
  const [fileUrl, setFileUrl] = useState('');
  const [drag, setDrag] = useState(false);
  const [isAttachmentDeleted, setIsAttachmentDeleted] = useState(false);
  const [modalWindow, setModalWindow] = useState({
    isOpen: false,
    type: 'not-supported'
  });
  const [addAttachmentError, setAddAttachmentError] =
    useState<ApolloError | null>(null);
  const [deletedFile, setDeletedFile] = useState('');

  useEffect(() => {
    if (currentRow?.value) {
      if (currentRow.key === 'address') {
        const getAddressValue = currentRow.value.split('\\n').join('\n');
        setValue(getAddressValue);
        return;
      }
      if (currentRow.key === 'logo') {
        setFileUrl(logoImg);
        return;
      }
      setValue(currentRow.value);
    }
    return () => {
      setValue('');
    };
  }, [currentRow]);

  const [createAttachment] = useMutation(createSettingAttachment);
  const [deleteAttachment] = useMutation(deleteSettingAttachment);

  const onDropHandler = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const uploadFiles = e.dataTransfer.files;
    setDrag(false);

    if (
      fileExtensions.includes(
        uploadFiles[0]?.name.split('.').pop()?.toUpperCase() ?? ''
      )
    ) {
      setFile(uploadFiles[0]);
      setFileUrl(URL.createObjectURL(uploadFiles[0]));
      return;
    }
    setModalWindow({ isOpen: true, type: 'not-supported' });
  };
  const handleUploadFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const uploadFiles = e.target.files;

    if (
      uploadFiles &&
      fileExtensions.includes(
        uploadFiles[0]?.name.split('.').pop()?.toUpperCase() ?? ''
      )
    ) {
      setFile(uploadFiles[0]);
      setFileUrl(URL.createObjectURL(uploadFiles[0]));
      return;
    }
    setModalWindow({ isOpen: true, type: 'not-supported' });
  };

  const deletePreviewItem = () => {
    if (!file && currentRow?.value) {
      setDeletedFile(JSON.parse(currentRow.value).id);
    }
    setFile(null);
    setFileUrl('');
  };

  const logoUpload = async (file: File) => {
    try {
      const { data: firmSettingsLogoUrl } = await client.query({
        query: getSettingAttachmentUploadUrl,
        variables: {
          data: {
            fieldType: 'firmSetting',
            key: 'logo',
            contentType: file.type,
            filename: file.name.replace(/\s/g, '')
          }
        }
      });

      await fetch(firmSettingsLogoUrl.getSettingAttachmentUploadUrl.url, {
        method: 'PUT',
        body: file,
        headers: {
          'Content-Type': file.type
        }
      });

      createAttachment({
        variables: {
          assetKey: firmSettingsLogoUrl.getSettingAttachmentUploadUrl.assetKey
        },
        onCompleted: (data) => {
          if (currentRow?.value && !isAttachmentDeleted) {
            deleteAttachment({
              variables: { id: JSON.parse(currentRow.value).id }
            });
          }
          if (queryParams.get('id') !== 'null') {
            updateFirmSetting(
              queryParams.get('id') as string,
              JSON.stringify({
                value: data.createSettingAttachment.url,
                assetKey:
                  firmSettingsLogoUrl.getSettingAttachmentUploadUrl.assetKey,
                id: data.createSettingAttachment.id
              })
            );
            return;
          }
          createFirmSetting(
            'firmSetting',
            'logo',
            JSON.stringify({
              value: data.createSettingAttachment.url,
              assetKey:
                firmSettingsLogoUrl.getSettingAttachmentUploadUrl.assetKey,
              id: data.createSettingAttachment.id
            })
          );
        },
        onError: (error) => {
          handleAttachmentError(error);
        }
      });
    } catch (error) {
      console.log(error, 'error');
      handleAttachmentError(error as ApolloError);
    }
  };

  const handleAttachmentError = (error: ApolloError) => {
    console.log(error, 'error');
    if (
      error.message.includes(
        'duplicate key value violates unique constraint'
      ) ||
      error.message.includes('Asset key already exists')
    ) {
      setAddAttachmentError(error);
      setFile(null);
      setFileUrl(currentRow?.value ? JSON.parse(currentRow.value).value : '');
    }
  };

  const saveValue = (key: string, value: string) => {
    if (deletedFile) {
      deleteAttachment({
        variables: { id: deletedFile },
        onCompleted: () => {
          setIsAttachmentDeleted(true);
          updateFirmSetting(queryParams.get('id') as string, '');
        }
      });
    }

    if (file) {
      logoUpload(file);
      return;
    }

    if (currentRow?.key === 'logo' && !isAttachmentDeleted) {
      handleClose();
      return;
    }
    if (queryParams.get('id') !== 'null') {
      updateFirmSetting(queryParams.get('id') as string, value);
      return;
    }
    createFirmSetting('firmSetting', key, value);
  };

  const handleChangePhone = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const regex = /^[0-9+\-()\s]*$/;
    if (regex.test(value)) {
      setValue(value);
    }
  };

  const fieldValue = (type: string) => {
    switch (type) {
      case 'firmName':
        return (
          <Input
            type={'text'}
            value={value}
            onChange={(event) => setValue(event.target.value)}
          />
        );
      case 'email':
        return (
          <Input
            type={'text'}
            value={value}
            onChange={(event) => setValue(event.target.value)}
          />
        );
      case 'website':
        return (
          <Input
            type={'text'}
            value={value}
            onChange={(event) => setValue(event.target.value)}
          />
        );
      case 'address':
        return (
          <>
            <StyledTextarea
              maxLength={250}
              value={value}
              onChange={(event) => setValue(event.target.value)}
            />
            <CharactersRemainder>
              <AmountCharacters>
                {250 - value.length > 0 ? 250 - value.length : 0}
              </AmountCharacters>{' '}
              Characters Remaining
            </CharactersRemainder>
          </>
        );
      case 'logo':
        return (
          <>
            {fileUrl && (
              <div>
                <PreviewItem>
                  <DecorStick isImage />
                  <ApprovedIcon width="25" height="24" fill="#373F4E" />
                  <ImageWrapper href={fileUrl} target="_blank">
                    <ImageItem src={fileUrl} alt={file?.name && ''} />
                  </ImageWrapper>
                  <ControlButtons>
                    <OpenButton href={fileUrl} download>
                      <DownloadItemIcon />
                    </OpenButton>
                    <OpenButton href={fileUrl} target="_blank">
                      <OpenFileIcon />
                    </OpenButton>
                    <DeleteButton onClick={deletePreviewItem}>
                      <DeleteItemIcon />
                    </DeleteButton>
                  </ControlButtons>
                </PreviewItem>
              </div>
            )}
            <DropFileArea
              id="firmLogo"
              fileExtensions={fileExtensions}
              handleUploadFile={handleUploadFile}
              onDropHandler={onDropHandler}
              drag={drag}
              setDrag={setDrag}
            />
          </>
        );
      case 'phone':
        return <Input type="text" value={value} onChange={handleChangePhone} />;
    }
  };

  return (
    <>
      {modalWindow.type === 'not-supported' && (
        <FileNotSupported
          isOpen={modalWindow.isOpen}
          onClose={() => setModalWindow({ ...modalWindow, isOpen: false })}
        />
      )}
      <MainWrap>
        <Header
          modalControl={
            <ModalControlWrap>
              <CloseBtn onClick={handleClose}>
                <CloseInfoIcon width={18} height={18} />
              </CloseBtn>
              <ModalPageTitle isTablet={isTablet} isMobile={isMobile}>
                {queryParams.get('fieldName')}
              </ModalPageTitle>
            </ModalControlWrap>
          }
        />
        <MarginTop />
        {addAttachmentError && (
          <ErrorBanner
            errorMessage={'File name already exists'}
            errorDescription={
              'In order to upload your file please give it a unique name.'
            }
          />
        )}
        <PaddingWrap>
          <SettingsContainer isMobile={isMobile}>
            {queryParams.get('fieldName')}
            {fieldValue(queryParams.get('edit') as string)}
            <CustomControlWrapper isMobile={isMobile}>
              <ButtonWrapper>
                <CancelButton
                  isMobile={isMobile}
                  onClick={handleClose}
                  disabled={false}
                >
                  Cancel
                </CancelButton>
                <SaveButton
                  isMobile={isMobile}
                  onClick={() =>
                    saveValue(queryParams.get('edit') as string, value)
                  }
                  disabled={false}
                >
                  Save
                </SaveButton>
              </ButtonWrapper>
            </CustomControlWrapper>
          </SettingsContainer>
        </PaddingWrap>
      </MainWrap>
    </>
  );
};

const Input = styled.input`
  width: 100%;
  border: 1px solid #c7cbd2;
  border-radius: 4px;
  padding: 12px 8px;
  font-weight: 400;
  font-size: 16px;
  color: #373f4e;

  &:focus {
    outline: none;
  }
`;

const CustomControlWrapper = styled(ControlWrapper)`
  justify-content: flex-end;
  margin: 30px 0;
`;

const DeleteButton = styled(OpenButton)`
  border: 1px solid #d63b4b;
`;

const CancelButton = styled(Button)<{ isMobile: boolean }>`
  background: white;
  border: 1px solid #4587ec;
  color: #4587ec;
  padding: ${({ isMobile }) => (isMobile ? '8.5px 40.5px' : '11.5px 86.5px')};
`;

const SaveButton = styled(Button)<{ isMobile: boolean }>`
  background: ${({ disabled }) => (disabled ? '#dedede' : '#1c488a')};
  padding: ${({ isMobile }) => (isMobile ? '8.5px 50.5px' : '11.5px 93.5px')};
`;
