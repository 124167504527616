import { GroupedIcon } from '@/assets/icons/info-icons/groupedIcon';
import { PlusIcon } from '@/assets/icons/info-icons/plusIcon';
import FormattingTooltip from '@/components/basicComponents/tooltip/dollarsTooltip';
import { useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { AssetClassTargetAllocationDto, SubAssetClassTargetAllocationDto } from '../types';

interface AssetClassRowProps {
  assetClass: AssetClassTargetAllocationDto;
  targetSubAssetClasses: boolean;
  isActivePacingModel: boolean;
  targetOCRatio: number | null;
}

const getCapacityCellContent = (
  row: AssetClassTargetAllocationDto | SubAssetClassTargetAllocationDto,
  isActivePacingModel: boolean,
  targetOCRatio: number | null
) => {
  if (isActivePacingModel && targetOCRatio === null) {
    return (
      <>
        {row.illiquid ? (
          <FormattingTooltip zIndex={1000}>{row?.pacingTarget?.remainingPacingTarget}</FormattingTooltip>
        ) : (
          <FormattingTooltip zIndex={1000}>{row?.capacity}</FormattingTooltip>
        )}
      </>
    );
  }

  if (!isActivePacingModel && targetOCRatio === null) {
    return <>{row.illiquid ? <>--</> : <FormattingTooltip zIndex={1000}>{row?.capacity}</FormattingTooltip>}</>;
  }

  return (
    <>
      <FormattingTooltip zIndex={1000}>{row.capacity}</FormattingTooltip>
    </>
  );
};

export const AssetClassRow = ({ assetClass, targetSubAssetClasses, isActivePacingModel, targetOCRatio }: AssetClassRowProps) => {
  const theme = useTheme();

  const [showSubAssetClass, setShowSubAssetClass] = useState(false);

  return (
    <>
      <SimpleTableRow>
        <RowItem>
          {targetSubAssetClasses && assetClass.subAssetClasses ? (
            <PlusIconWrapper onClick={() => setShowSubAssetClass(!showSubAssetClass)}>
              <PlusIcon fill={theme.font.base} />
            </PlusIconWrapper>
          ) : (
            <></>
          )}
          {assetClass.name}
        </RowItem>
        <RowItem isBold={Boolean(targetSubAssetClasses && assetClass.subAssetClasses)}>{assetClass.percentage?.toFixed(2)}%</RowItem>
        <RowItem isBold={Boolean(targetSubAssetClasses && assetClass.subAssetClasses)}>
          {getCapacityCellContent(assetClass, isActivePacingModel, targetOCRatio)}
        </RowItem>
      </SimpleTableRow>
      {targetSubAssetClasses && showSubAssetClass && (
        <>
          {assetClass.subAssetClasses.map((subAssetClass) => (
            <SimpleTableRow key={subAssetClass.id}>
              <RowItem>
                <GroupIconWrapper>
                  <GroupedIcon width={10} height={10} />
                </GroupIconWrapper>
                {subAssetClass.name}
              </RowItem>
              <RowItem>{subAssetClass.percentage?.toFixed(2)}%</RowItem>
              <RowItem>{getCapacityCellContent(subAssetClass, isActivePacingModel, targetOCRatio)}</RowItem>
            </SimpleTableRow>
          ))}
        </>
      )}
    </>
  );
};

const RowItem = styled.div<{ isBold?: boolean }>`
  width: 33%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 6px;
  font-family: Blinker, serif;
  font-size: 16px;
  font-weight: ${({ isBold }) => (isBold ? 600 : 400)};
  line-height: 22px;
  letter-spacing: 0;
  color: ${({ theme }) => theme.font.base};
`;

const PlusIconWrapper = styled.div`
  padding: 5px;
  cursor: pointer;
`;

const GroupIconWrapper = styled.div`
  padding: 5px;
  position: relative;
  top: -5px;
  left: 5px;
`;

const SimpleTableRow = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  background-color: ${({ theme }) => theme.layer[1]};
  margin-bottom: 3px;
  justify-content: space-between;
  border-radius: 4px;
  padding: 5px 24px;
  position: relative;
`;
