import styled, { useTheme } from 'styled-components';

import { useEffect, useState } from 'react';
import { CheckBoxIcon } from './icon';

type CheckBoxProp = {
  isActive: boolean;
  onClick?: (isChecked?: boolean) => void;
  id: string;
};

const CheckBox = ({ isActive, onClick, id }: CheckBoxProp) => {
  const theme = useTheme();

  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    setIsChecked(isActive);
  }, [isActive]);

  const handleCheck = () => {
    setIsChecked(!isChecked);

    if (onClick) {
      onClick(!isChecked);
    }
  };

  return (
    <CheckBoxWrap id={id} isActive={isActive} onClick={handleCheck}>
      {isActive && <CheckBoxIcon fill={theme.layer[2]} />}
    </CheckBoxWrap>
  );
};

const CheckBoxWrap = styled.div<{ isActive: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 24px;
  width: 24px;
  min-width: 24px;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.border.base};
  background: ${({ isActive, theme }) => (isActive ? theme.font.base : theme.layer[2])};
  cursor: pointer;
`;

export default CheckBox;
