import { useEffect, useState } from 'react';
import styled, { useTheme } from 'styled-components';

import { PaginationBtnIcon } from '@/assets/icons/paginationBtnIcon';
import { SelectIcon } from '@/assets/icons/selectIcon';
import { useResponsive } from '../../hooks/use-responsive';
import FilterSelect from '../basicComponents/select';
import { IPaginationValues } from '../clients-page/types';
import { initialPagination, initialValuesFromTable } from './constants';
import { IPagination } from './types';

export interface TablePaginationProps {
  savePagination?: (limit: number, offset: number) => void;
  amountRowsPerPage?: number[];
  paginationValues: IPaginationValues | undefined;
  refetch: any;
  usePage?: boolean;
  color?: string;
}

const TablePagination = ({
  savePagination,
  paginationValues,
  refetch,
  amountRowsPerPage = initialValuesFromTable.initialAmountRowsPerPage,
  usePage = false,
  color = '#4587EC'
}: TablePaginationProps) => {
  const theme = useTheme();
  const { isMobile, isTablet, isPortrait } = useResponsive();
  const [{ limit, total, offset }, setPagination] = useState<IPagination>(initialPagination);

  useEffect(() => {
    if (paginationValues === undefined) return;
    const { limit, offset, total } = paginationValues;
    setPagination((pagination) => ({ ...pagination, limit, offset, total }));
  }, [paginationValues]);

  const updatePagination = <K extends keyof IPagination>(field: K, value: IPagination[K]) => {
    setPagination((prevPagination) => ({ ...prevPagination, [field]: value }));
    if (usePage) {
      const updatedOffset = field === 'offset' ? value : offset;
      const updatedLimit = field === 'limit' ? value : limit;
      refetch({
        page: updatedOffset / updatedLimit + 1,
        rowsPerPage: updatedLimit
      });
    } else {
      refetch({
        offset: field === 'offset' ? value : offset,
        limit: field === 'limit' ? value : limit
      });
    }
  };

  const nextPage = () => {
    if (offset + limit <= total) {
      updatePagination<'offset'>('offset', offset + limit);
      savePagination?.(limit, offset + limit);
    }
  };

  const prevPage = () => {
    if (offset - limit >= 0) {
      updatePagination<'offset'>('offset', offset - limit);
      savePagination?.(limit, offset - limit);
    }
  };

  const changeRowsPerPage = (value: string) => {
    updatePagination<'limit'>('limit', +value);
    savePagination?.(+value, offset);
  };

  return (
    <TablePaginationWrap isMobile={isMobile || isTablet || isPortrait}>
      <PaginationTitle>Rows per page:</PaginationTitle>
      <FilterSelect
        arrow={<SelectIcon fill={theme.font.action} />}
        width={'55px'}
        data={amountRowsPerPage.map((amount) => amount.toString())}
        selected={`${limit}`}
        setSelected={changeRowsPerPage}
      />
      {isMobile && <PaginationBtnIcon cursor="pointer" onClick={prevPage} />}
      <PaginationInfoWrap isMobile={isMobile}>
        {limit && (
          <PaginationInfo>
            {offset + 1}-{limit + offset < total ? limit + offset : total} of {total}
          </PaginationInfo>
        )}
      </PaginationInfoWrap>
      <ButtonsWrap>
        {!isMobile && <PaginationBtnIcon cursor="pointer" onClick={prevPage} />}
        <PaginationBtnIcon cursor="pointer" onClick={nextPage} rotate="rotate(180deg)" />
      </ButtonsWrap>
    </TablePaginationWrap>
  );
};

const TablePaginationWrap = styled.div<{ isMobile: boolean }>`
  margin-top: 34px;
  display: flex;
  justify-content: ${({ isMobile }) => (isMobile ? 'center' : 'flex-end')} flex-end;
  align-items: center;
  gap: 14px;
`;

const PaginationTitle = styled.div`
  font-family: Blinker, serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0;
  text-align: left;
  color: ${({ theme }) => theme.font.base};
`;

const ButtonsWrap = styled.div`
  display: flex;
  gap: 38px;
`;

const PaginationInfoWrap = styled.div<{ isMobile: boolean }>`
  display: flex;
  justify-content: center;
  margin-right: ${({ isMobile }) => (isMobile ? 0 : '14px')};
`;

const PaginationInfo = styled.div`
  color: ${({ theme }) => theme.font.base};
  font-family: Blinker, serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0;
  text-align: left;
`;
export default TablePagination;
