import * as React from 'react';

export const GroupedIcon = ({ ...props }: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width={props?.width || '26'} height={props?.height || '26'} viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="2.08" height="25.6618" fill="#D9D9D9" />
      <rect x="26" y="23.9468" width="2.05294" height="26" transform="rotate(90 26 23.9468)" fill={props.fill || '#D9D9D9'} />
    </svg>
  );
};
