import FormattingTooltip from '@/components/basicComponents/tooltip/dollarsTooltip';
import { MobileRow, MobileRowTitle, MobileRowValue, MobileTableRowWrap } from '@/components/table/mobileTable/mobileTable';
import { TableColumns } from '@/components/table/types';
import styled from 'styled-components';
import { IInvestorCommitment } from '../../types';

interface MobileSumFooterProps {
  columns: TableColumns[];
  tableData: IInvestorCommitment[];
}

const MobileSumFooterInvestor = ({ columns, tableData }: MobileSumFooterProps) => {
  const total = tableData.reduce<{ committedCapital: number; capitalCalled: number; percentOfCapitalCalled: number }>(
    (sum, value: IInvestorCommitment) => {
      return {
        committedCapital: (sum.committedCapital += value.committedCapital),
        capitalCalled: (sum.capitalCalled += value.capitalCalled),
        percentOfCapitalCalled: (sum.percentOfCapitalCalled += value.percentOfCapitalCalled)
      };
    },
    { committedCapital: 0, capitalCalled: 0, percentOfCapitalCalled: 0 }
  );

  return (
    <>
      <MobileSumFooterWrap>
        <MobileRow>
          <MobileRowTitle>Total</MobileRowTitle>
        </MobileRow>

        <MobileRow>
          <MobileRowTitle>{columns[3].title}</MobileRowTitle>
          <FormattingTooltip zIndex={1000}>{total.committedCapital}</FormattingTooltip>
        </MobileRow>

        <MobileRow>
          <MobileRowTitle>{columns[4].title}</MobileRowTitle>
          <MobileRowValue>
            <FormattingTooltip zIndex={1000}>{total.capitalCalled}</FormattingTooltip>
          </MobileRowValue>
        </MobileRow>

        <MobileRow>
          <MobileRowTitle>{columns[5].title}</MobileRowTitle>
          <MobileRowValue>
            {total.capitalCalled === 0 || total.committedCapital === 0
              ? '0%'
              : ((total.capitalCalled / total.committedCapital) * 100).toFixed(2) + '%'}
          </MobileRowValue>
        </MobileRow>
      </MobileSumFooterWrap>
    </>
  );
};

const MobileSumFooterWrap = styled(MobileTableRowWrap)`
  margin-top: 6px;
  background-color: ${({ theme }) => theme.layer[2]};
`;

export default MobileSumFooterInvestor;
