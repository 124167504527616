import ModalWrappedContent from '@/components/fat-basicComponents/modal/modalWrappedContent';
import { Loader } from '@/components/loaders/loader';
import styled from 'styled-components';
import { UpdateTenantDto, ITenant } from '../types';
import { Input } from '@/components/fat-form-field/input';
import { useForm } from 'react-hook-form';
import { useEffect } from 'react';
import ErrorNotification from '@/components/recommendations-page/error-notification';

interface EditTenantModalProps {
  onCancel: () => void;
  onConfirm: (data: UpdateTenantDto) => void | null;
  isOpen: boolean;
  tenant: ITenant;
  isLoading?: boolean;
  error?: string;
}

const defaultFormValues: UpdateTenantDto = {
  id: '',
  name: '',
};

const EditTenantModal = ({ onCancel, onConfirm, isOpen, tenant, isLoading, error }: EditTenantModalProps) => {
  const {
    getValues,
    control,
    setValue,
    formState: { errors, isValid },
  } = useForm<UpdateTenantDto>({ defaultValues: defaultFormValues as any });

  useEffect(() => {
    if (tenant) {
      const { id, name } = tenant;
      setValue('id', id);
      setValue('name', name);
    }

    return () => {
      setValue('id', '');
      setValue('name', '');
    };
  }, [tenant]);
  
  return (
    <ModalWrappedContent
      isOpen={isOpen}
      onClose={onCancel}
      confirmButton={
        isLoading 
          ? {
            name: 'Save',
            disabled: true,
            onClick: () => null,
          }
          : {
              name: 'Save',
              disabled: !isValid,
              onClick: () => { 
                if (!isValid) return;
                onConfirm(getValues());
              },
            }
      }
      showCancelButton={!isLoading}
      isUseOutsideClick={!isLoading}
      showCloseIcon={!isLoading}
    >
      <ModalContainer>
        {error && (
          <ErrorNotification
            title="There was an error editing this Tenant."
            message={error ?? ''}
          />
        )}
        <ModalTitle>Edit Tenant</ModalTitle>
        <ModalText fontWeight="400">
          {isLoading && (
            <>
              <br /> <br />
              <Loader size={30} />
              Updating tenant...
            </>
          )}
          {!isLoading && (
            <>
              <br /> <br />
              <Input
                title={'Name'}
                nameField={'name'}
                rules={{
                  required: {
                    value: true,
                    message: 'Name is required'
                  }
                }}
                control={control}
                placeHolder={'Name of the firm'}
                isWarning={true}
              />
            </>
          )}
        </ModalText>
      </ModalContainer>
    </ModalWrappedContent>
  );
};

export default EditTenantModal;

const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  padding: 30px 0;
`;

const ModalTitle = styled.div`
  color: ${({ theme }) => theme.font.strong};
  text-align: center;
  font-family: Blinker;
  font-size: 33px;
  font-style: normal;
  font-weight: 400;
  line-height: 46.2px;
`;

const ModalText = styled.span<{ fontWeight: string }>`
  color: ${({ theme }) => theme.font.base};
  text-align: center;
  font-family: Blinker;
  font-size: 19px;
  font-style: normal;
  font-weight: ${({ fontWeight }) => fontWeight};
  line-height: 26.6px;
`;
