import { Box } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

export const Loading = () => {
  return (
    <>
      <Box aria-label="loading spinner" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', p: 8 }}>
        <CircularProgress color="primary" />
      </Box>
    </>
  );
};
