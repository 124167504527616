export type ColumnList = {
  title: string;
  key: string | null;
  width: number;
  keySort: string;
  rowKey: string;
  value: boolean;
  order: number;
};

export interface DealRegistryFiltersStructure {
  assetClass: string[];
  funnel: string[];
}

export enum ASSET_CLASS {
  PRIVATE_EQUITY = 'Private Equity',
  PRIVATE_CREDIT = 'Private Credit',
  PRIVATE_REAL_ASSETS = 'Private Real Assets',
  NOT_SET = 'Not Set',
}

export enum InvestmentStatusEnum {
  LEAD = 'Lead',
  DECLINED = 'Declined',
  DUE_DILIGENCE = 'Due Diligence',
  APPROVED = 'Approved',
  REJECTED = 'Rejected',
  OPEN = 'Open',
  ACTIVE = 'Active',
  REALIZED = 'Realized',
  REDEEMED = 'Redeemed',
}
