import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import React from 'react';

interface CheckedItemsProp {
  deleteHandle: (item: string) => void;
  list: string[];
}

export const CheckedItems: React.FC<CheckedItemsProp> = ({ deleteHandle, list }) => {
  return (
    <ItemsContainer>
      {list.map(item => (
        <Item key={item}>
          {item}
          <ImageCross onClick={() => deleteHandle(item)} src="/static/icons/blue-cross.svg" alt="Cross" />
        </Item>
      ))}
    </ItemsContainer>
  );
};
const ItemsContainer = styled(Box)(() => ({
  width: '100%',
  paddingTop: 10,
  display: 'flex',
  flexWrap: 'wrap',
  gap: '10px 4px',
}));
const Item = styled(Box)(() => ({
  background: '#F0F1F3',
  color: '#578FE4',
  fontFamily: 'Inter',
  fontWeight: 600,
  border: '1px solid #578FE4',
  boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
  borderRadius: 4,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '8px 14px',
  fontSize: 13,
}));
const ImageCross = styled('img')(() => ({
  fill: '#578FE4',
  cursor: 'pointer',
  marginLeft: 10,
  height: 10,
  width: 10,
}));
