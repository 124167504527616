import ModalWrappedContent from '@/components/basicComponents/modal/modalWrappedContent';
import { useAuth } from '@/hooks/use-auth';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

interface SignOutProps {
  onClose: () => void;
  isOpen: boolean;
}

const SignOut = ({ onClose, isOpen }: SignOutProps) => {
  const { signOut } = useAuth();
  const navigate = useNavigate();

  const saveValue = () => {
    signOut();
    navigate('/', { state: { isLastPageProfile: true } });
  };

  return (
    <ModalWrappedContent
      isOpen={isOpen}
      onClose={onClose}
      showCancelButton={false}
      confirmButton={{
        name: 'Sign out',
        onClick: saveValue,
        bgColor: '#172d4d'
      }}
    >
      <ModalTitle>Sign out?</ModalTitle>
      <Description>Are you sure you’re ready to sign out?</Description>
    </ModalWrappedContent>
  );
};

export default SignOut;

const ModalTitle = styled.div`
  color: ${(props) => props.theme.body.header};
  font-size: 33px;
  font-weight: 400;
  line-height: 46.2px;
  text-align: center;
  padding-bottom: 35px;
`;

const Description = styled.p`
  font-weight: 400;
  font-size: 22px;
  line-height: 18px;
  text-align: center;
  color: ${(props) => props.theme.body.header};
`;
