import React from 'react';
import styled, { useTheme } from 'styled-components';

import { CloseInfoIcon } from '@/assets/icons/info-icons/closeInfo';

type MobileModalType = {
  children: React.ReactNode;
  onClick: () => void;
  title: string;
  onClose: () => void;
};

const MobileModal = ({ children, title, onClick, onClose }: MobileModalType) => {
  const theme = useTheme();

  return (
    <MobileTableWrap>
      <ModalContentWrap>
        <HeaderDropDown>
          <DropDownHeader>
            <HyperTitle onClick={onClick}>{title}</HyperTitle>
            <CloseInfoIconWrap onClick={onClose}>
              <CloseInfoIcon fill={theme.font.action} />
            </CloseInfoIconWrap>
          </DropDownHeader>
          <Divider />
        </HeaderDropDown>
        {children}
      </ModalContentWrap>
    </MobileTableWrap>
  );
};

const MobileTableWrap = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.65);
  z-index: 9999;
`;

const ModalContentWrap = styled.div`
  margin-top: 15%;
  margin-left: 6%;
  margin-right: 6%;
  margin-bottom: 15%;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  width: 88%;
  height: max-content;
  max-height: 80%;
  overflow: scroll;
  overflow-x: hidden;
  background: ${({ theme }) => theme.layer.base};
  border: 1px solid ${({ theme }) => theme.border.base};
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.15);
  border-radius: 3px;
  padding: 20px 30px 30px;
`;

const HeaderDropDown = styled.div`
  display: flex;
  flex-direction: column;
`;

const HyperTitle = styled.span`
  color: ${({ theme }) => theme.font.action};
  font-family: 'Blinker', serif;
  font-style: normal;
  font-weight: 600;
  word-spacing: 4px;
  line-height: 25px;
  font-size: 22px;
  padding: 0 20px 0 0;
  margin: 0;
  cursor: pointer;
`;

const DropDownHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const CloseInfoIconWrap = styled.div`
  cursor: pointer;
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: ${({ theme }) => theme.border.base};
  margin-top: 10px;
  margin-bottom: 22px;
`;

export default MobileModal;
