import { useMutation } from '@apollo/client';

import Label from '@/components/basicComponents/label';
import Textarea from '@/components/basicComponents/textarea';
import { INotesData } from '@/components/client-details/types';
import ModalWrappedContent from '@/components/fat-basicComponents/modal/modalWrappedContent';
import { updateLegalEntityMutation } from '@/components/fat-investors-page/fat-addInvestors/queries';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

interface EditNoteProps {
  currentNote: INotesData | null;
  onClose: () => void;
  isOpen: boolean;
  notes: INotesData[] | null;
  refetch: () => void;
}

const EditNote = ({ currentNote, onClose, isOpen, notes, refetch }: EditNoteProps) => {
  const params = useParams();

  const [note, setNote] = useState('');

  useEffect(() => {
    if (currentNote) {
      setNote(currentNote.notes);
      return;
    }
    setNote('');
  }, [isOpen]);

  const [updateEntity] = useMutation(updateLegalEntityMutation);

  const addNewNote = () => {
    const prevNotes =
      notes?.map((note) => ({
        title: {
          note: note.notes,
          date: note.notesDate
        }
      })) ?? [];

    updateEntity({
      variables: {
        data: {
          id: params?.entityId,
          notes: JSON.stringify([
            ...prevNotes,
            {
              title: {
                note: note,
                date: new Date()
              }
            }
          ])
        }
      },
      onCompleted: () => {
        refetch();
        onClose();
      }
    });
  };

  const editNote = () => {
    if (!notes?.length) return;

    const updatedNotes = notes.map((noteItem) => {
      if (noteItem === currentNote) {
        return {
          title: {
            note: note,
            date: noteItem.notesDate
          }
        };
      }
      return {
        title: {
          note: noteItem.notes,
          date: noteItem.notesDate
        }
      };
    });

    updateEntity({
      variables: {
        data: {
          id: params?.entityId,
          notes: JSON.stringify(updatedNotes)
        }
      },
      onCompleted: () => {
        refetch();
        onClose();
      }
    });
  };

  return (
    <ModalWrappedContent
      isOpen={isOpen}
      onClose={onClose}
      title={currentNote ? 'Edit Note' : 'Add New Note'}
      confirmButton={{
        name: 'Confirm',
        onClick: currentNote ? editNote : addNewNote,
        disabled: !note.length
      }}
    >
      <Label required>Note</Label>
      <Textarea value={note} setValue={setNote} maxLength={250} />
    </ModalWrappedContent>
  );
};

export default EditNote;
