import { OPERATIONS_STATUS } from '../opportunities-entity/proposed';

export const columns = [
  { title: 'ENTITY', key: 'entityName', width: 20, keySort: 'entity' },
  { title: 'ENTITY VALUE', key: 'totalEntityValue', width: 20, keySort: 'entity value' },
  { title: '% TO PRIVATE CAPITAL', key: 'privatePercentage', width: 20, keySort: '% to private capital' },
  { title: 'OC RATIO', key: 'overCommitRatio', width: 20, keySort: 'oc ratio' },
  { title: 'CAPACITY', key: 'capacity', width: 20, keySort: 'capacity' },
];

export const statusNextStepRelations = {
  [OPERATIONS_STATUS.READY_FOR_CLIENT_REVIEW]: 'Send to client',
  [OPERATIONS_STATUS.SENT_TO_CLIENT]: 'Waiting for client approval',
  [OPERATIONS_STATUS.CLIENT_APPROVED]: 'Prepare subdoc',
  [OPERATIONS_STATUS.SUBDOC_READY_FOR_REVIEW]: 'Review and approve subdoc',
  [OPERATIONS_STATUS.SUBDOC_READY_FOR_CLIENT]: 'Send subdoc to client',
  [OPERATIONS_STATUS.SUBDOC_SENT_TO_CLIENT]: 'Wait for client signature',
  [OPERATIONS_STATUS.SUBDOC_SIGNED_BY_CLIENT]: 'Send subdocs to manager',
  [OPERATIONS_STATUS.SUBDOC_SENT_TO_FUND_MANAGER]: 'Wait for manager confirmation',
  [OPERATIONS_STATUS.SUBDOC_CONFIRMED_BY_FUND_MANAGER]: 'Submit commitment to reporting system',
  [OPERATIONS_STATUS.COMMITMENT_SUBMITTED_TO_REPORTING_SYSTEM]: 'Waiting for commitment in reporting system',
};

export const clientDetailsSort = [
  {
    title: 'Entity • A -> Z',
    key: 'entityName',
    asc: true,
  },
  {
    title: 'Entity • Z -> A',
    key: 'entityName',
    asc: false,
  },
  {
    title: 'Entity value • Max -> Min',
    key: 'totalEntityValue',
    asc: false,
  },
  {
    title: 'Entity value • Min -> Max',
    key: 'totalEntityValue',
    asc: true,
  },
  {
    title: '% To Private capital • Max -> Min',
    key: 'privatePercentage',
    asc: false,
  },
  {
    title: '% To Private capital • Min -> Max',
    key: 'privatePercentage',
    asc: true,
  },
  {
    title: 'OC Ratio • Max -> Min',
    key: 'overCommitRatio',
    asc: false,
  },
  {
    title: 'OC Ratio • Min -> Max',
    key: 'overCommitRatio',
    asc: true,
  },
  {
    title: 'Capacity • Max -> Min',
    key: 'capacity',
    asc: false,
  },
  {
    title: 'Capacity • Min -> Max',
    key: 'capacity',
    asc: true,
  },
];

export const reverseStatusNextStepRelations = Object.fromEntries(Object.entries(statusNextStepRelations).map(([key, value]) => [value, key]));
