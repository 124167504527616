import { getUTCDate } from '@/components/allocations-page/utils';
import BasicTooltip from '@/components/basicComponents/tooltip';
import FormattingTooltip from '@/components/basicComponents/tooltip/dollarsTooltip';
import { formatterClient } from '@/components/clients-page/utils';
import NoResults from '@/components/table/noResults';
import { TableBodyWrapper, TableRowCell, TableRowWrapper, TextCell } from '@/components/table/styles';
import { formatCash } from '@/utils/formatCash';
import guid from '@/utils/guid';
import styled from 'styled-components';
import { transactionsTableColumns } from '../constatnts';
import { IListEntityTransactionsData } from '../types';

interface TableBodyProps {
  tableRows: IListEntityTransactionsData[];
}

const TableBody = ({ tableRows }: TableBodyProps) => {
  if (!tableRows?.length) {
    return <NoResults />;
  }

  const amountToolTipContent = (row: IListEntityTransactionsData) => {
    return (
      <TableBodyWrapper>
        <TableRowCell width={100}>
          <TextCell>Investment Amount: {formatterClient.format(row.amount)}</TextCell>
        </TableRowCell>
        <TableRowCell width={100}>
          <TextCell>Total Amount incl Fees: {formatterClient.format(row.totalAmount)}</TextCell>
        </TableRowCell>
      </TableBodyWrapper>
    );
  };

  return (
    <>
      {tableRows.map((row) => (
        <TableRowWrapper key={guid()}>
          <TableRowCell width={transactionsTableColumns[0].width}>
            <TextCell>{row.strategy}</TextCell>
          </TableRowCell>
          <TableRowCell width={transactionsTableColumns[1].width}>
            <TextCell>{getUTCDate(row.date)}</TextCell>
          </TableRowCell>
          <TableRowCell width={transactionsTableColumns[2].width}>
            <TextCell>{row.transactionType}</TextCell>
          </TableRowCell>
          <TableRowCell width={transactionsTableColumns[3].width}>
            <TextCell>
              <CellWithTooltip>
                {formatCash(row.amount)}
                <TooltipWrapper id="infoWrap">
                  <BasicTooltip tooltipContent={amountToolTipContent(row)} withTooltipIcon zIndex={1000} />
                </TooltipWrapper>
              </CellWithTooltip>
            </TextCell>
          </TableRowCell>
          <TableRowCell width={transactionsTableColumns[4].width}>
            <TextCell>
              <FormattingTooltip zIndex={1000}>{row.distributionsToDate}</FormattingTooltip>
            </TextCell>
          </TableRowCell>
          <TableRowCell width={transactionsTableColumns[5].width}>
            <TextCell>
              <FormattingTooltip zIndex={1000}>{row.remainingCapital}</FormattingTooltip>
            </TextCell>
          </TableRowCell>
          <TableRowCell width={transactionsTableColumns[5].width}>
            <TextCell>
              <FormattingTooltip zIndex={1000}>{row.marketValue}</FormattingTooltip>
            </TextCell>
          </TableRowCell>
        </TableRowWrapper>
      ))}
    </>
  );
};

export default TableBody;

const TooltipWrapper = styled.div`
  opacity: 0;
`;

const CellWithTooltip = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;
