import FilterPage from '@/components/table/fat-filterPage';
import TableHeader from '@/components/table/fat-tableHeader';
import { TableBodyWrapper, TableWrapper } from '@/components/table/styles';
import { ISort } from '@/components/table/types';
import { useResponsive } from '@/hooks/use-responsive';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import styled from 'styled-components';
import { IEntityImportComparison, TypeWindow } from '../../../types';
import { columns } from '../constants';
import MobileTable from './mobileTable';
import TableBody from './tableBody';

interface TableProps {
  tableData: IEntityImportComparison[];
  openEntityImportDetailPage: (type: TypeWindow, row: IEntityImportComparison) => void;
  sort: ISort;
  setSort: Dispatch<SetStateAction<ISort>>;
  search: string;
  onChangeSearch: (value: string) => void;
  primaryFilter: string;
  changePrimaryFilterValue: (filter: string) => void;
}

const Table = ({
  tableData,
  openEntityImportDetailPage,
  sort,
  setSort,
  search,
  onChangeSearch,
  primaryFilter,
  changePrimaryFilterValue
}: TableProps) => {
  const { isMobile, isTablet } = useResponsive();

  const [sortedTableData, setSortedTableData] = useState<IEntityImportComparison[]>([]);

  useEffect(() => {
    const sortedData = [...tableData].sort((a, b) => {
      switch (sort.key) {
        case 'ENTITY_CRM_ID':
          return sort.asc ? a.entityCRMId.localeCompare(b.entityCRMId) : b.entityCRMId.localeCompare(a.entityCRMId);
        case 'ENTITY':
          return sort.asc ? a.entityName.localeCompare(b.entityName) : b.entityName.localeCompare(a.entityName);
        case 'INVESTOR':
          return sort.asc ? a.investorName.localeCompare(b.investorName) : b.investorName.localeCompare(a.investorName);
        case 'FIELDS_MODIFIED':
          return sort.asc ? a.fieldsModifiedCount - b.fieldsModifiedCount : b.fieldsModifiedCount - a.fieldsModifiedCount;
        case 'STATUS':
          return sort.asc ? a.status.localeCompare(b.status) : b.status.localeCompare(a.status);
        default:
          return 0;
      }
    });

    setSortedTableData(sortedData);
  }, [sort, tableData]);

  const filteredTableData = () => {
    const newTableData = [...sortedTableData];
    const filteredTableData = newTableData.filter(
      (item) => item.entityCRMId?.toLowerCase().includes(search.toLowerCase()) || item.entityName?.toLowerCase().includes(search.toLowerCase())
    );

    switch (primaryFilter) {
      case 'Updates Only':
        return filteredTableData.filter((item) => item.status !== 'error' && item.status !== 'unchanged');
      case 'Errors Only':
        return filteredTableData.filter((item) => item.status === 'error');
      default:
        return filteredTableData;
    }
  };

  return (
    <>
      <CustomFilterPage
        isMobile={isMobile}
        isTablet={isTablet}
        search={{
          value: search,
          onChange: onChangeSearch,
          placeholder: 'Search'
        }}
        primaryFilter={{
          value: primaryFilter,
          onChange: changePrimaryFilterValue,
          selects: ['All Rows', 'Updates Only', 'Errors Only']
        }}
        resultsValue={filteredTableData().length}
        refetch={() => null as any}
      />
      <TableWrapper padding="0">
        {isMobile ? (
          <MobileTable tableData={filteredTableData()} openEntityImportDetailPage={openEntityImportDetailPage} />
        ) : (
          <>
            <CustomTableHeader isTablet={isTablet} refetch={() => null as any} columns={columns} savedSort={sort} savedSetSort={setSort} />
            <TableBodyWrapper>
              <TableBody tableData={filteredTableData()} openEntityImportDetailPage={openEntityImportDetailPage} />
            </TableBodyWrapper>
          </>
        )}
      </TableWrapper>
    </>
  );
};

export default Table;

const CustomFilterPage = styled(FilterPage)<{ isMobile: boolean; isTablet: boolean }>`
  position: sticky;
  top: ${({ isMobile, isTablet }) => (isMobile || isTablet ? '50px' : '0')};
  z-index: 9;
  margin-top: 0;
  margin-left: -1px;
  width: calc(100% + 2px);
  padding: ${({ isMobile }) => (isMobile ? '20px 0 0 0' : '20px 0 10px 0')};
`;

const CustomTableHeader = styled(TableHeader)<{ isTablet: boolean }>`
  position: sticky;
  top: ${({ isTablet }) => (isTablet ? '130px' : '80px')};
  z-index: 8;
  padding-top: 0;
`;
