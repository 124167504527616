import * as React from 'react';

export const TodoCalendar = ({ ...props }: React.SVGProps<SVGSVGElement>) => (
  <svg width="25" height="27" viewBox="0 0 25 27" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.492 2.83198H21.82C22.1709 2.83093 22.5186 2.89901 22.8432 3.03232C23.1678 3.16563 23.4629 3.36156 23.7117 3.60893C23.9606 3.85631 24.1583 4.15029 24.2936 4.47407C24.4288 4.79785 24.499 5.1451 24.5 5.49599V24.168C24.4979 24.8752 24.2154 25.5528 23.7146 26.0522C23.2137 26.5516 22.5353 26.832 21.828 26.832H3.16403C2.45749 26.832 1.77988 26.5513 1.28029 26.0517C0.780688 25.5521 0.500012 24.8745 0.500012 24.168V5.49599C0.498959 5.14653 0.56686 4.80026 0.699866 4.4771C0.832871 4.15393 1.02837 3.86022 1.27511 3.61274C1.52185 3.36526 1.81499 3.16889 2.13776 3.03491C2.46052 2.90093 2.80655 2.83198 3.15602 2.83198H4.492V0.167969H7.15602V2.83198H17.82V0.167969H20.492V2.83198ZM18.9666 11.7224L9.67865 20.4294L6.03946 17.0258L4.05519 18.8859L9.67865 24.1656L20.9424 13.6063L18.9666 11.7224ZM21.8312 5.50077H3.16642V9.05597H21.8312V5.50077Z"
      fill={props.fill || '#FAFAFA'}
    />
  </svg>
);
