export const VIEW_BY_SELECTS = ['Advisor', 'Investor', 'Entity'];

export const advisorColumns = [
  {
    title: 'Advisory Firms',
    key: 'ADVISORY_FIRMS',
    width: 20,
    keySort: 'advisory firms'
  },
  {
    title: '# Of Investors',
    key: 'NUM_OF_INVESTORS',
    width: 20,
    keySort: '# of investors'
  },
  {
    title: '# Of Commitments',
    key: 'NUM_OF_INVESTMENTS',
    width: 20,
    keySort: '# of commitments'
  },
  {
    title: 'Total Entity Value',
    key: 'TOTAL_ENTITY_VALUE',
    width: 20,
    keySort: 'total entity value'
  },
  {
    title: 'Total Commitment',
    key: 'TOTAL_COMMITMENTS',
    width: 20,
    keySort: 'total commitment'
  }
];

export const investorColumns = [
  { title: 'Investor', key: 'INVESTOR', width: 20, keySort: 'investor' },
  {
    title: 'Advisory Firms',
    key: 'ADVISORY_FIRMS',
    width: 20,
    keySort: 'advisory firms'
  },
  {
    title: '# Of Commitments',
    key: 'NUM_OF_INVESTMENTS',
    width: 20,
    keySort: '# of commitments'
  },
  {
    title: 'Total Entity Value',
    key: 'TOTAL_ENTITY_VALUE',
    width: 20,
    keySort: 'total entity value'
  },
  {
    title: 'Total Commitment',
    key: 'TOTAL_COMMITMENTS',
    width: 20,
    keySort: 'total commitment'
  }
];

export const entityColumns = [
  { title: 'Entity', key: 'ENTITY', width: 15, keySort: 'entity' },
  { title: 'Investor', key: 'INVESTOR', width: 15, keySort: 'investor' },
  {
    title: 'Advisory Firms',
    key: 'ADVISORY_FIRMS',
    width: 15,
    keySort: 'advisory firms'
  },
  {
    title: '# Of Commitments',
    key: 'NUM_OF_INVESTMENTS',
    width: 20,
    keySort: '# of commitments'
  },
  {
    title: 'Total Entity Value',
    key: 'TOTAL_ENTITY_VALUE',
    width: 20,
    keySort: 'total entity value'
  },
  {
    title: 'Total Commitment',
    key: 'TOTAL_COMMITMENTS',
    width: 20,
    keySort: 'total commitment'
  }
];

export const initialListValue = {
  data: [] as [],
  total: 0,
  perPage: 0,
  page: 0
};

export const advisorTableSort = [
  {
    title: 'Advisory Firms • A -> Z',
    key: 'ADVISORY_FIRMS',
    asc: true
  },
  {
    title: 'Advisory Firms • Z -> A',
    key: 'ADVISORY_FIRMS',
    asc: false
  },
  {
    title: '# Of Investors • Max -> Min',
    key: 'NUM_OF_INVESTORS',
    asc: false
  },
  {
    title: '# Of Investors • Min -> Max',
    key: 'NUM_OF_INVESTORS',
    asc: true
  },
  {
    title: '# Of Commitments • Max -> Min',
    key: 'NUM_OF_INVESTMENTS',
    asc: false
  },
  {
    title: '# Of Commitments • Min -> Max',
    key: 'NUM_OF_INVESTMENTS',
    asc: true
  },
  {
    title: 'Total Entity Value • Max -> Min',
    key: 'TOTAL_ENTITY_VALUE',
    asc: false
  },
  {
    title: 'Total Entity Value • Min -> Max',
    key: 'TOTAL_ENTITY_VALUE',
    asc: true
  },
  {
    title: 'Total Commitment • Max -> Min',
    key: 'TOTAL_COMMITMENTS',
    asc: false
  },
  {
    title: 'Total Commitment • Min -> Max',
    key: 'TOTAL_COMMITMENTS',
    asc: true
  }
];

export const investorTableSort = [
  {
    title: 'Investor • A -> Z',
    key: 'INVESTOR',
    asc: true
  },
  {
    title: 'Investor • Z -> A',
    key: 'INVESTOR',
    asc: false
  },
  {
    title: 'Advisory Firms • A -> Z',
    key: 'ADVISORY_FIRMS',
    asc: true
  },
  {
    title: 'Advisory Firms • Z -> A',
    key: 'ADVISORY_FIRMS',
    asc: false
  },

  {
    title: '# Of Commitments • Max -> Min',
    key: 'NUM_OF_INVESTMENTS',
    asc: false
  },
  {
    title: '# Of Commitments • Min -> Max',
    key: 'NUM_OF_INVESTMENTS',
    asc: true
  },
  {
    title: 'Total Entity Value • Max -> Min',
    key: 'TOTAL_ENTITY_VALUE',
    asc: false
  },
  {
    title: 'Total Entity Value • Min -> Max',
    key: 'TOTAL_ENTITY_VALUE',
    asc: true
  },
  {
    title: 'Total Commitment • Max -> Min',
    key: 'TOTAL_COMMITMENTS',
    asc: false
  },
  {
    title: 'Total Commitment • Min -> Max',
    key: 'TOTAL_COMMITMENTS',
    asc: true
  }
];

export const entityTableSort = [
  {
    title: 'Entity • A -> Z',
    key: 'ENTITY',
    asc: true
  },
  {
    title: 'Entity • Z -> A',
    key: 'ENTITY',
    asc: false
  },
  {
    title: 'Investor • A -> Z',
    key: 'INVESTOR',
    asc: true
  },
  {
    title: 'Investor • Z -> A',
    key: 'INVESTOR',
    asc: false
  },
  {
    title: 'Advisory Firms • A -> Z',
    key: 'ADVISORY_FIRMS',
    asc: true
  },
  {
    title: 'Advisory Firms • Z -> A',
    key: 'ADVISORY_FIRMS',
    asc: false
  },

  {
    title: '# Of Commitments • Max -> Min',
    key: 'NUM_OF_INVESTMENTS',
    asc: false
  },
  {
    title: '# Of Commitments • Min -> Max',
    key: 'NUM_OF_INVESTMENTS',
    asc: true
  },
  {
    title: 'Total Entity Value • Max -> Min',
    key: 'TOTAL_ENTITY_VALUE',
    asc: false
  },
  {
    title: 'Total Entity Value • Min -> Max',
    key: 'TOTAL_ENTITY_VALUE',
    asc: true
  },
  {
    title: 'Total Commitment • Max -> Min',
    key: 'TOTAL_COMMITMENTS',
    asc: false
  },
  {
    title: 'Total Commitment • Min -> Max',
    key: 'TOTAL_COMMITMENTS',
    asc: true
  }
];
