import { RejectedBannerIcon } from '@/assets/icons/info-icons/rejectedBanner';
import { AnimatePresence, motion } from 'framer-motion';
import { ChangeEvent, Dispatch, SetStateAction } from 'react';

import RadioButton from '@/components/basicComponents/radioButton';
import FilterSelect from '@/components/basicComponents/select';
import { useResponsive } from '@/hooks/use-responsive';
import { User } from '@/types/user';
import styled, { useTheme } from 'styled-components';
import { clientAdvisorSectionOptions } from '..';
import { Section } from '../../fat-entityDetails-page/edit-entity/section';

interface ClientAdvisorSectionProps {
  selectedAdvisor: string;
  setSelectedAdvisor: Dispatch<SetStateAction<string>>;
  advisorsList: User[];
  selectedOption: string;
  setSelectedOption: Dispatch<SetStateAction<string>>;
}

export const ClientAdvisorSection = ({
  selectedAdvisor,
  setSelectedAdvisor,
  advisorsList,
  selectedOption,
  setSelectedOption
}: ClientAdvisorSectionProps) => {
  const { isMobile, isTablet } = useResponsive();
  const theme = useTheme();

  const handleRadioChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSelectedOption(event.target.value);
  };

  return (
    <Section title="Client Advisor">
      <ClientAdvisorWrapper>
        Organize your investors by assigning an advisor (optional).
        <ClientAdvisor isMobile={isMobile}>
          <OptionsWrapper>
            {clientAdvisorSectionOptions.map((option, index) => (
              <RadioButton
                key={option + index}
                name="defaultBiteSize"
                radioButtonText={option}
                selectedOption={selectedOption}
                handleRadioChange={(event) => handleRadioChange(event)}
              />
            ))}
          </OptionsWrapper>
          <AnimatePresence>
            <ClientAdvisorWrap>
              {selectedOption === clientAdvisorSectionOptions[0] && (
                <motion.div
                  initial={{
                    opacity: 0
                  }}
                  animate={{
                    opacity: 1
                  }}
                  exit={{
                    opacity: 0
                  }}
                >
                  <BannerWrap>
                    <BannerIconWrap>
                      <RejectedBannerIcon fill={theme.header.base} />
                    </BannerIconWrap>
                    <BannerText>Administrators have full control over all investors at all times.</BannerText>
                  </BannerWrap>
                </motion.div>
              )}
              {selectedOption === clientAdvisorSectionOptions[1] && (
                <motion.div
                  initial={{
                    opacity: 0
                  }}
                  animate={{
                    opacity: 1
                  }}
                  exit={{
                    opacity: 0
                  }}
                >
                  <CustomFilterSelect
                    data={advisorsList.map((el) => `${el.firstName} ${el.lastName}`)}
                    selected={selectedAdvisor}
                    setSelected={setSelectedAdvisor}
                    label="Advisor"
                    required
                    width={isMobile ? '100%' : '500px'}
                  />
                </motion.div>
              )}
            </ClientAdvisorWrap>
          </AnimatePresence>
        </ClientAdvisor>
      </ClientAdvisorWrapper>
    </Section>
  );
};

const ClientAdvisorWrapper = styled.div`
  color: ${({ theme }) => theme.font.base};
`;

const OptionsWrapper = styled.div`
  max-width: 300px;
  width: 100%;
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;
`;

const ClientAdvisor = styled.div<{ isMobile: boolean }>`
  display: flex;
  align-items: ${({ isMobile }) => (isMobile ? 'flex-start' : 'flex-end')};
  flex-direction: ${({ isMobile }) => (isMobile ? 'column-reverse' : 'row')};
  gap: ${({ isMobile }) => (isMobile ? '15px' : '0')};
`;

const BannerWrap = styled.span`
  display: flex;
  align-items: center;
  gap: 20px;
  background-color: ${({ theme }) => theme.layer[1]};
  height: 76px;
  padding: 10px 20px;
`;

const BannerIconWrap = styled.span`
  display: flex;
  align-items: center;
  height: 100%;
  padding-right: 20px;
  border-right: 1px solid ${({ theme }) => theme.border.base};
`;

const BannerText = styled.span`
  font-size: 13px;
  font-weight: 400;
  line-height: 18.2px;
  color: ${({ theme }) => theme.font.base};
`;

const ClientAdvisorWrap = styled.div`
  width: 100%;
`;

const CustomFilterSelect = styled(FilterSelect)`
  padding: 10px 12px;
  border-radius: 5px;
  color: ${({ theme }) => theme.font.strong};
  #titleSelect {
    font-family: Blinker;
    font-size: 16px;
    font-weight: 400;
  }
`;
