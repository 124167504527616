import {
  Box,
  Table,
  TableBody,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Typography
} from '@mui/material';
import styled, { useTheme } from 'styled-components';
import { tooltipClasses } from '@mui/material/Tooltip';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Advisor } from '@/types/family';
// import { makeStyles } from 'tss-react/mui';

import { IPagination } from '../../../types/opportunity/pending-allocation';
import {
  IModalWindowValues,
  ISelectors,
  ITable,
  ITypeModalWindow
} from '../../../types/opportunity/proposed';
import InputWithSigns from '../../inputWithSigns';
import {
  BodyCell,
  BodyCellInner,
  BodyRow,
  HeaderCell,
  HeaderRow
} from '../../investments-table';
import {
  CustomTriangle,
  GreenCircle
} from '../../investments-table/active-triangle-table';
import { Loader } from '../../loaders/loader';
import ModalWindow from '../../modalWindow';
import { Selector } from '../../selector';
import { Tooltip } from '../../tooltip';
import {
  header,
  initialModalWindowValuesOptions,
  initialSelectorsOptions,
  listOfStatusWhenAllowToEditAmount,
  statusInvestments
} from './constants';
import Complete from './modalWindows/complete';
import Confirmation from './modalWindows/confirmation';
import TerminateProposalModalWindow from './modalWindows/terminate-proposal';
import { style } from '@mui/system';

interface ProposedAllocationProps {
  investmentId: string | undefined;
  advisors: Advisor[];
  allocations: any[];
  total: number;
  refetch: ({}: any) => void;
  refetchAllocationHistoryQuery: ({}: any) => void;
  updateAllocations: ({}: any) => void;
  updateSort: (tab: string, sort: string, order: string) => void;
  loading: boolean;
  updatePagination: (tab: string, pagination: any) => void;
}

const groupItems = [
  'No Entity Selected',
  'Selection Hover',
  'Hendricks Family Trust',
  'Hendricks Real Esate',
  'Hendricks Materials Fund, LLC',
  'Hendricks Transporations Assets, LLC'
];

export enum OPERATIONS_STATUS {
  ALL_SUITABILITY = 'All Statuses',
  READY_FOR_CLIENT_REVIEW = 'Ready for client review',
  SENT_TO_CLIENT = 'Sent to client',
  CLIENT_APPROVED = 'Client approved',
  SUBDOC_READY_FOR_REVIEW = 'Subdoc ready for review',
  SUBDOC_READY_FOR_CLIENT = 'Subdoc ready for client',
  SUBDOC_SENT_TO_CLIENT = 'Subdoc sent to client',
  SUBDOC_SIGNED_BY_CLIENT = 'Subdoc signed by client',
  SUBDOC_SENT_TO_FUND_MANAGER = 'Subdoc sent to fund manager',
  SUBDOC_CONFIRMED_BY_FUND_MANAGER = 'Subdoc confirmed by fund manager',
  COMMITMENT_SUBMITTED_TO_REPORTING_SYSTEM = 'Commitment submitted to reporting system',
  COMMITMENT_RECORDED_IN_REPORTING_SYSTEM = 'Commitment recorded in reporting system',
  TERMINATED = 'Terminated'
}

const Proposed: React.FC<ProposedAllocationProps> = ({
  advisors,
  allocations,
  total,
  refetch,
  refetchAllocationHistoryQuery,
  updateAllocations,
  updatePagination,
  updateSort,
  loading
}) => {
  const statusLists = Object.values(OPERATIONS_STATUS);
  const advisorLists = [
    'All Advisors',
    ...advisors.map((advisor) => advisor.initials)
  ];
  const [tablesRows, setTablesRows] = useState<ITable[]>([]);
  const [changedTablesRows, setChangedTablesRows] = useState<ITable[]>([]);
  const [selectors, setSelectors] = React.useState<ISelectors>(
    initialSelectorsOptions
  );
  const [modalWindowOptions, setModalWindowOptions] =
    useState<IModalWindowValues>(initialModalWindowValuesOptions);
  const [pagination, setPagination] = useState<IPagination>({
    total,
    rowsPerPageOptions: [50, 100, 200],
    page: 1,
    perPage: 50
  });
  const [sort, setSort] = useState({
    key: 'entity',
    order: 'ASC'
  });
  const [currentRow, setCurrentRow] = useState('');
  const [isConfirm, setIsConfirm] = useState(false);

  const setConfirmTrue = () => {
    setIsConfirm(true);
  };

  const setConfirmFalse = () => {
    setIsConfirm(false);
  };
  useEffect(() => {
    return () =>
      refetch({
        advisorFilter: 'All Advisors',
        lastStatusFilter: null
      });
  }, []);
  useEffect(() => {
    const newPotentialAllocations = allocations.map((row: ITable) => ({
      ...row,
      updateStatus: {
        isOpen: false,
        activeIndex: statusInvestments.findIndex(
          ({ status }) => row.operationsStatus === status
        )
      },
      clientApprovalDate: row.clientApprovedAt
        ? new Date(row.clientApprovedAt)
        : '',
      lastStatusDate: new Date(row.lastUpdatedAt),
      ...(row.legalEntity.isGroupEntity && {
        groupedValue: row.legalEntity.entities[0].entityName
      })
    }));
    const changeRowsWithState = newPotentialAllocations.map((allocation) => {
      const isExistCurrentRowWithState = changedTablesRows.find(
        ({ legalEntity }) => allocation.legalEntity.id === legalEntity.id
      );
      return isExistCurrentRowWithState
        ? isExistCurrentRowWithState
        : allocation;
    });
    setTablesRows(changeRowsWithState as any);
  }, [allocations]);

  useEffect(() => {
    setPagination({
      ...pagination,
      total
    });
  }, [total]);

  useEffect(() => {
    setPagination({ ...pagination });
    updatePagination('outstanding', {
      page: pagination.page,
      perPage: pagination.perPage
    });

    refetch({
      page: pagination.page,
      rowsPerPage: pagination.perPage
    });
  }, [pagination.perPage, pagination.page]);

  const handlePageChange = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    page: number
  ) =>
    setPagination({
      ...pagination,
      page: page + 1
    });
  const handlePerPageChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) =>
    setPagination({
      ...pagination,
      page: 1,
      perPage: parseInt(event.target.value)
    });
  const changeSelector = (field: keyof ISelectors) => (value: string) => {
    refetch({
      advisorFilter: field === 'advisors' ? value : selectors.advisors,
      lastStatusFilter:
        field === 'status'
          ? value === statusLists[0]
            ? null
            : value
          : selectors.status === statusLists[0]
            ? null
            : selectors.status
    });

    setSelectors({ ...selectors, [field]: value });
  };
  const changeFieldInModalWindowOptions =
    (field: string) => (value: string | boolean | number | ITypeModalWindow) =>
      setModalWindowOptions((prevState) => ({ ...prevState, [field]: value }));
  const sortData = (key: string) => {
    const reverseOrder = sort.order === 'ASC' ? 'DESC' : 'ASC';
    const order = sort.key === key ? reverseOrder : 'DESC';
    updateSort('outstanding', key, order);
    setSort({
      key,
      order
    });
  };

  const terminateAllocation = (id: string, reason: string, note: string) => {
    updateAllocations({
      variables: {
        data: [
          {
            id,
            operationsStatus: 'Terminate Proposal',
            allocationStatus: 'Terminated',
            terminationReason: reason,
            terminationNote: note
          }
        ]
      }
    });
    refetchAllocationHistoryQuery({
      advisorFilter: 'All Advisors',
      suitabilityFilter: null
    });
  };

  const changeDropDownOptions = (id: string) => {
    const currentRowInTable = tablesRows.find((row) => row.id === id) as ITable;
    updateAllocations({
      variables: {
        data: [
          {
            id,
            committedCapital: currentRowInTable.committedCapital,
            allocationStatus: 'Committed'
          }
        ]
      }
    });
  };

  return (
    <>
      <ModalWindow
        isOpen={modalWindowOptions.isOpen}
        closeModalWindow={() =>
          changeFieldInModalWindowOptions('isOpen')(false)
        }
      >
        <ModalWindowBody>
          <ModalWindowLogo src="/static/logo/JFGLogoOnly.svg" alt="Logo" />
          {modalWindowOptions.type === 'terminate-proposal' && (
            <TerminateProposalModalWindow
              changeField={changeFieldInModalWindowOptions}
              state={modalWindowOptions}
              terminateAllocation={terminateAllocation}
            />
          )}
          {modalWindowOptions.type === 'confirmation' && (
            <Confirmation changeField={changeFieldInModalWindowOptions} />
          )}
          {modalWindowOptions.type === 'complete' && (
            <Complete
              setConfirmTrue={setConfirmTrue}
              setConfirmFalse={setConfirmFalse}
              changeDropDownOptions={() => changeDropDownOptions(currentRow)}
              changeField={changeFieldInModalWindowOptions}
            />
          )}
        </ModalWindowBody>
      </ModalWindow>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          pt: '50px',
          pb: '10px'
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', columnGap: 1.25 }}>
          <Selector
            items={statusLists}
            currentItem={selectors.status}
            handleClick={changeSelector('status')}
          />
          <Selector
            items={advisorLists}
            currentItem={selectors.advisors}
            handleClick={changeSelector('advisors')}
          />
        </Box>
      </Box>
      <Table sx={{ borderSpacing: '0 0.625rem', borderCollapse: 'separate' }}>
        <TableHead>
          <HeaderRow>
            {header.map(({ width, justifyContent, column, key, order }) => (
              <CustomizeHeaderCell key={column} width={width}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent,
                    alignItems: 'center',
                    textAlign:
                      justifyContent === 'flex-start' ? 'left' : 'right'
                  }}
                >
                  {column}
                  {order && (
                    <GreenCircle
                      active={key === sort.key}
                      onClick={() => sortData(key)}
                    >
                      <CustomTriangle
                        active={key === sort.key}
                        isSideTop={sort.order === 'ASC'}
                        side={
                          key === sort.key
                            ? sort.order === 'ASC'
                              ? 'top'
                              : 'bottom'
                            : 'top'
                        }
                      />
                    </GreenCircle>
                  )}
                </Box>
              </CustomizeHeaderCell>
            ))}
          </HeaderRow>
        </TableHead>
        <TableBody>
          <OpportunitiesList
            isConfirm={isConfirm}
            setCurrentRow={setCurrentRow}
            updateAllocations={updateAllocations}
            tablesRows={tablesRows}
            setTablesRows={setTablesRows}
            changedTablesRows={changedTablesRows}
            setChangedTablesRows={setChangedTablesRows}
            selectors={selectors}
            changeFieldInModalWindowOptions={changeFieldInModalWindowOptions}
            page={pagination.page}
            perPage={pagination.perPage}
            loading={loading}
          />
        </TableBody>
        <TableFooter>
          <TableRow>
            <CustomTablePagination
              count={pagination.total}
              rowsPerPageOptions={pagination.rowsPerPageOptions}
              rowsPerPage={pagination.perPage}
              page={pagination.page - 1}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handlePerPageChange}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </>
  );
};
interface OpportunitiesListProps {
  isConfirm: boolean;
  tablesRows: ITable[];
  setTablesRows: Dispatch<SetStateAction<ITable[]>>;
  changedTablesRows: ITable[];
  setChangedTablesRows: Dispatch<SetStateAction<ITable[]>>;
  selectors: ISelectors;
  changeFieldInModalWindowOptions: (
    field: string
  ) => (value: string | boolean | number | ITypeModalWindow) => void;
  page: number;
  perPage: number;
  updateAllocations: ({}: any) => void;
  setCurrentRow: (value: string) => void;
  loading: boolean;
}

const OpportunitiesList: React.FC<OpportunitiesListProps> = ({
  tablesRows,
  setTablesRows,
  changedTablesRows,
  setChangedTablesRows,
  selectors,
  changeFieldInModalWindowOptions,
  page,
  perPage,
  updateAllocations,
  setCurrentRow,
  isConfirm,
  loading
}) => {
  // const { classes } = useStyles();
  const theme = useTheme();
  const actionsOnItem = {
    Committed: (id: string) => {
      changeFieldInModalWindowOptions('isOpen')(true);
      changeFieldInModalWindowOptions('currentRowId')(id);
      changeFieldInModalWindowOptions('type')('complete');
    },
    'Terminate Proposal': (id: string) => {
      changeFieldInModalWindowOptions('type')('terminate-proposal');
      changeFieldInModalWindowOptions('isOpen')(true);
      changeFieldInModalWindowOptions('currentRowId')(id);
    }
  };

  const changeCellInTable =
    (field: string, id: string) => (value: string | boolean | number) => {
      const currentRow = tablesRows.find((row) => row.id === id) as ITable;
      if (currentRow[field as keyof typeof currentRow] === value) return;
      const updatedCurrentRow = {
        ...currentRow,
        [field]: value
      };
      setTablesRows(
        tablesRows.map((row) => (row.id === id ? updatedCurrentRow : row))
      );
      const isAllocationChanged = changedTablesRows.some(
        (row) => row.id === updatedCurrentRow.id
      );
      setChangedTablesRows(
        isAllocationChanged
          ? changedTablesRows.map((row) =>
              row.id === updatedCurrentRow.id ? updatedCurrentRow : row
            )
          : [...changedTablesRows, updatedCurrentRow]
      );

      updateAllocations({
        variables: {
          data: [{ id: currentRow.id, [field]: value }]
        }
      });
    };

  const changeDropDownOptions =
    (id: string) => (bool: boolean, activeIndex: number, date?: Date) => {
      setCurrentRow(id);
      const newTablesRows = tablesRows.map((data) => ({
        ...data,
        clientApprovalDate:
          data.id === id &&
          statusInvestments[activeIndex].status === 'Client approved'
            ? date ?? data.clientApprovalDate
            : data.clientApprovalDate,
        updateStatus: {
          ...data.updateStatus,
          isOpen: data.id === id ? bool : false
        },
        operationsStatus: statusInvestments[activeIndex].status
      }));
      setTablesRows(newTablesRows);
      // Check should send request or not
      const currentRow = tablesRows.find((row) => row.id === id) as ITable;
      if (
        currentRow.id === id &&
        currentRow.updateStatus.activeIndex === activeIndex
      )
        return;
      if (
        statusInvestments[activeIndex].status === 'Terminate Proposal' ||
        statusInvestments[activeIndex].status === 'Committed'
      )
        return;
      const operationsStatus = statusInvestments[activeIndex].status;
      updateAllocations({
        variables: {
          data: [
            {
              id,
              committedCapital: currentRow.committedCapital,
              ...(operationsStatus === 'Client approved' && {
                clientApprovedAt: new Date()
              }),
              ...(operationsStatus !== 'Committed' && { operationsStatus }),
              ...(operationsStatus === 'Committed' &&
                isConfirm && { allocationStatus: 'Committed' })
            }
          ]
        }
      });
    };

  const convertDate = (date: Date) =>
    date.toLocaleDateString('en-US', {
      year: '2-digit',
      month: '2-digit',
      day: '2-digit'
    });
  const updateGroupedValue = (id: any, value: string) => {
    setTablesRows(
      tablesRows.map((row) =>
        row.legalEntity.id === id ? { ...row, groupedValue: value } : row
      )
    );
  };
  const findEntities = (row: ITable) => {
    const entities = tablesRows
      .filter(
        (item) =>
          item.legalEntity.family.name === row.legalEntity.family.name &&
          item.legalEntity.grouped
      )
      .map((item) => item.legalEntity.entityName);
    return [...new Set(entities)];
  };
  if (loading) {
    return (
      <BodyRow cursor={'default'}>
        <BodyCell colSpan={8}>
          <Loader />
        </BodyCell>
      </BodyRow>
    );
  }
  if (!tablesRows.length) {
    return (
      <BodyRow cursor={'default'}>
        <BodyCell colSpan={6}>
          <BodyCellInner>
            <ContainerWarning>
              <Typography>0 Results</Typography>
            </ContainerWarning>
          </BodyCellInner>
        </BodyCell>
      </BodyRow>
    );
  }

  return (
    <>
      {tablesRows.map((row) => (
        <BodyRow cursor={'default'} key={row.id}>
          <BodyCell width={'12%'}>
            <BodyCellInner>
              <Grouped>
                <Box sx={{ display: 'flex', columnGap: 1.25 }}>
                  {row.legalEntity.grouped && (
                    <Tooltip
                      title={'Entity Group'}
                      arrow
                      // className={classes.tooltipGroup}
                    >
                      <img src="/static/icons/group.svg" alt="group" />
                    </Tooltip>
                  )}
                  <Box sx={{ display: 'flex', columnGap: 1 }}>
                    <Typography sx={{ lineHeight: 1 }}>
                      {row.legalEntity.entityName}
                    </Typography>
                  </Box>
                </Box>
                {row.legalEntity.isGroupEntity && (
                  <Selector
                    // classNames={{
                    //   select: classes.groupSelectorSelect,
                    //   items: classes.groupSelectorItems
                    // }}
                    items={[
                      'No Entity Selected',
                      ...row.legalEntity.entities.map(
                        (entity) => entity.entityName
                      )
                    ]}
                    currentItem={
                      row.selectedGroupLegalEntity?.entityName ||
                      'No Entity Selected'
                    }
                    handleClick={(value) =>
                      updateGroupedValue(row.legalEntity, value)
                    }
                    inputProps={{
                      MenuProps: {
                        MenuListProps: {
                          sx: {
                            backgroundColor: theme.primary.dark,
                            border: `1px solid ${theme.secondary.light}`,
                            borderRadius: 1
                          }
                        }
                      }
                    }}
                  />
                )}
              </Grouped>
            </BodyCellInner>
          </BodyCell>
          <BodyCell width={'10%'}>
            <BodyCellInner>{row.legalEntity.family.name}</BodyCellInner>
          </BodyCell>
          <BodyCell width={'8%'}>
            <BodyCellInner>{row.legalEntity.advisor.initials}</BodyCellInner>
          </BodyCell>
          <BodyCell width={'10%'}>
            <BodyCellInner>
              <InputWithSigns
                value={row.committedCapital}
                changeField={changeCellInTable('committedCapital', row.id)}
                disabled={
                  !listOfStatusWhenAllowToEditAmount.includes(
                    statusInvestments[row.updateStatus.activeIndex].status
                  )
                }
              />
            </BodyCellInner>
          </BodyCell>
          <BodyCell width={'10%'} align={'right'}>
            <BodyCellInner>
              {row.clientApprovalDate
                ? convertDate(row.clientApprovalDate as Date)
                : ''}
            </BodyCellInner>
          </BodyCell>
          <BodyCell width={'14%'} align={'right'}>
            <BodyCellInner>{convertDate(row.lastStatusDate)}</BodyCellInner>
          </BodyCell>
          <BodyCell width={'14%'}>
            <BodyCellInner>
              {statusInvestments[row.updateStatus.activeIndex].status}
            </BodyCellInner>
          </BodyCell>
          <BodyCell width={'20%'}>
            <BodyCellInner>Hard Code</BodyCellInner>
          </BodyCell>
        </BodyRow>
      ))}
    </>
  );
};

export default Proposed;

const CustomizeHeaderCell = styled(HeaderCell)`
  background: '#2a2e3f !important',
  color: ' #f0f0f0 !important'
`;
const ModalWindowBody = styled(Box)`
  width: 100%;
  padding: 70px 60px 30px;
  display: flex;
  justify-content: center;
  flex-direction: column;
`;
const ModalWindowLogo = styled('img')`
  margin: 8px auto 0;
`;
const ContainerWarning = styled(Box)`
  width: 100%;
  display: flex;
  align-items: center;
`;
const CustomTablePagination = styled(TablePagination)`
  .MuiTablePagination-select {
    background-color: ${(props) => props.theme.primary.dark};
    border: 1px solid ${(props) => props.theme.secondary.light};
    border-radius: 0.4em; // or '0.4rem' if using relative units
  }

  .MuiTablePagination-selectLabel {
    color: ${(props) => props.theme.font.base};
    font-weight: 400;
    font-size: 16px;
  }

  .MuiTablePagination-displayedRows {
    color: ${(props) => props.theme.font.base};
    font-weight: 400;
    font-size: 16px;
  }

  .MuiSelect-icon {
    color: ${(props) => props.theme.font.action};
    transform: scale(1.2);
    border-radius: 5px;
  }
`;
const Grouped = styled(Box)(() => ({
  display: 'flex',
  columnGap: 5,
  flexDirection: 'column'
}));

const groupSelectorSelect = styled.div`
  color: ${(props) => props.theme.warning.main};
  border: none;
  margin: 0 0 0 29px;
  background: transparent;

  .MuiSelect-select {
    padding: 0;
  }

  .MuiSelect-icon {
    color: ${(props) => props.theme.warning.main};
  }

  .Mui-disabled {
    & .MuiSelect-icon {
      color: ${(props) => props.theme.secondary.main};
    }
  }
`;

const groupSelectorItems = styled.div`
  color: ${(props) => props.theme.font.action};
  background-color: ${(props) => props.theme.primary.dark};

  .MuiList-root {
    background: red;
  }

  &:hover {
    background-color: ${(props) => props.theme.font.action};
    color: ${(props) => props.theme.primary.main};
  }

  &.Mui-selected {
    color: ${(props) => props.theme.font.action};
    width: 100%;
    margin: 0 auto;
    border-radius: 0;

    &:hover {
      background-color: ${(props) => props.theme.font.action};
      color: ${(props) => props.theme.primary.main};
    }
  }
`;
