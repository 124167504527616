import { ArrowDown } from '@/assets/icons/info-icons/arrowDown';
import { DocumentIcon } from '@/assets/static/icons/document';
import { OpenFileIcon } from '@/assets/static/icons/open-file';
import { ArrowUp } from '@/assets/static/icons/todo-page/arrow-up';
import { isImageExtension } from '@/components/dueDiligence-page/dueDiligenceRecord/utils';
import { useResponsive } from '@/hooks/use-responsive';
import { headingsPlugin, linkDialogPlugin, linkPlugin, listsPlugin, MDXEditor, MDXEditorMethods } from '@mdxeditor/editor';
import '@mdxeditor/editor/style.css';
import { MutableRefObject, useEffect, useRef, useState } from 'react';
import styled, { useTheme } from 'styled-components';

interface OfferingMaterialsProps {
  description: string;
  attachments: any;
}

export const OfferingMaterials = ({ description, attachments }: OfferingMaterialsProps) => {
  const { isMobile } = useResponsive();
  const theme = useTheme();

  const markdownEditorRef = useRef<MDXEditorMethods>(null);
  const containerRef: MutableRefObject<HTMLDivElement | null> = useRef(null);

  const [isDropdown, setIsDropdown] = useState(true);

  useEffect(() => {
    if (markdownEditorRef?.current && !markdownEditorRef.current.getMarkdown()) {
      markdownEditorRef.current.setMarkdown(description);
    }
  }, [description]);

  useEffect(() => {
    if (containerRef.current) {
      const links: NodeListOf<HTMLAnchorElement> = containerRef.current.querySelectorAll('a');
      links.forEach((link: HTMLAnchorElement) => {
        link.setAttribute('target', '_blank');
      });
    }
  }, [description]);

  return (
    <DropdownWrapper>
      <DropdownHeader isMobile={isMobile} isDropdown={isDropdown}>
        <HeaderInfo>
          <Title isMobile={isMobile}>Offering Materials</Title>
        </HeaderInfo>
        <ArrowScoreWrapper>
          <ArrowWrapper onClick={() => setIsDropdown(!isDropdown)}>
            <>
              {isDropdown ? (
                <ArrowUp style={{ cursor: 'pointer' }} fill={theme.action.primary} />
              ) : (
                <ArrowDown style={{ width: '28px', height: '28px', padding: '7px' }} fill={theme.action.primary} />
              )}
            </>
          </ArrowWrapper>
        </ArrowScoreWrapper>
      </DropdownHeader>
      <DropdownBody isMobile={isMobile} isDropdown={isDropdown}>
        <ExpandableWrapp isMobile={isMobile} isDropdown={isDropdown}>
          <Description isMobile={isMobile} ref={containerRef}>
            <CustomMDXEditor
              markdown={description}
              ref={markdownEditorRef}
              readOnly
              plugins={[listsPlugin(), headingsPlugin(), linkPlugin(), linkDialogPlugin()]}
            />
          </Description>
          <PreviewWrapper>
            {attachments.map((item: any, index: number) => (
              <PreviewItemWrap key={item.asset.assetKey + index}>
                <PreviewItem>
                  <DocumentIcon width="25" height="24" fill={theme.font.base} />
                  {isImageExtension(item.asset.assetKey) ? (
                    <ImageWrapper href={item.asset.url} target="_blank">
                      <ImageItem src={item.asset.url} alt={item.asset.assetKey.split('/').pop()} />
                    </ImageWrapper>
                  ) : (
                    <FileWrapper>
                      <FileItem href={item.asset.url} target="_blank">
                        {item.asset.assetKey.split('/').pop()}
                      </FileItem>
                    </FileWrapper>
                  )}
                  <ControlButtons>
                    <OpenButton href={item.asset.url} target="_blank">
                      <OpenFileIcon fill={theme.action.primary} />
                    </OpenButton>
                  </ControlButtons>
                </PreviewItem>
              </PreviewItemWrap>
            ))}
          </PreviewWrapper>
        </ExpandableWrapp>
      </DropdownBody>
    </DropdownWrapper>
  );
};

const DropdownWrapper = styled.div`
  border: 1px solid ${({ theme }) => theme.border.base};
  border-radius: 10px;
`;

const DropdownHeader = styled.div<{ isMobile: boolean; isDropdown: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${({ isMobile }) => (isMobile ? '15px 20px' : '20px 40px')};
  position: relative;
  border-radius: 12px 12px 0 0;

  &:hover {
    /* background-color: ${({ theme }) => theme.layer[1]}; */
    transition: background-color 100ms ease;
    border-radius: ${({ isDropdown }) => (isDropdown ? '12px 12px 0 0' : '12px')};
  }
`;

const ArrowWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border-radius: 25px;
  cursor: pointer;
`;

const Title = styled.div<{ isMobile: boolean }>`
  font-family: Lato;
  font-weight: 700;
  font-size: ${({ isMobile }) => (isMobile ? '20px' : '28px')};
  line-height: ${({ isMobile }) => (isMobile ? '30px' : '22px')};
  color: ${({ theme }) => theme.font.strong};
`;

const HeaderInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const DropdownBody = styled.div<{ isMobile: boolean; isDropdown: boolean }>`
  background-color: ${({ theme }) => theme.layer[1]};
  border-radius: 0 0 10px 10px;
  border-top: ${({ isDropdown, theme }) => (isDropdown ? `1px solid ${theme.border.base}` : 'none')};
  opacity: ${({ isDropdown }) => (isDropdown ? '1' : '0')};
  display: grid;
  grid-template-rows: ${({ isDropdown }) => (isDropdown ? '1fr' : '0fr')};
  overflow: ${({ isDropdown }) => (isDropdown ? 'visible' : 'hidden')};
  transition: grid-template-rows 300ms;
`;

const ExpandableWrapp = styled.div<{ isMobile: boolean; isDropdown: boolean }>`
  min-height: 0;
  padding: ${({ isMobile, isDropdown }) => (isMobile && isDropdown ? '20px' : isDropdown ? '40px' : 0)};
`;

const ArrowScoreWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 30px;
  width: auto;
  position: relative;
`;

const Description = styled.div<{ isMobile: boolean }>`
  padding: ${({ isMobile }) => (isMobile ? '0' : '20px')};
  color: ${({ theme }) => theme.font.base};
`;

const CustomMDXEditor = styled(MDXEditor)`
  .mdxeditor-root-contenteditable ul,
  .mdxeditor-root-contenteditable ol {
    padding-left: 26px;
  }
`;

const PreviewWrapper = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-height: 200px;
  overflow: auto;
`;

const PreviewItemWrap = styled.div`
  width: 100%;
  height: 100%;
  padding: 10px 20px;
  border: 1px solid ${({ theme }) => theme.context.success};
  border-radius: 5px;
  background-color: ${({ theme }) => theme.layer[1]};
`;

const PreviewItem = styled.div`
  border-left: 5px solid ${({ theme }) => theme.context.success};
  padding-left: 7.5px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 7.5px;
`;

const ControlButtons = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
`;

const OpenButton = styled.a`
  border: 1px solid ${({ theme }) => theme.action.primary};
  border-radius: 20px;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const ImageWrapper = styled.a`
  display: block;
  width: 163px;
  height: 100px;
  overflow: hidden;
`;

const ImageItem = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const FileWrapper = styled.div``;

const FileItem = styled.a`
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  text-decoration: none;
  color: inherit;
  cursor: pointer;
  color: ${({ theme }) => theme.font.base};
`;
