import { getUTCDate } from '@/components/allocations-page/utils';
import FormattingTooltip from '@/components/basicComponents/tooltip/dollarsTooltip';
import { IInvestmentWorkflowTransitionTask, Investment } from '@/components/fat-dealCatalog-page/types';
import NextStep from '@/components/fat-pendingInvestments-page/fat-table/cell/nextStep';
import DropdownActions from '@/components/table/fat-dropdownActions';
import NoResults from '@/components/table/noResults';
import RedirectTitleCell from '@/components/table/redirectTitleCell';
import { TableRowCell, TableRowWrapper, TextCell } from '@/components/table/styles';
import { IItems } from '@/components/table/types';
import guid from '@/utils/guid';
import styled from 'styled-components';
import { columns } from '../constatnts';
import LastUpdate from './fat-cell/lastUpdate';

interface TableBodyProps {
  tableRows: Investment[];
  actionsByStatus: (row: Investment) => IItems[];
  statuses: IInvestmentWorkflowTransitionTask[];
  openModifyInvestment: (id: string) => void;
  updateLoading: boolean;
  currentRow: Investment | null;
}

const TableBody = ({ tableRows, actionsByStatus, statuses, openModifyInvestment, updateLoading, currentRow }: TableBodyProps) => {
  if (tableRows.length === 0) {
    return <NoResults />;
  }

  return (
    <>
      {tableRows.map((row) => (
        <TableRowWrapper key={row.id} loading={updateLoading && row.id === currentRow?.id ? 1 : 0}>
          <CustomTableRowCell width={columns[0].width}>
            <RedirectTitleCell id="actionCurioText" onClick={() => openModifyInvestment(row.id)}>
              {row.name}
            </RedirectTitleCell>
            {actionsByStatus(row).length ? (
              <DropdownActionsWrap>
                <DropdownActions items={actionsByStatus(row)} />
              </DropdownActionsWrap>
            ) : (
              <></>
            )}
          </CustomTableRowCell>
          <TableRowCell width={columns[1].width}>
            <TextCell>{row.managerId}</TextCell>
          </TableRowCell>
          <TableRowCell width={columns[2].width}>
            <TextCell>
              {row.aggregateInterest && (
                <FormattingTooltip zIndex={1000} key={guid()}>
                  {row.aggregateInterest}
                </FormattingTooltip>
              )}
            </TextCell>
          </TableRowCell>
          <TableRowCell width={columns[3].width}>
            <TextCell>
              {row.aggregateCommitment && (
                <FormattingTooltip zIndex={1000} key={guid()}>
                  {row.aggregateCommitment}
                </FormattingTooltip>
              )}
            </TextCell>
          </TableRowCell>
          <TableRowCell width={columns[4].width}>
            <TextCell>{row.finalClose && getUTCDate(new Date(row.finalClose))}</TextCell>
          </TableRowCell>
          <TableRowCell width={columns[5].width}>
            <TextCell>
              {row.assignee && `${row.assignee.firstName && row.assignee?.firstName} ${row.assignee?.lastName && row.assignee.lastName}`}
            </TextCell>
          </TableRowCell>
          <TableRowCell width={columns[6].width}>
            <LastUpdate row={row} statuses={statuses} initialDate={row.updatedAt} updatedAt={row.updatedAt} />
          </TableRowCell>
          <TableRowCell width={columns[7].width}>
            <TextCell>
              {row.investmentWorkflowTransitionTask && (
                <NextStep status={row.securityStatus === 'Terminated' ? 'Terminated' : row.investmentWorkflowTransitionTask.name} />
              )}
            </TextCell>
          </TableRowCell>
        </TableRowWrapper>
      ))}
    </>
  );
};

export default TableBody;

const CustomTableRowCell = styled(TableRowCell)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const DropdownActionsWrap = styled.div`
  margin-right: 10px;
`;
