import * as React from 'react';

export const CloseInfoIcon = ({ ...props }: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={props.width || 15}
      height={props.height || 14}
      style={props?.style}
      onClick={props?.onClick}
      viewBox="0 0 15 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.5 1.4102L13.0898 0L7.50299 5.5868L1.91021 0L0.5 1.4102L6.09281 6.997L0.5 12.5898L1.91021 14L7.50299 8.40721L13.0898 14L14.5 12.5898L8.9132 6.997L14.5 1.4102Z"
        fill={props.fill || '#4587EC'}
      />
    </svg>
  );
};
