import { EditTodoIcon } from '@/assets/icons/info-icons/editTodo';
import { AddButtonIcon } from '@/assets/static/icons/add-button-icon';
import Button from '@/components/fat-basicComponents/button';
import { useResponsive } from '@/hooks/use-responsive';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';
import { SectionZeroStateText, SectionZeroStateWrapper } from '..';
import { Section } from '../../edit-entity/section';

const ITEMS = [
  'Legal Entity Name To Be Used On Subdocs',
  'Individual Street Address',
  'City',
  'State',
  'Zip',
  'Business Phone',
  'Home Phone',
  'Email',
  'SSN',
  'Date Of Birth',
  'Country Of Citizenship',
  'Correspondence Address'
];

interface GeneralInformationSectionProps {
  saveScrollPostion: () => void;
}

export const GeneralInformationSection = ({ saveScrollPostion }: GeneralInformationSectionProps) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { isMobile } = useResponsive();

  const [showSectionZeroState, setShowSectionZeroState] = useState(false);

  const openGeneralInformationFormPage = () => {
    saveScrollPostion();
    navigate('generalInformation?backToTitle=Investor Documentation');
  };

  if (showSectionZeroState) {
    return (
      <Section title="General Information">
        <SectionZeroStateWrapper>
          <SectionZeroStateText>No information has been added yet.</SectionZeroStateText>
          <Button
            onClick={openGeneralInformationFormPage}
            styleType="outline"
            icon={<AddButtonIcon fill={theme.font.action} />}
            size="md"
            width="max-content"
          >
            Add Information
          </Button>
        </SectionZeroStateWrapper>
      </Section>
    );
  }

  return (
    <Section title="General Information">
      <GeneralInformationWrapper isMobile={isMobile}>
        <GeneralInformationItemsWrapper>
          {ITEMS.map((item) => (
            <TwoColumnWrapper key={item} isMobile={isMobile}>
              <GeneralInformationItem>{item}</GeneralInformationItem>
              <GeneralInformationItem isBold>MOCKED DATA</GeneralInformationItem>
            </TwoColumnWrapper>
          ))}
        </GeneralInformationItemsWrapper>
        <EditIconWrapper onClick={openGeneralInformationFormPage} isMobile={isMobile} id="editButton">
          <EditTodoIcon />
        </EditIconWrapper>
      </GeneralInformationWrapper>
    </Section>
  );
};

const GeneralInformationWrapper = styled.div<{ isMobile: boolean }>`
  display: flex;
  align-items: center;
  gap: 15px;
  flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};
  padding: ${({ isMobile }) => (isMobile ? '0' : '20px')};
  transition: 0.3s ease-in-out;

  @media (hover: hover) {
    &:hover #editButton {
      opacity: 1;
    }

    &:hover {
      background-color: ${({ theme }) => theme.layer[12]};
    }
  }
`;

const GeneralInformationItemsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const TwoColumnWrapper = styled.div<{ isMobile: boolean }>`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  width: ${({ isMobile }) => (isMobile ? '100%' : '60%')};
  padding: 2px 10px;
`;

const GeneralInformationItem = styled.div<{ isBold?: boolean }>`
  font-family: Blinker;
  font-size: 16px;
  font-weight: ${({ isBold }) => (isBold ? 600 : 400)};
  line-height: 22.4px;
  color: ${({ theme }) => theme.font.base};
  padding: 4px;
`;

const EditIconWrapper = styled.div<{ isMobile: boolean }>`
  width: ${({ isMobile }) => (isMobile ? '100%' : 'max-content')};
  padding: 12px;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.action.default};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  cursor: pointer;
  opacity: ${({ isMobile }) => (isMobile ? '1' : '0')};
  transition: 0.3s ease-in-out;
`;
