import Label from '@/components/basicComponents/label';
import FilterSelect from '@/components/basicComponents/select';
import Button from '@/components/fat-basicComponents/button';
import styled from 'styled-components';
import { DescriptionSection } from '../../../descriptionSection';
import { ProgressBar } from '../../../progressBar';
import { ButtonsControl, ImportWrapper } from '../../../styles';
import { IDescriptionSection } from '../../../types';
import { investorEntitiesSteps } from '../../constants';

interface GettingStartedProps {
  importSteps: typeof investorEntitiesSteps;
  handleClose: () => void;
  nextStep: () => void;
  descriptionSection: IDescriptionSection;
  selectedAdvisoryFirm: string;
  advisoryFirmList: string[];
  changeSelectedAdvisoryFirm: (advisoryFirm: string) => void;
}

export const GettingStarted = ({
  importSteps,
  handleClose,
  nextStep,
  descriptionSection,
  selectedAdvisoryFirm,
  advisoryFirmList,
  changeSelectedAdvisoryFirm
}: GettingStartedProps) => {
  return (
    <ImportWrapper>
      <ProgressBar importSteps={importSteps} />
      <SectionsWrapper>
        <DescriptionSection {...descriptionSection} />
      </SectionsWrapper>
      <SelectText>Select options to get started</SelectText>
      <SelectsWrapper>
        <SelectWrapper>
          <Label
            fontSize="16px"
            paddingBottom="0"
            tooltip="Assign all newly created Investor Entities to the selected Advisory Firm.  Note that the import file must contain only investors that belong to the selected firm, otherwise duplicate records may be created. "
            tooltipWidth={400}
            required
          >
            Advisory Firm
          </Label>
          <AdvisoryFirmSelect
            selected={selectedAdvisoryFirm}
            setSelected={changeSelectedAdvisoryFirm}
            data={advisoryFirmList}
            width="100%"
            withSearch
          />
        </SelectWrapper>
        <SelectWrapper>
          <Label
            fontSize="16px"
            paddingBottom="0"
            tooltip="For imported Investor CRM IDs that match an existing Investor Entity, the imported values will overwrite those on the existing Investor Entity record.  New Investor Entities will be created for each unrecognized Investor CRM ID in the import file.  Existing Investor Entities that do not correspond to an Investor CRM ID listed in the Import File will be unchanged."
            tooltipWidth={400}
            required
          >
            Update Method
          </Label>
          <UpdateMethodSelect
            selected="Update using Investor CRM ID"
            setSelected={() => null as any}
            data={['Update using Investor CRM ID']}
            width="100%"
            disabled
          />
        </SelectWrapper>
      </SelectsWrapper>
      <ButtonsControl>
        <Button styleType="outline" size="lg" onClick={handleClose}>
          Cancel
        </Button>
        <Button size="lg" styleType={selectedAdvisoryFirm === 'Select' ? 'disabled' : 'default'} onClick={nextStep}>
          Continue
        </Button>
      </ButtonsControl>
    </ImportWrapper>
  );
};

const SectionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const SelectText = styled.p`
  text-align: center;
  padding: 50px 0 10px 0;
  color: ${({ theme }) => theme.font.weak};
  font-family: Blinker;
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;
`;
const AdvisoryFirmSelect = styled(FilterSelect)`
  border-radius: 4px;

  #titleSelect {
    font-family: Blinker;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 22.4px;
  }
`;

const UpdateMethodSelect = styled(FilterSelect)`
  #titleSelect {
    font-family: Blinker;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 22.4px;
  }
  border-radius: 4px;
  color: ${({ theme }) => theme.font.base};
  box-shadow: none;
  background: ${({ theme }) => theme.layer[2]};
`;

const SelectsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 25px;
`;

const SelectWrapper = styled.div`
  width: 70%;
`;
