import FormattingTooltip from '@/components/basicComponents/tooltip/dollarsTooltip';
import { IInvestmentEntity } from '@/components/fat-dealCatalog-page/fat-seekingInterest-page/types';
import { Loader } from '@/components/loaders/loader';
import {
  MobileRow,
  MobileRowTitle,
  MobileRowValue,
  MobileTableRowWrap,
  MobileTableWrapper,
  NoResults
} from '@/components/table/mobileTable/mobileTable';
import { format } from 'date-fns';
import { investorColumns } from '../../constatnts';

interface MobileTableInvestorProps {
  listInvestmentEntity: IInvestmentEntity[];
  loading: boolean;
}

const MobileTableInvestor = ({ listInvestmentEntity, loading }: MobileTableInvestorProps) => {
  if (loading) return <Loader />;

  const TableRow = ({ el }: { el: IInvestmentEntity }) => {
    return (
      <MobileTableRowWrap key={el.id}>
        <MobileRow>
          <MobileRowTitle>{investorColumns[0].title}</MobileRowTitle>
          <MobileRowValue>{el.entityName}</MobileRowValue>
        </MobileRow>

        <MobileRow>
          <MobileRowTitle>{investorColumns[1].title}</MobileRowTitle>
          <MobileRowValue>{el.family.name}</MobileRowValue>
        </MobileRow>

        <MobileRow>
          <MobileRowTitle>{investorColumns[2].title}</MobileRowTitle>
          <MobileRowValue>{el.tenant.name}</MobileRowValue>
        </MobileRow>

        <MobileRow>
          <MobileRowTitle>{investorColumns[3].title}</MobileRowTitle>
          <MobileRowValue>
            {!el.investor_interest[0]?.exempt && <FormattingTooltip zIndex={1000}>{el.investor_interest[0]?.amount}</FormattingTooltip>}
          </MobileRowValue>
        </MobileRow>

        <MobileRow>
          <MobileRowTitle>{investorColumns[4].title}</MobileRowTitle>
          <MobileRowValue>
            {el.investor_interest[0]?.exempt && <FormattingTooltip zIndex={1000}>{el.investor_interest[0]?.amount}</FormattingTooltip>}
          </MobileRowValue>
        </MobileRow>

        <MobileRow>
          <MobileRowTitle>{investorColumns[5].title}</MobileRowTitle>
          <MobileRowValue>
            <FormattingTooltip zIndex={1000}>{el.investor_interest[0]?.amount}</FormattingTooltip>
          </MobileRowValue>
        </MobileRow>

        <MobileRow>
          <MobileRowTitle>{investorColumns[6].title}</MobileRowTitle>
          <MobileRowValue>{el.investor_interest[0]?.updatedAt && format(new Date(el.investor_interest[0]?.updatedAt), 'LLL d, yyyy')}</MobileRowValue>
        </MobileRow>
      </MobileTableRowWrap>
    );
  };

  return (
    <MobileTableWrapper>
      {listInvestmentEntity.length ? (
        listInvestmentEntity.map((el, index) => {
          return <TableRow el={el} key={index} />;
        })
      ) : (
        <NoResults>No Results</NoResults>
      )}
    </MobileTableWrapper>
  );
};

export default MobileTableInvestor;
