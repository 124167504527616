import { useResponsive } from '@/hooks/use-responsive';
import styled from 'styled-components';
import { EntityInput } from '../input';
import { Section } from '../section';
import { IDataFields } from '../types';

interface EntityDetailsSectionProps {
  entityName: string;
  investorName: string;
  exportId: string;
  entityCRMId: string;
  changeProperties: (field: keyof IDataFields) => (value: string | number) => void;
}

export const EntityDetailsSection = ({ entityName, investorName, exportId, entityCRMId, changeProperties }: EntityDetailsSectionProps) => {
  const { isMobile } = useResponsive();

  return (
    <Section title="Entity Details">
      <EntityDetails isMobile={isMobile}>
        <EntityInput
          value={entityName}
          setValue={(value) => changeProperties('entityName')(value)}
          label="Entity Name"
          required
          tooltip="The entity name as it should appear on legal documents."
        />
        <EntityInput value={investorName} setValue={() => null} label="Investor" isInputDisabled />
      </EntityDetails>
      <EntityDetails isMobile={isMobile}>
        <EntityInput
          value={entityCRMId}
          setValue={(value) => changeProperties('entityCRMId')(value)}
          label="Entity CRM ID"
          tooltipWidth={500}
          tooltip="This identifier is used when bulk updating entities using the investor entity upload file or through an integration with an external CRM."
        />
        <EntityInput
          value={exportId}
          setValue={(value) => changeProperties('exportId')(value)}
          label="Account Number"
          tooltipWidth={500}
          tooltip="This identifier is used when you export reporting data for upload into your reporting system. If you do not provide an ID reporting data for this entity will not be included in the reporting data export."
        />
      </EntityDetails>
    </Section>
  );
};

const EntityDetails = styled.div<{ isMobile: boolean }>`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};
  gap: ${({ isMobile }) => (isMobile ? '15px' : '30px')};
`;
