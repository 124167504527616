import Label from '@/components/basicComponents/label';
import RadioButton from '@/components/basicComponents/radioButton';
import { useController } from 'react-hook-form';
import styled from 'styled-components';

interface ToggleCircleProps {
  label: string;
  tooltip?: string;
  tooltipWidth?: number;
  nameField: string;
  control: any;
}

const options = ['No', 'Yes'];

export const ToggleCircle = ({ label, tooltip, tooltipWidth, nameField, control }: ToggleCircleProps) => {
  const {
    field: { value, ...filedProperties }
  } = useController({ name: nameField, control });

  return (
    <ToggleWrap>
      <Label required={false} tooltip={tooltip} tooltipWidth={tooltipWidth}>
        {label}
      </Label>
      <RadioButtonWrap>
        {options.map((option, index) => (
          <RadioButton
            key={option + index}
            name={nameField}
            radioButtonText={option}
            selectedOption={value}
            handleRadioChange={(event) => filedProperties.onChange(event.target.value)}
          />
        ))}
      </RadioButtonWrap>
    </ToggleWrap>
  );
};

const ToggleWrap = styled.div`
  width: 100%;
`;

const RadioButtonWrap = styled.div`
  display: flex;
`;
