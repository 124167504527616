import { ArrowUp } from '@/assets/static/icons/todo-page/arrow-up';
import { useResponsive } from '@/hooks/use-responsive';
import { useEffect, useState } from 'react';
import styled, { useTheme } from 'styled-components';

interface SectionProps {
  title: string;
  children: React.ReactNode;
  collapsable?: boolean;
  openSection?: boolean;
  id?: string;
  saveSectionState?: (id: string, state: { isSectionOpen: boolean }) => void;
}

export const Section = ({ title, children, collapsable = false, openSection = true, id, saveSectionState }: SectionProps) => {
  const theme = useTheme();
  const { isMobile } = useResponsive();

  const [isSectionOpen, setIsSectionOpen] = useState(true);

  useEffect(() => {
    setIsSectionOpen(openSection);
  }, [openSection]);

  const handleCollapse = () => {
    if (!collapsable) return;
    setIsSectionOpen(!isSectionOpen);
    if (saveSectionState) {
      saveSectionState(id, { isSectionOpen: !isSectionOpen });
    }
  };

  return (
    <SectionWrap id={id}>
      <TitleWrapper collapsable={collapsable} onClick={handleCollapse}>
        <Title>{title}</Title>
        {collapsable && (
          <CollapseButtonWrapper>
            <ArrowUp fill={theme.font.action} style={{ transform: isSectionOpen ? 'rotate(0deg)' : 'rotate(180deg)' }} />
          </CollapseButtonWrapper>
        )}
      </TitleWrapper>
      <BodyContainer isSectionOpen={isSectionOpen}>
        <ExpandableWrapper isMobile={isMobile} isSectionOpen={isSectionOpen}>
          {children}
        </ExpandableWrapper>
      </BodyContainer>
    </SectionWrap>
  );
};

const SectionWrap = styled.div`
  background-color: ${({ theme }) => theme.layer.base};
  border: 1px solid ${({ theme }) => theme.border.base};
  border-radius: 10px;
`;

const TitleWrapper = styled.div<{ collapsable: boolean }>`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: transparent;
  padding: 17px 30px;
  cursor: ${({ collapsable }) => (collapsable ? 'pointer' : 'default')};
`;

const BodyContainer = styled.div<{ isSectionOpen: boolean }>`
  border-top: ${({ isSectionOpen, theme }) => (isSectionOpen ? `1px solid ${theme.border.base}` : 'none')};
  border-radius: 0px 0px 10px 10px;
  background-color: ${({ theme }) => theme.layer[1]};
  display: grid;
  grid-template-rows: ${({ isSectionOpen }) => (isSectionOpen ? '1fr' : '0fr')};
  overflow: ${({ isSectionOpen }) => (isSectionOpen ? 'visible' : 'hidden')};
  transition: grid-template-rows 300ms;
`;

const ExpandableWrapper = styled.div<{ isMobile: boolean; isSectionOpen: boolean }>`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 20px;
  min-height: 0;
  opacity: ${({ isSectionOpen }) => (isSectionOpen ? '1' : '0')};
  padding: ${({ isMobile, isSectionOpen }) => (isMobile ? '20px' : isSectionOpen ? '40px' : 0)};
`;

const Title = styled.span`
  font-family: Lato;
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  color: ${({ theme }) => theme.font.strong};
  padding: 10px 0 5px 0;
  margin-left: 10px;
`;

const CollapseButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
`;
