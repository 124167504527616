import { getUTCDate } from '@/components/allocations-page/utils';
import { formatCash } from '@/utils/formatCash';
import { useEffect, useState } from 'react';
import { Bar, CartesianGrid, Cell, ComposedChart, Legend, Line, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { Section } from '../../edit-entity/section';
import { ChartTooltip } from '../chartTooltip';
import { SimpleForecastValueDto } from '../types';

interface ForecastSummarySectionProps {
  netCommitments: SimpleForecastValueDto[];
  totalNAVs: SimpleForecastValueDto[];
  cumulativeContributions: SimpleForecastValueDto[];
  cumulativeDistributions: SimpleForecastValueDto[];
}

interface IChartData {
  year: string;
  newCommitments?: number;
  cumContributions?: number;
  nav?: number;
  forecastNav?: number;
  actual: boolean;
}

export const ForecastSummarySection = ({
  netCommitments,
  totalNAVs,
  cumulativeContributions,
  cumulativeDistributions
}: ForecastSummarySectionProps) => {
  const [chartData, setChartData] = useState<IChartData[]>([]);

  const addItem = (transformedChartData: { [key: number]: IChartData }, item: SimpleForecastValueDto, fieldName: string) => {
    const formattedYear = getUTCDate(new Date(item.date)).toUpperCase();
    const year = new Date(item.date).getFullYear();

    if (!transformedChartData[year]) {
      transformedChartData[year] = {
        year: formattedYear,
        [fieldName]: item.value,
        actual: item.actual
      };
    }
    transformedChartData[year] = {
      ...transformedChartData[year],
      [fieldName]: item.value
    };
  };

  useEffect(() => {
    if (!netCommitments.length || !totalNAVs.length || !cumulativeContributions.length || !cumulativeDistributions.length) return;
    const transformedChartData: { [key: number]: IChartData } = {};

    netCommitments.forEach((item) => {
      addItem(transformedChartData, item, 'newCommitments');
    });

    // cumulativeContributions.forEach((item) => {
    //   addItem(transformedChartData, item, 'cumContributions');
    // });

    // cumulativeDistributions.forEach((item) => {
    //   addItem(transformedChartData, { ...item, value: -item.value }, 'cumDistributions');
    // });

    totalNAVs.forEach((item, index) => {
      addItem(transformedChartData, item, 'nav');
      // if (item.actual) {
      //   addItem(transformedChartData, item, 'nav');
      //   return;
      // }
      // if (totalNAVs[index - 1]?.actual && !item?.actual) {
      //   addItem(transformedChartData, item, 'nav');
      //   addItem(transformedChartData, item, 'forecastNav');
      //   return;
      // }
      // addItem(transformedChartData, item, 'forecastNav');
    });

    setChartData(Object.values(transformedChartData).map((value) => value));
  }, [netCommitments, totalNAVs, cumulativeContributions, cumulativeDistributions]);

  return (
    <Section id="commitmentsVsNetAssetValue" title="Commitments vs Net Asset Value">
      <ResponsiveContainer width="100%" height={400}>
        <ComposedChart stackOffset="sign" data={chartData}>
          <CartesianGrid stroke="#B0B0B0" />
          <XAxis dataKey="year" />
          <YAxis width={85} tickFormatter={(value) => formatCash(value)} />
          <Tooltip content={<ChartTooltip />} />
          <Legend />
          <Bar dataKey="newCommitments" fill="#199BDC" name="New Commitments">
            {chartData.map((entry) => {
              const color = entry.actual ? '#97D4F2' : '#199BDC';
              return <Cell key={`newCommitments-${entry.year}`} fill={color} />;
            })}
          </Bar>
          {/* <Bar stackId="a" dataKey="cumContributions" fill="#20CB70" name="Cum. Contributions">
            {chartData.map((entry) => {
              const color = entry.actual ? '#9CEBC1' : '#20CB70';
              return <Cell key={`cumContributions-${entry.year}`} fill={color} />;
            })}
          </Bar>
          <Bar stackId="a" dataKey="cumDistributions" fill="#FC265E" name="Cum. Distributions">
            {chartData.map((entry) => {
              const color = entry.actual ? '#FAA6BC' : '#FC265E';
              return <Cell key={`cumDistributions-${entry.year}`} fill={color} />;
            })}
          </Bar> */}
          <Line type="monotone" dataKey="nav" name="NAV" stroke="#0E718A" strokeWidth={4} />
          {/* <Line type="monotone" dataKey="forecastNav" name="Forecast NAV" stroke="#4BADA0" strokeWidth={4} /> */}
        </ComposedChart>
      </ResponsiveContainer>
    </Section>
  );
};
