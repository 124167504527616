import { ApprovedIcon } from '@/assets/icons/info-icons/approved';
import { RedStarIcon } from '@/assets/icons/info-icons/redStar';
import styled, { useTheme } from 'styled-components';

interface RowStatusProps {
  status: string;
}

export const RowStatus = ({ status }: RowStatusProps) => {
  const theme = useTheme();

  switch (status) {
    case 'Required':
      return (
        <RequiredStatusCell>
          <RedStarIcon width={9} height={11} fill={theme.context.error} />
          Required
        </RequiredStatusCell>
      );
    case 'Assigned':
      return (
        <AssignedStatusCell>
          <svg xmlns="http://www.w3.org/2000/svg" width="15" height="18" viewBox="0 0 15 18" fill="none">
            <path
              d="M12.667 6.09988H11.8151V4.40132C11.8349 3.83061 11.7396 3.26179 11.5348 2.72872C11.33 2.19564 11.02 1.70923 10.6233 1.29851C10.2266 0.88779 9.75124 0.561159 9.22557 0.33807C8.6999 0.11498 8.13469 0 7.56364 0C6.9926 0 6.42739 0.11498 5.90172 0.33807C5.37605 0.561159 4.90067 0.88779 4.50393 1.29851C4.10719 1.70923 3.79718 2.19564 3.59242 2.72872C3.38767 3.26179 3.29234 3.83061 3.31211 4.40132V6.09988H2.46542C2.01447 6.09988 1.58187 6.27868 1.26252 6.59707C0.943167 6.91547 0.763069 7.3475 0.761719 7.79845V16.3014C0.763069 16.7524 0.943167 17.1844 1.26252 17.5028C1.58187 17.8212 2.01447 18 2.46542 18H12.667C13.117 17.9987 13.5483 17.8193 13.8665 17.501C14.1848 17.1828 14.3642 16.7515 14.3655 16.3014V7.79845C14.3642 7.34838 14.1848 6.91713 13.8665 6.59888C13.5483 6.28063 13.117 6.10123 12.667 6.09988ZM5.01581 4.40132C5.01581 3.72491 5.28452 3.07622 5.76281 2.59793C6.2411 2.11964 6.88979 1.85093 7.5662 1.85093C8.2426 1.85093 8.8913 2.11964 9.36959 2.59793C9.84788 3.07622 10.1166 3.72491 10.1166 4.40132V6.09988H5.01581V4.40132ZM12.667 16.3014H2.46542V7.79845H12.667V16.3014ZM7.5662 13.751C7.90337 13.751 8.23292 13.651 8.51319 13.4636C8.79346 13.2761 9.01184 13.0098 9.14064 12.6982C9.26943 12.3866 9.30286 12.0437 9.23671 11.7131C9.17056 11.3825 9.00781 11.079 8.76904 10.8409C8.53027 10.6029 8.22621 10.441 7.89539 10.3758C7.56458 10.3107 7.22188 10.3452 6.91067 10.4749C6.59946 10.6046 6.33376 10.8238 6.14717 11.1046C5.96058 11.3855 5.86148 11.7153 5.86249 12.0525C5.86384 12.5034 6.04394 12.9355 6.36329 13.2539C6.68265 13.5723 7.11524 13.751 7.5662 13.751Z"
              fill={theme.font.weak}
            />
          </svg>
          Assigned
        </AssignedStatusCell>
      );
    case 'Complete':
      return (
        <CompleteStatusCell>
          <ApprovedIcon width={23} height={23} fill={theme.context.success} />
          Complete
        </CompleteStatusCell>
      );
    default:
      return <></>;
  }
};

const StatusCell = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
  line-height: 22.4px;
`;

const RequiredStatusCell = styled(StatusCell)`
  color: ${({ theme }) => theme.context.error};
`;

const AssignedStatusCell = styled(StatusCell)`
  color: ${({ theme }) => theme.font.weak};
`;

const CompleteStatusCell = styled(StatusCell)`
  color: ${({ theme }) => theme.context.success};
`;
