import Button from '@/components/fat-basicComponents/button';
import {
  MobileRow,
  MobileRowTitle,
  MobileRowValue,
  MobileTableRowWrap,
  MobileTableWrapper,
  NoResults
} from '@/components/table/mobileTable/mobileTable';
import guid from '@/utils/guid';
import { InvestorEntitiesRowStatus } from '../../../investorEntitiesRowStatus';
import { IGroupImportComparison, TypeWindow } from '../../../types';
import { columns } from '../constants';

interface MobileTableProps {
  tableData: IGroupImportComparison[];
  openGroupImportDetailPage: (type: TypeWindow, row: IGroupImportComparison) => void;
}

const MobileTable = ({ tableData, openGroupImportDetailPage }: MobileTableProps) => {
  const TableRow = ({ el }: { el: IGroupImportComparison }) => {
    return (
      <MobileTableRowWrap key={guid()}>
        <MobileRow>
          <MobileRowTitle>{columns[0].title}</MobileRowTitle>
          <MobileRowValue>{el.investorName}</MobileRowValue>
        </MobileRow>

        <MobileRow>
          <MobileRowTitle>{columns[1].title}</MobileRowTitle>
          <MobileRowValue>{el.totalGroupe}</MobileRowValue>
        </MobileRow>

        <MobileRow>
          <MobileRowTitle>{columns[2].title}</MobileRowTitle>
          <MobileRowValue>{el.newlyGrouped}</MobileRowValue>
        </MobileRow>

        <MobileRow>
          <MobileRowTitle>{columns[3].title}</MobileRowTitle>
          <MobileRowValue>{el.ungrouped}</MobileRowValue>
        </MobileRow>

        <MobileRow>
          <MobileRowTitle>{columns[4].title}</MobileRowTitle>
          <MobileRowValue>{el.primaryEntityName}</MobileRowValue>
        </MobileRow>

        <MobileRow>
          <MobileRowTitle>{columns[5].title}</MobileRowTitle>
          <MobileRowValue>
            <InvestorEntitiesRowStatus status={el.status} />
          </MobileRowValue>
        </MobileRow>

        <MobileRow>
          <MobileRowTitle>{columns[6].title}</MobileRowTitle>
          <MobileRowValue>
            {el.status !== 'toDelete' && (
              <Button size="sm" onClick={() => openGroupImportDetailPage('groupImportDetail', el)}>
                View
              </Button>
            )}
          </MobileRowValue>
        </MobileRow>
      </MobileTableRowWrap>
    );
  };

  return (
    <MobileTableWrapper>
      {tableData.length ? (
        tableData.map((el: any, index: number) => {
          return <TableRow el={el} key={index} />;
        })
      ) : (
        <NoResults>No Results</NoResults>
      )}
    </MobileTableWrapper>
  );
};

export default MobileTable;
