import Button from '@/components/fat-basicComponents/button';
import Header from '@/components/fat-header';
import { GoBackButton } from '@/components/fat-header/goBackButton';
import { PageTitle } from '@/components/fat-header/pageTitle';
import { useResponsive } from '@/hooks/use-responsive';
import { MainWrap, PaddingWrap } from '@/styles/common';
import { Dispatch, SetStateAction, useState } from 'react';
import styled from 'styled-components';
import { ColumnsDisplayed } from '../columnsDisplayed';
import { SelectedColumnsList } from '../selectedColumnsList';
import { ColumnList } from '../types';
import { ModalContentWrapper } from './editTableSettings';

interface EditTableSettingsPageProps {
  onClose: () => void;
  columnsList: ColumnList[];
  setColumnsList: Dispatch<SetStateAction<ColumnList[]>>;
}

const EditTableSettingsPage = ({ onClose, columnsList, setColumnsList }: EditTableSettingsPageProps) => {
  const { isMobile, isTablet } = useResponsive();

  const [newColumns, setNewColumns] = useState(columnsList.map((column) => ({ ...column })));

  const saveNewColumns = () => {
    setColumnsList(newColumns);
    const updatedOrder = newColumns.map((column) => {
      return { rowKey: column.rowKey, value: column.value };
    });
    localStorage.setItem('dealRegistryColumnsOrder', JSON.stringify(updatedOrder));
    onClose();
  };

  return (
    <MainWrap>
      <Header modalControl={<GoBackButton handleClose={onClose} backToTitle={'Deal Registry'} />} />
      <PageTitle title="Choose Which Columns You See" />
      <PaddingWrap>
        <ModalContentWrapper>
          <ColumnsDisplayed newColumns={newColumns} setNewColumns={setNewColumns} />
          <SelectedColumnsList newColumns={newColumns} setNewColumns={setNewColumns} />
        </ModalContentWrapper>
        <ActionButtons>
          <Button onClick={onClose} styleType="outline" size="md">
            Cancel
          </Button>
          <Button onClick={saveNewColumns} size="md">
            Apply
          </Button>
        </ActionButtons>
      </PaddingWrap>
    </MainWrap>
  );
};

export default EditTableSettingsPage;

const ActionButtons = styled.div`
  display: flex;
  gap: 10px;
  justify-content: flex-end;
  padding: 30px 60px;
`;
