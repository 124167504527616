import { TData } from '@/components/basicComponents/selectWithCheckBox';
import Header from '@/components/header';
import { ACTIONS, FieldsForRedirectWindows, ScrollTo, TypeWindow } from '@/components/recommendations-page/types';
import { AdditionalFilter, AllocationsFiltersStructure, FiltersItems } from '@/components/table/filterPage/types';
import { createNewFilter } from '@/components/table/filterPage/utils';
import { IAdvisor, ISort, SORT_ORDER } from '@/components/table/types';
import { SELECTORS } from '@/types/enums';
import { Advisor } from '@/types/family';
import { useMutation, useQuery } from '@apollo/client';
import { subject } from '@casl/ability';
import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import { EditIcon } from '@/assets/icons/edit';
import { CloseMenuIcon } from '@/assets/static/icons/close-menu';
import { buildAbilityFor } from '@/config/ability';
import { useAuth } from '@/hooks/use-auth';
import { useResponsive } from '@/hooks/use-responsive';
import { useGoBack } from '@/hooks/useGoBack';
import { MainWrap, PaddingWrap } from '@/styles/common';
import { Allocation } from '@/types/investments';
import { ITab } from '@/types/opportunity';
import { IAllocationRow } from '@/types/opportunity/pending-allocation';
import { User } from '@/types/user';
import guid from '@/utils/guid';
import { Helmet } from 'react-helmet';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import FilterSelect from '../../basicComponents/select';
import { CloseBtn, ModalControlWrap, ModalPageTitle } from '../../client-details';
import { Loader } from '../../loaders/loader';
import MobileModal from '../../mobileModal';
import { ADVISORS_QUERY } from '../../opportunities-entity/constants';
import PendingAllocation from '../../opportunities-entity/pendingAllocation';
import {
  AllocationsQuery,
  createAllocationMutation,
  investorReviewQuery,
  updateAllocationMutation
} from '../../opportunities-entity/pendingAllocation/constants';
import SuitabilityDetails from '../../opportunities-entity/pendingAllocation/suitabilityDetails';
import { ITabsSelectors, ModalWindow, ModalWindowType } from '../../opportunities-entity/pendingAllocation/types';
import EntityPopover from '../../recommendations-page/popovers/entity';
import FilterPage from '../../table/filterPage';
import { Results, TopWrapper } from '../../table/filterPage/desktop-filter';
import { TASKS_QUERY } from '../../todo-page/queries';
import Triangle from '../../triangle';
import { DECISION_STATUS, SUITABILITY_STATUS } from '../constants';
import { AllocationItemType, InvestorPotentialAllocationsType, InvestorReviewType, Mode, ModeType } from '../types';
import { initialAllocationStatusFilter, opportunitySort } from './constants';
import Details, { CounterTitle, IconWrap } from './details';
import FooterModal from './footer';

const OpportunityModal = () => {
  const { setUserTasks } = useAuth();
  const { isMobile, isTablet, isPortrait } = useResponsive();
  const goBack = useGoBack();
  const navigate = useNavigate();
  const params = useParams();

  const [searchParams, setSearchParams] = useSearchParams();

  const currentTab = searchParams.get('currentTab') || 'pending';
  const allocationStatusFilterParams = searchParams.get('allocationStatusFilter') || '';
  const advisorsParams = searchParams.get('advisors') || '';
  const suitabilityParams = searchParams.get('suitability') || '';
  const decisionParams = searchParams.get('decision') || '';
  const position = searchParams.get('position') || 0;

  const pendingSortKey = searchParams.get('pendingSortKey') || 'entity';
  const pendingSortAsc = searchParams.get('pendingSortAsc') || 'ASC';
  const investmentReviewSortKey = searchParams.get('investmentReviewSortKey') || 'entity';
  const investmentReviewSortAsc = searchParams.get('investmentReviewSortAsc') || 'ASC';
  const advisorReviewSortKey = searchParams.get('advisorReviewSortKey') || 'entity';
  const advisorReviewSortAsc = searchParams.get('advisorReviewSortAsc') || 'ASC';
  const complianceSortKey = searchParams.get('complianceSortKey') || 'entity';
  const complianceSortAsc = searchParams.get('complianceSortAsc') || 'ASC';

  const pendingSearch = searchParams.get('pendingSearch') || '';
  const investmentReviewSearch = searchParams.get('investmentReviewSearch') || '';
  const advisorReviewSearch = searchParams.get('advisorReviewSearch') || '';
  const complianceSearch = searchParams.get('complianceSearch') || '';

  const queryParams = new URLSearchParams(location.search);
  const indexTab = queryParams.get('indexTab') || 0;
  const defaultItemsTab = [
    {
      title: 'Begin Allocation',
      amount: 0,
      checked: indexTab === 0,
      value: 'pending',
      icon: <CustomTriangle side={'right'} />
    },
    {
      title: 'Investment Review',
      amount: 0,
      checked: false,
      value: 'investmentReview',
      icon: <CustomTriangle side={'right'} />
    },
    {
      title: 'Advisor Review',
      amount: 0,
      checked: false,
      value: 'advisorReview',
      icon: <CustomTriangle side={'right'} />
    },
    {
      title: 'Compliance',
      amount: 0,
      checked: false,
      value: 'compliance',
      icon: <CustomTriangle side={'right'} />
    }
  ] as ITab[];
  const [changedAllocations, setChangedAllocations] = useState<IAllocationRow[]>([]);
  const [flagRefetchBegin, setFlagRefetchBegin] = useState(false);
  const [advisors, setAdvisors] = useState<Advisor[]>([]);
  const includeProposedInvestments = true;
  const [tabs, setTabs] = useState<ITab[]>(defaultItemsTab);
  const [currentRow, setCurrentRow] = useState<IAllocationRow | null>(null);
  const [modalWindow, setModalWindow] = useState<ModalWindow>({ isOpen: false, type: 'amount' });
  const [potentialAllocations, setPotentialAllocations] = useState<IAllocationRow[]>([]);
  const { data: advisorsData, loading: loadingAdvisors, error: errorAdvisors } = useQuery(ADVISORS_QUERY);
  const [firstLoading, setFirstLoading] = useState({
    beginAllocation: true,
    investmentReview: true,
    advisorReview: true,
    complianceReview: true
  });
  const [submitToScroll, setSubmitToScroll] = useState(false);
  const [detailsData, setDetailsData] = useState<InvestorPotentialAllocationsType>();
  const [checkedIds, setCheckedIds] = useState<IAllocationRow[]>([]);
  const [allocationStatusFilter, setAllocationStatusFilter] = useState<TData[]>(() => getAllocationStatusFilter());
  const [scrollTo, setScrollTo] = useState<ScrollTo>({ y: 0, makeScroll: false, limit: 50, offset: 0 });
  const [mode, setMode] = useState<ModeType>(Mode.VIEW);
  const [isLoadingAll, setIsLoadingAll] = useState(false);
  const [filtersItems, setFiltersItems] = useState<FiltersItems>({
    advisors: [SELECTORS.ALL_ADVISORS],
    suitability: [SELECTORS.ALL_SUITABILITY, ...Object.values(SUITABILITY_STATUS)],
    decision: [SELECTORS.ANY_DECISION, ...Object.values(DECISION_STATUS)]
  });
  const [additionalFilters, setAdditionalFilters] = useState<AdditionalFilter[]>(() => getAdditionalFilters());
  const [searchScroll, setSearchScroll] = useState(false);
  const detailsElementRef = React.useRef<HTMLDivElement>();
  const detailsElement = detailsElementRef?.current;
  const [isUpdateAll, setIsUpdateAll] = useState(false);
  const [isFirstLoading, setIsFirstLoading] = useState(true);
  const [isTabMenuOpen, setIsTabMenuOpen] = useState(false);
  const [isMoreFiltersOpen, setIsMoreFiltersOpen] = useState(false);
  const [mobileSort, setMobileSort] = useState(opportunitySort[0].title);
  const sortItem = opportunitySort.find((item) => item.title === mobileSort);
  const mobileSortList = opportunitySort.map((item) => item.title);
  const [isSuitabilityOpen, setIsSuitabilityOpen] = useState(false);
  const [suitabilityData, setSuitabilityData] = useState<IAllocationRow>();
  const [isEntityOpen, setIsEntityOpen] = useState(false);
  const [entityData, setEntityData] = useState<IAllocationRow>();
  // need fix
  const [switcher, setSwitcher] = useState(true);
  const [title, setTitle] = useState('');

  function getAllocationStatusFilter() {
    if (!allocationStatusFilterParams) {
      return initialAllocationStatusFilter;
    }

    const matchedAllocationStatusFilterValues = initialAllocationStatusFilter.map((item) => {
      const matchedFilterItem = JSON.parse(allocationStatusFilterParams).find((filterItem: string) => filterItem === item.name);

      return {
        ...item,
        value: Boolean(matchedFilterItem)
      };
    });
    return matchedAllocationStatusFilterValues;
  }

  function getAdditionalFilters() {
    const matchedAdditionalFilters = [];

    if (!advisorsParams && !suitabilityParams && !decisionParams) {
      return [createNewFilter('Select A Filter Option')];
    }

    if (advisorsParams) {
      for (const filterItem of JSON.parse(advisorsParams)) {
        matchedAdditionalFilters.push(createNewFilter('advisors', filterItem));
      }
    }
    if (suitabilityParams) {
      for (const filterItem of JSON.parse(suitabilityParams)) {
        matchedAdditionalFilters.push({ ...createNewFilter('suitability', filterItem), listOfItems: Object.values(SUITABILITY_STATUS) });
      }
    }
    if (decisionParams) {
      for (const filterItem of JSON.parse(decisionParams)) {
        matchedAdditionalFilters.push({ ...createNewFilter('decision', filterItem), listOfItems: Object.values(DECISION_STATUS) });
      }
    }

    return matchedAdditionalFilters;
  }

  const { refetch: refetchTask } = useQuery(TASKS_QUERY, {
    variables: {},
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      setUserTasks(data?.AllocationTasks?.assignedToMeTaskCount || 0);
    }
  });

  const unitedFilters = additionalFilters.reduce<AllocationsFiltersStructure>(
    (acc, { selected, operator, typeFilter }) => {
      return acc[typeFilter as keyof AllocationsFiltersStructure]
        ? {
            ...acc,
            [typeFilter]: [
              ...acc[typeFilter as keyof AllocationsFiltersStructure],
              {
                selected,
                operator
              }
            ]
          }
        : acc;
    },
    {
      advisors: [],
      suitability: [],
      decision: []
    }
  );
  const chosenDecision = unitedFilters.decision.map((item) => item.selected);
  useQuery<{ Advisors: IAdvisor[] }>(ADVISORS_QUERY, {
    onCompleted: (data) =>
      setFiltersItems({
        ...filtersItems,
        advisors: [SELECTORS.ALL_ADVISORS, ...data.Advisors.map(({ initials }) => initials)]
      })
  });

  const {
    data: beginAllocationDataView,
    loading: beginAllocationLoadingView,
    error: beginAllocationErrorView,
    networkStatus: beginAllocationNetworkStatusView,
    refetch: refetchBeginAllocationQueryView
  } = useQuery<InvestorReviewType>(investorReviewQuery, {
    notifyOnNetworkStatusChange: true,
    variables: {
      investmentId: params?.id,
      page: 1,
      rowsPerPage: 50,
      sort: pendingSortKey,
      order: pendingSortAsc,
      search: pendingSearch.toLowerCase(),
      includeProposedInvestments: includeProposedInvestments,
      allocationStatusFilter: allocationStatusFilter.every((el: { value: boolean }) => !el.value)
        ? []
        : {
            initialAllocation: allocationStatusFilter[0]?.value,
            alreadyCommitted: allocationStatusFilter[1]?.value,
            internalReviewRejections: allocationStatusFilter[2]?.value,
            terminated: allocationStatusFilter[3]?.value
          },
      additionalAdvisorFilters: unitedFilters.advisors,
      additionalDecisionFilters: unitedFilters.decision,
      additionalSuitabilityFilters: unitedFilters.suitability
    },
    fetchPolicy: 'cache-and-network',
    // skip: mode === Mode.EDIT,
    onCompleted: (data) => {
      setFirstLoading({ ...firstLoading, beginAllocation: !firstLoading.beginAllocation });
      setIsFirstLoading(false);
      setDetailsData(data.PotentialAllocations);
      setSearchScroll(!searchScroll);
      setTitle(data.PotentialAllocations?.investment?.name ?? '');
    }
  });

  const [beginAllocationData, setBeginAllocationData] = useState<InvestorReviewType>();
  const [investmentReviewData, setInvestmentReviewData] = useState<any>();
  const [advisorReviewData, setAdvisorReviewData] = useState<any>();
  const [complianceReviewData, setComplianceReviewData] = useState<any>();

  const replaceBeginAllocationsWithLocalAllocations = (allocations: any[]) => {
    return allocations.map((allocation: any) => {
      const checkedAllocation = changedAllocations.find((checkedAllocation) => checkedAllocation.legalEntity.id === allocation.legalEntity.id);
      if (checkedAllocation) {
        return checkedAllocation;
      } else {
        return allocation;
      }
    });
  };

  useEffect(() => {
    if (beginAllocationDataView) {
      setBeginAllocationData({
        ...beginAllocationDataView,
        PotentialAllocations: {
          ...beginAllocationDataView.PotentialAllocations,
          allocations: replaceBeginAllocationsWithLocalAllocations(
            beginAllocationDataView.PotentialAllocations.allocations.filter((allocation: AllocationItemType) => allocation.editable)
          )
        }
      });
    }
  }, [beginAllocationDataView]);

  const refetchBegin = async () => {
    await refetchBeginAllocationQueryView({
      sort: pendingSortKey,
      order: pendingSortAsc,
      search: pendingSearch.toLowerCase(),
      allocationStatusFilter: {
        initialAllocation: allocationStatusFilter[0]?.value,
        alreadyCommitted: allocationStatusFilter[1]?.value,
        internalReviewRejections: allocationStatusFilter[2]?.value,
        terminated: allocationStatusFilter[3]?.value
      },
      additionalAdvisorFilters: unitedFilters.advisors,
      additionalDecisionFilters: unitedFilters.decision,
      additionalSuitabilityFilters: unitedFilters.suitability
    });
    setFlagRefetchBegin(!flagRefetchBegin);
  };

  const filterRefecth = () => {
    if (mode === Mode.VIEW) {
      updateAdditionalFiltersView(currentTab);
    } else {
      if (currentTab === 'pending') {
        refetchBegin();
      } else {
        updateAdditionalFiltersEdit(currentTab);
      }
    }
  };

  const {
    data: investmentReviewDataView,
    loading: investmentReviewLoadingView,
    error: investmentReviewErrorView,
    networkStatus: investmentReviewNetworkStatusView,
    refetch: refetchInvestmentReviewQueryView
  } = useQuery(AllocationsQuery, {
    notifyOnNetworkStatusChange: true,
    variables: {
      status: 'Investment review',
      investmentId: params?.id,
      page: 1,
      rowsPerPage: 1000,
      sort: investmentReviewSortKey,
      order: investmentReviewSortAsc,
      search: investmentReviewSearch.toLowerCase(),
      includeProposedInvestments: includeProposedInvestments,
      additionalAdvisorFilters: unitedFilters.advisors,
      additionalDecisionFilters: unitedFilters.decision,
      additionalSuitabilityFilters: unitedFilters.suitability
    },
    fetchPolicy: 'cache-and-network',
    // skip: mode === Mode.EDIT,
    onCompleted: () => {
      setSearchScroll(!searchScroll);
    }
  });

  useEffect(() => {
    if (investmentReviewDataView) {
      setInvestmentReviewData({
        ...investmentReviewDataView,
        Allocations: {
          ...investmentReviewDataView.Allocations,
          allocations: replaceBeginAllocationsWithLocalAllocations(
            investmentReviewDataView.Allocations.allocations.filter((allocation: AllocationItemType) => allocation.editable)
          )
        }
      });
    }
  }, [investmentReviewDataView]);

  const {
    data: advisorReviewDataView,
    loading: advisorReviewLoadingView,
    error: advisorReviewErrorView,
    networkStatus: advisorReviewNetworkStatusView,
    refetch: refetchAdvisorReviewQueryView
  } = useQuery(AllocationsQuery, {
    notifyOnNetworkStatusChange: true,
    variables: {
      status: 'Advisor review',
      investmentId: params?.id,
      page: 1,
      rowsPerPage: 1000,
      sort: advisorReviewSortKey,
      order: advisorReviewSortAsc,
      search: advisorReviewSearch.toLowerCase(),
      includeProposedInvestments: includeProposedInvestments,
      additionalAdvisorFilters: unitedFilters.advisors,
      additionalDecisionFilters: unitedFilters.decision,
      additionalSuitabilityFilters: unitedFilters.suitability
    },
    fetchPolicy: 'cache-and-network',
    // skip: mode === Mode.EDIT,
    onCompleted: () => {
      setSearchScroll(!searchScroll);
    }
  });

  useEffect(() => {
    if (advisorReviewDataView) {
      setAdvisorReviewData({
        ...advisorReviewDataView,
        Allocations: {
          ...advisorReviewDataView.Allocations,
          allocations: replaceBeginAllocationsWithLocalAllocations(
            advisorReviewDataView.Allocations.allocations.filter((allocation: AllocationItemType) => allocation.editable)
          )
        }
      });
    }
  }, [advisorReviewDataView]);

  const {
    data: complianceReviewDataView,
    loading: complianceReviewLoadingView,
    error: complianceReviewErrorView,
    networkStatus: complianceNetworkStatusView,
    refetch: refetchComplianceReviewQueryView
  } = useQuery(AllocationsQuery, {
    notifyOnNetworkStatusChange: true,
    variables: {
      investmentId: params?.id,
      status: 'Compliance review',
      page: 1,
      rowsPerPage: 1000,
      sort: complianceSortKey,
      order: complianceSortAsc,
      search: complianceSearch.toLowerCase(),
      includeProposedInvestments: includeProposedInvestments,
      additionalAdvisorFilters: unitedFilters.advisors,
      additionalDecisionFilters: unitedFilters.decision,
      additionalSuitabilityFilters: unitedFilters.suitability
    },
    fetchPolicy: 'cache-and-network',
    // skip: mode === Mode.EDIT,
    onCompleted: () => {
      setSearchScroll(!searchScroll);
    }
  });

  useEffect(() => {
    if (complianceReviewDataView) {
      setComplianceReviewData({
        ...complianceReviewDataView,
        Allocations: {
          ...complianceReviewDataView.Allocations,
          allocations: replaceBeginAllocationsWithLocalAllocations(
            complianceReviewDataView.Allocations.allocations.filter((allocation: AllocationItemType) => allocation.editable)
          )
        }
      });
    }
  }, [complianceReviewDataView]);

  useEffect(() => {
    const amountsEdit = {
      pending: beginAllocationData?.PotentialAllocations?.total ?? 0,
      investmentReview: investmentReviewData?.Allocations?.total ?? 0,
      advisorReview: advisorReviewData?.Allocations?.total ?? 0,
      compliance: complianceReviewData?.Allocations?.total ?? 0
    } as any;

    const amountsView = {
      pending: beginAllocationDataView?.PotentialAllocations?.total ?? 0,
      investmentReview: investmentReviewDataView?.Allocations?.total ?? 0,
      advisorReview: advisorReviewDataView?.Allocations?.total ?? 0,
      compliance: complianceReviewDataView?.Allocations?.total ?? 0
    } as any;
    const finalAmounts = mode === Mode.VIEW ? amountsView : amountsEdit;
    const newTabs = tabs.map((tab) => ({ ...tab, amount: finalAmounts[tab.value] }));
    setTabs(newTabs);
    document.title = beginAllocationDataView?.PotentialAllocations?.investment?.name || 'Allocations';
  }, [
    mode,
    beginAllocationDataView,
    beginAllocationData,
    investmentReviewDataView,
    investmentReviewData,
    advisorReviewDataView,
    advisorReviewData,
    complianceReviewDataView,
    complianceReviewData
  ]);

  useEffect(() => {
    if (beginAllocationLoadingView || beginAllocationErrorView) return;
  }, [beginAllocationDataView]);

  useEffect(() => {
    if (!loadingAdvisors && !errorAdvisors) {
      setAdvisors(advisorsData.Advisors);
    }
  }, [advisorsData, loadingAdvisors, errorAdvisors]);

  useEffect(() => {
    if (pendingSearch.length || investmentReviewSearch.length || advisorReviewSearch.length || complianceSearch.length)
      detailsElement?.scrollIntoView({
        block: 'start',
        behavior: 'smooth'
      });
  }, [searchScroll]);

  useEffect(() => {
    detailsElement?.scrollIntoView({ block: 'start', behavior: 'auto' });
    setSubmitToScroll(false);
  }, [submitToScroll]);

  useEffect(() => {
    if (!potentialAllocations.length) return;
    makeScroll(position);
  }, [potentialAllocations]);

  const handleExitMyTask = () => {
    if (!changedAllocations.length) {
      setMode(Mode.VIEW);
      setCheckedIds([]);
      return;
    }
    openModalWindow('cancelChanges');
    updateAdditionalFiltersView(currentTab);
    setIsUpdateAll(true);
  };

  const changeTab = (value: string) => {
    if (!changedAllocations.length) {
      setMode(Mode.VIEW);
      onChangeActiveTab(value);
      setIsTabMenuOpen(false);
      return;
    }
    if (currentTab === 'pending' && mode === Mode.EDIT) {
      openModalWindow('cancelChanges');
      setIsTabMenuOpen(false);
    } else {
      onChangeActiveTab(value);
    }
  };

  const openModalWindow = (type: ModalWindowType, row?: IAllocationRow) => {
    setModalWindow({ ...modalWindow, isOpen: true, type });
    setCurrentRow(row ?? currentRow);
  };

  const closeModalWindow = () => {
    setModalWindow({ ...modalWindow, isOpen: false });
    // if (isTablet) return;
    // setCurrentRow(null);
  };

  useEffect(() => {
    setMode(Mode.VIEW);
  }, [currentTab]);

  const currentDataEditMode: IAllocationRow[] = useMemo(() => {
    let fileredComplianceReviewData = [];
    if (complianceReviewData?.Allocations?.allocations.length) {
      fileredComplianceReviewData = complianceReviewData.Allocations.allocations.filter(
        (allocation: Allocation) => allocation.allocationStatus === 'Compliance review'
      );
    }

    switch (currentTab) {
      case 'pending':
        return beginAllocationData?.PotentialAllocations?.allocations || [];
      case 'investmentReview':
        return investmentReviewData?.Allocations?.allocations || [];
      case 'advisorReview':
        return advisorReviewData?.Allocations?.allocations || [];
      case 'compliance':
        return fileredComplianceReviewData;
      default:
        return [];
    }
  }, [flagRefetchBegin, beginAllocationData, investmentReviewData, advisorReviewData, complianceReviewData, currentTab]);

  const currentDataViewMode = useMemo(() => {
    let fileredComplianceReviewData = [];
    if (complianceReviewDataView?.Allocations?.allocations.length) {
      fileredComplianceReviewData = complianceReviewDataView.Allocations.allocations.filter(
        (allocation: Allocation) => allocation.allocationStatus === 'Compliance review'
      );
    }

    switch (currentTab) {
      case 'pending':
        return beginAllocationDataView?.PotentialAllocations?.allocations || [];
      case 'investmentReview':
        return investmentReviewDataView?.Allocations?.allocations || [];
      case 'advisorReview':
        return advisorReviewDataView?.Allocations?.allocations || [];
      case 'compliance':
        return fileredComplianceReviewData;
      default:
        return [];
    }
  }, [beginAllocationDataView, investmentReviewDataView, advisorReviewDataView, complianceReviewDataView, currentTab]);
  const ability = buildAbilityFor(useAuth()?.user as User);
  const canEditInvestment = ability?.can(ACTIONS.EDIT_INVESTMENT, subject('Investment', {}));

  const filteredByYes: IAllocationRow[] = potentialAllocations.filter((row: any) => !row.decisionReason.includes('No'));
  const { amount: amountFooter, total: totalFooter } = filteredByYes.reduce<{ amount: number; total: number }>(
    (acc, currentItem) => {
      return { amount: acc.amount + currentItem.committedCapital, total: acc.total + 1 };
    },
    {
      amount: 0,
      total: 0
    }
  );

  const refetchArrays = [
    {
      query: investorReviewQuery,
      variables: {
        investmentId: params?.id,
        // page: pagination?.page,
        // rowsPerPage: pagination?.rowsPerPage,
        sort: pendingSortKey,
        order: pendingSortAsc,
        search: pendingSearch.toLowerCase(),
        includeProposedInvestments: includeProposedInvestments,
        allocationStatusFilter: allocationStatusFilter.every((el: { value: boolean }) => !el.value)
          ? []
          : ({
              initialAllocation: allocationStatusFilter[0]?.value,
              alreadyCommitted: allocationStatusFilter[1]?.value,
              internalReviewRejections: allocationStatusFilter[2]?.value,
              terminated: allocationStatusFilter[3]?.value
            } as any),
        additionalAdvisorFilters: unitedFilters.advisors,
        additionalDecisionFilters: unitedFilters.decision,
        additionalSuitabilityFilters: unitedFilters.suitability
      }
    },
    {
      query: AllocationsQuery,
      variables: {
        investmentId: params?.id,
        status: 'Investment review',
        // page: pagination?.page,
        // rowsPerPage: pagination?.rowsPerPage,
        sort: investmentReviewSortKey,
        order: investmentReviewSortAsc,
        search: investmentReviewSearch.toLowerCase(),
        includeProposedInvestments: includeProposedInvestments,
        additionalAdvisorFilters: unitedFilters.advisors,
        additionalDecisionFilters: unitedFilters.decision,
        additionalSuitabilityFilters: unitedFilters.suitability
      }
    },
    {
      query: AllocationsQuery,
      variables: {
        investmentId: params?.id,
        status: 'Advisor review',
        // page: pagination?.page,
        // rowsPerPage: pagination?.rowsPerPage,
        sort: advisorReviewSortKey,
        order: advisorReviewSortAsc,
        search: advisorReviewSearch.toLowerCase(),
        includeProposedInvestments: includeProposedInvestments,
        additionalAdvisorFilters: unitedFilters.advisors,
        additionalDecisionFilters: unitedFilters.decision,
        additionalSuitabilityFilters: unitedFilters.suitability
      }
    },
    {
      query: AllocationsQuery,
      variables: {
        investmentId: params?.id,
        status: 'Compliance review',
        // page: pagination?.page,
        // rowsPerPage: pagination?.rowsPerPage,
        sort: complianceSortKey,
        order: complianceSortAsc,
        search: complianceSearch.toLowerCase(),
        includeProposedInvestments: includeProposedInvestments,
        additionalAdvisorFilters: unitedFilters.advisors,
        additionalDecisionFilters: unitedFilters.decision,
        additionalSuitabilityFilters: unitedFilters.suitability
      }
    }
  ];
  const refetchesView: any = {
    pending: refetchBeginAllocationQueryView,
    investmentReview: refetchInvestmentReviewQueryView,
    advisorReview: refetchAdvisorReviewQueryView,
    compliance: refetchComplianceReviewQueryView
  };

  const [createAllocations, { loading: createAllocationsLoading }] = useMutation(createAllocationMutation, {
    awaitRefetchQueries: true,
    refetchQueries: refetchArrays,
    onCompleted: async () => {
      await Promise.all([refetchesView.pending(), refetchesView.investmentReview()]);
      setIsLoadingAll(false);
      setSubmitToScroll(true);
      refetchTask();
    }
  });
  const [updateAllocations, { loading: updateAllocationsLoading }] = useMutation(updateAllocationMutation, {
    awaitRefetchQueries: true,
    refetchQueries: refetchArrays,
    onCompleted: async () => {
      if (isUpdateAll) {
        await Promise.all([refetchesView.investmentReview(), refetchesView.advisorReview(), refetchesView.compliance()]);
        setSubmitToScroll(false);
        setIsLoadingAll(false);
      }
      setCurrentRow(null);
      refetchTask();
    }
  });

  const footerData: any = useMemo(() => {
    return {
      beginAllocation: {
        amount: beginAllocationData?.PotentialAllocations?.allocations || [],
        total: beginAllocationData?.PotentialAllocations?.total || 0
      },
      investmentReview: {
        amount: investmentReviewData?.Allocations?.allocations || [],
        total: investmentReviewData?.Allocations?.allocations.filter((allocation: any) => allocation.editable).length || 0
      },
      advisorReview: {
        amount: advisorReviewData?.Allocations?.allocations || [],
        total: advisorReviewData?.Allocations?.allocations.filter((allocation: any) => allocation.editable).length || 0
      },
      complianceReview: {
        amount: complianceReviewData?.Allocations?.allocations || [],
        total: complianceReviewData?.Allocations?.allocations.filter((allocation: any) => allocation.editable).length || 0
      }
    };
  }, [
    firstLoading.beginAllocation,
    firstLoading.advisorReview,
    firstLoading.complianceReview,
    firstLoading.investmentReview,
    updateAllocationsLoading,
    investmentReviewData,
    advisorReviewData,
    complianceReviewData
  ]);

  const footerDataCurrentTab = useMemo(() => {
    switch (currentTab) {
      case 'pending':
        return footerData.beginAllocation.amount;
      case 'investmentReview':
        return footerData.investmentReview.amount;
      case 'advisorReview':
        return footerData.advisorReview.amount;
      case 'compliance':
        return footerData.complianceReview.amount;
      default:
        return [];
    }
  }, [currentTab, updateAllocationsLoading]);

  const totalAmount = useMemo(() => {
    switch (currentTab) {
      case 'pending':
        return canEditInvestment
          ? {
              amount: amountFooter || 0,
              length: totalFooter || 0
            }
          : {
              amount: 0,
              length: 0
            };
      case 'investmentReview':
        return canEditInvestment
          ? {
              amount: amountFooter || 0,
              length: totalFooter || 0
            }
          : {
              amount: 0,
              length: 0
            };
      case 'advisorReview':
        return ability?.can(ACTIONS.EDIT_ADVISOR_ALLOCATION, subject('Allocation', {}))
          ? {
              amount: amountFooter || 0,
              length: totalFooter || 0
            }
          : {
              amount: 0,
              length: 0
            };
      case 'compliance':
        return ability?.can(ACTIONS.EDIT_COMPLIANCE_ALLOCATION, subject('Allocation', {}))
          ? {
              amount: amountFooter || 0,
              length: totalFooter || 0
            }
          : {
              amount: 0,
              length: 0
            };
      default:
        return {
          amount: 0,
          length: 0
        };
    }
  }, [
    beginAllocationDataView,
    investmentReviewDataView,
    advisorReviewDataView,
    complianceReviewDataView,
    currentTab,
    chosenDecision,
    updateAllocationsLoading
  ]);

  useEffect(() => {
    if (!isMobile) return;

    refetchesView[currentTab]({
      order: sortItem?.asc ? SORT_ORDER.ASC : SORT_ORDER.DESC,
      sort: sortItem?.key
    });
  }, [mobileSort, currentTab]);

  // const search = (params: any) => {
  //   const { event } = params;
  //   if (currentTab === 'pending') {
  //     setBeginAllocationSearch(event.target.value);
  //   } else if (currentTab === 'investmentReview') {
  //     setInvestmentSearch(event.target.value);
  //   } else if (currentTab === 'advisorReview') {
  //     setAdvisorSearch(event.target.value);
  //   } else if (currentTab === 'compliance') {
  //     setComplianceSearch(event.target.value);
  //   }
  // };

  // const debouncedSearch = React.useRef(
  //   debounce(async (params) => {
  //     search(params);
  //   }, 30)
  // ).current;

  // useEffect(() => {
  //   return () => {
  //     debouncedSearch.cancel();
  //   };
  // }, [debouncedSearch]);

  const updateAdditionalFiltersView = (tab: string) => {
    if (tab === 'pending') {
      refetchBeginAllocationQueryView({
        additionalAdvisorFilters: unitedFilters.advisors,
        // additionalDecisionFilters: unitedFilters.decision,
        additionalSuitabilityFilters: unitedFilters.suitability
      });
    } else if (tab === 'investmentReview') {
      refetchInvestmentReviewQueryView({
        additionalAdvisorFilters: unitedFilters.advisors,
        additionalDecisionFilters: unitedFilters.decision,
        additionalSuitabilityFilters: unitedFilters.suitability
      });
    } else if (tab === 'advisorReview') {
      refetchAdvisorReviewQueryView({
        additionalAdvisorFilters: unitedFilters.advisors,
        additionalDecisionFilters: unitedFilters.decision,
        additionalSuitabilityFilters: unitedFilters.suitability
      });
    } else if (tab === 'compliance') {
      refetchComplianceReviewQueryView({
        additionalAdvisorFilters: unitedFilters.advisors,
        additionalDecisionFilters: unitedFilters.decision,
        additionalSuitabilityFilters: unitedFilters.suitability
      });
    }
  };

  const updateAdditionalFiltersEdit = (tab: string) => {
    if (tab === 'pending') {
      refetchBeginAllocationQueryView({
        additionalAdvisorFilters: unitedFilters.advisors,
        additionalDecisionFilters: unitedFilters.decision,
        additionalSuitabilityFilters: unitedFilters.suitability
      });
    } else if (tab === 'investmentReview') {
      refetchInvestmentReviewQueryView({
        additionalAdvisorFilters: unitedFilters.advisors,
        additionalDecisionFilters: unitedFilters.decision,
        additionalSuitabilityFilters: unitedFilters.suitability
      });
    } else if (tab === 'advisorReview') {
      refetchAdvisorReviewQueryView({
        additionalAdvisorFilters: unitedFilters.advisors,
        additionalDecisionFilters: unitedFilters.decision,
        additionalSuitabilityFilters: unitedFilters.suitability
      });
    } else if (tab === 'compliance') {
      refetchComplianceReviewQueryView({
        additionalAdvisorFilters: unitedFilters.advisors,
        additionalDecisionFilters: unitedFilters.decision,
        additionalSuitabilityFilters: unitedFilters.suitability
      });
    }
  };

  const getTotalEditMode = () => {
    switch (currentTab) {
      case 'pending':
        return canEditInvestment ? beginAllocationData?.PotentialAllocations?.total : 0;
      case 'investmentReview':
        return canEditInvestment ? footerData.investmentReview.total : 0;
      case 'advisorReview':
        return ability?.can(ACTIONS.EDIT_ADVISOR_ALLOCATION, subject('Allocation', {})) ? footerData.advisorReview.total : 0;
      case 'compliance':
        return ability?.can(ACTIONS.EDIT_COMPLIANCE_ALLOCATION, subject('Allocation', {})) ? footerData.complianceReview.total : 0;
      default:
        return 0;
    }
  };

  const getTotalViewMode = () => {
    switch (currentTab) {
      case 'pending':
        return beginAllocationDataView?.PotentialAllocations?.total || 0;
      case 'investmentReview':
        return investmentReviewDataView?.Allocations?.total || 0;
      case 'advisorReview':
        return advisorReviewDataView?.Allocations?.total || 0;
      case 'compliance':
        return complianceReviewDataView?.Allocations?.total || 0;
      default:
        return 0;
    }
  };

  const getSearchValue = () => {
    switch (currentTab) {
      case 'pending':
        return pendingSearch;
      case 'investmentReview':
        return investmentReviewSearch;
      case 'advisorReview':
        return advisorReviewSearch;
      case 'compliance':
        return complianceSearch;
      default:
        return '';
    }
  };

  // const mutationRefetchLoading = createAllocationsLoading || updateAllocationsLoading;
  // const pendingAllocationsLoading = investmentReviewLoadingView || advisorReviewLoading || complianceReviewLoading;
  const pendingAllocationsLoading = useMemo(() => {
    return investmentReviewLoadingView || advisorReviewLoadingView || complianceReviewLoadingView;
  }, [investmentReviewLoadingView, advisorReviewLoadingView, complianceReviewLoadingView]);

  const mutationRefetchLoading = useMemo(() => {
    return createAllocationsLoading || updateAllocationsLoading;
  }, [createAllocationsLoading, updateAllocationsLoading]);

  const [isDetailsOpen, setIsDetailsOpen] = useState(true);

  const setNetworkStatusEdit = () => {
    switch (currentTab) {
      case 'pending':
        return beginAllocationNetworkStatusView;
      case 'investmentReview':
        return investmentReviewNetworkStatusView;
      case 'advisorReview':
        return advisorReviewNetworkStatusView;
      case 'compliance':
        return complianceNetworkStatusView;
      default:
        return '';
    }
  };

  const setNetworkStatusView = () => {
    switch (currentTab) {
      case 'pending':
        return beginAllocationNetworkStatusView;
      case 'investmentReview':
        return investmentReviewNetworkStatusView;
      case 'advisorReview':
        return advisorReviewNetworkStatusView;
      case 'compliance':
        return complianceNetworkStatusView;
      default:
        return '';
    }
  };

  // const callRefetchesOnAllTabs = () => {
  //   refetchBeginAllocationQuery();
  //   refetchInvestmentReviewQuery();
  //   refetchAdvisorReviewQuery();
  //   refetchComplianceReviewQuery();
  // };

  const detailsClick = (type: TypeWindow, id: FieldsForRedirectWindows) => {
    saveScrollPostion();

    const { familyId, familyName, id: entityId } = id;
    switch (type) {
      case 'details':
        navigate(`/clients/${familyName}`);
        break;
      case 'entityDetails':
        navigate(`/clients/${familyId}/${entityId}`);
        break;
      default:
        break;
    }
  };

  const openMobileModal = (data: IAllocationRow, modalType: string) => {
    if (modalType === 'suitability') {
      setIsSuitabilityOpen(true);
      setSuitabilityData(data);
    }
    if (modalType === 'entity') {
      setIsEntityOpen(true);
      setEntityData(data);
    }
  };

  if (isFirstLoading) {
    return <CustomLoader size={60} />;
  }

  const getAllocationStatus = (row: any, currentTabValue: any) => {
    switch (currentTabValue) {
      case 'pending':
        return 'Investment review';
      case 'investmentReview':
        return 'Advisor review';
      case 'advisorReview':
        return 'Compliance review';
      case 'compliance':
        return row.decision ? 'Outstanding' : 'Internal Review Rejected';
    }
  };

  const getCheckedAllocations = () => {
    if (currentTab === 'pending') {
      return checkedIds.filter(({ checked }) => checked);
    }
    return changedAllocations.filter(({ checked }) => checked);
  };

  const createDataToUpdate = () => {
    const checkedAllocations = getCheckedAllocations();
    if (!checkedAllocations.length) return [];
    return checkedAllocations.map((row) => ({
      ...(row.id && { id: row.id }),
      ...(currentTab === 'pending' && { investmentId: row.investment.id }),
      ...(currentTab === 'pending' && { legalEntityId: row.legalEntity?.id }),
      ...(currentTab === 'compliance' && row.decision && { operationsStatus: 'Ready for client review' }),
      committedCapital: row.committedCapital,
      proposalType: row.proposalType,
      decision: row.decision,
      reason: row.reason,
      suitability: row.suitability,
      allocationStatus: getAllocationStatus(row, currentTab),
      ...(row.selectedGroupLegalEntity && { selectedGroupLegalEntityId: row.selectedGroupLegalEntity.id })
    }));
  };

  const getAmountCheckedRows = () => {
    if (currentTab === 'pending') {
      return checkedIds.filter((allocation) => allocation.checked).length;
    }
    return changedAllocations.filter((allocation) => allocation.checked).length;
  };

  const getEditSum = () => {
    if (currentTab === 'pending') {
      return checkedIds
        .filter((allocation) => allocation.checked && allocation.decisionReason.startsWith('Yes'))
        .reduce((acc, currentValue) => acc + currentValue.committedCapital, 0);
    }
    return changedAllocations
      .filter((allocation) => allocation.checked && allocation.decisionReason.startsWith('Yes'))
      .reduce((acc, currentValue) => acc + currentValue.committedCapital, 0);
  };

  const getEditTotalYes = () => {
    if (currentTab === 'pending') {
      return checkedIds.filter((allocation) => allocation.checked && allocation.decisionReason.startsWith('Yes')).length;
    }
    return changedAllocations.filter((allocation) => allocation.checked && allocation.decisionReason.startsWith('Yes')).length;
  };

  const submitTo = (): string => {
    if (currentTab === 'pending') {
      return 'Submit to Investment Review';
    } else if (currentTab === 'investmentReview') {
      return 'Submit to Advisor Review';
    } else if (currentTab === 'advisorReview') {
      return 'Submit to Compliance Review';
    } else if (currentTab === 'compliance') {
      return 'Approve for Recommendations';
    } else {
      return '';
    }
  };

  const updateAllocationRequest = () => {
    setIsUpdateAll(true);
    if (currentTab === 'compliance') {
      openModalWindow('submitTo');
      return;
    }
    if (currentTab === 'pending') {
      setIsLoadingAll(true);
    } else {
      setIsLoadingAll(changedAllocations.map((row) => row.checked).includes(true));
    }

    const data = createDataToUpdate();
    if (!data.length) return;
    const actionsOnTab: any = {
      pending: () => {
        createAllocations({
          variables: {
            data
          }
        });
        setChangedAllocations([]);
        setCheckedIds([]);
      },
      investmentReview: () => {
        updateAllocations({
          variables: {
            data
          }
        });
        setChangedAllocations([]);
        setCheckedIds([]);
      },
      advisorReview: () => {
        updateAllocations({
          variables: {
            data
          }
        });
        setChangedAllocations([]);
        setCheckedIds([]);
      }
    };
    actionsOnTab[currentTab as ITabsSelectors]();
    // callRefetchesOnAllTabs();
    // if (mode === Mode.VIEW) refetchesView[currentTab.value];
    // refetchesEdit[currentTab.value];
  };

  const submitToOutstanding = () => {
    const data = createDataToUpdate();
    if (!data.length) return;
    setIsLoadingAll(true);
    updateAllocations({
      variables: {
        data
      },
      onCompleted: () => {
        setIsLoadingAll(false);
        setSubmitToScroll(true);
      }
    });
    setChangedAllocations([]);
    setCheckedIds([]);
    closeModalWindow();
  };

  const changeField = (field: string, id: string) => (value?: string | boolean | number) => {
    setIsUpdateAll(false);
    const currentRow = potentialAllocations.find((row) => row.legalEntity?.id === id) as IAllocationRow;
    if (currentRow[field as keyof typeof currentRow] === value) return;
    const updatedCurrentRow = {
      ...currentRow,
      ...(field === 'decisionReason' && { decisionReason: value as string }),
      ...(field === 'reason' || field === 'decisionReason'
        ? {
            decision: (value as string).split(' - ')[0] === 'Yes',
            reason: (value as string).split(' - ')[1]
          }
        : { [field]: value })
    };
    setPotentialAllocations(potentialAllocations.map((row) => (row.legalEntity?.id === id ? updatedCurrentRow : row)));
    checkedIds.length && setCheckedIds(checkedIds.map((row) => (row.legalEntity?.id === id ? updatedCurrentRow : row)));
    const isAllocationChanged = changedAllocations.some((row) => row.legalEntity?.id === updatedCurrentRow.legalEntity?.id);
    if (field === 'checked' && currentTab === 'pending') {
      if (value) {
        const combinedList = [
          ...checkedIds,
          ...potentialAllocations.map((row) => (row.legalEntity?.id === id ? updatedCurrentRow : row)).filter((row) => row.checked)
        ];
        const dedupedList = combinedList.reduce((dedupedList: any[], allocation: any) => {
          if (dedupedList.find((selectedAllocation) => selectedAllocation.legalEntity.id === allocation.legalEntity.id)) {
            return dedupedList;
          } else {
            return dedupedList.concat(allocation);
          }
        }, []);
        setCheckedIds(dedupedList);
        setChangedAllocations(
          isAllocationChanged
            ? changedAllocations.map((row) => (row.legalEntity?.id === updatedCurrentRow.legalEntity?.id ? updatedCurrentRow : row))
            : [...changedAllocations, updatedCurrentRow]
        );
      } else {
        setCheckedIds(checkedIds.filter((checkedAllocation) => checkedAllocation.legalEntity.id !== id));
      }
    } else {
      setChangedAllocations(
        isAllocationChanged
          ? changedAllocations.map((row) => (row.legalEntity?.id === updatedCurrentRow.legalEntity?.id ? updatedCurrentRow : row))
          : [...changedAllocations, updatedCurrentRow]
      );
    }
    if (currentTab === 'pending' || (field !== 'decisionReason' && field !== 'committedCapital')) return;
    const data = {
      id: currentRow.id,
      ...(field === 'decisionReason' && {
        decision: (value as string).split(' - ')[0] === 'Yes',
        reason: (value as string).split(' - ')[1] ?? ''
      }),
      ...(field === 'committedCapital' && { committedCapital: value })
    };
    updateAllocations({
      variables: {
        data
      }
    });
    setCurrentRow(currentRow ?? currentRow);
  };

  const getSummary = () => {
    const initialSummary = {
      count: 0,
      yesCount: 0,
      totalAmount: 0
    };
    let data = undefined;
    if (currentTab === 'investmentReview') {
      data = investmentReviewData?.Allocations;
    } else if (currentTab === 'advisorReview') {
      data = advisorReviewData?.Allocations;
    } else if (currentTab === 'compliance') {
      data = complianceReviewData?.Allocations;
    }
    if (data) {
      data = {
        count: data.allocations.filter((allocation: any) => allocation.editable).length,
        yesCount: data.allocations.filter((allocation: any) => allocation.decision).length,
        totalAmount: data.allocations
          .filter((allocation: any) => allocation.decision)
          .reduce((sum: number, allocation: any) => sum + allocation.committedCapital, 0)
      };
    }

    return data || initialSummary;
  };

  const onChangeActiveTab = (value: string) => {
    setChangedAllocations([]);
    setAdditionalFilters([createNewFilter('Select A Filter Option')]);
    setSearchParams({ currentTab: value }, { replace: true });
  };

  const onChangeSearch = (searchValue: string) => {
    setSearchParams(
      (prev) => {
        if (!searchValue) {
          prev.delete(`${currentTab}Search`);
          return prev;
        }
        prev.set(`${currentTab}Search`, searchValue);
        return prev;
      },
      { replace: true }
    );
  };

  const onChangeSort = (value: ISort) => {
    setSearchParams(
      (prev) => {
        prev.set(`${currentTab}SortKey`, value.key);
        prev.set(`${currentTab}SortAsc`, value.asc ? SORT_ORDER.ASC : SORT_ORDER.DESC);
        return prev;
      },
      { replace: true }
    );
  };

  const onChangeAllocationStatusFilter = (filterItems: TData[]) => {
    setAllocationStatusFilter(filterItems);
    const allocationStatusValues = filterItems.filter((item) => item.value).map((item) => item.name);
    setSearchParams(
      (prev) => {
        if (!allocationStatusValues.length) {
          prev.delete('allocationStatusFilter');
          return prev;
        }
        prev.set('allocationStatusFilter', JSON.stringify(allocationStatusValues));
        return prev;
      },
      { replace: true }
    );
  };

  const onChangeAdditionalFilters = (filters: AdditionalFilter[]) => {
    setAdditionalFilters(filters);

    const matchedAdditionalFilters = filters.reduce<AllocationsFiltersStructure>(
      (acc, { selected, operator, typeFilter }) => {
        return acc[typeFilter as keyof AllocationsFiltersStructure]
          ? {
              ...acc,
              [typeFilter]: [...acc[typeFilter as keyof AllocationsFiltersStructure], selected]
            }
          : acc;
      },
      {
        advisors: [],
        suitability: [],
        decision: []
      }
    );

    const { advisors, suitability, decision } = matchedAdditionalFilters;

    setSearchParams(
      (prev) => {
        if (!advisors.length) {
          prev.delete('advisors');
          return prev;
        }
        prev.set('advisors', JSON.stringify(advisors));
        return prev;
      },
      { replace: true }
    );

    setSearchParams(
      (prev) => {
        if (!suitability.length) {
          prev.delete('suitability');
          return prev;
        }

        prev.set('suitability', JSON.stringify(suitability));
        return prev;
      },
      { replace: true }
    );

    setSearchParams(
      (prev) => {
        if (!decision.length) {
          prev.delete('decision');
          return prev;
        }

        prev.set('decision', JSON.stringify(decision));
        return prev;
      },
      { replace: true }
    );
  };

  const saveScrollPostion = () => {
    setSearchParams(
      (prev) => {
        prev.set('position', window.scrollY.toString());
        return prev;
      },
      { replace: true }
    );
  };

  function makeScroll(position: string | number) {
    if (!position) return;
    setTimeout(() => window.scrollTo({ top: Number(position), behavior: 'smooth' }), 0);
    setSearchParams(
      (prev) => {
        prev.delete('position');
        return prev;
      },
      { replace: true }
    );
  }

  return (
    <>
      <Helmet>
        <title>{beginAllocationDataView?.PotentialAllocations?.investment?.name || 'Investment'}</title>
      </Helmet>
      {isSuitabilityOpen && suitabilityData && (
        <MobileModal
          title={suitabilityData.suitability}
          onClose={() => {
            setIsSuitabilityOpen(false);
          }}
          onClick={() => null}
        >
          <SuitabilityDetails data={suitabilityData.suitabilityCriteria} key={guid()} />
        </MobileModal>
      )}
      {isEntityOpen && entityData && (
        <MobileModal
          title={entityData.legalEntity?.entityName}
          onClose={() => {
            setIsEntityOpen(false);
          }}
          onClick={() => {
            setIsEntityOpen(false);
            detailsClick('entityDetails', {
              id: entityData.legalEntity.id,
              entityName: entityData.legalEntity.entityName,
              familyId: entityData.legalEntity.family.id,
              familyName: entityData.legalEntity.family.name
            });
          }}
        >
          <EntityPopover
            tooltipContent={`${entityData.investment.assetClass} corresponds to\n${entityData.investment.name}.`}
            switcher={switcher}
            setSwitcher={setSwitcher}
            id={entityData.id}
            allocation={entityData}
            type={entityData.investment.type}
            alwaysShow={entityData.legalEntity.alwaysShow}
          />
        </MobileModal>
      )}
      <MainWrap>
        <Header
          modalControl={
            <ModalControlWrap>
              <CloseBtn
                onClick={() => {
                  goBack({ fallBack: '/allocations' });
                }}
              >
                <CloseMenuIcon width={18} height={18} />
              </CloseBtn>
              <ModalPageTitle isMobile={isMobile} isTablet={isTablet}>
                {title}
                {canEditInvestment && beginAllocationDataView?.PotentialAllocations?.investment && (
                  <IconWrap
                    onClick={() => {
                      const investment = beginAllocationDataView?.PotentialAllocations?.investment;
                      const path = `/allocations/modify/?investment=${beginAllocationDataView?.PotentialAllocations?.investment.id}&fromDetailPage=true`;
                      navigate(path);
                    }}
                  >
                    <EditIcon />
                  </IconWrap>
                )}
              </ModalPageTitle>
            </ModalControlWrap>
          }
        />
        <PaddingWrap>
          <Details
            data={detailsData}
            isOpen={isDetailsOpen}
            setOpen={() => setIsDetailsOpen(!isDetailsOpen)}
            currentTab={{ value: currentTab }}
            changeTab={changeTab}
            tabs={tabs}
            setMode={setMode}
            isTabMenuOpen={isTabMenuOpen}
            setIsTabMenuOpen={setIsTabMenuOpen}
          />
        </PaddingWrap>
      </MainWrap>
      <MainWrap>
        <PaddingWrap>
          {isTabMenuOpen && isMobile && (
            <MainTabs>
              {tabs.map((tab, tabId) => (
                <TabsWrap key={tab.value + tabId} onClick={() => changeTab(tab.value)} isActive={currentTab === tab.value}>
                  {tab.value !== 'pending' && <CounterTitle isActive={currentTab === tab.value}>{tab.amount}</CounterTitle>}
                  <TabsDetailsTitle isActive={currentTab === tab.value}>{tab.title}</TabsDetailsTitle>
                </TabsWrap>
              ))}
            </MainTabs>
          )}
          {!isTabMenuOpen && (
            <div>
              <div ref={detailsElementRef} />
              <CustomFilterPage
                isMobile={isMobile}
                isTablet={isTablet}
                search={{
                  value: getSearchValue(),
                  onChange: onChangeSearch,
                  placeholder: 'Search for a client'
                }}
                isResult
                additionalFilters={{
                  filtersItems,
                  filters: additionalFilters,
                  applyFilters: onChangeAdditionalFilters,
                  amountResults: mode === Mode.VIEW ? currentDataViewMode.length : currentDataEditMode.length,
                  showChosenFilters: false,
                  onRefetch: filterRefecth
                }}
                withCheckBox
                currentTab={{ value: currentTab }}
                allocationStatusFilter={allocationStatusFilter}
                setAllocationStatusFilter={onChangeAllocationStatusFilter}
                setIsMoreFiltersOpen={setIsMoreFiltersOpen}
              />
              {!isMoreFiltersOpen && (
                <>
                  {isMobile && (
                    <SortSelectWrapper isMobile={isMobile} isTablet={isTablet} isPortrait={isPortrait} currentTab={currentTab}>
                      <FilterSelect
                        isMobile
                        label={'Sort By'}
                        width={'100%'}
                        data={mobileSortList}
                        selected={mobileSort}
                        setSelected={setMobileSort}
                      />
                      <CustomRefetch>
                        <Results>{currentDataViewMode.length || 0} Results</Results>
                      </CustomRefetch>
                    </SortSelectWrapper>
                  )}
                  <PendingAllocation
                    checkedIds={checkedIds}
                    beginAllocationLoading={beginAllocationLoadingView}
                    flagRefetchBegin={flagRefetchBegin}
                    isDetailsOpen={isDetailsOpen}
                    changeField={changeField}
                    potentialAllocations={potentialAllocations}
                    setPotentialAllocations={setPotentialAllocations}
                    currentRow={currentRow}
                    modalWindow={modalWindow}
                    openModalWindow={openModalWindow}
                    closeModalWindow={closeModalWindow}
                    // callRefetchesOnAllTabs={callRefetchesOnAllTabs}
                    includeProposedInvestments={includeProposedInvestments}
                    refetch={refetchesView[currentTab]}
                    total={mode === Mode.VIEW ? getTotalViewMode() : getTotalEditMode()}
                    allocations={mode === Mode.VIEW ? currentDataViewMode : currentDataEditMode}
                    investmentId={params?.id as string | undefined}
                    // advisors={advisors}
                    currentTab={currentTab}
                    // currentTabIndex={currentTabIndex}
                    // createAllocations={createAllocations}
                    updateAllocations={updateAllocations}
                    // updateSort={mode === Mode.VIEW ? updateSortView : updateSortEdit}
                    loading={mutationRefetchLoading || pendingAllocationsLoading}
                    // updatePagination={updatePagination}
                    changedAllocations={changedAllocations}
                    setChangedAllocations={setChangedAllocations}
                    detailsClick={detailsClick}
                    setScrollTo={setScrollTo}
                    scrollTo={scrollTo}
                    mode={mode}
                    setMode={setMode}
                    // chosenDecision={chosenDecision}
                    networkStatus={mode === Mode.VIEW ? setNetworkStatusView() : setNetworkStatusEdit()}
                    // setCurrentRow={setCurrentRow}
                    // saveTotalResult={setTotalFiltersResult}
                    isLoadingAll={isLoadingAll}
                    setCheckedIds={setCheckedIds}
                    submitToOutstanding={submitToOutstanding}
                    openMobileModal={openMobileModal}
                    switcher={switcher}
                    setSwitcher={setSwitcher}
                    savedSetSort={onChangeSort}
                  />
                </>
              )}
            </div>
          )}
        </PaddingWrap>
      </MainWrap>
      {!isTabMenuOpen && !isMobile && (
        <FooterModal
          handleExitMyTask={handleExitMyTask}
          currentTab={{ value: currentTab }}
          editSum={getEditSum()}
          editTotalYes={getEditTotalYes()}
          totalAmount={totalAmount}
          getAmountCheckedRows={getAmountCheckedRows}
          submitTo={submitTo}
          updateAllocationRequest={updateAllocationRequest}
          mode={mode}
          summary={getSummary()}
          setMode={setMode}
          getTotalViewMode={getTotalViewMode()}
          getTotalEditMode={getTotalEditMode()}
        />
      )}
    </>
  );
};

export default OpportunityModal;

const CustomTriangle = styled(Triangle)`
  border-width: 0 8.5px 8px 8.5px;
  border-radius: 2px;
`;

const CustomLoader = styled(Loader)`
  height: 100%;
  align-items: center;
`;

const CustomFilterPage = styled(FilterPage)<{ isMobile: boolean; isTablet: boolean }>`
  position: sticky;
  top: ${({ isMobile, isTablet }) => (isMobile || isTablet ? '50px' : '0')};
  z-index: 12;
  background-color: #fafafa;
  margin-top: 0;
  margin-left: -1px;
  width: calc(100% + 2px);
  padding: ${({ isMobile }) => (isMobile ? '20px 0 0 0' : '20px 0 10px 0')};
`;

const TabsWrap = styled.div<{ isActive: boolean }>`
  display: flex;
  gap: 10px;
  width: auto;
  background-color: ${({ isActive }) => (isActive ? '#3AB070' : '#FAFAFA')};
  color: ${({ isActive }) => (isActive ? '#FAFAFA' : '#4587ec')};
  padding: 13px 20px;
  cursor: pointer;
`;

const MainTabs = styled.div`
  height: 90vh;
  padding-top: 20px;
`;

const TabsDetailsTitle = styled.div<{ isActive: boolean }>`
  cursor: pointer;
  font-family: Lato;
  font-weight: 700;
`;

const SortSelectWrapper = styled.div<{ isMobile: boolean; isTablet: boolean; isPortrait: boolean; currentTab: string }>`
  position: sticky;
  top: ${({ isMobile, isTablet, isPortrait, currentTab }) =>
    isMobile || isTablet ? (isMobile && isPortrait && currentTab === 'pending' ? '160px' : '120px') : '0'};
  z-index: 11;
  background-color: #fafafa;
  margin-top: 0;
  padding: 5px 20px 10px 20px;
`;

const CustomRefetch = styled(TopWrapper)`
  padding-top: 25px;
`;
