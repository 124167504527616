import { IRowData } from '@/components/clients-page/types';
import styled from 'styled-components';

import Button from '@/components/fat-basicComponents/button';
import guid from '../../../utils/guid';
import FormattingTooltip from '../../basicComponents/tooltip/dollarsTooltip';

interface InfoListClientMobileProps {
  data: IRowData;
  onRedirect: () => void;
}

export const InfoListClientMobile = ({ data, onRedirect }: InfoListClientMobileProps) => {
  const getBoolean = (value: boolean) => (value ? 'YES' : 'NO');
  const clearStr = (value: string): number => {
    return +value.replaceAll('$', '').replaceAll(',', '');
  };

  return (
    <MoreDataWrap>
      <MoreDataItem>
        <MoreDataItemTitle>Total Client Value</MoreDataItemTitle>
        <MoreDataItemValue>
          <FormattingTooltip>{clearStr(data.clientMoreInformation.totalClientValue)}</FormattingTooltip>
        </MoreDataItemValue>
      </MoreDataItem>

      <MoreDataItem>
        <MoreDataItemTitle>Committed Capital</MoreDataItemTitle>
        <MoreDataItemValue>
          <FormattingTooltip>{clearStr(data.clientMoreInformation.committedCapital)}</FormattingTooltip>
        </MoreDataItemValue>
      </MoreDataItem>

      <MoreDataItem>
        <MoreDataItemTitle>Economic Exposure</MoreDataItemTitle>
        <MoreDataItemValue>
          <FormattingTooltip>{clearStr(data.clientMoreInformation.economicExposure)}</FormattingTooltip>
        </MoreDataItemValue>
      </MoreDataItem>

      <MoreDataItem>
        <MoreDataItemTitle>On Hold</MoreDataItemTitle>
        <MoreDataItemValue>{getBoolean(data.clientMoreInformation.onHold)}</MoreDataItemValue>
      </MoreDataItem>

      {data.clientMoreInformation.sectorToAvoid.filter((sector) => sector !== '').length ? (
        <MoreDataItem>
          <MoreDataItemTitle>Sectors To Avoid</MoreDataItemTitle>
          <SectorsWrap>
            {data.clientMoreInformation.sectorToAvoid
              .filter((x, i, a) => a.indexOf(x) == i)
              .map((el) => {
                if (el.length) {
                  return <SectorsItem key={guid()}>{el.trim()}</SectorsItem>;
                }
              })}
          </SectorsWrap>
        </MoreDataItem>
      ) : null}

      {onRedirect && (
        <RedirectButton styleType="outline" size="md" onClick={onRedirect}>
          View {data.name}
        </RedirectButton>
      )}
    </MoreDataWrap>
  );
};

const SectorsWrap = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 5px;
  width: 100%;
`;

const SectorsItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px 20px;
  background-color: ${({ theme }) => theme.layer[2]};
  color: ${({ theme }) => theme.font.base};
  border-radius: 12px;
  font-family: Blinker, serif;
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0;
`;

const MoreDataItemValue = styled.div`
  display: flex;
  justify-content: center;
  font-family: Blinker, serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0;
  text-align: left;
  width: 50%;
  color: ${({ theme }) => theme.font.base};
`;

const MoreDataItemTitle = styled.div`
  display: flex;
  justify-content: flex-start;
  font-family: Blinker, serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0;
  text-align: left;
  width: 50%;
  min-width: 50%;
  color: ${({ theme }) => theme.font.base};
`;

const MoreDataItem = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  padding: 5px 20px;
  background-color: ${({ theme }) => theme.layer[1]};
  gap: 10px;
  border-radius: 4px;
`;

const MoreDataWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3px;
`;

const RedirectButton = styled(Button)`
  margin-top: 13px;
`;
