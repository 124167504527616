import { Investment } from '../../types/investments';
import { ITenant } from '../fat-investors-page/types';
import { OPERATIONS_STATUS } from '../opportunities-entity/proposed';
import { ModalWindowType } from './fat-modals/types';

export enum ROLE {
  ANY = 'Any',
  ADVISOR_OWNER = 'Advisor Owner',
  ADVISOR_OWNER_REPORTS = 'Advisor Owner’s reports',
  ADVISOR_LEAD = 'Advisor Lead',
  OPERATION_LEAD = 'Operation Lead',
  OPERATION_OWNER = 'Operations Owner',
  ASSIGNEE = 'Assignee'
}

export enum ACTIONS {
  MARK_AS_SENT_TO_CLIENT = 'markAsSentToClient',
  MARK_AS_CLIENT_APPROVED = 'markAsClientApproved',
  MARK_AS_SUBDOCS_READY_FOR_REVIEW = 'markAsSubdocReadyForReview',
  MARK_AS_SUBDOCS_REVIEW_COMPLETE = 'markAsSubdocReviewComplete',
  MARK_AS_SUBDOCS_SENT_TO_CLIENT = 'markAsSubdocSentToClient',
  MARK_AS_SUBDOCS_RECEIVED_FROM_CLIENT = 'markAsSubdocReceivedFromClient',
  MARK_AS_SUBDOCS_SENT_TO_FUND_MANAGER = 'markAsSubdocSentToManager',
  MARK_AS_CONFIRMED_BY_MANAGER = 'markAsConfimedByManager',
  MARK_AS_SUBMITTED_TO_REPORTING_SYSTEM = 'markAsSubmittedToReportingSystem',
  MARK_AS_COMMITTED = 'markAsCommitted',
  CHANGE_ASSIGNEE = 'changeAssignee',
  CHANGE_OWNER = 'changeOwners',
  CHANGE_AMOUNT = 'changeAmount',
  GO_BACK_TO_PREVIOUS_STEP = 'goBackToPreviousStep',
  TERMINATE = 'terminate',
  SHOW_SETTING = 'showSetting',
  CHANGE_SELECTED_GROUPED_ENTITY = 'changeSelectedGroupedEntity',
  CREATE_INVESTMENT = 'createInvestment',
  EDIT_INVESTMENT = 'editInvestment',
  EDIT_ADVISOR_ALLOCATION = 'editAdvisorAllocation',
  EDIT_COMPLIANCE_ALLOCATION = 'editComplianceAllocation',
  GENERATE_SUBDOC = 'generateSubdoc',
  REVIEW_SUBDOC = 'reviewSubdoc'
}

export interface RecommendationsQuery {
  RecommendedAllocations: {
    allocations: IRowData[];
    limit: number;
    offset: number;
    total: number;
  };
}

export type Statuses = Exclude<
  (typeof OPERATIONS_STATUS)[keyof typeof OPERATIONS_STATUS],
  OPERATIONS_STATUS.ALL_SUITABILITY | OPERATIONS_STATUS.TERMINATED
>;

export type ProposalType = 'Initial' | 'Increase';

export interface IRowData {
  isAmountHidden?: boolean;
  assignee: any;
  id: string;
  investmentSwitcher: boolean;
  allocationStatus: string;
  committedCapital: number;
  operationsStatus: Statuses;
  reason: string;
  proposalType: ProposalType;
  suitability: string;
  groupedValue: string;
  selectedGroupLegalEntity: LegalEntityEntity; // change to legal entity
  investment: Investment;
  legalEntity: {
    family: {
      id: string;
      name: string;
    };
    advisor: {
      initials: string;
    };
    tenant: ITenant;
    biteSize: number;
    alwaysShow: boolean;
    entityName: string;
    id: string;
    grouped: boolean;
    isGroupEntity: boolean;
    entities: LegalEntityEntity[]; // change to legal entity
    ignoreAllocationTargets: boolean;
  };
  updateStatus: {
    isOpen: boolean;
    activeIndex: number;
  };
  clientApprovalDate: Date | string;

  lastUpdatedAt: Date;
  lastStatusDate: Date;

  completedAt: string | null;
  investmentApprovedAt: Date;
  advisorApprovedAt: string | null;
  complianceApprovedAt: string | null;
  sentToClientAt: string | null;
  clientApprovedAt: string;
  subdocsGeneratedAt: string | null;
  subdocsReviewedAt: string | null;
  subdocsSentToClientAt: string | null;
  subdocsSignedByClientAt: string | null;
  subdocsSentToManagerAt: string | null;
  subdocsCompletedAt: string | null;
  commitmentSubmittedToReportingSystemAt: string | null;
  commitmentRecordedInReportingSystemAt: string | null;
  moreInfo: {
    onHold: boolean;
    name: string;
    entityValue: number;
    privatePercentage: number;
    targetOCRatio: number;
    privateCapitalTarget: number;
    overallCapacity: number;
    privateCreditClassCapacity: number;
    privateRealAssetsCapacity: number;
    privateEquityCapacity: number;
    biteSize: number;
    sectorsToAvoid: string[];
  };
  investmentOwner: {
    id: string;
    firstName: string;
    lastName: string;
  };
  advisorOwner: {
    id: string;
    firstName: string;
    lastName: string;
  };
  complianceOwner: {
    id: string;
    firstName: string;
    lastName: string;
  };
  operationsOwner: {
    id: string;
    firstName: string;
    lastName: string;
  };
  operationsWorkflowTransitionTask: IOperationsWorkflowTransitionTask;
}

export interface IOperationsWorkflowTransitionTask {
  id: string;
  name: string;
  transitionText: string;
  lastUpdate: string;
  transitionTemplate: string;
  order: number;
  createdAt: Date;
}

export interface LegalEntityEntity {
  entityName: string;
  id: string;
}

export interface ModalWindow {
  isOpen: boolean;
  type: ModalWindowType;
}

export type TypeWindow = 'default' | 'investorDetails' | 'entityDetails' | 'opportunityDetails';

// export enum AssigneeFilterEnum {
//   ASSIGNED_TO_ME = 'Assigned to me',
//   OWNED_BY_ME = 'Owned by me',
//   OWNED_BY_ME_ASSIGNED_TO_SOMEONE_ELSE = 'Owned by me assigned to someone else',
// }
export type FieldsForRedirectWindows = Partial<{
  familyName: string;
  id: string;
  entityName: string;
  familyId: string;
  investmentName: string;
  investmentId: string;
}>;

export type InvestmentsFilter = Record<'id' | 'name', string>;

export interface FiltrationResults {
  results: number;
  refetch: () => void;
}

export interface IPaginationData {
  limit: number;
  offset: number;
}

export interface IMobileSort {
  title: string;
  key: string;
  asc: boolean;
}
