import * as React from 'react';

export const DeleteBucketIcon = ({ ...props }: React.SVGProps<SVGSVGElement>) => (
  <svg
    onClick={props.onClick || undefined}
    width={props.width || 48}
    style={props?.style}
    height={props.height || 60}
    viewBox="0 0 48 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_5549_26783)">
      <path
        d="M37.3299 20V53.34H10.6699V20H37.3299ZM32.3299 0H15.6699L12.4099 3.33997H0.669922V10H47.3299V3.33997H35.6699L32.3299 0ZM44.0099 13.34H4.00993V53.34C4.01521 55.1047 4.71858 56.7956 5.96643 58.0435C7.21428 59.2913 8.9052 59.9947 10.6699 60H37.3299C39.0981 60 40.7942 59.299 42.0463 58.0505C43.2985 56.8021 44.0046 55.1081 44.0099 53.34V13.34Z"
        fill={props.fill || '#ABABAB'}
      />
    </g>
    <defs>
      <clipPath id="clip0_5549_26783">
        <rect width="46.66" height="60" fill="white" transform="translate(0.669922)" />
      </clipPath>
    </defs>
  </svg>
);
