import { Loader } from '@/components/loaders/loader';
import React from 'react';
import styled, { css } from 'styled-components';

interface ButtonProps {
  children: React.ReactNode;
  size?: 'sm' | 'md' | 'lg';
  styleType?: 'default' | 'outline' | 'ghost' | 'transparent' | 'disabled';
  icon?: React.ReactNode;
  secondaryIcon?: React.ReactNode;
  type?: 'button' | 'reset' | 'submit';
  width?: string;
  id?: string;
  isLoading?: boolean;
  onClick?: (event?: React.MouseEvent<HTMLButtonElement>) => void;
  className?: string;
}

const getSizeClass = (size: ButtonProps['size']) => {
  switch (size) {
    case 'sm':
      return css`
        padding: 10px 20px;
        font-size: 13px;
        line-height: 18.2px;
        gap: 4px;
      `;
    case 'md':
      return css`
        padding: 10px 30px;
        font-size: 16px;
        line-height: 22.4px;
        gap: 6px;
      `;
    case 'lg':
      return css`
        padding: 10px 40px;
        font-size: 19px;
        line-height: 26.6px;
        gap: 8px;
      `;
    default:
      return '';
  }
};

const getTypeClass = (styleType: ButtonProps['styleType']) => {
  switch (styleType) {
    case 'default':
      return css`
        background-color: ${({ theme }) => theme.action.default};
        border: 1px solid ${({ theme }) => theme.action.default};
        color: ${({ theme }) => theme.context.light};
      `;
    case 'outline':
      return css`
        background-color: ${({ theme }) => theme.layer.base};
        border: 1px solid ${({ theme }) => theme.font.action};
        color: ${({ theme }) => theme.font.action};

        &:hover {
          background-color: ${({ theme }) => theme.action.default};
          border: 1px solid ${({ theme }) => theme.action.default};
          color: ${({ theme }) => theme.context.light};

          svg {
            path {
              fill: ${({ theme }) => theme.context.light};
            }
          }
        }
      `;
    case 'ghost':
      return css`
        background-color: ${({ theme }) => theme.layer.base};
        border: 1px solid ${({ theme }) => theme.layer.base};
        color: ${({ theme }) => theme.font.action};

        &:hover {
          background-color: ${({ theme }) => theme.action.default};
          border: 1px solid ${({ theme }) => theme.action.default};
          color: ${({ theme }) => theme.context.light};
        }
      `;
    case 'transparent':
      return css`
        background-color: transparent;
        color: ${({ theme }) => theme.font.action};

        &:hover {
          background-color: ${({ theme }) => theme.action.default};
          border: 1px solid ${({ theme }) => theme.action.default};
          color: ${({ theme }) => theme.context.light};
        }
      `;
    case 'disabled':
      return css`
        background-color: ${({ theme }) => theme.action.disabled};
        color: ${({ theme }) => theme.font.disabled};
      `;
    default:
      return '';
  }
};

const Button = ({
  children,
  size = 'sm',
  styleType = 'default',
  icon,
  secondaryIcon,
  type,
  width = 'auto',
  id,
  isLoading = false,
  onClick,
  className
}: ButtonProps) => {
  return (
    <StyledButton
      id={id}
      type={type}
      onClick={onClick}
      disabled={styleType === 'disabled'}
      width={width}
      size={size}
      styleType={styleType}
      className={className}
    >
      {icon && icon}
      {children}
      {isLoading && <CustomLoader size={20} />}
      {secondaryIcon && secondaryIcon}
    </StyledButton>
  );
};

export default Button;

const StyledButton = styled.button<ButtonProps>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 4px;
  transition: 0.3s ease;
  font-family: Blinker;
  font-style: normal;
  font-weight: 600;
  width: ${({ width }) => width};
  cursor: ${({ styleType }) => (styleType === 'disabled' ? 'not-allowed' : 'pointer')};

  ${({ size }) => getSizeClass(size)}
  ${({ styleType }) => getTypeClass(styleType)}
`;

const CustomLoader = styled(Loader)`
  padding: 0 !important;
`;
