import { Loader } from '@/components/loaders/loader';
import TableHeader from '@/components/table/fat-tableHeader';
import { TableBodyWrapper, TableWrapper } from '@/components/table/styles';
import { ISort } from '@/components/table/types';
import { useResponsive } from '@/hooks/use-responsive';
import { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';
import { firmTableColumns } from '../constants';
import MobileTable from './mobileTable';
import TableBody from './tableBody';

interface FirmTableTableProps {
  firmTableTableData: any;
  sort: ISort;
  setSort: Dispatch<SetStateAction<ISort>>;
  refetch: () => void;
  loading: boolean;
}

const FirmTable = ({ sort, setSort, refetch, loading, firmTableTableData }: FirmTableTableProps) => {
  const { isMobile, isTablet } = useResponsive();

  return (
    <TableWrapper padding="0">
      {isMobile ? (
        <>{loading ? <Loader /> : <MobileTable firmTableTableData={firmTableTableData} />}</>
      ) : (
        <>
          <CustomTableHeader isTablet={isTablet} refetch={refetch} columns={firmTableColumns} savedSort={sort} savedSetSort={setSort} />
          <TableBodyWrapper>{loading ? <Loader /> : <TableBody firmTableTableData={firmTableTableData} />}</TableBodyWrapper>
        </>
      )}
    </TableWrapper>
  );
};

export default FirmTable;

const CustomTableHeader = styled(TableHeader)<{ isTablet: boolean }>`
  position: sticky;
  top: ${({ isTablet }) => (isTablet ? '130px' : '80px')};
  z-index: 8;
  padding-top: 0;
`;
