import { MainWrap, PaddingWrap } from '@/styles/common';
import { useQuery } from '@apollo/client';
import { useState } from 'react';
import styled from 'styled-components';
import Button from '../fat-basicComponents/button';
import Header from '../fat-header';
import { LoaderOnWholeScreen } from '../loaders/loader-on-whole-screen';
import { SORT_ORDER } from '../table/types';
import { LIST_INVESTMENTS_FOR_MANAGER_UPDATE_QUERY } from './queries';
import { IListInvestmentsForManagerUpdateQuery } from './types';

interface ManagerUpdatesZeroStateProps {
  onViewAllStrategiesButtonClick: () => void;
}

export const ManagerUpdatesZeroState = ({ onViewAllStrategiesButtonClick }: ManagerUpdatesZeroStateProps) => {
  const [isOtherStrategies, setIsOtherStrategies] = useState(false);

  const { loading: otherStrategiesLoading } = useQuery<{ listInvestmentsForManagerUpdate: IListInvestmentsForManagerUpdateQuery }>(
    LIST_INVESTMENTS_FOR_MANAGER_UPDATE_QUERY,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'cache-and-network',
      variables: {
        sortBy: 'STRATEGY',
        order: SORT_ORDER.ASC,
        rowsPerPage: 1,
        page: 1,
        managerUpdateFilter: ['Other Strategies']
      },
      onCompleted: (data) => {
        const { data: tableData } = data.listInvestmentsForManagerUpdate;
        if (tableData.length) {
          setIsOtherStrategies(true);
        }
      },
      onError: (error) => {
        console.error(error);
      }
    }
  );

  if (otherStrategiesLoading) {
    return <LoaderOnWholeScreen />;
  }

  return (
    <MainWrap>
      <Header />
      <PaddingWrap>
        <ZeroStateWrapper>
          <ZeroStateTitleWrapper>
            <img src="/static/investors-page/missing-mag-glass-graphic.png" draggable="false" alt="No Investors" />
            <ZeroStateTitle maxWidth={isOtherStrategies ? '680px' : '550px'}>
              <ZeroStateText fontSize="28px" fontWeight={700}>
                {isOtherStrategies ? 'No Manager Updates have been logged yet.' : 'No Strategies Found.'}
              </ZeroStateText>
              <ZeroStateText fontSize="22px" fontWeight={400}>
                {isOtherStrategies
                  ? 'Use Manager Updates to log manager notes and store quarterly letters. Select a strategy to get started.'
                  : 'Once strategies are added the system use this module to log manager notes and store quarterly letters.'}
              </ZeroStateText>
            </ZeroStateTitle>
          </ZeroStateTitleWrapper>
          {isOtherStrategies && (
            <StyledButton size="md" onClick={onViewAllStrategiesButtonClick}>
              View All Strategies
            </StyledButton>
          )}
        </ZeroStateWrapper>
      </PaddingWrap>
    </MainWrap>
  );
};

const ZeroStateWrapper = styled.div`
  width: 100%;
  margin-top: 120px;
`;

const ZeroStateTitleWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  padding: 20px 80px;
  margin: 0 auto;
  width: max-content;
  background: ${({ theme }) => theme.layer[1]};
  color: ${({ theme }) => theme.font.weak};
`;

const ZeroStateTitle = styled.div<{ maxWidth: string }>`
  max-width: ${({ maxWidth }) => maxWidth};
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const ZeroStateText = styled.p<{ fontSize: string; fontWeight: number }>`
  font-family: Lato;
  font-size: ${({ fontSize }) => fontSize};
  font-weight: ${({ fontWeight }) => fontWeight};
  line-height: 32px;
`;

const StyledButton = styled(Button)`
  margin: 30px auto;
  display: flex;
  align-self: center;
`;
