import styled from 'styled-components';

import SuitabilityDetails, { SuitabilityWrapper } from '@/components/opportunities-entity/pendingAllocation/suitabilityDetails';
import { AnchorPopover } from '@/components/table/anchor-drop-down/fat-index';
import { dateFormatter } from '../../../utils/dateFormatter';
import guid from '../../../utils/guid';
import FormattingTooltip from '../../basicComponents/tooltip/dollarsTooltip';
import { columnsAllocations } from '../constants';
import { ClientEntityAllocationsTableType } from '../types';
import Suitability from './suitability';

interface BodyRowProps {
  clientsRows: ClientEntityAllocationsTableType[];
  keySort?: string;
}

export const TableBody = ({ clientsRows, keySort }: BodyRowProps) => {
  // Condition: If status === Investment review, show empty cell
  // customSortByStatus raises up rows, which are not Investment review
  const customSortByStatus = () => {
    const rows = clientsRows.reduce<Record<'investmentReviewStatus' | 'otherStatuses', ClientEntityAllocationsTableType[]>>(
      (acc, row) => {
        return row.status === 'Investment review'
          ? {
              ...acc,
              investmentReviewStatus: [...acc.investmentReviewStatus, row]
            }
          : { ...acc, otherStatuses: [...acc.otherStatuses, row] };
      },
      {
        investmentReviewStatus: [],
        otherStatuses: []
      }
    );
    return keySort === 'status' ? [...rows.otherStatuses, ...rows.investmentReviewStatus] : clientsRows;
  };

  return (
    <TableWrapper>
      {customSortByStatus().map((row) => (
        <TableRowWrap key={guid()}>
          <TableRowItem width={columnsAllocations[0].width}>
            <BasicTitle>{row.name}</BasicTitle>
          </TableRowItem>

          <TableRowItem width={columnsAllocations[1].width}>
            <BasicTitle>{row.assetClass}</BasicTitle>
          </TableRowItem>

          <TableRowItem width={columnsAllocations[2].width}>
            <AnchorPopover
              zIndex={9999}
              rowBody={
                <SuitabilityWrapper>
                  <Suitability suitability={row.suitability} width="80" />
                </SuitabilityWrapper>
              }
              title={row.suitability}
            >
              <SuitabilityDetails data={row.suitabilityCriteria} key={guid()} />
            </AnchorPopover>
          </TableRowItem>

          <TableRowItem width={columnsAllocations[3].width}>
            <BasicTitle>{row.status}</BasicTitle>
          </TableRowItem>

          <TableRowItem width={columnsAllocations[4].width}>
            {row.investorDocsDue && <BasicTitle>{dateFormatter(row.investorDocsDue, 'yyyy-MM-dd')}</BasicTitle>}
          </TableRowItem>

          <TableRowItem width={columnsAllocations[5].width}>
            <BasicTitle>
              <FormattingTooltip>{row.minimum}</FormattingTooltip>
            </BasicTitle>
          </TableRowItem>
        </TableRowWrap>
      ))}
    </TableWrapper>
  );
};

const TableWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 6px;
`;

const BasicTitle = styled.div`
  font-family: 'Blinker', serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;
  letter-spacing: 0;
  text-align: left;
  color: ${({ theme }) => theme.font.base};
`;

const TableRowWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  min-height: 60px;
  background-color: ${({ theme }) => theme.layer[1]};
  border-radius: 8px;
  padding: 0 24px;
  gap: 5px;

  &:hover #infoWrap {
    opacity: 1;
  }

  &:hover #tempInfoWrap {
    display: none;
  }

  &:hover {
    background-color: ${({ theme }) => theme.layer[12]};
    transition: background-color 100ms ease;
  }
`;

const TableRowItem = styled.div<{ width: number }>`
  display: flex;
  justify-content: flex-start;
  width: ${({ width }) => `${width}%`};
`;
