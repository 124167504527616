import Typography from '@/components/muis/typography';
import React, { Dispatch, SetStateAction, useState } from 'react';
import styled, { css } from 'styled-components';

import { ArrowDown } from '@/assets/icons/info-icons/arrowDown';
import { IInfoIcon } from '@/assets/icons/info-icons/i-info';
import { useResponsive } from '../../../../hooks/use-responsive';
import { ITab } from '../../../../types/opportunity';
import { IAllocationRow } from '../../../../types/opportunity/pending-allocation';
import guid from '../../../../utils/guid';
import { Mode, ModeType } from '../../../allocations-page/types';
import FilterSelect from '../../../basicComponents/select';
import FormattingTooltip from '../../../basicComponents/tooltip/dollarsTooltip';
import { Checkbox } from '../../../checkbox';
import Suitability from '../../../client-entity-details/tableAllocations/suitability';
import { BodyCellInner } from '../../../investments-table';
import Amount from '../../../recommendations-page/cell/amount';
import ClientsAdvisor from '../../../recommendations-page/cell/clientsAdvisor';
import EntityPopover from '../../../recommendations-page/popovers/entity';
import { FieldsForRedirectWindows, TypeWindow } from '../../../recommendations-page/types';
import { AnchorPopover } from '../../../table/anchor-drop-down';
import RedirectTitleCell from '../../../table/redirectTitleCell';
import { CustomTooltip, TableRowCell, blinkingFrame } from '../../../table/styles';
import { TableColumns } from '../../../table/types';
import PrevStatus from '../prevStatus';
import SuitabilityDetails, { SuitabilityWrapper } from '../suitabilityDetails';
import { ModalWindowType } from '../types';

interface OpportunitiesListProps {
  refetch: any;
  changeField: (field: string, id: string) => (value?: string | boolean | number) => void;
  setMissingSubEntityWindowOpen: (value: boolean) => void;
  includeProposedInvestments: boolean;
  potentialAllocations: IAllocationRow[];
  setPotentialAllocations: (value: IAllocationRow[]) => void;
  changedAllocations: IAllocationRow[];
  setChangedAllocations: Dispatch<SetStateAction<IAllocationRow[]>>;
  currentTab: ITab;
  allocations: any;
  updateAllocations: any;
  loading: boolean;
  columns: TableColumns[];
  detailsClick: (type: TypeWindow, id: FieldsForRedirectWindows) => void;
  openModalWindow: (type: ModalWindowType, row: IAllocationRow) => void;
  mode: ModeType;
  setMode: Dispatch<SetStateAction<ModeType>>;
  currentRow: IAllocationRow | null;
  setSwitcher: Dispatch<SetStateAction<boolean>>;
  switcher: boolean;
}

const OpportunitiesList: React.FC<OpportunitiesListProps> = ({
  refetch,
  changeField,
  setMissingSubEntityWindowOpen,
  potentialAllocations,
  setPotentialAllocations,
  changedAllocations,
  setChangedAllocations,
  currentTab,
  updateAllocations,
  loading,
  columns,
  detailsClick,
  openModalWindow,
  mode,
  setMode,
  currentRow,
  switcher,
  setSwitcher
}) => {
  const updateGroupedValue = (row: any, entityName: string) => {
    const updatedSelectedEntity = row.legalEntity.entities.find((entity: any) => entity.entityName === entityName);
    const selectedSubEntity = potentialAllocations.find((allocation) => allocation.legalEntity?.id === row.legalEntity.id) as IAllocationRow;
    const updatedCurrentRow = {
      ...selectedSubEntity,
      selectedGroupLegalEntity: {
        ...selectedSubEntity.selectedGroupLegalEntity,
        ...updatedSelectedEntity
      }
    };
    setPotentialAllocations(
      potentialAllocations.map((allocation) => (allocation.legalEntity?.id === row.legalEntity.id ? updatedCurrentRow : allocation))
    );
    const isAllocationChanged = changedAllocations.some((row) => row.legalEntity?.id === updatedCurrentRow.legalEntity?.id);
    setChangedAllocations(
      isAllocationChanged
        ? changedAllocations.map((row) => (row.legalEntity?.id === updatedCurrentRow.legalEntity?.id ? updatedCurrentRow : row))
        : [...changedAllocations, updatedCurrentRow]
    );

    updateAllocations({
      variables: {
        data: {
          id: updatedCurrentRow.id,
          selectedGroupLegalEntityId: updatedCurrentRow.selectedGroupLegalEntity.id
        }
      }
    });
  };

  const windowHeight = window.innerHeight;
  const isTwoColumn = windowHeight - 215 <= 600;

  const openModalAmount = (row: any) => {
    if (loading) return;
    openModalWindow('amount', row);
  };

  if (!potentialAllocations.length) {
    return mode === Mode.VIEW ? (
      <BodyRowWarning cursor={'default'}>
        <BodyCell>
          <BodyCellInner>
            <Typography component={'span'}>0 Results</Typography>
          </BodyCellInner>
        </BodyCell>
      </BodyRowWarning>
    ) : (
      <ContainerDone>
        <Typography component={'span'}>Nice work! There are no more tasks assigned to you. </Typography>
        <CustomRedirectTitleCell color={'#4587EC'} onClick={() => setMode(Mode.VIEW)}>
          Exit “My Tasks” to view all
        </CustomRedirectTitleCell>
      </ContainerDone>
    );
  }

  const isUpdatedColumn = (rowId: string) => {
    return currentTab.value !== 'pending' ? loading && rowId === currentRow?.id : false;
  };
  const { isTablet } = useResponsive();
  const [selectedRow, setSelectedRow] = useState<string>();
  return (
    <AllocationWrap>
      {potentialAllocations.map((row, index) => (
        <TableRowWrapper
          isTablet={isTablet}
          selected={selectedRow === index.toString()}
          onMouseOver={() => isTablet && setSelectedRow(index.toString())}
          key={row.legalEntity?.id}
          loading={isUpdatedColumn(row.id) ? 1 : 0}
        >
          {mode === Mode.EDIT && (
            <TableRowCell>
              <BodyCellInner>
                <CustomCheckbox
                  checked={row.checked}
                  onChange={(value) => {
                    if (row.legalEntity?.isGroupEntity && !row.selectedGroupLegalEntity && currentTab.value === 'compliance') {
                      setMissingSubEntityWindowOpen(true);
                    } else {
                      changeField('checked', row.legalEntity?.id)(value);
                    }
                  }}
                />
              </BodyCellInner>
            </TableRowCell>
          )}
          <TableRowCell width={columns[0].width}>
            <BodyCellInner>
              <CustomAnchorPopover
                isTwoColumn={isTwoColumn}
                title={row.legalEntity?.entityName}
                nameCell={row.legalEntity?.entityName}
                onRedirect={() =>
                  detailsClick('entityDetails', {
                    id: row.legalEntity.id,
                    entityName: row.legalEntity.entityName,
                    familyId: row.legalEntity.family.id,
                    familyName: row.legalEntity.family.name
                  })
                }
                hyperTitle={() =>
                  detailsClick('entityDetails', {
                    id: row.legalEntity.id,
                    entityName: row.legalEntity.entityName,
                    familyId: row.legalEntity.family.id,
                    familyName: row.legalEntity.family.name
                  })
                }
                requestMakesInChildren
              >
                <EntityPopover
                  tooltipContent={`${row.investment.assetClass} corresponds to\n${row.investment.name}.`}
                  switcher={switcher}
                  setSwitcher={setSwitcher}
                  id={row.id}
                  allocation={row}
                  type={row.investment.type}
                  alwaysShow={row.legalEntity.alwaysShow}
                />
              </CustomAnchorPopover>
              {row.legalEntity.isGroupEntity && row.selectedGroupLegalEntity?.entityName && (
                <CustomSelect
                  openZIndex={31}
                  width={'auto'}
                  longTitle={true}
                  arrow={mode === Mode.EDIT && <ArrowDown fill={'#EF9208'} />}
                  selected={row.selectedGroupLegalEntity?.entityName || row.legalEntity?.entities[0].entityName}
                  data={row?.legalEntity.entities.map((entity) => entity.entityName) ?? []}
                  setSelected={(value) => updateGroupedValue(row, value)}
                  forbiddenOpen={mode === Mode.VIEW}
                />
              )}
            </BodyCellInner>
          </TableRowCell>
          <TableRowCell width={columns[1].width}>
            <BodyCellInner>
              <ClientsAdvisor
                onRedirect={() =>
                  detailsClick('details', {
                    familyName: row.legalEntity.family.name
                  })
                }
                familyName={row.legalEntity?.family.name}
                advisor={row.legalEntity?.advisor?.initials}
              />
            </BodyCellInner>
          </TableRowCell>
          <TableRowCell width={columns[2].width}>
            <BodyCellInner bold>
              <FormattingTooltip zIndex={1000}>{+row.legalEntity?.overallCapacity}</FormattingTooltip>
            </BodyCellInner>
          </TableRowCell>
          <TableRowCell width={columns[3].width}>
            <BodyCellInner bold>
              <FormattingTooltip zIndex={1000}>{+row.legalEntity?.assetClassCapacity}</FormattingTooltip>
            </BodyCellInner>
          </TableRowCell>
          <TableRowCell width={columns[4].width}>
            <BodyCellInner>
              <AnchorPopover
                showLeft
                zIndex={100}
                rowBody={
                  <SuitabilityWrapper>
                    <Suitability suitability={row.suitability} width="80" />
                    {row.committedCapital !== row.legalEntity.fundBiteSize && (
                      <CustomTooltip title={'Differs from default bite size'}>
                        <IInfoIcon fill={'#EF9208'} />
                      </CustomTooltip>
                    )}
                  </SuitabilityWrapper>
                }
                title={row.suitability}
              >
                <SuitabilityDetails data={row.suitabilityCriteria} key={guid()} />
              </AnchorPopover>
            </BodyCellInner>
          </TableRowCell>
          <TableRowCell width={columns[5].width}>
            {mode === Mode.VIEW ? (
              <FormattingTooltip zIndex={1000}>{+row.committedCapital}</FormattingTooltip>
            ) : (
              <Amount showChangeAmountButton={true} amount={row.committedCapital} openWindow={() => openModalAmount(row)} />
            )}
          </TableRowCell>
          <TableRowCell width={columns[6].width}>
            {mode === Mode.VIEW ? (
              <>{row.decisionReason}</>
            ) : (
              <BodyCellInner>
                {row.decisionReason && (
                  <DecisionSelect
                    openZIndex={31}
                    data={row.override}
                    selected={row.decisionReason}
                    setSelected={changeField('decisionReason', row.legalEntity?.id)}
                    width={'100%'}
                    id={'selector'}
                    disabled={!row?.decisionReason}
                  />
                )}
              </BodyCellInner>
            )}
          </TableRowCell>
          {currentTab.value === 'pending' && (
            <TableRowCell width={columns[7].width}>
              <PrevStatus amount={row.existingCommitment} data={row.preAllocationStatus} />
            </TableRowCell>
          )}
        </TableRowWrapper>
      ))}
    </AllocationWrap>
  );
};

const AllocationWrap = styled.div`
  padding-bottom: 30px;
  margin-bottom: 50px;
`;

const CustomAnchorPopover = styled(AnchorPopover)<{ isTwoColumn: boolean }>`
  min-height: ${({ isTwoColumn }) => (isTwoColumn ? '300px' : '660px')};
  z-index: 100 !important;
`;

export const CustomSelect = styled(FilterSelect)`
  padding: 0 !important;
  background: transparent;
  border: 0 !important;
  justify-content: flex-start !important;

  svg {
    margin: 0 6px;
  }

  #titleSelect {
    color: #ef9208;
    white-space: normal;
    border: 0;
    width: auto;
  }

  #dropDownSelect {
    top: 26px;
  }
`;

const DecisionSelect = styled(FilterSelect)`
  border: none !important;

  #titleSelect {
    font-size: 16px;
  }

  #selectIcon {
    opacity: 0;
  }
`;

const BodyCell = styled('div')<{ width?: string }>`
  width: ${({ width }) => width ?? 'auto'};
  padding: 0 0.75rem;
  height: 51px !important;

  &:first-child {
    border-radius: 5px 0 0 5px;
  }

  &:last-child {
    border-radius: 0 5px 5px 0;
    display: flex;
    align-items: center;
  }

  @media (max-width: 1400px) {
    padding: 4px 7px;
  }
`;

const BodyRow = styled('div')<{ cursor?: 'pointer' | 'default' }>`
  cursor: ${({ cursor }) => cursor ?? 'pointer'};
  background: white;
  height: 51px;
`;

const BodyRowWarning = styled(BodyRow)`
  margin-bottom: 140px;
`;

const ContainerDone = styled.div`
  width: 100%;
  margin: 26px 0 0 20px;
  font-size: 16px;
  padding-bottom: 140px;
`;

const CustomRedirectTitleCell = styled(RedirectTitleCell)`
  font-size: 16px !important;
`;

export const CustomCheckbox = styled(Checkbox)`
  margin-right: 15px;
`;

const TableRowWrapper = styled.div<{
  addAdaptive?: boolean;
  loading?: boolean | number;
  selected: boolean;
  isTablet: boolean;
}>`
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 60px;
  background-color: #ffffff;
  border-radius: 8px;
  padding: 0 24px;
  gap: 5px;
  opacity: ${({ loading }) => (loading ? 0.5 : 1)};
  pointer-events: ${({ loading }) => (loading ? 'none' : 'auto')};
  animation: ${({ loading }) => (loading ? blinkingFrame : null)} 1.5s infinite;

  @media (max-width: 1200px) {
    padding: 0 ${({ addAdaptive }) => (addAdaptive ? 10 : 24)}px;
  }

  ${({ isTablet, selected }) =>
    isTablet
      ? css`
          #infoWrap {
            ${selected
              ? css`
                  opacity: 1;
                `
              : ''}
          }

          #actionText {
            ${selected
              ? css`
                  color: #4587ec;
                `
              : ''}
          }

          #actionOrangeText {
            ${selected
              ? css`
                  color: #ef9208;
                `
              : ''}
          }

          #tempInfoWrap {
            ${selected
              ? css`
                  display: none;
                `
              : ''}
          }

          #arrowInSelectRow {
            svg {
              path {
                ${selected
                  ? css`
                      fill: #ef9208;
                    `
                  : ''}
              }
            }
          }

          #selector {
            ${selected
              ? css`
                  outline: 1px solid #c7cbd2 !important;
                  background-color: #fff;
                `
              : ''}
          }

          #selectIcon {
            ${selected
              ? css`
                  opacity: 1;
                `
              : ''}
          }

          ${selected
            ? css`
                background-color: #f0f1f3;
                transition: background-color 100ms ease;
              `
            : ''}
        `
      : css`
          &:hover #infoWrap {
            opacity: 1;
          }

          &:hover #actionText {
            color: #4587ec;
          }

          &:hover #actionOrangeText {
            color: #ef9208;
          }

          &:hover #tempInfoWrap {
            display: none;
          }

          &:hover #arrowInSelectRow {
            svg {
              path {
                fill: #ef9208;
              }
            }
          }

          &:hover #selector {
            outline: 1px solid #c7cbd2 !important;
            background-color: #fff;
          }

          &:hover #selectIcon {
            opacity: 1;
          }

          &:hover {
            background-color: #f0f1f3;
            transition: background-color 100ms ease;
          }
        `}
`;

export default OpportunitiesList;
