import { CloseInfoIcon } from '@/assets/icons/info-icons/closeInfo';
import { EditTodoIcon } from '@/assets/icons/info-icons/editTodo';
import { AddButtonIcon } from '@/assets/static/icons/add-button-icon';
import Button from '@/components/basicComponents/button';
import { CloseBtn, ModalControlWrap, ModalPageTitle } from '@/components/client-details';
import { IFunnelData } from '@/components/dueDiligence-page/types';
import Header from '@/components/header';
import { Loader } from '@/components/loaders/loader';
import DropdownActions from '@/components/table/dropdownActions';
import { SORT_ORDER } from '@/components/table/types';
import { useResponsive } from '@/hooks/use-responsive';
import { MainWrap, PaddingWrap } from '@/styles/common';
import { useMutation, useQuery } from '@apollo/client';
import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useLocation, useNavigate } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { DUE_DILIGENCE_FUNNEL_LIST_SETTINGS_QUERY, UPDATE_DUE_DILIGENCE_FUNNEL_ORDER } from '../../queries';
import { FunnelEditorDropdown } from './dropdown';
import { FunnelEditorEdit } from './funnelEditorEdit';
import AddFunnel from './modals/addFunnel';
import CreateNewVersion from './modals/createNewVersion';
import DeleteFunnel from './modals/deleteFunnel';
import DuplicateFunnel from './modals/duplicateFunnel';
import EditPublishedFunnel from './modals/editPublishedFunnel';
import { IFunnelDataWithStatuses } from './types';

interface DealFunnelEditorProps {
  handleClose: () => void;
}

export const DealFunnelEditor = ({ handleClose }: DealFunnelEditorProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const { isMobile, isTablet } = useResponsive();
  const [DDRImportFunnel, setDDRImportFunnel] = useState<IFunnelDataWithStatuses[]>([]);
  const [windowType, setWindowType] = useState({
    isOpen: false,
    type: 'default'
  });
  const [scrollTo, setScrollTo] = useState(0);
  const [modalWindow, setModalWindow] = useState({
    isOpen: false,
    type: 'add-funnel'
  });
  const [currentFunnel, setCurrentFunnel] = useState<IFunnelDataWithStatuses | null>(null);
  const [funnelsOrder, setFunnelsOrder] = useState<{ id: string; order: number }[] | null>(null);
  const [isFirstLoading, setIsFirstLoading] = useState(true);

  const {
    data,
    loading,
    refetch: funnelListRefetch,
    error,
    networkStatus
  } = useQuery(DUE_DILIGENCE_FUNNEL_LIST_SETTINGS_QUERY, {
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
    variables: {
      data: {
        statusFilter: ['PUBLISHED', 'DRAFT_WITHOUT_PUBLISHED'],
        sortDirection: SORT_ORDER.ASC,
        sortBy: 'ORDER'
      },
      archivedFunnelData: {
        sortDirection: SORT_ORDER.ASC,
        sortBy: 'ORDER'
      }
    },
    onCompleted: (data) => {
      const updatedFunnelsData: IFunnelDataWithStatuses[] = data.listDueDiligenceFunnels.map((funnel: IFunnelData) => {
        const statuses = funnel.archivedFunnels.reduce(
          (acc, archivedFunnel) => {
            archivedFunnel.records.forEach((record) => {
              if (record.investment.securityStatus === 'DUE_DILIGENCE') {
                acc.dueDiligence++;
              } else if (record.investment.securityStatus === 'LEAD') {
                acc.lead++;
              }
            });
            return acc;
          },
          { dueDiligence: 0, lead: 0 }
        );

        statuses.dueDiligence += funnel.records.filter((record) => record.investment.securityStatus === 'DUE_DILIGENCE').length;
        statuses.lead += funnel.records.filter((record) => record.investment.securityStatus === 'LEAD').length;

        return { ...funnel, statuses };
      });

      setDDRImportFunnel(updatedFunnelsData);
      setFunnelsOrder(
        data.listDueDiligenceFunnels.map((funnel: IFunnelData, index: number) => {
          return { id: funnel.id, order: index };
        })
      );
      setIsFirstLoading(false);
    },
    onError: () => {
      setIsFirstLoading(false);
    }
  });

  const [updateFunnelOrder] = useMutation(UPDATE_DUE_DILIGENCE_FUNNEL_ORDER);

  useEffect(() => {
    if (!queryParams.get('funnelId')) {
      setWindowType({ isOpen: false, type: 'default' });
      return;
    }
    if (queryParams.get('funnelId')) {
      setWindowType({ isOpen: false, type: 'funnel-editor' });
      return;
    }
  }, [queryParams.get('funnelId')]);

  useEffect(() => {
    if (windowType.type === 'default') {
      window.scrollTo(0, scrollTo);
    }
  }, [windowType.type]);

  const areArraysEqual = (arr1: typeof funnelsOrder, arr2: typeof funnelsOrder) => {
    if (arr1 && arr2) {
      for (let i = 0; i < arr1.length; i++) {
        if (arr1[i].id !== arr2[i].id) {
          return true;
        }
      }
      return false;
    }
  };

  useEffect(() => {
    const newFunnelOrder = DDRImportFunnel.map((funnel, index) => {
      return { id: funnel.id, order: index + 1 };
    });

    if (areArraysEqual(newFunnelOrder, funnelsOrder)) {
      updateFunnelOrder({
        variables: {
          data: newFunnelOrder
        }
      });
    }
  }, [DDRImportFunnel]);

  const openFunnelEditor = (funnelId: string, funnelName: string, isViewMode?: boolean, isSecondDraft?: boolean) => {
    setScrollTo(window.scrollY);
    navigate(
      `/settings?action=${queryParams.get('action')}&funnelId=${funnelId}&funnelName=${funnelName}&mode=${
        isViewMode ? 'view' : 'edit'
      }&isSecondDraft=${Boolean(isSecondDraft)}`,
      { replace: true } // This is equivalent to { shallow: true } in next/router
    );
    setWindowType({ isOpen: true, type: 'funnel-editor' });
    return;
  };

  const closeFunnelEditor = () => {
    const newPath = `/settings?action=${queryParams.get('action')}`;
    navigate(newPath);
    setWindowType({ isOpen: false, type: 'default' });
  };

  const openModalWindow = (type: string, funnel?: IFunnelDataWithStatuses) => {
    setModalWindow({ isOpen: true, type: type });
    setCurrentFunnel(funnel ?? null);
  };

  const handleDragDrop = (results: any) => {
    const { source, destination, type } = results;

    if (!destination) return;

    if (source.droppableId === destination.droppableId && source.index === destination.index) return;

    if (type === 'group') {
      const reorderedFunnels = [...DDRImportFunnel];

      const sourceIndex = source.index;
      const destinationIndex = destination.index;

      const [removedFunnel] = reorderedFunnels.splice(sourceIndex, 1);
      reorderedFunnels.splice(destinationIndex, 0, removedFunnel);

      return setDDRImportFunnel(reorderedFunnels);
    }
  };

  if (isFirstLoading && windowType.type === 'default' && !modalWindow.isOpen) {
    return <CustomLoader size={60} />;
  }

  return (
    <>
      {windowType.type === 'funnel-editor' && <FunnelEditorEdit handleClose={closeFunnelEditor} />}
      {windowType.type === 'default' && (
        <>
          {modalWindow.type === 'add-funnel' && modalWindow.isOpen && (
            <AddFunnel
              isOpen={modalWindow.isOpen}
              onClose={() => setModalWindow({ type: 'add-funnel', isOpen: false })}
              openFunnelEditor={openFunnelEditor}
              currentFunnel={currentFunnel}
            />
          )}
          {modalWindow.type === 'create-new-version' && modalWindow.isOpen && (
            <CreateNewVersion
              isOpen={modalWindow.isOpen}
              onClose={() => setModalWindow({ type: 'create-new-version', isOpen: false })}
              openFunnelEditor={openFunnelEditor}
              currentFunnel={currentFunnel}
            />
          )}
          {modalWindow.type === 'edit-published-funnel' && modalWindow.isOpen && (
            <EditPublishedFunnel
              isOpen={modalWindow.isOpen}
              onClose={() => setModalWindow({ type: 'create-new-version', isOpen: false })}
              openFunnelEditor={openFunnelEditor}
              currentFunnel={currentFunnel}
            />
          )}
          {modalWindow.type === 'delete-funnel' && modalWindow.isOpen && (
            <DeleteFunnel
              isOpen={modalWindow.isOpen}
              onClose={() => setModalWindow({ type: 'delete-funnel', isOpen: false })}
              currentFunnel={currentFunnel}
              // deleteFunnel={deleteFunnel}
            />
          )}
          {modalWindow.type === 'duplicate-funnel' && modalWindow.isOpen && (
            <DuplicateFunnel
              isOpen={modalWindow.isOpen}
              onClose={() => setModalWindow({ type: 'delete-funnel', isOpen: false })}
              currentFunnel={currentFunnel}
            />
          )}
          <MainWrap>
            <Header
              modalControl={
                <ModalControlWrap>
                  <CloseBtn onClick={handleClose}>
                    <CloseInfoIcon width={18} height={18} />
                  </CloseBtn>
                  <ModalPageTitle isTablet={isTablet} isMobile={isMobile}>
                    Due Diligence Funnel Settings
                  </ModalPageTitle>
                </ModalControlWrap>
              }
            />
            <PaddingWrap>
              <AddButton onClick={() => openModalWindow('add-funnel')}>
                <AddButtonIcon />
                Add New Funnel
              </AddButton>
              <DragDropContext onDragEnd={handleDragDrop}>
                <Droppable droppableId="FUNNELS" type="group">
                  {(provided) => (
                    <FunnelList {...provided.droppableProps} ref={provided.innerRef}>
                      {DDRImportFunnel.map((item, index) => (
                        <Draggable draggableId={item.id} key={item.id} index={index}>
                          {(provided) => (
                            <div {...provided.dragHandleProps} {...provided.draggableProps} ref={provided.innerRef}>
                              <FunnelEditorDropdown
                                key={item.id}
                                title={item.name}
                                handleDelete={() => openModalWindow('delete-funnel', item)}
                                handleDuplicate={() => openModalWindow('duplicate-funnel', item)}
                                handleEdit={() =>
                                  item.draftFunnel || item.status === 'DRAFT'
                                    ? item.status === 'PUBLISHED'
                                      ? openFunnelEditor(item.draftFunnel.id, item.draftFunnel.name)
                                      : openFunnelEditor(item.id, item.name)
                                    : openModalWindow('edit-published-funnel', item)
                                }
                              >
                                {item.status === 'PUBLISHED' && (
                                  <RowWrapper disabled={false} isMobile={isMobile}>
                                    <StatusWrapper isMobile={isMobile}>
                                      Current Version
                                      <RecordStatus>
                                        <CountWrap>
                                          <Count>{item.statuses ? item.statuses.lead : 0}</Count>
                                        </CountWrap>
                                        Leads
                                      </RecordStatus>
                                      <RecordStatus>
                                        <CountWrap>
                                          <Count>{item.statuses ? item.statuses.dueDiligence : 0}</Count>
                                        </CountWrap>
                                        Due Diligence
                                      </RecordStatus>
                                    </StatusWrapper>
                                    <LastUpdate>Last Update: {format(new Date(item.createdAt), 'LLL d, yyyy')}</LastUpdate>
                                    <ActionDropdownWrapper isMobile={isMobile}>
                                      <DropdownActions
                                        isMobile={isMobile}
                                        isListCenter
                                        items={
                                          item.draftFunnel
                                            ? [
                                                {
                                                  name: 'Open',
                                                  onClick: () => openFunnelEditor(item.id, item.name, true)
                                                }
                                              ]
                                            : [
                                                {
                                                  name: 'Open',
                                                  onClick: () => openFunnelEditor(item.id, item.name, true)
                                                },
                                                {
                                                  name: 'Start New Draft',
                                                  onClick: () => openModalWindow('create-new-version', item)
                                                }
                                              ]
                                        }
                                      />
                                    </ActionDropdownWrapper>
                                  </RowWrapper>
                                )}
                                {item.status === 'DRAFT' && (
                                  <RowWrapper disabled={false} isMobile={isMobile}>
                                    <VersionWrapper>
                                      <EditTodoIcon
                                        width={23}
                                        height={23}
                                        fill="#EF9208"
                                        style={{
                                          position: 'relative',
                                          top: '3px'
                                        }}
                                      />
                                      Draft Version
                                    </VersionWrapper>
                                    <LastUpdate>Last Update: {format(new Date(item.createdAt), 'LLL d, yyyy')}</LastUpdate>
                                    <ActionDropdownWrapper isMobile={isMobile}>
                                      <DropdownActions
                                        isMobile={isMobile}
                                        isListCenter
                                        items={[
                                          {
                                            name: 'Open',
                                            onClick: () => openFunnelEditor(item.id, item.name)
                                          }
                                        ]}
                                      />
                                    </ActionDropdownWrapper>
                                  </RowWrapper>
                                )}
                                {item.draftFunnel && (
                                  <RowWrapper disabled={false} isMobile={isMobile}>
                                    <VersionWrapper>
                                      <EditTodoIcon
                                        width={23}
                                        height={23}
                                        fill="#EF9208"
                                        style={{
                                          position: 'relative',
                                          top: '3px'
                                        }}
                                      />
                                      Draft Version
                                    </VersionWrapper>
                                    <LastUpdate>Last Update: {format(new Date(item.draftFunnel.createdAt), 'LLL d, yyyy')}</LastUpdate>
                                    <ActionDropdownWrapper isMobile={isMobile}>
                                      <DropdownActions
                                        isMobile={isMobile}
                                        isListCenter
                                        items={[
                                          {
                                            name: 'Open',
                                            onClick: () => openFunnelEditor(item.draftFunnel.id, item.draftFunnel.name, false, true)
                                          }
                                        ]}
                                      />
                                    </ActionDropdownWrapper>
                                  </RowWrapper>
                                )}
                              </FunnelEditorDropdown>
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </FunnelList>
                  )}
                </Droppable>
              </DragDropContext>
            </PaddingWrap>
          </MainWrap>
        </>
      )}
    </>
  );
};

const CustomLoader = styled(Loader)`
  height: 100%;
  align-items: center;
`;

export const TierDropdownWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 30px;
`;

export const AddButton = styled(Button)`
  height: max-content;
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 20px;
  padding: 10px 30px;
`;

const RowWrapper = styled.div<{ disabled: boolean; isMobile: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: ${({ isMobile }) => (isMobile ? 'flex-start' : 'center')};
  flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};
  gap: ${({ isMobile }) => (isMobile ? '20px' : '0')};
  padding: 20px;
  border-bottom: 1px solid #c7cbd2;

  &:hover #infoWrap {
    opacity: 1;
  }

  &:hover {
    background-color: ${({ disabled }) => (disabled ? 'none' : '#f0f1f3')};
    transition: background-color 100ms ease;
  }
`;

const FunnelList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
`;

const CountWrap = styled.div`
  background-color: #36474f;
  display: inline-block;
  border-radius: 12px;
  color: #f4f5f6;
  padding: 1px 11px;
  font-size: 13px;
  margin-right: 6px;
`;

const Count = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  font-weight: 700;
`;

const StatusWrapper = styled.div<{ isMobile: boolean }>`
  display: flex;
  flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};
  gap: 30px;
  color: #2e2e2e;
  font-size: 16px;
  font-weight: 600;
  width: 40%;
`;

const VersionWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  color: #ef9208;
  font-size: 16px;
  font-weight: 600;
  width: 40%;
`;

const ActionDropdownWrapper = styled.div<{ isMobile: boolean }>`
  width: auto;
  ${({ isMobile }) =>
    isMobile &&
    css`
      margin: 0 auto;
    `};
`;

const RecordStatus = styled.div`
  color: #373f4e;
  font-weight: 400;
  display: flex;
  align-items: center;
`;

const LastUpdate = styled.div`
  color: #2e2e2e;
  font-size: 16px;
  font-weight: 600;
`;
