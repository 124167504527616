import { format } from 'date-fns';
import { fontSelect } from '@/components/settings-page/settings-pages/firmSettings/constants';
import { ISetting } from '@/components/settings-page/settings-pages/firmSettings/types';
import { formatCash } from '@/utils/formatCash';
import { extractAndFormatDate } from '../dueDiligenceRecord/edit-pages/dateContent';
import { extractAmount } from '../dueDiligenceRecord/edit-pages/dollarContent';
import { extractNumbersFromString } from '../dueDiligenceRecord/edit-pages/numberContent';
import { extractPercentage } from '../dueDiligenceRecord/edit-pages/percentageContent';
import { ISectionFields } from '../dueDiligenceRecord/types';
import { decimalToPercentage } from '../dueDiligenceRecord/utils';

export const getValueByKey = (firmSettings: ISetting[], key: string) => {
  const item = firmSettings.find((item) => item.key === key);
  return item?.value ? JSON.parse(item.value) : '';
};

export const getFontByKey = (templateStylingData: ISetting[], key: string) => {
  const item = templateStylingData.find((item) => item.key === key);

  const parsedItem = item?.value ? JSON.parse(item.value) : null;
  return parsedItem ? JSON.parse(parsedItem) : [];
};

export const getTermFontWeight = (
  templateStylingData: ISetting[],
  key: string
) => {
  const item = templateStylingData.find((item) => item.key === key);
  const parsedItem = item?.value ? JSON.parse(item.value) : null;
  const getFontProperties = parsedItem ? JSON.parse(parsedItem) : null;

  if (!getFontProperties) {
    return 700;
  }
  const fontWeightIndex = (fontSelect as { [key: string]: string[] })[
    getFontProperties.fontFamily
  ].indexOf(getFontProperties.fontWeight);
  if (
    fontWeightIndex ===
    (fontSelect as { [key: string]: string[] })[getFontProperties.fontFamily]
      .length -
      1
  ) {
    return (fontSelect as { [key: string]: string[] })[
      getFontProperties.fontFamily
    ][fontWeightIndex];
  }
  return (fontSelect as { [key: string]: string[] })[
    getFontProperties.fontFamily
  ][fontWeightIndex + 1];
};

export const fieldValue = (
  type: string,
  value: string,
  commentary: string
): string => {
  switch (type) {
    case 'LONG_FORM_CONTENT':
      return value;
    case 'SHORT_TEXT':
      return value;
    case 'NUMBER':
      return value && commentary
        ? `${value} | ${commentary}`
        : (value && value) || (commentary && commentary);
    case 'DOLLAR':
      return value && commentary
        ? `${formatCash(+value)} | ${commentary}`
        : (value && formatCash(+value)) || (commentary && commentary);
    case 'PERCENTAGE':
      return value && commentary
        ? `${decimalToPercentage(value as string)} | ${commentary}`
        : (value && decimalToPercentage(value as string)) ||
            (commentary && commentary);
    case 'DATE':
      return value && commentary
        ? `${format(new Date(value), 'LLL d, yyyy')} | ${commentary}`
        : (value && format(new Date(value), 'LLL d, yyyy')) ||
            (commentary && commentary);
    default:
      return '';
  }
};

export const aiFieldValue = (type: string, aiAnswer: string) => {
  switch (type) {
    case 'NUMBER':
      return extractNumbersFromString(aiAnswer)
        ? `${extractNumbersFromString(aiAnswer)} | ${aiAnswer}`
        : aiAnswer;
    case 'DOLLAR':
      return extractAmount(aiAnswer)
        ? `${formatCash(+extractAmount(aiAnswer))} | ${aiAnswer}`
        : aiAnswer;
    case 'PERCENTAGE':
      return extractPercentage(aiAnswer)
        ? `${decimalToPercentage(extractPercentage(aiAnswer))} | ${aiAnswer}`
        : aiAnswer;
    case 'DATE':
      return extractAndFormatDate(aiAnswer)
        ? `${format(
            new Date(extractAndFormatDate(aiAnswer)),
            'LLL d, yyyy'
          )} | ${aiAnswer}`
        : aiAnswer;
    default:
      return aiAnswer;
  }
};

export const matchFieldWithAI = (field: ISectionFields): boolean => {
  switch (field.type) {
    case 'NUMBER':
      return Boolean(
        (field.data.value === null || field.data.commentary === null) &&
          (field.data.value !==
            extractNumbersFromString(field.AI?.answers[0]) ||
            field.data.commentary !== field.AI?.answers[0])
      );
    case 'DOLLAR':
      return Boolean(
        (field.data.value === null || field.data.commentary === null) &&
          (field.data.value !== extractAmount(field.AI?.answers[0]) ||
            field.data.commentary !== field.AI?.answers[0])
      );
    case 'PERCENTAGE':
      return Boolean(
        (field.data.value === null || field.data.commentary === null) &&
          (field.data.value !== extractPercentage(field.AI?.answers[0]) ||
            field.data.commentary !== field.AI?.answers[0])
      );
    case 'DATE':
      return Boolean(
        (field.data.value === null || field.data.commentary === null) &&
          (field.data.value !== extractAndFormatDate(field.AI?.answers[0]) ||
            field.data.commentary !== field.AI?.answers[0])
      );
    default:
      return Boolean(
        !field.data.value ||
          !field.data.value ||
          field.data?.value === field.AI?.answers[0]
      );
  }
};

export const splitTermsThreeColumns = (arr: ISectionFields[]) => {
  const partSize = Math.ceil(arr.length / 3);
  const parts = [];

  for (let i = 0; i < arr.length; i += partSize) {
    parts.push(arr.slice(i, i + partSize));
  }

  return parts;
};

export const truncateString = (
  string: string | null,
  maxLength: number
): string => {
  if (!string) return '';

  if (string.length <= maxLength) {
    return string;
  } else {
    return string.slice(0, maxLength) + '...';
  }
};
