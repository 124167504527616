import { TierDropdown } from '@/components/dueDiligence-page/dueDiligenceRecord/tierDropdown';
import Button from '@/components/fat-basicComponents/button';
import styled from 'styled-components';
import { SectionFields } from '../../editManagerUpdate/sectionFields';
import { IDocumentRecordEntity, IGetDocumentRecordQuery } from '../../types';

interface LatestUpdateSectionProps {
  documentRecordData: IGetDocumentRecordQuery | null;
  openModalWindow: (type: string, row?: IDocumentRecordEntity) => void;
  openEditManagerUpdatePage: (updateId: string) => void;
}

export const LatestUpdateSection = ({ documentRecordData, openModalWindow, openEditManagerUpdatePage }: LatestUpdateSectionProps) => {
  if (!documentRecordData) return <></>;

  return (
    <TierDropdown title={`Latest Update (${documentRecordData.name})`}>
      <SectionFields fields={documentRecordData?.fields ?? []} mode="view" />
      <ButtonWrapper>
        <Button styleType="outline" size="md" onClick={() => openModalWindow('download-pdf')}>
          Download
        </Button>
        <Button size="md" onClick={() => openEditManagerUpdatePage(documentRecordData.id)}>
          Open Update
        </Button>
      </ButtonWrapper>
    </TierDropdown>
  );
};

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
  padding: 20px 0;
`;
