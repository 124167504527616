import { IRowData } from './types';
import { OPERATIONS_STATUS } from '../opportunities-entity/proposed';
import { Step } from '../table/anchor-drop-down/stepLists/types';
import { statusNextStepRelations } from '../client-details/constants';

export const createListForLastUpdateHoverCard = (row: IRowData): Step[] => {
  const statuses = [
    {
      status: OPERATIONS_STATUS.READY_FOR_CLIENT_REVIEW,
      date: row.complianceApprovedAt,
    },
    {
      status: OPERATIONS_STATUS.SENT_TO_CLIENT,
      date: row.sentToClientAt,
    },
    {
      status: OPERATIONS_STATUS.CLIENT_APPROVED,
      date: row.clientApprovedAt,
    },
    {
      status: OPERATIONS_STATUS.SUBDOC_READY_FOR_REVIEW,
      date: row.subdocsGeneratedAt,
    },
    {
      status: OPERATIONS_STATUS.SUBDOC_READY_FOR_CLIENT,
      date: row.subdocsReviewedAt,
    },
    {
      status: OPERATIONS_STATUS.SUBDOC_SENT_TO_CLIENT,
      date: row.subdocsSentToClientAt,
    },
    {
      status: OPERATIONS_STATUS.SUBDOC_SIGNED_BY_CLIENT,
      date: row.subdocsSignedByClientAt,
    },
    {
      status: OPERATIONS_STATUS.SUBDOC_SENT_TO_FUND_MANAGER,
      date: row.subdocsSentToManagerAt,
    },
    {
      status: OPERATIONS_STATUS.SUBDOC_CONFIRMED_BY_FUND_MANAGER,
      date: row.subdocsCompletedAt,
    },
    {
      status: OPERATIONS_STATUS.COMMITMENT_SUBMITTED_TO_REPORTING_SYSTEM,
      date: row.commitmentSubmittedToReportingSystemAt,
    },
  ].map(({ status, date }, index, array) => {
    if (!array[index + 1]?.date && date) {
      return [
        { status, date },
        {
          status: statusNextStepRelations[status as StatusesWithoutAll],
          date: null,
        },
      ];
    }
    if (!array[index + 1]?.date && !date) {
      return { status: statusNextStepRelations[status as StatusesWithoutAll], date };
    }

    return { status, date };
  });
  return statuses.flat();
};

type StatusesWithoutAll = Extract<keyof typeof OPERATIONS_STATUS, OPERATIONS_STATUS.ALL_SUITABILITY>;

export const sortByAlphabet = (list: string[] = []) => {
  if (!list) return [];
  return list.sort((a: string, b: string) => {
    const lowerA = a.toLowerCase();
    const lowerB = b.toLowerCase();
    return lowerA.localeCompare(lowerB);
  });
};
