import Header from '@/components/fat-header';
import { GoBackButton } from '@/components/fat-header/goBackButton';
import { PageTitle } from '@/components/fat-header/pageTitle';
import { LoaderOnWholeScreen } from '@/components/loaders/loader-on-whole-screen';
import { useGoBack } from '@/hooks/useGoBack';
import { MainWrap } from '@/styles/common';
import { useQuery } from '@apollo/client';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import { INVESTMENT_QUERY } from '../fat-seekingInterest-page/queries';
import { Details } from './details';
import { OfferingMaterials } from './offeringMaterials';

export const OpportunityDetailsPage = () => {
  const goBack = useGoBack();
  const location = useLocation();
  const params = useParams();
  const [searchParams, setSearchParams] = useSearchParams();

  const backToTitle = searchParams.get('backToTitle') || '';

  const {
    data: investmentData,
    loading: loadingInvestment,
    error: errorInvestment
  } = useQuery(INVESTMENT_QUERY, {
    notifyOnNetworkStatusChange: true,
    variables: { id: params?.id },
    skip: !params?.id,
    onError: (error) => {
      console.error(error);
    }
  });

  if (loadingInvestment) {
    return <LoaderOnWholeScreen />;
  }

  return (
    <MainWrap>
      <Header
        modalControl={
          <GoBackButton
            handleClose={() => goBack({ fallBack: location.pathname.substring(0, location.pathname.indexOf('/', 1)) })}
            backToTitle={backToTitle}
          />
        }
      />
      <PageTitle title={investmentData?.Investment.name ?? ''} />
      <Details detailsData={investmentData?.Investment ?? null} />
      {(investmentData?.Investment.offeringMaterialsAttachments.length || investmentData?.Investment.offeringMaterialsDescription) && (
        <OfferingMaterialsWrap>
          <OfferingMaterials
            attachments={investmentData?.Investment.offeringMaterialsAttachments ?? []}
            description={investmentData?.Investment.offeringMaterialsDescription ?? ''}
          />
        </OfferingMaterialsWrap>
      )}
    </MainWrap>
  );
};

const OfferingMaterialsWrap = styled.div`
  margin: 20px 0;
  padding: 0 16px;
  @media (min-width: 600px) {
    padding: 0 60px;
  }
`;
