import * as React from 'react';

export const LogOutBurgerIcon = ({ ...props }: React.SVGProps<SVGSVGElement>) => (
  <svg width={props.width || 18} style={props?.style} height={props.height || 18} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.998 5.004L12.588 6.43801L14.166 8.028H6V10.026H14.166L12.588 11.604L13.998 13.026L18 9.02401L13.998 5.004ZM1.99801 2.004H9V0H1.99801C1.46756 0.00158819 0.959365 0.213436 0.584839 0.589087C0.210313 0.964738 -2.37752e-06 1.47354 0 2.004V16.002C0.00158272 16.5314 0.212608 17.0387 0.586963 17.4131C0.961318 17.7874 1.46859 17.9984 1.99801 18H9V16.002H1.99801V2.004Z"
      fill="#4587EC"
    />
  </svg>
);
