import CheckBox from '@/components/basicComponents/checkbox';
import FilterSearch from '@/components/basicComponents/input';
import { PrimaryFilterItem } from '@/components/basicComponents/select';
import { Dispatch, SetStateAction, useState } from 'react';
import styled from 'styled-components';
import { SelectColumnsWrapper } from './selectedColumnsList';
import { ColumnList } from './types';
import { handleSelect, selectColumns } from './utils';

interface ColumnsDisplayedListProps {
  newColumns: ColumnList[];
  setNewColumns: Dispatch<SetStateAction<ColumnList[]>>;
}

export const ColumnsDisplayed = ({ newColumns, setNewColumns }: ColumnsDisplayedListProps) => {
  const [search, setSearch] = useState('');

  return (
    <SelectColumnsWrapper isShadow>
      <div>
        <FilterSearch value={search} width="100%" onChange={(value) => setSearch(value)} placeholder={'Search Columns'} />
      </div>
      <SelectAction>
        <SelectAll isSelectAll onClick={() => handleSelect(true, newColumns, setNewColumns)}>
          Select All
        </SelectAll>
        <>|</>
        <SelectAll onClick={() => handleSelect(false, newColumns, setNewColumns)}>Deselect All</SelectAll>
      </SelectAction>
      <ChooseColumn>
        <CheckBoxLabel>Section Label</CheckBoxLabel>
        {[...newColumns]
          .filter((item) => item.title.toLowerCase().includes(search.toLowerCase()))
          .sort((a, b) => a.order - b.order)
          .map((el, index) => (
            <CustomPrimaryFilterItem id={'input'} key={el.title + index} onClick={() => selectColumns(el.keySort, newColumns, setNewColumns)}>
              <CheckBox id={'input'} isActive={el.value} /> {el.title}
            </CustomPrimaryFilterItem>
          ))}
      </ChooseColumn>
    </SelectColumnsWrapper>
  );
};

const SelectAction = styled.div`
  display: flex;
  gap: 3px;
  font-weight: 600;
  font-size: 13px;
  color: ${({ theme }) => theme.border.base};
  margin-top: 10px;
`;

const SelectAll = styled.div<{ isSelectAll?: boolean }>`
  color: ${({ isSelectAll, theme }) => (isSelectAll ? theme.font.action : theme.font.disabled)};
  cursor: pointer;
`;

const ChooseColumn = styled.div``;

const CustomPrimaryFilterItem = styled(PrimaryFilterItem)`
  display: flex;
  align-items: center;
  gap: 9px;
  font-weight: 400;
  font-size: 16px;
  color: ${({ theme }) => theme.font.base};
`;

const CheckBoxLabel = styled.div`
  padding: 10px 0;
  font-weight: 600;
  font-size: 16px;
  color: ${({ theme }) => theme.font.strong};
`;
