export const columns = [
  { title: 'Strategy', key: 'DEAL', width: 24, keySort: 'strategy' },
  { title: 'Manager', key: 'MANAGER', width: 15, keySort: 'manager' },
  { title: 'Aggregate Interest', key: 'AGGREGATE_INTEREST', width: 8, keySort: 'aggregate interest' },
  { title: 'Aggregate Commitment', key: 'AGGREGATE_COMMITMENT', width: 8, keySort: 'aggregate commitment' },
  { title: 'Close Date', key: 'CLOSE_DATE', width: 11, keySort: 'close date' },
  { title: 'Assignee', key: 'ASSIGNEE', width: 11, keySort: 'assignee' },
  { title: 'Last Update', key: 'LAST_UPDATED', width: 11, keySort: 'last update' },
  { title: 'Status', key: 'NEXT_TASK', width: 12, keySort: 'status' }
];

export const dealPipelineSelectors = [
  { key: 'ALL', value: 'All Assignees' },
  { key: 'ASSIGN_TO_ME', value: 'Assigned To Me' },
  { key: 'OWNED_BY_ME', value: 'Owned By Me' },
  { key: 'OWNED_BUT_ASSIGNED_TO_OTHER', value: 'Owned By Me Assigned To Someone Else' }
];

export const securityStatuses = ['DRAFT', 'IN_RESEARCH', 'OTHER_RECOMMENDED', 'APPROVED', 'OPEN', 'ACTIVE', 'REALIZED'];

export const initialInvestmentStatusFilter = [
  {
    type: 'statusFilter',
    name: 'Draft',
    value: 'Draft',
    enabled: true,
    count: 0
  },
  {
    type: 'statusFilter',
    name: 'In Research',
    value: 'In Research',
    enabled: true,
    count: 0
  },
  {
    type: 'statusFilter',
    name: 'Recommended',
    value: 'Other Recommended',
    enabled: true,
    count: 0
  },
  {
    type: 'statusFilter',
    name: 'Seeking Interest',
    value: 'Approved',
    enabled: true,
    count: 0
  },
  {
    type: 'statusFilter',
    name: 'Accepting Commitments',
    value: 'Open',
    enabled: true,
    count: 0
  },
  {
    type: 'statusFilter',
    name: 'Closed',
    value: 'Active',
    enabled: false,
    count: 0
  },
  {
    type: 'statusFilter',
    name: 'Fully Realized',
    value: 'Realized',
    enabled: false,
    count: 0
  },
  {
    type: 'statusFilter',
    name: 'Terminated',
    value: 'Terminated',
    enabled: false,
    count: 0
  }
];

export const dealPipelineMobileSort = [
  {
    title: 'Strategy • A -> Z',
    key: 'DEAL',
    asc: true
  },
  {
    title: 'Strategy • Z -> A',
    key: 'DEAL',
    asc: false
  },
  {
    title: 'Manager • A -> Z',
    key: 'MANAGER',
    asc: true
  },
  {
    title: 'Manager • Z -> A',
    key: 'MANAGER',
    asc: false
  },
  {
    title: 'Aggregate Interest • Max -> Min',
    key: 'AGGREGATE_INTEREST',
    asc: false
  },
  {
    title: 'Aggregate Interest • Min -> Max',
    key: 'AGGREGATE_INTEREST',
    asc: true
  },
  {
    title: 'Aggregate Commitment • Max -> Min',
    key: 'AGGREGATE_COMMITMENT',
    asc: false
  },
  {
    title: 'Aggregate Commitment • Min -> Max',
    key: 'AGGREGATE_COMMITMENT',
    asc: true
  },
  {
    title: 'Close Date • Sooner -> Later',
    key: 'CLOSE_DATE',
    asc: true
  },
  {
    title: 'Close Date  • Later -> Sooner',
    key: 'CLOSE_DATE',
    asc: false
  },
  {
    title: 'Assignee • A -> Z',
    key: 'ASSIGNEE',
    asc: true
  },
  {
    title: 'Assignee • Z -> A',
    key: 'ASSIGNEE',
    asc: false
  },
  {
    title: 'Last Update • Newest -> Oldest',
    key: 'LAST_UPDATED',
    asc: false
  },
  {
    title: 'Last Update • Oldest -> Newest',
    key: 'LAST_UPDATED',
    asc: true
  },
  {
    title: 'Status • A -> Z',
    key: 'NEXT_TASK',
    asc: false
  },
  {
    title: 'Status • Z -> A',
    key: 'NEXT_TASK',
    asc: true
  }
];
