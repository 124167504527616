import FormattingTooltip from '@/components/basicComponents/tooltip/dollarsTooltip';
import {
  MobileRow,
  MobileRowTitle,
  MobileRowValue,
  MobileTableRowWrap,
  MobileTableWrapper,
  NoResults
} from '@/components/table/mobileTable/mobileTable';
import { TableColumns } from '@/components/table/types';
import { InvestmentManager } from '@/types/investments';
import guid from '@/utils/guid';
import MobileSumFooter from '../mobileSumFooter';

type MobileTableProps = {
  columns: TableColumns[];
  tableData: InvestmentManager[];
  loading: boolean | undefined;
  handleManagerClick: (name: string) => void;
};

const MobileTable = ({ columns, tableData, loading, handleManagerClick }: MobileTableProps) => {
  const TableRow = ({ el }: { el: InvestmentManager }) => {
    return (
      <MobileTableRowWrap key={guid()}>
        <MobileRow>
          <MobileRowTitle>{columns[0].title}</MobileRowTitle>
          <MobileRowValue isRedirect onClick={() => handleManagerClick(el.name)}>
            {el.name}
          </MobileRowValue>
        </MobileRow>

        <MobileRow>
          <MobileRowTitle>{columns[1].title}</MobileRowTitle>
          <MobileRowValue>{el.countOfFunds}</MobileRowValue>
        </MobileRow>

        <MobileRow>
          <MobileRowTitle>{columns[2].title}</MobileRowTitle>
          <MobileRowValue>{el.countOfInvestors}</MobileRowValue>
        </MobileRow>

        <MobileRow>
          <MobileRowTitle>{columns[3].title}</MobileRowTitle>
          <MobileRowValue>
            <FormattingTooltip key={guid()}>{el.committedCapital}</FormattingTooltip>
          </MobileRowValue>
        </MobileRow>
      </MobileTableRowWrap>
    );
  };

  return (
    <MobileTableWrapper>
      {tableData?.length ? (
        <>
          {tableData.map((el, index) => {
            return <TableRow el={el} key={guid()} />;
          })}
          <MobileSumFooter tableData={tableData} />
        </>
      ) : (
        <NoResults>No Results</NoResults>
      )}
    </MobileTableWrapper>
  );
};

export default MobileTable;
