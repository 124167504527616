import { UploadFileIcon } from '@/assets/static/icons/upload-file';
import { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';

interface DropFileAreaProps {
  id: string;
  fileExtensions?: string[];
  selectedProcessingOption?: string;
  handleUploadFile: (e: React.ChangeEvent<HTMLInputElement>, selectedProcessingOption?: string) => void;
  onDropHandler: (e: React.DragEvent<HTMLDivElement>, selectedProcessingOption?: string) => void;
  drag: boolean;
  setDrag: Dispatch<SetStateAction<boolean>>;
  maxSize?: number;
  onClose?: () => void;
  withIcon?: boolean;
}

export const DropFileArea = ({
  fileExtensions,
  handleUploadFile,
  onDropHandler,
  setDrag,
  drag,
  id,
  selectedProcessingOption,
  maxSize,
  onClose,
  withIcon = false
}: DropFileAreaProps) => {
  const dragStartHandler = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setDrag(true);
  };

  const dragLeaveHandler = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setDrag(false);
  };

  return (
    <>
      {drag ? (
        <ActiveDrop
          isFileExtensions={Boolean(fileExtensions)}
          isMaxSize={Boolean(maxSize)}
          onDragStart={(e) => dragStartHandler(e)}
          onDragLeave={(e) => dragLeaveHandler(e)}
          onDragOver={(e) => dragStartHandler(e)}
          onDrop={(e) => onDropHandler(e, selectedProcessingOption)}
        >
          <ActiveDropAreaTitle>Drop ...</ActiveDropAreaTitle>
        </ActiveDrop>
      ) : (
        <DropArea
          onDragStart={(e) => dragStartHandler(e)}
          onDragLeave={(e) => dragLeaveHandler(e)}
          onDragOver={(e) => dragStartHandler(e)}
          onDrop={(e) => onDropHandler(e, selectedProcessingOption)}
        >
          {onClose && <Cross src="/static/icons/blue-cross.svg" alt="Cross" onClick={onClose} />}
          {withIcon && <UploadFileIcon />}
          <DropAreaTitle>
            Drop File Here or
            <Browse htmlFor={id}>
              <UploadFile type="file" id={id} multiple onChange={(e) => handleUploadFile(e, selectedProcessingOption)} hidden />
              {' Browse'}
            </Browse>
          </DropAreaTitle>
          {fileExtensions && (
            <FileExtensionsWrapper>
              {fileExtensions.map((extension, index) => (
                <FileExtension key={extension + index}>{extension}</FileExtension>
              ))}
            </FileExtensionsWrapper>
          )}
          {maxSize && (
            <MaxFileSize>
              Max File Size <Bold>{maxSize ?? 10} MB</Bold>
            </MaxFileSize>
          )}
        </DropArea>
      )}
    </>
  );
};

const DropArea = styled.div`
  margin-top: 10px;
  width: 100%;
  border: 1px dashed ${({ theme }) => theme.border.base};
  background-color: ${({ theme }) => theme.layer[1]};
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  padding: 20px 0;
  position: relative;
`;

const ActiveDrop = styled(DropArea)<{ isFileExtensions?: boolean; isMaxSize?: boolean }>`
  padding-top: ${({ isFileExtensions, isMaxSize }) => (isFileExtensions ? (isMaxSize ? '51px' : '36px') : isMaxSize ? '60px' : '45px')};
  padding-bottom: ${({ isFileExtensions, isMaxSize }) => (isFileExtensions ? (isMaxSize ? '51.5px' : '36.5px') : isMaxSize ? '60px' : '44.5px')};
`;

const DropAreaTitle = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: ${({ theme }) => theme.font.weak};
`;

const ActiveDropAreaTitle = styled(DropAreaTitle)`
  font-size: 17px;
`;

const Cross = styled.img`
  position: absolute;
  top: 13px;
  right: 17px;
  cursor: pointer;
`;

const UploadFile = styled.input``;

const Browse = styled.label`
  font-weight: 600;
  color: ${({ theme }) => theme.action.primary};
  cursor: pointer;
`;

const FileExtensionsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0 10px;
  gap: 4px;
`;

const FileExtension = styled.div`
  font-size: 13px;
  line-height: 18px;
  color: ${({ theme }) => theme.font.weak};
  background: ${({ theme }) => theme.layer[2]};
  border-radius: 4px;
  padding: 2.5px 10px;
`;

const MaxFileSize = styled.span`
  font-size: 13px;
  color: ${({ theme }) => theme.font.weak};
  font-weight: 400;
`;

const Bold = styled.span`
  font-weight: 600;
`;
