import { AiIcon } from '@/components/dueDiligence-page/DDR-PDF/AiIcon';
import { templateStyles } from '@/components/dueDiligence-page/DDR-PDF/PDFTemplate';
import { getFontByKey, getTermFontWeight } from '@/components/dueDiligence-page/DDR-PDF/utils';
import { ISetting } from '@/components/settings-page/settings-pages/firmSettings/types';
import { Image as PdfImage, StyleSheet, Text, View } from '@react-pdf/renderer';
import { IDocumentRecordFieldEntity } from '../../types';
import { aiFieldValueMu, fieldValueMu } from './utils';

interface OneColumnSectionProps {
  fields: IDocumentRecordFieldEntity[];
  imageSizes: { [url: string]: { width: number; height: number } };
  templateStylingData: ISetting[];
  includeAiSuggested: boolean;
}

export const OneColumnSection = ({ fields, imageSizes, templateStylingData, includeAiSuggested }: OneColumnSectionProps) => {
  const styles = StyleSheet.create({
    sectionName: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      fontSize: 20,
      fontFamily: getFontByKey(templateStylingData, 'headerFontStyle').fontFamily
        ? getFontByKey(templateStylingData, 'headerFontStyle').fontFamily
        : 'Roboto Slab',
      color: Boolean(getFontByKey(templateStylingData, 'headerFontStyle').fontColor)
        ? `#${getFontByKey(templateStylingData, 'headerFontStyle').fontColor}`
        : '#35739C',
      fontWeight: getFontByKey(templateStylingData, 'headerFontStyle').fontWeight
        ? getFontByKey(templateStylingData, 'headerFontStyle').fontWeight
        : 400,
      paddingBottom: '4px',
      borderBottom: '2px solid #F0F1F3',
      marginBottom: '10px'
    },
    fieldName: {
      fontSize: 12,
      fontFamily: getFontByKey(templateStylingData, 'bodyFontStyle').fontFamily
        ? getFontByKey(templateStylingData, 'bodyFontStyle').fontFamily
        : 'Open Sans',
      color: Boolean(getFontByKey(templateStylingData, 'bodyFontStyle').fontColor)
        ? `#${getFontByKey(templateStylingData, 'bodyFontStyle').fontColor}`
        : '#757575',
      fontWeight: getTermFontWeight(templateStylingData, 'bodyFontStyle') as number
    },
    fieldValue: {
      fontSize: 9,
      fontFamily: getFontByKey(templateStylingData, 'bodyFontStyle').fontFamily
        ? getFontByKey(templateStylingData, 'bodyFontStyle').fontFamily
        : 'Open Sans',
      color: Boolean(getFontByKey(templateStylingData, 'bodyFontStyle').fontColor)
        ? `#${getFontByKey(templateStylingData, 'bodyFontStyle').fontColor}`
        : '#757575',
      fontWeight: getFontByKey(templateStylingData, 'bodyFontStyle').fontWeight ? getFontByKey(templateStylingData, 'bodyFontStyle').fontWeight : 600,
      paddingTop: '2px',
      lineHeight: '1.3px'
    },
    aiIconWrap: {
      display: 'flex',
      flexDirection: 'row',
      fontSize: '7px',
      fontFamily: getFontByKey(templateStylingData, 'bodyFontStyle').fontFamily
        ? getFontByKey(templateStylingData, 'bodyFontStyle').fontFamily
        : 'Open Sans',
      color: Boolean(getFontByKey(templateStylingData, 'bodyFontStyle').fontColor)
        ? `#${getFontByKey(templateStylingData, 'bodyFontStyle').fontColor}`
        : '#757575',
      fontWeight: getTermFontWeight(templateStylingData, 'bodyFontStyle') as number
    }
  });

  return (
    <View
      break={Boolean(
        fields.length === 1 && !fields[0].value && fields[0].attachments.filter((attachment) => attachment.includeInAutomatedContent).length
      )}
    >
      {fields
        .filter(
          (field) =>
            (field.value && field.value !== field.AI?.answers[0]) ||
            (field.AI?.answers.length && includeAiSuggested && (field.value === null || field.value === field.AI?.answers[0])) ||
            field.attachments.filter((attachment) => attachment.includeInAutomatedContent).length
        )
        .map((field) => (
          <View key={field.id} style={{ marginBottom: '25px' }}>
            <View wrap={Boolean((field.value && field.value.length > 6000) || (field.AI?.answers.length && field.AI?.answers[0].length > 6000))}>
              {field.value || field.commentary || field.attachments.filter((attachment) => attachment.includeInAutomatedContent).length ? (
                <View style={{ marginBottom: '25px' }}>
                  <Text style={styles.fieldName}>{field.documentField.name}</Text>
                  <Text style={styles.fieldValue}>{fieldValueMu(field.documentField.type, field.value, field.commentary)}</Text>
                </View>
              ) : null}
              {field.value === null && field.commentary === null && field.AI?.answers.length && includeAiSuggested ? (
                <View style={{ marginBottom: '25px' }}>
                  {fields.length > 1 ? <Text style={styles.fieldName}>* {field.documentField.name}</Text> : null}
                  <Text style={styles.fieldValue}>{aiFieldValueMu(field.documentField.type, field.AI.answers[0])}</Text>
                </View>
              ) : null}
            </View>
            <View
              style={{
                ...templateStyles.previewWrapper,
                marginBottom: fields.length > 1 && field.attachments.filter((attachment) => attachment.includeInAutomatedContent).length ? '25px' : 0
              }}
            >
              {field.attachments
                .sort((attachment1, attachment2) => {
                  const width1 = imageSizes[attachment1.secure_blob_storage_asset.url]?.width || 0;
                  const width2 = imageSizes[attachment2.secure_blob_storage_asset.url]?.width || 0;
                  return width2 - width1;
                })
                .map((attachment) => {
                  return attachment.includeInAutomatedContent ? (
                    <View
                      key={attachment.id}
                      style={{
                        ...templateStyles.imgPreviewWrap,
                        width:
                          imageSizes[attachment.secure_blob_storage_asset.url]?.width > 690
                            ? '100%'
                            : imageSizes[attachment.secure_blob_storage_asset.url]?.width,
                        height:
                          imageSizes[attachment.secure_blob_storage_asset.url]?.height > 400
                            ? 'auto'
                            : imageSizes[attachment.secure_blob_storage_asset.url]?.height
                      }}
                    >
                      <PdfImage
                        style={{
                          padding: '2px',
                          border: '1px solid #F0F1F3'
                        }}
                        src={attachment.secure_blob_storage_asset.url}
                      />
                    </View>
                  ) : null;
                })}
            </View>
          </View>
        ))}
      {fields.filter((field) => field.value === null && field.commentary === null && field.AI?.answers.length).length && includeAiSuggested ? (
        <View style={styles.aiIconWrap}>
          <View
            style={{
              borderRight: '1px solid #C7CBD2',
              paddingRight: '3px'
            }}
          >
            <AiIcon />
          </View>
          <Text style={{ margin: '2px 0 0 5px' }}>{fields.length > 1 ? '* These fields are AI Suggested' : 'This field is AI Suggested'}</Text>
        </View>
      ) : null}
    </View>
  );
};
