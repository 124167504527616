export interface IListData {
  list: string[];
  chosen: string[];
  duplicate: string;
}

export interface IForm {
  name: string;
  manager: string;
  assetClass: string;
  subAssetClass: string;
  pacingType: PACING_TYPE | null;
  pacingStartingYear: string | null;
  pacingStartingQuarter: string | null;
  minimum: string;
  // custodied: string;
  ubtiBlocker: string;
  sri: string;
  nextClose: string | null;
  finalClose: string | null;
  investorDocsDue: string | null;
  targetIRR: string | number;
  targetYield: string | number;
  vintage: string | number;
  type: string;
  investmentOwner: string;
  operationsOwner: string;
  complianceOwner: string;
  description: string;
}

export enum PACING_TYPE {
  DRAWDOWN = 'Drawdown',
  EVERGREEN = 'Evergreen'
}
