import { gql } from '@apollo/client';

export const ClientDetailsSummary = gql`
  query ClientEntityDetailsSummary($id: String!, $familyId: String!, $includeRecommendation: Boolean, $sort: String, $order: String) {
    ClientEntityDetailsSummary(id: $id, familyId: $familyId, includeRecommendation: $includeRecommendation, sort: $sort, order: $order) {
      name
      totalEntitiesValue
      privatePercentage
      targetOCRatio
      currentOCRatio
      notes {
        title
        notes
        notesDate
      }
      alwaysShow
      biteSize {
        fundBiteSize
        directBiteSize
      }
      onHold
      custodied
      ubtiBlocker
      impact
      sectorsToAvoid
      progressBarValues {
        economicExposure
        totalCapitalTarget
        capitalTarget
        isGoalAchieved
      }
      summary {
        privateRealAssetPercentage
        privateEquityPercentage
        privateCreditPercentage
        privateEquityTargetAllocations
        privateCreditTargetAllocations
        privateRealAssetTargetAllocations
        economicExposureForPrivateCredit
        economicExposureForPrivateRealAssets
        economicExposureForPrivateEquity
        privateEquityCapacity
        privateCreditCapacity
        privateRealAssetsCapacity
        overallCapacity
      }
    }
  }
`;

export const ClientDetailsCommitments = gql`
  query ClientEntityDetailsCommitments(
    $id: String!
    $familyId: String!
    $includeRecommendation: Boolean
    $sort: String
    $order: String
    $search: String
  ) {
    ClientEntityDetailsCommitments(
      id: $id
      familyId: $familyId
      includeRecommendation: $includeRecommendation
      sort: $sort
      order: $order
      search: $search
    ) {
      name
      totalEntitiesValue
      privatePercentage
      targetOCRatio
      currentOCRatio
      biteSize {
        fundBiteSize
        directBiteSize
      }
      notes {
        title
        notes
        notesDate
      }
      alwaysShow
      onHold
      custodied
      ubtiBlocker
      impact
      sectorsToAvoid
      privateRealAssetPercentage
      privateEquityPercentage
      privateCreditPercentage
      privateEquityCapacity
      privateCreditCapacity
      privateRealAssetsCapacity
      commitments {
        id
        investmentId
        name
        committedCapital
        capitalCalled
        capitalRemaining
        distributionsToDate
        marketValue
        status
        moreInfo {
          id
          managerId
          assetClass
          nextClose
          finalClose
          minimum
          custodied
          ubtiBlocker
          sri
          targetIRR
          targetYield
        }
      }
    }
  }
`;

export const ClientsDetailsAllocations = gql`
  query ClientEntityDetailsAllocations($id: String!, $familyId: String!, $includeRecommendation: Boolean, $sort: String, $order: String) {
    ClientEntityDetailsAllocations(id: $id, familyId: $familyId, includeRecommendation: $includeRecommendation, sort: $sort, order: $order) {
      name
      totalEntitiesValue
      privatePercentage
      targetOCRatio
      currentOCRatio
      notes {
        title
        notes
        notesDate
      }
      biteSize {
        fundBiteSize
        directBiteSize
      }
      ubtiBlocker
      custodied
      alwaysShow
      onHold
      impact
      sectorsToAvoid
      privateRealAssetPercentage
      privateEquityPercentage
      privateCreditPercentage
      privateEquityCapacity
      privateCreditCapacity
      privateRealAssetsCapacity
      allocations {
        name
        suitability
        status
        finalClose
        assetClass
        minimum
        suitabilityCriteria {
          label
          value
        }
      }
    }
  }
`;
