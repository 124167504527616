import * as React from 'react';

export const CloseMenuIcon = ({ ...props }: React.SVGProps<SVGSVGElement>) => (
  <svg
    onClick={props.onClick || undefined}
    width={props.width || 22}
    style={props?.style}
    height={props.height || 17}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18 1.81312L16.1869 0L9.00385 7.18303L1.81312 0L0 1.81312L7.19075 8.99614L0 16.1869L1.81312 18L9.00385 10.8093L16.1869 18L18 16.1869L10.817 8.99614L18 1.81312Z"
      fill={props?.fill || '#4587EC'}
    />
  </svg>
);
