import TableHeader from '@/components/table/fat-tableHeader';
import { useResponsive } from '@/hooks/use-responsive';
import { useRef } from 'react';
import styled from 'styled-components';

import { Loader } from '@/components/loaders/loader';
import { TableBodyWrapper } from '@/components/table/styles';
import { TableColumns } from '@/components/table/types';
import useStickyFooter from '@/hooks/useStickyFooter';
import { TransformedAssetClassForecastValueDto } from '../../../types';
import MobileTable from './mobileTable';
import TableBody from './tableBody';
import TableSumFooter from './tableSumFooter';

interface AssetClassTableProps {
  contributionsTableData: TransformedAssetClassForecastValueDto[];
  distributionsTableData: TransformedAssetClassForecastValueDto[];
  tableHeaderColumns: TableColumns[];
  loading: boolean;
  columnWidth: number;
  targetSubAssetClasses: boolean;
}

const AssetClassTable = ({
  contributionsTableData,
  distributionsTableData,
  tableHeaderColumns,
  loading,
  columnWidth,
  targetSubAssetClasses
}: AssetClassTableProps) => {
  const { isMobile, isTablet } = useResponsive();

  const containerRef = useRef<HTMLDivElement | null>(null);
  const footerRef = useRef<HTMLDivElement | null>(null);

  useStickyFooter(containerRef, footerRef);

  return (
    <>
      {isMobile ? (
        <>{loading ? <Loader /> : <MobileTable contributionsTableData={contributionsTableData} distributionsTableData={distributionsTableData} />}</>
      ) : (
        <>
          <CustomTableHeader refetch={() => {}} withOutSort columns={tableHeaderColumns} />
          <TableBodyWrapper>
            {loading ? (
              <Loader />
            ) : (
              <>
                <TableBody
                  contributionsTableData={contributionsTableData}
                  distributionsTableData={distributionsTableData}
                  columnWidth={columnWidth}
                  targetSubAssetClasses={targetSubAssetClasses}
                />
                <TableSumFooterWrapper ref={footerRef}>
                  <TableSumFooter
                    contributionsTableData={contributionsTableData}
                    distributionsTableData={distributionsTableData}
                    columnWidth={columnWidth}
                  />
                </TableSumFooterWrapper>
                <div ref={containerRef}></div>
              </>
            )}
          </TableBodyWrapper>
        </>
      )}
    </>
  );
};

export default AssetClassTable;

const CustomTableHeader = styled(TableHeader)`
  #tableColumnTitle {
    flex-basis: auto;
  }
`;

const TableSumFooterWrapper = styled.div`
  &.sticky {
    position: sticky;
    bottom: 0;
    z-index: 1;
  }
`;
