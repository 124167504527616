import styled, { css } from 'styled-components';

import TableHeader from '@/components/table/fat-tableHeader';
import { ISort } from '@/components/table/types';
import { useResponsive } from '../../../hooks/use-responsive';
import { Loader } from '../../loaders/loader';
import TablePagination from '../../table/tablePagination';
import { columns } from '../constants';
import { IClientEntitiesWithGuid } from '../types';
import MobileTable from './mobileTable';
import { TableBody } from './tableBody';

type ClientDetailsTable = {
  clientsRows: IClientEntitiesWithGuid[] | undefined;
  loading: boolean;
  paginationSaver: (limit: number, offset: number) => void;
  paginationValues: {
    limit: number;
    offset: number;
    total: number;
  };
  refetch: any;
  handleClickClient: (value: string, name: string) => void;
  onRowClick: (data: IClientEntitiesWithGuid) => void;
  savedSort: ISort;
  savedSetSort: (value: ISort) => void;
};

const Table = ({
  clientsRows,
  loading,
  refetch,
  paginationSaver,
  paginationValues,
  handleClickClient,
  onRowClick,
  savedSort,
  savedSetSort
}: ClientDetailsTable) => {
  const { isMobile, isTablet } = useResponsive();

  return (
    <TableWrapper isMobile={isMobile} padding={isMobile ? '10px 0 30px' : '30px 0'}>
      {isMobile ? (
        <MobileTable onRowClick={onRowClick} columns={columns} clientsRows={clientsRows} handleClickClient={handleClickClient} loading={loading} />
      ) : (
        <>
          <TableContainer>
            <CustomTableHeader isTablet={isTablet} columns={columns} refetch={refetch} savedSort={savedSort} savedSetSort={savedSetSort} />
            {!loading && clientsRows ? <TableBody handleClickClient={handleClickClient} clientsRows={clientsRows} /> : <></>}
          </TableContainer>
          {loading ? (
            <Loader />
          ) : clientsRows ? (
            <PaginationWrap>
              <TablePagination savePagination={paginationSaver} paginationValues={paginationValues} refetch={refetch} />
            </PaginationWrap>
          ) : null}
        </>
      )}
    </TableWrapper>
  );
};

const PaginationWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

const TableWrapper = styled.div<{ padding?: string; isMobile: boolean }>`
  display: flex;
  ${({ isMobile }) =>
    isMobile
      ? ''
      : css`
          height: calc(100vh - 400px);
          justify-content: space-between;
        `};
  width: 100%;
  flex-direction: column;
  gap: 6px;
  padding: ${({ padding }) => padding ?? '10px 0 30px'};
`;

const TableContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 8px;
`;

const CustomTableHeader = styled(TableHeader)<{ isTablet: boolean }>`
  position: sticky;
  top: ${({ isTablet }) => (isTablet ? '49px' : '0')};
  z-index: 8;
`;

export default Table;
