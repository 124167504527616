import TableHeader from '@/components/table/fat-tableHeader';
import { ISort } from '@/components/table/types';
import { useResponsive } from '@/hooks/use-responsive';
import styled from 'styled-components';

import { Loader } from '@/components/loaders/loader';
import { TableBodyWrapper, TableWrapper } from '@/components/table/styles';
import { Investment } from '../../types';
import { columns } from '../constatnts';
import { IInvestmentEntity } from '../types';
import MobileTable from './mobileTable';
import TableBody from './tableBody';

interface InvestorsTableProps {
  sort: ISort;
  setSort: (value: ISort) => void;
  loading: boolean;
  openModalWindow: (type: string, row: IInvestmentEntity) => void;
  listInvestmentEntity: IInvestmentEntity[];
  selectRow: (row: any) => void;
  selectAllRows: (isChecked: boolean) => void;
  openInvestorDetails: (id: string) => void;
  openEntityDetails: (investorId: string, entityId: string) => void;
  investment: Investment | null;
  openMobileModal: (data: IInvestmentEntity, modalType: string) => void;
}

const InvestorsTable = ({
  sort,
  setSort,
  loading,
  openModalWindow,
  listInvestmentEntity,
  selectRow,
  selectAllRows,
  openInvestorDetails,
  openEntityDetails,
  investment,
  openMobileModal
}: InvestorsTableProps) => {
  const { isMobile, isTablet } = useResponsive();

  return (
    <TableWrapper padding="0">
      {isMobile ? (
        <>
          {loading ? (
            <Loader />
          ) : (
            <MobileTable
              listInvestmentEntity={listInvestmentEntity}
              openInvestorDetails={openInvestorDetails}
              openMobileModal={openMobileModal}
              openEntityDetails={openEntityDetails}
            />
          )}
        </>
      ) : (
        <>
          <CustomTableHeader
            isTablet={isTablet}
            refetch={() => null as any}
            columns={columns}
            savedSort={sort}
            savedSetSort={setSort}
            checkBox={{
              checked: listInvestmentEntity.every((row) => row.checked) && !!listInvestmentEntity.length,
              onChange: selectAllRows,
              show: true
            }}
          />
          <TableBodyWrapper>
            {loading ? (
              <Loader />
            ) : (
              <TableBody
                listInvestmentEntity={listInvestmentEntity}
                openModalWindow={openModalWindow}
                selectRow={selectRow}
                openInvestorDetails={openInvestorDetails}
                openEntityDetails={openEntityDetails}
                investment={investment}
              />
            )}
          </TableBodyWrapper>
        </>
      )}
    </TableWrapper>
  );
};

export default InvestorsTable;

const CustomTableHeader = styled(TableHeader)<{ isTablet: boolean }>`
  position: sticky;
  top: ${({ isTablet }) => (isTablet ? '130px' : '80px')};
  z-index: 8;
  padding-top: 0;
`;
