import TableHeader from '@/components/table/fat-tableHeader';
import useStickyFooter from '@/hooks/useStickyFooter';
import { useRef } from 'react';
import styled from 'styled-components';
import { useResponsive } from '../../../hooks/use-responsive';
import { Loader } from '../../loaders/loader';
import { TableWrapper } from '../../table/styles';
import { columnsSummary } from '../constants';
import { SummaryTableData } from '../types';
import MobileTable from './mobileTable';
import { TableBody } from './tableBody';
import TableSumFooter from './tableSumFooter';

type ClientSummaryTable = {
  clientsRows: SummaryTableData[] | undefined;
  loading: boolean;
  refetch: any;
};

const TableSummary = ({ clientsRows, loading, refetch }: ClientSummaryTable) => {
  const { isMobile, isTablet } = useResponsive();

  const containerRef = useRef<HTMLDivElement | null>(null);
  const footerRef = useRef<HTMLDivElement | null>(null);

  useStickyFooter(containerRef, footerRef);

  return (
    <TableWrapper padding="30px 0">
      {isMobile ? (
        <MobileTable columns={columnsSummary} clientsRows={clientsRows} loading={loading} />
      ) : (
        <>
          <CustomTableHeader isTablet={isTablet} withOutSort columns={columnsSummary} refetch={refetch} />
          {loading ? (
            <Loader />
          ) : clientsRows ? (
            <>
              <TableBody clientsRows={clientsRows} amountColumns={columnsSummary.length} />
              <TableSumFooterWrapper ref={footerRef}>
                <TableSumFooter clientsRows={clientsRows} amountColumns={columnsSummary.length} />
              </TableSumFooterWrapper>
              <div ref={containerRef}></div>
            </>
          ) : null}
        </>
      )}
    </TableWrapper>
  );
};

export default TableSummary;

const CustomTableHeader = styled(TableHeader)<{ isTablet: boolean }>`
  position: sticky;
  top: ${({ isTablet }) => (isTablet ? '49px' : '0')};
  z-index: 8;
`;

const TableSumFooterWrapper = styled.div`
  &.sticky {
    position: sticky;
    bottom: 0;
    z-index: 1;
  }
`;
