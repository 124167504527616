import * as React from 'react';

export const CommittedIcon = ({ ...props }: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.0066 1.97893e-06C8.83043 -0.00130306 6.70281 0.642894 4.89286 1.85109C3.08291 3.05928 1.67199 4.77719 0.838607 6.78745C0.0052257 8.7977 -0.213187 11.01 0.21104 13.1444C0.635267 15.2788 1.68303 17.2394 3.22181 18.7782C4.76058 20.3169 6.72121 21.3647 8.85561 21.789C10.99 22.2132 13.2023 21.9948 15.2126 21.1614C17.2228 20.328 18.9407 18.9171 20.1489 17.1072C21.3571 15.2972 22.0013 13.1696 22 10.9934C21.9982 8.0783 20.8394 5.28311 18.7782 3.22183C16.7169 1.16055 13.9217 0.00175016 11.0066 1.97893e-06ZM11.0066 19.796C9.26531 19.7973 7.56278 19.2822 6.11432 18.3157C4.66587 17.3493 3.53658 15.975 2.86932 14.3666C2.20206 12.7582 2.02677 10.9881 2.36568 9.28015C2.70458 7.57218 3.54244 6.00306 4.77324 4.77133C6.00405 3.5396 7.57255 2.70059 9.28027 2.36041C10.988 2.02022 12.7582 2.19414 14.3671 2.8602C15.976 3.52626 17.3511 4.65452 18.3187 6.10225C19.2862 7.54997 19.8026 9.25212 19.8026 10.9934C19.7991 13.3258 18.8715 15.5618 17.2229 17.2116C15.5743 18.8615 13.339 19.7908 11.0066 19.796ZM16.0546 6.13678L8.79604 13.3953L5.95202 10.5579L4.4013 12.1086L8.79604 16.5099L17.5987 7.70727L16.0546 6.13678Z"
        fill="#ABABAB"
      />
    </svg>
  );
};
