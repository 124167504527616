import FormattingTooltip from '@/components/basicComponents/tooltip/dollarsTooltip';
import { ClientDetailsSummary } from '@/components/fat-investors-page/fat-investorDetails-page/fat-entityDetails-page/queries';
import { Loader } from '@/components/loaders/loader';
import { InfoList } from '@/components/table/anchor-drop-down/infoList/fat-index';
import { InfoListRow } from '@/components/table/anchor-drop-down/infoList/types';
import { useAuth } from '@/hooks/use-auth';
import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';

interface EntityPopoverProps {
  id: string;
  allocation: any;
  type: string;
  alwaysShow: boolean;
  tooltipContent: string;
}

const EntityPopover = ({ id, allocation, type, alwaysShow, tooltipContent }: EntityPopoverProps) => {
  const { user } = useAuth();

  const [switcher, setSwitcher] = useState(true);

  const { data, refetch, loading } = useQuery(ClientDetailsSummary, {
    notifyOnNetworkStatusChange: true,
    variables: {
      id: allocation.legalEntity.id,
      familyId: allocation.legalEntity.family.name,
      includeRecommendation: switcher,
      limit: 50,
      offset: 0
    }
  });

  const windowHeight = window.innerHeight;
  const isTwoColumn = windowHeight - 215 <= 600;

  useEffect(() => {
    setSwitcher(true);
    refetch();
  }, []);

  const changeSwitcher = () => {
    setSwitcher(!switcher);
    refetch({
      id: allocation.legalEntity.id,
      familyId: allocation.legalEntity.family.name,
      includeRecommendation: !switcher
    });
  };

  if (data === undefined || loading) {
    return <Loader />;
  }

  const simplifiedEntityPopover: InfoListRow[] = [
    {
      name: 'On Hold',
      formatType: 'yesNo',
      value: data.ClientEntityDetailsSummary.onHold
    },
    {
      name: 'Always Show',
      formatType: 'yesNo',
      value:
        user && user.tenant.type === 'advisoryFirm' && data.ClientEntityDetailsSummary.alwaysShow ? data.ClientEntityDetailsSummary.alwaysShow : null
    },
    {
      name: 'Tax Exempt',
      formatType: 'yesNo',
      value: data.ClientEntityDetailsSummary.ubtiBlocker
    },
    {
      name: 'Bite Size',
      tooltipName: type === 'Illiquid Alternative' ? 'Bite Size for Illiquid Alternative' : 'Bite Size for Restricted Alternative',
      formatType: 'JSX',
      value: data.ClientEntityDetailsSummary.ignoreBiteSize ? (
        <>Use minimum</>
      ) : (
        <FormattingTooltip>
          {type === 'Illiquid Alternative'
            ? data.ClientEntityDetailsSummary.biteSize.fundBiteSize
            : data.ClientEntityDetailsSummary.biteSize.directBiteSize}
        </FormattingTooltip>
      )
    },
    {
      name: 'Sectors To Avoid',
      tooltipName: undefined,
      formatType: 'string',
      value: data.ClientEntityDetailsSummary.sectorsToAvoid
    }
  ];

  const entityPopover: InfoListRow[] = [
    {
      name: 'On Hold',
      formatType: 'yesNo',
      value: data.ClientEntityDetailsSummary.onHold
    },
    {
      name: 'Always Show',
      formatType: 'yesNo',
      value: data.ClientEntityDetailsSummary.alwaysShow ? data.ClientEntityDetailsSummary.alwaysShow : null
    },
    {
      name: 'Tax Exempt',
      formatType: 'yesNo',
      value: data.ClientEntityDetailsSummary.ubtiBlocker
    },
    {
      name: 'Entity Value',
      formatType: 'JSX',
      value: <FormattingTooltip>{data.ClientEntityDetailsSummary.totalEntitiesValue}</FormattingTooltip>
    },
    {
      name: '% To Private Capital',
      formatType: 'percentage',
      value: data.ClientEntityDetailsSummary.privatePercentage
    },
    {
      name: 'Target OC Ratio',
      formatType: 'x',
      value: data.ClientEntityDetailsSummary.targetOCRatio
    },
    {
      name: 'Private Capital Target',
      formatType: 'JSX',
      value: (
        <FormattingTooltip>
          {data.ClientEntityDetailsSummary.summary.privateEquityTargetAllocations +
            data.ClientEntityDetailsSummary.summary.privateCreditTargetAllocations +
            data.ClientEntityDetailsSummary.summary.privateRealAssetTargetAllocations}
        </FormattingTooltip>
      )
    },
    {
      name: 'Overall Capacity',
      formatType: 'JSX',
      value: <FormattingTooltip>{data.ClientEntityDetailsSummary.summary.overallCapacity}</FormattingTooltip>
    },
    {
      name: 'Private Equity Capacity',
      tooltipName: allocation.investment.assetClass === 'Private Equity' ? tooltipContent : '',
      formatType: 'JSX',
      value: <FormattingTooltip>{data.ClientEntityDetailsSummary.summary.privateEquityCapacity}</FormattingTooltip>
    },
    {
      name: 'Private Credit Capacity',
      tooltipName: allocation.investment.assetClass === 'Private Credit' ? tooltipContent : '',
      formatType: 'JSX',
      value: <FormattingTooltip>{data.ClientEntityDetailsSummary.summary.privateCreditCapacity}</FormattingTooltip>
    },
    {
      name: 'Real Assets Capacity',
      tooltipName: allocation.investment.assetClass === 'Private Real Assets' ? tooltipContent : '',
      formatType: 'JSX',
      value: <FormattingTooltip>{data.ClientEntityDetailsSummary.summary.privateRealAssetsCapacity}</FormattingTooltip>
    },
    {
      name: 'Diversifying Strategies Capacity',
      tooltipName: allocation.investment.assetClass === 'Diversifying Strategies' ? tooltipContent : '',
      formatType: 'JSX',
      value: <FormattingTooltip>{data.ClientEntityDetailsSummary.summary.diversifyingStrategiesCapacity}</FormattingTooltip>
    },
    {
      name: 'Bite Size',
      tooltipName: type === 'Illiquid Alternative' ? 'Bite Size for Illiquid Alternative' : 'Bite Size for Restricted Alternative',
      formatType: 'JSX',
      value: data.ClientEntityDetailsSummary.ignoreBiteSize ? (
        <>Use minimum</>
      ) : (
        <FormattingTooltip>
          {type === 'Illiquid Alternative'
            ? data.ClientEntityDetailsSummary.biteSize.fundBiteSize
            : data.ClientEntityDetailsSummary.biteSize.directBiteSize}
        </FormattingTooltip>
      )
    },
    {
      name: 'Sectors To Avoid',
      tooltipName: undefined,
      formatType: 'string',
      value: data.ClientEntityDetailsSummary.sectorsToAvoid
    }
  ];

  return (
    <InfoList
      isTwoColumn={isTwoColumn}
      data={data.ClientEntityDetailsSummary.ignoreAllocationTargets ? simplifiedEntityPopover : entityPopover}
      isSwitcherActive={switcher}
      setIsSwitcherActive={changeSwitcher}
      label={'Include Pending Commitments'}
    />
  );
};

export default EntityPopover;
