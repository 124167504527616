import {
  MobileRow,
  MobileRowTitle,
  MobileRowValue,
  MobileTableRowWrap,
  MobileTableWrapper,
  NoResults
} from '@/components/table/mobileTable/mobileTable';
import { format } from 'date-fns';
import styled from 'styled-components';

import FormattingTooltip from '@/components/basicComponents/tooltip/dollarsTooltip';
import { TextCell } from '@/components/table/styles';
import { TableColumns } from '@/components/table/types';
import guid from '@/utils/guid';
import { EntityType } from '../index';
import { TextGroupedCell } from '../tableBody';
import { IRowData } from '../types';

type MobileTableProps = {
  columns: TableColumns[];
  complianceRows: IRowData[];
  handleFamilyClick: (id: string) => void;
  handleEntityClick: (ids: EntityType) => void;
  handleClickInvestment: (name: string, id: string) => void;
  onEntityClick: (data: IRowData) => void;
  onInvestmentClick: (data: IRowData) => void;
  onStatusClick: (data: IRowData) => void;
};

const MobileTable = ({
  columns,
  complianceRows,
  handleFamilyClick,
  handleEntityClick,
  handleClickInvestment,
  onEntityClick,
  onInvestmentClick,
  onStatusClick
}: MobileTableProps) => {
  const TableRow = ({ el }: { el: IRowData }) => {
    return (
      <MobileTableRowWrap key={guid()}>
        <MobileRow>
          <MobileRowTitle>{columns[0].title}</MobileRowTitle>
          <EntityMobileRowValue isRedirect>
            <div onClick={() => onEntityClick(el)}>{el.legalEntity.entityName}</div>
            {el.selectedGroupLegalEntity && <TextGroupedCell>{el.selectedGroupLegalEntity.entityName}</TextGroupedCell>}
          </EntityMobileRowValue>
        </MobileRow>

        <MobileRow>
          <MobileRowTitle>{columns[1].title}</MobileRowTitle>
          <MobileRowValue onClick={() => onInvestmentClick(el)} isRedirect>
            {el.investment.name}
          </MobileRowValue>
        </MobileRow>

        <MobileRow>
          <MobileRowTitle>{columns[2].title}</MobileRowTitle>
          <EntityMobileRowValue isRedirect>
            <div onClick={() => handleFamilyClick(el.family.name)}>{el.family.name}</div>
            <TextCell>{el.advisor?.initials} </TextCell>
          </EntityMobileRowValue>
        </MobileRow>

        <MobileRow>
          <MobileRowTitle>{columns[3].title}</MobileRowTitle>
          <MobileRowValue onClick={() => onStatusClick(el)} isRedirect>
            {el.allocationStatus}
          </MobileRowValue>
        </MobileRow>

        <MobileRow>
          <MobileRowTitle>{columns[4].title}</MobileRowTitle>
          <MobileRowValue>{format(new Date(el.updatedAt), 'PP').toLocaleUpperCase()}</MobileRowValue>
        </MobileRow>

        <MobileRow>
          <MobileRowTitle>{columns[5].title}</MobileRowTitle>
          <MobileRowValue>
            <FormattingTooltip zIndex={1000}>{+el.amount}</FormattingTooltip>
          </MobileRowValue>
        </MobileRow>

        <MobileRow>
          <MobileRowTitle>{columns[6].title}</MobileRowTitle>
          <MobileRowValue>{el.terminationReason}</MobileRowValue>
        </MobileRow>
      </MobileTableRowWrap>
    );
  };

  return (
    <MobileTableWrapper>
      {complianceRows.length ? (
        complianceRows.map((el, index) => {
          // eslint-disable-next-line react/prop-types
          return <TableRow el={el} key={el.legalEntity?.id + index} />;
        })
      ) : (
        <NoResults>No Results</NoResults>
      )}
    </MobileTableWrapper>
  );
};

const EntityMobileRowValue = styled(MobileRowValue)`
  flex-direction: column;
`;

export default MobileTable;
