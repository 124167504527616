import { useResponsive } from '@/hooks/use-responsive';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import LoginHero from '../authentication/fat-login-hero';

export const Maintenance = () => {
  const { isMobile, isTablet, isPortrait } = useResponsive();

  const [maintenancePageHeight, setMaintenancePageHeight] = useState('');

  useEffect(() => {
    setMaintenancePageHeight(window.innerHeight.toString() + 'px');
  }, [isPortrait]);

  return (
    <Container isMobile={isMobile} isTablet={isTablet} isPortrait={isPortrait} maintenancePageHeight={maintenancePageHeight}>
      {isMobile || (isPortrait && isTablet) ? (
        <HeroBlockMobile backgroundColor="#E6E6E6">
          <LoginHero />
        </HeroBlockMobile>
      ) : (
        <HeroBlock backgroundColor="#E6E6E6">
          <LoginHero />
        </HeroBlock>
      )}
      <MaintenanceWrap isMobile={isMobile} isTablet={isTablet} isPortrait={isPortrait}>
        <MaintenanceText>We are currently upgrading the Curio platform. Please check back later.</MaintenanceText>
      </MaintenanceWrap>
    </Container>
  );
};

const Container = styled.div<{ isMobile: boolean; isTablet: boolean; isPortrait: boolean; maintenancePageHeight: string }>`
  height: ${({ isMobile, isTablet, isPortrait, maintenancePageHeight }) => (isMobile || (isPortrait && isTablet) ? maintenancePageHeight : '100vh')};
  width: 100%;
  display: flex;
  flex-direction: ${({ isMobile, isTablet, isPortrait }) => (isMobile || (isPortrait && isTablet) ? 'column' : 'row')};
  justify-content: center;
`;

const HeroBlock = styled.div<{ backgroundColor?: string }>`
  background-color: ${({ backgroundColor }) => (backgroundColor ? backgroundColor : '#36474f')};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 50%;
  position: relative;
`;

const HeroBlockMobile = styled.div<{ backgroundColor?: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 36%;
  background-color: ${({ backgroundColor }) => (backgroundColor ? backgroundColor : '#36474f')};
`;

const MaintenanceWrap = styled.div<{ isMobile: boolean; isPortrait: boolean; isTablet: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: ${({ isMobile, isPortrait, isTablet }) => (isMobile || (isPortrait && isTablet) ? '100%' : '50%')};
`;

const MaintenanceText = styled.div`
  color: #202020;
  text-align: center;
  font-family: Blinker;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22.4px;
  max-width: 280px;
  width: 100%;
`;
