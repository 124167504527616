import { Loader } from '@/components/loaders/loader';
import styled from 'styled-components';

interface ModalLoaderProps {
  customText?: string;
}

const ModalLoader = ({ customText }: ModalLoaderProps) => {
  return (
    <LoaderWrapper>
      <ModalText>{customText ?? 'Please allow a few moment for this process to complete.'}</ModalText>
      <Loader />
    </LoaderWrapper>
  );
};

export default ModalLoader;

const LoaderWrapper = styled.div`
  text-align: center;
`;

const ModalText = styled.div`
  display: inline-block;
  text-align: center;
  font-size: 19px;
  font-weight: 400;
  color: ${({ theme }) => theme.font.base};
`;
