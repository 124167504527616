import { ResponsiveInitialState } from '@/contexts/responsive-context';
import { useResponsive } from '@/hooks/use-responsive';
import { useEffect, useMemo, useState } from 'react';
import { Pie, PieChart, ResponsiveContainer, Sector } from 'recharts';
import styled from 'styled-components';
import { DrawdownTargetPercentagesWithSubAssetClassesDto, DrawdownTargetsDto } from '../../../fat-pacingTab/types';
import Table from './table/table';

interface ChartWithTableProps {
  drawdownTargetDialogData: DrawdownTargetsDto | null;
  openAssetClassTargetsPage?: () => void;
}

const renderActiveShape = (props: any) => {
  const RADIAN = Math.PI / 180;
  const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, payload, value } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? 'start' : 'end';

  const activeFill = '#199BDC';

  return (
    <g>
      <text x={cx} y={cy} dy={8} textAnchor="middle" fill={activeFill}>
        {payload.name}
      </text>
      <Sector cx={cx} cy={cy} innerRadius={innerRadius} outerRadius={outerRadius} startAngle={startAngle} endAngle={endAngle} fill={activeFill} />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={activeFill}
      />
      <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={activeFill} fill="none" />
      <circle cx={ex} cy={ey} r={2} fill={activeFill} stroke="none" />
      <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill={activeFill}>
        {payload.name}
      </text>
      <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#919195">
        {`${value.toFixed(2)}%`}
      </text>
    </g>
  );
};

const getPieChartRadiuses = (responsive: ResponsiveInitialState) => {
  const { isMobile, isTablet, isDesktop, isLargeDesktop } = responsive;
  if (isMobile) return { innerRadius: 60, outerRadius: 80 };
  if (isTablet || isDesktop) return { innerRadius: 110, outerRadius: 140 };
  if (isLargeDesktop) return { innerRadius: 130, outerRadius: 160 };
  return { innerRadius: 80, outerRadius: 100 };
};

export const ChartWithTable = ({ drawdownTargetDialogData, openAssetClassTargetsPage }: ChartWithTableProps) => {
  const responsive = useResponsive();

  const [tabs, setTabs] = useState(['Allocation Targets']);
  const [activeTab, setActiveTab] = useState('Allocation Targets');
  const [drawdownData, setDrawdownData] = useState<DrawdownTargetPercentagesWithSubAssetClassesDto[]>([]);
  const [evergreenData, setEvergreenData] = useState<DrawdownTargetPercentagesWithSubAssetClassesDto[]>([]);
  const [chartData, setChartData] = useState<{ name: string; value: number }[]>([]);
  const [chartActiveIndex, setChartActiveIndex] = useState(0);

  useEffect(() => {
    if (!drawdownTargetDialogData) return;

    const hasDrawdownValues = drawdownTargetDialogData?.actuals?.drawdown.some(
      (item) => item.portfolioPercentage !== 0 || item.privateCapitalPercentage !== 0
    );
    const hasEvergreenValues = drawdownTargetDialogData?.actuals?.evergreen.some(
      (item) => item.portfolioPercentage !== 0 || item.privateCapitalPercentage !== 0
    );

    if (hasDrawdownValues || hasEvergreenValues) {
      setTabs(['Allocation Targets', 'Current Allocations']);
    }
  }, [drawdownTargetDialogData]);

  useEffect(() => {
    if (!drawdownTargetDialogData) return;

    if (activeTab === tabs[0]) {
      setDrawdownData(drawdownTargetDialogData?.targets?.drawdown || []);
      setEvergreenData(drawdownTargetDialogData?.targets?.evergreen || []);
      return;
    }

    if (activeTab === tabs[1]) {
      setDrawdownData(drawdownTargetDialogData?.actuals?.drawdown || []);
      setEvergreenData(drawdownTargetDialogData?.actuals?.evergreen || []);
      return;
    }
  }, [activeTab, drawdownTargetDialogData]);

  const drawdownTotals = useMemo(() => {
    return drawdownData.reduce(
      (acc, item) => {
        return {
          portfolioPercentage: acc.portfolioPercentage + item.portfolioPercentage,
          privateCapitalPercentage: acc.privateCapitalPercentage + item.privateCapitalPercentage
        };
      },
      { portfolioPercentage: 0, privateCapitalPercentage: 0 }
    );
  }, [drawdownData]);

  const evergreenTotals = useMemo(() => {
    return evergreenData.reduce(
      (acc, item) => {
        return {
          portfolioPercentage: acc.portfolioPercentage + item.portfolioPercentage,
          privateCapitalPercentage: acc.privateCapitalPercentage + item.privateCapitalPercentage
        };
      },
      { portfolioPercentage: 0, privateCapitalPercentage: 0 }
    );
  }, [evergreenData]);

  useEffect(() => {
    setChartData([
      { name: 'Drawdown', value: drawdownTotals.portfolioPercentage },
      { name: 'Evergreen', value: evergreenTotals.portfolioPercentage },
      { name: 'Non-PC', value: 100 - drawdownTotals.portfolioPercentage - evergreenTotals.portfolioPercentage }
    ]);
  }, [drawdownTotals, evergreenTotals]);

  const onPieEnter = (index: number) => {
    setChartActiveIndex(index);
  };

  const changeActiveTab = (tab: string) => {
    setActiveTab(tab);
    setChartActiveIndex(0);
  };

  if (!drawdownTargetDialogData || !drawdownTargetDialogData?.actuals?.drawdown || !drawdownTargetDialogData?.actuals?.evergreen) return <></>;

  return (
    <>
      <SectionsNavWrapper>
        {tabs.map((tab) => (
          <SectionsNavItem key={tab} isActive={activeTab === tab} onClick={() => changeActiveTab(tab)}>
            {tab}
          </SectionsNavItem>
        ))}
      </SectionsNavWrapper>
      <ModalBodyWrapper isMobile={responsive.isMobile} isTablet={responsive.isTablet}>
        <ChartWrapper isMobile={responsive.isMobile} isTablet={responsive.isTablet}>
          <ResponsiveContainer width="100%" height={400}>
            <PieChart>
              <Pie
                activeIndex={chartActiveIndex}
                activeShape={renderActiveShape}
                data={chartData}
                innerRadius={getPieChartRadiuses(responsive).innerRadius}
                outerRadius={getPieChartRadiuses(responsive).outerRadius}
                fill="#D6D6D6"
                dataKey="value"
                onMouseEnter={(_, index) => onPieEnter(index)}
              />
            </PieChart>
          </ResponsiveContainer>
        </ChartWrapper>
        <TableWrapper isMobile={responsive.isMobile} isTablet={responsive.isTablet}>
          <Table
            drawdownData={drawdownData}
            evergreenData={evergreenData}
            drawdownTotals={drawdownTotals}
            evergreenTotals={evergreenTotals}
            showEditButton={activeTab === tabs[0]}
            openAssetClassTargetsPage={openAssetClassTargetsPage}
          />
        </TableWrapper>
      </ModalBodyWrapper>
    </>
  );
};

const ModalBodyWrapper = styled.div<{ isMobile: boolean; isTablet: boolean }>`
  display: flex;
  align-items: center;
  flex-direction: ${({ isMobile, isTablet }) => (isMobile || isTablet ? 'column' : 'row')};
`;

const SectionsNavWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  padding: 10px 0;
`;

const SectionsNavItem = styled.div<{ isActive: boolean }>`
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 13px;
  font-weight: 600;
  line-height: 20px;
  color: ${({ theme, isActive }) => (isActive ? theme.font.base : theme.font.action)};
  cursor: pointer;
  border-bottom: ${({ isActive }) => (isActive ? '4px solid #fbb161' : '4px solid transparent')};
  width: max-content;
  transition:
    color 0.3s,
    border-bottom 0.3s;
`;

const ChartWrapper = styled.div<{ isMobile: boolean; isTablet: boolean }>`
  width: ${({ isMobile, isTablet }) => (isMobile || isTablet ? '100%' : '55%')};

  .recharts-pie * {
    outline: none !important;
  }
`;

const TableWrapper = styled.div<{ isMobile: boolean; isTablet: boolean }>`
  color: ${({ theme }) => theme.font.base};
  width: ${({ isMobile, isTablet }) => (isMobile || isTablet ? '100%' : '45%')};
  border: 1px solid ${({ theme }) => theme.border.base};
  padding: 20px;
`;
