import FormattingTooltip from '@/components/basicComponents/tooltip/dollarsTooltip';
import {
  MobileRow,
  MobileRowTitle,
  MobileRowValue,
  MobileTableRowWrap,
  MobileTableWrapper,
  NoResults
} from '@/components/table/mobileTable/mobileTable';
import { TableColumns } from '@/components/table/types';
import guid from '@/utils/guid';
import { IListAdvisor } from '../../types';

interface MobileTableAdvisorProps {
  columns: TableColumns[];
  listAdvisers: IListAdvisor[];
  changeViewBy: (view: string) => void;
}

const MobileTableAdvisor = ({ columns, listAdvisers, changeViewBy }: MobileTableAdvisorProps) => {
  const TableRow = ({ el }: { el: IListAdvisor }) => {
    return (
      <MobileTableRowWrap key={guid()}>
        <MobileRow>
          <MobileRowTitle>{columns[0].title}</MobileRowTitle>
          <MobileRowValue isRedirect onClick={() => changeViewBy(el.id)}>
            {el.name}
          </MobileRowValue>
        </MobileRow>

        <MobileRow>
          <MobileRowTitle>{columns[1].title}</MobileRowTitle>
          <MobileRowValue>{el.investorCount}</MobileRowValue>
        </MobileRow>

        <MobileRow>
          <MobileRowTitle>{columns[2].title}</MobileRowTitle>
          <MobileRowValue>{el.investmentCount}</MobileRowValue>
        </MobileRow>

        <MobileRow>
          <MobileRowTitle>{columns[3].title}</MobileRowTitle>
          <MobileRowValue>
            <FormattingTooltip zIndex={1000} key={guid()}>
              {el.totalValue}
            </FormattingTooltip>
          </MobileRowValue>
        </MobileRow>
        <MobileRow>
          <MobileRowTitle>{columns[4].title}</MobileRowTitle>
          <MobileRowValue>
            <FormattingTooltip zIndex={1000} key={guid()}>
              {el.totalCommitments}
            </FormattingTooltip>
          </MobileRowValue>
        </MobileRow>
      </MobileTableRowWrap>
    );
  };

  return (
    <MobileTableWrapper>
      {listAdvisers.length ? (
        listAdvisers.map((el) => {
          return <TableRow el={el} key={guid()} />;
        })
      ) : (
        <NoResults>No Results</NoResults>
      )}
    </MobileTableWrapper>
  );
};

export default MobileTableAdvisor;
