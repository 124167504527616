import FileNotSupported from '@/components/dueDiligence-page/modals/fileNotSupported';
import { ScrollTo } from '@/components/fat-dealCatalog-page/types';
import { ALL_ADVISORY_FIRM_LIST_QUERY } from '@/components/fat-dealPipeline-page/fat-investorInterest/queries';
import Header from '@/components/fat-header';
import { GoBackButton } from '@/components/fat-header/goBackButton';
import { PageTitle } from '@/components/fat-header/pageTitle';
import { GET_ASSET_CLASSES } from '@/components/fat-investors-page/fat-addInvestors/queries';
import { IAssetClass } from '@/components/fat-investors-page/fat-addInvestors/types';
import { ITenant } from '@/components/fat-investors-page/types';
import { LoaderOnWholeScreen } from '@/components/loaders/loader-on-whole-screen';
import { ISort, SORT_ORDER } from '@/components/table/types';
import { useResponsive } from '@/hooks/use-responsive';
import { useStatusData } from '@/hooks/useStatusData';
import { MainWrap } from '@/styles/common';
import { useMutation, useQuery } from '@apollo/client';
import axios from 'axios';
import { parse } from 'csv-parse/sync';
import fileDownload from 'js-file-download';
import { useState } from 'react';
import styled from 'styled-components';
import Loading from '../fat-modals/loading';
import { UploadDataFile } from '../importAllocations/steps/uploadDataFile';
import { importLegalEntitiesMutation } from '../queries';
import { allowFileExtensions } from '../utils';
import {
  investorEntitiesColumnsSectionData,
  investorEntitiesDescriptionSectionData,
  investorEntitiesSteps,
  startOverInvestorEntitiesSteps
} from './constants';
import { EntityImportDetail } from './entityImportDetail';
import { GroupImportDetail } from './groupImportDetail';
import { InvestorImportDetail } from './investorImportDetail';
import { GettingStarted } from './steps/gettingStarted';
import { Groups } from './steps/groups';
import { InvestorEntities } from './steps/investorEntities';
import { InvestorHouseholds } from './steps/investorHouseholds';
import {
  IEntityImportComparison,
  IEntityResponse,
  IGroupImportComparison,
  IGroupResponse,
  IInvestorComparison,
  IInvestorResponse,
  ILegalEntityImport,
  TypeWindow
} from './types';

interface ImportInvestorEntitiesProps {
  handleCloseImportInvestorEntities: () => void;
  backToTitle?: string;
}

const fileExtensions = ['CSV'];

export const ImportInvestorEntities = ({ handleCloseImportInvestorEntities, backToTitle }: ImportInvestorEntitiesProps) => {
  const { isMobile, isTablet } = useResponsive();
  const { data: statusData } = useStatusData();

  const [typeWindow, setTypeWindow] = useState<TypeWindow>('default');
  const [file, setFile] = useState<File | null>(null);
  const [drag, setDrag] = useState(false);
  const [importSteps, setImportSteps] = useState(investorEntitiesSteps);
  const [selectedAdvisoryFirm, setSelectedAdvisoryFirm] = useState('Select');
  const [advisoryFirmList, setAdvisoryFirmList] = useState<string[]>([]);
  const [modalWindow, setModalWindow] = useState({ isOpen: false, type: 'not-supported' });
  const [sort, setSort] = useState<ISort>({
    key: '',
    asc: true
  });
  const [search, setSearch] = useState('');
  const [primaryFilter, setPrimaryFilter] = useState('');
  const [isValidFile, setIsValidFile] = useState(true);
  const [showTable, setShowTable] = useState(false);
  const [scrollTo, setScrollTo] = useState<ScrollTo>({ y: 0, makeScroll: false });
  const [importData, setImportData] = useState<any>([]);
  const [columnsSectionData, setColumnsSectionData] = useState<typeof investorEntitiesColumnsSectionData>(investorEntitiesColumnsSectionData);

  const [currentEntityRow, setCurrentEntityRow] = useState<IEntityImportComparison | null>(null);
  const [currentInvestorRow, setCurrentInvestorRow] = useState<IInvestorComparison | null>(null);
  const [currentGroupRow, setCurrentGroupRow] = useState<IGroupImportComparison | null>(null);

  const [entityData, setEntityData] = useState<IEntityResponse | null>(null);
  const [investorData, setInvestorData] = useState<IInvestorResponse | null>(null);
  const [groupData, setGroupData] = useState<IGroupResponse | null>(null);

  const [entityErrors, setEntityErrors] = useState<IEntityImportComparison[]>([]);
  const [investorErrors, setInvestorErrors] = useState<IInvestorComparison[]>([]);
  const [groupErrors, setGroupErrors] = useState<IGroupImportComparison[]>([]);

  const [importLegalEntities, { loading: importLegalEntitiesMutationLoading }] = useMutation<{ importLegalEntities: ILegalEntityImport }>(
    importLegalEntitiesMutation,
    {
      onError: () => {
        setFile(null);
        setIsValidFile(false);
      }
    }
  );

  const { data: allAdvisoryFirmList } = useQuery<{ allAdvisoryFirmList: ITenant[] }>(ALL_ADVISORY_FIRM_LIST_QUERY, {
    variables: {
      data: {
        sortBy: 'NAME',
        sortDirection: SORT_ORDER.ASC
      }
    },
    onCompleted: ({ allAdvisoryFirmList }) => {
      setAdvisoryFirmList([...new Set(allAdvisoryFirmList.map((el) => el.name))]);
    }
  });

  const { loading: assetClassesLoading } = useQuery<{ assetClasses: IAssetClass[] }>(GET_ASSET_CLASSES, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
    onCompleted: async ({ assetClasses }) => {
      const assetClassesColumns = getAssetClassesColumnNames(assetClasses, statusData?.target_sub_asset_classes);
      const ocRatioIndex = columnsSectionData.columns.findIndex((column) => column.name === 'OC Ratio');

      if (ocRatioIndex !== -1) {
        const newColumns = [
          ...columnsSectionData.columns.slice(0, ocRatioIndex + 1),
          ...assetClassesColumns,
          ...columnsSectionData.columns.slice(ocRatioIndex + 1)
        ];

        setColumnsSectionData({
          ...columnsSectionData,
          columns: newColumns
        });
      }
    },
    onError: (error) => {
      console.error(error);
    }
  });

  const getAssetClassesColumnNames = (assetClass: IAssetClass[], targetSubAssetClasses: boolean) => {
    const hasSubAssetClasses = assetClass.some((assetClass) => assetClass.subAssetClasses.length > 0);

    if (hasSubAssetClasses && targetSubAssetClasses) {
      return assetClass.flatMap((assetClass) => {
        return assetClass.subAssetClasses.map((subAssetClass) => ({
          name: `Asset Class: ${subAssetClass.name}`,
          isRequired: false
        }));
      });
    }

    return assetClass.map((assetClass) => ({
      name: `Asset Class: ${assetClass.name}`,
      isRequired: false
    }));
  };

  const importFile = async (file: File) => {
    setIsValidFile(true);

    try {
      const text = await file.text();
      const records = parse(text, {
        columns: true,
        skip_empty_lines: true,
        trim: true
      });
      let isValidData = true;

      const recordsToUpdate = records.map((record: any) => {
        if (!record['Entity Name']) {
          setFile(null);
          setIsValidFile(false);
          isValidData = false;
          return;
        }

        const allocationTargets = Object.keys(record)
          .filter((key) => key.includes('Asset Class'))
          .map((key) => {
            const assetClass = key.split('Asset Class: ')[1];
            return {
              name: assetClass,
              percentage: record[key]
            };
          });

        return {
          ...(record['Entity Name'] && { entityName: record['Entity Name'] }),
          entityCRMId: record['Entity CRM ID'] === undefined ? null : record['Entity CRM ID'],
          investorName: record['Investor Name'] === undefined ? null : record['Investor Name'],
          grouped: record['Grouped'] === undefined ? null : record['Grouped'],
          primaryEntity: record['Primary Entity'] === undefined ? null : record['Primary Entity'],
          totalEntityValue: record['Total Entity Value'] === undefined ? null : record['Total Entity Value'],
          privatePercentage: record['Private Percentage'] === undefined ? null : record['Private Percentage'],
          overCommitRatio: record['OC Ratio'] === undefined ? null : record['OC Ratio'],
          allocationTargets,
          //notes: record['Notes'] === undefined ? null : record['Notes'],
          //noteDate: record['Note Date'] === undefined ? null : record['Note Date'],
          sectorsToAvoid: record['Sub Asset Classes to Avoid'] === undefined ? null : JSON.stringify(record['Sub Asset Classes to Avoid']),
          sri: record['SRI'] === undefined ? null : record['SRI'],
          taxExempt: record['Tax Exempt'] === undefined ? null : record['Tax Exempt'],
          custodied: record['Custodied'] === undefined ? null : record['Custodied'],
          unmanaged: record['Unmanaged'] === undefined ? null : record['Unmanaged'],
          alwaysShow: record['Always Show'] === undefined ? null : record['Always Show'],
          onHold: record['On Hold'] === undefined ? null : record['On Hold'],
          entityInception: record['Entity Inception Date'] === undefined ? null : record['Entity Inception Date'],
          defaultBiteSize1: record['Default Bite Size 1'] === undefined ? null : record['Default Bite Size 1'],
          defaultBiteSize2: record['Default Bite Size 2'] === undefined ? null : record['Default Bite Size 2'],
          advisorEmail: record['Client Advisor Owner'] === undefined ? null : record['Client Advisor Owner']
        };
      });

      if (!isValidData) return;

      setImportData(recordsToUpdate);

      const { data } = await importLegalEntities({
        variables: {
          data: {
            tenantId: allAdvisoryFirmList?.allAdvisoryFirmList.find((advisoryFirm) => advisoryFirm.name === selectedAdvisoryFirm)?.id,
            importEntities: recordsToUpdate,
            save: false
          }
        }
      });

      const { entityResponse, investorResponse, groupResponse } = data?.importLegalEntities ?? {
        entityResponse: null,
        investorResponse: null,
        groupResponse: null
      };

      setEntityData(entityResponse);
      setInvestorData(investorResponse);
      setGroupData(groupResponse);

      if (entityResponse) {
        const entityErrorRows = entityResponse.entityImportComparisons?.filter((row) => row.status === 'error') ?? [];
        setEntityErrors(entityErrorRows);
      }

      if (investorResponse) {
        const investorErrorRows = investorResponse.investorComparisons?.filter((row) => row.status === 'error') ?? [];
        setInvestorErrors(investorErrorRows);
      }

      if (groupResponse) {
        const groupErrorRows = groupResponse.groupImportComparisons?.filter((row) => row.status === 'error') ?? [];
        setGroupErrors(groupErrorRows);
      }
    } catch (error) {
      setFile(null);
      setIsValidFile(false);
    }
  };

  const onDropHandler = async (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const uploadFiles = e.dataTransfer.files;

    setDrag(false);
    if (!allowFileExtensions(uploadFiles, fileExtensions).length) {
      setModalWindow({ isOpen: true, type: 'not-supported' });
      return;
    }
    if (uploadFiles && uploadFiles.length > 0) {
      setFile(uploadFiles[0]);
      importFile(uploadFiles[0]);
    }
  };

  const handleUploadFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const uploadFiles = e.target.files;

    if (!allowFileExtensions(uploadFiles, fileExtensions).length) {
      setModalWindow({ isOpen: true, type: 'not-supported' });
      return;
    }
    if (uploadFiles && uploadFiles.length > 0) {
      setFile(uploadFiles[0]);
      importFile(uploadFiles[0]);
    }
  };

  const resetFilters = (currentStepIndex?: number) => {
    setSort({ key: '', asc: true });
    setSearch('');
    setShowTable(false);

    if (!currentStepIndex) return;
    const nextStep = investorEntitiesSteps[currentStepIndex + 1];
    switch (nextStep.name) {
      case 'Investor Entities':
        if (entityErrors.length) {
          setPrimaryFilter('Errors Only');
          return;
        }
        const isRowsWithUpdateStatusInvestorEntities = entityData?.entityImportComparisons?.some((row) => row.status === 'updated');
        setPrimaryFilter(isRowsWithUpdateStatusInvestorEntities ? 'Updates Only' : 'All Rows');
        break;
      case 'Investor Households':
        if (investorErrors.length) {
          setPrimaryFilter('Errors Only');
          return;
        }
        const isRowsWithUpdateStatusInvestorHouseholds = investorData?.investorComparisons?.some((row) => row.status === 'updated');
        setPrimaryFilter(isRowsWithUpdateStatusInvestorHouseholds ? 'Updates Only' : 'All Rows');
        break;
      case 'Groups':
        setPrimaryFilter(groupErrors.length ? 'Errors Only' : 'Updates Only');
        break;
      default:
        setPrimaryFilter('All Rows');
        break;
    }
  };

  const startOver = () => {
    if (!file) return;
    importFile(file);
    setImportSteps(startOverInvestorEntitiesSteps);
    resetFilters();
  };

  const nextStep = () => {
    const currentStepIndex = importSteps.findIndex((step) => step.status === 'IN_PROGRESS');
    if (currentStepIndex === importSteps.length - 1) return;

    const updatedImportSteps = importSteps.map((step, index) => {
      if (currentStepIndex === index) {
        return { ...step, status: 'COMPLETED' };
      }
      if (currentStepIndex + 1 === index) {
        return { ...step, status: 'IN_PROGRESS' };
      }

      return step;
    });

    resetFilters(currentStepIndex);
    setImportSteps(updatedImportSteps);
  };

  const completeImport = async () => {
    if (!importData?.length) return;
    setModalWindow({ isOpen: true, type: 'loading' });

    await importLegalEntities({
      variables: {
        data: {
          tenantId: allAdvisoryFirmList?.allAdvisoryFirmList.find((advisoryFirm) => advisoryFirm.name === selectedAdvisoryFirm)?.id,
          importEntities: importData,
          save: true
        }
      }
    });

    handleCloseImportInvestorEntities();
    setModalWindow({ isOpen: false, type: 'loading' });
  };

  const changeSelectedAdvisoryFirm = (advisoryFirm: string) => {
    setSelectedAdvisoryFirm(advisoryFirm);
  };

  const onChangeSearch = (value: string) => {
    setSearch(value);
  };

  const changePrimaryFilterValue = (filter: string) => {
    setPrimaryFilter(filter);
  };

  const openEntityImportDetailPage = (type: TypeWindow, row: IEntityImportComparison) => {
    setTypeWindow(type);
    setCurrentEntityRow(row);
    setScrollTo({ y: window.scrollY, makeScroll: true });
  };

  const openInvestorImportDetailPage = (type: TypeWindow, row: IInvestorComparison) => {
    setTypeWindow(type);
    setCurrentInvestorRow(row);
    setScrollTo({ y: window.scrollY, makeScroll: true });
  };

  const openGroupImportDetailPage = (type: TypeWindow, row: IGroupImportComparison) => {
    setTypeWindow(type);
    setCurrentGroupRow(row);
    setScrollTo({ y: window.scrollY, makeScroll: true });
  };

  const handleCloseDetailPage = () => {
    setTypeWindow('default');
    setCurrentEntityRow(null);
    setCurrentInvestorRow(null);
    setCurrentGroupRow(null);
    scrollTo.makeScroll && setTimeout(() => window.scrollTo({ top: scrollTo.y, behavior: 'smooth' }), 0);
  };

  const downloadExistingData = async () => {
    const currentAdvisoryFirm = allAdvisoryFirmList?.allAdvisoryFirmList.find((advisoryFirm) => advisoryFirm.name === selectedAdvisoryFirm);
    if (!currentAdvisoryFirm) return;

    const response = await axios.post(
      `${process.env.REACT_APP_BACKEND_BASE_URL}/entity/export`,
      {
        tenantId: currentAdvisoryFirm.id
      },
      {
        headers: { authorization: `Bearer ${localStorage.getItem('token')}` }
      }
    );
    fileDownload(response.data, 'investor-entities.csv');
  };

  if (assetClassesLoading) {
    return <LoaderOnWholeScreen />;
  }

  return (
    <>
      {typeWindow === 'entityImportDetail' && (
        <EntityImportDetail currentRow={currentEntityRow} handleClose={handleCloseDetailPage} backToTitle="Investor Entities" />
      )}
      {typeWindow === 'investorImportDetail' && (
        <InvestorImportDetail currentRow={currentInvestorRow} handleClose={handleCloseDetailPage} backToTitle="Investor Entities" />
      )}
      {typeWindow === 'groupImportDetail' && (
        <GroupImportDetail currentRow={currentGroupRow} handleClose={handleCloseDetailPage} backToTitle="Investor Entities" />
      )}
      {typeWindow === 'default' && (
        <>
          {modalWindow.type === 'not-supported' && (
            <FileNotSupported isOpen={modalWindow.isOpen} onClose={() => setModalWindow({ ...modalWindow, isOpen: false })} />
          )}
          {modalWindow.type === 'loading' && <Loading isOpen={modalWindow.isOpen} />}
          <MainWrap>
            <Header modalControl={<GoBackButton handleClose={handleCloseImportInvestorEntities} backToTitle={backToTitle} />} />
            <PageTitle title="Investor Entities" />
          </MainWrap>
          <MainWrap>
            <PaddingWrap>
              {importSteps[0].status === 'IN_PROGRESS' && (
                <GettingStarted
                  importSteps={importSteps}
                  handleClose={handleCloseImportInvestorEntities}
                  nextStep={nextStep}
                  descriptionSection={investorEntitiesDescriptionSectionData}
                  selectedAdvisoryFirm={selectedAdvisoryFirm}
                  advisoryFirmList={advisoryFirmList}
                  changeSelectedAdvisoryFirm={changeSelectedAdvisoryFirm}
                />
              )}
              {importSteps[1].status === 'IN_PROGRESS' && (
                <UploadDataFile
                  importSteps={importSteps}
                  file={file}
                  handleUploadFile={handleUploadFile}
                  onDropHandler={onDropHandler}
                  drag={drag}
                  setDrag={setDrag}
                  handleClose={handleCloseImportInvestorEntities}
                  nextStep={nextStep}
                  loading={importLegalEntitiesMutationLoading}
                  isValidFile={isValidFile}
                  columnsSection={columnsSectionData}
                  downloadExistingData={downloadExistingData}
                  deleteFile={() => {
                    setFile(null);
                  }}
                />
              )}
              {importSteps[2].status === 'IN_PROGRESS' && (
                <InvestorEntities
                  importSteps={importSteps}
                  startOver={startOver}
                  nextStep={nextStep}
                  entityData={entityData}
                  errors={entityErrors}
                  sort={sort}
                  setSort={setSort}
                  search={search}
                  onChangeSearch={onChangeSearch}
                  primaryFilter={primaryFilter}
                  showTable={showTable}
                  setShowTable={setShowTable}
                  changePrimaryFilterValue={changePrimaryFilterValue}
                  openEntityImportDetailPage={openEntityImportDetailPage}
                />
              )}
              {importSteps[3].status === 'IN_PROGRESS' && (
                <InvestorHouseholds
                  importSteps={importSteps}
                  startOver={startOver}
                  nextStep={nextStep}
                  investorData={investorData}
                  errors={investorErrors}
                  sort={sort}
                  setSort={setSort}
                  search={search}
                  onChangeSearch={onChangeSearch}
                  primaryFilter={primaryFilter}
                  showTable={showTable}
                  setShowTable={setShowTable}
                  changePrimaryFilterValue={changePrimaryFilterValue}
                  openInvestorImportDetailPage={openInvestorImportDetailPage}
                />
              )}
              {importSteps[4].status === 'IN_PROGRESS' && (
                <Groups
                  importSteps={importSteps}
                  startOver={startOver}
                  nextStep={completeImport}
                  groupData={groupData}
                  errors={groupErrors}
                  sort={sort}
                  setSort={setSort}
                  search={search}
                  onChangeSearch={onChangeSearch}
                  primaryFilter={primaryFilter}
                  showTable={showTable}
                  setShowTable={setShowTable}
                  changePrimaryFilterValue={changePrimaryFilterValue}
                  openGroupImportDetailPage={openGroupImportDetailPage}
                />
              )}
            </PaddingWrap>
          </MainWrap>
        </>
      )}
    </>
  );
};

const PaddingWrap = styled.div`
  padding-left: 16px;
  padding-right: 16px;
  @media (min-width: 600px) {
    padding-left: 50px;
    padding-right: 50px;
  }
`;
