export const columns = [
  { title: 'Entity', key: 'entity', width: 22, keySort: 'entity' },
  { title: 'Investor', key: 'family', width: 12, keySort: 'investor' },
  { title: 'Overall Capacity', key: 'overallCapacity', width: 10, keySort: 'overall capacity' },
  { title: 'Asset Class Capacity', key: 'assetClassCapacity', width: 10, keySort: 'asset class' },
  { title: 'Suitability', key: 'suitability', width: 20, keySort: 'suitability' },
  { title: 'Amount', key: 'amount', width: 10, keySort: 'amount' },
  { title: 'Decision', key: 'decision', width: 10, keySort: 'decision' },
  { title: '', width: 4, key: 'key' }
];

export const initialFilterBarValues = [
  {
    type: 'allocationStatus',
    name: 'Initial Allocation',
    value: 'initialAllocation',
    enabled: true
  },
  {
    type: 'allocationStatus',
    name: 'Already Committed',
    value: 'alreadyCommitted',
    enabled: false
  },
  {
    type: 'allocationStatus',
    name: 'Internal Review Rejected',
    value: 'internalReviewRejections',
    enabled: false
  },
  {
    type: 'allocationStatus',
    name: 'Terminated',
    value: 'terminated',
    enabled: false
  }
];

export enum DECISION_STATUS {
  YES = 'Yes',
  NO = 'No'
}
