import { InfoIcon } from '@/assets/icons/info-icons/info';
import { PlusIcon } from '@/assets/icons/info-icons/plus';
import { Banner } from '@/components/basicComponents/banner';
import RadioButton from '@/components/basicComponents/radioButton';
import FilterSelect from '@/components/basicComponents/select';
import Input from '@/components/fat-basicComponents/input';
import Label from '@/components/fat-basicComponents/label';
import { useResponsive } from '@/hooks/use-responsive';
import { AnimatePresence, motion } from 'framer-motion';
import { Control, Controller, useWatch } from 'react-hook-form';
import styled, { useTheme } from 'styled-components';
import { OptionsWrapper, RadioInputWrapper, RadioWrapper, SectionsWrap, TwoColumnEntityWrapper, houseHoldSectionOptions } from '..';
import { Section } from '../../fat-investorDetails-page/fat-entityDetails-page/edit-entity/section';
import { CreateInvestorDto } from '../types';

const taxExemptSectionOptions = ['No', 'Yes'];

interface InvestorDetailsProps {
  householdList: { id: string; name: string }[];
  control: Control<CreateInvestorDto, any>;
  isEditMode: boolean;
  openModalWindow: (type: string) => void;
}

export const InvestorDetails = ({ householdList, control, isEditMode, openModalWindow }: InvestorDetailsProps) => {
  const theme = useTheme();
  const { isMobile } = useResponsive();
  const investorHouseholdOptionValue = useWatch({
    control,
    name: 'investorHouseholdOption'
  });

  return (
    <SectionsWrap>
      <Section title="Investor Details" id="investorDetailsSection" openSection={true}>
        <TwoColumnEntityWrapper isMobile={isMobile}>
          <Controller
            name="entityName"
            control={control}
            rules={{
              required: {
                value: true,
                message: 'Entity Name is required'
              }
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Input
                label="Entity Name"
                value={value}
                onChange={(e) => {
                  onChange(e.target.value);
                }}
                errorText={error?.message}
                tooltip="Give this investor a user-friendly name."
                isRequiredField
                styleType="form"
              />
            )}
          />
        </TwoColumnEntityWrapper>
        {isEditMode ? (
          <TwoColumnEntityWrapper isMobile={isMobile}>
            <div>
              <Label required>Investor Household</Label>
              <Controller
                name="familyId"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: 'Investor Household is required'
                  },
                  pattern: {
                    value: /^(?!Select$)/,
                    message: 'Investor Household is required'
                  }
                }}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <>
                    <HouseholdSelect
                      data={householdList.map((el) => el.name)}
                      selected={value}
                      setSelected={onChange}
                      withSearch
                      width="100%"
                      additionalOption={
                        <AdditionalOptionWrapper onClick={() => openModalWindow('add-investor-household')}>
                          <PlusIcon width={20} height={20} fill={theme.font.action} />
                          <AddText>Add New Investor Household</AddText>
                        </AdditionalOptionWrapper>
                      }
                    />
                    {error?.message && <ErrorText>{error.message}</ErrorText>}
                  </>
                )}
              />
            </div>
          </TwoColumnEntityWrapper>
        ) : (
          <RadioWrapper>
            Investor Household
            <RadioInputWrapper isMobile={isMobile}>
              <OptionsWrapper>
                <Controller
                  name="investorHouseholdOption"
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <>
                      {houseHoldSectionOptions.map((option, index) => (
                        <RadioButton
                          key={option + index}
                          name="investorHouseholdOption"
                          radioButtonText={option}
                          selectedOption={value}
                          handleRadioChange={(event) => onChange(event.target.value)}
                        />
                      ))}
                    </>
                  )}
                />
              </OptionsWrapper>
              <AnimatePresence>
                <RadioWrapper>
                  {investorHouseholdOptionValue === houseHoldSectionOptions[0] && (
                    <motion.div
                      initial={{
                        opacity: 0
                      }}
                      animate={{
                        opacity: 1
                      }}
                      exit={{
                        opacity: 0
                      }}
                    >
                      <Banner
                        title=""
                        description="Organize investor entities into households that correspond to the client relationship."
                        icon={<InfoIcon width={26} height={26} fill={theme.header.base} />}
                        bgColor={theme.layer[1]}
                        color={theme.font.base}
                      />
                    </motion.div>
                  )}
                  {investorHouseholdOptionValue === houseHoldSectionOptions[1] && (
                    <motion.div
                      initial={{
                        opacity: 0
                      }}
                      animate={{
                        opacity: 1
                      }}
                      exit={{
                        opacity: 0
                      }}
                    >
                      <Label required>Household</Label>
                      <Controller
                        name="familyId"
                        control={control}
                        rules={{
                          required: {
                            value: true,
                            message: 'Household is required'
                          },
                          pattern: {
                            value: /^(?!Select$)/,
                            message: 'Household is required'
                          }
                        }}
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                          <>
                            <HouseholdSelect
                              data={householdList.map((el) => el.name)}
                              selected={value}
                              setSelected={onChange}
                              withSearch
                              width={isMobile ? '100%' : '500px'}
                            />
                            {error?.message && <ErrorText>{error.message}</ErrorText>}
                          </>
                        )}
                      />
                    </motion.div>
                  )}
                </RadioWrapper>
              </AnimatePresence>
            </RadioInputWrapper>
          </RadioWrapper>
        )}

        <RadioWrapper>
          Tax Exempt
          <RadioInputWrapper isMobile={isMobile}>
            <OptionsWrapper>
              <Controller
                name="taxExempt"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <>
                    {taxExemptSectionOptions.map((option, index) => (
                      <RadioButton
                        key={option + index}
                        name="taxExempt"
                        radioButtonText={option}
                        selectedOption={value ? taxExemptSectionOptions[1] : taxExemptSectionOptions[0]}
                        handleRadioChange={(event) => onChange(event.target.value === taxExemptSectionOptions[1])}
                      />
                    ))}
                  </>
                )}
              />
            </OptionsWrapper>
            <AnimatePresence>
              <Banner
                title=""
                description="Flag this entity as unsuitable for opportunities that do not provide a UBTI Blocker."
                icon={<InfoIcon width={26} height={26} fill={theme.header.base} />}
                bgColor={theme.layer[1]}
                color={theme.font.base}
              />
            </AnimatePresence>
          </RadioInputWrapper>
        </RadioWrapper>
      </Section>
    </SectionsWrap>
  );
};

const HouseholdSelect = styled(FilterSelect)`
  background-color: ${({ theme }) => theme.layer[2]};
  border: 1px solid ${({ theme }) => theme.border.base};
  padding: 10px 12px;
  border-radius: 5px;

  #titleSelect {
    text-transform: none;
    font-family: Blinker;
    font-size: 19px;
    line-height: 26.6px;
  }

  #dropDownSelect {
    top: 50px;
  }
`;

const AdditionalOptionWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  cursor: pointer;
`;

const AddText = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: ${({ theme }) => theme.font.action};
`;

const ErrorText = styled.p`
  color: ${({ theme }) => theme.context.error};
  font-family: Blinker;
  font-style: normal;
  font-weight: 400;
  font-size: 19px;
  line-height: 26.6px;
`;
