import React from 'react';

import { useResponsive } from '@/hooks/use-responsive';
import styled from 'styled-components';

const LoginHero: React.FC = () => {
  const { isMobile, isTablet, isPortrait } = useResponsive();

  return (
    <>
      {isMobile || (isPortrait && isTablet) ? (
        <>
          <Container>
            <MobileLogo src={process.env.REACT_APP_LOGIN_LOGO} />
          </Container>
        </>
      ) : (
        <>
          <Container>
            <img src={process.env.REACT_APP_LOGIN_LOGO} />
          </Container>
        </>
      )}
    </>
  );
};

export default LoginHero;

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const MobileLogo = styled.img`
  width: 100px;
  height: 100px;
`;
