import client from '@/apollo-client';
import { CloseInfoIcon } from '@/assets/icons/info-icons/closeInfo';
import { EditTodoIcon } from '@/assets/icons/info-icons/editTodo';
import Button from '@/components/basicComponents/button';
import { CloseBtn, ModalControlWrap, ModalPageTitle } from '@/components/client-details';
import ShowText from '@/components/dueDiligence-page/dueDiligenceRecord/showText';
import { TierDropdown } from '@/components/dueDiligence-page/dueDiligenceRecord/tierDropdown';
import { EmptyTodoRowCell, FieldValueWrapper, InfoRowCell, RowWrapper } from '@/components/dueDiligence-page/dueDiligenceRecord/tierSection';
import Header from '@/components/header';
import { Loader } from '@/components/loaders/loader';
import { useResponsive } from '@/hooks/use-responsive';
import { MainWrap, PaddingWrap } from '@/styles/common';
import { useMutation, useQuery } from '@apollo/client';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { CREATE_SETTING, GET_SETTINGS, UPDATE_SETTING, getAssetSignedUrl } from '../../queries';
import { HeaderSubTitle, HeaderTitleWrap } from '../importData';
import { basicInformationItems, templateStylingItems } from './constants';
import { EditDisclosureText } from './editDisclosureText';
import { EditFirmFields } from './editFirmFields';
import { EditFontStyle } from './editFontStyle';
import { EditWatermark } from './editWatermark';
import { ISetting } from './types';
import { isURL } from './utils';

interface FirmSettingsProps {
  handleClose: () => void;
}

export interface IFirmSettings {
  id: string | null;
  fieldName: string;
  key: string;
  value: string;
}

export const FirmSettings = ({ handleClose }: FirmSettingsProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const { isMobile, isTablet } = useResponsive();

  const [windowType, setWindowType] = useState({
    isOpen: false,
    type: 'default'
  });
  const [firmSettingsData, setFirmSettingsData] = useState<IFirmSettings[]>();
  const [templateStyling, setTemplateStyling] = useState<IFirmSettings[]>();
  const [currentRow, setCurrentRow] = useState<IFirmSettings | null>(null);
  const [scrollTo, setScrollTo] = useState(0);
  const [logoImg, setLogoImg] = useState('');
  const [watermarkImg, setWatermarkImg] = useState('');

  interface ValuesMap {
    [key: string]: {
      value: any;
      id: string | null;
    };
  }

  const mappedData = (keyArray: any, valueArray: ISetting[], setMappedArray: Dispatch<SetStateAction<IFirmSettings[] | undefined>>) => {
    const valuesMap: ValuesMap = {};
    for (const item of valueArray) {
      valuesMap[item.key] = {
        value: JSON.parse(item.value),
        id: item.id
      };
    }

    const mappedItems = keyArray.map((item: any) => {
      const mappedValue = valuesMap[item.key] || { value: '', id: null };
      return {
        fieldName: item.fieldName,
        value: mappedValue.value,
        key: item.key,
        id: mappedValue.id
      };
    });

    setMappedArray(mappedItems);
  };

  const {
    data: firmSettings,
    refetch: firmSettingsRefetch,
    loading: firmSettingsLoading
  } = useQuery(GET_SETTINGS, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    variables: {
      type: 'firmSetting'
    },
    onCompleted: (data) => {
      mappedData(basicInformationItems, data.Settings, setFirmSettingsData);
    }
  });

  const {
    data: templateStylingData,
    refetch: templateStylingRefetch,
    loading: templateStylingLoading
  } = useQuery(GET_SETTINGS, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    variables: {
      type: 'templateStyling'
    },
    onCompleted: (data) => {
      mappedData(templateStylingItems, data.Settings, setTemplateStyling);
    }
  });

  const [createSetting] = useMutation(CREATE_SETTING);
  const [updateSetting] = useMutation(UPDATE_SETTING);

  const updateImageUrl = async (assetKey: string, setImage: Dispatch<SetStateAction<string>>) => {
    const { data } = await client.query({
      query: getAssetSignedUrl,
      fetchPolicy: 'network-only',
      variables: {
        assetKey: assetKey
      }
    });
    setImage(data.getAssetSignedUrl);
  };

  useEffect(() => {
    firmSettingsData?.forEach((item) => {
      if (item.key === 'logo') {
        try {
          const parsedValue = item?.value ? JSON.parse(item.value) : null;
          if (!parsedValue) return;
          if (parsedValue.value) {
            updateImageUrl(parsedValue.assetKey, setLogoImg);
          }
        } catch (error) {
          console.error(error);
        }
      }
    });
  }, [firmSettings, firmSettingsData]);

  useEffect(() => {
    templateStyling?.forEach((item) => {
      if (item.key === 'watermark') {
        try {
          const parsedValue = item?.value ? JSON.parse(item.value) : null;
          if (!parsedValue) return;
          if (parsedValue.value && isURL(parsedValue.value)) {
            updateImageUrl(parsedValue.assetKey, setWatermarkImg);
          }
        } catch (error) {
          console.error(error);
        }
      }
    });
  }, [templateStyling, templateStylingData]);

  useEffect(() => {
    if (!queryParams.get('edit')) {
      setWindowType({ isOpen: false, type: 'default' });
      return;
    }
    if (queryParams.get('windowType') === 'edit-firm-settings') {
      setWindowType({ isOpen: false, type: 'edit-firm-settings' });
      return;
    }
    if (queryParams.get('windowType') === 'edit-headerFontStyle') {
      setWindowType({ isOpen: false, type: 'edit-headerFontStyle' });
      return;
    }
    if (queryParams.get('windowType') === 'edit-bodyFontStyle') {
      setWindowType({ isOpen: false, type: 'edit-bodyFontStyle' });
      return;
    }
  }, [queryParams.get('edit')]);

  useEffect(() => {
    if (windowType.type === 'default') {
      window.scrollTo(0, scrollTo);
    }
  }, [windowType.type, firmSettingsLoading, templateStylingLoading]);

  const createFirmSetting = (type: string, key: string, value: string) => {
    createSetting({
      variables: {
        data: {
          key: key,
          type: type,
          description: '',
          value: value
        }
      },
      onCompleted: () => {
        firmSettingsRefetch();
        templateStylingRefetch();
        handleCloseEdit();
      }
    });
  };

  const updateFirmSetting = (id: string, value: string) => {
    updateSetting({
      variables: {
        data: {
          id: id,
          description: '',
          value: value
        }
      },
      onCompleted: () => {
        firmSettingsRefetch();
        templateStylingRefetch();
        handleCloseEdit();
      }
    });
  };

  const handleRedirect = (row: IFirmSettings, windowType: string) => {
    navigate(`/settings?action=${queryParams.get('action')}&windowType=${windowType}&edit=${row.key}&fieldName=${row.fieldName}&id=${row.id}`);

    setScrollTo(window.scrollY);
    setWindowType({ isOpen: true, type: windowType });
    setCurrentRow(row);
  };

  const handleCloseEdit = () => {
    const newPath = `/settings?action=${queryParams.get('action')}`;
    navigate(newPath);
    setWindowType({ isOpen: false, type: 'default' });
    setCurrentRow(null);
  };

  const getValue = (value: string, fieldKey: string) => {
    switch (fieldKey) {
      case 'address':
        return (
          <ShowText>
            {value.split('\\n').map((line, index) => (
              <React.Fragment key={index}>
                {line}
                <br />
              </React.Fragment>
            ))}
          </ShowText>
        );
      case 'logo':
        return (
          <ImageWrapper>
            <ImageItem src={logoImg} alt="logo" />
          </ImageWrapper>
        );

      default:
        return value;
    }
  };

  const getTemplateStylingValue = (value: string, fieldKey: string) => {
    switch (fieldKey) {
      case 'headerFontStyle':
        const parsedHeaderFont = JSON.parse(value);
        return (
          <FieldValueWrapper
            style={{
              fontFamily: parsedHeaderFont.fontFamily,
              color: `#${parsedHeaderFont.fontColor}`,
              fontWeight: parsedHeaderFont.fontWeight
            }}
          >
            {parsedHeaderFont.fontFamily}, {parsedHeaderFont.fontColor}, {parsedHeaderFont.fontWeight}
          </FieldValueWrapper>
        );
      case 'bodyFontStyle':
        const parsedBodyFont = JSON.parse(value);
        return (
          <FieldValueWrapper
            style={{
              fontFamily: parsedBodyFont.fontFamily,
              color: `#${parsedBodyFont.fontColor}`,
              fontWeight: parsedBodyFont.fontWeight
            }}
          >
            {parsedBodyFont.fontFamily}, {parsedBodyFont.fontColor}, {parsedBodyFont.fontWeight}
          </FieldValueWrapper>
        );
      case 'disclosureText':
        return <ShowText>{value}</ShowText>;
      case 'watermark':
        return isURL(JSON.parse(value).value) ? (
          <ImageWrapper>
            <ImageItem src={watermarkImg} alt="logo" />
          </ImageWrapper>
        ) : (
          <FieldValueWrapper>{JSON.parse(value).value}</FieldValueWrapper>
        );
      default:
        return <></>;
    }
  };

  if (firmSettingsLoading || templateStylingLoading) {
    return <CustomLoader size={60} />;
  }

  return (
    <>
      {windowType.type === 'edit-firm-settings' && (
        <EditFirmFields
          handleClose={handleCloseEdit}
          createFirmSetting={createFirmSetting}
          updateFirmSetting={updateFirmSetting}
          currentRow={currentRow}
          logoImg={logoImg}
        />
      )}
      {(windowType.type === 'edit-headerFontStyle' || windowType.type === 'edit-bodyFontStyle') && (
        <EditFontStyle
          handleClose={handleCloseEdit}
          createFirmSetting={createFirmSetting}
          updateFirmSetting={updateFirmSetting}
          currentRow={currentRow}
        />
      )}
      {windowType.type === 'edit-watermark' && (
        <EditWatermark
          handleClose={handleCloseEdit}
          createFirmSetting={createFirmSetting}
          updateFirmSetting={updateFirmSetting}
          currentRow={currentRow}
          watermarkImg={watermarkImg}
        />
      )}
      {windowType.type === 'edit-disclosureText' && (
        <EditDisclosureText
          handleClose={handleCloseEdit}
          createFirmSetting={createFirmSetting}
          updateFirmSetting={updateFirmSetting}
          currentRow={currentRow}
        />
      )}
      {windowType.type === 'default' && (
        <MainWrap>
          <Header
            modalControl={
              <ModalControlWrap>
                <CloseBtn onClick={handleClose}>
                  <CloseInfoIcon width={18} height={18} />
                </CloseBtn>
                <HeaderTitleWrap>
                  <ModalPageTitle isTablet={isTablet} isMobile={isMobile}>
                    Firm Settings
                  </ModalPageTitle>
                  <HeaderSubTitle>Configure your Firm details and brand appearance for the application and PDF Templates</HeaderSubTitle>
                </HeaderTitleWrap>
              </ModalControlWrap>
            }
          />
          <PaddingWrap>
            <SettingsContainer>
              <TierDropdown title="Basic Information">
                {firmSettingsData?.map((item, index) => (
                  <RowWrapper key={item.fieldName + index} disabled={false} isMobile={isMobile}>
                    <InfoRowCell>{item.fieldName}</InfoRowCell>
                    {item.value ? (
                      <FieldValueWrapper>{getValue(item.value, item.key)}</FieldValueWrapper>
                    ) : (
                      <EmptyTodoRowCell>To Do</EmptyTodoRowCell>
                    )}
                    <EditTodoButton isMobile={isMobile} id="editTodoButton" onClick={() => handleRedirect(item, 'edit-firm-settings')}>
                      <EditTodoIcon />
                    </EditTodoButton>
                  </RowWrapper>
                ))}
              </TierDropdown>
              <TierDropdown title="Template Styling">
                {templateStyling?.map((item, index) => (
                  <RowWrapper key={item.fieldName + index} disabled={false} isMobile={isMobile}>
                    <InfoRowCell>{item.fieldName}</InfoRowCell>
                    {item.value ? <>{getTemplateStylingValue(item.value, item.key)}</> : <EmptyTodoRowCell>To Do</EmptyTodoRowCell>}
                    <EditTodoButton isMobile={isMobile} id="editTodoButton" onClick={() => handleRedirect(item, `edit-${item.key}`)}>
                      <EditTodoIcon />
                    </EditTodoButton>
                  </RowWrapper>
                ))}
              </TierDropdown>
              {/* <TierDropdown title="PDF Template Preview">
                <></>
              </TierDropdown> */}
            </SettingsContainer>
          </PaddingWrap>
        </MainWrap>
      )}
    </>
  );
};

const CustomLoader = styled(Loader)`
  height: 100%;
  align-items: center;
`;

const SettingsContainer = styled.div`
  margin-top: 31px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const EditTodoButton = styled(Button)<{ isMobile: boolean }>`
  padding: 12px;
  background-color: #1c488a;
  margin-left: auto;
  width: ${({ isMobile }) => (isMobile ? '100%' : 'auto')};
  opacity: ${({ isMobile }) => (isMobile ? '1' : '0')};
`;

const ImageWrapper = styled.div`
  width: 183px;
  height: 120px;
  overflow: hidden;
  border: 1px solid #3ab070;
  border-radius: 5px;
  padding: 10px;
`;

const ImageItem = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
