import { NetworkStatus, useQuery } from '@apollo/client';
import styled from 'styled-components';

import { MainWrap, PaddingWrap } from '@/styles/common';
import { useResponsive } from '../../hooks/use-responsive';
import Header from '../fat-header';
import { LoaderOnWholeScreen } from '../loaders/loader-on-whole-screen';
import { DASHBOARD, DEFAULT_LAST_DAYS } from './constants';
import { FirmOverview } from './firm-overview';
import { FundsClosingSoon } from './funds-closing-soon';
import { Recommendations } from './recommendations';
import { IDashboard } from './types';
import { subtractDate } from './utils';

export const Dashboard = () => {
  const { isMobile } = useResponsive();
  const { data, loading, refetch, previousData, networkStatus } = useQuery<IDashboard>(DASHBOARD, {
    variables: {
      date: subtractDate(new Date(), DEFAULT_LAST_DAYS)
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network'
  });
  const dashboardData = data ?? previousData;
  const isLoadingRefetch = NetworkStatus['setVariables'] === networkStatus || NetworkStatus['refetch'] === networkStatus;

  return (
    <Container>
      {(loading && !isLoadingRefetch) || dashboardData === undefined ? (
        <LoaderOnWholeScreen size={60} />
      ) : (
        <MainWrap>
          <Header />
          <PaddingWrap>
            <FirmOverview data={dashboardData.FirmOverview} />
            <Recommendations
              data={dashboardData.Recommendations}
              loading={isLoadingRefetch || data?.Recommendations === undefined}
              refetch={refetch}
            />
            <FundsClosingSoon data={dashboardData.FundsClosingSoon} isMobile={isMobile} />
          </PaddingWrap>
        </MainWrap>
      )}
    </Container>
  );
};

const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;
