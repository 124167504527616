import React from 'react';
import styled, { css, CSSObject } from 'styled-components';

const variantStyles = {
  h1: css`
    font-size: 2.5rem; /* example size, adjust as needed */
    font-weight: bold;
  `,
  h2: css`
    font-size: 2rem;
    font-weight: bold;
  `,
  h3: css`
    font-size: 1.75rem;
    font-weight: bold;
  `,
  h4: css`
    font-size: 1.5rem;
    font-weight: bold;
  `,
  h5: css`
    font-size: 1.25rem;
    font-weight: bold;
  `,
  h6: css`
    font-size: 1rem;
    font-weight: bold;
  `,
  subtitle2: css`
    font-size: 0.875rem; // example size, adjust as needed
    font-weight: normal;
  `
};

type Variant = keyof typeof variantStyles;

interface StyledTypographyProps {
  align?: 'inherit' | 'left' | 'center' | 'right' | 'justify';
  variant?: Variant;
  sx?: CSSObject;
  children?: React.ReactNode;
  component?: keyof JSX.IntrinsicElements;
  className?: string;
  color?: string;
}

const cssFromSx = (sx?: CSSObject) => {
  if (!sx) return undefined;
  return css(sx);
};
const StyledTypography = styled(
  ({
    component: Component = 'p',
    align,
    variant,
    sx,
    className,
    color,
    ...props
  }: StyledTypographyProps) => <Component className={className} {...props} />
)`
  text-align: ${(props) => props.align || 'inherit'};
  ${(props) => props.variant && variantStyles[props.variant]};
  ${(props) => cssFromSx(props.sx)};
`;

const Typography: React.FC<StyledTypographyProps> = ({
  children,
  component,
  align,
  variant,
  sx,
  className,
  color
}) => {
  return (
    <StyledTypography
      as={component}
      align={align}
      variant={variant}
      sx={sx}
      className={className}
      color={color}
    >
      {children}
    </StyledTypography>
  );
};

export default Typography;
