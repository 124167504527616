import FormattingTooltip from '@/components/basicComponents/tooltip/dollarsTooltip';
import NoResults from '@/components/table/noResults';
import RedirectTitleCell from '@/components/table/redirectTitleCell';
import { TableRowCell, TableRowWrapper, TextCell } from '@/components/table/styles';
import { TableColumns } from '@/components/table/types';
import { useAuth } from '@/hooks/use-auth';
import guid from '@/utils/guid';
import styled from 'styled-components';
import { IListInvestors } from '../types';

interface TableBodyInvestorProps {
  listInvestors: IListInvestors[];
  columns: TableColumns[];
  openInvestorDetails: (id: string) => void;
}

const TableBodyInvestor = ({ listInvestors, columns, openInvestorDetails }: TableBodyInvestorProps) => {
  const { user } = useAuth();

  if (!listInvestors?.length) {
    return <NoResults />;
  }

  return (
    <>
      {listInvestors.map((row) => (
        <CustomTableRowWrapper key={row.id}>
          <TableRowCell width={columns[0].width}>
            <RedirectTitleCell id="actionCurioText" onClick={() => openInvestorDetails(row.id)}>
              {row.name}
            </RedirectTitleCell>
          </TableRowCell>
          <TableRowCell width={columns[1].width}>
            {user && user.tenant.type === 'fundManager' && <TextCell> {row.tenant.name}</TextCell>}
          </TableRowCell>
          <TableRowCell width={columns[2].width}>
            <TextCell>{row.investmentCount}</TextCell>
          </TableRowCell>
          <TableRowCell width={columns[3].width}>
            <TextCell>
              <FormattingTooltip zIndex={1000} key={guid()}>
                {row.totalValue}
              </FormattingTooltip>
            </TextCell>
          </TableRowCell>
          <TableRowCell width={columns[4].width}>
            <TextCell>
              <FormattingTooltip zIndex={1000} key={guid()}>
                {row.totalCommitments}
              </FormattingTooltip>
            </TextCell>
          </TableRowCell>
        </CustomTableRowWrapper>
      ))}
    </>
  );
};

export default TableBodyInvestor;

const CustomTableRowWrapper = styled(TableRowWrapper)`
  justify-content: space-between;
`;
