import React from 'react';
import styled, { useTheme } from 'styled-components';

import { RedStarIcon } from '@/assets/icons/info-icons/redStar';
import BasicTooltip from '../tooltip';

interface LabelProps {
  children: React.ReactNode;
  required: boolean;
  className?: string;
  fontSize?: string;
  paddingBottom?: string;
  tooltip?: string;
  tooltipWidth?: number;
}

const Label = ({ children, required, className, fontSize = '19px', paddingBottom = '5px', tooltip, tooltipWidth = 0 }: LabelProps) => {
  const theme = useTheme();

  return (
    <LabelContainer className={className} fontSize={fontSize} paddingBottom={paddingBottom}>
      {required && <RedStarIcon width={12} fill={theme.context.error} />}
      {children}
      {tooltip && <BasicTooltip tooltipContent={tooltip} tooltipWidth={tooltipWidth} withTooltipIcon />}
    </LabelContainer>
  );
};

export default Label;

const LabelContainer = styled.span<{ fontSize: string; paddingBottom: string }>`
  display: flex;
  align-items: center;
  column-gap: 8px;
  font-family: 'Blinker', serif;
  font-weight: 400;
  font-size: ${({ fontSize }) => fontSize};
  line-height: 27px;
  color: ${({ theme }) => theme.font.base};
  padding-bottom: ${({ paddingBottom }) => paddingBottom};
`;
