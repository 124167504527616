import { gql } from '@apollo/client';

export const GET_INVESTOR_EDIT_QUERY = gql`
  query getInvestor($data: getInvestorDto!) {
    getInvestor(data: $data) {
      name
      tenant {
        id
        name
      }
      advisor {
        id
        firstName
        lastName
        initials
      }
    }
  }
`;
