import DropdownActions, { EmptyDropdownActions } from '@/components/table/fat-dropdownActions';
import NoResults from '@/components/table/noResults';
import RedirectTitleCell from '@/components/table/redirectTitleCell';
import { TableBodyWrapper, TableRowCell, TextCell, blinkingFrame } from '@/components/table/styles';
import { IItems, TableColumns } from '@/components/table/types';
import { useAuth } from '@/hooks/use-auth';
import { useResponsive } from '@/hooks/use-responsive';
import { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { ModalWindowType } from '../fat-modals/types';
import { IRowData } from '../types';
import Amount from './cell/amount';
import ClientsAdvisor from './cell/clientsAdvisor';
import LastUpdate from './cell/lastUpdate';
import NextStep from './cell/nextStep';

interface BodyRowProps {
  data: IRowData[] | undefined;
  columns: TableColumns[];
  updateLoading: boolean;
  openEntityDetails: (investorId: string, entityId: string) => void;
  openInvestorDetails: (id: string) => void;
  openOpportunityDetailsPage: (id: string) => void;
  openModalWindow: (type: ModalWindowType, row: IRowData) => void;
  currentRow: IRowData | null;
  changeSelectRow: (row: IRowData, entityName: string) => void;
  canShowChangeAmountButton: (row: IRowData) => any;
  actionsByStatus: (row: IRowData) => IItems[];
}

export const TableBody = ({
  columns,
  data,
  openEntityDetails,
  openInvestorDetails,
  openOpportunityDetailsPage,
  updateLoading,
  currentRow,
  changeSelectRow,
  canShowChangeAmountButton,
  actionsByStatus,
  openModalWindow
}: BodyRowProps) => {
  const { user } = useAuth();

  const { isTablet } = useResponsive();
  const [recommendationsRows, setRecommendationsRows] = useState<IRowData[]>([]);
  const [selectedRow, setSelectedRow] = useState<string>();

  useEffect(() => {
    if (data === undefined) return;
    setRecommendationsRows(data);
  }, [data]);

  if (!recommendationsRows.length) {
    return <NoResults />;
  }
  return (
    <TableBodyWrapper>
      {recommendationsRows.map((row) => (
        <TableRowWrapper
          key={row.id}
          isTablet={isTablet}
          selected={selectedRow === row.id}
          onMouseEnter={() => isTablet && setSelectedRow(row.id)}
          loading={updateLoading && row.id === currentRow?.id ? 1 : 0}
        >
          <TableRowCell width={columns[0].width}>
            {user && user.tenant.type === 'fundManager' && <TextCell>{row.legalEntity.tenant.name}</TextCell>}
          </TableRowCell>
          <TableRowCell width={columns[1].width}>
            <ClientsAdvisor
              onRedirect={() => openInvestorDetails(row.legalEntity.family.id)}
              familyName={row.legalEntity.family.name}
              advisor={row?.legalEntity?.advisor?.initials}
            />
          </TableRowCell>
          <TableRowCell width={columns[2].width}>
            <RedirectTitleCell id="actionCurioText" onClick={() => openEntityDetails(row.legalEntity?.family.id, row.legalEntity?.id)}>
              {row.legalEntity?.entityName}
            </RedirectTitleCell>
            {/* <Entity
              row={row}
              changeSelect={(value) => changeSelectRow(row, value)}
              onRedirect={() => openEntityDetails(row.legalEntity.family.id, row.legalEntity.id)}
            /> */}
          </TableRowCell>
          <TableRowCell width={columns[3].width}>
            <RedirectTitleCell id="actionCurioText" onClick={() => openOpportunityDetailsPage(row.investment.id)}>
              {row.investment.name}
            </RedirectTitleCell>
            {/* <InvestmentComponent row={row} onRedirect={() => openOpportunityDetailsPage(row.investment.id)} /> */}
          </TableRowCell>
          <TableRowCell width={columns[4].width}>
            {!row.isAmountHidden ? (
              <Amount
                showChangeAmountButton={Boolean(user && user.tenant.type === 'fundManager')}
                amount={row.committedCapital}
                openWindow={() => openModalWindow('amount', row)}
              />
            ) : null}
          </TableRowCell>
          <TableRowCell width={columns[5].width}>
            {user && user.tenant.type === 'fundManager' && (
              <TextCell>
                {row.assignee && `${row.assignee.firstName && row.assignee?.firstName} ${row.assignee?.lastName && row.assignee.lastName}`}
              </TextCell>
            )}
          </TableRowCell>
          <TableRowCell width={columns[6].width}>
            <LastUpdate row={row} />
          </TableRowCell>
          <TableRowCell width={columns[7].width}>
            <NextStep status={row.operationsWorkflowTransitionTask.name} />
          </TableRowCell>
          {actionsByStatus(row).length ? (
            <TableRowCell width={columns[8].width}>
              <DropdownActions selected={selectedRow === row.id} items={actionsByStatus(row)} disabled={updateLoading && row.id === currentRow?.id} />
            </TableRowCell>
          ) : (
            <TableRowCell width={columns[8].width}>
              <EmptyDropdownActions />
            </TableRowCell>
          )}
        </TableRowWrapper>
      ))}
    </TableBodyWrapper>
  );
};

const TableRowWrapper = styled.div<{
  addAdaptive?: boolean;
  loading?: boolean | number;
  selected: boolean;
  isTablet: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  min-height: 60px;
  background-color: ${({ theme }) => theme.layer[1]};
  border-radius: 8px;
  padding: 0 24px;
  gap: 5px;
  opacity: ${({ loading }) => (loading ? 0.5 : 1)};
  pointer-events: ${({ loading }) => (loading ? 'none' : 'auto')};
  animation: ${({ loading }) => (loading ? blinkingFrame : null)} 1.5s infinite;

  @media (max-width: 1200px) {
    padding: 0 ${({ addAdaptive }) => (addAdaptive ? 10 : 24)}px;
  }

  ${({ isTablet, selected }) =>
    isTablet
      ? css`
          #infoWrap {
            ${selected
              ? css`
                  opacity: 1;
                `
              : ''}
          }

          #actionText {
            ${selected
              ? css`
                  color: #4587ec;
                `
              : ''}
          }

          #actionCurioText {
            ${selected
              ? css`
                  color: ${({ theme }) => theme.font.action};
                `
              : ''}
          }

          #actionOrangeText {
            ${selected
              ? css`
                  color: #ef9208;
                `
              : ''}
          }

          #tempInfoWrap {
            ${selected
              ? css`
                  display: none;
                `
              : ''}
          }

          #arrowInSelectRow {
            svg {
              path {
                ${selected
                  ? css`
                      fill: #ef9208;
                    `
                  : ''}
              }
            }
          }

          #selector {
            ${selected
              ? css`
                  outline: 1px solid #c7cbd2 !important;
                  background-color: #fff;
                `
              : ''}
          }

          #selectIcon {
            ${selected
              ? css`
                  opacity: 1;
                `
              : ''}
          }

          ${selected
            ? css`
                background-color: ${({ theme }) => theme.layer[12]};
                transition: background-color 100ms ease;
              `
            : ''}
        `
      : css`
          &:hover #infoWrap {
            opacity: 1;
          }

          &:hover #actionText {
            color: #4587ec;
          }

          &:hover #actionOrangeText {
            color: #ef9208;
          }

          &:hover #tempInfoWrap {
            display: none;
          }

          &:hover #actionCurioText {
            color: #00aeef;
          }

          &:hover #arrowInSelectRow {
            svg {
              path {
                fill: #ef9208;
              }
            }
          }

          &:hover #selector {
            outline: 1px solid #c7cbd2 !important;
            background-color: #fff;
          }

          &:hover #selectIcon {
            opacity: 1;
          }

          &:hover {
            background-color: ${({ theme }) => theme.layer[12]};
            transition: background-color 100ms ease;
          }
        `}
`;
