import FormattingTooltip from '@/components/basicComponents/tooltip/dollarsTooltip';
import NoResults from '@/components/table/noResults';
import RedirectTitleCell from '@/components/table/redirectTitleCell';
import { TableRowCell, TableRowWrapper, TextCell } from '@/components/table/styles';
import { TableColumns } from '@/components/table/types';
import { formatPercent } from '@/utils/currency';
import guid from '@/utils/guid';
import { InvestmentEntityByAdvisoryFirm } from '../types';

interface TabelBodyAdvisoryFirmProps {
  advisoryFirmTableRows: InvestmentEntityByAdvisoryFirm[];
  columns: TableColumns[];
  openAdvisorFirmDetails: (id: string) => void;
}

const TabelBodyAdvisoryFirm = ({ advisoryFirmTableRows, columns, openAdvisorFirmDetails }: TabelBodyAdvisoryFirmProps) => {
  if (!advisoryFirmTableRows?.length) {
    return <NoResults />;
  }

  return (
    <>
      {advisoryFirmTableRows.map((row: InvestmentEntityByAdvisoryFirm) => (
        <TableRowWrapper key={guid()}>
          <TableRowCell width={columns[0].width}>
            <RedirectTitleCell id="actionCurioText" onClick={() => openAdvisorFirmDetails(row.tenant.id)}>
              {row.tenant.name}
            </RedirectTitleCell>
          </TableRowCell>
          <TableRowCell width={columns[1].width}>
            <TextCell>{row.investorEntities}</TextCell>
          </TableRowCell>
          <TableRowCell width={columns[2].width}>
            <TextCell>
              <FormattingTooltip zIndex={1000}>{row.committedCapital}</FormattingTooltip>
            </TextCell>
          </TableRowCell>
          <TableRowCell width={columns[3].width}>
            <TextCell>
              <FormattingTooltip zIndex={1000}>{row.capitalCalled}</FormattingTooltip>
            </TextCell>
          </TableRowCell>
          <TableRowCell width={columns[4].width}>
            <TextCell>{formatPercent(row.percentOfCapitalCalled)}</TextCell>
          </TableRowCell>
          {/* <TableRowCell width={columns[5].width}>
            <TextCell >{formatPercent(row.IRR)}</TextCell>
          </TableRowCell>
          <TableRowCell width={columns[6].width}>
            <TextCell >{row.TVPI}x</TextCell>
          </TableRowCell> */}
        </TableRowWrapper>
      ))}
    </>
  );
};

export default TabelBodyAdvisoryFirm;
