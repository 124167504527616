import { RejectedBannerIcon } from '@/assets/icons/info-icons/rejectedBanner';
import { Banner } from '@/components/basicComponents/banner';
import Input from '@/components/fat-basicComponents/input';
import { formatDollarInputValue, formatMaskedDollarInputValue } from '@/components/fat-basicComponents/input/utils';
import ModalWrappedContent from '@/components/fat-basicComponents/modal/modalWrappedContent';
import { useEffect, useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { IInvestmentEntity } from '../types';

interface EditAmountProps {
  onClose: () => void;
  isOpen: boolean;
  row: IInvestmentEntity | null;
  updateAmountInState: (id: string, value: number) => void;
  minimum: number;
}

interface ErrorMessage {
  title: string;
  message: string;
}

const EditAmount = ({ onClose, isOpen, row, updateAmountInState, minimum }: EditAmountProps) => {
  const theme = useTheme();

  const [value, setValue] = useState('');
  const [errorText, setErrorText] = useState<ErrorMessage | null>(null);

  const validateAmount = (amount: number) => {
    let errorMessage: ErrorMessage | null = null;
    if (value[0] === '0') {
      errorMessage = {
        title: 'Cannot be less than 0',
        message: 'Any increase investment value must be greater than 0'
      };
    }
    if (amount < minimum) {
      errorMessage = {
        title: 'Cannot be less than fund minimum',
        message: 'The initial investment amount must be equal or greater than the investment minimum.'
      };
    }

    setErrorText(errorMessage);
    return errorMessage;
  };

  useEffect(() => {
    if (!row) return;

    setValue(row?.investor_interest[0]?.amount?.toString() ?? '');
  }, [row]);

  const saveValue = () => {
    const numberAmount = parseInt(value);
    const errorMessage = validateAmount(numberAmount);
    if (errorMessage) return;

    updateAmountInState(row.id, numberAmount);
    onClose();
  };

  if (!row) return <></>;

  return (
    <ModalWrappedContent
      isOpen={isOpen}
      onClose={onClose}
      title="Edit Investment Amount"
      confirmButton={{
        name: 'Save',
        onClick: saveValue,
        disabled: !value
      }}
    >
      {errorText && (
        <Banner
          icon={<RejectedBannerIcon fill={theme.context.light} />}
          title={errorText?.title ?? ''}
          description={errorText?.message ?? ''}
          bgColor="#D63B4B"
        />
      )}
      <Input
        value={value ? formatMaskedDollarInputValue(value) : ''}
        onChange={(e) => {
          const formattedValue = formatDollarInputValue(e.target.value);
          setValue(formattedValue);
        }}
        label="Initial Investment Amount"
        isRequiredField
        withDollarSign
        styleType={value ? 'success' : 'default'}
        autoFocus
      />
      <Description>Initial Investment Minimum: ${minimum}</Description>
    </ModalWrappedContent>
  );
};

export default EditAmount;

const Description = styled.span`
  padding-top: 10px;
  font-weight: 300;
  font-size: 13px;
  line-height: 18px;
  color: ${({ theme }) => theme.font.weak};
`;
