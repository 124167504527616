import * as React from 'react';

export const ApprovedIcon = ({ ...props }: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={props.width || 33}
      height={props.height || 33}
      style={props?.style}
      viewBox="0 0 33 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M29.4978 7.58688L14.7074 22.3773L9.20376 16.8737L11.0331 15.0444L14.7074 18.7186L27.6762 5.74198L29.4978 7.58688ZM26.6331 14.1258C26.8132 14.8912 26.9046 15.6748 26.9056 16.4611C26.9106 18.7702 26.1471 21.0153 24.7353 22.8425C23.3236 24.6698 21.3438 25.9752 19.1082 26.5531C16.8726 27.1309 14.5083 26.9484 12.3879 26.0341C10.2676 25.1199 8.51171 23.526 7.3972 21.5037C6.28269 19.4814 5.87288 17.1457 6.23238 14.8648C6.59189 12.5839 7.70027 10.4875 9.38279 8.90602C11.0653 7.32457 13.2263 6.34802 15.5251 6.13032C17.8238 5.91262 20.1297 6.46615 22.0792 7.70365L23.9475 5.83539C21.7759 4.30805 19.1838 3.4921 16.5289 3.50006C13.7634 3.49391 11.0679 4.36985 8.83435 6.00059C6.60077 7.63132 4.94547 9.93186 4.10896 12.5678C3.27245 15.2038 3.29836 18.0379 4.18294 20.6582C5.06751 23.2784 6.7646 25.5483 9.02763 27.1379C11.2907 28.7275 14.0017 29.554 16.7666 29.4973C19.5316 29.4406 22.2064 28.5036 24.4023 26.8225C26.5982 25.1414 28.2009 22.8038 28.9772 20.1495C29.7536 17.4951 29.6633 14.6624 28.7193 12.0629L26.6331 14.1258Z"
        fill={props?.fill || '#3DBD4A'}
      />
    </svg>
  );
};
