import { getUTCDate } from '@/components/allocations-page/utils';
import FormattingTooltip from '@/components/basicComponents/tooltip/dollarsTooltip';
import SuitabilityDetails, { SuitabilityWrapper } from '@/components/opportunities-entity/pendingAllocation/suitabilityDetails';
import { AnchorPopover } from '@/components/table/anchor-drop-down/fat-index';
import DropdownActions from '@/components/table/fat-dropdownActions';
import NoResults from '@/components/table/noResults';
import RedirectTitleCell from '@/components/table/redirectTitleCell';
import { TableRowCell, TableRowWrapper, TextCell } from '@/components/table/styles';
import guid from '@/utils/guid';
import { allocationsTablecolumns } from '../constatnts';
import { ClientEntityAllocationsTableType } from '../types';
import Suitability from './suitability';

interface TableBodyProps {
  investorAllocationRows: ClientEntityAllocationsTableType[];
  openModalWindow: (type: string, row: ClientEntityAllocationsTableType) => void;
  openOpportunityDetailsPage: (id: string) => void;
}

const TableBody = ({ investorAllocationRows, openModalWindow, openOpportunityDetailsPage }: TableBodyProps) => {
  if (!investorAllocationRows?.length) {
    return <NoResults />;
  }

  return (
    <>
      {investorAllocationRows.map((row) => (
        <TableRowWrapper key={row.investmentId}>
          <TableRowCell width={allocationsTablecolumns[0].width}>
            <RedirectTitleCell id="actionCurioText" onClick={() => openOpportunityDetailsPage(row.investmentId)}>
              {row.name}
            </RedirectTitleCell>
          </TableRowCell>
          <TableRowCell width={allocationsTablecolumns[1].width}>
            <TextCell>{row.status === 'Outstanding' ? 'Pending' : row.status}</TextCell>
          </TableRowCell>
          <TableRowCell width={allocationsTablecolumns[2].width}>
            <TextCell>{row.subAssetClass ? `${row.assetClass?.name} - ${row.subAssetClass.name}` : row.assetClass?.name}</TextCell>
          </TableRowCell>
          <TableRowCell width={allocationsTablecolumns[3].width}>
            {row.investorDocsDue && <TextCell>{getUTCDate(new Date(row.investorDocsDue))}</TextCell>}
          </TableRowCell>
          <TableRowCell width={allocationsTablecolumns[4].width}>
            <TextCell>
              <FormattingTooltip zIndex={1000}>{row.minimum}</FormattingTooltip>
            </TextCell>
          </TableRowCell>
          <TableRowCell width={allocationsTablecolumns[5].width}>
            <AnchorPopover
              showLeft
              rowBody={
                <SuitabilityWrapper>
                  <Suitability suitability={row.suitability} width="80" />
                </SuitabilityWrapper>
              }
              title={row.suitability}
            >
              <SuitabilityDetails data={row.suitabilityCriteria} key={guid()} />
            </AnchorPopover>
          </TableRowCell>
          <TableRowCell width={allocationsTablecolumns[6].width}>
            {row.status !== 'Outstanding' && (
              <DropdownActions
                items={[
                  {
                    name: 'Begin Allocation',
                    onClick: () => openModalWindow('edit-amount', row)
                  }
                ]}
              />
            )}
          </TableRowCell>
        </TableRowWrapper>
      ))}
    </>
  );
};

export default TableBody;
