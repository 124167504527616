import * as React from 'react';

export const OverdueIcon = ({ ...props }: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={props.width || 27}
      height={props.height || 25}
      style={props?.style}
      viewBox="0 0 27 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.38649 8.27523H6.77727V9.66965H5.38649V8.27523ZM5.38649 2.71007H6.77727V6.88499H5.38649V2.71007ZM6.07562 11.7529C4.97352 11.7538 3.89591 11.4278 2.97915 10.8164C2.06238 10.2049 1.34762 9.33538 0.925293 8.31779C0.502964 7.3002 0.392047 6.18026 0.60655 5.09964C0.821053 4.01902 1.35135 3.02627 2.13037 2.24697C2.90938 1.46766 3.9021 0.936814 4.98296 0.721581C6.06383 0.506348 7.18427 0.616389 8.20256 1.0378C9.22085 1.4592 10.0912 2.17304 10.7036 3.08901C11.316 4.00497 11.6429 5.08192 11.6429 6.18361C11.644 7.65924 11.0592 9.07499 10.0169 10.12C8.97469 11.165 7.56014 11.7538 6.08396 11.7571L6.07562 11.7529Z"
        fill="#D63B4B"
      />
      <path d="M6.77727 8.27523H5.38649V9.66965H6.77727V8.27523Z" fill="#FAFAFA" />
      <path d="M6.77727 2.71007H5.38649V6.88499H6.77727V2.71007Z" fill="#FAFAFA" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.42736 13.2344C6.30964 13.2402 6.19116 13.2432 6.07199 13.2432C5.43023 13.2432 4.80849 13.1575 4.21756 12.997C4.19968 13.807 4.27011 14.6206 4.43026 15.4232C4.8615 17.5844 5.92398 19.5692 7.48327 21.1267C9.04256 22.6841 11.0286 23.7442 13.1904 24.1728C15.3521 24.6015 17.5925 24.3795 19.6281 23.5349C21.6637 22.6903 23.4031 21.261 24.6264 19.4278C25.8497 17.5947 26.5019 15.4399 26.5006 13.2361C26.5014 11.7723 26.2136 10.3227 25.6534 8.9703C25.0933 7.61792 24.2718 6.38934 23.2361 5.3549C22.2004 4.32046 20.9709 3.50049 19.6178 2.94195C18.2648 2.3834 16.8148 2.09723 15.351 2.09987C14.2562 2.10053 13.1737 2.26247 12.1369 2.57553C12.514 3.20772 12.7952 3.90384 12.9601 4.64377C13.1717 4.58506 13.3859 4.53404 13.6023 4.49093C15.3322 4.14633 17.1254 4.32251 18.7552 4.99722C20.3849 5.67193 21.7779 6.81484 22.7581 8.28138C23.7382 9.74791 24.2613 11.4722 24.2613 13.2361C24.2613 15.5975 23.3246 17.8625 21.6567 19.5342C19.9887 21.2059 17.7259 22.1477 15.3644 22.153H15.351C13.5872 22.1543 11.8625 21.6325 10.3952 20.6535C8.92794 19.6745 7.78402 18.2823 7.10809 16.6531C6.65632 15.5641 6.42716 14.402 6.42736 13.2344ZM14.2347 7.66797H15.9058V13.5168L20.9191 16.4914L20.0836 17.8617L14.2347 14.3524V7.66797Z"
        fill={props?.fill || '#D63B4B'}
      />
    </svg>
  );
};
