import React from 'react';
import { useRoutes } from 'react-router-dom';
import { fatRoutes } from './fatRouteConfig';
import { jfgRoutes } from './jfgRouteConfig';

export function AppRoutes() {
  // const parseRouteObjects = (routes: AppRoute[]): RouteObject[] => {
  //   return routes.map((route) => ({
  //     path: route.path,
  //     element: privateRoutes.includes(route)
  //       ? PrivateRoute({
  //           children: route.layout({ children: route.element })
  //         })
  //       : route.layout({ children: route.element })
  //   }));
  // };

  // const publicRouteObjects = parseRouteObjects(publicRoutes);
  // const privateRouteObjects = parseRouteObjects(privateRoutes);
  // const fallbackRouteObjects = parseRouteObjects(fallbackRoute);

  // const routes = [...publicRouteObjects, ...privateRouteObjects, ...fallbackRouteObjects];

  const project: string = 'curio';

  const routes = project === 'jfg' ? jfgRoutes : fatRoutes;

  const allRoutes = useRoutes(routes);

  return <React.Fragment> {allRoutes} </React.Fragment>;
}
