import Label from '@/components/basicComponents/label';
import Textarea from '@/components/basicComponents/textarea';
import { TierDropdown } from '@/components/dueDiligence-page/dueDiligenceRecord/tierDropdown';
import Button from '@/components/fat-basicComponents/button';
import Header from '@/components/fat-header';
import { GoBackButton } from '@/components/fat-header/goBackButton';
import { PageTitle } from '@/components/fat-header/pageTitle';
import { IDocumentRecordFieldEntity } from '@/components/managerUpdates-page/managerUpdateDetails/types';
import { CREATE_DEAL_PROMPT_GROUP, UPDATE_DEAL_PROMPT_GROUP } from '@/components/settings-page/queries';
import { TierDropdownWrapper } from '@/components/settings-page/settings-pages/dealFunnelEditor';
import DeleteAllPrompt from '@/components/settings-page/settings-pages/dealFunnelEditor/modals/deleteAllPrompt';
import { PromptTemplate } from '@/components/settings-page/settings-pages/dealFunnelEditor/promptTemplate';
import { useResponsive } from '@/hooks/use-responsive';
import { MainWrap, PaddingWrap } from '@/styles/common';
import { useMutation } from '@apollo/client';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

interface PromptEditorProps {
  handleClose: () => void;
  currentRow: IDocumentRecordFieldEntity | null;
  currentRowName: string;
  refetch: any;
  savePromptGroup?: (promptGroup: { [key: string]: string } | null) => void;
}
// In one paragraph, provide a summary of the background of the firm behind this fund. Include details such as its founding date or years in operation, key members of the management team and their relevant experience, and a concise description of the firm's strategy. Please include details regarding the firm's track record and historical performance. In a second paragraph, explain how this particular fund fits within that strategy and a high level summary of the fund. Remove any repetitive statements.
export const PromptEditor = ({ handleClose, currentRow, currentRowName, refetch, savePromptGroup }: PromptEditorProps) => {
  const { isMobile, isTablet } = useResponsive();

  const [promptGroup, setPromptGroup] = useState({
    Question: '',
    'Context Template': '',
    'Summary Template': ''
  });
  const [selectedOption, setSelectedOption] = useState({
    'Context Template': 'Use default',
    'Summary Template': 'Use default'
  });
  const [modalWindow, setModalWindow] = useState({
    isOpen: false,
    type: 'delete-prompts'
  });
  const [isValid, setIsValid] = useState({
    'Context Template': true,
    'Summary Template': true
  });

  useEffect(() => {
    if (currentRow?.promptGroup) {
      setPromptGroup({
        Question: currentRow.promptGroup?.prompts[0] ?? '',
        'Context Template': currentRow.promptGroup.useCustomContextPrompt ? currentRow.promptGroup?.contextPrompt : '',
        'Summary Template': currentRow.promptGroup.useCustomSummaryPrompt ? currentRow.promptGroup?.summaryPrompt : ''
      });
      setSelectedOption({
        'Context Template': currentRow.promptGroup.useCustomContextPrompt ? 'Customize' : 'Use default',
        'Summary Template': currentRow.promptGroup.useCustomSummaryPrompt ? 'Customize' : 'Use default'
      });
    }
  }, [currentRow]);

  useEffect(() => {
    if (!isValid['Context Template']) {
      setIsValid({ ...isValid, 'Context Template': true });
    }
  }, [promptGroup['Context Template']]);

  useEffect(() => {
    if (!isValid['Summary Template']) {
      setIsValid({ ...isValid, 'Summary Template': true });
    }
  }, [promptGroup['Summary Template']]);

  const [updatePromptGroup] = useMutation(UPDATE_DEAL_PROMPT_GROUP);
  const [createPromptGroup] = useMutation(CREATE_DEAL_PROMPT_GROUP);

  const saveValue = () => {
    const isCustomContext = selectedOption['Context Template'] === 'Customize';
    const isCustomSummary = selectedOption['Summary Template'] === 'Customize';

    const isContextPresent = promptGroup['Context Template'].includes('{context}');

    const isContextValid = !isCustomContext || isContextPresent;
    const isSummaryValid = !isCustomSummary || true;

    setIsValid({
      'Summary Template': isSummaryValid,
      'Context Template': isContextValid
    });

    if (!isContextValid || !isSummaryValid) {
      return;
    }

    if (!currentRow?.promptGroup?.id) {
      createPromptGroup({
        variables: {
          data: {
            fieldId: currentRow?.fieldId,
            prompts: [promptGroup.Question],
            ...(selectedOption['Summary Template'] === 'Customize' && {
              summaryPrompt: promptGroup['Summary Template']
            }),
            ...(selectedOption['Context Template'] === 'Customize' && {
              contextPrompt: promptGroup['Context Template']
            }),
            useCustomContextPrompt: selectedOption['Context Template'] !== 'Use default',
            useCustomSummaryPrompt: selectedOption['Summary Template'] !== 'Use default',
            rubricTemplates: []
          }
        },
        onCompleted: (data) => {
          const { id, prompts, summaryPrompt, contextPrompt, useCustomContextPrompt, useCustomSummaryPrompt } = data.createDealAssistantAIPromptGroup;

          if (savePromptGroup) {
            savePromptGroup({
              id: id,
              Question: prompts[0],
              'Context Template': contextPrompt,
              'Summary Template': summaryPrompt,
              useCustomContextPrompt: useCustomContextPrompt,
              useCustomSummaryPrompt: useCustomSummaryPrompt
            });
          }
          handleClose();
          refetch();
        }
      });
      return;
    } else {
      updatePromptGroup({
        variables: {
          data: {
            id: currentRow?.promptGroup?.id,
            prompts: [promptGroup.Question],
            ...(selectedOption['Summary Template'] === 'Customize' && {
              summaryPrompt: promptGroup['Summary Template']
            }),
            ...(selectedOption['Context Template'] === 'Customize' && {
              contextPrompt: promptGroup['Context Template']
            }),
            useCustomContextPrompt: selectedOption['Context Template'] !== 'Use default',
            useCustomSummaryPrompt: selectedOption['Summary Template'] !== 'Use default',
            rubricTemplates: []
          }
        },
        onCompleted: (data) => {
          const { id, prompts, summaryPrompt, contextPrompt, useCustomContextPrompt, useCustomSummaryPrompt } = data.updateDealAssistantAIPromptGroup;

          if (savePromptGroup) {
            savePromptGroup({
              id: id,
              Question: prompts[0],
              'Context Template': contextPrompt,
              'Summary Template': summaryPrompt,
              useCustomContextPrompt: useCustomContextPrompt,
              useCustomSummaryPrompt: useCustomSummaryPrompt
            });
          }
          handleClose();
          refetch();
        }
      });
    }
  };

  return (
    <>
      {modalWindow.type === 'delete-prompts' && (
        <DeleteAllPrompt
          isOpen={modalWindow.isOpen}
          onClose={() => setModalWindow({ ...modalWindow, isOpen: false })}
          fieldName={currentRowName}
          promptGroupId={currentRow?.promptGroup?.id ?? ''}
          closePromptPage={handleClose}
          refetch={refetch}
          savePromptGroup={savePromptGroup}
        />
      )}
      <MainWrap>
        <Header modalControl={<GoBackButton handleClose={handleClose} backToTitle="" />} />
        <PageTitle title={`${currentRowName} Settings`} />
        <PaddingWrap>
          <TierDropdownWrapper>
            <TierDropdown title="Prompt Settings">
              <PromptsWrapper>
                <div>
                  <Label tooltip="Provide a question that corresponds to the desired content for this field." tooltipWidth={250} required>
                    Question
                  </Label>
                  <Textarea
                    textareaHeight="110px"
                    maxLength={5000}
                    value={promptGroup.Question}
                    setValue={(value) =>
                      setPromptGroup({
                        ...promptGroup,
                        Question: value
                      })
                    }
                  />
                </div>
                <PromptTemplate
                  title="Context Template"
                  tooltip="The AI Assistant will digest each document in chunks. The Context Template assembles a full prompt for the AI, which will include {context}. Be sure to provide this token in the template."
                  value={promptGroup['Context Template']}
                  setValue={(value) =>
                    setPromptGroup({
                      ...promptGroup,
                      ['Context Template']: value
                    })
                  }
                  selectedOption={selectedOption['Context Template']}
                  setSelectedOption={(option) =>
                    setSelectedOption({
                      ...selectedOption,
                      ['Context Template']: option
                    })
                  }
                  isValid={isValid['Context Template']}
                  errorMessage="Include the {context} token in the template"
                />
                <PromptTemplate
                  title="Summary Template"
                  tooltip="For better performance, wrap any instructions with the tags <instructions> and </instructions>."
                  value={promptGroup['Summary Template']}
                  setValue={(value) =>
                    setPromptGroup({
                      ...promptGroup,
                      ['Summary Template']: value
                    })
                  }
                  selectedOption={selectedOption['Summary Template']}
                  setSelectedOption={(option) =>
                    setSelectedOption({
                      ...selectedOption,
                      ['Summary Template']: option
                    })
                  }
                  isValid={isValid['Summary Template']}
                  errorMessage=""
                />
              </PromptsWrapper>
            </TierDropdown>
          </TierDropdownWrapper>
          <ButtonWrapper isMobile={isMobile}>
            <Button styleType="outline" onClick={handleClose} size="lg">
              Cancel
            </Button>
            {currentRow?.promptGroup && (
              <Button styleType="outline" onClick={() => setModalWindow({ type: 'delete-prompts', isOpen: true })} size="lg">
                Delete Prompt Settings
              </Button>
            )}
            <Button
              styleType={
                Boolean(
                  !promptGroup.Question ||
                    (selectedOption['Context Template'] === 'Customize' && !promptGroup['Context Template']) ||
                    (selectedOption['Summary Template'] === 'Customize' && !promptGroup['Summary Template'])
                )
                  ? 'disabled'
                  : 'default'
              }
              onClick={saveValue}
              size="lg"
            >
              Save Prompt Settings
            </Button>
          </ButtonWrapper>
        </PaddingWrap>
      </MainWrap>
    </>
  );
};

const PromptsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
`;

const ButtonWrapper = styled.div<{ isMobile: boolean }>`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};
  gap: ${({ isMobile }) => (isMobile ? '10px' : '0')};
  margin: 45.5px 0;
  gap: 10px;
`;
