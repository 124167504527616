import { InfoIcon } from '@/assets/icons/info-icons/info';
import { Banner } from '@/components/basicComponents/banner';
import Button from '@/components/fat-basicComponents/button';
import { useResponsive } from '@/hooks/use-responsive';
import { Dispatch, SetStateAction } from 'react';
import styled, { useTheme } from 'styled-components';
import { ColumnsSection } from '../../columnsSection';
import { DescriptionSection } from '../../descriptionSection';
import { ProgressBar } from '../../progressBar';
import { ButtonsControl, ImportWrapper } from '../../styles';
import { IColumnsSection, IDescriptionSection } from '../../types';
import { UploadFile } from '../../uploadFile';
import { allocationsSteps } from '../constants';

interface UploadDataFileProps {
  importSteps: typeof allocationsSteps;
  handleUploadFile: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onDropHandler: (e: React.DragEvent<HTMLDivElement>) => void;
  drag: boolean;
  setDrag: Dispatch<SetStateAction<boolean>>;
  file: File | null;
  handleClose: () => void;
  nextStep: () => void;
  loading: boolean;
  isValidFile: boolean;
  descriptionSection?: IDescriptionSection;
  columnsSection?: IColumnsSection;
  downloadExistingData?: () => void;
  deleteFile?: () => void;
}

export const UploadDataFile = ({
  importSteps,
  handleUploadFile,
  onDropHandler,
  drag,
  setDrag,
  file,
  handleClose,
  nextStep,
  loading,
  isValidFile,
  descriptionSection,
  columnsSection,
  downloadExistingData,
  deleteFile
}: UploadDataFileProps) => {
  const theme = useTheme();
  const { isMobile, isTablet } = useResponsive();

  return (
    <ImportWrapper>
      <ProgressBar importSteps={importSteps} />
      <SectionsWrapper>
        {descriptionSection && <DescriptionSection {...descriptionSection} />}
        {columnsSection && <ColumnsSection columnsSection={columnsSection} downloadExistingData={downloadExistingData} />}
      </SectionsWrapper>
      <UploadFileText>Upload a file to get started</UploadFileText>
      <DropFileAreaWrap>
        {!isValidFile && (
          <Banner
            icon={<InfoIcon width={26} height={26} fill={theme.context.light} />}
            title="Something went wrong"
            description="Confirm that you are uploading a CSV file, check the required column names, remove any empty or malformed rows, and try again."
            bgColor={theme.context.error}
            color={theme.context.light}
          />
        )}
        <UploadFile
          file={file}
          handleUploadFile={handleUploadFile}
          onDropHandler={onDropHandler}
          drag={drag}
          setDrag={setDrag}
          loading={loading}
          deleteFile={deleteFile}
        />
      </DropFileAreaWrap>
      <ButtonsControl>
        <Button styleType="outline" size="lg" onClick={handleClose}>
          Cancel
        </Button>
        <Button size="lg" styleType={Boolean(!file || loading) ? 'disabled' : 'default'} onClick={nextStep}>
          Continue
        </Button>
      </ButtonsControl>
    </ImportWrapper>
  );
};

const SectionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const UploadFileText = styled.p`
  text-align: center;
  padding: 50px 0 10px 0;
  color: ${({ theme }) => theme.font.weak};
  font-family: Blinker;
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;
`;

const DropFileAreaWrap = styled.div`
  margin-top: 65px;
`;
