import CheckBox from '@/components/basicComponents/checkbox';
import RadioButton from '@/components/basicComponents/radioButton';
import { FormFooter } from '@/components/fat-basicComponents/formFooter';
import Input from '@/components/fat-basicComponents/input';
import { formatPercentageInputValue, handleCursorPositionInPercentageInput } from '@/components/fat-basicComponents/input/utils';
import Label from '@/components/fat-basicComponents/label';
import Header from '@/components/fat-header';
import { GoBackButton } from '@/components/fat-header/goBackButton';
import { PageTitle } from '@/components/fat-header/pageTitle';
import { useResponsive } from '@/hooks/use-responsive';
import { useGoBack } from '@/hooks/useGoBack';
import { MainWrap, PaddingWrap } from '@/styles/common';
import { Controller, useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';

export const BenefitPlansPage = () => {
  const { isMobile } = useResponsive();
  const theme = useTheme();
  const goBack = useGoBack();
  const {
    handleSubmit,
    getValues,
    setValue,
    control,
    setError,
    formState: { errors, isValid },
    reset,
    watch
  } = useForm();

  const [searchParams, setSearchParams] = useSearchParams();

  const backToTitle = searchParams.get('backToTitle') || '';

  const onSubmit = (data: any) => {
    console.log(data);
  };

  return (
    <MainWrap>
      <Header modalControl={<GoBackButton handleClose={() => goBack({ fallBack: '/investors' })} backToTitle={backToTitle} />} />
      <PageTitle title="Benefit Plans" />
      <PaddingWrap>
        <form id="benefitPlansForm" onSubmit={handleSubmit(onSubmit, () => window.scrollTo(0, 0))}>
          <BenefitPlansWrapper isMobile={isMobile}>
            <div>
              <Label size="md">Is entity an ERISA Benefit Plan Investor?</Label>
              <RadioButtonsWrapper>
                <Controller
                  name="radioButton"
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <>
                      {['Yes', 'No'].map((option, index) => (
                        <RadioButton
                          key={option + index}
                          name="capacityCalculation"
                          radioButtonText={option}
                          selectedOption={value}
                          handleRadioChange={(event) => onChange(event.target.value)}
                        />
                      ))}
                    </>
                  )}
                />
              </RadioButtonsWrapper>
            </div>
            <CheckboxWrapper id="subscriberCheckbox" onClick={() => {}}>
              <CheckBox id="subscriberCheckbox" isActive={false} />
              Subscriber is is a Benefit Plan Investor that is subject to Part 4 of Title I of ERISA.
            </CheckboxWrapper>
            <Divider />
            <div>
              <Label size="md">Is entity a Plan Asset?</Label>
              <RadioButtonsWrapper>
                <Controller
                  name="radioButton"
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <>
                      {['Yes', 'No'].map((option, index) => (
                        <RadioButton
                          key={option + index}
                          name="capacityCalculation"
                          radioButtonText={option}
                          selectedOption={value}
                          handleRadioChange={(event) => onChange(event.target.value)}
                        />
                      ))}
                    </>
                  )}
                />
              </RadioButtonsWrapper>
            </div>
            <div style={{ paddingBottom: '15px' }}>
              <Controller
                name="insuranceCompanyPercentage"
                control={control}
                render={({ field: { value, onChange, onBlur }, fieldState: { error, invalid } }) => (
                  <Input
                    label="Enter Entity ERISA Equity Percentage"
                    value={value}
                    onChange={(e) => {
                      const formattedValue = formatPercentageInputValue(e);
                      onChange(formattedValue);
                    }}
                    onClick={handleCursorPositionInPercentageInput}
                    onKeyUp={handleCursorPositionInPercentageInput}
                    onBlur={onBlur}
                    placeholder="%"
                    size="md"
                    width="205px"
                    errorText={error?.message}
                  />
                )}
              />
            </div>

            <div>
              <Label size="md">
                Is subscriber primarily engaged, directly or through a majority owned subsidiary, in the production or sale of a product or service
                other than the investment of capital?
              </Label>
              <RadioButtonsWrapper>
                <Controller
                  name="radioButton"
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <>
                      {['Yes', 'No'].map((option, index) => (
                        <RadioButton
                          key={option + index}
                          name="capacityCalculation"
                          radioButtonText={option}
                          selectedOption={value}
                          handleRadioChange={(event) => onChange(event.target.value)}
                        />
                      ))}
                    </>
                  )}
                />
              </RadioButtonsWrapper>
            </div>
            <Divider />
            <div>
              <CustomLabel size="md">Insurance Company Percentage</CustomLabel>
              <SubLabel>
                (Subscriber is using the assets of an insurance company general account to purchase an Interest to not exceed the following
                percentage.)
              </SubLabel>
              <Controller
                name="insuranceCompanyPercentage"
                control={control}
                render={({ field: { value, onChange, onBlur }, fieldState: { error, invalid } }) => (
                  <Input
                    value={value}
                    onChange={(e) => {
                      const formattedValue = formatPercentageInputValue(e);
                      onChange(formattedValue);
                    }}
                    onClick={handleCursorPositionInPercentageInput}
                    onKeyUp={handleCursorPositionInPercentageInput}
                    onBlur={onBlur}
                    placeholder="%"
                    size="md"
                    width="205px"
                    errorText={error?.message}
                  />
                )}
              />
            </div>
          </BenefitPlansWrapper>
          <FormFooter onCancel={() => goBack({ fallBack: '/investors' })} disableSaveButton={false} showRequiredFieldsWarning={false} />
        </form>
      </PaddingWrap>
    </MainWrap>
  );
};

const BenefitPlansWrapper = styled.div<{ isMobile: boolean }>`
  margin-top: 50px;
  padding: ${({ isMobile }) => (isMobile ? '20px' : '40px')};
  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.border.base};
  background-color: ${({ theme }) => theme.layer[1]};
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const RadioButtonsWrapper = styled.div`
  display: flex;
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.border.base};
`;

const CustomLabel = styled(Label)`
  padding: 4px 4px 0 4px;
`;

const SubLabel = styled.p`
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;
  color: ${({ theme }) => theme.font.weak};
  padding: 0 4px 4px;
`;

const CheckboxWrapper = styled.div`
  display: flex;
  gap: 9px;
  color: ${({ theme }) => theme.font.base};
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;
  cursor: pointer;
  padding: 8px;
`;
