import { EditManagerUpdate } from '@/components/managerUpdates-page/managerUpdateDetails/editManagerUpdate';
import { Helmet as Head } from 'react-helmet';

const EditManagerUpdatePage = () => {
  return (
    <>
      <Head>
        <title>Edit Manager Update</title>
      </Head>
      <EditManagerUpdate />
    </>
  );
};

export default EditManagerUpdatePage;
