export const firmTableColumns = [
  { title: 'Email', key: 'EMAIL', width: 16, keySort: 'email' },
  { title: 'Last Login', key: 'LAST_LOGIN', width: 16, keySort: 'last login' },
  { title: 'First Name', key: 'FIRST_NAME', width: 18, keySort: 'first name' },
  { title: 'Last Name', key: 'LAST_NAME', width: 18, keySort: 'last name' },
  { title: 'Mobile Number', key: 'MOBILE_NUMBER', width: 16, keySort: 'mobile number' },
  { title: 'Roles', key: 'ROLES', width: 16, keySort: 'roles' }
];

export const tenantsTableColumns = [
  { title: 'Email', key: 'EMAIL', width: 13, keySort: 'email' },
  { title: 'Last Login', key: 'LAST_LOGIN', width: 12, keySort: 'last login' },
  { title: 'First Name', key: 'FIRST_NAME', width: 14, keySort: 'first name' },
  { title: 'Last Name', key: 'LAST_NAME', width: 14, keySort: 'last name' },
  { title: 'Mobile Number', key: 'MOBILE_NUMBER', width: 13, keySort: 'mobile number' },
  { title: 'Roles', key: 'ROLES', width: 13, keySort: 'roles' },
  { title: 'Advisory Firm', key: 'tenant.name', width: 13, keySort: 'advisory firm' },
  { title: '', key: null, width: 8, keySort: '' }
];

export enum ROLE {
  INVESTMENT_LEAD = 'Investment Lead',
  INVESTMENT_MEMBER = 'Investment Member',
  ADVISOR_LEAD = 'Advisor Lead',
  ADVISOR_MEMBER = 'Advisor Member',
  ADVISOR_ASSOCIATE = 'Advisor Associate',
  OPERATIONS_LEAD = 'Operations Lead',
  OPERATIONS_MEMBER = 'Operations Member',
  COMPLIANCE_LEAD = 'Compliance Lead',
  COMPLIANCE_MEMBER = 'Compliance Member',
  ADMIN = 'Admin',
  SUPER_ADMIN = 'Super Admin',
  VIEWER = 'Viewer',
  DEFAULT_ADVISOR = 'Default Advisor'
}
