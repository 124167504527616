import { useMutation } from '@apollo/client';
import { useNavigate, useLocation } from 'react-router-dom';
import { DeleteBucketIcon } from '@/assets/static/icons/delete-bucket';
import ModalWrappedContent from '@/components/basicComponents/modal/modalWrappedContent';
import { ITier } from '@/components/dueDiligence-page/dueDiligenceRecord/types';
import {
  DELETE_DUE_DILIGENCE_TIER,
  GET_FUNNEL_QUERY,
  UPDATE_DRAFT_DUE_DILIGENCE_TIER
} from '@/components/settings-page/queries';
import styled from 'styled-components';

interface DeleteTierProps {
  onClose: () => void;
  isOpen: boolean;
  currentId: string;
  tierName: string;
  tiersData: ITier[];
  openErrorModal: (type: string, title: string, description: string) => void;
  funnelRefetch: () => void;
}

const DeleteTier = ({
  onClose,
  isOpen,
  currentId,
  tierName,
  tiersData,
  openErrorModal,
  funnelRefetch
}: DeleteTierProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const [updateDraftTier] = useMutation(UPDATE_DRAFT_DUE_DILIGENCE_TIER, {
    refetchQueries: () => [
      {
        query: GET_FUNNEL_QUERY,
        variables: {
          id: queryParams.get('funnelId')
        }
      }
    ]
  });
  const [deleteTier] = useMutation(DELETE_DUE_DILIGENCE_TIER);

  const saveValue = () => {
    deleteTier({
      variables: {
        id: currentId
      },
      onCompleted: () => {
        const filteredTiers = tiersData.filter((tier) => tier.id !== currentId);
        if (!filteredTiers.length) {
          funnelRefetch();
          return;
        }
        for (const [index, tier] of filteredTiers.entries()) {
          updateDraftTier({
            variables: {
              data: {
                id: tier.id,
                order: index + 1
              }
            }
          });
        }
      },
      onError: () => {
        openErrorModal(
          'linked-field-error',
          'Cannot Delete This Tier',
          'You cannot delete this tier because there are other fields linked to it. Please unlink the fields before deleting this one.'
        );
      }
    });
    onClose();
  };

  return (
    <ModalWrappedContent
      isOpen={isOpen}
      onClose={onClose}
      confirmButton={{ name: 'Confirm', onClick: saveValue }}
    >
      <ModalContainer>
        <DeleteBucketIcon width={46} height={60} />
        <ModalTitle>
          Please confirm you want to delete{' '}
          <span style={{ fontWeight: 700 }}>{tierName}</span>.
        </ModalTitle>
        <ModalDescription>
          Data will be permanently lost upon deletion. There is no undo for this
          operation.
        </ModalDescription>
      </ModalContainer>
    </ModalWrappedContent>
  );
};

export default DeleteTier;

const ModalContainer = styled.div`
  font-weight: 400;
  padding: 10px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;

const ModalTitle = styled.div`
  font-size: 33px;
  font-weight: 400;
  text-align: center;
  color: #2e2e2e;
`;

const ModalDescription = styled.div`
  font-size: 16px;
  line-height: 27px;
  text-align: center;
  color: #373f4e;
  font-weight: 400;
`;
