import { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import { ExportDataIcon } from '@/assets/icons/exportDataIcon';
import { FilterIcon } from '@/assets/icons/filter';
import { FilterSettingIcon } from '@/assets/icons/filterSetting';
import FilterSearch from '../../basicComponents/input';
import FilterSelect from '../../basicComponents/select';
import SelectWithCheckBox, { TData } from '../../basicComponents/selectWithCheckBox';
import ChosenFilters from './chosenFilters';
import { FilterSettingWrap } from './desktop-filter';
import MoreFilters from './moreFilters';
import { AdditionalFilters } from './types';

interface FilterPageProps {
  search: {
    value: string;
    onChange: any;
    placeholder: string;
  };
  primaryFilter?: {
    value: string;
    onChange: (value: string) => void;
    selects: string[];
  };
  withCheckBox?: boolean;
  additionalFilters?: AdditionalFilters;
  withExportData?: boolean;
  setAllocationStatusFilter?: any;
  allocationStatusFilter?: TData[];
  currentTab?: { value: string };
  className?: string;
  isResult?: boolean;
  handleDownload: () => void;
  tableColumnControl?: () => void;
}

const LandscapeFilterPage = ({
  search,
  primaryFilter,
  additionalFilters,
  withExportData = false,
  withCheckBox,
  setAllocationStatusFilter,
  allocationStatusFilter,
  currentTab,
  className,
  isResult,
  handleDownload,
  tableColumnControl
}: FilterPageProps) => {
  const [moreFilter, setMoreFilter] = useState(false);
  const [chosenFilters, setChosenFilters] = useState(!!additionalFilters?.showChosenFilters);
  const [checkBoxData, setCheckBoxData] = useState<TData[]>(allocationStatusFilter ?? []);

  useEffect(() => {
    if (additionalFilters?.filters[0].typeFilter === 'Select A Filter Option' && currentTab) {
      setChosenFilters(false);
    }
  }, [currentTab?.value, additionalFilters]);

  useEffect(() => {
    if (additionalFilters?.filters[0].typeFilter !== 'Select A Filter Option') {
      setChosenFilters(true);
    }
  }, []);

  const checkBoxSelectLabel = useMemo(() => {
    const choosenNames = checkBoxData.filter((el) => el.value);
    let label = '';
    if (choosenNames.length >= 1 && choosenNames.length <= 2) {
      choosenNames.map((el, index) => {
        if (index === 0) {
          label = el.name;
        } else {
          label = label + ', ' + el.name;
        }
      });
      return label;
    } else {
      return choosenNames.length === 0 ? 'Filter by' : choosenNames.length + ' ' + 'Selected';
    }
  }, [checkBoxData]);

  const handleCheckBoxData = (id: number) => {
    const newData = [...checkBoxData];
    newData[id].value = !newData[id].value;
    setCheckBoxData(newData);
  };

  return (
    <>
      <DefaultSearchWrap>
        {/* {isResult ? (*/}
        {/*  <TopWrapper>*/}
        {/*    <Results>{additionalFilters?.amountResults} Results</Results>*/}
        {/*    <RefreshButton onClick={() => additionalFilters?.onRefetch()}>*/}
        {/*      <RefreshIcon />*/}
        {/*    </RefreshButton>*/}
        {/*  </TopWrapper>*/}
        {/* ) : null}*/}
        <MainSearchWrap>
          {withExportData && (
            <ExportDataWrap onClick={handleDownload}>
              <ExportDataIcon />
              <ExportDataTitle>Export Data</ExportDataTitle>
            </ExportDataWrap>
          )}
          <FilterSearch
            value={search.value}
            onChange={search.onChange}
            placeholder={search.placeholder}
            width={(currentTab?.value === 'pending' && withCheckBox) || primaryFilter ? '48%' : additionalFilters ? '96%' : '100%'}
          />
          {additionalFilters && (
            <FilterWrap onClick={() => setMoreFilter(!moreFilter)} moreFilter={moreFilter}>
              <FilterIcon fill={moreFilter ? '#FFF' : '#4587EC'} />
            </FilterWrap>
          )}
          {tableColumnControl && (
            <FilterSettingWrap onClick={tableColumnControl}>
              <FilterSettingIcon />
            </FilterSettingWrap>
          )}
          {primaryFilter && (
            <FilterSelect width={'48%'} data={primaryFilter.selects} selected={primaryFilter.value} setSelected={primaryFilter.onChange} />
          )}
          {currentTab?.value === 'pending' && withCheckBox && (
            <SelectWithCheckBox
              width={'48%'}
              data={checkBoxData}
              setSelected={handleCheckBoxData}
              label={checkBoxSelectLabel}
              setAllocationStatusFilter={setAllocationStatusFilter}
              setCheckBoxData={setCheckBoxData}
            />
          )}
        </MainSearchWrap>
      </DefaultSearchWrap>
      {additionalFilters && moreFilter && (
        <MoreFilters onClose={() => setMoreFilter(false)} setChosenFilters={setChosenFilters} {...additionalFilters} />
      )}
      {additionalFilters && chosenFilters && <ChosenFilters setChosenFilters={setChosenFilters} {...additionalFilters} />}
    </>
  );
};

const ExportDataTitle = styled.div`
  font-family: Blinker, serif;
  font-size: 13px;
  font-weight: 600;
  line-height: 18px;
  text-align: left;
  color: #4587ec;
`;

const ExportDataWrap = styled.div`
  display: flex;
  cursor: pointer;
  gap: 6px;
  justify-content: center;
  align-items: center;
`;

const FilterWrap = styled.div<{ moreFilter: boolean }>`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ moreFilter }) => (moreFilter ? '#3AB070' : '#fafafa')};
  border: ${({ moreFilter }) => `1px solid ${moreFilter ? '#3AB070' : '#4587ec'}`};
  border-radius: 3px;
  padding: 10px;
`;

const DefaultSearchWrap = styled.div`
  //width: 100%;
  //display: flex;
  //align-items: flex-end;
  //justify-content: flex-end;
  //gap: 22px;
`;

const MainSearchWrap = styled.div`
  display: flex;
  justify-content: space-between;
  border-radius: 10px;
  background: #fafafa;
  padding: 8px 20px;
  gap: 10px;
`;

const TopWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const Results = styled.h1`
  font-family: 'Blinker';
  font-weight: 600;
  font-size: 16px;
  color: #373f4e;
`;

const RefreshButton = styled.div`
  padding-left: 5px;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export default LandscapeFilterPage;
