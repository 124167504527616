import * as React from 'react';

export const OpenFileIcon = ({ ...props }: React.SVGProps<SVGSVGElement>) => (
  <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.79572 0H2.5968C2.12078 0.00142951 1.66474 0.191542 1.32864 0.528649C0.992547 0.865755 0.803831 1.32236 0.803833 1.79839V16.2016C0.803831 16.6776 0.992547 17.1342 1.32864 17.4714C1.66474 17.8085 2.12078 17.9986 2.5968 18H13.3979C13.8744 17.9986 14.331 17.8086 14.668 17.4717C15.0049 17.1347 15.1948 16.6781 15.1963 16.2016V5.40054L9.79572 0ZM2.5968 1.79839H8.89383L13.3979 6.29704V14.0198L11.7399 12.3672C12.3147 11.5002 12.5712 10.4608 12.4658 9.42592C12.3605 8.39104 11.8998 7.42467 11.1621 6.69127C10.7476 6.27404 10.2545 5.9432 9.71123 5.71793C9.16798 5.49266 8.58543 5.37744 7.99734 5.37895C7.29651 5.37717 6.60502 5.53957 5.97823 5.85311C5.35145 6.16665 4.80681 6.62261 4.38794 7.18449C3.96906 7.74637 3.6876 8.39853 3.5661 9.08875C3.44461 9.77897 3.48645 10.4881 3.68828 11.1592C3.89011 11.8303 4.24631 12.4449 4.72835 12.9536C5.2104 13.4623 5.80489 13.8511 6.4642 14.0887C7.1235 14.3264 7.82931 14.4063 8.52506 14.3221C9.22082 14.238 9.88719 13.992 10.4708 13.604L13.036 16.18H2.5968V1.79839ZM9.89293 11.7894C9.3904 12.2935 8.70914 12.5788 7.99734 12.5832C7.6434 12.5854 7.29267 12.5162 6.96606 12.3798C6.63946 12.2434 6.34367 12.0426 6.09635 11.7894C5.59091 11.2855 5.30549 10.6021 5.30247 9.88838C5.29797 9.44206 5.40418 9.00158 5.61159 8.60635C5.81901 8.21112 6.12115 7.87347 6.49101 7.62361C6.86087 7.37375 7.2869 7.21946 7.73099 7.17457C8.17507 7.12967 8.62336 7.19556 9.03575 7.36634C9.44813 7.53712 9.81176 7.80743 10.0941 8.15315C10.3764 8.49887 10.5686 8.90918 10.6536 9.34737C10.7385 9.78557 10.7134 10.238 10.5807 10.6641C10.448 11.0903 10.2117 11.4769 9.89293 11.7894Z"
      fill={props.fill || '#4587EC'}
    />
  </svg>
);
