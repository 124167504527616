import { IInitialValuesFromTable, IPagination, ISort } from './types';

export const initialPagination = {
  limit: 50,
  total: 0,
  offset: 0,
} as IPagination;
export const initialSort = {
  key: 'name',
  asc: true,
} as ISort;

export const initialValuesFromTable = {
  initialAmountRowsPerPage: [50, 100, 150],
  initialSortColumn: 'name',
} as IInitialValuesFromTable;

export const SIZE_DROPDOWN_ACTIONS = 40; // px
