import { useMemo } from 'react';

import BasicTooltip from '@/components/basicComponents/tooltip';
import FormattingTooltip from '@/components/basicComponents/tooltip/dollarsTooltip';
import styled from 'styled-components';
import {
  overCommitmentRatioModeColumns,
  overCommitmentRatioModeSimplifiedColumns,
  pacingModelModeColumns,
  pacingModelModeSimplifiedColumns
} from '../constatnts';
import { AssetClassTargetAllocationDto } from '../types';

interface TableSumFooterProps {
  investorSummaryRows: AssetClassTargetAllocationDto[];
  ignoreAllocationTargets: boolean;
  commitmentPlansTotal: number | null;
  targetOCRatio: number | null;
}

interface ISum {
  total: string;
  target: number;
  targetAllocations: number;
  exposure: number;
  capacity: number;
  currentNAV: number;
  unfundedCommitments: number;
}

export const calculateCapacity = (row: AssetClassTargetAllocationDto, commitmentPlansTotal: number | null, targetOCRatio: number | null) => {
  if (commitmentPlansTotal !== null && targetOCRatio === null) {
    if (commitmentPlansTotal > 0) {
      return row.illiquid ? row.pacingTarget?.remainingPacingTarget || 0 : row.capacity;
    } else if (commitmentPlansTotal === 0) {
      return !row.illiquid ? row.capacity : 0;
    }
  }
  return row.capacity;
};

const TableSumFooter = ({ investorSummaryRows, ignoreAllocationTargets, commitmentPlansTotal, targetOCRatio }: TableSumFooterProps) => {
  const rowSum: ISum = useMemo(() => {
    const sum = {
      total: 'Total',
      target: 0,
      targetAllocations: 0,
      exposure: 0,
      capacity: 0,
      currentNAV: 0,
      unfundedCommitments: 0
    };

    investorSummaryRows.forEach((row) => {
      sum.target += row.percentage;
      sum.targetAllocations += row.targetAllocation;
      sum.exposure += row.economicExposure;
      sum.currentNAV += row.currentNAV || 0;
      sum.unfundedCommitments += row.unfundedCommitments || 0;
      sum.capacity += calculateCapacity(row, commitmentPlansTotal, targetOCRatio);
    });

    return sum;
  }, [investorSummaryRows, commitmentPlansTotal, targetOCRatio]);

  if (ignoreAllocationTargets && targetOCRatio !== null) {
    return (
      <TableSumWrap>
        <TableSumItem width={overCommitmentRatioModeSimplifiedColumns[0]?.width}>{rowSum.total}</TableSumItem>
        <TableSumItem width={overCommitmentRatioModeSimplifiedColumns[1]?.width}>
          <FormattingTooltip>{rowSum.exposure}</FormattingTooltip>
        </TableSumItem>
      </TableSumWrap>
    );
  }

  if (ignoreAllocationTargets) {
    return (
      <TableSumWrap>
        <TableSumItem width={pacingModelModeSimplifiedColumns[0]?.width}>{rowSum.total}</TableSumItem>
        <TableSumItem width={pacingModelModeSimplifiedColumns[1]?.width}>
          <FormattingTooltip>{rowSum.currentNAV}</FormattingTooltip>
        </TableSumItem>
        <TableSumItem width={pacingModelModeSimplifiedColumns[2]?.width}>
          <FormattingTooltip>{rowSum.unfundedCommitments}</FormattingTooltip>
        </TableSumItem>
      </TableSumWrap>
    );
  }

  if (targetOCRatio === null) {
    return (
      <TableSumWrap>
        <TableSumItem width={pacingModelModeColumns[0].width}>{rowSum.total}</TableSumItem>
        <TableSumItem width={pacingModelModeColumns[1].width}>
          <BasicTooltip tooltipContent={`${rowSum.target.toFixed(2)}%`} zIndex={1000}>
            {rowSum.target.toFixed(0)}%
          </BasicTooltip>
        </TableSumItem>
        <TableSumItem width={pacingModelModeColumns[2].width}>
          <FormattingTooltip>{rowSum.targetAllocations}</FormattingTooltip>
        </TableSumItem>
        <TableSumItem width={pacingModelModeColumns[3].width}>
          <FormattingTooltip>{rowSum.currentNAV}</FormattingTooltip>
        </TableSumItem>
        <TableSumItem width={pacingModelModeColumns[4].width}>
          <FormattingTooltip>{rowSum.unfundedCommitments}</FormattingTooltip>
        </TableSumItem>
        <TableSumItem width={pacingModelModeColumns[5].width}>
          <FormattingTooltip>{rowSum.capacity}</FormattingTooltip>
        </TableSumItem>
      </TableSumWrap>
    );
  }

  return (
    <TableSumWrap>
      <TableSumItem width={overCommitmentRatioModeColumns[0].width}>{rowSum.total}</TableSumItem>
      <TableSumItem width={overCommitmentRatioModeColumns[1].width}>
        <BasicTooltip tooltipContent={`${rowSum.target.toFixed(2)}%`} zIndex={1000}>
          {rowSum.target.toFixed(0)}%
        </BasicTooltip>
      </TableSumItem>
      <TableSumItem width={overCommitmentRatioModeColumns[2].width}>
        <FormattingTooltip>{rowSum.targetAllocations}</FormattingTooltip>
      </TableSumItem>
      <TableSumItem width={overCommitmentRatioModeColumns[3].width}>
        <FormattingTooltip>{rowSum.exposure}</FormattingTooltip>
      </TableSumItem>
      <TableSumItem width={overCommitmentRatioModeColumns[4].width}>
        <FormattingTooltip>{rowSum.capacity}</FormattingTooltip>
      </TableSumItem>
    </TableSumWrap>
  );
};

const TableSumWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
  width: 100%;
  min-height: 60px;
  background-color: ${({ theme }) => theme.layer[2]};
  border-radius: 8px;
  padding: 0 24px;
`;

const TableSumItem = styled.div<{ width: number }>`
  display: flex;
  justify-content: flex-start;
  width: ${({ width }) => `${width}%`};
  color: ${({ theme }) => theme.font.base};
  font-family: Blinker, serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0;
`;

export default TableSumFooter;
