import { useQuery } from '@apollo/client';
import { ChangeEvent, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';

import { ArrowDown } from '@/assets/icons/info-icons/arrowDown';
import { useGoBack } from '@/hooks/useGoBack';
import { useResponsive } from '../../hooks/use-responsive';
import { useClickOutside } from '../../hooks/useClickOutside';
import { MainWrap, PaddingWrap } from '../../styles/common';
import guid from '../../utils/guid';
import { ITab, MainTabsMobile, TabsDetailsTitleMobile, TabsWrapMobile } from '../basicComponents/fat-tabs';
import FilterSelect, { Divider, FilterItems, PrimaryFilterItem, SearchInput, SearchWrap } from '../basicComponents/select';
import { EntityListFilterType } from '../client-details';
import { MobileNotes } from '../client-details/mobileNotes';
import { CLIENT_DETAILS_QUERY_BASIC } from '../client-details/queries';
import { IClientBasicDetails, INotesData } from '../client-details/types';
import SpecialControlHeader from '../fat-dealCatalog-page/fat-switcher/specialControlHeader';
import Header from '../fat-header';
import { AdditionalOptionWrap, GoBackButton, GoBackButtonWrap } from '../fat-header/goBackButton';
import { PageTitle } from '../fat-header/pageTitle';
import MobileModal from '../mobileModal';
import FilterPage from '../table/fat-filterPage';
import { DATA_TYPE } from '../table/filterPage';
import { ISort, SORT_ORDER } from '../table/types';
import { allocationsSort, commitmentsSort } from './constants';
import Details from './details';
import Notes from './notes';
import { ClientDetailsCommitments, ClientDetailsSummary, ClientsDetailsAllocations } from './queries';
import TableAllocations from './tableAllocations';
import TableCommitments from './tableCommitments';
import { MobileInfoList } from './tableCommitments/mobileInfoList';
import TableSummary from './tableSummary';
import { ClientEntityDetailsAllocations, ClientEntityDetailsCommitments, ClientEntityDetailsSummary, CommitmentsType, TabsEnum } from './types';

const tabs: ITab[] = [
  { value: TabsEnum.summary, amount: null },
  { value: TabsEnum.commitments, amount: null },
  { value: TabsEnum.allocations, amount: null }
];

const ClientEntityDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const goBack = useGoBack();
  const theme = useTheme();
  const { isMobile, isTablet } = useResponsive();

  const [searchParams, setSearchParams] = useSearchParams();

  const currentTab = (searchParams.get('tab') || TabsEnum.summary) as TabsEnum;
  const key = searchParams.get('key') || getSortKey();
  const asc = searchParams.get('asc') === null || searchParams.get('asc') === 'true';
  const switcher = searchParams.get('switcher') === null || searchParams.get('switcher') === 'true';
  const backToTitle = searchParams.get('backToTitle') || '';

  const ref = useRef<HTMLDivElement | null>(null);
  const [isNotesOpen, setIsNotesOpen] = useState(false);
  const [isShowDetails, setIsShowDetails] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [entityList, setEntityList] = useState([]);
  const [list, setList] = useState([]);
  const [selected, setSelected] = useState('');
  const [isTabMenuOpen, setIsTabMenuOpen] = useState(false);
  const [mobileSort, setMobileSort] = useState(commitmentsSort[0].title);
  const [mobileSortList, setMobileSortList] = useState<string[]>([]);
  const [searchInvestment, setSearchInvestment] = useState<string>('');
  const [title, setTitle] = useState('');
  const [search, setSearch] = useState('');

  const [isModalDetailsOpen, setIsModalDetailsOpen] = useState(false);
  const [modalDetailsData, setModalDetailsData] = useState<CommitmentsType>();

  const [isModalNotesOpen, setIsModalNotesOpen] = useState(false);
  const [modalNotesData, setModalNotesData] = useState<INotesData[]>();

  useClickOutside(ref, () => setIsOpen(false));

  function getSortKey() {
    switch (currentTab) {
      case TabsEnum.summary:
        return 'name';
      case TabsEnum.commitments:
        return 'assetClass';
      case TabsEnum.allocations:
        return 'name';
      default:
        return '';
    }
  }

  const handleInvestment = () => {
    navigate(`/investments/${modalDetailsData?.moreInfo.managerId}/${encodeURIComponent(modalDetailsData?.investmentId)}`);
  };

  const onNotesClick = (data: INotesData[] | undefined) => {
    setModalNotesData(data);
    setIsModalNotesOpen(true);
  };

  const onRowClick = (data: CommitmentsType) => {
    setModalDetailsData(data);
    setIsModalDetailsOpen(true);
  };

  const onItemClick = (id: string) => {
    setSelected(id);

    navigate(`/clients/${params?.clientId}/${id}`, { replace: true });
  };

  const { data: clientData } = useQuery<IClientBasicDetails>(CLIENT_DETAILS_QUERY_BASIC, {
    notifyOnNetworkStatusChange: true,
    variables: {
      id: params?.clientId
    },
    onCompleted: (data) => {
      const rowsWithId = data.ClientEntity.clientEntities.map((el) => {
        if (el.entities !== null && el.entities.length > 0) {
          const newGroupedEntities = el.entities.map((el) => {
            return {
              guid: guid(),
              id: el.id,
              entityName: el.entityName
            };
          });
          return {
            guid: guid(),
            id: el.id,
            entityName: el.entityName,
            entities: newGroupedEntities
          };
        }
        return {
          guid: guid(),
          id: el.id,
          entityName: el.entityName,
          entities: el.entities
        };
      });
      const entityListFilter: EntityListFilterType[] = [];
      rowsWithId.forEach((el) => {
        if (el.entities?.length) {
          entityListFilter.push({
            id: el.id,
            name: el.entityName,
            grouped: false
          });
          el.entities.forEach((el) => {
            entityListFilter.push({
              id: el.id,
              name: el.entityName,
              grouped: true
            });
          });
        } else {
          entityListFilter.push({
            id: el.id,
            name: el.entityName,
            grouped: false
          });
        }
      });
      setEntityList(entityListFilter);
      setList(entityListFilter);
    }
  });

  const {
    data: summaryData,
    loading: summaryLoading,
    refetch: summaryRefetch
  } = useQuery<ClientEntityDetailsSummary>(ClientDetailsSummary, {
    notifyOnNetworkStatusChange: true,
    skip: currentTab !== TabsEnum.summary,
    variables: {
      includeRecommendation: switcher,
      id: params?.entityId,
      familyId: params?.clientId
    },
    onCompleted: (data) => {
      if (title) return;
      setTitle(data.ClientEntityDetailsSummary?.name);
    }
  });

  const {
    data: commitmentsData,
    loading: commitmentsLoading,
    refetch: commitmentsRefetch
  } = useQuery<ClientEntityDetailsCommitments>(ClientDetailsCommitments, {
    notifyOnNetworkStatusChange: true,
    skip: currentTab !== TabsEnum.commitments,
    variables: {
      includeRecommendation: switcher,
      id: params?.entityId,
      familyId: params?.clientId,
      sort: 'assetClass',
      order: SORT_ORDER.ASC,
      search: searchInvestment
    },
    onCompleted: (data) => {
      if (title) return;
      setTitle(data.ClientEntityDetailsCommitments?.name);
    }
  });

  const {
    data: allocationsData,
    loading: allocationsLoading,
    refetch: allocationsRefetch
  } = useQuery<ClientEntityDetailsAllocations>(ClientsDetailsAllocations, {
    notifyOnNetworkStatusChange: true,
    skip: currentTab !== TabsEnum.allocations,
    variables: {
      includeRecommendation: switcher,
      id: params?.entityId,
      familyId: params?.clientId,
      order: SORT_ORDER.ASC,
      sort: 'name'
    },
    onCompleted: (data) => {
      if (title) return;
      setTitle(data.ClientEntityDetailsAllocations?.name);
    }
  });

  useEffect(() => {
    if (currentTab === TabsEnum.summary) {
      summaryRefetch();
      return;
    }
    if (isMobile) {
      if (currentTab === TabsEnum.commitments) {
        setMobileSortList(commitmentsSort.map((item) => item.title));
        const sortItem = commitmentsSort.find((item) => item.title === mobileSort);
        commitmentsRefetch({
          order: sortItem?.asc ? SORT_ORDER.ASC : SORT_ORDER.DESC,
          sort: sortItem?.key
        });
      } else if (currentTab === TabsEnum.allocations) {
        setMobileSortList(allocationsSort.map((item) => item.title));
        const sortItem = allocationsSort.find((item) => item.title === mobileSort);
        allocationsRefetch({
          order: sortItem?.asc ? SORT_ORDER.ASC : SORT_ORDER.DESC,
          sort: sortItem?.key
        });
      }
    }
  }, [currentTab, mobileSort, isMobile]);

  const detailsData = useMemo(() => {
    if (currentTab === TabsEnum.commitments) {
      return commitmentsData?.ClientEntityDetailsCommitments;
    } else if (currentTab === TabsEnum.allocations) {
      return allocationsData?.ClientEntityDetailsAllocations;
    }
    return summaryData?.ClientEntityDetailsSummary;
  }, [currentTab, summaryData, commitmentsData, allocationsData]);

  const notesData = useMemo(() => {
    if (currentTab === TabsEnum.summary) {
      return summaryData?.ClientEntityDetailsSummary.notes;
    } else if (currentTab === TabsEnum.commitments) {
      return commitmentsData?.ClientEntityDetailsCommitments.notes;
    } else if (currentTab === TabsEnum.allocations) {
      return allocationsData?.ClientEntityDetailsAllocations.notes;
    }
  }, [summaryData, commitmentsData, allocationsData]);

  const clientsSummaryRows = summaryData
    ? [
        {
          asset: 'Private Equity',
          target: summaryData.ClientEntityDetailsSummary.summary.privateEquityPercentage,
          targetAllocations: summaryData.ClientEntityDetailsSummary.summary.privateEquityTargetAllocations,
          exposure: summaryData.ClientEntityDetailsSummary.summary.economicExposureForPrivateEquity,
          capacity: summaryData.ClientEntityDetailsSummary.summary.privateEquityCapacity
        },
        {
          asset: 'Private Credit',
          target: summaryData.ClientEntityDetailsSummary.summary.privateCreditPercentage,
          targetAllocations: summaryData.ClientEntityDetailsSummary.summary.privateCreditTargetAllocations,
          exposure: summaryData.ClientEntityDetailsSummary.summary.economicExposureForPrivateCredit,
          capacity: summaryData.ClientEntityDetailsSummary.summary.privateCreditCapacity
        },
        {
          asset: 'Private Real Assets',
          target: summaryData.ClientEntityDetailsSummary.summary.privateRealAssetPercentage,
          targetAllocations: summaryData.ClientEntityDetailsSummary.summary.privateRealAssetTargetAllocations,
          exposure: summaryData.ClientEntityDetailsSummary.summary.economicExposureForPrivateRealAssets,
          capacity: summaryData.ClientEntityDetailsSummary.summary.privateRealAssetsCapacity
        }
      ]
    : undefined;

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (entityList) {
      const value = event.target.value;
      setSearch(value);
      const newList = [...entityList];
      const filteredList = newList.filter((item) => item.name.toLowerCase().indexOf(event.target.value.toLowerCase()) !== -1);
      if (value === '' && filteredList.length === 0) {
        setList(entityList);
      } else {
        setList(filteredList);
      }
    }
  };

  const handleSelectCLick = (event: any) => {
    if (event.target.id !== 'input') {
      setIsOpen(!isOpen);
    }
  };

  const toggleNotes = () => {
    setIsNotesOpen(!isNotesOpen);
    const html = document.querySelector('html');
    if (html) {
      html.style.overflowY = isNotesOpen ? 'visible' : 'hidden';
    }
  };
  const clickOutside = (event: any) => {
    if (event.target.id !== 'notes' && event.target.id !== 'openNotes' && isNotesOpen) {
      toggleNotes();
    }
  };

  const onClientNameClick = () => {
    navigate(`/clients/${params?.clientId}`, { replace: true });
  };

  const onChangeActiveTab = (value: TabsEnum) => {
    setSearchParams({ tab: value }, { replace: true });
    setIsTabMenuOpen(!isTabMenuOpen);
  };

  const onChangeSort = (value: ISort) => {
    setSearchParams(
      (prev) => {
        prev.set('key', value.key);
        prev.set('asc', value.asc.toString());
        return prev;
      },
      { replace: true }
    );
  };

  const toggleSwitcher = () => {
    setSearchParams(
      (prev) => {
        prev.set('switcher', Boolean(!switcher).toString());
        return prev;
      },
      { replace: true }
    );
    saveScrollPostion();
  };

  const saveScrollPostion = () => {
    setSearchParams(
      (prev) => {
        prev.set('position', window.scrollY.toString());
        return prev;
      },
      { replace: true }
    );
  };

  const makeScroll = (position: string | number) => {
    if (!position) return;
    setTimeout(() => window.scrollTo({ top: Number(position), behavior: 'smooth' }), 0);
    setSearchParams(
      (prev) => {
        prev.delete('position');
        return prev;
      },
      { replace: true }
    );
  };

  const changeTab = (value: TabsEnum) => {
    onChangeActiveTab(value);
    setIsTabMenuOpen(false);
  };

  return (
    <ClientDetailsWrap onClick={clickOutside}>
      {isModalDetailsOpen && modalDetailsData ? (
        <MobileModal onClose={() => setIsModalDetailsOpen(false)} title={modalDetailsData.name} onClick={handleInvestment}>
          <MobileInfoList onClick={handleInvestment} data={modalDetailsData} />
        </MobileModal>
      ) : (
        <></>
      )}

      {isModalNotesOpen && modalNotesData ? (
        <MobileModal onClose={() => setIsModalNotesOpen(false)} title="Notes" onClick={() => null}>
          <MobileNotes data={modalNotesData} />
        </MobileModal>
      ) : (
        <></>
      )}
      <MainWrap>
        <Header
          specialControl={<SpecialControlHeader switcher={switcher} setSwitcher={toggleSwitcher} title="Include Pending Commitments" />}
          modalControl={
            <GoBackButtonWrap>
              <GoBackButton handleClose={() => goBack({ fallBack: '/investors' })} backToTitle={backToTitle} withAdditionalOption />
              {entityList?.length ? (
                <AdditionalOptionWrap ref={ref} onClick={(event) => handleSelectCLick(event)}>
                  <ArrowDown fill={theme.font.action} />
                  <SelectWrap isMobile={isMobile} openZIndex={10} onClick={(event) => handleSelectCLick(event)} width={'200px'} isOpen={isOpen}>
                    <SearchWrap id="input">
                      <SearchInput id="input" placeholder={'Type Here To Filter Results'} value={search} onChange={onChange} />
                      <Divider />
                    </SearchWrap>
                    <>
                      <SelectItem key={guid()} onClick={onClientNameClick}>
                        {clientData?.ClientEntity.name ?? ''}
                      </SelectItem>
                    </>
                    {list?.map((el) => (
                      <SelectItem isActive={el.id === selected} key={guid()} onClick={() => onItemClick(el.id)}>
                        {el.grouped ? '-- ' + el.name : '- ' + el.name}
                      </SelectItem>
                    ))}
                  </SelectWrap>
                </AdditionalOptionWrap>
              ) : (
                <></>
              )}
            </GoBackButtonWrap>
          }
        />
        <PageTitle title={title} />
        <Notes data={notesData} onClick={toggleNotes} isOpen={isNotesOpen} />
        <Details
          tabs={tabs}
          onNotesClick={onNotesClick}
          currentTab={currentTab}
          setCurrentTab={onChangeActiveTab}
          data={detailsData}
          isOpen={isShowDetails}
          setOpen={() => setIsShowDetails(!isShowDetails)}
          setIsNotesOpen={toggleNotes}
          isTabMenuOpen={isTabMenuOpen}
          setIsTabMenuOpen={setIsTabMenuOpen}
          isGrouped={title?.endsWith('Group')}
        />
      </MainWrap>

      {isTabMenuOpen && isMobile ? (
        <MainTabsMobile>
          {tabs.map((tab, tabId) => (
            <TabsWrapMobile key={tab.value + tabId} onClick={() => changeTab(tab.value as TabsEnum)} isActive={currentTab === tab.value}>
              <TabsDetailsTitleMobile isActive={currentTab === tab.value}>{tab.value}</TabsDetailsTitleMobile>
            </TabsWrapMobile>
          ))}
        </MainTabsMobile>
      ) : (
        <MainWrap>
          <PaddingWrap>
            {currentTab === TabsEnum.summary && <TableSummary loading={summaryLoading} clientsRows={clientsSummaryRows} refetch={summaryRefetch} />}
            {currentTab === TabsEnum.commitments && (
              <>
                <CustomFilterPage
                  isMobile={isMobile}
                  isTablet={isTablet}
                  withExportData
                  search={{
                    value: searchInvestment,
                    onChange: setSearchInvestment,
                    placeholder: 'Search investment'
                  }}
                  dataType={DATA_TYPE.COMMITMENTS}
                  queryParams={{
                    id: params?.entityId,
                    familyId: params?.clientId,
                    includeRecommendation: switcher
                  }}
                  resultsValue={commitmentsData?.ClientEntityDetailsCommitments?.commitments?.length || 0}
                  refetch={commitmentsRefetch}
                />
                {isMobile && (
                  <FilterSelect
                    isMobile
                    label={'Sort By'}
                    paddingContainer={'4px 20px 0 20px'}
                    width={'100%'}
                    data={mobileSortList}
                    selected={mobileSort}
                    setSelected={setMobileSort}
                  />
                )}
                <TableCommitments
                  onRowClick={onRowClick}
                  clientsRows={commitmentsData?.ClientEntityDetailsCommitments.commitments}
                  loading={commitmentsLoading}
                  refetch={commitmentsRefetch}
                  savedSort={{ key, asc }}
                  savedSetSort={onChangeSort}
                />
              </>
            )}
            {currentTab === TabsEnum.allocations && (
              <>
                {isMobile && (
                  <FilterSelect
                    isMobile
                    label={'Sort By'}
                    paddingContainer={'4px 20px 0 20px'}
                    width={'100%'}
                    data={mobileSortList}
                    selected={mobileSort}
                    setSelected={setMobileSort}
                  />
                )}
                <TableAllocations
                  paginationValues={{
                    limit: 10,
                    offset: 9,
                    total: 10
                  }}
                  clientsRows={allocationsData?.ClientEntityDetailsAllocations.allocations}
                  refetch={allocationsRefetch}
                  loading={allocationsLoading}
                  savedSort={{ key, asc }}
                  savedSetSort={onChangeSort}
                />
              </>
            )}
          </PaddingWrap>
        </MainWrap>
      )}

      {/* <ExportDataWrap onClick={() => null}>*/}
      {/* <ExportDataIcon />*/}
      {/* <ExportDataTitle>Export Data</ExportDataTitle>*/}
      {/* </ExportDataWrap>*/}
    </ClientDetailsWrap>
  );
};

// const ExportDataTitle = styled.div`
//   font-family: Blinker, serif;
//   font-size: 13px;
//   font-weight: 600;
//   line-height: 18px;
//   text-align: left;
//   color: #4587ec;
// `;
//
// const ExportDataWrap = styled.div`
//   margin-top: 35px;
//   margin-bottom: 25px;
//   display: flex;
//   width: 100%;
//   cursor: pointer;
//   gap: 6px;
//   justify-content: flex-end;
//   align-items: center;
// `;

const ClientDetailsWrap = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 300;
  overflow: visible;
  overflow-x: clip;
`;

const SelectWrap = styled(FilterItems)<{ isMobile: boolean; isOpen: boolean; width: string; openZIndex: number }>`
  width: 245px;
  top: 65px;
  left: ${({ isMobile }) => (isMobile ? '40px' : '60px')};
  overflow-x: hidden;
`;

const SelectItem = styled(PrimaryFilterItem)`
  padding: 11px 9.5px;
  font-size: 16px;
  font-weight: 600;
  color: ${({ theme }) => theme.action.primary};
`;

const CustomFilterPage = styled(FilterPage)<{ isMobile: boolean; isTablet: boolean }>`
  position: sticky;
  top: ${({ isMobile, isTablet }) => (isMobile || isTablet ? '50px' : '0')};
  z-index: 9;
  margin-top: 0;
  margin-left: -1px;
  width: calc(100% + 2px);
  padding: ${({ isMobile }) => (isMobile ? '20px 0 0 0' : '20px 0 10px 0')};
`;

export default ClientEntityDetails;
