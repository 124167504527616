import React from 'react';
import styled from 'styled-components';

type SwitcherProps = {
  isActive: boolean;
  onClick: () => void;
};

const Switcher = ({ isActive, onClick }: SwitcherProps) => {
  return (
    <SwitcherWrap onClick={onClick} isActive={isActive}>
      <Circle isActive={isActive} />
    </SwitcherWrap>
  );
};

const SwitcherWrap = styled.div<{ isActive: boolean }>`
  display: flex;
  cursor: pointer;
  min-width: 44px;
  width: 44px;
  height: 24px;
  background: ${({ isActive }) => (isActive ? '#3AB070' : '#D9D9D9')};
  border-radius: 14px;
  padding: 2px;
  transition: all 0.3s ease-in-out;
`;

const Circle = styled.div<{ isActive: boolean }>`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #fff;
  transform: ${({ isActive }) => (isActive ? 'translate(20px)' : 'translate(0px)')};
  transition: all 0.3s ease-in-out;
`;

export default Switcher;
