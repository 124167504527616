import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';

interface PortalProps {
  children: React.ReactNode;
  id?: string;
}

const Portal = ({ children, id = 'portal' }: PortalProps) => {
  const [element, setElement] = useState<HTMLDivElement | null>(null);

  useEffect(() => {
    const body = document.body;
    const div = document.createElement('div');
    div.setAttribute('id', id);
    body.appendChild(div);
    body.style.overflow = 'hidden';
    setElement(div);
    return () => {
      body.style.overflow = 'visible';
      body.removeChild(div);
    };
  }, []);

  if (element === null) return null;

  return ReactDOM.createPortal(children, element);
};

export default Portal;
