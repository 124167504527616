import { ApprovedIcon } from '@/assets/icons/info-icons/approved';
import styled, { useTheme } from 'styled-components';
import { allocationsSteps } from './importAllocations/constants';

interface ProgressBarProps {
  importSteps: typeof allocationsSteps;
}

export const ProgressBar = ({ importSteps }: ProgressBarProps) => {
  const theme = useTheme();
  return (
    <ImportStatusWrapper>
      {importSteps.map((step) => (
        <StepItemWrapper key={step.name}>
          {step.status === 'COMPLETED' ? (
            <ApprovedIcon fill={theme.context.success} />
          ) : (
            <>
              <StepItem isStepInProgress={step.status === 'IN_PROGRESS'}>{step.status === 'IN_PROGRESS' && <Circle />}</StepItem>
              {step.status === 'IN_PROGRESS' && <StepName>{step.name}</StepName>}
            </>
          )}
        </StepItemWrapper>
      ))}
    </ImportStatusWrapper>
  );
};

const ImportStatusWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 10%;
  margin-bottom: 50px;
`;

const StepItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6px;
`;

const StepItem = styled.div<{ isStepInProgress: boolean }>`
  width: 26px;
  height: 26px;
  border-radius: 50%;
  border: 2px solid ${({ isStepInProgress, theme }) => (isStepInProgress ? theme.action.active : theme.layer[2])};
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.layer.base};
`;

const StepName = styled.p`
  color: ${({ theme }) => theme.font.weak};
  font-family: Blinker;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
`;

const Circle = styled.div`
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background: ${({ theme }) => theme.action.active};
`;
