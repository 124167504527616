// import { styled } from '@mui/material/styles';
import styled from 'styled-components';

import React, { useState } from 'react';

interface InputWithSignsProps {
  value: number;
  changeField: (value: number) => void;
  minimumValue?: number;
  disabled?: boolean;
  numberOfDecimals?: number;
  className?: string;
}
const InputWithSigns: React.FC<InputWithSignsProps> = ({
  value,
  changeField,
  disabled = false,
  minimumValue = -Infinity,
  numberOfDecimals = 0,
  className
}) => {
  const [inputValue, setInputValue] = useState<number>(value);
  const [isFocus, setIsFocus] = useState<boolean>(false);

  const onFocus = () => {
    setInputValue(value);
    setIsFocus(true);
  };
  const onBlur = () => {
    changeField(minimumValue < inputValue ? inputValue || value : value);
    setIsFocus(false);
  };
  const addPoints = (value: number): string =>
    `$${value.toLocaleString('en-US', {
      minimumFractionDigits: numberOfDecimals,
      maximumFractionDigits: numberOfDecimals
    })}`;

  return (
    <Input
      className={className}
      value={isFocus ? inputValue || '' : addPoints(value)}
      onFocus={onFocus}
      onChange={(event) => setInputValue(+event.target.value)}
      onBlur={onBlur}
      disabled={disabled}
    />
  );
};

export default InputWithSigns;

const Input = styled.input`
  height: 38px;
  width: 100%;
  border-radius: 3px;
  outline: none;
  padding: 5px 8px;
  text-align: right;
  color: ${(props) => props.theme.font.strong};
  background: ${(props) => props.theme.primary.dark};
  border: 1px solid ${(props) => props.theme.secondary.light};
  font-family: Blinker;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
`;
