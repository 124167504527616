import * as React from 'react';

export const DownloadItemIcon = ({ ...props }: React.SVGProps<SVGSVGElement>) => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.7525 12.3712V15.7458H2.25421V12.3712H0V15.7458C0.00178036 16.3431 0.239865 16.9154 0.662228 17.3378C1.08459 17.7601 1.65691 17.9982 2.25421 18H15.7525C16.3492 17.9982 16.9209 17.7599 17.3421 17.3374C17.7634 16.9148 18 16.3425 18 15.7458V12.3712H15.7525ZM14.6254 7.87625L13.0394 6.29022L10.1237 9.19234V0H7.87627V9.19234L4.96063 6.29022L3.37458 7.87625L9.00337 13.4983L14.6254 7.87625Z"
      fill={props?.fill || '#4587EC'}
    />
  </svg>
);
