import * as React from 'react';

export const CheckBoxIcon = ({ ...props }: React.SVGProps<SVGSVGElement>) => (
  <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.7801 1.91931L14.8751 1.0078C14.8064 0.937629 14.7245 0.881865 14.634 0.843799C14.5435 0.805733 14.4463 0.786133 14.3482 0.786133C14.25 0.786133 14.1529 0.805733 14.0624 0.843799C13.9719 0.881865 13.89 0.937629 13.8213 1.0078L5.22335 9.60577L2.17852 6.56739C2.03846 6.43077 1.85051 6.35429 1.65485 6.35429C1.45919 6.35429 1.27128 6.43077 1.13121 6.56739L0.219717 7.47245C0.149543 7.54108 0.093799 7.62304 0.0557331 7.71351C0.0176673 7.80399 -0.00195312 7.90115 -0.00195312 7.99931C-0.00195312 8.09747 0.0176673 8.19464 0.0557331 8.28512C0.093799 8.37559 0.149543 8.45755 0.219717 8.52618L4.70618 12.9997C4.77526 13.0691 4.85736 13.1242 4.94778 13.1617C5.03819 13.1993 5.13514 13.2187 5.23305 13.2187C5.33096 13.2187 5.42791 13.1993 5.51833 13.1617C5.60874 13.1242 5.69085 13.0691 5.75992 12.9997L15.7866 2.97305C15.8564 2.90399 15.9116 2.82169 15.9491 2.73098C15.9866 2.64027 16.0056 2.54298 16.005 2.44482C16.0044 2.34666 15.9842 2.24962 15.9456 2.15938C15.907 2.06913 15.8507 1.98751 15.7801 1.91931Z"
      fill={props.fill || '#F0F1F3'}
    />
  </svg>
);
