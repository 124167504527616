import * as React from 'react';

export const AiAssistantIcon = ({ ...props }: React.SVGProps<SVGSVGElement>) => (
  <svg width={props.width || 23} height={props.height || 24} style={props?.style} viewBox="0 0 23 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_5156_234340)">
      <path
        d="M2.5 18.9235C2.59642 18.6084 2.82543 18.3914 3.04926 18.1676C6.55319 14.6671 10.0554 11.1632 13.5576 7.66097C14.0569 7.16164 14.6182 7.16164 15.1176 7.65925C15.2467 7.78838 15.3793 7.91408 15.5015 8.04838C15.8614 8.44096 15.858 9.01433 15.4964 9.40862C15.4826 9.42412 15.4671 9.4379 15.4533 9.45339C11.8547 13.052 8.2578 16.6506 4.65745 20.2475C4.28037 20.6246 3.7225 20.6883 3.34197 20.3646C3.00277 20.0754 2.63947 19.7895 2.5 19.3298C2.5 19.1938 2.5 19.0595 2.5 18.9235ZM12.8224 11.1735C12.831 11.1701 12.843 11.1666 12.8499 11.1614C13.5765 10.4366 14.3031 9.71167 15.0263 8.98505C15.1864 8.8232 15.1795 8.62519 15.0211 8.45473C14.9109 8.33765 14.7939 8.22573 14.6802 8.11209C14.4254 7.85898 14.2566 7.85726 14.0053 8.10692C13.3492 8.76294 12.6949 9.41896 12.0372 10.0733C11.9821 10.1283 11.915 10.1697 11.8599 10.2127C12.1973 10.5502 12.5055 10.8584 12.8224 11.1752V11.1735Z"
        fill={props?.fill || '#4587EC'}
      />
      <path
        d="M18.3013 12.594L20.5001 12.0396L18.3099 11.4869C18.2411 11.4696 18.1877 11.4163 18.1705 11.3474L17.6126 9.13312L17.0547 11.3474C17.0375 11.4163 16.9841 11.4696 16.9153 11.4869L14.7251 12.0396L16.9239 12.594C16.9927 12.6112 17.0461 12.6646 17.0633 12.7335L17.6109 14.9081L18.1584 12.7335C18.1756 12.6646 18.229 12.6112 18.2979 12.594H18.3013Z"
        fill={props?.fill || '#4587EC'}
      />
      <path
        d="M17.6401 6.56762L18.9246 6.24391L17.6452 5.92193C17.6056 5.9116 17.5747 5.88061 17.5643 5.84101L17.2389 4.54791L16.9135 5.84101C16.9031 5.88061 16.8721 5.9116 16.8325 5.92193L15.5532 6.24391L16.8377 6.56762C16.8773 6.57795 16.9083 6.60894 16.9186 6.64854L17.2389 7.91925L17.5592 6.64854C17.5695 6.60894 17.6005 6.57795 17.6401 6.56762Z"
        fill={props?.fill || '#4587EC'}
      />
      <path
        d="M5.89203 5.44842L7.17652 5.12471L5.8972 4.80273C5.8576 4.7924 5.8266 4.76141 5.81627 4.7218L5.49085 3.42871L5.16542 4.7218C5.15509 4.76141 5.1241 4.7924 5.08449 4.80273L3.80518 5.12471L5.08966 5.44842C5.12926 5.45875 5.16026 5.48974 5.17059 5.52934L5.49085 6.80005L5.81111 5.52934C5.82144 5.48974 5.85243 5.45875 5.89203 5.44842Z"
        fill={props?.fill || '#4587EC'}
      />
      <path
        d="M10.3307 7.30628L12.2729 6.81728L10.3393 6.33C10.279 6.31451 10.2325 6.26802 10.217 6.20775L9.72458 4.25348L9.23213 6.20775C9.21664 6.26802 9.17015 6.31451 9.10988 6.33L7.17627 6.81728L9.11849 7.30628C9.17876 7.32178 9.22525 7.36827 9.24074 7.42853L9.72458 9.34837L10.2084 7.42853C10.2239 7.36827 10.2704 7.32178 10.3307 7.30628Z"
        fill={props?.fill || '#4587EC'}
      />
    </g>
    <defs>
      <clipPath id="clip0_5156_234340">
        <rect width="18" height="17.146" fill="white" transform="translate(2.5 3.427)" />
      </clipPath>
    </defs>
  </svg>
);
