import styled from 'styled-components';
import FormattingTooltip from '../../basicComponents/tooltip/dollarsTooltip';
import { Container, Title } from '../styles';
import { IFirmOverview } from '../types/firm-overview';
import { Card } from './card';

interface FirmOverviewProps {
  data: IFirmOverview;
}

export const FirmOverview = ({ data }: FirmOverviewProps) => {
  const dataCards = [
    { data: data.privateRealAssets, title: 'Private Real Assets' },
    { data: data.privateCredit, title: 'Private Credit' },
    {
      data: data.privateEquity,
      title: 'Private Equity'
    },
    { data: data.total, title: 'TOTAL' }
  ];

  return (
    <Container>
      <Title>Firm Overview</Title>
      <TopContent>
        <TopContentItem>
          <TopContentItemTitle>
            <FormattingTooltip noCent>{data.totalEntitiesValue}</FormattingTooltip>
          </TopContentItemTitle>
          <TopContentItemSubtitle>TOTAL ENTITY VALUE</TopContentItemSubtitle>
        </TopContentItem>
        <TopContentItem>
          <TopContentItemTitle>
            <FormattingTooltip noCent>{data.targetPrivateCapitalAllocation}</FormattingTooltip>
          </TopContentItemTitle>
          <TopContentItemSubtitle>TARGET PRIVATE CAPITAL ALLOCATION</TopContentItemSubtitle>
        </TopContentItem>
      </TopContent>
      <BottomContent>
        {dataCards.map(({ data, title }, index) => (
          <Card key={index} data={data} title={title} isHighlighted={dataCards.length - 1 === index} />
        ))}
      </BottomContent>
    </Container>
  );
};

const TopContent = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  gap: 20px;
  padding-top: 10px;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const TopContentItem = styled.div`
  background: ${({ theme }) => theme.layer[1]};
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 13px;
  border-radius: 10px;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const TopContentItemTitle = styled.span`
  color: ${({ theme }) => theme.font.strong};
  font-weight: 700;
  font-size: 23px;
`;

const TopContentItemSubtitle = styled.span`
  font-weight: 400;
  font-size: 16px;
  color: ${({ theme }) => theme.font.weak};
  text-align: center;
`;

const BottomContent = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: space-around;
  gap: 20px;
  margin-top: 20px;
  @media (max-width: 1200px) {
    flex-wrap: wrap;
  }
`;
