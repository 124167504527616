import { ISetting } from '@/components/settings-page/settings-pages/firmSettings/types';
import { StyleSheet, Text, View } from '@react-pdf/renderer';
import { ISection } from '../dueDiligenceRecord/types';
import { AiIcon } from './AiIcon';
import { templateStyles } from './PDFTemplate';
import { SectionScore } from './sectionScore';
import { aiFieldValue, fieldValue, getFontByKey, getTermFontWeight, matchFieldWithAI, splitTermsThreeColumns, truncateString } from './utils';

interface ThreeColumnSectionProps {
  section: ISection;
  imageSizes: { [url: string]: { width: number; height: number } };
  templateStylingData: ISetting[];
  includeAiSuggested: boolean;
}

export const ThreeColumnSection = ({ section, imageSizes, templateStylingData, includeAiSuggested }: ThreeColumnSectionProps) => {
  const getFontStyle = (fontFamily: string) => {
    if (!fontFamily) return 'normal';

    switch (fontFamily) {
      case 'Roboto Slab':
        return 'normal';
      case 'Oswald':
        return 'normal';
      case 'Pacifico':
        return 'normal';
      default:
        return getFontByKey(templateStylingData, 'bodyFontStyle').fontWeight + 'italic';
    }
  };

  const styles = StyleSheet.create({
    sectionName: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      fontSize: 20,
      fontFamily: getFontByKey(templateStylingData, 'headerFontStyle').fontFamily
        ? getFontByKey(templateStylingData, 'headerFontStyle').fontFamily
        : 'Roboto Slab',
      color: Boolean(getFontByKey(templateStylingData, 'headerFontStyle').fontColor)
        ? `#${getFontByKey(templateStylingData, 'headerFontStyle').fontColor}`
        : '#35739C',
      fontWeight: getFontByKey(templateStylingData, 'headerFontStyle').fontWeight
        ? getFontByKey(templateStylingData, 'headerFontStyle').fontWeight
        : 400,
      paddingBottom: '4px',
      borderBottom: '2px solid #F0F1F3',
      marginBottom: '10px'
    },
    termField: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      fontSize: '9px',
      fontFamily: getFontByKey(templateStylingData, 'bodyFontStyle').fontFamily
        ? getFontByKey(templateStylingData, 'bodyFontStyle').fontFamily
        : 'Open Sans',
      color: Boolean(getFontByKey(templateStylingData, 'bodyFontStyle').fontColor)
        ? `#${getFontByKey(templateStylingData, 'bodyFontStyle').fontColor}`
        : '#757575',
      fontWeight: getFontByKey(templateStylingData, 'bodyFontStyle').fontWeight ? getFontByKey(templateStylingData, 'bodyFontStyle').fontWeight : 600
    },
    termFieldName: {
      fontWeight: getTermFontWeight(templateStylingData, 'bodyFontStyle') as number
    },
    blankTermField: {
      fontStyle: getFontStyle(getFontByKey(templateStylingData, 'bodyFontStyle').fontFamily)
    },
    aiIconWrap: {
      display: 'flex',
      flexDirection: 'row',
      fontSize: '7px',
      fontFamily: getFontByKey(templateStylingData, 'bodyFontStyle').fontFamily
        ? getFontByKey(templateStylingData, 'bodyFontStyle').fontFamily
        : 'Open Sans',
      color: Boolean(getFontByKey(templateStylingData, 'bodyFontStyle').fontColor)
        ? `#${getFontByKey(templateStylingData, 'bodyFontStyle').fontColor}`
        : '#757575',
      fontWeight: getTermFontWeight(templateStylingData, 'bodyFontStyle') as number, // TODO: Check type
      marginTop: '25px'
    }
  });

  return (
    <View style={{ marginBottom: '30px' }}>
      <View style={styles.sectionName}>
        <Text>{section.name}</Text>
        {section.userCanScore ? <SectionScore score={section.data.score} templateStylingData={templateStylingData} /> : null}
      </View>
      <View style={templateStyles.termSectionWrap}>
        {splitTermsThreeColumns(section.fields).map((fields, index) => (
          <View key={index} style={templateStyles.termFieldsWrap}>
            {fields.map((field) => (
              <View key={field.data.id} wrap={false} style={styles.termField}>
                <Text style={styles.termFieldName}>
                  {!matchFieldWithAI(field) || !field.AI?.answers.length || !includeAiSuggested ? field.name : `* ${field.name}`}:
                </Text>
                {matchFieldWithAI(field) ? (
                  <>
                    {field.AI?.answers.length && includeAiSuggested ? (
                      <Text>{truncateString(aiFieldValue(field.type, field.AI.answers[0]), 250)}</Text>
                    ) : (
                      <Text style={styles.blankTermField}> Not specified</Text>
                    )}
                  </>
                ) : (
                  <Text> {truncateString(fieldValue(field.type, field.data.value, field.data.commentary), 250)}</Text>
                )}
              </View>
            ))}
          </View>
        ))}
      </View>
      {section.fields.filter((field) => (!field.data.value || field.data.value === field.AI?.answers[0]) && field.AI?.answers.length).length &&
      includeAiSuggested ? (
        <View style={styles.aiIconWrap}>
          <View style={{ borderRight: '1px solid #C7CBD2', paddingRight: '3px' }}>
            <AiIcon />
          </View>
          <Text style={{ margin: '2px 0 0 5px' }}>
            {section.fields.length > 1 ? '* These fields are AI Suggested' : 'This field is AI Suggested'}
          </Text>
        </View>
      ) : null}
    </View>
  );
};
