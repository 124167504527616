import { format } from 'date-fns';
import { useMemo } from 'react';
import styled, { useTheme } from 'styled-components';

import { CloseInfoIcon } from '@/assets/icons/info-icons/closeInfo';
import { NotesIcon } from '@/assets/icons/menu-icons/notes';
import { useResponsive } from '@/hooks/use-responsive';
import guid from '@/utils/guid';
import { INotesData } from './types';

type NotesProps = {
  data: INotesData[] | undefined;
  isOpen: boolean;
  onClick: () => void;
};

const Notes = ({ data, isOpen, onClick }: NotesProps) => {
  const theme = useTheme();
  const { isMobile } = useResponsive();
  const dataFiltered = useMemo(() => {
    return data?.filter((el) => el.title && el.notes);
  }, [data]);
  return isMobile ? (
    <></>
  ) : (
    <NotesWrap id="notes" isOpen={isOpen}>
      <NotesTitleWrap id="notes">
        <TitleWrap id="notes">
          <NotesIcon id="notes" fill={theme.font.strong} />
          <Title id="notes">Notes</Title>
        </TitleWrap>
        <CloseBtn onClick={onClick}>
          <CloseInfoIcon width={14} height={14} fill={theme.action.primary} />
        </CloseBtn>
      </NotesTitleWrap>
      <NotesBody id="notes">
        {dataFiltered &&
          dataFiltered.map((el) => (
            <NoteItem id="notes" key={guid()}>
              <NoteItemTitle id="notes">{el.title}</NoteItemTitle>
              <NoteItemDate id="notes">{el.notesDate ? format(new Date(el.notesDate), 'LLL d, yyyy') : 'No date'}</NoteItemDate>
              <NoteItemDesc id="notes">{el.notes}</NoteItemDesc>
              <Divider />
            </NoteItem>
          ))}
      </NotesBody>
    </NotesWrap>
  );
};

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: ${({ theme }) => theme.border.base};
  margin: 30px 0;
`;

const NoteItemDesc = styled.div`
  font-family: 'Blinker', serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  color: ${({ theme }) => theme.font.base};
  margin-top: 10px;
`;

const NoteItemDate = styled.div`
  font-family: 'Blinker', serif;
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  color: ${({ theme }) => theme.font.weak};
`;

const NoteItemTitle = styled.div`
  font-family: 'Blinker', serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 18px;
  color: ${({ theme }) => theme.font.base};
`;

const NoteItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const NotesBody = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  overflow: auto;
`;

const Title = styled.div`
  font-family: 'Blinker', serif;
  font-size: 28px;
  font-weight: 600;
  line-height: 39px;
  color: ${({ theme }) => theme.font.strong};
`;

const TitleWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 9px;
`;

const CloseBtn = styled.div`
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  min-width: 40px;
  border-radius: 50%;
  border: 1px solid ${({ theme }) => theme.action.primary};
  background: ${({ theme }) => theme.layer.base};
`;

const NotesTitleWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const NotesWrap = styled.div<{ isOpen: boolean }>`
  display: flex;
  flex-direction: column;
  padding: ${({ isOpen }) => (isOpen ? '20px 40px' : 0)};
  position: absolute;
  margin-right: -24px;
  top: 60px;
  bottom: 0;
  right: 0;
  width: ${({ isOpen }) => (isOpen ? '600px' : 0)};
  height: calc(100vh - 60px);
  background: ${({ theme }) => theme.layer[1]};
  z-index: 125;
  box-shadow: -14px 0 12px -10px rgba(0, 0, 0, 0.15);
  transition:
    all 0.3s,
    height 1ms;
`;

export default Notes;
