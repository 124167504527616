import * as React from 'react';

export const PendingInvestmentsMenuIcon = ({ ...props }: React.SVGProps<SVGSVGElement>) => (
  <svg
    style={props.style}
    width={props.width || '26'}
    height={props.height || '26'}
    viewBox="0 0 26 26"
    fill={props.fill || 'none'}
    stroke={props.stroke || undefined}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.95025 23.022C4.7111 23.022 4.48988 22.92 4.27298 22.7099C0.552326 19.111 -0.747832 14.8478 0.408963 10.0389C1.59727 5.09598 4.78217 1.8771 9.87466 0.471274C10.3591 0.33718 10.8541 0.254993 11.3775 0.167245C11.6037 0.12955 11.8373 0.0906195 12.069 0.0473633H13.9624C14.2089 0.096181 14.4592 0.142527 14.7008 0.187019C15.2508 0.28898 15.8193 0.394031 16.3619 0.534305C18.3393 1.04596 20.0763 1.99636 21.8282 3.52454L22.1353 3.79273L22.3979 3.48067C22.5252 3.32927 22.6488 3.17664 22.7681 3.02833C23.0332 2.70021 23.2828 2.39 23.5615 2.1113C23.7142 1.95867 23.8711 1.87463 24.0034 1.87463C24.2703 1.87463 24.3865 2.24601 24.4155 2.35972C24.9556 4.46382 25.4741 6.54445 25.9567 8.54288C26.0253 8.8259 26.0117 9.05516 25.9209 9.17072C25.8572 9.25167 25.7392 9.29307 25.5705 9.29307C25.4741 9.29307 25.3659 9.27886 25.2479 9.25105C22.9646 8.71406 20.96 8.2302 19.1204 7.77107C18.7972 7.69012 18.5803 7.55788 18.5401 7.41699C18.4999 7.27548 18.6155 7.04931 18.8485 6.81202C19.134 6.52035 19.4324 6.23733 19.7488 5.93762C19.8977 5.79611 20.0504 5.65151 20.2073 5.50074L20.5132 5.20659L20.2018 4.91801C19.1587 3.95216 17.9623 3.25079 16.5454 2.77374C15.3713 2.37826 14.1638 2.17804 12.957 2.17804C10.1119 2.17804 7.42327 3.27366 5.38652 5.26221C3.30219 7.29772 2.14354 10.0587 2.12376 13.0372C2.10337 16.1374 3.25275 18.8329 5.541 21.0495C5.91362 21.4104 6.08294 21.8368 6.00632 22.2193C5.94143 22.5418 5.70291 22.7983 5.33461 22.9423C5.20052 22.9948 5.07136 23.0214 4.95025 23.0214V23.022Z"
      fill={props.fill || 'white'}
    />
    <path
      d="M17.0167 16.5774C16.777 16.5774 16.5137 16.4921 16.2548 16.3308C15.6733 15.9687 15.0851 15.5942 14.5159 15.2321C13.8998 14.8403 13.2627 14.435 12.6312 14.0426C12.1671 13.7546 11.9712 13.4073 11.9762 12.8814C11.9854 11.8519 11.9842 10.8058 11.983 9.79418C11.9817 8.93832 11.9805 8.05342 11.9861 7.18397C11.991 6.43564 12.3822 5.97095 13.0081 5.97095C13.0186 5.97095 13.0285 5.97095 13.039 5.97095C13.6576 5.98578 14.0333 6.43935 14.045 7.18397C14.0531 7.68266 14.0512 8.19184 14.0494 8.68373C14.0488 8.93214 14.0475 9.17994 14.0481 9.42835C14.0481 9.65082 14.0506 9.87389 14.0525 10.097C14.058 10.6111 14.063 11.1432 14.0383 11.6585C14.0104 12.2314 14.2261 12.6349 14.7168 12.9278C15.6344 13.4765 16.5619 14.0747 17.3807 14.603C18.011 15.0103 18.1816 15.5491 17.8479 16.083C17.6458 16.4062 17.3585 16.5768 17.0167 16.5768V16.5774Z"
      fill={props.fill || 'white'}
    />
    <path
      d="M19.9286 23.8919C19.6375 23.8894 19.3749 23.7832 19.1895 23.5922C18.9992 23.3969 18.8972 23.122 18.9028 22.8192C18.9139 22.1889 19.7469 21.4424 20.4384 21.4424C20.7115 21.4424 20.9705 21.5524 21.1663 21.752C21.369 21.959 21.4778 22.2321 21.4728 22.5213C21.4611 23.1491 20.6281 23.8919 19.9366 23.8919H19.9286Z"
      fill={props.fill || 'white'}
    />
    <path
      d="M14.8609 25.9522C14.8325 25.9522 14.804 25.9522 14.775 25.9509C14.2547 25.9361 13.7437 25.4047 13.7437 24.8782C13.7437 24.191 14.4042 23.7282 15.3886 23.7263C15.6716 23.7263 15.9516 23.8474 16.1604 24.0582C16.36 24.2596 16.4731 24.5253 16.4706 24.7855C16.4632 25.4831 15.8169 25.9522 14.8615 25.9522H14.8609Z"
      fill={props.fill || 'white'}
    />
    <path
      d="M9.87838 25.622C8.9904 25.622 8.25998 25.0857 8.2501 24.4269C8.24639 24.1594 8.3502 23.9048 8.54362 23.7101C8.73951 23.513 9.0009 23.4024 9.28021 23.3987H9.31605C9.84872 23.3987 10.3245 23.5686 10.6218 23.8658C10.8195 24.0642 10.9239 24.3071 10.9239 24.5691C10.9233 25.1512 10.4784 25.614 9.91052 25.622H9.87838Z"
      fill={props.fill || 'white'}
    />
  </svg>
);
