import styled from 'styled-components';
import TableHeader from '../../../components/table/tableHeader';
import { useResponsive } from '../../../hooks/use-responsive';
import { Loader } from '../../loaders/loader';
import { TableWrapper } from '../../table/styles';
import { ISort } from '../../table/types';
import { header } from './constants';
import MobileTable from './mobile-table';
import TableBody from './tableBody';

type ManagerDetailsTable = {
  managersRows?: any;
  loading?: boolean;
  paginationValues?: {
    limit: number;
    offset: number;
    total: number;
  };
  refetch?: any;
  handleClickClient: (id: string) => void;
  savedSort?: ISort;
  savedSetSort?: (value: ISort) => void;
};

const Table = ({ managersRows, loading, refetch, handleClickClient, savedSort, savedSetSort }: ManagerDetailsTable) => {
  const { isMobile, isTablet } = useResponsive();
  return (
    <TableWrapper padding="0 0 30px">
      {isMobile ? (
        <MobileTable columns={header} managersRows={managersRows} handleClickManager={handleClickClient} loading={loading} />
      ) : (
        <>
          <CustomTableHeader isTablet={isTablet} columns={header} refetch={refetch} savedSort={savedSort} savedSetSort={savedSetSort} />
          <>{loading ? <Loader /> : <TableBody columns={header} managerRows={managersRows} handleClickClient={handleClickClient} />}</>
        </>
      )}
    </TableWrapper>
  );
};

export default Table;

const CustomTableHeader = styled(TableHeader)<{ isTablet: boolean }>`
  position: sticky;
  top: ${({ isTablet }) => (isTablet ? '130px' : '80px')};
  z-index: 10;
  padding-top: 0;
`;
