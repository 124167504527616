import {
  MobileRow,
  MobileRowTitle,
  MobileRowValue,
  MobileTableRowWrap,
  MobileTableWrapper,
  NoResults
} from '@/components/table/mobileTable/mobileTable';

import FormattingTooltip from '@/components/basicComponents/tooltip/dollarsTooltip';
import { columns } from '../../constatnts';
import { IInvestorTotalRow, ILegalEntity } from '../../types';
import MobileSumFooter from '../mobileSumFooter';

interface MobileTableProps {
  tableRows: ILegalEntity[];
  openEntityDetails: (id: string) => void;
  onMobileRowClick: (data: ILegalEntity) => void;
  showSpecificColumn: boolean;
  totalRowData: IInvestorTotalRow | null;
}

const MobileTable = ({ tableRows, openEntityDetails, onMobileRowClick, showSpecificColumn, totalRowData }: MobileTableProps) => {
  const TableRow = ({ el }: { el: ILegalEntity }) => {
    return (
      <MobileTableRowWrap key={el.id}>
        <MobileRow>
          <MobileRowTitle>{columns[0].title}</MobileRowTitle>
          <MobileRowValue isRedirect onClick={() => openEntityDetails(el.id)}>
            {el.entityName}
          </MobileRowValue>
        </MobileRow>
        {showSpecificColumn && (
          <MobileRow>
            <MobileRowTitle>{columns[1].title}</MobileRowTitle>
            <MobileRowValue>
              {el.totalEntityValue ? <FormattingTooltip zIndex={1000}>{+el.totalEntityValue}</FormattingTooltip> : <>--</>}
            </MobileRowValue>
          </MobileRow>
        )}

        {showSpecificColumn && (
          <MobileRow>
            <MobileRowTitle>{columns[2].title}</MobileRowTitle>
            <MobileRowValue>{el.privatePercentage ? <> {el.privatePercentage.toFixed(2)}%</> : <>--</>}</MobileRowValue>
          </MobileRow>
        )}

        {showSpecificColumn && (
          <MobileRow>
            <MobileRowTitle>{columns[3].title}</MobileRowTitle>
            <MobileRowValue>{el.overCommitRatio ? el.overCommitRatio.toFixed(2) : <>--</>}</MobileRowValue>
          </MobileRow>
        )}

        <MobileRow>
          <MobileRowTitle>{columns[4].title}</MobileRowTitle>
          <MobileRowValue>
            <FormattingTooltip zIndex={1000}>{+el.economicExposure}</FormattingTooltip>
          </MobileRowValue>
        </MobileRow>

        {showSpecificColumn && (
          <MobileRow>
            <MobileRowTitle>{columns[5].title}</MobileRowTitle>

            {!el.totalEntityValue || !el.privatePercentage || !el.overCommitRatio ? (
              <MobileRowValue>--</MobileRowValue>
            ) : (
              <MobileRowValue isRedirect onClick={() => onMobileRowClick(el)}>
                <FormattingTooltip zIndex={1000}>{+el.capacity}</FormattingTooltip>
              </MobileRowValue>
            )}
          </MobileRow>
        )}
      </MobileTableRowWrap>
    );
  };

  return (
    <MobileTableWrapper>
      {tableRows.length ? (
        <>
          {tableRows.map((el, index) => {
            return <TableRow el={el} key={index} />;
          })}
          <MobileSumFooter totalRowData={totalRowData} showSpecificColumn={showSpecificColumn} />
        </>
      ) : (
        <NoResults>No Results</NoResults>
      )}
    </MobileTableWrapper>
  );
};

export default MobileTable;
