import { useMemo } from 'react';
import styled from 'styled-components';

import { formatCash } from '../../../utils/formatCash';
import Tooltip from '../../basicComponents/tooltip';
import FormattingTooltip from '../../basicComponents/tooltip/dollarsTooltip';
import { TableBodyWrapper, TableRowCell, TextCell } from '../../table/styles';
import { columnsCommitments } from '../constants';
import { CommitmentsType } from '../types';

type TableSumFooterProps = {
  clientsRows: CommitmentsType[];
};

type RowSumType = {
  total: string;
  committedCapital: number;
  capitalCalled: number;
  capitalRemaining: number;
  distributionsToDate: number;
  marketValue: number;
  status: string;
};

const TableSumFooter = ({ clientsRows }: TableSumFooterProps) => {
  const rowSum: RowSumType = useMemo(() => {
    const sum = {
      total: 'Total',
      committedCapital: 0,
      capitalCalled: 0,
      capitalRemaining: 0,
      distributionsToDate: 0,
      marketValue: 0,
      status: ''
    };
    for (let i = 0; i < clientsRows.length; i++) {
      sum.committedCapital += clientsRows[i].committedCapital;
      sum.capitalCalled += clientsRows[i].capitalCalled;
      sum.capitalRemaining += clientsRows[i].capitalRemaining;
      sum.distributionsToDate += +clientsRows[i].distributionsToDate;
      sum.marketValue += +clientsRows[i].marketValue;
    }
    return sum;
  }, [clientsRows]);

  const toolTipContent = (row: RowSumType) => {
    return (
      <TableBodyWrapper>
        <TableRowCell width={100}>
          <TextCell>{formatCash(row.committedCapital)} Committed Capital</TextCell>
        </TableRowCell>
        <TableRowCell width={100}>
          <TextCell>{formatCash(row.capitalCalled)} Capital Called</TextCell>
        </TableRowCell>
        <TableRowCell width={100}>
          <TextCell>{formatCash(row.capitalRemaining)} Capital Remaining</TextCell>
        </TableRowCell>
        <TableRowCell width={100}>
          <TextCell>{((row.capitalCalled / row.committedCapital) * 100).toFixed(2)}% Called to Date</TextCell>
        </TableRowCell>
      </TableBodyWrapper>
    );
  };

  return (
    <TableSumWrap>
      <TableSumItem width={columnsCommitments[0].width}>{rowSum.total}</TableSumItem>
      <TableSumItem width={columnsCommitments[1].width}> </TableSumItem>

      <TableSumItem width={columnsCommitments[2].width}>
        <FormattingTooltip zIndex={1000}>{rowSum.committedCapital}</FormattingTooltip>
      </TableSumItem>
      <TableSumItem width={columnsCommitments[3].width} cursor={'pointer'}>
        <Tooltip zIndex={1000} tooltipContent={toolTipContent(rowSum)}>
          {rowSum.capitalCalled === 0 || rowSum.committedCapital === 0
            ? '0%'
            : ((rowSum.capitalCalled / rowSum.committedCapital) * 100).toFixed(2) + '%'}
        </Tooltip>
      </TableSumItem>
      <TableSumItem width={columnsCommitments[4].width}>
        <FormattingTooltip zIndex={1000}>{rowSum.distributionsToDate}</FormattingTooltip>
      </TableSumItem>
      <TableSumItem width={columnsCommitments[5].width}>
        <FormattingTooltip zIndex={1000}>{rowSum.marketValue}</FormattingTooltip>
      </TableSumItem>
      <TableSumItem width={columnsCommitments[6].width}> </TableSumItem>
    </TableSumWrap>
  );
};

const TableSumWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  min-height: 60px;
  background-color: ${({ theme }) => theme.layer[2]};
  border-radius: 8px;
  padding: 0 24px;
`;

const TableSumItem = styled.div<{ width: number; cursor?: string }>`
  display: flex;
  justify-content: flex-start;
  width: ${({ width }) => `${width}%`};
  font-family: Blinker, serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0;
  color: ${({ theme }) => theme.font.base};
  cursor: ${({ cursor }) => cursor};
`;

export default TableSumFooter;
