import { updatesTableColumnsEditMode, updatesTableColumnsViewMode } from '@/components/managerUpdates-page/constatnts';
import DropdownActions from '@/components/table/fat-dropdownActions';
import RedirectTitleCell from '@/components/table/redirectTitleCell';
import { TableRowCell, TableRowWrapper, TextCell } from '@/components/table/styles';
import { IItems } from '@/components/table/types';
import { dateFormatter } from '@/utils/dateFormatter';
import { format } from 'date-fns';
import { DOCUMENT_RECORD_STATUS_ENUM, IDocumentRecordEntity } from '../../types';

interface TableBodyProp {
  tableData: IDocumentRecordEntity[];
  dropdownActions: (row: IDocumentRecordEntity) => IItems[];
  openEditManagerUpdatePage: (updateId: string) => void;
  canUserEdit: boolean;
}

export const TableBody = ({ tableData, dropdownActions, openEditManagerUpdatePage, canUserEdit }: TableBodyProp) => {
  if (canUserEdit) {
    return (
      <>
        {tableData.map((row) => (
          <TableRowWrapper key={row.id}>
            <TableRowCell width={updatesTableColumnsEditMode[0].width}>
              <RedirectTitleCell onClick={() => openEditManagerUpdatePage(row.id)}>{row.name}</RedirectTitleCell>
            </TableRowCell>
            <TableRowCell width={updatesTableColumnsEditMode[1].width}>
              <TextCell>{`${row?.owner?.firstName ?? ''} ${row?.owner?.lastName ?? ''}`}</TextCell>
            </TableRowCell>
            <TableRowCell width={updatesTableColumnsEditMode[2].width}>
              <TextCell>{row.statusUpdatedAt && dateFormatter(format(new Date(row.statusUpdatedAt), 'yyyy-MM-dd'), 'yyyy-MM-dd')}</TextCell>
            </TableRowCell>
            <TableRowCell width={updatesTableColumnsEditMode[3].width}>
              <TextCell>{DOCUMENT_RECORD_STATUS_ENUM[row.status]}</TextCell>
            </TableRowCell>
            <TableRowCell width={updatesTableColumnsEditMode[4].width}>
              <DropdownActions items={dropdownActions(row)} />
            </TableRowCell>
          </TableRowWrapper>
        ))}
      </>
    );
  }

  return (
    <>
      {tableData.map((row) => (
        <TableRowWrapper key={row.id}>
          <TableRowCell width={updatesTableColumnsViewMode[0].width}>
            <RedirectTitleCell onClick={() => openEditManagerUpdatePage(row.id)}>{row.name}</RedirectTitleCell>
          </TableRowCell>
          <TableRowCell width={updatesTableColumnsViewMode[1].width}>
            <TextCell>{row.statusUpdatedAt && dateFormatter(format(new Date(row.statusUpdatedAt), 'yyyy-MM-dd'), 'yyyy-MM-dd')}</TextCell>
          </TableRowCell>
          <TableRowCell width={updatesTableColumnsViewMode[2].width}>
            <TextCell>{DOCUMENT_RECORD_STATUS_ENUM[row.status]}</TextCell>
          </TableRowCell>
          <TableRowCell width={updatesTableColumnsViewMode[3].width}>
            <DropdownActions items={dropdownActions(row)} />
          </TableRowCell>
        </TableRowWrapper>
      ))}
    </>
  );
};
