import { useMemo } from 'react';
import styled from 'styled-components';
import FormattingTooltip from '../../basicComponents/tooltip/dollarsTooltip';
import { SummaryTableData } from '../types';

type TableSumFooterProps = {
  amountColumns: number;
  clientsRows: SummaryTableData[];
};

type RowSumType = {
  total: string;
  target: number;
  targetAllocations: number;
  exposure: number;
  capacity: number;
};

const TableSumFooter = ({ amountColumns, clientsRows }: TableSumFooterProps) => {
  const WIDTH = 100 / amountColumns;

  const rowSum: RowSumType = useMemo(() => {
    const sum = {
      total: 'Total',
      target: 0,
      targetAllocations: 0,
      exposure: 0,
      capacity: 0
    };
    for (let i = 0; i < clientsRows.length; i++) {
      sum.target += clientsRows[i].target;
      sum.targetAllocations += clientsRows[i].targetAllocations;
      sum.exposure += clientsRows[i].exposure;
      sum.capacity += clientsRows[i].capacity;
    }
    return sum;
  }, [clientsRows]);

  return (
    <TableSumWrap>
      <TableSumItem width={WIDTH}>{rowSum.total}</TableSumItem>
      <TableSumItem width={WIDTH}>{rowSum.target.toFixed(0)}%</TableSumItem>
      <TableSumItem width={WIDTH}>
        <FormattingTooltip zIndex={1000}>{rowSum.targetAllocations}</FormattingTooltip>
      </TableSumItem>
      <TableSumItem width={WIDTH}>
        <FormattingTooltip zIndex={1000}>{rowSum.exposure}</FormattingTooltip>
      </TableSumItem>
      <TableSumItem width={WIDTH}>
        <FormattingTooltip zIndex={1000}>{rowSum.capacity}</FormattingTooltip>
      </TableSumItem>
    </TableSumWrap>
  );
};

const TableSumWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  min-height: 60px;
  background-color: ${({ theme }) => theme.layer[2]};
  border-radius: 8px;
  padding: 0 24px;
`;

const TableSumItem = styled.div<{ width: number }>`
  display: flex;
  justify-content: flex-start;
  width: ${({ width }) => `${width}%`};
  font-family: Blinker, serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0;
  color: ${({ theme }) => theme.font.base};
`;

export default TableSumFooter;
