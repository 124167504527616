import client from '@/apollo-client';
import { EditIcon } from '@/assets/icons/info-icons/edit';
import { ILabelerData, IRawAnswers } from '@/components/dueDiligence-page/dueDiligenceRecord/types';
import {
  createGroundTruthMutation,
  updateGroundTruthMutation,
  updateHumanScoreMutation,
  updateRawAnswerMutation
} from '@/components/dueDiligence-page/queries';
import Button from '@/components/fat-basicComponents/button';
import { getAssetSignedUrl } from '@/components/settings-page/queries';
import { useResponsive } from '@/hooks/use-responsive';
import { PaddingWrap } from '@/styles/common';
import { useMutation } from '@apollo/client';
import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import styled, { useTheme } from 'styled-components';
import { IDocumentRecordFieldEntity } from '../../../types';
import AiLabelerSection from './aiLabelerSection';
import Table from './table';
import MobileTable from './tableMobile';

interface LabelerToolProps {
  labelerData: ILabelerData | null;
  investmentId: string | null;
  setIsPromptEditorOpen: Dispatch<SetStateAction<boolean>>;
  refetch: any;
  nextField: () => void;
  isLastField: boolean;
  onClose: () => void;
  currentField: IDocumentRecordFieldEntity;
}

export interface IDefaultLabelerToolFormData {
  humanScore: string;
  documentContainsAnswer: string;
  truePageNumbers: string;
  falsePageNumbers: string;
  notes: string;
  groundtruthAnswer: string;
}

const defaultLabelerToolFormData: IDefaultLabelerToolFormData = {
  humanScore: 'Select',
  documentContainsAnswer: 'Select',
  truePageNumbers: '',
  falsePageNumbers: '',
  notes: '',
  groundtruthAnswer: ''
};

export const LabelerTool = ({
  labelerData,
  investmentId,
  setIsPromptEditorOpen,
  refetch,
  nextField,
  isLastField,
  onClose,
  currentField
}: LabelerToolProps) => {
  const { isMobile } = useResponsive();
  const theme = useTheme();

  const { handleSubmit, control, reset, watch } = useForm<IDefaultLabelerToolFormData>({ defaultValues: defaultLabelerToolFormData });
  const watchDocumentContainsAnswer = useWatch({ control, name: 'documentContainsAnswer' });

  const [tableRows, setTableRows] = useState<IRawAnswers[]>([]);

  useEffect(() => {
    if (!labelerData) return;
    reset({
      groundtruthAnswer: labelerData?.groundTruth?.answer ?? '',
      documentContainsAnswer:
        labelerData?.groundTruth?.docContainsAnswer !== null ? labelerData?.groundTruth?.docContainsAnswer.toString() : 'Select',
      notes: labelerData?.groundTruth?.notes ?? '',
      truePageNumbers: labelerData?.groundTruth?.pageNumber ? labelerData.groundTruth.pageNumber : '',
      falsePageNumbers: labelerData?.groundTruth?.falsePageNumber ? labelerData.groundTruth.falsePageNumber : '',
      humanScore: labelerData?.humanScore?.score ? labelerData.humanScore.score.toString() : 'Select'
    });

    setTableRows(labelerData?.rawAnswers ?? []);

    return () => {
      reset(defaultLabelerToolFormData);
      setTableRows([]);
    };
  }, [labelerData]);

  const openPdf = useCallback(
    async (pageNumber: number) => {
      if (!investmentId || !currentField.labelerData.filename) return;

      try {
        const assetKey = `investments/${investmentId}/mu/${currentField.recordId}/${currentField.labelerData.filename}`;
        const { data } = await client.query({
          fetchPolicy: 'network-only',
          query: getAssetSignedUrl,
          variables: {
            assetKey: assetKey
          }
        });

        window.open(`${data.getAssetSignedUrl}#page=${pageNumber}`, '_blank');
      } catch (error) {
        console.log(error);
      }
    },
    [investmentId, currentField]
  );

  const changeField = useCallback((id: string, value: string, fieldName: string) => {
    setTableRows((prevTableRows) => {
      return prevTableRows.map((row) => {
        if (row.id === id) {
          if (fieldName === 'hasAnswer') {
            return {
              ...row,
              hasAnswer: value === '--' ? null : value === 'true',
              isChangedRow: true
            };
          }
          if (fieldName === 'score') {
            return {
              ...row,
              score: value === '--' ? null : Number(value),
              isChangedRow: true
            };
          }
        }
        return row;
      });
    });
  }, []);

  const [createGroundTruth] = useMutation(createGroundTruthMutation);
  const [updateGroundTruth] = useMutation(updateGroundTruthMutation);
  const [updateHumanScore] = useMutation(updateHumanScoreMutation);
  const [updateRawAnswer] = useMutation(updateRawAnswerMutation);

  const saveLabelerToolData = async (formData: IDefaultLabelerToolFormData) => {
    const { truePageNumbers, falsePageNumbers, notes, groundtruthAnswer, documentContainsAnswer, humanScore } = formData;

    const truePageNumberSet = new Set(truePageNumbers.split(',').map(Number));
    const falsePageNumberSet = new Set(falsePageNumbers.split(',').map(Number));
    for await (const row of tableRows) {
      if (row.isChangedRow) {
        await updateRawAnswer({
          variables: {
            data: {
              id: row.id,
              score: row.score,
              hasAnswer: row.hasAnswer
            }
          }
        });
      }
    }
    truePageNumberSet.delete(0);
    falsePageNumberSet.delete(0);
    const updatedTruePageNumbers = Array.from(truePageNumberSet)
      .sort((n1, n2) => n1 - n2)
      .join(', ');
    const updatedFalsePageNumbers = Array.from(falsePageNumberSet)
      .sort((n1, n2) => n1 - n2)
      .join(', ');
    if (labelerData?.groundTruth?.id) {
      await updateGroundTruth({
        variables: {
          data: {
            id: labelerData?.groundTruth.id,
            notes: notes,
            pageNumber: updatedTruePageNumbers,
            falsePageNumber: updatedFalsePageNumbers,
            answer: groundtruthAnswer,
            docContainsAnswer: documentContainsAnswer === 'Select' ? null : documentContainsAnswer === 'true'
          }
        }
      });
    } else if (currentField.labelerData?.filename && currentField.documentField.id) {
      await createGroundTruth({
        variables: {
          data: {
            fieldId: currentField.id,
            filename: currentField.labelerData.filename,
            notes: notes,
            pageNumber: updatedTruePageNumbers,
            falsePageNumber: updatedFalsePageNumbers,
            answer: groundtruthAnswer,
            docContainsAnswer: documentContainsAnswer === 'Select' ? null : documentContainsAnswer === 'true'
          }
        }
      });
    }
    if (labelerData?.humanScore?.id) {
      await updateHumanScore({
        variables: {
          data: {
            id: labelerData.humanScore.id,
            score: humanScore === 'Select' ? null : Number(humanScore)
          }
        }
      });
    }

    if (isLastField) {
      onClose();
    } else {
      nextField();
    }
    refetch();
  };

  return (
    <LabelerToolWrapper>
      <AiAnswerHeader>
        AI Answer
        <EditButton onClick={() => setIsPromptEditorOpen(true)}>
          <EditIcon width={16} height={16} fill={theme.action.primary} /> Edit Prompt
        </EditButton>
      </AiAnswerHeader>
      <AiLabelerSection
        aiAnswer={currentField.AI.answers[0]}
        control={control}
        rubricScore={labelerData?.rubricScore?.score ? labelerData.rubricScore.score : 'N/A'}
        filename={currentField?.labelerData?.filename ?? ''}
        watchDocumentContainsAnswer={watchDocumentContainsAnswer}
        openPdf={openPdf}
      />
      <TableWrapper>
        {isMobile ? (
          <MobileTable rows={tableRows} changeField={changeField} openPdf={openPdf} />
        ) : (
          <Table rows={tableRows} changeField={changeField} openPdf={openPdf} />
        )}
      </TableWrapper>
      <ButtonWrapper>
        <Button styleType="outline" onClick={onClose} size="lg">
          Cancel
        </Button>
        <Button styleType={'default'} onClick={handleSubmit(saveLabelerToolData, () => window.scrollTo(0, 0))} size="lg">
          Save & Continue
        </Button>
      </ButtonWrapper>
    </LabelerToolWrapper>
  );
};

const LabelerToolWrapper = styled(PaddingWrap)`
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;
  color: ${({ theme }) => theme.font.base};
  margin: 50px 0;
`;

const AiAnswerHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 4px 0;
`;

const EditButton = styled.div`
  color: ${({ theme }) => theme.action.primary};
  cursor: pointer;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 4px;
`;

const TableWrapper = styled.div`
  margin: 40px 0;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
`;
