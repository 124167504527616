import { ArrowDown } from '@/assets/icons/info-icons/arrowDown';
import { ITab } from '@/components/basicComponents/fat-tabs';
import { Divider, FilterItems, PrimaryFilterItem, SearchInput, SearchWrap } from '@/components/basicComponents/select';
import { IManagerInvestment, IManagerInvestments } from '@/components/fat-activeInvestments-page/types';
import { Investment } from '@/components/fat-dealCatalog-page/types';
import Header from '@/components/fat-header';
import { AdditionalOptionWrap, GoBackButton, GoBackButtonWrap } from '@/components/fat-header/goBackButton';
import { PageTitle } from '@/components/fat-header/pageTitle';
import { LoaderOnWholeScreen } from '@/components/loaders/loader-on-whole-screen';
import { SORT_ORDER } from '@/components/table/types';
import { useResponsive } from '@/hooks/use-responsive';
import { useClickOutside } from '@/hooks/useClickOutside';
import { useGoBack } from '@/hooks/useGoBack';
import { MainWrap, PaddingWrap } from '@/styles/common';
import guid from '@/utils/guid';
import { useQuery } from '@apollo/client';
import { ChangeEvent, useRef, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';
import { Details } from './details';
import { INVESTMENT_DETAILS_QUERY, MANAGER_INVESTMENTS_MIN_QUERY } from './queries';
import { UpdatesTab } from './updatesTab';

export const ManagerUpdateDetails = () => {
  const { isMobile } = useResponsive();
  const navigate = useNavigate();
  const goBack = useGoBack();
  const theme = useTheme();
  const params = useParams();

  const [searchParams, setSearchParams] = useSearchParams();

  const backToTitle = searchParams.get('backToTitle') || '';
  const activeTab = searchParams.get('tab') || 'Updates';

  const [tabs, setTabs] = useState<ITab[]>([
    { value: 'Investors', amount: null, disabled: true },
    { value: 'Updates', amount: null }
  ]);
  const [isTabMenuOpen, setIsTabMenuOpen] = useState(false);
  const [initialStrategiesList, setInitialStrategiesList] = useState<IManagerInvestment[]>([]);
  const [strategiesList, setStrategiesList] = useState<IManagerInvestment[]>([]);
  const [isOpen, setIsOpen] = useState(false);

  const [managerId, setManagerId] = useState<string | null>(null);
  const [search, setSearch] = useState('');

  const ref = useRef<HTMLDivElement | null>(null);
  useClickOutside(ref, () => setIsOpen(false));

  useQuery<{ ManagerInvestments: IManagerInvestments }>(MANAGER_INVESTMENTS_MIN_QUERY, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
    skip: !managerId,
    variables: {
      managerId: managerId,
      sort: 'name',
      order: SORT_ORDER.ASC,
      rowsPerPage: 1000,
      page: 1
    },
    onCompleted: (data) => {
      const { investments: listInvestments } = data.ManagerInvestments;
      setStrategiesList(listInvestments);
      setInitialStrategiesList(listInvestments);
    },
    onError: (error) => {
      console.error(error);
    }
  });

  const { data: investmentData, loading: loadingInvestment } = useQuery<{ Investment: Investment }>(INVESTMENT_DETAILS_QUERY, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
    variables: { id: params?.strategyId },
    skip: !params?.strategyId,
    onCompleted: (data) => {
      setManagerId(data.Investment?.managerId ?? null);
    },
    onError: (error) => {
      console.error(error);
    }
  });

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (initialStrategiesList) {
      const value = event.target.value;
      setSearch(value);
      const newList = [...initialStrategiesList];
      const filteredList = newList.filter((item) => item.name.toLowerCase().indexOf(event.target.value.toLowerCase()) !== -1);
      if (value === '' && filteredList.length === 0) {
        setStrategiesList(initialStrategiesList);
      } else {
        setStrategiesList(filteredList);
      }
    }
  };

  const handleSelectCLick = (event: any) => {
    if (event.target.id !== 'input') {
      setIsOpen(!isOpen);
    }
  };

  const onItemClick = (id: string) => {
    navigate(`/managerUpdates/${id}?backToTitle=${backToTitle}`, { replace: true });
  };

  const onChangeActiveTab = (value: string) => {
    setSearchParams(
      (prev) => {
        prev.set('tab', value);
        return prev;
      },
      { replace: true }
    );
  };

  const openModifyInvestmentPage = (id?: string) => {
    navigate(`/dealPipeline/modify?backToTitle=Manager Update Details&investment=${id}`, {
      state: {
        investment: id
      }
    });
  };

  if (loadingInvestment) {
    return <LoaderOnWholeScreen />;
  }

  return (
    <>
      <MainWrap>
        <Header
          modalControl={
            <GoBackButtonWrap>
              <GoBackButton
                handleClose={() => goBack({ fallBack: '/managerUpdates' })}
                backToTitle={backToTitle}
                withAdditionalOption={!!initialStrategiesList.length}
              />
              {initialStrategiesList.length ? (
                <AdditionalOptionWrap ref={ref} onClick={(event) => handleSelectCLick(event)}>
                  <ArrowDown fill={theme.font.action} />
                  <SelectWrap isMobile={isMobile} openZIndex={10} onClick={(event) => handleSelectCLick(event)} width={'200px'} isOpen={isOpen}>
                    <SearchWrap id="input">
                      <SearchInput id="input" placeholder={'Type Here To Filter Results'} value={search} onChange={onChange} />
                      <Divider />
                    </SearchWrap>
                    <>
                      <SelectItem key={guid()} onClick={() => {}}>
                        {investmentData?.Investment?.name ?? ''}
                      </SelectItem>
                    </>
                    {strategiesList?.map((el) => (
                      <SelectItem isActive={el.id === params?.strategyId} key={guid()} onClick={() => onItemClick(el.id)}>
                        {'- ' + el.name}
                      </SelectItem>
                    ))}
                  </SelectWrap>
                </AdditionalOptionWrap>
              ) : (
                <></>
              )}
            </GoBackButtonWrap>
          }
        />
        <PageTitle title={investmentData?.Investment?.name ?? ''} onEditClick={() => openModifyInvestmentPage(investmentData?.Investment.id ?? '')} />
        <Details
          data={investmentData?.Investment || null}
          // tabs={tabs}
          // activeTab={activeTab}
          // setActiveTab={onChangeActiveTab}
          // isTabMenuOpen={isTabMenuOpen}
          // setIsTabMenuOpen={setIsTabMenuOpen}
        />
      </MainWrap>
      <MainWrap>
        <PaddingWrap>
          <UpdatesTab managerId={investmentData?.Investment?.managerId || null} backToTitle="Manager Update Details" />
        </PaddingWrap>
      </MainWrap>
    </>
  );
};

const SelectWrap = styled(FilterItems)<{ isMobile: boolean; isOpen: boolean; width: string; openZIndex: number }>`
  width: 245px;
  top: 70px;
  left: ${({ isMobile }) => (isMobile ? '100px' : '130px')};
  overflow-x: hidden;
`;

const SelectItem = styled(PrimaryFilterItem)`
  padding: 11px 9.5px;
  font-size: 16px;
  font-weight: 600;
  color: ${({ theme }) => theme.action.primary};
`;
