import { useMutation } from '@apollo/client';
import { useNavigate, useLocation } from 'react-router-dom';
import { DeleteBucketIcon } from '@/assets/static/icons/delete-bucket';
import ModalWrappedContent from '@/components/basicComponents/modal/modalWrappedContent';
import {
  DELETE_DUE_DILIGENCE_FUNNEL,
  DUE_DILIGENCE_FUNNEL_LIST_SETTINGS_QUERY
} from '@/components/settings-page/queries';
import { SORT_ORDER } from '@/components/table/types';
import styled from 'styled-components';

interface DeleteDraftProps {
  onClose: () => void;
  closeAll: () => void;
  isOpen: boolean;
}

const DeleteDraft = ({ onClose, isOpen, closeAll }: DeleteDraftProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const [deleteDueDiligenceFunnel] = useMutation(DELETE_DUE_DILIGENCE_FUNNEL, {
    refetchQueries: () => [
      {
        query: DUE_DILIGENCE_FUNNEL_LIST_SETTINGS_QUERY,
        variables: {
          data: {
            statusFilter: ['PUBLISHED', 'DRAFT_WITHOUT_PUBLISHED'],
            sortDirection: SORT_ORDER.ASC,
            sortBy: 'ORDER'
          },
          archivedFunnelData: {
            sortDirection: SORT_ORDER.ASC,
            sortBy: 'ORDER'
          }
        }
      }
    ]
  });

  const saveValue = () => {
    deleteDueDiligenceFunnel({
      variables: {
        id: queryParams.get('funnelId')
      },
      onCompleted: () => {
        closeAll();
      }
    });
  };

  return (
    <ModalWrappedContent
      isOpen={isOpen}
      onClose={onClose}
      confirmButton={{ name: 'Confirm', onClick: saveValue }}
    >
      <ModalContainer>
        <DeleteBucketIcon width={46} height={60} />
        <ModalTitle>Please confirm you want to delete this Draft.</ModalTitle>
        <ModalDescription>
          This action will delete this unpublished funnel version. This cannot
          be undone.
        </ModalDescription>
      </ModalContainer>
    </ModalWrappedContent>
  );
};

export default DeleteDraft;

const ModalContainer = styled.div`
  font-weight: 400;
  padding: 10px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;

const ModalTitle = styled.div`
  font-size: 33px;
  font-weight: 400;
  text-align: center;
  color: #2e2e2e;
`;

const ModalDescription = styled.div`
  font-size: 16px;
  line-height: 27px;
  text-align: center;
  color: #373f4e;
  font-weight: 400;
`;
