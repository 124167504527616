import styled from 'styled-components';

const TIME = 250;

export const FadeAnimation = styled.div<{
  isOpen: boolean;
  opacityTime?: number;
  removepointerevents?: boolean;
}>`
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
  pointer-events: ${({ isOpen, removepointerevents }) =>
    isOpen || removepointerevents ? 'auto' : 'none'};
  transition:
    opacity ${({ opacityTime }) => opacityTime ?? TIME}ms ease-in,
    background ${TIME}ms ease-in,
    height ${TIME}ms ease-in,
    max-height ${TIME}ms ease-in;
`;
