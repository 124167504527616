import FormattingTooltip from '@/components/basicComponents/tooltip/dollarsTooltip';
import { IInvestmentEntity } from '@/components/fat-dealCatalog-page/fat-seekingInterest-page/types';
import { MobileRow, MobileRowTitle, MobileRowValue, MobileTableRowWrap } from '@/components/table/mobileTable/mobileTable';
import styled from 'styled-components';
import { investorColumns } from '../constatnts';

interface MobileSumFooterProps {
  listInvestmentEntity: IInvestmentEntity[];
}

const MobileSumFooterInvestor = ({ listInvestmentEntity }: MobileSumFooterProps) => {
  const total = listInvestmentEntity.reduce<{
    estimatedCommitment: number;
    taxableEstimate: number;
    exemptEstimate: number;
  }>(
    (sum, value: IInvestmentEntity) => {
      return {
        estimatedCommitment: (sum.estimatedCommitment += Number(value.investor_interest[0]?.amount)),
        taxableEstimate: !value.investor_interest[0]?.exempt
          ? (sum.taxableEstimate += Number(value.investor_interest[0]?.amount))
          : (sum.taxableEstimate += 0),
        exemptEstimate: value.investor_interest[0]?.exempt
          ? (sum.exemptEstimate += Number(value.investor_interest[0]?.amount))
          : (sum.exemptEstimate += 0)
      };
    },
    { estimatedCommitment: 0, taxableEstimate: 0, exemptEstimate: 0 }
  );

  return (
    <>
      <MobileSumFooterWrap>
        <MobileRow>
          <MobileRowTitle>Total</MobileRowTitle>
        </MobileRow>

        <MobileRow>
          <MobileRowTitle>{investorColumns[3].title}</MobileRowTitle>
          <MobileRowValue>
            <FormattingTooltip zIndex={1000}>{total.taxableEstimate}</FormattingTooltip>
          </MobileRowValue>
        </MobileRow>

        <MobileRow>
          <MobileRowTitle>{investorColumns[4].title}</MobileRowTitle>
          <MobileRowValue>
            <FormattingTooltip zIndex={1000}>{total.exemptEstimate}</FormattingTooltip>
          </MobileRowValue>
        </MobileRow>

        <MobileRow>
          <MobileRowTitle>{investorColumns[5].title}</MobileRowTitle>
          <MobileRowValue>
            <FormattingTooltip zIndex={1000}>{total.estimatedCommitment}</FormattingTooltip>
          </MobileRowValue>
        </MobileRow>
      </MobileSumFooterWrap>
    </>
  );
};

const MobileSumFooterWrap = styled(MobileTableRowWrap)`
  margin-top: 6px;
  background-color: ${({ theme }) => theme.layer[2]};
`;

export default MobileSumFooterInvestor;
