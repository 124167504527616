import { Dispatch, SetStateAction, useState } from 'react';

import FilterSearch from '@/components/basicComponents/input';
import ModalWrappedContent from '@/components/basicComponents/modal/modalWrappedContent';
import { IListData } from '@/components/fat-dealPipeline-page/fat-modify/types';
import styled from 'styled-components';

interface CreateStrategyProps {
  onClose: () => void;
  isOpen: boolean;
  setStrategies: Dispatch<SetStateAction<IListData>>;
}

const CreateStrategy = ({ onClose, isOpen, setStrategies }: CreateStrategyProps) => {
  const [value, setValue] = useState('');

  const saveValue = () => {
    setStrategies((prevStrategies) => {
      if (!prevStrategies.list.includes(value)) {
        return { ...prevStrategies, list: [...prevStrategies.list, value] };
      }
      return prevStrategies;
    });

    setStrategies((prevStrategies) => {
      if (!prevStrategies.list.length) {
        return {
          ...prevStrategies,
          chosen: [...new Set([...prevStrategies.chosen, value])]
        };
      }

      let duplicateTag = '';

      const updatedTags = prevStrategies.chosen.map((tag) => {
        if (tag === 'None') {
          return value;
        }
        if (tag === value) {
          duplicateTag = value;
          return value;
        }
        return tag;
      });

      return { ...prevStrategies, chosen: updatedTags };
    });

    setTimeout(() => {
      setStrategies((prevStrategies) => {
        return {
          ...prevStrategies,
          duplicate: '',
          chosen: [...new Set(prevStrategies.chosen)]
        };
      });
    }, 1000);
    onClose();
  };

  return (
    <ModalWrappedContent
      isOpen={isOpen}
      onClose={onClose}
      confirmButton={{
        name: 'save',
        onClick: saveValue,
        disabled: !value.length
      }}
      showRequiredFields
    >
      <ModalContainer>
        <ModalTitel>Create New Strategy</ModalTitel>
        <ModalDescription>Use strategies to categorize investments by sector or other dimensions useful for investor allocation.</ModalDescription>
      </ModalContainer>
      <ManagerInputWrap>
        <CustomManagerInput
          label="Strategy"
          placeholder="Provide a strategy"
          type="text"
          value={value}
          onChange={setValue}
          showSearchBtn={false}
          width="100%"
          required
          autoFocus
        />
      </ManagerInputWrap>
    </ModalWrappedContent>
  );
};

export default CreateStrategy;

const ModalContainer = styled.div`
  font-weight: 400;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const ModalTitel = styled.div`
  font-size: 33px;
  text-align: center;
  color: #2e2e2e;
`;

const ModalDescription = styled.div`
  font-size: 19px;
  line-height: 27px;
  text-align: center;
  color: #373f4e;
`;

const CustomManagerInput = styled(FilterSearch)`
  font-weight: 300;
  font-size: 19px;
  padding: 12px;
  border-radius: 5px;
  color: #373f4e;
`;

const ManagerInputWrap = styled.div`
  padding-top: 35px;
`;
