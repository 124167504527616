import * as React from 'react';

export const DealPipelineMenuIcon = ({ ...props }: React.SVGProps<SVGSVGElement>) => (
  <svg
    style={props.style}
    width={props.width || '26'}
    height={props.height || '26'}
    fill={props.fill || 'none'}
    stroke={props.stroke || undefined}
    viewBox="0 0 28 28"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_683_12241)">
      <path
        d="M17.8292 1.3133e-05C18.1559 0.134536 18.2808 0.369951 18.2712 0.725476C18.252 1.50859 18.2664 2.29171 18.2664 3.11806C18.3865 3.11806 18.4922 3.11806 18.5979 3.11806C21.2211 3.11806 23.8491 3.11806 26.4723 3.11806C27.1785 3.11806 27.5533 3.488 27.5581 4.19425C27.5581 7.40839 27.5581 10.6273 27.5581 13.8415C27.5581 14.6054 27.1785 14.9753 26.4146 14.9801C25.8093 14.9801 25.1991 14.9801 24.5505 14.9801C24.7091 15.3597 24.8532 15.696 24.9877 16.0419C25.0117 16.1091 24.9925 16.1908 24.9973 16.2677C24.9205 16.2581 24.834 16.2725 24.7715 16.2389C23.3831 15.5566 21.9994 14.8648 20.6157 14.1778C20.5629 14.1489 20.5148 14.1105 20.4284 14.0433C20.5244 13.9712 20.5917 13.8991 20.6686 13.8607C22.0378 13.1833 23.4023 12.5058 24.7763 11.8332C24.8388 11.8044 24.9253 11.814 24.9973 11.8044C24.9973 11.8813 25.0117 11.963 24.9877 12.0302C24.8532 12.3857 24.7043 12.7317 24.5505 13.1016H25.6651V4.99178H18.2712C18.2712 5.08306 18.2712 5.17915 18.2712 5.27524C18.2712 5.9959 18.2568 6.71655 18.2808 7.43241C18.2952 7.79754 18.0454 8.10022 17.6418 8.10022C15.3645 8.08581 13.0872 8.09542 10.8052 8.09061C10.3776 8.09061 10.1806 7.88402 10.1758 7.45163C10.1758 5.18395 10.1758 2.91147 10.1758 0.643802C10.1758 0.312299 10.3487 0.129732 10.6178 -0.00479126H17.8388L17.8292 1.3133e-05ZM16.4023 1.88334H12.0495V6.2169H16.4023V1.88334Z"
        fill={props.fill || 'white'}
      />
      <path
        d="M3.45954 24.9444C2.80614 24.9444 2.18638 24.9204 1.57142 24.9492C0.807517 24.9924 0.442383 24.4832 0.442383 23.8346C0.451992 20.6349 0.442383 17.4351 0.442383 14.2402C0.442383 13.9039 0.485622 13.5628 0.77869 13.361C0.951648 13.2409 1.18706 13.1352 1.39365 13.1352C2.84458 13.116 4.2907 13.1208 5.74163 13.1208C7.20697 13.1208 8.67231 13.1208 10.1569 13.1208C10.1617 13.0199 10.1713 12.943 10.1713 12.8662C10.1713 12.1167 10.1713 11.372 10.1713 10.6225C10.1713 10.1757 10.3827 9.9595 10.8151 9.9595C13.0827 9.9595 15.3552 9.9595 17.6229 9.9595C18.0601 9.9595 18.2619 10.1661 18.2619 10.6177C18.2619 12.8854 18.2619 15.1579 18.2619 17.4255C18.2619 17.8339 18.0505 18.0501 17.6469 18.0549C15.36 18.0549 13.0731 18.0549 10.7814 18.0549C10.3779 18.0549 10.1665 17.8339 10.1665 17.4207C10.1665 16.7097 10.1665 15.9986 10.1665 15.2876C10.1665 15.1963 10.1665 15.1098 10.1665 15.0041H2.33051V23.0755H3.44993C3.301 22.72 3.16167 22.3885 3.02715 22.0522C2.99832 21.9753 3.01273 21.884 3.00312 21.8023C3.0896 21.8119 3.18089 21.8023 3.25295 21.836C4.60779 22.5038 5.96263 23.1716 7.31266 23.849C7.39434 23.8922 7.4568 23.9691 7.52886 24.0316C7.4616 24.0892 7.40875 24.1661 7.33188 24.2045C5.98185 24.8771 4.62701 25.545 3.27217 26.208C3.1953 26.2464 3.09441 26.2416 3.00312 26.256C3.01754 26.1647 3.01273 26.0638 3.04636 25.9822C3.17608 25.6458 3.31541 25.3143 3.45954 24.954V24.9444ZM12.0546 11.8236V16.1668H16.3978V11.8236H12.0546Z"
        fill={props.fill || 'white'}
      />
      <path
        d="M18.2712 23.9691C18.2712 25.0885 18.2712 26.2079 18.2712 27.3274C18.2712 27.7838 18.0646 27.9952 17.6034 27.9952C15.3549 27.9952 13.1017 27.9952 10.8532 27.9952C10.3872 27.9952 10.1758 27.7742 10.1758 27.3034C10.1758 25.0741 10.1758 22.8401 10.1758 20.6108C10.1758 20.1016 10.3728 19.8998 10.8772 19.8998C13.1065 19.8998 15.3405 19.8998 17.5697 19.8998C18.079 19.8998 18.2664 20.092 18.2712 20.6108C18.2712 21.7303 18.2712 22.8497 18.2712 23.9691ZM16.3927 26.1263V21.7831H12.0447V26.1263H16.3927Z"
        fill={props.fill || 'white'}
      />
    </g>
    <defs>
      <clipPath id="clip0_683_12241">
        <rect width="27.116" height="28" fill="white" transform="translate(0.442383)" />
      </clipPath>
    </defs>
  </svg>
);
