import * as React from 'react';

export const InvestorsMenuIcon = ({ ...props }: React.SVGProps<SVGSVGElement>) => (
  <svg
    style={props.style}
    width={props.width || '28'}
    height={props.height || '28'}
    viewBox="0 0 28 28"
    fill={props.fill || 'none'}
    stroke={props.stroke || undefined}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.9694 6.8738C14.4154 6.8738 14.8514 7.00606 15.2222 7.25383C15.593 7.50161 15.8821 7.85377 16.0527 8.26581C16.2234 8.67784 16.2681 9.13125 16.181 9.56867C16.094 10.0061 15.8793 10.4079 15.5639 10.7232C15.2486 11.0386 14.8468 11.2533 14.4093 11.3403C13.9719 11.4273 13.5185 11.3827 13.1065 11.212C12.6945 11.0414 12.3423 10.7523 12.0945 10.3815C11.8468 10.0107 11.7145 9.57472 11.7145 9.12873C11.7145 8.53069 11.9521 7.95716 12.375 7.53428C12.7978 7.1114 13.3714 6.8738 13.9694 6.8738ZM13.9694 5.54163C13.26 5.54163 12.5664 5.752 11.9765 6.14615C11.3866 6.54031 10.9269 7.10056 10.6554 7.75601C10.3839 8.41147 10.3128 9.13273 10.4513 9.82856C10.5897 10.5244 10.9313 11.1636 11.433 11.6652C11.9346 12.1669 12.5738 12.5085 13.2696 12.6469C13.9655 12.7853 14.6867 12.7143 15.3422 12.4428C15.9976 12.1713 16.5579 11.7115 16.952 11.1216C17.3462 10.5317 17.5565 9.8382 17.5565 9.12873C17.5565 8.17737 17.1786 7.26499 16.5059 6.59228C15.8332 5.91956 14.9208 5.54163 13.9694 5.54163Z"
      fill={props.fill || '#FAFAFA'}
    />
    <path
      d="M18.3994 22.4567C18.3123 22.4567 18.2259 22.4394 18.1455 22.4059C18.065 22.3723 17.992 22.3232 17.9306 22.2612C17.8692 22.1993 17.8207 22.1258 17.7879 22.045C17.7551 21.9642 17.7387 21.8778 17.7395 21.7906V17.2563C17.7379 16.4805 17.4289 15.7369 16.8803 15.1883C16.3318 14.6397 15.5882 14.3308 14.8123 14.3292H13.1624C12.3871 14.3308 11.6442 14.6399 11.0965 15.1887C10.5489 15.7374 10.2414 16.481 10.2414 17.2563V21.7784C10.2414 21.955 10.1712 22.1245 10.0463 22.2494C9.92135 22.3743 9.75193 22.4445 9.57527 22.4445C9.39861 22.4445 9.22918 22.3743 9.10427 22.2494C8.97935 22.1245 8.90918 21.955 8.90918 21.7784V17.2563C8.90918 16.1218 9.35987 15.0338 10.1621 14.2316C10.9643 13.4293 12.0523 12.9786 13.1868 12.9786H14.8368C15.9713 12.9786 17.0593 13.4293 17.8615 14.2316C18.6637 15.0338 19.1144 16.1218 19.1144 17.2563V21.7784C19.1164 21.8711 19.0989 21.9633 19.0632 22.0489C19.0275 22.1346 18.9742 22.2118 18.9069 22.2756C18.8396 22.3395 18.7597 22.3886 18.6723 22.4198C18.5849 22.4509 18.492 22.4635 18.3994 22.4567Z"
      fill={props.fill || '#FAFAFA'}
    />
    <path
      d="M6.69119 9.25097C7.03807 9.25097 7.37715 9.35384 7.66557 9.54655C7.95398 9.73927 8.17879 10.0132 8.31153 10.3337C8.44428 10.6541 8.479 11.0068 8.41132 11.347C8.34365 11.6872 8.17661 11.9997 7.93133 12.245C7.68606 12.4902 7.37356 12.6573 7.03334 12.7249C6.69313 12.7926 6.3405 12.7579 6.02003 12.6251C5.69956 12.4924 5.42564 12.2676 5.23293 11.9792C5.04021 11.6908 4.93736 11.3517 4.93736 11.0048C4.93736 10.5397 5.12214 10.0935 5.45105 9.76464C5.77996 9.43573 6.22605 9.25097 6.69119 9.25097ZM6.69119 8.36488C6.16906 8.36488 5.65867 8.51973 5.22454 8.8098C4.7904 9.09988 4.45204 9.51216 4.25223 9.99454C4.05242 10.4769 4.00013 11.0077 4.10199 11.5198C4.20386 12.0319 4.4553 12.5023 4.8245 12.8715C5.19369 13.2407 5.66408 13.4921 6.17618 13.594C6.68827 13.6959 7.21907 13.6436 7.70145 13.4438C8.18383 13.2439 8.59613 12.9056 8.8862 12.4715C9.17628 12.0373 9.33111 11.5269 9.33111 11.0048C9.33192 10.6579 9.26418 10.3143 9.13179 9.99361C8.99941 9.67296 8.805 9.38161 8.5597 9.13631C8.3144 8.89101 8.02305 8.69658 7.7024 8.5642C7.38175 8.43181 7.0381 8.36407 6.69119 8.36488Z"
      fill={props.fill || '#FAFAFA'}
    />
    <path
      d="M3.43999 20.77C3.3233 20.77 3.2114 20.7237 3.12888 20.6412C3.04637 20.5587 3 20.4468 3 20.3301V16.9874C2.99998 16.5835 3.08002 16.1837 3.23548 15.811C3.39094 15.4383 3.61874 15.1001 3.90571 14.816C4.19268 14.5319 4.53313 14.3074 4.90737 14.1557C5.28161 14.004 5.68221 13.9279 6.08603 13.9319H7.30821C7.76917 13.93 8.2246 14.0324 8.64039 14.2314C8.73557 14.2887 8.80596 14.3794 8.83777 14.4859C8.86958 14.5923 8.86052 14.7068 8.81237 14.8069C8.76422 14.9071 8.68044 14.9856 8.57743 15.0272C8.47442 15.0688 8.35958 15.0705 8.2554 15.0319C7.95981 14.8902 7.63602 14.817 7.30821 14.818H6.08603C5.50257 14.818 4.943 15.0498 4.53043 15.4624C4.11787 15.8749 3.88609 16.4345 3.88609 17.018V20.3606C3.87687 20.4725 3.82583 20.5767 3.74315 20.6526C3.66047 20.7285 3.55221 20.7704 3.43999 20.77Z"
      fill={props.fill || '#FAFAFA'}
    />
    <path
      d="M21.3025 9.25097C21.6494 9.25097 21.9885 9.35384 22.2769 9.54655C22.5653 9.73927 22.7901 10.0132 22.9228 10.3337C23.0556 10.6541 23.0903 11.0068 23.0227 11.347C22.955 11.6872 22.7879 11.9997 22.5427 12.245C22.2974 12.4902 21.9849 12.6573 21.6447 12.7249C21.3045 12.7926 20.9518 12.7579 20.6314 12.6251C20.3109 12.4924 20.037 12.2676 19.8443 11.9792C19.6515 11.6908 19.5487 11.3517 19.5487 11.0048C19.5487 10.7745 19.5941 10.5464 19.6822 10.3337C19.7703 10.1209 19.8995 9.92749 20.0624 9.76464C20.2252 9.60178 20.4186 9.47259 20.6314 9.38446C20.8441 9.29632 21.0722 9.25097 21.3025 9.25097ZM21.3025 8.36488C20.7804 8.36488 20.27 8.51973 19.8359 8.8098C19.4017 9.09988 19.0634 9.51216 18.8636 9.99454C18.6637 10.4769 18.6115 11.0077 18.7133 11.5198C18.8152 12.0319 19.0666 12.5023 19.4358 12.8715C19.805 13.2407 20.2754 13.4921 20.7875 13.594C21.2996 13.6959 21.8304 13.6436 22.3128 13.4438C22.7952 13.2439 23.2075 12.9056 23.4975 12.4715C23.7876 12.0373 23.9424 11.5269 23.9424 11.0048C23.9432 10.6579 23.8755 10.3143 23.7431 9.99361C23.6107 9.67296 23.4163 9.38161 23.171 9.13631C22.9257 8.89101 22.6344 8.69658 22.3137 8.5642C21.9931 8.43181 21.6494 8.36407 21.3025 8.36488Z"
      fill={props.fill || '#FAFAFA'}
    />
    <path
      d="M24.5536 20.7701C24.4363 20.7701 24.3238 20.7239 24.2403 20.6416C24.1568 20.5592 24.1091 20.4473 24.1075 20.3301V16.9874C24.1075 16.4039 23.8757 15.8444 23.4631 15.4318C23.0506 15.0193 22.491 14.7875 21.9075 14.7875H20.6853C20.3575 14.7865 20.0337 14.8596 19.7382 15.0014C19.685 15.0334 19.6257 15.054 19.5641 15.0617C19.5024 15.0695 19.4399 15.0644 19.3804 15.0466C19.3209 15.0288 19.2658 14.9988 19.2185 14.9585C19.1713 14.9182 19.133 14.8685 19.1061 14.8125C19.0792 14.7566 19.0642 14.6956 19.0622 14.6335C19.0602 14.5715 19.0712 14.5096 19.0945 14.4521C19.1177 14.3945 19.1528 14.3424 19.1973 14.2991C19.2419 14.2559 19.2949 14.2224 19.3532 14.2008C19.769 14.0018 20.2244 13.8994 20.6853 13.9014H21.9075C22.7213 13.9013 23.5023 14.2221 24.0811 14.7941C24.66 15.366 24.99 16.1431 24.9997 16.9569V20.2995C25.003 20.3602 24.9939 20.4208 24.973 20.4778C24.9521 20.5347 24.9197 20.5869 24.878 20.6309C24.8362 20.675 24.7859 20.71 24.7301 20.734C24.6743 20.7579 24.6143 20.7702 24.5536 20.7701Z"
      fill={props.fill || '#FAFAFA'}
    />
  </svg>
);
