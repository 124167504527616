import { useStatusData } from '@/hooks/useStatusData';
import type { FC, ReactNode } from 'react';
import { Maintenance } from '.';

interface MaintenanceModeProps {
  children: ReactNode;
}

export const MaintenanceMode: FC<MaintenanceModeProps> = (props) => {
  const { children } = props;
  const { data, loading, error } = useStatusData();

  if (loading || error) {
    return null;
  }

  if (data.maintenance_mode) {
    return <Maintenance />;
  }

  return <>{children}</>;
};
