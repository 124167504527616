import lodash from 'lodash';
import styled, { useTheme } from 'styled-components';

import { WrongSuitabilityIcon } from '@/assets/icons/suitability/false';
import { GoodSuitabilityIcon } from '@/assets/icons/suitability/true';
import guid from '../../../utils/guid';
import { SuitabilityCriterion } from '../../allocations-page/types';

type SuitabilityDetails = {
  data: SuitabilityCriterion[];
};

const SuitabilityDetails = ({ data }: SuitabilityDetails) => {
  const theme = useTheme();

  return (
    <SuitabilityDetailsWrap>
      {data.map((suitability: SuitabilityCriterion) => (
        <SuitabilityDetailsItem key={guid()}>
          <ItemTitle>{lodash.startCase(suitability.label).replace('Pending', '(Pending)')}</ItemTitle>
          {suitability.value ? <GoodSuitabilityIcon fill={theme.context.success} /> : <WrongSuitabilityIcon fill={theme.context.error} />}
        </SuitabilityDetailsItem>
      ))}
    </SuitabilityDetailsWrap>
  );
};

const ItemTitle = styled.div`
  font-family: Blinker, serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0;
  color: ${({ theme }) => theme.font.base};
`;

const SuitabilityDetailsItem = styled.div`
  background-color: ${({ theme }) => theme.layer[1]};
  display: flex;
  justify-content: space-between;
  padding: 5px 22px 5px 10px;
`;

const SuitabilityDetailsWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3px;
`;

export const SuitabilityWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export default SuitabilityDetails;
