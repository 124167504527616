import MuiAlert, { AlertProps } from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import React, { Dispatch, SetStateAction } from 'react';

interface SnackbarAlertProps {
  children: React.ReactNode;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  logo: string;
  nodeElementToClose?: React.ReactNode;
  type: 'error' | 'success';
  width?: string;
  delayToClose?: number; // in milliseconds
  className?: string;
}
const stylesToTypeOfSnackbar = {
  error: {
    border: '3px solid #ED9DAB',
    boxShadow: '0px 4px 4px #FFDFE5',
    background: '#FFDFE5'
  },
  success: {
    background: 'rgba(175, 254, 202, 0.9)',
    border: '3px solid #84C59D'
  }
};

const SnackbarAlert: React.FC<SnackbarAlertProps> = ({
  children,
  open,
  setOpen,
  logo,
  nodeElementToClose = '',
  type,
  delayToClose = 5000,
  width = '100%',
  className
}) => {
  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === 'clickaway') return;
    setOpen(false);
  };
  return (
    <Stack spacing={2} sx={{ width: '100%' }}>
      <Snackbar
        open={open}
        autoHideDuration={delayToClose}
        anchorOrigin={{
          horizontal: 'center',
          vertical: 'top'
        }}
        sx={{ width }}
        onClose={handleClose}
      >
        <CustomAlert
          icon={<img src={logo} alt={'Warning'} />}
          action={nodeElementToClose}
          onClose={handleClose}
          severity="error"
          styles={stylesToTypeOfSnackbar[type]}
          className={className}
        >
          {children}
        </CustomAlert>
      </Snackbar>
    </Stack>
  );
};
export default SnackbarAlert;
const Alert = React.forwardRef<HTMLDivElement, AlertProps>(
  function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  }
);
const CustomAlert = styled(Alert)<{ styles: { [key: string]: string } }>(
  ({ styles }) => ({
    width: '70%',
    display: 'flex',
    alignItems: 'center',
    borderRadius: 8,
    color: '#2A2E3F',
    ...styles
  })
);
