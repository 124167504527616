import * as React from 'react';

export const TerminatedIcon = ({ ...props }: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width={props.width || '22'} height={props.height || '22'} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.8506 6.59868L11.0066 9.44273L8.15598 6.59868L6.60529 8.17578L9.45592 11.0198L6.60529 13.8704L8.15598 15.4211L11.0066 12.5705L13.8506 15.4211L15.4013 13.8704L12.5573 11.0198L15.4013 8.17578L13.8506 6.59868ZM11.0066 1.9829e-06C8.83044 -0.00130306 6.7028 0.642894 4.89285 1.85109C3.0829 3.05928 1.67197 4.77719 0.838592 6.78745C0.00521075 8.79771 -0.213182 11.01 0.211045 13.1444C0.635272 15.2788 1.68306 17.2394 3.22183 18.7782C4.76061 20.317 6.72124 21.3647 8.85564 21.789C10.99 22.2132 13.2023 21.9948 15.2126 21.1614C17.2228 20.328 18.9407 18.9171 20.1489 17.1072C21.3571 15.2972 22.0013 13.1696 22 10.9934C22.0009 9.54949 21.7171 8.11957 21.165 6.7854C20.6128 5.45123 19.8031 4.23897 18.7821 3.21797C17.7611 2.19696 16.5488 1.38723 15.2146 0.835067C13.8805 0.282904 12.4505 -0.000865496 11.0066 1.9829e-06ZM11.0066 19.796C9.26532 19.7973 7.56277 19.2822 6.11431 18.3158C4.66586 17.3493 3.53659 15.975 2.86933 14.3666C2.20206 12.7583 2.0268 10.9881 2.36571 9.28016C2.70461 7.57218 3.54246 6.00307 4.77327 4.77134C6.00408 3.53961 7.57254 2.70059 9.28026 2.36041C10.988 2.02022 12.7582 2.19415 14.3671 2.8602C15.976 3.52626 17.3511 4.65452 18.3187 6.10225C19.2862 7.54998 19.8026 9.25213 19.8026 10.9934C19.7992 13.3258 18.8715 15.5618 17.2229 17.2116C15.5742 18.8615 13.339 19.7908 11.0066 19.796Z"
        fill={props.fill || '#ABABAB'}
      />
    </svg>
  );
};
