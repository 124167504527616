import { useMemo } from 'react';
import styled from 'styled-components';

import { Loader } from '@/components/loaders/loader';
import TableHeader from '@/components/table/fat-tableHeader';
import { TableBodyWrapper, TableWrapper } from '@/components/table/styles';
import TablePagination from '@/components/table/tablePagination';
import { ISort } from '@/components/table/types';
import { useResponsive } from '@/hooks/use-responsive';
import { clientColumns, entityColumns } from '../constants';
import { IClientsQuery, IHandleEntityClick, IRowData } from '../types';
import { default as MobileTableClient } from './mobileTable/mobileTableClient';
import MobileTableEntity from './mobileTable/mobileTableEntity';
import { TableBodyClient } from './tableBodyClient';
import { TableBodyEntity } from './tableBodyEntity';

export type AdvisorTableProps = {
  advisorFilter: string;
  handleFamilyClick: (id: string) => void;
  paginationSaver: (limit: number, offset: number) => void;
  savedSort?: ISort;
  savedSetSort: (value: ISort) => void;
  loading: boolean;
  dataClients: IClientsQuery | undefined;
  refetch: any;
  onRowClick: (data: IRowData) => void;
  viewByValue: string;
  handleEntityClick: ({ familyId, entityId, entityName }: IHandleEntityClick) => void;
};

const Table = ({
  dataClients,
  loading,
  paginationSaver,
  handleFamilyClick,
  savedSort,
  savedSetSort,
  refetch,
  onRowClick,
  viewByValue,
  handleEntityClick
}: AdvisorTableProps) => {
  const { isMobile, isTablet } = useResponsive();
  const paginationValuesFromBE = useMemo(() => {
    if (!dataClients?.ClientsEntities) return;
    if (viewByValue === 'Entities') {
      const { limit, offset, totalEntities } = dataClients.ClientsEntities;
      return { limit, offset, total: totalEntities };
    } else {
      const { limit, offset, total } = dataClients.ClientsEntities;
      return { limit, offset, total };
    }
  }, [dataClients, viewByValue]);

  const TableBody = () => {
    switch (viewByValue) {
      case 'Clients':
        return isMobile ? (
          <>
            {loading ? (
              <Loader />
            ) : (
              <MobileTableClient
                onRowClick={onRowClick}
                columns={clientColumns}
                handleFamilyClick={handleFamilyClick}
                clientsRows={dataClients?.ClientsEntities.clients || []}
              />
            )}
          </>
        ) : (
          <TableWrapper padding="0">
            <CustomTableHeader isTablet={isTablet} savedSort={savedSort} savedSetSort={savedSetSort} columns={clientColumns} refetch={refetch} />
            <TableBodyWrapper>
              {loading ? (
                <Loader />
              ) : (
                <TableBodyClient
                  handleFamilyClick={handleFamilyClick}
                  clientsRows={dataClients?.ClientsEntities.clients || []}
                  columns={clientColumns}
                />
              )}
            </TableBodyWrapper>
          </TableWrapper>
        );
      case 'Entities':
        return isMobile ? (
          <>
            {loading ? (
              <Loader />
            ) : (
              <MobileTableEntity
                handleFamilyClick={handleFamilyClick}
                handleEntityClick={handleEntityClick}
                clientEntityRows={dataClients?.ClientsEntities.entities || []}
                columns={entityColumns}
              />
            )}
          </>
        ) : (
          <TableWrapper padding="0">
            <CustomTableHeader isTablet={isTablet} savedSort={savedSort} savedSetSort={savedSetSort} columns={entityColumns} refetch={refetch} />
            <TableBodyWrapper>
              {loading ? (
                <Loader />
              ) : (
                <TableBodyEntity
                  handleFamilyClick={handleFamilyClick}
                  handleEntityClick={handleEntityClick}
                  clientEntityRows={dataClients?.ClientsEntities.entities || []}
                  columns={entityColumns}
                />
              )}
            </TableBodyWrapper>
          </TableWrapper>
        );
      default:
        return <></>;
    }
  };

  return (
    <>
      <TableBody />
      <PaginationWrap>
        <TablePagination
          savePagination={(limit, offset) => paginationSaver(limit, offset)}
          paginationValues={paginationValuesFromBE}
          refetch={refetch}
        />
      </PaginationWrap>
    </>
  );
};

const CustomTableHeader = styled(TableHeader)<{ isTablet: boolean }>`
  position: sticky;
  top: ${({ isTablet }) => (isTablet ? '130px' : '80px')};
  z-index: 8;
  padding-top: 0;
`;

const PaginationWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

export default Table;
