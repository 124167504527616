import styled from 'styled-components';

import React from 'react';
import FilterSelect from '@/components/basicComponents/select';
import RedirectTitleCell from '@/components/table/redirectTitleCell';
import { TableBodyWrapper, TableRowCell, TableRowWrapper, TableWrapper, TextCell } from '@/components/table/styles';
import TableHeader from '@/components/table/tableHeader';
import { useResponsive } from '@/hooks/use-responsive';
import guid from '@/utils/guid';
import ShowText from '../../showText';
import { IRawAnswers } from '../../types';
import { columns } from './constatnts';

type TableProp = {
  rows: IRawAnswers[];
  changeField: (id: string, value: string, fieldName: string) => void;
  openPdf: (pageNumber: number) => void;
};

export const Table = ({ rows, changeField, openPdf }: TableProp) => {
  const { isTablet } = useResponsive();

  return (
    <CustomTableWrapper>
      <CustomTableHeader isTablet={isTablet} refetch={(): void => null} columns={columns} withOutSort />
      <TableBodyWrapper>
        {rows.map((row) => (
          <CustomTableRowWrapper key={guid()}>
            <TableRowCell width={columns[0].width}>
              <TextCell>
                <TextCell>
                  {row.pageNumbers?.map((pageNumber, index) => (
                    <PageNumber key={pageNumber + index} onClick={() => openPdf(pageNumber)}>{`${index ? ', ' : ''}${pageNumber}`}</PageNumber>
                  ))}
                </TextCell>
              </TextCell>
            </TableRowCell>
            <TableRowCell width={columns[1].width}>
              <TextCell>
                <ShowText>{row.answer}</ShowText>
              </TextCell>
            </TableRowCell>
            <TableRowCell width={columns[2].width}>
              <FilterSelect
                data={['true', 'false']}
                setSelected={(value) => changeField(row.id, value, 'hasAnswer')}
                selected={row.hasAnswer === null ? '--' : row.hasAnswer.toString()}
                width="100%"
                fontSize="16px"
                fontWeight="400"
                minHeight="40px"
              />
            </TableRowCell>
            <TableRowCell width={columns[3].width}>
              <FilterSelect
                data={['--', ...Array(11).keys()].map(String)}
                setSelected={(value) => changeField(row.id, value, 'score')}
                selected={row.score === null ? '--' : row.score.toString()}
                width="100%"
                fontSize="16px"
                fontWeight="400"
                minHeight="40px"
              />
            </TableRowCell>
          </CustomTableRowWrapper>
        ))}
      </TableBodyWrapper>
    </CustomTableWrapper>
  );
};

const CustomTableWrapper = styled(TableWrapper)`
  padding: 20px 0 0 0;
`;

const CustomTableRowWrapper = styled(TableRowWrapper)`
  justify-content: space-between;
`;

const CustomTableHeader = styled(TableHeader)<{ isTablet: boolean }>`
  position: sticky;
  top: ${({ isTablet }) => (isTablet ? '130px' : '80px')};
  z-index: 8;
  padding-top: 0;
`;

const PageNumber = styled.span`
  cursor: pointer;
  color: ${({ theme }) => theme.font.action};
`;
