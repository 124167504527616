import { Loader } from '@/components/loaders/loader';
import TableHeader from '@/components/table/fat-tableHeader';
import { ISort } from '@/components/table/types';
import { useResponsive } from '@/hooks/use-responsive';
import styled from 'styled-components';
import { TableBodyWrapper, TableWrapper } from '../../table/styles';
import { opportunitiesTableColumns } from '../constants';
import { Investment, TypeWindow } from '../types';
import MobileTable from './mobileTable';
import TableBody from './tableBody';

interface OpenOpportunitiesTableProps {
  openPage: (id: string, type: TypeWindow) => void;
  loading: boolean;
  openOpportunitiesData: Investment[];
  refetch: () => void;
  sort: ISort;
  setSort: (value: ISort) => void;
}

const OpportunitiesTable = ({ openPage, loading, openOpportunitiesData, refetch, sort, setSort }: OpenOpportunitiesTableProps) => {
  const { isMobile, isTablet } = useResponsive();

  return (
    <TableWrapper padding="0">
      {isMobile ? (
        <>{loading ? <Loader /> : <MobileTable openOpportunitiesData={openOpportunitiesData} openPage={openPage} />}</>
      ) : (
        <>
          <CustomTableHeader isTablet={isTablet} refetch={refetch} columns={opportunitiesTableColumns} savedSort={sort} savedSetSort={setSort} />
          <TableBodyWrapper>
            {loading ? <Loader /> : <TableBody openOpportunitiesData={openOpportunitiesData} openPage={openPage} />}
          </TableBodyWrapper>
        </>
      )}
    </TableWrapper>
  );
};

export default OpportunitiesTable;

const CustomTableHeader = styled(TableHeader)<{ isTablet: boolean }>`
  position: sticky;
  top: ${({ isTablet }) => (isTablet ? '187px' : '137px')};
  z-index: 8;
  padding-top: 0;
`;
