import { Investment } from '@/components/fat-dealCatalog-page/types';
import { useQuery } from '@apollo/client';
import { Dispatch, SetStateAction, useState } from 'react';
import styled from 'styled-components';
import FooterModal from '../../../footer';
import { ProgressBar } from '../../../progressBar';
import { INVESTMENTS_QUERY_BASIC } from '../../../queries';
import { Status } from '../../../status';
import { ImportWrapper } from '../../../styles';
import { ISeriesCodesTableData, MissingIdError } from '../../../types';
import { allocationsSteps } from '../../constants';
import { seriesCodesStatusError, seriesCodesStatusSuccess } from './constants';
import Table from './table/table';

interface SeriesCodesProps {
  importSteps: typeof allocationsSteps;
  startOver: () => void;
  nextStep: () => void;
  tableData: ISeriesCodesTableData[];
  setTableData: Dispatch<SetStateAction<ISeriesCodesTableData[]>>;
  seriesCodesError: MissingIdError[];
  setErrors: Dispatch<SetStateAction<MissingIdError[]>>;
}

export const SeriesCodes = ({ importSteps, startOver, nextStep, tableData, setTableData, seriesCodesError, setErrors }: SeriesCodesProps) => {
  const [showTable, setShowTable] = useState(false);
  const [investmentList, setInvestmentList] = useState<Investment[]>([]);

  const { loading: investmentListLoading } = useQuery(INVESTMENTS_QUERY_BASIC, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
    variables: {
      rowsPerPage: 1000
    },
    onCompleted: (data) => {
      const sortedList = [...data.Investments.data].sort((a: Investment, b: Investment) => a.name.localeCompare(b.name));
      setInvestmentList(sortedList);
    },
    onError: (error) => {
      console.error(error);
    }
  });

  const handleChangeAssignment = (investmentName: string, seriesCode: string) => {
    const selectedInvestment = investmentList.find((investment: Investment) => investment.name === investmentName);
    if (!selectedInvestment) return;

    const updatedTableData = tableData.map((row) => {
      if (row.investmentCode === seriesCode) {
        return { ...row, seriesStatus: 'Complete', seriesAssignment: investmentName, changed: true, investmentId: selectedInvestment.id };
      }
      return row;
    });

    setTableData(updatedTableData);
    setErrors((prev) => {
      return prev.filter((error) => error.id !== seriesCode);
    });

    if (!showTable) {
      setShowTable(true);
    }
  };

  const resetAssignment = (seriesCode: string) => {
    const updatedTableData = tableData.map((row) => {
      if (row.investmentCode === seriesCode) {
        return { ...row, seriesStatus: 'Required', seriesAssignment: 'Select A Strategy', changed: false, investmentId: null };
      }
      return row;
    });

    setTableData(updatedTableData);
    const updatedErrors = [{ id: seriesCode, message: `Missing investment vehicle for investment code ${seriesCode}` }, ...seriesCodesError];

    setErrors(updatedErrors);
  };

  return (
    <>
      <ImportWrapper>
        <ProgressBar importSteps={importSteps} />
        <Status
          errorsCount={seriesCodesError.length || null}
          matched={[{ count: tableData.length, text: 'Matched' }]}
          showTable={showTable}
          setShowTable={setShowTable}
          startOver={startOver}
          confirmButton={{ name: 'Continue', onClick: nextStep, disabled: Boolean(seriesCodesError.length) }}
          success={seriesCodesStatusSuccess}
          error={seriesCodesStatusError}
        />
      </ImportWrapper>
      {showTable || seriesCodesError?.length ? (
        <TableWrapper>
          <Table
            tableData={tableData}
            seriesCodesError={seriesCodesError}
            investmentList={investmentList ?? []}
            investmentListLoading={investmentListLoading}
            handleChangeAssignment={handleChangeAssignment}
            resetAssignment={resetAssignment}
          />
        </TableWrapper>
      ) : (
        <></>
      )}
      {seriesCodesError?.length || showTable ? (
        <FooterModal startOver={startOver} confirmButton={{ name: 'Continue', onClick: nextStep, disabled: Boolean(seriesCodesError.length) }} />
      ) : (
        <></>
      )}
    </>
  );
};

const TableWrapper = styled.div`
  margin-bottom: 100px;
`;
