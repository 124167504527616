import FormattingTooltip from '@/components/basicComponents/tooltip/dollarsTooltip';
import { MobileRow, MobileRowTitle, MobileRowValue, MobileTableRowWrap } from '@/components/table/mobileTable/mobileTable';
import guid from '@/utils/guid';
import styled from 'styled-components';
import { columns } from '../constatnts';
import { IInvestorTotalRow } from '../types';

interface MobileTableFooterProps {
  totalRowData: IInvestorTotalRow | null;
  showSpecificColumn: boolean;
}

const MobileSumFooter = ({ totalRowData, showSpecificColumn }: MobileTableFooterProps) => {
  if (!totalRowData) return <></>;

  return (
    <MobileSumFooterWrap key={guid()}>
      <MobileRow>
        <MobileRowTitle>Total</MobileRowTitle>
      </MobileRow>

      {showSpecificColumn && (
        <MobileRow>
          <MobileRowTitle>{columns[1].title}</MobileRowTitle>
          <MobileRowValue>
            <FormattingTooltip>{totalRowData.totalValue}</FormattingTooltip>
          </MobileRowValue>
        </MobileRow>
      )}

      <MobileRow>
        <MobileRowTitle>{columns[4].title}</MobileRowTitle>
        <MobileRowValue>
          <FormattingTooltip>{totalRowData.economicExposure}</FormattingTooltip>
        </MobileRowValue>
      </MobileRow>

      {showSpecificColumn && (
        <MobileRow>
          <MobileRowTitle>{columns[5].title}</MobileRowTitle>
          <MobileRowValue>
            <FormattingTooltip>{totalRowData.totalCapacity}</FormattingTooltip>
          </MobileRowValue>
        </MobileRow>
      )}
    </MobileSumFooterWrap>
  );
};

const MobileSumFooterWrap = styled(MobileTableRowWrap)`
  background-color: ${({ theme }) => theme.layer[2]};
`;

export default MobileSumFooter;
