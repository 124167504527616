import FilterSelect from '@/components/basicComponents/select';
import { Investment } from '@/components/fat-dealCatalog-page/types';
import { ISeriesCodesTableData } from '@/components/fat-settings-page/importData/types';
import {
  MobileRow,
  MobileRowTitle,
  MobileRowValue,
  MobileTableRowWrap,
  MobileTableWrapper,
  NoResults
} from '@/components/table/mobileTable/mobileTable';
import styled from 'styled-components';
import { RowStatus } from '../../entityCodes/table/cell/rowStatus';
import { columns } from '../constants';

interface MobileTableProps {
  tableData: ISeriesCodesTableData[];
  investmentList: Investment[];
  investmentListLoading: boolean;
  handleChangeAssignment: (investmentName: string, seriesCode: string) => void;
  resetAssignment: (seriesCode: string) => void;
}

const MobileTable = ({ tableData, investmentList, investmentListLoading, handleChangeAssignment, resetAssignment }: MobileTableProps) => {
  const TableRow = ({ el }: { el: ISeriesCodesTableData }) => {
    return (
      <MobileTableRowWrap key={el.id}>
        <MobileRow>
          <MobileRowTitle>{columns[0].title}</MobileRowTitle>
          <MobileRowValue>{el.investmentCode}</MobileRowValue>
        </MobileRow>

        <MobileRow>
          <MobileRowTitle>{columns[1].title}</MobileRowTitle>
          <MobileRowValue>{el.seriesName}</MobileRowValue>
        </MobileRow>

        <MobileRow>
          <MobileRowTitle>{columns[2].title}</MobileRowTitle>
          <MobileRowValue>
            {el.seriesStatus === 'Assigned' ? (
              <>{el.seriesAssignment}</>
            ) : (
              <AssignmentSelect
                data={investmentList.map((investment) => investment.name)}
                selected={el.seriesAssignment}
                setSelected={(value) => handleChangeAssignment(value, el.investmentCode)}
                width="100%"
                openZIndex={35}
                loading={investmentListLoading}
                withSearch
                clearSelectionButton={{ defaultValue: 'Select A Strategy', onClick: () => resetAssignment(el.investmentCode) }}
              />
            )}
          </MobileRowValue>
        </MobileRow>

        <MobileRow>
          <MobileRowTitle>{columns[3].title}</MobileRowTitle>
          <MobileRowValue>
            <RowStatus status={el.seriesStatus} />
          </MobileRowValue>
        </MobileRow>
      </MobileTableRowWrap>
    );
  };

  return (
    <MobileTableWrapper>
      {tableData.length ? (
        tableData.map((el: ISeriesCodesTableData, index: number) => {
          return <TableRow el={el} key={index} />;
        })
      ) : (
        <NoResults>No Results</NoResults>
      )}
    </MobileTableWrapper>
  );
};

export default MobileTable;

const AssignmentSelect = styled(FilterSelect)`
  border-radius: 4px;
  #titleSelect {
    color: #202020;
    font-size: 16px;
    line-height: 22.4px;
  }
`;
