import { CheckPendingCommitmentsIcon } from '@/assets/icons/fat-documentation-tab-icons/checkPendingCommitments';
import { DocumentationCompleteIcon } from '@/assets/icons/fat-documentation-tab-icons/documentationComplete';
import { InvestorDocumentationIcon } from '@/assets/icons/fat-documentation-tab-icons/investorDocumentation';
import { SignatureIcon } from '@/assets/icons/fat-documentation-tab-icons/signature';
import { EditTodoIcon } from '@/assets/icons/info-icons/editTodo';
import Button from '@/components/fat-basicComponents/button';
import React, { useState } from 'react';
import styled, { useTheme } from 'styled-components';
import EntityType from './modals/entityType';

const zeroStateItems = [
  { icon: <InvestorDocumentationIcon />, title: 'Add Investor Documentation here ' },
  { icon: <DocumentationCompleteIcon />, title: 'Mark documentation as complete' },
  { icon: <CheckPendingCommitmentsIcon />, title: 'Check Pending Commitments for any missing info' },
  { icon: <SignatureIcon />, title: 'Investor receives subdocs for signature' }
];

export const DocumentationZeroState = () => {
  const theme = useTheme();

  const [modalWindow, setModalWindow] = useState({ isOpen: false, type: '' });

  const openModalWindow = (type: string) => {
    setModalWindow({ type, isOpen: true });
  };

  const closeModalWindow = () => {
    setModalWindow({ ...modalWindow, isOpen: false });
  };

  return (
    <>
      {modalWindow.isOpen && modalWindow.type === 'entity-type' && <EntityType isOpen={modalWindow.isOpen} onClose={closeModalWindow} />}
      <DocumentationZeroWrapper>
        <DocumentationZeroBanner>
          <DocumentationZeroStateImage src="/static/investors-page/documentation-zero-state.webp" draggable="false" alt="No Investors" />
          <div>
            <h2>Adding Investor Documentation</h2>
            <p>By completing investor documentation you can complete commitments for this investor</p>
          </div>
        </DocumentationZeroBanner>
        <Divider />
        <DocumentationSteps>
          {zeroStateItems.map((item, index) => (
            <React.Fragment key={index}>
              <DocumentationStep>
                {item.icon}
                <p>{item.title}</p>
              </DocumentationStep>
              {index !== zeroStateItems.length - 1 && (
                <DocumentationStepSeparatorWrapper>
                  <div>
                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="21" viewBox="0 0 14 21" fill="none">
                      <path
                        d="M0.856689 2.85001L8.48669 10.5L0.856689 18.15L3.20669 20.5L13.2067 10.5L3.20669 0.5L0.856689 2.85001Z"
                        fill="#ABABAB"
                      />
                    </svg>
                  </div>
                </DocumentationStepSeparatorWrapper>
              )}
            </React.Fragment>
          ))}
        </DocumentationSteps>
        <Button onClick={() => openModalWindow('entity-type')} size="lg" icon={<EditTodoIcon width={22} height={18} fill={theme.context.light} />}>
          Begin by Selecting Entity Type
        </Button>
      </DocumentationZeroWrapper>
    </>
  );
};

const DocumentationZeroWrapper = styled.div`
  padding: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
`;

const DocumentationZeroStateImage = styled.img`
  width: 131px;
  height: 106px;
`;

const DocumentationZeroBanner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  color: ${({ theme }) => theme.font.weak};
  background-color: ${({ theme }) => theme.layer[1]};
  border-radius: 20px;
  padding: 30px 80px;
  font-family: Lato;
`;

const Divider = styled.div`
  width: 100%;
  min-height: 1px;
  background-color: ${({ theme }) => theme.border.base};
`;

const DocumentationSteps = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

const DocumentationStep = styled.div`
  display: flex;
  width: 200px;
  padding: 10px;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  gap: 15px;
  color: ${({ theme }) => theme.font.base};
  text-align: center;
`;

const DocumentationStepSeparatorWrapper = styled.div`
  display: flex;
  width: 40px;
  height: 40px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.font.weak};
  & > div {
    width: 20px;
    height: 12.35px;
    flex-shrink: 0;
  }
`;
