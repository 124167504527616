import * as React from 'react';

export const DocumentationCompleteIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || 46}
    height={props.height || 45}
    onClick={props.onClick || undefined}
    style={props?.style}
    viewBox="0 0 46 45"
    fill="none"
  >
    <g clip-path="url(#clip0_12044_145734)">
      <path
        d="M0.509766 21.5553C0.546994 21.183 0.59353 20.8107 0.630758 20.4384C1.19849 14.7239 3.57181 9.89345 7.76933 5.98445C11.1292 2.85725 15.1127 0.977212 19.6359 0.26987C23.2843 -0.297866 26.8676 0.0558056 30.3577 1.24712C31.4932 1.63802 32.0982 2.68042 31.8748 3.8252C31.6701 4.88621 30.6928 5.64009 29.576 5.59355C29.334 5.58424 29.0827 5.50979 28.85 5.43533C21.851 3.10855 14.4425 5.05374 9.52838 10.5356C7.13645 13.2161 5.69384 16.3619 5.17264 19.9172C3.82311 29.1127 9.78898 37.8893 18.8262 40.03C28.9338 42.4126 38.9203 35.879 40.7631 25.6504C41.1261 23.6401 41.1354 21.6297 40.8097 19.6194C40.6049 18.3816 41.4053 17.1996 42.578 17.0041C43.881 16.79 44.9979 17.5998 45.2026 18.9028C46.8872 29.5687 40.9213 39.7973 30.8045 43.576C28.7476 44.3392 26.6256 44.7859 24.4384 44.9255C24.2988 44.9348 24.1499 44.972 24.0103 44.9907H22.0744C21.9534 44.972 21.8324 44.9441 21.7114 44.9255C21.1623 44.8697 20.6132 44.8417 20.0641 44.7673C15.243 44.0972 11.0361 42.1241 7.51804 38.7549C3.63696 35.0506 1.37533 30.4994 0.705215 25.1572C0.630758 24.5801 0.574916 24.0031 0.509766 23.426C0.509766 22.8024 0.509766 22.1789 0.509766 21.546V21.5553Z"
        fill="#8F8F8F"
      />
      <path
        d="M22.9023 23.3144C23.5445 22.5791 24.1588 21.8811 24.7638 21.183C28.7751 16.6039 32.7772 12.0248 36.7886 7.44571C38.073 5.98449 40.3346 6.31024 41.0512 8.06929C41.4515 9.06515 41.2467 9.96795 40.5487 10.7684C38.9758 12.5646 37.4028 14.3609 35.8393 16.1572C32.2374 20.2709 28.6355 24.3847 25.0337 28.5078C23.9261 29.7828 22.3346 29.8387 21.1433 28.6474C18.9561 26.4695 16.7782 24.2916 14.6004 22.1045C13.8372 21.3413 13.6045 20.4199 13.9768 19.4054C14.3491 18.4002 15.1123 17.8604 16.1826 17.7766C16.9551 17.7208 17.6066 18.0093 18.1464 18.5584C19.6355 20.0476 21.1247 21.5367 22.6138 23.0259C22.6976 23.1096 22.7906 23.1934 22.9116 23.3051L22.9023 23.3144Z"
        fill="#2B2B2B"
      />
    </g>
    <defs>
      <clipPath id="clip0_12044_145734">
        <rect width="44.9814" height="45" fill="white" transform="translate(0.509766)" />
      </clipPath>
    </defs>
  </svg>
);
