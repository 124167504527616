import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { AnchorPopover } from '@/components/table/anchor-drop-down/fat-index';
import { formatCash } from '../../../utils/formatCash';
import guid from '../../../utils/guid';
import Tooltip from '../../basicComponents/tooltip';
import FormattingTooltip from '../../basicComponents/tooltip/dollarsTooltip';
import { TableBodyWrapper, TableRowCell, TextCell } from '../../table/styles';
import { columnsCommitments } from '../constants';
import { CommitmentsType } from '../types';
import { InfoList } from './infoList';

interface BodyRowProps {
  clientsRows: CommitmentsType[];
}

export const TableBody = ({ clientsRows }: BodyRowProps) => {
  const navigate = useNavigate();
  const location = useLocation();

  const getColorStatus = (status: string) => {
    if (status === 'Active') {
      return '#3DBD4A';
    } else if (status === 'Recommended' || status === 'Outstanding') {
      return '#DFC902';
    } else {
      return '#111827';
    }
  };

  const toolTipContent = (row: CommitmentsType) => {
    return (
      <TableBodyWrapper>
        <TableRowCell width={100}>
          <TextCell>{formatCash(row.committedCapital)} Committed Capital</TextCell>
        </TableRowCell>
        <TableRowCell width={100}>
          <TextCell>{formatCash(row.capitalCalled)} Capital Called</TextCell>
        </TableRowCell>
        <TableRowCell width={100}>
          <TextCell>{formatCash(row.capitalRemaining)} Capital Remaining</TextCell>
        </TableRowCell>
        <TableRowCell width={100}>
          <TextCell>{((row.capitalCalled / row.committedCapital) * 100).toFixed(2)}% Called to Date</TextCell>
        </TableRowCell>
      </TableBodyWrapper>
    );
  };

  const handleInvestment = (name: string, id: string) => {
    navigate(`/investments/${name}/${encodeURIComponent(id)}`, { state: { prevPath: location.pathname } });
  };

  return (
    <TableWrapper>
      {clientsRows.map((row) => (
        <TableRowWrap key={guid()}>
          <TableRowItem width={columnsCommitments[0].width}>
            <AnchorPopover
              title={row.name}
              hyperTitle={() => handleInvestment(row.moreInfo.managerId, row.investmentId)}
              rowBody={<InvestmentTitle>{row.name}</InvestmentTitle>}
            >
              <InfoList title={row.name} data={row} handleInvestment={handleInvestment} />
            </AnchorPopover>
          </TableRowItem>
          <TableRowItem width={columnsCommitments[1].width}>
            <BasicTitle>{row.moreInfo.assetClass || ''}</BasicTitle>
          </TableRowItem>
          <TableRowItem width={columnsCommitments[2].width}>
            <BasicTitle>
              <FormattingTooltip>{row.committedCapital}</FormattingTooltip>
            </BasicTitle>
          </TableRowItem>
          <TableRowItem width={columnsCommitments[3].width}>
            <BasicTitle>
              <Tooltip tooltipContent={toolTipContent(row)}>{((row.capitalCalled / row.committedCapital) * 100).toFixed(2)}%</Tooltip>
            </BasicTitle>
          </TableRowItem>
          <TableRowItem width={columnsCommitments[4].width}>
            <BasicTitle>
              <FormattingTooltip>{+row.distributionsToDate}</FormattingTooltip>
            </BasicTitle>
          </TableRowItem>
          <TableRowItem width={columnsCommitments[5].width}>
            <BasicTitle>
              <FormattingTooltip>{row.marketValue}</FormattingTooltip>
            </BasicTitle>
          </TableRowItem>

          <TableRowItem width={columnsCommitments[6].width}>
            <ColorTitle color={getColorStatus(row.status)}>{row.status === 'Outstanding' ? 'Recommended' : row.status}</ColorTitle>
          </TableRowItem>
        </TableRowWrap>
      ))}
    </TableWrapper>
  );
};

const TableWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 6px;
`;

export const BasicTitle = styled.div`
  font-family: 'Blinker', serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0;
  text-align: left;
  color: ${({ theme }) => theme.font.base};
`;

const InvestmentTitle = styled(BasicTitle)`
  cursor: pointer;
  padding-right: 18px;
  font-weight: 600;
  color: ${({ theme }) => theme.font.base};
`;

const ColorTitle = styled(BasicTitle)<{ color: string }>`
  color: ${({ color }) => color};
  font-weight: 600;
`;

const TableRowWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  min-height: 60px;
  background-color: ${({ theme }) => theme.layer[1]};
  border-radius: 8px;
  padding: 0 24px;

  &:hover ${InvestmentTitle} {
    color: ${({ theme }) => theme.font.action};
  }

  &:hover #infoWrap {
    opacity: 1;
  }

  &:hover #tempInfoWrap {
    display: none;
  }

  &:hover {
    background-color: ${({ theme }) => theme.layer[12]};
    transition: background-color 100ms ease;
  }
`;

const TableRowItem = styled.div<{ width: number }>`
  display: flex;
  justify-content: flex-start;
  width: ${({ width }) => `${width}%`};
`;
