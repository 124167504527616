import { DealPipeline } from '@/components/fat-dealPipeline-page';
import { Helmet as Head } from 'react-helmet';
import useRefresh from '../../hooks/use-refresh';

const DealPipelinePage = () => {
  useRefresh();
  return (
    <>
      <Head>
        <title>Manager Pipeline</title>
      </Head>
      <DealPipeline />
    </>
  );
};

export default DealPipelinePage;
