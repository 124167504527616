import { ISetting } from '@/components/settings-page/settings-pages/firmSettings/types';
import { Image as PdfImage, StyleSheet, Text, View } from '@react-pdf/renderer';
import { ISection } from '../dueDiligenceRecord/types';
import { AiIcon } from './AiIcon';
import { templateStyles } from './PDFTemplate';
import { SectionScore } from './sectionScore';
import { aiFieldValue, fieldValue, getFontByKey, getTermFontWeight, matchFieldWithAI } from './utils';

interface OneColumnSectionProps {
  section: ISection;
  imageSizes: { [url: string]: { width: number; height: number } };
  templateStylingData: ISetting[];
  includeAiSuggested: boolean;
}

export const OneColumnSection = ({ section, imageSizes, templateStylingData, includeAiSuggested }: OneColumnSectionProps) => {
  const styles = StyleSheet.create({
    sectionName: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      fontSize: 20,
      fontFamily: getFontByKey(templateStylingData, 'headerFontStyle').fontFamily
        ? getFontByKey(templateStylingData, 'headerFontStyle').fontFamily
        : 'Roboto Slab',
      color: Boolean(getFontByKey(templateStylingData, 'headerFontStyle').fontColor)
        ? `#${getFontByKey(templateStylingData, 'headerFontStyle').fontColor}`
        : '#35739C',
      fontWeight: getFontByKey(templateStylingData, 'headerFontStyle').fontWeight
        ? getFontByKey(templateStylingData, 'headerFontStyle').fontWeight
        : 400,
      paddingBottom: '4px',
      borderBottom: '2px solid #F0F1F3',
      marginBottom: '10px'
    },
    fieldName: {
      fontSize: 12,
      fontFamily: getFontByKey(templateStylingData, 'bodyFontStyle').fontFamily
        ? getFontByKey(templateStylingData, 'bodyFontStyle').fontFamily
        : 'Open Sans',
      color: Boolean(getFontByKey(templateStylingData, 'bodyFontStyle').fontColor)
        ? `#${getFontByKey(templateStylingData, 'bodyFontStyle').fontColor}`
        : '#757575',
      fontWeight: getTermFontWeight(templateStylingData, 'bodyFontStyle') as number
    },
    fieldValue: {
      fontSize: 9,
      fontFamily: getFontByKey(templateStylingData, 'bodyFontStyle').fontFamily
        ? getFontByKey(templateStylingData, 'bodyFontStyle').fontFamily
        : 'Open Sans',
      color: Boolean(getFontByKey(templateStylingData, 'bodyFontStyle').fontColor)
        ? `#${getFontByKey(templateStylingData, 'bodyFontStyle').fontColor}`
        : '#757575',
      fontWeight: getFontByKey(templateStylingData, 'bodyFontStyle').fontWeight ? getFontByKey(templateStylingData, 'bodyFontStyle').fontWeight : 600,
      paddingTop: '2px',
      lineHeight: '1.3px'
    },
    aiIconWrap: {
      display: 'flex',
      flexDirection: 'row',
      fontSize: '7px',
      fontFamily: getFontByKey(templateStylingData, 'bodyFontStyle').fontFamily
        ? getFontByKey(templateStylingData, 'bodyFontStyle').fontFamily
        : 'Open Sans',
      color: Boolean(getFontByKey(templateStylingData, 'bodyFontStyle').fontColor)
        ? `#${getFontByKey(templateStylingData, 'bodyFontStyle').fontColor}`
        : '#757575',
      fontWeight: getTermFontWeight(templateStylingData, 'bodyFontStyle') as number
    }
  });

  return (
    <View
      break={Boolean(
        section.fields.length === 1 &&
          !section.fields[0].data.value &&
          section.fields[0].data.attachments.filter((attachment) => attachment.includeInAutomatedContent).length
      )}
    >
      {section.fields.filter(
        (field) =>
          (field.data.value && field.data.value !== field.AI?.answers[0]) ||
          (field.AI?.answers.length && includeAiSuggested && (field.data.value === null || field.data.value === field.AI?.answers[0])) ||
          field.data.attachments.filter((attachment) => attachment.includeInAutomatedContent).length
      ).length ? (
        <>
          {section.fields.map((field, index) => (
            <View key={field.data.id} style={{ marginBottom: '30px' }}>
              <View
                wrap={Boolean(
                  (field.data.value && field.data.value.length > 6000) || (field.AI?.answers.length && field.AI?.answers[0].length > 6000)
                )}
              >
                {index === 0 ? (
                  <View style={styles.sectionName}>
                    <Text>{section.name}</Text>
                    {section.userCanScore ? <SectionScore score={section.data.score} templateStylingData={templateStylingData} /> : null}
                  </View>
                ) : null}
                {(field.data.value ||
                  field.data.commentary ||
                  field.data.attachments.filter((attachment) => attachment.includeInAutomatedContent).length) &&
                field.data.value !== field.AI?.answers[0] ? (
                  <View style={{ marginBottom: '25px' }}>
                    {section.fields.length > 1 ? <Text style={styles.fieldName}>{field.name}</Text> : null}
                    <Text style={styles.fieldValue}>{fieldValue(field.type, field.data.value, field.data.commentary)}</Text>
                  </View>
                ) : null}
                {matchFieldWithAI(field) && field.AI?.answers.length && includeAiSuggested ? (
                  <View style={{ marginBottom: '25px' }}>
                    {section.fields.length > 1 ? <Text style={styles.fieldName}>* {field.name}</Text> : null}
                    <Text style={styles.fieldValue}>{aiFieldValue(field.type, field.AI.answers[0])}</Text>
                  </View>
                ) : (
                  <></>
                )}
              </View>
              <View
                style={{
                  ...templateStyles.previewWrapper,
                  marginBottom:
                    section.fields.length > 1 && field.data.attachments.filter((attachment) => attachment.includeInAutomatedContent).length
                      ? '25px'
                      : 0
                }}
              >
                {field.data.attachments
                  .sort((attachment1, attachment2) => {
                    const width1 = imageSizes[attachment1.asset.url]?.width || 0;
                    const width2 = imageSizes[attachment2.asset.url]?.width || 0;
                    return width2 - width1;
                  })
                  .map((attachment) => {
                    return attachment.includeInAutomatedContent ? (
                      <View
                        key={attachment.id}
                        style={{
                          ...templateStyles.imgPreviewWrap,
                          width: imageSizes[attachment.asset.url]?.width > 690 ? '100%' : imageSizes[attachment.asset.url]?.width,
                          height: imageSizes[attachment.asset.url]?.height > 400 ? 'auto' : imageSizes[attachment.asset.url]?.height
                        }}
                      >
                        <PdfImage
                          style={{
                            padding: '2px',
                            border: '1px solid #F0F1F3'
                          }}
                          src={attachment.asset.url}
                        />
                      </View>
                    ) : null;
                  })}
              </View>
              {section.fields.filter((field) => (!field.data.value || field.data.value === field.AI?.answers[0]) && field.AI?.answers.length)
                .length && includeAiSuggested ? (
                <View style={styles.aiIconWrap}>
                  <View
                    style={{
                      borderRight: '1px solid #C7CBD2',
                      paddingRight: '3px'
                    }}
                  >
                    <AiIcon />
                  </View>
                  <Text style={{ margin: '2px 0 0 5px' }}>
                    {section.fields.length > 1 ? '* These fields are AI Suggested' : 'This field is AI Suggested'}
                  </Text>
                </View>
              ) : null}
            </View>
          ))}
        </>
      ) : (
        <></>
      )}
    </View>
  );
};
