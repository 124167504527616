import React from 'react';
import styled from 'styled-components';

import { InfoIcon } from '@/assets/icons/info-icons/info';

interface ErrorNotificationProps {
  title: string;
  message: string;
}

const ErrorNotification = ({ title, message }: ErrorNotificationProps) => {
  return (
    <Container>
      <InfoIcon width={26} />
      <Divider />
      <Content>
        <Title>{title}</Title>
        <Description>{message}</Description>
      </Content>
    </Container>
  );
};

export default ErrorNotification;

const Container = styled.div`
  background: #d63b4b;
  display: flex;
  align-items: center;
  padding: 10px 20px;
  margin: 0 0 15px;
`;
const Divider = styled.div`
  background: #f4f5f6;
  height: 100%;
  min-height: 58px;
  width: 1px;
  margin: 0 20px;
`;
const Content = styled.div`
  display: flex;
  flex-direction: column;
`;
const Title = styled.span`
  font-family: 'Blinker';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  color: #f4f5f6;
`;
const Description = styled.span`
  font-family: 'Blinker';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  color: #f4f5f6;
`;
