import * as docx from 'docx';
import { aiFieldValue, fieldValue, matchFieldWithAI } from '../DDR-PDF/utils';
import { IAttachment, ISection, ISectionFields } from '../dueDiligenceRecord/types';
import { getAiSuggestedSection, getDocxImage } from './utils';

const formatText = (text: string): docx.Paragraph => {
  const formattedText = text.split('\n').flatMap((line, index, array) => {
    return index < array.length - 1
      ? [
          new docx.TextRun({
            text: line,
            color: '474747'
          }),
          new docx.TextRun({ break: 1 })
        ]
      : new docx.TextRun({
          text: line,
          color: '474747'
        });
  });

  return new docx.Paragraph({
    children: formattedText,
    indent: {
      left: 300,
      right: 300
    },
    spacing: {
      after: 300
    }
  });
};

const getImageData = async (attachment: IAttachment) => {
  if (!attachment.includeInAutomatedContent) return;

  const imageData = await getDocxImage(attachment.asset.url, attachment.asset.assetKey);
  if (!imageData) return;

  const img = new Image();
  img.src = URL.createObjectURL(new Blob([imageData]));
  await new Promise((resolve) => (img.onload = resolve));

  let dimensions = { width: img.naturalWidth, height: img.naturalHeight };
  if (dimensions.width > 150) {
    const aspectRatio = dimensions.height / dimensions.width;
    dimensions = { width: 150, height: Math.round(150 * aspectRatio) };
  }

  return new docx.Paragraph({
    children: [
      new docx.ImageRun({
        data: imageData,
        transformation: dimensions
      })
    ],
    spacing: { before: 100, after: 100 },
    alignment: docx.AlignmentType.CENTER
  });
};

const createTextRun = (field: ISectionFields, includeAiSuggested: boolean) => {
  const isMatchedWithAI = matchFieldWithAI(field);
  const text = !isMatchedWithAI || !field.AI?.answers.length || !includeAiSuggested ? `${field.name}:` : `* ${field.name}:`;

  return new docx.TextRun({
    text,
    bold: true,
    size: 24,
    color: '474747'
  });
};

const createTableCell = async (field: ISectionFields, includeAiSuggested: boolean, fill: string) => {
  const formattedText = matchFieldWithAI(field)
    ? field.AI?.answers.length && includeAiSuggested
      ? aiFieldValue(field.type, field.AI.answers[0])
      : 'Not specified'
    : fieldValue(field.type, field.data.value, field.data.commentary);

  const images = [];
  if (field.data.attachments) {
    for (const attachment of field.data.attachments) {
      const imageData = await getImageData(attachment);
      if (imageData) {
        images.push(imageData);
      }
    }
  }

  return new docx.TableCell({
    children: [
      new docx.Paragraph({
        children: [createTextRun(field, includeAiSuggested)],
        indent: { left: 300, right: 300 },
        spacing: { after: 300, before: 300 }
      }),
      formatText(formattedText),
      ...images
    ],
    borders: {
      top: { style: docx.BorderStyle.SINGLE, size: 0, color: 'FFFFFF' },
      bottom: { style: docx.BorderStyle.SINGLE, size: 40, color: 'FFFFFF' },
      left: { style: docx.BorderStyle.SINGLE, size: 0, color: 'FFFFFF' },
      right: { style: docx.BorderStyle.SINGLE, size: 40, color: 'FFFFFF' }
    },
    width: { size: 6000, type: docx.WidthType.DXA },
    shading: { fill, color: 'FFFFFF' }
  });
};

export const getTwoColumnSection = async (section: ISection, includeAiSuggested: boolean, bodyFontColor: string) => {
  const rows = [];

  for (let i = 0; i < section.fields.length; i += 2) {
    const cell1 = await createTableCell(section.fields[i], includeAiSuggested, 'F4F5F6');
    const cell2 = await createTableCell(section.fields[i + 1], includeAiSuggested, 'EBF0F4');
    const row = new docx.TableRow({
      children: [cell1, cell2]
    });

    rows.push(row);
  }

  const isAiSuggestedIncluded = (section: ISection, includeAiSuggested: boolean) => {
    return (
      section.fields.some((field) => (!field.data.value || field.data.value === field.AI?.answers[0]) && field.AI?.answers.length) &&
      includeAiSuggested
    );
  };

  if (isAiSuggestedIncluded(section, includeAiSuggested)) {
    const aiSuggestedSection = await getAiSuggestedSection(section, bodyFontColor);
    return [new docx.Table({ rows }), ...aiSuggestedSection];
  }

  return [new docx.Table({ rows })];
};
