import { EditIcon } from '@/assets/icons/edit';
import { ArrowDown } from '@/assets/icons/info-icons/arrowDown';
import { CopyIcon } from '@/assets/static/icons/copy-icon';
import { DeleteBucketIcon } from '@/assets/static/icons/delete-bucket';
import { DragAndDropBigIcon } from '@/assets/static/icons/drag-and-drop-icon';
import { ArrowUp } from '@/assets/static/icons/todo-page/arrow-up';
import BasicTooltip from '@/components/basicComponents/tooltip';
import { ArrowWrapper, DropdownBody, ExpandableWrapp, Title } from '@/components/dueDiligence-page/dueDiligenceRecord/tierDropdown';
import { blinkingFrame } from '@/components/table/styles';
import { useResponsive } from '@/hooks/use-responsive';
import { useState } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import styled from 'styled-components';

interface DropdownProps {
  title: string;
  children: React.ReactNode;
  handleEdit: () => void;
  handleDelete: () => void;
  handleDuplicate: () => void;
  isLimitedEdits: boolean;
  isDragDisabled: boolean;
  canDragging: { action: boolean; id: string };
  draggableId: string;
  index: number;
}

export const FunnelEditorDraggableDropdown = ({
  title,
  children,
  handleEdit,
  handleDelete,
  handleDuplicate,
  isLimitedEdits,
  isDragDisabled,
  canDragging,
  draggableId,
  index
}: DropdownProps) => {
  const { isMobile } = useResponsive();

  const [isDropdown, setIsDropdown] = useState(true);
  const [showExpandable, setShowExpandable] = useState(true);
  const [arrowHover, setArrowHover] = useState(false);
  const [dropdownHover, setDropdownHover] = useState(false);

  const handleDropdownChange = () => {
    setIsDropdown(!isDropdown);

    if (isDropdown) {
      setTimeout(() => {
        setShowExpandable(!isDropdown);
      }, 300);
    } else {
      setShowExpandable(!isDropdown);
    }
  };

  return (
    <Draggable key={draggableId} draggableId={draggableId} index={index} isDragDisabled={isDropdown || isLimitedEdits || isDragDisabled}>
      {(provided) => (
        <DropdownWrapper {...provided.draggableProps} ref={provided.innerRef}>
          <DropdownHeader
            isMobile={isMobile}
            isDropdown={isDropdown}
            onMouseEnter={() => setDropdownHover(true)}
            onMouseLeave={() => setDropdownHover(false)}
            {...provided.dragHandleProps}
            loading={!canDragging.action && canDragging.id === draggableId ? 1 : 0}
          >
            <HeaderInfo>
              <BasicTooltip translateX={-20} shouldShowContent={!isLimitedEdits && isDropdown} tooltipContent={'Please collapse to move.'}>
                <DragAndDropBigIcon
                  style={{
                    visibility: dropdownHover && !isLimitedEdits ? 'visible' : 'hidden'
                  }}
                />
              </BasicTooltip>

              <Title isMobile={isMobile}>{title}</Title>
            </HeaderInfo>
            {dropdownHover && (
              <ActionWrap>
                {<EditIcon fill="#4587EC" onClick={handleEdit} />}
                {!isLimitedEdits && <CopyIcon onClick={handleDuplicate} />}
                {!isLimitedEdits && <DeleteBucketIcon width={17} height={22} fill="#4587EC" onClick={handleDelete} />}
              </ActionWrap>
            )}
            <ArrowWrapper
              isMobile={isMobile}
              onClick={handleDropdownChange}
              onMouseEnter={() => setArrowHover(true)}
              onMouseLeave={() => setArrowHover(false)}
            >
              <>
                {isDropdown ? (
                  <ArrowUp style={{ cursor: 'pointer' }} fill={arrowHover && !isMobile ? '#F4F5F6' : '#4587EC'} />
                ) : (
                  <ArrowDown
                    style={{
                      cursor: 'pointer',
                      width: '28px',
                      height: '28px',
                      padding: '7px'
                    }}
                    fill={arrowHover && !isMobile ? '#F4F5F6' : '#4587EC'}
                  />
                )}
              </>
            </ArrowWrapper>
          </DropdownHeader>
          <DropdownBody isMobile={isMobile} isDropdown={isDropdown} bgColor="#fff">
            {showExpandable && (
              <ExpandableWrapp isMobile={isMobile} isDropdown={isDropdown}>
                {children}
              </ExpandableWrapp>
            )}
          </DropdownBody>
        </DropdownWrapper>
      )}
    </Draggable>
  );
};

const DropdownWrapper = styled.div`
  border: 1px solid #c7cbd2;
  border-radius: 10px;
`;

const DropdownHeader = styled.div<{
  isMobile: boolean;
  isDropdown: boolean;
  loading?: boolean | number;
}>`
  display: flex;
  justify-content: space-between;
  align-items: ${({ isMobile }) => (isMobile ? 'flex-start' : 'center')};
  flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};
  padding: ${({ isMobile }) => (isMobile ? '15px 20px' : '20px 40px')};
  position: relative;
  border-radius: ${({ isDropdown }) => (isDropdown ? '12px 12px 0 0' : '12px')};
  transition: 0.2s;
  background-color: #fafafa;

  opacity: ${({ loading }) => (loading ? 0.5 : 1)};
  pointer-events: ${({ loading }) => (loading ? 'none' : 'auto')};
  animation: ${({ loading }) => (loading ? blinkingFrame : null)} 1.5s infinite;

  &:hover {
    background-color: #f0f1f3;
    transition: background-color 100ms ease;
    border-radius: ${({ isDropdown }) => (isDropdown ? '12px 12px 0 0' : '12px')};
  }
`;

const HeaderInfo = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
`;

const ActionWrap = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  padding-right: 20px;
  margin-left: auto;
  cursor: pointer;
`;
