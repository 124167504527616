import { Dispatch, SetStateAction } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { FilterIcon } from '@/assets/icons/filter';
import { CloseInfoIcon } from '@/assets/icons/info-icons/closeInfo';
import { useResponsive } from '../../../hooks/use-responsive';
import { AdditionalFilters } from './types';
import { addSpaceBtwCamelCases, createNewFilter } from './utils';

interface ChosenFiltersProps extends AdditionalFilters {
  setChosenFilters: Dispatch<SetStateAction<boolean>>;
}

const ChosenFilters = ({ filters, applyFilters, setChosenFilters, amountResults, onRefetch }: ChosenFiltersProps) => {
  const { isMobile, isTablet } = useResponsive();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const removeFilter = (indexElement: number) => {
    const newFilters = filters.filter((_, index) => index !== indexElement);
    applyFilters(newFilters);
    newFilters.find((type) => {
      //type.typeFilter !== 'nextTask' ? (router.query.filterField = '') : null;
      let filterField = queryParams.get('filterField');
      type.typeFilter !== 'nextTask' ? (filterField = '') : null;

      let filterInvestmentsField = queryParams.get('filterInvestmentsField');
      type.typeFilter !== 'investments' ? (filterInvestmentsField = '') : null;
    });
    if (filters.length === 1) clearAll();
  };

  const clearAll = () => {
    applyFilters([createNewFilter('Select A Filter Option')]);
    setChosenFilters(false);

    let filterBy = queryParams.get('filterBy');
    filterBy = '';
    let filterField = queryParams.get('filterField');
    filterField = '';
  };

  return (
    <Container id={'chosenFliters'}>
      {/* <TopWrapper>*/}
      {/*  <Results>{amountResults} Results</Results>*/}
      {/*  <RefreshButton onClick={onRefetch}>*/}
      {/*    <RefreshIcon />*/}
      {/*  </RefreshButton>*/}
      {/* </TopWrapper>*/}
      <Divider />
      <Filters>
        {filters.map(({ id, typeFilter, selected, operator }, index) => (
          <FilterItem isMobile={isMobile} isTablet={isTablet} key={id} border={false}>
            <FilterIcon />
            <FilterText isMobile={isMobile} isTablet={isTablet}>
              {addSpaceBtwCamelCases(typeFilter)}: {selected}
            </FilterText>
            <CloseFilter onClick={() => removeFilter(index)}>
              <CloseInfoIcon width={isMobile || isTablet ? '22' : '15'} height={isMobile || isTablet ? '21' : '14'} />
            </CloseFilter>
          </FilterItem>
        ))}
        <FilterItem isMobile={isMobile} isTablet={isTablet} border={true}>
          <ClearAll isMobile={isMobile} isTablet={isTablet}>
            Clear All
          </ClearAll>
          <CloseFilter onClick={clearAll}>
            <CloseInfoIcon width={isMobile || isTablet ? '22' : '15'} height={isMobile || isTablet ? '21' : '14'} />
          </CloseFilter>
        </FilterItem>
      </Filters>
    </Container>
  );
};

export default ChosenFilters;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;
// const TopWrapper = styled.div`
//   display: flex;
//   align-items: center;
// `;
// const Results = styled.h1`
//   font-family: 'Blinker';
//   font-weight: 600;
//   font-size: 16px;
//   color: #373f4e;
// `;
const Divider = styled.div`
  background: #c7cbd2;
  height: 1px;
  width: 100%;
  margin: 10px 0;
`;
const Filters = styled.div`
  gap: 5px;
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 20px;
`;
const FilterItem = styled.div<{ border: boolean; isMobile: boolean; isTablet: boolean }>`
  display: flex;
  justify-content: space-between;
  background: #f0f1f3;
  border: ${({ border }) => (border ? '1px solid #f0f1f3' : 'none')};
  border-radius: 4px;
  column-gap: 6px;
  padding: ${({ isMobile, isTablet }) => (isMobile || isTablet ? '4px 7px' : '3px 6px')};
  align-items: center;
`;
const FilterText = styled.span<{ isMobile: boolean; isTablet: boolean }>`
  font-family: 'Blinker';
  font-weight: 400;
  font-size: ${({ isMobile, isTablet }) => (isMobile || isTablet ? '14px' : '13px')};
  line-height: 18px;
  color: #7f7f7f;
  text-transform: capitalize;
`;
const ClearAll = styled.span<{ isMobile: boolean; isTablet: boolean }>`
  font-family: 'Blinker';
  font-weight: 600;
  font-size: ${({ isMobile, isTablet }) => (isMobile || isTablet ? '14px' : '13px')};
  line-height: 18px;
  color: #4587ec;
`;
const CloseFilter = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  transform: scale(0.6);
`;
// const RefreshButton = styled.div`
//   padding-left: 5px;
//   display: flex;
//   align-items: center;
//   cursor: pointer;
// `;
