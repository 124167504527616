import { format } from 'date-fns';
import styled from 'styled-components';

import FormattingTooltip from '@/components/basicComponents/tooltip/dollarsTooltip';
import { AnchorPopover } from '@/components/table/anchor-drop-down/fat-index';
import NoResults from '@/components/table/noResults';
import RedirectTitleCell from '@/components/table/redirectTitleCell';
import { CellWrapper, TableBodyWrapper, TableRowCell, TableRowWrapper, TextCell } from '@/components/table/styles';
import guid from '@/utils/guid';
import { ComplianceEntityInfoList } from './compilianceEntityInfoList';
import { ComplianceInfoList } from './complianceInfoList';
import { ComplianceInvestmentInfoList } from './complianceInvestmentInfoList';
import { columns } from './constatnts';
import { EntityType } from './index';
import { IRowData } from './types';

interface BodyRowProps {
  complianceRows: IRowData[];
  amountColumns: number;
  handleFamilyClick: (id: string) => void;
  handleEntityClick: (ids: EntityType) => void;
  handleClickInvestment: (name: string, id: string) => void;
}

export const TableBody = ({ complianceRows, handleFamilyClick, handleEntityClick, handleClickInvestment }: BodyRowProps) => {
  const modalTitle = (allocationStatus: string) => {
    switch (allocationStatus) {
      case 'Internal Review Rejected':
        return 'Internal Review Rejected';
      case 'Committed':
        return 'Committed';
      case 'Terminated':
        return 'Terminated';
    }
  };

  if (!complianceRows.length) {
    return <NoResults />;
  }

  return (
    <TableBodyWrapper>
      {complianceRows?.map((row) => (
        <TableRowWrapper key={guid()}>
          <TableRowCell width={columns[0].width}>
            <ClientAdvisorWrap>
              <GrowWrap grow={3}>
                <CellWrapper flexDirection={'column'}>
                  <AnchorPopover
                    title={row.legalEntity.entityName}
                    hyperTitle={() =>
                      handleEntityClick({
                        id: row.legalEntity.id,
                        name: row.legalEntity.entityName,
                        familyId: row.family.name
                      })
                    }
                    rowBody={<RedirectTitleCell>{row.legalEntity.entityName}</RedirectTitleCell>}
                    onRedirect={() =>
                      handleEntityClick({
                        id: row.legalEntity.id,
                        name: row.legalEntity.entityName,
                        familyId: row.family.name
                      })
                    }
                  >
                    <ComplianceEntityInfoList data={row.moreInfoEntity} investment={row.investment} />
                  </AnchorPopover>
                  {row.selectedGroupLegalEntity ? <TextGroupedCell>{row.selectedGroupLegalEntity.entityName}</TextGroupedCell> : null}
                </CellWrapper>
              </GrowWrap>
            </ClientAdvisorWrap>
          </TableRowCell>
          <TableRowCell width={columns[1].width}>
            <AnchorPopover
              title={row.investment.name}
              nameCell={row.investment.name}
              hyperTitle={() => handleClickInvestment(row.investment.name, row.investment.id)}
            >
              <ComplianceInvestmentInfoList data={row?.investment} />
            </AnchorPopover>
          </TableRowCell>
          <TableRowCell width={columns[2].width}>
            <CellWrapper flexDirection={'column'}>
              <RedirectTitleCell onClick={() => handleFamilyClick(row.family.name)}>{row.family.name}</RedirectTitleCell>
              <TextCell>{row.advisor?.initials} </TextCell>
            </CellWrapper>
          </TableRowCell>
          <TableRowCell width={columns[3].width}>
            <CellWrapper alignItems={'center'}>
              <AnchorPopover title={modalTitle(row.allocationStatus)} rowBody={<RedirectTitleCell>{row.allocationStatus}</RedirectTitleCell>}>
                <ComplianceInfoList data={row.moreInfoAllocationStatus} allocationStatus={row.allocationStatus} />
              </AnchorPopover>
            </CellWrapper>
          </TableRowCell>
          <TableRowCell width={columns[4].width}>
            <TextCell>{format(new Date(row.updatedAt), 'PP').toLocaleUpperCase()}</TextCell>
          </TableRowCell>
          <TableRowCell width={columns[5].width}>
            <TextCell>
              <FormattingTooltip zIndex={1000}>{+row.amount}</FormattingTooltip>
            </TextCell>
          </TableRowCell>
          <TableRowCell width={columns[6].width}>
            <TextCell>{row.terminationReason}</TextCell>
          </TableRowCell>
        </TableRowWrapper>
      ))}
    </TableBodyWrapper>
  );
};

const GrowWrap = styled.div<{ grow: number }>`
  flex-grow: ${({ grow }) => grow};
`;

const ClientAdvisorWrap = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  gap: 10px;
  padding-right: 25px;
`;

export const TextGroupedCell = styled.div`
  font-family: 'Blinker', serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 23px;
  color: ${({ theme }) => theme.context.warning};
`;
