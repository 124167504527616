import * as React from 'react';

export const SignOutIcon = ({ ...props }: React.SVGProps<SVGSVGElement>) => (
  <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_d_969_175917)">
      <path d="M17 12H5" stroke={props?.fill || '#172D4D'} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M5.94699 16C7.41999 18.961 10.468 21 14 21C18.971 21 23 16.971 23 12C23 7.029 18.971 3 14 3C10.468 3 7.41999 5.039 5.94699 8"
        stroke={props?.fill || '#172D4D'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M14 9L17 12L14 15" stroke={props?.fill || '#172D4D'} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </g>
    <defs>
      <filter id="filter0_d_969_175917" x="-2" y="0" width="32" height="32" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_969_175917" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_969_175917" result="shape" />
      </filter>
    </defs>
  </svg>
);
