import FilterSelect from '@/components/basicComponents/select';
import { DropFileArea } from '@/components/dueDiligence-page/dueDiligenceRecord/dropFileArea';
import { FormFooter } from '@/components/fat-basicComponents/formFooter';
import Input from '@/components/fat-basicComponents/input';
import Label from '@/components/fat-basicComponents/label';
import Header from '@/components/fat-header';
import { GoBackButton } from '@/components/fat-header/goBackButton';
import { PageTitle } from '@/components/fat-header/pageTitle';
import { useResponsive } from '@/hooks/use-responsive';
import { useGoBack } from '@/hooks/useGoBack';
import { MainWrap, PaddingWrap } from '@/styles/common';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';

const fileExtensions = ['PDF', 'DOC', 'DOCX', 'XLS', 'XLSX', 'CSV', 'JPEG', 'PNG', 'GIF', 'JPG'];
const types = ['Select', 'W-9', 'Organization Docs', 'IRS Determination Letter', 'Other'];

type SectionNameType = 'Authorized Signatories' | 'Reporting Recipients' | 'AML Documents';

const pageProperties: Record<SectionNameType, { title: string }> = {
  ['Authorized Signatories']: {
    title: 'Add Authorized Signatories File'
  },
  ['Reporting Recipients']: {
    title: 'Add Reporting Recipients File'
  },
  ['AML Documents']: {
    title: 'Add AML Document'
  }
};

export const AddDocumentPage = () => {
  const { isMobile } = useResponsive();
  const goBack = useGoBack();
  const theme = useTheme();
  const {
    handleSubmit,
    getValues,
    setValue,
    control,
    setError,
    formState: { errors, isValid },
    reset,
    watch
  } = useForm();

  const [searchParams, setSearchParams] = useSearchParams();

  const backToTitle = searchParams.get('backToTitle') || '';
  const sectionName = searchParams.get('fromSection') || '';

  const [drag, setDrag] = useState(false);
  const [files, setFiles] = useState<File[]>([]);
  const [modalWindow, setModalWindow] = useState({ isOpen: false, type: '' });

  const allowFileExtensions = (files: FileList | null) => {
    return Array.from(files || []).filter((file: File) => {
      const fileExt = file.name.split('.').pop()?.toLowerCase();
      if ([...fileExtensions.map((item) => item.toLowerCase())].includes(fileExt || '')) {
        return file;
      }
    });
  };

  const onDropHandler = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const uploadFiles = e.dataTransfer.files;

    setDrag(false);
    if (allowFileExtensions(uploadFiles).length) {
      setFiles([...(files || []), ...allowFileExtensions(uploadFiles)]);
      return;
    }
    setModalWindow({ isOpen: true, type: 'not-supported' });
  };

  const handleUploadFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const uploadFiles = e.target.files;

    if (allowFileExtensions(uploadFiles).length) {
      setFiles([...(files || []), ...(allowFileExtensions(uploadFiles) || [])]);
      return;
    }
    setModalWindow({ isOpen: true, type: 'not-supported' });
  };

  return (
    <MainWrap>
      <Header modalControl={<GoBackButton handleClose={() => goBack({ fallBack: '/investors' })} backToTitle={backToTitle} />} />
      <PageTitle title={pageProperties[sectionName as SectionNameType]?.title || ''} />
      <PaddingWrap>
        <AddDocumentForm>
          <AddDocumentWrapper isMobile={isMobile}>
            <TwoColumnWrapper isMobile={isMobile}>
              {sectionName === 'AML Documents' && (
                <div>
                  <Label required size="md">
                    Type
                  </Label>
                  <Controller
                    name="entityName"
                    control={control}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                      <>
                        <CustomSelect selected={'Select'} setSelected={onChange} data={types} width="100%" />
                        {error?.message && <ErrorText>{error.message}</ErrorText>}
                      </>
                    )}
                  />
                </div>
              )}
              <Controller
                name="name"
                control={control}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <Input
                    label="Description (250 Characters Max)"
                    value={value}
                    onChange={(e) => {
                      onChange(e.target.value);
                    }}
                    errorText={error?.message}
                    size="md"
                  />
                )}
              />
            </TwoColumnWrapper>
            <DropFileArea
              id="add-aml-document"
              handleUploadFile={handleUploadFile}
              onDropHandler={onDropHandler}
              drag={drag}
              setDrag={setDrag}
              maxSize={10}
              fileExtensions={fileExtensions}
            />
          </AddDocumentWrapper>
          <FormFooter onCancel={() => goBack({ fallBack: '/investors' })} disableSaveButton={false} />
        </AddDocumentForm>
      </PaddingWrap>
    </MainWrap>
  );
};

const AddDocumentForm = styled.form`
  margin-top: 50px;
`;

const AddDocumentWrapper = styled.div<{ isMobile: boolean }>`
  padding: ${({ isMobile }) => (isMobile ? '20px' : '40px')};
  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.border.base};
  background-color: ${({ theme }) => theme.layer[1]};
  display: flex;
  flex-direction: column;
  gap: ${({ isMobile }) => (isMobile ? '15px' : '25px')};
`;

const TwoColumnWrapper = styled.div<{ isMobile: boolean }>`
  display: grid;
  grid-template-columns: ${({ isMobile }) => (isMobile ? '1fr' : '1fr 1fr')};
  gap: ${({ isMobile }) => (isMobile ? '15px' : '30px')};
`;

const CustomSelect = styled(FilterSelect)`
  background-color: ${({ theme }) => theme.layer[1]};
  border: 1px solid ${({ theme }) => theme.border.base};
  padding: 10px;
  border-radius: 4px;

  #titleSelect {
    text-transform: none;
    font-family: Blinker;
    font-size: 16px;
    font-weight: 400;
    line-height: 22.4px;
  }

  #dropDownSelect {
    top: 50px;
  }
`;

const ErrorText = styled.p`
  color: ${({ theme }) => theme.context.error};
  font-family: Blinker;
  font-style: normal;
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;
`;
