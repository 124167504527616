export const advisoryFirmColumns = [
  { title: 'Advisory Firm', key: 'ADVISORY_FIRMS', width: 20, keySort: 'advisory firm' },
  { title: 'Investor Entities', key: 'INVESTOR_ENTITIES', width: 20, keySort: 'investor entities' },
  { title: 'Committed Capital', key: 'committedCapital', width: 20, keySort: 'committed capital' },
  { title: 'Capital Called', key: 'capitalCalled', width: 20, keySort: 'capital called' },
  { title: '%Capital Called', key: 'percentOfCapitalCalled', width: 20, keySort: '% capital called' }
  // { title: 'IRR', key: 'IRR', width: 12, keySort: 'irr' },
  // { title: 'TVPI', key: 'TVPI', width: 12, keySort: 'tvpi' },
];

export const investorColumns = [
  { title: 'Investor', key: 'name', width: 25, keySort: 'investor' },
  { title: 'Investor Entities', key: 'INVESTOR_ENTITIES', width: 18, keySort: 'investor entities' },
  { title: 'Advisory Firm', key: 'ADVISORY_FIRMS', width: 18, keySort: 'advisory firm' },
  { title: 'Committed Capital', key: 'committedCapital', width: 13, keySort: 'committed capital' },
  { title: 'Capital Called', key: 'capitalCalled', width: 13, keySort: 'capital called' },
  { title: '%Capital Called', key: 'percentOfCapitalCalled', width: 13, keySort: '% capital called' }
  // { title: 'IRR', key: 'IRR', width: 11, keySort: 'irr' },
  // { title: 'TVPI', key: 'TVPI', width: 11, keySort: 'tvpi' },
];

export const entityColumns = [
  { title: 'Entity', key: 'name', width: 20, keySort: 'entity' },
  { title: 'Investor', key: 'INVESTOR', width: 20, keySort: 'investor' },
  { title: 'Advisory Firm', key: 'ADVISORY_FIRMS', width: 20, keySort: 'advisory firm' },
  { title: 'Vehicle', key: 'VEHICLE', width: 14, keySort: 'vehicle' },
  { title: 'Committed Capital', key: 'committedCapital', width: 14, keySort: 'committed capital' },
  { title: 'Capital Called', key: 'capitalCalled', width: 14, keySort: 'capital called' },
  { title: '%Capital Called', key: 'percentOfCapitalCalled', width: 12, keySort: '% capital called' }
  // { title: 'IRR', key: 'IRR', width: 10, keySort: 'irr' },
  // { title: 'TVPI', key: 'TVPI', width: 12, keySort: 'tvpi' },
];

export const viewByList = ['Advisory Firm', 'Investor', 'Entity'];

export const advisoryFirmTableSort = [
  {
    title: 'Advisory Firm • A -> Z',
    key: 'ADVISORY_FIRMS',
    asc: true
  },
  {
    title: 'Advisory Firm • Z -> A',
    key: 'ADVISORY_FIRMS',
    asc: false
  },
  {
    title: 'Investor Entities • Max -> Min',
    key: 'INVESTOR_ENTITIES',
    asc: false
  },
  {
    title: 'Investor Entities • Min -> Max',
    key: 'INVESTOR_ENTITIES',
    asc: true
  },
  {
    title: 'Committed Capital • Max -> Min',
    key: 'committedCapital',
    asc: false
  },
  {
    title: 'Committed Capital • Min -> Max',
    key: 'committedCapital',
    asc: true
  },
  {
    title: 'Capital Called • Max -> Min',
    key: 'capitalCalled',
    asc: true
  },
  {
    title: 'Capital Called • Min -> Max',
    key: 'capitalCalled',
    asc: false
  },
  {
    title: '%Capital Called • Max -> Min',
    key: 'percentOfCapitalCalled',
    asc: false
  },
  {
    title: '%Capital Called • Min -> Max',
    key: 'percentOfCapitalCalled',
    asc: true
  }
];

export const investorTableSort = [
  {
    title: 'Investor • A -> Z',
    key: 'name',
    asc: true
  },
  {
    title: 'Investor • Z -> A',
    key: 'name',
    asc: false
  },
  {
    title: 'Investor Entities • Max -> Min',
    key: 'INVESTOR_ENTITIES',
    asc: false
  },
  {
    title: 'Investor Entities • Min -> Max',
    key: 'INVESTOR_ENTITIES',
    asc: true
  },
  {
    title: 'Advisory Firm • A -> Z',
    key: 'ADVISORY_FIRMS',
    asc: true
  },
  {
    title: 'Advisory Firm • Z -> A',
    key: 'ADVISORY_FIRMS',
    asc: false
  },
  {
    title: 'Committed Capital • Max -> Min',
    key: 'committedCapital',
    asc: false
  },
  {
    title: 'Committed Capital • Min -> Max',
    key: 'committedCapital',
    asc: true
  },
  {
    title: 'Capital Called • Max -> Min',
    key: 'capitalCalled',
    asc: true
  },
  {
    title: 'Capital Called • Min -> Max',
    key: 'capitalCalled',
    asc: false
  },
  {
    title: '%Capital Called • Max -> Min',
    key: 'percentOfCapitalCalled',
    asc: false
  },
  {
    title: '%Capital Called • Min -> Max',
    key: 'percentOfCapitalCalled',
    asc: true
  }
];

export const entityTableSort = [
  {
    title: 'Entity • A -> Z',
    key: 'name',
    asc: true
  },
  {
    title: 'Entity • Z -> A',
    key: 'name',
    asc: false
  },
  {
    title: 'Investor • A -> Z',
    key: 'INVESTOR',
    asc: true
  },
  {
    title: 'Investor • Z -> A',
    key: 'INVESTOR',
    asc: false
  },
  {
    title: 'Advisory Firm • A -> Z',
    key: 'ADVISORY_FIRMS',
    asc: true
  },
  {
    title: 'Advisory Firm • Z -> A',
    key: 'ADVISORY_FIRMS',
    asc: false
  },
  {
    title: 'Committed Capital • Max -> Min',
    key: 'committedCapital',
    asc: false
  },
  {
    title: 'Committed Capital • Min -> Max',
    key: 'committedCapital',
    asc: true
  },
  {
    title: 'Capital Called • Max -> Min',
    key: 'capitalCalled',
    asc: true
  },
  {
    title: 'Capital Called • Min -> Max',
    key: 'capitalCalled',
    asc: false
  },
  {
    title: '%Capital Called • Max -> Min',
    key: 'percentOfCapitalCalled',
    asc: false
  },
  {
    title: '%Capital Called • Min -> Max',
    key: 'percentOfCapitalCalled',
    asc: true
  }
];
