import styled from 'styled-components';

import { Loader } from '@/components/loaders/loader';
import { useQuery } from '@apollo/client';
import React, { useState } from 'react';
import { LIST_ALLOCATION_OPERATIONS_STATUS_QUERY } from '../queries';
import { IOperationsWorkflowTransitionTask, IRowData } from '../types';
import { CompletedStatusRow } from './CompletedStatusRow';
import { FutureStatusRow } from './FutureStatusRow';
import { NextStepStatusRow } from './NextStepStatusRow';
import { Step } from './types';

interface StepsListProps {
  row: IRowData;
  isMobile?: boolean;
}

export const StepsList = ({ row, isMobile = false }: StepsListProps) => {
  const [list, setList] = useState<Step[]>([]);

  const { loading } = useQuery<{ listAllocationOperationsStatusWorkflowTransitionTasks: IOperationsWorkflowTransitionTask[] }>(
    LIST_ALLOCATION_OPERATIONS_STATUS_QUERY,
    {
      notifyOnNetworkStatusChange: true,
      variables: {
        allocationId: row.id
      },
      onCompleted: (data) => {
        const listOperationsStatus = data.listAllocationOperationsStatusWorkflowTransitionTasks.map((task, index) => {
          return {
            id: task.id,
            lastUpdate: task.lastUpdate,
            status: task.name,
            date: index === 0 ? row.investmentApprovedAt : data.listAllocationOperationsStatusWorkflowTransitionTasks[index - 1].createdAt
          };
        });
        setList(listOperationsStatus);
      }
    }
  );

  if (loading) {
    return <Loader />;
  }

  if (!list.length) return <NoStatuses>There is no statuses</NoStatuses>;
  return (
    <Container>
      {list.map(({ id, status, lastUpdate, date }, index) => (
        <React.Fragment key={id}>
          {date && (
            <>
              <CompletedStatusRow isMobile={isMobile} status={lastUpdate} date={date} />
              {!list[index + 1]?.date && <NextStepStatusRow isMobile={isMobile} status={status} />}
            </>
          )}
          {!date && <FutureStatusRow isMobile={isMobile} index={index} status={status} />}
        </React.Fragment>
      ))}
    </Container>
  );
};

const NoStatuses = styled.span`
  text-align: left;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 3px;

  * {
    white-space: nowrap;
  }
`;
const WrapperRow = styled.div<{ background?: string }>`
  width: 100%;
`;
export const Status = styled.span<{ color: string; isMobile?: boolean }>`
  display: inline-block;
  width: ${({ isMobile }) => (isMobile ? '70%' : '100%')};
  overflow: ${({ isMobile }) => (isMobile ? 'hidden' : 'none')};
  text-overflow: ${({ isMobile }) => (isMobile ? 'ellipsis' : 'none')};
  //white-space: nowrap;
  user-select: ${({ isMobile }) => (isMobile ? 'none' : 'auto')};
  color: ${({ color }) => color};
  padding-left: 10px;
  font-family: 'Blinker', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
`;

export const StatusIconWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
