import * as React from 'react';

export const SortIcon = ({ className, ...props }: React.SVGProps<SVGSVGElement>) => (
  <svg className={className} style={props.style} width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.72041 5.25115C5.32701 5.65999 4.67266 5.65999 4.27926 5.25115L0.855788 1.69337C0.244466 1.05807 0.694704 -9.59185e-07 1.57636 -8.82108e-07L8.42331 -2.83529e-07C9.30497 -2.06452e-07 9.75521 1.05807 9.14389 1.69337L5.72041 5.25115Z"
      fill={props.fill || '#FAFAFA'}
    />
  </svg>
);
