import { getUTCDate } from '@/components/allocations-page/utils';
import BasicTooltip from '@/components/basicComponents/tooltip';
import FormattingTooltip from '@/components/basicComponents/tooltip/dollarsTooltip';
import { formatterClient } from '@/components/clients-page/utils';
import NoResults from '@/components/table/noResults';
import RedirectTitleCell from '@/components/table/redirectTitleCell';
import { TableBodyWrapper, TableRowCell, TableRowWrapper, TextCell } from '@/components/table/styles';
import { formatCash } from '@/utils/formatCash';
import guid from '@/utils/guid';
import styled from 'styled-components';
import { commitmentsTablecolumns } from '../constatnts';
import { CommitmentsType } from '../types';

interface TableBodyProps {
  investorCommitmentRows: CommitmentsType[];
  openOpportunityDetailsPage: (id: string) => void;
}

const TableBody = ({ investorCommitmentRows, openOpportunityDetailsPage }: TableBodyProps) => {
  if (!investorCommitmentRows?.length) {
    return <NoResults />;
  }

  const getColorStatus = (status: string) => {
    if (status === 'Active') {
      return '#3DBD4A';
    } else if (status === 'Recommended' || status === 'Outstanding') {
      return '#DFC902';
    } else {
      return '#111827';
    }
  };

  const toolTipContent = (row: CommitmentsType) => {
    return (
      <TableBodyWrapper>
        <TableRowCell width={100}>
          <TextCell>{formatCash(row.committedCapital)} Committed Capital</TextCell>
        </TableRowCell>
        <TableRowCell width={100}>
          <TextCell>{formatCash(row.capitalCalled)} Capital Called</TextCell>
        </TableRowCell>
        <TableRowCell width={100}>
          <TextCell>{formatCash(row.capitalRemaining)} Capital Remaining</TextCell>
        </TableRowCell>
        <TableRowCell width={100}>
          <TextCell>{((row.capitalCalled / row.committedCapital) * 100).toFixed(2)}% Called to Date</TextCell>
        </TableRowCell>
      </TableBodyWrapper>
    );
  };

  const marketValueToolTipContent = (row: CommitmentsType) => {
    return (
      <TableBodyWrapper>
        {row.valuationDate && (
          <>
            <TableRowCell width={100}>
              <TextCell>Valuation Date: {row.valuationAmount ? getUTCDate(row.valuationDate) : 'None'}</TextCell>
            </TableRowCell>
            {row.valuationAmount !== null && (
              <TableRowCell width={100}>
                <TextCell>Valuation Amount: {formatterClient.format(row.valuationAmount)}</TextCell>
              </TableRowCell>
            )}
          </>
        )}
        {row.aggregateCallsAndDistributions !== null && (
          <TableRowCell width={100}>
            <TextCell>Aggregate Calls and Distributions since last valuation: {formatterClient.format(row.aggregateCallsAndDistributions)}</TextCell>
          </TableRowCell>
        )}
        <TableRowCell width={100}>
          <TextCell>Market Value: {formatterClient.format(row.marketValue)}</TextCell>
        </TableRowCell>
      </TableBodyWrapper>
    );
  };

  return (
    <>
      {investorCommitmentRows.map((row) => (
        <TableRowWrapper key={guid()}>
          <TableRowCell width={commitmentsTablecolumns[0].width}>
            <RedirectTitleCell id="actionCurioText" onClick={() => openOpportunityDetailsPage(row.investmentId)}>
              {row.name}
            </RedirectTitleCell>
            {/* <AnchorPopover
              title={row.name}
              hyperTitle={() => openOpportunityDetailsPage(row.investmentId)}
              rowBody={<RedirectTitleCell id="actionCurioText">{row.name}</RedirectTitleCell>}
            >
              <InfoList title={row.name} data={row} openOpportunityDetailsPage={openOpportunityDetailsPage} />
            </AnchorPopover> */}
          </TableRowCell>
          <TableRowCell width={commitmentsTablecolumns[1].width}>
            <TextCell>{row.subAssetClass ? `${row.assetClass?.name} - ${row.subAssetClass.name}` : row.assetClass?.name}</TextCell>
          </TableRowCell>
          <TableRowCell width={commitmentsTablecolumns[2].width}>
            <TextCell>
              <FormattingTooltip zIndex={1000}>{row.committedCapital}</FormattingTooltip>
            </TextCell>
          </TableRowCell>
          <TableRowCell width={commitmentsTablecolumns[3].width}>
            <TextCell>
              <CellWithTooltip>
                {((row.capitalCalled / row.committedCapital) * 100).toFixed(2)}%
                <TooltipWrapper id="infoWrap">
                  <BasicTooltip tooltipContent={toolTipContent(row)} zIndex={1000} withTooltipIcon />
                </TooltipWrapper>
              </CellWithTooltip>
            </TextCell>
          </TableRowCell>
          <TableRowCell width={commitmentsTablecolumns[4].width}>
            <TextCell>
              <FormattingTooltip zIndex={1000}>{+row.distributionsToDate}</FormattingTooltip>
            </TextCell>
          </TableRowCell>
          <TableRowCell width={commitmentsTablecolumns[5].width}>
            <TextCell>
              {row.valuationDate || row.valuationAmount || row.aggregateCallsAndDistributions || row.marketValue ? (
                <CellWithTooltip>
                  {formatCash(row.marketValue)}
                  <TooltipWrapper id="infoWrap">
                    <BasicTooltip tooltipContent={marketValueToolTipContent(row)} withTooltipIcon zIndex={1000} />
                  </TooltipWrapper>
                </CellWithTooltip>
              ) : (
                <FormattingTooltip zIndex={1000}>{row.marketValue}</FormattingTooltip>
              )}
            </TextCell>
          </TableRowCell>
          <TableRowCell width={commitmentsTablecolumns[6].width}>
            <ColorTitle color={getColorStatus(row.status)}>{row.status === 'Outstanding' ? 'Pending Commitment' : row.status}</ColorTitle>
          </TableRowCell>
        </TableRowWrapper>
      ))}
    </>
  );
};

export default TableBody;

const ColorTitle = styled(TextCell)<{ color: string }>`
  color: ${({ color }) => color};
  font-weight: 600;
`;

const CellWithTooltip = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const TooltipWrapper = styled.div`
  opacity: 0;
`;
