import { GroupedIcon } from '@/assets/icons/info-icons/groupedIcon';
import { MinusIcon } from '@/assets/icons/info-icons/minusIcon';
import { PlusIcon } from '@/assets/icons/info-icons/plusIcon';
import BasicTooltip from '@/components/basicComponents/tooltip';
import FormattingTooltip from '@/components/basicComponents/tooltip/dollarsTooltip';
import { formatterClient } from '@/components/clients-page/utils';
import { TableBodyWrapper, TableRowCell, TableRowWrapper, TitleCell } from '@/components/table/styles';
import { formatCash } from '@/utils/formatCash';
import { useState } from 'react';
import styled, { useTheme } from 'styled-components';
import {
  overCommitmentRatioModeColumns,
  overCommitmentRatioModeSimplifiedColumns,
  pacingModelModeColumns,
  pacingModelModeSimplifiedColumns
} from '../constatnts';
import { AssetClassTargetAllocationDto, SubAssetClassTargetAllocationDto } from '../types';
import CapacityCell from './cell/capacityCell';

interface TableRowProps {
  row: AssetClassTargetAllocationDto;
  ignoreAllocationTargets: boolean;
  targetSubAssetClasses: boolean;
  commitmentPlansTotal: number | null;
  targetOCRatio: number | null;
}

const economicExposureToolTipContent = (row: AssetClassTargetAllocationDto | SubAssetClassTargetAllocationDto) => {
  return (
    <TableBodyWrapper>
      <TableRowCell width={100}>
        <TextCell>Current NAV: {formatterClient.format(row.currentNAV)}</TextCell>
      </TableRowCell>
      <TableRowCell width={100}>
        <TextCell>Unfunded Commitment: {formatterClient.format(row.unfundedCommitments)}</TextCell>
      </TableRowCell>
      <TableRowCell width={100}>
        <TextCell>Economic Exposure: {formatterClient.format(row.economicExposure)}</TextCell>
      </TableRowCell>
    </TableBodyWrapper>
  );
};

const TableRow = ({ row, ignoreAllocationTargets, targetSubAssetClasses, commitmentPlansTotal, targetOCRatio }: TableRowProps) => {
  const theme = useTheme();

  const [showSubAssetClass, setShowSubAssetClass] = useState(false);

  if (ignoreAllocationTargets && targetOCRatio !== null) {
    return (
      <>
        <TableRowWrapper>
          <TableRowCell width={overCommitmentRatioModeSimplifiedColumns[0].width}>
            <AssetClassCell>
              {targetSubAssetClasses && row.subAssetClasses && (
                <PlusIconWrapper onClick={() => setShowSubAssetClass(!showSubAssetClass)}>
                  {showSubAssetClass ? <MinusIcon fill={theme.font.base} /> : <PlusIcon fill={theme.font.base} />}
                </PlusIconWrapper>
              )}
              <TextCell>{row.name}</TextCell>
            </AssetClassCell>
          </TableRowCell>
          <TableRowCell width={overCommitmentRatioModeSimplifiedColumns[1].width}>
            <TextCell bold={Boolean(targetSubAssetClasses && row.subAssetClasses)}>
              <FormattingTooltip zIndex={1000}>{row.economicExposure}</FormattingTooltip>
            </TextCell>
          </TableRowCell>
        </TableRowWrapper>
        {targetSubAssetClasses && row.subAssetClasses && showSubAssetClass && (
          <>
            {row.subAssetClasses.map((subAssetClass) => (
              <TableRowWrapper key={subAssetClass.id}>
                <TableRowCell width={overCommitmentRatioModeSimplifiedColumns[0].width}>
                  <AssetClassCell>
                    <GroupIconWrapper>
                      <GroupedIcon width={26} height={26} />
                    </GroupIconWrapper>
                    <TextCell>{subAssetClass.name}</TextCell>
                  </AssetClassCell>
                </TableRowCell>
                <TableRowCell width={overCommitmentRatioModeSimplifiedColumns[1].width}>
                  <TextCell>
                    <FormattingTooltip zIndex={1000}>{subAssetClass.economicExposure}</FormattingTooltip>
                  </TextCell>
                </TableRowCell>
              </TableRowWrapper>
            ))}
          </>
        )}
      </>
    );
  }

  if (ignoreAllocationTargets) {
    return (
      <>
        <TableRowWrapper>
          <TableRowCell width={pacingModelModeSimplifiedColumns[0].width}>
            <AssetClassCell>
              {targetSubAssetClasses && row.subAssetClasses && (
                <PlusIconWrapper onClick={() => setShowSubAssetClass(!showSubAssetClass)}>
                  {showSubAssetClass ? <MinusIcon fill={theme.font.base} /> : <PlusIcon fill={theme.font.base} />}
                </PlusIconWrapper>
              )}
              <TextCell>{row.name}</TextCell>
            </AssetClassCell>
          </TableRowCell>
          <TableRowCell width={pacingModelModeSimplifiedColumns[1].width}>
            <TextCell bold={Boolean(targetSubAssetClasses && row.subAssetClasses)}>
              <FormattingTooltip zIndex={1000}>{row.currentNAV}</FormattingTooltip>
            </TextCell>
          </TableRowCell>
          <TableRowCell width={pacingModelModeSimplifiedColumns[2].width}>
            <TextCell bold={Boolean(targetSubAssetClasses && row.subAssetClasses)}>
              <FormattingTooltip zIndex={1000}>{row.unfundedCommitments}</FormattingTooltip>
            </TextCell>
          </TableRowCell>
        </TableRowWrapper>
        {targetSubAssetClasses && row.subAssetClasses && showSubAssetClass && (
          <>
            {row.subAssetClasses.map((subAssetClass) => (
              <TableRowWrapper key={subAssetClass.id}>
                <TableRowCell width={pacingModelModeSimplifiedColumns[0].width}>
                  <AssetClassCell>
                    <GroupIconWrapper>
                      <GroupedIcon width={26} height={26} />
                    </GroupIconWrapper>
                    <TextCell>{subAssetClass.name}</TextCell>
                  </AssetClassCell>
                </TableRowCell>
                <TableRowCell width={pacingModelModeSimplifiedColumns[1].width}>
                  <TextCell>
                    <FormattingTooltip zIndex={1000}>{subAssetClass.currentNAV}</FormattingTooltip>
                  </TextCell>
                </TableRowCell>
                <TableRowCell width={pacingModelModeSimplifiedColumns[2].width}>
                  <TextCell>
                    <FormattingTooltip zIndex={1000}>{subAssetClass.unfundedCommitments}</FormattingTooltip>
                  </TextCell>
                </TableRowCell>
              </TableRowWrapper>
            ))}
          </>
        )}
      </>
    );
  }

  if (targetOCRatio === null) {
    return (
      <>
        <TableRowWrapper>
          <TableRowCell width={pacingModelModeColumns[0].width}>
            <AssetClassCell>
              {targetSubAssetClasses && row.subAssetClasses && (
                <PlusIconWrapper onClick={() => setShowSubAssetClass(!showSubAssetClass)}>
                  {showSubAssetClass ? <MinusIcon fill={theme.font.base} /> : <PlusIcon fill={theme.font.base} />}
                </PlusIconWrapper>
              )}
              <TextCell>{row.name}</TextCell>
            </AssetClassCell>
          </TableRowCell>
          <TableRowCell width={pacingModelModeColumns[1].width}>
            <TextCell bold={Boolean(targetSubAssetClasses && row.subAssetClasses)}>
              {row.percentage === null ? (
                '--'
              ) : (
                <BasicTooltip tooltipContent={`${row.percentage.toFixed(2)}%`} zIndex={1000}>
                  {row.percentage.toFixed(0)}%
                </BasicTooltip>
              )}
            </TextCell>
          </TableRowCell>
          <TableRowCell width={pacingModelModeColumns[2].width}>
            <TextCell bold={Boolean(targetSubAssetClasses && row.subAssetClasses)}>
              <FormattingTooltip zIndex={1000}>{row.targetAllocation}</FormattingTooltip>
            </TextCell>
          </TableRowCell>
          <TableRowCell width={pacingModelModeColumns[3].width}>
            <TextCell bold={Boolean(targetSubAssetClasses && row.subAssetClasses)}>
              <FormattingTooltip zIndex={1000}>{row.currentNAV}</FormattingTooltip>
            </TextCell>
          </TableRowCell>
          <TableRowCell width={pacingModelModeColumns[4].width}>
            <TextCell bold={Boolean(targetSubAssetClasses && row.subAssetClasses)}>
              <FormattingTooltip zIndex={1000}>{row.unfundedCommitments}</FormattingTooltip>
            </TextCell>
          </TableRowCell>
          <TableRowCell width={pacingModelModeColumns[5].width}>
            <CapacityCell
              row={row}
              bold={Boolean(targetSubAssetClasses && row.subAssetClasses)}
              commitmentPlansTotal={commitmentPlansTotal}
              targetOCRatio={targetOCRatio}
            />
          </TableRowCell>
        </TableRowWrapper>
        {targetSubAssetClasses && row.subAssetClasses && showSubAssetClass && (
          <>
            {row.subAssetClasses.map((subAssetClass) => (
              <TableRowWrapper key={subAssetClass.id}>
                <TableRowCell width={pacingModelModeColumns[0].width}>
                  <AssetClassCell>
                    <GroupIconWrapper>
                      <GroupedIcon width={26} height={26} />
                    </GroupIconWrapper>
                    <TextCell>{subAssetClass.name}</TextCell>
                  </AssetClassCell>
                </TableRowCell>
                <TableRowCell width={pacingModelModeColumns[1].width}>
                  <TextCell>
                    {subAssetClass.percentage === null ? (
                      '--'
                    ) : (
                      <BasicTooltip tooltipContent={`${subAssetClass.percentage.toFixed(2)}%`} zIndex={1000}>
                        {subAssetClass.percentage.toFixed(0)}%
                      </BasicTooltip>
                    )}
                  </TextCell>
                </TableRowCell>
                <TableRowCell width={pacingModelModeColumns[2].width}>
                  <TextCell>
                    <FormattingTooltip zIndex={1000}>{subAssetClass.targetAllocation}</FormattingTooltip>
                  </TextCell>
                </TableRowCell>
                <TableRowCell width={pacingModelModeColumns[3].width}>
                  <TextCell>
                    <FormattingTooltip zIndex={1000}>{subAssetClass.currentNAV}</FormattingTooltip>
                  </TextCell>
                </TableRowCell>
                <TableRowCell width={pacingModelModeColumns[4].width}>
                  <TextCell>
                    <FormattingTooltip zIndex={1000}>{subAssetClass.unfundedCommitments}</FormattingTooltip>
                  </TextCell>
                </TableRowCell>
                <TableRowCell width={pacingModelModeColumns[5].width}>
                  <CapacityCell row={subAssetClass} commitmentPlansTotal={commitmentPlansTotal} targetOCRatio={targetOCRatio} />
                </TableRowCell>
              </TableRowWrapper>
            ))}
          </>
        )}
      </>
    );
  }

  return (
    <>
      <TableRowWrapper>
        <TableRowCell width={overCommitmentRatioModeColumns[0].width}>
          <AssetClassCell>
            {targetSubAssetClasses && row.subAssetClasses && (
              <PlusIconWrapper onClick={() => setShowSubAssetClass(!showSubAssetClass)}>
                {showSubAssetClass ? <MinusIcon fill={theme.font.base} /> : <PlusIcon fill={theme.font.base} />}
              </PlusIconWrapper>
            )}
            <TextCell>{row.name}</TextCell>
          </AssetClassCell>
        </TableRowCell>
        <TableRowCell width={overCommitmentRatioModeColumns[1].width}>
          <TextCell bold={Boolean(targetSubAssetClasses && row.subAssetClasses)}>
            {row.percentage === null ? (
              '--'
            ) : (
              <BasicTooltip tooltipContent={`${row.percentage.toFixed(2)}%`} zIndex={1000}>
                {row.percentage.toFixed(0)}%
              </BasicTooltip>
            )}
          </TextCell>
        </TableRowCell>
        <TableRowCell width={overCommitmentRatioModeColumns[2].width}>
          <TextCell bold={Boolean(targetSubAssetClasses && row.subAssetClasses)}>
            <FormattingTooltip zIndex={1000}>{row.targetAllocation}</FormattingTooltip>
          </TextCell>
        </TableRowCell>
        <TableRowCell width={overCommitmentRatioModeColumns[3].width}>
          <TextCell bold={Boolean(targetSubAssetClasses && row.subAssetClasses)}>
            <CellWithTooltip>
              {formatCash(row.economicExposure)}
              <TooltipWrapper id="infoWrap">
                <BasicTooltip tooltipContent={economicExposureToolTipContent(row)} withTooltipIcon zIndex={1000} />
              </TooltipWrapper>
            </CellWithTooltip>
          </TextCell>
        </TableRowCell>
        <TableRowCell width={overCommitmentRatioModeColumns[4].width}>
          <CapacityCell
            row={row}
            bold={Boolean(targetSubAssetClasses && row.subAssetClasses)}
            commitmentPlansTotal={commitmentPlansTotal}
            targetOCRatio={targetOCRatio}
          />
        </TableRowCell>
      </TableRowWrapper>
      {targetSubAssetClasses && row.subAssetClasses && showSubAssetClass && (
        <>
          {row.subAssetClasses.map((subAssetClass) => (
            <TableRowWrapper key={subAssetClass.id}>
              <TableRowCell width={overCommitmentRatioModeColumns[0].width}>
                <AssetClassCell>
                  <GroupIconWrapper>
                    <GroupedIcon width={26} height={26} />
                  </GroupIconWrapper>
                  <TextCell>{subAssetClass.name}</TextCell>
                </AssetClassCell>
              </TableRowCell>
              <TableRowCell width={overCommitmentRatioModeColumns[1].width}>
                <TextCell>
                  {subAssetClass.percentage === null ? (
                    '--'
                  ) : (
                    <BasicTooltip tooltipContent={`${subAssetClass.percentage.toFixed(2)}%`} zIndex={1000}>
                      {subAssetClass.percentage.toFixed(0)}%
                    </BasicTooltip>
                  )}
                </TextCell>
              </TableRowCell>
              <TableRowCell width={overCommitmentRatioModeColumns[2].width}>
                <TextCell>
                  <FormattingTooltip zIndex={1000}>{subAssetClass.targetAllocation}</FormattingTooltip>
                </TextCell>
              </TableRowCell>
              <TableRowCell width={overCommitmentRatioModeColumns[3].width}>
                <TextCell>
                  <CellWithTooltip>
                    {formatCash(subAssetClass.economicExposure)}
                    <TooltipWrapper id="infoWrap">
                      <BasicTooltip tooltipContent={economicExposureToolTipContent(subAssetClass)} withTooltipIcon zIndex={1000} />
                    </TooltipWrapper>
                  </CellWithTooltip>
                </TextCell>
              </TableRowCell>
              <TableRowCell width={overCommitmentRatioModeColumns[4].width}>
                <CapacityCell row={subAssetClass} commitmentPlansTotal={commitmentPlansTotal} targetOCRatio={targetOCRatio} />
              </TableRowCell>
            </TableRowWrapper>
          ))}
        </>
      )}
    </>
  );
};

export default TableRow;

const AssetClassCell = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
`;

const PlusIconWrapper = styled.div`
  cursor: pointer;
  border-radius: 12px;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: ${({ theme }) => theme.action.hover};
    transition: background-color 100ms ease;
  }
`;

const GroupIconWrapper = styled.div`
  padding: 5px;
  position: relative;
  top: -9px;
  margin-right: 14px;
  margin-left: 5px;
`;

const TextCell = styled(TitleCell)<{ bold?: boolean }>`
  font-weight: ${({ bold }) => (bold ? 600 : 400)};
`;

const CellWithTooltip = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const TooltipWrapper = styled.div`
  opacity: 0;
`;
