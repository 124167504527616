import StatusDataContext from '@/contexts/status-data-context';
import axios, { AxiosError } from 'axios';
import React, { useEffect, useState } from 'react';

export interface IStatusData {
  fe_build: string;
  maintenance_mode: boolean;
  target_sub_asset_classes: boolean;
  pacing_model_enabled: boolean;
  subdoc_enabled: boolean;
}

export const StatusDataProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [data, setData] = useState<IStatusData | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_BASE_URL}/system/status`);
        setData(response.data);
      } catch (err) {
        const error = err as AxiosError;
        console.error(error);
        setError(error?.message ?? 'An error occurred');
        setLoading(false);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  return <StatusDataContext.Provider value={{ data, loading, error }}>{children}</StatusDataContext.Provider>;
};
