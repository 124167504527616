import { gql } from '@apollo/client';

export const getCurrentManagers = gql`
  query getCurrentManagers($withCommitments: Boolean) {
    getCurrentManagers(withCommitments: $withCommitments) {
      values
    }
  }
`;

export const INVESTMENT_MANAGERS_QUERY = gql`
  query InvestmentManagers(
    $page: Int
    $rowsPerPage: Int
    $sort: String
    $order: String
    $managerId: String
    $search: String
    $withCommitments: Boolean
  ) {
    InvestmentManagers(
      page: $page
      rowsPerPage: $rowsPerPage
      sort: $sort
      order: $order
      managerId: $managerId
      search: $search
      withCommitments: $withCommitments
    ) {
      total
      page
      perPage
      managers {
        name
        assetClasses
        countOfFunds
        countOfInvestors
        committedCapital
        percentOfTotalActiveCommmitments
      }
    }
  }
`;

export const INVESTMENTS_QUERY = gql`
  query ManagerInvestments($managerId: String, $page: Int, $rowsPerPage: Int, $sort: String, $order: String, $search: String) {
    ManagerInvestments(managerId: $managerId, page: $page, rowsPerPage: $rowsPerPage, sort: $sort, order: $order, search: $search) {
      total
      page
      perPage
      totalInvestors
      totalCommittedCapital
      investments {
        id
        managerId
        name
        assetClass {
          id
          name
        }
        maxCalledPercent
        countOfInvestors
        committedCapital
      }
    }
  }
`;

export const MANAGER_QUERY = gql`
  query InvestmentManager($managerId: String!) {
    InvestmentManager(managerId: $managerId) {
      name
      assetClasses
      sectors
      economicExposure
      totalActiveCommitments
    }
  }
`;

export const GET_INVESTMENT_ENTITIES_BY_ADVISORY_FIRM_TOTAL_QUERY = gql`
  query InvestmentEntitiesByAdvisoryFirm($id: String!) {
    InvestmentEntitiesByAdvisoryFirm(id: $id) {
      total
    }
  }
`;

export const GET_COMMITMENTS_BY_INVESTOR_TOTAL_QUERY = gql`
  query CommitmentsByInvestor($investmentId: String!) {
    CommitmentsByInvestor(investmentId: $investmentId) {
      total
    }
  }
`;

export const GET_INVESTMENT_ENTITIES_TOTAL_QUERY = gql`
  query InvestmentEntities($id: String!) {
    InvestmentEntities(id: $id) {
      total
    }
  }
`;

export const INVESTMENT_ENTITIES_QUERY = gql`
  query InvestmentEntities(
    $id: String!
    $sort: String
    $order: String
    $search: String
    $quickFilter: String
    $page: Int
    $rowsPerPage: Int
    $vehicleFilter: [String!]
  ) {
    InvestmentEntities(
      id: $id
      sort: $sort
      order: $order
      search: $search
      quickFilter: $quickFilter
      page: $page
      rowsPerPage: $rowsPerPage
      vehicleFilter: $vehicleFilter
    ) {
      total
      page
      perPage
      entities {
        id
        name
        family
        familyId
        committedCapital
        capitalCalled
        percentOfCapitalCalled
        IRR
        TVPI
        tenant {
          name
        }
        vehicle
      }
    }
  }
`;

export const INVESTMENT_ENTITIES_BY_ADVISORY_FIRM_QUERY = gql`
  query InvestmentEntitiesByAdvisoryFirm(
    $id: String!
    $sort: String
    $order: String
    $search: String
    $quickFilter: String
    $page: Int
    $rowsPerPage: Int
    $vehicleFilter: [String!]
  ) {
    InvestmentEntitiesByAdvisoryFirm(
      id: $id
      sort: $sort
      order: $order
      search: $search
      quickFilter: $quickFilter
      page: $page
      rowsPerPage: $rowsPerPage
      vehicleFilter: $vehicleFilter
    ) {
      total
      page
      perPage
      firms {
        tenant {
          id
          name
        }
        investorEntities
        committedCapital
        capitalCalled
        percentOfCapitalCalled
        IRR
        TVPI
      }
      filters {
        name
        type
        value
        enabled
        count
      }
    }
  }
`;

export const COMMITMENTS_BY_INVESTOR_QUERY = gql`
  query CommitmentsByInvestor(
    $investmentId: String!
    $sort: String
    $order: String
    $search: String
    $quickFilter: String
    $page: Int
    $rowsPerPage: Int
    $vehicleFilter: [String!]
  ) {
    CommitmentsByInvestor(
      investmentId: $investmentId
      sort: $sort
      order: $order
      search: $search
      quickFilter: $quickFilter
      page: $page
      rowsPerPage: $rowsPerPage
      vehicleFilter: $vehicleFilter
    ) {
      total
      page
      perPage
      investors {
        investor {
          id
          name
        }
        tenant {
          name
        }
        committedCapital
        capitalCalled
        percentOfCapitalCalled
        investorEntities
      }
      filters {
        name
        type
        value
        enabled
        count
      }
    }
  }
`;

export const INVESTMENT_QUERY = gql`
  query Investment($id: String!) {
    Investment(id: $id) {
      id
      symbol
      name
      type
      managerId
      assetClass {
        id
        name
      }
      subAssetClass {
        id
        name
      }
      sectors
      averageCalledPercent
      countOfInvestors
      committedCapital
      economicExposure
      targetReturn
      targetYield
      vintageYear
      nextClose
      finalClose
      investorDocsDue
      minimum
      sri
      ubtiBlocker
      subAssetClasses
      tags
      description
      securityStatus
      distributionsToDate
      investmentOwner {
        firstName
        lastName
        email
      }
      operationsOwner {
        firstName
        lastName
        email
      }
      complianceOwner {
        firstName
        lastName
        email
      }
      offeringMaterialsDescription
      offeringMaterialsAttachments {
        id
        asset {
          url
          assetKey
        }
      }
    }
  }
`;
