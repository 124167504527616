import { useFormik } from 'formik';
import { useState } from 'react';
import * as Yup from 'yup';

// import { lightTheme } from '@/styles/theme';
import styled, { useTheme } from 'styled-components';
import { useAuth } from '../../hooks/use-auth';
import { useMounted } from '../../hooks/use-mounted';
import { useResponsive } from '../../hooks/use-responsive';
import Label from '../basicComponents/label';
import Button from '../fat-basicComponents/button';
import { InputFieldProps } from '../input-fields/fat-index';
import { LinksProps } from '../links';

const ResetPassword = () => {
  const isMounted = useMounted();
  const { isMobile, isTablet, isPortrait } = useResponsive();
  const theme = useTheme();

  const [isRequestedReset, setIsRequestedReset] = useState<boolean>(false);
  const { resetPassword } = useAuth();
  const formik = useFormik({
    initialValues: {
      email: '',
      submit: null,
      isSubmitting: true
    },
    validationSchema: Yup.object({
      email: Yup.string().email('Must be a valid email').max(255).required('Email is required')
    }),
    onSubmit: async (values, helpers): Promise<void> => {
      try {
        resetPassword(values.email);
        setIsRequestedReset(true);
      } catch (err: any) {
        console.error(err);
        if (isMounted()) {
          helpers.setStatus({ success: false });
          helpers.setErrors({ submit: err.message });
          helpers.setSubmitting(false);
        }
      }
    }
  });

  return (
    <ResetPasswordWrapper isMobile={isMobile} isTablet={isTablet} isPortrait={isPortrait}>
      {isRequestedReset ? (
        <div style={{ width: '100%', textAlign: 'center' }}>
          If your email address is registered you will receive a password reset email in a few minutes.
        </div>
      ) : (
        <>
          <p
            style={{
              paddingBottom: '44px',
              color: 'passwordLabelText',
              fontWeight: 500,
              fontSize: 16,
              width: '100%',
              textAlign: 'center'
            }}
          >
            Please enter your email address to receive a reset password.
          </p>
          <form noValidate onSubmit={formik.handleSubmit}>
            <Label required>Enter Your Email</Label>
            <InputFieldProps
              error={Boolean(formik.touched.email && formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
              name="email"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              type="email"
              value={formik.values.email}
            />

            <RequestResetButtonWrapper>
              <Button
                id="sign-in-button"
                styleType={formik.isSubmitting || !formik.isValid ? 'disabled' : 'default'}
                type="submit"
                size="lg"
                width="100%"
              >
                Request Reset
              </Button>
            </RequestResetButtonWrapper>
          </form>
        </>
      )}
      <BackToWrapper>
        <CustomLinksProps underline="none" textColor={theme.font.action} url={'/'}>
          Back to log in
        </CustomLinksProps>
      </BackToWrapper>
    </ResetPasswordWrapper>
  );
};

export default ResetPassword;

const ResetPasswordWrapper = styled.div<{ isMobile: boolean; isPortrait: boolean; isTablet: boolean }>`
  width: ${({ isMobile, isPortrait, isTablet }) => (isMobile || (isPortrait && isTablet) ? '70%' : '50%')};
  font-size: 19px;
`;

const BackToWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

const CustomLinksProps = styled(LinksProps)`
  font-weight: 600;
`;

const RequestResetButtonWrapper = styled.div`
  margin-top: 30px;
`;
