import { useMutation } from '@apollo/client';
import { useNavigate, useLocation } from 'react-router-dom';
import { useState } from 'react';
import Label from '@/components/basicComponents/label';
import ModalWrappedContent from '@/components/basicComponents/modal/modalWrappedContent';
import {
  ISection,
  ITier
} from '@/components/dueDiligence-page/dueDiligenceRecord/types';
import {
  CREATE_DUE_DILIGENCE_SECTION,
  GET_FUNNEL_QUERY
} from '@/components/settings-page/queries';
import { MainSearch, ModalContainer, ModalTitle } from './addFunnel';

interface DuplicateSectionProps {
  onClose: () => void;
  isOpen: boolean;
  tiersData: ITier[];
  tierId: string;
  currentSection: ISection | null;
}

const DuplicateSection = ({
  onClose,
  isOpen,
  tiersData,
  tierId,
  currentSection
}: DuplicateSectionProps) => {
  if (!currentSection) return <></>;
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const [value, setValue] = useState('');

  const [createSection] = useMutation(CREATE_DUE_DILIGENCE_SECTION, {
    refetchQueries: () => [
      {
        query: GET_FUNNEL_QUERY,
        variables: {
          id: queryParams.get('funnelId')
        }
      }
    ]
  });

  const saveValue = () => {
    const updatedFields = currentSection.fields.map((field) => {
      return {
        name: field.name,
        required: field.required,
        type: field.type,
        linkedFieldId: null,
        promptGroup: field.promptGroup
          ? {
              prompts: field.promptGroup.prompts,
              summaryPrompt: field.promptGroup.summaryPrompt,
              contextPrompt: field.promptGroup.contextPrompt,
              rubricTemplates: field.promptGroup.rubricTemplates
            }
          : null
      };
    });
    const numberOfSections =
      tiersData.find((tier) => tier.id === tierId)?.sections.length ?? 0;

    createSection({
      variables: {
        data: {
          funnelTierId: tierId,
          name: value,
          userCanScore: currentSection.userCanScore,
          order: numberOfSections + 1,
          pdfLayout: currentSection.pdfLayout,
          fields: updatedFields
        }
      }
    });

    onClose();
  };

  return (
    <ModalWrappedContent
      isOpen={isOpen}
      onClose={onClose}
      confirmButton={{ name: 'Save', onClick: saveValue, disabled: !value }}
    >
      <ModalTitle>Duplicate Section</ModalTitle>
      <ModalContainer>
        <Label required>Name</Label>
        <MainSearch
          type="text"
          placeholder="Give this Section a unique name"
          value={value}
          onChange={(event) => setValue(event.target.value)}
          autoFocus
        />
      </ModalContainer>
    </ModalWrappedContent>
  );
};

export default DuplicateSection;
