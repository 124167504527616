import { useQuery } from '@apollo/client';
import { useMemo } from 'react';
import styled from 'styled-components';

import FormattingTooltip from '@/components/basicComponents/tooltip/dollarsTooltip';
import { Loader } from '@/components/loaders/loader';
import { MobileRow, MobileRowTitle, MobileRowValue, MobileTableRowWrap } from '@/components/table/mobileTable/mobileTable';
import { TableColumns } from '@/components/table/types';
import guid from '@/utils/guid';
import { INVESTMENT_MANAGERS_QUERY } from '../queries';

type MobileTableProps = {
  columns: TableColumns[];
  loading: boolean;
};

type RowSumType = {
  total: string;
  totalFunds: number;
  totalInvestors: number;
  totalCommittedCapital: number;
  countOfFunds: number;
  countOfInvestors: number;
  committedCapital: number;
  percentOfTotalActiveCommmitments: number;
  totalActiveCommitments: number;
};

const MobileSumFooter = ({ columns, loading }: MobileTableProps) => {
  const { data } = useQuery(INVESTMENT_MANAGERS_QUERY, {
    variables: { page: 0, rowsPerPage: 500 }
  });
  const rowSum: { totalCommittedCapital: number; totalInvestors: number; totalFunds: number; totalActiveCommitments: number } = useMemo(() => {
    const sum = {
      totalFunds: 0,
      totalInvestors: 0,
      totalCommittedCapital: 0,
      totalActiveCommitments: 0
    };

    sum.totalFunds = data?.InvestmentManagers.managers.reduce((sum: number, value: RowSumType) => (sum += Number(value.countOfFunds)), 0);
    sum.totalInvestors = data?.InvestmentManagers.managers.reduce((sum: number, value: RowSumType) => (sum += Number(value.countOfInvestors)), 0);
    sum.totalCommittedCapital = data?.InvestmentManagers.managers.reduce(
      (sum: number, value: RowSumType) => (sum += Number(value.committedCapital)),
      0
    );
    sum.totalActiveCommitments = data?.InvestmentManagers.managers.reduce(
      (sum: number, value: RowSumType) => (sum += Number(value.percentOfTotalActiveCommmitments) / 100),
      0
    );
    return sum;
  }, [data]);

  return (
    <>
      {!loading ? (
        data && (
          <MobileSumFooterWrap key={guid()}>
            <MobileRow>
              <MobileRowTitle>Total</MobileRowTitle>
            </MobileRow>

            <MobileRow>
              <MobileRowTitle>{columns[2].title}</MobileRowTitle>
              <MobileRowValue>{rowSum.totalFunds}</MobileRowValue>
            </MobileRow>

            <MobileRow>
              <MobileRowTitle>{columns[3].title}</MobileRowTitle>
              <MobileRowValue>{rowSum.totalInvestors}</MobileRowValue>
            </MobileRow>

            <MobileRow>
              <MobileRowTitle>{columns[4].title}</MobileRowTitle>
              <MobileRowValue>
                <FormattingTooltip>{rowSum.totalCommittedCapital}</FormattingTooltip>
              </MobileRowValue>
            </MobileRow>

            {/* <MobileRow>*/}
            {/*  <MobileRowTitle>{columns[5].title}</MobileRowTitle>*/}
            {/*  <MobileRowValue>{`${rowSum.totalActiveCommitments.toFixed(2)}%`}</MobileRowValue>*/}
            {/* </MobileRow>*/}
          </MobileSumFooterWrap>
        )
      ) : (
        <Loader />
      )}
    </>
  );
};

const MobileSumFooterWrap = styled(MobileTableRowWrap)`
  background-color: #f0f1f3;
`;

export default MobileSumFooter;
