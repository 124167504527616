import * as React from 'react';

export const UploadFileIcon = ({ ...props }: React.SVGProps<SVGSVGElement>) => (
  <svg width={props.width || 40} height={props.height || 40} style={props?.style} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M32.2622 16.7361C31.7614 14.194 30.4828 11.8704 28.6034 10.087C26.724 8.30349 24.3367 7.14833 21.7719 6.7813C19.2071 6.41427 16.5916 6.85355 14.2874 8.03833C11.9832 9.22312 10.1043 11.0948 8.91064 13.3944C6.3677 13.6742 4.02856 14.9175 2.37403 16.8688C0.719506 18.82 -0.124711 21.3309 0.0149266 23.8854C0.154564 26.4399 1.26744 28.8438 3.1248 30.6031C4.98216 32.3624 7.44289 33.3433 10.0012 33.3443H31.6719C33.8282 33.3479 35.9016 32.5136 37.4545 31.0175C39.0073 29.5214 39.9182 27.4804 39.9947 25.3255C40.0713 23.1705 39.3077 21.0701 37.865 19.4675C36.4223 17.8649 34.4133 16.8855 32.2622 16.7361V16.7361ZM31.6719 30.0026H10.0012C8.29619 30.0055 6.65497 29.3547 5.41529 28.1842C4.17561 27.0136 3.4318 25.4124 3.33694 23.7101C3.24208 22.0077 3.80339 20.3338 4.90535 19.0328C6.0073 17.7317 7.56606 16.9026 9.2608 16.7161L11.0517 16.5359L11.8821 14.9452C12.7518 13.2547 14.1267 11.8774 15.8156 11.0047C17.5045 10.132 19.4233 9.8073 21.3053 10.0758C23.1873 10.3444 24.9388 11.1928 26.3162 12.503C27.6936 13.8132 28.6284 15.5202 28.9906 17.3864L29.4908 19.8876L32.0421 20.0677C33.2579 20.251 34.3637 20.8757 35.1482 21.8225C35.9327 22.7693 36.341 23.9718 36.2953 25.2005C36.2495 26.4293 35.7528 27.5981 34.9 28.4838C34.0472 29.3696 32.898 29.9103 31.6719 30.0026V30.0026ZM13.3328 21.6685H17.5849V26.671H22.4173V21.6685H26.6694L20.0061 14.9952L13.3328 21.6685Z"
      fill={props?.fill || '#7F7F7F'}
    />
  </svg>
);
