export const columns = [
  { title: 'Entity', key: 'ENTITY_NAME', width: 25, keySort: 'entity' },
  { title: 'Investor', key: 'INVESTOR', width: 15, keySort: 'investor' },
  {
    title: 'Overall Capacity',
    key: 'OVERALL_CAPACITY',
    width: 15,
    keySort: 'overall capacity'
  },
  {
    title: 'Asset Class Capacity',
    key: 'ASSET_CLASS_CAPACITY',
    width: 15,
    keySort: 'asset class'
  },
  {
    title: 'Suitability',
    key: 'SUITABILITY',
    width: 20,
    keySort: 'suitability'
  },
  { title: 'Amount', key: 'AMOUNT', width: 10, keySort: 'amount' }
];
