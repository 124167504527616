import styled from 'styled-components';

import TableHeader from '@/components/table/fat-tableHeader';
import { useResponsive } from '../../../hooks/use-responsive';
import { Loader } from '../../loaders/loader';
import { initialSort } from '../../table/constants';
import { TableWrapper } from '../../table/styles';
import TablePagination from '../../table/tablePagination';
import { ISort } from '../../table/types';
import { columnsAllocations } from '../constants';
import { ClientEntityAllocationsTableType } from '../types';
import MobileTable from './mobileTable';
import { TableBody } from './tableBody';

type ClientAllocationTable = {
  clientsRows: ClientEntityAllocationsTableType[] | undefined;
  loading: boolean;
  refetch: any;
  paginationValues: {
    limit: number;
    offset: number;
    total: number;
  };
  savedSort?: ISort;
  savedSetSort?: (value: ISort) => void;
};

const TableAllocations = ({ clientsRows, loading, paginationValues, refetch, savedSort = initialSort, savedSetSort }: ClientAllocationTable) => {
  const { isMobile, isTablet } = useResponsive();
  return (
    <TableWrapper padding={isMobile ? '10px 0 30px' : '30px 0'}>
      {isMobile ? (
        <>
          <MobileTable columns={columnsAllocations} clientsRows={clientsRows} loading={loading} />
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <TablePagination paginationValues={paginationValues} refetch={refetch} />
          </div>
        </>
      ) : (
        <>
          <CustomTableHeader isTablet={isTablet} columns={columnsAllocations} refetch={refetch} savedSort={savedSort} savedSetSort={savedSetSort} />
          {loading ? (
            <Loader />
          ) : clientsRows ? (
            <>
              <TableBody clientsRows={clientsRows} keySort={savedSort?.key} />
              <PaginationWrap>
                <TablePagination paginationValues={paginationValues} refetch={refetch} />
              </PaginationWrap>
            </>
          ) : null}
        </>
      )}
    </TableWrapper>
  );
};

const CustomTableHeader = styled(TableHeader)<{ isTablet: boolean }>`
  position: sticky;
  top: ${({ isTablet }) => (isTablet ? '49px' : '0')};
  z-index: 8;
`;

const PaginationWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

export default TableAllocations;
