import { TableCell, TableRow } from '@mui/material';
import React from 'react';
import styled from 'styled-components';

export const HeaderRow = styled(TableRow)`
  height: 4rem;
`;

export const FooterRow = styled(TableRow)`
  height: 4rem;
  background: #e1e1e1;
`;

export const HeaderCell = styled(TableCell)<{ isSorted?: boolean }>`
  color: ${props => (props.isSorted ? '#7F7F7F' : '#f5f5f5')} !important;
  font-weight: 600 !important;
  padding: 0 0.75rem;
  background: #36474f !important;
  line-height: 1rem;

  &:first-child {
    border-radius: 5px 0 0 5px;
  }

  &:last-child {
    border-radius: 0 5px 5px 0;
  }
`;

export const FooterCell = styled(TableCell)`
  font-size: 16px;
  line-height: 16px;
  color: #3b4352 !important;
  font-weight: 600 !important;
  padding: 0 0.75rem;
`;

export const BodyRow = styled(TableRow)<{ cursor?: 'pointer' | 'default' }>`
  cursor: ${({ cursor }) => cursor ?? 'pointer'};
  background: white;
  height: 51px;
`;

export const BodyCell = styled(TableCell)<{ width?: string }>`
  width: ${({ width }) => width ?? 'auto'};
  padding: 0 0.75rem;
  height: 51px !important;

  &:first-child {
    border-radius: 5px 0 0 5px;
  }

  &:last-child {
    border-radius: 0 5px 5px 0;
  }

  @media (max-width: 1400px) {
    padding: 4px 7px;
  }
`;

export const BodyCellInner = styled.div<{
  bold?: boolean;
  width?: string | number;
  color?: string;
  cursor?: React.CSSProperties['cursor'];
}>`
  font-style: normal !important;
  font-weight: ${props => (props.bold ? '600' : '400')} !important;
  font-size: 16px !important;
  line-height: 16px !important;
  color: ${props => props.color ?? '#2E2E2E'} !important;
  width: ${props => props.width ?? '100%'} !important;
  cursor: ${props => props.cursor ?? 'default'} !important;
  @media (max-width: 1200px) {
    font-size: 14px !important;
  }
`;

export const ItemHeader = styled.h6`
  font-style: normal;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.5rem;
  letter-spacing: 0.01em;
  color: #33363f;
  margin: 0;
`;

export const ItemBody = styled.span`
  font-style: normal;
  font-weight: 300;
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: 0.01em;
  color: #172d4d;
  margin: 0;
`;
