import { getUTCDate } from '@/components/allocations-page/utils';
import DropdownActions from '@/components/table/fat-dropdownActions';
import NoResults from '@/components/table/noResults';
import RedirectTitleCell from '@/components/table/redirectTitleCell';
import { TableBodyWrapper, TableRowCell, TableRowWrapper, TextCell } from '@/components/table/styles';
import { IItems } from '@/components/table/types';
import { useNavigate, useParams } from 'react-router-dom';
import { ICommitmentPlan } from '../../fat-pacingTab/types';
import { columns } from '../constatnts';

interface TableBodyProps {
  tableData: ICommitmentPlan[];
  updateLoading: boolean;
  currentRow: ICommitmentPlan | null;
  dropdownActions: (row: ICommitmentPlan) => IItems[];
}

const TableBody = ({ tableData, updateLoading, currentRow, dropdownActions }: TableBodyProps) => {
  const params = useParams();
  const navigate = useNavigate();

  if (!tableData?.length) {
    return <NoResults />;
  }

  return (
    <TableBodyWrapper>
      {tableData.map((row) => (
        <TableRowWrapper key={row.id} loading={updateLoading && row.id === currentRow?.id ? 1 : 0}>
          <TableRowCell width={columns[0].width}>
            <RedirectTitleCell
              onClick={() =>
                navigate(`/investors/${params.investorId}/${params.entityId}?backToTitle=All Commitment Plans&tab=Pacing&commitmentPlanId=${row.id}`)
              }
            >{`${row.name} ${row.active ? '(Active)' : ''}`}</RedirectTitleCell>
          </TableRowCell>
          <TableRowCell width={columns[1].width}>
            <TextCell>{getUTCDate(new Date(row.startDate))}</TextCell>
          </TableRowCell>
          <TableRowCell width={columns[2].width}>
            <TextCell>{row?.growthRate && `${row.growthRate.toFixed(2)}%`}</TextCell>
          </TableRowCell>
          <TableRowCell width={columns[3].width}>
            <DropdownActions disabled={updateLoading} items={dropdownActions(row)} />
          </TableRowCell>
        </TableRowWrapper>
      ))}
    </TableBodyWrapper>
  );
};

export default TableBody;
