import FormattingTooltip from '@/components/basicComponents/tooltip/dollarsTooltip';
import { Loader } from '@/components/loaders/loader';
import { MobileRow, MobileRowTitle, MobileRowValue, MobileTableRowWrap } from '@/components/table/mobileTable/mobileTable';
import { TableColumns } from '@/components/table/types';
import guid from '@/utils/guid';
import { useMemo } from 'react';
import styled from 'styled-components';
import { CommitmentsType } from '../types';

type MobileTableProps = {
  columns: TableColumns[];
  clientsRows: CommitmentsType[];
  loading: boolean;
};

type RowSumType = {
  total: string;
  committedCapital: number;
  capitalCalled: number;
  capitalRemaining: number;
  distributionsToDate: number;
  marketValue: number;
  status: string;
};

const MobileSumFooter = ({ columns, clientsRows, loading }: MobileTableProps) => {
  const rowSum: RowSumType = useMemo(() => {
    const sum = {
      total: 'Total',
      committedCapital: 0,
      capitalCalled: 0,
      capitalRemaining: 0,
      distributionsToDate: 0,
      marketValue: 0,
      status: ''
    };
    for (let i = 0; i < clientsRows.length; i++) {
      sum.committedCapital += clientsRows[i].committedCapital;
      sum.capitalCalled += clientsRows[i].capitalCalled;
      sum.capitalRemaining += clientsRows[i].capitalRemaining;
      sum.distributionsToDate += +clientsRows[i].distributionsToDate;
      sum.marketValue += +clientsRows[i].marketValue;
    }
    return sum;
  }, [clientsRows]);

  return (
    <>
      {!loading ? (
        clientsRows && (
          <MobileSumFooterWrap key={guid()}>
            <MobileRow>
              <MobileRowTitle>{rowSum.total}</MobileRowTitle>
            </MobileRow>

            <MobileRow>
              <MobileRowTitle>{columns[2].title}</MobileRowTitle>
              <MobileRowValue>
                <FormattingTooltip>{rowSum.committedCapital}</FormattingTooltip>
              </MobileRowValue>
            </MobileRow>

            <MobileRow>
              <MobileRowTitle>{columns[3].title}</MobileRowTitle>
              <MobileRowValue>
                {rowSum.capitalCalled === 0 || rowSum.committedCapital === 0
                  ? '0%'
                  : ((rowSum.capitalCalled / rowSum.committedCapital) * 100).toFixed(2) + '%'}
              </MobileRowValue>
            </MobileRow>

            <MobileRow>
              <MobileRowTitle>{columns[4].title}</MobileRowTitle>
              <MobileRowValue>
                <FormattingTooltip>{rowSum.distributionsToDate}</FormattingTooltip>
              </MobileRowValue>
            </MobileRow>

            <MobileRow>
              <MobileRowTitle>{columns[5].title}</MobileRowTitle>
              <MobileRowValue>
                <FormattingTooltip>{rowSum.marketValue}</FormattingTooltip>
              </MobileRowValue>
            </MobileRow>
          </MobileSumFooterWrap>
        )
      ) : (
        <Loader />
      )}
    </>
  );
};

const MobileSumFooterWrap = styled(MobileTableRowWrap)`
  background-color: ${({ theme }) => theme.layer[2]};
`;

export default MobileSumFooter;
