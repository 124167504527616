import { gql } from '@apollo/client';

export const CLIENTS_LIST_QUERY = gql`
  query ClientsEntities(
    $advisor: String
    $search: String
    $limit: Int
    $offset: Int
    $sort: String
    $sortFor: String
    $order: String
    $includeRecommendation: Boolean
  ) {
    ClientsEntities(
      advisor: $advisor
      search: $search
      limit: $limit
      offset: $offset
      sort: $sort
      sortFor: $sortFor
      order: $order
      includeRecommendation: $includeRecommendation
    ) {
      offset
      limit
      total
      clients {
        advisor {
          initials
          firstName
          lastName
        }
        name
        totalClientValue
        privateEquityCapacity
        privateCreditCapacity
        privateRealAssetsCapacity
        privateOverallCapacity
        clientMoreInformation {
          totalClientValue
          committedCapital
          economicExposure
          onHold
          sectorToAvoid
        }
      }
      entities {
        id
        entityName
        clientName
        clientAdvisor {
          initials
        }
        totalEntityValue
        privateEquityCapacity
        privateCreditCapacity
        privateRealAssetsCapacity
        overallCapacity
      }
      totalEntities
    }
  }
`;
