import { gql } from '@apollo/client';

import { InvestmentFragment } from '../allocations-page/fragments';

export const COMPLIANCE_QUERY = gql`
  ${InvestmentFragment}
  query Compliance($search: String, $sort: String, $order: String, $limit: Int, $offset: Int, $allocationFilter: String) {
    Compliance(search: $search, sort: $sort, order: $order, limit: $limit, offset: $offset, allocationFilter: $allocationFilter) {
      total
      limit
      offset
      allocations {
        id
        selectedGroupLegalEntity {
          id
          entityName
        }
        legalEntity {
          id
          entityName
        }
        advisor {
          id
          initials
        }
        family {
          id
          name
        }
        investment {
          ...InvestmentFragment
        }
        amount
        updatedAt
        terminationReason
        allocationStatus
        moreInfoAllocationStatus {
          id
          statusDate
          suitability
          decision
          reason
          internalDecision
          complianceOfficer
          lastUpdate
          terminationNote
          terminationReason
          terminateBy
        }
        moreInfoEntity {
          alwaysShow
          entityValue
          onHold
          biteSize
        }
      }
    }
  }
`;
