import { ApprovedIcon } from '@/assets/icons/info-icons/approved';
import { InfoIcon } from '@/assets/icons/info-icons/info';
import Button from '@/components/fat-basicComponents/button';
import Header from '@/components/fat-header';
import { GoBackButton } from '@/components/fat-header/goBackButton';
import { PageTitle } from '@/components/fat-header/pageTitle';
import { useResponsive } from '@/hooks/use-responsive';
import { MainWrap } from '@/styles/common';
import guid from '@/utils/guid';
import React from 'react';
import styled, { useTheme } from 'styled-components';
import { ButtonsControl, ImportText, ImportWrapper } from '../../styles';
import { IGroupImportComparison } from '../types';

interface GroupImportDetailProps {
  currentRow: IGroupImportComparison | null;
  handleClose: () => void;
  backToTitle: string;
}

export const GroupImportDetail = ({ currentRow, handleClose, backToTitle }: GroupImportDetailProps) => {
  const { isMobile, isTablet } = useResponsive();
  const theme = useTheme();

  if (!currentRow) {
    return <></>;
  }

  return (
    <MainWrap>
      <Header modalControl={<GoBackButton handleClose={handleClose} backToTitle={backToTitle} />} />
      <PageTitle title={currentRow.investorName} />
      <PaddingWrap>
        <ImportWrapper>
          <TitleWrap>
            {currentRow.status === 'error' ? (
              <>
                <InfoIcon width={30} height={30} fill={theme.context.warning} />
                <ImportText fontWeight="700">Multiple Primary Entities</ImportText>
              </>
            ) : (
              <>
                <ApprovedIcon width={30} height={30} fill={theme.context.success} />
                <ImportText fontWeight="700" color={theme.context.success}>
                  No Conflicts
                </ImportText>
              </>
            )}
          </TitleWrap>
          <DetailWrap>
            <ColumnsWrap>
              <Column isMobile={isMobile}>
                <ColumnTitle>ENTITY</ColumnTitle>
              </Column>
              <Column isMobile={isMobile}>
                <ColumnTitle>PRIMARY ENTITY</ColumnTitle>
              </Column>
            </ColumnsWrap>
            {currentRow.entityGroups.map((entityGroup) => (
              <React.Fragment key={guid()}>
                <ColumnsWrap>
                  <Column isMobile={isMobile}>
                    <RowWrap>
                      <RowValue>
                        <ImportText fontWeight="400" fontSize="16px">
                          {entityGroup.entityName}
                        </ImportText>
                      </RowValue>
                    </RowWrap>
                  </Column>
                  <Column isMobile={isMobile}>
                    <RowWrap>
                      <RowValue bgColor="#FFEAEC">
                        <ImportText fontWeight="400" fontSize="16px">
                          {entityGroup.isPrimaryEntity.toString()}
                        </ImportText>
                      </RowValue>
                    </RowWrap>
                  </Column>
                </ColumnsWrap>
                <DividerWrapper>
                  <Divider />
                  <Divider />
                </DividerWrapper>
              </React.Fragment>
            ))}
          </DetailWrap>
          <ButtonsControl>
            <Button size="lg" onClick={handleClose}>
              Done
            </Button>
          </ButtonsControl>
        </ImportWrapper>
      </PaddingWrap>
    </MainWrap>
  );
};

const PaddingWrap = styled.div`
  padding-left: 16px;
  padding-right: 16px;
  @media (min-width: 600px) {
    padding-left: 50px;
    padding-right: 50px;
  }
`;

const TitleWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  padding-bottom: 25px;
`;

const DetailWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const ColumnsWrap = styled.div`
  display: flex;
  gap: 10px;
`;

const Column = styled.div<{ isMobile: boolean }>`
  width: ${({ isMobile }) => (isMobile ? '100%' : '50%')};
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const ColumnTitle = styled.div`
  background: ${({ theme }) => theme.layer[2]};
  padding: 10px 30px;
  color: ${({ theme }) => theme.font.base};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22.4px;
`;

const RowWrap = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 30px;
  gap: 10px;
`;

const RowValue = styled.div<{ bgColor?: string }>`
  background: ${({ bgColor }) => bgColor ?? 'transparent'};
  line-height: 18.2px;
  padding: 8px 20px;
  border-radius: 4px;
`;

const DividerWrapper = styled.div`
  display: flex;
  width: 100%;
`;

const Divider = styled.div`
  background: ${({ theme }) => theme.border.base};
  height: 1px;
  margin: 0 30px;
  width: 50%;
`;
