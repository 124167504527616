import React from 'react';
import styled, { useTheme } from 'styled-components';

import BasicTooltip from '@/components/basicComponents/tooltip';
import { css } from 'styled-components';

interface LabelProps {
  children: React.ReactNode;
  required?: boolean;
  size?: 'sm' | 'md' | 'lg';
  tooltip?: string;
  tooltipWidth?: number;
  className?: string;
}

const getLabelSize = (size: LabelProps['size']) => {
  switch (size) {
    case 'sm':
      return css`
        font-size: 13px;
        line-height: 18.2px;
        padding: 3px;
        gap: 5px;
      `;
    case 'md':
      return css`
        font-size: 16px;
        line-height: 22.4px;
        padding: 4px;
        gap: 6px;
      `;
    case 'lg':
      return css`
        font-size: 19px;
        line-height: 26.6px;
        padding: 5px;
        gap: 8px;
      `;
    default:
      return '';
  }
};

const requiredIconWidths = {
  sm: {
    width: '8px',
    height: '7.7px'
  },
  md: {
    width: '10px',
    height: '9.6px'
  },
  lg: {
    width: '12px',
    height: '11.6px'
  }
};

const Label = ({ children, required = false, size = 'lg', tooltip, tooltipWidth = 0, className }: LabelProps) => {
  const theme = useTheme();

  return (
    <LabelContainer className={className} size={size}>
      {required && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={requiredIconWidths[size].width}
          height={requiredIconWidths[size].height}
          viewBox="0 0 12 13"
          fill="none"
        >
          <g clipPath="url(#clip0_1393_2033)">
            <path
              d="M7.57623 0.694458L7.07703 5.15894L11.5994 3.8933L12.0001 6.95282L7.87812 7.2431L10.5834 10.8425L7.83163 12.3055L5.93903 8.51447L4.27873 12.2822L1.41665 10.8425L4.09873 7.2431L0 6.92376L0.470281 3.8933L4.89996 5.15894L4.40063 0.694458H7.57623Z"
              fill={theme.context.error}
            />
          </g>
          <defs>
            <clipPath id="clip0_1393_2033">
              <rect width="12" height="11.611" fill="white" transform="translate(0 0.694458)" />
            </clipPath>
          </defs>
        </svg>
      )}
      {children}
      {tooltip && <BasicTooltip tooltipContent={tooltip} tooltipWidth={tooltipWidth} withTooltipIcon />}
    </LabelContainer>
  );
};

export default Label;

const LabelContainer = styled.span<{ size: LabelProps['size'] }>`
  display: flex;
  align-items: center;
  font-family: Blinker;
  font-weight: 400;
  color: ${({ theme }) => theme.font.base};

  ${({ size }) => getLabelSize(size)}
`;
