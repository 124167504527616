import * as React from 'react';

export const ClockIcon = ({ ...props }: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.4922 0.500021C10.9211 0.501564 8.40814 1.26552 6.27115 2.69526C4.13417 4.125 2.46912 6.15633 1.48661 8.53237C0.504093 10.9084 0.248194 13.5224 0.751315 16.0439C1.25444 18.5653 2.49399 20.881 4.31316 22.6979C6.13234 24.5149 8.4494 25.7517 10.9715 26.2518C13.4935 26.7519 16.1073 26.4929 18.4821 25.5075C20.857 24.5221 22.8863 22.8547 24.3134 20.716C25.7406 18.5773 26.5015 16.0634 26.5 13.4922C26.501 11.7845 26.1652 10.0933 25.5117 8.51552C24.8581 6.93775 23.8998 5.5044 22.6915 4.29755C21.4832 3.09071 20.0487 2.13408 18.4701 1.48244C16.8916 0.830805 15.2 0.496944 13.4922 0.500021ZM13.4922 23.8954C11.4344 23.8969 9.42223 23.2881 7.71042 22.1459C5.99861 21.0038 4.66404 19.3795 3.87545 17.4788C3.08687 15.578 2.87967 13.486 3.28019 11.4675C3.68072 9.44897 4.67091 7.59456 6.1255 6.13888C7.5801 4.6832 9.43378 3.69163 11.452 3.28959C13.4702 2.88755 15.5623 3.0931 17.4637 3.88026C19.3651 4.66742 20.9903 6.00082 22.1337 7.71178C23.2772 9.42273 23.8875 11.4344 23.8875 13.4922C23.8875 16.2473 22.7947 18.8898 20.8488 20.8401C18.9029 22.7904 16.2629 23.8892 13.5079 23.8954H13.4922ZM14.1395 6.99614H12.1899V14.7946L19.0135 18.8888L19.9883 17.2901L14.1395 13.8198V6.99614Z"
        fill={props?.fill || '#373F4E'}
      />
    </svg>
  );
};
