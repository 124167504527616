import { useState } from 'react';
import styled from 'styled-components';

import Label from '@/components/basicComponents/label';
import Textarea from '@/components/basicComponents/textarea';
import ModalWrappedContent from '@/components/fat-basicComponents/modal/modalWrappedContent';
import { TierStatusEnum } from '../dueDiligenceRecord/types';

interface RejectInvestmentProps {
  onClose: () => void;
  isOpen: boolean;
  updateRecordStatus: (variables: any) => void;
  updateTierStatus: (variables: any) => void;
  currentRow: any;
  recordId: string | null;
}

const RejectInvestment = ({ onClose, isOpen, updateRecordStatus, updateTierStatus, currentRow, recordId }: RejectInvestmentProps) => {
  const [reasonValue, setReasonValue] = useState('');

  const saveValue = () => {
    if (recordId) {
      updateTierStatus({
        variables: {
          data: {
            id: currentRow.data.id,
            note: reasonValue,
            status: TierStatusEnum.REJECTED
          }
        }
      });

      updateRecordStatus({
        variables: {
          data: {
            id: recordId,
            note: reasonValue,
            status: TierStatusEnum.REJECTED
          }
        },
        onCompleted: () => {
          setReasonValue('');
          onClose();
          window.scrollTo(0, 0);
        }
      });
    }
  };

  return (
    <ModalWrappedContent
      isOpen={isOpen}
      onClose={onClose}
      title={'Reject Investment'}
      confirmButton={{
        name: 'Reject',
        onClick: saveValue,
        disabled: !reasonValue.length
      }}
      showRequiredFields
    >
      <RejectInvestmentWrapper>
        <ModalText>Please provide reasons for determining why this investment is unsuitable at this time.</ModalText>
        <RejectWrapper>
          <Label fontSize="16px" required>
            Reason
          </Label>
          <Textarea value={reasonValue} setValue={setReasonValue} textareaHeight="212px" />
        </RejectWrapper>
      </RejectInvestmentWrapper>
    </ModalWrappedContent>
  );
};

export default RejectInvestment;

const ModalText = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: ${({ theme }) => theme.font.base};
`;

const RejectWrapper = styled.div``;

const RejectInvestmentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
`;
