import Button from '@/components/basicComponents/button';
import Label from '@/components/basicComponents/label';
import { TierDropdown } from '@/components/dueDiligence-page/dueDiligenceRecord/tierDropdown';
import { ISectionFields } from '@/components/dueDiligence-page/dueDiligenceRecord/types';
import { AmountCharacters, CharactersRemainder, StyledTextarea } from '@/components/dueDiligence-page/modals/changeAssignee';
import Header from '@/components/fat-header';
import { GoBackButton } from '@/components/fat-header/goBackButton';
import { PageTitle } from '@/components/fat-header/pageTitle';
import { IDocumentRecordFieldEntity } from '@/components/managerUpdates-page/managerUpdateDetails/types';
import { useResponsive } from '@/hooks/use-responsive';
import { MainWrap, PaddingWrap } from '@/styles/common';
import { useMutation } from '@apollo/client';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { TierDropdownWrapper } from '.';
import { CREATE_DEAL_PROMPT_GROUP_FOR_DDR } from '../../queries';
import DeleteAllPrompt from './modals/deleteAllPrompt';
import { PromptTemplate } from './promptTemplate';

interface PromptEditorProps {
  handleClose: () => void;
  handleCloseAll: () => void;
  currentRow: ISectionFields | IDocumentRecordFieldEntity | null;
  currentRowName: string;
  refetch: any;
  savePromptGroup?: (promptGroup: { [key: string]: string } | null) => void;
}

export const PromptEditor = ({ handleClose, handleCloseAll, currentRow, currentRowName, refetch, savePromptGroup }: PromptEditorProps) => {
  const { isMobile, isTablet } = useResponsive();

  const [promptGroup, setPromptGroup] = useState({
    Question: '',
    'Context Template': '',
    'Summary Template': ''
  });
  const [selectedOption, setSelectedOption] = useState({
    'Context Template': 'Use default',
    'Summary Template': 'Use default'
  });
  const [modalWindow, setModalWindow] = useState({
    isOpen: false,
    type: 'delete-prompts'
  });
  const [isValid, setIsValid] = useState({
    'Context Template': true,
    'Summary Template': true
  });

  useEffect(() => {
    if (currentRow?.promptGroup) {
      setPromptGroup({
        Question: currentRow.promptGroup?.prompts[0] ?? '',
        'Context Template': currentRow.promptGroup.useCustomContextPrompt ? currentRow.promptGroup?.contextPrompt : '',
        'Summary Template': currentRow.promptGroup.useCustomSummaryPrompt ? currentRow.promptGroup?.summaryPrompt : ''
      });
      setSelectedOption({
        'Context Template': currentRow.promptGroup.useCustomContextPrompt ? 'Customize' : 'Use default',
        'Summary Template': currentRow.promptGroup.useCustomSummaryPrompt ? 'Customize' : 'Use default'
      });
    }
  }, [currentRow]);

  useEffect(() => {
    if (!isValid['Context Template']) {
      setIsValid({ ...isValid, 'Context Template': true });
    }
  }, [promptGroup['Context Template']]);

  useEffect(() => {
    if (!isValid['Summary Template']) {
      setIsValid({ ...isValid, 'Summary Template': true });
    }
  }, [promptGroup['Summary Template']]);

  const [createPromptGroup] = useMutation(CREATE_DEAL_PROMPT_GROUP_FOR_DDR);

  const saveValue = () => {
    const isCustomContext = selectedOption['Context Template'] === 'Customize';
    const isCustomSummary = selectedOption['Summary Template'] === 'Customize';

    const isContextPresent = promptGroup['Context Template'].includes('{context}');

    const isContextValid = !isCustomContext || isContextPresent;
    const isSummaryValid = !isCustomSummary || true;

    setIsValid({
      'Summary Template': isSummaryValid,
      'Context Template': isContextValid
    });

    if (!isContextValid || !isSummaryValid) {
      return;
    }

    createPromptGroup({
      variables: {
        data: {
          id: currentRow?.id,
          prompts: promptGroup.Question,
          ...(selectedOption['Summary Template'] === 'Customize' && {
            summaryPrompt: promptGroup['Summary Template']
          }),
          ...(selectedOption['Context Template'] === 'Customize' && {
            contextPrompt: promptGroup['Context Template']
          }),
          useCustomContextPrompt: selectedOption['Context Template'] !== 'Use default',
          useCustomSummaryPrompt: selectedOption['Summary Template'] !== 'Use default',
          rubricTemplates: []
        }
      },
      onCompleted: (data) => {
        const { id, prompts, summaryPrompt, contextPrompt, useCustomContextPrompt, useCustomSummaryPrompt } =
          data.createDealAssistantPromptGroupForDDR;

        if (savePromptGroup) {
          savePromptGroup({
            id: id,
            Question: prompts[0],
            'Context Template': contextPrompt,
            'Summary Template': summaryPrompt,
            useCustomContextPrompt: useCustomContextPrompt,
            useCustomSummaryPrompt: useCustomSummaryPrompt
          });
        }
        handleClose();
        refetch();
      }
    });
  };

  return (
    <>
      {modalWindow.type === 'delete-prompts' && (
        <DeleteAllPrompt
          isOpen={modalWindow.isOpen}
          onClose={() => setModalWindow({ ...modalWindow, isOpen: false })}
          fieldName={currentRowName}
          promptGroupId={currentRow?.promptGroup?.id ?? ''}
          closePromptPage={handleClose}
          refetch={refetch}
          savePromptGroup={savePromptGroup}
        />
      )}
      <MainWrap>
        <Header modalControl={<GoBackButton handleClose={handleClose} backToTitle="" />} />
        <PageTitle title={`${currentRowName} Settings`} />
        <PaddingWrap>
          <TierDropdownWrapper>
            <TierDropdown title="Prompt Settings">
              <PromptsWrapper>
                <div>
                  <Label tooltip="Provide a question that corresponds to the desired content for this field." tooltipWidth={250} required>
                    Question
                  </Label>
                  <StyledTextarea
                    height="110px"
                    maxLength={1000}
                    value={promptGroup.Question}
                    onChange={(event) =>
                      setPromptGroup({
                        ...promptGroup,
                        Question: event.target.value
                      })
                    }
                  />
                  <CharactersRemainder>
                    <AmountCharacters>{1000 - promptGroup.Question.length > 0 ? 1000 - promptGroup.Question.length : 0}</AmountCharacters> Characters
                    Remaining
                  </CharactersRemainder>
                </div>
                <PromptTemplate
                  title="Context Template"
                  tooltip="The AI Assistant will digest each document in chunks. The Context Template assembles a full prompt for the AI, which will include {context}. Be sure to provide this token in the template."
                  value={promptGroup['Context Template']}
                  setValue={(value) =>
                    setPromptGroup({
                      ...promptGroup,
                      ['Context Template']: value
                    })
                  }
                  selectedOption={selectedOption['Context Template']}
                  setSelectedOption={(option) =>
                    setSelectedOption({
                      ...selectedOption,
                      ['Context Template']: option
                    })
                  }
                  isValid={isValid['Context Template']}
                  errorMessage="Include the {context} token in the template"
                />
                <PromptTemplate
                  title="Summary Template"
                  tooltip="For better performance, wrap any instructions with the tags <instructions> and </instructions>."
                  value={promptGroup['Summary Template']}
                  setValue={(value) =>
                    setPromptGroup({
                      ...promptGroup,
                      ['Summary Template']: value
                    })
                  }
                  selectedOption={selectedOption['Summary Template']}
                  setSelectedOption={(option) =>
                    setSelectedOption({
                      ...selectedOption,
                      ['Summary Template']: option
                    })
                  }
                  isValid={isValid['Summary Template']}
                  errorMessage=""
                />
              </PromptsWrapper>
            </TierDropdown>
          </TierDropdownWrapper>
          <ButtonWrapper isMobile={isMobile}>
            <CancelButton isMobile={isMobile} onClick={handleClose} disabled={false}>
              Cancel
            </CancelButton>
            {currentRow?.promptGroup && (
              <DeletePrompt isMobile={isMobile} onClick={() => setModalWindow({ type: 'delete-prompts', isOpen: true })} disabled={false}>
                Delete Prompt Settings
              </DeletePrompt>
            )}
            <SaveButton
              isMobile={isMobile}
              disabled={Boolean(
                !promptGroup.Question ||
                  (selectedOption['Context Template'] === 'Customize' && !promptGroup['Context Template']) ||
                  (selectedOption['Summary Template'] === 'Customize' && !promptGroup['Summary Template'])
              )}
              onClick={saveValue}
            >
              Save Prompt Settings
            </SaveButton>
          </ButtonWrapper>
        </PaddingWrap>
      </MainWrap>
    </>
  );
};

const BtnWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const PromptsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
`;

const ButtonWrapper = styled.div<{ isMobile: boolean }>`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};
  gap: ${({ isMobile }) => (isMobile ? '10px' : '0')};
  margin: 45.5px 0;
  gap: 10px;
`;

const CancelButton = styled(Button)<{ isMobile: boolean }>`
  background-color: #ffffff;
  border: 1px solid #4587ec;
  color: #4587ec;
  font-size: 19px;
  font-weight: 400;
  padding: ${({ isMobile }) => (isMobile ? '10px 30px' : '10px 40px')};
`;

const DeletePrompt = styled(CancelButton)<{ isMobile: boolean }>``;

const SaveButton = styled(Button)<{ isMobile: boolean }>`
  background: ${({ disabled }) => (disabled ? '#dedede' : '#1c488a')};
  font-size: 19px;
  font-weight: 400;
  border: 1px solid rgba(0, 0, 0, 0);
  padding: ${({ isMobile }) => (isMobile ? '10px 37px' : '10px 40px')};
`;
