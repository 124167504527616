import { RejectedBannerIcon } from '@/assets/icons/info-icons/rejectedBanner';
import { DeleteBucketIcon } from '@/assets/static/icons/delete-bucket';
import { Banner } from '@/components/basicComponents/banner';
import ModalLoader from '@/components/fat-basicComponents/modal/modalLoader';
import ModalWrappedContent from '@/components/fat-basicComponents/modal/modalWrappedContent';
import { useResponsive } from '@/hooks/use-responsive';
import styled, { useTheme } from 'styled-components';

interface DeleteConfirmationDialogProps {
  onClose: () => void;
  isOpen: boolean;
}

const DeleteConfirmationDialog = ({ onClose, isOpen }: DeleteConfirmationDialogProps) => {
  const isMobile = useResponsive();
  const theme = useTheme();

  const onSave = () => {};

  return (
    <ModalWrappedContent
      isOpen={isOpen}
      onClose={onClose}
      showCloseIcon={!false}
      showCancelButton={!false}
      isUseOutsideClick={!false}
      confirmButton={{
        name: 'Confirm',
        onClick: onSave
      }}
    >
      <ModalBody>
        <DeleteBucketIcon fill={theme.font.disabled} />
        {false ? (
          <ModalLoader />
        ) : (
          <>
            <ModalTitle>Delete Confirmation</ModalTitle>
            {false && (
              <ErrorBannerWrapper>
                <Banner icon={<RejectedBannerIcon fill={theme.context.light} />} title="Error" description={''} bgColor={theme.context.error} />
              </ErrorBannerWrapper>
            )}
            <ModalDescription>Are you sure that you want to remove this contact from the list of Authorized Signatories?</ModalDescription>
          </>
        )}
      </ModalBody>
    </ModalWrappedContent>
  );
};

export default DeleteConfirmationDialog;

const ModalBody = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
`;

const ModalTitle = styled.div`
  font-family: Blinker;
  font-size: 33px;
  font-style: normal;
  font-weight: 400;
  line-height: 46.2px;
  text-align: center;
  color: ${({ theme }) => theme.font.strong};
`;

const ModalDescription = styled.div`
  font-size: 16px;
  font-style: normal;
  text-align: center;
  color: ${({ theme }) => theme.font.base};
`;

const ErrorBannerWrapper = styled.div`
  margin-bottom: 10px;
`;
