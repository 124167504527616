import * as React from 'react';

export const SendIcon = ({ ...props }: React.SVGProps<SVGSVGElement>) => (
  <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_5249_536882)">
      <path
        d="M2.9012 10.002C2.51317 9.80954 2.409 9.48303 2.33346 9.12484C1.80227 6.61875 1.26256 4.11448 0.728322 1.609C0.676543 1.36534 0.605271 1.1168 0.608317 0.871306C0.615018 0.190263 1.34418 -0.140512 1.76938 0.0562477C2.27072 0.288338 2.76901 0.525911 3.26548 0.76714C4.64523 1.43844 6.02377 2.11278 7.4023 2.78651C10.1173 4.11327 12.8324 5.44063 15.5474 6.76738C17.1245 7.53797 18.7016 8.30917 20.2788 9.08037C20.3665 9.12301 20.4542 9.16687 20.5389 9.215C20.8556 9.39592 21.0091 9.67065 21 10.0355C20.9909 10.3876 20.824 10.6459 20.5169 10.8043C20.124 11.0071 19.7238 11.1954 19.3273 11.3897C16.9607 12.5483 14.5941 13.707 12.2269 14.8644C9.83651 16.0333 7.44555 17.2005 5.0552 18.3689C4.02815 18.8708 3.0011 19.374 1.97406 19.8753C1.77547 19.9722 1.56714 20.0264 1.34479 19.9844C0.842844 19.8887 0.510242 19.4105 0.625373 18.8793C1.03108 17.0068 1.42825 15.1324 1.82786 13.2586C1.99903 12.457 2.17508 11.6559 2.33834 10.8524C2.40961 10.5003 2.53022 10.189 2.90303 10.0008L2.9012 10.002ZM1.51109 19.0926C7.71724 16.0571 13.896 13.035 20.093 10.0045C13.8917 6.97084 7.71419 3.9494 1.51658 0.918212C1.5245 0.973036 1.52693 0.998012 1.53242 1.02238C1.79192 2.23522 2.05203 3.44867 2.31153 4.66151C2.6216 6.11192 2.93105 7.56173 3.23868 9.01214C3.26304 9.12788 3.31178 9.18758 3.43909 9.19489C4.1311 9.23571 4.8225 9.28078 5.5139 9.32891C7.2957 9.45257 9.07749 9.57866 10.8593 9.70232C12.0137 9.78212 13.168 9.8601 14.3224 9.93624C14.4198 9.94294 14.4387 9.97706 14.404 10.0636C13.9496 10.0946 13.5 10.1245 13.0504 10.1561C12.0319 10.2274 11.014 10.2993 9.9955 10.37C9.29557 10.4187 8.59565 10.4662 7.89572 10.5131C7.05569 10.5698 6.21504 10.6246 5.3744 10.6812C4.73356 10.7239 4.09333 10.7684 3.45249 10.8104C3.33066 10.8183 3.26122 10.8707 3.23563 10.998C3.14426 11.4476 3.04496 11.8953 2.94872 12.3443C2.54241 14.2381 2.13609 16.1326 1.73039 18.0265C1.65668 18.3707 1.58785 18.7161 1.51049 19.0913L1.51109 19.0926Z"
        fill={props?.fill || '#4587EC'}
      />
    </g>
    <defs>
      <clipPath id="clip0_5249_536882">
        <rect width="20.399" height="20" fill="white" transform="translate(0.601074)" />
      </clipPath>
    </defs>
  </svg>
);
