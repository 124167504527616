import { RejectedBannerIcon } from '@/assets/icons/info-icons/rejectedBanner';
import styled from 'styled-components';

interface DealTrialInfoBannerProps {
  title: string;
  description: string;
}

export const DealTrialInfoBanner = ({
  title,
  description
}: DealTrialInfoBannerProps) => {
  return (
    <DealTrialWrapper>
      <RejectedBannerIcon fill="#36474F" />
      <DescriptionWrap>
        <Text fontSize="16px" fontWeight={700}>
          {title}
        </Text>
        <Text fontSize="13px" fontWeight={400}>
          {description}
        </Text>
      </DescriptionWrap>
    </DealTrialWrapper>
  );
};

const DealTrialWrapper = styled.div`
  margin-top: 10px;
  background: #fafafa;
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 10px 20px;
`;

const DescriptionWrap = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 20px;
  border-left: 1px solid #c7cbd2;
`;

const Text = styled.span<{ fontWeight: number; fontSize: string }>`
  font-size: ${({ fontSize }) => fontSize};
  font-weight: ${({ fontWeight }) => fontWeight};
`;
