import { ApprovedIcon } from '@/assets/icons/info-icons/approved';
import { CloseInfoIcon } from '@/assets/icons/info-icons/closeInfo';
import Button from '@/components/basicComponents/button';
import FilterSelect from '@/components/basicComponents/select';
import { CloseBtn, ModalControlWrap, ModalPageTitle } from '@/components/client-details';
import { DropFileArea } from '@/components/dueDiligence-page/dueDiligenceRecord/dropFileArea';
import { TierDropdown } from '@/components/dueDiligence-page/dueDiligenceRecord/tierDropdown';
import FileNotSupported from '@/components/dueDiligence-page/modals/fileNotSupported';
import Header from '@/components/header';
import SnackbarAlert from '@/components/snackbar';
import { useResponsive } from '@/hooks/use-responsive';
import { MainWrap, PaddingWrap } from '@/styles/common';
import { gql } from '@apollo/client';
import axios from 'axios';
import { parse } from 'csv-parse/sync';
import { isValid } from 'date-fns';
import { print } from 'graphql';
import { useState } from 'react';
import styled from 'styled-components';
import { selectImportItems } from '../../constants';
import ImportStatus from './modals/importStatus';

interface ImportDataProps {
  handleClose: () => void;
}

type IStatus = 'success' | 'error' | 'pending' | 'default';

export interface IStatusOfUploading {
  status: IStatus;
  message: string;
}

const fileExtensions = ['CSV'];

export const ImportData = ({ handleClose }: ImportDataProps) => {
  const { isMobile, isTablet } = useResponsive();

  const [drag, setDrag] = useState(false);
  const [file, setFile] = useState<File | null>(null);
  const [modalWindow, setModalWindow] = useState({ isOpen: false, type: 'not-supported' });

  const [statusOfUploading, setStatusOfUploading] = useState<IStatusOfUploading>({
    status: 'default',
    message: ''
  });
  // const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [template, setTemplate] = useState('default');

  const handleChange = (value: string) => {
    const chosenItem = selectImportItems.find((item) => item.name === value);
    if (chosenItem) {
      setTemplate(chosenItem.value);
      setFile(null);
    }
  };

  const removeSpecialCharacters = (value: string): number => parseFloat(value?.replace(/\$|,|%/g, ''));

  const templateOperations = {
    USER: {
      postProcessor: async (file: File) => {
        const text = await file.text();
        const records = parse(text, {
          columns: true,
          skip_empty_lines: true
        });
        return records.map((record: any) => {
          return {
            firstName: record['First Name'],
            lastName: record['Last Name'],
            email: record['Email']?.toLowerCase(),
            initials: record['Initials'],
            officeNumber: record['Office Phone'],
            mobileNumber: record['Cell Phone'],
            roles: record['Roles']?.split(',').map((role: string) => role.trim()),
            supervisor: record['Supervisor']?.toLowerCase()
          };
        });
      },
      gqlMutation: gql`
        mutation updateUsers($data: [CreateUserDto!]!) {
          updateUsers(data: $data) {
            id
          }
        }
      `
    },
    PERMISSION: {
      postProcessor: async (file: File) => {
        const text = await file.text();
        const records = parse(text, {
          columns: true,
          skip_empty_lines: true
        });
        return records.map((record: any) => {
          return {
            roles: record['Roles']?.split(',').map((role: string) => role.trim()),
            action: record['Action'],
            subject: record['Subject'],
            conditions: record['Conditions']
          };
        });
      },
      gqlMutation: gql`
        mutation updatePermissions($data: [PermissionCreateDto!]!) {
          updatePermissions(data: $data) {
            id
          }
        }
      `
    },
    ENTITY: {
      postProcessor: async (file: File) => {
        const text = await file.text();
        const records = parse(text, {
          columns: true,
          skip_empty_lines: true
        });

        return records.map((record: any) => {
          return {
            entityName: record['Entity Name'],
            id: record['Entity Number'],
            familyName: record['Family Name'],
            familyId: record['Family Number'],
            advisorId: record['Advisor'],
            grouped: record['Grouped']?.toLowerCase() === 'yes',
            totalEntityValue: removeSpecialCharacters(record['Total Entity Value']),
            privatePercentage: removeSpecialCharacters(record['Private Percentage']),
            overCommitRatio: parseFloat(record['OC Ratio']),
            privateCreditPercentage: removeSpecialCharacters(record['PC%']),
            privateEquityPercentage: removeSpecialCharacters(record['PE%']),
            privateRealAssetPercentage: removeSpecialCharacters(record['RA%']),
            notes: record['Notes'],
            ...(record['Note Date'] && { noteDate: record['Note Date'] }),
            sectorsToAvoid: record['Sectors to Avoid']?.split(',').map((sectorToAvoid: string) => sectorToAvoid.trim()),
            sri: record['SRI?'],
            ubtiBlocker: record['UBTI Blocker']?.toLowerCase() === 'yes',
            custodied: record['Custodied']?.toLowerCase() === 'yes',
            unmanaged: record['Unmanaged?']?.toLowerCase() === 'yes',
            alwaysShow: record['Always Show?']?.toLowerCase() === 'yes',
            onHold: record['On Hold']?.toLowerCase() === 'yes',
            entityInception: record['Entity Inception'],
            biteSize: 0,
            fundBiteSize: removeSpecialCharacters(record['Fund Bite Size']),
            directBiteSize: removeSpecialCharacters(record['Direct Bite Size']),
            advisorEmail: record['Advisor Owner']
          };
        });
      },
      gqlMutation: gql`
        mutation updateEntities($data: [LegalEntityCreateDto!]!) {
          updateEntities(data: $data) {
            entityName
          }
        }
      `
    },
    INVESTMENT: {
      postProcessor: async (file: File) => {
        const text = await file.text();
        const records = parse(text, {
          columns: true,
          skip_empty_lines: true
        });

        return records.map((record: any) => {
          return {
            id: record['Symbol'],
            assetClass: record['Asset Class'],
            subAssetClasses: record['Sub-Asset Class']?.split(',').map((subclass: string) => subclass.trim()),
            securityStatus: record['Security Status']?.toUpperCase(),
            name: record['Investment'],
            managerId: record['Manager'],
            custodied: record['Custodied']?.toLowerCase() === 'true',
            ubtiBlocker: record['UBTI Blocker']?.toLowerCase() === 'true',
            nextClose: isValid(new Date(record['Next Close'])) ? record['Next Close'] : null,
            finalClose: isValid(new Date(record['Final Close'])) ? record['Final Close'] : null,
            minimum: removeSpecialCharacters(record['Minimum']),
            sri: record['SRI']?.toLowerCase() === 'yes',
            ...(record['Target IRR'] && { targetIRR: removeSpecialCharacters(record['Target IRR']) }),
            ...(record['Target Yield'] && { targetYield: removeSpecialCharacters(record['Target Yield']) }),
            vintageYear: parseFloat(record['Vintage']),
            type: record['Investment Type'],
            investmentOwnerEmail: record['Investment Owner'],
            operationsOwnerEmail: record['Operations Owner'],
            complianceOwnerEmail: record['Compliance Owner']
          };
        });
      },
      gqlMutation: gql`
        mutation updateInvestments($data: [InvestmentEntityCreateDto!]!) {
          updateInvestments(data: $data) {
            name
          }
        }
      `
    },
    COMMITTED_CAPITAL: {
      postProcessor: async (file: File) => {
        const text = await file.text();
        const records = parse(text, {
          columns: true,
          skip_empty_lines: true
        });

        return records.map((record: any) => {
          return {
            lastUpdated: record['As of Date'],
            managed: record['Managed?']?.toLowerCase() === 'yes',
            commitmentStatus: record['Security Status']?.split(' ')[0],
            investmentId: record['Symbol'],
            legalEntityId: record['Account Number'],
            committedCapital: parseFloat(record['Committed Capital']) ? removeSpecialCharacters(record['Committed Capital']) : 0,
            capitalCalled: removeSpecialCharacters(record['Capital Called']),
            remainingCapital: parseFloat(record['Remaining Capital']) ? removeSpecialCharacters(record['Remaining Capital']) : 0,
            currentValue: removeSpecialCharacters(record['Current Value']),
            valuationDate: record['Valuation Date'] ? record['Valuation Date'] : null,
            distributionsToDate: removeSpecialCharacters(record['Distributions to Date']),
            netIRR: parseFloat(record['Net IRR']) ? removeSpecialCharacters(record['Net IRR']) : 0,
            startDate: isValid(new Date(record['Start Date'])) ? record['Start Date'] : null,
            endDate: isValid(new Date(record['End Date'])) ? record['End Date'] : null,
            returnStatus: record['Return Status'] ? record['Return Status'] : null,
            tvpi: parseFloat(record['TVPI']) ? removeSpecialCharacters(record['TVPI']) : 0
          };
        });
      },
      gqlMutation: gql`
        mutation replaceCommitments($data: [CommitmentEntityCreateDto!]!) {
          replaceCommitments(data: $data) {
            id
          }
        }
      `
    },
    INVESTMENT_REPAIR: {
      postProcessor: async (file: File) => {
        const text = await file.text();
        const records = parse(text, {
          columns: true,
          skip_empty_lines: true
        });

        return records.map((record: any) => {
          return {
            delete: record['Delete'] === 'Y',
            name: record['name'],
            assetClass: record['assetClass'],
            securityStatus: record['securityStatus'],
            managerId: record['managerId'],
            custodied: record['custodied'] === 'TRUE' ? true : false,
            ubtiBlocker: record['ubtiBlocker'] === 'TRUE' ? true : false,
            nextClose: record['nextClose'] === 'NULL' ? null : record['nextClose'],
            finalClose: record['finalClose'] === 'NULL' ? null : record['finalClose'],
            minimum: record['minimum'] === 'NULL' ? 0 : Number(record['minimum']),
            sri: record['sri'] === 'TRUE' ? true : false,
            targetIRR: record['targetIRR'] === 'NULL' ? 0 : Number(record['targetIRR']),
            targetYield: record['targetYield'] === 'NULL' ? 0 : Number(record['targetYield']),
            vintageYear: record['vintageYear'] === 'NULL' ? null : Number(record['vintageYear']),
            subAssetClasses: record['subAssetClasses'].split(',').map((sac: string) => sac.trim()),
            //createdAt: record.createdAt,
            //updatedAt: record.updatedAt,
            id: record.id,
            type: record.type,
            investmentOwnerId: record.investmentOwnerId,
            operationsOwnerId: record.operationsOwnerId,
            complianceOwnerId: record.complianceOwnerId,
            description: record.description === 'NULL' ? '' : record['description'],
            symbol: record.symbol,
            contact: record.contact === 'NULL' ? '' : record['contact'],
            referral: record.referral === 'NULL' ? '' : record['referral'],
            tags: record.tags,
            //templateId: record.templateId,
            //offeringMaterialsDescription: record.offeringMaterialsDescription,
            investmentOwnerEmail: record.investment_owner === 'NULL' ? null : record['investment_owner'],
            operationsOwnerEmail: record.operations_owner === 'NULL' ? null : record['operations_owner'],
            complianceOwnerEmail: record.compliance_owner === 'NULL' ? null : record['compliance_owner']
          };
        });
      },
      gqlMutation: gql`
        mutation repairInvestments($data: [RepairInvestmentDto!]!) {
          repairInvestments(data: $data) {
            status
          }
        }
      `
    }
    // OUTSTANDING_COMMITTED_CAPITAL: {
    //   postProcessor: (rows: any) => {
    //     return rows.map((row: any) => {
    //       const updatedRow = {
    //         ...row.data,
    //         updatedAt: row.data.date,
    //         ...(row.data.commitmentStatus === 'AV Pending' && {
    //           allocationStatus: 'Outstanding',
    //           operationsStatus: 'Client approved',
    //         }),
    //         ...(row.data.commitmentStatus === 'Proposed' && {
    //           allocationStatus: 'Outstanding',
    //           operationsStatus: 'Recommendation sent to client',
    //         }),
    //         ...(row.data.commitmentStatus === 'Rejected' && { allocationStatus: 'Terminated' }),
    //       };
    //       delete updatedRow.date;
    //       delete updatedRow.commitmentStatus;
    //       return updatedRow;
    //     });
    //   },
    //   gqlMutation: gql`
    //     mutation UpsertAllocations($data: [AllocationEntityCreateDto!]!) {
    //       UpsertAllocations(data: $data) {
    //         id
    //       }
    //     }
    //   `,
    // },
  };

  // const openAlert = () => setIsAlertOpen(true);

  // const closeAlert = () => setIsAlertOpen(false);

  const handleInit = async () => {
    if (!file) return;
    setStatusOfUploading({ status: 'pending', message: '' });
    setModalWindow({ isOpen: true, type: 'import-status' });

    const templateOps = templateOperations[template as keyof typeof templateOperations];
    const data = await templateOps.postProcessor(file);

    axios
      .post(
        `${process.env.REACT_APP_BACKEND_BASE_URL}/graphql`,
        {
          query: print(templateOps.gqlMutation),
          variables: {
            data
          }
        },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        }
      )
      .then((res) => {
        console.log(res);
        if (res?.data?.errors) {
          console.log(res?.data?.errors);
          setStatusOfUploading({ status: 'error', message: res.data.errors[0].message });
        } else {
          setStatusOfUploading({ status: 'success', message: '' });
        }
      })
      .catch((err) => {
        setStatusOfUploading({ status: 'error', message: err.message });
        console.log(err);
      })
      .finally(() => {
        setFile(null);
      });
  };

  const allowFileExtensions = (files: FileList | null) => {
    return Array.from(files || []).filter((file: File) => {
      const fileExt = file.name.split('.').pop()?.toLowerCase();
      if ([...fileExtensions.map((item) => item.toLowerCase())].includes(fileExt || '')) {
        return file;
      }
    });
  };

  const onDropHandler = async (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const uploadFiles = e.dataTransfer.files;

    setDrag(false);
    if (!allowFileExtensions(uploadFiles).length) {
      setModalWindow({ isOpen: true, type: 'not-supported' });
      return;
    }
    if (uploadFiles && uploadFiles.length > 0) {
      setFile(uploadFiles[0]);
    }
  };

  const handleUploadFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const uploadFiles = e.target.files;

    if (!allowFileExtensions(uploadFiles).length) {
      setModalWindow({ isOpen: true, type: 'not-supported' });
      return;
    }
    if (uploadFiles && uploadFiles.length > 0) {
      setFile(uploadFiles[0]);
    }
  };

  // const showNotificationStatusAboutUploading = () => {
  //   if (statusOfUploading.status === 'default') return;
  //   if (statusOfUploading.status === 'success')
  //     return (
  //       <CustomSnackbarAlert
  //         width={'60%'}
  //         open={isAlertOpen}
  //         setOpen={setIsAlertOpen}
  //         logo={'/static/icons/success-tick.svg'}
  //         type={'success'}
  //         nodeElementToClose={<ImageCross src={'/static/icons/dark-cross.svg'} onClick={closeAlert} alt={'Cross'} />}
  //       >
  //         Success
  //       </CustomSnackbarAlert>
  //     );
  //   if (statusOfUploading.status === 'error')
  //     return (
  //       <CustomSnackbarAlert
  //         width={'60%'}
  //         open={isAlertOpen}
  //         setOpen={setIsAlertOpen}
  //         logo={'/static/icons/warning-exclamation.svg'}
  //         type={'error'}
  //         nodeElementToClose={<ImageCross src={'/static/icons/dark-cross.svg'} onClick={closeAlert} alt={'Cross'} />}
  //       >
  //         {`UnSuccess, ${statusOfUploading.message}`}
  //       </CustomSnackbarAlert>
  //     );
  // };

  // useEffect(() => {
  //   if (!isAlertOpen) setStatusOfUploading({ status: 'default', message: '' });
  // }, [isAlertOpen]);

  return (
    <>
      {modalWindow.type === 'import-status' && (
        <ImportStatus
          isOpen={modalWindow.isOpen}
          onClose={() => setModalWindow({ ...modalWindow, isOpen: false })}
          statusOfUploading={statusOfUploading}
        />
      )}
      {modalWindow.type === 'not-supported' && (
        <FileNotSupported isOpen={modalWindow.isOpen} onClose={() => setModalWindow({ ...modalWindow, isOpen: false })} />
      )}
      <MainWrap>
        <Header
          modalControl={
            <ModalControlWrap>
              <CloseBtn onClick={handleClose}>
                <CloseInfoIcon width={18} height={18} />
              </CloseBtn>
              <HeaderTitleWrap>
                <ModalPageTitle isTablet={isTablet} isMobile={isMobile}>
                  Import Data
                </ModalPageTitle>
                <HeaderSubTitle>Make bulk updates to data and settings</HeaderSubTitle>
              </HeaderTitleWrap>
            </ModalControlWrap>
          }
        />
        <MarginTop />
        {/* {showNotificationStatusAboutUploading()} */}
        <PaddingWrap>
          <SettingsContainer isMobile={isMobile}>
            <TierDropdown title="Upload a File">
              <SelectLabel>Select file type</SelectLabel>
              <FilterSelect
                data={selectImportItems.map((item) => item.name)}
                selected={selectImportItems.find((item) => item.value === template)?.name ?? ''}
                setSelected={handleChange}
                width={isMobile ? '100%' : '35%'}
                minHeight={'43px'}
                fontSize="16"
                fontWeight="300"
              />
              <UploadButtonWrapper>
                {template !== 'default' && (
                  <>
                    {file ? (
                      <PreviewItem key={file.lastModified}>
                        <DecorStick />
                        <ApprovedIcon width="25" height="24" fill="#373F4E" />
                        <FileWrapper>
                          <FileItem>{file.name}</FileItem>
                        </FileWrapper>
                      </PreviewItem>
                    ) : (
                      <DropFileArea
                        id={'import-data'}
                        fileExtensions={fileExtensions}
                        handleUploadFile={handleUploadFile}
                        onDropHandler={onDropHandler}
                        drag={drag}
                        setDrag={setDrag}
                      />
                    )}
                    {file && (
                      <UploadButton onClick={handleInit} disabled={template === 'default' || !file}>
                        Upload
                      </UploadButton>
                    )}
                  </>
                )}
              </UploadButtonWrapper>
            </TierDropdown>
          </SettingsContainer>
        </PaddingWrap>
      </MainWrap>
    </>
  );
};

export const HeaderTitleWrap = styled.div`
  display: flex;
  flex-direction: column;
`;

export const HeaderSubTitle = styled.div`
  font-family: Blinker, serif;
  font-size: 13px;
  line-height: 18px;
  font-weight: 400;
  color: #7f7f7f;
`;

export const MarginTop = styled.div`
  height: 71px;
  background-color: #ffffff;
  border-bottom: 1px solid #c7cbd2;
`;

export const SettingsContainer = styled.div<{ isMobile: boolean }>`
  border: 1px solid #c7cbd2;
  border-radius: 10px;
  margin-top: 50px;
  padding: ${({ isMobile }) => (isMobile ? '20px 30px' : '20px 60px')};
  height: 75vh;
  margin-bottom: 40px;
`;

export const SelectLabel = styled.div`
  font-family: Blinker, serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
  margin-bottom: 5px;
`;

export const UploadButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 25px;
  margin-top: 50px;
`;

export const UploadButton = styled(Button)`
  width: 137px;
  height: 50px;
  font-weight: 400;
  font-size: 19px;
  background: ${({ disabled }) => (disabled ? '#dedede' : '#1c488a')};
`;

const CustomSnackbarAlert = styled(SnackbarAlert)`
  margin-top: 70px;
`;

const ImageCross = styled.img`
  cursor: 'pointer';
  height: 35px;
  width: 35px;
`;

const PreviewItem = styled.div`
  width: 100%;
  height: 100%;
  padding: 10px 20px;
  border: 1px solid #3ab070;
  border-radius: 5px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 7.5px;
`;

const DecorStick = styled.div`
  width: 5px;
  height: 30px;
  background-color: #3ab070;
`;

const FileWrapper = styled.div``;

const FileItem = styled.div`
  color: #373f4e;
  font-weight: 600;
  font-size: 16px;
  line-height: 22.4px;
  color: inherit;
`;
