export const allocationsSteps = [
  { name: 'Upload Data File', status: 'IN_PROGRESS' },
  { name: 'Entity Codes', status: 'NOT_STARTED' },
  { name: 'Series Codes', status: 'NOT_STARTED' },
  { name: 'Allocation Conflicts', status: 'NOT_STARTED' },
];

export const allocationsDescriptionSectionData = {
  title: 'Import Allocations performs the following actions',
  description: [
    'Provides the opportunity to reconcile existing allocations with those in the import file.',
    'Marks any pending allocations that appear in the import file as committed.',
    'Creates, increases, or otherwise adjusts commitments to match the allocations in the committed status.',
  ],
};
export const allocationsColumnsSectionData = {
  title: 'Provide the following columns in the upload file',
  columns: [
    { name: 'Entity Code', isRequired: true },
    { name: 'Series Code', isRequired: true },
    { name: 'Trade Date', isRequired: true },
    { name: 'Entity Name', isRequired: false },
    { name: 'Series Name', isRequired: false },
    { name: 'NetAmount', isRequired: true },
    { name: 'Advisory Firm', isRequired: false },
  ],
};
