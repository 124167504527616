import styled, { keyframes } from 'styled-components';

// Constants
const DEFAULT_LOADER_SIZE = 50; // Example size, adjust as needed

interface LoaderProps {
  size?: number;
  className?: string;
}

export const Loader = ({ size = DEFAULT_LOADER_SIZE, className }: LoaderProps) => {
  return (
    <ContainerLoader className={className}>
      <CircularProgressWrapper size={size}>
        <CircularProgressCircle viewBox="0 0 40 40">
          <CircularProgressPath cx="20" cy="20" r="15.91549430918954" fill="none" strokeWidth="3" />
        </CircularProgressCircle>
      </CircularProgressWrapper>
    </ContainerLoader>
  );
};
const rotation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const dash = keyframes`
  0% {
    stroke-dashoffset: 80;
  }
  50% {
    stroke-dashoffset: 0;
    transform: rotate(135deg);
  }
  100% {
    stroke-dashoffset: 80;
    transform: rotate(360deg);
  }
`;

const CircularProgressWrapper = styled.div<{ size: number }>`
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
`;

const CircularProgressCircle = styled.svg`
  animation: ${rotation} 1.4s linear infinite;
`;

const CircularProgressPath = styled.circle`
  stroke-dasharray: 80;
  stroke-dashoffset: 0;
  transform-origin: center;
  animation: ${dash} 1.4s ease-in-out infinite;
  stroke: ${({ theme }) => theme.font.base}; /* Adjust color as needed */
`;

const ContainerLoader = styled.div`
  display: flex;
  justify-content: center;
  background-color: transparent;
  padding: 10px 0;
`;
