import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { Tabs } from '../../constants/tabs/tabs';

import styled, { keyframes } from 'styled-components';
import packageJson from '../../../package.json';
import ControlButton from './fat-controlButton';

interface DashboardNavbarProps {
  tabs: Tabs[];
}

export const DashboardNavbar = ({ tabs }: DashboardNavbarProps) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [isNavOpen, setIsNavOpen] = useState(false);
  const [isMouseInside, setIsMouseInside] = useState(false);
  const [isNavFixed, setIsNavFixed] = useState(false);

  const mouseRef = useRef(isMouseInside);
  mouseRef.current = isMouseInside;

  useEffect(() => {
    if (mouseRef.current) {
      setTimeout(() => {
        if (mouseRef.current) {
          setIsNavOpen(true);
        }
      }, 1200);
    }
  }, [isMouseInside]);

  const onMouseLeave = () => {
    setIsMouseInside(false);
    setIsNavOpen(false);
  };

  const onLogoClick = () => {
    setIsNavFixed(!isNavFixed);
    setIsNavOpen(!isNavOpen);
  };

  const selectedTabIndex = tabs.findIndex((tab) => location.pathname.includes(tab.path.split('/')[0])) ?? 0;

  return (
    <NavBarWrap
      onMouseEnter={() => {
        setIsMouseInside(true);
      }}
      onMouseLeave={onMouseLeave}
    >
      <DashboardNavbarWrap isNavOpen={isNavOpen || isNavFixed}>
        <LogoWrap>
          <ControlButton icon={isNavOpen} visible={false} onClick={() => setIsNavFixed(!isNavFixed)} />
          {isNavOpen || isNavFixed ? (
            <img src={process.env.REACT_APP_NAVBAR_LOGO} onClick={onLogoClick} />
          ) : (
            <img src={process.env.REACT_APP_NAVBAR_SIMPLE_LOGO} width={32} height={32} onClick={onLogoClick} />
          )}
          <ControlButton icon={isNavOpen} visible={true} onClick={() => setIsNavFixed(!isNavFixed)} />
        </LogoWrap>
        <MenuWrap>
          {tabs?.map((el, index) => {
            return (
              !el?.disabled && (
                <MenuItemWrap
                  active={index === selectedTabIndex}
                  onClick={(event) => {
                    event.preventDefault();
                    const returnUrl = location.state?.returnUrl || `/${el.path}`;
                    navigate(returnUrl, { replace: true });

                    setIsNavOpen(false);
                    setIsNavFixed(false);
                  }}
                  key={el.path}
                >
                  {el.icon}
                  <MenuItemTitle>{el.label}</MenuItemTitle>
                </MenuItemWrap>
              )
            );
          })}
        </MenuWrap>
        <MenuFooter>{packageJson.version}</MenuFooter>
      </DashboardNavbarWrap>
    </NavBarWrap>
  );
};

const NavBarWrap = styled.div`
  display: flex;
  position: fixed;
  transition: 0.3s;
  z-index: 1000;
`;

const MenuFooter = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: auto;
`;

const displayToDoTitle = keyframes`
  to {
    visibility: visible;
  }
`;

const MenuItemTitle = styled.div`
  font-family: Lato;
  color: ${({ theme }) => theme.header.action};
  font-weight: 700;
  white-space: nowrap;
  animation: 0s linear 0.1s forwards ${displayToDoTitle};
`;

const MenuItemWrap = styled.div<{ active: boolean }>`
  cursor: pointer;
  background-color: ${({ active, theme }) => (active ? theme.action.active : 'transparent')};
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 30px;
  padding: 20px 25px;
`;

const MenuWrap = styled.div`
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  margin: 0 -10px;

  &::-webkit-scrollbar {
    display: none;
    background-color: transparent;
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    display: none;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.layer[4]};
    border-radius: 6px;
    border: 2px solid transparent;
  }

  &::-webkit-scrollbar-button {
    display: none;
  }
`;

const LogoWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 28px;
  min-height: 64px;
`;

const DashboardNavbarWrap = styled.div<{ isNavOpen: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100vh;
  position: ${({ isNavOpen }) => (isNavOpen ? 'absolute' : 'fixed')};
  width: ${({ isNavOpen }) => (isNavOpen ? '270px' : '80px')};
  background-color: ${({ theme }) => theme.menu.tab};
  transition: 0.3s;
  padding: 20px 10px;
  padding-bottom: 0;
  z-index: 400;
  top: 0;
  bottom: 0;
  left: 0;
  box-shadow: 4px 0px 8px 0px rgba(0, 0, 0, 0.25);
`;
