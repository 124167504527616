import { ApprovedIcon } from '@/assets/icons/info-icons/approved';
import { DeleteItemIcon } from '@/assets/static/icons/delete-item';
import { DropFileArea } from '@/components/dueDiligence-page/dueDiligenceRecord/dropFileArea';
import { Loader } from '@/components/loaders/loader';
import { Dispatch, SetStateAction } from 'react';
import styled, { useTheme } from 'styled-components';

interface UploadFileProps {
  handleUploadFile: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onDropHandler: (e: React.DragEvent<HTMLDivElement>) => void;
  drag: boolean;
  setDrag: Dispatch<SetStateAction<boolean>>;
  file: File | null;
  loading: boolean;
  deleteFile?: () => void;
}

export const UploadFile = ({ handleUploadFile, onDropHandler, drag, setDrag, file, loading, deleteFile }: UploadFileProps) => {
  const theme = useTheme();

  return (
    <>
      {file && !loading ? (
        <PreviewItem key={file.lastModified}>
          <DecorStick />
          <ApprovedIcon width="25" height="24" fill={theme.font.base} />
          <FileWrapper>
            <FileItem>{file.name}</FileItem>
          </FileWrapper>
          {deleteFile && (
            <DeleteButton onClick={deleteFile}>
              <DeleteItemIcon fill={theme.context.error} />
            </DeleteButton>
          )}
        </PreviewItem>
      ) : (
        <>
          {loading ? (
            <UploadingLoaderWrapper>
              <Loader />
              Uploading File
            </UploadingLoaderWrapper>
          ) : (
            <DropFileArea
              id={'handle-upload-file'}
              handleUploadFile={handleUploadFile}
              onDropHandler={onDropHandler}
              drag={drag}
              setDrag={setDrag}
              fileExtensions={['CSV']}
              maxSize={10}
              withIcon={true}
            />
          )}
        </>
      )}
    </>
  );
};

const PreviewItem = styled.div`
  width: 100%;
  height: 100%;
  padding: 10px 20px;
  border: 1px solid ${({ theme }) => theme.context.success};
  border-radius: 5px;
  background-color: ${({ theme }) => theme.layer[1]};
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 7.5px;
`;

const DecorStick = styled.div`
  width: 5px;
  height: 30px;
  background-color: ${({ theme }) => theme.context.success};
`;

const FileWrapper = styled.div``;

const FileItem = styled.div`
  color: ${({ theme }) => theme.font.base};
  font-weight: 600;
  font-size: 16px;
  line-height: 22.4px;
`;

const UploadingLoaderWrapper = styled.div`
  width: 100%;
  border: 1px solid ${({ theme }) => theme.border.base};
  background-color: ${({ theme }) => theme.layer[1]};
  border-radius: 5px;
  margin-top: 65px;
  padding: 50px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.font.base};
  font-family: Blinker;
  font-size: 19px;
  font-weight: 400;
  line-height: 26.6px;
`;

const DeleteButton = styled.button`
  border: 1px solid ${({ theme }) => theme.context.error};
  border-radius: 20px;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-left: auto;
  background-color: ${({ theme }) => theme.layer.base};
`;
