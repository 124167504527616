import {
  MobileRow,
  MobileRowTitle,
  MobileRowValue,
  MobileTableRowWrap,
  MobileTableWrapper,
  NoResults
} from '@/components/table/mobileTable/mobileTable';

import { getUTCDate } from '@/components/allocations-page/utils';
import BasicTooltip from '@/components/basicComponents/tooltip';
import FormattingTooltip from '@/components/basicComponents/tooltip/dollarsTooltip';
import { BasicTitle } from '@/components/client-entity-details/tableCommitments/tableBody';
import { formatterClient } from '@/components/clients-page/utils';
import { TableBodyWrapper, TableRowCell, TextCell } from '@/components/table/styles';
import { formatCash } from '@/utils/formatCash';
import guid from '@/utils/guid';
import styled, { useTheme } from 'styled-components';
import { commitmentsTablecolumns } from '../../constatnts';
import { CommitmentsType } from '../../types';
import MobileSumFooter from '../mobileSumFooter';

interface MobileTableProps {
  investorCommitmentRows: CommitmentsType[] | undefined;
  loading: boolean;
  openOpportunityDetailsPage: (id: string) => void;
}

const MobileTable = ({ investorCommitmentRows, loading, openOpportunityDetailsPage }: MobileTableProps) => {
  const theme = useTheme();

  const toolTipContent = (row: CommitmentsType) => {
    return (
      <TableBodyWrapper>
        <TableRowCell width={100}>
          <TextCell>{formatCash(row.committedCapital)} Committed Capital</TextCell>
        </TableRowCell>
        <TableRowCell width={100}>
          <TextCell>{formatCash(row.capitalCalled)} Capital Called</TextCell>
        </TableRowCell>
        <TableRowCell width={100}>
          <TextCell>{formatCash(row.capitalRemaining)} Capital Remaining</TextCell>
        </TableRowCell>
        <TableRowCell width={100}>
          <TextCell>{((row.capitalCalled / row.committedCapital) * 100).toFixed(2)}% Called to Date</TextCell>
        </TableRowCell>
      </TableBodyWrapper>
    );
  };

  const marketValueToolTipContent = (row: CommitmentsType) => {
    return (
      <TableBodyWrapper>
        {row.valuationDate && (
          <>
            <TableRowCell width={100}>
              <TextCell>Valuation Date: {row.valuationAmount ? getUTCDate(row.valuationDate) : 'None'}</TextCell>
            </TableRowCell>
            {row.valuationAmount !== null && (
              <TableRowCell width={100}>
                <TextCell>Valuation Amount: {formatterClient.format(row.valuationAmount)}</TextCell>
              </TableRowCell>
            )}
          </>
        )}
        {row.aggregateCallsAndDistributions !== null && (
          <TableRowCell width={100}>
            <TextCell>Aggregate Calls and Distributions since last valuation: {formatterClient.format(row.aggregateCallsAndDistributions)}</TextCell>
          </TableRowCell>
        )}
        <TableRowCell width={100}>
          <TextCell>Market Value: {formatterClient.format(row.marketValue)}</TextCell>
        </TableRowCell>
      </TableBodyWrapper>
    );
  };

  const getColorStatus = (status: string) => {
    if (status === 'Active') {
      return theme.context.success;
    } else if (status === 'Recommended' || status === 'Outstanding') {
      return theme.context.caution;
    } else {
      return '#111827';
    }
  };

  const TableRow = ({ el }: { el: CommitmentsType }) => {
    return (
      <MobileTableRowWrap key={guid()}>
        <MobileRow>
          <MobileRowTitle>{commitmentsTablecolumns[0].title}</MobileRowTitle>
          <MobileRowValue isRedirect onClick={() => openOpportunityDetailsPage(el.investmentId)}>
            {el.name}
          </MobileRowValue>
        </MobileRow>

        <MobileRow>
          <MobileRowTitle>{commitmentsTablecolumns[1].title}</MobileRowTitle>
          <MobileRowValue>{el.subAssetClass ? `${el.assetClass?.name} - ${el.subAssetClass.name}` : el.assetClass?.name}</MobileRowValue>
        </MobileRow>

        <MobileRow>
          <MobileRowTitle>{commitmentsTablecolumns[2].title}</MobileRowTitle>
          <MobileRowValue>
            <FormattingTooltip zIndex={1000}>{el.committedCapital}</FormattingTooltip>
          </MobileRowValue>
        </MobileRow>

        <MobileRow>
          <MobileRowTitle>{commitmentsTablecolumns[3].title}</MobileRowTitle>
          <MobileRowValue>
            <CellWithTooltip>
              {((el.capitalCalled / el.committedCapital) * 100).toFixed(2)}%
              <BasicTooltip tooltipContent={toolTipContent(el)} zIndex={1000} withTooltipIcon />
            </CellWithTooltip>
          </MobileRowValue>
        </MobileRow>

        <MobileRow>
          <MobileRowTitle>{commitmentsTablecolumns[4].title}</MobileRowTitle>
          <MobileRowValue>
            <FormattingTooltip zIndex={1000}>{+el.distributionsToDate}</FormattingTooltip>
          </MobileRowValue>
        </MobileRow>

        <MobileRow>
          <MobileRowTitle>{commitmentsTablecolumns[5].title}</MobileRowTitle>
          <MobileRowValue>
            {el.valuationDate || el.valuationAmount || el.aggregateCallsAndDistributions || el.marketValue ? (
              <CellWithTooltip>
                {formatCash(el.marketValue)}
                <BasicTooltip tooltipContent={marketValueToolTipContent(el)} withTooltipIcon zIndex={1000} />
              </CellWithTooltip>
            ) : (
              <FormattingTooltip zIndex={1000}>{el.marketValue}</FormattingTooltip>
            )}
          </MobileRowValue>
        </MobileRow>

        <MobileRow>
          <MobileRowTitle>{commitmentsTablecolumns[6].title}</MobileRowTitle>
          <MobileRowValue>
            <ColorTitle color={getColorStatus(el.status)}>{el.status === 'Outstanding' ? 'Pending Commitment' : el.status}</ColorTitle>
          </MobileRowValue>
        </MobileRow>
      </MobileTableRowWrap>
    );
  };

  return (
    <MobileTableWrapper>
      {investorCommitmentRows?.length ? (
        <>
          {investorCommitmentRows.map((el, index) => {
            return <TableRow el={el} key={index} />;
          })}
          <MobileSumFooter columns={commitmentsTablecolumns} clientsRows={investorCommitmentRows} loading={loading} />
        </>
      ) : (
        <NoResults>No Results</NoResults>
      )}
    </MobileTableWrapper>
  );
};

export default MobileTable;

const ColorTitle = styled(BasicTitle)<{ color: string }>`
  color: ${({ color }) => color};
  font-weight: 600;
`;

const CellWithTooltip = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;
