import { GroupedIcon } from '@/assets/icons/info-icons/groupedIcon';
import { PlusIcon } from '@/assets/icons/info-icons/plusIcon';
import { useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { IZeroStateAssetClassTableData } from '.';

interface AssetClassRowProps {
  assetClass: IZeroStateAssetClassTableData;
  targetSubAssetClasses: boolean;
}

export const AssetClassRow = ({ assetClass, targetSubAssetClasses }: AssetClassRowProps) => {
  const theme = useTheme();

  const [showSubAssetClass, setShowSubAssetClass] = useState(false);

  return (
    <>
      <SimpleTableRow>
        <RowItem width={'36%'}>
          {targetSubAssetClasses && assetClass.subAssetClassDrawdownTargets ? (
            <PlusIconWrapper onClick={() => setShowSubAssetClass(!showSubAssetClass)}>
              <PlusIcon fill={theme.font.base} />
            </PlusIconWrapper>
          ) : (
            <></>
          )}
          {assetClass.name}
        </RowItem>
        <RowItem width={'37%'} isBold={Boolean(assetClass.subAssetClassDrawdownTargets)}>
          {assetClass?.portfolioPercentageTarget?.toFixed(2)}%
        </RowItem>
        <RowItem width={'37%'} isBold={Boolean(assetClass.subAssetClassDrawdownTargets)}>
          {assetClass?.portfolioPercentageActual?.toFixed(2)}%
        </RowItem>
      </SimpleTableRow>
      {showSubAssetClass && (
        <>
          {assetClass.subAssetClassDrawdownTargets.map((subAssetClassForecast) => (
            <SimpleTableRow key={subAssetClassForecast.name + subAssetClassForecast.portfolioPercentageTarget}>
              <RowItem width={'36%'}>
                <GroupIconWrapper>
                  <GroupedIcon width={10} height={10} />
                </GroupIconWrapper>
                {subAssetClassForecast.name}
              </RowItem>
              <RowItem width={'37%'}>{subAssetClassForecast.portfolioPercentageTarget?.toFixed(2)}%</RowItem>
              <RowItem width={'37%'}>{subAssetClassForecast.portfolioPercentageActual?.toFixed(2)}%</RowItem>
            </SimpleTableRow>
          ))}
        </>
      )}
    </>
  );
};

const RowItem = styled.div<{ width: string; isBold?: boolean }>`
  width: ${({ width }) => width};
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 6px;
  font-family: Blinker, serif;
  font-size: 16px;
  font-weight: ${({ isBold }) => (isBold ? 600 : 400)};
  line-height: 22px;
  letter-spacing: 0;
  color: ${({ theme }) => theme.font.base};
`;

const PlusIconWrapper = styled.div`
  padding: 5px;
  cursor: pointer;
`;

const GroupIconWrapper = styled.div`
  padding: 5px;
  position: relative;
  top: -5px;
  left: 5px;
`;

const SimpleTableRow = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  background-color: ${({ theme }) => theme.layer[1]};
  margin-bottom: 3px;
  justify-content: space-between;
  border-radius: 4px;
  padding: 5px 24px;
  position: relative;
`;
