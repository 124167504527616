import { SortIcon } from '@/assets/icons/sortIcon';
import FormattingTooltip from '@/components/basicComponents/tooltip/dollarsTooltip';
import { ContainerPage } from '@/styles/common';
import { formatPercent } from '@/utils/currency';
import guid from '@/utils/guid';
import { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

type DetailsProps = {
  isOpen: boolean;
  isMobile: boolean;
  setOpen: () => void;
  setIsNotesOpen: () => void;
  data: any | undefined;
  capitalCalled: number;
};

const Details = ({ data, isOpen, setOpen, capitalCalled, isMobile }: DetailsProps) => {
  const [detailsData, setDetailsData] = useState();
  const detailsDataMemo = useMemo(() => {
    return data ? data : detailsData;
  }, [data]);

  useEffect(() => {
    if (data) {
      setDetailsData(data);
    }
  }, [data]);

  return (
    <DetailsWrap isOpen={isOpen} padding={isMobile ? 16 : '60px'}>
      <CardsContainer isOpen={isOpen} isMobile={isMobile}>
        <Card isMobile={isMobile}>
          <CardTitle>
            <FormattingTooltip zIndex={120} key={guid()}>
              {data?.committedCapital}
            </FormattingTooltip>
          </CardTitle>
          <CardDesc>COMMITTED CAPITAL</CardDesc>
        </Card>
        <Card isMobile={isMobile}>
          <CardTitle>
            <FormattingTooltip zIndex={120}>{capitalCalled}</FormattingTooltip>
          </CardTitle>
          <CardDesc>CAPITAL CALLED</CardDesc>
        </Card>
      </CardsContainer>

      <MoreDetails isOpen={isOpen} isMobile={isMobile}>
        <MoreDetailItem isMobile={isMobile}>
          <ItemRow>
            <ItemTitle isMobile={isMobile}>Manager</ItemTitle>
            {/* <ItemValue>{router.query.mid}</ItemValue> */}
          </ItemRow>

          <ItemRow>
            <ItemTitle isMobile={isMobile}>Asset Class</ItemTitle>
            <ItemValue>{detailsDataMemo?.assetClass}</ItemValue>
          </ItemRow>
        </MoreDetailItem>

        <MoreDetailItem isMobile={isMobile}>
          <ItemRow>
            <ItemTitle isMobile={isMobile}>Vintage</ItemTitle>
            <ItemValue>{data?.vintageYear && Array.from(new Set(data?.vintageYear.split(/, /g))).toString()}</ItemValue>
          </ItemRow>

          <ItemRow>
            <ItemTitle isMobile={isMobile}>Strategies</ItemTitle>
            <SectorsWrap>
              {detailsDataMemo?.sectors.filter((sector: string) => sector !== '').length ? (
                detailsDataMemo?.sectors
                  .filter((x: string, i: number, a: string[]) => a.indexOf(x) == i)
                  .map((el: string) => {
                    if (el.length) {
                      return <SectorsItem key={guid()}>{el.trim()}</SectorsItem>;
                    }
                  })
              ) : (
                <ItemValue>N/A</ItemValue>
              )}
            </SectorsWrap>
          </ItemRow>
        </MoreDetailItem>

        <MoreDetailItem isMobile={isMobile}>
          <ItemRow>
            <ItemTitle isMobile={isMobile}>Target IRR</ItemTitle>
            <ItemValue>{formatPercent(detailsDataMemo?.targetReturn)}</ItemValue>
          </ItemRow>

          <ItemRow>
            <ItemTitle isMobile={isMobile}>Target Yield</ItemTitle>
            <ItemValue>{formatPercent(detailsDataMemo?.targetYield)}</ItemValue>
          </ItemRow>
        </MoreDetailItem>
      </MoreDetails>

      <ControlDetails onClick={setOpen}>
        <ArrowBtn isRotate={isOpen}>
          <SortIcon fill={'#4587EC'} />
        </ArrowBtn>
        <ControlDetailsTitle>{isOpen ? 'Hide Details' : 'Show Details'}</ControlDetailsTitle>
      </ControlDetails>
    </DetailsWrap>
  );
};

const ArrowBtn = styled.div<{ isRotate: boolean }>`
  transform: ${({ isRotate }) => (isRotate ? 'rotate(180deg)' : 'rotate(0deg)')};
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.3s;
`;

const SectorsWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  width: 70%;
`;

const SectorsItem = styled.div`
  font-family: Blinker, serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  color: #373f4e;
`;

const ControlDetailsTitle = styled.div`
  font-family: Blinker, serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  color: #4587ec;
  z-index: 30;
`;

const ControlDetails = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
  align-self: flex-end;
  padding-top: 30px;
  padding-bottom: 10px;
  gap: 12.5px;
  z-index: 100;
`;

const ItemValue = styled.div`
  font-family: Blinker, serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  color: #373f4e;
`;

const ItemTitle = styled.div<{ isMobile: boolean }>`
  font-family: Blinker, serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  color: #373f4e;
  width: ${({ isMobile }) => (isMobile ? '30%' : '30%')};
`;

const ItemRow = styled.div`
  display: flex;
  //width: 310px;
  gap: 10px;
`;

const MoreDetailItem = styled.div<{ isMobile: boolean }>`
  display: flex;
  flex-direction: column;
  width: ${({ isMobile }) => (isMobile ? '100%' : '50%')};
  gap: 14px;
  justify-content: flex-start;
  padding: 25px 45px 12px 45px;
  background: #fafafa;
  border-radius: 5px 5px;
`;

const CardsContainer = styled.div<{ isOpen: boolean; isMobile: boolean }>`
  display: flex;
  gap: 10px;
  flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};
  height: ${({ isOpen, isMobile }) => (isMobile && isOpen ? '100%' : isOpen ? '80px' : '0px')};
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
  transition: ${({ isMobile }) => (isMobile ? 'height, opacity 0.5s ease-in-out' : 'none')};
`;

const MoreDetails = styled.div<{ isOpen: boolean; isMobile: boolean }>`
  display: flex;
  gap: ${({ isMobile }) => (isMobile ? '14px' : '0')};
  flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};
  padding-top: ${({ isOpen }) => (isOpen ? '10px' : 0)};
  padding-bottom: ${({ isOpen }) => (isOpen ? '1px' : 0)};
  height: ${({ isOpen, isMobile }) => (isMobile && isOpen ? '100%' : isOpen && !isMobile ? '10%' : '0px')};
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
  transition: ${({ isMobile }) => (!isMobile ? 'height, opacity 0.5s ease-in-out' : 'none')};
`;

const CardTitle = styled.div`
  color: #2e2e2e;
  font-family: Blinker, serif;
  font-size: 23px;
  font-weight: 700;
  line-height: 32px;
  text-align: center;
`;

const CardDesc = styled.div`
  color: #7f7f7f;
  font-family: Blinker, serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0;
  text-align: center;
`;

const Card = styled.div<{ isMobile: boolean; short?: boolean; isOpen?: boolean; notesLength?: number }>`
  display: flex;
  cursor: ${({ short, isOpen, notesLength }) => (short && isOpen && notesLength ? 'pointer' : 'default')};
  width: ${({ short, isMobile }) => (!isMobile ? '100%' : short ? '5.3%' : '100%')};
  height: 80px;
  min-height: 80px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #fafafa;
  border-radius: 10px;
`;

const DetailsWrap = styled(ContainerPage)<{ isOpen: boolean; padding?: string | number }>`
  padding-top: 20px;
  //padding-bottom: 10px;
  padding-right: ${({ padding }) => padding};
  padding-left: ${({ padding }) => padding};
  background: ${({ isOpen }) => (isOpen ? '#ffffff' : 'transparent')};
  border-bottom: ${({ isOpen }) => (isOpen ? '1px solid transparent' : 'none')};
  transition:
    height,
    opacity 0.5s ease-in-out;
`;

export default Details;
