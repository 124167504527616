import {
  MobileRow,
  MobileRowTitle,
  MobileRowValue,
  MobileTableRowWrap,
  MobileTableWrapper,
  NoResults
} from '@/components/table/mobileTable/mobileTable';

import { getUTCDate } from '@/components/allocations-page/utils';
import BasicTooltip from '@/components/basicComponents/tooltip';
import FormattingTooltip from '@/components/basicComponents/tooltip/dollarsTooltip';
import { formatterClient } from '@/components/clients-page/utils';
import { TableBodyWrapper, TableRowCell, TextCell } from '@/components/table/styles';
import { formatCash } from '@/utils/formatCash';
import guid from '@/utils/guid';
import styled from 'styled-components';
import { transactionsTableColumns } from '../../constatnts';
import { IListEntityTransactionsData } from '../../types';

interface MobileTableProps {
  tableRows: IListEntityTransactionsData[];
}

const MobileTable = ({ tableRows }: MobileTableProps) => {
  const amountToolTipContent = (row: IListEntityTransactionsData) => {
    return (
      <TableBodyWrapper>
        <TableRowCell width={100}>
          <TextCell>Investment Amount: {formatterClient.format(row.amount)}</TextCell>
        </TableRowCell>
        <TableRowCell width={100}>
          <TextCell>Total Amount incl Fees: {formatterClient.format(row.totalAmount)}</TextCell>
        </TableRowCell>
      </TableBodyWrapper>
    );
  };

  const TableRow = ({ el }: { el: IListEntityTransactionsData }) => {
    return (
      <MobileTableRowWrap key={guid()}>
        <MobileRow>
          <MobileRowTitle>{transactionsTableColumns[0].title}</MobileRowTitle>
          <MobileRowValue>{el.strategy}</MobileRowValue>
        </MobileRow>

        <MobileRow>
          <MobileRowTitle>{transactionsTableColumns[1].title}</MobileRowTitle>
          <MobileRowValue>{getUTCDate(el.date)}</MobileRowValue>
        </MobileRow>

        <MobileRow>
          <MobileRowTitle>{transactionsTableColumns[2].title}</MobileRowTitle>
          <MobileRowValue>{el.transactionType}</MobileRowValue>
        </MobileRow>

        <MobileRow>
          <MobileRowTitle>{transactionsTableColumns[3].title}</MobileRowTitle>
          <MobileRowValue>
            <CellWithTooltip>
              {formatCash(el.amount)}
              <BasicTooltip tooltipContent={amountToolTipContent(el)} withTooltipIcon zIndex={1000} />
            </CellWithTooltip>
          </MobileRowValue>
        </MobileRow>

        <MobileRow>
          <MobileRowTitle>{transactionsTableColumns[4].title}</MobileRowTitle>
          <MobileRowValue>
            <MobileRowValue>
              <FormattingTooltip zIndex={1000}>{el.distributionsToDate}</FormattingTooltip>
            </MobileRowValue>
          </MobileRowValue>
        </MobileRow>
        <MobileRow>
          <MobileRowTitle>{transactionsTableColumns[5].title}</MobileRowTitle>
          <MobileRowValue>
            <FormattingTooltip zIndex={1000}>{el.remainingCapital}</FormattingTooltip>
          </MobileRowValue>
        </MobileRow>
        <MobileRow>
          <MobileRowTitle>{transactionsTableColumns[6].title}</MobileRowTitle>
          <MobileRowValue>
            <FormattingTooltip zIndex={1000}>{el.marketValue}</FormattingTooltip>
          </MobileRowValue>
        </MobileRow>
      </MobileTableRowWrap>
    );
  };

  return (
    <MobileTableWrapper>
      {tableRows?.length ? (
        tableRows.map((el, index) => {
          return <TableRow el={el} key={index} />;
        })
      ) : (
        <NoResults>No Results</NoResults>
      )}
    </MobileTableWrapper>
  );
};

export default MobileTable;

const CellWithTooltip = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;
