import * as React from 'react';

export const CURIOLogoOnly = ({ ...props }: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="174"
    height="40"
    style={{ cursor: 'pointer' }}
    onClick={props.onClick}
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 385.01 72.35"
  >
    <rect x="0" fill="#FFFFFF" width="72.32" height="72.32" />
    <path fill="#EF9439" d="M30.17,45.87V31.63h-4.41V46.8C27.16,46.41,28.64,46.1,30.17,45.87z" />
    <g>
      <path
        d="M0,0v72.32h25.75h4.35h0.06V52.85c1.23-0.07,2.49-0.12,3.79-0.14v19.61h4.41V52.71c1.35,0.03,2.67,0.08,3.95,0.16v19.45
		h0.05h4.37h25.59V0H0z M67.91,67.91H46.73V53.23c4.44,0.48,8.17,1.25,10.7,2.21c-4.38-4.37-12.3-7.29-21.34-7.29
		s-16.96,2.92-21.34,7.29c2.59-0.98,6.42-1.76,10.99-2.24v14.71H4.41V4.41h63.49V67.91z"
      />
      <path fill="#EF9439" d="M38.37,45.49V21.72h-4.41v23.76c0.73-0.04,1.46-0.06,2.2-0.06C36.9,45.43,37.64,45.45,38.37,45.49z" />
    </g>
    <path fill="#EF9439" d="M46.73,46.85V12.46h-4.41v33.44C43.85,46.13,45.33,46.45,46.73,46.85z" />
    <g>
      <path
        fill="#FFFFFF"
        d="M118.14,3.04c-0.15,1.44-0.26,2.83-0.31,4.17c-0.06,1.34-0.09,2.37-0.09,3.09c0,0.91,0.02,1.76,0.06,2.55
		c0.04,0.79,0.08,1.49,0.11,2.1h-1.3c-0.26-2.65-0.62-4.74-1.08-6.27s-1.25-2.64-2.38-3.32s-2.89-1.02-5.27-1.02h-3.57
		c-1.29,0-2.26,0.1-2.92,0.31c-0.66,0.21-1.11,0.64-1.33,1.3c-0.23,0.66-0.34,1.69-0.34,3.09v28.13c0,1.36,0.14,2.38,0.43,3.06
		c0.28,0.68,0.84,1.13,1.67,1.36c0.83,0.23,2.04,0.38,3.63,0.45v1.13c-0.98-0.08-2.23-0.12-3.74-0.14
		c-1.51-0.02-3.04-0.03-4.59-0.03c-1.47,0-2.85,0.01-4.14,0.03c-1.29,0.02-2.32,0.07-3.12,0.14v-1.13c1.28-0.08,2.26-0.23,2.92-0.45
		c0.66-0.23,1.11-0.68,1.33-1.36c0.23-0.68,0.34-1.7,0.34-3.06V9.05c0-1.4-0.11-2.43-0.34-3.09c-0.23-0.66-0.67-1.11-1.33-1.36
		c-0.66-0.25-1.64-0.39-2.92-0.43V3.04c1.4,0.04,2.87,0.07,4.42,0.09c1.55,0.02,3.08,0.04,4.59,0.06c1.51,0.02,2.92,0.03,4.22,0.03
		c1.3,0,2.37,0,3.2,0c1.74,0,3.71-0.01,5.93-0.03C114.43,3.16,116.41,3.11,118.14,3.04z M109.35,23v1.13H98.01V23H109.35z
		 M110.43,15.51c-0.15,2.15-0.22,3.8-0.2,4.93c0.02,1.13,0.03,2.17,0.03,3.12c0,0.95,0.02,1.98,0.06,3.12
		c0.04,1.13,0.13,2.78,0.28,4.93h-1.3c-0.15-1.21-0.33-2.39-0.54-3.54c-0.21-1.15-0.66-2.1-1.36-2.84c-0.7-0.74-1.88-1.11-3.54-1.11
		V23c1.25,0,2.2-0.26,2.86-0.77c0.66-0.51,1.14-1.16,1.45-1.96c0.3-0.79,0.51-1.62,0.62-2.47c0.11-0.85,0.23-1.62,0.34-2.3H110.43z"
      />
      <path
        fill="#FFFFFF"
        d="M150.98,3.04c-0.15,1.32-0.26,2.61-0.31,3.86c-0.06,1.25-0.09,2.19-0.09,2.84c0,0.68,0.02,1.33,0.06,1.96
		c0.04,0.62,0.08,1.14,0.11,1.56h-1.3c-0.23-2.23-0.55-4-0.96-5.3c-0.42-1.3-1.13-2.23-2.13-2.78c-1-0.55-2.5-0.82-4.51-0.82h-4.71
		c-1.29,0-2.26,0.1-2.92,0.31c-0.66,0.21-1.11,0.64-1.33,1.3c-0.23,0.66-0.34,1.69-0.34,3.09v28.13c0,1.36,0.11,2.38,0.34,3.06
		c0.23,0.68,0.67,1.13,1.33,1.33c0.66,0.21,1.63,0.31,2.92,0.31h4.14c2.38,0,4.19-0.32,5.42-0.96c1.23-0.64,2.12-1.69,2.67-3.15
		c0.55-1.45,0.95-3.43,1.22-5.93h1.3c-0.11,1.02-0.17,2.38-0.17,4.08c0,0.72,0.03,1.75,0.09,3.09c0.06,1.34,0.16,2.73,0.31,4.17
		c-1.93-0.08-4.1-0.12-6.52-0.14c-2.42-0.02-4.57-0.03-6.46-0.03c-0.83,0-1.9,0-3.2,0c-1.3,0-2.71,0.01-4.22,0.03
		c-1.51,0.02-3.04,0.04-4.59,0.06c-1.55,0.02-3.03,0.05-4.42,0.09v-1.13c1.28-0.08,2.26-0.23,2.92-0.45
		c0.66-0.23,1.11-0.68,1.33-1.36c0.23-0.68,0.34-1.7,0.34-3.06V9.05c0-1.4-0.11-2.43-0.34-3.09c-0.23-0.66-0.67-1.11-1.33-1.36
		c-0.66-0.25-1.64-0.39-2.92-0.43V3.04c1.4,0.04,2.87,0.07,4.42,0.09c1.55,0.02,3.08,0.04,4.59,0.06c1.51,0.02,2.92,0.03,4.22,0.03
		c1.3,0,2.37,0,3.2,0c1.74,0,3.71-0.01,5.93-0.03C147.26,3.16,149.24,3.11,150.98,3.04z M141.22,22.43v1.13h-10.38v-1.13H141.22z
		 M142.87,14.94c-0.15,2.16-0.22,3.8-0.2,4.93c0.02,1.13,0.03,2.17,0.03,3.12c0,0.95,0.02,1.98,0.06,3.12
		c0.04,1.13,0.13,2.78,0.28,4.93h-1.3c-0.15-1.21-0.33-2.39-0.54-3.54c-0.21-1.15-0.66-2.1-1.36-2.84c-0.7-0.74-1.88-1.11-3.54-1.11
		v-1.13c1.25,0,2.2-0.26,2.86-0.77c0.66-0.51,1.14-1.16,1.45-1.96c0.3-0.79,0.51-1.62,0.62-2.47c0.11-0.85,0.23-1.62,0.34-2.3
		H142.87z"
      />
      <path
        fill="#FFFFFF"
        d="M176.72,2.24c2.46,0,4.44,0.37,5.95,1.11c1.51,0.74,2.93,1.6,4.25,2.58c0.49,0.38,0.87,0.57,1.13,0.57
		c0.72,0,1.19-1.15,1.42-3.46h1.3c-0.08,1.4-0.13,3.1-0.17,5.1c-0.04,2-0.06,4.65-0.06,7.94h-1.3c-0.26-1.59-0.59-3.12-0.96-4.59
		c-0.38-1.47-1-2.74-1.87-3.8c-1.1-1.4-2.53-2.49-4.31-3.26c-1.78-0.77-3.61-1.16-5.5-1.16c-1.89,0-3.6,0.46-5.13,1.39
		c-1.53,0.93-2.85,2.26-3.94,4c-1.1,1.74-1.94,3.83-2.52,6.27c-0.59,2.44-0.88,5.19-0.88,8.25c0,6.81,1.1,11.79,3.29,14.94
		c2.19,3.16,5.59,4.74,10.21,4.74c1.36,0,2.54-0.2,3.54-0.6c1-0.4,1.79-0.78,2.35-1.16c0.72-0.49,1.17-0.95,1.36-1.39
		c0.19-0.43,0.28-1.01,0.28-1.73v-5.44c0-1.55-0.15-2.7-0.45-3.46c-0.3-0.76-0.86-1.27-1.67-1.53c-0.81-0.26-2.01-0.42-3.6-0.45
		v-1.13c0.68,0.04,1.47,0.07,2.38,0.08c0.91,0.02,1.86,0.04,2.86,0.06c1,0.02,1.92,0.03,2.75,0.03c1.25,0,2.37-0.02,3.37-0.06
		c1-0.04,1.84-0.08,2.52-0.11v1.13c-0.83,0.04-1.46,0.17-1.87,0.4c-0.42,0.23-0.69,0.68-0.82,1.36c-0.13,0.68-0.2,1.72-0.2,3.12
		v11.23h-1.13c-0.04-0.64-0.18-1.34-0.43-2.1c-0.25-0.76-0.61-1.13-1.11-1.13c-0.23,0-0.45,0.06-0.68,0.17
		c-0.23,0.11-0.62,0.38-1.19,0.79c-1.25,0.95-2.57,1.69-3.97,2.24c-1.4,0.55-3.03,0.82-4.88,0.82c-3.89,0-7.23-0.8-10.01-2.41
		c-2.78-1.61-4.91-3.92-6.38-6.95c-1.47-3.02-2.21-6.63-2.21-10.83c0-4.42,0.79-8.24,2.38-11.46c1.59-3.21,3.75-5.7,6.49-7.46
		C170.08,3.12,173.21,2.24,176.72,2.24z"
      />
      <path
        fill="#FFFFFF"
        d="M231.56,2.24c2.5,0,4.55,0.37,6.15,1.11c1.61,0.74,3.03,1.6,4.28,2.58c0.76,0.57,1.33,0.63,1.73,0.2
		c0.4-0.43,0.67-1.46,0.82-3.09h1.3c-0.08,1.4-0.13,3.1-0.17,5.1c-0.04,2-0.06,4.65-0.06,7.94h-1.3c-0.26-1.62-0.51-2.92-0.74-3.88
		s-0.5-1.79-0.82-2.47c-0.32-0.68-0.75-1.36-1.28-2.04c-1.17-1.55-2.65-2.67-4.42-3.37c-1.78-0.7-3.65-1.05-5.61-1.05
		c-1.85,0-3.53,0.46-5.02,1.39c-1.49,0.93-2.78,2.26-3.86,4c-1.08,1.74-1.91,3.84-2.5,6.29c-0.59,2.46-0.88,5.22-0.88,8.28
		c0,3.18,0.32,5.98,0.96,8.42c0.64,2.44,1.54,4.49,2.69,6.15c1.15,1.66,2.51,2.92,4.08,3.77c1.57,0.85,3.26,1.28,5.08,1.28
		c1.7,0,3.48-0.34,5.33-1.02c1.85-0.68,3.31-1.78,4.37-3.29c0.83-1.1,1.41-2.29,1.73-3.57c0.32-1.28,0.61-3.08,0.88-5.39h1.3
		c0,3.44,0.02,6.21,0.06,8.31c0.04,2.1,0.09,3.87,0.17,5.3h-1.3c-0.15-1.63-0.41-2.65-0.77-3.06c-0.36-0.42-0.96-0.36-1.79,0.17
		c-1.4,0.98-2.88,1.84-4.45,2.58c-1.57,0.74-3.58,1.11-6.04,1.11c-3.59,0-6.74-0.81-9.44-2.44c-2.7-1.62-4.8-3.97-6.29-7.03
		c-1.49-3.06-2.24-6.75-2.24-11.06c0-4.23,0.77-7.94,2.33-11.11c1.55-3.18,3.68-5.65,6.38-7.43C224.93,3.13,228.04,2.24,231.56,2.24
		z"
      />
      <path
        fill="#FFFFFF"
        d="M287.25,3.04v1.13c-1.29,0.04-2.26,0.23-2.92,0.57c-0.66,0.34-1.11,0.89-1.33,1.64
		c-0.23,0.76-0.34,1.83-0.34,3.23v17.07c0,2.65-0.17,4.95-0.51,6.92c-0.34,1.97-0.95,3.67-1.81,5.1c-0.95,1.59-2.33,2.86-4.14,3.83
		s-3.89,1.45-6.24,1.45c-1.81,0-3.54-0.22-5.19-0.65c-1.64-0.43-3.09-1.24-4.34-2.41c-1.1-1.06-1.96-2.17-2.58-3.35
		c-0.62-1.17-1.05-2.61-1.28-4.31c-0.23-1.7-0.34-3.84-0.34-6.41V9.05c0-1.4-0.11-2.43-0.34-3.09c-0.23-0.66-0.67-1.11-1.33-1.36
		c-0.66-0.25-1.64-0.39-2.92-0.43V3.04c0.79,0.04,1.83,0.08,3.12,0.11c1.28,0.04,2.67,0.06,4.14,0.06c1.32,0,2.64-0.02,3.94-0.06
		c1.3-0.04,2.39-0.08,3.26-0.11v1.13c-1.29,0.04-2.26,0.18-2.92,0.43c-0.66,0.25-1.11,0.7-1.33,1.36c-0.23,0.66-0.34,1.69-0.34,3.09
		v18.77c0,1.93,0.09,3.75,0.28,5.47c0.19,1.72,0.58,3.23,1.16,4.54c0.59,1.3,1.48,2.33,2.69,3.06c1.21,0.74,2.82,1.11,4.82,1.11
		c2.87,0,5.09-0.63,6.66-1.9c1.57-1.27,2.67-3.01,3.29-5.25c0.62-2.23,0.94-4.74,0.94-7.54V9.61c0-1.4-0.16-2.48-0.48-3.23
		c-0.32-0.76-0.86-1.3-1.62-1.64c-0.76-0.34-1.78-0.53-3.06-0.57V3.04c0.68,0.04,1.58,0.08,2.69,0.11c1.11,0.04,2.15,0.06,3.09,0.06
		c0.98,0,1.96-0.02,2.92-0.06C285.86,3.11,286.64,3.07,287.25,3.04z"
      />
      <path
        fill="#FFFFFF"
        d="M291.73,3.04c0.79,0.04,1.83,0.08,3.12,0.11c1.28,0.04,2.55,0.06,3.8,0.06c1.78,0,3.48-0.02,5.1-0.06
		c1.63-0.04,2.78-0.06,3.46-0.06c3.06,0,5.6,0.44,7.63,1.3c2.02,0.87,3.53,2.05,4.54,3.54c1,1.49,1.5,3.17,1.5,5.02
		c0,1.13-0.23,2.34-0.68,3.63c-0.45,1.29-1.24,2.5-2.35,3.63c-1.12,1.13-2.64,2.07-4.57,2.81c-1.93,0.74-4.37,1.11-7.32,1.11h-5.44
		V23h4.88c2.68,0,4.74-0.43,6.15-1.28c1.42-0.85,2.38-1.98,2.89-3.4c0.51-1.42,0.77-3,0.77-4.74c0-2.99-0.71-5.3-2.13-6.95
		c-1.42-1.64-3.85-2.47-7.29-2.47c-1.74,0-2.87,0.32-3.4,0.96c-0.53,0.64-0.79,1.95-0.79,3.91v28.13c0,1.36,0.11,2.38,0.34,3.06
		c0.23,0.68,0.67,1.13,1.33,1.36c0.66,0.23,1.63,0.38,2.92,0.45v1.13c-0.87-0.08-1.96-0.12-3.26-0.14c-1.3-0.02-2.62-0.03-3.94-0.03
		c-1.47,0-2.85,0.01-4.14,0.03c-1.29,0.02-2.32,0.07-3.12,0.14v-1.13c1.28-0.08,2.26-0.23,2.92-0.45c0.66-0.23,1.11-0.68,1.33-1.36
		c0.23-0.68,0.34-1.7,0.34-3.06V9.05c0-1.4-0.11-2.43-0.34-3.09c-0.23-0.66-0.67-1.11-1.33-1.36c-0.66-0.25-1.64-0.39-2.92-0.43
		V3.04z M300.74,23.17c2.04,0.08,3.65,0.16,4.82,0.26c1.17,0.09,2.13,0.18,2.86,0.26c0.74,0.08,1.45,0.17,2.13,0.28
		c2.53,0.42,4.36,1.18,5.47,2.3c1.11,1.12,1.94,2.85,2.47,5.19l1.42,5.78c0.38,1.66,0.77,2.86,1.16,3.6
		c0.4,0.74,1.01,1.09,1.84,1.05c0.64-0.04,1.18-0.25,1.62-0.62c0.43-0.38,0.9-0.89,1.39-1.53l0.85,0.62
		c-0.79,1.17-1.62,2.07-2.47,2.69c-0.85,0.62-2.05,0.94-3.6,0.94c-1.4,0-2.63-0.42-3.69-1.25c-1.06-0.83-1.85-2.46-2.38-4.88
		l-1.25-5.67c-0.34-1.63-0.73-3.03-1.16-4.22c-0.44-1.19-1.04-2.13-1.81-2.81c-0.78-0.68-1.92-1.02-3.43-1.02h-6.12L300.74,23.17z"
      />
      <path
        fill="#FFFFFF"
        d="M343.5,3.04v1.13c-1.29,0.04-2.26,0.18-2.92,0.43c-0.66,0.25-1.11,0.7-1.33,1.36
		c-0.23,0.66-0.34,1.69-0.34,3.09v28.13c0,1.36,0.11,2.38,0.34,3.06c0.23,0.68,0.67,1.13,1.33,1.36c0.66,0.23,1.63,0.38,2.92,0.45
		v1.13c-0.87-0.08-1.96-0.12-3.26-0.14c-1.3-0.02-2.62-0.03-3.94-0.03c-1.47,0-2.85,0.01-4.14,0.03c-1.29,0.02-2.32,0.07-3.12,0.14
		v-1.13c1.28-0.08,2.26-0.23,2.92-0.45c0.66-0.23,1.11-0.68,1.33-1.36c0.23-0.68,0.34-1.7,0.34-3.06V9.05c0-1.4-0.11-2.43-0.34-3.09
		c-0.23-0.66-0.67-1.11-1.33-1.36c-0.66-0.25-1.64-0.39-2.92-0.43V3.04c0.79,0.04,1.83,0.08,3.12,0.11
		c1.28,0.04,2.67,0.06,4.14,0.06c1.32,0,2.64-0.02,3.94-0.06C341.54,3.11,342.63,3.07,343.5,3.04z"
      />
      <path
        fill="#FFFFFF"
        d="M367.03,2.24c3.59,0,6.74,0.81,9.44,2.44c2.7,1.63,4.8,3.96,6.29,7c1.49,3.04,2.24,6.74,2.24,11.09
		c0,4.23-0.77,7.94-2.3,11.11c-1.53,3.18-3.66,5.65-6.38,7.43c-2.72,1.78-5.84,2.67-9.36,2.67c-3.59,0-6.74-0.81-9.44-2.44
		c-2.7-1.62-4.8-3.97-6.29-7.03c-1.49-3.06-2.24-6.75-2.24-11.06c0-4.23,0.77-7.94,2.33-11.11c1.55-3.18,3.68-5.65,6.38-7.43
		C360.41,3.13,363.52,2.24,367.03,2.24z M366.81,3.26c-2.46,0-4.59,0.85-6.41,2.55c-1.81,1.7-3.22,4.05-4.22,7.03
		c-1,2.99-1.5,6.45-1.5,10.38c0,4.01,0.56,7.5,1.67,10.46c1.11,2.97,2.63,5.26,4.54,6.86c1.91,1.61,4.02,2.41,6.32,2.41
		c2.46,0,4.59-0.85,6.41-2.55c1.81-1.7,3.22-4.05,4.22-7.06c1-3.01,1.5-6.45,1.5-10.35c0-4.04-0.56-7.54-1.67-10.49
		c-1.12-2.95-2.62-5.23-4.51-6.83C371.27,4.07,369.15,3.26,366.81,3.26z"
      />
    </g>
    <g>
      <path fill="#FFFFFF" d="M93.35,61.6v10.75h-1.43V61.6H93.35z" />
      <path fill="#FFFFFF" d="M103.89,61.6l5.66,8.72h0.03V61.6h1.36v10.75h-1.57l-5.62-8.63h-0.03v8.63h-1.36V61.6H103.89z" />
      <path fill="#FFFFFF" d="M122.49,72.35l-3.78-10.75h1.54l3.07,9.32h0.03l3.1-9.32h1.49l-3.83,10.75H122.49z" />
      <path fill="#FFFFFF" d="M143.14,61.6v1.2h-5.99v3.42h5.59v1.2h-5.59v3.72h6.04v1.2h-7.47V61.6H143.14z" />
      <path
        fill="#FFFFFF"
        d="M156.93,63.07c-0.48-0.35-1.09-0.52-1.83-0.52c-0.3,0-0.6,0.03-0.89,0.09c-0.29,0.06-0.55,0.16-0.78,0.3
		c-0.23,0.14-0.41,0.33-0.54,0.56c-0.14,0.24-0.2,0.53-0.2,0.88c0,0.33,0.1,0.6,0.29,0.81c0.2,0.21,0.46,0.37,0.78,0.5
		c0.33,0.13,0.7,0.24,1.11,0.32c0.41,0.09,0.83,0.18,1.26,0.28c0.43,0.1,0.85,0.22,1.26,0.35c0.41,0.14,0.78,0.32,1.11,0.55
		c0.33,0.23,0.59,0.52,0.78,0.87c0.2,0.35,0.29,0.79,0.29,1.33c0,0.57-0.13,1.06-0.38,1.47c-0.26,0.41-0.58,0.74-0.99,0.99
		c-0.4,0.26-0.85,0.44-1.35,0.56c-0.5,0.12-0.99,0.17-1.48,0.17c-0.6,0-1.17-0.08-1.71-0.23c-0.54-0.15-1.01-0.38-1.42-0.69
		c-0.41-0.31-0.73-0.7-0.96-1.17c-0.24-0.48-0.35-1.04-0.35-1.69h1.36c0,0.45,0.09,0.84,0.26,1.17c0.18,0.33,0.41,0.59,0.69,0.81
		c0.29,0.21,0.62,0.37,1,0.47c0.38,0.1,0.77,0.15,1.17,0.15c0.32,0,0.64-0.03,0.97-0.09c0.33-0.06,0.62-0.16,0.88-0.31
		c0.26-0.15,0.47-0.34,0.63-0.59c0.16-0.25,0.24-0.57,0.24-0.96c0-0.37-0.1-0.67-0.29-0.9c-0.2-0.23-0.46-0.42-0.78-0.56
		c-0.33-0.15-0.7-0.26-1.11-0.35c-0.41-0.09-0.83-0.18-1.26-0.28c-0.43-0.1-0.85-0.21-1.26-0.33c-0.41-0.13-0.78-0.29-1.11-0.5
		c-0.33-0.21-0.59-0.47-0.78-0.8c-0.2-0.33-0.29-0.74-0.29-1.23c0-0.54,0.11-1.01,0.33-1.41c0.22-0.4,0.51-0.72,0.88-0.98
		c0.37-0.26,0.78-0.45,1.25-0.57c0.47-0.13,0.95-0.19,1.44-0.19c0.55,0,1.06,0.07,1.54,0.2c0.47,0.13,0.89,0.34,1.24,0.62
		c0.36,0.28,0.64,0.64,0.84,1.06c0.21,0.43,0.32,0.94,0.34,1.53h-1.36C157.68,63.98,157.4,63.42,156.93,63.07z"
      />
      <path fill="#FFFFFF" d="M166.78,62.81v-1.2h8.6v1.2h-3.58v9.55h-1.43v-9.55H166.78z" />
      <path
        fill="#FFFFFF"
        d="M185.18,61.6l3.39,9.04l3.4-9.04h1.96v10.75h-1.36v-8.95h-0.03l-3.36,8.95h-1.22l-3.36-8.95h-0.03v8.95h-1.36
		V61.6H185.18z"
      />
      <path fill="#FFFFFF" d="M210.36,61.6v1.2h-5.99v3.42h5.59v1.2h-5.59v3.72h6.04v1.2h-7.47V61.6H210.36z" />
      <path fill="#FFFFFF" d="M220.27,61.6l5.66,8.72h0.03V61.6h1.36v10.75h-1.57l-5.62-8.63h-0.03v8.63h-1.36V61.6H220.27z" />
      <path fill="#FFFFFF" d="M235.12,62.81v-1.2h8.6v1.2h-3.58v9.55h-1.43v-9.55H235.12z" />
      <path
        fill="#FFFFFF"
        d="M267.06,61.6c1.08,0,1.91,0.28,2.46,0.84c0.56,0.56,0.84,1.34,0.84,2.34c0,1-0.28,1.79-0.84,2.35
		c-0.56,0.56-1.38,0.84-2.46,0.83h-3.28v4.4h-1.43V61.6H267.06z M266.58,66.75c0.81,0.01,1.41-0.16,1.78-0.5
		c0.38-0.34,0.56-0.83,0.56-1.48c0-0.64-0.19-1.13-0.56-1.47c-0.38-0.34-0.97-0.5-1.78-0.5h-2.8v3.95H266.58z"
      />
      <path
        fill="#FFFFFF"
        d="M282.12,61.6l4.2,10.75h-1.58l-1.17-3.24h-4.55l-1.2,3.24h-1.46l4.19-10.75H282.12z M283.11,67.91l-1.79-5.01
		h-0.03l-1.82,5.01H283.11z"
      />
      <path
        fill="#FFFFFF"
        d="M299.06,61.6c1.02,0,1.82,0.25,2.4,0.75c0.58,0.5,0.87,1.19,0.87,2.06c0,0.65-0.15,1.23-0.44,1.72
		c-0.3,0.49-0.77,0.83-1.42,1.01v0.03c0.31,0.06,0.56,0.16,0.76,0.3c0.2,0.14,0.35,0.31,0.47,0.5c0.12,0.2,0.2,0.41,0.26,0.66
		c0.06,0.24,0.1,0.49,0.13,0.75c0.02,0.26,0.04,0.53,0.05,0.8c0.01,0.27,0.04,0.54,0.08,0.8c0.04,0.26,0.1,0.51,0.17,0.75
		c0.08,0.24,0.19,0.44,0.34,0.62h-1.6c-0.1-0.11-0.17-0.26-0.2-0.45c-0.04-0.19-0.06-0.4-0.07-0.64c-0.01-0.24-0.02-0.49-0.03-0.76
		c-0.01-0.27-0.04-0.54-0.09-0.8c-0.04-0.26-0.09-0.51-0.15-0.75c-0.06-0.24-0.16-0.44-0.29-0.62c-0.13-0.18-0.3-0.32-0.51-0.42
		c-0.21-0.11-0.49-0.16-0.84-0.16h-3.49v4.59H294V61.6H299.06z M299.36,66.48c0.3-0.05,0.56-0.15,0.79-0.29
		c0.23-0.15,0.41-0.34,0.54-0.59c0.14-0.25,0.2-0.56,0.2-0.96c0-0.54-0.15-0.98-0.45-1.33c-0.3-0.34-0.79-0.51-1.46-0.51h-3.55v3.75
		h2.98C298.75,66.56,299.06,66.53,299.36,66.48z"
      />
      <path fill="#FFFFFF" d="M309.53,62.81v-1.2h8.6v1.2h-3.58v9.55h-1.43v-9.55H309.53z" />
      <path fill="#FFFFFF" d="M327.45,61.6l5.66,8.72h0.03V61.6h1.36v10.75h-1.57l-5.62-8.63h-0.03v8.63h-1.36V61.6H327.45z" />
      <path fill="#FFFFFF" d="M350.87,61.6v1.2h-5.99v3.42h5.59v1.2h-5.59v3.72h6.04v1.2h-7.47V61.6H350.87z" />
      <path
        fill="#FFFFFF"
        d="M364.33,61.6c1.02,0,1.82,0.25,2.4,0.75c0.58,0.5,0.87,1.19,0.87,2.06c0,0.65-0.15,1.23-0.44,1.72
		c-0.3,0.49-0.77,0.83-1.42,1.01v0.03c0.31,0.06,0.56,0.16,0.76,0.3c0.2,0.14,0.35,0.31,0.47,0.5c0.12,0.2,0.2,0.41,0.26,0.66
		c0.06,0.24,0.1,0.49,0.13,0.75c0.02,0.26,0.04,0.53,0.05,0.8c0.01,0.27,0.04,0.54,0.08,0.8c0.04,0.26,0.1,0.51,0.17,0.75
		c0.08,0.24,0.19,0.44,0.34,0.62h-1.6c-0.1-0.11-0.17-0.26-0.2-0.45c-0.04-0.19-0.06-0.4-0.07-0.64c-0.01-0.24-0.02-0.49-0.03-0.76
		c-0.01-0.27-0.04-0.54-0.09-0.8c-0.04-0.26-0.09-0.51-0.15-0.75c-0.06-0.24-0.16-0.44-0.29-0.62c-0.13-0.18-0.3-0.32-0.51-0.42
		c-0.21-0.11-0.49-0.16-0.84-0.16h-3.49v4.59h-1.43V61.6H364.33z M364.63,66.48c0.3-0.05,0.56-0.15,0.79-0.29
		c0.23-0.15,0.41-0.34,0.54-0.59c0.14-0.25,0.2-0.56,0.2-0.96c0-0.54-0.15-0.98-0.45-1.33c-0.3-0.34-0.79-0.51-1.46-0.51h-3.55v3.75
		h2.98C364.01,66.56,364.33,66.53,364.63,66.48z"
      />
      <path
        fill="#FFFFFF"
        d="M381.59,63.07c-0.48-0.35-1.09-0.52-1.83-0.52c-0.3,0-0.6,0.03-0.89,0.09c-0.29,0.06-0.55,0.16-0.78,0.3
		c-0.23,0.14-0.41,0.33-0.54,0.56c-0.14,0.24-0.2,0.53-0.2,0.88c0,0.33,0.1,0.6,0.29,0.81c0.2,0.21,0.46,0.37,0.78,0.5
		c0.33,0.13,0.7,0.24,1.11,0.32c0.41,0.09,0.83,0.18,1.26,0.28c0.43,0.1,0.85,0.22,1.26,0.35c0.41,0.14,0.78,0.32,1.11,0.55
		c0.33,0.23,0.59,0.52,0.78,0.87c0.2,0.35,0.29,0.79,0.29,1.33c0,0.57-0.13,1.06-0.38,1.47c-0.26,0.41-0.58,0.74-0.99,0.99
		c-0.4,0.26-0.85,0.44-1.35,0.56c-0.5,0.12-0.99,0.17-1.48,0.17c-0.6,0-1.17-0.08-1.71-0.23c-0.54-0.15-1.01-0.38-1.42-0.69
		c-0.41-0.31-0.73-0.7-0.96-1.17c-0.24-0.48-0.35-1.04-0.35-1.69h1.36c0,0.45,0.09,0.84,0.26,1.17c0.18,0.33,0.41,0.59,0.69,0.81
		c0.29,0.21,0.62,0.37,1,0.47c0.38,0.1,0.77,0.15,1.17,0.15c0.32,0,0.64-0.03,0.97-0.09c0.33-0.06,0.62-0.16,0.88-0.31
		c0.26-0.15,0.47-0.34,0.63-0.59c0.16-0.25,0.24-0.57,0.24-0.96c0-0.37-0.1-0.67-0.29-0.9c-0.2-0.23-0.46-0.42-0.78-0.56
		c-0.33-0.15-0.7-0.26-1.11-0.35c-0.41-0.09-0.83-0.18-1.26-0.28c-0.43-0.1-0.85-0.21-1.26-0.33c-0.41-0.13-0.78-0.29-1.11-0.5
		c-0.33-0.21-0.59-0.47-0.78-0.8c-0.2-0.33-0.29-0.74-0.29-1.23c0-0.54,0.11-1.01,0.33-1.41c0.22-0.4,0.51-0.72,0.88-0.98
		c0.37-0.26,0.78-0.45,1.25-0.57c0.47-0.13,0.95-0.19,1.44-0.19c0.55,0,1.06,0.07,1.54,0.2c0.47,0.13,0.89,0.34,1.24,0.62
		c0.36,0.28,0.64,0.64,0.84,1.06c0.21,0.43,0.32,0.94,0.34,1.53h-1.36C382.35,63.98,382.07,63.42,381.59,63.07z"
      />
    </g>
  </svg>
);
