import { getTimezoneDate, isValidDate } from '@/components/allocations-page/utils';
import BasicTooltip from '@/components/basicComponents/tooltip';
import NoResults from '@/components/table/noResults';
import { TableRowCell, TableRowWrapper, TextCell } from '@/components/table/styles';
import guid from '@/utils/guid';
import { format } from 'date-fns';
import { ROLE, firmTableColumns } from '../constants';
import { IUser } from '../types';

interface TableBodyProps {
  firmTableTableData: IUser[];
}

const TableBody = ({ firmTableTableData }: TableBodyProps) => {
  if (!firmTableTableData?.length) {
    return <NoResults />;
  }

  return (
    <>
      {firmTableTableData.map((user: IUser) => {
        const lastLoginDate = new Date(user.lastLogin);
        const isLastLoginValidDate = isValidDate(lastLoginDate);
        const convertedLastLogin = isLastLoginValidDate ? getTimezoneDate(lastLoginDate) : null;
        const formattedLastLogin = isLastLoginValidDate ? format(lastLoginDate, 'MMM d, yyyy') : null;
        const tz_shorthand = lastLoginDate.toLocaleTimeString('en-us', { timeZoneName: 'short' }).split(' ')[2];
        const truncatedEmail = user.email.length > 30 ? `${user.email.substring(0, 30)}...` : user.email;

        return (
          <TableRowWrapper key={guid()}>
            <TableRowCell width={firmTableColumns[0].width}>
              <TextCell>{truncatedEmail}</TextCell>
            </TableRowCell>
            <TableRowCell width={firmTableColumns[1].width}>
              <TextCell>
                {user.lastLogin && isLastLoginValidDate && (
                  <BasicTooltip tooltipContent={`${formattedLastLogin} ${format(lastLoginDate, 'h:mm a')} ${tz_shorthand}`} zIndex={1000}>
                    {formattedLastLogin}
                  </BasicTooltip>
                )}
                {user.lastLogin && !isValidDate(new Date(user.lastLogin)) && user.lastLogin}
              </TextCell>
            </TableRowCell>
            <TableRowCell width={firmTableColumns[2].width}>
              <TextCell>{user.firstName}</TextCell>
            </TableRowCell>
            <TableRowCell width={firmTableColumns[3].width}>
              <TextCell>{user.lastName}</TextCell>
            </TableRowCell>
            <TableRowCell width={firmTableColumns[4].width}>
              <TextCell>{user.mobileNumber}</TextCell>
            </TableRowCell>
            <TableRowCell width={firmTableColumns[5].width}>
              <TextCell>
                {user?.roles?.length > 1 ? (
                  <BasicTooltip
                    tooltipContent={user.roles
                      .map((role) => ROLE[role])
                      .toString()
                      .replace(/,/g, ', ')}
                    zIndex={1000}
                  >
                    Multiple ({user.roles.length})
                  </BasicTooltip>
                ) : (
                  user?.roles && ROLE[user.roles[0]]
                )}
              </TextCell>
            </TableRowCell>
          </TableRowWrapper>
        );
      })}
    </>
  );
};

export default TableBody;
