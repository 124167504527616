import Label from '@/components/basicComponents/label';
import FilterSelect from '@/components/basicComponents/select';
import { IRecordData } from '@/components/dueDiligence-page/types';
import ModalWrappedContent from '@/components/fat-basicComponents/modal/modalWrappedContent';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

interface ApprovedInvestmentProps {
  isOpen: boolean;
  onClose: () => void;
  recordList: IRecordData[];
}

const ApprovedInvestment = ({ isOpen, onClose, recordList }: ApprovedInvestmentProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [selectInvestment, setSelectInvestment] = useState('None');

  const handleConfirm = () => {
    const chosenInvestment = recordList.find((record) => record.investment.name === selectInvestment);
    if (!chosenInvestment) return;

    const queryParams = new URLSearchParams({
      investment: chosenInvestment.investment.id,
      openInvestment: 'Open Investment for Allocations',
      isDealRegistry: 'true'
    });

    navigate(`/allocations/modify?${queryParams.toString()}&backToTitle=Allocations`);
  };

  return (
    <ModalWrappedContent
      showRequiredFields
      isOpen={isOpen}
      onClose={onClose}
      confirmButton={{
        name: 'Confirm',
        onClick: handleConfirm,
        disabled: selectInvestment === 'None'
      }}
    >
      <ModalContainer>
        <ModalTitel>Add Approved Investment</ModalTitel>
        <ModalDescription>Select an approved investment to open it for client allocations.</ModalDescription>
        <SelectWrapper>
          <Label required fontSize="16px">
            Select Approved Investment
          </Label>
          <FilterSelect
            withSearch
            required
            data={recordList.map((record: IRecordData) => record.investment.name)}
            selected={selectInvestment}
            setSelected={setSelectInvestment}
            width={'100%'}
          />
        </SelectWrapper>
      </ModalContainer>
    </ModalWrappedContent>
  );
};

export default ApprovedInvestment;

const ModalContainer = styled.div`
  font-weight: 400;
  padding: 50px 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const ModalTitel = styled.div`
  font-weight: 400;
  font-size: 33px;
  text-align: center;
  color: ${({ theme }) => theme.font.strong};
`;

const ModalDescription = styled.div`
  font-weight: 400;
  font-size: 19px;
  line-height: 27px;
  text-align: center;
  color: ${({ theme }) => theme.font.base};
`;

const SelectWrapper = styled.div`
  width: 100%;
  padding: 0 26px;
  margin-top: 10px;
`;
