import ModalWrappedContent from '@/components/fat-basicComponents/modal/modalWrappedContent';
import styled from 'styled-components';

interface FailureProps {
  onClose: () => void;
  isOpen: boolean;
  retryUpdate: () => void;
}

const Failure = ({ onClose, isOpen, retryUpdate }: FailureProps) => {
  const savaValue = () => {
    retryUpdate();
  };

  return (
    <ModalWrappedContent
      isOpen={isOpen}
      onClose={onClose}
      confirmButton={{
        name: 'Retry',
        onClick: savaValue
      }}
    >
      <ModalTitle>Update Failed</ModalTitle>
      <ModalBody>
        <Text>We’re sorry. This process did not complete.</Text>
        <Text>Please try again.</Text>
      </ModalBody>
    </ModalWrappedContent>
  );
};

export default Failure;

const ModalTitle = styled.div`
  color: ${({ theme }) => theme.font.strong};
  text-align: center;
  font-size: 33px;
  font-weight: 400;
  line-height: 46.2px;
  margin: 40px 0 10px 0;
`;

const ModalBody = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  margin-bottom: 40px;
`;

const Text = styled.p`
  color: ${({ theme }) => theme.font.base};
  font-size: 19px;
  font-weight: 400;
  line-height: 26.6px;
`;
