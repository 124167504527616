import * as React from 'react';

export const DocumentIcon = ({ ...props }: React.SVGProps<SVGSVGElement>) => (
  <svg width={props.width || 23} height={props.height || 23} viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.6365 2.5H5.67054C5.27669 2.5 4.89897 2.65646 4.62048 2.93495C4.34199 3.21344 4.18555 3.59118 4.18555 3.98502V19.015C4.18555 19.4089 4.34199 19.7866 4.62048 20.0651C4.89897 20.3435 5.27669 20.5 5.67054 20.5H17.33C17.7239 20.5 18.1016 20.3435 18.3801 20.0651C18.6586 19.7866 18.815 19.4089 18.815 19.015V8.66503L12.6365 2.5ZM17.3075 8.67404H13.0505C12.9407 8.67404 12.8354 8.63039 12.7578 8.55275C12.6802 8.47511 12.6365 8.36982 12.6365 8.26002V4.01205L17.3075 8.67404ZM11.5655 8.26002C11.5655 8.65387 11.722 9.0316 12.0005 9.31009C12.279 9.58859 12.6567 9.74504 13.0505 9.74504H17.7485V19.006C17.7485 19.0604 17.7378 19.1142 17.717 19.1644C17.6962 19.2147 17.6657 19.2603 17.6273 19.2988C17.5888 19.3372 17.5432 19.3677 17.493 19.3885C17.4427 19.4093 17.3889 19.42 17.3345 19.42H5.67054C5.56074 19.42 5.45544 19.3764 5.3778 19.2988C5.30016 19.2211 5.25654 19.1158 5.25654 19.006V3.98502C5.25654 3.93065 5.26725 3.87683 5.28805 3.8266C5.30886 3.77637 5.33935 3.73073 5.3778 3.69229C5.41624 3.65385 5.46188 3.62334 5.5121 3.60253C5.56233 3.58173 5.61617 3.571 5.67054 3.571H11.5655V8.26002Z"
      fill={props.fill || '#7F7F7F'}
    />
  </svg>
);
