import { RejectedBannerIcon } from '@/assets/icons/info-icons/rejectedBanner';
import { Banner } from '@/components/basicComponents/banner';
import Input from '@/components/fat-basicComponents/input';
import { formatDollarInputValue, formatMaskedDollarInputValue } from '@/components/fat-basicComponents/input/utils';
import ModalWrappedContent from '@/components/fat-basicComponents/modal/modalWrappedContent';
import { PROPOSAL_TYPE } from '@/types/investments';
import { useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { IRowData } from '../types';

interface EditAmountProps {
  onClose: () => void;
  isOpen: boolean;
  updateAllocationAmount: (variables: any) => void;
  allocation: IRowData | null;
}

interface ErrorMessage {
  title: string;
  message: string;
}

const EditAmount = ({ onClose, isOpen, updateAllocationAmount, allocation }: EditAmountProps) => {
  const theme = useTheme();

  const [value, setValue] = useState(allocation?.committedCapital?.toString() ?? '');
  const [errorText, setErrorText] = useState<ErrorMessage | null>(null);

  const validateAmount = (amount: number, allocation: IRowData) => {
    let errorMessage: ErrorMessage | null = null;

    if (allocation.proposalType === PROPOSAL_TYPE.INITIAL) {
      if (amount < allocation.investment.minimum) {
        errorMessage = {
          title: 'Cannot be less than fund minimum',
          message: 'The initial investment amount must be equal or greater than the investment minimum.'
        };
      }
    } else if (allocation.proposalType === PROPOSAL_TYPE.INCREASE) {
      if (amount <= 0) {
        errorMessage = {
          title: 'Cannot be less than 0',
          message: 'Any increase investment value must be greater than 0'
        };
      }
    }
    setErrorText(errorMessage);
    return errorMessage;
  };

  const saveValue = () => {
    const errorMessage = validateAmount(parseFloat(value), allocation);
    if (errorMessage) return;
    updateAllocationAmount({
      variables: {
        data: {
          id: allocation.id,
          amount: parseFloat(value)
        }
      }
    });
    onClose();
  };

  if (!allocation) return <></>;

  return (
    <ModalWrappedContent
      isOpen={isOpen}
      onClose={onClose}
      title={'Edit Investment Amount'}
      confirmButton={{
        name: 'Save',
        onClick: saveValue,
        disabled: !value
      }}
    >
      {errorText && (
        <Banner
          icon={<RejectedBannerIcon fill={theme.context.light} />}
          title={errorText?.title ?? ''}
          description={errorText?.message ?? ''}
          bgColor="#D63B4B"
        />
      )}
      <Input
        value={value ? formatMaskedDollarInputValue(value) : ''}
        onChange={(e) => {
          const formattedValue = formatDollarInputValue(e.target.value);
          setValue(formattedValue);
        }}
        label={allocation.proposalType === 'Increase' ? 'Follow-on Investment Amount' : 'Initial Investment Amount'}
        isRequiredField
        withDollarSign
        styleType={value ? 'success' : 'default'}
        autoFocus
      />
      <Description>{allocation.proposalType === 'Increase' ? '' : `Initial Investment minimum: $${allocation.investment.minimum}`}</Description>
    </ModalWrappedContent>
  );
};

export default EditAmount;

const Description = styled.span`
  padding-top: 10px;
  font-weight: 300;
  font-size: 13px;
  line-height: 18px;
  color: ${({ theme }) => theme.font.weak};
`;
