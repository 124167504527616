import { format } from 'date-fns';
import styled from 'styled-components';

import { IRecordData } from '@/components/dueDiligence-page/types'; // interface TableBodyProps {}
import RedirectTitleCell from '@/components/table/redirectTitleCell';
import { TableBodyWrapper, TableRowCell, TableRowWrapper, TextCell } from '@/components/table/styles';
import guid from '@/utils/guid';
import { dueDiligenceColumns } from './../constatnts';

interface TableBodyProps {
  dueDiligenceRows: IRecordData[];
  handleClickInvestment: (row: IRecordData) => void;
}

export const TableBody = ({ dueDiligenceRows, handleClickInvestment }: TableBodyProps) => {
  return (
    <TableBodyWrapper>
      {dueDiligenceRows?.map((row: IRecordData) => (
        <TableRowWrapper key={guid()}>
          <TableRowCell width={dueDiligenceColumns[0].width}>
            <RedirectTitleCell onClick={() => handleClickInvestment(row)}>{row.investment.name}</RedirectTitleCell>
          </TableRowCell>
          <TableRowCell width={dueDiligenceColumns[1].width}>
            <TextCell>{row.investment.assetClass}</TextCell>
          </TableRowCell>
          <TableRowCell width={dueDiligenceColumns[2].width}>
            <TextCellStatus isApproved={row.status === 'APPROVED'}>{row.status[0].toUpperCase() + row.status.slice(1).toLowerCase()}</TextCellStatus>
          </TableRowCell>
          <TableRowCell width={dueDiligenceColumns[3].width}>
            <TextCell>{`${row.activeTier.funnel.name} - ${row.activeTier.name}`}</TextCell>
          </TableRowCell>
          <TableRowCell width={dueDiligenceColumns[4].width}>
            <TextCell>{`${row.approver.firstName} ${row.approver.lastName}`}</TextCell>
          </TableRowCell>
          <TableRowCell width={dueDiligenceColumns[5].width}>
            <TextCell>{format(new Date(row.updatedAt), 'LLL d, yyyy')}</TextCell>
          </TableRowCell>
        </TableRowWrapper>
      ))}
    </TableBodyWrapper>
  );
};

const TextCellStatus = styled(TextCell)<{ isApproved: boolean }>`
  font-weight: 700;
  font-size: 16px;
  color: ${({ isApproved, theme }) => (isApproved ? theme.context.success : theme.context.error)};
`;
