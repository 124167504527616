import FormattingTooltip from '@/components/basicComponents/tooltip/dollarsTooltip';
import { Loader } from '@/components/loaders/loader';
import {
  MobileRow,
  MobileRowTitle,
  MobileRowValue,
  MobileTableRowWrap,
  MobileTableWrapper,
  NoResults
} from '@/components/table/mobileTable/mobileTable';
import { TableColumns } from '@/components/table/types';
import { useAuth } from '@/hooks/use-auth';
import { formatPercent } from '@/utils/currency';
import guid from '@/utils/guid';
import { IInvestorCommitment } from '../../types';

interface MobileTableInvestorProps {
  columns: TableColumns[];
  investorTableRows: IInvestorCommitment[];
  loading: boolean;
  openDetailsPage: (investorId: string, entityId?: string) => void;
}

const MobileTableInvestor = ({ columns, investorTableRows, loading, openDetailsPage }: MobileTableInvestorProps) => {
  const { user } = useAuth();

  if (loading) return <Loader />;

  const TableRow = ({ el }: { el: IInvestorCommitment }) => {
    return (
      <MobileTableRowWrap key={guid()}>
        <MobileRow>
          <MobileRowTitle>{columns[0].title}</MobileRowTitle>
          <MobileRowValue onClick={() => openDetailsPage(el.investor.id)} isRedirect>
            {el.investor.name}
          </MobileRowValue>
        </MobileRow>

        <MobileRow>
          <MobileRowTitle>{columns[1].title}</MobileRowTitle>
          <MobileRowValue>{el.investorEntities}</MobileRowValue>
        </MobileRow>

        {user && user.tenant.type === 'fundManager' && (
          <MobileRow>
            <MobileRowTitle>{columns[2].title}</MobileRowTitle>
            <MobileRowValue>{el.tenant.name}</MobileRowValue>
          </MobileRow>
        )}

        <MobileRow>
          <MobileRowTitle>{columns[3].title}</MobileRowTitle>
          <FormattingTooltip zIndex={1000}>{el.committedCapital}</FormattingTooltip>
        </MobileRow>

        <MobileRow>
          <MobileRowTitle>{columns[4].title}</MobileRowTitle>
          <FormattingTooltip zIndex={1000}>{el.capitalCalled}</FormattingTooltip>
        </MobileRow>

        <MobileRow>
          <MobileRowTitle>{columns[5].title}</MobileRowTitle>
          <MobileRowValue>{formatPercent(el.percentOfCapitalCalled)}</MobileRowValue>
        </MobileRow>

        {/* <MobileRow>
          <MobileRowTitle>{columns[6].title}</MobileRowTitle>
          <MobileRowValue>{formatPercent(el.IRR)}</MobileRowValue>
        </MobileRow>

        <MobileRow>
          <MobileRowTitle>{columns[7].title}</MobileRowTitle>
          <MobileRowValue>{el.TVPI}x</MobileRowValue>
        </MobileRow> */}
      </MobileTableRowWrap>
    );
  };

  return (
    <MobileTableWrapper>
      {investorTableRows.length ? (
        investorTableRows.map((el, index) => {
          return <TableRow el={el} key={index} />;
        })
      ) : (
        <NoResults>No Results</NoResults>
      )}
    </MobileTableWrapper>
  );
};

export default MobileTableInvestor;
