import React from 'react';
import styled, { useTheme } from 'styled-components';

interface InputFieldProps {
  autoFocus?: boolean;
  error?: boolean;
  name?: string;
  placeholder?: string;
  required?: boolean;
  helperText?: React.ReactNode;
  onBlur?: any;
  onChange: (value: any) => void;
  type?: React.InputHTMLAttributes<string>['type'];
  value: string;
  icon?: React.ReactNode;
}

export const InputFieldProps = ({
  autoFocus = false,
  error,
  helperText,
  name,
  onBlur,
  onChange,
  type,
  value,
  required,
  icon,
  placeholder
}: InputFieldProps) => {
  const theme = useTheme();

  const inputColor = {
    bgColor: theme.layer[1],
    borderColor: theme.border.base,
    textColor: theme.font.strong
  };

  switch (error) {
    case true:
      inputColor.bgColor = '#FFEAEC';
      inputColor.borderColor = theme.context.error;
      inputColor.textColor = '#2E2E2E';
      break;
    case false:
      inputColor.bgColor = '#EFFFEA';
      inputColor.borderColor = theme.context.success;
      inputColor.textColor = '#2E2E2E';
      break;
    default:
      inputColor.bgColor = theme.layer[1];
      inputColor.borderColor = theme.border.base;
      inputColor.textColor = '#2E2E2E';
  }

  return (
    <>
      <InputWrapper>
        <CustomInput
          backgroundColor={inputColor.bgColor}
          borderColor={inputColor.borderColor}
          textColor={inputColor.textColor}
          placeholder={placeholder}
          name={name}
          type={type}
          value={value}
          onChange={onChange}
          autoFocus={autoFocus}
          onBlur={onBlur}
          required={required}
        />
        <PasswordIcon>{icon}</PasswordIcon>
      </InputWrapper>
      <HelperText>{helperText}</HelperText>
    </>
  );
};

const InputWrapper = styled.div`
  position: relative;
`;

const PasswordIcon = styled.div`
  position: absolute;
  right: 12px;
  top: 10px;
`;

const CustomInput = styled.input<{
  backgroundColor: string;
  borderColor: string;
  textColor: string;
}>`
  width: 100%;
  border: 1px solid ${({ borderColor }) => borderColor};
  background-color: ${({ backgroundColor }) => backgroundColor};
  border-radius: 5px;
  padding: 10px 45px 10px 12px;
  font-size: 19px;
  font-family: Blinker;
  font-weight: 400;
  color: ${({ textColor }) => textColor};

  &:focus {
    outline: none;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px ${({ backgroundColor }) => backgroundColor} inset !important;
    -webkit-text-fill-color: ${({ textColor }) => textColor} !important;
    border: 1px solid ${({ borderColor }) => borderColor} !important;
  }
`;

const HelperText = styled.div`
  color: #eb0028;
  margin-left: 10px;
  font-size: small;
`;
