import FormattingTooltip from '@/components/basicComponents/tooltip/dollarsTooltip';
import { MobileRow, MobileRowTitle, MobileRowValue, MobileTableRowWrap } from '@/components/table/mobileTable/mobileTable';
import styled from 'styled-components';
import { investmentColumns } from '../constatnts';
import { IManagerInvestment } from '../types';

interface MobileSumFooterProps {
  tableData: IManagerInvestment[];
}

const MobileSumFooter = ({ tableData }: MobileSumFooterProps) => {
  const totalCountOfInvestors = tableData.reduce((sum: number, value: IManagerInvestment) => (sum += Number(value.countOfInvestors)), 0);
  const totalCommittedCapital = tableData.reduce((sum: number, value: IManagerInvestment) => (sum += Number(value.committedCapital)), 0);

  return (
    <>
      <MobileSumFooterWrap>
        <MobileRow>
          <MobileRowTitle>Total</MobileRowTitle>
        </MobileRow>

        <MobileRow>
          <MobileRowTitle>{investmentColumns[3].title}</MobileRowTitle>
          <MobileRowValue>{totalCountOfInvestors}</MobileRowValue>
        </MobileRow>

        <MobileRow>
          <MobileRowTitle>{investmentColumns[4].title}</MobileRowTitle>
          <MobileRowValue>
            <FormattingTooltip zIndex={1000}>{totalCommittedCapital}</FormattingTooltip>
          </MobileRowValue>
        </MobileRow>
      </MobileSumFooterWrap>
    </>
  );
};

const MobileSumFooterWrap = styled(MobileTableRowWrap)`
  margin-top: 6px;
  background-color: ${({ theme }) => theme.layer[2]};
`;

export default MobileSumFooter;
