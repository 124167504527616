import styled from 'styled-components';

import { InvestmentManager } from '@/types/investments';
import FormattingTooltip from '../../basicComponents/tooltip/dollarsTooltip';
import { managersColumns } from '../constatnts';

interface TableSumFooterProps {
  tableData: InvestmentManager[];
}

const TableSumFooter = ({ tableData }: TableSumFooterProps) => {
  const totalFunds = tableData.reduce((sum: number, value: InvestmentManager) => (sum += Number(value.countOfFunds)), 0);
  const totalInvestors = tableData.reduce((sum: number, value: InvestmentManager) => (sum += Number(value.countOfInvestors)), 0);
  const totalCommittedCapital = tableData.reduce((sum: number, value: InvestmentManager) => (sum += Number(value.committedCapital)), 0);

  return (
    <TableSumWrap>
      <TableSumItem width={managersColumns[0].width}>Total</TableSumItem>
      <TableSumItem width={managersColumns[1].width}>{totalFunds}</TableSumItem>
      <TableSumItem width={managersColumns[2].width}>{totalInvestors}</TableSumItem>
      <TableSumItem width={managersColumns[3].width}>
        <FormattingTooltip zIndex={1000}>{totalCommittedCapital}</FormattingTooltip>
      </TableSumItem>
    </TableSumWrap>
  );
};

const TableSumWrap = styled.div`
  display: flex;
  align-items: center;
  padding: 0 13px 0 13px;
  width: 100%;
  min-height: 60px;
  background-color: ${({ theme }) => theme.layer[2]};
  border-radius: 8px;
  margin-top: 6px;
`;

const TableSumItem = styled.div<{ width: number; justifyContent?: string }>`
  display: flex;
  justify-content: ${({ justifyContent }) => `${justifyContent}`};
  width: ${({ width }) => `${width}%`};
  color: ${({ theme }) => theme.font.base};
  font-family: Blinker, serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0;
`;

export default TableSumFooter;
