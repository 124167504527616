import { useQuery } from '@apollo/client';
import { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';

import { RefreshIcon } from '@/assets/icons/info-icons/refresh';
import FilterSelect from '@/components/basicComponents/select';
import { reverseStatusNextStepRelations, statusNextStepRelations } from '@/components/client-details/constants';
import Header from '@/components/header';
import MobileModal from '@/components/mobileModal';
import FilterPage, { DATA_TYPE } from '@/components/table/filterPage';
import { RefreshButton, Results, TopWrapper } from '@/components/table/filterPage/desktop-filter';
import { AdditionalFilter, FiltersItems, FiltersStructure } from '@/components/table/filterPage/types';
import { createNewFilter } from '@/components/table/filterPage/utils';
import { ADVISORS_QUERY } from '@/components/table/queries';
import { IAdvisor, ISort } from '@/components/table/types';
import { useAuth } from '@/hooks/use-auth';
import { useResponsive } from '@/hooks/use-responsive';
import { ContainerPage, MainWrap } from '@/styles/common';
import { SELECTORS } from '@/types/enums';
import { Assignee } from '@/types/selectors/enums';
import { initialValuesFromTable } from '../table/constants';
import { recommendationsSort } from './constants';
import { EntityInfoList } from './entityInfoList';
import { InvestmentInfoList } from './investmentInfoList';
import { LastUpdateInfoList } from './lastUpdateInfoList';
import { INVESTMENTS_QUERY } from './queries';
import Table from './table';
import { FieldsForRedirectWindows, FiltrationResults, IRowData, InvestmentsFilter, TypeWindow } from './types';
import { createListForLastUpdateHoverCard, sortByAlphabet } from './utils';

export const Recommendations = () => {
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();

  const q = searchParams.get('q') || '';
  const key = searchParams.get('key') || 'name';
  const asc = searchParams.get('asc') === null || searchParams.get('asc') === 'true';
  const primaryFilter = searchParams.get('primaryFilter') || Assignee.ALL_ASSIGNEES;
  const advisorsParams = searchParams.get('advisors') || '';
  const investmentsParams = searchParams.get('investments') || '';
  const nextTaskParams = searchParams.get('nextTask') || '';
  const limit = searchParams.get('limit') || initialValuesFromTable.initialAmountRowsPerPage[0];
  const offset = searchParams.get('offset') || 0;
  const position = searchParams.get('position') || 0;

  const queryParams = new URLSearchParams(location.search);
  const investments = queryParams.get('investments');
  const { isMobile, isTablet, isPortrait } = useResponsive();
  const [mobileSort, setMobileSort] = useState(recommendationsSort[0].title);
  const sortItem = recommendationsSort.find((item) => item.title === mobileSort);
  const mobileSortList = recommendationsSort.map((item) => item.title);
  const mobileSortValue = { key: sortItem?.key, asc: sortItem?.asc } as ISort;
  const [amountResult, setAmountResult] = useState<number>(0);
  const [filtrationResults, setFiltrationResults] = useState<FiltrationResults>({ results: 0, refetch: () => null });
  const [additionalFilters, setAdditionalFilters] = useState<AdditionalFilter[]>(() => getAdditionalFilters());
  const [filtersItems, setFiltersItems] = useState<FiltersItems>({
    advisors: [SELECTORS.ALL_ADVISORS],
    investments: [SELECTORS.ALL_INVESTMENTS],
    nextTask: [SELECTORS.ANY_NEXT_TASK, ...Object.values(statusNextStepRelations)]
  });

  const [isEntityModalOpen, setIsEntityModalOpen] = useState(false);
  const [entityModalData, setEntityModalData] = useState<IRowData>();

  const [isInvestmentModalOpen, setIsInvestmentModalOpen] = useState(false);
  const [investmentModalData, setInvestmentModalData] = useState<IRowData>();

  const [isLastUpdateModalOpen, setIsLastUpdateModalOpen] = useState(false);
  const [lastUpdateModalData, setLastUpdateModalData] = useState<IRowData>();

  const { user } = useAuth();

  function getAdditionalFilters() {
    const matchedAdditionalFilters = [];

    if (!advisorsParams && !investmentsParams && !nextTaskParams) {
      return [createNewFilter('Select A Filter Option')];
    }

    if (advisorsParams) {
      for (const filterItem of JSON.parse(advisorsParams)) {
        matchedAdditionalFilters.push(createNewFilter('advisors', filterItem));
      }
    }
    if (investmentsParams) {
      for (const filterItem of JSON.parse(investmentsParams)) {
        matchedAdditionalFilters.push(createNewFilter('investments', filterItem));
      }
    }
    if (nextTaskParams) {
      for (const filterItem of JSON.parse(nextTaskParams)) {
        matchedAdditionalFilters.push(createNewFilter('nextTask', filterItem));
      }
    }

    return matchedAdditionalFilters;
  }

  const onClickEntity = (data: IRowData) => {
    setEntityModalData(data);
    setIsEntityModalOpen(true);
  };

  const onClickInvestment = (data: IRowData) => {
    setInvestmentModalData(data);
    setIsInvestmentModalOpen(true);
  };

  const omClickLastUpdate = (data: IRowData) => {
    setLastUpdateModalData(data);
    setIsLastUpdateModalOpen(true);
  };

  useQuery<{ Advisors: IAdvisor[] }>(ADVISORS_QUERY, {
    onCompleted: (data) =>
      setFiltersItems({
        ...filtersItems,
        advisors: [SELECTORS.ALL_ADVISORS, ...data.Advisors.map(({ initials }) => initials)]
      })
  });
  useQuery<{ RecommendationInvestmentsList: InvestmentsFilter[] }>(INVESTMENTS_QUERY, {
    onCompleted: (data) => {
      setFiltersItems({
        ...filtersItems,
        investments: [SELECTORS.ALL_INVESTMENTS, ...sortByAlphabet([...new Set(data.RecommendationInvestmentsList.map(({ name }) => name))])]
      });
    },
    fetchPolicy: 'network-only'
  });

  const saveFiltrationResults = (results: number, refetch: () => void) => {
    setFiltrationResults({ results, refetch });
  };
  const filterStructure = () => {
    const unitedFilters = additionalFilters.reduce<FiltersStructure>(
      (acc, { selected, operator, typeFilter }) => {
        return acc[typeFilter as keyof FiltersStructure]
          ? {
              ...acc,
              [typeFilter]: [...acc[typeFilter as keyof FiltersStructure], { selected, operator }]
            }
          : acc;
      },
      {
        advisors: [],
        investments: [],
        nextTask: []
      }
    );

    return {
      ...unitedFilters,
      nextTask: unitedFilters.nextTask.map(({ selected, ...rest }) => ({
        ...rest,
        selected: reverseStatusNextStepRelations[selected]
      }))
    };
  };

  const detailsClick = (type: TypeWindow, id: FieldsForRedirectWindows) => {
    saveScrollPostion();

    const { familyId, familyName, id: entityId, investmentId } = id;
    switch (type) {
      case 'details':
        navigate(`/clients/${familyName}`);
        break;
      case 'entityDetails':
        navigate(`/clients/${familyId}/${entityId}`);
        break;
      case 'allocation':
        navigate(`/allocations/allocator/${investmentId}`);
        break;
      default:
        break;
    }
  };

  const onChangeSearch = (searchValue: string) => {
    setSearchParams(
      (prev) => {
        if (!searchValue) {
          prev.delete('q');
          return prev;
        }
        prev.set('q', searchValue);
        return prev;
      },
      { replace: true }
    );
  };

  const onChangeSort = (value: ISort) => {
    setSearchParams(
      (prev) => {
        prev.set('key', value.key);
        prev.set('asc', value.asc.toString());
        return prev;
      },
      { replace: true }
    );
  };

  const onChangePrimaryFilter = (filter: string) => {
    setSearchParams(
      (prev) => {
        prev.set('primaryFilter', filter);
        return prev;
      },
      { replace: true }
    );
  };

  const onChangePaginationData = (limit: number, offset: number) => {
    setSearchParams(
      (prev) => {
        prev.set('limit', limit.toString());
        prev.set('offset', offset.toString());
        return prev;
      },
      { replace: true }
    );
  };

  const onChangeAdditionalFilters = (filters: AdditionalFilter[]) => {
    setAdditionalFilters(filters);

    const matchedAdditionalFilters = filters.reduce<FiltersStructure>(
      (acc, { selected, operator, typeFilter }) => {
        return acc[typeFilter as keyof FiltersStructure]
          ? {
              ...acc,
              [typeFilter]: [...acc[typeFilter as keyof FiltersStructure], selected]
            }
          : acc;
      },
      {
        advisors: [],
        investments: [],
        nextTask: []
      }
    );

    const { advisors, investments, nextTask } = matchedAdditionalFilters;

    setSearchParams(
      (prev) => {
        if (!advisors.length) {
          prev.delete('advisors');
          return prev;
        }
        prev.set('advisors', JSON.stringify(advisors));
        return prev;
      },
      { replace: true }
    );

    setSearchParams(
      (prev) => {
        if (!investments.length) {
          prev.delete('investments');
          return prev;
        }

        prev.set('investments', JSON.stringify(investments));
        return prev;
      },
      { replace: true }
    );

    setSearchParams(
      (prev) => {
        if (!nextTask.length) {
          prev.delete('nextTask');
          return prev;
        }

        prev.set('nextTask', JSON.stringify(nextTask));
        return prev;
      },
      { replace: true }
    );
  };

  const saveScrollPostion = () => {
    setSearchParams(
      (prev) => {
        prev.set('position', window.scrollY.toString());
        return prev;
      },
      { replace: true }
    );
  };

  const makeScroll = () => {
    if (!position) return;
    setTimeout(() => window.scrollTo({ top: Number(position), behavior: 'smooth' }), 0);
    setSearchParams(
      (prev) => {
        prev.delete('position');
        return prev;
      },
      { replace: true }
    );
  };

  const isChosenFilters = document.querySelector('#chosenFliters');

  return (
    <RecommendationWrap>
      {isEntityModalOpen && entityModalData ? (
        <MobileModal
          title={entityModalData.legalEntity.entityName}
          onClose={() => setIsEntityModalOpen(false)}
          onClick={() =>
            detailsClick('entityDetails', {
              id: entityModalData.legalEntity.id,
              entityName: entityModalData.legalEntity.entityName,
              familyId: entityModalData.legalEntity.family.id,
              familyName: entityModalData.legalEntity.family.name
            })
          }
        >
          <EntityInfoList allocation={entityModalData} />
        </MobileModal>
      ) : (
        <></>
      )}

      {isInvestmentModalOpen && investmentModalData ? (
        <MobileModal
          title={investmentModalData.investment.name}
          onClose={() => setIsInvestmentModalOpen(false)}
          onClick={() =>
            detailsClick('allocation', {
              investmentName: investmentModalData.investment.name,
              investmentId: investmentModalData.investment.id
            })
          }
        >
          <InvestmentInfoList row={investmentModalData} />
        </MobileModal>
      ) : (
        <></>
      )}

      {isLastUpdateModalOpen && lastUpdateModalData ? (
        <MobileModal title={'Updates'} onClose={() => setIsLastUpdateModalOpen(false)} onClick={() => null}>
          <LastUpdateInfoList list={createListForLastUpdateHoverCard(lastUpdateModalData)} />
        </MobileModal>
      ) : (
        <></>
      )}
      <MainWrap>
        <Header />
      </MainWrap>
      <PaddingWrap>
        <CustomFilterPage
          isMobile={isMobile}
          isTablet={isTablet}
          withExportData
          search={{
            value: q,
            onChange: onChangeSearch,
            placeholder: 'Search for Family or Entity'
          }}
          primaryFilter={{
            value: primaryFilter,
            onChange: onChangePrimaryFilter,
            selects: Object.values(Assignee)
          }}
          isResult
          additionalFilters={{
            filtersItems,
            filters: additionalFilters,
            applyFilters: onChangeAdditionalFilters,
            amountResults: amountResult,
            showChosenFilters: !!investments || !!queryParams.get('filterBy'),
            onRefetch: filtrationResults.refetch
          }}
          queryParams={{
            userId: user?.id ?? '',
            selected: Assignee.ALL_ASSIGNEES,
            offset: Number(offset) ?? 0,
            limit: Number(limit) ?? 100,
            includeProposedInvestments: true
          }}
          dataType={DATA_TYPE.RECOMMENDATIONS}
        />
        {isMobile && (
          <SortSelectWrapper isChosenFilters={Boolean(isChosenFilters)} isPortrait={isPortrait}>
            <FilterSelect
              isMobile
              label={'Sort By'}
              paddingContainer={'0 20px'}
              width={'100%'}
              data={mobileSortList}
              selected={mobileSort}
              setSelected={setMobileSort}
            />
            <CustomRefetch>
              <Results>{amountResult || 0} Results</Results>
              <RefreshButton onClick={filtrationResults.refetch}>
                <RefreshIcon />
              </RefreshButton>
            </CustomRefetch>
          </SortSelectWrapper>
        )}

        <Table
          omClickLastUpdate={omClickLastUpdate}
          onClickInvestment={onClickInvestment}
          onClickEntity={onClickEntity}
          search={q}
          assigneeFilter={primaryFilter}
          saveFiltrationResults={saveFiltrationResults}
          additionalFiltersData={filterStructure()}
          detailsClick={detailsClick}
          sort={isMobile ? mobileSortValue : { key, asc }}
          setSort={onChangeSort}
          paginationData={{ limit: Number(limit), offset: Number(offset) }}
          onChangePaginationData={onChangePaginationData}
          makeScroll={makeScroll}
          setAmountResult={setAmountResult}
        />
      </PaddingWrap>
    </RecommendationWrap>
  );
};

const RecommendationWrap = styled(ContainerPage)`
  padding: 0;
`;

const CustomFilterPage = styled(FilterPage)<{ isMobile: boolean; isTablet: boolean }>`
  position: sticky;
  top: ${({ isMobile, isTablet }) => (isMobile || isTablet ? '50px' : '0')};
  z-index: 9;
  background-color: #fafafa;
  margin-top: 0;
  margin-left: -1px;
  width: calc(100% + 2px);
  padding: ${({ isMobile }) => (isMobile ? '20px 0 0 0' : '20px 0 10px 0')};
`;

const SortSelectWrapper = styled.div<{ isChosenFilters?: boolean; isPortrait: boolean }>`
  position: sticky;
  top: ${({ isPortrait, isChosenFilters }) => (isChosenFilters ? (isPortrait ? '150px' : '110px') : isPortrait ? '162px' : '120px')};
  z-index: 8;
  background-color: #fafafa;
  margin-top: 0;
  padding-bottom: 10px;
`;

const PaddingWrap = styled.div`
  padding-left: 16px;
  padding-right: 16px;
  @media (min-width: 600px) {
    padding-left: 24px;
    padding-right: 24px;
  }
`;

const CustomRefetch = styled(TopWrapper)`
  padding: 25px 0 0 20px;
`;
