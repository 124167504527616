import * as React from 'react';

export const DeleteModalIcon = ({ ...props }: React.SVGProps<SVGSVGElement>) => (
  <svg 
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || 48}
    height={props.height || 60}
    viewBox={`0 0 ${props.width || 48} ${props.height || 60}`}
    fill="none">
    <g clip-path="url(#clip0_5730_16820)">
      <path 
        d="M37.33 20V53.34H10.67V20H37.33ZM32.33 0H15.67L12.41 3.33997H0.669983V10H47.33V3.33997H35.67L32.33 0ZM44.01 13.34H4.00999V53.34C4.01527 55.1047 4.71864 56.7956 5.96649 58.0435C7.21434 59.2913 8.90526 59.9947 10.67 60H37.33C39.0982 60 40.7942 59.299 42.0464 58.0505C43.2986 56.8021 44.0047 55.1081 44.01 53.34V13.34Z"
        fill={props.fill || 'ABABAB'}
      />
    </g>
    <defs>
      <clipPath id="clip0_5730_16820">
        <rect width="46.66" height="60" fill="white" transform="translate(0.669983)"/>
      </clipPath>
    </defs>
  </svg>
);
