import { ISort } from '@/components/table/types';
import { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';
import FooterModal from '../../../footer';
import { ProgressBar } from '../../../progressBar';
import { Status } from '../../../status';
import { ImportWrapper } from '../../../styles';
import { investorEntitiesSteps } from '../../constants';
import { IInvestorComparison, IInvestorResponse, TypeWindow } from '../../types';
import { investorHouseholdsStatusError, investorHouseholdsStatusSuccess } from './constants';
import Table from './table/table';

interface InvestorHouseholdsProps {
  importSteps: typeof investorEntitiesSteps;
  startOver: () => void;
  nextStep: () => void;
  investorData: IInvestorResponse | null;
  errors: IInvestorComparison[];
  sort: ISort;
  setSort: Dispatch<SetStateAction<ISort>>;
  search: string;
  onChangeSearch: (value: string) => void;
  primaryFilter: string;
  showTable: boolean;
  setShowTable: Dispatch<SetStateAction<boolean>>;
  changePrimaryFilterValue: (filter: string) => void;
  openInvestorImportDetailPage: (type: TypeWindow, row: IInvestorComparison) => void;
}

export const InvestorHouseholds = ({
  importSteps,
  startOver,
  nextStep,
  investorData,
  errors,
  sort,
  setSort,
  search,
  onChangeSearch,
  primaryFilter,
  showTable,
  setShowTable,
  changePrimaryFilterValue,
  openInvestorImportDetailPage
}: InvestorHouseholdsProps) => {
  if (!investorData) {
    return <></>;
  }

  return (
    <>
      <ImportWrapper>
        <ProgressBar importSteps={importSteps} />
        <Status
          errorsCount={errors.length || null}
          matched={[
            { count: investorData.newInvestorsCount, text: 'New Investor Households' },
            { count: investorData.updatedInvestorsCount, text: 'Updated Investor Households' },
            { count: investorData.investorsToDeleteCount, text: 'Households To Delete' }
          ]}
          showTable={showTable}
          setShowTable={setShowTable}
          startOver={startOver}
          confirmButton={{ name: 'Continue', onClick: nextStep, disabled: Boolean(errors.length) }}
          success={{ ...investorHouseholdsStatusSuccess, viewAll: primaryFilter === 'Updates Only' ? 'View All Updates' : 'View All' }}
          error={investorHouseholdsStatusError}
        />
      </ImportWrapper>
      {showTable || errors?.length ? (
        <TableWrapper>
          <Table
            tableData={investorData.investorComparisons}
            openInvestorImportDetailPage={openInvestorImportDetailPage}
            sort={sort}
            setSort={setSort}
            search={search}
            onChangeSearch={onChangeSearch}
            primaryFilter={primaryFilter}
            changePrimaryFilterValue={changePrimaryFilterValue}
          />
        </TableWrapper>
      ) : (
        <></>
      )}
      {errors?.length || showTable ? (
        <FooterModal startOver={startOver} confirmButton={{ name: 'Continue', onClick: nextStep, disabled: Boolean(errors.length) }} />
      ) : (
        <></>
      )}
    </>
  );
};

const TableWrapper = styled.div`
  margin-bottom: 100px;
`;
