import { format } from 'date-fns';
import styled from 'styled-components';

import { IRowData } from './types';

interface InfoListProps {
  data: IRowData;
}

export const MobileComplianceInfoList = ({ data }: InfoListProps) => {
  const getBoolean = (value: boolean) => (value ? 'YES' : 'NO');
  const dateFormat = (date: Date) => {
    try {
      return format(new Date(date), 'PP').toUpperCase();
    } catch (e) {
      return 'No date';
    }
  };
  return (
    <MoreDataWrap id="modal">
      {data.moreInfoAllocationStatus.statusDate ? (
        <MoreDataItem id="modal">
          <MoreDataItemTitle id="modal">Status Date</MoreDataItemTitle>
          <MoreDataItemValue id="modal">{dateFormat(data.moreInfoAllocationStatus.statusDate)}</MoreDataItemValue>
        </MoreDataItem>
      ) : null}

      <MoreDataItem id="modal">
        <MoreDataItemTitle id="modal">Internal Decison</MoreDataItemTitle>
        <MoreDataItemValue id="modal">{getBoolean(data.moreInfoAllocationStatus.decision)}</MoreDataItemValue>
      </MoreDataItem>

      {data.moreInfoAllocationStatus.internalDecision ? (
        <MoreDataItem id="modal">
          <MoreDataItemTitle id="modal">Internal Decison Date</MoreDataItemTitle>
          <MoreDataItemValue id="modal">{dateFormat(data.moreInfoAllocationStatus.internalDecision)}</MoreDataItemValue>
        </MoreDataItem>
      ) : null}

      {data.moreInfoAllocationStatus.reason ? (
        <MoreDataItem id="modal">
          <MoreDataItemTitle id="modal">Reason</MoreDataItemTitle>
          <MoreDataItemValue id="modal">{data.moreInfoAllocationStatus.reason}</MoreDataItemValue>
        </MoreDataItem>
      ) : null}

      {data.moreInfoAllocationStatus.suitability ? (
        <MoreDataItem id="modal">
          <MoreDataItemTitle id="modal">Suitability</MoreDataItemTitle>
          <MoreDataItemValue id="modal">{data.moreInfoAllocationStatus.suitability}</MoreDataItemValue>
        </MoreDataItem>
      ) : null}

      {data.moreInfoAllocationStatus.complianceOfficer ? (
        <MoreDataItem id="modal">
          <MoreDataItemTitle id="modal">Internal Decision By</MoreDataItemTitle>
          <MoreDataItemValue id="modal">{data.moreInfoAllocationStatus.complianceOfficer}</MoreDataItemValue>
        </MoreDataItem>
      ) : null}

      {data.moreInfoAllocationStatus.lastUpdate && data.allocationStatus !== 'Committed' ? (
        <MoreDataItem id="modal">
          <MoreDataItemTitle id="modal">Last Update</MoreDataItemTitle>
          <MoreDataItemValue id="modal">{data.moreInfoAllocationStatus.lastUpdate}</MoreDataItemValue>
        </MoreDataItem>
      ) : null}

      {data.allocationStatus !== 'Internal Review Rejected' && data.allocationStatus !== 'Committed' ? (
        <MoreDataItem id="modal">
          <MoreDataItemTitle id="modal">Terminated By</MoreDataItemTitle>
          <MoreDataItemValue id="modal">{data.moreInfoAllocationStatus.terminateBy}</MoreDataItemValue>
        </MoreDataItem>
      ) : null}

      {data.terminationReason ? (
        <MoreDataItem id="modal">
          <MoreDataItemTitle id="modal">Terminated Reason</MoreDataItemTitle>
          <MoreDataItemValue id="modal">{data.terminationReason}</MoreDataItemValue>
        </MoreDataItem>
      ) : null}

      {data.moreInfoAllocationStatus.terminationNote ? (
        <MoreDataItem
          id="modal"
          style={{
            display: 'flex',
            alignItems: 'flex-start',
            flexDirection: 'column',
            paddingTop: 5
          }}
        >
          <MoreDataItemTitle id="modal">Terminated Notes</MoreDataItemTitle>
          <NoteData>{data.moreInfoAllocationStatus.terminationNote}</NoteData>
        </MoreDataItem>
      ) : null}
    </MoreDataWrap>
  );
};

const MoreDataItemValue = styled.div`
  font-family: 'Blinker', serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0;
  text-align: left;
  width: 200px;
  color: ${({ theme }) => theme.font.base};
`;

const MoreDataItemTitle = styled.div`
  font-family: 'Blinker', serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0;
  text-align: left;
  width: 150px;
  min-width: 150px;
  color: ${({ theme }) => theme.font.base};
`;

const MoreDataItem = styled.div`
  display: flex;
  align-items: center;
  padding: 5px 20px;
  background-color: ${({ theme }) => theme.layer[1]};
  gap: 10px;
  border-radius: 4px;
`;

const NoteData = styled.div`
  font-family: 'Blinker', serif;
  font-size: 13px;
  font-weight: 400;
  line-height: 28px;
  color: ${({ theme }) => theme.font.base};
`;

const MoreDataWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3px;
`;
