import { DragAndDropBigIcon } from '@/assets/static/icons/drag-and-drop-icon';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { NoRequired } from '@/components/dueDiligence-page/dueDiligenceRecord/tierSection';
import { IChecklistItem } from '@/components/dueDiligence-page/dueDiligenceRecord/types';
import DropdownActions from '@/components/table/dropdownActions';
import { useResponsive } from '@/hooks/use-responsive';
import { RedStarIcon } from '@/assets/icons/info-icons/redStar';
import {
  ActionDropdownWrapper,
  ActionWrap,
  DragAndDropIconWrap,
  InfoRowCell,
  RowWrapper
} from './sectionFields';

interface TierChecklistItemsProps {
  checklistItems: IChecklistItem[];
  isLimitedEdits: boolean;
  tierId: string;
  isDragDisabled: boolean;
  canDragging: { action: boolean; id: string };
  openModalWindow: (
    type: string,
    id: string,
    row: IChecklistItem,
    rowType: string
  ) => void;
}

export const TierChecklistItems = ({
  checklistItems,
  isLimitedEdits,
  tierId,
  isDragDisabled,
  canDragging,
  openModalWindow
}: TierChecklistItemsProps) => {
  const { isMobile } = useResponsive();

  return (
    <Droppable droppableId={tierId} type="CHECKLIST">
      {(provided) => (
        <div ref={provided.innerRef} {...provided.droppableProps}>
          {checklistItems.map((item, index) => (
            <Draggable
              key={item.id}
              draggableId={item.id}
              index={index}
              isDragDisabled={isLimitedEdits || isDragDisabled}
            >
              {(provided, snapshot) => (
                <div
                  {...provided.dragHandleProps}
                  {...provided.draggableProps}
                  ref={provided.innerRef}
                >
                  <RowWrapper
                    disabled={false}
                    isDragging={snapshot.isDragging}
                    isMobile={isMobile}
                    loading={
                      !canDragging.action && canDragging.id === item.id ? 1 : 0
                    }
                  >
                    <InfoRowCell>
                      {!isLimitedEdits && (
                        <DragAndDropIconWrap id="dragIcon">
                          <DragAndDropBigIcon
                            style={{ position: 'relative', top: '3px' }}
                          />
                        </DragAndDropIconWrap>
                      )}
                      {item?.required ? (
                        <RedStarIcon width={10} height={10} />
                      ) : (
                        <NoRequired />
                      )}
                      {item?.name}
                    </InfoRowCell>
                    <ActionWrap isMobile={isMobile}>
                      <ActionDropdownWrapper isMobile={isMobile}>
                        <DropdownActions
                          showListItems={false}
                          isMobile={isMobile}
                          isListCenter
                          items={
                            isLimitedEdits
                              ? [
                                  {
                                    name: 'Edit',
                                    onClick: () =>
                                      openModalWindow(
                                        'add-checklist',
                                        item.id,
                                        item,
                                        'checklist'
                                      )
                                  }
                                ]
                              : [
                                  {
                                    name: 'Edit',
                                    onClick: () =>
                                      openModalWindow(
                                        'add-checklist',
                                        item.id,
                                        item,
                                        'checklist'
                                      )
                                  },
                                  {
                                    name: 'Delete',
                                    onClick: () =>
                                      openModalWindow(
                                        'delete-checklist',
                                        item.id,
                                        item,
                                        'checklist'
                                      )
                                  }
                                ]
                          }
                        />
                      </ActionDropdownWrapper>
                    </ActionWrap>
                  </RowWrapper>
                </div>
              )}
            </Draggable>
          ))}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
};
