import styled from 'styled-components';

import guid from '../../../../utils/guid';

interface SectorsToAvoidProps {
  list: string[];
}

const SectorsToAvoid = ({ list }: SectorsToAvoidProps) => {
  return (
    <SectorsWrap>
      {list
        .filter((el) => el.length)
        .filter((x, i, a) => a.indexOf(x) == i)
        .map((el) => (
          <SectorsItem key={guid()}>{el}</SectorsItem>
        ))}
    </SectorsWrap>
  );
};

export default SectorsToAvoid;

const SectorsWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  width: 100%;
`;

const SectorsItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px 20px;
  background-color: ${({ theme }) => theme.layer[2]};
  color: ${({ theme }) => theme.font.base};
  border-radius: 12px;
  font-family: Blinker, serif;
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0;
`;
