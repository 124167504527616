import { createContext, FC, ReactNode } from 'react';
import PropTypes from 'prop-types';
import { useMediaQuery } from 'react-responsive';

interface ResponsiveProviderProps {
  children: ReactNode;
}

export interface ResponsiveInitialState {
  isLargeDesktop: boolean;
  isDesktop: boolean;
  isTablet: boolean;
  isMobile: boolean;
  isPortrait: boolean;
}

const initialState: ResponsiveInitialState = {
  isLargeDesktop: false,
  isDesktop: true,
  isTablet: false,
  isMobile: false,
  isPortrait: false,
};
export const ResponsiveContext = createContext<ResponsiveInitialState>({
  ...initialState,
});

export const ResponsiveProvider: FC<ResponsiveProviderProps> = props => {
  const { children } = props;

  const isLargeDesktop = useMediaQuery({
    minWidth: 1900,
  });
  const isDesktop = useMediaQuery({ minWidth: 1370, maxWidth: 1899 });
  const isTablet = useMediaQuery({ minWidth: 1080, maxWidth: 1369 });
  const isMobile = useMediaQuery({ maxWidth: 1079 });
  const isPortrait = useMediaQuery({ orientation: 'portrait' });

  return (
    <ResponsiveContext.Provider
      value={{
        isLargeDesktop,
        isDesktop,
        isTablet,
        isMobile,
        isPortrait,
      }}
    >
      {children}
    </ResponsiveContext.Provider>
  );
};

ResponsiveProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const ResponsiveConsumer = ResponsiveContext.Consumer;
