import { AnchorPopover } from '@/components/table/anchor-drop-down/fat-index';
import RedirectTitleCell from '@/components/table/redirectTitleCell';
import { CellWrapper } from '@/components/table/styles';
import { dateFormatter } from '@/utils/dateFormatter';
import { format } from 'date-fns';
import { StepsList } from '../../fat-stepLists';
import { IRowData } from '../../types';

interface LastUpdateProps {
  row: IRowData;
  isMobile?: boolean;
}

const LastUpdate = ({ row, isMobile = false }: LastUpdateProps) => {
  const { lastUpdatedAt } = {
    lastUpdatedAt: row.lastUpdatedAt
  };

  return (
    <CellWrapper width={'100%'} alignItems={'center'} justifyContent={'space-between'}>
      {isMobile ? (
        <RedirectTitleCell>{dateFormatter(format(new Date(lastUpdatedAt), 'yyyy-MM-dd'), 'yyyy-MM-dd')}</RedirectTitleCell>
      ) : (
        <>
          <AnchorPopover
            isMobile={isMobile}
            title={'Updates'}
            showLeft
            nameCell={dateFormatter(format(new Date(lastUpdatedAt), 'yyyy-MM-dd'), 'yyyy-MM-dd')}
            requestMakesInChildren
          >
            <StepsList row={row} />
          </AnchorPopover>
          {/* {[OPERATIONS_STATUS.READY_FOR_CLIENT_REVIEW, OPERATIONS_STATUS.SENT_TO_CLIENT].includes(status) &&
            alwaysShow &&
            suitability !== 'Suitable' &&
            reason === 'Always Show' && (
              <div style={{ width: 24, height: 24 }}>
                <BasicTooltip tooltipContent={`Always Show: ${suitability}`}>
                  <IInfoIcon />
                </BasicTooltip>
              </div>
            )} */}
        </>
      )}
    </CellWrapper>
  );
};

export default LastUpdate;
