import { InfoIcon } from '@/assets/icons/info-icons/info';
import { Banner } from '@/components/basicComponents/banner';
import RadioButton from '@/components/basicComponents/radioButton';
import FilterSelect from '@/components/basicComponents/select';
import { blinkingFrame } from '@/components/table/styles';
import { useResponsive } from '@/hooks/use-responsive';
import { Dispatch, SetStateAction } from 'react';
import styled, { useTheme } from 'styled-components';
import { AddButton } from '../../addButton';
import { distributionSettingsOptions } from '../../constatnts';
import { Section } from '../../section';
import { IListData } from '../../types';
import { addStrategies, delStrategies, onChangeStrategies } from '../../utils';

interface DistributionSettingsProps {
  advisoryFirms: IListData;
  setAdvisoryFirms: Dispatch<SetStateAction<IListData>>;
  selectedDistributionSettingsOptions: string;
  setSelectedDistributionSettingsOptions: Dispatch<SetStateAction<string>>;
}

export const DistributionSettings = ({
  advisoryFirms,
  setAdvisoryFirms,
  selectedDistributionSettingsOptions,
  setSelectedDistributionSettingsOptions
}: DistributionSettingsProps) => {
  const { isMobile } = useResponsive();
  const theme = useTheme();

  return (
    <Section title="Distribution Settings">
      <DistributionSettingsWrapper isMobile={isMobile}>
        <OptionsWrapper>
          Restrictions
          {distributionSettingsOptions.map((option, index) => (
            <RadioButton
              key={option + index}
              name="distributionSettings"
              radioButtonText={option}
              selectedOption={selectedDistributionSettingsOptions}
              handleRadioChange={(event) => setSelectedDistributionSettingsOptions(event.target.value)}
            />
          ))}
        </OptionsWrapper>
        <Banner
          title=""
          description="This strategy will be shown to all advisory firms by default. Optionally, you may restrict those firms who have access."
          icon={<InfoIcon width={26} height={26} fill={theme.header.base} />}
          bgColor={theme.layer[1]}
          color={theme.font.base}
        />
      </DistributionSettingsWrapper>
      {selectedDistributionSettingsOptions === distributionSettingsOptions[1] && (
        <Line width50>
          <StrategiesWrap>
            {advisoryFirms.chosen.map((el, index) => (
              <StrategiesItemWrap key={`advisoryFirm-${index}`}>
                <CustomFilterSelect
                  data={advisoryFirms.list}
                  selected={advisoryFirms.chosen[index]}
                  setSelected={(value) => onChangeStrategies(value, index, advisoryFirms, setAdvisoryFirms)}
                  disabled={advisoryFirms.chosen[advisoryFirms.chosen.length - 1] !== el}
                  width="100%"
                  withSearch
                  fontSize="16px"
                />
                <DelItemWrap onClick={() => delStrategies(el, index, advisoryFirms, setAdvisoryFirms)}>
                  <img src="/static/icons/blue-cross.svg" alt="cross" />
                </DelItemWrap>
              </StrategiesItemWrap>
            ))}
            {advisoryFirms.list.length && advisoryFirms.chosen[advisoryFirms.chosen.length - 1] !== 'None' ? (
              <AddButton text="Add" onClick={() => addStrategies(advisoryFirms, setAdvisoryFirms)} />
            ) : (
              <></>
            )}
          </StrategiesWrap>
        </Line>
      )}
    </Section>
  );
};

const DistributionSettingsWrapper = styled.div<{ isMobile: boolean }>`
  display: flex;
  align-items: ${({ isMobile }) => (isMobile ? 'flex-start' : 'flex-end')};
  flex-direction: ${({ isMobile }) => (isMobile ? 'column-reverse' : 'row')};
  gap: ${({ isMobile }) => (isMobile ? '15px' : '0')};
`;

const OptionsWrapper = styled.div`
  max-width: 343px;
  width: 100%;
  color: ${({ theme }) => theme.font.base};
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;
`;

const CustomFilterSelect = styled(FilterSelect)`
  background: ${({ disabled, theme }) => (disabled ? theme.action.disabled : theme.layer[2])};
  border: 1px solid ${({ theme }) => theme.border.base};
  padding: 10px 12px;
  border-radius: 5px;

  #titleSelect {
    text-transform: none;
    font-family: Blinker;
    font-size: 16px;
    font-weight: 400;
    line-height: 22.4px;
  }
`;

const Line = styled.span<{ width50?: boolean }>`
  display: flex;
  justify-content: space-between;
  width: ${({ width50 }) => (width50 ? '50%' : '100%')};
  gap: 30px;
  padding-right: ${({ width50 }) => (width50 ? '15px' : '0')};
  margin-top: 4px;
`;

const StrategiesWrap = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

const StrategiesItemWrap = styled.div<{ loading?: boolean | number }>`
  display: flex;
  width: 100%;
  align-items: center;
  margin-bottom: 10px;
  position: relative;

  opacity: ${({ loading }) => (loading ? 0.5 : 1)};
  pointer-events: ${({ loading }) => (loading ? 'none' : 'auto')};
  animation: ${({ loading }) => (loading ? blinkingFrame : null)} 1.5s infinite;
`;

const DelItemWrap = styled.div`
  position: relative;
  top: 3px;
  padding-left: 15px;
  cursor: pointer;
`;
