import * as React from 'react';

export const ComplianceMenuIcon = ({ ...props }: React.SVGProps<SVGSVGElement>) => (
  <svg
    style={props.style}
    width={props.width || '28'}
    height={props.height || '28'}
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M28.5056 15.1665H27.0858V12.3355C27.1188 11.3844 26.9599 10.4363 26.6187 9.54786C26.2774 8.6594 25.7607 7.84872 25.0995 7.16419C24.4382 6.47965 23.646 5.93526 22.7699 5.56345C21.8938 5.19163 20.9518 5 20.0001 5C19.0483 5 18.1063 5.19163 17.2302 5.56345C16.3541 5.93526 15.5618 6.47965 14.9005 7.16419C14.2393 7.84872 13.7226 8.6594 13.3814 9.54786C13.0401 10.4363 12.8812 11.3844 12.9142 12.3355V15.1665H11.503C10.7514 15.1665 10.0304 15.4645 9.49818 15.9951C8.96593 16.5258 8.66576 17.2458 8.66351 17.9974V32.1691C8.66576 32.9206 8.96593 33.6407 9.49818 34.1714C10.0304 34.702 10.7514 35 11.503 35H28.5056C29.2557 34.9978 29.9744 34.6988 30.5048 34.1683C31.0353 33.6379 31.3342 32.9192 31.3365 32.1691V17.9974C31.3342 17.2473 31.0353 16.5285 30.5048 15.9981C29.9744 15.4677 29.2557 15.1687 28.5056 15.1665ZM15.7537 12.3355C15.7537 11.2082 16.2015 10.127 16.9987 9.32988C17.7958 8.53273 18.877 8.08488 20.0043 8.08488C21.1317 8.08488 22.2128 8.53273 23.01 9.32988C23.8071 10.127 24.255 11.2082 24.255 12.3355V15.1665H15.7537V12.3355ZM28.5056 32.1691H11.503V17.9974H28.5056V32.1691ZM20.0043 27.9184C20.5663 27.9184 21.1155 27.7517 21.5826 27.4393C22.0498 27.1269 22.4137 26.683 22.6284 26.1636C22.843 25.6443 22.8988 25.0729 22.7885 24.5219C22.6783 23.9709 22.407 23.465 22.009 23.0682C21.6111 22.6714 21.1043 22.4016 20.553 22.2931C20.0016 22.1845 19.4304 22.2419 18.9118 22.4582C18.3931 22.6744 17.9502 23.0396 17.6393 23.5077C17.3283 23.9757 17.1631 24.5255 17.1648 25.0875C17.1671 25.8391 17.4672 26.5591 17.9995 27.0898C18.5317 27.6204 19.2527 27.9184 20.0043 27.9184Z"
      fill={props.fill || '#FAFAFA'}
    />
  </svg>
);
