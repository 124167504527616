import { getUTCDate } from '@/components/allocations-page/utils';
import { formatCash } from '@/utils/formatCash';
import { useEffect, useState } from 'react';
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { Section } from '../../edit-entity/section';
import { ChartTooltip } from '../chartTooltip';
import { SimpleForecastValueDto } from '../types';

interface CumulativeCashFlowSectionProps {
  cumulativeContributions: SimpleForecastValueDto[];
  cumulativeDistributions: SimpleForecastValueDto[];
}

interface IChartData {
  year: string;
  cumulativeContribution?: number;
  contributionForecast?: number;
  cumulativeDistribution?: number;
  distributionForecast?: number;
  cumulativeCF?: number;
  forecastCumulativeCF?: number;
  initContribution?: number;
  initDistribution?: number;
  actual: boolean;
}

export const CumulativeCashFlowSection = ({ cumulativeContributions, cumulativeDistributions }: CumulativeCashFlowSectionProps) => {
  const [chartData, setChartData] = useState<IChartData[]>([]);

  const addItem = (transformedChartData: { [key: number]: IChartData }, item: SimpleForecastValueDto, fieldName: string) => {
    const formattedYear = getUTCDate(new Date(item.date)).toUpperCase();
    const year = new Date(item.date).getFullYear();

    if (!transformedChartData[year]) {
      transformedChartData[year] = {
        year: formattedYear,
        [fieldName]: item.value,
        actual: item.actual
      };
    }

    transformedChartData[year] = {
      ...transformedChartData[year],
      [fieldName]: item.value
    };

    if (transformedChartData[year].actual) {
      transformedChartData[year].cumulativeCF =
        (transformedChartData[year]?.initDistribution || 0) - (transformedChartData[year]?.initContribution || 0);
      return;
    }

    if (transformedChartData[year - 1]?.actual && !transformedChartData[year]?.actual) {
      transformedChartData[year].cumulativeCF =
        (transformedChartData[year]?.initDistribution || 0) - (transformedChartData[year]?.initContribution || 0);
      transformedChartData[year].forecastCumulativeCF =
        (transformedChartData[year]?.initDistribution || 0) - (transformedChartData[year]?.initContribution || 0);
      return;
    }

    transformedChartData[year].forecastCumulativeCF =
      (transformedChartData[year]?.initDistribution || 0) - (transformedChartData[year]?.initContribution || 0);
  };

  useEffect(() => {
    if (!cumulativeContributions.length || !cumulativeDistributions.length) return;
    const transformedChartData: { [key: number]: IChartData } = {};

    cumulativeContributions.forEach((item) => {
      addItem(transformedChartData, item, 'initContribution');
      if (item.actual) {
        addItem(transformedChartData, { ...item, value: item.value }, 'cumulativeContribution');
        return;
      }
      addItem(transformedChartData, { ...item, value: item.value }, 'contributionForecast');
    });

    cumulativeDistributions.forEach((item) => {
      addItem(transformedChartData, item, 'initDistribution');
      if (item.actual) {
        addItem(transformedChartData, item, 'cumulativeDistribution');
        return;
      }
      addItem(transformedChartData, item, 'distributionForecast');
    });

    setChartData(Object.values(transformedChartData).map((value) => value));
  }, [cumulativeContributions, cumulativeDistributions]);

  return (
    <Section id="cumulativeCashFlow" title="Cumulative Cash Flow">
      <ResponsiveContainer width="100%" height={400}>
        <LineChart stackOffset="sign" data={chartData}>
          <CartesianGrid stroke="#B0B0B0" />
          <XAxis dataKey="year" />
          <YAxis width={85} tickFormatter={(value) => formatCash(value)} />
          <Tooltip content={<ChartTooltip />} />
          <Legend />
          <Line type="monotone" dataKey="distributionForecast" name="Distribution Forecast" stroke="#20CB70" strokeWidth={4} />
          <Line type="monotone" dataKey="contributionForecast" name="Contribution Forecast" stroke="#FC265E" strokeWidth={4} />
        </LineChart>
      </ResponsiveContainer>
      {/* <ResponsiveContainer width="100%" height={400}>
        <ComposedChart stackOffset="sign" data={chartData}>
          <CartesianGrid stroke="#B0B0B0" />
          <XAxis dataKey="year" />
          <YAxis width={85} tickFormatter={(value) => formatCash(value)} />
          <Tooltip content={<ChartTooltip />} />
          <Legend />
          <Bar stackId="a" dataKey="cumulativeDistribution" fill="#9CEBC1" name="Cumulative Distribution" />
          <Bar stackId="a" dataKey="distributionForecast" fill="#20CB70" name="Distribution Forecast" />
          <Bar stackId="a" dataKey="cumulativeContribution" fill="#FAA6BC" name="Cumulative Contribution" />
          <Bar stackId="a" dataKey="contributionForecast" fill="#FC265E" name="Contribution Forecast" />
          <Line type="monotone" dataKey="cumulativeCF" name="Cumulative CF" stroke="#4BADA0" strokeWidth={4} />
          <Line type="monotone" dataKey="forecastCumulativeCF" name="Forecast Cumulative CF" stroke="#0E718A" strokeWidth={4} />
        </ComposedChart>
      </ResponsiveContainer> */}
    </Section>
  );
};
