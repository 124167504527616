import { InfoIcon } from '@/assets/icons/info-icons/info';
import { Banner } from '@/components/basicComponents/banner';
import RadioButton from '@/components/basicComponents/radioButton';
import Input from '@/components/fat-basicComponents/input';
import { formatDollarInputValue, formatMaskedDollarInputValue } from '@/components/fat-basicComponents/input/utils';
import { useResponsive } from '@/hooks/use-responsive';
import { AnimatePresence, motion } from 'framer-motion';
import { Control, Controller, useWatch } from 'react-hook-form';
import styled, { useTheme } from 'styled-components';
import { Section } from '../../fat-investorDetails-page/fat-entityDetails-page/edit-entity/section';
import { CreateInvestorDto } from '../types';

const defaultBiteSizeOptions = ['Default to minimum required commitment', 'Set a default bite size for this entity'];

interface SetAllocationBiteSizeProps {
  control: Control<CreateInvestorDto, any>;
  isSectionOpen: boolean;
  saveSectionState: (id: string, state: { isSectionOpen: boolean }) => void;
}

export const SetAllocationBiteSize = ({ control, isSectionOpen, saveSectionState }: SetAllocationBiteSizeProps) => {
  const { isMobile } = useResponsive();
  const theme = useTheme();
  const ignoreBiteSizeValue = useWatch({
    control,
    name: 'ignoreBiteSize'
  });

  return (
    <Section title="Default Bite Size" id="defaultBiteSizeSection" openSection={isSectionOpen} saveSectionState={saveSectionState} collapsable>
      <DefaultBiteSize isMobile={isMobile}>
        <OptionsWrapper>
          Set the default amount for initial commitments:
          <Controller
            name="ignoreBiteSize"
            control={control}
            render={({ field: { value, onChange } }) => (
              <>
                {defaultBiteSizeOptions.map((option, index) => (
                  <RadioButton
                    key={option + index}
                    name="ignoreBiteSize"
                    radioButtonText={option}
                    selectedOption={value ? defaultBiteSizeOptions[0] : defaultBiteSizeOptions[1]}
                    handleRadioChange={(event) => onChange(event.target.value === defaultBiteSizeOptions[0])}
                  />
                ))}
              </>
            )}
          />
        </OptionsWrapper>
        <Banner
          title=""
          description="The commitment amount will be set to the minimum required for any deal unless a specific default bite size is set here. This amount can be overridden when making an allocation."
          icon={<InfoIcon width={26} height={26} fill={theme.header.base} />}
          bgColor={theme.layer[1]}
          color={theme.font.base}
        />
      </DefaultBiteSize>
      <AnimatePresence>
        {!ignoreBiteSizeValue && (
          <motion.div
            initial={{
              opacity: 0
            }}
            animate={{
              opacity: 1
            }}
            exit={{
              opacity: 0
            }}
          >
            <BiteSizeWrap isMobile={isMobile}>
              <Controller
                name="defaultBiteSize1"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: 'Illiquid Alternative is required'
                  }
                }}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <Input
                    label="Illiquid Alternative"
                    value={value ? formatMaskedDollarInputValue(value) : ''}
                    onChange={(e) => {
                      const formattedValue = formatDollarInputValue(e.target.value);
                      onChange(formattedValue);
                    }}
                    errorText={error?.message}
                    tooltipWidth={400}
                    tooltip="The default commitment amount for corresponding strategies"
                    withDollarSign
                    isRequiredField
                    styleType="form"
                  />
                )}
              />
              <Controller
                name="defaultBiteSize2"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: 'Restricted Alternative is required'
                  }
                }}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <Input
                    label="Restricted Alternative"
                    value={value ? formatMaskedDollarInputValue(value) : ''}
                    onChange={(e) => {
                      const formattedValue = formatDollarInputValue(e.target.value);
                      onChange(formattedValue);
                    }}
                    errorText={error?.message}
                    tooltipWidth={400}
                    tooltip="The default commitment amount for corresponding strategies"
                    withDollarSign
                    isRequiredField
                    styleType="form"
                  />
                )}
              />
            </BiteSizeWrap>
          </motion.div>
        )}
      </AnimatePresence>
    </Section>
  );
};

const OptionsWrapper = styled.div`
  max-width: 343px;
  width: 100%;
  color: ${({ theme }) => theme.font.base};
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;
`;

const DefaultBiteSize = styled.div<{ isMobile: boolean }>`
  display: flex;
  align-items: ${({ isMobile }) => (isMobile ? 'flex-start' : 'flex-end')};
  flex-direction: ${({ isMobile }) => (isMobile ? 'column-reverse' : 'row')};
  gap: ${({ isMobile }) => (isMobile ? '15px' : '0')};
`;

const BiteSizeWrap = styled.div<{ isMobile: boolean }>`
  display: flex;
  gap: ${({ isMobile }) => (isMobile ? '30px' : '15px')};
  flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};
`;
