import styled from 'styled-components';

import client from '@/apollo-client';
import Button from '@/components/fat-basicComponents/button';
import { formatCash } from '@/utils/formatCash';
import { useMutation } from '@apollo/client';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useResponsive } from '../../../hooks/use-responsive';
import { createInvestorInterests, deleteInvestorInterestAmount } from '../queries';
import { IInvestorInterestList } from '../types';
import { GET_INVESTOR_INTERESTS_MIN_QUERY } from './queries';
import { IInvestmentEntity, IInvestorInterests } from './types';

interface FooterModalProps {
  selectedRows: IInvestmentEntity[];
  handleClosePage: (withRefetch?: boolean) => void;
}

const FooterModal = ({ selectedRows, handleClosePage }: FooterModalProps) => {
  const { isMobile, isTablet } = useResponsive();
  const params = useParams();

  const totalValue = useMemo(() => {
    return selectedRows.reduce((accumulator, currentValue) => accumulator + currentValue.investor_interest[0].amount, 0);
  }, [selectedRows]);

  const [createInvestorInterest] = useMutation(createInvestorInterests);
  const [deleteInvestorInterest] = useMutation(deleteInvestorInterestAmount);

  const deleteAmount = async (ids: string[]) => {
    deleteInvestorInterest({
      variables: {
        ids
      }
    });
  };

  const saveEstimate = async () => {
    const { data: investorInterestsData } = await client.query({
      query: GET_INVESTOR_INTERESTS_MIN_QUERY,
      fetchPolicy: 'network-only',
      variables: {
        data: {
          limit: 1000,
          assetClassFilter: [],
          statusFilter: ['Approved']
        }
      }
    });
    const currentInvestorInterests = investorInterestsData.getInvestorInterests.data.find(
      (item: IInvestorInterestList) => item.investment.id === params?.id
    );

    const ids = currentInvestorInterests?.investorInterests.map((item: IInvestorInterests) => item.id);

    const createDataToUpdate = selectedRows.map((row) => {
      return {
        amount: row.investor_interest[0].amount,
        investmentId: params?.id,
        legalEntityId: row.id,
        exempt: row.ubtiBlocker
      };
    });

    if (ids?.length) {
      await deleteAmount(ids);
    }

    await createInvestorInterest({
      variables: {
        data: createDataToUpdate
      },
      onCompleted: () => {
        handleClosePage(true);
      }
    });
  };

  return (
    <FooterModalWrap isMobile={isMobile} isTablet={isTablet}>
      <BtnWrap>
        <Button
          size="lg"
          onClick={saveEstimate}
          styleType={!selectedRows.length ? 'disabled' : 'default'}
          icon={
            <BackWrap disabled={!selectedRows.length}>
              <EditCount disabled={false}>{selectedRows.length}</EditCount>
            </BackWrap>
          }
        >
          Save Estimate
        </Button>
        <EditDesc>
          <BoldInfo>{formatCash(totalValue)}</BoldInfo> total value
        </EditDesc>
      </BtnWrap>
    </FooterModalWrap>
  );
};

const EditDesc = styled.div`
  font-family: Blinker, serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0;
  text-align: left;
  color: ${({ theme }) => theme.font.base};
`;

const BtnWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  @media (max-width: 990px) {
    flex-direction: column;
    gap: 10px;
  }
`;

const Count = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 13px;
`;

const EditCount = styled(Count)<{ disabled: boolean }>`
  color: ${({ disabled, theme }) => (disabled ? theme.font.disabled : theme.font.strong)};
`;

const BackWrap = styled.div<{ disabled: boolean }>`
  background-color: ${({ disabled, theme }) => (disabled ? theme.layer[2] : theme.layer[1])};
  line-height: 15px;
  display: inline-block;
  border-radius: 12px;
  color: ${({ theme }) => theme.context.light};
  padding: 2px 12px;
  font-size: 13px;
  margin: 0 5px;
`;

const FooterModalWrap = styled.div<{ isMobile: boolean; isTablet: boolean }>`
  width: ${({ isMobile, isTablet }) => (isMobile || isTablet ? '100%' : 'calc(100% - 80px)')};
  display: flex;
  align-items: center;
  padding: 20px 80px;
  position: fixed;
  bottom: 0;
  clear: both;
  background-color: ${({ theme }) => theme.layer[1]};
  border-top: 1px solid ${({ theme }) => theme.border.base};
  max-height: 90px;
  height: 100%;
  z-index: 30;
`;

const BoldInfo = styled.div`
  display: inline-block;
  font-weight: 700;
  font-size: 16px;
  color: ${({ theme }) => theme.font.base};
`;

export default FooterModal;
