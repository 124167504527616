import styled, { useTheme } from 'styled-components';

import { NotesIcon } from '@/assets/icons/menu-icons/notes';

type NoteButtonProps = {
  openNotes: () => void;
  isVisible?: boolean;
};

export const NoteButton = ({ openNotes, isVisible = true }: NoteButtonProps) => {
  const theme = useTheme();

  return (
    <NotesButtonWrapper onClick={openNotes} isVisible={isVisible}>
      <NotesIcon id="openNotes" width={12} height={12} fill={theme.font.action} />
      <NotesTitle id="openNotes">Notes</NotesTitle>
    </NotesButtonWrapper>
  );
};

const NotesButtonWrapper = styled.button<{
  isVisible: boolean;
}>`
  background-color: transparent;
  border: 1px solid ${({ theme }) => theme.font.action};
  border-radius: 3px;
  display: flex;
  align-items: center;
  gap: 6px;
  visibility: ${({ isVisible }) => (isVisible ? 'visible' : 'hidden')};
  cursor: pointer;
  font-weight: 400;
  font-size: 19px;
  line-height: 27px;
  padding: 7px 20px;
  outline: none;
  text-transform: capitalize;
`;

const NotesTitle = styled.div`
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  color: ${({ theme }) => theme.font.action};
`;
