import {
  Box,
  CircularProgress,
  Divider,
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent
} from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import { styled } from '@mui/material/styles';
import axios from 'axios';
import fileDownload from 'js-file-download';
import React, { useEffect } from 'react';
import { ButtonProps } from '@/components/buttons';
import { Title } from '@/components/title';

import { AuthGuard } from '@/components/authentication/auth-guard';
import SnackbarAlert from '@/components/snackbar';
import { useResponsive } from '@/hooks/use-responsive';

type IStatus = 'success' | 'error' | 'pending' | 'default';

interface IStatusOfUploading {
  status: IStatus;
  message: string;
}

const ExportPage = () => {
  const [statusOfUploading, setStatusOfUploading] =
    React.useState<IStatusOfUploading>({
      status: 'default',
      message: ''
    });
  const [isAlertOpen, setIsAlertOpen] = React.useState(false);
  const [dataType, setDataType] = React.useState('default');
  const { isMobile } = useResponsive();
  const handleChange = (event: SelectChangeEvent) => {
    setDataType(event.target.value);
  };

  const openAlert = () => setIsAlertOpen(true);
  const closeAlert = () => setIsAlertOpen(false);

  const showNotificationStatusAboutUploading = () => {
    if (statusOfUploading.status === 'default') return;
    if (statusOfUploading.status === 'success')
      return (
        <CustomSnackbarAlert
          width={'60%'}
          open={isAlertOpen}
          setOpen={setIsAlertOpen}
          logo={'/static/icons/success-tick.svg'}
          type={'success'}
          nodeElementToClose={
            <ImageCross
              src={'/static/icons/dark-cross.svg'}
              onClick={closeAlert}
              alt={'Cross'}
            />
          }
        >
          Success
        </CustomSnackbarAlert>
      );
    if (statusOfUploading.status === 'error')
      return (
        <CustomSnackbarAlert
          width={'60%'}
          open={isAlertOpen}
          setOpen={setIsAlertOpen}
          logo={'/static/icons/warning-exclamation.svg'}
          type={'error'}
          nodeElementToClose={
            <ImageCross
              src={'/static/icons/dark-cross.svg'}
              onClick={closeAlert}
              alt={'Cross'}
            />
          }
        >
          {`UnSuccess, ${statusOfUploading.message}`}
        </CustomSnackbarAlert>
      );
  };
  useEffect(() => {
    if (!isAlertOpen) setStatusOfUploading({ status: 'default', message: '' });
  }, [isAlertOpen]);

  const getParams = (dataType: any) => {
    const types = {
      entities: {
        path: 'entity/entities',
        filename: 'entites.csv'
      },
      groups: {
        path: 'entity/groups',
        filename: 'groups.csv'
      },
      managers: {
        path: 'investment/managers',
        filename: 'investment-managers.csv'
      },
      investments: {
        path: 'investment/investments',
        filename: 'Investments.csv'
      },
      families: {
        path: 'family/families',
        filename: 'families.csv'
      }
    };
    return types[dataType as keyof typeof types];
  };

  const handleDownload = async () => {
    const { path, filename } = getParams(dataType);

    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_BASE_URL}/${path}`,
      {
        headers: { authorization: `Bearer ${localStorage.getItem('token')}` },
        responseType: 'blob'
      }
    );
    fileDownload(response.data, filename);
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center'
        }}
      >
        <Box
          sx={{
            width: isMobile ? '100%' : '38%'
          }}
        >
          {showNotificationStatusAboutUploading()}
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={statusOfUploading.status === 'pending'}
          >
            <CircularProgress size={100} />
          </Backdrop>
          <Title content="Select data you want to export"></Title>
          <Divider sx={{ mt: 3 }} />
          <Box sx={{ mt: 3 }}>
            <FormControl fullWidth variant="outlined">
              <Select value={dataType} onChange={handleChange}>
                <MenuItem value="default">Select file type</MenuItem>
                <MenuItem value="entities">Entities</MenuItem>
                <MenuItem value="groups">Groups</MenuItem>
                <MenuItem value="managers">Managers</MenuItem>
                <MenuItem value="investments">Investments</MenuItem>
                <MenuItem value="families">Families</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <Box sx={{ mt: 3 }}>
            <ButtonProps
              variant={'contained'}
              fullWidth
              size="large"
              onClick={handleDownload}
              label="DOWNLOAD"
              disabled={dataType === 'default'}
            ></ButtonProps>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default ExportPage;

const ImageCross = styled('img')(() => ({
  cursor: 'pointer',
  height: 35,
  width: 35
}));
const CustomSnackbarAlert = styled(SnackbarAlert)(() => ({
  marginTop: 70
}));
