import { InfoIcon } from '@/assets/icons/info-icons/info';
import { Banner } from '@/components/basicComponents/banner';
import FilterSelect from '@/components/basicComponents/select';
import Input from '@/components/fat-basicComponents/input';
import Label from '@/components/fat-basicComponents/label';
import FooterModal from '@/components/fat-settings-page/importData/footer';
import { ProgressBar } from '@/components/fat-settings-page/importData/progressBar';
import { ImportWrapper } from '@/components/fat-settings-page/importData/styles';
import { useResponsive } from '@/hooks/use-responsive';
import { Control, Controller, UseFormTrigger } from 'react-hook-form';
import styled, { useTheme } from 'styled-components';
import { ICreateAutoPlanForm } from '..';

interface PlanDetailsProps {
  autoPlanWizardSteps: { name: string; status: string }[];
  control: Control<ICreateAutoPlanForm, any>;
  trigger: UseFormTrigger<ICreateAutoPlanForm>;
  startOver: () => void;
  nextStep: () => void;
}

export const PlanDetails = ({ autoPlanWizardSteps, control, trigger, startOver, nextStep }: PlanDetailsProps) => {
  const { isMobile } = useResponsive();
  const theme = useTheme();

  const moveToTheNextStep = async () => {
    const isNameValid = await trigger('name');
    if (!isNameValid) return;

    nextStep();
  };

  return (
    <>
      <StepWrapper isMobile={isMobile}>
        <ProgressBar importSteps={autoPlanWizardSteps} />
        <StepDetailsWrapper>
          <StepTitle>Plan Details</StepTitle>
          <StepDescription>
            Your plan specifies annual commitment totals to be made in this year and in future years to achieve and maintain the investors target
            allocation. Once the commitment plan is finished, you can forecast allocations and cash flows for both existing and future planned
            commitments.
          </StepDescription>
        </StepDetailsWrapper>
        <StepBodyWrapper isMobile={isMobile}>
          <FieldWrapper isMobile={isMobile}>
            <Controller
              name="name"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: 'Plan Name is required'
                }
              }}
              render={({ field: { onChange, value }, fieldState: { error, invalid } }) => (
                <Input
                  label="Plan Name"
                  tooltip="You can create alternative plans to present to your client or for what-if scenarios."
                  tooltipWidth={250}
                  value={value}
                  onChange={(e) => onChange(e.target.value)}
                  errorText={error?.message}
                  isRequiredField
                />
              )}
            />
            <Banner
              title=""
              description="You can create alternative plans to present to your client or for what-if scenarios."
              icon={<InfoIcon width={26} height={26} fill={theme.header.base} />}
              bgColor={theme.layer[1]}
              color={theme.font.base}
            />
          </FieldWrapper>
          <FieldWrapper isMobile={isMobile}>
            <PlanStartSelectWrapper>
              <Label
                tooltip="By default, if the current calendar quarter is more than half over the commitment plan will start next quarter."
                tooltipWidth={250}
                required
              >
                Plan Start
              </Label>
              <Controller
                name="startDate"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <PlanStartSelect data={['Today', 'Next Quarter']} selected={value} setSelected={onChange} width="100%" />
                )}
              />
            </PlanStartSelectWrapper>
            <Banner
              title=""
              description="Your plan will specify total commitment amounts to be made each year.  The beginning of each annual term corresponds to the calendar quarter selected here."
              icon={<InfoIcon width={26} height={26} fill={theme.header.base} />}
              bgColor={theme.layer[1]}
              color={theme.font.base}
            />
          </FieldWrapper>
        </StepBodyWrapper>
      </StepWrapper>
      <FooterModal
        startOverButtonName="Cancel"
        startOver={startOver}
        confirmButton={{ name: 'Continue', onClick: moveToTheNextStep, disabled: false }}
      />
    </>
  );
};

const StepWrapper = styled(ImportWrapper)<{ isMobile: boolean }>`
  margin-bottom: 150px;
  padding: ${({ isMobile }) => (isMobile ? '20px 30px' : '40px 60px')};
`;

const StepDetailsWrapper = styled.div``;

const StepTitle = styled.p`
  font-size: 20px;
  font-weight: 700;
  line-height: 22.4px;
  text-align: center;
  margin-top: 10px;
  color: ${({ theme }) => theme.font.base};
`;

const StepDescription = styled.p`
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;
  text-align: center;
  padding: 10px;
  color: ${({ theme }) => theme.font.weak};
`;

const StepBodyWrapper = styled.div<{ isMobile: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: ${({ isMobile }) => (isMobile ? '10px' : '40px')};
`;

const FieldWrapper = styled.div<{ isMobile: boolean }>`
  display: grid;
  grid-template-columns: ${({ isMobile }) => (isMobile ? '1fr' : '1fr 1fr')};
  gap: 10px;

  & > *:nth-child(1) {
    order: ${({ isMobile }) => (isMobile ? 2 : 1)};
  }

  & > *:nth-child(2) {
    order: ${({ isMobile }) => (isMobile ? 1 : 2)};
  }
`;

const PlanStartSelectWrapper = styled.div``;

const PlanStartSelect = styled(FilterSelect)`
  border: 1px solid ${({ theme }) => theme.border.base};
  padding: 10px 12px;
  border-radius: 5px;

  #titleSelect {
    text-transform: none;
    font-family: Blinker;
    font-size: 19px;
    line-height: 26.6px;
  }

  #dropDownSelect {
    top: 50px;
  }
`;
