export const fixedColumns = [{ title: 'Deal', key: 'INVESTMENT_NAME', width: 390, keySort: 'deal', rowKey: 'name', value: true }];

export const columns = [
  { title: 'Date Added', key: 'CREATED_DATE', width: 300, keySort: 'Date Added', rowKey: 'createdAt', value: true, order: 1 },
  { title: 'Asset Class', key: 'ASSET_CLASS', width: 360, keySort: 'Asset Class', rowKey: 'assetClass', value: true, order: 2 },
  { title: 'Strategy', key: null, width: 325, keySort: 'strategy', rowKey: 'strategy', value: true, order: 3 },
  { title: 'Tags', key: null, width: 300, keySort: 'tags', rowKey: 'tags', value: true, order: 4 },
  { title: 'Target IRR', key: 'TARGET_IRR', width: 200, keySort: 'Target IRR', rowKey: 'targetIRR', value: true, order: 5 },
  { title: 'Target Yield', key: 'TARGET_YIELD', width: 200, keySort: 'Target Yield', rowKey: 'targetYield', value: true, order: 6 },
  { title: 'Status', key: 'STATUS', width: 300, keySort: 'Status', rowKey: 'status', value: true, order: 7 },
  { title: 'Tier', key: 'TIER', width: 300, keySort: 'Tier', rowKey: 'tier', value: true, order: 8 },
  { title: 'AI Suggestions', key: null, width: 250, keySort: 'ai_suggestions', rowKey: 'ai_suggestions', value: true, order: 9 },
];

export const dealRegistrySelectors = [
  'All Statuses',
  'Lead',
  'Declined',
  'Due Diligence',
  'Approved',
  'Rejected',
  'Open',
  'Active',
  'Realized',
  'Redeemed',
];

export const dealRegistrySort = [
  {
    title: 'Deal • A -> Z',
    key: 'INVESTMENT_NAME',
    asc: true,
  },
  {
    title: 'Deal • Z -> A',
    key: 'INVESTMENT_NAME',
    asc: false,
  },
  {
    title: 'Date Added • Newest -> Oldest',
    key: 'CREATED_DATE',
    asc: false,
  },
  {
    title: 'Date Added • Oldest -> Newest',
    key: 'CREATED_DATE',
    asc: true,
  },
  {
    title: 'Asset Class • A -> Z',
    key: 'ASSET_CLASS',
    asc: true,
  },
  {
    title: 'Asset Class • Z -> A',
    key: 'ASSET_CLASS',
    asc: false,
  },
  {
    title: 'Target IRR • Max -> Min',
    key: 'TARGET_IRR',
    asc: false,
  },
  {
    title: 'Target IRR • Min -> Max',
    key: 'TARGET_IRR',
    asc: true,
  },
  {
    title: 'Target Yield • Max -> Min',
    key: 'TARGET_YIELD',
    asc: false,
  },
  {
    title: 'Target Yield • Min -> Max',
    key: 'TARGET_YIELD',
    asc: true,
  },
  {
    title: 'Status • First -> Last',
    key: 'STATUS',
    asc: true,
  },
  {
    title: 'Status • Last -> First',
    key: 'STATUS',
    asc: false,
  },
  {
    title: 'Tier • A -> Z',
    key: 'TIER',
    asc: false,
  },
  {
    title: 'Tier • Z -> A',
    key: 'TIER',
    asc: true,
  },
];
