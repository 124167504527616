import { getUTCDate } from '@/components/allocations-page/utils';
import { formatCash } from '@/utils/formatCash';
import { useEffect, useState } from 'react';
import { Bar, CartesianGrid, ComposedChart, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { Section } from '../../edit-entity/section';
import { ChartTooltip } from '../chartTooltip';
import { SimpleForecastValueDto } from '../types';

interface EconomicExposureSectionProps {
  totalNAVs: SimpleForecastValueDto[];
  uncalledCapital: SimpleForecastValueDto[];
}

interface IChartData {
  year: string;
  unfundedCommitmentsActuals?: number;
  unfundedCommitmentsForecast?: number;
  navActuals?: number;
  navForcast?: number;
}

export const EconomicExposureSection = ({ totalNAVs, uncalledCapital }: EconomicExposureSectionProps) => {
  const [chartData, setChartData] = useState<IChartData[]>([]);

  const addItem = (transformedChartData: { [key: number]: IChartData }, item: SimpleForecastValueDto, fieldName: string) => {
    const formattedYear = getUTCDate(new Date(item.date)).toUpperCase();
    const year = new Date(item.date).getFullYear();

    if (!transformedChartData[year]) {
      transformedChartData[year] = {
        year: formattedYear,
        [fieldName]: item.value
      };
    }
    transformedChartData[year] = {
      ...transformedChartData[year],
      [fieldName]: item.value
    };
  };

  useEffect(() => {
    if (!totalNAVs.length || !uncalledCapital.length) return;
    const transformedChartData: { [key: number]: IChartData } = {};

    totalNAVs.forEach((item) => {
      if (item.actual) {
        addItem(transformedChartData, item, 'navActuals');
        return;
      }
      addItem(transformedChartData, item, 'navForcast');
    });

    uncalledCapital.forEach((item) => {
      if (item.actual) {
        addItem(transformedChartData, item, 'unfundedCommitmentsActuals');
        return;
      }
      addItem(transformedChartData, item, 'unfundedCommitmentsForecast');
    });

    setChartData(Object.values(transformedChartData).map((value) => value));
  }, [totalNAVs, uncalledCapital]);

  return (
    <Section id="navVsUnfundedCommitments" title="NAV vs Unfunded Commitments">
      <ResponsiveContainer width="100%" height={400}>
        <ComposedChart data={chartData}>
          <CartesianGrid stroke="#B0B0B0" />
          <XAxis dataKey="year" />
          <YAxis width={85} tickFormatter={(value) => formatCash(value)} />
          <Tooltip content={<ChartTooltip />} />
          <Legend />
          <Bar stackId="a" dataKey="navForcast" fill="#AD5CB9" name="NAV" />
          {/* <Bar stackId="a" dataKey="navActuals" fill="#E6BCED" name="NAV (Actuals)" /> */}
          <Bar stackId="a" dataKey="unfundedCommitmentsForecast" fill="#199BDC" name="Unfunded Commitments" />
          {/* <Bar stackId="a" dataKey="unfundedCommitmentsActuals" fill="#97D4F2" name="Unfunded Commitments (Actuals)" /> */}
        </ComposedChart>
      </ResponsiveContainer>
    </Section>
  );
};
