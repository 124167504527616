import { useCallback } from 'react';
import styled from 'styled-components';

import ModalWrappedContent from '@/components/fat-basicComponents/modal/modalWrappedContent';
import Button from '../../basicComponents/button';
import { Loader } from '../../loaders/loader';

export enum DialogStatus {
  LOADING = 'loading',
  SUCCESS = 'success',
  FAILED = 'failed',
  WARNING = 'warning'
}

interface IDialogProps {
  status: DialogStatus | undefined;
  isOpen: boolean;
  isModify: boolean;
  onClose: () => void;
  onSuccess: () => void;
  onCreateInvestment: () => void;
  onUpdateInvestment: () => void;
}

const Dialog = ({ isOpen, isModify, onClose, status, onSuccess, onCreateInvestment, onUpdateInvestment }: IDialogProps) => {
  const renderContent = useCallback(() => {
    switch (status) {
      case DialogStatus.LOADING:
        return <Loading />;
      case DialogStatus.SUCCESS:
        return <Success onSuccess={onSuccess} />;
      case DialogStatus.FAILED:
        return <Failed onClose={onClose} reFetch={isModify ? onUpdateInvestment : onCreateInvestment} />;
      case DialogStatus.WARNING:
        return <Warning onClose={onClose} onConfirm={onUpdateInvestment} />;
      default:
        return <Default onClose={onClose} />;
    }
  }, [status]);

  const shouldShowCloseIcon = () => status !== DialogStatus.LOADING && status !== DialogStatus.SUCCESS;

  return (
    <ModalWrappedContent
      background={false}
      isUseOutsideClick={false}
      showCancelButton={false}
      showCloseIcon={shouldShowCloseIcon()}
      isOpen={isOpen}
      onClose={onClose}
    >
      {renderContent()}
    </ModalWrappedContent>
  );
};

const Loading = () => {
  return (
    <ModalBody>
      <Title>Saving Investment</Title>
      <ModalText>Please allow a few moment for this process to complete.</ModalText>
      <Loader />
    </ModalBody>
  );
};

const Success = ({ onSuccess }: { onSuccess: () => void }) => {
  return (
    <ModalBody>
      <Icon src="/static/icons/check-circle.svg" alt="Success" />
      <Title>Investment Saved Successfully</Title>
      <WrapperButton>
        <ConfirmButton onClick={onSuccess}>
          <ButtonTitle>OK</ButtonTitle>
        </ConfirmButton>
      </WrapperButton>
    </ModalBody>
  );
};

const Failed = ({ onClose, reFetch }: { onClose: () => void; reFetch: () => void }) => {
  return (
    <ModalBody>
      <Title>Proccess Failed</Title>
      <ModalText>We're sorry. This process did not complete. Please try again.</ModalText>
      <WrapperButton>
        <CancelButton onClick={onClose}>
          <ButtonTitle>Cancel</ButtonTitle>
        </CancelButton>
        <RetryButton onClick={reFetch}>
          <ButtonTitle>Retry</ButtonTitle>
        </RetryButton>
      </WrapperButton>
    </ModalBody>
  );
};

const Warning = ({ onClose, onConfirm }: { onClose: () => void; onConfirm: () => void }) => {
  return (
    <ModalBody>
      <Title>Warning</Title>
      <ModalText>Allocations for this investment are underway. Changing some fields may imply that some allocations are no longer valid.</ModalText>
      <WrapperButton>
        <CancelButton onClick={onClose}>
          <ButtonTitle>Cancel</ButtonTitle>
        </CancelButton>
        <RetryButton onClick={onConfirm}>
          <ButtonTitle>Confirm</ButtonTitle>
        </RetryButton>
      </WrapperButton>
    </ModalBody>
  );
};

const Default = ({ onClose }: { onClose: () => void }) => {
  return (
    <ModalBody>
      <Title>Something Went Wrong</Title>
      <WrapperButton>
        <ConfirmButton onClick={onClose}>
          <ButtonTitle>OK</ButtonTitle>
        </ConfirmButton>
      </WrapperButton>
    </ModalBody>
  );
};

const ModalBody = styled.div`
  text-align: center;
`;
const ModalText = styled.div<{ bold?: boolean }>`
  display: inline-block;
  text-align: center;
  font-size: 19px;
  font-weight: ${({ bold }) => (bold ? 600 : 400)};
  margin-left: ${({ bold }) => (bold ? '3px' : 0)};
  color: ${({ theme }) => theme.font.base};
`;

const Title = styled.h1`
  font-family: 'Blinker', serif;
  font-weight: 400;
  font-size: 33px;
  text-align: center;
  color: ${({ theme }) => theme.font.strong};
  margin-bottom: 10px;
`;

const Icon = styled.img`
  width: 60px;
  height: 60px;
`;

const WrapperButton = styled.span`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 50px;
  gap: 10px;
`;

const ConfirmButton = styled(Button)`
  background-color: #4587ec;
  color: white;
`;

const ButtonTitle = styled.p`
  font-family: 'Blinker';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
`;

const RetryButton = styled(Button)`
  background-color: #1c488a;
  color: white;
`;

const CancelButton = styled(Button)`
  background: white;
  border: 1px solid #4587ec;
  color: #4587ec;
`;

export default Dialog;
