import BasicTooltip from '@/components/basicComponents/tooltip';
import styled from 'styled-components';

export const FutureStatusRow = ({ status, index, isMobile = false }: { status: string; index: number; isMobile?: boolean }) => {
  if (isMobile) {
    return (
      <BasicTooltip tooltipContent={status}>
        <Row isMobile={isMobile}>
          <Number>{index + 1}</Number>
          <FutureStatus isMobile={isMobile}>{status}</FutureStatus>
        </Row>
      </BasicTooltip>
    );
  }

  return (
    <Row isMobile={isMobile}>
      <Number>{index + 1}</Number>
      <FutureStatus isMobile={isMobile}>{status}</FutureStatus>
    </Row>
  );
};
const Row = styled.div<{ isMobile: boolean }>`
  display: flex;
  justify-content: ${({ isMobile }) => (isMobile ? 'flex-start' : 'space-between')};
  align-items: center;
  border-radius: 4px;
  padding: 7px 12px;
  background: ${({ theme }) => theme.layer[1]};
`;

const FutureStatus = styled.div<{ isMobile: boolean }>`
  width: 100%;
  overflow: ${({ isMobile }) => (isMobile ? 'hidden' : 'none')};
  text-overflow: ${({ isMobile }) => (isMobile ? 'ellipsis' : 'none')};
  user-select: ${({ isMobile }) => (isMobile ? 'none' : 'auto')};
  display: inline-block;
  color: ${({ theme }) => theme.font.base};
  padding-left: 10px;
  font-family: 'Blinker';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
`;

const Number = styled.span`
  color: ${({ theme }) => theme.font.base};
  font-family: 'Blinker', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
`;
