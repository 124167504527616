import DropdownActions from '@/components/table/fat-dropdownActions';
import NoResults from '@/components/table/noResults';
import { TableRowCell, TableRowWrapper, TextCell } from '@/components/table/styles';
import guid from '@/utils/guid';
import { tenantsTableColumns } from '../constants';
import { ITenant } from '../types';

interface TableBodyProps {
  tenantsTableTableData?: ITenant[];
  openModalWindow: (type: string, tenant?: ITenant) => void;
}

const TableBody = ({ tenantsTableTableData, openModalWindow }: TableBodyProps) => {
  if (!tenantsTableTableData?.length) {
    return <NoResults />;
  }

  return (
    <>
      {tenantsTableTableData.map((tenant: ITenant) => {
        const truncatedName = tenant.name.length > 30 ? `${tenant.name.substring(0, 30)}...` : tenant.name;
        const truncatedEmail = tenant.primaryAdmin?.email.length > 30
          ? `${tenant.primaryAdmin.email.substring(0, 30)}...`
          : tenant.primaryAdmin?.email;
        
        return (
          <TableRowWrapper key={guid()}>
            <TableRowCell width={tenantsTableColumns[0].width}>
              <TextCell>{truncatedName}</TextCell>
            </TableRowCell>
            <TableRowCell width={tenantsTableColumns[1].width}>
              <TextCell>
                {truncatedEmail}
              </TextCell>
            </TableRowCell>
            <TableRowCell width={tenantsTableColumns[2].width}>
              <DropdownActions
                items={[
                  {
                    name: 'Edit',
                    onClick: () => openModalWindow('edit', tenant)
                  },
                  {
                    name: 'Delete',
                    onClick: () => openModalWindow('delete', tenant)
                  },
                ]}
              />
            </TableRowCell>
          </TableRowWrapper>
        );
      })}
    </>
  );
};

export default TableBody;
