import {
  IListInvestmentEntityInterests,
  IListInvestmentEntityInterestsPaginated
} from '@/components/fat-dealCatalog-page/fat-seekingInterest-page/types';
import Header from '@/components/fat-header';
import { GoBackButton } from '@/components/fat-header/goBackButton';
import { PageTitle } from '@/components/fat-header/pageTitle';
import { CurrentStatus } from '@/components/fat-investors-page/types';
import { IMobileSort } from '@/components/fat-pendingInvestments-page/types';
import { initialValuesFromTable } from '@/components/table/constants';
import FilterPage from '@/components/table/fat-filterPage';
import TablePagination from '@/components/table/tablePagination';
import { ISort, SORT_ORDER } from '@/components/table/types';
import { useResponsive } from '@/hooks/use-responsive';
import { useGoBack } from '@/hooks/useGoBack';
import { MainWrap } from '@/styles/common';
import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import { IInvestmentEntityByAdvisoryFirmPaginated, InvestorInterestViewType, Tenant } from '../types';
import { VIEW_BY_SELECTS, advisorTableSort, investorTableSort } from './constatnts';
import Table from './fat-table/table';
import {
  ALL_ADVISORY_FIRM_LIST_QUERY,
  LIST_INVESTMENT_ENTITY_INTERESTS_BY_ADVISORY_FIRM_QUERY,
  LIST_INVESTMENT_ENTITY_INTERESTS_BY_INVESTOR_QUERY
} from './queries';

export const InvestorInterestPage = () => {
  const { isMobile, isTablet, isPortrait } = useResponsive();
  const goBack = useGoBack();
  const params = useParams();
  const [searchParams, setSearchParams] = useSearchParams();

  const backToTitle = searchParams.get('backToTitle') || '';
  const q = searchParams.get('q') || '';
  const key = searchParams.get('key') || 'ESTIMATED_COMMITMENT';
  const asc = searchParams.get('asc') === 'true' || false;
  const primaryFilter = searchParams.get('primaryFilter') || 'All';
  const viewBy = (searchParams.get('viewBy') || 'Advisory Firm') as InvestorInterestViewType;
  const limit = searchParams.get('limit') || initialValuesFromTable.initialAmountRowsPerPage[0];
  const offset = searchParams.get('offset') || 0;

  const [mobileSortItems, setMobileSortItems] = useState<IMobileSort[]>([]);
  const [mobileSort, setMobileSort] = useState('');
  const sortItem = mobileSortItems.find((item) => item.title === mobileSort);
  const mobileSortList = mobileSortItems.map((item) => item.title);
  const [listInvestmentEntity, setListInvestmentEntity] = useState<IListInvestmentEntityInterestsPaginated>({
    page: 1,
    perPage: 10,
    total: 0,
    lastPage: 1,
    data: []
  });
  const [listInvestmentEntityByAdvisoryFirm, setListInvestmentEntityByAdvisoryFirm] = useState<IInvestmentEntityByAdvisoryFirmPaginated>({
    page: 1,
    perPage: 10,
    total: 0,
    lastPage: 1,
    data: []
  });

  useEffect(() => {
    if (!isMobile) return;

    switch (viewBy) {
      case 'Advisory Firm':
        setMobileSortItems(advisorTableSort);
        setMobileSort(advisorTableSort[6].title);
        break;
      case 'Investor':
        setMobileSortItems(investorTableSort);
        setMobileSort(investorTableSort[10].title);
        break;
      default:
        break;
    }
  }, [viewBy, isMobile]);

  useEffect(() => {
    if (!isMobile) return;
    getCurrentStatus().refetch({
      data: {
        investmentId: params.id,
        sortDirection: sortItem?.asc ? SORT_ORDER.ASC : SORT_ORDER.DESC,
        sortBy: sortItem?.key,
        search: q.toLowerCase().trim(),
        advisoryFirm: primaryFilter,
        limit: limit,
        offset: offset
      }
    });
  }, [mobileSort]);

  const { data: { allAdvisoryFirmList = [] } = {} } = useQuery<{
    allAdvisoryFirmList: Tenant[];
  }>(ALL_ADVISORY_FIRM_LIST_QUERY);

  const { refetch: listInvestmentEntityRefetch, loading: listInvestmentEntityLoading } = useQuery<IListInvestmentEntityInterests>(
    LIST_INVESTMENT_ENTITY_INTERESTS_BY_INVESTOR_QUERY,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'no-cache',
      skip: !params.id || viewBy !== 'Investor',
      variables: {
        data: {
          investmentId: params.id,
          sortBy: key,
          sortDirection: asc ? SORT_ORDER.ASC : SORT_ORDER.DESC,
          search: q.toLowerCase().trim(),
          advisoryFirm: primaryFilter,
          limit: limit,
          offset: offset
        }
      },
      onCompleted: (data) => {
        setListInvestmentEntity(data.listInvestmentEntityInterests);
      },
      onError: (error) => {
        console.error(error);
      }
    }
  );

  const { refetch: listInvestmentEntityByAdvisoryFirmRefetch, loading: listInvestmentEntityByAdvisoryFirmLoading } = useQuery<{
    listInvestmentEntityInterestsByAdvisoryFirm: IInvestmentEntityByAdvisoryFirmPaginated;
  }>(LIST_INVESTMENT_ENTITY_INTERESTS_BY_ADVISORY_FIRM_QUERY, {
    notifyOnNetworkStatusChange: true,
    skip: !params.id || viewBy !== 'Advisory Firm',
    variables: {
      data: {
        investmentId: params.id,
        sortBy: key,
        sortDirection: asc ? SORT_ORDER.ASC : SORT_ORDER.DESC,
        search: q.toLowerCase().trim(),
        advisoryFirm: primaryFilter,
        limit: limit,
        offset: offset
      }
    },
    onCompleted: (data) => {
      setListInvestmentEntityByAdvisoryFirm(data.listInvestmentEntityInterestsByAdvisoryFirm);
    },
    onError: (error) => {
      console.error(error);
    }
  });

  const getCurrentStatus = (): CurrentStatus => {
    switch (viewBy) {
      case 'Advisory Firm':
        return {
          loading: listInvestmentEntityByAdvisoryFirmLoading,
          refetch: listInvestmentEntityByAdvisoryFirmRefetch,
          total: listInvestmentEntityByAdvisoryFirm.total
        };
      case 'Investor':
        return {
          loading: listInvestmentEntityLoading,
          refetch: listInvestmentEntityRefetch,
          total: listInvestmentEntity.total
        };
      default:
        return {
          loading: false,
          refetch: () => null,
          total: 0
        };
    }
  };

  const openAdvisorFirmDetails = (id: string) => {
    changeViewBy('Investor');
    onChangePrimaryFilter(allAdvisoryFirmList.find((item) => item.id === id)?.name || 'All');
  };

  const changeViewBy = (view: InvestorInterestViewType) => {
    setSearchParams(
      (prev) => {
        prev.set('viewBy', view);
        prev.delete('q');
        prev.delete('limit');
        prev.delete('offset');
        return prev;
      },
      { replace: true }
    );
  };

  const onChangeSearch = (searchValue: string) => {
    setSearchParams(
      (prev) => {
        if (!searchValue) {
          prev.delete('q');
          return prev;
        }
        prev.set('q', searchValue);
        prev.delete('limit');
        prev.delete('offset');
        return prev;
      },
      { replace: true }
    );
  };

  const onChangeSort = (value: ISort) => {
    setSearchParams(
      (prev) => {
        prev.set('key', value.key);
        prev.set('asc', value.asc.toString());
        return prev;
      },
      { replace: true }
    );
  };

  const onChangePrimaryFilter = (filter: string) => {
    setSearchParams(
      (prev) => {
        prev.set('primaryFilter', filter);
        prev.delete('limit');
        prev.delete('offset');
        return prev;
      },
      { replace: true }
    );
  };

  const onChangePaginationData = (limit: number, offset: number) => {
    setSearchParams(
      (prev) => {
        prev.set('limit', limit.toString());
        prev.set('offset', offset.toString());
        return prev;
      },
      { replace: true }
    );
  };

  return (
    <>
      <MainWrap>
        <Header modalControl={<GoBackButton handleClose={() => goBack({ fallBack: '/dealPipeline' })} backToTitle={backToTitle} />} />
        <PageTitle title={params?.name ?? ''} />
      </MainWrap>
      <MainWrap>
        <PaddingWrap>
          <CustomFilterPage
            isMobile={isMobile}
            isTablet={isTablet}
            search={{
              value: q,
              onChange: onChangeSearch,
              placeholder: viewBy === 'Advisory Firm' ? 'Search for an advisor' : 'Search for an investor'
            }}
            primaryFilter={{
              value: primaryFilter,
              onChange: onChangePrimaryFilter,
              selects: ['All', ...allAdvisoryFirmList.map(({ name }) => name)]
            }}
            resultsValue={getCurrentStatus().total}
            refetch={getCurrentStatus().refetch}
            viewBySelect={{
              value: viewBy,
              onChange: (v) => changeViewBy(v as InvestorInterestViewType),
              selects: VIEW_BY_SELECTS
            }}
            mobileSort={{
              value: mobileSort,
              onChange: setMobileSort,
              selects: mobileSortList
            }}
          />
          <Table
            listInvestmentEntity={listInvestmentEntity.data}
            listInvestmentEntityByAdvisoryFirm={listInvestmentEntityByAdvisoryFirm.data}
            sort={{ key, asc }}
            setSort={onChangeSort}
            loading={listInvestmentEntityLoading || listInvestmentEntityByAdvisoryFirmLoading}
            viewByValue={viewBy}
            openAdvisorFirmDetails={openAdvisorFirmDetails}
          />
          <PaginationWrap>
            <TablePagination
              savePagination={onChangePaginationData}
              paginationValues={{
                limit: Number(limit),
                offset: Number(offset),
                total: getCurrentStatus().total
              }}
              refetch={() => null as any}
            />
          </PaginationWrap>
        </PaddingWrap>
      </MainWrap>
    </>
  );
};

const PaddingWrap = styled.div`
  padding-left: 16px;
  padding-right: 16px;
  @media (min-width: 600px) {
    padding-left: 50px;
    padding-right: 50px;
  }
`;

const CustomFilterPage = styled(FilterPage)<{ isMobile: boolean; isTablet: boolean }>`
  position: sticky;
  top: ${({ isMobile, isTablet }) => (isMobile || isTablet ? '50px' : '0')};
  z-index: 9;
  margin-top: 0;
  margin-left: -1px;
  width: calc(100% + 2px);
  padding: ${({ isMobile }) => (isMobile ? '20px 0 0 0' : '20px 0 10px 0')};
`;

const PaginationWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;
