import { PasswordToggleButton } from '@/components/passwordToggleButton';
import { useState } from 'react';
import styled, { css } from 'styled-components';
import Label from '../label';

interface InputProps {
  value?: string | number;
  onChange?: (event?: React.ChangeEvent<HTMLInputElement>) => void;
  onClick?: (event?: React.MouseEvent<HTMLInputElement>) => void;
  onKeyUp?: (event?: React.KeyboardEvent<HTMLInputElement>) => void;
  onBlur?: (event?: React.FocusEvent<HTMLInputElement>) => void;
  ref?: React.LegacyRef<HTMLInputElement>;
  size?: 'sm' | 'md' | 'lg';
  styleType?: 'default' | 'form' | 'success' | 'error' | 'disabled';
  type?: 'text' | 'number' | 'email' | 'password';
  width?: string;
  errorText?: string;
  label?: string;
  tooltip?: string;
  tooltipWidth?: number;
  isRequiredField?: boolean;
  withPasswordIcon?: boolean;
  withDollarSign?: boolean;
  placeholder?: string;
  autoFocus?: boolean;
  className?: string;
}

const getInputSize = (size: InputProps['size'], withDollarSign: boolean, withPasswordIcon: boolean) => {
  let paddingRight = '0px';
  let paddingLeft = '0px';

  if (withPasswordIcon) {
    paddingRight = '30px';
  }

  if (withDollarSign) {
    paddingLeft = '12px';
  }

  switch (size) {
    case 'sm':
      return css`
        padding: 8px ${`calc(5px + ${paddingRight})`} 8px ${paddingLeft ? `calc(5px + ${paddingLeft})` : '5px'};
        border-radius: 3px;
      `;
    case 'md':
      return css`
        padding: 10px ${`calc(10px + ${paddingRight})`} 10px ${`calc(10px + ${paddingLeft})`};
        border-radius: 4px;
      `;
    case 'lg':
      return css`
        padding: 10px ${`calc(12px + ${paddingRight})`} 10px ${`calc(12px + ${paddingLeft})`};
        border-radius: 5px;
      `;
    default:
      return '';
  }
};

const getInputStyleType = (styleType: InputProps['styleType']) => {
  switch (styleType) {
    case 'default':
      return css`
        background-color: ${({ theme }) => theme.layer[1]};
        border: 1px solid ${({ theme }) => theme.border.base};
        color: ${({ theme }) => theme.font.strong};

        &:-webkit-autofill,
        &:-webkit-autofill:hover,
        &:-webkit-autofill:focus,
        &:-webkit-autofill:active {
          -webkit-box-shadow: 0 0 0 30px ${({ theme }) => theme.layer[1]} inset;
          -webkit-text-fill-color: ${({ theme }) => theme.font.strong};
        }
      `;
    case 'form':
      return css`
        background-color: ${({ theme }) => theme.layer[2]};
        border: 1px solid ${({ theme }) => theme.border.base};
        color: ${({ theme }) => theme.font.strong};

        &:-webkit-autofill,
        &:-webkit-autofill:hover,
        &:-webkit-autofill:focus,
        &:-webkit-autofill:active {
          -webkit-box-shadow: 0 0 0 30px ${({ theme }) => theme.layer[2]} inset;
          -webkit-text-fill-color: ${({ theme }) => theme.font.strong};
        }
      `;
    case 'success':
      return css`
        background-color: #efffea;
        border: 1px solid ${({ theme }) => theme.context.success};
        color: #2e2e2e;

        &:-webkit-autofill,
        &:-webkit-autofill:hover,
        &:-webkit-autofill:focus,
        &:-webkit-autofill:active {
          -webkit-box-shadow: 0 0 0 30px #efffea inset;
          -webkit-text-fill-color: #2e2e2e;
        }
      `;
    case 'error':
      return css`
        background-color: #ffeaec;
        border: 1px solid ${({ theme }) => theme.context.error};
        color: #2e2e2e;

        &:-webkit-autofill,
        &:-webkit-autofill:hover,
        &:-webkit-autofill:focus,
        &:-webkit-autofill:active {
          -webkit-box-shadow: 0 0 0 30px #ffeaec inset;
          -webkit-text-fill-color: #2e2e2e;
        }
      `;
    case 'disabled':
      return css`
        background-color: ${({ theme }) => theme.action.disabled};
        border: 1px solid ${({ theme }) => theme.border.base};
        color: ${({ theme }) => theme.font.disabled};
      `;
    default:
      return '';
  }
};

const getPasswordIconTopPosition = (size: InputProps['size']) => {
  switch (size) {
    case 'sm':
      return css`
        top: 8px;
      `;
    case 'md':
      return css`
        top: 12px;
      `;
    case 'lg':
      return css`
        top: 15px;
      `;
    default:
      return '';
  }
};

const getDollarSignTopPosition = (size: InputProps['size']) => {
  switch (size) {
    case 'sm':
      return css`
        top: 9px;
      `;
    case 'md':
      return css`
        top: 11px;
      `;
    case 'lg':
      return css`
        top: 11px;
      `;
    default:
      return '';
  }
};

const getFontProperties = (size: InputProps['size']) => {
  switch (size) {
    case 'sm':
      return css`
        font-size: 13px;
        line-height: 18.2px;
      `;
    case 'md':
      return css`
        font-size: 16px;
        line-height: 22.4px;
      `;
    case 'lg':
      return css`
        font-size: 19px;
        line-height: 26.6px;
      `;
    default:
      return '';
  }
};

const Input = ({
  value,
  onChange,
  onClick,
  onKeyUp,
  onBlur,
  ref,
  size = 'lg',
  styleType = 'default',
  type,
  width = '100%',
  errorText,
  label,
  tooltip,
  tooltipWidth = 0,
  isRequiredField = false,
  withPasswordIcon = false,
  withDollarSign = false,
  placeholder = '',
  autoFocus = false,
  className
}: InputProps) => {
  const [isPasswordMasked, setIsPasswordMasked] = useState(withPasswordIcon);

  return (
    <InputWrapper>
      {label && (
        <Label required={isRequiredField} size={size} tooltip={tooltip} tooltipWidth={tooltipWidth}>
          {label}
        </Label>
      )}
      <StyledInputWrapper>
        <StyledInput
          type={withPasswordIcon ? (isPasswordMasked ? type : 'text') : type}
          value={value}
          onChange={onChange}
          onClick={onClick}
          onKeyUp={onKeyUp}
          onBlur={onBlur}
          ref={ref}
          disabled={styleType === 'disabled'}
          placeholder={placeholder}
          width={width}
          size={size}
          styleType={styleType}
          withPasswordIcon={withPasswordIcon}
          withDollarSign={withDollarSign}
          className={className}
          autoFocus={autoFocus}
        />
        {withDollarSign && (
          <Dollar size={size} styleType={styleType}>
            $
          </Dollar>
        )}
        {withPasswordIcon && (
          <PasswordIcon size={size}>
            <PasswordToggleButton isPasswordMasked={isPasswordMasked} setIsPasswordMasked={setIsPasswordMasked} />
          </PasswordIcon>
        )}
      </StyledInputWrapper>
      {errorText && <ErrorText size={size}>{errorText}</ErrorText>}
    </InputWrapper>
  );
};

export default Input;

const InputWrapper = styled.div`
  width: 100%;
`;

const StyledInputWrapper = styled.div`
  position: relative;
`;

const StyledInput = styled.input<InputProps>`
  font-family: Blinker;
  font-style: normal;
  font-weight: 400;
  width: ${({ width }) => width};
  cursor: ${({ styleType }) => (styleType === 'disabled' ? 'not-allowed' : 'auto')};

  ${({ size, withDollarSign, withPasswordIcon }) => getInputSize(size, withDollarSign, withPasswordIcon)}
  ${({ styleType }) => getInputStyleType(styleType)}
  ${({ size }) => getFontProperties(size)}


   &:focus {
    outline: none;
  }

  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const Dollar = styled.span<{ size: InputProps['size']; styleType: InputProps['styleType'] }>`
  position: absolute;
  left: 10px;
  color: ${({ theme, styleType }) => (styleType === 'success' || styleType === 'error' ? '#2e2e2e' : theme.font.strong)};

  ${({ size }) => getFontProperties(size)}
  ${({ size }) => getDollarSignTopPosition(size)}
`;

const PasswordIcon = styled.div<{ size: InputProps['size'] }>`
  position: absolute;
  right: 12px;

  ${({ size }) => getPasswordIconTopPosition(size)}
`;

const ErrorText = styled.p<{ size: InputProps['size'] }>`
  color: ${({ theme }) => theme.context.error};
  font-family: Blinker;
  font-style: normal;
  font-weight: 400;

  ${({ size }) => getFontProperties(size)}
`;
