import { useEffect, useMemo, useState } from 'react';
import styled, { useTheme } from 'styled-components';

import { ExportDataIcon } from '@/assets/icons/exportDataIcon';
import { FilterIcon } from '@/assets/icons/filter';
import { FilterSettingIcon } from '@/assets/icons/filterSetting';
import { RefreshIcon } from '@/assets/icons/info-icons/refresh';
import FilterBar, { IFilterBarData } from '@/components/basicComponents/filterBar';
import FilterSearch from '../../basicComponents/input';
import FilterSelect from '../../basicComponents/select';
import SelectWithCheckBox, { TData } from '../../basicComponents/selectWithCheckBox';
import MoreFilters from './moreFilters';
import { AdditionalFilters } from './types';
import ChosenFilters from './сhosenFilters';

interface FilterPageProps {
  search: {
    value: string;
    onChange: any;
    placeholder: string;
  };
  primaryFilter?: {
    value: string;
    onChange: (value: string) => void;
    selects: string[];
    withSearch?: boolean;
  };
  withCheckBox?: boolean;
  additionalFilters?: AdditionalFilters;
  withExportData?: boolean;
  setAllocationStatusFilter?: any;
  allocationStatusFilter?: TData[];
  currentTab?: { value: string };
  className?: string;
  isResult?: boolean;
  resultsValue?: number;
  refetch?: any;
  isDealRegistryFilter?: boolean;
  handleDownload: () => void;
  tableColumnControl?: () => void;
  viewBySelect?: {
    value: string;
    onChange: (value: string) => void;
    selects: string[];
  };
  filterBar?: IFilterBarData[];
  onChangeFilterBar?: (filterBarItems: IFilterBarData[]) => void;
}

const DesktopFilterPage = ({
  search,
  primaryFilter,
  additionalFilters,
  withExportData = false,
  withCheckBox,
  setAllocationStatusFilter,
  allocationStatusFilter,
  currentTab,
  isResult,
  resultsValue,
  refetch,
  handleDownload,
  tableColumnControl,
  viewBySelect,
  isDealRegistryFilter = false,
  filterBar,
  onChangeFilterBar
}: FilterPageProps) => {
  const theme = useTheme();
  const [moreFilter, setMoreFilter] = useState(false);
  const [chosenFilters, setChosenFilters] = useState(!!additionalFilters?.showChosenFilters);
  const [checkBoxData, setCheckBoxData] = useState<TData[]>(allocationStatusFilter ?? []);

  useEffect(() => {
    if (additionalFilters?.filters[0].typeFilter === 'Select A Filter Option' && currentTab) {
      setChosenFilters(false);
    }
  }, [currentTab?.value, additionalFilters]);

  useEffect(() => {
    if (additionalFilters?.filters[0].typeFilter !== 'Select A Filter Option') {
      setChosenFilters(true);
    }
  }, []);

  useEffect(() => {
    if (isDealRegistryFilter) {
      setChosenFilters(!!additionalFilters?.showChosenFilters);
    }
  }, [additionalFilters?.showChosenFilters]);

  const checkBoxSelectLabel = useMemo(() => {
    const choosenNames = checkBoxData.filter((el) => el.value);
    let label = '';
    if (choosenNames.length >= 1 && choosenNames.length <= 2) {
      choosenNames.map((el, index) => {
        if (index === 0) {
          label = el.name;
        } else {
          label = label + ', ' + el.name;
        }
      });
      return label;
    } else {
      return choosenNames.length === 0 ? 'Filter by' : choosenNames.length + ' ' + 'Selected';
    }
  }, [checkBoxData]);

  const handleCheckBoxData = (id: number) => {
    const newData = [...checkBoxData];
    newData[id].value = !newData[id].value;
    setCheckBoxData(newData);
  };

  return (
    <>
      <DefaultSearchWrap>
        {isResult ? (
          <TopWrapper>
            <Results>{additionalFilters?.amountResults || resultsValue || 0} Results</Results>
            <RefreshButton
              onClick={() => {
                if (additionalFilters?.onRefetch) {
                  additionalFilters.onRefetch();
                } else if (refetch) {
                  refetch();
                } else null;
              }}
            >
              <RefreshIcon width={15} height={15} fill={theme.action.primary} />
            </RefreshButton>
            {viewBySelect && (
              <WrapperSelector>
                <ViewByText>View By</ViewByText>
                <ViewBySelectSelect width={'auto'} selected={viewBySelect.value} setSelected={viewBySelect.onChange} data={viewBySelect.selects} />
              </WrapperSelector>
            )}
          </TopWrapper>
        ) : null}

        <MainSearchWrap>
          {withExportData && (
            <ExportDataWrap onClick={handleDownload}>
              <ExportDataIcon fill={theme.font.action} />
              <ExportDataTitle>Export Data</ExportDataTitle>
            </ExportDataWrap>
          )}
          <CustomFilterSearch value={search.value} onChange={search.onChange} placeholder={search.placeholder} />
          {primaryFilter && (
            <FilterSelect
              data={primaryFilter.selects}
              selected={primaryFilter.value}
              setSelected={primaryFilter.onChange}
              withSearch={!!primaryFilter?.withSearch}
              longTitle
            />
          )}
          {currentTab?.value === 'pending' && withCheckBox && (
            <SelectWithCheckBox
              data={checkBoxData}
              setSelected={handleCheckBoxData}
              label={checkBoxSelectLabel}
              setAllocationStatusFilter={setAllocationStatusFilter}
              setCheckBoxData={setCheckBoxData}
            />
          )}
          {additionalFilters && (
            <FilterWrap onClick={() => setMoreFilter(!moreFilter)} moreFilter={moreFilter}>
              <FilterIcon fill={moreFilter ? theme.context.light : theme.font.action} />
            </FilterWrap>
          )}
          {tableColumnControl && (
            <FilterSettingWrap onClick={tableColumnControl}>
              <FilterSettingIcon fill={theme.font.action} />
            </FilterSettingWrap>
          )}
        </MainSearchWrap>
      </DefaultSearchWrap>

      {additionalFilters && moreFilter && (
        <MoreFilters onClose={() => setMoreFilter(false)} setChosenFilters={setChosenFilters} {...additionalFilters} />
      )}
      {additionalFilters && chosenFilters && <ChosenFilters setChosenFilters={setChosenFilters} {...additionalFilters} />}
      {filterBar && (
        <FilterBarWrap>
          <FilterBar filterBar={filterBar} onChangeFilterBar={onChangeFilterBar} />
        </FilterBarWrap>
      )}
    </>
  );
};

const ExportDataTitle = styled.div`
  font-family: Blinker, serif;
  font-size: 13px;
  font-weight: 600;
  line-height: 18px;
  text-align: left;
  color: ${({ theme }) => theme.font.action};
`;

const ExportDataWrap = styled.div`
  display: flex;
  cursor: pointer;
  gap: 6px;
  justify-content: center;
  align-items: center;
  width: 30%;
`;

const FilterWrap = styled.div<{ moreFilter: boolean }>`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ moreFilter, theme }) => (moreFilter ? theme.action.active : theme.layer.base)};
  border: ${({ moreFilter, theme }) => `1px solid ${moreFilter ? theme.action.active : theme.font.action}`};
  border-radius: 3px;
  padding: 10px;
`;

export const FilterSettingWrap = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.layer.base};
  border: ${({ theme }) => `1px solid ${theme.font.action}`};
  border-radius: 3px;
  padding: 8px;
`;

const DefaultSearchWrap = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  gap: 22px;
`;

const MainSearchWrap = styled.div`
  display: flex;
  border-radius: 4px;
  background: ${({ theme }) => theme.layer[1]};
  padding: 8px 10px;
  gap: 20px;
`;

export const FilterBarWrap = styled.div`
  margin-top: 10px;
`;

export const TopWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const Results = styled.h1`
  color: ${({ theme }) => theme.font.base};
  font-family: Blinker;
  font-size: 13px;
  font-weight: 600;
  line-height: 13px;
`;

export const RefreshButton = styled.div`
  padding-left: 5px;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const WrapperSelector = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 10px 5px 30px;
  gap: 6px;
`;

export const ViewBySelectSelect = styled(FilterSelect)`
  padding: 0;
  background: transparent;
  border: 0;
  justify-content: flex-start;

  svg {
    margin: 0 6px;
  }

  #titleSelect {
    color: ${({ theme }) => theme.font.action};
    white-space: normal;
    font-family: 'Blinker';
    font-weight: 400;
    font-size: 14px;
  }

  #dropDownSelect {
    top: 26px;
    width: max-content;
  }
`;

export const ViewByText = styled.p`
  font-family: 'Blinker';
  font-weight: 400;
  font-size: 14px;
  color: ${({ theme }) => theme.font.strong};
`;

const CustomFilterSearch = styled(FilterSearch)`
  font-family: Blinker;
  font-size: 13px;
  font-weight: 300;
  line-height: 18.2px;
`;

export default DesktopFilterPage;
