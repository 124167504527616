import { gql } from '@apollo/client';

export const DASHBOARD = gql`
  query DashboardOutputDto($date: String!) {
    FundsClosingSoon {
      finalClose {
        id
        name
        count
        date
      }
      nextClose {
        id
        name
        count
        date
      }
    }
    Recommendations(date: $date) {
      sentToClient
      sentToClientAtCount
      clientApproved
      clientApprovedCount
      terminated
      terminatedCount
      commitments
      commitmentsCount
    }
    FirmOverview {
      totalEntitiesValue
      targetPrivateCapitalAllocation
      privateRealAssets {
        targeted
        committed
        capacity
        openFundsCount
      }
      privateCredit {
        targeted
        committed
        capacity
        openFundsCount
      }
      privateEquity {
        targeted
        committed
        capacity
        openFundsCount
      }
      total {
        targeted
        committed
        capacity
        openFundsCount
      }
    }
  }
`;

export const DEFAULT_LAST_DAYS = 30;
