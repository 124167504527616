import { Dispatch, SetStateAction, useMemo, useState } from 'react';
import styled from 'styled-components';

import { PlusIcon } from '@/assets/icons/info-icons/plus';
import { RemoveIcon } from '@/assets/icons/info-icons/remove';
import { useResponsive } from '../../../hooks/use-responsive';
import Button from '../../basicComponents/button';
import FilterSelect from '../../basicComponents/select';
import { AdditionalFilter, AdditionalFilters } from './types';
import { addSpaceBtwCamelCases, createNewFilter } from './utils';

const operators = ['is'];

interface MoreFiltersProps extends AdditionalFilters {
  onClose: () => void;
  setChosenFilters: Dispatch<SetStateAction<boolean>>;
}

const MoreFilters = ({
  onClose,
  setChosenFilters,
  applyFilters,
  filtersItems: filtersItemsWithoutInitialValues,
  initialValueTypeFilter = 'Select A Filter Option',
  filters: globalFilters
}: MoreFiltersProps) => {
  const { isPortrait, isMobile } = useResponsive();
  const [filters, setFilters] = useState<AdditionalFilter[]>(globalFilters);
  const filtersItems = useMemo(() => {
    return { [initialValueTypeFilter]: [''], ...filtersItemsWithoutInitialValues };
  }, []);

  const filtersType = useMemo(() => {
    return Object.keys(filtersItems).map((filter) => addSpaceBtwCamelCases(filter));
  }, []);

  const changeTypeFilter = (id: string, typeFilter: string) => {
    const cleanTypeFilter = typeFilter.replace(/\s/g, '');
    const newFilters = filters.map((filter) =>
      filter.id === id && filtersItems[cleanTypeFilter]
        ? {
            ...filter,
            typeFilter: cleanTypeFilter,
            selected: filtersItems[cleanTypeFilter][0],
            listFilters: filtersItems[cleanTypeFilter]
          }
        : filter
    );
    setFilters(newFilters);
  };

  const changeAdditionalFilters = (id: string, field: string, value: string) => {
    setFilters(filters.map((filter) => (filter.id === id ? { ...filter, [field]: value } : filter)));
  };

  const addMore = () => setFilters([...filters, createNewFilter(initialValueTypeFilter)]);

  const removeFilter = (id: string) => {
    const newFilters = filters.filter((filter) => filter.id !== id);
    setFilters(filters.length <= 1 ? [createNewFilter(initialValueTypeFilter)] : newFilters);
  };

  // for disable button and control add more button
  const isFilterSelected = () => {
    const lastElement = filters[filters.length - 1];
    if (!lastElement) return true;
    const isTypeFilterSelected = lastElement.typeFilter === initialValueTypeFilter;
    const isValueSelected = lastElement.selected === filtersItems[lastElement.typeFilter][0];
    return isTypeFilterSelected || isValueSelected;
  };
  const applySelectedFilters = () => {
    applyFilters(filters);
    onClose();
    setChosenFilters(true);
  };
  return (
    <MoreFilter>
      <MoreFilterTitle>Filters</MoreFilterTitle>
      <MoreFilterBody>
        <Divider />
        {filters.map(({ id, selected, operator, typeFilter, listOfItems }, index) => (
          <MoreFilterSelectorsWrap key={id}>
            <MoreFilterSelectors isPortrait={isPortrait} isMobile={isMobile}>
              <FilterSelect
                width={isPortrait && isMobile ? '100%' : '30%'}
                data={filtersType}
                selected={addSpaceBtwCamelCases(typeFilter)}
                setSelected={(value) => changeTypeFilter(id, value)}
              />
              {initialValueTypeFilter !== typeFilter && (
                <>
                  <FilterSelect
                    disabled={operators.length <= 1}
                    width={isPortrait && isMobile ? '100%' : '30%'}
                    data={operators}
                    selected={operator}
                    setSelected={(value) => changeAdditionalFilters(id, 'operator', value)}
                  />
                  <FilterSelect
                    width={isPortrait && isMobile ? '100%' : '30%'}
                    data={filtersItems[typeFilter]}
                    selected={selected}
                    withSearch
                    setSelected={(value) => changeAdditionalFilters(id, 'selected', value)}
                  />
                  <RemoveFilter onClick={() => removeFilter(id)}>
                    <RemoveIcon />
                  </RemoveFilter>
                  {isPortrait && isMobile && <Divider />}
                </>
              )}
            </MoreFilterSelectors>
            {!isFilterSelected() && filters.length === index + 1 && (
              <MoreFilterAddWrap onClick={addMore}>
                <PlusIcon />
                <MoreFilterAdd>Add more</MoreFilterAdd>
              </MoreFilterAddWrap>
            )}
          </MoreFilterSelectorsWrap>
        ))}
        <BtnWrap>
          <CancelBtn onClick={onClose}>Cancel</CancelBtn>
          <Button disabled={isFilterSelected()} onClick={applySelectedFilters}>
            Apply Filters
          </Button>
        </BtnWrap>
      </MoreFilterBody>
    </MoreFilter>
  );
};

export default MoreFilters;

const MoreFilterSelectorsWrap = styled.div`
  display: flex;
  flex-direction: column;
`;

const MoreFilterSelectors = styled.div<{ isPortrait: boolean; isMobile: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: ${({ isPortrait, isMobile }) => (isPortrait && isMobile ? 'column' : 'row')};
  gap: 20px;
`;
const MoreFilterAddWrap = styled.div`
  width: fit-content;
  font-size: 18px;
  cursor: pointer;
  column-gap: 6px;
  display: flex;
  align-items: center;
  margin-top: 26px;
`;

const MoreFilterAdd = styled.div`
  font-family: 'Blinker';
  font-weight: 600;
  font-size: 13px;
  color: #4587ec;
`;
const MoreFilterBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const BtnWrap = styled.div`
  display: flex;
  gap: 10px;
  align-self: flex-end;
  padding-top: 20px;
  @media (max-width: 500px) {
    width: 100%;
    justify-content: space-between;
  }
`;

const BasicBtn = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Blinker', serif;
  font-weight: 600;
  font-size: 16px;
  padding: 8.5px 30px;
  border-radius: 4px;
`;
const RemoveFilter = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
`;

const CancelBtn = styled(BasicBtn)`
  border: 1px solid #4587ec;
  color: #4587ec;
`;

const Divider = styled.div`
  background: #c7cbd2;
  width: 100%;
  height: 1px;
`;

const MoreFilterTitle = styled.div`
  font-family: 'Blinker', serif;
  font-weight: 400;
  font-size: 19px;
`;
const MoreFilter = styled.div`
  display: flex;
  flex-direction: column;
  padding: 3px 20px;
  width: 100%;
  background: #fafafa;
  border-radius: 10px;
  transition: all 0.26s linear;
`;
