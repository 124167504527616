export const columns = [
  { title: 'Commitment Plan', key: 'NAME', width: 30, keySort: 'commitment plan' },
  { title: 'Plan Start', key: 'START_DATE', width: 30, keySort: 'plan start' },
  { title: 'Growth Rate Ex. Drawdowns', key: null, width: 30, keySort: 'growth rate ex. drawdowns' },
  { title: '', key: null, width: 10, keySort: '' }
];

export const mobileTableSort = [
  {
    title: 'Commitment Plan • A -> Z',
    key: 'NAME',
    asc: true
  },
  {
    title: 'Commitment Plan • Z -> A',
    key: 'NAME',
    asc: false
  },
  {
    title: 'Plan Start • Most Recent -> Oldest',
    key: 'START_DATE',
    asc: false
  },
  {
    title: 'Plan Start • Oldest -> Most Recent',
    key: 'START_DATE',
    asc: true
  }
];
