import { format } from 'date-fns';
import React from 'react';
import styled from 'styled-components';

import { NoteDataType } from './types';

interface MobileNotesProps {
  data: NoteDataType | null;
}

export const MobileNotes = ({ data }: MobileNotesProps) => {
  if (!data) return <></>;

  return (
    <NotesBody id="notes">
      <NoteDetails>
        <NoteTask>Task: {data.task}</NoteTask>
        <NoteTask>{`Assigned To ${data.assignedTo?.firstName + ' ' + data?.assignedTo?.lastName} by ${
          data.assignedBy?.firstName + ' ' + data.assignedBy?.lastName
        }`}</NoteTask>
        <NoteUpdateAt>{data.updateAt ? format(new Date(data.updateAt), 'LLL d, yyyy') : 'No date'}</NoteUpdateAt>
      </NoteDetails>
      <NoteText>{data.note}</NoteText>
    </NotesBody>
  );
};

const NoteDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const NoteTask = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  color: #373f4e;
`;

const NoteUpdateAt = styled.div`
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  color: #7f7f7f;
`;

const NoteText = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #373f4e;
  padding: 15px 0;
  border-bottom: 1px solid #c7cbd2;
`;

const NotesBody = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
`;
