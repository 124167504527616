import { format } from 'date-fns';
import styled from 'styled-components';

import { useResponsive } from '../../hooks/use-responsive';
import BasicTooltip from '../basicComponents/tooltip';
import { formatByType } from '../table/anchor-drop-down/infoList/formatSettings';
import SectorsToAvoid from '../table/anchor-drop-down/infoList/sectorsToAvoid';
import { InfoListRow } from '../table/anchor-drop-down/infoList/types';
import { IRowData } from './types';

interface InfoListProps {
  row: IRowData;
}

export const InvestmentInfoList = ({ row }: InfoListProps) => {
  const { investment } = {
    investment: row.investment
  };

  const data: InfoListRow[] = [
    {
      name: 'Manager',
      formatType: 'string',
      value: investment.managerId
    },
    {
      name: 'Asset Class',
      formatType: 'string',
      value: investment.assetClass
    },
    {
      name: 'Next Close',
      formatType: 'date',
      value: format(new Date(investment.nextClose), 'LLL d, yyyy')
    },
    {
      name: 'Final Close',
      formatType: 'date',
      value: format(new Date(investment.finalClose), 'LLL d, yyyy')
    },
    {
      name: 'Minimum',
      formatType: 'number',
      value: investment.minimum
    },
    {
      name: 'UBTI Blocker',
      formatType: 'yesNo',
      value: investment.ubtiBlocker
    },
    {
      name: 'SRI',
      formatType: 'yesNo',
      value: investment.sri
    },
    {
      name: 'Target IRR',
      formatType: 'percentage',
      value: investment.targetIRR
    },
    {
      name: 'Target Yield',
      formatType: 'percentage',
      value: investment.targetYield
    }
  ];

  const checkISArrayFilled = (array: string[]) => !!array.filter((item) => item).length;
  const { isMobile } = useResponsive();

  const cleanData = () =>
    data.filter(({ value }) => {
      if (Array.isArray(value)) return checkISArrayFilled(value);
      return value !== null && value !== undefined;
    });

  return (
    <MoreDataWrap>
      {cleanData().map(({ name, tooltipName, value, formatType }) => (
        <MoreDataItem key={name}>
          <MoreDataItemTitle isMobile={isMobile}>
            <p>{name}</p>
            {tooltipName && <BasicTooltip tooltipContent={tooltipName} withTooltipIcon />}
          </MoreDataItemTitle>
          {Array.isArray(value) ? (
            <SectorsToAvoid list={value} />
          ) : (
            <MoreDataItemValue isMobile={isMobile}>{formatByType[formatType](value as never)}</MoreDataItemValue>
          )}
        </MoreDataItem>
      ))}
    </MoreDataWrap>
  );
};

const MoreDataItemValue = styled.div<{ isMobile?: boolean }>`
  font-family: Blinker, serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0;
  text-align: left;
  width: ${({ isMobile }) => (isMobile ? '50%' : '180px')};
  color: ${({ theme }) => theme.font.base};
`;

const MoreDataItemTitle = styled.div<{ isMobile?: boolean }>`
  display: flex;
  align-items: center;
  gap: 5px;
  font-family: Blinker, serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0;
  text-align: left;
  width: ${({ isMobile }) => (isMobile ? '50%' : '150px')};
  min-width: ${({ isMobile }) => (isMobile ? '50%' : '150px')};
  color: ${({ theme }) => theme.font.base};
`;

const MoreDataItem = styled.div`
  display: flex;
  align-items: center;
  padding: 5px 20px;
  background-color: ${({ theme }) => theme.layer[1]};
  gap: 10px;
  border-radius: 4px;
`;

const MoreDataWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3px;
`;
