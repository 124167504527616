import guid from '../../../utils/guid';

export const createNewFilter = (initialValueTypeFilter: string, selected?: string) => ({
  id: guid(),
  typeFilter: initialValueTypeFilter,
  operator: 'is',
  selected: selected ?? '',
  listOfItems: [] as any[]
});

export const addSpaceBtwCamelCases = (string: string) => string.replace(/([A-Z])/g, ' $1').trim();

export const buildQueryString = (queryParams?: object) => {
  let queryString = '?';
  if (queryParams) {
    for (const [key, value] of Object.entries(queryParams)) {
      queryString += `${key}=${value}&`;
    }
    queryString = queryString.slice(0, -1);
  } else {
    queryString = '';
  }
  return queryString;
};
