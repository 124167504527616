import {
  MobileRow,
  MobileRowTitle,
  MobileRowValue,
  MobileTableRowWrap,
  MobileTableWrapper,
  NoResults
} from '@/components/table/mobileTable/mobileTable';
import styled from 'styled-components';

import FormattingTooltip from '@/components/basicComponents/tooltip/dollarsTooltip';
import { TextCell } from '@/components/table/styles';
import { TableColumns } from '@/components/table/types';
import { formatCash } from '@/utils/formatCash';
import guid from '@/utils/guid';
import { IRowData } from '../../types';

type MobileTableProps = {
  columns: TableColumns[];
  clientsRows: IRowData[];
  handleFamilyClick: (id: string) => void;
  onRowClick: (data: IRowData) => void;
};

const MobileTableClient = ({ columns, clientsRows, handleFamilyClick, onRowClick }: MobileTableProps) => {
  const TableRow = ({ el }: { el: IRowData }) => {
    return (
      <MobileTableRowWrap key={guid()}>
        <MobileRow>
          <MobileRowTitle>{columns[0].title}</MobileRowTitle>
          <WrapperClient onClick={() => handleFamilyClick(el.name)} isRedirect>
            <ClientText>{el.name}</ClientText>
            <TextCell>{el.advisor?.initials ?? ''}</TextCell>
          </WrapperClient>
        </MobileRow>

        <MobileRow>
          <MobileRowTitle>{columns[1].title}</MobileRowTitle>
          <MobileRowValue isRedirect onClick={() => onRowClick(el)}>
            {formatCash(+el.totalClientValue)}
          </MobileRowValue>
        </MobileRow>

        <MobileRow>
          <MobileRowTitle>{columns[2].title}</MobileRowTitle>
          <MobileRowValue>
            <FormattingTooltip>{+el.privateEquityCapacity}</FormattingTooltip>
          </MobileRowValue>
        </MobileRow>

        <MobileRow>
          <MobileRowTitle>{columns[3].title}</MobileRowTitle>
          <MobileRowValue>
            <FormattingTooltip>{+el.privateCreditCapacity}</FormattingTooltip>
          </MobileRowValue>
        </MobileRow>

        <MobileRow>
          <MobileRowTitle>{columns[4].title}</MobileRowTitle>
          <MobileRowValue>
            <FormattingTooltip>{+el.privateRealAssetsCapacity}</FormattingTooltip>
          </MobileRowValue>
        </MobileRow>

        <MobileRow>
          <MobileRowTitle>{columns[5].title}</MobileRowTitle>
          <MobileRowValue>
            <FormattingTooltip>{+el.privateOverallCapacity}</FormattingTooltip>
          </MobileRowValue>
        </MobileRow>
      </MobileTableRowWrap>
    );
  };

  return (
    <MobileTableWrapper>
      {clientsRows.length ? (
        clientsRows.map((el, index) => {
          return <TableRow el={el} key={el.name + index} />;
        })
      ) : (
        <NoResults>No Results</NoResults>
      )}
    </MobileTableWrapper>
  );
};

export default MobileTableClient;

const WrapperClient = styled(MobileRowValue)`
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

const ClientText = styled.span`
  :hover {
    cursor: pointer;
  }
`;
