import { DealCatalog } from '@/components/fat-dealCatalog-page';
import { Helmet as Head } from 'react-helmet';
import useRefresh from '../../hooks/use-refresh';

const DealCatalogPage = () => {
  useRefresh();
  return (
    <>
      <Head>
        <title>CURIO Select</title>
      </Head>
      <DealCatalog />
    </>
  );
};

export default DealCatalogPage;
