import { Investors } from '@/components/fat-investors-page';
import { Helmet as Head } from 'react-helmet';
import useRefresh from '../../hooks/use-refresh';

const InvestorsPage = () => {
  useRefresh();
  return (
    <>
      <Head>
        <title>Investors</title>
      </Head>
      <Investors />
    </>
  );
};

export default InvestorsPage;
