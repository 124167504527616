import { CloseInfoIcon } from '@/assets/icons/info-icons/closeInfo';
import RadioButton from '@/components/basicComponents/radioButton';
import FilterSelect from '@/components/basicComponents/select';
import Label from '@/components/fat-basicComponents/label';
import { AddButton } from '@/components/fat-dealPipeline-page/fat-modify/addButton';
import { useResponsive } from '@/hooks/use-responsive';
import { useQuery } from '@apollo/client';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Control, Controller, UseFormSetValue, useWatch } from 'react-hook-form';
import styled, { useTheme } from 'styled-components';
import { TwoColumnEntityWrapper } from '..';
import { LabelWrap } from '../../fat-investorDetails-page/fat-entityDetails-page/edit-entity/input';
import { Section } from '../../fat-investorDetails-page/fat-entityDetails-page/edit-entity/section';
import { LIST_SECTORS_TO_AVOID_QUERY } from '../../fat-investorDetails-page/fat-entityDetails-page/queries';
import { CreateInvestorDto } from '../types';

const onHoldOptions = ['No', 'Yes'];

interface SetAllocationRestrictionsProps {
  control: Control<CreateInvestorDto, any>;
  isSectionOpen: boolean;
  saveSectionState: (id: string, state: { isSectionOpen: boolean }) => void;
  setValue: UseFormSetValue<CreateInvestorDto>;
}

export const SetAllocationRestrictions = ({ control, isSectionOpen, saveSectionState, setValue }: SetAllocationRestrictionsProps) => {
  const { isMobile } = useResponsive();
  const theme = useTheme();
  const sectorsToAvoidValue = useWatch({
    control,
    name: 'sectorsToAvoid'
  });

  const [strategiesList, setStrategiesList] = useState<string[]>(['None']);

  const { data: listSectorsToAvoid } = useQuery<{ listSectorsToAvoid: string[] }>(LIST_SECTORS_TO_AVOID_QUERY, {
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      setStrategiesList(data.listSectorsToAvoid);
    },
    onError: (error) => {
      console.error(error);
    }
  });

  useEffect(() => {
    if (listSectorsToAvoid?.listSectorsToAvoid?.length && sectorsToAvoidValue.length) {
      setStrategiesList(listSectorsToAvoid.listSectorsToAvoid.filter((strategies) => !sectorsToAvoidValue.includes(strategies)));
    }
  }, [listSectorsToAvoid, sectorsToAvoidValue]);

  const addStrategies = (chosen: string[]) => {
    const newStrategies = [...chosen];
    newStrategies.push('None');
    setValue('sectorsToAvoid', newStrategies);
  };

  const delStrategies = (el: string, index: number, chosen: string[], list: string[], setList: Dispatch<SetStateAction<string[]>>) => {
    const newStrategies = [...chosen];
    newStrategies.splice(index, 1);
    setValue('sectorsToAvoid', newStrategies);
    if (el !== 'None') {
      setList([...list, el]);
    }
  };

  const onChangeStrategies = (value: string, index: number, chosen: string[], list: string[], setList: Dispatch<SetStateAction<string[]>>) => {
    const newStrategies = [...chosen];
    newStrategies[index] = value;
    setValue('sectorsToAvoid', newStrategies);
    const filteredList = list.filter((item) => item !== value);
    if (chosen[index] !== 'None') {
      setList([chosen[index], ...filteredList]);
      return;
    }
    setList(filteredList);
  };

  if (!listSectorsToAvoid?.listSectorsToAvoid?.length) return;

  return (
    <Section
      title="Allocation Restrictions"
      id="allocationRestrictionsSection"
      openSection={isSectionOpen}
      saveSectionState={saveSectionState}
      collapsable
    >
      <TwoColumnEntityWrapper isMobile={isMobile}>
        <div>
          <Label size="md" tooltip="Flag this entity for no new commitments.">
            On Hold
          </Label>
          <Controller
            name="onHold"
            control={control}
            render={({ field: { value, onChange } }) => (
              <TogglesWrap isMobile={isMobile}>
                {onHoldOptions.map((option, index) => (
                  <RadioButton
                    key={option + index}
                    name="onHold"
                    radioButtonText={option}
                    selectedOption={value ? onHoldOptions[1] : onHoldOptions[0]}
                    handleRadioChange={(event) => onChange(event.target.value === onHoldOptions[1])}
                  />
                ))}
              </TogglesWrap>
            )}
          />
        </div>
        <StrategiesWrap>
          <LabelWrap>
            <Label size="md" tooltip="Add any Sectors that should be flagged as unsuitable for this entity.">
              Excluded Sectors
            </Label>
          </LabelWrap>
          {sectorsToAvoidValue.map((el, index) => (
            <StrategiesItemWrap key={`strategies-${index}`}>
              <StrategiesFilterSelect
                data={strategiesList}
                selected={sectorsToAvoidValue[index]}
                setSelected={(value: string) => onChangeStrategies(value, index, sectorsToAvoidValue, strategiesList, setStrategiesList)}
                disabled={sectorsToAvoidValue[sectorsToAvoidValue.length - 1] !== el}
                width="100%"
                withSearch
                fontSize="16px"
              />
              <DelItemWrap onClick={() => delStrategies(el, index, sectorsToAvoidValue, strategiesList, setStrategiesList)}>
                <CloseInfoIcon width={21} height={20} fill={theme.font.action} />
              </DelItemWrap>
            </StrategiesItemWrap>
          ))}
          {strategiesList.length && sectorsToAvoidValue[sectorsToAvoidValue.length - 1] !== 'None' ? (
            <AddButton text="Add" onClick={() => addStrategies(sectorsToAvoidValue)} />
          ) : (
            <></>
          )}
        </StrategiesWrap>
      </TwoColumnEntityWrapper>
    </Section>
  );
};

const TogglesWrap = styled.div<{ isMobile: boolean }>`
  display: flex;
  flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};
  gap: ${({ isMobile }) => (isMobile ? '15px' : '0')};
`;

const StrategiesWrap = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

const StrategiesFilterSelect = styled(FilterSelect)`
  background: ${({ disabled, theme }) => (disabled ? theme.action.disabled : theme.layer[2])};
  border: 1px solid ${({ theme }) => theme.border.base};
  padding: 10px 12px;
  border-radius: 5px;

  #titleSelect {
    text-transform: none;
    font-family: Blinker;
    font-size: 19px;
    line-height: 26.6px;
  }

  #dropDownSelect {
    top: 50px;
  }
`;

const StrategiesItemWrap = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  margin-bottom: 10px;
  position: relative;
`;

const DelItemWrap = styled.div`
  position: relative;
  top: 3px;
  padding-left: 15px;
  cursor: pointer;
`;
