import { Loader } from '@/components/loaders/loader';
import TableHeader from '@/components/table/fat-tableHeader';
import { TableBodyWrapper, TableWrapper } from '@/components/table/styles';
import { ISort } from '@/components/table/types';
import { useResponsive } from '@/hooks/use-responsive';
import useStickyFooter from '@/hooks/useStickyFooter';
import { useRef } from 'react';
import styled from 'styled-components';
import { investmentColumns } from '../constatnts';
import { IManagerInvestment } from '../types';
import MobileTable from './mobileTable';
import TableBody from './tableBody';
import TableSumFooter from './tableSumFooter';

interface TableProps {
  tableData: IManagerInvestment[];
  sort: ISort;
  setSort: (value: ISort) => void;
  refetch: () => void;
  loading: boolean;
  openInvestmentDetails: (id: string) => void;
}

const InvestmentTable = ({ tableData, sort, setSort, refetch, loading, openInvestmentDetails }: TableProps) => {
  const { isMobile, isTablet } = useResponsive();

  const containerRef = useRef<HTMLDivElement | null>(null);
  const footerRef = useRef<HTMLDivElement | null>(null);

  useStickyFooter(containerRef, footerRef);

  return (
    <TableWrapper padding="0">
      {isMobile ? (
        <>
          {loading ? (
            <Loader />
          ) : (
            <MobileTable columns={investmentColumns} tableData={tableData} openInvestmentDetails={openInvestmentDetails} loading={loading} />
          )}
        </>
      ) : (
        <>
          <CustomTableHeader isTablet={isTablet} refetch={refetch} columns={investmentColumns} savedSort={sort} savedSetSort={setSort} />
          <TableBodyWrapper>
            {loading ? (
              <Loader />
            ) : (
              <>
                <TableBody tableData={tableData} openInvestmentDetails={openInvestmentDetails} />
                <TableSumFooterWrapper ref={footerRef}>{<TableSumFooter tableData={tableData} />}</TableSumFooterWrapper>
                <div ref={containerRef}></div>
              </>
            )}
          </TableBodyWrapper>
        </>
      )}
    </TableWrapper>
  );
};

export default InvestmentTable;

const CustomTableHeader = styled(TableHeader)<{ isTablet: boolean }>`
  position: sticky;
  top: ${({ isTablet }) => (isTablet ? '130px' : '80px')};
  z-index: 8;
  padding-top: 0;
`;

const TableSumFooterWrapper = styled.div`
  &.sticky {
    position: sticky;
    bottom: 0;
    z-index: 1;
  }
`;
