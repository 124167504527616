import { ApprovedIcon } from '@/assets/icons/info-icons/approved';
import { RedStarIcon } from '@/assets/icons/info-icons/redStar';
import { getUTCDate } from '@/components/allocations-page/utils';
import FormattingTooltip from '@/components/basicComponents/tooltip/dollarsTooltip';
import NoResults from '@/components/table/noResults';
import { TableRowCell, TableRowWrapper, TextCell } from '@/components/table/styles';
import guid from '@/utils/guid';
import styled, { useTheme } from 'styled-components';
import { columns } from '../constants';

interface TableBodyProps {
  tableData: any;
}

const TableBody = ({ tableData }: TableBodyProps) => {
  const theme = useTheme();

  if (!tableData?.length) {
    return <NoResults />;
  }

  const getRowStatus = (status: string) => {
    switch (status) {
      case 'Missing Commitment':
      case 'Unrecognized Transaction Type':
        return (
          <RequiredStatusCell>
            <div style={{ padding: '5px' }}>
              <RedStarIcon width={9} height={11} fill={theme.context.error} />
            </div>
            {status}
          </RequiredStatusCell>
        );
      case 'Validated':
        return (
          <CompleteStatusCell>
            <ApprovedIcon width={23} height={23} fill={theme.context.success} />
            Validated
          </CompleteStatusCell>
        );
      default:
        return <></>;
    }
  };

  return (
    <>
      {tableData.map((row: any) => (
        <TableRowWrapper key={guid()}>
          <TableRowCell width={columns[0].width}>
            <TextCell>{row.entityName}</TextCell>
          </TableRowCell>
          <TableRowCell width={columns[1].width}>
            <TextCell>{row.entityCode}</TextCell>
          </TableRowCell>
          <TableRowCell width={columns[2].width}>
            <TextCell>{row.seriesName}</TextCell>
          </TableRowCell>
          <TableRowCell width={columns[3].width}>
            <TextCell>{row.investmentCode}</TextCell>
          </TableRowCell>
          <TableRowCell width={columns[4].width}>
            <TextCell>{row.transactionType}</TextCell>
          </TableRowCell>
          <TableRowCell width={columns[5].width}>
            <TextCell>{row.tradeDate && getUTCDate(new Date(row.tradeDate))}</TextCell>
          </TableRowCell>
          <TableRowCell width={columns[6].width}>
            <TextCell>
              <FormattingTooltip zIndex={1000}>{row.netAmount}</FormattingTooltip>
            </TextCell>
          </TableRowCell>
          <TableRowCell width={columns[7].width}>
            <TextCell>
              <FormattingTooltip zIndex={1000}>{row.totalAmount}</FormattingTooltip>
            </TextCell>
          </TableRowCell>
          <TableRowCell width={columns[8].width}>
            <TextCell>{getRowStatus(row.commitmentStatus)}</TextCell>
          </TableRowCell>
        </TableRowWrapper>
      ))}
    </>
  );
};

export default TableBody;

const StatusCell = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
  line-height: 22.4px;
`;

const RequiredStatusCell = styled(StatusCell)`
  color: ${({ theme }) => theme.context.error};
`;

const CompleteStatusCell = styled(StatusCell)`
  color: ${({ theme }) => theme.context.success};
`;
