import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';

import ModalWrappedContent from '@/components/fat-basicComponents/modal/modalWrappedContent';
import { User } from '../../../types/user';
import FilterSelect from '../../basicComponents/select';
import { ASSIGNEE_QUERY } from '../queries';
import { IRowData } from '../types';

interface AssignTaskProps {
  onClose: () => void;
  updateAllocationAssignee: (variables: any) => void;
  isOpen: boolean;
  allocation: IRowData | null;
  refetch: any;
}

const AssignTask = ({ isOpen, onClose, updateAllocationAssignee, allocation, refetch }: AssignTaskProps) => {
  if (!allocation) return <></>;
  const { data } = useQuery(ASSIGNEE_QUERY, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'no-cache',
    variables: {
      id: allocation.id
    }
  });
  const [assignees, setAssignees] = useState([]);
  const [select, setSelect] = useState(`${allocation?.assignee?.firstName ?? ''} ${allocation?.assignee?.lastName ?? ''}`);
  const assignTask = () => {
    const newAssignee = data.AllocationAssignees.find((assignee: User) => `${assignee.firstName} ${assignee.lastName}` === select);
    updateAllocationAssignee({
      variables: {
        data: {
          id: allocation.id,
          assigneeUserId: newAssignee.id
        }
      }
    });
    onClose();
  };
  useEffect(() => {
    if (data?.AllocationAssignees) {
      setAssignees(data.AllocationAssignees.map((user: User) => `${user.firstName} ${user.lastName}`));
    }
  }, [data]);

  return (
    <ModalWrappedContent
      isOpen={isOpen}
      onClose={onClose}
      title={'Assign Task'}
      confirmButton={{ name: 'Assign', onClick: assignTask, disabled: !assignees?.length }}
      showRequiredFields
    >
      <FilterSelect
        width={'auto'}
        label={'Select An Assignee'}
        selected={select}
        disabled={!assignees?.length}
        setSelected={(value) => setSelect(value)}
        data={assignees || []}
        required
        minHeight="37px"
      />
    </ModalWrappedContent>
  );
};

export default AssignTask;
