export const basicInformationItems = [
  { fieldName: 'Firm Name', key: 'firmName' },
  { fieldName: 'Logo', key: 'logo' },
  { fieldName: 'Website', key: 'website' },
  { fieldName: 'Address', key: 'address' },
  { fieldName: 'Phone', key: 'phone' },
  { fieldName: 'Email', key: 'email' },
];

export const templateStylingItems = [
  { fieldName: 'Header Font Style', key: 'headerFontStyle' },
  { fieldName: 'Body Font Style', key: 'bodyFontStyle' },
  { fieldName: 'Disclosure Text', key: 'disclosureText' },
  { fieldName: 'Watermark', key: 'watermark' },
];

export const fontSelect = {
  Roboto: ['100', '300', '400', '500', '700', '900'],
  'Roboto Slab': ['100', '200', '300', '400', '500', '600', '700', '800', '900'],
  'Open Sans': ['300', '400', '500', '600', '700', '800'],
  Montserrat: ['100', '200', '300', '400', '500', '600', '700', '800', '900'],
  Lato: ['100', '300', '400', '700', '900'],
  Raleway: ['100', '200', '300', '400', '500', '600', '700', '800', '900'],
  Pacifico: ['400'],
  'Playfair Display': ['400', '500', '600', '700', '800', '900'],
  Oswald: ['200', '300', '400', '500', '600', '700'],
  Poppins: ['100', '200', '300', '400', '500', '600', '700', '800', '900'],
  Sanchez: ['400'],
};

export const fileExtensions = ['JPEG', 'PNG', 'GIF', 'JPG'];
