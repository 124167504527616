import { format } from 'date-fns';
import styled from 'styled-components';

import { MoreInfoAllocationStatus } from './types';

interface InfoListProps {
  data: MoreInfoAllocationStatus;
  allocationStatus: string;
}

export const ComplianceInfoList = ({ data, allocationStatus }: InfoListProps) => {
  const getBoolean = (value: boolean) => (value ? 'YES' : 'NO');
  const dateFormat = (date: Date) => {
    try {
      return format(new Date(date), 'PP').toUpperCase();
    } catch (e) {
      return 'No date';
    }
  };
  return (
    <MoreDataWrap id="modal">
      {data.statusDate ? (
        <MoreDataItem id="modal">
          <MoreDataItemTitle id="modal">Status Date</MoreDataItemTitle>
          <MoreDataItemValue id="modal">{dateFormat(data.statusDate)}</MoreDataItemValue>
        </MoreDataItem>
      ) : null}

      <MoreDataItem id="modal">
        <MoreDataItemTitle id="modal">Internal Decison</MoreDataItemTitle>
        <MoreDataItemValue id="modal">{getBoolean(data.decision)}</MoreDataItemValue>
      </MoreDataItem>

      {data.internalDecision ? (
        <MoreDataItem id="modal">
          <MoreDataItemTitle id="modal">Internal Decison Date</MoreDataItemTitle>
          <MoreDataItemValue id="modal">{dateFormat(data.internalDecision)}</MoreDataItemValue>
        </MoreDataItem>
      ) : null}

      {data.reason ? (
        <MoreDataItem id="modal">
          <MoreDataItemTitle id="modal">Reason</MoreDataItemTitle>
          <MoreDataItemValue id="modal">{data.reason}</MoreDataItemValue>
        </MoreDataItem>
      ) : null}

      {data.suitability ? (
        <MoreDataItem id="modal">
          <MoreDataItemTitle id="modal">Suitability</MoreDataItemTitle>
          <MoreDataItemValue id="modal">{data.suitability}</MoreDataItemValue>
        </MoreDataItem>
      ) : null}

      {data.complianceOfficer ? (
        <MoreDataItem id="modal">
          <MoreDataItemTitle id="modal">Internal Decision By</MoreDataItemTitle>
          <MoreDataItemValue id="modal">{data.complianceOfficer}</MoreDataItemValue>
        </MoreDataItem>
      ) : null}

      {data.lastUpdate && allocationStatus !== 'Committed' ? (
        <MoreDataItem id="modal">
          <MoreDataItemTitle id="modal">Last Update</MoreDataItemTitle>
          <MoreDataItemValue id="modal">{data.lastUpdate}</MoreDataItemValue>
        </MoreDataItem>
      ) : null}

      {allocationStatus !== 'Internal Review Rejected' && allocationStatus !== 'Committed' ? (
        <MoreDataItem id="modal">
          <MoreDataItemTitle id="modal">Terminated By</MoreDataItemTitle>
          <MoreDataItemValue id="modal">{data.terminateBy}</MoreDataItemValue>
        </MoreDataItem>
      ) : null}

      {data.terminationReason ? (
        <MoreDataItem id="modal">
          <MoreDataItemTitle id="modal">Terminated Reason</MoreDataItemTitle>
          <MoreDataItemValue id="modal">{data.terminationReason}</MoreDataItemValue>
        </MoreDataItem>
      ) : null}

      {data.terminationNote ? (
        <MoreDataItem
          id="modal"
          style={{
            display: 'flex',
            alignItems: 'flex-start',
            flexDirection: 'column',
            paddingTop: 5
          }}
        >
          <MoreDataItemTitle id="modal">Terminated Notes</MoreDataItemTitle>
          <NoteData>{data.terminationNote}</NoteData>
        </MoreDataItem>
      ) : null}
    </MoreDataWrap>
  );
};

const MoreDataItemValue = styled.div`
  font-family: 'Blinker', serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0;
  text-align: left;
  width: 200px;
  color: ${({ theme }) => theme.font.base};
`;

const MoreDataItemTitle = styled.div`
  font-family: 'Blinker', serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0;
  text-align: left;
  width: 150px;
  min-width: 150px;
  color: ${({ theme }) => theme.font.base};
`;

const MoreDataItem = styled.div`
  display: flex;
  align-items: center;
  padding: 5px 20px;
  background-color: ${({ theme }) => theme.layer[1]};
  gap: 10px;
  border-radius: 4px;
`;

const NoteData = styled.div`
  font-family: 'Blinker', serif;
  font-size: 13px;
  font-weight: 400;
  line-height: 28px;
  color: ${({ theme }) => theme.font.base};
`;

const MoreDataWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3px;
`;
