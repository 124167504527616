import * as React from 'react';

export const ScoreArrow = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={props.width || 34} height={props.height || 34} style={props?.style} viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.33002 16.99C3.33002 14.3512 4.11266 11.7716 5.57892 9.57766C7.04518 7.38369 9.12917 5.67386 11.5673 4.66448C14.0055 3.6551 16.6882 3.39153 19.2762 3.90707C21.8642 4.4226 24.2411 5.69412 26.1064 7.56075C27.9716 9.42739 29.2413 11.8053 29.7549 14.3936C30.2685 16.982 30.0029 19.6645 28.9917 22.1019C27.9805 24.5393 26.2691 26.622 24.074 28.0867C21.879 29.5513 19.2988 30.332 16.66 30.33C13.1246 30.3247 9.73561 28.9172 7.23661 26.4163C4.7376 23.9155 3.33266 20.5255 3.33002 16.99ZM2.26987e-06 16.99C-0.00197532 20.2879 0.974277 23.5122 2.80524 26.2551C4.6362 28.998 7.23962 31.1362 10.2861 32.3992C13.3325 33.6621 16.6851 33.9931 19.9197 33.3502C23.1543 32.7073 26.1255 31.1194 28.4575 28.7875C30.7894 26.4555 32.3773 23.4843 33.0202 20.2497C33.6631 17.0151 33.3321 13.6625 32.0692 10.6161C30.8062 7.5696 28.668 4.96621 25.9251 3.13525C23.1822 1.30429 19.9579 0.328036 16.66 0.330013C12.2423 0.33266 8.00632 2.08874 4.88254 5.21252C1.75876 8.3363 0.00265137 12.5723 2.26987e-06 16.99ZM16.66 15.33L10 15.33L10 18.66L16.66 18.66L16.66 23.66L23.33 16.99L16.66 10.33L16.66 15.33Z"
      fill={props.fill || '#A4A9B1'}
    />
  </svg>
);
