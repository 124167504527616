import React from 'react';
import styled from 'styled-components';

import { RedStarIcon } from '@/assets/icons/info-icons/redStar';
import Button from '../button';
import Modal from './index';

interface ModalWrappedContentProps {
  children: React.ReactNode;
  isOpen: boolean;
  onClose: () => void;
  title?: string;
  showRequiredFields?: boolean;
  background?: boolean;
  confirmButton?: {
    name: string;
    onClick: () => void;
    disabled?: boolean;
    bgColor?: string;
  };
  width?: string;
  isUseOutsideClick?: boolean;
  showCloseIcon?: boolean;
  showCancelButton?: boolean;
}

const ModalWrappedContent = ({
  children,
  isOpen,
  onClose,
  title,
  confirmButton,
  showRequiredFields,
  background = false,
  width,
  isUseOutsideClick = true,
  showCloseIcon = true,
  showCancelButton = true
}: ModalWrappedContentProps) => {
  return (
    <Modal
      showCloseIcon={showCloseIcon}
      isOpen={isOpen}
      onClose={onClose}
      background={background}
      width={width}
      isUseOutsideClick={isUseOutsideClick}
    >
      <Container>
        {title && <Title>{title}</Title>}
        {children}
        <Actions>
          {showCancelButton ? (
            <CancelButton onClick={onClose}>Cancel</CancelButton>
          ) : null}
          {confirmButton ? (
            <Button
              onClick={confirmButton.onClick}
              disabled={confirmButton?.disabled}
              bgColor={confirmButton?.bgColor}
            >
              {confirmButton.name}
            </Button>
          ) : null}
        </Actions>
        {showRequiredFields && (
          <Warning>
            <RedStarIcon width={8} />
            Required Field
          </Warning>
        )}
      </Container>
    </Modal>
  );
};

export default ModalWrappedContent;
const Container = styled.div`
  width: 100%;
`;
const Title = styled.h1`
  font-family: 'Blinker', serif;
  font-weight: 600;
  font-size: 33px;
  text-align: center;
  color: #2e2e2e;
  padding-bottom: 35px;
`;
const Actions = styled.div`
  display: flex;
  justify-content: center;
  align-items: stretch;
  column-gap: 10px;
  padding: 30px 0 15px;
`;
const CancelButton = styled(Button)`
  background: white;
  border: 1px solid #4587ec;
  color: #4587ec;
`;
const Warning = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 6px;
  font-family: 'Blinker', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  color: #d63b4b;
`;
