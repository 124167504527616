import { ApprovedIcon } from '@/assets/icons/info-icons/approved';
import { RedStarIcon } from '@/assets/icons/info-icons/redStar';
import { useTheme } from 'styled-components';
import { StatusCell } from '../styles';

export const InvestorEntitiesRowStatus = ({ status }: { status: string }) => {
  const theme = useTheme();

  switch (status) {
    case 'error':
      return (
        <StatusCell color={theme.context.error}>
          <div style={{ padding: '5px' }}>
            <RedStarIcon width={9} height={11} fill={theme.context.error} />
          </div>
          Error
        </StatusCell>
      );
    case 'updated':
      return (
        <StatusCell color={theme.context.success}>
          <ApprovedIcon width={23} height={23} fill={theme.context.success} />
          Updated
        </StatusCell>
      );
    case 'new':
      return (
        <StatusCell color={theme.context.success}>
          <ApprovedIcon width={23} height={23} fill={theme.context.success} />
          New
        </StatusCell>
      );
    case 'toDelete':
      return (
        <StatusCell color={theme.context.success}>
          <ApprovedIcon width={23} height={23} fill={theme.context.success} />
          To Delete
        </StatusCell>
      );
    case 'unchanged':
      return (
        <StatusCell color={theme.font.weak}>
          <svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" viewBox="0 0 19 18" fill="none">
            <path
              d="M4.6697 8.09838V9.89623H13.6697V8.09838M9.17239 1.61912e-06C7.3919 -0.00106614 5.6511 0.526004 4.17023 1.51453C2.68936 2.50305 1.53497 3.90861 0.853113 5.55337C0.171256 7.19812 -0.00742909 9.00816 0.339666 10.7545C0.68676 12.5008 1.54404 14.105 2.80304 15.364C4.06203 16.623 5.66617 17.4802 7.41251 17.8273C9.15884 18.1744 10.9689 17.9957 12.6136 17.3139C14.2584 16.632 15.6639 15.4776 16.6525 13.9968C17.641 12.5159 18.1681 10.7751 18.167 8.9946C18.1656 6.60952 17.2175 4.32255 15.5309 2.63604C13.8444 0.949539 11.5575 0.00143195 9.17239 1.61912e-06ZM9.17239 16.1968C7.74771 16.1978 6.35472 15.7763 5.16962 14.9856C3.98452 14.1949 3.06056 13.0704 2.51462 11.7545C1.96868 10.4386 1.82528 8.99029 2.10257 7.59285C2.37985 6.19542 3.06536 4.9116 4.07239 3.90382C5.07941 2.89604 6.3627 2.20958 7.75993 1.93124C9.15716 1.65291 10.6056 1.79521 11.9219 2.34016C13.2382 2.88512 14.3634 3.80824 15.155 4.99275C15.9466 6.17725 16.3692 7.56992 16.3692 8.9946C16.3663 10.9029 15.6073 12.7324 14.2584 14.0822C12.9096 15.4321 11.0807 16.1925 9.17239 16.1968Z"
              fill={theme.font.weak}
            />
          </svg>
          No Change
        </StatusCell>
      );
    default:
      return <></>;
  }
};
