import { gql } from '@apollo/client';
import { OwnersFragment } from '../allocations-page/fragments';

export const updateAllocationAmountMutation = gql`
  mutation updateAllocationAmount($data: AllocationAmountUpdateDto!) {
    updateAllocationAmount(data: $data) {
      id
      allocationStatus
      committedCapital
      lastUpdatedAt
      operationsStatus
      investmentApprovedAt
      advisorApprovedAt
      complianceApprovedAt
      suitability
      sentToClientAt
      clientApprovedAt
      subdocsGeneratedAt
      subdocsReviewedAt
      subdocsSentToClientAt
      subdocsSignedByClientAt
      subdocsSentToManagerAt
      subdocsCompletedAt
      commitmentSubmittedToReportingSystemAt
      commitmentRecordedInReportingSystemAt
      terminatedAt
      completedAt
      proposalType
      selectedGroupLegalEntity {
        id
        entityName
      }
      assignee {
        id
        email
        firstName
        lastName
      }
      investmentOwner {
        id
        firstName
        lastName
      }
      advisorOwner {
        id
        firstName
        lastName
      }
      complianceOwner {
        id
        firstName
        lastName
      }
      operationsOwner {
        id
        firstName
        lastName
      }
    }
  }
`;

export const ASSIGNEE_QUERY = gql`
  ${OwnersFragment}
  query ($id: String!) {
    AllocationAssignees(id: $id) {
      ...OwnersFragment
    }
  }
`;
export const INVESTMENTS_QUERY = gql`
  query RecommendationInvestmentsList {
    RecommendationInvestmentsList {
      id
      name
    }
  }
`;

export const updateAllocationAssigneeMutation = gql`
  mutation updateAllocationAssignee($data: AllocationAssigneeUpdateDto!) {
    updateAllocationAssignee(data: $data) {
      id
      allocationStatus
      committedCapital
      lastUpdatedAt
      operationsStatus
      investmentApprovedAt
      advisorApprovedAt
      complianceApprovedAt
      suitability
      sentToClientAt
      clientApprovedAt
      subdocsGeneratedAt
      subdocsReviewedAt
      subdocsSentToClientAt
      subdocsSignedByClientAt
      subdocsSentToManagerAt
      subdocsCompletedAt
      commitmentSubmittedToReportingSystemAt
      commitmentRecordedInReportingSystemAt
      terminatedAt
      completedAt
      proposalType
      selectedGroupLegalEntity {
        id
        entityName
      }
      assignee {
        id
        email
        firstName
        lastName
      }
      investmentOwner {
        id
        firstName
        lastName
      }
      advisorOwner {
        id
        firstName
        lastName
      }
      complianceOwner {
        id
        firstName
        lastName
      }
      operationsOwner {
        id
        firstName
        lastName
      }
    }
  }
`;

export const OWNERS_QUERY = gql`
  ${OwnersFragment}
  query ($id: String!) {
    AllocationOwners(id: $id) {
      advisorOwners {
        ...OwnersFragment
      }
      operationsOwners {
        ...OwnersFragment
      }
    }
  }
`;

export const updateAllocationOwnersMutation = gql`
  mutation updateAllocationOwners($data: AllocationOwnersUpdateDto!) {
    updateAllocationOwners(data: $data) {
      id
      allocationStatus
      committedCapital
      lastUpdatedAt
      operationsStatus
      investmentApprovedAt
      advisorApprovedAt
      complianceApprovedAt
      suitability
      sentToClientAt
      clientApprovedAt
      subdocsGeneratedAt
      subdocsReviewedAt
      subdocsSentToClientAt
      subdocsSignedByClientAt
      subdocsSentToManagerAt
      subdocsCompletedAt
      commitmentSubmittedToReportingSystemAt
      commitmentRecordedInReportingSystemAt
      terminatedAt
      completedAt
      proposalType
      selectedGroupLegalEntity {
        id
        entityName
      }
      assignee {
        id
        email
        firstName
        lastName
      }
      investmentOwner {
        id
        firstName
        lastName
      }
      advisorOwner {
        id
        firstName
        lastName
      }
      complianceOwner {
        id
        firstName
        lastName
      }
      operationsOwner {
        id
        firstName
        lastName
      }
    }
  }
`;

export const RECOMMENDATION_QUERY = gql`
  ${OwnersFragment}
  query RecommendedAllocations(
    $limit: Int
    $offset: Int
    $sort: String
    $order: String
    $search: String
    $additionalInvestmentFilters: [AdditionalFilterDto!]
    $additionalAdvisorFilters: [AdditionalFilterDto!]
    $additionalStepFilters: [AdditionalFilterDto!]
    $assigneeFilter: AssigneeFilter
    $includeProposedInvestments: Boolean
  ) {
    RecommendedAllocations(
      limit: $limit
      offset: $offset
      sort: $sort
      order: $order
      search: $search
      additionalInvestmentFilters: $additionalInvestmentFilters
      additionalAdvisorFilters: $additionalAdvisorFilters
      additionalStepFilters: $additionalStepFilters
      assigneeFilter: $assigneeFilter
      includeProposedInvestments: $includeProposedInvestments
    ) {
      limit
      offset
      total
      allocations {
        allocationStatus
        operationsStatus
        investmentApprovedAt
        advisorApprovedAt
        complianceApprovedAt
        sentToClientAt
        clientApprovedAt
        subdocsGeneratedAt
        subdocsReviewedAt
        subdocsSentToClientAt
        subdocsSignedByClientAt
        subdocsSentToManagerAt
        subdocsCompletedAt
        commitmentSubmittedToReportingSystemAt
        commitmentRecordedInReportingSystemAt
        terminatedAt
        completedAt
        proposalType
        suitability
        reason
        selectedGroupLegalEntity {
          id
          entityName
        }
        id
        committedCapital
        lastUpdatedAt
        assignee {
          id
          email
          firstName
          lastName
        }
        moreInfo {
          onHold
          name
          entityValue
          privatePercentage
          targetOCRatio
          privateCapitalTarget
          overallCapacity
          privateCreditClassCapacity
          privateRealAssetsCapacity
          privateEquityCapacity
          biteSize
          sectorsToAvoid
        }
        investmentOwner {
          ...OwnersFragment
        }
        advisorOwner {
          ...OwnersFragment
        }
        complianceOwner {
          ...OwnersFragment
        }
        operationsOwner {
          ...OwnersFragment
        }
        legalEntity {
          id
          entityName
          isGroupEntity
          alwaysShow
          biteSize
          family {
            id
            name
          }
          advisor {
            initials
          }
          entities {
            id
            entityName
          }
        }
        investment {
          id
          name
          managerId
          assetClass
          nextClose
          finalClose
          minimum
          ubtiBlocker
          sri
          targetIRR
          targetYield
          type
        }
      }
    }
  }
`;

export const updateAllocationMutation = gql`
  mutation updateAllocations($data: [AllocationEntityUpdateDto!]!) {
    updateAllocations(data: $data) {
      id
      allocationStatus
      committedCapital
      lastUpdatedAt
      operationsStatus
      investmentApprovedAt
      advisorApprovedAt
      complianceApprovedAt
      suitability
      sentToClientAt
      clientApprovedAt
      subdocsGeneratedAt
      subdocsReviewedAt
      subdocsSentToClientAt
      subdocsSignedByClientAt
      subdocsSentToManagerAt
      subdocsCompletedAt
      commitmentSubmittedToReportingSystemAt
      commitmentRecordedInReportingSystemAt
      terminatedAt
      completedAt
      proposalType
      selectedGroupLegalEntity {
        id
        entityName
      }
      assignee {
        id
        email
        firstName
        lastName
      }
      investmentOwner {
        id
        firstName
        lastName
      }
      advisorOwner {
        id
        firstName
        lastName
      }
      complianceOwner {
        id
        firstName
        lastName
      }
      operationsOwner {
        id
        firstName
        lastName
      }
    }
  }
`;

export const popupQuery = gql`
  query RecommendedAllocationsPopUp($allocationId: String!, $includeProposedInvestments: Boolean) {
    RecommendedAllocationsPopUp(allocationId: $allocationId, includeProposedInvestments: $includeProposedInvestments) {
      onHold
      name
      entityValue
      privatePercentage
      targetOCRatio
      privateCapitalTarget
      overallCapacity
      privateCreditClassCapacity
      privateRealAssetsCapacity
      privateEquityCapacity
      biteSize
      sectorsToAvoid
    }
  }
`;

export const ALLOCATION = gql`
  ${OwnersFragment}
  query Allocation($id: String!) {
    Allocation(id: $id) {
      id
      allocationStatus
      committedCapital
      lastUpdatedAt
      operationsStatus
      investmentApprovedAt
      advisorApprovedAt
      complianceApprovedAt
      suitability
      sentToClientAt
      clientApprovedAt
      subdocsGeneratedAt
      subdocsReviewedAt
      subdocsSentToClientAt
      subdocsSignedByClientAt
      subdocsSentToManagerAt
      subdocsCompletedAt
      commitmentSubmittedToReportingSystemAt
      commitmentRecordedInReportingSystemAt
      terminatedAt
      completedAt
      proposalType
      selectedGroupLegalEntity {
        id
        entityName
      }
      assignee {
        id
        email
        firstName
        lastName
      }
      investmentOwner {
        ...OwnersFragment
      }
      advisorOwner {
        ...OwnersFragment
      }
      complianceOwner {
        ...OwnersFragment
      }
      operationsOwner {
        ...OwnersFragment
      }
    }
  }
`;

export const ALLOCATION_SUBSCRIPTION = gql`
  subscription allocationUpdated {
    allocationUpdated {
      id
    }
  }
`;

export const generateSubdocMutation = gql`
  mutation GenerateSubdoc($allocationId: String!) {
    GenerateSubdoc(allocationId: $allocationId) {
      status
      envelopeId
      uri
    }
  }
`;
