import { DueDiligenceRecord } from '@/components/dueDiligence-page/dueDiligenceRecord';
import { Helmet as Head } from 'react-helmet';

const DueDiligenceRecordPage = () => {
  return (
    <>
      <Head>
        <title>Due Diligence Record</title>
      </Head>
      <DueDiligenceRecord />
    </>
  );
};

export default DueDiligenceRecordPage;
