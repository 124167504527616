import { SettingsMenuIcon } from '@/assets/icons/fat-menu-icons/settings';
import styled, { useTheme } from 'styled-components';
import { IDescriptionSection } from './types';

export const DescriptionSection = ({ title, description }: IDescriptionSection) => {
  const theme = useTheme();
  return (
    <Section>
      <SettingsMenuIcon width={29} height={30} fill={theme.font.weak} />
      <SectionBody>
        <SectionTitle>{title}</SectionTitle>
        {description.map((item, index) => (
          <TextLine key={item + index}>{`${index + 1}. ${item}`}</TextLine>
        ))}
      </SectionBody>
    </Section>
  );
};

const Section = styled.div`
  width: 100%;
  display: flex;
  gap: 15px;
`;

const SectionTitle = styled.div`
  color: ${({ theme }) => theme.font.base};
  font-family: Blinker;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 22.4px;
  padding: 3px 0;
  margin-bottom: 15px;
`;

const SectionBody = styled.div``;

const TextLine = styled.p`
  color: ${({ theme }) => theme.font.base};
  font-family: Blinker;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22.4px;
`;
