import { DataRoomIcon } from '@/assets/static/icons/data-room';
import { Dispatch, SetStateAction, useState } from 'react';
import Button from '@/components/basicComponents/button';
import FilterSearch from '@/components/basicComponents/input';
import {
  CloseBtn,
  ModalControlWrap,
  ModalPageTitle
} from '@/components/client-details';
import Header from '@/components/header';
import { ISort } from '@/components/table/types';
import { useResponsive } from '@/hooks/use-responsive';
import { CloseInfoIcon } from '@/assets/icons/info-icons/closeInfo';
import { MainWrap, PaddingWrap } from '@/styles/common';
import styled from 'styled-components';
import { Table } from './table/table';

interface DataRoomProps {
  setIsDataRoomOpen: Dispatch<SetStateAction<boolean>>;
}

const dataRoomTabs = ['Due Diligence', 'Updates', 'Misc'];
const files = [
  {
    name: 'Q3 2023',
    lastModified: 'MAY 05, 2023 Katherine Adams',
    fileSize: null as number | null
  },
  {
    name: 'Q4 2023',
    lastModified: 'MAY 05, 2023 Katherine Adams',
    fileSize: null as number | null
  },
  {
    name: 'Q5 2023',
    lastModified: 'MAY 05, 2023 Katherine Adams',
    fileSize: null as number | null
  }
];

export const DataRoom = ({ setIsDataRoomOpen }: DataRoomProps) => {
  const { isMobile, isTablet } = useResponsive();

  const [dataRoomRoute, setDataRoomRoute] = useState<string[]>([
    'Angelo Gordon',
    'Angelo Gordon Direct Lending  Fund V'
  ]);
  const [activeTab, setActiveTab] = useState('');
  const [search, setSearch] = useState('');
  const [sort, setSort] = useState<ISort>({
    key: 'NAME',
    asc: true
  });

  const handleChangeTab = (tab: string) => {
    setActiveTab(tab);
    if (dataRoomRoute.includes(tab)) return;
    const newDataRoomRoute = dataRoomRoute;
    newDataRoomRoute[2] = tab;
    setDataRoomRoute(newDataRoomRoute);
  };

  return (
    <MainWrap>
      <Header
        modalControl={
          <ModalControlWrap>
            <CloseBtn onClick={() => setIsDataRoomOpen(false)}>
              <CloseInfoIcon width={18} height={18} />
            </CloseBtn>
            <ModalPageTitle isTablet={isTablet} isMobile={isMobile}>
              Data Room
            </ModalPageTitle>
          </ModalControlWrap>
        }
      />
      <PaddingWrap>
        <RouterWrap>
          {dataRoomRoute.map((route, index) => (
            <>
              <RouteItem>{route}</RouteItem>
              {dataRoomRoute.length - 1 !== index && (
                <svg
                  width="23"
                  height="23"
                  viewBox="0 0 23 23"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.4126 7.675L12.2276 11.5L8.4126 15.325L9.5876 16.5L14.5876 11.5L9.5876 6.5L8.4126 7.675Z"
                    fill="#7F7F7F"
                  />
                </svg>
              )}
            </>
          ))}
        </RouterWrap>
        <ContainerWrap>
          <DataRoomTabs>
            {dataRoomTabs.map((tab, index) => (
              <TabItem
                key={tab + index}
                onClick={() => handleChangeTab(tab)}
                isMobile={isMobile}
                isActive={tab === activeTab}
              >
                <DataRoomIcon
                  fill={tab === activeTab ? '#FAFAFA' : '#4587EC'}
                />
                {tab}
              </TabItem>
            ))}
          </DataRoomTabs>
          <TableContainerWrapper>
            <UploadWrapper>
              <Browse htmlFor="data-room">
                <UploadFile
                  type="file"
                  id="data-room"
                  multiple
                  onChange={(e) => null}
                  hidden
                />
                <svg
                  width="23"
                  height="24"
                  viewBox="0 0 23 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M18.2525 15.3712V18.7458H4.75421V15.3712H2.5V18.7458C2.50178 19.3431 2.73987 19.9154 3.16223 20.3378C3.58459 20.7601 4.15691 20.9982 4.75421 21H18.2525C18.8492 20.9982 19.4209 20.7599 19.8421 20.3374C20.2634 19.9148 20.5 19.3425 20.5 18.7458V15.3712H18.2525ZM5.87458 8.62204L7.46063 10.2081L10.3763 7.30596V16.4983H12.6237V7.30596L15.5394 10.2081L17.1254 8.62204L11.5034 3L5.87458 8.62204Z"
                    fill="#F4F5F6"
                  />
                </svg>
                {'Upload File'}
              </Browse>
              <SearchWrapper>
                <FilterSearch
                  value={search}
                  onChange={setSearch}
                  placeholder={'Search'}
                />
              </SearchWrapper>
            </UploadWrapper>
            <Table
              sort={sort}
              setSort={setSort}
              files={files}
              loading={false}
              refetch={(): void => null}
            />
          </TableContainerWrapper>
        </ContainerWrap>
      </PaddingWrap>
    </MainWrap>
  );
};

const RouterWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  font-size: 25px;
  font-family: Blinker;
  font-weight: 600;
  line-height: 22.4px;
  margin-top: 50px;
`;

const RouteItem = styled.div``;

const ContainerWrap = styled.div`
  display: flex;
  margin-top: 30px;
  gap: 10px;
`;

const DataRoomTabs = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
  width: 20%;
  background-color: #ffffff;
`;

const TableContainerWrapper = styled.div`
  padding: 10px;
  width: 80%;
`;

const TabItem = styled(Button)<{ isMobile: boolean; isActive: boolean }>`
  justify-content: flex-start;
  gap: 6px;
  background: ${({ isActive }) => (isActive ? '#3AB070' : '#fafafa')};
  border: ${({ isActive }) =>
    isActive ? '1px solid #3AB070' : '1px solid #4587ec'};
  color: ${({ isActive }) => (isActive ? '#FAFAFA' : '#4587ec')};
  width: 100%;
  height: 40px;
`;

const UploadFile = styled.input``;

const Browse = styled.label`
  display: flex;
  height: 40px;
  padding: 10px 30px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  background-color: #4587ec;
  border-radius: 4px;
  font-size: 16px;
  font-family: Blinker;
  font-weight: 600;
  line-height: 22.4px;
  color: #f4f5f6;
  cursor: pointer;
`;

const UploadWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 20px;
`;

const SearchWrapper = styled.div`
  padding: 8px 20px;
  background-color: #ffffff;
  height: 54px;
  border-radius: 8px;
`;
