import FormattingTooltip from '@/components/basicComponents/tooltip/dollarsTooltip';
import {
  MobileRow,
  MobileRowTitle,
  MobileRowValue,
  MobileTableRowWrap,
  MobileTableWrapper,
  NoResults
} from '@/components/table/mobileTable/mobileTable';
import { useAuth } from '@/hooks/use-auth';
import guid from '@/utils/guid';
import { entityColumns } from '../../constatnts';
import { IListEntity } from '../../types';

interface MobileTableEntityProps {
  listEntity: IListEntity[];
  openInvestorDetails: (id: string) => void;
  openEntityDetails: (investorId: string, entityId: string) => void;
}

const MobileTableEntity = ({ listEntity, openInvestorDetails, openEntityDetails }: MobileTableEntityProps) => {
  const { user } = useAuth();

  const TableRow = ({ el }: { el: IListEntity }) => {
    return (
      <MobileTableRowWrap key={guid()}>
        <MobileRow>
          <MobileRowTitle>{entityColumns[0].title}</MobileRowTitle>
          <MobileRowValue isRedirect onClick={() => openEntityDetails(el.family.id, el.id)}>
            {el.entityName}
          </MobileRowValue>
        </MobileRow>

        <MobileRow>
          <MobileRowTitle>{entityColumns[1].title}</MobileRowTitle>
          <MobileRowValue isRedirect onClick={() => openInvestorDetails(el.id)}>
            {el.family.name}
          </MobileRowValue>
        </MobileRow>

        {user && user.tenant.type === 'fundManager' && (
          <MobileRow>
            <MobileRowTitle>{entityColumns[2].title}</MobileRowTitle>
            <MobileRowValue>{el.tenant.name}</MobileRowValue>
          </MobileRow>
        )}

        <MobileRow>
          <MobileRowTitle>{entityColumns[3].title}</MobileRowTitle>
          <MobileRowValue>{el.investmentCount}</MobileRowValue>
        </MobileRow>

        <MobileRow>
          <MobileRowTitle>{entityColumns[4].title}</MobileRowTitle>
          <MobileRowValue>
            <FormattingTooltip zIndex={1000} key={guid()}>
              {el.totalEntityValue}
            </FormattingTooltip>
          </MobileRowValue>
        </MobileRow>

        <MobileRow>
          <MobileRowTitle>{entityColumns[5].title}</MobileRowTitle>
          <MobileRowValue>
            <FormattingTooltip zIndex={1000} key={guid()}>
              {el.totalCommitments}
            </FormattingTooltip>
          </MobileRowValue>
        </MobileRow>
      </MobileTableRowWrap>
    );
  };

  return (
    <MobileTableWrapper>
      {listEntity.length ? (
        listEntity.map((el) => {
          return <TableRow el={el} key={guid()} />;
        })
      ) : (
        <NoResults>No Results</NoResults>
      )}
    </MobileTableWrapper>
  );
};

export default MobileTableEntity;
