import { GroupedIcon } from '@/assets/icons/info-icons/groupedIcon';
import { MinusIcon } from '@/assets/icons/info-icons/minusIcon';
import { PlusIcon } from '@/assets/icons/info-icons/plusIcon';
import FormattingTooltip from '@/components/basicComponents/tooltip/dollarsTooltip';
import { blinkingFrame, TitleCell } from '@/components/table/styles';
import React, { useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { ITransformedAllocation, ITransformedAssetClass } from '../types';
import BulkDropdownActions from './bulkActionDropdown';

interface TableRowProps {
  row: ITransformedAssetClass;
  columnWidth: number;
  mode: 'edit' | 'view';
  targetSubAssetClasses: boolean;
  handleAssetClassAllocationChange?: (assetClassId: string, date: string, newValue: string) => void;
  handleSubAssetClassAllocationChange?: (
    assetClassId: string,
    subAssetClassId: string,
    sacAllocation: ITransformedAllocation,
    newValue: string
  ) => void;
  applyValueToAllAssetClassRows?: (assetClassId: string, acAllocation: ITransformedAllocation) => void;
  applyValueToAllGoingForwardAssetClassRows?: (assetClassId: string, acAllocation: ITransformedAllocation) => void;
  applyValueToAllSubAssetClassRows?: (assetClassId: string, subAssetClassId: string, sacAllocation: ITransformedAllocation) => void;
  applyValueToAllGoingForwardSubAssetClassRows?: (assetClassId: string, subAssetClassId: string, sacAllocation: ITransformedAllocation) => void;
}

const formatAllocation = (allocation: string, mode: 'view' | 'edit') => {
  if (allocation === '-') {
    return mode === 'view' ? '--' : '-';
  }
  return <FormattingTooltip zIndex={1000}>{+allocation}</FormattingTooltip>;
};

const TableRow = ({
  row,
  columnWidth,
  mode,
  targetSubAssetClasses,
  handleAssetClassAllocationChange,
  handleSubAssetClassAllocationChange,
  applyValueToAllAssetClassRows,
  applyValueToAllGoingForwardAssetClassRows,
  applyValueToAllSubAssetClassRows,
  applyValueToAllGoingForwardSubAssetClassRows
}: TableRowProps) => {
  const theme = useTheme();

  const [showSubAssetClass, setShowSubAssetClass] = useState(false);

  return (
    <>
      <TableRowWrapper>
        <TableRowCell width={15}>
          <AssetClassCell>
            {row.subAssetClasses && targetSubAssetClasses && (
              <PlusIconWrapper onClick={() => setShowSubAssetClass(!showSubAssetClass)}>
                {showSubAssetClass ? <MinusIcon fill={theme.font.base} /> : <PlusIcon fill={theme.font.base} />}
              </PlusIconWrapper>
            )}
            <TextCell>{row.name}</TextCell>
          </AssetClassCell>
        </TableRowCell>
        {row.allocations.map((allocation) => (
          <TableRowCell key={allocation.id} width={columnWidth} loading={allocation.updated ? 1 : 0}>
            {mode === 'edit' && !targetSubAssetClasses ? (
              <BulkDropdownActions
                isListCenter
                isMobile
                items={[
                  {
                    name: 'Apply To All In This Row',
                    onClick: () => applyValueToAllAssetClassRows(row.assetClassId, allocation)
                  },
                  {
                    name: 'Apply To All Going Forward',
                    onClick: () => applyValueToAllGoingForwardAssetClassRows(row.assetClassId, allocation)
                  }
                ]}
                value={allocation.allocation}
                onChange={(value) => handleAssetClassAllocationChange(row.assetClassId, allocation.date, value)}
              />
            ) : (
              <TextCell>{formatAllocation(allocation.allocation, mode)}</TextCell>
            )}
          </TableRowCell>
        ))}
      </TableRowWrapper>
      {targetSubAssetClasses && row.subAssetClasses && showSubAssetClass && (
        <>
          {row.subAssetClasses.map((subAssetClass) => {
            const hasSubAssetClassValues = subAssetClass.allocations.some((allocation) => allocation.allocation !== '-');
            if (!hasSubAssetClassValues && mode === 'view') return <React.Fragment key={subAssetClass.subAssetClassId} />;

            return (
              <TableRowWrapper key={subAssetClass.subAssetClassId}>
                <TableRowCell width={15}>
                  <AssetClassCell>
                    <GroupIconWrapper>
                      <GroupedIcon width={26} height={26} />
                    </GroupIconWrapper>
                    <TextCell>{subAssetClass.name}</TextCell>
                  </AssetClassCell>
                </TableRowCell>
                {subAssetClass.allocations.map((allocation) => (
                  <TableRowCell key={allocation.id} width={columnWidth} loading={allocation.updated ? 1 : 0}>
                    {mode === 'edit' ? (
                      <BulkDropdownActions
                        isListCenter
                        isMobile
                        items={[
                          {
                            name: 'Apply To All In This Row',
                            onClick: () => applyValueToAllSubAssetClassRows(row.assetClassId, subAssetClass.subAssetClassId, allocation)
                          },
                          {
                            name: 'Apply To All Going Forward',
                            onClick: () => applyValueToAllGoingForwardSubAssetClassRows(row.assetClassId, subAssetClass.subAssetClassId, allocation)
                          }
                        ]}
                        value={allocation.allocation}
                        onChange={(value) => handleSubAssetClassAllocationChange(row.assetClassId, subAssetClass.subAssetClassId, allocation, value)}
                      />
                    ) : (
                      <TextCell>
                        {allocation.allocation === '-' ? '--' : <FormattingTooltip zIndex={1000}>{+allocation.allocation}</FormattingTooltip>}
                      </TextCell>
                    )}
                  </TableRowCell>
                ))}
              </TableRowWrapper>
            );
          })}
        </>
      )}
    </>
  );
};

export default TableRow;

const TableRowWrapper = styled.div<{ addAdaptive?: boolean; loading?: boolean | number }>`
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 60px;
  background-color: ${({ theme }) => theme.layer[1]};
  border-radius: 8px;
  padding: 0 24px;
  gap: 5px;
  opacity: ${({ loading }) => (loading ? 0.5 : 1)};
  pointer-events: ${({ loading }) => (loading ? 'none' : 'auto')};
  animation: ${({ loading }) => (loading ? blinkingFrame : null)} 1.5s infinite;

  &:hover #allocationInput {
    border: 1px solid ${({ theme }) => theme.border.base};
  }

  &:hover {
    background-color: ${({ theme }) => theme.layer[12]};
    transition: background-color 100ms ease;
  }
`;

const TableRowCell = styled.div<{ width?: number; loading?: boolean | number }>`
  display: flex;
  justify-content: flex-start;
  width: ${({ width }) => (width === undefined ? 'auto' : `${width}%`)};

  opacity: ${({ loading }) => (loading ? 0.5 : 1)};
  pointer-events: ${({ loading }) => (loading ? 'none' : 'auto')};
  animation: ${({ loading }) => (loading ? blinkingFrame : null)} 1.5s infinite;
`;

const AssetClassCell = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
`;

const PlusIconWrapper = styled.div`
  cursor: pointer;
  border-radius: 12px;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: ${({ theme }) => theme.action.hover};
    transition: background-color 100ms ease;
  }
`;

const GroupIconWrapper = styled.div`
  padding: 5px;
  position: relative;
  top: -9px;
  margin-right: 14px;
  margin-left: 5px;
`;

const TextCell = styled(TitleCell)<{ bold?: boolean }>`
  font-weight: ${({ bold }) => (bold ? 600 : 400)};
`;
