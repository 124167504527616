import React, { useState } from 'react';
import styled, { useTheme } from 'styled-components';

import { ArrowDown } from '@/assets/icons/info-icons/arrowDown';
import { ScoreArrow } from '@/assets/icons/scoreArrow';
import { ArrowUp } from '@/assets/static/icons/todo-page/arrow-up';
import Button from '@/components/fat-basicComponents/button';
import { useMutation } from '@apollo/client';
import { useResponsive } from '../../../hooks/use-responsive';
import { User } from '../../../types/user';
import { TOGGLE_SECTION_COMPLETION_MUTATION, updateDueDiligenceRecordSectionScoreMutation } from '../queries';
import { ChangeScore } from './changeScore';
import SectionStatus from './sectionStatus';
import { IChecklistItem, ISection, RecordStatusEnum, TierStatusEnum } from './types';

interface TierDropdownProps {
  children: React.ReactNode;
  title: string;
  bgColor?: string;
  openNotes?: (note: string, updateAt: Date, task: string, assignedBy: User, assignedTo: User) => void;
  openModalWindow?: (type: string, row: ISection | IChecklistItem) => void;
  sectionData?: ISection;
  checkListData?: IChecklistItem[];
  tierStatus?: TierStatusEnum;
  owner?: User;
  prevTierStatus?: TierStatusEnum | null;
  isBackToLoading?: boolean;
  recordStatus?: RecordStatusEnum | null;
}

export const TierDropdown = ({
  children,
  title,
  bgColor,
  openNotes,
  openModalWindow,
  sectionData,
  tierStatus,
  checkListData,
  owner,
  prevTierStatus,
  isBackToLoading = false,
  recordStatus
}: TierDropdownProps) => {
  const { isMobile } = useResponsive();
  const theme = useTheme();

  const [isDropdown, setIsDropdown] = useState(true);
  const [currentScore, setCurrentScore] = useState(sectionData?.data.score || 0);
  const [isScoreOpen, setIsScoreOpen] = useState(false);
  const [arrowHover, setArrowHover] = useState(false);

  const [updateDueDiligenceRecordSectionScore] = useMutation(updateDueDiligenceRecordSectionScoreMutation);
  const [updateSectionCompletion] = useMutation(TOGGLE_SECTION_COMPLETION_MUTATION);

  const updateScore = (count: number) => {
    updateDueDiligenceRecordSectionScore({
      variables: {
        data: {
          id: sectionData?.data.id,
          score: count
        }
      },
      onCompleted: (data: any) => {
        setCurrentScore(data.updateDueDiligenceRecordSectionScore.score);
      }
    });
  };

  const handleChangeSectionStatus = (isCompleted: boolean) => {
    if (updateSectionCompletion) {
      updateSectionCompletion({
        variables: {
          data: {
            id: sectionData?.data.id,
            completed: isCompleted
          }
        }
      });
    }
  };

  const isSectionFieldsValue = sectionData?.fields.filter((item) => {
    if (item.required && !item.data.value && !item.data.commentary && !item.data.attachments.length && !item.AI?.answers.length) {
      return item;
    }
  });

  const isDisabled =
    sectionData?.data.completed ||
    prevTierStatus === TierStatusEnum.REJECTED ||
    recordStatus === RecordStatusEnum.APPROVED ||
    recordStatus === RecordStatusEnum.REJECTED;

  return (
    <DropdownWrapper>
      <DropdownHeader isMobile={isMobile} isChecklist={Boolean(openModalWindow)} isDropdown={isDropdown}>
        <HeaderInfo>
          <Title isMobile={isMobile} id="dropdownTitle">
            {title}
          </Title>
          {openModalWindow && openNotes && owner && recordStatus && (
            <SectionStatus
              prevTierStatus={prevTierStatus ?? null}
              owner={owner}
              isSection={true}
              currentRow={sectionData}
              sectionTitle={title}
              openModalWindow={openModalWindow}
              openNotes={openNotes}
              loading={isBackToLoading}
              recordStatus={recordStatus}
            />
          )}
        </HeaderInfo>
        <ArrowScoreWrapper isMobile={isMobile} isChecklist={Boolean(openModalWindow)}>
          {sectionData?.userCanScore && owner && (
            <>
              <ScoreCounter isMobile={isMobile} disabled={isDisabled} onClick={isDisabled ? () => null : () => setIsScoreOpen(!isScoreOpen)}>
                {currentScore === 0 && (
                  <>
                    <ScoreArrow width={isMobile ? 25 : 34} height={isMobile ? 25 : 34} fill={theme.layer[3]} />
                    <ScoreWrapper>-</ScoreWrapper>
                  </>
                )}
                {currentScore > 0 && (
                  <>
                    <ScoreArrow
                      width={isMobile ? 25 : 34}
                      height={isMobile ? 25 : 34}
                      fill={theme.context.success}
                      style={{ transform: 'rotate(-90deg)' }}
                    />
                    <ScoreWrapper color={theme.context.success}>+{currentScore}</ScoreWrapper>
                  </>
                )}
                {currentScore < 0 && (
                  <>
                    <ScoreArrow
                      width={isMobile ? 25 : 34}
                      height={isMobile ? 25 : 34}
                      fill={theme.context.error}
                      style={{ transform: 'rotate(90deg)' }}
                    />
                    <ScoreWrapper color={theme.context.error}>{currentScore}</ScoreWrapper>
                  </>
                )}
              </ScoreCounter>
              {isScoreOpen && (
                <>
                  {!isDisabled && (
                    <ChangeScore
                      updateScore={(count) => updateScore(count)}
                      count={currentScore}
                      setCount={setCurrentScore}
                      onClose={setIsScoreOpen}
                    />
                  )}
                </>
              )}
            </>
          )}
          <ArrowWrapper
            isMobile={isMobile}
            onClick={() => setIsDropdown(!isDropdown)}
            onMouseEnter={() => setArrowHover(true)}
            onMouseLeave={() => setArrowHover(false)}
          >
            <>
              {isDropdown ? (
                <ArrowUp style={{ cursor: 'pointer' }} fill={arrowHover && !isMobile ? theme.context.light : theme.font.action} />
              ) : (
                <ArrowDown
                  style={{
                    cursor: 'pointer',
                    width: '28px',
                    height: '28px',
                    padding: '7px'
                  }}
                  fill={arrowHover && !isMobile ? theme.context.light : theme.font.action}
                />
              )}
            </>
          </ArrowWrapper>
        </ArrowScoreWrapper>
      </DropdownHeader>
      <DropdownBody isMobile={isMobile} isDropdown={isDropdown} bgColor={bgColor}>
        <ExpandableWrapp isMobile={isMobile} isDropdown={isDropdown}>
          {children}
          {owner && tierStatus === TierStatusEnum.IN_PROGRESS && (
            <>
              {sectionData?.data.completed ? (
                <ButtonWrapper>
                  <Button onClick={() => handleChangeSectionStatus(false)} styleType="outline" size="lg">
                    Reopen Task
                  </Button>
                </ButtonWrapper>
              ) : (
                <ButtonWrapper>
                  <Button
                    onClick={() => handleChangeSectionStatus(true)}
                    styleType={Boolean(isSectionFieldsValue?.length) ? 'disabled' : 'default'}
                    size="lg"
                  >
                    Mark As Complete
                  </Button>
                </ButtonWrapper>
              )}
            </>
          )}
        </ExpandableWrapp>
      </DropdownBody>
    </DropdownWrapper>
  );
};

export const DropdownWrapper = styled.div`
  border: 1px solid ${({ theme }) => theme.border.base};
  border-radius: 10px;
`;

const ScoreWrapper = styled.div<{ color?: string }>`
  padding: 1px 10px;
  font-weight: 700;
  font-size: 13px;
  background-color: ${({ color, theme }) => (color ? color : theme.layer[1])};
  display: inline-block;
  border-radius: 12px;
  color: ${({ color, theme }) => (color ? theme.context.light : theme.font.strong)};
  margin-right: 6px;
`;

export const DropdownHeader = styled.div<{ isMobile: boolean; isChecklist: boolean; isDropdown: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: ${({ isMobile, isChecklist }) => (isMobile && isChecklist ? 'flex-start' : 'center')};
  flex-direction: ${({ isMobile, isChecklist }) => (isMobile && isChecklist ? 'column' : 'row')};
  padding: ${({ isMobile }) => (isMobile ? '15px 20px' : '20px 40px')};
  position: relative;
  border-radius: 12px 12px 0 0;
  background-color: ${({ theme }) => theme.layer.base};

  &:hover {
    background-color: ${({ theme }) => theme.layer[2]};
    transition: background-color 100ms ease;
    border-radius: ${({ isDropdown }) => (isDropdown ? '12px 12px 0 0' : '12px')};
  }
`;

export const ArrowWrapper = styled.div<{ isMobile: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border-radius: 25px;
  cursor: pointer;

  &:hover {
    background-color: ${({ isMobile, theme }) => (isMobile ? 'transparent' : theme.action.hover)};
    transition: background-color 100ms ease;
  }
`;

export const Title = styled.div<{ isMobile: boolean }>`
  font-family: Lato;
  font-weight: 700;
  font-size: ${({ isMobile }) => (isMobile ? '20px' : '28px')};
  line-height: ${({ isMobile }) => (isMobile ? '30px' : '22px')};
  color: ${({ theme }) => theme.font.strong};
`;

export const HeaderInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const DropdownBody = styled.div<{ isMobile: boolean; isDropdown: boolean; bgColor?: string }>`
  background-color: ${({ bgColor, theme }) => (bgColor ? bgColor : theme.layer[1])};
  border-radius: 0 0 10px 10px;
  border-top: ${({ isDropdown, theme }) => (isDropdown ? `1px solid ${theme.border.base}` : 'none')};
  opacity: ${({ isDropdown }) => (isDropdown ? '1' : '0')};
  display: grid;
  grid-template-rows: ${({ isDropdown }) => (isDropdown ? '1fr' : '0fr')};
  overflow: ${({ isDropdown }) => (isDropdown ? 'visible' : 'hidden')};
  transition: grid-template-rows 300ms;
`;

export const ExpandableWrapp = styled.div<{ isMobile: boolean; isDropdown: boolean }>`
  min-height: 0;
  padding: ${({ isMobile, isDropdown }) => (isMobile && isDropdown ? '20px' : isDropdown ? '40px' : 0)};
`;

const ArrowScoreWrapper = styled.div<{ isMobile: boolean; isChecklist: boolean }>`
  display: flex;
  align-items: center;
  justify-content: ${({ isMobile }) => (isMobile ? 'space-between' : 'flex-start')};
  margin-top: ${({ isMobile }) => (isMobile ? '10px' : '0')};
  gap: ${({ isMobile }) => (isMobile ? '0' : '30px')};
  width: ${({ isMobile, isChecklist }) => (isMobile && isChecklist ? '100%' : 'auto')};
  position: relative;
`;

const ScoreCounter = styled.div<{ disabled: boolean; isMobile: boolean }>`
  display: flex;
  align-items: center;
  gap: 8px;
  background: ${({ theme }) => theme.layer[1]};
  border-radius: 32px;
  padding: 8px;
  margin-left: ${({ isMobile }) => (isMobile ? '0' : 'auto')};
  cursor: ${({ disabled }) => (disabled ? 'auto' : 'pointer')};
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 30px 0 20px 0;
`;
