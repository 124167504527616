import Button from '@/components/fat-basicComponents/button';
import {
  MobileRow,
  MobileRowTitle,
  MobileRowValue,
  MobileTableRowWrap,
  MobileTableWrapper,
  NoResults
} from '@/components/table/mobileTable/mobileTable';
import { TextCell, TitleCell } from '@/components/table/styles';
import guid from '@/utils/guid';
import { InvestorEntitiesRowStatus } from '../../../investorEntitiesRowStatus';
import { IInvestorComparison, TypeWindow } from '../../../types';
import { columns } from '../constants';

interface MobileTableProps {
  tableData: IInvestorComparison[];
  openInvestorImportDetailPage: (type: TypeWindow, row: IInvestorComparison) => void;
}

const MobileTable = ({ tableData, openInvestorImportDetailPage }: MobileTableProps) => {
  const TableRow = ({ el }: { el: IInvestorComparison }) => {
    return (
      <MobileTableRowWrap key={guid()}>
        <MobileRow>
          <MobileRowTitle>{columns[0].title}</MobileRowTitle>
          <MobileRowValue>{el.investorName}</MobileRowValue>
        </MobileRow>

        <MobileRow>
          <MobileRowTitle>{columns[1].title}</MobileRowTitle>
          <MobileRowValue>{el.totalEntities}</MobileRowValue>
        </MobileRow>

        <MobileRow>
          <MobileRowTitle>{columns[2].title}</MobileRowTitle>
          <MobileRowValue>{el.entitiesAdded}</MobileRowValue>
        </MobileRow>

        <MobileRow>
          <MobileRowTitle>{columns[3].title}</MobileRowTitle>
          <MobileRowValue>{el.entitiesRemoved}</MobileRowValue>
        </MobileRow>

        <MobileRow>
          <MobileRowTitle>{columns[4].title}</MobileRowTitle>
          <MobileRowValue>
            {el.advisorEmail.toLowerCase() === 'multiple' ? <TitleCell color="#B5121B">Multiple</TitleCell> : <TextCell>{el.advisorEmail}</TextCell>}
          </MobileRowValue>
        </MobileRow>

        <MobileRow>
          <MobileRowTitle>{columns[5].title}</MobileRowTitle>
          <MobileRowValue>
            <InvestorEntitiesRowStatus status={el.status} />
          </MobileRowValue>
        </MobileRow>

        <MobileRow>
          <MobileRowTitle>{columns[6].title}</MobileRowTitle>
          <MobileRowValue>
            {el.status !== 'toDelete' && (
              <Button size="sm" onClick={() => openInvestorImportDetailPage('investorImportDetail', el)}>
                View
              </Button>
            )}
          </MobileRowValue>
        </MobileRow>
      </MobileTableRowWrap>
    );
  };

  return (
    <MobileTableWrapper>
      {tableData.length ? (
        tableData.map((el: any, index: number) => {
          return <TableRow el={el} key={index} />;
        })
      ) : (
        <NoResults>No Results</NoResults>
      )}
    </MobileTableWrapper>
  );
};

export default MobileTable;
