import { gql } from '@apollo/client';

export const OPEN_OPPORTUNITIES_QUERY = gql`
  query OpenOpportunities(
    $page: Int
    $rowsPerPage: Int
    $assetClassQuickFilter: String
    $sort: String
    $order: String
    $search: String
    $statusFilter: [String!]
  ) {
    OpenOpportunities(
      page: $page
      rowsPerPage: $rowsPerPage
      assetClassQuickFilter: $assetClassQuickFilter
      sort: $sort
      order: $order
      search: $search
      statusFilter: $statusFilter
    ) {
      total
      page
      perPage
      filters {
        name
        type
        value
        enabled
        count
      }
      data {
        id
        type
        assetClass {
          id
          name
        }
        subAssetClass {
          id
          name
        }
        subAssetClasses
        securityStatus
        nextClose
        finalClose
        investorDocsDue
        name
        aggregateCommitment
        openedAt
        workflowTransitionTasksHistory {
          id
          taskId
          createdAt
        }
      }
    }
  }
`;

export const GET_INVESTOR_INTERESTS_QUERY = gql`
  query getInvestorInterests($data: InvestorInterestListDto!) {
    getInvestorInterests(data: $data) {
      total
      page
      perPage
      filters {
        type
        name
        value
        enabled
        count
      }
      data {
        investment {
          id
          type
          symbol
          name
          assetClass {
            id
            name
          }
          subAssetClass {
            id
            name
          }
          subAssetClasses
          finalClose
          investorDocsDue
          securityStatus
          createdAt
          workflowTransitionTasksHistory {
            id
            createdAt
          }
        }
        totalInvestmentAmount
        investorInterests {
          id
          amount
          exempt
          legal_entity {
            id
            entityName
          }
        }
        investorInterestsExempt {
          id
          amount
          exempt
          legal_entity {
            id
            entityName
          }
        }
        investorInterestsTaxable {
          id
          amount
          exempt
          legal_entity {
            id
            entityName
          }
        }
      }
    }
  }
`;

export const GET_OTHER_RECOMMENDED_INVESTMENTS_QUERY = gql`
  query getOtherRecommendedInvestments($data: InvestorInterestListDto!) {
    getOtherRecommendedInvestments(data: $data) {
      total
      page
      lastPage
      perPage
      filters {
        type
        name
        value
        enabled
        count
      }
      data {
        investment {
          id
          name
          type
          symbol
          assetClass {
            id
            name
          }
          subAssetClass {
            id
            name
          }
          subAssetClasses
          finalClose
          investorDocsDue
          securityStatus
          createdAt
          workflowTransitionTasksHistory {
            id
            createdAt
          }
        }
        totalInvestmentAmount
        investorInterests {
          id
          amount
          exempt
          legal_entity {
            id
            entityName
          }
        }
        investorInterestsExempt {
          id
          amount
          exempt
          legal_entity {
            id
            entityName
          }
        }
        investorInterestsTaxable {
          id
          amount
          exempt
          legal_entity {
            id
            entityName
          }
        }
      }
    }
  }
`;

export const GET_IN_RESEARCH_INVESTMENTS_QUERY = gql`
  query getInResearchInvestments($data: InvestorInterestListDto!) {
    getInResearchInvestments(data: $data) {
      total
      page
      lastPage
      perPage
      filters {
        type
        name
        value
        enabled
        count
      }
      data {
        investment {
          id
          name
          type
          symbol
          assetClass {
            id
            name
          }
          subAssetClass {
            id
            name
          }
          subAssetClasses
          finalClose
          investorDocsDue
          securityStatus
          createdAt
          workflowTransitionTasksHistory {
            id
            createdAt
          }
        }
        totalInvestmentAmount
        investorInterests {
          id
          amount
          exempt
          legal_entity {
            id
            entityName
          }
        }
        investorInterestsExempt {
          id
          amount
          exempt
          legal_entity {
            id
            entityName
          }
        }
        investorInterestsTaxable {
          id
          amount
          exempt
          legal_entity {
            id
            entityName
          }
        }
      }
    }
  }
`;

export const createInvestorInterests = gql`
  mutation createInvestorInterests($data: [CreateInvestorInterestDto!]!) {
    createInvestorInterests(data: $data) {
      id
    }
  }
`;

export const updateInvestorInterestAmount = gql`
  mutation updateInvestorInterestAmount($data: [UpdateInvestorInterestDto!]!) {
    updateInvestorInterestAmount(data: $data) {
      id
    }
  }
`;

export const deleteInvestorInterestAmount = gql`
  mutation deleteInvestorInterestAmount($ids: [String!]!) {
    deleteInvestorInterestAmount(ids: $ids)
  }
`;
