import { SortIcon } from '@/assets/icons/sortIcon';
import { BurgerMenuIcon } from '@/assets/static/icons/burger-menu';
import { CloseMenuIcon } from '@/assets/static/icons/close-menu';
import Tabs, { ITab, TabMobileWrap, TabTitle, TabsControl, TabsLine } from '@/components/basicComponents/fat-tabs';
import BasicTooltip from '@/components/basicComponents/tooltip';
import FormattingTooltip from '@/components/basicComponents/tooltip/dollarsTooltip';
import { Investment } from '@/components/fat-dealCatalog-page/types';
import { useResponsive } from '@/hooks/use-responsive';
import { dateFormatter } from '@/utils/dateFormatter';
import guid from '@/utils/guid';
import { format } from 'date-fns';
import { Dispatch, SetStateAction, useState } from 'react';
import styled, { useTheme } from 'styled-components';

interface DetailsProps {
  data: Investment | null;
  capitalCalled: number;
  tabs?: ITab[];
  activeTab?: string;
  setActiveTab?: (tab: string) => void;
  isTabMenuOpen?: boolean;
  setIsTabMenuOpen?: Dispatch<SetStateAction<boolean>>;
}

export const Details = ({ data, capitalCalled, tabs, activeTab, setActiveTab, isTabMenuOpen, setIsTabMenuOpen }: DetailsProps) => {
  const { isMobile, isDesktop, isTablet, isLargeDesktop } = useResponsive();
  const theme = useTheme();

  const [isDetailsOpen, setIsDetailsOpen] = useState(true);

  if (!data) return <></>;

  return (
    <DetailsWrap isOpen={isDetailsOpen} isMobile={isMobile}>
      <DetailsBoby isMobile={isMobile} isOpen={isDetailsOpen}>
        <ExpandableWrapp isMobile={isMobile} isOpen={isDetailsOpen}>
          {data?.committedCapital > 0 ? (
            <CardsContainer isMobile={isMobile} isOpen={isDetailsOpen}>
              <Card isMobile={isMobile}>
                <CardTitle>
                  <FormattingTooltip zIndex={120} key={guid()}>
                    {data?.committedCapital ?? 0}
                  </FormattingTooltip>
                </CardTitle>
                <CardDesc>COMMITTED CAPITAL</CardDesc>
              </Card>
              <Card isMobile={isMobile}>
                <CardTitle>
                  <FormattingTooltip zIndex={120}>{capitalCalled}</FormattingTooltip>
                </CardTitle>
                <CardDesc>CAPITAL CALLED</CardDesc>
              </Card>
              <Card isMobile={isMobile}>
                <CardTitle>
                  <FormattingTooltip zIndex={120}>{data?.distributionsToDate ?? 0}</FormattingTooltip>
                </CardTitle>
                <CardDesc>DISTRIBUTIONS TO DATE</CardDesc>
              </Card>
            </CardsContainer>
          ) : (
            <></>
          )}

          <MoreDetailsWrapper>
            <MoreDetails isMobile={isMobile} isOpen={isDetailsOpen}>
              <MoreDetailItem isMobile={isMobile}>
                {data.managerId && (
                  <ItemRow isMobile={isMobile}>
                    <ItemTitle>Manager</ItemTitle>
                    <ItemValue>{data.managerId}</ItemValue>
                  </ItemRow>
                )}

                <ItemRow isMobile={isMobile}>
                  <ItemTitle>Investment Type</ItemTitle>
                  <ItemValue>{data?.type}</ItemValue>
                </ItemRow>

                {data?.targetIRR || data?.targetReturn ? (
                  <ItemRow isMobile={isMobile}>
                    <ItemTitle>Target IRR</ItemTitle>
                    <ItemValue>{data?.targetIRR ? data.targetIRR : data?.targetReturn}%</ItemValue>
                  </ItemRow>
                ) : (
                  <></>
                )}

                {data?.subAssetClasses.filter((sector) => sector !== '').length ? (
                  <ItemRow isMobile={isMobile}>
                    <ItemTitle>Sectors</ItemTitle>
                    <ItemsWrap>
                      {data?.subAssetClasses
                        .filter((x, i, a) => a.indexOf(x) == i)
                        .map((el) => {
                          const key = 'subAssetClass_' + el.trim();

                          if (el.length && el.length > 15) {
                            return (
                              <BasicTooltip key={key} tooltipContent={el.trim()}>
                                <ItemValues>{el.trim().slice(0, 15) + ' ...'}</ItemValues>
                              </BasicTooltip>
                            );
                          }
                          if (el.length) {
                            return <ItemValues key={key}>{el.trim()}</ItemValues>;
                          }
                        })}
                    </ItemsWrap>
                  </ItemRow>
                ) : (
                  // <ItemRow isMobile={isMobile}>
                  //   <ItemTitle>Sectors</ItemTitle>
                  //   <ItemValue>{'N/A'}</ItemValue>
                  // </ItemRow>
                  <></>
                )}
              </MoreDetailItem>

              <MoreDetailItem isMobile={isMobile}>
                {!data?.assetClass || (
                  <ItemRow isMobile={isMobile}>
                    <ItemTitle>Asset Class</ItemTitle>
                    <ItemValue>{data?.subAssetClass ? `${data?.assetClass.name} - ${data?.subAssetClass.name}` : data?.assetClass.name}</ItemValue>
                  </ItemRow>
                )}

                {data.finalClose && (
                  <ItemRow isMobile={isMobile}>
                    <ItemTitle>Final Close</ItemTitle>
                    <ItemValue>{dateFormatter(format(new Date(data.finalClose), 'yyyy-MM-dd'), 'yyyy-MM-dd')}</ItemValue>
                  </ItemRow>
                )}

                {data.targetYield && (
                  <ItemRow isMobile={isMobile}>
                    <ItemTitle>Target Yield</ItemTitle>
                    <ItemValue>{data.targetYield}%</ItemValue>
                  </ItemRow>
                )}

                {data?.tags.filter((sector) => sector !== '').length ? (
                  <ItemRow isMobile={isMobile}>
                    <ItemTitle>Focus</ItemTitle>
                    <ItemsWrap>
                      {data?.tags
                        .filter((x, i, a) => a.indexOf(x) == i)
                        .map((el) => {
                          const key = 'tag_' + el.trim();

                          if (el.length && el.length > 15) {
                            return (
                              <BasicTooltip key={key} tooltipContent={el.trim()}>
                                <ItemValues>{el.trim().slice(0, 15) + ' ...'}</ItemValues>
                              </BasicTooltip>
                            );
                          }
                          if (el.length) {
                            return <ItemValues key={key}>{el.trim()}</ItemValues>;
                          }
                        })}
                    </ItemsWrap>
                  </ItemRow>
                ) : (
                  // <ItemRow isMobile={isMobile}>
                  //   <ItemTitle>Focus</ItemTitle>
                  //   <ItemValue>{'N/A'}</ItemValue>
                  // </ItemRow>
                  <></>
                )}
              </MoreDetailItem>

              <MoreDetailItem isMobile={isMobile}>
                {data.vintageYear && (
                  <ItemRow isMobile={isMobile}>
                    <ItemTitle>Vintage</ItemTitle>
                    <ItemValue>{data.vintageYear}</ItemValue>
                  </ItemRow>
                )}

                {data.nextClose && (
                  <ItemRow isMobile={isMobile}>
                    <ItemTitle>Next Close</ItemTitle>
                    <ItemValue>{dateFormatter(format(new Date(data.nextClose), 'yyyy-MM-dd'), 'yyyy-MM-dd')}</ItemValue>
                  </ItemRow>
                )}
              </MoreDetailItem>
            </MoreDetails>
          </MoreDetailsWrapper>
        </ExpandableWrapp>
      </DetailsBoby>

      <ControlDetails isMobile={isMobile} isOpen={isDetailsOpen}>
        {tabs && activeTab && setActiveTab && setIsTabMenuOpen && (
          <>
            {isDesktop || isTablet || isLargeDesktop ? (
              <>
                <Tabs tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab} />
                <TabsLine />
              </>
            ) : (
              <TabsControl isMobile={isMobile}>
                {tabs.length !== 1 && (
                  <>
                    {isTabMenuOpen ? (
                      <CloseMenuIcon fill={theme.action.primary} onClick={() => setIsTabMenuOpen(!isTabMenuOpen)} style={{ cursor: 'pointer' }} />
                    ) : (
                      <BurgerMenuIcon fill={theme.action.primary} onClick={() => setIsTabMenuOpen(!isTabMenuOpen)} style={{ cursor: 'pointer' }} />
                    )}
                  </>
                )}
                <TabMobileWrap onClick={() => setActiveTab(activeTab)} isActive>
                  <TabTitle isActive isMobile={isMobile}>
                    {activeTab}
                  </TabTitle>
                </TabMobileWrap>
              </TabsControl>
            )}
          </>
        )}

        <HideDetailsWrap withoutTabs={!tabs} isMobile={isMobile} onClick={() => setIsDetailsOpen(!isDetailsOpen)}>
          <ArrowBtn isRotate={isDetailsOpen}>
            <SortIcon fill={theme.action.primary} />
          </ArrowBtn>
          <ControlDetailsTitle isMobile={isMobile}>{isDetailsOpen ? 'Hide Details' : 'Show Details'}</ControlDetailsTitle>
        </HideDetailsWrap>
      </ControlDetails>
    </DetailsWrap>
  );
};

const ItemValues = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px;
  max-height: 24px;
  background-color: ${({ theme }) => theme.layer[2]};
  color: ${({ theme }) => theme.font.base};
  border-radius: 10px;
  min-width: 140px;
  font-family: Blinker, serif;
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0;
`;

const ArrowBtn = styled.div<{ isRotate: boolean }>`
  transform: ${({ isRotate }) => (isRotate ? 'rotate(180deg)' : 'rotate(0deg)')};
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.3s;
`;

const ControlDetailsTitle = styled.div<{ isMobile: boolean }>`
  font-family: Blinker, serif;
  font-size: ${({ isMobile }) => (isMobile ? '13px' : '16px')};
  font-weight: 600;
  line-height: 22px;
  color: ${({ theme }) => theme.action.primary};
`;

const HideDetailsWrap = styled.div<{ isMobile: boolean; withoutTabs: boolean }>`
  position: relative;
  top: ${({ isMobile, withoutTabs }) => (isMobile || withoutTabs ? '0' : '15px')};
  margin-left: auto;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 10px 0;
  gap: 12.5px;
`;

const ItemValue = styled.div`
  font-family: Blinker, serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  color: ${({ theme }) => theme.font.base};
`;

const ItemsWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
`;

const ItemTitle = styled.div`
  font-family: Blinker, serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  color: ${({ theme }) => theme.font.base};
  min-width: 150px;
`;

const ItemRow = styled.div<{ isMobile: boolean }>`
  display: flex;
  justify-content: ${({ isMobile }) => (isMobile ? 'space-between' : 'flex-start')};
  width: 100%;
  gap: 10px;
  @media (max-width: 1100px) {
    gap: 5px;
  }
`;

const MoreDetailItem = styled.div<{ isMobile: boolean }>`
  display: flex;
  flex-direction: column;
  width: ${({ isMobile }) => (isMobile ? '100%' : '32%')};
  gap: 14px;
  padding: 30px;
  @media (max-width: 1100px) {
    padding: 15px;
  }
  @media (max-width: 1079px) {
    padding: 15px 60px;
  }
  @media (max-width: 445px) {
    padding: 15px 20px;
  }
`;

const CardsContainer = styled.div<{ isOpen: boolean; isMobile: boolean }>`
  display: flex;
  flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};
  align-items: ${({ isMobile }) => (isMobile ? 'center' : 'flex-start')};
  gap: 10px;
`;

const MoreDetailsWrapper = styled.div`
  margin-top: 15px;
`;

const MoreDetails = styled.div<{ isOpen: boolean; isMobile: boolean }>`
  display: flex;
  flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};
  align-items: ${({ isMobile }) => (isMobile ? 'center' : 'flex-start')};
  border-radius: 10px;
  justify-content: space-between;
`;

const CardTitle = styled.div`
  color: ${({ theme }) => theme.font.strong};
  font-family: Blinker, serif;
  font-size: 23px;
  font-weight: 700;
  line-height: 32px;
  text-align: center;
`;

const CardDesc = styled.div`
  color: ${({ theme }) => theme.font.weak};
  font-family: Blinker, serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0;
  text-align: center;
`;

const Card = styled.div<{ short?: boolean; isMobile?: boolean }>`
  display: flex;
  cursor: ${({ short }) => (short ? 'pointer' : 'default')};
  width: ${({ short, isMobile }) => (short ? '25.3%' : isMobile ? '100%' : '50.9%')};
  //height: 80px;
  min-height: 80px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.layer.base};
  border-radius: 10px;
`;

const DetailsWrap = styled.div<{ isOpen: boolean; isMobile: boolean }>`
  display: flex;
  width: calc(100%);
  /* margin-left: -60px; */
  flex-direction: column;
  padding: ${({ isMobile }) => (isMobile ? '10px 20px 0 20px' : '20px 60px 0 60px')};
  background-color: ${({ theme }) => theme.layer[1]};
  border-bottom: 1px solid ${({ theme }) => theme.border.base};
  transition: 1s;
`;

const ControlDetails = styled.div<{ isMobile: boolean; isOpen: boolean }>`
  display: flex;
  justify-content: space-between;
  padding-top: ${({ isMobile, isOpen }) => (isMobile && isOpen ? '25px' : '0')};
`;

const DetailsBoby = styled.div<{ isOpen: boolean; isMobile: boolean }>`
  opacity: ${({ isOpen }) => (isOpen ? '1' : '0')};
  display: grid;
  grid-template-rows: ${({ isOpen }) => (isOpen ? '1fr' : '0fr')};
  overflow: ${({ isOpen }) => (isOpen ? 'visible' : 'hidden')};
  transition: grid-template-rows 300ms;
`;

const ExpandableWrapp = styled.div<{ isMobile: boolean; isOpen: boolean }>`
  min-height: 0;
`;
