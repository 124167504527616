import { useState } from 'react';

import { IInfoIcon } from '@/assets/icons/info-icons/i-info';
import DropdownActions from '@/components/table/fat-dropdownActions';
import { MobileRow, MobileRowTitle, MobileRowValue, MobileTableWrapper, NoResults } from '@/components/table/mobileTable/mobileTable';
import { useAuth } from '@/hooks/use-auth';
import { dateFormatter } from '@/utils/dateFormatter';
import { format } from 'date-fns';
import styled, { css, useTheme } from 'styled-components';
import guid from '../../../utils/guid';
import BasicTooltip from '../../basicComponents/tooltip';
import { OPERATIONS_STATUS } from '../../opportunities-entity/proposed';
import { EmptyDropdownActions } from '../../table/dropdownActions';
import { blinkingFrame } from '../../table/styles';
import { IItems, TableColumns } from '../../table/types';
import { ModalWindowType } from '../fat-modals/types';
import { IRowData } from '../types';
import Amount from './cell/amount';
import NextStep from './cell/nextStep';

type MobileTableProps = {
  columns: TableColumns[];
  recommendationsRows: IRowData[] | [];
  updateLoading: boolean;
  openModalWindow: (type: ModalWindowType, row: IRowData) => void;
  currentRow: IRowData | null;
  changeSelectRow: (row: IRowData, entityName: string) => void;
  actionsByStatus: (row: IRowData) => IItems[];
  onClickEntity: (data: IRowData) => void;
  onClickInvestment: (data: IRowData) => void;
  onClickLastUpdate: (data: IRowData) => void;
  openInvestorDetails: (id: string) => void;
  openOpportunityDetailsPage: (id: string) => void;
  openEntityDetails: (investorId: string, entityId: string) => void;
};

const MobileTable = ({
  columns,
  recommendationsRows,
  openModalWindow,
  currentRow,
  changeSelectRow,
  actionsByStatus,
  updateLoading,
  onClickEntity,
  onClickInvestment,
  onClickLastUpdate,
  openInvestorDetails,
  openOpportunityDetailsPage,
  openEntityDetails
}: MobileTableProps) => {
  const theme = useTheme();

  const [selectedRow, setSelectedRow] = useState<string>();

  const TableRow = ({ el }: { el: IRowData }) => {
    const { user } = useAuth();

    return (
      <MobileTableRowWrap
        key={guid()}
        loading={updateLoading && el.id === currentRow?.id}
        onClick={() => setSelectedRow(el.id)}
        isSelected={el.id === selectedRow}
      >
        {user && user.tenant.type === 'fundManager' && (
          <MobileRow>
            <MobileRowTitle>{columns[0].title}</MobileRowTitle>
            <MobileRowValue>{el.legalEntity.tenant.name}</MobileRowValue>
          </MobileRow>
        )}

        <MobileRow>
          <MobileRowTitle>{columns[1].title}</MobileRowTitle>
          <MobileRowValue isRedirect onClick={() => openInvestorDetails(el.legalEntity.family.id)}>
            {el.legalEntity.family.name}
          </MobileRowValue>
        </MobileRow>

        <MobileRow>
          <MobileRowTitle>{columns[2].title}</MobileRowTitle>
          <MobileRowValue isRedirect onClick={() => openEntityDetails(el.legalEntity?.family.id, el.legalEntity?.id)}>
            {el.legalEntity.entityName}
          </MobileRowValue>
        </MobileRow>

        <MobileRow>
          <MobileRowTitle>{columns[3].title}</MobileRowTitle>
          <MobileRowValue isRedirect onClick={() => openOpportunityDetailsPage(el.investment.id)}>
            {el.investment.name}
          </MobileRowValue>
        </MobileRow>

        <MobileRow>
          <MobileRowTitle>{columns[4].title}</MobileRowTitle>
          <AmountMobileRowValue>
            {el.committedCapital !== el.legalEntity.biteSize &&
              !(
                [OPERATIONS_STATUS.READY_FOR_CLIENT_REVIEW, OPERATIONS_STATUS.SENT_TO_CLIENT].includes(el.operationsStatus) &&
                el.reason === 'Always Show'
              ) && (
                <BasicTooltip tooltipContent={'Differs from default bite size'}>
                  <IconWrapper>
                    <IInfoIcon
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyItems: 'center'
                      }}
                      fill={theme.context.warning}
                    />
                  </IconWrapper>
                </BasicTooltip>
              )}
            {!el.isAmountHidden ? (
              <Amount
                isMobile
                showChangeAmountButton={Boolean(user && user.tenant.type === 'fundManager')}
                amount={el.committedCapital}
                openWindow={() => openModalWindow('amount', el)}
              />
            ) : null}
          </AmountMobileRowValue>
        </MobileRow>

        <MobileRow>
          <MobileRowTitle>{columns[5].title}</MobileRowTitle>
          <MobileRowValue>
            {el.assignee && `${el.assignee.firstName && el.assignee?.firstName} ${el.assignee?.lastName && el.assignee.lastName}`}
          </MobileRowValue>
        </MobileRow>

        <MobileRow>
          <MobileRowTitle>{columns[6].title}</MobileRowTitle>
          <MobileRowValue isRedirect onClick={() => onClickLastUpdate(el)}>
            {dateFormatter(format(new Date(el.lastUpdatedAt), 'yyyy-MM-dd'), 'yyyy-MM-dd')}
          </MobileRowValue>
        </MobileRow>

        <MobileRow>
          <MobileRowTitle>{columns[7].title}</MobileRowTitle>
          <MobileRowValue>
            <NextStep width={'80%'} status={el.operationsWorkflowTransitionTask.name} />
            {actionsByStatus(el).length && el.id === selectedRow ? (
              <DropdownActions isMobile items={actionsByStatus(el)} disabled={updateLoading && el.id === currentRow?.id} />
            ) : (
              <EmptyDropdownActions />
            )}
          </MobileRowValue>
        </MobileRow>
      </MobileTableRowWrap>
    );
  };

  return (
    <MobileTableWrapper>
      {recommendationsRows.length ? (
        recommendationsRows.map((el, index) => {
          return <TableRow el={el} key={el.id + index} />;
        })
      ) : (
        <NoResults>No Results</NoResults>
      )}
    </MobileTableWrapper>
  );
};

export default MobileTable;

const AmountMobileRowValue = styled(MobileRowValue)`
  gap: 5px;
`;
const IconWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const MobileTableRowWrap = styled.div<{
  zIndex?: number;
  loading?: boolean;
  isSelected?: boolean;
}>`
  //z-index: 30;
  width: 100%;
  padding: 14px 24px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  background-color: ${({ isSelected, theme }) => (isSelected ? theme.layer[12] : theme.layer[1])};
  border-radius: 8px;
  opacity: ${({ loading }) => (loading ? 0.5 : 1)};
  pointer-events: ${({ loading }) => (loading ? 'none' : 'auto')};
  animation: ${({ loading }) => (loading ? blinkingFrame : null)} 1.5s infinite;
  ${({ zIndex }) =>
    zIndex &&
    css`
      z-index: ${zIndex};
    `}
`;
