import { AppStateProvider } from '@/providers/AppStateProvider';
import { ApolloProvider } from '@apollo/client';
import { CookiesProvider } from 'react-cookie';
import ReactDOM from 'react-dom/client';
import { StyleSheetManager, ThemeProvider } from 'styled-components';
import App from './App';
import './index.css';
import reportWebVitals from './reportWebVitals';

import client from '@/apollo-client';

import isPropValid from '@emotion/is-prop-valid';
import { QueryClient } from 'react-query';
import { AuthProvider } from './contexts/firebase-auth-context';
import { ResponsiveProvider } from './contexts/responsive-context';
import { StatusDataProvider } from './providers';

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <ApolloProvider client={client}>
    <ResponsiveProvider>
      <AuthProvider>
        <StyleSheetManager
          enableVendorPrefixes
          shouldForwardProp={(propName, elementToBeRendered) => {
            return typeof elementToBeRendered === 'string' ? isPropValid(propName) : true;
          }}
        >
          <ThemeProvider theme={JSON.parse(process.env.REACT_APP_THEME)}>
            <CookiesProvider>
              <AppStateProvider>
                <StatusDataProvider>
                  <App />
                </StatusDataProvider>
              </AppStateProvider>
            </CookiesProvider>
          </ThemeProvider>
        </StyleSheetManager>
      </AuthProvider>
    </ResponsiveProvider>
  </ApolloProvider>
);
reportWebVitals();
