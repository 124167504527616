import { DatePicker } from '@/components/fat-form-field';
import { useResponsive } from '@/hooks/use-responsive';
import styled from 'styled-components';
import { Section } from '../../section';

interface DatesSectionProps {
  control: any;
  onBlur?: any;
  errors: any;
  setError: any;
  isModify: boolean;
}

const OWNER_TOOL_TIP = 'Each owner will be the default assignee for relevant tasks associated with this investment.';

export const DatesSection = ({ control, onBlur, errors, setError, isModify }: DatesSectionProps) => {
  const { isMobile } = useResponsive();

  return (
    <Section title="Dates">
      <DatesSectionWrap>
        <Line isMobile={isMobile}>
          <DatePicker
            title={'Investor Docs Due'}
            nameField={'investorDocsDue'}
            control={control}
            errors={errors}
            tooltip={'Provide advisors with a due date for investor docs.'}
          />
          <DatePicker
            title={'Next Close'}
            nameField={'nextClose'}
            control={control}
            errors={errors}
            tooltip={'Set the Next Close date that you wish to track.  You can edit this date at any time.'}
            tooltipWidth={250}
          />
          <DatePicker title={'Final Close'} nameField={'finalClose'} control={control} errors={errors} tooltip={'Date the fund closes.'} />
        </Line>
      </DatesSectionWrap>
    </Section>
  );
};

const DatesSectionWrap = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 20px;
`;

const Line = styled.span<{ isMobile: boolean; width50?: boolean }>`
  display: flex;
  justify-content: space-between;
  flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};
  width: ${({ width50, isMobile }) => (width50 && !isMobile ? '50%' : '100%')};
  gap: 30px;
  padding-right: ${({ width50, isMobile }) => (width50 && !isMobile ? '15px' : '0')};
  margin-top: 4px;
`;
