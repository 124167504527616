import { RedStarIcon } from '@/assets/icons/info-icons/redStar';
import { useResponsive } from '@/hooks/use-responsive';
import styled, { useTheme } from 'styled-components';
import Button from '../button';

interface FormFooterProps {
  onCancel?: () => void;
  onSave?: () => void;
  showRequiredFieldsWarning?: boolean;
  disableSaveButton: boolean;
}

export const FormFooter = ({ disableSaveButton, showRequiredFieldsWarning = true, onCancel, onSave }: FormFooterProps) => {
  const { isMobile } = useResponsive();
  const theme = useTheme();

  return (
    <Footer isMobile={isMobile}>
      <WarningAboutFields>
        {showRequiredFieldsWarning && (
          <>
            <RedStarIcon width={12} fill={theme.context.error} />
            <RequiredText>Required fields</RequiredText>
          </>
        )}
      </WarningAboutFields>
      <Buttons isMobile={isMobile}>
        <Button styleType="outline" onClick={onCancel} type="reset" size="lg" width="100%">
          Cancel
        </Button>
        <Button styleType={disableSaveButton ? 'disabled' : 'default'} onClick={onSave} type="submit" size="lg" width="100%">
          Save
        </Button>
      </Buttons>
    </Footer>
  );
};

const Footer = styled.div<{ isMobile: boolean }>`
  margin: 25px 0;
  display: flex;
  justify-content: space-between;
  gap: 10px;
  flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};
`;

const WarningAboutFields = styled.div`
  display: flex;
  align-items: center;
  gap: 7px;
  grid-column: 3/5;
  justify-content: center;
`;

const RequiredText = styled.span`
  font-family: Inter;
  font-size: 13px;
  color: ${({ theme }) => theme.font.weak};
`;

const Buttons = styled.div<{ isMobile: boolean }>`
  display: flex;
  justify-content: flex-end;
  column-gap: 10px;
  margin: ${({ isMobile }) => (isMobile ? '0 auto' : '0')};
  width: ${({ isMobile }) => (isMobile ? '100%' : '30%')};
  max-width: 460px;
`;
