import FilterSelect from '@/components/basicComponents/select';
import { IListEntity } from '@/components/fat-investors-page/types';
import { IEntityCodesTableData } from '@/components/fat-settings-page/importData/types';
import NoResults from '@/components/table/noResults';
import { TableRowCell, TableRowWrapper, TextCell } from '@/components/table/styles';
import styled from 'styled-components';
import { columns } from '../constants';
import { RowStatus } from './cell/rowStatus';

interface TableBodyProps {
  tableData: IEntityCodesTableData[];
  entityList: IListEntity[];
  entityListLoading: boolean;
  handleChangeAssignment: (entityName: string, prevValue: string, entityCode: string) => void;
  resetAssignment: (entityCode: string, prevValue: string) => void;
}

const TableBody = ({ tableData, entityList, entityListLoading, handleChangeAssignment, resetAssignment }: TableBodyProps) => {
  if (!tableData?.length) {
    return <NoResults />;
  }

  return (
    <>
      {tableData.map((row) => (
        <CustomTableRowWrapper key={row.id}>
          <TableRowCell width={columns[0].width}>
            <TextCell>{row.entityCode}</TextCell>
          </TableRowCell>
          <TableRowCell width={columns[1].width}>
            <TextCell>{row.entityName}</TextCell>
          </TableRowCell>
          <TableRowCell width={columns[2].width}>
            {row.entityStatus === 'Assigned' ? (
              <TextCell>{row.entityAssignment}</TextCell>
            ) : (
              <AssignmentSelect
                data={entityList.map((entity) => `${entity.entityName} (${entity.tenant.name})`)}
                selected={row.entityAssignment}
                setSelected={(value) => handleChangeAssignment(value, row.entityAssignment, row.entityCode)}
                width="100%"
                openZIndex={35}
                withSearch
                loading={entityListLoading}
                clearSelectionButton={{ defaultValue: 'Select An Entity', onClick: () => resetAssignment(row.entityCode, row.entityAssignment) }}
              />
            )}
          </TableRowCell>
          <TableRowCell width={columns[3].width}>
            <TextCell>
              <RowStatus status={row.entityStatus} />
            </TextCell>
          </TableRowCell>
        </CustomTableRowWrapper>
      ))}
    </>
  );
};

export default TableBody;

const CustomTableRowWrapper = styled(TableRowWrapper)`
  justify-content: space-between;
`;

const AssignmentSelect = styled(FilterSelect)`
  border-radius: 4px;
  #titleSelect {
    color: ${({ theme }) => theme.font.strong};
    font-size: 16px;
    line-height: 22.4px;
  }
`;
