import React from 'react';
import styled from 'styled-components';

interface CircleButtonRowProps {
  children: React.ReactNode;
  onClick?: () => void;
  background?: string;
  withoutHover?: boolean;
}

const CircleButtonRow = ({ children, onClick, background, withoutHover = false }: CircleButtonRowProps) => {
  return (
    <ButtonContainer id={'infoWrap'} onClick={onClick} background={background} withoutHover={withoutHover}>
      {children}
    </ButtonContainer>
  );
};

export default CircleButtonRow;

const ButtonContainer = styled.div<{ background?: string; withoutHover: boolean }>`
  cursor: pointer;
  width: 23px;
  height: 23px;
  aspect-ratio: 1/1;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  border-radius: 50px;
  background: ${({ background }) => background ?? '#ffffff'};
  margin: auto 0 auto 3px;
  &:hover {
    background-color: ${({ withoutHover }) => (withoutHover ? '#ffffff' : '#3ab070')};
    svg {
      path {
        fill: ${({ withoutHover }) => (withoutHover ? null : '#fff')};
      }
    }
  }
`;
