import TableHeader from '@/components/table/fat-tableHeader';
import { ISort } from '@/components/table/types';
import { useResponsive } from '@/hooks/use-responsive';
import styled from 'styled-components';
import { Loader } from '../../loaders/loader';
import { TableBodyWrapper, TableWrapper } from '../../table/styles';
import { otherRecommendedTableColumns } from '../constants';
import { IInvestorInterestList, TypeWindow } from '../types';
import MobileTable from './mobileTable';
import TableBody from './tableBody';

interface OtherRecommendedTableProps {
  openPage: (id: string, type: TypeWindow) => void;
  loading: boolean;
  otherRecommendedData: IInvestorInterestList[];
  refetch: () => void;
  sort: ISort;
  setSort: (value: ISort) => void;
}

const OtherRecommendedTable = ({ openPage, loading, otherRecommendedData, refetch, sort, setSort }: OtherRecommendedTableProps) => {
  const { isMobile, isTablet } = useResponsive();

  return (
    <TableWrapper padding="0">
      {isMobile ? (
        <>{loading ? <Loader /> : <MobileTable otherRecommendedData={otherRecommendedData} openPage={openPage} />}</>
      ) : (
        <>
          <CustomTableHeader
            isTablet={isTablet}
            refetch={() => null as any}
            columns={otherRecommendedTableColumns}
            savedSort={sort}
            savedSetSort={setSort}
          />
          <TableBodyWrapper>{loading ? <Loader /> : <TableBody otherRecommendedData={otherRecommendedData} openPage={openPage} />}</TableBodyWrapper>
        </>
      )}
    </TableWrapper>
  );
};

export default OtherRecommendedTable;

const CustomTableHeader = styled(TableHeader)<{ isTablet: boolean }>`
  position: sticky;
  /* top: ${({ isTablet }) => (isTablet ? '189px' : '139px')}; */
  top: ${({ isTablet }) => (isTablet ? '130px' : '80px')};
  z-index: 8;
  padding-top: 0;
`;
