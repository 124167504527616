export const columns = [
  { title: 'Entity', key: 'entityName', keySort: 'entity' },
  { title: 'Entity Value', key: 'totalEntityValue', keySort: 'entity value' },
  { title: 'Private Capital', key: 'privatePercentage', keySort: 'private capital' },
  { title: 'Oc Ratio', key: 'overCommitRatio', keySort: 'oc ratio' },
  { title: 'Economic Exposure', key: 'economicExposure', keySort: 'economic exposure' },
  { title: 'Capacity', key: 'capacity', keySort: 'capacity' }
];

export const mobileTableSort = [
  {
    title: 'Entity • A -> Z',
    key: 'entityName',
    asc: true
  },
  {
    title: 'Entity • Z -> A',
    key: 'entityName',
    asc: false
  },
  {
    title: 'Entity Value • Max -> Min',
    key: 'totalEntityValue',
    asc: false
  },
  {
    title: 'Entity Value • Min -> Max',
    key: 'totalEntityValue',
    asc: true
  },
  {
    title: 'Private Capital • Max -> Min',
    key: 'privatePercentage',
    asc: false
  },
  {
    title: 'Private Capital • Min -> Max',
    key: 'privatePercentage',
    asc: true
  },
  {
    title: 'Oc Ratio • Max -> Min',
    key: 'overCommitRatio',
    asc: false
  },
  {
    title: 'Oc Ratio • Min -> Max',
    key: 'overCommitRatio',
    asc: true
  },
  {
    title: 'Economic Exposure • Max -> Min',
    key: 'economicExposure',
    asc: false
  },
  {
    title: 'Economic Exposure • Min -> Max',
    key: 'economicExposure',
    asc: true
  },
  {
    title: 'Capacity • Max -> Min',
    key: 'capacity',
    asc: false
  },
  {
    title: 'Capacity • Min -> Max',
    key: 'capacity',
    asc: true
  }
];
