import { FolderIcon } from '@/assets/static/icons/folder';
import { Dispatch, SetStateAction } from 'react';
import { dataRoomColumns } from '@/components/dueDiligence-page/constatnts';
import { Loader } from '@/components/loaders/loader';
import DropdownActions from '@/components/table/dropdownActions';
import {
  TableBodyWrapper,
  TableRowCell,
  TableRowWrapper,
  TableWrapper,
  TextCell
} from '@/components/table/styles';
import TableHeader from '@/components/table/tableHeader';
import { ISort } from '@/components/table/types';
import guid from '@/utils/guid';
import styled from 'styled-components';

type TableProp = {
  sort: ISort;
  setSort: Dispatch<SetStateAction<ISort>>;
  refetch: any;
  files: any;
  loading: boolean;
};

export const Table = ({
  files,
  sort,
  setSort,
  refetch,
  loading
}: TableProp) => {
  return (
    <TableWrapper>
      <TableHeader
        refetch={refetch}
        columns={dataRoomColumns}
        savedSort={sort}
        savedSetSort={setSort}
      />
      <TableBodyWrapper>
        {loading ? (
          <Loader />
        ) : (
          <>
            {files.map((row: any, index: number) => (
              <TableRowWrapper key={guid()}>
                <TableRowCell width={dataRoomColumns[0].width}>
                  <FolderCell color="#4587ec">
                    <FolderIcon />
                    {row.name}
                  </FolderCell>
                </TableRowCell>
                <TableRowCell width={dataRoomColumns[1].width}>
                  <TextCell>{row.lastModified}</TextCell>
                </TableRowCell>
                <TableRowCell width={dataRoomColumns[2].width}>
                  <TextCell>{row.fileSize ? row.fileSize : '--'}</TextCell>
                </TableRowCell>
                <TableRowCell width={dataRoomColumns[3].width}>
                  <DropdownActions
                    items={[{ name: 'Open Folder', onClick: () => null }]}
                  />
                </TableRowCell>
              </TableRowWrapper>
            ))}
          </>
        )}
      </TableBodyWrapper>
    </TableWrapper>
  );
};

const FolderCell = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 16px;
  font-family: Blinker;
  font-weight: 600;
  color: #4587ec;
  cursor: pointer;
`;
