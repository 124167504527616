export enum SELECTORS {
  ALL_ADVISORS = 'All Advisors',
  ALL_SUITABILITY = 'All Suitability',
  ALL_STATUSES = 'All Statuses',
  ALL_ALLOCATION_STATUSES = 'All Allocation Statuses',
  ALL_INVESTMENTS = 'All Investments',
  ALL_ADVISORY_FIRM = 'All Advisory Firm',
  ANY_DECISION = 'Any Decision',
  ANY_NEXT_TASK = 'Any Next Task',
}
