import ModalWrappedContent from '@/components/fat-basicComponents/modal/modalWrappedContent';
import styled from 'styled-components';

interface DiscardChangesProps {
  isOpen: boolean;
  onClose: () => void;
  onSave: () => void;
}

const DiscardChanges = ({ isOpen, onClose, onSave }: DiscardChangesProps) => {
  return (
    <ModalWrappedContent
      isOpen={isOpen}
      onClose={onClose}
      confirmButton={{
        name: 'Discard Changes',
        onClick: onSave
      }}
    >
      <ModalBody>
        <ModalTitle>You Have Unsaved Changes</ModalTitle>
        Closing this page will discard your changes. Click Discard Changes to proceed or Cancel to continue editing.
      </ModalBody>
    </ModalWrappedContent>
  );
};

export default DiscardChanges;

const ModalTitle = styled.div`
  color: ${({ theme }) => theme.font.strong};
  text-align: center;
  font-size: 33px;
  font-weight: 400;
  line-height: 46.2px;
`;

const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  color: ${({ theme }) => theme.font.base};
  text-align: center;
  font-family: Blinker;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22.4px;
  margin: 40px 0;
`;
