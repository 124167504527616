import { useNavigate, useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Button from '@/components/basicComponents/button';
import {
  CloseBtn,
  ModalControlWrap,
  ModalPageTitle
} from '@/components/client-details';
import { StyledTextarea } from '@/components/dueDiligence-page/modals/changeAssignee';
import {
  ButtonWrapper,
  ControlWrapper
} from '@/components/dueDiligence-page/modifyDiligence';
import Header from '@/components/header';
import { useResponsive } from '@/hooks/use-responsive';
import { CloseInfoIcon } from '@/assets/icons/info-icons/closeInfo';
import { MainWrap, PaddingWrap } from '@/styles/common';
import styled from 'styled-components';
import { IFirmSettings } from '.';
import { MarginTop, SettingsContainer } from '../importData';

interface EditDisclosureTextProps {
  handleClose: () => void;
  createFirmSetting: (type: string, key: string, value: string) => void;
  updateFirmSetting: (id: string, value: string) => void;
  currentRow: IFirmSettings | null;
}

export const EditDisclosureText = ({
  handleClose,
  createFirmSetting,
  updateFirmSetting,
  currentRow
}: EditDisclosureTextProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const { isMobile, isTablet } = useResponsive();

  const [disclosureTextValue, setDisclosureTextValue] = useState('');

  useEffect(() => {
    if (currentRow?.value) {
      setDisclosureTextValue(currentRow.value);
    }
    return () => {
      setDisclosureTextValue('');
    };
  }, [currentRow]);

  const saveValue = (key: string) => {
    if (queryParams.get('id') !== 'null') {
      updateFirmSetting(queryParams.get('id') as string, disclosureTextValue);
      return;
    }
    createFirmSetting('templateStyling', key, disclosureTextValue);
  };

  return (
    <MainWrap>
      <Header
        modalControl={
          <ModalControlWrap>
            <CloseBtn onClick={handleClose}>
              <CloseInfoIcon width={18} height={18} />
            </CloseBtn>
            <ModalPageTitle isTablet={isTablet} isMobile={isMobile}>
              {queryParams.get('fieldName')}
            </ModalPageTitle>
          </ModalControlWrap>
        }
      />
      <MarginTop />
      <PaddingWrap>
        <SettingsContainer isMobile={isMobile}>
          <DisclosureTextArea
            value={disclosureTextValue}
            onChange={(event) => setDisclosureTextValue(event.target.value)}
            height="250px"
          />
          <CustomControlWrapper isMobile={isMobile}>
            <ButtonWrapper>
              <CancelButton
                isMobile={isMobile}
                onClick={handleClose}
                disabled={false}
              >
                Cancel
              </CancelButton>
              <SaveButton
                isMobile={isMobile}
                onClick={() => saveValue(queryParams.get('edit') as string)}
                disabled={false}
              >
                Save
              </SaveButton>
            </ButtonWrapper>
          </CustomControlWrapper>
        </SettingsContainer>
      </PaddingWrap>
    </MainWrap>
  );
};

const CustomControlWrapper = styled(ControlWrapper)`
  justify-content: flex-end;
  margin: 30px 0;
`;

const DisclosureTextArea = styled(StyledTextarea)`
  color: #7f7f7f;
  font-weight: 300;
  border-radius: 4px;
`;

const CancelButton = styled(Button)<{ isMobile: boolean }>`
  background: white;
  border: 1px solid #4587ec;
  color: #4587ec;
  padding: ${({ isMobile }) => (isMobile ? '8.5px 40.5px' : '11.5px 86.5px')};
`;

const SaveButton = styled(Button)<{ isMobile: boolean }>`
  background: ${({ disabled }) => (disabled ? '#dedede' : '#1c488a')};
  padding: ${({ isMobile }) => (isMobile ? '8.5px 50.5px' : '11.5px 93.5px')};
`;
