import React, { Dispatch, SetStateAction } from 'react';
import styled, { css } from 'styled-components';

import { useResponsive } from '../../../../hooks/use-responsive';
import { InfoIcon } from '@/assets/icons/info-icons/info';
import Switcher from '../../../basicComponents/switcher';
import BasicTooltip from '../../../basicComponents/tooltip';
import { formatByType } from './formatSettings';
import SectorsToAvoid from './sectorsToAvoid';
import { InfoListRow } from './types';

interface InfoListProps {
  data: InfoListRow[];
  isSwitcherActive?: boolean;
  setIsSwitcherActive?: Dispatch<SetStateAction<boolean>>;
  label?: string;
  isTwoColumn?: boolean;
}

export const InfoList = ({
  isSwitcherActive,
  setIsSwitcherActive,
  label,
  data,
  isTwoColumn = false
}: InfoListProps) => {
  const changeSwitcher = () => setIsSwitcherActive?.(!isSwitcherActive);
  const checkISArrayFilled = (array: string[]) =>
    !!array.filter((item) => item).length;
  const { isMobile } = useResponsive();

  const cleanData = () =>
    data.filter(({ value }) => {
      if (Array.isArray(value)) return checkISArrayFilled(value);
      return value !== null && value !== undefined;
    });

  return (
    <MoreDataWrap>
      {isSwitcherActive !== undefined && (
        <WrapperSwitcher>
          <Switcher isActive={isSwitcherActive} onClick={changeSwitcher} />
          {label ?? ''}
        </WrapperSwitcher>
      )}
      <ElementsWrap isTwoColumn={!isMobile && isTwoColumn}>
        {cleanData().map(({ name, tooltipName, value, formatType }) => (
          <MoreDataItem isTwoColumn={!isMobile && isTwoColumn} key={name}>
            <MoreDataItemTitle isMobile={isMobile}>
              <p>{name}</p>
              {tooltipName && (
                <BasicTooltip tooltipContent={tooltipName}>
                  <InfoIcon
                    fill={'#ABABAB'}
                    style={{ position: 'relative', top: '3px' }}
                  />
                </BasicTooltip>
              )}
            </MoreDataItemTitle>
            {Array.isArray(value) ? (
              <SectorsToAvoid list={value} />
            ) : (
              <MoreDataItemValue isMobile={isMobile}>
                {formatByType[formatType](value as never)}
              </MoreDataItemValue>
            )}
          </MoreDataItem>
        ))}
      </ElementsWrap>
    </MoreDataWrap>
  );
};

const WrapperSwitcher = styled.div`
  display: flex;
  column-gap: 10px;
  margin-bottom: 22px;
`;

const MoreDataItemValue = styled.div<{ isMobile?: boolean }>`
  font-family: Blinker, serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0;
  text-align: left;
  width: ${({ isMobile }) => (isMobile ? '50%' : '180px')};
`;

const MoreDataItemTitle = styled.div<{ isMobile?: boolean }>`
  display: flex;
  align-items: center;
  gap: ${({ isMobile }) => (isMobile ? '20px' : '0')};
  font-family: Blinker, serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0;
  text-align: left;
  width: ${({ isMobile }) => (isMobile ? '50%' : '150px')};
  min-width: ${({ isMobile }) => (isMobile ? '50%' : '150px')};
`;

const MoreDataItem = styled.div<{ isTwoColumn: boolean }>`
  width: ${({ isTwoColumn }) => (isTwoColumn ? '50%' : '100%')};
  display: flex;
  align-items: center;
  padding: 5px 20px;
  background-color: #ffffff;
  gap: 10px;
  border-radius: 4px;
`;

const ElementsWrap = styled.div<{ isTwoColumn: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 3px;
  ${({ isTwoColumn }) =>
    isTwoColumn
      ? css`
          flex-wrap: wrap;
          max-height: 300px;
        `
      : ''}
`;

const MoreDataWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3px;
`;
