import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import { CloseInfoIcon } from '@/assets/icons/info-icons/closeInfo';
import { useClickOutside } from '../../../hooks/useClickOutside';
import Button from '../../basicComponents/button';
import CircleButtonRow from '../circleButtonRow';
import RedirectTitleCell from '../redirectTitleCell';

interface AnchorPopoverProps {
  iconButton?: React.ReactNode;
  children: React.ReactNode;
  rowBody?: React.ReactNode;
  title: string | any;
  nameCell?: string;
  hyperTitle?: () => void;
  limitNameCell?: boolean;
  className?: string;
  showLeft?: boolean;
  requestMakesInChildren?: boolean;
  onRedirect?: () => void;
  hoverControl?: boolean;
  showUnderContent?: boolean;
  showAboveContent?: boolean;
  zIndex?: number;
  isMobile?: boolean;
  mobileNotes?: boolean;
  isTwoColumn?: boolean;
}

export const AnchorPopover = ({
  isMobile = false,
  rowBody,
  iconButton,
  title,
  nameCell,
  children,
  hyperTitle,
  showLeft = false,
  className,
  hoverControl,
  onRedirect,
  requestMakesInChildren = false,
  limitNameCell = false,
  showUnderContent = false,
  showAboveContent = false,
  zIndex = 10,
  mobileNotes = false,
  isTwoColumn = false
}: AnchorPopoverProps) => {
  const windowWidth = window.innerWidth;
  const [isOpenDropDown, setIsOpenDropDown] = useState(false);
  const [showPopover, setShowPopover] = useState(false);
  const [top, setTop] = useState(0);
  const ref = useRef<HTMLDivElement>(null);
  const cellRef = useRef<HTMLDivElement>(null);
  const dropDown = useRef<HTMLDivElement>(null);

  useClickOutside(ref, () => handleCloseDropdown());

  useEffect(() => {
    if (showUnderContent || showAboveContent || isMobile) return;
    const HEADER_HEIGHT = 80;
    const windowHeight = window.innerHeight;
    if (!dropDown.current) return;
    if (!cellRef.current) return;
    const { top } = cellRef.current.getBoundingClientRect();
    const { height } = dropDown.current.getBoundingClientRect();
    const freeSpace = windowHeight - HEADER_HEIGHT - height;
    setTop(0 - ((isTwoColumn ? 80 : 0) + top - 80 - freeSpace / 2));
  }, [cellRef.current, dropDown.current, isOpenDropDown]);

  const handleCloseDropdown = () => {
    setIsOpenDropDown(false);

    setTimeout(() => {
      setShowPopover(false);
    }, 250);
  };

  const handleDropdownChange = () => {
    setIsOpenDropDown(!isOpenDropDown);

    if (isOpenDropDown) {
      setTimeout(() => {
        setShowPopover(false);
      }, 250);
    } else {
      setShowPopover(true);
    }
  };

  return (
    <Container ref={ref} onMouseLeave={() => hoverControl && handleCloseDropdown()}>
      <ToggleAnchor
        onClick={() => !hoverControl && handleDropdownChange()}
        ref={cellRef}
        onMouseEnter={() => {
          if (hoverControl) {
            setIsOpenDropDown(true);
            setShowPopover(true);
          }
        }}
      >
        {nameCell && (
          <RedirectTitleCell color={isMobile ? '#4587EC' : '#373f4e'} limit={limitNameCell}>
            {nameCell}
          </RedirectTitleCell>
        )}
        {iconButton && <CircleButtonRow>{iconButton}</CircleButtonRow>}
        {rowBody && rowBody}
      </ToggleAnchor>
      {showPopover && (
        <DropDown
          mobileNotes={mobileNotes}
          minWidth={isMobile ? windowWidth - 80 : isTwoColumn ? 680 : 440}
          isMobile={isMobile}
          zIndex={zIndex}
          isOpen={isOpenDropDown}
          className={className}
          showLeft={showLeft}
          top={top}
          ref={dropDown}
          showUnderContent={showUnderContent}
          showAboveContent={showAboveContent}
        >
          <HeaderDropDown>
            <DropDownHeader>
              {hyperTitle ? <HyperTitle onClick={hyperTitle}>{title}</HyperTitle> : <Title>{title}</Title>}
              <CloseInfoIconWrap onClick={() => handleDropdownChange()}>
                <CloseInfoIcon />
              </CloseInfoIconWrap>
            </DropDownHeader>
            <Divider />
          </HeaderDropDown>
          {/* if request makes in children, we should render children only if isOpenDropDown is true*/}
          {requestMakesInChildren ? showPopover && children : children}
          {onRedirect && <RedirectButton onClick={onRedirect}>View {title}</RedirectButton>}
        </DropDown>
      )}
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  position: relative;
  height: fit-content;
  margin: auto 0;

  &:hover {
    #infoWrap {
      background-color: #3ab070;

      svg {
        path {
          fill: #fff;
        }
      }
    }
  }
`;

const DropDown = styled.div<{
  minWidth: number;
  zIndex: number;
  showLeft: boolean;
  top: number;
  isOpen: boolean;
  showUnderContent: boolean;
  showAboveContent: boolean;
  isMobile: boolean;
  mobileNotes: boolean;
}>`
  min-width: ${({ minWidth }) => `${minWidth}px`};
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  position: absolute;
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
  transition: opacity 250ms ease-in-out;
  top: calc(
    ${({ top, showUnderContent, showAboveContent, isMobile }) =>
      `${isMobile ? 0 : showUnderContent ? '100%' : showAboveContent ? '-519px' : `${top}px`} + ${
        showUnderContent ? 6 : showAboveContent ? -35 : 25
      }px`}
  );
  left: ${({ showLeft, showUnderContent, showAboveContent, isMobile, mobileNotes }) =>
    mobileNotes && isMobile
      ? 0
      : isMobile && !mobileNotes
        ? '-100%'
        : showLeft
          ? 'auto'
          : `${showUnderContent || showAboveContent ? '0px' : '100%'}`};
  right: ${({ showLeft, showUnderContent, showAboveContent, isMobile }) =>
    isMobile ? '0' : showLeft ? `${showUnderContent || showAboveContent ? '0' : 'calc(100% + 16px)'}` : 'auto'};
  z-index: ${({ zIndex }) => zIndex};
  background: #fafafa;
  border: 1px solid #c7cbd2;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.15);
  border-radius: 3px;
  padding: 20px 30px 30px;
`;

const HeaderDropDown = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.span`
  font-family: 'Blinker', serif;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  word-spacing: 4px;
  line-height: 25px;
  color: #2e2e2e;
  padding: 0 20px 0 0;
  margin: 0;
`;

const HyperTitle = styled.span`
  color: #4587ec;
  font-family: 'Blinker', serif;
  font-style: normal;
  font-weight: 600;
  word-spacing: 4px;
  line-height: 25px;
  font-size: 22px;
  padding: 0 20px 0 0;
  margin: 0;
  cursor: pointer;
`;
const DropDownHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const CloseInfoIconWrap = styled.div`
  cursor: pointer;
`;
const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: #c7cbd2;
  margin-top: 10px;
  margin-bottom: 22px;
`;
export const RedirectButton = styled(Button)`
  width: 100%;
  background: #fafafa;
  border: 1px solid #4587ec;
  border-radius: 4px;
  color: #4587ec;
  margin-top: 13px;
  padding: 10px 20px;
`;
const ToggleAnchor = styled.div`
  display: flex;
  align-items: center;
  gap: 3px;
`;
