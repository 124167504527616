import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import BasicTooltip from '../basicComponents/tooltip';
import FormattingTooltip from '../basicComponents/tooltip/dollarsTooltip';
import Switcher from '../fat-dealCatalog-page/fat-switcher';
import { ClientDetailsSummary } from '../fat-investors-page/fat-investorDetails-page/fat-entityDetails-page/queries';
import { Loader } from '../loaders/loader';
import { formatByType } from '../table/anchor-drop-down/infoList/formatSettings';
import SectorsToAvoid from '../table/anchor-drop-down/infoList/sectorsToAvoid';
import { InfoListRow } from '../table/anchor-drop-down/infoList/types';
import { IRowData } from './types';

interface InfoListProps {
  allocation: IRowData;
}

export const EntityInfoList = ({ allocation }: InfoListProps) => {
  const [switcher, setSwitcher] = useState(true);

  const { investmentType, assetClass, investmentName } = {
    investmentType: allocation.investment.type,
    assetClass: allocation.investment.assetClass,
    investmentName: allocation.investment.name
  };

  const { data, refetch, loading } = useQuery(ClientDetailsSummary, {
    notifyOnNetworkStatusChange: true,
    variables: {
      id: allocation.legalEntity.id,
      familyId: allocation.legalEntity.family.name,
      includeRecommendation: switcher,
      limit: 50,
      offset: 0
    }
  });

  useEffect(() => {
    refetch();
  }, []);

  const changeSwitcher = () => {
    setSwitcher(!switcher);
    refetch({
      id: allocation.legalEntity.id,
      familyId: allocation.legalEntity.family.name,
      includeRecommendation: !switcher
    });
  };

  if (data === undefined || loading) {
    return <Loader />;
  }

  const tooltipContent = `${assetClass} corresponds to\n${investmentName}.`;

  const entityPopover: InfoListRow[] = [
    {
      name: 'On Hold',
      formatType: 'yesNo',
      value: data.ClientEntityDetailsSummary.onHold
    },
    {
      name: 'Always Show',
      formatType: 'yesNo',
      value: data.ClientEntityDetailsSummary.alwaysShow
    },
    {
      name: 'Name',
      formatType: 'string',
      value: data.ClientEntityDetailsSummary.name
    },
    {
      name: 'Entity Value',
      formatType: 'JSX',
      value: <FormattingTooltip>{data.ClientEntityDetailsSummary.totalEntitiesValue}</FormattingTooltip>
    },
    {
      name: '% To Private Capital',
      formatType: 'percentage',
      value: data.ClientEntityDetailsSummary.privatePercentage
    },
    {
      name: 'Target OC Ratio',
      formatType: 'x',
      value: data.ClientEntityDetailsSummary.targetOCRatio
    },
    {
      name: 'Private Capital Target',
      formatType: 'JSX',
      value: (
        <FormattingTooltip>
          {data.ClientEntityDetailsSummary.summary.privateEquityTargetAllocations +
            data.ClientEntityDetailsSummary.summary.privateCreditTargetAllocations +
            data.ClientEntityDetailsSummary.summary.privateRealAssetTargetAllocations}
        </FormattingTooltip>
      )
    },
    {
      name: 'Overall Capacity',
      formatType: 'JSX',
      value: <FormattingTooltip>{data.ClientEntityDetailsSummary.summary.overallCapacity}</FormattingTooltip>
    },
    {
      name: 'Private Equity Capacity',
      tooltipName: allocation.investment.assetClass === 'Private Equity' ? tooltipContent : '',
      formatType: 'JSX',
      value: <FormattingTooltip>{data.ClientEntityDetailsSummary.summary.privateEquityCapacity}</FormattingTooltip>
    },
    {
      name: 'Private Credit Capacity',
      tooltipName: allocation.investment.assetClass === 'Private Credit' ? tooltipContent : '',
      formatType: 'JSX',
      value: <FormattingTooltip>{data.ClientEntityDetailsSummary.summary.privateCreditCapacity}</FormattingTooltip>
    },
    {
      name: 'Real Assets Capacity',
      tooltipName: allocation.investment.assetClass === 'Private Real Assets' ? tooltipContent : '',
      formatType: 'JSX',
      value: <FormattingTooltip>{data.ClientEntityDetailsSummary.summary.privateRealAssetsCapacity}</FormattingTooltip>
    },
    {
      name: investmentType ? `Bite Size (${investmentType?.toLowerCase()})` : 'Bite Size',
      formatType: 'JSX',
      value: (
        <FormattingTooltip>
          {investmentType === 'Fund'
            ? data.ClientEntityDetailsSummary.biteSize.fundBiteSize
            : data.ClientEntityDetailsSummary.biteSize.directBiteSize}
        </FormattingTooltip>
      )
    },
    {
      name: 'Sectors To Avoid',
      tooltipName: undefined,
      formatType: 'string',
      value: data.ClientEntityDetailsSummary.sectorsToAvoid
    }
  ];

  const checkISArrayFilled = (array: string[]) => !!array.filter((item) => item).length;

  const cleanData = (): InfoListRow[] =>
    entityPopover.filter(({ value }) => {
      if (Array.isArray(value)) return checkISArrayFilled(value);
      return value !== null && value !== undefined;
    });

  return (
    <MoreDataWrap>
      <WrapperSwitcher>
        <Switcher isActive={switcher} onClick={changeSwitcher} />
        Include Pending Investments
      </WrapperSwitcher>
      <ScrollWrap>
        {cleanData().map(({ name, tooltipName, value, formatType }) => (
          <MoreDataItem key={name}>
            <MoreDataItemTitle>
              <p>{name}</p>
              {tooltipName && <BasicTooltip tooltipContent={tooltipName} withTooltipIcon />}
            </MoreDataItemTitle>
            {Array.isArray(value) ? (
              <SectorsToAvoid list={value} />
            ) : (
              <MoreDataItemValue>{formatByType[formatType](value as never)}</MoreDataItemValue>
            )}
          </MoreDataItem>
        ))}
      </ScrollWrap>
    </MoreDataWrap>
  );
};

const ScrollWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3px;
  overflow: scroll;
`;

const WrapperSwitcher = styled.div`
  display: flex;
  column-gap: 10px;
  margin-bottom: 22px;
  color: ${({ theme }) => theme.font.base};
`;

const MoreDataItemValue = styled.div`
  display: flex;
  justify-content: center;
  font-family: Blinker, serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0;
  text-align: center;
  width: 50%;
  color: ${({ theme }) => theme.font.base};
`;

const MoreDataItemTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  font-family: Blinker, serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0;
  text-align: left;
  width: 50%;
  min-width: 50%;
  color: ${({ theme }) => theme.font.base};
`;

const MoreDataItem = styled.div`
  display: flex;
  align-items: center;
  padding: 5px 20px;
  background-color: ${({ theme }) => theme.layer[1]};
  gap: 10px;
  border-radius: 4px;
`;

const MoreDataWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3px;
`;
