import * as React from 'react';

export const DataRoomIcon = ({ ...props }: React.SVGProps<SVGSVGElement>) => (
  <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_5179_90638)">
      <path
        d="M14.9373 7.1629C14.9373 8.34078 14.9388 9.52015 14.9328 10.701C14.9328 10.7653 14.8746 10.8475 14.8207 10.8909C14.5621 11.1031 14.2497 11.2092 13.9358 11.3034C13.2064 11.5246 12.4575 11.6592 11.7027 11.7623C10.4545 11.9342 9.20191 12.0134 7.94481 12.0164C7.19294 12.0179 6.43958 12.0209 5.6907 11.9701C4.85064 11.9133 4.01357 11.8101 3.17949 11.6995C2.33644 11.5889 1.50834 11.4006 0.722088 11.0598C0.574106 10.9955 0.433598 10.9133 0.297574 10.8281C0.257215 10.8027 0.218351 10.7354 0.218351 10.6876C0.213867 9.52314 0.213867 8.35722 0.213867 7.1898C0.213867 7.17635 0.216857 7.1629 0.218351 7.15393C0.562148 7.26903 0.90445 7.3916 1.25124 7.49623C2.0031 7.72344 2.77142 7.87889 3.55168 7.96858C4.21835 8.04631 4.88651 8.13151 5.55617 8.15991C6.60849 8.20326 7.6623 8.22717 8.71461 8.21073C9.47844 8.19877 10.2423 8.11955 11.0046 8.05378C12.0001 7.96708 12.9852 7.81013 13.9433 7.52164C14.2766 7.42149 14.601 7.28696 14.9373 7.16439V7.1629Z"
        fill={props?.fill || '#4587EC'}
      />
      <path
        d="M14.9373 2.7758C14.9373 2.79972 14.9373 2.84456 14.9373 2.8909C14.9373 4.006 14.9343 5.12109 14.9403 6.23769C14.9403 6.37819 14.888 6.4634 14.7819 6.53515C14.4725 6.7474 14.1197 6.85951 13.7654 6.96115C13.0494 7.16594 12.3185 7.30645 11.5771 7.38268C10.752 7.46639 9.92687 7.56205 9.09877 7.59643C8.20041 7.6338 7.29757 7.62184 6.39772 7.6039C5.76693 7.59195 5.13614 7.55308 4.50983 7.4903C3.32896 7.37072 2.15408 7.2048 1.03001 6.79523C0.789353 6.70704 0.560653 6.58298 0.333449 6.4634C0.279637 6.435 0.218351 6.35428 0.218351 6.29897C0.213867 5.11661 0.213867 3.93574 0.213867 2.73694C0.32747 2.79224 0.432103 2.84606 0.536737 2.89538C1.22433 3.21526 1.95378 3.39464 2.69369 3.53365C3.51731 3.68911 4.3484 3.78029 5.18397 3.84755C6.36633 3.94172 7.55019 3.9492 8.73405 3.91482C9.5173 3.89239 10.2991 3.8326 11.0793 3.75189C12.0853 3.64875 13.0868 3.49927 14.0479 3.16743C14.3499 3.0628 14.6354 2.91033 14.9373 2.7758Z"
        fill={props?.fill || '#4587EC'}
      />
      <path
        d="M0.218262 14.3916V11.5336C0.427529 11.6114 0.624839 11.6921 0.828127 11.7593C1.56505 12.0045 2.31841 12.1839 3.08672 12.2915C3.76535 12.3856 4.44397 12.4843 5.12559 12.5351C6.33934 12.6263 7.55458 12.6652 8.77133 12.5994C9.42006 12.5635 10.0703 12.5441 10.716 12.4813C11.9387 12.3617 13.157 12.2003 14.3259 11.7892C14.5292 11.7175 14.725 11.6278 14.9372 11.5396C14.9372 11.5964 14.9372 11.6472 14.9372 11.6966C14.9372 12.5605 14.9372 13.426 14.9402 14.29C14.9402 14.3423 14.8909 14.429 15 14.4424C14.9911 14.4783 14.9896 14.5202 14.9731 14.5516C14.8416 14.7877 14.6293 14.9342 14.3976 15.0568C13.858 15.3423 13.2736 15.5007 12.6787 15.6024C12.0284 15.713 11.3737 15.8176 10.716 15.8759C9.9492 15.9447 9.1779 15.9791 8.40959 15.9925C7.61139 16.0075 6.81168 15.997 6.01348 15.9641C5.36026 15.9372 4.71004 15.867 4.05982 15.8042C3.30944 15.7309 2.56804 15.5994 1.84457 15.3842C1.41408 15.2556 0.992552 15.1046 0.635302 14.8206C0.529174 14.7354 0.446961 14.6203 0.352791 14.5187C0.316916 14.4798 0.276558 14.4439 0.219756 14.3886L0.218262 14.3916Z"
        fill={props?.fill || '#4587EC'}
      />
      <path
        d="M7.54442 0C8.50854 0.0403587 9.28283 0.0538117 10.0556 0.110613C11.2365 0.197309 12.4114 0.337818 13.5489 0.690583C13.9779 0.823617 14.3994 0.974589 14.7432 1.27952C15.0302 1.53363 15.0422 1.8281 14.7716 2.09417C14.4697 2.39163 14.081 2.53513 13.6924 2.66517C13.0212 2.89088 12.3307 3.03587 11.6296 3.1151C10.8897 3.1988 10.1483 3.30045 9.4054 3.32885C8.39942 3.36622 7.39046 3.36622 6.38448 3.33333C5.58029 3.30792 4.77461 3.23169 3.97491 3.13602C3.12738 3.03438 2.28732 2.87294 1.4906 2.55007C1.26489 2.45889 1.04516 2.34081 0.847855 2.20179C0.39195 1.88042 0.436793 1.49776 0.868781 1.18535C1.31123 0.865471 1.82543 0.713004 2.34561 0.578475C3.10645 0.381166 3.88074 0.246637 4.664 0.188341C5.68492 0.112108 6.70735 0.0538117 7.54292 0L7.54442 0Z"
        fill={props?.fill || '#4587EC'}
      />
    </g>
    <defs>
      <clipPath id="clip0_5179_90638">
        <rect width="14.7862" height="16" fill="white" transform="translate(0.213867)" />
      </clipPath>
    </defs>
  </svg>
);
