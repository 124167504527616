import client from '@/apollo-client';
import aiMagicWand from '@/assets/icons/ai-suggested-icon.png';
import { getAssetSignedUrl } from '@/components/settings-page/queries';
import * as docx from 'docx';
import { ISection } from '../dueDiligenceRecord/types';

export const transparentDocxTableBorders = {
  top: { style: docx.BorderStyle.SINGLE, size: 0, color: 'FFFFFF' },
  bottom: { style: docx.BorderStyle.SINGLE, size: 0, color: 'FFFFFF' },
  left: { style: docx.BorderStyle.SINGLE, size: 0, color: 'FFFFFF' },
  right: { style: docx.BorderStyle.SINGLE, size: 0, color: 'FFFFFF' }
};

export const getDocxImage = async (url: string, assetKey: string, withUpdatingUrl?: boolean): Promise<Uint8Array | null> => {
  if (withUpdatingUrl) {
    try {
      const result = await client.query({
        query: getAssetSignedUrl,
        fetchPolicy: 'network-only',
        variables: {
          assetKey
        }
      });

      const response = await fetch(result.data.getAssetSignedUrl);
      if (!response.ok) {
        return null;
      }

      const arrayBuffer = await response.arrayBuffer();
      return new Uint8Array(arrayBuffer);
    } catch (error) {
      console.log(error);
    }
  }

  const response = await fetch(url);
  if (!response.ok) {
    return null;
  }

  const arrayBuffer = await response.arrayBuffer();
  return new Uint8Array(arrayBuffer);
};

export const getAiSuggestedSection = async (section: ISection, bodyFontColor: string) => {
  const aiSuggestedSectionData = [];

  const aiSuggestedIcon = await getDocxImage(aiMagicWand, '');

  const imageRun = new docx.ImageRun({
    data: aiSuggestedIcon ?? '',
    transformation: {
      width: 15,
      height: 15
    }
  });

  const textRun = new docx.TextRun({
    text: section.fields.length > 1 ? '* These fields are AI Suggested' : 'This field is AI Suggested',
    color: bodyFontColor
  });

  const table = new docx.Table({
    rows: [
      new docx.TableRow({
        children: [
          new docx.TableCell({
            children: [
              new docx.Paragraph({
                children: [imageRun]
              })
            ],
            borders: {
              top: { style: docx.BorderStyle.SINGLE, size: 0, color: 'FFFFFF' },
              bottom: { style: docx.BorderStyle.SINGLE, size: 0, color: 'FFFFFF' },
              left: { style: docx.BorderStyle.SINGLE, size: 0, color: 'FFFFFF' },
              right: { style: docx.BorderStyle.SINGLE, size: 6, color: 'C7CBD2' }
            },
            margins: {
              top: 100,
              bottom: 100,
              right: 100
            }
          }),
          new docx.TableCell({
            children: [
              new docx.Paragraph({
                children: [textRun]
              })
            ],
            borders: transparentDocxTableBorders,
            margins: {
              top: 100,
              bottom: 100,
              left: 100
            }
          })
        ]
      })
    ]
  });

  aiSuggestedSectionData.push(new docx.Paragraph({}));
  aiSuggestedSectionData.push(table);
  aiSuggestedSectionData.push(new docx.Paragraph({}));

  return aiSuggestedSectionData;
};
