import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { format } from 'date-fns';
import dayjs, { Dayjs } from 'dayjs';
import { useState } from 'react';

import { DatePickerIcon } from '@/assets/static/icons/date-picker';
import Label from '@/components/fat-basicComponents/label';
import { TextField } from '@mui/material';
import { useController } from 'react-hook-form';
import styled, { useTheme } from 'styled-components';

interface DatePickerProps {
  title: string;
  nameField: string;
  control: any;
  errors: any;
  rules?: any;
  className?: string;
  isWarning?: boolean;
  tooltip?: string;
  tooltipWidth?: number;
}

export const DatePicker = ({ title, control, rules, nameField, className, isWarning, tooltip, tooltipWidth }: DatePickerProps) => {
  const theme = useTheme();
  const {
    field: { value, ...filedProperties },
    fieldState: { invalid },
    formState: { errors, isSubmitted }
  } = useController({ name: nameField, rules, control });

  const [open, setOpen] = useState(false);

  const handleValue = (date: Dayjs | any) => {
    if (dayjs(date).isValid()) {
      const chosenDate = format(new Date(date.year(), date.month(), date.date()), 'MM/dd/yyyy');
      filedProperties.onChange(chosenDate);
      return;
    }

    filedProperties.onChange(date);
  };

  return (
    <DatePickerWrapper className={className}>
      <Label required={isWarning} tooltip={tooltip} tooltipWidth={tooltipWidth} size="md">
        {title}
      </Label>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          gap: '8px',
          width: '100%'
        }}
      >
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Stack style={{ width: '100%' }} spacing={3}>
            <CustomDesktopDatePicker
              PaperProps={{
                sx: {
                  bgcolor: theme.layer[1],
                  color: theme.font.strong,
                  border: `1px solid ${theme.border.base}`,
                  marginTop: '8px',
                  '& .MuiPickersDay-root': {
                    bgcolor: theme.layer[2],
                    color: theme.font.strong
                  },
                  '& .MuiButtonBase-root': {
                    color: theme.font.strong,
                    '&:hover': {
                      bgcolor: theme.action.hover
                    }
                  },
                  '& .MuiTypography-root': {
                    color: theme.font.weak
                  },
                  '& .MuiPickersDay-today': {
                    border: `1px solid ${theme.border.base}`
                  },
                  '& .PrivatePickersFadeTransitionGroup-root': {
                    '&::-webkit-scrollbar': {
                      width: '10px',
                      left: '-100px'
                    },
                    '&::-webkit-scrollbar-track': {
                      background: theme.layer[2],
                      border: '1px solid',
                      borderColor: theme.border.base,
                      padding: '2px',
                      borderRadius: '10px',
                      margin: '6px 0'
                    },
                    '&::-webkit-scrollbar-thumb': {
                      backgroundColor: theme.layer[4],
                      backgroundClip: 'padding-box',
                      borderRadius: '10px'
                    }
                  }
                }
              }}
              components={{
                OpenPickerIcon: () => <DatePickerIcon fill={theme.font.action} />
              }}
              open={open}
              onOpen={() => setOpen(true)}
              onClose={() => setOpen(false)}
              inputFormat="MM/DD/YYYY"
              value={value}
              onChange={(d) => handleValue(d)}
              renderInput={(params) => <Placeholder {...params} />}
            />
          </Stack>
        </LocalizationProvider>
      </Box>
      {invalid && <ErrorText>{String(errors?.[nameField]?.message)}</ErrorText>}
    </DatePickerWrapper>
  );
};

const DatePickerWrapper = styled.div`
  width: 100%;
`;

const CustomDesktopDatePicker = styled(DesktopDatePicker)`
  .MuiOutlinedInput-notchedOutline {
    border: 0;
  }

  border-radius: 4px;
  box-shadow: none;
  background-color: ${({ theme }) => theme.layer[1]};
  outline: 1px solid ${({ theme }) => theme.border.base};
`;

const Placeholder = styled(TextField)`
  &.MuiTextField-root .MuiOutlinedInput-input {
    padding: 10px;
    font-family: Blinker;
    font-size: 16px;
    font-weight: 400;
    line-height: 22.4px;
    color: ${({ theme }) => theme.font.strong};
  }
`;

const ErrorText = styled.p`
  color: ${({ theme }) => theme.context.error};
  font-family: Blinker;
  font-style: normal;
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;
`;
