import { ManagerUpdates } from '@/components/managerUpdates-page';
import { Helmet as Head } from 'react-helmet';

const ManagerUpdatesPage = () => {
  return (
    <>
      <Head>
        <title>Manager Updates</title>
      </Head>
      <ManagerUpdates />
    </>
  );
};

export default ManagerUpdatesPage;
