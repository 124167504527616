import { ApprovedIcon } from '@/assets/icons/info-icons/approved';
import ModalWrappedContent from '@/components/basicComponents/modal/modalWrappedContent';
import { Loader } from '@/components/loaders/loader';
import styled from 'styled-components';
import { IStatusOfUploading } from '..';

interface ImportStatusProps {
  isOpen: boolean;
  onClose: () => void;
  statusOfUploading: IStatusOfUploading;
}

const ImportStatus = ({ isOpen, onClose, statusOfUploading }: ImportStatusProps) => {
  const GetCurrentStatus = () => {
    switch (statusOfUploading.status) {
      case 'pending':
        return (
          <ModalBody>
            <Loader size={70} />
            <ModalTitle>Processing File</ModalTitle>
            This may take a few moments depending on the size of your file.
          </ModalBody>
        );
      case 'success':
        return (
          <ModalBody>
            <ApprovedIcon width={75} height={75} fill="#72A492" />
            <ModalTitle>Success</ModalTitle>
          </ModalBody>
        );
      case 'error':
        return (
          <ModalBody>
            <ModalTitle>Import Failed</ModalTitle>
            We’re sorry. This process did not complete.
          </ModalBody>
        );
      default:
        return <></>;
    }
  };
  return (
    <ModalWrappedContent
      isOpen={isOpen}
      onClose={statusOfUploading.status !== 'pending' ? onClose : () => null}
      showCloseIcon={statusOfUploading.status !== 'pending'}
      isUseOutsideClick={statusOfUploading.status !== 'pending'}
      showCancelButton={false}
      {...(statusOfUploading.status !== 'pending' && {
        confirmButton: {
          name: 'OK',
          onClick: onClose
        }
      })}
    >
      <GetCurrentStatus />
    </ModalWrappedContent>
  );
};

export default ImportStatus;

const ModalTitle = styled.div`
  color: #2e2e2e;
  text-align: center;
  font-size: 33px;
  font-weight: 400;
  line-height: 46.2px;
`;

const ModalBody = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  gap: 10px;
  margin: 23px 0 23px 0;
  font-size: 19px;
  font-weight: 400;
  line-height: 26.6px;
`;
