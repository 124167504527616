import { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

import DropdownActions, { EmptyDropdownActions } from '@/components/table/dropdownActions';
import NoResults from '@/components/table/noResults';
import { blinkingFrame, TableBodyWrapper, TableRowCell, TextCell } from '@/components/table/styles';
import { IItems, TableColumns } from '@/components/table/types';
import { useResponsive } from '@/hooks/use-responsive';
import Amount from './cell/amount';
import ClientsAdvisor from './cell/clientsAdvisor';
import Entity from './cell/entity';
import Investment from './cell/investment';
import LastUpdate from './cell/lastUpdate';
import NextStep from './cell/nextStep';
import { ModalWindowType } from './modals/types';
import { FieldsForRedirectWindows, IRowData, TypeWindow } from './types';

interface BodyRowProps {
  data: IRowData[] | undefined;
  columns: TableColumns[];
  updateLoading: boolean;
  detailsClick: (type: TypeWindow, id: FieldsForRedirectWindows) => void;
  openModalWindow: (type: ModalWindowType, row: IRowData) => void;
  currentRow: IRowData | null;
  changeSelectRow: (row: IRowData, entityName: string) => void;
  canShowChangeAmountButton: (row: IRowData) => any;
  actionsByStatus: (row: IRowData) => IItems[];
}

export const TableBody = ({
  columns,
  data,
  detailsClick,
  updateLoading,
  currentRow,
  changeSelectRow,
  canShowChangeAmountButton,
  actionsByStatus,
  openModalWindow
}: BodyRowProps) => {
  const { isTablet } = useResponsive();
  const [recommendationsRows, setRecommendationsRows] = useState<IRowData[]>([]);
  const [selectedRow, setSelectedRow] = useState<string>();

  useEffect(() => {
    if (data === undefined) return;
    setRecommendationsRows(data);
  }, [data]);

  if (!recommendationsRows.length) {
    return <NoResults />;
  }
  return (
    <TableBodyWrapper>
      {recommendationsRows.map((row) => (
        <TableRowWrapper
          isTablet={isTablet}
          selected={selectedRow === row.id}
          onMouseEnter={() => isTablet && setSelectedRow(row.id)}
          key={row.id}
          loading={updateLoading && row.id === currentRow?.id ? 1 : 0}
        >
          <TableRowCell width={columns[0].width}>
            <ClientsAdvisor
              onRedirect={() =>
                detailsClick('details', {
                  familyName: row.legalEntity.family.name
                })
              }
              familyName={row.legalEntity.family.name}
              advisor={row?.legalEntity?.advisor?.initials}
            />
          </TableRowCell>
          <TableRowCell width={columns[1].width}>
            <Entity
              row={row}
              changeSelect={(value) => changeSelectRow(row, value)}
              onRedirect={() =>
                detailsClick('entityDetails', {
                  id: row.legalEntity.id,
                  entityName: row.legalEntity.entityName,
                  familyId: row.legalEntity.family.id,
                  familyName: row.legalEntity.family.name
                })
              }
            />
          </TableRowCell>
          <TableRowCell width={columns[2].width}>
            <Investment
              row={row}
              onRedirect={() =>
                detailsClick('allocation', {
                  investmentName: row.investment.name,
                  investmentId: row.investment.id
                })
              }
            />
          </TableRowCell>
          <TableRowCell width={columns[3].width}>
            {!row.isAmountHidden ? (
              <Amount
                showChangeAmountButton={canShowChangeAmountButton(row)}
                amount={row.committedCapital}
                openWindow={() => openModalWindow('amount', row)}
              />
            ) : null}
          </TableRowCell>
          <TableRowCell width={columns[4].width}>
            <TextCell>
              {row.assignee && `${row.assignee.firstName && row.assignee?.firstName} ${row.assignee?.lastName && row.assignee.lastName}`}
            </TextCell>
          </TableRowCell>
          <TableRowCell width={columns[5].width}>
            <LastUpdate row={row} />
          </TableRowCell>
          <TableRowCell width={columns[6].width}>
            <NextStep status={row.operationsStatus} />
          </TableRowCell>
          {actionsByStatus(row).length ? (
            <TableRowCell width={columns[7].width}>
              <DropdownActions selected={selectedRow === row.id} items={actionsByStatus(row)} disabled={updateLoading && row.id === currentRow?.id} />
            </TableRowCell>
          ) : (
            <TableRowCell width={columns[7].width}>
              <EmptyDropdownActions />
            </TableRowCell>
          )}
        </TableRowWrapper>
      ))}
    </TableBodyWrapper>
  );
};

const TableRowWrapper = styled.div<{
  addAdaptive?: boolean;
  loading?: boolean | number;
  selected: boolean;
  isTablet: boolean;
}>`
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 60px;
  background-color: #ffffff;
  border-radius: 8px;
  padding: 0 24px;
  gap: 5px;
  opacity: ${({ loading }) => (loading ? 0.5 : 1)};
  pointer-events: ${({ loading }) => (loading ? 'none' : 'auto')};
  animation: ${({ loading }) => (loading ? blinkingFrame : null)} 1.5s infinite;

  @media (max-width: 1200px) {
    padding: 0 ${({ addAdaptive }) => (addAdaptive ? 10 : 24)}px;
  }

  ${({ isTablet, selected }) =>
    isTablet
      ? css`
          #infoWrap {
            ${selected
              ? css`
                  opacity: 1;
                `
              : ''}
          }

          #actionText {
            ${selected
              ? css`
                  color: #4587ec;
                `
              : ''}
          }

          #actionOrangeText {
            ${selected
              ? css`
                  color: #ef9208;
                `
              : ''}
          }

          #tempInfoWrap {
            ${selected
              ? css`
                  display: none;
                `
              : ''}
          }

          #arrowInSelectRow {
            svg {
              path {
                ${selected
                  ? css`
                      fill: #ef9208;
                    `
                  : ''}
              }
            }
          }

          #selector {
            ${selected
              ? css`
                  outline: 1px solid #c7cbd2 !important;
                  background-color: #fff;
                `
              : ''}
          }

          #selectIcon {
            ${selected
              ? css`
                  opacity: 1;
                `
              : ''}
          }

          ${selected
            ? css`
                background-color: #f0f1f3;
                transition: background-color 100ms ease;
              `
            : ''}
        `
      : css`
          &:hover #infoWrap {
            opacity: 1;
          }

          &:hover #actionText {
            color: #4587ec;
          }

          &:hover #actionOrangeText {
            color: #ef9208;
          }

          &:hover #tempInfoWrap {
            display: none;
          }

          &:hover #arrowInSelectRow {
            svg {
              path {
                fill: #ef9208;
              }
            }
          }

          &:hover #selector {
            outline: 1px solid #c7cbd2 !important;
            background-color: #fff;
          }

          &:hover #selectIcon {
            opacity: 1;
          }

          &:hover {
            background-color: #f0f1f3;
            transition: background-color 100ms ease;
          }
        `}
`;
