import * as React from 'react';

export const DataIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || 12}
    height={props.height || 12}
    onClick={props.onClick || undefined}
    style={props?.style}
    viewBox="0 0 12 12"
    fill="none"
  >
    <path d="M2.80078 11.5061L2.80078 8.70605L0.000781298 8.70605L0.000781211 11.5061L2.80078 11.5061Z" fill={props.fill || '#202020'} />
    <path d="M12 11.5559L12 8.75586L4 8.75586L4 11.5559L12 11.5559Z" fill={props.fill || '#202020'} />
    <path d="M2.80078 7.33125L2.80078 4.53125L0.000781298 4.53125L0.000781211 7.33125L2.80078 7.33125Z" fill={props.fill || '#202020'} />
    <path d="M12 7.38105L12 4.58105L4 4.58105L4 7.38105L12 7.38105Z" fill={props.fill || '#202020'} />
    <path d="M2.80078 3.24336L2.80078 0.443359L0.000781298 0.443359L0.000781211 3.24336L2.80078 3.24336Z" fill={props.fill || '#202020'} />
    <path d="M12 3.29316L12 0.493164L4 0.493164L4 3.29316L12 3.29316Z" fill={props.fill || '#202020'} />
  </svg>
);
