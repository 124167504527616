import { Dispatch, SetStateAction, useState } from 'react';
import styled, { useTheme } from 'styled-components';

import { ArrowDown } from '@/assets/icons/info-icons/arrowDown';
import { ArrowUp } from '@/assets/static/icons/todo-page/arrow-up';
import { useResponsive } from '@/hooks/use-responsive';
import { Loader } from '../loaders/loader';
import { ISort } from '../table/types';
import { Table } from './table/table';
import MobileTable from './table/tableMobile';
import { IRecordData, ITierData } from './types';

interface TierItemProp extends ITierData {
  sort: ISort;
  setSort: Dispatch<SetStateAction<ISort>>;
  handleTierClick: (row: IRecordData) => void;
  openModalWindow: (type: string, row: IRecordData) => void;
  handleModifyDiligenceClick: (row: IRecordData) => void;
  updateDueDiligenceRecordLoading: boolean;
  currentRow: IRecordData | null;
  loading: boolean;
}

export const TierItem = ({
  name,
  records,
  handleTierClick,
  tasksCount,
  sort,
  setSort,
  openModalWindow,
  handleModifyDiligenceClick,
  updateDueDiligenceRecordLoading,
  currentRow,
  loading
}: TierItemProp) => {
  const { isMobile } = useResponsive();
  const theme = useTheme();
  const [isTableOpen, setIsTableOpen] = useState(true);

  return (
    <SectionWrapper isMobile={isMobile}>
      <Section>
        <SctionItem>
          <SctionTitle>{name}</SctionTitle>
        </SctionItem>
        {isTableOpen ? (
          <ArrowUp onClick={() => setIsTableOpen(false)} style={{ cursor: 'pointer' }} fill={theme.font.action} />
        ) : (
          <ArrowDown
            onClick={() => setIsTableOpen(true)}
            style={{
              cursor: 'pointer',
              width: '28px',
              height: '28px',
              padding: '7px'
            }}
            fill={theme.font.action}
          />
        )}
      </Section>
      <SectionBodyWrapper isTableOpen={isTableOpen}>
        <ExpandableWrapp>
          {records.length ? (
            <>
              {isMobile ? (
                loading ? (
                  <Loader />
                ) : (
                  <MobileTable
                    records={records}
                    tasksCount={tasksCount}
                    openModalWindow={openModalWindow}
                    handleTierClick={handleTierClick}
                    handleModifyDiligenceClick={handleModifyDiligenceClick}
                    updateDueDiligenceRecordLoading={updateDueDiligenceRecordLoading}
                    currentRow={currentRow}
                  />
                )
              ) : (
                <Table
                  records={records}
                  handleTierClick={handleTierClick}
                  tasksCount={tasksCount}
                  sort={sort}
                  setSort={setSort}
                  openModalWindow={openModalWindow}
                  handleModifyDiligenceClick={handleModifyDiligenceClick}
                  updateDueDiligenceRecordLoading={updateDueDiligenceRecordLoading}
                  currentRow={currentRow}
                  loading={loading}
                />
              )}
            </>
          ) : (
            <NoInvestmens>{`No Investments with outstanding tasks in ${name}`}</NoInvestmens>
          )}
        </ExpandableWrapp>
      </SectionBodyWrapper>
    </SectionWrapper>
  );
};

const SectionWrapper = styled.div<{ isMobile?: boolean }>`
  border: 1px solid ${({ theme }) => theme.border.base};
  border-radius: 10px;
  padding: ${({ isMobile }) => (isMobile ? '20px' : '20px 40px')};
`;

const Section = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const SectionBodyWrapper = styled.div<{ isTableOpen: boolean }>`
  display: grid;
  grid-template-rows: ${({ isTableOpen }) => (isTableOpen ? '1fr' : '0fr')};
  overflow: ${({ isTableOpen }) => (isTableOpen ? 'visible' : 'hidden')};
  transition: grid-template-rows 300ms;
`;

const ExpandableWrapp = styled.div`
  min-height: 0;
`;

const SctionItem = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;
`;

const SctionTitle = styled.div`
  font-family: 'Blinker';
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 22px;
  color: ${({ theme }) => theme.font.strong};
`;

const NoInvestmens = styled.div`
  font-weight: 300;
  font-size: 16px;
  line-height: 18px;
  color: ${({ theme }) => theme.font.weak};
  padding-top: 10px;
`;
