export const managersColumns = [
  { title: 'Manager', key: 'name', width: 50, keySort: 'manager' },
  { title: 'Strategies', key: 'countOfFunds', width: 15, keySort: 'strategies' },
  { title: 'Investor Entities', key: 'countOfInvestors', width: 15, keySort: 'investor entities' },
  { title: 'Committed Capital', key: 'committedCapital', width: 20, keySort: 'committed capital' }
];

export const investmentColumns = [
  { title: 'Strategy', key: 'name', width: 20, keySort: 'strategy' },
  { title: 'Manager', key: 'manager', width: 20, keySort: 'manager' },
  { title: 'Max % Called', key: 'maxCalledPercent', width: 20, keySort: 'max % called' },
  { title: 'Investor Entities', key: 'countOfInvestors', width: 20, keySort: 'investor entities' },
  { title: 'Committed Capital', key: 'committedCapital', width: 20, keySort: 'committed capital' }
];

export const viewByList = ['Manager', 'Strategy'];

export const managersTableSort = [
  {
    title: 'Manager • A -> Z',
    key: 'name',
    asc: true
  },
  {
    title: 'Manager • Z -> A',
    key: 'name',
    asc: false
  },
  {
    title: 'Strategies • Max -> Min',
    key: 'countOfFunds',
    asc: false
  },
  {
    title: 'Strategies • Min -> Max',
    key: 'countOfFunds',
    asc: true
  },
  {
    title: 'Investor Entities • Max -> Min',
    key: 'countOfInvestors',
    asc: false
  },
  {
    title: 'Investor Entities • Min -> Max',
    key: 'countOfInvestors',
    asc: true
  },
  {
    title: 'Committed Capital • Max -> Min',
    key: 'committedCapital',
    asc: false
  },
  {
    title: 'Committed Capital • Min -> Max',
    key: 'committedCapital',
    asc: true
  }
];

export const investmentTableSort = [
  {
    title: 'Strategy • A -> Z',
    key: 'name',
    asc: true
  },
  {
    title: 'Strategy • Z -> A',
    key: 'name',
    asc: false
  },
  {
    title: 'Manager • A -> Z',
    key: 'manager',
    asc: true
  },
  {
    title: 'Manager • Z -> A',
    key: 'manager',
    asc: false
  },
  {
    title: 'Max % Called • Max -> Min',
    key: 'maxCalledPercent',
    asc: false
  },
  {
    title: 'Max % Called • Min -> Max',
    key: 'maxCalledPercent',
    asc: true
  },
  {
    title: 'Investor Entities • Max -> Min',
    key: 'countOfInvestors',
    asc: false
  },
  {
    title: 'Investor Entities • Min -> Max',
    key: 'countOfInvestors',
    asc: true
  },
  {
    title: 'Committed Capital • Max -> Min',
    key: 'committedCapital',
    asc: false
  },
  {
    title: 'Committed Capital • Min -> Max',
    key: 'committedCapital',
    asc: true
  }
];
