import { RejectedBannerIcon } from '@/assets/icons/info-icons/rejectedBanner';
import { DeleteBucketIcon } from '@/assets/static/icons/delete-bucket';
import { Banner } from '@/components/basicComponents/banner';
import ModalLoader from '@/components/fat-basicComponents/modal/modalLoader';
import ModalWrappedContent from '@/components/fat-basicComponents/modal/modalWrappedContent';
import { useMutation } from '@apollo/client';
import { useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { deleteDocumentRecordMutation } from '../queries';
import { IDocumentRecordEntity } from '../types';

interface DeleteUpdateProps {
  onClose: () => void;
  isOpen: boolean;
  currentRow: IDocumentRecordEntity | null;
  latestUpdateId: string | null;
  latestUpdateRefetch: () => void;
  listDocumentRecordsRefetch: () => void;
}

const DEFAULT_ERROR_MESSAGE = `We're sorry. This process did not complete. Please try again.`;

const DeleteUpdate = ({ onClose, isOpen, currentRow, latestUpdateId, latestUpdateRefetch, listDocumentRecordsRefetch }: DeleteUpdateProps) => {
  const theme = useTheme();

  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const [deleteDocumentRecord, { loading: deleteDocumentRecordLoading }] = useMutation(deleteDocumentRecordMutation, {
    onError: (error) => {
      console.error(error);
      setErrorMessage(DEFAULT_ERROR_MESSAGE);
    }
  });

  const saveValue = () => {
    setErrorMessage(null);
    if (!currentRow) {
      setErrorMessage(DEFAULT_ERROR_MESSAGE);
      return;
    }

    deleteDocumentRecord({
      variables: {
        id: currentRow.id
      },
      onCompleted: () => {
        if (currentRow.id === latestUpdateId) {
          latestUpdateRefetch();
        }
        listDocumentRecordsRefetch();
        onClose();
      }
    });
  };

  return (
    <ModalWrappedContent
      isOpen={isOpen}
      onClose={onClose}
      confirmButton={{ name: 'Yes, Delete', onClick: saveValue, disabled: deleteDocumentRecordLoading }}
      isUseOutsideClick={!deleteDocumentRecordLoading}
      showCloseIcon={!deleteDocumentRecordLoading}
      showCancelButton={!deleteDocumentRecordLoading}
    >
      <ModalContainer>
        <DeleteBucketIcon fill={theme.font.disabled} />
        {deleteDocumentRecordLoading ? (
          <ModalLoader />
        ) : (
          <>
            <ModalTitel>Please confirm you want to delete this update.</ModalTitel>
            {errorMessage && (
              <ErrorBannerWrapper>
                <Banner
                  icon={<RejectedBannerIcon fill={theme.context.light} />}
                  title="Error"
                  description={errorMessage}
                  bgColor={theme.context.error}
                />
              </ErrorBannerWrapper>
            )}
            <ModalDescription>This update will be permanently deleted.</ModalDescription>
          </>
        )}
      </ModalContainer>
    </ModalWrappedContent>
  );
};

export default DeleteUpdate;

const ModalContainer = styled.div`
  font-weight: 400;
  padding: 10px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;

const ModalTitel = styled.div`
  font-size: 33px;
  text-align: center;
  color: ${({ theme }) => theme.font.strong};
`;

const ModalDescription = styled.div`
  font-size: 16px;
  line-height: 27px;
  text-align: center;
  color: ${({ theme }) => theme.font.base};
`;

const ErrorBannerWrapper = styled.div`
  margin-bottom: 10px;
`;
