import { useMutation } from '@apollo/client';
import { DeleteBucketIcon } from '@/assets/static/icons/delete-bucket';
import ModalWrappedContent from '@/components/basicComponents/modal/modalWrappedContent';
import {
  DUE_DILIGENCE_FUNNEL_LIST_SETTINGS_QUERY,
  UPDATE_DUE_DILIGENCE_FUNNEL
} from '@/components/settings-page/queries';
import { SORT_ORDER } from '@/components/table/types';
import { useResponsive } from '@/hooks/use-responsive';
import styled from 'styled-components';
import { IFunnelDataWithStatuses } from '../types';

interface DeletePromptProps {
  onClose: () => void;
  isOpen: boolean;
  currentFunnel: IFunnelDataWithStatuses | null;
  // deleteFunnel: (id: string) => void;
}

const DeleteFunnel = ({
  onClose,
  isOpen,
  currentFunnel
}: DeletePromptProps) => {
  if (!currentFunnel) return <></>;
  const { isMobile } = useResponsive();

  const [updateFunnel] = useMutation(UPDATE_DUE_DILIGENCE_FUNNEL, {
    refetchQueries: () => [
      {
        query: DUE_DILIGENCE_FUNNEL_LIST_SETTINGS_QUERY,
        variables: {
          data: {
            statusFilter: ['PUBLISHED', 'DRAFT_WITHOUT_PUBLISHED'],
            sortDirection: SORT_ORDER.ASC,
            sortBy: 'ORDER'
          },
          archivedFunnelData: {
            sortDirection: SORT_ORDER.ASC,
            sortBy: 'ORDER'
          }
        }
      }
    ]
  });

  const saveValue = () => {
    updateFunnel({
      variables: {
        data: { id: currentFunnel.id, status: 'ARCHIVED' }
      },
      onCompleted: () => {
        onClose();
      }
    });
    // deleteFunnel(currentFunnel.id);
  };

  return (
    <ModalWrappedContent
      isOpen={isOpen}
      onClose={onClose}
      showCancelButton={
        !currentFunnel.statuses.dueDiligence && !currentFunnel.statuses.lead
      }
      confirmButton={
        !currentFunnel.statuses.dueDiligence && !currentFunnel.statuses.lead
          ? { name: 'Confirm', onClick: saveValue }
          : { name: 'Done', onClick: onClose }
      }
    >
      <ModalContainer>
        <DeleteBucketIcon />
        <ModalTitle>
          Please confirm you want to archive the{' '}
          <ModalTitle bold>{currentFunnel?.name}</ModalTitle> funnel.
        </ModalTitle>
        {currentFunnel.statuses.dueDiligence > 0 ||
        currentFunnel.statuses.lead > 0 ? (
          <>
            <ModalDescription>
              You cannot archive a funnel that is associated with due diligence
              records that are currently in a Lead or Open status.
            </ModalDescription>
            <StatusWrapper isMobile={isMobile}>
              <RecordStatus>
                <CountWrap>
                  <Count>{currentFunnel.statuses.lead}</Count>
                </CountWrap>
                Leads
              </RecordStatus>
              <RecordStatus>
                <CountWrap>
                  <Count>{currentFunnel.statuses.dueDiligence}</Count>
                </CountWrap>
                Due Diligence
              </RecordStatus>
            </StatusWrapper>
            <ModalDescription>
              Resolve these due diligence records before archiving this funnel.
            </ModalDescription>
          </>
        ) : (
          <ModalDescription>
            This action will archive the {currentFunnel?.name} Funnel.  No
            existing due diligence records that correspond to this funnel will
            be impacted.  But this funnel will not be available for future due
            diligence. This action cannot be undone. 
          </ModalDescription>
        )}
      </ModalContainer>
    </ModalWrappedContent>
  );
};

export default DeleteFunnel;

const ModalContainer = styled.div`
  font-weight: 400;
  padding: 10px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;

const ModalTitle = styled.div<{ bold?: boolean }>`
  display: inline-block;
  font-size: 33px;
  text-align: center;
  color: #2e2e2e;
  font-weight: ${({ bold }) => (bold ? '600' : '400')};
`;

const ModalDescription = styled.div`
  font-size: 16px;
  line-height: 27px;
  text-align: center;
  color: #373f4e;
  font-weight: 400;
`;

const StatusWrapper = styled.div<{ isMobile: boolean }>`
  display: flex;
  flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};
  gap: 30px;
  color: #2e2e2e;
  font-size: 16px;
  font-weight: 600;
`;

const RecordStatus = styled.div`
  color: #373f4e;
  font-size: 19px;
  font-weight: 400;
  display: flex;
  align-items: center;
`;

const CountWrap = styled.div`
  background-color: #36474f;
  display: inline-block;
  border-radius: 12px;
  color: #f4f5f6;
  padding: 1px 11px;
  font-size: 13px;
  margin-right: 6px;
`;

const Count = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  font-weight: 700;
`;
