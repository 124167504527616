import * as React from 'react';

export const EditTodoIcon = ({ ...props }: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={props.width || 18}
      height={props.height || 16}
      style={props?.style}
      viewBox="0 0 18 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 4.526H10.9106V6.5103H0V4.526ZM0 2.54171H10.9106V0.551491H0V2.54171ZM0 10.4848H6.94797V8.50052H0V10.4848ZM14.897 7.37429L15.6061 6.67116C15.698 6.57909 15.8071 6.50605 15.9273 6.45621C16.0474 6.40638 16.1762 6.38075 16.3063 6.38075C16.4363 6.38075 16.5651 6.40638 16.6853 6.45621C16.8054 6.50605 16.9145 6.57909 17.0064 6.67116L17.7096 7.37429C17.8016 7.46617 17.8747 7.57533 17.9245 7.69547C17.9744 7.81562 18 7.94441 18 8.07448C18 8.20455 17.9744 8.33335 17.9245 8.45349C17.8747 8.57363 17.8016 8.68276 17.7096 8.77463L17.0064 9.48373L14.897 7.37429ZM14.1939 8.08339L8.93224 13.345V15.4485H11.0357L16.2973 10.1869L14.1939 8.08339Z"
        fill={props.fill || '#F4F5F6'}
      />
    </svg>
  );
};
