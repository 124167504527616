import { useMutation } from '@apollo/client';
import { useState } from 'react';
import Label from '@/components/basicComponents/label';
import ModalWrappedContent from '@/components/basicComponents/modal/modalWrappedContent';
import { UPDATE_DUE_DILIGENCE_FUNNEL } from '@/components/settings-page/queries';
import styled from 'styled-components';
import { saveOnEnter } from '../utils';

interface EditFunnelProps {
  onClose: () => void;
  isOpen: boolean;
  funnelName: string | null;
  funnelId: string | null;
}

const EditFunnel = ({
  onClose,
  isOpen,
  funnelName,
  funnelId
}: EditFunnelProps) => {
  if (!funnelName || !funnelId) return <></>;

  const [value, setValue] = useState(funnelName);

  const [updateFunnel] = useMutation(UPDATE_DUE_DILIGENCE_FUNNEL);

  const saveValue = () => {
    updateFunnel({
      variables: {
        data: {
          name: value,
          id: funnelId
        }
      },
      onCompleted: () => {
        onClose();
      }
    });
  };

  return (
    <ModalWrappedContent
      isOpen={isOpen}
      onClose={onClose}
      confirmButton={{ name: 'Save', onClick: saveValue, disabled: !value }}
      showRequiredFields
    >
      <ModalTitle>Edit Funnel</ModalTitle>
      <ModalContainer
        onKeyDown={(event) => saveOnEnter(event, saveValue, !value)}
      >
        <Label required>Name</Label>
        <MainSearch
          type="text"
          placeholder="Give this Funnel a unique name"
          value={value}
          onChange={(event) => setValue(event.target.value)}
          autoFocus
        />
      </ModalContainer>
    </ModalWrappedContent>
  );
};

export default EditFunnel;

export const ModalContainer = styled.div`
  margin-top: 20px;
  outline: none;
`;

export const ModalTitle = styled.div`
  font-size: 33px;
  text-align: center;
  color: #2e2e2e;
`;

export const MainSearch = styled.input`
  width: 100%;
  border: 1px solid #c7cbd2;
  border-radius: 5px;
  padding: 10px 12px;
  font-size: 19px;
  font-family: Blinker;
  font-weight: 400;

  &:focus {
    outline: none;
  }
`;
