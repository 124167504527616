import * as React from 'react';

export const SelectIcon = ({ ...props }: React.SVGProps<SVGSVGElement>) => (
  <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.11739 5.25115C5.72399 5.65999 5.06963 5.65999 4.67623 5.25115L1.25276 1.69337C0.641439 1.05807 1.09168 -9.59185e-07 1.97334 -8.82108e-07L8.82028 -2.83529e-07C9.70194 -2.06452e-07 10.1522 1.05807 9.54086 1.69337L6.11739 5.25115Z"
      fill={props.fill || '#4587EC'}
    />
  </svg>
);
