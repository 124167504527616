import BasicTooltip from '@/components/basicComponents/tooltip';
import { IRecordData } from '@/components/dueDiligence-page/types';
import { TableRowWrapper, TextCell, blinkingFrame } from '@/components/table/styles';
import { format } from 'date-fns';
import styled from 'styled-components';
import { ColumnList, InvestmentStatusEnum } from '../types';
import { actionDropdownItems } from '../utils';
import FixedTableColumn from './fixedColumn';

interface TableBodyProps {
  columnsList: ColumnList[];
  moduleConfig: any;
  openModalWindow: (row: IRecordData, type: string) => void;
  handleModifyDealClick: (row: IRecordData) => void;
  dealRegistryRows: IRecordData[];
  handleClickDeal: (row: IRecordData) => void;
  dealRegistryLoading: boolean;
  currentRow: IRecordData | null;
  handleUpdateInvestmentStatus: (row: IRecordData, status: string) => void;
  openInvestmentForAllocations: (row: IRecordData, isEdit?: boolean) => void;
  openManagerUpdates: (row: IRecordData) => void;
}

const getAIRunValue = (startedAt: Date, completedAt: Date) => {
  if (completedAt) {
    return format(new Date(completedAt), 'LLL d, yyyy');
  } else {
    return 'In Progress';
  }
};

export const rowValue = (row: IRecordData, column: ColumnList) => {
  switch (column.rowKey) {
    case 'createdAt':
      return <TextCell>{format(new Date(row.createdAt), 'LLL d, yyyy')}</TextCell>;
    case 'assetClass':
      return <TextCell>{row.investment.assetClass && row.investment.assetClass}</TextCell>;
    case 'tier':
      return (
        <TextCell>
          {row.investment.securityStatus === 'LEAD'
            ? `${row.activeTier?.funnel.latestFunnel.name} - Not Started`
            : `${row.activeTier?.funnel.latestFunnel.name} - ${row.activeTier?.name}`}
        </TextCell>
      );
    case 'targetIRR':
      return <TextCell>{row.investment?.targetIRR}</TextCell>;
    case 'targetYield':
      return <TextCell>{row.investment?.targetYield}</TextCell>;
    case 'strategy':
      return row.investment?.subAssetClasses?.length > 1 ? (
        <BasicTooltip tooltipContent={row.investment.subAssetClasses.toString().replace(/,/g, ', ')}>
          <TextCell>Multiple ({row.investment.subAssetClasses.length})</TextCell>
        </BasicTooltip>
      ) : (
        <TextCell>{row.investment?.subAssetClasses && row.investment?.subAssetClasses[0]}</TextCell>
      );
    case 'tags':
      return row.investment.tags?.length > 1 ? (
        <BasicTooltip tooltipContent={row.investment.tags.toString().replace(/,/g, ', ')}>
          <TextCell>Multiple ({row.investment.tags.length})</TextCell>
        </BasicTooltip>
      ) : row.investment.tags?.length ? (
        <TextCell>row.investment?.tags[0]</TextCell>
      ) : (
        <></>
      );
    case 'status':
      return <TextCell>{InvestmentStatusEnum[row.investment.securityStatus as keyof typeof InvestmentStatusEnum]}</TextCell>;
    case 'ai_suggestions':
      return row.dealAssistantAIRuns && row.dealAssistantAIRuns.length > 0 ? (
        <TextCell>{getAIRunValue(row.dealAssistantAIRuns[0].startedAt, row.dealAssistantAIRuns[0].completedAt)}</TextCell>
      ) : (
        <TextCell>Never Run</TextCell>
      );
    default:
      return <TextCell>{row[column.rowKey]}</TextCell>;
  }
};

export const TableBody = ({
  columnsList,
  openModalWindow,
  handleModifyDealClick,
  dealRegistryRows,
  handleClickDeal,
  dealRegistryLoading,
  currentRow,
  handleUpdateInvestmentStatus,
  openInvestmentForAllocations,
  openManagerUpdates,
  moduleConfig
}: TableBodyProps) => {
  if (!dealRegistryRows.length) {
    return (
      <FixedWrapper>
        <FixedTableRowWrapper>
          <TextCell>No Results</TextCell>
        </FixedTableRowWrapper>
      </FixedWrapper>
    );
  }

  return (
    <TableBodyWrapper>
      {dealRegistryRows.map((row, rowIndex) => (
        <TableRow key={rowIndex} loading={dealRegistryLoading && row.id === currentRow?.id ? 1 : 0}>
          <FixedTableColumn
            row={row}
            handleClickDeal={handleClickDeal}
            items={actionDropdownItems(
              row,
              moduleConfig,
              handleClickDeal,
              handleModifyDealClick,
              openModalWindow,
              handleUpdateInvestmentStatus,
              openInvestmentForAllocations,
              openManagerUpdates
            )}
          />
          {columnsList.map((column) => {
            return (
              column.value && (
                <TableColumn key={column.keySort} style={{ width: column.width }}>
                  {rowValue(row, column)}
                </TableColumn>
              )
            );
          })}
        </TableRow>
      ))}
    </TableBodyWrapper>
  );
};

const TableBodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  transition: background-color 0.3s ease;
`;

const TableRow = styled.div<{ loading?: boolean | number }>`
  display: flex;
  background-color: ${({ theme }) => theme.layer[1]};
  border-radius: 8px;
  opacity: ${({ loading }) => (loading ? 0.5 : 1)};
  pointer-events: ${({ loading }) => (loading ? 'none' : 'auto')};
  animation: ${({ loading }) => (loading ? blinkingFrame : null)} 1.5s infinite;

  &:hover #actionText {
    color: ${({ theme }) => theme.font.action};
  }

  &:hover #infoWrap {
    opacity: 1;
  }

  &:hover {
    background-color: ${({ theme }) => theme.layer[12]};
    transition: background-color 100ms ease;
  }
`;

const TableColumn = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 24px;
`;

const FixedTableRowWrapper = styled.div`
  position: sticky;
  left: 0;
  z-index: 1;
  width: 390px;
  padding: 0 24px;
`;

const FixedWrapper = styled(TableRowWrapper)`
  width: 100%;
  padding: 0;
`;
