import { FormFooter } from '@/components/fat-basicComponents/formFooter';
import Header from '@/components/fat-header';
import { GoBackButton } from '@/components/fat-header/goBackButton';
import { PageTitle } from '@/components/fat-header/pageTitle';
import { LoaderOnWholeScreen } from '@/components/loaders/loader-on-whole-screen';
import { useResponsive } from '@/hooks/use-responsive';
import { useGoBack } from '@/hooks/useGoBack';
import { MainWrap } from '@/styles/common';
import { User } from '@/types/user';
import { useMutation, useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import { LIST_USERS_QUERY, updateInvestorMutation } from '../queries';
import { IInvestorQuery } from '../types';
import { GET_INVESTOR_EDIT_QUERY } from './queries';
import { ClientAdvisorSection } from './sections/clientAdvisor';
import { InvestorDetailsSection } from './sections/investorDetails';

export const clientAdvisorSectionOptions = ['None', 'Assign a client advisor'];

export const EditInvestorPage = () => {
  const params = useParams();
  const goBack = useGoBack();
  const { isMobile, isTablet } = useResponsive();
  const [searchParams, setSearchParams] = useSearchParams();

  const backToTitle = searchParams.get('backToTitle') || '';

  const [selectedOption, setSelectedOption] = useState(clientAdvisorSectionOptions[0]);
  const [investorName, setInvestorName] = useState('');
  const [selectedAdvisor, setSelectedAdvisor] = useState('Assign a client advisor');
  const [advisorsList, setAdvisorsList] = useState<User[]>([]);

  const [updateInvestor] = useMutation(updateInvestorMutation);

  const { data: investorData, loading: investorLoading } = useQuery<IInvestorQuery>(GET_INVESTOR_EDIT_QUERY, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
    skip: !params?.investorId,
    variables: {
      data: {
        id: params?.investorId
      }
    },
    onCompleted: (data) => {
      const { name } = data.getInvestor;
      setInvestorName(name);
    },
    onError: (error) => {
      console.error(error);
    }
  });

  const { loading } = useQuery<{ listUsers: User[] }>(LIST_USERS_QUERY, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
    skip: !investorData?.getInvestor.tenant?.id,
    variables: {
      filters: {
        roles: ['ADVISOR_LEAD', 'ADVISOR_MEMBER', 'ADVISOR_ASSOCIATE'],
        tenantId: investorData?.getInvestor.tenant?.id
      }
    },
    onCompleted: (data) => {
      setAdvisorsList(data.listUsers);
    },
    onError: (error) => {
      console.error(error);
    }
  });

  useEffect(() => {
    if (!investorData || !advisorsList.length) return;
    const { advisor } = investorData.getInvestor;

    if (advisor && advisorsList.length) {
      setSelectedOption(clientAdvisorSectionOptions[1]);
      setSelectedAdvisor(`${advisor.firstName} ${advisor.lastName}`);
    }
  }, [investorData, advisorsList]);

  const onUpdateInvestor = () => {
    if (!params?.investorId) return;
    const newAdvisor = advisorsList.find((advisor) => `${advisor.firstName} ${advisor.lastName}` === selectedAdvisor);

    if (!newAdvisor && selectedOption === clientAdvisorSectionOptions[1]) return;

    updateInvestor({
      variables: {
        data: {
          id: params.investorId,
          name: investorName,
          advisorId: selectedOption === clientAdvisorSectionOptions[0] ? 'None' : newAdvisor?.id
        }
      },
      onCompleted: () => {
        goBack({ fallBack: '/investors' });
      }
    });
  };

  if (investorLoading || loading) {
    return <LoaderOnWholeScreen size={60} />;
  }

  return (
    <>
      <MainWrap>
        <Header modalControl={<GoBackButton handleClose={() => goBack({ fallBack: '/investors' })} backToTitle={backToTitle} />} />
        <PageTitle title="Edit Investor" />
      </MainWrap>
      <MainWrap>
        <PaddingWrap>
          <SectionsWrap>
            <InvestorDetailsSection investorName={investorName} setInvestorName={setInvestorName} />
            {!advisorsList.length || (
              <ClientAdvisorSection
                selectedAdvisor={selectedAdvisor}
                setSelectedAdvisor={setSelectedAdvisor}
                advisorsList={advisorsList}
                selectedOption={selectedOption}
                setSelectedOption={setSelectedOption}
              />
            )}
          </SectionsWrap>
          <FormFooter
            onCancel={() => goBack({ fallBack: '/investors' })}
            onSave={onUpdateInvestor}
            disableSaveButton={!investorName || (selectedAdvisor === 'Assign a client advisor' && selectedOption === clientAdvisorSectionOptions[1])}
          />
        </PaddingWrap>
      </MainWrap>
    </>
  );
};

const PaddingWrap = styled.div`
  padding: 50px 16px 0 16px;
  @media (min-width: 600px) {
    padding-left: 50px;
    padding-right: 50px;
  }
`;

const SectionsWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
