import { UPDATE_INVESTMENT } from '@/components/dueDiligence-page/queries';
import ModalWrappedContent from '@/components/fat-basicComponents/modal/modalWrappedContent';
import { Investment } from '@/components/fat-dealCatalog-page/types';
import { useMutation } from '@apollo/client';
import styled from 'styled-components';

interface TerminateProps {
  onClose: () => void;
  isOpen: boolean;
  currentRow: Investment | null;
  refetch: () => void;
}

const Terminate = ({ onClose, isOpen, currentRow, refetch }: TerminateProps) => {
  if (!currentRow) return <></>;

  const [updateInvestment] = useMutation(UPDATE_INVESTMENT);

  const saveValue = () => {
    updateInvestment({
      variables: {
        data: {
          id: currentRow.id,
          securityStatus: 'TERMINATED',
          nextClose: currentRow.nextClose,
          finalClose: currentRow.finalClose
        }
      },
      onCompleted: () => {
        refetch();
        onClose();
      }
    });
  };

  return (
    <ModalWrappedContent
      isOpen={isOpen}
      onClose={onClose}
      confirmButton={{
        name: 'Confirm',
        onClick: saveValue
      }}
    >
      <ModalBody>
        <Text fontWeight="400">
          Please confirm you want to terminate <Text fontWeight="700">{currentRow.name}</Text>
        </Text>
      </ModalBody>
    </ModalWrappedContent>
  );
};

export default Terminate;

const ModalBody = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 35px;
`;

const Text = styled.span<{ fontWeight: string }>`
  color: ${({ theme }) => theme.font.base};
  font-size: 33px;
  text-align: center;
  font-weight: ${({ fontWeight }) => fontWeight};
  line-height: 46.2px;
`;
