import * as React from 'react';

export const BurgerMenuIcon = ({ ...props }: React.SVGProps<SVGSVGElement>) => (
  <svg
    onClick={props.onClick || undefined}
    width={props.width || 22}
    style={props?.style}
    height={props.height || 17}
    viewBox="0 0 22 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 9.86804H22V6.79172H0V9.86804ZM0 16.0299H22V12.9444H0V16.0299ZM0 0.629883V3.70621H22V0.629883H0ZM0 9.86804H22V6.79172H0V9.86804ZM0 16.0299H22V12.9444H0V16.0299ZM0 0.629883V3.70621H22V0.629883H0Z"
      fill={props?.fill || '#4587EC'}
    />
  </svg>
);
