import { MobileRow, MobileRowTitle, MobileRowValue, MobileTableRowWrap, NoResults } from '@/components/table/mobileTable/mobileTable';

import BasicTooltip from '@/components/basicComponents/tooltip';
import { Investment } from '@/components/fat-dealCatalog-page/types';
import { TableBodyWrapper, TableRowCell, TextCell } from '@/components/table/styles';
import { dateFormatter } from '@/utils/dateFormatter';
import { format } from 'date-fns';
import styled from 'styled-components';
import { columns } from '../constatnts';
import { isMoreThan90DaysInPast } from '../utils';

type MobileTableProps = {
  tableData: Investment[];
  openManagerUpdateDetailsPage: (strategyId: string) => void;
};

const tooltipContent = (el: Investment) => {
  return (
    <TableBodyWrapper>
      <TableRowCell width={100}>
        <TextCell>Draft: {el.documentRecordMetadata.num_of_mu_draft}</TextCell>
      </TableRowCell>
      <TableRowCell width={100}>
        <TextCell>Published: {el.documentRecordMetadata.num_of_mu_published}</TextCell>
      </TableRowCell>
    </TableBodyWrapper>
  );
};

const MobileTable = ({ tableData, openManagerUpdateDetailsPage }: MobileTableProps) => {
  if (!tableData.length) {
    return <NoResults />;
  }

  return (
    <>
      {tableData.map((el) => {
        return (
          <MobileTableRowWrap key={el.id}>
            <MobileRow>
              <MobileRowTitle>{columns[0].title}</MobileRowTitle>
              <MobileRowValue onClick={() => openManagerUpdateDetailsPage(el.id)} isRedirect>
                {el.name}
              </MobileRowValue>
            </MobileRow>

            <MobileRow>
              <MobileRowTitle>{columns[1].title}</MobileRowTitle>
              <MobileRowValue>{el.managerId}</MobileRowValue>
            </MobileRow>

            <MobileRow>
              <MobileRowTitle>{columns[2].title}</MobileRowTitle>
              <MobileRowValue>{el.securityStatus}</MobileRowValue>
            </MobileRow>

            <MobileRow>
              <MobileRowTitle>{columns[3].title}</MobileRowTitle>
              <MobileRowValue>
                <CellWithTooltip>
                  {el.documentRecordMetadata.num_of_mu}
                  <BasicTooltip tooltipContent={tooltipContent(el)} withTooltipIcon zIndex={1000} />
                </CellWithTooltip>
              </MobileRowValue>
            </MobileRow>

            <MobileRow>
              <MobileRowTitle>{columns[4].title}</MobileRowTitle>
              <LastPublishDateMobileRowValue isMoreThan90DaysInPast={isMoreThan90DaysInPast(el.documentRecordMetadata.last_mu_publish_date)}>
                {el.documentRecordMetadata.last_mu_publish_date &&
                  dateFormatter(format(new Date(el.documentRecordMetadata.last_mu_publish_date), 'yyyy-MM-dd'), 'yyyy-MM-dd')}
              </LastPublishDateMobileRowValue>
            </MobileRow>
          </MobileTableRowWrap>
        );
      })}
    </>
  );
};

export default MobileTable;

const LastPublishDateMobileRowValue = styled(MobileRowValue)<{ isMoreThan90DaysInPast: boolean }>`
  font-weight: 600;
  color: ${({ isMoreThan90DaysInPast, theme }) => (isMoreThan90DaysInPast ? theme.context.error : theme.font.base)};
`;

const CellWithTooltip = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;
