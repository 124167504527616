import { DatePickerIcon } from '@/assets/static/icons/date-picker';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { format } from 'date-fns';
import dayjs, { Dayjs } from 'dayjs';
import React, { useState } from 'react';
import styled, { useTheme } from 'styled-components';
import Label from '../basicComponents/label';

interface DatePickerProps {
  title: string;
  value: string | null;
  changeValue: (value: string) => void;
  className?: string;
}

export const DatePicker: React.FC<DatePickerProps> = ({ title, value, changeValue, className }) => {
  const theme = useTheme();
  const [open, setOpen] = useState(false);

  const handleValue = (date: Dayjs | any) => {
    if (dayjs(date).isValid()) {
      const chosenDate = format(new Date(date.year(), date.month(), date.date()), 'MM/dd/yyyy');
      changeValue(chosenDate);
      return;
    }

    changeValue(date);
  };

  return (
    <Box style={{ width: '100%' }} className={className}>
      <CustomLabel required={false}>{title}</CustomLabel>
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: '8px', width: '100%' }}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Stack style={{ width: '100%' }} spacing={3}>
            <CustomDesktopDatePicker
              PaperProps={{
                sx: {
                  bgcolor: theme.layer[1],
                  color: theme.font.strong,
                  border: `1px solid ${theme.border.base}`,
                  marginTop: '8px',
                  '& .MuiPickersDay-root': {
                    bgcolor: theme.layer[2],
                    color: theme.font.strong
                  },
                  '& .MuiButtonBase-root': {
                    color: theme.font.strong,
                    '&:hover': {
                      bgcolor: theme.action.hover
                    }
                  },
                  '& .MuiTypography-root': {
                    color: theme.font.weak
                  },
                  '& .MuiPickersDay-today': {
                    border: `1px solid ${theme.border.base}`
                  },
                  '& .PrivatePickersFadeTransitionGroup-root': {
                    '&::-webkit-scrollbar': {
                      width: '10px',
                      left: '-100px'
                    },
                    '&::-webkit-scrollbar-track': {
                      background: theme.layer[2],
                      border: '1px solid',
                      borderColor: theme.border.base,
                      padding: '2px',
                      borderRadius: '10px',
                      margin: '6px 0'
                    },
                    '&::-webkit-scrollbar-thumb': {
                      backgroundColor: theme.layer[4],
                      backgroundClip: 'padding-box',
                      borderRadius: '10px'
                    }
                  }
                }
              }}
              components={{
                OpenPickerIcon: () => (
                  <OpenIconWrapper>
                    <DatePickerIcon />
                  </OpenIconWrapper>
                )
              }}
              open={open}
              onOpen={() => setOpen(true)}
              onClose={() => setOpen(false)}
              inputFormat="MM/DD/YYYY"
              value={value}
              onChange={(d) => handleValue(d)}
              renderInput={(params) => (
                <Placeholder
                  {...params}
                  inputProps={{
                    ...params.inputProps,
                    placeholder: 'MM/DD/YYYY'
                  }}
                />
              )}
            />
          </Stack>
        </LocalizationProvider>
      </Box>
    </Box>
  );
};

const CustomLabel = styled(Label)`
  font-size: 16px;
  line-height: 22.4px;
`;

const CustomDesktopDatePicker = styled(DesktopDatePicker)`
  border-radius: 4px;

  .MuiOutlinedInput-notchedOutline {
    border: 0;
  }

  background-color: ${({ theme }) => theme.layer[2]};
  outline: 1px solid ${({ theme }) => theme.border.base};
`;

const Placeholder = styled(TextField)`
  &.MuiTextField-root .MuiOutlinedInput-input {
    padding: 9px 10px;
    font-family: Blinker;
    font-size: 16px;
    line-height: 20px;
    color: ${({ theme }) => theme.font.strong};
  }
`;

const OpenIconWrapper = styled.div`
  color: ${({ theme }) => theme.font.action};
  display: flex;
  justify-content: center;
  align-items: center;
`;
