import React from 'react';
import styled from 'styled-components';

import ModalWrappedContent from '../../basicComponents/modal/modalWrappedContent';

interface CompleteProps {
  isOpen: boolean;
  onClose: () => void;
  confirmAction: () => void;
}

const Complete = ({ isOpen, onClose, confirmAction }: CompleteProps) => {
  return (
    <ModalWrappedContent
      title={'Remove allocation from list'}
      isOpen={isOpen}
      onClose={onClose}
      confirmButton={{ name: 'Confirm', onClick: confirmAction }}
    >
      <Description>
        This action will remove the confirmed allocation from this list. Once it is included in the reporting feed, it will appear in the entity’s
        list of active commitments.
      </Description>
    </ModalWrappedContent>
  );
};

export default Complete;

const Description = styled.span`
  text-align: center;
  margin: 0;
  font-size: 18px;
  font-weight: 400;
  padding: 10px 0 30px;
  line-height: 28px;
`;
