import { getUTCDate } from '@/components/allocations-page/utils';
import { formatCash } from '@/utils/formatCash';
import { useEffect, useState } from 'react';
import { Bar, CartesianGrid, ComposedChart, Legend, Line, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { Section } from '../../edit-entity/section';
import { ChartTooltip } from '../chartTooltip';
import { SimpleForecastValueDto } from '../types';

interface NetCashFlowsSectionProps {
  netContributions: SimpleForecastValueDto[];
  netDistributions: SimpleForecastValueDto[];
}

interface IChartData {
  year: string;
  contribution?: number;
  contributionForecast?: number;
  distribution?: number;
  distributionForecast?: number;
  netCashFlow?: number;
  netCashFlowForecast?: number;
  initContribution?: number;
  initDistribution?: number;
  actual: boolean;
}

export const NetCashFlowsSection = ({ netContributions, netDistributions }: NetCashFlowsSectionProps) => {
  const [chartData, setChartData] = useState<IChartData[]>([]);

  const addItem = (transformedChartData: { [key: number]: IChartData }, item: SimpleForecastValueDto, fieldName: string) => {
    const formattedYear = getUTCDate(new Date(item.date)).toUpperCase();
    const year = new Date(item.date).getFullYear();

    if (!transformedChartData[year]) {
      transformedChartData[year] = {
        year: formattedYear,
        [fieldName]: item.value,
        actual: item.actual
      };
    }

    transformedChartData[year] = {
      ...transformedChartData[year],
      [fieldName]: item.value
    };

    if (transformedChartData[year].actual) {
      transformedChartData[year].netCashFlow =
        (transformedChartData[year].initDistribution || 0) - (transformedChartData[year].initContribution || 0);
      return;
    }

    if (transformedChartData[year - 1]?.actual && !transformedChartData[year]?.actual) {
      transformedChartData[year].netCashFlow =
        (transformedChartData[year].initDistribution || 0) - (transformedChartData[year].initContribution || 0);
      transformedChartData[year].netCashFlowForecast =
        (transformedChartData[year].initDistribution || 0) - (transformedChartData[year].initContribution || 0);
      return;
    }

    transformedChartData[year].netCashFlowForecast =
      (transformedChartData[year].initDistribution || 0) - (transformedChartData[year].initContribution || 0);
  };

  useEffect(() => {
    if (!netContributions.length || !netDistributions.length) return;
    const transformedChartData: { [key: number]: IChartData } = {};

    netContributions.forEach((item) => {
      addItem(transformedChartData, item, 'initContribution');

      if (item.actual) {
        addItem(transformedChartData, { ...item, value: -item.value }, 'contribution');
        return;
      }
      addItem(transformedChartData, { ...item, value: -item.value }, 'contributionForecast');
    });

    netDistributions.forEach((item) => {
      addItem(transformedChartData, item, 'initDistribution');

      if (item.actual) {
        addItem(transformedChartData, item, 'distribution');
        return;
      }
      addItem(transformedChartData, item, 'distributionForecast');
    });

    setChartData(Object.values(transformedChartData).map((value) => value));
  }, [netContributions, netDistributions]);

  return (
    <Section id="netCashFlows" title="Net Cash Flows">
      <ResponsiveContainer width="100%" height={400}>
        <ComposedChart stackOffset="sign" data={chartData}>
          <CartesianGrid stroke="#B0B0B0" />
          <XAxis dataKey="year" />
          <YAxis width={85} tickFormatter={(value) => formatCash(value)} />
          <Tooltip content={<ChartTooltip />} />
          <Legend />
          <Bar stackId="a" dataKey="contributionForecast" fill="#FC265E" name="Contribution Forecast" />
          {/* <Bar stackId="a" dataKey="contribution" fill="#FAA6BC" name="Contribution" /> */}
          <Bar stackId="a" dataKey="distributionForecast" fill="#20CB70 " name="Distribution Forecast" />
          {/* <Bar stackId="a" dataKey="distribution" fill="#9CEBC1" name="Distribution" /> */}
          {/* <Line type="monotone" dataKey="netCashFlow" name="Net Cash Flow" stroke="#4BADA0" strokeWidth={4} /> */}
          <Line type="monotone" dataKey="netCashFlowForecast" name="Net Cash Flow Forecast" stroke="#0E718A" strokeWidth={4} />
        </ComposedChart>
      </ResponsiveContainer>
    </Section>
  );
};
