import { AnimatePresence, motion } from 'framer-motion';
import { ChangeEvent, useEffect, useState } from 'react';

import { ApprovedIcon } from '@/assets/icons/info-icons/approved';
import { RejectedBannerIcon } from '@/assets/icons/info-icons/rejectedBanner';
import Label from '@/components/basicComponents/label';
import RadioButton from '@/components/basicComponents/radioButton';
import BasicTooltip from '@/components/basicComponents/tooltip';
import { useResponsive } from '@/hooks/use-responsive';
import styled, { useTheme } from 'styled-components';
import { EntityInput, Info, Input, LabelWrap } from '../input';
import { Section } from '../section';
import { IDataFields } from '../types';

interface AllocationTargetsSectionProps {
  dataFields: IDataFields;
  changeProperties: (field: keyof IDataFields) => (value: string | number | boolean) => void;
  totalTargets: number;
}

const allocationTargetOptions = ['Disregard allocation targets', 'Set allocation targets for this entity'];

export const AllocationTargetsSection = ({ dataFields, changeProperties, totalTargets }: AllocationTargetsSectionProps) => {
  const { isMobile, isTablet } = useResponsive();
  const theme = useTheme();

  const [selectedAllocationTargetOption, setSelectedAllocationTargetOption] = useState(allocationTargetOptions[0]);

  useEffect(() => {
    setSelectedAllocationTargetOption(dataFields?.ignoreAllocationTargets ? allocationTargetOptions[0] : allocationTargetOptions[1]);
  }, [dataFields?.ignoreAllocationTargets]);

  const handleRadioChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    setSelectedAllocationTargetOption(newValue);
    changeProperties('ignoreAllocationTargets')(newValue === allocationTargetOptions[0]);
  };

  const handleChangeTotalEntityValue = (value: string) => {
    const cleanValue = value.replace(/\D/g, '');
    if (!cleanValue) {
      changeProperties('totalEntityValue')('');
      return;
    }
    const formattedValue = parseFloat(cleanValue).toLocaleString('en-US');

    changeProperties('totalEntityValue')(formattedValue);
  };

  return (
    <Section title="Allocation Targets">
      <AllocationOptionTargets isMobile={isMobile}>
        <OptionsWrapper>
          Set Allocation Targets for this entity:
          {allocationTargetOptions.map((option, index) => (
            <RadioButton
              key={option + index}
              name="allocationTarget"
              radioButtonText={option}
              selectedOption={selectedAllocationTargetOption}
              handleRadioChange={(event) => handleRadioChange(event)}
            />
          ))}
        </OptionsWrapper>
        <BannerWrap>
          <BannerIconWrap>
            <RejectedBannerIcon fill={theme.header.base} />
          </BannerIconWrap>
          <BannerText>
            When you provide Allocation Targets for this entity capacity calculations and allocation suitability checks are made available.
          </BannerText>
        </BannerWrap>
      </AllocationOptionTargets>
      <AnimatePresence>
        {selectedAllocationTargetOption === allocationTargetOptions[1] && (
          <motion.div
            initial={{
              opacity: 0
            }}
            animate={{
              opacity: 1
            }}
            exit={{
              opacity: 0
            }}
          >
            <AllocationTargets isMobile={isMobile}>
              <EntityInput
                value={dataFields.totalEntityValue ?? ''}
                setValue={(value) => handleChangeTotalEntityValue(value)}
                type="text"
                label="Total Entity Value"
                required
                tooltipWidth={400}
                tooltip="The total investable assets for this entity.  This value is used to calculate target allocation amounts."
                width={isMobile ? '100%' : '33%'}
                withDollarSign
              />
              <EntityInput
                value={dataFields.privateCapital ?? ''}
                setValue={(value) => changeProperties('privateCapital')(value)}
                type="number"
                label="Private Capital %"
                required
                tooltip="The percentage of the Total Entity Value that should be allocated to private investments."
                width={isMobile ? '100%' : '33%'}
              />
              <RatioWrap isMobile={isMobile}>
                <LabelWrap>
                  <Label required>OC Ratio</Label>
                  <BasicTooltip tooltipContent="Optionally set an over commitment ratio to over allocate slightly." tooltipWidth={400}>
                    <Info src={'/static/icons/info-circle.svg'} alt="info" />
                  </BasicTooltip>
                </LabelWrap>
                <RatioInputWrap>
                  <RatioValue>X</RatioValue>
                  <RatioValue>1.</RatioValue>
                  <Input
                    type="number"
                    value={dataFields.overCommitRatio ?? ''}
                    onChange={(event) => changeProperties('overCommitRatio')(event.target.value)}
                    width="45px"
                    min={0}
                    max={99}
                    onWheel={(e) => (e.target as HTMLInputElement).blur()}
                  />
                </RatioInputWrap>
              </RatioWrap>
            </AllocationTargets>
            <TargetsWrap isError={totalTargets !== 100} isMobile={isMobile} isTablet={isTablet}>
              <Targets isMobile={isMobile}>
                <EntityInput
                  value={dataFields.privateEquityTarget ?? ''}
                  setValue={(value) => changeProperties('privateEquityTarget')(value)}
                  type="number"
                  label="Private Equity"
                  required
                  width={isMobile ? '100%' : '184px'}
                />
                <EntityInput
                  value={dataFields.privateCreditTarget ?? ''}
                  setValue={(value) => changeProperties('privateCreditTarget')(value)}
                  type="number"
                  label="Private Credit"
                  required
                  width={isMobile ? '100%' : '184px'}
                />
                <EntityInput
                  value={dataFields.privateRealAssetTarget ?? ''}
                  setValue={(value) => changeProperties('privateRealAssetTarget')(value)}
                  type="number"
                  label="Private Real Asset"
                  required
                  width={isMobile ? '100%' : '184px'}
                />
                <EntityInput
                  value={dataFields.diversifyingStrategiesTarget ?? ''}
                  setValue={(value) => changeProperties('diversifyingStrategiesTarget')(value)}
                  type="number"
                  label="Diversifying Strategies"
                  required
                  width={isMobile ? '100%' : '184px'}
                />
              </Targets>
              <SuccessBannerWrap isError={totalTargets !== 100} isMobile={isMobile}>
                <SuccessBannerIconWrap>
                  {totalTargets === 100 ? <ApprovedIcon fill={theme.context.light} /> : <RejectedBannerIcon fill={theme.context.light} />}
                </SuccessBannerIconWrap>
                <SuccessBannerBody>
                  <SuccessBannerText fontWeight={700}>{totalTargets}%</SuccessBannerText>
                  <SuccessBannerText>Allocation targets must total 100%</SuccessBannerText>
                </SuccessBannerBody>
              </SuccessBannerWrap>
            </TargetsWrap>
          </motion.div>
        )}
      </AnimatePresence>
    </Section>
  );
};

const OptionsWrapper = styled.div`
  max-width: 343px;
  width: 100%;
  color: ${({ theme }) => theme.font.base};
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;
`;

const AllocationOptionTargets = styled.div<{ isMobile: boolean }>`
  display: flex;
  align-items: ${({ isMobile }) => (isMobile ? 'flex-start' : 'flex-end')};
  flex-direction: ${({ isMobile }) => (isMobile ? 'column-reverse' : 'row')};
  gap: ${({ isMobile }) => (isMobile ? '15px' : '0')};
`;

const BannerWrap = styled.span`
  display: flex;
  align-items: center;
  gap: 20px;
  background-color: ${({ theme }) => theme.layer[1]};
  height: 76px;
  padding: 10px 20px;
`;

const BannerIconWrap = styled.span`
  display: flex;
  align-items: center;
  height: 100%;
  padding-right: 20px;
  border-right: 1px solid ${({ theme }) => theme.border.base};
`;

const BannerText = styled.span`
  font-size: 13px;
  font-weight: 400;
  line-height: 18.2px;
  color: ${({ theme }) => theme.font.base};
`;

const AllocationTargets = styled.div<{ isMobile: boolean }>`
  width: 100%;
  display: flex;
  align-items: ${({ isMobile }) => (isMobile ? 'flex-start' : 'center')};
  flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};
  gap: ${({ isMobile }) => (isMobile ? '30px' : '15px')};
`;

const RatioWrap = styled.div<{ isMobile: boolean }>`
  width: ${({ isMobile }) => (isMobile ? '100%' : '33%')};
`;

const RatioInputWrap = styled.div`
  display: flex;
  align-items: center;
`;

const RatioValue = styled.div`
  padding: 10px 12px;
  color: ${({ theme }) => theme.font.strong};
`;

const TargetsWrap = styled.div<{ isError: boolean; isMobile: boolean; isTablet: boolean }>`
  display: flex;
  justify-content: center;
  align-items: ${({ isMobile }) => (isMobile ? 'flex-start' : 'center')};
  flex-direction: ${({ isMobile, isTablet }) => (isMobile || isTablet ? 'column-reverse' : 'row')};
  gap: 30px;
  border: 1px solid ${({ isError, theme }) => (isError ? theme.context.error : theme.context.success)};
  padding: 40px 20px;
  margin-top: 30px;
`;

const Targets = styled.div<{ isMobile: boolean }>`
  display: flex;
  justify-content: center;
  flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};
  gap: ${({ isMobile }) => (isMobile ? '15px' : '30px')};
  width: 100%;
`;

const SuccessBannerWrap = styled.div<{ isError: boolean; isMobile: boolean }>`
  display: flex;
  align-items: center;
  gap: 20px;
  background-color: ${({ isError, theme }) => (isError ? theme.context.error : theme.context.success)};
  max-width: ${({ isMobile }) => (isMobile ? '100%' : '341px')};
  width: 100%;
  height: 66px;
  padding: 10px 20px;
`;

const SuccessBannerIconWrap = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  padding-right: 20px;
  border-right: 1px solid ${({ theme }) => theme.context.light};
`;

const SuccessBannerBody = styled.div`
  display: flex;
  flex-direction: column;
`;

const SuccessBannerText = styled.div<{ fontWeight?: number }>`
  color: ${({ theme }) => theme.context.light};
  font-size: 13px;
  font-weight: ${({ fontWeight }) => (fontWeight ? fontWeight : 400)};
  line-height: 18.2px;
`;
