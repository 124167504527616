import Button from '@/components/fat-basicComponents/button';
import Header from '@/components/fat-header';
import { GoBackButton } from '@/components/fat-header/goBackButton';
import { PageTitle } from '@/components/fat-header/pageTitle';
import { useResponsive } from '@/hooks/use-responsive';
import { MainWrap } from '@/styles/common';
import { useMutation } from '@apollo/client';
import { useState } from 'react';
import styled from 'styled-components';
import { superAdminItems } from './constants';
import Failure from './fat-modals/failure';
import Success from './fat-modals/success';
import Updating from './fat-modals/updating';
import { updateAggregateCommitment, updateAggregateInterest, updateCalculations } from './queries';
import { useNavigate } from 'react-router-dom';

interface TransactionImportDataProps {
  handleClose: () => void;
  backToTitle?: string;
}

export const TransactionImportData = ({ handleClose, backToTitle }: TransactionImportDataProps) => {
  const { isMobile, isTablet } = useResponsive();
  const [modalWindow, setModalWindow] = useState({ isOpen: false, type: 'failure' });
  const [chosenUpdateOption, setChosenUpdateOption] = useState<string | null>(null);

  const [runUpdateCalculations, { loading: updateCalculationsLoading }] = useMutation(updateCalculations);
  const [runUpdateAggregateCommitment, { loading: updateAggregateCommitmentLoading }] = useMutation(updateAggregateCommitment);
  const [runUpdateAggregateInterest, { loading: updateAggregateInterestLoading }] = useMutation(updateAggregateInterest);

  const navigate = useNavigate();

  const getCurrentUpdateMutation = (item: string | null) => {
    if (!item) return null;

    switch (item) {
      case superAdminItems[0]:
        return runUpdateCalculations;
      case superAdminItems[1]:
        return runUpdateAggregateCommitment;
      case superAdminItems[2]:
        return runUpdateAggregateInterest;
      default:
        return null;
    }
  };

  const runUpdate = (item: string | null) => {
    const runUpdateMutation = getCurrentUpdateMutation(item);
    if (!runUpdateMutation) return;

    setChosenUpdateOption(item);
    setModalWindow({ isOpen: true, type: 'updating' });
    runUpdateMutation({
      onCompleted: () => {
        setModalWindow({ isOpen: true, type: 'success' });
        setChosenUpdateOption(null);
      },
      onError: () => {
        setModalWindow({ isOpen: true, type: 'failure' });
      }
    });
  };

  const closeModalWindow = () => {
    setModalWindow({ ...modalWindow, isOpen: false });
  };

  const handleRedirect = (path: string) => {
    navigate(`/settings?action=${path}`);
  };

  return (
    <>
      {modalWindow.type === 'updating' && <Updating isOpen={modalWindow.isOpen} />}
      {modalWindow.type === 'success' && <Success isOpen={modalWindow.isOpen} onClose={closeModalWindow} />}
      {modalWindow.type === 'failure' && (
        <Failure isOpen={modalWindow.isOpen} onClose={closeModalWindow} retryUpdate={() => runUpdate(chosenUpdateOption)} />
      )}
      <MainWrap>
        <Header modalControl={<GoBackButton handleClose={handleClose} backToTitle={backToTitle} />} />
        <PageTitle title="Super Admin" />
        <PaddingWrap>
          <SettingsWrapper>
            <SettingsItem>
              <ItemTitle id="itemTitle" onClick={() => handleRedirect('tenants')}>
                {'Tenants'}
              </ItemTitle>
              <OpenButtonWrapper id="openButton">
                <Button onClick={() => handleRedirect('tenants')}>Open</Button>
              </OpenButtonWrapper>
            </SettingsItem>
            {superAdminItems.map((item, index) => (
              <SettingsItem key={item + index}>
                <ItemTitle id="itemTitle" onClick={() => runUpdate(item)}>
                  {item}
                </ItemTitle>
                <OpenButtonWrapper id="openButton">
                  <Button onClick={() => runUpdate(item)}>Update Now</Button>
                </OpenButtonWrapper>
              </SettingsItem>
            ))}
          </SettingsWrapper>
        </PaddingWrap>
      </MainWrap>
    </>
  );
};

const PaddingWrap = styled.div`
  padding-left: 16px;
  padding-right: 16px;
  @media (min-width: 600px) {
    padding-left: 50px;
    padding-right: 50px;
  }
`;

const SettingsWrapper = styled.div`
  margin-top: 30px;
  background: ${({ theme }) => theme.layer[1]};
  padding: 40px 60px;
  border: 1px solid ${({ theme }) => theme.border.base};
  border-radius: 10px;
`;

const SettingsItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 21px 14px;
  border-bottom: 1px solid ${({ theme }) => theme.border.base};

  &:hover #openButton {
    opacity: 1;
    transition: background-color 100ms ease;
  }
  &:hover #itemTitle {
    color: ${({ theme }) => theme.font.action};
  }
  &:hover {
    background-color: ${({ theme }) => theme.layer[2]};
    transition: background-color 100ms ease;
  }
`;

const ItemTitle = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: ${({ theme }) => theme.font.strong};
  cursor: pointer;
`;

const OpenButtonWrapper = styled.div`
  opacity: 0;
`;
