export const columns = [
  { title: 'Series Code', key: 'SERIES_CODE', width: 20, keySort: 'entity code' },
  { title: 'Series Name', key: 'SERIES_NAME', width: 30, keySort: 'entity name' },
  { title: 'Assignment', key: 'ASSIGNMENT', width: 25, keySort: 'assignment' },
  { title: 'Status', key: 'STATUS', width: 20, keySort: 'status' },
];

export const seriesCodesStatusSuccess = {
  allMatched: 'All Series Codes Matched',
  continue: 'Please continue to the next step',
  orContinue: 'Or continue to next step',
  viewAll: 'View All Series Codes',
};

export const seriesCodesStatusError = {
  text: 'Unrecognized Series Codes',
  description: 'There are unrecognized Investment Codes that need to be mapped. Please reconcile the Series IDs before proceeding.',
};
