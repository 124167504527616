import FilterSelect from '@/components/basicComponents/select';
import { PaddingWrap } from '@/styles/common';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import { DocumentationDetails } from './documentationDetails';
import { DocumentationZeroState } from './documentationZeroState';
import DeleteConfirmationDialog from './modals/deleteConfirmationDialog';
import { AmlDocumentsSection } from './sections/amlDocuments';
import { AuthorizedSignatoriesSection } from './sections/authorizedSignatories';
import { BankAccountsSection } from './sections/bankAccounts';
import { BenefitPlansSection } from './sections/benefitPlans';
import { EligibilitySection } from './sections/eligibility';
import { GeneralInformationSection } from './sections/generalInformation';
import { ReportingRecipientsSection } from './sections/reportingRecipients';
import { SuitabilitySection } from './sections/suitability';
import { TaxDetailsSection } from './sections/taxDetails';

export const DocumentationTab = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const position = searchParams.get('position') || 0;

  const [showZeroState, setShowZeroState] = useState(false);
  const [modalWindow, setModalWindow] = useState({ isOpen: false, type: '' });

  useEffect(() => {
    makeScroll(position);
  }, [position]);

  const saveScrollPostion = () => {
    setSearchParams(
      (prev) => {
        prev.set('position', window.scrollY.toString());
        return prev;
      },
      { replace: true }
    );
  };

  const makeScroll = (position: string | number) => {
    if (!position) return;
    setTimeout(() => window.scrollTo({ top: Number(position), behavior: 'smooth' }), 0);
    setSearchParams(
      (prev) => {
        prev.delete('position');
        return prev;
      },
      { replace: true }
    );
  };

  const openModalWindow = (type: string) => {
    setModalWindow({ type, isOpen: true });
  };

  const closeModalWindow = () => {
    setModalWindow({ ...modalWindow, isOpen: false });
  };

  if (showZeroState) {
    return (
      <>
        <DocumentationDetails />
        <DocumentationZeroState />
      </>
    );
  }

  return (
    <>
      {modalWindow.isOpen && modalWindow.type === 'delete-dialog' && (
        <DeleteConfirmationDialog isOpen={modalWindow.isOpen} onClose={closeModalWindow} />
      )}
      <DocumentationDetails />
      <PaddingWrap>
        <SectionsWrapper>
          <GeneralInformationSection saveScrollPostion={saveScrollPostion} />
          <AuthorizedSignatoriesSection saveScrollPostion={saveScrollPostion} openModalWindow={openModalWindow} />
          <ReportingRecipientsSection saveScrollPostion={saveScrollPostion} openModalWindow={openModalWindow} />
          <BankAccountsSection saveScrollPostion={saveScrollPostion} />
          <AmlDocumentsSection saveScrollPostion={saveScrollPostion} />
          <EligibilitySection saveScrollPostion={saveScrollPostion} />
          <SuitabilitySection saveScrollPostion={saveScrollPostion} />
          <TaxDetailsSection saveScrollPostion={saveScrollPostion} />
          <BenefitPlansSection saveScrollPostion={saveScrollPostion} />
        </SectionsWrapper>
      </PaddingWrap>
    </>
  );
};

const SectionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 50px 0;
`;

export const SectionZeroStateWrapper = styled.div`
  display: flex;
  gap: 10px;
  flex-direction: column;
`;

export const SectionZeroStateText = styled.div`
  padding: 10px 0;
  font-size: 19px;
  font-weight: 400;
  line-height: 26.6px;
  color: ${({ theme }) => theme.font.weak};
`;

export const SectionSelect = styled(FilterSelect)`
  background-color: ${({ theme }) => theme.layer[1]};
  border: 1px solid ${({ theme }) => theme.border.base};
  padding: 10px;
  border-radius: 4px;

  #titleSelect {
    text-transform: none;
    font-family: Blinker;
    font-size: 16px;
    font-weight: 400;
    line-height: 22.4px;
  }

  #dropDownSelect {
    top: 50px;
  }
`;
