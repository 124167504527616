import React, { useState } from 'react';
import ModalWrappedContent from '../../basicComponents/modal/modalWrappedContent';
import FilterSelect from '../../basicComponents/select';
import FilterSearch from '../../basicComponents/input';
import styled from 'styled-components';
import { useAuth } from '../../../hooks/use-auth';

interface TerminateProps {
  isOpen: boolean;
  onClose: () => void;
  id: string;
  updateAllocations: any;
}
const items = ['Select', 'Client preference', 'Manager rejection', 'Client unresponsive', 'Account closure'];

export default function Terminate({ isOpen, onClose, id, updateAllocations }: TerminateProps) {
  const { user } = useAuth();

  const [select, setSelect] = useState(items[0]);
  const [note, setNote] = useState('');
  const onTerminate = () => {
    updateAllocations({
      variables: {
        data: [
          {
            id,
            operationsStatus: 'Terminated',
            allocationStatus: 'Terminated',
            terminationReason: select,
            terminationNote: note,
          },
        ],
      },
    });
    onClose();
  };
  return (
    <ModalWrappedContent
      isOpen={isOpen}
      onClose={onClose}
      title={'Terminate'}
      confirmButton={{ name: 'Terminate Proposal', onClick: onTerminate, disabled: select === items[0] }}
      showRequiredFields
    >
      <ContentWrapper>
        <FilterSelect width={'100%'} label={'Select Reason'} selected={select} setSelected={value => setSelect(value)} data={items} required />
        <FilterSearch width={'100%'} showSearchBtn={false} placeholder={'note'} value={note} onChange={setNote} />
      </ContentWrapper>
    </ModalWrappedContent>
  );
}

const ContentWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
`;
