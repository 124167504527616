import * as docx from 'docx';
import { getValueByKey } from '../DDR-PDF/utils';
import { getDocxImage, transparentDocxTableBorders } from './utils';

const createFooterImage = async (firmSettingsData: any) => {
  let logoProperties = null;

  try {
    const parsedValue = getValueByKey(firmSettingsData?.Settings, 'logo') ? JSON.parse(getValueByKey(firmSettingsData?.Settings, 'logo')) : '';
    if (parsedValue.value) {
      logoProperties = parsedValue;
    }
  } catch (error) {
    console.error(error);
  }

  if (!logoProperties) {
    return new docx.Paragraph({});
  }

  const imageData = (await getDocxImage(logoProperties.value, logoProperties.assetKey, true)) ?? '';

  if (!imageData) return new docx.Paragraph({});

  const img = new Image();
  img.src = URL.createObjectURL(new Blob([imageData]));
  await new Promise(resolve => (img.onload = resolve));

  let newWidth = img.naturalWidth;
  let newHeight = img.naturalHeight;

  if (img.naturalWidth > 150) {
    const ratio = img.naturalWidth / 150;
    newWidth = 150;
    newHeight = img.naturalHeight / ratio;
  }

  return new docx.Paragraph({
    children: [
      new docx.ImageRun({
        data: imageData,
        transformation: {
          width: newWidth,
          height: newHeight,
        },
      }),
    ],
  });
};

export const getDocxFooter = async (firmSettingsData: any) => {
  const footer = new docx.Footer({
    children: [
      new docx.Paragraph({
        spacing: {
          before: 720,
        },
        border: {
          top: {
            color: '#F0F1F3',
            space: 2,
            style: 'single',
            size: 16,
          },
        },
      }),
      new docx.Table({
        rows: [
          new docx.TableRow({
            children: [
              new docx.TableCell({
                children: [await createFooterImage(firmSettingsData)],
                borders: transparentDocxTableBorders,
                width: {
                  size: 6000,
                  type: docx.WidthType.DXA,
                },
              }),
              new docx.TableCell({
                children: [
                  new docx.Paragraph({
                    children: [
                      new docx.TextRun({
                        text: getValueByKey(firmSettingsData?.Settings, 'firmName'),
                      }),
                    ],
                  }),
                  new docx.Paragraph({
                    children: [
                      new docx.TextRun({
                        text: getValueByKey(firmSettingsData?.Settings, 'address'),
                      }),
                    ],
                  }),
                ],
                borders: transparentDocxTableBorders,
                width: {
                  size: 6000,
                  type: docx.WidthType.DXA,
                },
              }),
              new docx.TableCell({
                children: [
                  new docx.Paragraph({
                    children: [
                      new docx.TextRun({
                        text: getValueByKey(firmSettingsData?.Settings, 'website'),
                      }),
                    ],
                  }),
                  new docx.Paragraph({
                    children: [
                      new docx.TextRun({
                        text: getValueByKey(firmSettingsData?.Settings, 'email'),
                      }),
                    ],
                  }),
                  ...(getValueByKey(firmSettingsData?.Settings, 'phone')
                    ? [
                        new docx.Paragraph({
                          children: [
                            new docx.TextRun({
                              text: `Office: ${getValueByKey(firmSettingsData?.Settings, 'phone')}`,
                            }),
                          ],
                        }),
                      ]
                    : []),
                ],
                borders: transparentDocxTableBorders,
                width: {
                  size: 6000,
                  type: docx.WidthType.DXA,
                },
              }),
            ],
          }),
        ],
      }),
    ],
  });

  return footer;
};
