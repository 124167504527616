import { DealRegistry } from '@/components/dealRegistry-page';
import { Helmet as Head } from 'react-helmet';
import useRefresh from '../../hooks/use-refresh';

const DealRegistryPage = () => {
  useRefresh();
  return (
    <>
      <Head>
        <title>Deal Registry</title>
      </Head>
      <DealRegistry />
    </>
  );
};

export default DealRegistryPage;
