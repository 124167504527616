import styled from 'styled-components';

import ModalWrappedContent from '@/components/fat-basicComponents/modal/modalWrappedContent';
import { useMutation } from '@apollo/client';
import { ISectionFields } from '../dueDiligenceRecord/types';
import { DUE_DILIGENCE_RECORD_QUERY, relinkDueDiligenceRecordFieldAnswer } from '../queries';

interface RelinkFieldProps {
  onClose: () => void;
  isOpen: boolean;
  currentRow: ISectionFields | null;
  recordId: string | null;
}

const RelinkField = ({ onClose, isOpen, currentRow, recordId }: RelinkFieldProps) => {
  const [relinkFieldAnswer] = useMutation(relinkDueDiligenceRecordFieldAnswer, {
    refetchQueries: () => [
      {
        query: DUE_DILIGENCE_RECORD_QUERY,
        variables: {
          id: recordId
        }
      }
    ]
  });

  const saveValue = () => {
    relinkFieldAnswer({
      variables: {
        id: currentRow.data.id
      },
      onCompleted: () => {
        onClose();
      }
    });
  };

  if (!recordId || !currentRow?.linkedField) return <></>;

  return (
    <ModalWrappedContent isOpen={isOpen} onClose={onClose} confirmButton={{ name: 'Confirm', onClick: saveValue }}>
      <ModalContainer>
        <ModalTitel>Confirmation</ModalTitel>
        <ModalText>
          Do you want to discard your revised answer for this field and relink it to the corresponding field on{' '}
          {currentRow.linkedField.funnelTierSection.funnelTier.name}? Your revised answer will not be recoverable.
        </ModalText>
      </ModalContainer>
    </ModalWrappedContent>
  );
};

export default RelinkField;

const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const ModalTitel = styled.div`
  font-size: 33px;
  text-align: center;
  color: ${({ theme }) => theme.font.strong};
`;

const ModalText = styled.p`
  font-weight: 400;
  font-size: 19px;
  line-height: 26.6px;
  text-align: center;
  color: ${({ theme }) => theme.font.base};
`;
