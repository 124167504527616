import { EditIcon } from '@/assets/icons/edit';
import { IInfoIcon } from '@/assets/icons/info-icons/i-info';
import CheckBox from '@/components/basicComponents/checkbox';
import BasicTooltip from '@/components/basicComponents/tooltip';
import FormattingTooltip from '@/components/basicComponents/tooltip/dollarsTooltip';
import { SuitabilityEnum } from '@/components/client-entity-details/types';
import Suitability from '@/components/fat-investors-page/fat-investorDetails-page/fat-entityDetails-page/fat-tableAllocations/suitability';
import SuitabilityDetails, { SuitabilityWrapper } from '@/components/opportunities-entity/pendingAllocation/suitabilityDetails';
import ClientsAdvisor from '@/components/recommendations-page/cell/clientsAdvisor';
import { AnchorPopover } from '@/components/table/anchor-drop-down/fat-index';
import NoResults from '@/components/table/noResults';
import RedirectTitleCell from '@/components/table/redirectTitleCell';
import { TableRowCell, TableRowWrapper, TextCell } from '@/components/table/styles';
import guid from '@/utils/guid';
import styled, { useTheme } from 'styled-components';
import { Investment } from '../../types';
import { columns } from '../constatnts';
import { IInvestmentEntity } from '../types';

interface OpenOpportunitiesTableBodyProps {
  listInvestmentEntity: IInvestmentEntity[];
  openModalWindow: (type: string, row: IInvestmentEntity) => void;
  selectRow: (row: any) => void;
  openInvestorDetails: (id: string) => void;
  openEntityDetails: (investorId: string, entityId: string) => void;
  investment: Investment | null;
}

const TableBody = ({
  listInvestmentEntity,
  openModalWindow,
  selectRow,
  openInvestorDetails,
  openEntityDetails,
  investment
}: OpenOpportunitiesTableBodyProps) => {
  const theme = useTheme();

  if (!listInvestmentEntity?.length) {
    return <NoResults />;
  }

  return (
    <>
      {listInvestmentEntity.map((row) => (
        <TableRowWrapper key={row.id}>
          <TableRowCell>
            <CheckboxWrapper id={'input'} onClick={() => selectRow(row)}>
              <CheckBox id={'input'} isActive={row.checked} />
            </CheckboxWrapper>
          </TableRowCell>
          <TableRowCell width={columns[0].width}>
            <RedirectTitleCell id="actionCurioText" onClick={() => openEntityDetails(row.family.id, row.id)}>
              {row?.entityName}
            </RedirectTitleCell>
            {/* <Entity
              row={row}
              onRedirect={() => openEntityDetails(row.family.id, row.id)}
              hyperTitle={() => openEntityDetails(row.family.id, row.id)}
              investment={investment}
            /> */}
          </TableRowCell>
          <TableRowCell width={columns[1].width}>
            <ClientsAdvisor onRedirect={() => openInvestorDetails(row.family.id)} familyName={row.family.name} advisor={row.advisor.initials} />
          </TableRowCell>
          <TableRowCell width={columns[2].width}>
            <TextCell>
              <FormattingTooltip zIndex={1000}>{row.overallCapacity}</FormattingTooltip>
            </TextCell>
          </TableRowCell>
          <TableRowCell width={columns[3].width}>
            <TextCell>
              <FormattingTooltip zIndex={1000}>{row.assetClassCapacity}</FormattingTooltip>
            </TextCell>
          </TableRowCell>
          <TableRowCell width={columns[4].width}>
            <AnchorPopover
              zIndex={9999}
              showLeft
              rowBody={
                <SuitabilityWrapper>
                  <Suitability suitability={row.suitability as SuitabilityEnum} width="80" />
                  {row.ignoreBiteSize ||
                    (((investment?.type === 'Restricted Alternative' && row.investor_interest[0]?.amount !== row.directBiteSize) ||
                      (investment?.type === 'Illiquid Alternative' && row.investor_interest[0]?.amount !== row.fundBiteSize)) && (
                      <BasicTooltip tooltipContent={'Differs from default bite size'}>
                        <IInfoIcon fill={theme.context.warning} />
                      </BasicTooltip>
                    ))}
                </SuitabilityWrapper>
              }
              title={row.suitability}
            >
              <SuitabilityDetails data={row.suitabilityCriteria} key={guid()} />
            </AnchorPopover>
          </TableRowCell>
          <TableRowCell width={columns[5].width}>
            <TextCell>
              <AmountWrapper onClick={() => openModalWindow('edit-amount', row)}>
                <RedirectTitleCell>
                  <FormattingTooltip zIndex={1000}>{row.investor_interest[0]?.amount}</FormattingTooltip>
                </RedirectTitleCell>
                <EditIconWrap id="infoWrap">
                  <EditIcon style={{ cursor: 'pointer' }} fill={theme.font.action} width={23} height={23} />
                </EditIconWrap>
              </AmountWrapper>
            </TextCell>
          </TableRowCell>
        </TableRowWrapper>
      ))}
    </>
  );
};

export default TableBody;

const CheckboxWrapper = styled.div`
  margin-right: 15px;
`;

const AmountWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const EditIconWrap = styled.div`
  display: flex;
  align-items: center;
  opacity: 0;
`;
