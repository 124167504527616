import { SUITABILITY } from '@/components/opportunities-entity/constants';
import { Advisor, Family } from '@/types/family';
import { Tenant } from '../fat-dealPipeline-page/types';
import { IStatusSuitability } from '../suitability';

export type LegalEntity = {
  id: string;
  entityName: string;
  totalEntityValue: number;
  tenant: Tenant;
};

interface IAllocationRow {
  id: string;
  checked: boolean;
  decision: boolean;
  reason: string;
  override: string[];
  decisionReason: string;
  proposalType: string;
  suitability: string;
  suitabilityStatus: keyof IStatusSuitability;
  allocationStatus: string;
  committedCapital: number;
  groupedValue: string;
  selectedGroupLegalEntity: any; // change to legal entity
  investment: {
    id: string;
    name: string;
    assetClass: string;
  };
  legalEntity: {
    outstandingAllocations: any;
    totalEntityValue: number;
    id: string;
    alwaysShow: boolean;
    entityName: string;
    overallCapacity: number;
    assetClassCapacity: number;
    isGroupEntity: boolean;
    entities: any[]; // change to legal entity
    grouped: boolean;
    family: {
      name: string;
    };
    advisor: {
      firstName: string;
      initials: string;
      lastName: string;
    };
  };
}

export interface Investment {
  symbol: string;
  allocationStatus: string;
  securityStatus: string;
  returnStatus: string;
  investment: any;
  description: string;
  id: string;
  name: string;
  assetClass: string;
  sectors: string[];
  vintageYear: string;
  economicExposure: number;
  averageCalledPercent: number;
  maxCalledPercent: number;
  countOfInvestors: number;
  committedCapital: number;
  capitalCalled: number;
  remainingCapital: number;
  distributionsToDate: number;
  currentValue: number;
  status: string;
  minimum: number;
  commitmentStatus: string;
  targetReturn: number;
  managerId: string;
  nextClose: Date;
  finalClose: Date;
  custodied: boolean;
  ubtiBlocker: boolean;
  sri: boolean;
  targetIRR: number;
  targetYield: number;
  subAssetClasses: string[];
  type: string;
  referral: string;
  contact: string;
  investmentOwner?: IOwner;
  operationsOwner?: IOwner;
  complianceOwner?: IOwner;
}

export type IRowData = {
  advisor: Advisor;
  id: string;
  terminationReason: string;
  lastUpdatedAt: Date;
  amount: number;
  allocationStatus: string;
  investment: Investment;
  legalEntity: LegalEntity;
  family: Family;
  allocations: IAllocationRow[];
};

export interface IForm {
  name: string;
  manager: string; // ISelect[];
  assetClass: string;
  minimum: string;
  symbol: string;
  status: string;
  strategies: string[];
  custodied: string;
  ubtiBlocker: string;
  sri: string;
  nextClose: Date[] | string[];
  finalClose: Date | string;
  targetIRR: string | number;
  targetYield: string | number;
  vintage: string;
  type: string;
  investmentOwner: string;
  operationsOwner: string;
  complianceOwner: string;
}

export interface InvestorReviewType {
  PotentialAllocations: InvestorPotentialAllocationsType;
}

export interface TotalAmountType {
  length: number;
  amount: number;
}

export interface InvestorPotentialAllocationsType {
  allocations: AllocationItemType[];
  investment: {
    name: string;
    id: string;
    minimum: number;
    assetClass: string;
    managerId: string;
    vintageYear: number;
    nextClose: string;
    finalClose: string;
    investorDocsDue: string;
    custodied: boolean;
    ubtiBlocker: boolean;
    sri: boolean;
    targetIRR: number;
    targetYield: number;
    subAssetClasses: string[];
    type: string;
    investmentOwner: IOwner;
    operationsOwner: IOwner;
    complianceOwner: IOwner;
  };
  page: number;
  perPage: number;
  total: number;
  totalAmount: TotalAmountType;
}

export interface AllocationItemType {
  allocationStatus: string | null;
  editable: boolean;
  committedCapital: number;
  investment: AllocationInvestmentItemType;
  legalEntity: AllocationLegalEntityItemType;
  proposalType: string;
  selectedGroupLegalEntity: any;
  suitability: SUITABILITY;
  suitabilityCriteria: SuitabilityCriterion[];
  preAllocationStatus: PreAllocationStatusType;
}

export interface PreAllocationStatusType {
  alreadyCommitted: boolean;
  committedAt: string;
  terminated: boolean;
  terminatedAt: string;
  internalReviewRejections: boolean;
  internalReviewRejectionAt: string;
}

interface AllocationInvestmentItemType {
  id: string;
  name: string;
  assetClass: string;
  type: string;
}

interface AllocationLegalEntityItemType {
  id: string;
  alwaysShow: boolean;
  entityName: string;
  overallCapacity: number;
  assetClassCapacity: number;
  totalEntityValue: number;
  isGroupEntity: boolean;
  grouped: boolean;
  fundBiteSize: number;
  directBiteSize: number;
  entities: LegalEntitiesType[];
  outstandingAllocations: OutstandingAllocationsType[];
  family: {
    name: string;
    id: string;
  };
  advisor: {
    firstName: string;
    lastName: string;
    initials: string;
  };
}

interface LegalEntitiesType {
  id: string;
  entityName: string;
  outstandingAllocations: OutstandingAllocationsType[];
}

interface OutstandingAllocationsType {
  allocationStatus: string;
  committedCapital: number;
  investment: {
    assetClass: string;
  };
}

export interface SuitabilityCriterion {
  label: string;
  value: boolean;
}

export enum Mode {
  VIEW = 'view',
  EDIT = 'edit'
}

export type ModeType = (typeof Mode)[keyof typeof Mode];

export interface IOwner {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
}
