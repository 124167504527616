import Label from '@/components/basicComponents/label';
import styled from 'styled-components';

interface EntityInputProps {
  value: string;
  setValue: (value: string) => void;
  type?: string;
  label?: string;
  required?: boolean;
  tooltip?: string;
  isInputDisabled?: boolean;
  width?: string;
  tooltipWidth?: number;
  withDollarSign?: boolean;
}

export const EntityInput = ({
  value,
  setValue,
  type = 'text',
  label,
  required = false,
  tooltip,
  isInputDisabled = false,
  width = '100%',
  tooltipWidth,
  withDollarSign = false
}: EntityInputProps) => {
  return (
    <EntityInputWrap width={width}>
      {label && (
        <LabelWrap>
          <CustomLabel required={required} tooltip={tooltip} tooltipWidth={tooltipWidth}>
            {label}
          </CustomLabel>
        </LabelWrap>
      )}
      <InputWrap>
        <Input
          withDollarSign={withDollarSign}
          type={type}
          value={value}
          onChange={(event) => setValue(event.target.value)}
          disabled={isInputDisabled}
          onWheel={(e) => (e.target as HTMLInputElement).blur()}
        />
        {withDollarSign && <Dollar>$</Dollar>}
      </InputWrap>
    </EntityInputWrap>
  );
};

const EntityInputWrap = styled.div<{ width: string }>`
  width: ${({ width }) => width};
`;

const CustomLabel = styled(Label)`
  white-space: nowrap;
`;

export const LabelWrap = styled.div`
  display: flex;
  align-items: center;
`;

export const Info = styled.img`
  font-weight: 600;
  font-size: 16px;
  cursor: pointer;
  margin-left: 6px;
`;

export const Input = styled.input<{ disabled?: boolean; width?: string; withDollarSign?: boolean }>`
  color: ${({ theme }) => theme.font.strong};
  width: ${({ width }) => (width ? width : '100%')};
  background: ${({ disabled, theme }) => (disabled ? theme.action.disabled : theme.layer[2])};
  border: 1px solid ${({ theme }) => theme.border.base};
  opacity: ${({ disabled }) => (disabled ? 0.4 : 1)};
  font-family: Blinker;
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;
  border-radius: 5px;
  padding: ${({ withDollarSign }) => (withDollarSign ? '10px 20px' : '10px 12px')};

  &:focus {
    outline: none;
  }

  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const InputWrap = styled.div`
  position: relative;
`;

const Dollar = styled.span`
  position: absolute;
  top: 10px;
  left: 10px;
  color: ${({ theme }) => theme.font.strong};
`;
