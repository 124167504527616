import ModalWrappedContent from '@/components/fat-basicComponents/modal/modalWrappedContent';
import styled from 'styled-components';

interface SignOutProps {
  onClose: () => void;
  isOpen: boolean;
}

const SuccessfulPasswordChange = ({ onClose, isOpen }: SignOutProps) => {
  return (
    <ModalWrappedContent
      isOpen={isOpen}
      onClose={onClose}
      showCancelButton={false}
      confirmButton={{
        name: 'OK',
        onClick: onClose
      }}
    >
      <ModalTitle>You have successfully changed your password.</ModalTitle>
    </ModalWrappedContent>
  );
};

export default SuccessfulPasswordChange;

const ModalTitle = styled.div`
  color: ${({ theme }) => theme.font.strong};
  font-size: 33px;
  font-weight: 400;
  line-height: 46.2px;
  text-align: center;
  padding-bottom: 35px;
`;
