import {
  MobileRow,
  MobileRowTitle,
  MobileRowValue,
  MobileTableRowWrap,
  MobileTableWrapper,
  NoResults
} from '@/components/table/mobileTable/mobileTable';
import { format } from 'date-fns';
import styled from 'styled-components';

import { IRecordData } from '@/components/dueDiligence-page/types';
import { TableColumns } from '@/components/table/types';
import guid from '@/utils/guid';

type MobileTableProps = {
  columns: TableColumns[];
  dueDiligenceRows: IRecordData[];
  handleClickInvestment: (row: IRecordData) => void;
};

const MobileTable = ({ columns, dueDiligenceRows, handleClickInvestment }: MobileTableProps) => {
  const TableRow = ({ el }: { el: IRecordData }) => {
    return (
      <MobileTableRowWrap key={guid()}>
        <MobileRow>
          <MobileRowTitle>{columns[0].title}</MobileRowTitle>
          <MobileRowValue isRedirect onClick={() => handleClickInvestment(el)}>
            {el.investment.name}
          </MobileRowValue>
        </MobileRow>

        <MobileRow>
          <MobileRowTitle>{columns[1].title}</MobileRowTitle>
          <MobileRowValue>{el.investment.assetClass}</MobileRowValue>
        </MobileRow>

        <MobileRow>
          <MobileRowTitle>{columns[2].title}</MobileRowTitle>

          <TextCellStatus isApproved={el.status === 'APPROVED'}>{el.status[0].toUpperCase() + el.status.slice(1).toLowerCase()}</TextCellStatus>
        </MobileRow>

        <MobileRow>
          <MobileRowTitle>{columns[3].title}</MobileRowTitle>
          <MobileRowValue>{el.activeTier.name}</MobileRowValue>
        </MobileRow>

        <MobileRow>
          <MobileRowTitle>{columns[4].title}</MobileRowTitle>
          <MobileRowValue>{`${el.approver.firstName} ${el.approver.lastName}`}</MobileRowValue>
        </MobileRow>

        <MobileRow>
          <MobileRowTitle>{columns[5].title}</MobileRowTitle>
          <MobileRowValue>{format(new Date(el.updatedAt), 'LLL d, yyyy')}</MobileRowValue>
        </MobileRow>
      </MobileTableRowWrap>
    );
  };

  return (
    <MobileTableWrapper>
      {dueDiligenceRows.length ? (
        dueDiligenceRows.map((el, index) => {
          // eslint-disable-next-line react/prop-types
          return <TableRow el={el} key={el.id + index} />;
        })
      ) : (
        <NoResults>No Results</NoResults>
      )}
    </MobileTableWrapper>
  );
};

export default MobileTable;

const TextCellStatus = styled(MobileRowValue)<{ isApproved: boolean }>`
  font-weight: 700;
  font-size: 16px;
  color: ${({ isApproved, theme }) => (isApproved ? theme.context.success : theme.context.error)};
`;
