import styled from 'styled-components';

import ModalWrappedContent from '@/components/fat-basicComponents/modal/modalWrappedContent';
import { ISectionFields } from '../dueDiligenceRecord/types';

interface OverrideAnswerProps {
  onClose: () => void;
  isOpen: boolean;
  currentRow: ISectionFields | null;
  openEditPage: (type: string, row: ISectionFields) => void;
}

const OverrideAnswer = ({ onClose, isOpen, currentRow, openEditPage }: OverrideAnswerProps) => {
  if (!currentRow?.linkedField) return <></>;

  const saveValue = () => {
    openEditPage(currentRow.type, currentRow);
    onClose();
  };

  return (
    <ModalWrappedContent isOpen={isOpen} onClose={onClose} confirmButton={{ name: 'Confirm', onClick: saveValue }}>
      <ModalContainer>
        <ModalTitel>Confirmation</ModalTitel>
        <ModalText>
          This field shows the corresponding answer provided on {currentRow.linkedField.funnelTierSection.funnelTier.name} by default. Do you want to
          override the default and provide a revised answer? Note that the corresponding field on{' '}
          {currentRow.linkedField.funnelTierSection.funnelTier.name} will not be impacted.
        </ModalText>
      </ModalContainer>
    </ModalWrappedContent>
  );
};

export default OverrideAnswer;

const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const ModalTitel = styled.div`
  font-size: 33px;
  text-align: center;
  color: ${({ theme }) => theme.font.strong};
`;

const ModalText = styled.p`
  font-weight: 400;
  font-size: 19px;
  line-height: 26.6px;
  text-align: center;
  color: ${({ theme }) => theme.font.base};
`;
