import { useMutation } from '@apollo/client';
import { useNavigate, useLocation } from 'react-router-dom';
import { DeleteBucketIcon } from '@/assets/static/icons/delete-bucket';
import ModalWrappedContent from '@/components/basicComponents/modal/modalWrappedContent';
import {
  DELETE_DUE_DILIGENCE_CHECKLIST_ITEM,
  GET_FUNNEL_QUERY
} from '@/components/settings-page/queries';
import styled from 'styled-components';

interface DeleteChecklistProps {
  onClose: () => void;
  isOpen: boolean;
  currentId: string;
  checklistName: string;
}

const DeleteChecklist = ({
  onClose,
  isOpen,
  currentId,
  checklistName
}: DeleteChecklistProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const [deleteChecklistItem] = useMutation(
    DELETE_DUE_DILIGENCE_CHECKLIST_ITEM,
    {
      refetchQueries: () => [
        {
          query: GET_FUNNEL_QUERY,
          variables: {
            id: queryParams.get('funnelId')
          }
        }
      ]
    }
  );

  const saveValue = () => {
    deleteChecklistItem({
      variables: {
        id: currentId
      }
    });
    onClose();
  };

  return (
    <ModalWrappedContent
      isOpen={isOpen}
      onClose={onClose}
      confirmButton={{ name: 'Confirm', onClick: saveValue }}
    >
      <ModalContainer>
        <DeleteBucketIcon width={46} height={60} />
        <ModalTitle>
          Please confirm you want to delete the{' '}
          <span style={{ fontWeight: 700 }}>{checklistName}</span> Checklist.
        </ModalTitle>
        <ModalDescription>
          This action deletes the selected checklist item. This action cannot be
          undone. Changes to this draft version of the funnel are saved
          immediately. Once you publish your draft, existing diligence records
          will be migrated to the new version, which may result in a loss of
          data.
        </ModalDescription>
      </ModalContainer>
    </ModalWrappedContent>
  );
};

export default DeleteChecklist;

const ModalContainer = styled.div`
  font-weight: 400;
  padding: 10px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;

const ModalTitle = styled.div`
  font-size: 33px;
  font-weight: 400;
  text-align: center;
  color: #2e2e2e;
`;

const ModalDescription = styled.div`
  font-size: 16px;
  line-height: 27px;
  text-align: center;
  color: #373f4e;
  font-weight: 400;
`;
