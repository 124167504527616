import {
  MobileRow,
  MobileRowTitle,
  MobileRowValue,
  MobileTableRowWrap,
  MobileTableWrapper,
  NoResults
} from '@/components/table/mobileTable/mobileTable';

import { getUTCDate } from '@/components/allocations-page/utils';
import FormattingTooltip from '@/components/basicComponents/tooltip/dollarsTooltip';
import { Investment } from '@/components/fat-dealCatalog-page/types';
import NextStep from '@/components/fat-pendingInvestments-page/fat-table/cell/nextStep';
import DropdownActions from '@/components/table/fat-dropdownActions';
import { IItems } from '@/components/table/types';
import { dateFormatter } from '@/utils/dateFormatter';
import guid from '@/utils/guid';
import { format } from 'date-fns';
import styled from 'styled-components';
import { columns } from '../../constatnts';

interface MobileTableProps {
  tableRows: Investment[];
  openMobileModal: (data: Investment, modalType: string) => void;
  openModifyInvestment: (id: string) => void;
  actionsByStatus: (row: Investment) => IItems[];
}

const MobileTable = ({ tableRows, openMobileModal, openModifyInvestment, actionsByStatus }: MobileTableProps) => {
  const TableRow = ({ el }: { el: Investment }) => {
    return (
      <MobileTableRowWrap key={el.id}>
        <MobileRow>
          <MobileRowTitle>{columns[0].title}</MobileRowTitle>
          <StrategyRowWrapper>
            <StrategyRowValue isRedirect onClick={() => openModifyInvestment(el.id)}>
              {el.name}
            </StrategyRowValue>
            {actionsByStatus(el).length ? (
              <div>
                <DropdownActions items={actionsByStatus(el)} isMobile />
              </div>
            ) : (
              <></>
            )}
          </StrategyRowWrapper>
        </MobileRow>

        <MobileRow>
          <MobileRowTitle>{columns[1].title}</MobileRowTitle>
          <MobileRowValue>{el.managerId}</MobileRowValue>
        </MobileRow>

        <MobileRow>
          <MobileRowTitle>{columns[2].title}</MobileRowTitle>
          <MobileRowValue>
            {el.aggregateInterest && (
              <FormattingTooltip zIndex={1000} key={guid()}>
                {el.aggregateInterest}
              </FormattingTooltip>
            )}
          </MobileRowValue>
        </MobileRow>

        <MobileRow>
          <MobileRowTitle>{columns[3].title}</MobileRowTitle>
          <MobileRowValue>
            {el.aggregateCommitment && (
              <FormattingTooltip zIndex={1000} key={guid()}>
                {el.aggregateCommitment}
              </FormattingTooltip>
            )}
          </MobileRowValue>
        </MobileRow>

        <MobileRow>
          <MobileRowTitle>{columns[4].title}</MobileRowTitle>
          <MobileRowValue>{el.finalClose && getUTCDate(new Date(el.finalClose))}</MobileRowValue>
        </MobileRow>

        <MobileRow>
          <MobileRowTitle>{columns[5].title}</MobileRowTitle>
          <MobileRowValue>
            {el.assignee && `${el.assignee.firstName && el.assignee?.firstName} ${el.assignee?.lastName && el.assignee.lastName}`}
          </MobileRowValue>
        </MobileRow>

        <MobileRow>
          <MobileRowTitle>{columns[6].title}</MobileRowTitle>
          <MobileRowValue isRedirect onClick={() => openMobileModal(el, 'updates')}>
            {el.updatedAt && dateFormatter(format(new Date(el.updatedAt), 'yyyy-MM-dd'), 'yyyy-MM-dd')}
          </MobileRowValue>
        </MobileRow>

        <MobileRow>
          <MobileRowTitle>{columns[7].title}</MobileRowTitle>
          <MobileRowValue>
            {el.investmentWorkflowTransitionTask && (
              <NextStep status={el.securityStatus === 'Terminated' ? 'Terminated' : el.investmentWorkflowTransitionTask.name} />
            )}
          </MobileRowValue>
        </MobileRow>
      </MobileTableRowWrap>
    );
  };

  return (
    <MobileTableWrapper>
      {tableRows.length ? (
        tableRows.map((el: any, index: number) => {
          return <TableRow el={el} key={index} />;
        })
      ) : (
        <NoResults>No Results</NoResults>
      )}
    </MobileTableWrapper>
  );
};

export default MobileTable;

const StrategyRowWrapper = styled(MobileRowValue)`
  display: flex;
  justify-content: space-between;
`;

const StrategyRowValue = styled(MobileRowValue)`
  width: 100%;
`;
