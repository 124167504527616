import { TodoCalendar } from '@/assets/icons/todo-calendar';
import { BurgerMenuIcon } from '@/assets/static/icons/burger-menu';
import { CloseMenuIcon } from '@/assets/static/icons/close-menu';
import { LogOutBurgerIcon } from '@/assets/static/icons/log-out-burger';
import { JfgLogoOnly } from '@/assets/static/logo/JFGLogoOnly';
import jfgTabs from '@/constants/tabs/jfgTabs';
import { useAuth } from '@/hooks/use-auth';
import { useResponsive } from '@/hooks/use-responsive';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import styled, { keyframes } from 'styled-components';

interface DashboardNavbarProps {
  refetch?: any;
  moduleConfig: any;
  showComplianceTab: boolean;
}

export const DashboardNavbarMobile = ({ refetch, moduleConfig, showComplianceTab }: DashboardNavbarProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { isMobile } = useResponsive();
  const { signOut, userTasks } = useAuth();

  const [active, setActive] = useState(false);
  const [isNavOpen, setIsNavOpen] = useState(false);

  const onLogoClick = () => {
    setIsNavOpen(!isNavOpen);
  };

  const mobileTabs = jfgTabs.filter((el) => {
    if (showComplianceTab) {
      return el.label !== 'Manager Updates' && el.label !== 'Settings';
    }
    return el.label !== 'Manager Updates' && el.label !== 'Settings' && el.label !== 'Compliance';
  });

  const moduleConfigFilteredTabs = !!moduleConfig
    ? mobileTabs.filter((tab) => {
        if (moduleConfig.DDR && ['dealRegistry', 'dueDiligence', 'settings'].includes(tab.path)) {
          return tab;
        }
        if (
          moduleConfig.Allocator &&
          [
            'todo',
            'dashboard',
            'clients',
            'allocations',
            'recommendations',
            'families/advisor/?advisor=all',
            'investments',
            'compliance',
            'settings'
          ].includes(tab.path)
        ) {
          return tab;
        }
        return undefined;
      })
    : mobileTabs;

  const selectedTabIndex = mobileTabs.findIndex((tab) => location.pathname.includes(tab.path.split('/')[0])) ?? 0;

  useEffect(() => {
    if (selectedTabIndex === 0) {
      setActive(true);
    } else {
      setActive(false);
    }
  }, [selectedTabIndex]);

  return (
    <NavBarWrap isNavOpen={isNavOpen}>
      <DashboardNavbarWrap isNavOpen={isNavOpen}>
        <ActionWrap isNavOpen={isNavOpen}>
          {isNavOpen ? (
            <CloseMenuWrapper>
              <CloseIconWrapper style={{ cursor: 'pointer' }} onClick={onLogoClick}>
                <CloseMenuIcon width={16} height={16} style={{ cursor: 'pointer' }} fill="#fafafa" />
              </CloseIconWrapper>
              <LogOutBtn
                onClick={() => {
                  signOut();
                  setIsNavOpen(!isNavOpen);
                  navigate('/');
                }}
              >
                <LogOutBurgerIcon width={16} height={16} />
              </LogOutBtn>
            </CloseMenuWrapper>
          ) : (
            <BurgerMenuIcon width={24} height={18} onClick={onLogoClick} style={{ cursor: 'pointer' }} fill="#fafafa" />
          )}
        </ActionWrap>
        <MenuItemsWrap isMobile={isMobile} isNavOpen={isNavOpen}>
          {isNavOpen && (
            <>
              <LogoWrap>
                <JfgLogoOnly onClick={onLogoClick} />
              </LogoWrap>
              {moduleConfig.Allocator && (
                <ToDoWrap
                  active={active}
                  onClick={() => {
                    navigate('todo');
                    setIsNavOpen(false);
                    refetch();
                  }}
                >
                  <NumberWrap isNavOpen={isNavOpen}>
                    <TodoCalendar />
                    <ToDoNumber>{userTasks}</ToDoNumber>
                  </NumberWrap>
                  {isNavOpen && <ToDoTitle>TO DO</ToDoTitle>}
                </ToDoWrap>
              )}
              <Divider />
              <MenuWrap>
                {moduleConfigFilteredTabs?.map((el, index) => {
                  return (
                    !el?.disabled && (
                      <MenuItemWrap
                        active={index === selectedTabIndex}
                        isMobile={isMobile}
                        onClick={(event) => {
                          event.preventDefault();
                          const returnUrl = (location.state?.returnUrl as string | undefined) || `/${el.path}`;
                          navigate(returnUrl, { replace: true });
                          setIsNavOpen(false);
                          refetch();
                        }}
                        key={el.path}
                      >
                        {el.icon}
                        <MenuItemTitle>{el.label}</MenuItemTitle>
                      </MenuItemWrap>
                    )
                  );
                })}
              </MenuWrap>
            </>
          )}
        </MenuItemsWrap>
      </DashboardNavbarWrap>
    </NavBarWrap>
  );
};

const NavBarWrap = styled.div<{ isNavOpen: boolean }>`
  display: flex;
  position: fixed;
  width: 100%;
  height: ${({ isNavOpen }) => (isNavOpen ? '100vh' : 0)};
  transition: 0.3s;
  z-index: 1000;
`;

const CloseIconWrapper = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CloseMenuWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const LogOutBtn = styled.div`
  background-color: #f0f1f3;
  border-radius: 50%;
  border: 1px solid #c7cbd2;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 6px;
  font-size: 13px;
  font-weight: 400;
  padding: 6px;
  cursor: pointer;
  color: #4587ec;
`;

const displayToDoTitle = keyframes`
  to {
    visibility: visible;
  }
`;

const MenuItemTitle = styled.div`
  font-family: Lato;
  color: #fff;
  font-weight: 700;
  visibility: hidden;
  animation: 0s linear 0.1s forwards ${displayToDoTitle};
`;

const MenuItemWrap = styled.div<{ active: boolean; isMobile: boolean }>`
  cursor: pointer;
  background-color: ${({ active }) => (active ? '#EFAB5D' : 'transparent')};
  width: ${({ isMobile }) => (isMobile ? '100%' : '260px')};
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 30px;
  padding: 20px 25px;
`;

const MenuWrap = styled.div`
  height: calc(100vh - 110px);
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  margin: 0px -10px;
  &::before {
    content: none;
  }
  &::-webkit-scrollbar {
    background-color: transparent;
    width: 6px;
  }
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
  &::-webkit-scrollbar-track:hover {
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #babac0;
    border-radius: 6px;
    border: 2px solid transparent;
  }
  &::-webkit-scrollbar-thumb:hover {
    background-color: #babac0;
    border: 2px solid transparent;
  }
  &::-webkit-scrollbar-button {
    display: none;
  }
`;

const ActionWrap = styled.div<{ isNavOpen: boolean }>`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${({ isNavOpen }) => (isNavOpen ? '10px 20px' : '16px 20px')};
  box-shadow: 4px 0px 8px 0px rgba(0, 0, 0, 0.25);
  background-color: #919195;
`;

const LogoWrap = styled.div`
  display: flex;
  justify-content: center;
  padding: 10px 0;
`;

const DashboardNavbarWrap = styled.div<{ isNavOpen: boolean }>`
  box-sizing: border-box;
  height: ${({ isNavOpen }) => (isNavOpen ? '100vh' : '50px')};
  overflow: hidden;
  position: ${({ isNavOpen }) => (isNavOpen ? 'absolute' : 'fixed')};
  width: 100%;
  transition: 0.3s;
  padding-bottom: 0;
  z-index: 400;
  top: 0;
  bottom: 0;
  left: 0;
`;

const MenuItemsWrap = styled.div<{ isMobile: boolean; isNavOpen: boolean }>`
  width: ${({ isMobile }) => (isMobile ? '100%' : '250px')};
  height: 100%;
  background-color: #919195;
  margin-top: ${({ isNavOpen }) => (isNavOpen ? '-1px' : 0)};
`;

const ToDoTitle = styled.div`
  font-family: Lato;
  font-weight: 700;
  font-size: 16px;
  color: #fff;
  visibility: hidden;
  animation: 0s linear 0.1s forwards ${displayToDoTitle};
`;

const NumberWrap = styled.div<{ isNavOpen: boolean }>`
  display: flex;
  flex-direction: ${({ isNavOpen }) => (isNavOpen ? 'row' : 'column')};
  align-items: center;
  justify-content: center;
  gap: 8px;
  transition: 0.3s;
`;

const ToDoNumber = styled.div`
  font-family: Lato;
  font-size: 23px;
  color: #fff;
  font-weight: 700;
`;

const ToDoWrap = styled.div<{ active?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${({ active }) => (active ? '#3AB070' : '#465D67')};
  border-radius: 10px;
  padding: 20px;
  margin: 10px;
  transition: 0.3s;
  max-height: 100px;
  height: 100%;
  cursor: pointer;
`;

const Divider = styled.div`
  background-color: #202e35;
  height: 1px;
  width: 100%;
  margin: 10px 0;
  padding: 0 10px;
`;
