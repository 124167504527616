import { useMutation, useQuery } from '@apollo/client';
import { useMemo, useState } from 'react';

import { InfoIcon } from '@/assets/icons/info-icons/info';
import { Banner } from '@/components/basicComponents/banner';
import { ClientEntityDetailsSummary } from '@/components/client-entity-details/types';
import { FormFooter } from '@/components/fat-basicComponents/formFooter';
import Header from '@/components/fat-header';
import { GoBackButton } from '@/components/fat-header/goBackButton';
import { PageTitle } from '@/components/fat-header/pageTitle';
import { LoaderOnWholeScreen } from '@/components/loaders/loader-on-whole-screen';
import { useResponsive } from '@/hooks/use-responsive';
import { useGoBack } from '@/hooks/useGoBack';
import { MainWrap } from '@/styles/common';
import { useParams, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import { GET_INVESTOR_EDIT_QUERY } from '../../fat-editInvestor/queries';
import { IInvestorQuery } from '../../types';
import { ClientDetailsSummary, LIST_SECTORS_TO_AVOID_QUERY, updateEntityMutation } from '../queries';
import { AllocationRestrictionsSection } from './sections/allocationRestrictions';
import { AllocationTargetsSection } from './sections/allocationTargets';
import { DefaultBiteSizeSection } from './sections/defaultBiteSize';
import { EntityDetailsSection } from './sections/entityDetails';
import { IDataFields } from './types';
import { buildDataToUpdate, getDefaultDataFields, isDataReadyToSave } from './utils';

export const EditEntityPage = () => {
  const params = useParams();
  const goBack = useGoBack();
  const { isMobile, isTablet } = useResponsive();
  const [searchParams, setSearchParams] = useSearchParams();

  const backToTitle = searchParams.get('backToTitle') || '';

  const [dataFields, setDataFields] = useState<IDataFields>({} as IDataFields);
  const [updateError, setUpdateError] = useState<string | null>(null);

  const [updateEntity, { loading: updating }] = useMutation(updateEntityMutation, {
    onError: (error) => {
      window.scrollTo(0, 0);
      setUpdateError(error.message);
    }
  });

  const { loading } = useQuery<ClientEntityDetailsSummary>(ClientDetailsSummary, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
    skip: !params?.entityId,
    variables: {
      includeRecommendation: true,
      id: params?.entityId
    },
    onCompleted: (data) => {
      setDataFields(getDefaultDataFields(data.ClientEntityDetailsSummary, params?.entityId) as IDataFields);
    }
  });

  const { data: investorData, loading: investorLoading } = useQuery<IInvestorQuery>(GET_INVESTOR_EDIT_QUERY, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
    skip: !params?.investorId,
    variables: {
      data: {
        id: params?.investorId
      }
    },
    onError: (error) => {
      console.error(error);
    }
  });

  const { data: listSectorsToAvoid } = useQuery<{
    listSectorsToAvoid: string[];
  }>(LIST_SECTORS_TO_AVOID_QUERY, {
    fetchPolicy: 'no-cache',
    onError: (error) => {
      console.error(error);
    }
  });

  const changeProperties = (field: keyof typeof dataFields) => (value: string | string[] | number | boolean) => {
    setDataFields((prevDataFields: IDataFields) => {
      if (field === 'overCommitRatio') {
        const sanitizedValue = String(value).replace(/\D/g, '');
        const limitedValue = sanitizedValue.slice(0, 2);

        return { ...prevDataFields, [field]: limitedValue };
      }

      return { ...prevDataFields, [field]: value };
    });
  };

  const totalTargets = useMemo(() => {
    const privateEquityTarget = Number(dataFields.privateEquityTarget);
    const privateCreditTarget = Number(dataFields.privateCreditTarget);
    const privateRealAssetTarget = Number(dataFields.privateRealAssetTarget);
    const diversifyingStrategiesTarget = Number(dataFields.diversifyingStrategiesTarget);

    return Math.round(privateEquityTarget + privateCreditTarget + privateRealAssetTarget + diversifyingStrategiesTarget);
  }, [dataFields.privateEquityTarget, dataFields.privateCreditTarget, dataFields.privateRealAssetTarget, dataFields.diversifyingStrategiesTarget]);

  const onUpdateEntity = () => {
    setUpdateError(null);
    updateEntity({
      variables: {
        data: buildDataToUpdate(dataFields)
      },
      onCompleted: () => {
        setUpdateError(null);
        goBack({ fallBack: '/investors' });
      }
    });
  };

  if (loading || investorLoading) {
    return <LoaderOnWholeScreen size={60} />;
  }

  return (
    <>
      <MainWrap>
        <Header modalControl={<GoBackButton handleClose={() => goBack({ fallBack: '/investors' })} backToTitle={backToTitle} />} />
        <PageTitle title="Edit Entity" />
      </MainWrap>
      <MainWrap>
        <PaddingWrap>
          <SectionsWrap>
            {updateError && <Banner icon={<InfoIcon width={26} height={26} />} title="Error" description={updateError} bgColor="#D63B4B" />}
            <EntityDetailsSection
              entityName={dataFields.entityName || ''}
              exportId={dataFields.exportId || ''}
              entityCRMId={dataFields.entityCRMId || ''}
              investorName={investorData.getInvestor.name}
              changeProperties={changeProperties}
            />
            <AllocationTargetsSection dataFields={dataFields} changeProperties={changeProperties} totalTargets={totalTargets} />
            <DefaultBiteSizeSection
              fundBiteSize={dataFields.fundBiteSize}
              directBiteSize={dataFields.directBiteSize}
              ignoreBiteSize={dataFields.ignoreBiteSize}
              changeProperties={changeProperties}
            />
            <AllocationRestrictionsSection
              dataFields={dataFields}
              changeProperties={changeProperties}
              listSectorsToAvoid={listSectorsToAvoid?.listSectorsToAvoid ?? []}
            />
          </SectionsWrap>
          <FormFooter
            onCancel={() => goBack({ fallBack: '/investors' })}
            onSave={onUpdateEntity}
            disableSaveButton={!updating && !isDataReadyToSave(dataFields, totalTargets)}
          />
        </PaddingWrap>
      </MainWrap>
    </>
  );
};

const PaddingWrap = styled.div`
  padding: 50px 16px 0 16px;
  @media (min-width: 600px) {
    padding-left: 50px;
    padding-right: 50px;
  }
`;

const SectionsWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
