import { ReportingData } from '@/components/fat-reportingData-page';
import { Helmet as Head } from 'react-helmet';
import useRefresh from '../../hooks/use-refresh';

const ReportingDataPage = () => {
  useRefresh();
  return (
    <>
      <Head>
        <title>Reporting Data</title>
      </Head>
      <ReportingData />
    </>
  );
};

export default ReportingDataPage;
