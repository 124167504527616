import { CloseInfoIcon } from '@/assets/icons/info-icons/closeInfo';
import styled, { useTheme } from 'styled-components';

interface BannerProps {
  icon: React.ReactNode;
  title: string;
  description: string;
  bgColor: string;
  color?: string;
  onClose?: () => void;
}

export const Banner = ({ icon, title, description, bgColor, color, onClose }: BannerProps) => {
  const theme = useTheme();

  return (
    <StatusBannerWrapper bgColor={bgColor}>
      <IconWrapper>{icon}</IconWrapper>
      <SeparateStick />
      <StatusWrapper>
        <Status color={color}>{title}</Status>
        <Description color={color}>{description}</Description>
      </StatusWrapper>
      {onClose && (
        <CrossIconWrap onClick={onClose}>
          <CloseInfoIcon width={18} height={18} fill={theme.context.warning} />
        </CrossIconWrap>
      )}
    </StatusBannerWrapper>
  );
};

export const StatusBannerWrapper = styled.div<{ bgColor: string }>`
  background: ${({ bgColor }) => bgColor};
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 10px 20px;
`;

export const SeparateStick = styled.div<{ color?: string }>`
  width: 1px;
  min-width: 1px;
  min-height: 46px;
  height: 100%;
  background-color: ${({ color, theme }) => color || theme.context.light};
`;

export const StatusWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Status = styled.span<{ color: string }>`
  color: ${({ color, theme }) => color || theme.context.light};
  font-weight: 700;
  font-size: 16px;
`;

export const Description = styled.span<{ color: string }>`
  color: ${({ color, theme }) => color || theme.context.light};
  font-weight: 400;
  font-size: 13px;
`;

const CrossIconWrap = styled.div`
  background-color: ${({ theme }) => theme.layer.base};
  width: 50px;
  height: 50px;
  border-radius: 25px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
`;

export const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
