import ModalWrappedContent from '@/components/fat-basicComponents/modal/modalWrappedContent';
import { Input } from '@/components/fat-form-field';
import { PercentageInput } from '@/components/fat-form-field/percentageInput';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import { ICommitmentPlan } from '../../fat-pacingTab/types';

interface ModelOptionsProps {
  onClose: () => void;
  isOpen: boolean;
  commitmentPlanData: ICommitmentPlan | null;
  saveModelOptions: (name: string, growthRate: number) => void;
}

const ModelOptions = ({ onClose, isOpen, commitmentPlanData, saveModelOptions }: ModelOptionsProps) => {
  const {
    handleSubmit,
    getValues,
    control,
    reset,
    formState: { isValid }
  } = useForm();

  useEffect(() => {
    if (!commitmentPlanData) return;
    reset({ name: commitmentPlanData.name, growthRate: commitmentPlanData.growthRate + '%' });
  }, [commitmentPlanData]);

  const saveValue = () => {
    if (!isValid) return;
    const { name, growthRate } = getValues();

    saveModelOptions(name.trim(), parseFloat(growthRate));
  };

  const validatePercentage = (value: string) => {
    const numericValue = parseFloat(value.replace('%', ''));
    if (isNaN(numericValue)) {
      return 'Invalid number';
    }
    if (numericValue < 0) {
      return 'Value must be at least 0';
    }
    if (numericValue > 100) {
      return 'Value must be at most 100';
    }
    return true;
  };

  return (
    <ModalWrappedContent
      isOpen={isOpen}
      onClose={onClose}
      title="Model Options"
      confirmButton={{
        name: 'Confirm',
        onClick: handleSubmit(saveValue)
      }}
    >
      <ModalBody>
        <SubTitle>Changes here will update the model forecast but will not alter the existing commitment plan.</SubTitle>
        <Input
          title={'Plan Name'}
          nameField={'name'}
          rules={{
            required: {
              value: true,
              message: 'Plan Name is required'
            }
          }}
          control={control}
          isWarning={true}
        />
        <PercentageInput
          title={'Growth Rate ex. Drawdowns'}
          nameField={'growthRate'}
          rules={{
            required: {
              value: true,
              message: 'Growth Rate ex. Drawdowns is required'
            },
            validate: validatePercentage
          }}
          control={control}
          isWarning={true}
        />
      </ModalBody>
    </ModalWrappedContent>
  );
};

export default ModelOptions;

const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

const SubTitle = styled.p`
  text-align: center;
  color: ${({ theme }) => theme.font.base};
  font-size: 19px;
  font-weight: 400;
  line-height: 26.6px;
`;
