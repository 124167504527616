import client from '@/apollo-client';
import { ApprovedIcon } from '@/assets/icons/info-icons/approved';
import { AiAssistantIcon } from '@/assets/static/icons/ai-assistant';
import { DeleteItemIcon } from '@/assets/static/icons/delete-item';
import { DownloadItemIcon } from '@/assets/static/icons/download-item';
import { OpenFileIcon } from '@/assets/static/icons/open-file';
import CheckBox from '@/components/basicComponents/checkbox';
import { DropFileArea } from '@/components/dueDiligence-page/dueDiligenceRecord/dropFileArea';
import { isImageExtension } from '@/components/dueDiligence-page/dueDiligenceRecord/utils';
import FileNotSupported from '@/components/dueDiligence-page/modals/fileNotSupported';
import { LoaderOnWholeScreen } from '@/components/loaders/loader-on-whole-screen';
import { getAssetSignedUrl } from '@/components/settings-page/queries';
import { useResponsive } from '@/hooks/use-responsive';
import { ApolloError, useMutation } from '@apollo/client';
import {
  BlockTypeSelect,
  BoldItalicUnderlineToggles,
  CreateLink,
  headingsPlugin,
  linkDialogPlugin,
  linkPlugin,
  listsPlugin,
  ListsToggle,
  MDXEditor,
  MDXEditorMethods,
  Separator,
  toolbarPlugin
} from '@mdxeditor/editor';
import fileDownload from 'js-file-download';
import React, { useEffect, useRef, useState } from 'react';
import styled, { useTheme } from 'styled-components';
import {
  addDocumentFieldAnswerAttachmentMutation,
  deleteDocumentFieldAnswerAttachmentMutation,
  GET_DOCUMENT_FIELD_ATTACHMENT_URL_QUERY,
  updateDocumentFieldAnswerAttachmentMutation,
  updateDocumentFieldAnswerMutation
} from '../../queries';
import { IDocumentFieldAnswerAttachment, IDocumentRecordFieldEntity } from '../../types';
import { EditWrapper } from './editWrapper';

interface LongFormContentPageProps {
  handleClose: () => void;
  row: IDocumentRecordFieldEntity | null;
  investmentId: string | null;
  refetch: () => void;
}

type FileDataURL = {
  name: string;
  size: number;
  lastModified: number;
  type: string;
  imgUrl: string;
  automatedContent: boolean;
};

interface IFile extends File {
  automatedContent?: boolean;
}

const fileExtensions = ['PDF', 'DOC', 'DOCX', 'XLS', 'XLSX', 'CSV', 'JPEG', 'PNG', 'GIF', 'JPG'];
const DEFAULT_ERROR_MESSAGE = `We're sorry. This process did not complete. Please try again.`;

export const LongFormContentPage = ({ handleClose, row, investmentId, refetch }: LongFormContentPageProps) => {
  const { isMobile, isTablet } = useResponsive();
  const theme = useTheme();

  const [value, setValue] = useState('');
  const [drag, setDrag] = useState(false);
  const [files, setFiles] = useState<IFile[]>([]);
  const [fileDataURL, setFileDataURL] = useState<FileDataURL[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState<IDocumentFieldAnswerAttachment[] | []>([]);
  const [updatedAutomatedContent, setUpdatedAutomatedContent] = useState<IDocumentFieldAnswerAttachment[] | []>([]);
  const [modalWindow, setModalWindow] = useState({
    isOpen: false,
    type: 'not-supported'
  });
  const [errorMessage, setErrorMessage] = useState<{ title: string; message: string } | null>(null);
  const [deletedFiles, setDeletedFiles] = useState<string[]>([]);
  const [isEditMode, setIsEditMode] = useState(true);

  const markdownEditorRef = useRef<MDXEditorMethods>(null);

  useEffect(() => {
    const newFileUrl = files.map((file) => ({
      name: file.name,
      size: file.size,
      lastModified: file.lastModified,
      type: file.type,
      imgUrl: URL.createObjectURL(file),
      automatedContent: file.automatedContent ?? true
    }));

    setFileDataURL(newFileUrl);
  }, [files]);

  useEffect(() => {
    if (!row) return;

    if (row.value === null && row.AI?.answers?.length) {
      setIsEditMode(false);
      setValue(row.AI.answers[0]);
      if (markdownEditorRef?.current) {
        markdownEditorRef.current.setMarkdown(row.AI.answers[0]);
      }
    } else {
      setIsEditMode(true);
      setValue(row.value || '');
      if (markdownEditorRef?.current) {
        markdownEditorRef.current.setMarkdown(row.value || '');
      }
    }

    setUploadedFiles(row?.attachments || []);
  }, [row]);

  useEffect(() => {
    addEventListener('popstate', handleClose, false);
    return () => {
      removeEventListener('popstate', handleClose);
    };
  }, []);

  const [addAttachment] = useMutation(addDocumentFieldAnswerAttachmentMutation);
  const [updateAttachment] = useMutation(updateDocumentFieldAnswerAttachmentMutation);
  const [deleteAttachment] = useMutation(deleteDocumentFieldAnswerAttachmentMutation, {
    onError: (error) => {
      console.error(error);
      setErrorMessage({ title: 'Error', message: DEFAULT_ERROR_MESSAGE });
    }
  });
  const [updateDocumentFieldAnswer] = useMutation(updateDocumentFieldAnswerMutation, {
    onError: (error) => {
      console.error(error);
      setErrorMessage({ title: 'Error', message: DEFAULT_ERROR_MESSAGE });
    }
  });

  const saveValue = async () => {
    setIsLoading(true);
    setErrorMessage(null);

    try {
      if (updatedAutomatedContent?.length) {
        const updatePromises = updatedAutomatedContent.map((item) => {
          return updateAttachment({
            variables: {
              data: {
                id: item.id,
                includeInAutomatedContent: !item.includeInAutomatedContent
              }
            }
          });
        });

        await Promise.all(updatePromises);
      }

      if (deletedFiles?.length) {
        const updateDeletedFiles = deletedFiles.map((item) => {
          return deleteAttachment({
            variables: {
              id: item
            }
          });
        });

        await Promise.all(updateDeletedFiles);
      }

      const uploadPromises = files.map(async (file) => {
        const { data } = await client.query({
          query: GET_DOCUMENT_FIELD_ATTACHMENT_URL_QUERY,
          variables: {
            data: {
              contentType: file.type,
              recordId: row.recordId,
              fieldId: row.fieldId,
              filename: file.name.replace(/\s/g, '')
            }
          }
        });

        await fetch(data.getDocumentFieldAnswerAttachmentUploadUrl.url, {
          method: 'PUT',
          body: file,
          headers: {
            'Content-Type': file.type
          }
        });

        return addAttachment({
          variables: {
            data: {
              id: row.id,
              assetKey: data.getDocumentFieldAnswerAttachmentUploadUrl.assetKey,
              includeInAutomatedContent: file.automatedContent ?? isImageExtension(file.name)
            }
          },
          onCompleted: (data) => {
            setUploadedFiles((prev) => [...prev, data.addDueDiligenceRecordFieldAnswerAttachment]);
            setFileDataURL([]);
            setFiles([]);
          }
        });
      });

      await Promise.all(uploadPromises);
    } catch (error) {
      setFileDataURL([]);
      setFiles([]);
      setIsLoading(false);
      if (error instanceof ApolloError && error.message.includes('Asset key already exists')) {
        setErrorMessage({ title: 'File name already exists', message: 'In order to upload your file please give it a unique name.' });
        return;
      }
      setErrorMessage({ title: 'Error', message: DEFAULT_ERROR_MESSAGE });
      return;
    }

    updateDocumentFieldAnswer({
      variables: {
        data: {
          fieldId: row.fieldId,
          recordId: row.recordId,
          value: isEditMode ? value : null
        }
      },
      onCompleted: () => {
        refetch();
        setIsLoading(false);
        handleClose();
      },
      onError: () => {
        setIsLoading(false);
      }
    });
  };

  const deleteUploadedFile = (id: string) => {
    setUploadedFiles(uploadedFiles.filter((item) => item.id !== id));
    setDeletedFiles([...deletedFiles, id]);
  };

  const allowFileExtensions = (files: FileList | null) => {
    return Array.from(files || []).filter((file: File) => {
      const fileExt = file.name.split('.').pop()?.toLowerCase();
      if ([...fileExtensions.map((item) => item.toLowerCase())].includes(fileExt || '')) {
        return file;
      }
    });
  };

  const onDropHandler = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const uploadFiles = e.dataTransfer.files;

    setDrag(false);
    if (allowFileExtensions(uploadFiles).length) {
      setFiles([...(files || []), ...allowFileExtensions(uploadFiles)]);
      return;
    }
    setModalWindow({ isOpen: true, type: 'not-supported' });
  };
  const handleUploadFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const uploadFiles = e.target.files;

    if (allowFileExtensions(uploadFiles).length) {
      setFiles([...(files || []), ...(allowFileExtensions(uploadFiles) || [])]);
      return;
    }
    setModalWindow({ isOpen: true, type: 'not-supported' });
  };

  const deletePreviewItem = (file: FileDataURL, fileIndex: number) => {
    setFileDataURL(fileDataURL.filter((item: FileDataURL, index) => index !== fileIndex));
    setFiles(files.filter((item: File, index) => index !== fileIndex));
  };

  const setAutomatedContent = (id: number) => {
    const newFiles = [...files];
    newFiles[id].automatedContent = !newFiles[id].automatedContent;
    setFiles(newFiles);
  };

  const changeAutomatedContent = (currentFile: IDocumentFieldAnswerAttachment) => {
    const newFiles = uploadedFiles.map((file) => {
      if (file.id === currentFile.id) {
        return {
          ...file,
          includeInAutomatedContent: !file.includeInAutomatedContent
        };
      }
      return file;
    });
    setUploadedFiles(newFiles);

    setUpdatedAutomatedContent((prev) => {
      const isExistingItem = prev.some((item) => item.id === currentFile.id);

      if (isExistingItem) {
        return prev.map((item) =>
          item.id === currentFile.id
            ? {
                ...item,
                includeInAutomatedContent: !item.includeInAutomatedContent
              }
            : item
        );
      }

      return [...prev, currentFile];
    });
  };

  const handleDownloadClick = async (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, assetKey: string) => {
    e.preventDefault();
    const { data } = await client.query({
      fetchPolicy: 'network-only',
      query: getAssetSignedUrl,
      variables: {
        assetKey: assetKey
      }
    });
    fetch(data.getAssetSignedUrl)
      .then((response) => response.blob())
      .then((blob) => {
        fileDownload(blob, assetKey.split('/').pop() || assetKey);
      });
  };

  const openAttachment = async (assetKey: string) => {
    const { data } = await client.query({
      fetchPolicy: 'network-only',
      query: getAssetSignedUrl,
      variables: {
        assetKey: assetKey
      }
    });
    window.open(`${data.getAssetSignedUrl}`, '_blank');
  };

  const editSuggested = (suggestion: string) => {
    const formattedSuggestion = suggestion.replace(/[<>]/g, '');
    if (markdownEditorRef?.current) {
      markdownEditorRef.current.setMarkdown(formattedSuggestion);
    }
    setValue(formattedSuggestion);
    setIsEditMode(true);
  };

  const clearSuggestion = () => {
    setValue('');
    setIsEditMode(true);
    if (markdownEditorRef?.current) {
      markdownEditorRef.current.setMarkdown('');
    }
  };

  const startWithAISuggestion = () => {
    setValue(row.AI?.answers[0]);
    if (markdownEditorRef?.current) {
      markdownEditorRef.current.setMarkdown(row.AI?.answers[0] || '');
    }
    setIsEditMode(false);
  };

  const revertPriorDraft = () => {
    if (row.value) {
      if (markdownEditorRef?.current) {
        markdownEditorRef.current.setMarkdown(row.value);
      }
      setValue(row.value);
      setIsEditMode(true);
    }
  };

  if (!row) return <></>;

  if (isLoading) {
    return <LoaderOnWholeScreen size={60} />;
  }

  return (
    <>
      {modalWindow.type === 'not-supported' && (
        <FileNotSupported isOpen={modalWindow.isOpen} onClose={() => setModalWindow({ ...modalWindow, isOpen: false })} />
      )}
      <EditWrapper
        showAiSuggestion={Boolean(row.AI?.answers?.length > 0)}
        isEditMode={isEditMode}
        startWithAISuggestion={startWithAISuggestion}
        editSuggested={editSuggested}
        clearSuggestion={clearSuggestion}
        revertPriorDraft={revertPriorDraft}
        showRevertPriorDraft={Boolean(row.value) && row.value !== value}
        handleClose={handleClose}
        errorMessage={errorMessage && errorMessage.title}
        errorDescription={errorMessage && errorMessage.message}
        row={row}
        investmentId={investmentId}
        recordId={row.recordId}
        confirmButton={{ name: 'Save', onClick: saveValue }}
      >
        <>
          <TitleTask>{row && row.documentField.name}</TitleTask>
          {isEditMode ? (
            <CustomMDXEditor
              markdown={value}
              onChange={setValue}
              ref={markdownEditorRef}
              plugins={[
                listsPlugin(),
                headingsPlugin(),
                linkPlugin(),
                linkDialogPlugin(),
                toolbarPlugin({
                  toolbarContents: () => (
                    <>
                      <BoldItalicUnderlineToggles />
                      <CreateLink />
                      <Separator />
                      <ListsToggle options={['bullet', 'number']} />
                      <Separator />
                      <BlockTypeSelect />
                    </>
                  )
                })
              ]}
            />
          ) : (
            <AiAnswerWrap>
              <AiIconWrap>
                <AiAssistantIcon width={28} height={28} fill={theme.font.weak} />
              </AiIconWrap>
              <AiAnswer>
                <span style={{ fontWeight: 700 }}>Suggested {row.AI?.citations?.length >= 2 && `(${row.AI?.citations.length - 1} more)`}:</span>{' '}
                {row.AI?.answers[0]}
              </AiAnswer>
            </AiAnswerWrap>
          )}
          {uploadedFiles?.length || files?.length || row?.attachments?.length ? (
            <>
              <PreviewWrapper>
                {uploadedFiles.map((item, index) => (
                  <PreviewItem key={item.secure_blob_storage_asset.assetKey + index}>
                    <DecorStick isImage={isImageExtension(item.secure_blob_storage_asset.assetKey)} />
                    <ApprovedIcon width="25" height="24" fill={theme.font.base} />
                    {isImageExtension(item.secure_blob_storage_asset.assetKey) ? (
                      <ImageWrapper onClick={() => openAttachment(item.secure_blob_storage_asset.assetKey)}>
                        <ImageItem src={item.secure_blob_storage_asset.url} alt={item.secure_blob_storage_asset.assetKey.split('/').pop()} />
                      </ImageWrapper>
                    ) : (
                      <FileWrapper>
                        <FileItem onClick={() => openAttachment(item.secure_blob_storage_asset.assetKey)}>
                          {item.secure_blob_storage_asset.assetKey.split('/').pop()}
                        </FileItem>
                      </FileWrapper>
                    )}
                    <ControlButtons>
                      {isImageExtension(item.secure_blob_storage_asset.assetKey) ? (
                        <>
                          <AutomatedContent>
                            <CustomPrimaryFilterItem id={'input'} onClick={() => changeAutomatedContent(item)}>
                              <CheckBox id={'input'} isActive={item.includeInAutomatedContent} /> Include in automated content
                            </CustomPrimaryFilterItem>
                          </AutomatedContent>
                          <OpenButton onClick={(e) => handleDownloadClick(e, item.secure_blob_storage_asset.assetKey)}>
                            <DownloadItemIcon fill={theme.font.action} />
                          </OpenButton>
                          <OpenButton onClick={() => openAttachment(item.secure_blob_storage_asset.assetKey)}>
                            <OpenFileIcon fill={theme.font.action} />
                          </OpenButton>
                        </>
                      ) : (
                        <>
                          <OpenButton onClick={(e) => handleDownloadClick(e, item.secure_blob_storage_asset.assetKey)}>
                            <DownloadItemIcon fill={theme.font.action} />
                          </OpenButton>
                          <OpenButton onClick={() => openAttachment(item.secure_blob_storage_asset.assetKey)}>
                            <OpenFileIcon fill={theme.font.action} />
                          </OpenButton>
                        </>
                      )}
                      <DeleteButton onClick={() => deleteUploadedFile(item.id)}>
                        <DeleteItemIcon fill={theme.context.error} />
                      </DeleteButton>
                    </ControlButtons>
                  </PreviewItem>
                ))}
                {fileDataURL
                  ?.map((file: FileDataURL, index: number) => (
                    <PreviewItem key={file.lastModified + index}>
                      <DecorStick isImage={file.type.includes('image')} />
                      <ApprovedIcon width="25" height="24" fill={theme.font.base} />
                      {file.type.includes('image') ? (
                        <ImageWrapper href={file.imgUrl} target="_blank">
                          <ImageItem src={file.imgUrl} alt={file.name} />
                        </ImageWrapper>
                      ) : (
                        <FileWrapper>
                          <FileItem href={file.imgUrl} target="_blank">
                            {file.name}
                          </FileItem>
                        </FileWrapper>
                      )}
                      <ControlButtons>
                        {file.type.includes('image') ? (
                          <>
                            <AutomatedContent>
                              <CustomPrimaryFilterItem id={'input'} onClick={() => setAutomatedContent(index)}>
                                <CheckBox id={'input'} isActive={file.automatedContent} /> Include in automated content
                              </CustomPrimaryFilterItem>
                            </AutomatedContent>
                            <OpenButton href={file.imgUrl} download>
                              <DownloadItemIcon fill={theme.font.action} />
                            </OpenButton>
                            <OpenButton href={file.imgUrl} target="_blank">
                              <OpenFileIcon fill={theme.font.action} />
                            </OpenButton>
                          </>
                        ) : (
                          <>
                            <OpenButton href={file.imgUrl} download>
                              <DownloadItemIcon fill={theme.font.action} />
                            </OpenButton>
                            <OpenButton href={file.imgUrl} target="_blank">
                              <OpenFileIcon fill={theme.font.action} />
                            </OpenButton>
                          </>
                        )}
                        <DeleteButton onClick={() => deletePreviewItem(file, index)}>
                          <DeleteItemIcon fill={theme.context.error} />
                        </DeleteButton>
                      </ControlButtons>
                    </PreviewItem>
                  ))
                  .reverse()}
              </PreviewWrapper>
            </>
          ) : (
            <></>
          )}
          <DropFileArea
            id={'contained-button-file'}
            fileExtensions={fileExtensions}
            handleUploadFile={handleUploadFile}
            onDropHandler={onDropHandler}
            drag={drag}
            setDrag={setDrag}
          />
        </>
      </EditWrapper>
    </>
  );
};

const AiAnswerWrap = styled.div`
  width: 100%;
  height: 204px;
  overflow-y: auto;
  display: flex;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.border.base};
  background: ${({ theme }) => theme.action.disabled};
  padding: 10px;
  margin-bottom: 7px;
`;

const AiIconWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border-right: 1px solid ${({ theme }) => theme.border.base};
`;

const AiAnswer = styled.div`
  padding-left: 10px;
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;
  color: ${({ theme }) => theme.font.base};
  overflow-y: auto;
`;

const TitleTask = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: ${({ theme }) => theme.font.base};
  display: flex;
  align-items: center;
  gap: 7px;
  padding-bottom: 4px;
`;

export const ImageWrapper = styled.a`
  display: block;
  width: 163px;
  height: 100px;
  overflow: hidden;
  cursor: pointer;
`;

export const ImageItem = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const FileWrapper = styled.div``;

const FileItem = styled.a`
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  text-decoration: none;
  color: ${({ theme }) => theme.font.base};
  cursor: pointer;
`;

const PreviewWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
  max-height: 200px;
  overflow: auto;
`;

const DecorStick = styled.div<{ isImage: boolean }>`
  width: 5px;
  height: ${({ isImage }) => (isImage ? '122px' : '62px')}; // need fix
  background-color: ${({ theme }) => theme.action.active};
`;

const PreviewItem = styled.div`
  width: 100%;
  height: 100%;
  padding: 10px 20px;
  border: 1px solid ${({ theme }) => theme.action.active};
  border-radius: 5px;
  background-color: ${({ theme }) => theme.layer[1]};
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 7.5px;
`;

const AutomatedContent = styled.div`
  margin-right: 30px;
  font-weight: 400;
  font-size: 13px;
  color: ${({ theme }) => theme.font.base};
  cursor: pointer;
`;

const CustomPrimaryFilterItem = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`;

const ControlButtons = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
`;

const OpenButton = styled.a`
  border: 1px solid ${({ theme }) => theme.font.action};
  border-radius: 20px;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const DeleteButton = styled(OpenButton)`
  border: 1px solid ${({ theme }) => theme.context.error};
`;

const CustomMDXEditor = styled(MDXEditor)`
  border: 1px solid ${({ theme }) => theme.border.base};
  border-radius: 7px;
  font-family: Blinker;
  font-size: 16px;
  /* line-height: 22.4px; */

  .mdxeditor-toolbar {
    top: 59px;
  }

  &.mdxeditor-popup-container {
    z-index: 1000;
  }

  .mdxeditor-root-contenteditable > div {
    cursor: text;
    min-height: 240px;
    max-height: 600px;
    overflow: auto;
  }

  .mdxeditor-root-contenteditable ul,
  .mdxeditor-root-contenteditable ol {
    padding-left: 26px;
  }
`;
