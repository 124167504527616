import { gql } from '@apollo/client';

export const INVESTMENT_QUERY = gql`
  query Investment($id: String!) {
    Investment(id: $id) {
      id
      symbol
      name
      type
      managerId
      assetClass
      sectors
      averageCalledPercent
      countOfInvestors
      committedCapital
      economicExposure
      targetReturn
      targetYield
      vintageYear
      nextClose
      finalClose
      minimum
      sri
      subAssetClasses
      tags
      description
      custodied
      ubtiBlocker
      securityStatus
      investmentOwner {
        firstName
        lastName
        email
      }
      operationsOwner {
        firstName
        lastName
        email
      }
      complianceOwner {
        firstName
        lastName
        email
      }
    }
  }
`;
