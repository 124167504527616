export const columns = [
  { title: 'Entity', key: 'ENTITY', width: 12, keySort: 'entity' },
  { title: 'Entity Code', key: 'ENTITY_CODE', width: 11, keySort: 'entity code' },
  { title: 'Series', key: 'SERIES', width: 11, keySort: 'series' },
  { title: 'Series Code', key: 'SERIES_CODE', width: 11, keySort: 'series code' },
  { title: 'Transaction Type', key: 'TRANSACTION_TYPE', width: 11, keySort: 'transaction type' },
  { title: 'Trade Date', key: 'TRADE_DATE', width: 11, keySort: 'trade date' },
  { title: 'Investment Amount', key: 'INVESTMENT_AMOUNT', width: 11, keySort: 'investment amount' },
  { title: 'Total Amount', key: 'TOTAL_AMOUNT', width: 11, keySort: 'total amount' },
  { title: 'Status', key: 'STATUS', width: 11, keySort: 'status' }
];

export const commitmentsStatusSuccess = {
  allMatched: 'All Transactions Have Matching Commitments',
  continue: 'Your transactions are ready to be saved',
  orContinue: 'Your transactions are ready to be saved',
  viewAll: 'View All Transactions'
};

export const commitmentsStatusError = {
  text: 'Missing Commitments',
  description:
    'Transactions cannot be uploaded without a matching commitment. Either remove the following rows from your import file or ensure that committed (not pending) allocations exist for each transaction.'
};
