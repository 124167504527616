import FormattingTooltip from '@/components/basicComponents/tooltip/dollarsTooltip';
import styled from 'styled-components';
import { CommitmentForecastValueDto } from '../../../types';

interface TableSumFooterProps {
  contributionsTableData: CommitmentForecastValueDto[];
  distributionsTableData: CommitmentForecastValueDto[];
  columnWidth: number;
}

interface CommitmentSummary {
  [year: number]: number;
}

const TableSumFooter = ({ contributionsTableData, distributionsTableData, columnWidth }: TableSumFooterProps) => {
  const dateContributionsSummary: CommitmentSummary = {};
  const dateDistributionsSummary: CommitmentSummary = {};

  const addValueToSummary = (summary: CommitmentSummary, date: string, allocation: number) => {
    const year = new Date(date).getFullYear();
    if (!summary[year]) {
      summary[year] = 0;
    }
    summary[year] += allocation;
  };

  contributionsTableData.forEach((commitment) => {
    commitment.data.forEach((item) => {
      addValueToSummary(dateContributionsSummary, item.date, item.value);
    });
  });

  distributionsTableData.forEach((commitment) => {
    commitment.data.forEach((item) => {
      addValueToSummary(dateDistributionsSummary, item.date, item.value);
    });
  });

  const calculateNetCashFlow = (contributions: CommitmentSummary, distributions: CommitmentSummary): CommitmentSummary => {
    const result: CommitmentSummary = {};
    for (const year in contributions) {
      if (distributions.hasOwnProperty(year) || contributions.hasOwnProperty(year)) {
        result[year] = (distributions[year] || 0) - (contributions[year] || 0);
      }
    }
    return result;
  };

  const netCashFlow = calculateNetCashFlow(dateContributionsSummary, dateDistributionsSummary);

  return (
    <TableSumWrap>
      <TableSumItem width={15}>Net Cash Flow</TableSumItem>
      {Object.entries(netCashFlow).map(([year, value]) => (
        <TableSumItem key={year} width={columnWidth}>
          <FormattingTooltip zIndex={1000}>{value}</FormattingTooltip>
        </TableSumItem>
      ))}
    </TableSumWrap>
  );
};

const TableSumWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  min-height: 60px;
  background-color: ${({ theme }) => theme.layer[2]};
  border-radius: 8px;
  padding: 0 24px;
`;

const TableSumItem = styled.div<{ width: number }>`
  display: flex;
  justify-content: flex-start;
  width: ${({ width }) => `${width}%`};
  color: ${({ theme }) => theme.font.base};
  font-family: Blinker, serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0;
`;

export default TableSumFooter;
