import { useMutation } from '@apollo/client';
import { useNavigate, useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import CheckBox from '@/components/basicComponents/checkbox';
import Label from '@/components/basicComponents/label';
import ModalWrappedContent from '@/components/basicComponents/modal/modalWrappedContent';
import FilterSelect from '@/components/basicComponents/select';
import {
  ISection,
  ITier
} from '@/components/dueDiligence-page/dueDiligenceRecord/types';
import {
  CREATE_DUE_DILIGENCE_SECTION,
  GET_FUNNEL_QUERY,
  UPDATE_DRAFT_DUE_DILIGENCE_SECTION,
  UPDATE_DUE_DILIGENCE_SECTION
} from '@/components/settings-page/queries';
import styled from 'styled-components';
import { MainSearch, ModalContainer, ModalTitle } from './addFunnel';

interface AddSectionProps {
  onClose: () => void;
  isOpen: boolean;
  currentSection: ISection | null;
  currentId: string;
  tierId: string | null;
  numberOfSections: number;
  isLimitedEdits: boolean;
}

const pdfLayoutValues = {
  ONE_COLUMN: 'One Column',
  TWO_COLUMN_GRID: 'Two Column Grid',
  THREE_COLUMN: 'Three Column'
};

const initialFormValues = {
  value: '',
  layout: 'Select',
  canScore: true
};

const AddSection = ({
  onClose,
  isOpen,
  currentSection,
  currentId,
  tierId,
  numberOfSections,
  isLimitedEdits
}: AddSectionProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const [formValues, setFormValues] = useState(initialFormValues);

  const [createSection] = useMutation(CREATE_DUE_DILIGENCE_SECTION, {
    update(cache, { data }) {
      const currentData = cache.readQuery({
        query: GET_FUNNEL_QUERY,
        variables: {
          id: queryParams.get('funnelId')
        }
      });

      const { getDueDiligenceFunnel }: any = currentData;

      cache.writeQuery({
        query: GET_FUNNEL_QUERY,
        data: {
          getDueDiligenceFunnel: {
            ...getDueDiligenceFunnel,
            tiers: getDueDiligenceFunnel.tiers.map((tier: ITier) =>
              tierId === tier.id
                ? {
                    ...tier,
                    sections: [
                      ...tier.sections,
                      {
                        ...data.createDueDiligenceFunnelTierSection,
                        order: numberOfSections ? numberOfSections + 1 : 1,
                        fields: []
                      }
                    ]
                  }
                : tier
            )
          }
        }
      });
    }
  });
  const [updateSection] = useMutation(UPDATE_DUE_DILIGENCE_SECTION);
  const [updateDraftSection] = useMutation(UPDATE_DRAFT_DUE_DILIGENCE_SECTION);

  useEffect(() => {
    if (currentSection) {
      setFormValues({
        value: currentSection.name,
        layout:
          pdfLayoutValues[
            currentSection.pdfLayout as keyof typeof pdfLayoutValues
          ],
        canScore: currentSection.userCanScore
      });
    } else {
      setFormValues(initialFormValues);
    }
  }, [currentSection]);

  const getKeyByValue = (object: typeof pdfLayoutValues, value: string) => {
    return Object.keys(object).find(
      (key) => object[key as keyof typeof object] === value
    );
  };

  const saveValue = () => {
    if (currentSection && isLimitedEdits) {
      updateSection({
        variables: {
          data: {
            id: currentId,
            name: formValues.value,
            pdfLayout: getKeyByValue(pdfLayoutValues, formValues.layout) ?? ''
          }
        },
        onCompleted: () => {
          onClose();
        }
      });

      return;
    }

    if (currentSection) {
      updateDraftSection({
        variables: {
          data: {
            id: currentId,
            name: formValues.value,
            userCanScore: formValues.canScore,
            pdfLayout: getKeyByValue(pdfLayoutValues, formValues.layout) ?? ''
          }
        }
      });
    } else {
      createSection({
        variables: {
          data: {
            funnelTierId: tierId,
            name: formValues.value,
            userCanScore: formValues.canScore,
            order: numberOfSections ? numberOfSections + 1 : 1,
            pdfLayout: getKeyByValue(pdfLayoutValues, formValues.layout) ?? ''
          }
        }
      });
    }
    onClose();
  };

  return (
    <ModalWrappedContent
      isOpen={isOpen}
      onClose={onClose}
      confirmButton={{
        name: 'Save',
        onClick: saveValue,
        disabled: !formValues.value || formValues.layout === 'Select'
      }}
      showRequiredFields
    >
      <ModalTitle>
        {currentSection ? 'Edit Section' : 'Add New Section'}
      </ModalTitle>
      <ModalContainer>
        <Label required>Name</Label>
        <MainSearch
          type="text"
          placeholder="Give this Section a unique name"
          value={formValues.value}
          onChange={(event) =>
            setFormValues({ ...formValues, value: event.target.value })
          }
          autoFocus
        />
        <LayoutSelectWrap>
          <Label required>Layout</Label>
          <CustomFilterSelect
            data={Object.values(pdfLayoutValues)}
            selected={formValues.layout}
            setSelected={(value) =>
              setFormValues({ ...formValues, layout: value })
            }
            width="100%"
            fontSize="19px"
            fontWeight="400"
            minHeight="48px"
            // textTransform="none"
          />
        </LayoutSelectWrap>
        <CheckboxWrapper
          id={'input'}
          onClick={
            isLimitedEdits
              ? () => null
              : () =>
                  setFormValues({
                    ...formValues,
                    canScore: !formValues.canScore
                  })
          }
          isDisabled={isLimitedEdits}
        >
          <CheckBox id={'input'} isActive={formValues.canScore} /> Allow User to
          Score This Section
        </CheckboxWrapper>
      </ModalContainer>
    </ModalWrappedContent>
  );
};

export default AddSection;

const LayoutSelectWrap = styled.div`
  margin-top: 10px;
`;

const CustomFilterSelect = styled(FilterSelect)`
  border-radius: 5px;
  border: 1px solid #c7cbd2;
  background: #fff;

  #dropDownSelect {
    margin-top: 15px;
  }

  #titleSelect {
    text-transform: none;
  }
`;

const CheckboxWrapper = styled.div<{ isDisabled: boolean }>`
  cursor: ${({ isDisabled }) => (isDisabled ? 'auto' : 'pointer')};
  display: flex;
  gap: 9px;
  margin-top: 20px;
  padding-top: 18px;
  border-top: 1px solid #c7cbd2;
  color: #373f4e;
  font-size: 16px;
  font-weight: 400;
  opacity: ${({ isDisabled }) => (isDisabled ? '.5' : '1')};

  &:hover #input {
    cursor: ${({ isDisabled }) => (isDisabled ? 'auto' : 'pointer')};
  }
`;
