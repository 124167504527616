import Tabs from '@/components/basicComponents/fat-tabs';
import Header from '@/components/fat-header';
import { GoBackButton } from '@/components/fat-header/goBackButton';
import { PageTitle } from '@/components/fat-header/pageTitle';
import { ALL_ADVISORY_FIRM_LIST_QUERY } from '@/components/fat-investors-page/queries';
import FilterPage from '@/components/table/fat-filterPage';
import { ISort } from '@/components/table/types';
import { useAuth } from '@/hooks/use-auth';
import { useResponsive } from '@/hooks/use-responsive';
import { MainWrap } from '@/styles/common';
import { useQuery } from '@apollo/client';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import FirmTable from './firmTable/table';
import Impersonate from './modals/impersonate';
import { LIST_USERS } from './queries';
import TenantsTable from './tenantsTable/table';
import { ITenant, IUser } from './types';

interface UsersProps {
  handleClose: () => void;
  backToTitle?: string;
}

const tabs = [
  { value: 'Your Firm', amount: null as number | null },
  { value: 'Other Tenants', amount: null }
];

const emptyUser: IUser = {
  firstName: '',
  lastName: '',
  initials: '',
  email: '',
  officeNumber: '',
  mobileNumber: '',
  roles: [],
  lastLogin: '',
  tenant: { id: '', name: '' }
};

export const Users = ({ handleClose, backToTitle }: UsersProps) => {
  const navigate = useNavigate();
  const { isMobile, isTablet } = useResponsive();

  const [activeTab, setActiveTab] = useState(tabs[0].value);
  const [search, setSearch] = useState('');
  const [primaryFilter, setPrimaryFilter] = useState<string>('All');
  const [primaryFilterList, setPrimaryFilterList] = useState<string[]>([]);
  const [sort, setSort] = useState<ISort>({
    key: 'EMAIL',
    asc: true
  });
  const [firmUsers, setFirmUsers] = useState<IUser[]>([]);
  const [advisoryFirmTenants, setAdvisoryFirmTenants] = useState<ITenant[]>([]);
  const [otherTenantUsers, setOtherTenantUsers] = useState<IUser[]>([]);

  const [modalWindow, setModalWindow] = useState({ isOpen: false, type: 'impersonate' });
  const [impersonateUser, setImpersonateUser] = useState<IUser>(emptyUser);
  const [impersonationLoading, setImpersonationLoading] = useState(false);
  const { user, signInAs } = useAuth();

  useQuery<{ allAdvisoryFirmList: ITenant[] }>(ALL_ADVISORY_FIRM_LIST_QUERY, {
    onCompleted: ({ allAdvisoryFirmList }) => {
      setAdvisoryFirmTenants(allAdvisoryFirmList);
      setPrimaryFilterList(['All', ...new Set(allAdvisoryFirmList.map((el) => el.name))]);
    }
  });

  const { refetch: refetchFirmUsers, loading: loadingFirmUsers } = useQuery<{ listUsers: IUser[] }>(LIST_USERS, {
    skip: !(activeTab === tabs[0].value),
    variables: {
      filters: {
        tenantId: user?.tenant.id,
        search,
        sortBy: sort.key.toLowerCase().replace(/_(\w)/g, (_, letter) => letter.toUpperCase()),
        sortDirection: sort.asc ? 'ASC' : 'DESC'
      }
    },
    onCompleted: ({ listUsers }) => {
      setFirmUsers(listUsers);
    }
  });

  const { refetch: refetchOtherTenantUsers, loading: loadingOtherTenantUsers } = useQuery<{ listUsers: IUser[] }>(LIST_USERS, {
    skip: !(activeTab === tabs[1].value),
    variables: {
      filters: {
        tenantId: advisoryFirmTenants.filter((el) => el.name === primaryFilter || primaryFilter === 'All').map((el) => el.id),
        search,
        sortBy: sort.key.toLowerCase().replace(/_(\w)/g, (_, letter) => letter.toUpperCase()),
        sortDirection: sort.asc ? 'ASC' : 'DESC'
      }
    },
    onCompleted: ({ listUsers }) => {
      setOtherTenantUsers(listUsers);
    }
  });

  const openModalWindow = (user: IUser, type: string) => {
    setImpersonateUser(user);
    setImpersonationLoading(false);
    setModalWindow({ type, isOpen: true });
  };

  const closeModalWindow = () => {
    setModalWindow({ ...modalWindow, isOpen: false });
    setImpersonateUser(emptyUser);
  };

  const impersonate = () => {
    if (!impersonateUser.email) {
      closeModalWindow();
      return;
    }

    setImpersonationLoading(true);
    signInAs(impersonateUser.email)
      .then(() => {
        navigate('/dealCatalog');
      })
      .catch(console.error);
  };

  return (
    <>
      {modalWindow.type === 'impersonate' && (
        <Impersonate
          isOpen={modalWindow.isOpen}
          onConfirm={impersonate}
          onCancel={closeModalWindow}
          user={impersonateUser}
          signingIn={impersonationLoading}
        />
      )}
      <MainWrap>
        <Header modalControl={<GoBackButton handleClose={handleClose} backToTitle={backToTitle} />} />
        <PageTitle title="Users" />
      </MainWrap>
      <MainWrap>
        <PaddingWrap>
          <Tabs tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab} />
          <TabsLine />
          <CustomFilterPage
            isMobile={isMobile}
            isTablet={isTablet}
            search={{
              value: search,
              onChange: setSearch,
              placeholder: 'Search'
            }}
            {...(activeTab === tabs[1].value && {
              primaryFilter: {
                value: primaryFilter,
                onChange: setPrimaryFilter,
                selects: primaryFilterList
              }
            })}
            resultsValue={activeTab === tabs[0].value ? firmUsers.length : otherTenantUsers.length}
            refetch={() => (activeTab === tabs[0].value ? refetchFirmUsers() : refetchOtherTenantUsers())}
          />
          <TableWrapper>
            {activeTab === tabs[0].value && (
              <FirmTable firmTableTableData={firmUsers} sort={sort} setSort={setSort} refetch={refetchFirmUsers} loading={loadingFirmUsers} />
            )}
            {activeTab === tabs[1].value && (
              <TenantsTable
                tenantsTableTableData={otherTenantUsers}
                sort={sort}
                setSort={setSort}
                refetch={refetchOtherTenantUsers}
                loading={loadingOtherTenantUsers}
                openModalWindow={openModalWindow}
              />
            )}
          </TableWrapper>
          <FooterModalWrap isMobile={isMobile} isTablet={isTablet} />
        </PaddingWrap>
      </MainWrap>
    </>
  );
};

const PaddingWrap = styled.div`
  padding-left: 16px;
  padding-right: 16px;
  @media (min-width: 600px) {
    padding-left: 50px;
    padding-right: 50px;
  }
`;

const TabsLine = styled.div`
  border-bottom: 1px ${({ theme }) => theme.border.base} solid;
  margin: 0 -16px;
  @media (min-width: 600px) {
    margin: 0 -50px;
  }
`;

const CustomFilterPage = styled(FilterPage)<{ isMobile: boolean; isTablet: boolean }>`
  position: sticky;
  top: ${({ isMobile, isTablet }) => (isMobile || isTablet ? '50px' : '0')};
  z-index: 9;
  margin-top: 20px;
  margin-left: -1px;
  width: calc(100% + 2px);
  padding: ${({ isMobile }) => (isMobile ? '20px 0 0 0' : '20px 0 10px 0')};
`;

const TableWrapper = styled.div`
  margin-bottom: 110px;
`;

const FooterModalWrap = styled.div<{ isMobile: boolean; isTablet: boolean }>`
  width: ${({ isMobile, isTablet }) => (isMobile || isTablet ? '100%' : 'calc(100% - 80px)')};
  display: flex;
  align-items: center;
  justify-content: center;
  height: 90px;
  padding: 10px 80px;
  position: fixed;
  left: ${({ isMobile, isTablet }) => (isMobile || isTablet ? '0' : '80px')};
  bottom: 0;
  clear: both;
  background-color: ${({ theme }) => theme.layer[1]};
  border-top: 1px solid ${({ theme }) => theme.border.base};
  z-index: 9;
`;
