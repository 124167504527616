import { useEffect, useMemo, useState } from 'react';
import styled, { useTheme } from 'styled-components';

import { NotesIcon } from '@/assets/icons/menu-icons/notes';
import { SortIcon } from '@/assets/icons/sortIcon';
import guid from '../../utils/guid';
import FormattingTooltip from '../basicComponents/tooltip/dollarsTooltip';
import { INotesData, IRowData } from './types';

type DetailsProps = {
  isOpen: boolean;
  setOpen: () => void;
  setIsNotesOpen: () => void;
  data: IRowData | undefined;
  isMobile: boolean;
  onNotesClick: (data: INotesData[] | undefined) => void;
};

export const clearStringToNum = (value?: string): number => {
  return value ? +value.replaceAll('$', '').replaceAll(',', '') : 0;
};

const Details = ({ data, isOpen, setOpen, setIsNotesOpen, isMobile = false, onNotesClick }: DetailsProps) => {
  const theme = useTheme();

  const [detailsData, setDetailsData] = useState<IRowData>();
  const detailsDataMemo = useMemo(() => {
    return data ? data : detailsData;
  }, [data]);

  useEffect(() => {
    if (data) {
      setDetailsData(data);
    }
  }, [data]);

  const notesLength = useMemo(() => {
    return detailsDataMemo?.notes.filter((el) => el.title && el.notes).length;
  }, [data]);

  return (
    <DetailsWrap isMobile={isMobile} isOpen={isOpen}>
      <CardsContainer isMobile={isMobile} isOpen={isOpen}>
        <Card isMobile={isMobile}>
          <CardTitle>
            <FormattingTooltip zIndex={120}>{clearStringToNum(detailsDataMemo?.totalClientValue)}</FormattingTooltip>
          </CardTitle>
          <CardDesc>TOTAL CLIENT VALUE</CardDesc>
        </Card>
        <Card isMobile={isMobile}>
          <CardTitle>
            <FormattingTooltip zIndex={120}>{clearStringToNum(detailsDataMemo?.committedCapital)}</FormattingTooltip>
          </CardTitle>
          <CardDesc>COMMITTED CAPITAL</CardDesc>
        </Card>
        <Card isMobile={isMobile}>
          <CardTitle>
            <FormattingTooltip zIndex={120}>{clearStringToNum(detailsDataMemo?.economicExposure)}</FormattingTooltip>
          </CardTitle>
          <CardDesc>ECONOMIC EXPOSURE</CardDesc>
        </Card>
        <Card
          isMobile={isMobile}
          id="openNotes"
          onClick={isOpen && notesLength && !isMobile ? setIsNotesOpen : () => null}
          short
          isOpen={isOpen}
          notesLength={notesLength}
        >
          <NotesCounterWrap id="openNotes" onClick={isMobile ? () => onNotesClick(data?.notes) : () => null}>
            <NotesIcon id="openNotes" width={24} height={24} fill={theme.action.primary} />
            <NotesCounter id="openNotes">{notesLength || 0}</NotesCounter>
          </NotesCounterWrap>
          <NotesTitle id="openNotes">Notes</NotesTitle>
        </Card>
      </CardsContainer>

      <MoreDetails isMobile={isMobile} isOpen={isOpen}>
        <MoreDetailItem>
          <ItemRow>
            <ItemTitle>Always Show</ItemTitle>
            <ItemValue>{detailsDataMemo?.alwaysShow ? 'Yes' : 'No'}</ItemValue>
          </ItemRow>

          <ItemRow>
            <ItemTitle>Impact</ItemTitle>
            <ItemValue>{detailsDataMemo?.impact}</ItemValue>
          </ItemRow>

          <ItemRow>
            <ItemTitle>On Hold</ItemTitle>
            <ItemValue>{detailsDataMemo?.onHold ? 'Yes' : 'No'}</ItemValue>
          </ItemRow>
        </MoreDetailItem>

        <MoreDetailItem>
          <ItemRow>
            <ItemTitle>Sectors To Avoid</ItemTitle>
            <SectorsWrap>
              {detailsDataMemo?.sectorsToAvoid.filter((sector) => sector !== '').length ? (
                detailsDataMemo?.sectorsToAvoid
                  .filter((x, i, a) => a.indexOf(x) == i)
                  .map((el) => {
                    if (el.length) {
                      return <SectorsItem key={guid()}>{el.trim()}</SectorsItem>;
                    }
                  })
              ) : (
                <ItemValue>N/A</ItemValue>
              )}
            </SectorsWrap>
          </ItemRow>
        </MoreDetailItem>
      </MoreDetails>

      <ControlDetails isMobile={isMobile} onClick={setOpen}>
        <ArrowBtn isRotate={isOpen}>
          <SortIcon fill={theme.action.primary} />
        </ArrowBtn>
        <ControlDetailsTitle isMobile={isMobile}>{isOpen ? 'Hide Details' : 'Show Details'}</ControlDetailsTitle>
      </ControlDetails>
    </DetailsWrap>
  );
};

const NotesTitle = styled.div`
  font-family: Blinker, serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 16px;
  color: ${({ theme }) => theme.action.primary};
  margin-top: 3.5px;
`;

const NotesCounterWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const NotesCounter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -5px;
  bottom: 0;
  right: 0;
  left: 0;
  font-family: Blinker, serif;
  font-size: 13px;
  font-weight: 700;
  line-height: 18px;
  text-align: center;
  color: ${({ theme }) => theme.action.primary};
`;

const ArrowBtn = styled.div<{ isRotate: boolean }>`
  transform: ${({ isRotate }) => (isRotate ? 'rotate(180deg)' : 'rotate(0deg)')};
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.3s;
`;

const ControlDetailsTitle = styled.div<{ isMobile: boolean }>`
  font-family: 'Blinker', serif;
  font-size: ${({ isMobile }) => (isMobile ? '13px' : '16px')};
  font-weight: 600;
  line-height: 22px;
  color: ${({ theme }) => theme.action.primary};
  z-index: 30;
`;

const ControlDetails = styled.div<{ isMobile: boolean }>`
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
  align-self: flex-end;
  padding: 10px 0;
  gap: 12.5px;
  z-index: 100;
  margin-top: ${({ isMobile }) => (isMobile ? '15px' : 0)};
`;

const SectorsWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
`;

const SectorsItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px 20px;
  background-color: ${({ theme }) => theme.layer[2]};
  color: ${({ theme }) => theme.font.base};
  border-radius: 12px;
  font-family: Blinker, serif;
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0;
`;

const ItemValue = styled.div`
  font-family: 'Blinker', serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  color: ${({ theme }) => theme.font.base};
`;

const ItemTitle = styled.div`
  font-family: Blinker, serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  color: ${({ theme }) => theme.font.base};
  width: 150px;
`;

const ItemRow = styled.div`
  display: flex;
  width: 310px;
  gap: 10px;
`;

const MoreDetailItem = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  gap: 14px;
`;

const CardsContainer = styled.div<{ isOpen: boolean; isMobile: boolean }>`
  display: flex;
  gap: 10px;
  flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};
  height: ${({ isOpen, isMobile }) => (isMobile && isOpen ? '350px' : isOpen ? '80px' : '0px')};
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
  transition: ${({ isMobile }) => (isMobile ? 'none' : 'height, opacity 0.5s ease-in-out')};
`;

const MoreDetails = styled.div<{ isOpen: boolean; isMobile: boolean }>`
  display: flex;
  gap: ${({ isMobile }) => (isMobile ? '14px' : '0')};
  flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};
  padding-top: ${({ isOpen }) => (isOpen ? '25px' : 0)};
  padding-bottom: ${({ isOpen }) => (isOpen ? '34px' : 0)};
  height: ${({ isOpen, isMobile }) => (isMobile && isOpen ? '190px' : isOpen && !isMobile ? '94px' : '0px')};
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
  transition: ${({ isMobile }) => (isMobile ? 'none' : 'height, opacity 0.5s ease-in-out')};
`;

const CardTitle = styled.div`
  color: ${({ theme }) => theme.font.strong};
  font-family: 'Blinker', serif;
  font-size: 23px;
  font-weight: 700;
  line-height: 32px;
  text-align: center;
`;

const CardDesc = styled.div`
  color: ${({ theme }) => theme.font.weak};
  font-family: 'Blinker', serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0;
  text-align: center;
`;

const Card = styled.div<{ isMobile: boolean; short?: boolean; isOpen?: boolean; notesLength?: number }>`
  display: flex;
  cursor: ${({ short, isOpen, notesLength }) => (short && isOpen && notesLength ? 'pointer' : 'default')};
  width: ${({ short, isMobile }) => (isMobile ? '100%' : short ? '5.3%' : '30.9%')};
  height: 80px;
  min-height: 80px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.layer.base};
  border-radius: 10px;
`;

const DetailsWrap = styled.div<{ isOpen: boolean; isMobile: boolean }>`
  padding: ${({ isMobile }) => (isMobile ? '10px 20px 0 20px' : '20px 60px 0 60px')};
  background: ${({ theme, isOpen }) => (isOpen ? theme.layer[1] : 'transparent')};
  border-bottom: ${({ theme, isOpen }) => (isOpen ? `1px solid ${theme.border.base}` : 'none')};
  transition: 0.3s;
`;

export default Details;
