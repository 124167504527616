import { useEffect, useRef } from 'react';

import TableHeader from '@/components/table/tableHeader';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useResponsive } from '../../../hooks/use-responsive';
import { InvestmentManager } from '../../../types/investments';
import { Loader } from '../../loaders/loader';
import { TableBodyWrapper, TableWrapper } from '../../table/styles';
import { ISort } from '../../table/types';
import { columns } from '../constatnts';
import TableBody from '../table/tableBody';
import MobileSumFooter from './mobileSumFooter';
import MobileTable from './mobileTable';
import TableSumFooter from './tableSumFooter';

type TableProps = {
  loading: boolean;
  managers: InvestmentManager[];
  refetch: () => void;
  sort: ISort;
  setSort: (value: ISort) => void;
};

const Table = ({ loading, managers, refetch, sort, setSort }: TableProps) => {
  const { isMobile, isTablet } = useResponsive();
  const navigate = useNavigate();

  const containerRef = useRef<HTMLDivElement | null>(null);
  const footerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const handleScroll = () => {
      if (containerRef.current && footerRef.current) {
        const footerTop = containerRef.current.getBoundingClientRect().top;

        if (footerTop > window.innerHeight) {
          footerRef.current.classList.add('sticky');
        } else {
          footerRef.current.classList.remove('sticky');
        }
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleClickManager = (manager: InvestmentManager) => {
    navigate(`/investments/${encodeURIComponent(manager.name)}`);
  };

  return (
    <>
      <TableWrapper padding="0 0 30px">
        {isMobile ? (
          <>{loading ? <Loader /> : <MobileTable columns={columns} managersRows={managers || []} handleClickManager={handleClickManager} />}</>
        ) : (
          <>
            <CustomTableHeader isTablet={isTablet} columns={columns} refetch={refetch} savedSort={sort} savedSetSort={setSort} />
            <TableBodyWrapper>
              {loading ? <Loader /> : <TableBody managers={managers} header={columns} handleClickManager={handleClickManager} />}
            </TableBodyWrapper>
          </>
        )}
      </TableWrapper>
      {isMobile && !loading ? (
        <MobileSumFooter columns={columns} loading={loading} />
      ) : !loading ? (
        <TableSumFooterWrapper ref={footerRef}>
          <TableSumFooter />
        </TableSumFooterWrapper>
      ) : (
        <></>
      )}
      <div ref={containerRef}></div>
    </>
  );
};

export default Table;

const CustomTableHeader = styled(TableHeader)<{ isTablet: boolean }>`
  position: sticky;
  top: ${({ isTablet }) => (isTablet ? '130px' : '80px')};
  z-index: 10;
  padding-top: 0;
`;

const TableSumFooterWrapper = styled.div`
  &.sticky {
    position: sticky;
    bottom: 0;
    z-index: 1;
  }
`;
