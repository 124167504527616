import { InfoIcon } from '@/assets/icons/info-icons/info';
import { Banner } from '@/components/basicComponents/banner';
import Input from '@/components/fat-basicComponents/input';
import { formatDollarInputValue, formatMaskedDollarInputValue } from '@/components/fat-basicComponents/input/utils';
import FooterModal from '@/components/fat-settings-page/importData/footer';
import { ProgressBar } from '@/components/fat-settings-page/importData/progressBar';
import { ImportWrapper } from '@/components/fat-settings-page/importData/styles';
import { useResponsive } from '@/hooks/use-responsive';
import { Control, Controller, UseFormTrigger } from 'react-hook-form';
import styled, { useTheme } from 'styled-components';
import { ICreateAutoPlanForm } from '../..';
import { DrawdownTargetsDto } from '../../../fat-pacingTab/types';
import { ChartWithTable } from './chartWithTable';

interface AllocationTargetsProps {
  autoPlanWizardSteps: { name: string; status: string }[];
  control: Control<ICreateAutoPlanForm, any>;
  assetClassChartWithTableData: DrawdownTargetsDto | null;
  trigger: UseFormTrigger<ICreateAutoPlanForm>;
  startOver: () => void;
  nextStep: () => void;
  openAssetClassTargetsPage: () => void;
}

export const AllocationTargets = ({
  autoPlanWizardSteps,
  control,
  assetClassChartWithTableData,
  trigger,
  startOver,
  nextStep,
  openAssetClassTargetsPage
}: AllocationTargetsProps) => {
  const { isMobile } = useResponsive();
  const theme = useTheme();

  const moveToTheNextStep = async () => {
    const isTotalEntityValueValid = await trigger('totalEntityValue');
    if (!isTotalEntityValueValid) return;

    nextStep();
  };

  return (
    <>
      <StepWrapper isMobile={isMobile}>
        <ProgressBar importSteps={autoPlanWizardSteps} />
        <StepDetailsWrapper>
          <StepTitle>Allocation Targets</StepTitle>
          <StepDescription>
            A commitment plan is designed to achieve the investor’s Net Asset Value in each asset class according to the allocation targets shown
            here. Because drawdown funds involve capital calls and distributions ar irregular intervals, it can be difficult to maintain a desired
            allocation target over time. Auto Plan uses a forecasting algorithm to automatically generate a commitment plan that achieves these
            targets.
          </StepDescription>
        </StepDetailsWrapper>
        <StepBodyWrapper isMobile={isMobile}>
          <FieldWrapper isMobile={isMobile}>
            <Controller
              name="totalEntityValue"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: 'Current Portfolio Value is required'
                },
                min: {
                  value: 1,
                  message: 'Current Portfolio Value must be greater than 0'
                }
              }}
              render={({ field: { onChange, value }, fieldState: { error, invalid } }) => (
                <Input
                  label="Current Portfolio Value"
                  value={value ? formatMaskedDollarInputValue(value) : ''}
                  onChange={(e) => {
                    const formattedValue = formatDollarInputValue(e.target.value);
                    onChange(formattedValue);
                  }}
                  errorText={error?.message}
                  withDollarSign
                  isRequiredField
                />
              )}
            />
            <Banner
              title=""
              description="Set the investor entity’s current portfolio value.  This figure influences the size of commitments specified in your plan."
              icon={<InfoIcon width={26} height={26} fill={theme.header.base} />}
              bgColor={theme.layer[1]}
              color={theme.font.base}
            />
          </FieldWrapper>
          <ChartWithTable drawdownTargetDialogData={assetClassChartWithTableData} openAssetClassTargetsPage={openAssetClassTargetsPage} />
        </StepBodyWrapper>
      </StepWrapper>
      <FooterModal startOver={startOver} confirmButton={{ name: 'Continue', onClick: moveToTheNextStep, disabled: false }} />
    </>
  );
};

const StepWrapper = styled(ImportWrapper)<{ isMobile: boolean }>`
  margin-bottom: 150px;
  padding: ${({ isMobile }) => (isMobile ? '20px 30px' : '40px 60px')};
`;

const StepDetailsWrapper = styled.div``;

const StepTitle = styled.p`
  font-size: 20px;
  font-weight: 700;
  line-height: 22.4px;
  text-align: center;
  margin-top: 10px;
  color: ${({ theme }) => theme.font.base};
`;

const StepDescription = styled.p`
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;
  text-align: center;
  padding: 10px;
  color: ${({ theme }) => theme.font.weak};
`;

const StepBodyWrapper = styled.div<{ isMobile: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: ${({ isMobile }) => (isMobile ? '10px' : '40px')};
`;

const FieldWrapper = styled.div<{ isMobile: boolean }>`
  display: grid;
  grid-template-columns: ${({ isMobile }) => (isMobile ? '1fr' : '1fr 1fr')};
  gap: 10px;

  & > *:nth-child(1) {
    order: ${({ isMobile }) => (isMobile ? 2 : 1)};
  }

  & > *:nth-child(2) {
    order: ${({ isMobile }) => (isMobile ? 1 : 2)};
  }
`;
