import { formatterClient, formatterNoCentClient } from '@/components/clients-page/utils';
import { formatCash } from '@/utils/formatCash';
import BasicTooltip from './index';

type FormattingTooltipProps = {
  children: number;
  zIndex?: number;
  noCent?: boolean;
  showDecimal?: boolean;
};

const FormattingTooltip = ({ children, zIndex, noCent = false, showDecimal = false }: FormattingTooltipProps) => {
  return (
    <BasicTooltip tooltipContent={noCent ? formatterNoCentClient.format(children) : formatterClient.format(children)} zIndex={zIndex}>
      {formatCash(children, showDecimal)}
    </BasicTooltip>
  );
  // return (
  //   <>
  //     {children >= 999999 || children <= -999999 ? (
  //       <BasicTooltip tooltipContent={noCent ? formatterNoCentClient.format(children) : formatterClient.format(children)} zIndex={zIndex}>
  //         {formatCash(children, showDecimal)}
  //       </BasicTooltip>
  //     ) : (
  //       formatCash(children, showDecimal)
  //     )}
  //   </>
  // );
};

export default FormattingTooltip;
