import styled from 'styled-components';

import { Status } from './index';

export const NextStepStatusRow = ({
  status,
  isMobile = false
}: {
  status: string;
  isMobile?: boolean;
}) => {
  return (
    <Row>
      <NextStep>Next step:</NextStep>
      <Status isMobile={isMobile} color={'#F4F5F6'}>
        {status}
      </Status>
    </Row>
  );
};
const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
  padding: 7px 12px;
  background: #3ab070;
`;

const NextStep = styled.span`
  font-family: 'Blinker';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #f4f5f6;
  text-transform: uppercase;
`;
