import { Dispatch, SetStateAction, useState } from 'react';

import FilterSearch from '@/components/basicComponents/input';
import { getCurrentManagers } from '@/components/dueDiligence-page/queries';
import ModalWrappedContent from '@/components/fat-basicComponents/modal/modalWrappedContent';
import { useQuery } from '@apollo/client';
import styled from 'styled-components';

interface CreateManagerProps {
  onClose: () => void;
  isOpen: boolean;
  managerList: string[];
  setManagerList: Dispatch<SetStateAction<string[]>>;
  getNewManager: (newManager: string) => void;
}

const CreateManager = ({ onClose, isOpen, managerList, setManagerList, getNewManager }: CreateManagerProps) => {
  const [value, setValue] = useState('');

  useQuery(getCurrentManagers, {
    onCompleted: (data) => {
      setManagerList(data.getCurrentManagers.values);
    }
  });

  const saveValue = () => {
    setManagerList([...new Set([value, ...managerList])]);
    getNewManager(value);
    onClose();
  };

  return (
    <ModalWrappedContent
      isOpen={isOpen}
      onClose={onClose}
      title="Create New Manager"
      confirmButton={{
        name: 'Save',
        onClick: saveValue,
        disabled: !value.length
      }}
      showRequiredFields
    >
      <ModalDescription>Managers are used to group one or more investments.</ModalDescription>
      <ManagerInputWrap>
        <CustomManagerInput
          label="Manager Name"
          placeholder="Provide a name"
          type="text"
          value={value}
          onChange={setValue}
          showSearchBtn={false}
          width="100%"
          required
          autoFocus
        />
      </ManagerInputWrap>
    </ModalWrappedContent>
  );
};

export default CreateManager;

const ModalDescription = styled.div`
  font-size: 19px;
  line-height: 27px;
  text-align: center;
  color: ${({ theme }) => theme.font.base};
`;

const CustomManagerInput = styled(FilterSearch)`
  font-weight: 300;
  font-size: 19px;
  padding: 12px;
  border-radius: 5px;
  color: ${({ theme }) => theme.font.base};
`;

const ManagerInputWrap = styled.div`
  padding-top: 35px;
`;
