import { useMutation, useQuery } from '@apollo/client';
import { useNavigate, useLocation } from 'react-router-dom';
import { useState } from 'react';
import ModalWrappedContent from '@/components/basicComponents/modal/modalWrappedContent';
import { IFunnelData } from '@/components/dueDiligence-page/types';
import { Loader } from '@/components/loaders/loader';
import {
  DUE_DILIGENCE_FUNNEL_LIST_SETTINGS_QUERY,
  UPDATE_DUE_DILIGENCE_FUNNEL
} from '@/components/settings-page/queries';
import { SORT_ORDER } from '@/components/table/types';
import { useResponsive } from '@/hooks/use-responsive';
import styled from 'styled-components';

interface PublishFunnelProps {
  onClose: () => void;
  isOpen: boolean;
  funnelName: string;
  openModalWindow: (type: string) => void;
  funnelStaticId: string | null;
  isSecondDraft: boolean;
}

const PublishFunnel = ({
  onClose,
  isOpen,
  funnelName,
  openModalWindow,
  funnelStaticId,
  isSecondDraft
}: PublishFunnelProps) => {
  const { isMobile } = useResponsive();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [numberStatuses, setNumberStatuses] = useState({
    lead: 0,
    dueDiligence: 0
  });
  const [publishedFunnel, setPublishedFunnel] = useState<IFunnelData | null>(
    null
  );

  const { loading } = useQuery(DUE_DILIGENCE_FUNNEL_LIST_SETTINGS_QUERY, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    variables: {
      data: {
        statusFilter: ['PUBLISHED'],
        sortDirection: SORT_ORDER.ASC,
        sortBy: 'ORDER'
      },
      archivedFunnelData: {
        sortDirection: SORT_ORDER.ASC,
        sortBy: 'ORDER'
      }
    },
    onCompleted: (data) => {
      const publishedFunnel: IFunnelData = data.listDueDiligenceFunnels.find(
        (funnel: IFunnelData) => funnel.funnelStaticId === funnelStaticId
      );
      if (publishedFunnel) {
        const statuses = publishedFunnel.archivedFunnels.reduce(
          (
            acc: { lead: number; dueDiligence: number },
            archivedFunnel: IFunnelData
          ) => {
            archivedFunnel.records.forEach((record) => {
              if (record.investment.securityStatus === 'DUE_DILIGENCE') {
                acc.dueDiligence++;
              } else if (record.investment.securityStatus === 'LEAD') {
                acc.lead++;
              }
            });
            return acc;
          },
          { dueDiligence: 0, lead: 0 }
        );

        statuses.dueDiligence += publishedFunnel.records.filter(
          (record) => record.investment.securityStatus === 'DUE_DILIGENCE'
        ).length;
        statuses.lead += publishedFunnel.records.filter(
          (record) => record.investment.securityStatus === 'LEAD'
        ).length;

        setPublishedFunnel(publishedFunnel);
        setNumberStatuses(statuses);
      }
    }
  });

  const [updateFunnel] = useMutation(UPDATE_DUE_DILIGENCE_FUNNEL);

  const saveValue = () => {
    if (publishedFunnel) {
      updateFunnel({
        variables: {
          data: { id: publishedFunnel.id, status: 'ARCHIVED' }
        },
        onCompleted: () => {
          updateFunnel({
            variables: {
              data: { id: queryParams.get('funnelId'), status: 'PUBLISHED' }
            },
            refetchQueries: () => [
              {
                query: DUE_DILIGENCE_FUNNEL_LIST_SETTINGS_QUERY,
                variables: {
                  data: {
                    statusFilter: ['PUBLISHED', 'DRAFT_WITHOUT_PUBLISHED'],
                    sortDirection: SORT_ORDER.ASC,
                    sortBy: 'ORDER'
                  },
                  archivedFunnelData: {
                    sortDirection: SORT_ORDER.ASC,
                    sortBy: 'ORDER'
                  }
                }
              }
            ],
            onCompleted: () => {
              openModalWindow('success');
            }
          });
        }
      });
    } else {
      updateFunnel({
        variables: {
          data: { id: queryParams.get('funnelId'), status: 'PUBLISHED' }
        },
        refetchQueries: () => [
          {
            query: DUE_DILIGENCE_FUNNEL_LIST_SETTINGS_QUERY,
            variables: {
              data: {
                statusFilter: ['PUBLISHED', 'DRAFT_WITHOUT_PUBLISHED'],
                sortDirection: SORT_ORDER.ASC,
                sortBy: 'ORDER'
              },
              archivedFunnelData: {
                sortDirection: SORT_ORDER.ASC,
                sortBy: 'ORDER'
              }
            }
          }
        ],
        onCompleted: () => {
          openModalWindow('success');
        }
      });
    }
  };

  return (
    <ModalWrappedContent
      isOpen={isOpen}
      onClose={onClose}
      confirmButton={{ name: 'Confirm', onClick: saveValue, disabled: loading }}
    >
      <ModalTitle>Publish Confirmation</ModalTitle>
      {loading ? (
        <Loader />
      ) : (
        <>
          {publishedFunnel ? (
            <ModalContainer>
              <ModalDescription fontSize="19px">
                This action will replace the current published version of{' '}
                <span style={{ fontWeight: 700 }}>{funnelName}</span>. All new
                due diligence records will use the new funnel version going
                forward. Existing due diligence records will use the prior
                funnel version.
              </ModalDescription>
              <StatusWrapper isMobile={isMobile}>
                <RecordStatus>
                  <CountWrap>
                    <Count>{numberStatuses.lead}</Count>
                  </CountWrap>
                  Leads
                </RecordStatus>
                <RecordStatus>
                  <CountWrap>
                    <Count>{numberStatuses.dueDiligence}</Count>
                  </CountWrap>
                  Due Diligence
                </RecordStatus>
              </StatusWrapper>
            </ModalContainer>
          ) : (
            <ModalContainer>
              This action will make this funnel available in the due diligence
              workflow. Once you publish this funnel you cannot edit it
              directly. Instead, you can create new versions that will be used
              for new due diligence records. But you cannot change the funnel
              definition for records that already exist. Are you sure that you
              want to publish this funnel as defined?
            </ModalContainer>
          )}
        </>
      )}
    </ModalWrappedContent>
  );
};

export default PublishFunnel;

const ModalContainer = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 10px;
`;

const ModalTitle = styled.div`
  font-size: 33px;
  text-align: center;
  color: #2e2e2e;
`;

const ModalDescription = styled.div<{ fontSize: string }>`
  color: #373f4e;
  text-align: center;
  font-family: Blinker;
  font-size: ${({ fontSize }) => fontSize};
  font-weight: 400;
  line-height: 26.6px;
`;

const StatusWrapper = styled.div<{ isMobile: boolean }>`
  display: flex;
  flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};
  gap: 30px;
  color: #2e2e2e;
  font-size: 16px;
  font-weight: 600;
`;

const RecordStatus = styled.div`
  color: #373f4e;
  font-size: 19px;
  font-weight: 400;
  display: flex;
  align-items: center;
`;

const CountWrap = styled.div`
  background-color: #36474f;
  display: inline-block;
  border-radius: 12px;
  color: #f4f5f6;
  padding: 1px 11px;
  font-size: 13px;
  margin-right: 6px;
`;

const Count = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  font-weight: 700;
`;
