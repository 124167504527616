import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

import { CloseInfoIcon } from '@/assets/icons/info-icons/closeInfo';
import { initialValuesFromTable } from '@/components/table/constants';
import { ISort, SORT_ORDER } from '@/components/table/types';
import { useResponsive } from '@/hooks/use-responsive';
import { useGoBack } from '@/hooks/useGoBack';
import { MainWrap } from '@/styles/common';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import FilterSelect from '../../../components/basicComponents/select';
import { ModalPageTitle } from '../../../components/client-details';
import Header from '../../../components/header';
import { managerSort } from '../../../components/investments-page/manager/constants';
import Details from '../../../components/investments-page/manager/details';
import Table from '../../../components/investments-page/manager/table';
import { INVESTMENTS_QUERY, MANAGER_QUERY } from '../../../components/investments-page/queries';
import FilterPage from '../../../components/table/filterPage';
import { Results, TopWrapper } from '../../../components/table/filterPage/desktop-filter';
import TablePagination from '../../../components/table/tablePagination';

const ManagerDetails = () => {
  const params = useParams();
  const goBack = useGoBack();
  const navigate = useNavigate();
  const { isMobile, isTablet } = useResponsive();
  const [searchParams, setSearchParams] = useSearchParams();

  const q = searchParams.get('q') || '';
  const key = searchParams.get('key') || 'countOfInvestors';
  const asc = searchParams.get('asc') === 'true' || false;
  const limit = searchParams.get('limit') || initialValuesFromTable.initialAmountRowsPerPage[0];
  const offset = searchParams.get('offset') || 0;

  const [isShowDetails, setIsShowDetails] = useState(true);
  const [investment, setInvestment] = useState<any | null>([]);
  const [mobileSort, setMobileSort] = useState(managerSort[9].title);
  const sortItem = managerSort.find((item) => item.title === mobileSort);
  const mobileSortList = managerSort.map((item) => item.title);

  const { data, loading, error, refetch } = useQuery(INVESTMENTS_QUERY, {
    notifyOnNetworkStatusChange: true,
    variables: {
      managerId: params?.mid,
      sort: key,
      order: asc ? SORT_ORDER.ASC : SORT_ORDER.DESC,
      page: 1,
      rowsPerPage: Number(limit),
      search: q
    }
  });

  const {
    data: managerData,
    loading: loadingManager,
    error: errorManager
  } = useQuery(MANAGER_QUERY, {
    notifyOnNetworkStatusChange: true,
    variables: { managerId: params?.mid }
  });

  const handleClickClient = (id: string) => {
    navigate(`/investments/${params?.mid}/${encodeURIComponent(id)}`);
  };

  useEffect(() => {
    if (!error && !loading && data) {
      setInvestment(data?.ManagerInvestments.investments);
    }
  }, [data, loading, error]);

  useEffect(() => {
    if (!isMobile) return;
    refetch({
      order: sortItem?.asc ? SORT_ORDER.ASC : SORT_ORDER.DESC,
      sort: sortItem?.key,
      search: q
    });
  }, [mobileSort]);

  const onChangeSearch = (searchValue: string) => {
    setSearchParams(
      (prev) => {
        if (!searchValue) {
          prev.delete('q');
          return prev;
        }
        prev.set('q', searchValue);
        return prev;
      },
      { replace: true }
    );
  };

  const onChangeSort = (value: ISort) => {
    setSearchParams(
      (prev) => {
        prev.set('key', value.key);
        prev.set('asc', value.asc.toString());
        return prev;
      },
      { replace: true }
    );
  };

  const onChangePaginationData = (limit: number, offset: number) => {
    setSearchParams(
      (prev) => {
        prev.set('limit', limit.toString());
        prev.set('offset', offset.toString());
        return prev;
      },
      { replace: true }
    );
  };

  return (
    <ClientDetailsWrap>
      <MainWrap>
        <Header
          modalControl={
            <ModalControlWrap>
              <CloseBtn onClick={() => goBack({ fallBack: '/investments' })}>
                <CloseInfoIcon width={18} height={18} />
              </CloseBtn>
              <ModalPageTitle isTablet={isTablet} isMobile={isMobile}>
                {params?.mid ?? ''}
              </ModalPageTitle>
            </ModalControlWrap>
          }
        />
        <Details data={managerData?.InvestmentManager} isOpen={isShowDetails} setOpen={() => setIsShowDetails(!isShowDetails)} isMobile={isMobile} />
      </MainWrap>
      <ContainerPage>
        <CustomFilterPage
          isMobile={isMobile}
          isTablet={isTablet}
          search={{
            value: q,
            onChange: onChangeSearch,
            placeholder: 'Search Investments'
          }}
          resultsValue={data?.ManagerInvestments.total}
          refetch={refetch}
        />
        {isMobile && (
          <SortSelectWrapper>
            <FilterSelect
              isMobile
              label={'Sort By'}
              paddingContainer={'0 20px'}
              width={'100%'}
              data={mobileSortList}
              selected={mobileSort}
              setSelected={setMobileSort}
            />
            <CustomRefetch>
              <Results>{data?.ManagerInvestments.total || 0} Results</Results>
            </CustomRefetch>
          </SortSelectWrapper>
        )}
        <Table
          refetch={refetch}
          managersRows={investment}
          handleClickClient={handleClickClient}
          loading={loading}
          savedSort={{ key, asc }}
          savedSetSort={onChangeSort}
        />
        <TableFooter>
          <TablePagination
            paginationValues={{
              limit: Number(limit),
              offset: Number(offset),
              total: data?.ManagerInvestments.total
            }}
            savePagination={onChangePaginationData}
            refetch={refetch}
            usePage
          />
        </TableFooter>
      </ContainerPage>
    </ClientDetailsWrap>
  );
};

const CustomFilterPage = styled(FilterPage)<{ isMobile: boolean; isTablet: boolean }>`
  position: sticky;
  top: ${({ isMobile, isTablet }) => (isMobile || isTablet ? '50px' : '0')};
  z-index: 12;
  background-color: #fafafa;
  margin-top: 0;
  margin-left: -1px;
  width: calc(100% + 2px);
  padding: ${({ isMobile }) => (isMobile ? '20px 0 0 0' : '20px 0 10px 0')};
`;

const SortSelectWrapper = styled.div`
  position: sticky;
  top: 120px;
  z-index: 11;
  background-color: #fafafa;
  margin-top: 0;
  padding-bottom: 10px;
`;

export const ModalControlWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;
`;

const CustomRefetch = styled(TopWrapper)`
  padding: 25px 0 0 20px;
`;

const ClientDetailsWrap = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 20;
  //overflow: hidden;
`;

export const CloseBtn = styled.div`
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  min-width: 50px;
  background: #fafafa;
  border-radius: 50%;
`;

export const ContainerPage = styled.div`
  width: 100%;
  margin-left: auto;
  box-sizing: border-box;
  margin-right: auto;
  display: block;
  padding-left: 16px;
  padding-right: 16px;
  @media (min-width: 600px) {
    padding-left: 60px;
    padding-right: 60px;
  }
  @media (min-width: 1920px) {
    max-width: 1920px;
  }
`;

const TableFooter = styled('div')`
  display: flex;
  justify-content: flex-end;
`;

export default ManagerDetails;
