import { Loader } from '@/components/loaders/loader';
import TableHeader from '@/components/table/fat-tableHeader';
import { TableBodyWrapper, TableWrapper } from '@/components/table/styles';
import { ISort, TableColumns } from '@/components/table/types';
import { useAuth } from '@/hooks/use-auth';
import { useResponsive } from '@/hooks/use-responsive';
import styled from 'styled-components';
import { advisorColumns, entityColumns, investorColumns } from '../constatnts';
import { IListAdvisor, IListEntity, IListInvestors } from '../types';
import MobileTableAdvisor from './mobileTable/mobileTableAdvisor';
import MobileTableEntity from './mobileTable/mobileTableEntity';
import MobileTableInvestor from './mobileTable/mobileTableInvestor';
import TableBodyAdvisor from './tableBodyAdvisor';
import TableBodyEntity from './tableBodyEntity';
import TableBodyInvestor from './tableBodyInvestor';

interface TableProps {
  sort: ISort;
  setSort: (value: ISort) => void;
  refetch: () => void;
  loading: boolean;
  viewByValue: string;
  openAdvisorFirmDetails: (id: string) => void;
  openInvestorDetails: (id: string) => void;
  openEntityDetails: (investorId: string, entityId: string) => void;
  changeViewBy: (veiw: string) => void;
  listAdvisers: IListAdvisor[];
  listInvestors: IListInvestors[];
  listEntity: IListEntity[];
}

const Table = ({
  sort,
  setSort,
  refetch,
  loading,
  viewByValue,
  openAdvisorFirmDetails,
  openInvestorDetails,
  openEntityDetails,
  changeViewBy,
  listAdvisers,
  listInvestors,
  listEntity
}: TableProps) => {
  const { isMobile, isTablet } = useResponsive();
  const { user } = useAuth();

  const filteredColumns = (columns: TableColumns[]) => {
    if (user && user.tenant.type === 'advisoryFirm') {
      return columns.map((column) => {
        if (column.key !== 'ADVISORY_FIRMS') {
          return column;
        }
        return { ...column, width: 0, title: '' };
      });
    }

    return columns;
  };

  const TableBody = () => {
    switch (viewByValue) {
      case 'Advisor Firm':
        return isMobile ? (
          <>
            {loading ? <Loader /> : <MobileTableAdvisor columns={advisorColumns} listAdvisers={listAdvisers} changeViewBy={openAdvisorFirmDetails} />}
          </>
        ) : (
          <TableWrapper padding="0">
            <CustomTableHeader isTablet={isTablet} refetch={refetch} columns={advisorColumns} savedSort={sort} savedSetSort={setSort} />
            <TableBodyWrapper>
              {loading ? (
                <Loader />
              ) : (
                <TableBodyAdvisor listAdvisers={listAdvisers} columns={filteredColumns(advisorColumns)} changeViewBy={openAdvisorFirmDetails} />
              )}
            </TableBodyWrapper>
          </TableWrapper>
        );
      case 'Investor':
        return isMobile ? (
          <>{loading ? <Loader /> : <MobileTableInvestor listInvestors={listInvestors} openInvestorDetails={openInvestorDetails} />}</>
        ) : (
          <TableWrapper padding="0">
            <CustomTableHeader
              isTablet={isTablet}
              refetch={refetch}
              columns={filteredColumns(investorColumns)}
              savedSort={sort}
              savedSetSort={setSort}
            />
            <TableBodyWrapper>
              {loading ? (
                <Loader />
              ) : (
                <TableBodyInvestor
                  listInvestors={listInvestors}
                  columns={filteredColumns(investorColumns)}
                  openInvestorDetails={openInvestorDetails}
                />
              )}
            </TableBodyWrapper>
          </TableWrapper>
        );
      case 'Entity':
        return isMobile ? (
          <>
            {loading ? (
              <Loader />
            ) : (
              <MobileTableEntity listEntity={listEntity} openInvestorDetails={openInvestorDetails} openEntityDetails={openEntityDetails} />
            )}
          </>
        ) : (
          <TableWrapper padding="0">
            <CustomTableHeader
              isTablet={isTablet}
              refetch={refetch}
              columns={filteredColumns(entityColumns)}
              savedSort={sort}
              savedSetSort={setSort}
            />
            <TableBodyWrapper>
              {loading ? (
                <Loader />
              ) : (
                <TableBodyEntity
                  listEntity={listEntity}
                  columns={filteredColumns(entityColumns)}
                  openInvestorDetails={openInvestorDetails}
                  openEntityDetails={openEntityDetails}
                />
              )}
            </TableBodyWrapper>
          </TableWrapper>
        );
      default:
        return <></>;
    }
  };

  return <TableBody />;
};

export default Table;

const CustomTableHeader = styled(TableHeader)<{ isTablet: boolean }>`
  position: sticky;
  top: ${({ isTablet }) => (isTablet ? '130px' : '80px')};
  z-index: 8;
  padding-top: 0;
`;
