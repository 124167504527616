import { ApproveIconWithDollarIcon } from '@/assets/icons/fat-pricing-tab-icons/approveIconWithDollar';
import { AutoPlanIcon } from '@/assets/icons/fat-pricing-tab-icons/autoPlan';
import { CommitmentPlanIcon } from '@/assets/icons/fat-pricing-tab-icons/commitmentPlan';
import { ForecastIcon } from '@/assets/icons/fat-pricing-tab-icons/forecast';
import { TimeframeIcon } from '@/assets/icons/fat-pricing-tab-icons/timeframe';
import Button from '@/components/fat-basicComponents/button';
import { LoaderOnWholeScreen } from '@/components/loaders/loader-on-whole-screen';
import { useResponsive } from '@/hooks/use-responsive';
import { useStatusData } from '@/hooks/useStatusData';
import { PaddingWrap } from '@/styles/common';
import { NetworkStatus, useMutation, useQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';
import { autoPlanMinMutation, COMMITMENT_PLAN_QUERY, COMMITMENT_PLANS_QUERY, GET_AUTO_PLAN_STATUS_QUERY } from '../queries';
import CommitmentPlanStatuses from './commitmentPlanStatuses';
import AutoPlan from './fat-modals/autoPlan';
import ExportCSV from './fat-modals/exportCSV';
import { PacingDetails } from './pacingDetails';
import { AnnualVsTargetAllocationSection } from './sections/allocationForecast';
import { CashFlowForecastSection } from './sections/cashFlowForecast';
import { CommitmentPlanSection } from './sections/commitmentPlan';
import { CumulativeCashFlowSection } from './sections/cumulativeCashFlow';
import { EconomicExposureSection } from './sections/economicExposure';
import { ForecastSummarySection } from './sections/forecastSummary';
import { NavForecastSection } from './sections/navForecast';
import { NetCashFlowsSection } from './sections/netCashFlows';
import { SectionsNav } from './sectionsNav';
import { AUTO_PLAN_STATUS_ENUM, ICommitmentPlan, PaginatedCommitmentPlans } from './types';

const zeroStateItems = [
  { icon: <TimeframeIcon />, title: 'Set a timeframe to achieve allocation targets' },
  { icon: <CommitmentPlanIcon />, title: 'Automatically generate a commitment plan' },
  { icon: <ForecastIcon />, title: 'View quarterly cashflow and NAV projections' },
  { icon: <ApproveIconWithDollarIcon />, title: 'Pace new commitments according to plan' }
];

interface PricingTabProps {
  entityName: string;
  ignoreAllocationTargets: boolean;
  handleAutoPlanWizardPage: ({ open }: { open: boolean }) => void;
}

export const PricingTab = ({ entityName, ignoreAllocationTargets, handleAutoPlanWizardPage }: PricingTabProps) => {
  const { isMobile } = useResponsive();
  const params = useParams();
  const theme = useTheme();
  const { data: statusData } = useStatusData();

  const [searchParams, setSearchParams] = useSearchParams();

  const commitmentPlanId = searchParams.get('commitmentPlanId') || '';

  const [modalWindow, setModalWindow] = useState({
    isOpen: false,
    type: 'auto-plan'
  });
  const [commitmentPlanList, setCommitmentPlanList] = useState<ICommitmentPlan[]>([]);
  const [zeroStateData, setZeroStateData] = useState<ICommitmentPlan | null>(null);
  const [autoPlanStatus, setAutoPlanStatus] = useState<AUTO_PLAN_STATUS_ENUM | null>(null);

  const [getZeroStateData, { loading: getZeroStateDataLoading, error: getZeroStateDataError }] = useMutation(autoPlanMinMutation, {
    onError: (error) => {
      console.error(error);
    }
  });

  const {
    data: commitmentPlansData,
    loading: commitmentPlansLoading,
    refetch: commitmentPlansRefetch
  } = useQuery<{ commitmentPlans: PaginatedCommitmentPlans }>(COMMITMENT_PLANS_QUERY, {
    notifyOnNetworkStatusChange: true,
    skip: !params?.entityId,
    variables: {
      input: {
        legalEntityId: params?.entityId
      }
    },
    onCompleted: (data) => {
      setCommitmentPlanList(data.commitmentPlans.data);
      if (commitmentPlanId && data.commitmentPlans.data.length) {
        const isCommitmentPlanExist = data.commitmentPlans.data.some((plan) => plan.id === commitmentPlanId);
        if (!isCommitmentPlanExist) {
          const activeCommitmentPlan = data.commitmentPlans.data.find((plan) => plan.active);

          setSearchParams(
            (prev) => {
              prev.set('commitmentPlanId', activeCommitmentPlan ? activeCommitmentPlan?.id : data.commitmentPlans.data[0].id);
              return prev;
            },
            { replace: true }
          );
        }
        return;
      }
      if (!commitmentPlanId && data.commitmentPlans.data.length) {
        const activeCommitmentPlan = data.commitmentPlans.data.find((plan) => plan.active);
        setSearchParams(
          (prev) => {
            prev.set('commitmentPlanId', activeCommitmentPlan ? activeCommitmentPlan?.id : data.commitmentPlans.data[0].id);
            return prev;
          },
          { replace: true }
        );
      }
    }
  });

  useEffect(() => {
    if (!commitmentPlansData || commitmentPlansLoading || commitmentPlansData.commitmentPlans.data.length || ignoreAllocationTargets) return;

    getZeroStateData({
      variables: {
        input: {
          name: 'Plan Example',
          startDate: 'TODAY',
          growthRate: 0,
          legalEntityId: params?.entityId,
          biteSize: 0,
          yearsToAchieveAllocationTarget: 10,
          generateAllocations: false
        }
      },
      onCompleted: (data) => {
        setZeroStateData(data.autoPlan);
      }
    });
  }, [commitmentPlansData]);

  const {
    data: commitmentPlanData,
    loading: commitmentPlanLoading,
    refetch: commitmentPlanRefetch,
    networkStatus: commitmentPlanNetworkStatus
  } = useQuery<{ commitmentPlan: ICommitmentPlan }>(COMMITMENT_PLAN_QUERY, {
    notifyOnNetworkStatusChange: true,
    skip: !commitmentPlanId || !commitmentPlanList.length,
    variables: {
      id: commitmentPlanId
    },
    onCompleted: (data) => {
      setAutoPlanStatus(data.commitmentPlan.autoPlanStatus);
    }
  });

  const { refetch: autoPlanStatusRefetch } = useQuery<{ commitmentPlan: ICommitmentPlan }>(GET_AUTO_PLAN_STATUS_QUERY, {
    notifyOnNetworkStatusChange: true,
    skip: !commitmentPlanId || !commitmentPlanList.length,
    variables: {
      id: commitmentPlanId
    },
    onCompleted: (data) => {
      setAutoPlanStatus(data.commitmentPlan.autoPlanStatus);
    }
  });

  useEffect(() => {
    const intervalId = setInterval(() => {
      autoPlanStatusRefetch();
    }, 60000);

    if (autoPlanStatus !== AUTO_PLAN_STATUS_ENUM.IN_PROGRESS) {
      clearInterval(intervalId);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [autoPlanStatus]);

  const openModalWindow = (type: string) => {
    setModalWindow({ type, isOpen: true });
  };

  const closeModalWindow = () => {
    setModalWindow({ ...modalWindow, isOpen: false });
  };

  const onChangeCommitmentPlan = (commitmentPlanName: string) => {
    const selectedCommitmentPlan = commitmentPlanList.find((plan) => {
      if (plan.active) {
        return `${plan.name} (Active)` === commitmentPlanName;
      }
      return plan.name === commitmentPlanName;
    });

    if (!selectedCommitmentPlan) return;

    setSearchParams(
      (prev) => {
        prev.set('commitmentPlanId', selectedCommitmentPlan.id);
        return prev;
      },
      { replace: true }
    );
  };

  if (
    commitmentPlanNetworkStatus === NetworkStatus.loading ||
    commitmentPlanNetworkStatus === NetworkStatus.setVariables ||
    commitmentPlansLoading ||
    getZeroStateDataLoading
  ) {
    return <LoaderOnWholeScreen />;
  }

  if (!commitmentPlansData?.commitmentPlans?.data?.length) {
    return (
      <>
        {modalWindow.isOpen && modalWindow.type === 'auto-plan' && (
          <AutoPlan
            isOpen={modalWindow.isOpen}
            onClose={closeModalWindow}
            entityName={entityName}
            openAutoPlanDialogFrom="pacingTab"
            commitmentPlansRefetch={commitmentPlansRefetch}
          />
        )}
        {!ignoreAllocationTargets && (
          <PacingDetails mode="zeroState" detailsData={zeroStateData} drawdownTargetDialogData={zeroStateData?.drawdownTargets ?? null} />
        )}
        <PaddingWrap>
          <NoInvestorsWrapper>
            <NoInvestorsTitle>
              <img src="/static/investors-page/missing-mag-glass-graphic.png" draggable="false" alt="No Investors" />
              <div>
                <h2>Create a Commitment Plan</h2>
                <p>Automatically generate a commitment plan and customize it as needed</p>
              </div>
            </NoInvestorsTitle>
            <NoInvestorsSteps>
              {zeroStateItems.map((item, index) => (
                <React.Fragment key={index}>
                  <NoInvestorsStep>
                    {item.icon}
                    <p>{item.title}</p>
                  </NoInvestorsStep>
                  {index !== zeroStateItems.length - 1 && (
                    <NoInvestorsStepSeparatorWrapper>
                      <div>
                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="21" viewBox="0 0 14 21" fill="none">
                          <path
                            d="M0.856689 2.85001L8.48669 10.5L0.856689 18.15L3.20669 20.5L13.2067 10.5L3.20669 0.5L0.856689 2.85001Z"
                            fill="#ABABAB"
                          />
                        </svg>
                      </div>
                    </NoInvestorsStepSeparatorWrapper>
                  )}
                </React.Fragment>
              ))}
            </NoInvestorsSteps>
            <AutoPlanButtonWrapper>
              <Button size="lg" onClick={() => handleAutoPlanWizardPage({ open: true })} icon={<AutoPlanIcon fill={theme.context.light} />}>
                Auto Plan
              </Button>
            </AutoPlanButtonWrapper>
          </NoInvestorsWrapper>
        </PaddingWrap>
      </>
    );
  }

  if (
    autoPlanStatus === AUTO_PLAN_STATUS_ENUM.IN_PROGRESS ||
    autoPlanStatus === AUTO_PLAN_STATUS_ENUM.WAITING_FOR_REVIEW ||
    autoPlanStatus === AUTO_PLAN_STATUS_ENUM.FAILED
  ) {
    return (
      <>
        <PacingDetails
          mode="waiting"
          detailsData={commitmentPlanData?.commitmentPlan ?? null}
          entityName={entityName}
          assetClassForecasts={commitmentPlanData?.commitmentPlan?.forecastSummary?.assetClassForecasts ?? []}
          commitmentPlanId={commitmentPlanId}
          commitmentPlanList={commitmentPlanList}
          drawdownTargetDialogData={commitmentPlanData?.commitmentPlan?.drawdownTargets ?? null}
          onChangeCommitmentPlan={onChangeCommitmentPlan}
          onEditButtonClick={() => openModalWindow('coming-soon')}
        />
        <CommitmentPlanStatuses
          status={autoPlanStatus}
          currentCommitmentPlan={commitmentPlanData?.commitmentPlan ?? null}
          commitmentPlanNetworkStatus={commitmentPlanNetworkStatus}
          commitmentPlansRefetch={commitmentPlansRefetch}
          commitmentPlanRefetch={commitmentPlanRefetch}
        />
      </>
    );
  }

  return (
    <>
      {modalWindow.isOpen && modalWindow.type === 'coming-soon' && (
        <ExportCSV isOpen={modalWindow.isOpen} onClose={closeModalWindow} planId={commitmentPlanData?.commitmentPlan?.id ?? null} />
      )}
      <PacingDetails
        mode="pacingTab"
        detailsData={commitmentPlanData?.commitmentPlan ?? null}
        entityName={entityName}
        assetClassForecasts={commitmentPlanData?.commitmentPlan?.forecastSummary?.assetClassForecasts ?? []}
        commitmentPlanId={commitmentPlanId}
        commitmentPlanList={commitmentPlanList}
        drawdownTargetDialogData={commitmentPlanData?.commitmentPlan?.drawdownTargets ?? null}
        onChangeCommitmentPlan={onChangeCommitmentPlan}
        onEditButtonClick={() => openModalWindow('coming-soon')}
      />
      <PricingTabWrapper isMobile={isMobile}>
        <SectionsNav commitmentPlanLoading={commitmentPlanLoading} />
        <SectionsWrapper>
          <ForecastSummarySection
            netCommitments={commitmentPlanData?.commitmentPlan?.forecast?.netCommitments ?? []}
            totalNAVs={commitmentPlanData?.commitmentPlan?.forecast?.totalNAVs ?? []}
            cumulativeContributions={commitmentPlanData?.commitmentPlan?.forecast?.cumulativeContributions ?? []}
            cumulativeDistributions={commitmentPlanData?.commitmentPlan?.forecast?.cumulativeDistributions ?? []}
          />
          <CommitmentPlanSection
            planAllocations={commitmentPlanData?.commitmentPlan?.planAllocations ?? []}
            targetSubAssetClasses={statusData?.target_sub_asset_classes || false}
            commitmentPlanId={commitmentPlanData?.commitmentPlan?.id ?? null}
          />
          <AnnualVsTargetAllocationSection
            assetClassPercentages={commitmentPlanData?.commitmentPlan?.forecast?.assetClassPercentages ?? []}
            drawdownTarget={commitmentPlanData?.commitmentPlan?.drawdownTarget ?? 0}
          />
          <NavForecastSection
            assetClassNAVs={commitmentPlanData?.commitmentPlan?.forecast?.assetClassNAVs ?? []}
            existingCommitmentNAVs={commitmentPlanData?.commitmentPlan?.forecast?.existingCommitmentNAVs ?? []}
            targetSubAssetClasses={statusData?.target_sub_asset_classes || false}
            commitmentPlanId={commitmentPlanData?.commitmentPlan?.id ?? null}
          />
          {/* <CommitmentsVsTargetAllocationSection /> */}
          <CashFlowForecastSection
            assetClassContributions={commitmentPlanData?.commitmentPlan?.forecast?.assetClassContributions ?? []}
            assetClassDistributions={commitmentPlanData?.commitmentPlan?.forecast?.assetClassDistributions ?? []}
            existingCommitmentContributions={commitmentPlanData?.commitmentPlan?.forecast?.existingCommitmentContributions ?? []}
            existingCommitmentDistributions={commitmentPlanData?.commitmentPlan?.forecast?.existingCommitmentDistributions ?? []}
            targetSubAssetClasses={statusData?.target_sub_asset_classes || false}
            commitmentPlanId={commitmentPlanData?.commitmentPlan?.id ?? null}
          />
          <EconomicExposureSection
            totalNAVs={commitmentPlanData?.commitmentPlan?.forecast?.totalNAVs ?? []}
            uncalledCapital={commitmentPlanData?.commitmentPlan?.forecast?.uncalledCapital ?? []}
          />
          {/* <UnfundedCommitmentsAndNavSection
            totalNAVs={commitmentPlanData?.commitmentPlan?.forecast?.totalNAVs ?? []}
            uncalledCapital={commitmentPlanData?.commitmentPlan?.forecast?.uncalledCapital ?? []}
          /> */}
          <NetCashFlowsSection
            netContributions={commitmentPlanData?.commitmentPlan?.forecast?.netContributions ?? []}
            netDistributions={commitmentPlanData?.commitmentPlan?.forecast?.netDistributions ?? []}
          />
          <CumulativeCashFlowSection
            cumulativeContributions={commitmentPlanData?.commitmentPlan?.forecast?.cumulativeContributions ?? []}
            cumulativeDistributions={commitmentPlanData?.commitmentPlan?.forecast?.cumulativeDistributions ?? []}
          />
        </SectionsWrapper>
      </PricingTabWrapper>
    </>
  );
};

const PricingTabWrapper = styled.div<{ isMobile: boolean }>`
  padding: ${({ isMobile }) => (isMobile ? '10px 16px' : '10px 50px')};
`;

const SectionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
`;

const NoInvestorsWrapper = styled.div`
  margin: 100px;
`;

const NoInvestorsTitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: ${({ theme }) => theme.font.weak};
  padding-bottom: 40px;
  border-bottom: 1px solid ${({ theme }) => theme.border.base};
`;

const NoInvestorsSteps = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

const NoInvestorsStep = styled.div`
  display: flex;
  width: 200px;
  height: 139px;
  padding: 10px;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  gap: 15px;
  color: ${({ theme }) => theme.font.base};
  text-align: center;
`;

const NoInvestorsStepSeparatorWrapper = styled.div`
  display: flex;
  width: 40px;
  height: 40px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.font.weak};
  & > div {
    width: 20px;
    height: 12.35px;
    flex-shrink: 0;
  }
`;

const AutoPlanButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding-top: 40px;
`;
