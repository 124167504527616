import { User } from '@/types/user';
import { NetworkStatus, useLazyQuery, useMutation, useQuery, useSubscription } from '@apollo/client';
import { subject } from '@casl/ability';
import lodash from 'lodash';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

import { buildAbilityFor } from '../../config/ability';
import { useAuth } from '../../hooks/use-auth';
import { useResponsive } from '../../hooks/use-responsive';
import { Allocation } from '../../types/investments';
import guid from '../../utils/guid';
import { Loader } from '../loaders/loader';
import { updateAllocationMutation } from '../opportunities-entity/pendingAllocation/constants';
import { OPERATIONS_STATUS } from '../opportunities-entity/proposed';
import { FiltersStructure } from '../table/filterPage/types';
import { TableBodyWrapper, TableWrapper } from '../table/styles';
import TableHeader from '../table/tableHeader';
import TablePagination from '../table/tablePagination';
import { IItems, ISort, SORT_ORDER } from '../table/types';
import { TASKS_QUERY } from '../todo-page/queries';
import { columns } from './constants';
import AssignTask from './modals/assignTask';
import Complete from './modals/complete';
import EditAmount from './modals/editAmount';
import EditOwner from './modals/editOwner';
import Terminate from './modals/terminate';
import { ModalWindowType } from './modals/types';
import {
  ALLOCATION,
  ALLOCATION_SUBSCRIPTION,
  RECOMMENDATION_QUERY,
  generateSubdocMutation,
  updateAllocationAmountMutation,
  updateAllocationAssigneeMutation,
  updateAllocationOwnersMutation
} from './queries';
import { TableBody } from './tableBody';
import MobileTable from './tableMobile';
import { ACTIONS, FieldsForRedirectWindows, IRowData, ModalWindow, RecommendationsQuery, TypeWindow } from './types';

export type TableProps = {
  search: string;
  assigneeFilter: string;
  additionalFiltersData: FiltersStructure | any;
  saveFiltrationResults: (results: number, refetch: () => void) => void;
  detailsClick: (type: TypeWindow, id: FieldsForRedirectWindows) => void;
  sort: ISort;
  setSort: (value: ISort) => void;
  setAmountResult?: any;
  paginationData: { limit: number; offset: number };
  onChangePaginationData: (limit: number, offset: number) => void;
  makeScroll: () => void;
  onClickEntity: (data: IRowData) => void;
  onClickInvestment: (data: IRowData) => void;
  omClickLastUpdate: (data: IRowData) => void;
};

const Table = ({
  search,
  saveFiltrationResults,
  assigneeFilter,
  additionalFiltersData,
  detailsClick,
  sort,
  setSort,
  setAmountResult,
  paginationData,
  onChangePaginationData,
  makeScroll,
  onClickEntity,
  onClickInvestment,
  omClickLastUpdate
}: TableProps) => {
  const { isAuthenticated, user, setUserTasks } = useAuth();

  const { refetch: refetchTask } = useQuery(TASKS_QUERY, {
    variables: {},
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      setUserTasks(data?.AllocationTasks?.assignedToMeTaskCount || 0);
    }
  });
  const ability = buildAbilityFor(useAuth().user as User);
  const [allocations, setAllocations] = useState<IRowData[]>([]);
  const [modalWindow, setModalWindow] = useState<ModalWindow>({
    isOpen: false,
    type: 'assign-task'
  });
  const [currentRow, setCurrentRow] = useState<IRowData | null>(null);
  const [subdocURI, setSubdocURL] = useState();
  const { isMobile, isTablet } = useResponsive();
  const variables = {
    assigneeFilter: {
      userId: isAuthenticated ? user?.id : '',
      selected: assigneeFilter
    },
    additionalAdvisorFilters: additionalFiltersData.advisors,
    additionalStepFilters: additionalFiltersData.nextTask,
    additionalInvestmentFilters: additionalFiltersData.investments,
    order: sort.asc ? SORT_ORDER.ASC : SORT_ORDER.DESC,
    sort: sort.key,
    search: search.toLowerCase()
  };
  const {
    data,
    refetch,
    networkStatus,
    loading: recommendationLoading
  } = useQuery<RecommendationsQuery>(RECOMMENDATION_QUERY, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
    variables: {
      limit: paginationData.limit,
      offset: paginationData.offset,
      includeProposedInvestments: true,
      ...variables
    },
    onCompleted: (data) => {
      setAllocations(
        data.RecommendedAllocations.allocations
          .filter((allocation) => allocation.allocationStatus === 'Outstanding')
          .map((item) => {
            return {
              ...item,
              isAmountHidden:
                item.operationsStatus === (OPERATIONS_STATUS.READY_FOR_CLIENT_REVIEW || OPERATIONS_STATUS.SENT_TO_CLIENT) &&
                item.reason === 'Always Show'
            };
          })
      );
      setAmountResult(data.RecommendedAllocations.total);
    }
  });

  useEffect(() => {
    if (data) {
      const filteredData = data.RecommendedAllocations.allocations.filter((allocation) => allocation.allocationStatus === 'Outstanding');
      saveFiltrationResults(filteredData.length, () => refetch(variables));
    }
  }, [data]);

  useEffect(() => {
    if (!allocations.length) return;
    setTimeout(() => {
      makeScroll();
    }, 0);
  }, [allocations]);

  const [updateAllocations, { data: updateAllocationsData, loading: updateLoading }] = useMutation(updateAllocationMutation, {
    fetchPolicy: 'no-cache',
    onCompleted: () => refetchTask()
  });
  const [updateAllocationAmount, { data: updateAllocationAmountData, loading: updateAllocationAmountLoading }] = useMutation(
    updateAllocationAmountMutation,
    {
      fetchPolicy: 'no-cache'
    }
  );

  const [updateAllocationAssignee, { data: updateAllocationAssigneeData, loading: updateAllocationAssigneeLoading }] = useMutation(
    updateAllocationAssigneeMutation,
    {
      fetchPolicy: 'no-cache',
      onCompleted: () => refetchTask()
    }
  );

  const [updateAllocationOwners, { data: updateAllocationOwnersData, loading: updateAllocationOwnersLoading }] = useMutation(
    updateAllocationOwnersMutation,
    {
      fetchPolicy: 'no-cache'
    }
  );

  const [generateSubdoc, { data: generateSubdocData, loading: generateSubdocLoading }] = useMutation(generateSubdocMutation);

  useEffect(() => {
    if (generateSubdocData && ['created', 'sent'].includes(generateSubdocData.GenerateSubdoc.status) && currentRow) {
      updateStatus('next', currentRow);
      setSubdocURL(generateSubdocData.GenerateSubdoc.envelopeId);
    }
  }, [generateSubdocData]);

  useEffect(() => {
    if (updateAllocationAmountData?.updateAllocationAmount) {
      updateLocalAllocation(updateAllocationAmountData.updateAllocationAmount);
    }
  }, [updateAllocationAmountData]);

  useEffect(() => {
    if (updateAllocationOwnersData?.updateAllocationOwners) {
      updateLocalAllocation(updateAllocationOwnersData?.updateAllocationOwners);
    }
  }, [updateAllocationOwnersData]);

  useEffect(() => {
    if (updateAllocationAssigneeData?.updateAllocationAssignee) {
      updateLocalAllocation(updateAllocationAssigneeData.updateAllocationAssignee);
    }
  }, [updateAllocationAssigneeData]);

  useEffect(() => {
    if (updateAllocationsData?.updateAllocations?.length > 0) {
      updateAllocationsData.updateAllocations.forEach((allocation: Allocation) => getAllocation({ variables: { id: allocation.id } }));
    }
  }, [updateAllocationsData]);

  const [getAllocation, { loading, error, refetch: refechAllocation, called, data: updatedAllocationData }] = useLazyQuery(ALLOCATION);

  const {
    loading: subscriptionLoading,
    error: subscriptionError,
    data: subscriptionData
  } = useSubscription(ALLOCATION_SUBSCRIPTION, {
    shouldResubscribe: true
  });

  useEffect(() => {
    if (subscriptionError) {
      console.log(subscriptionError);
    }
  }, [subscriptionError]);

  const updateLocalAllocation = (updatedAllocation: any) => {
    const updatedAllocations = allocations.reduce((updatedAllocations: any, allocation) => {
      if (allocation.id === updatedAllocation.id) {
        if (updatedAllocation.allocationStatus === 'Outstanding') {
          return updatedAllocations.concat({
            ...allocation,
            ...updatedAllocation,
            updated: true // use this for animation
          });
        } else {
          return updatedAllocations;
        }
      } else {
        return updatedAllocations.concat({
          ...allocation,
          updated: false // use this for animation
        });
      }
    }, []);
    setAllocations(updatedAllocations);
  };

  useEffect(() => {
    if (subscriptionData) {
      const matchedAllocation = allocations.find((allocation) => allocation.id === subscriptionData.allocationUpdated.id);
      if (matchedAllocation) {
        if (called) {
          refechAllocation({ id: matchedAllocation.id });
        } else {
          getAllocation({ variables: { id: matchedAllocation.id } });
        }
      }
    }
  }, [subscriptionData]);

  useEffect(() => {
    if (updatedAllocationData) {
      updateLocalAllocation(updatedAllocationData.Allocation);
    }
  }, [updatedAllocationData]);

  const sortedByAmount = () => {
    const flag = sort.asc ? 1 : -1;
    return allocations?.sort((a, b) => ((a.isAmountHidden ? -1 : a.committedCapital) - (b.isAmountHidden ? -1 : b.committedCapital)) * flag);
  };

  const openModalWindow = (type: ModalWindowType, row: IRowData) => {
    setModalWindow({ ...modalWindow, isOpen: true, type });
    setCurrentRow(row);
  };
  const closeModalWindow = () => setModalWindow({ ...modalWindow, isOpen: false });

  const changeSelectRow = (row: IRowData, entityName: string) => {
    setCurrentRow(row);
    const entity = row.legalEntity.entities.find((item) => item.entityName === entityName);
    if (!entity) return;
    updateAllocations({
      variables: {
        data: [
          {
            id: row.id,
            selectedGroupLegalEntityId: entity.id
          }
        ]
      }
    });
  };

  const updateStatus = (step: 'next' | 'back', row: IRowData) => {
    setCurrentRow(row);
    const { id, operationsStatus, committedCapital } = row;
    const statuses = Object.values(OPERATIONS_STATUS);
    const currentStatusIndex = statuses.findIndex((status) => status === operationsStatus);
    const newStatus = step === 'next' ? statuses[currentStatusIndex + 1] : statuses[currentStatusIndex - 1];

    updateAllocations({
      variables: {
        data: [
          {
            id,
            committedCapital,
            ...(operationsStatus === OPERATIONS_STATUS.CLIENT_APPROVED && {
              clientApprovedAt: new Date()
            }),
            ...(operationsStatus !== OPERATIONS_STATUS.COMMITMENT_RECORDED_IN_REPORTING_SYSTEM && {
              operationsStatus: newStatus
            }),
            ...(operationsStatus === OPERATIONS_STATUS.COMMITMENT_SUBMITTED_TO_REPORTING_SYSTEM &&
              newStatus === OPERATIONS_STATUS.COMMITMENT_RECORDED_IN_REPORTING_SYSTEM && {
                allocationStatus: 'Committed'
              })
          }
        ]
      }
    });
  };

  const openInNewTab = (url: string) => {
    window.open(url, '_blank', 'noreferrer');
  };

  const actionsByStatus = (row: IRowData): IItems[] => {
    const { operationsStatus } = row;
    return [
      ...(ability?.can(ACTIONS.MARK_AS_SENT_TO_CLIENT, subject('Allocation', { ...row })) &&
      OPERATIONS_STATUS.READY_FOR_CLIENT_REVIEW === operationsStatus
        ? [
            {
              name: lodash.startCase(ACTIONS.MARK_AS_SENT_TO_CLIENT),
              onClick: () => updateStatus('next', row)
            }
          ]
        : []),
      ...(ability?.can(ACTIONS.MARK_AS_CLIENT_APPROVED, subject('Allocation', { ...row })) && OPERATIONS_STATUS.SENT_TO_CLIENT === operationsStatus
        ? [
            {
              name: lodash.startCase(ACTIONS.MARK_AS_CLIENT_APPROVED),
              onClick: () => updateStatus('next', row)
            }
          ]
        : []),
      ...(ability?.can(ACTIONS.MARK_AS_SUBDOCS_READY_FOR_REVIEW, subject('Allocation', { ...row })) &&
      OPERATIONS_STATUS.CLIENT_APPROVED === operationsStatus
        ? [
            {
              name: lodash.startCase(ACTIONS.MARK_AS_SUBDOCS_READY_FOR_REVIEW),
              onClick: () => updateStatus('next', row)
            }
          ]
        : []),
      ...(ability?.can(ACTIONS.GENERATE_SUBDOC, subject('Allocation', { ...row })) && OPERATIONS_STATUS.CLIENT_APPROVED === operationsStatus
        ? [
            {
              name: lodash.startCase(ACTIONS.GENERATE_SUBDOC),
              onClick: () => {
                setCurrentRow(row);
                generateSubdoc({
                  variables: {
                    allocationId: row.id
                  }
                });
              }
            }
          ]
        : []),

      ...(ability?.can(ACTIONS.MARK_AS_SUBDOCS_REVIEW_COMPLETE, subject('Allocation', { ...row })) &&
      OPERATIONS_STATUS.SUBDOC_READY_FOR_REVIEW === operationsStatus
        ? [
            {
              name: lodash.startCase(ACTIONS.MARK_AS_SUBDOCS_REVIEW_COMPLETE),
              onClick: () => updateStatus('next', row)
            }
          ]
        : []),
      ...(ability?.can(ACTIONS.MARK_AS_SUBDOCS_SENT_TO_CLIENT, subject('Allocation', { ...row })) &&
      OPERATIONS_STATUS.SUBDOC_READY_FOR_CLIENT === operationsStatus
        ? [
            {
              name: lodash.startCase(ACTIONS.MARK_AS_SUBDOCS_SENT_TO_CLIENT),
              onClick: () => updateStatus('next', row)
            }
          ]
        : []),
      ...(ability?.can(ACTIONS.GENERATE_SUBDOC, subject('Allocation', { ...row })) && OPERATIONS_STATUS.SUBDOC_READY_FOR_REVIEW === operationsStatus
        ? [
            {
              name: lodash.startCase(ACTIONS.REVIEW_SUBDOC),
              onClick: () => {
                openInNewTab(`https://appdemo.docusign.com/prepare/${subdocURI}/add-fields`);
              }
            }
          ]
        : []),
      ...(ability?.can(ACTIONS.MARK_AS_SUBDOCS_RECEIVED_FROM_CLIENT, subject('Allocation', { ...row })) &&
      OPERATIONS_STATUS.SUBDOC_SENT_TO_CLIENT === operationsStatus
        ? [
            {
              name: lodash.startCase(ACTIONS.MARK_AS_SUBDOCS_RECEIVED_FROM_CLIENT),
              onClick: () => updateStatus('next', row)
            }
          ]
        : []),
      ...(ability?.can(ACTIONS.MARK_AS_SUBDOCS_SENT_TO_FUND_MANAGER, subject('Allocation', { ...row })) &&
      OPERATIONS_STATUS.SUBDOC_SIGNED_BY_CLIENT === operationsStatus
        ? [
            {
              name: lodash.startCase(ACTIONS.MARK_AS_SUBDOCS_SENT_TO_FUND_MANAGER),
              onClick: () => updateStatus('next', row)
            }
          ]
        : []),
      ...(ability?.can(ACTIONS.MARK_AS_CONFIRMED_BY_MANAGER, subject('Allocation', { ...row })) &&
      OPERATIONS_STATUS.SUBDOC_SENT_TO_FUND_MANAGER === operationsStatus
        ? [
            {
              name: lodash.startCase(ACTIONS.MARK_AS_CONFIRMED_BY_MANAGER),
              onClick: () => updateStatus('next', row)
            }
          ]
        : []),
      ...(ability?.can(ACTIONS.MARK_AS_SUBMITTED_TO_REPORTING_SYSTEM, subject('Allocation', { ...row })) &&
      OPERATIONS_STATUS.SUBDOC_CONFIRMED_BY_FUND_MANAGER === operationsStatus
        ? [
            {
              name: lodash.startCase(ACTIONS.MARK_AS_SUBMITTED_TO_REPORTING_SYSTEM),
              onClick: () => updateStatus('next', row)
            }
          ]
        : []),
      ...(ability?.can(ACTIONS.MARK_AS_COMMITTED, subject('Allocation', { ...row })) &&
      OPERATIONS_STATUS.COMMITMENT_SUBMITTED_TO_REPORTING_SYSTEM === operationsStatus
        ? [
            {
              name: lodash.startCase(ACTIONS.MARK_AS_COMMITTED),
              onClick: () => openModalWindow('complete', row)
            }
          ]
        : []),
      ...(ability?.can(ACTIONS.CHANGE_ASSIGNEE, subject('Allocation', { ...row }))
        ? [
            {
              name: lodash.startCase(ACTIONS.CHANGE_ASSIGNEE),
              onClick: () => {
                openModalWindow('assign-task', row);
              }
            }
          ]
        : []),
      ...(ability?.can(ACTIONS.CHANGE_OWNER, subject('Allocation', { ...row }))
        ? [
            {
              name: lodash.startCase(ACTIONS.CHANGE_OWNER),
              onClick: () => openModalWindow('edit-owner', row)
            }
          ]
        : []),
      ...(ability?.can(ACTIONS.GO_BACK_TO_PREVIOUS_STEP, subject('Allocation', { ...row })) &&
      OPERATIONS_STATUS.READY_FOR_CLIENT_REVIEW !== operationsStatus
        ? [
            {
              name: lodash.startCase(ACTIONS.GO_BACK_TO_PREVIOUS_STEP),
              onClick: () => updateStatus('back', row)
            }
          ]
        : []),
      ...(ability?.can(ACTIONS.TERMINATE, subject('Allocation', { ...row }))
        ? [
            {
              name: lodash.startCase(ACTIONS.TERMINATE),
              onClick: () => {
                openModalWindow('terminate', row);
              }
            }
          ]
        : [])
    ];
  };

  const canShowChangeAmountButton = (row: IRowData) => {
    return ability?.can(ACTIONS.CHANGE_AMOUNT, subject('Allocation', { ...row }));
  };

  const isChosenFilters = document.querySelector('#chosenFliters');

  return (
    <>
      {modalWindow.isOpen && modalWindow.type === 'assign-task' && (
        <AssignTask
          updateAllocationAssignee={updateAllocationAssignee}
          key={guid()}
          isOpen={modalWindow.isOpen}
          onClose={closeModalWindow}
          allocation={currentRow}
          refetch={refetch}
        />
      )}
      {modalWindow.isOpen && modalWindow.type === 'edit-owner' && (
        <EditOwner
          updateAllocationOwners={updateAllocationOwners}
          key={guid()}
          isOpen={modalWindow.isOpen}
          onClose={closeModalWindow}
          allocation={currentRow}
          refetch={refetch}
        />
      )}
      {modalWindow.isOpen && modalWindow.type === 'amount' && (
        <EditAmount
          key={guid()}
          updateAllocationAmount={updateAllocationAmount}
          isOpen={modalWindow.isOpen}
          onClose={closeModalWindow}
          allocation={currentRow}
          refetch={refetch}
        />
      )}
      {modalWindow.isOpen && modalWindow.type === 'terminate' && (
        <Terminate
          key={guid()}
          isOpen={modalWindow.isOpen}
          onClose={closeModalWindow}
          id={currentRow?.id ?? ''}
          updateAllocations={updateAllocations}
        />
      )}
      {modalWindow.isOpen && modalWindow.type === 'complete' && (
        <Complete
          key={guid()}
          isOpen={modalWindow.isOpen}
          onClose={closeModalWindow}
          confirmAction={() => {
            updateStatus('next', currentRow as IRowData);
            closeModalWindow();
          }}
        />
      )}
      <TableWrapper padding="0 0 30px">
        {isMobile ? (
          <>
            {networkStatus === NetworkStatus.setVariables || networkStatus === NetworkStatus.loading || recommendationLoading ? (
              <Loader />
            ) : (
              <MobileTable
                omClickLastUpdate={omClickLastUpdate}
                onClickInvestment={onClickInvestment}
                onClickEntity={onClickEntity}
                columns={columns}
                updateLoading={updateLoading || generateSubdocLoading}
                recommendationsRows={(sort.key === 'amount' ? sortedByAmount() : allocations) || []}
                detailsClick={detailsClick}
                openModalWindow={openModalWindow}
                currentRow={currentRow}
                changeSelectRow={changeSelectRow}
                canShowChangeAmountButton={canShowChangeAmountButton}
                actionsByStatus={actionsByStatus}
              />
            )}
          </>
        ) : (
          <>
            <CustomTableHeader
              isChosenFilters={Boolean(isChosenFilters)}
              isTablet={isTablet}
              columns={columns}
              refetch={refetch}
              savedSort={sort}
              savedSetSort={setSort}
            />
            <TableBodyWrapper>
              {networkStatus === NetworkStatus.setVariables || networkStatus === NetworkStatus.loading || recommendationLoading ? (
                <Loader />
              ) : (
                <>
                  <TableBody
                    data={sort.key === 'amount' ? sortedByAmount() : allocations}
                    columns={columns}
                    detailsClick={detailsClick}
                    updateLoading={updateLoading || generateSubdocLoading}
                    currentRow={currentRow}
                    openModalWindow={openModalWindow}
                    changeSelectRow={changeSelectRow}
                    canShowChangeAmountButton={canShowChangeAmountButton}
                    actionsByStatus={actionsByStatus}
                  />
                  <PaginationWrap>
                    <TablePagination
                      savePagination={onChangePaginationData}
                      paginationValues={{
                        offset: paginationData.offset ?? 0,
                        limit: paginationData.limit ?? 0,
                        total: data?.RecommendedAllocations.total ?? 0
                      }}
                      refetch={refetch}
                    />
                  </PaginationWrap>
                </>
              )}
            </TableBodyWrapper>
          </>
        )}
      </TableWrapper>
    </>
  );
};

const CustomTableHeader = styled(TableHeader)<{
  isChosenFilters?: boolean;
  isTablet: boolean;
}>`
  position: sticky;
  top: ${({ isChosenFilters, isTablet }) => (isChosenFilters ? (isTablet ? '202px' : '147px') : isTablet ? '130px' : '80px')};
  z-index: 8;
  padding-top: 0;
`;

const PaginationWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

export default Table;
