import TableHeader from '@/components/table/fat-tableHeader';
import { useResponsive } from '@/hooks/use-responsive';
import { useRef } from 'react';
import styled from 'styled-components';

import { Loader } from '@/components/loaders/loader';
import { TableBodyWrapper, TableWrapper } from '@/components/table/styles';
import { TableColumns } from '@/components/table/types';
import useStickyFooter from '@/hooks/useStickyFooter';
import { ITransformedAllocation, ITransformedAssetClass } from '../types';
import MobileTable from './mobileTable';
import TableBody from './tableBody';
import TableSumFooter from './tableSumFooter';

interface CommitmentPlanTableProps {
  tableData: ITransformedAssetClass[];
  tableHeaderColumns: TableColumns[];
  loading: boolean;
  columnWidth: number;
  mode: 'edit' | 'view';
  targetSubAssetClasses: boolean;
  handleAssetClassAllocationChange?: (assetClassId: string, date: string, newValue: string) => void;
  handleSubAssetClassAllocationChange?: (
    assetClassId: string,
    subAssetClassId: string,
    sacAllocation: ITransformedAllocation,
    newValue: string
  ) => void;
  applyValueToAllAssetClassRows?: (assetClassId: string, acAllocation: ITransformedAllocation) => void;
  applyValueToAllGoingForwardAssetClassRows?: (assetClassId: string, acAllocation: ITransformedAllocation) => void;
  applyValueToAllSubAssetClassRows?: (assetClassId: string, subAssetClassId: string, sacAllocation: ITransformedAllocation) => void;
  applyValueToAllGoingForwardSubAssetClassRows?: (assetClassId: string, subAssetClassId: string, sacAllocation: ITransformedAllocation) => void;
}

const CommitmentPlanTable = ({
  tableData,
  tableHeaderColumns,
  loading,
  columnWidth,
  mode,
  targetSubAssetClasses,
  handleAssetClassAllocationChange,
  handleSubAssetClassAllocationChange,
  applyValueToAllAssetClassRows,
  applyValueToAllGoingForwardAssetClassRows,
  applyValueToAllSubAssetClassRows,
  applyValueToAllGoingForwardSubAssetClassRows
}: CommitmentPlanTableProps) => {
  const { isMobile, isTablet } = useResponsive();

  const containerRef = useRef<HTMLDivElement | null>(null);
  const footerRef = useRef<HTMLDivElement | null>(null);

  useStickyFooter(containerRef, footerRef);

  return (
    <>
      {isMobile ? (
        <>
          {loading ? (
            <Loader />
          ) : (
            <MobileTable
              tableData={tableData}
              mode={mode}
              handleAssetClassAllocationChange={handleAssetClassAllocationChange}
              applyValueToAllAssetClassRows={applyValueToAllAssetClassRows}
              applyValueToAllGoingForwardAssetClassRows={applyValueToAllGoingForwardAssetClassRows}
            />
          )}
        </>
      ) : (
        <TableWrapper padding="0">
          <CustomTableHeader refetch={() => {}} withOutSort columns={tableHeaderColumns} />
          <TableBodyWrapper>
            {loading ? (
              <Loader />
            ) : (
              <>
                <TableBody
                  tableData={tableData}
                  columnWidth={columnWidth}
                  mode={mode}
                  targetSubAssetClasses={targetSubAssetClasses}
                  handleAssetClassAllocationChange={handleAssetClassAllocationChange}
                  handleSubAssetClassAllocationChange={handleSubAssetClassAllocationChange}
                  applyValueToAllAssetClassRows={applyValueToAllAssetClassRows}
                  applyValueToAllGoingForwardAssetClassRows={applyValueToAllGoingForwardAssetClassRows}
                  applyValueToAllSubAssetClassRows={applyValueToAllSubAssetClassRows}
                  applyValueToAllGoingForwardSubAssetClassRows={applyValueToAllGoingForwardSubAssetClassRows}
                />
                <TableSumFooterWrapper ref={footerRef}>
                  <TableSumFooter tableData={tableData} columnWidth={columnWidth} />
                </TableSumFooterWrapper>
                <div ref={containerRef}></div>
              </>
            )}
          </TableBodyWrapper>
        </TableWrapper>
      )}
    </>
  );
};

export default CommitmentPlanTable;

const CustomTableHeader = styled(TableHeader)`
  #tableColumnTitle {
    flex-basis: auto;
  }
`;

const TableSumFooterWrapper = styled.div`
  &.sticky {
    position: sticky;
    bottom: 0;
    z-index: 1;
  }
`;
