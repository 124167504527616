import * as React from 'react';

export const DragAndDropBigIcon = ({ ...props }: React.SVGProps<SVGSVGElement>) => (
  <svg width="13" height="24" style={props?.style} viewBox="0 0 13 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="3" height="3" transform="matrix(-1 0 0 1 3 0.5)" fill="#4587EC" />
    <rect width="3" height="3" transform="matrix(-1 0 0 1 8 0.5)" fill="#4587EC" />
    <rect width="3" height="3" transform="matrix(-1 0 0 1 13 0.5)" fill="#4587EC" />
    <rect width="3" height="3" transform="matrix(-1 0 0 1 3 5.5)" fill="#4587EC" />
    <rect width="3" height="3" transform="matrix(-1 0 0 1 8 5.5)" fill="#4587EC" />
    <rect width="3" height="3" transform="matrix(-1 0 0 1 13 5.5)" fill="#4587EC" />
    <rect width="3" height="3" transform="matrix(-1 0 0 1 3 10.5)" fill="#4587EC" />
    <rect width="3" height="3" transform="matrix(-1 0 0 1 8 10.5)" fill="#4587EC" />
    <rect width="3" height="3" transform="matrix(-1 0 0 1 13 10.5)" fill="#4587EC" />
    <rect width="3" height="3" transform="matrix(-1 0 0 1 3 15.5)" fill="#4587EC" />
    <rect width="3" height="3" transform="matrix(-1 0 0 1 8 15.5)" fill="#4587EC" />
    <rect width="3" height="3" transform="matrix(-1 0 0 1 13 15.5)" fill="#4587EC" />
    <rect width="3" height="3" transform="matrix(-1 0 0 1 3 20.5)" fill="#4587EC" />
    <rect width="3" height="3" transform="matrix(-1 0 0 1 8 20.5)" fill="#4587EC" />
    <rect width="3" height="3" transform="matrix(-1 0 0 1 13 20.5)" fill="#4587EC" />
  </svg>
);
