import { AiAssistantIcon } from '@/assets/static/icons/ai-assistant';
import Textarea from '@/components/basicComponents/textarea';
import { DatePicker } from '@/components/fat-form-field';
import { ApolloError } from '@apollo/client';
import { format, isValid as isValidDate } from 'date-fns';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTheme } from 'styled-components';
import { ISectionFields } from '../types';
import { AiIconWrap, Answer, AnswerWrap, AutoGeneratedField, CommentaryWrap, FieldName } from './dollarContent';
import { EditWrapper } from './editWrapper';

interface DatePageProps {
  handleClose: () => void;
  row: ISectionFields | null;
  updateFieldAnswer: (variables: any) => void;
  investmentId: string | null;
}

export function extractAndFormatDate(input: string) {
  if (!input) return '';
  const dateRegex = /([A-Za-z]+) (\d{1,2}), (\d{4})/;

  const match = input.match(dateRegex);

  if (match) {
    const month = match[1];
    const day = match[2];
    const year = match[3];

    const monthMap = {
      January: 1,
      February: 2,
      March: 3,
      April: 4,
      May: 5,
      June: 6,
      July: 7,
      August: 8,
      September: 9,
      October: 10,
      November: 11,
      December: 12
    };

    const numericMonth = monthMap[month as keyof typeof monthMap];

    const formattedDate = `${year}-${numericMonth.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}T21:00:00.000Z`;

    return formattedDate;
  } else {
    return '';
  }
}

const DEFAULT_ERROR_MESSAGE = `We're sorry. This process did not complete. Please try again.`;

export const DatePage = ({ handleClose, row, updateFieldAnswer, investmentId }: DatePageProps) => {
  const theme = useTheme();

  const {
    handleSubmit,
    getValues,
    control,
    reset,
    watch,
    formState: { errors, isValid }
  } = useForm({ defaultValues: { fieldValue: null, commentary: '' } });

  const [isEditMode, setIsEditMode] = useState(true);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  useEffect(() => {
    if (!row) return;

    if (row?.data.value !== null || row?.data.commentary !== null) {
      setIsEditMode(true);
      reset({
        fieldValue: row?.data?.value || null,
        commentary: row?.data?.commentary || ''
      });
      return;
    }
    if (row.AI?.answers[0]) {
      setIsEditMode(false);
      reset({
        fieldValue: extractAndFormatDate(row.AI?.answers[0]),
        commentary: row.AI.answers[0]
      });
      return;
    }
    return () => {
      reset({ fieldValue: null, commentary: '' });
    };
  }, [row]);

  const saveValue = () => {
    setErrorMessage(null);

    if (!isValid) return;

    const { fieldValue, commentary } = getValues();

    updateFieldAnswer({
      variables: {
        data: {
          id: row.data.id,
          value: isEditMode ? fieldValue : null,
          commentary: isEditMode ? commentary : null
        }
      },
      onCompleted: () => {
        reset({ fieldValue: null, commentary: '' });
        handleClose();
      },
      onError: (error: ApolloError) => {
        console.error(error);
        setErrorMessage(DEFAULT_ERROR_MESSAGE);
      }
    });
  };

  const editSuggested = (suggestion: string) => {
    reset({
      fieldValue: extractAndFormatDate(suggestion),
      commentary: suggestion
    });
    setIsEditMode(true);
  };

  const clearSuggestion = () => {
    reset({ fieldValue: null, commentary: '' });
    setIsEditMode(true);
  };

  const startWithAISuggestion = () => {
    reset({
      fieldValue: extractAndFormatDate(row.AI?.answers[0]),
      commentary: row.AI?.answers[0]
    });
    setIsEditMode(false);
  };

  const revertPriorDraft = () => {
    if (row?.data.value || row?.data.commentary) {
      reset({
        fieldValue: row?.data.value,
        commentary: row?.data.commentary
      });
      setIsEditMode(true);
    }
  };

  if (!row) return <></>;

  return (
    <EditWrapper
      showAiSuggestion={Boolean(row.AI?.answers.length > 0)}
      isEditMode={isEditMode}
      startWithAISuggestion={startWithAISuggestion}
      editSuggested={editSuggested}
      clearSuggestion={clearSuggestion}
      revertPriorDraft={revertPriorDraft}
      showRevertPriorDraft={
        (Boolean(row?.data?.value) && row?.data?.value !== watch('fieldValue')) ||
        (Boolean(row?.data?.commentary) && row?.data?.commentary !== watch('commentary'))
      }
      handleClose={handleClose}
      row={row}
      investmentId={investmentId}
      confirmButton={{ name: 'Save', onClick: handleSubmit(saveValue, () => window.scrollTo(0, 0)) }}
      errorMessage={errorMessage && 'Error'}
      errorDescription={errorMessage || null}
    >
      <div>
        {isEditMode ? (
          <>
            <FieldName>{row.name}</FieldName>
            <DatePicker
              title={''}
              nameField={'fieldValue'}
              control={control}
              rules={{
                validate: {
                  isValidDate: (value: string | null) => {
                    if (!value) {
                      return true;
                    }
                    if (isValidDate(new Date(value))) {
                      return true;
                    }
                    return 'Invalid date';
                  }
                }
              }}
              errors={errors}
            />
          </>
        ) : (
          <>
            <FieldName>{row.name}</FieldName>
            <AutoGeneratedField>
              <AiIconWrap>
                <AiAssistantIcon width={28} height={28} fill={theme.font.weak} />
              </AiIconWrap>
              <div>
                <span style={{ fontWeight: 700 }}>Suggested {row.AI?.citations.length >= 2 && `(${row.AI?.citations.length - 1} more)`}:</span>{' '}
                {watch('fieldValue') && format(new Date(watch('fieldValue')), 'LLL d, yyyy')}
              </div>
            </AutoGeneratedField>
          </>
        )}
      </div>
      <CommentaryWrap>
        <FieldName>Commentary</FieldName>
        {isEditMode ? (
          <Controller
            name={'commentary'}
            control={control}
            render={({ field: { onChange, value } }) => (
              <Textarea value={value} textareaHeight="80px" isValidValue={!!value} maxLength={250} setValue={onChange} />
            )}
          />
        ) : (
          <AnswerWrap>
            <AiIconWrap>
              <AiAssistantIcon width={28} height={28} fill={theme.font.weak} />
            </AiIconWrap>
            <Answer>
              <span style={{ fontWeight: 700 }}>Suggested {row.AI?.citations.length >= 2 && `(${row.AI?.citations.length - 1} more)`}:</span>{' '}
              {row.AI?.answers[0]}
            </Answer>
          </AnswerWrap>
        )}
      </CommentaryWrap>
    </EditWrapper>
  );
};
