import styled from 'styled-components';

import { EditIcon } from '@/assets/icons/info-icons/edit';
import FormattingTooltip from '../../basicComponents/tooltip/dollarsTooltip';
import CircleButtonRow from '../../table/circleButtonRow';
import RedirectTitleCell from '../../table/redirectTitleCell';
import { CellWrapper } from '../../table/styles';

interface AmountProps {
  amount: number;
  openWindow: () => void;
  showChangeAmountButton: boolean;
  isMobile?: boolean;
}

const Amount = ({
  amount,
  openWindow,
  showChangeAmountButton,
  isMobile = false
}: AmountProps) => {
  return (
    <>
      {showChangeAmountButton ? (
        <CellWrapper alignItems={'center'} onClick={openWindow}>
          <RedirectTitleCell color={isMobile ? '#4587EC' : '#373f4e'}>
            <FormattingTooltip zIndex={1000}>{amount}</FormattingTooltip>
          </RedirectTitleCell>
          <CircleButtonRow background={'transparent'}>
            <WrapperIcon>
              <EditIcon />
            </WrapperIcon>
          </CircleButtonRow>
        </CellWrapper>
      ) : (
        <FormattingTooltip zIndex={1000}>{amount}</FormattingTooltip>
      )}
    </>
  );
};

export default Amount;

const WrapperIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  transform: scale(0.85);
`;
