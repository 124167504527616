import { ApolloError, useMutation } from '@apollo/client';
import { useNavigate, useLocation } from 'react-router-dom';
import { DeleteItemIcon } from '@/assets/static/icons/delete-item';
import { DownloadItemIcon } from '@/assets/static/icons/download-item';
import { OpenFileIcon } from '@/assets/static/icons/open-file';
import { ChangeEvent, useEffect, useState } from 'react';
import client from '@/apollo-client';
import Button from '@/components/basicComponents/button';
import RadioButton from '@/components/basicComponents/radioButton';
import {
  CloseBtn,
  ModalControlWrap,
  ModalPageTitle
} from '@/components/client-details';
import { DropFileArea } from '@/components/dueDiligence-page/dueDiligenceRecord/dropFileArea';
import {
  ControlButtons,
  DecorStick,
  ImageItem,
  ImageWrapper,
  OpenButton,
  PreviewItem
} from '@/components/dueDiligence-page/dueDiligenceRecord/edit-pages/longFormContent';
import { ErrorBanner } from '@/components/dueDiligence-page/dueDiligenceRecord/errorBanner';
import {
  AmountCharacters,
  CharactersRemainder,
  StyledTextarea
} from '@/components/dueDiligence-page/modals/changeAssignee';
import FileNotSupported from '@/components/dueDiligence-page/modals/fileNotSupported';
import {
  ButtonWrapper,
  ControlWrapper
} from '@/components/dueDiligence-page/modifyDiligence';
import Header from '@/components/header';
import { useResponsive } from '@/hooks/use-responsive';
import { ApprovedIcon } from '@/assets/icons/info-icons/approved';
import { CloseInfoIcon } from '@/assets/icons/info-icons/closeInfo';
import { MainWrap, PaddingWrap } from '@/styles/common';
import styled from 'styled-components';
import { IFirmSettings } from '.';
import {
  createSettingAttachment,
  deleteSettingAttachment,
  getSettingAttachmentUploadUrl
} from '../../queries';
import { MarginTop, SettingsContainer } from '../importData';
import { fileExtensions } from './constants';
import { isURL } from './utils';

interface EditWatermarkProps {
  handleClose: () => void;
  createFirmSetting: (type: string, key: string, value: string) => void;
  updateFirmSetting: (id: string, value: string) => void;
  currentRow: IFirmSettings | null;
  watermarkImg: string;
}

const radioButtonItems = ['None', 'Text', 'Image'];

export const EditWatermark = ({
  handleClose,
  createFirmSetting,
  updateFirmSetting,
  currentRow,
  watermarkImg
}: EditWatermarkProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const { isMobile, isTablet } = useResponsive();

  const [selectedOption, setSelectedOption] = useState(radioButtonItems[0]);
  const [watermarkText, setWatermarkText] = useState('');
  const [file, setFile] = useState<File | null>(null);
  const [fileUrl, setFileUrl] = useState('');
  const [drag, setDrag] = useState(false);
  const [isAttachmentDeleted, setIsAttachmentDeleted] = useState(false);
  const [modalWindow, setModalWindow] = useState({
    isOpen: false,
    type: 'not-supported'
  });
  const [addAttachmentError, setAddAttachmentError] =
    useState<ApolloError | null>(null);
  const [deletedFile, setDeletedFile] = useState('');

  useEffect(() => {
    if (currentRow?.value) {
      const parsedValue = JSON.parse(currentRow.value);
      if (!parsedValue.value) {
        setSelectedOption(radioButtonItems[0]);
        return;
      }
      if (isURL(parsedValue.value)) {
        setFileUrl(watermarkImg);
        setSelectedOption(radioButtonItems[2]);
        return;
      }
      if (parsedValue.value !== 'None') {
        setWatermarkText(parsedValue.value);
        setSelectedOption(radioButtonItems[1]);
        return;
      }
      setSelectedOption(radioButtonItems[0]);
    }
    return () => {
      setWatermarkText('');
    };
  }, [currentRow]);

  const [createAttachment] = useMutation(createSettingAttachment);
  const [deleteAttachment] = useMutation(deleteSettingAttachment);

  const handleRadioChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSelectedOption(event.target.value);
  };

  const onDropHandler = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const uploadFiles = e.dataTransfer.files;
    setDrag(false);

    if (
      fileExtensions.includes(
        uploadFiles[0]?.name.split('.').pop()?.toUpperCase() ?? ''
      )
    ) {
      setFile(uploadFiles[0]);
      setFileUrl(URL.createObjectURL(uploadFiles[0]));
      return;
    }
    setModalWindow({ isOpen: true, type: 'not-supported' });
  };
  const handleUploadFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const uploadFiles = e.target.files;

    if (
      uploadFiles &&
      fileExtensions.includes(
        uploadFiles[0]?.name.split('.').pop()?.toUpperCase() ?? ''
      )
    ) {
      setFile(uploadFiles[0]);
      setFileUrl(URL.createObjectURL(uploadFiles[0]));
      return;
    }
    setModalWindow({ isOpen: true, type: 'not-supported' });
  };

  const deletePreviewItem = () => {
    if (
      !file &&
      currentRow?.value &&
      isURL(JSON.parse(currentRow.value).value)
    ) {
      setDeletedFile(JSON.parse(currentRow.value).id);
    }
    setFile(null);
    setFileUrl('');
  };

  const logoUpload = async (file: File) => {
    const isValueImage =
      currentRow?.value && isURL(JSON.parse(currentRow.value).value);
    try {
      const { data: firmSettingsLogoUrl } = await client.query({
        query: getSettingAttachmentUploadUrl,
        variables: {
          data: {
            fieldType: 'firmSetting',
            key: 'logo',
            contentType: file.type,
            filename: file.name.replace(/\s/g, '')
          }
        }
      });
      await fetch(firmSettingsLogoUrl.getSettingAttachmentUploadUrl.url, {
        method: 'PUT',
        body: file,
        headers: {
          'Content-Type': file.type
        }
      });

      await createAttachment({
        variables: {
          assetKey: firmSettingsLogoUrl.getSettingAttachmentUploadUrl.assetKey
        },
        onCompleted: (data) => {
          if (isValueImage && !isAttachmentDeleted) {
            deleteAttachment({
              variables: { id: JSON.parse(currentRow.value).id }
            });
          }
          if (queryParams.get('id') !== 'null') {
            updateFirmSetting(
              queryParams.get('id') as string,
              JSON.stringify({
                value: data.createSettingAttachment.url,
                assetKey:
                  firmSettingsLogoUrl.getSettingAttachmentUploadUrl.assetKey,
                id: data.createSettingAttachment.id
              })
            );
            return;
          }
          createFirmSetting(
            'templateStyling',
            'watermark',
            JSON.stringify({
              value: data.createSettingAttachment.url,
              assetKey:
                firmSettingsLogoUrl.getSettingAttachmentUploadUrl.assetKey,
              id: data.createSettingAttachment.id
            })
          );
        },
        onError: (error) => {
          handleAttachmentError(error);
        }
      });
    } catch (error) {
      console.log(error, 'error');
      handleAttachmentError(error as ApolloError);
    }
  };

  const handleAttachmentError = (error: ApolloError) => {
    console.log(error, 'error');
    if (
      error.message.includes(
        'duplicate key value violates unique constraint'
      ) ||
      error.message.includes('Asset key already exists')
    ) {
      setAddAttachmentError(error);
      setFile(null);
      setFileUrl(currentRow?.value ? JSON.parse(currentRow.value).value : '');
    }
  };

  const saveValue = () => {
    if (deletedFile) {
      deleteAttachment({
        variables: { id: deletedFile },
        onCompleted: () => {
          setIsAttachmentDeleted(true);
          updateFirmSetting(queryParams.get('id') as string, '');
        }
      });
    }

    if (file) {
      logoUpload(file);
      return;
    }

    if (selectedOption === 'Image' && !isAttachmentDeleted) {
      handleClose();
      return;
    }

    if (
      selectedOption !== 'Image' &&
      currentRow?.value &&
      isURL(JSON.parse(currentRow.value).value)
    ) {
      deleteAttachment({
        variables: { id: JSON.parse(currentRow.value).id }
      });
    }

    const value = selectedOption === 'None' ? 'None' : watermarkText;
    if (queryParams.get('id') !== 'null') {
      updateFirmSetting(
        queryParams.get('id') as string,
        JSON.stringify({ value: value })
      );
      return;
    }
    createFirmSetting(
      'templateStyling',
      'watermark',
      JSON.stringify({ value: value })
    );
  };

  return (
    <>
      {modalWindow.type === 'not-supported' && (
        <FileNotSupported
          isOpen={modalWindow.isOpen}
          onClose={() => setModalWindow({ ...modalWindow, isOpen: false })}
        />
      )}
      <MainWrap>
        <Header
          modalControl={
            <ModalControlWrap>
              <CloseBtn onClick={handleClose}>
                <CloseInfoIcon width={18} height={18} />
              </CloseBtn>
              <ModalPageTitle isTablet={isTablet} isMobile={isMobile}>
                {queryParams.get('fieldName')}
              </ModalPageTitle>
            </ModalControlWrap>
          }
        />
        <MarginTop />
        {addAttachmentError && (
          <ErrorBanner
            errorMessage={'File name already exists'}
            errorDescription={
              'In order to upload your file please give it a unique name.'
            }
          />
        )}
        <PaddingWrap>
          <SettingsContainer isMobile={isMobile}>
            Watermark Type
            <RadioButtonsWrap>
              {radioButtonItems.map((item, index) => (
                <RadioButton
                  key={item + index}
                  name="watermark"
                  radioButtonText={item}
                  selectedOption={selectedOption}
                  handleRadioChange={handleRadioChange}
                />
              ))}
            </RadioButtonsWrap>
            {selectedOption === 'None' && <>Watermark None</>}
            {selectedOption === 'Text' && (
              <>
                Watermark Text
                <WatermarkTextWrap isTablet={isTablet} isMobile={isMobile}>
                  <StyledTextarea
                    height="44px"
                    maxLength={80}
                    value={watermarkText}
                    onChange={(event) => setWatermarkText(event.target.value)}
                  />
                  <CharactersRemainder>
                    <AmountCharacters>
                      {80 - watermarkText.length > 0
                        ? 80 - watermarkText.length
                        : 0}
                    </AmountCharacters>{' '}
                    Characters Remaining
                  </CharactersRemainder>
                </WatermarkTextWrap>
              </>
            )}
            {selectedOption === 'Image' && (
              <>
                {fileUrl && (
                  <div>
                    <PreviewItem>
                      <DecorStick isImage />
                      <ApprovedIcon width="25" height="24" fill="#373F4E" />
                      <ImageWrapper href={fileUrl} target="_blank">
                        <ImageItem src={fileUrl} alt={file?.name && ''} />
                      </ImageWrapper>
                      <ControlButtons>
                        <OpenButton href={fileUrl} download>
                          <DownloadItemIcon />
                        </OpenButton>
                        <OpenButton href={fileUrl} target="_blank">
                          <OpenFileIcon />
                        </OpenButton>
                        <DeleteButton onClick={deletePreviewItem}>
                          <DeleteItemIcon />
                        </DeleteButton>
                      </ControlButtons>
                    </PreviewItem>
                  </div>
                )}
                <DropFileArea
                  id="watermark"
                  fileExtensions={fileExtensions}
                  handleUploadFile={handleUploadFile}
                  onDropHandler={onDropHandler}
                  drag={drag}
                  setDrag={setDrag}
                />
              </>
            )}
            <CustomControlWrapper isMobile={isMobile}>
              <ButtonWrapper>
                <CancelButton
                  isMobile={isMobile}
                  onClick={handleClose}
                  disabled={false}
                >
                  Cancel
                </CancelButton>
                <SaveButton
                  isMobile={isMobile}
                  onClick={saveValue}
                  disabled={false}
                >
                  Save
                </SaveButton>
              </ButtonWrapper>
            </CustomControlWrapper>
          </SettingsContainer>
        </PaddingWrap>
      </MainWrap>
    </>
  );
};

const CustomControlWrapper = styled(ControlWrapper)`
  justify-content: flex-end;
  margin: 30px 0;
`;

const RadioButtonsWrap = styled.label`
  display: flex;
  align-items: center;
  gap: 12px;
  margin-top: 10px;
`;

const WatermarkTextWrap = styled.div<{ isTablet: boolean; isMobile: boolean }>`
  width: ${({ isMobile, isTablet }) => (isMobile || isTablet ? '100%' : '50%')};
`;

const DeleteButton = styled(OpenButton)`
  border: 1px solid #d63b4b;
`;

const CancelButton = styled(Button)<{ isMobile: boolean }>`
  background: white;
  border: 1px solid #4587ec;
  color: #4587ec;
  padding: ${({ isMobile }) => (isMobile ? '8.5px 40.5px' : '11.5px 86.5px')};
`;

const SaveButton = styled(Button)<{ isMobile: boolean }>`
  background: ${({ disabled }) => (disabled ? '#dedede' : '#1c488a')};
  padding: ${({ isMobile }) => (isMobile ? '8.5px 50.5px' : '11.5px 93.5px')};
`;
