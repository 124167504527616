import { ChartIcon } from '@/assets/icons/fat-pricing-tab-icons/chart';
import { DataIcon } from '@/assets/icons/fat-pricing-tab-icons/data';
import { useResponsive } from '@/hooks/use-responsive';
import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

const sectionNavItems = [
  {
    id: 'commitmentsVsNetAssetValue',
    title: 'Commitments vs Net Asset Value',
    icon: <ChartIcon />
  },
  {
    id: 'commitmentPlan',
    title: 'Commitment Plan',
    icon: <DataIcon />
  },
  {
    id: 'allocationForecast',
    title: 'Allocation Forecast',
    icon: <ChartIcon />
  },
  {
    id: 'navForecast',
    title: 'NAV Forecast',
    icon: <DataIcon />
  },
  // {
  //   id: 'commitmentsVsTargetAllocation',
  //   title: 'Commitments vs Target Allocation',
  //   icon: <ChartIcon />
  // },
  {
    id: 'cashFlowForecast',
    title: 'Cash Flow Forecast',
    icon: <DataIcon />
  },
  {
    id: 'navVsUnfundedCommitments',
    title: 'NAV vs Unfunded Commitments',
    icon: <ChartIcon />
  },
  // {
  //   id: 'annualVsTargetAllocation',
  //   title: 'Unfunded Commitments and NAV',
  //   icon: <ChartIcon />
  // },
  {
    id: 'netCashFlows',
    title: 'Net Cash Flows',
    icon: <ChartIcon />
  },
  {
    id: 'cumulativeCashFlow',
    title: 'Cumulative Cash Flow',
    icon: <ChartIcon />
  }
];

interface SectionsNavProps {
  commitmentPlanLoading: boolean;
}

export const SectionsNav = ({ commitmentPlanLoading }: SectionsNavProps) => {
  const { isMobile, isTablet } = useResponsive();

  const [activeSection, setActiveSection] = useState('commitmentsVsNetAssetValue');
  const [navHeight, setNavHeight] = useState(44);
  const navRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (navRef.current) {
      setNavHeight(isMobile || isTablet ? navRef.current.offsetHeight + 50 : navRef.current.offsetHeight);
    }
  }, []);

  useEffect(() => {
    if (commitmentPlanLoading) return;

    const sections: (HTMLElement | null)[] = sectionNavItems.map((item) => document.getElementById(item.id));

    if (sections.every((section) => section === null)) return;

    const observerOptions: IntersectionObserverInit = {
      root: null,
      rootMargin: '0px',
      threshold: 0.8
    };

    const observerCallback: IntersectionObserverCallback = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setActiveSection(entry.target.id);
        }
      });
    };

    const observer = new IntersectionObserver(observerCallback, observerOptions);

    sections.forEach((section) => {
      if (section) {
        observer.observe(section);
      }
    });

    return () => {
      sections.forEach((section) => {
        if (section) {
          observer.unobserve(section);
        }
      });
    };
  }, [sectionNavItems, commitmentPlanLoading]);

  const scrollToSection = (id: string) => {
    const element = document.getElementById(id);
    if (element) {
      window.scrollTo({
        top: element.offsetTop - navHeight,
        behavior: 'smooth'
      });
    }
  };

  return (
    <SectionsNavWrapper isMobile={isMobile} isTablet={isTablet} ref={navRef}>
      {sectionNavItems.map((item) => (
        <SectionsNavItem key={item.id} isActive={activeSection === item.id} onClick={() => scrollToSection(item.id)}>
          {item.icon}
          {item.title}
        </SectionsNavItem>
      ))}
    </SectionsNavWrapper>
  );
};

const SectionsNavWrapper = styled.div<{ isMobile: boolean; isTablet: boolean }>`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  padding: 10px 0;
  position: sticky;
  top: ${({ isMobile, isTablet }) => (isMobile || isTablet ? '50px' : '0')};
  z-index: 99;
  background-color: ${({ theme }) => theme.layer.base};
`;

const SectionsNavItem = styled.div<{ isActive: boolean }>`
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 13px;
  font-weight: 600;
  line-height: 20px;
  color: ${({ theme, isActive }) => (isActive ? theme.font.base : theme.font.action)};
  cursor: pointer;
  border-bottom: ${({ isActive }) => (isActive ? '4px solid #fbb161' : '4px solid transparent')};
  width: max-content;

  svg {
    path {
      fill: ${({ theme, isActive }) => (isActive ? theme.font.base : theme.font.action)};
    }
  }
`;
