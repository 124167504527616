import { Button, ButtonBaseProps } from '@mui/material';
import { styled } from 'styled-components';

interface ButtonProps extends ButtonBaseProps {
  variant?: 'text' | 'outlined' | 'contained';
  /**
   * If `true`, the component is disabled.
   * @default false
   */
  disabled?: boolean;

  /**
   * If `true`, the button will take up the full width of its container.
   * @default false
   */
  fullWidth?: boolean;

  /**
   * What background color to use
   */

  backgroundColor?: string;
  /**
   * How large should the button be?
   */
  size?: 'small' | 'medium' | 'large';
  /**
   * Button contents
   */
  label: string;
  /**
   * Optional click handler
   */

  onClick?: () => void;
  className?: string;
  primary?: boolean;
  colorText?: string | null;
}

/**
 * Primary UI component for user interaction
 */
export function ButtonProps({ className, variant, disabled, fullWidth, size, type, label, onClick, colorText = null, ...props }: ButtonProps) {
  return (
    <CustomButton className={className} variant={variant} disabled={disabled} fullWidth={fullWidth} type={type} size={size} onClick={onClick}>
      {label}
    </CustomButton>
  );
}

const CustomButton = styled(Button)`
  border-radius: 5;
  font-style: normal;
  font-weight: 600;
  font-size: 19;
  &.MuiButton-root {
  }
  &.Mui-disabled {
  }
`;
