import { Box } from '@/components/muis/box';
import { useAuth } from '@/hooks/use-auth';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { styled, useTheme } from 'styled-components';

import Button from '@/components/fat-basicComponents/button';
import { Input } from '@/components/fat-form-field';
import { PasswordToggleButton } from '@/components/passwordToggleButton';
import SuccessfulPasswordChange from './fat-modals/successfulPasswordChange';

interface IFormInput {
  newPassword: string;
  confirmedPassword: string;
}

export const ChangePassword: React.FC = () => {
  const {
    control,
    handleSubmit,
    getValues,
    watch,
    reset,
    formState: { errors, isSubmitSuccessful, isSubmitted }
  } = useForm<IFormInput>();
  const theme = useTheme();
  const { changePassword } = useAuth();
  const [isOpenModalWindow, setIsOpenModalWindow] = useState<boolean>(false);
  const [isPasswordMaskedNewPassword, setIsPasswordMaskedNewPassword] = useState(true);
  const [isPasswordMaskedConfirmedPassword, setIsPasswordMaskedConfirmedPassword] = useState(true);

  const [validation, setValidation] = useState([
    {
      hint: 'Password must be 8 or more characters in length',
      type: 'length',
      correct: true
    },
    {
      hint: 'Password must contain at least 1 uppercase character',
      type: 'oneUpperCase',
      correct: true
    },
    {
      hint: 'Password must contain at least 1 lowercase character',
      type: 'oneLowerCase',
      correct: true
    },
    {
      hint: 'Password must contain at least 1 or more symbols',
      type: 'symbols',
      correct: true
    },
    {
      hint: 'Password must NOT contain any spaces',
      type: 'space',
      correct: true
    }
  ]);

  const toggleModalWindow = () => setIsOpenModalWindow((prevState) => !prevState);
  const checkValidInput = (field: 'newPassword' | 'confirmedPassword') => !Object.keys(errors).some((error) => error === field) && !!getValues(field);
  const checkValidConfirmedInput = () => watch('confirmedPassword') === watch('newPassword') && !!getValues('confirmedPassword');
  const colorInput = (valid: boolean) => {
    if (!isSubmitted) {
      return 'defaultInput';
    }
    if (valid) {
      return 'success.light';
    } else {
      return 'error.light';
    }
  };
  const onSubmit = async (data: IFormInput): Promise<void> => {
    try {
      const response = await changePassword(data.newPassword);
      toggleModalWindow();
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (errors !== undefined) {
      const newValidation = validation.map((valid) =>
        errors.newPassword?.type === valid.type ? { ...valid, correct: false } : { ...valid, correct: true }
      );
      setValidation(newValidation);
    }
  }, [errors.newPassword?.type]);

  useEffect(() => {
    reset();
  }, [isSubmitSuccessful]);

  return (
    <>
      <SuccessfulPasswordChange isOpen={isOpenModalWindow} onClose={toggleModalWindow} />
      <ChangePasswordWrapper>
        <ChangePasswordForm noValidate onSubmit={handleSubmit(onSubmit)}>
          <FormLabel>Change Password</FormLabel>
          <InputsWrapper>
            <NewPasswordInputWrap>
              <Input
                title={'New Password'}
                nameField={'newPassword'}
                type={isPasswordMaskedNewPassword ? 'password' : 'text'}
                rules={{
                  validate: {
                    length: (v: string) => v.length >= 8,
                    oneUpperCase: (v: string) => !!v.match(/[A-Z]/),
                    oneLowerCase: (v: string) => !!v.match(/[a-z]/),
                    symbols: (v: string) => !!v.match(/[!$%^&*()_+|~=`{}[\]:";'<>?,./]/),
                    space: (v: string) => !v.match(/\s/)
                  }
                }}
                control={control}
                icon={<PasswordToggleButton isPasswordMasked={isPasswordMaskedNewPassword} setIsPasswordMasked={setIsPasswordMaskedNewPassword} />}

                // error={isSubmitted ? !checkValidInput('newPassword') : false}
              />
              {getValues('newPassword') && (
                <ValidationHints>
                  {validation.map(({ hint, correct }) => (
                    <ValidationHint key={hint} color={correct ? theme.font.base : theme.context.error}>
                      {hint}
                    </ValidationHint>
                  ))}
                </ValidationHints>
              )}
            </NewPasswordInputWrap>
            <div>
              <Input
                title={'Confirm new Password'}
                nameField={'confirmedPassword'}
                type={isPasswordMaskedConfirmedPassword ? 'password' : 'text'}
                rules={{
                  validate: {
                    theSame: (v: string) => v === getValues('newPassword')
                  }
                }}
                control={control}
                icon={
                  <PasswordToggleButton
                    isPasswordMasked={isPasswordMaskedConfirmedPassword}
                    setIsPasswordMasked={setIsPasswordMaskedConfirmedPassword}
                  />
                }
                // error={isSubmitted ? !checkValidInput('confirmedPassword') : false}
              />
            </div>
          </InputsWrapper>

          {!checkValidConfirmedInput() && isSubmitted ? <ErrorText>Please make sure your passwords match</ErrorText> : null}
          <ResetPasswordWrapper>
            <Button type="submit" size="lg" width="100%">
              Reset Password
            </Button>
          </ResetPasswordWrapper>
        </ChangePasswordForm>
      </ChangePasswordWrapper>
    </>
  );
};

const ChangePasswordWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 50%;
`;

const ChangePasswordForm = styled.form`
  width: 372px;
`;

const FormLabel = styled.label`
  display: block;
  font-weight: 400;
  font-size: 18px;
  color: ${({ theme }) => theme.font.base};
  margin-bottom: 20px;
`;

const ValidationHints = styled(Box)`
  display: flex;
  flex-direction: column;
`;

const ValidationHint = styled.span<{ color: string }>`
  color: ${({ color }) => color};
  position: relative;
  padding-left: 15px;
  font-size: 14;
  &:before {
    content: '';
    height: 5px;
    width: 5px;
    background: ${({ color }) => color};
    position: absolute;
    top: calc(50% - 2px);
    left: 5px;
    border-radius: 50%;
  }
`;

const InputsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const NewPasswordInputWrap = styled.div`
  display: flex;
  flex-direction: column;
`;

const ErrorText = styled.p`
  color: ${({ theme }) => theme.context.error};
  font-family: Blinker;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22.4px;
`;

const ResetPasswordWrapper = styled.div`
  margin-top: 30px;
`;
