import Label from '@/components/basicComponents/label';
import ModalWrappedContent from '@/components/fat-basicComponents/modal/modalWrappedContent';
import { Select } from '@/components/fat-form-field';
import { FormInput, Input } from '@/components/fat-form-field/input';
import { Loader } from '@/components/loaders/loader';
import ErrorNotification from '@/components/recommendations-page/error-notification';
import { Controller, useForm } from 'react-hook-form';
import styled from 'styled-components';
import { CreateTenantDto } from '../types';

interface AddTenantModalProps {
  onCancel: () => void;
  onConfirm: (data: CreateTenantDto) => void | null;
  isOpen: boolean;
  isLoading?: boolean;
  error?: { title: string; message: string };
}

const defaultFormValues: CreateTenantDto = {
  name: '',
  adminEmail: '',
  countryCode: 'Select',
  adminMobileNumber: '',
  adminFirstName: '',
  adminLastName: ''
};

const AddTenantModal = ({ onCancel, onConfirm, isOpen, isLoading, error }: AddTenantModalProps) => {
  const {
    control,
    watch,
    handleSubmit,
    formState: { errors, isValid }
  } = useForm<CreateTenantDto>({ defaultValues: defaultFormValues });

  const handleMobilePhoneChange = (e: React.ChangeEvent<HTMLInputElement>, onChange: (value: string) => void) => {
    let inputValue = e.target.value.replace(/\D/g, '');

    if (inputValue.length > 3 && inputValue.length <= 6) {
      inputValue = `(${inputValue.slice(0, 3)}) ${inputValue.slice(3)}`;
    } else if (inputValue.length > 6) {
      inputValue = `(${inputValue.slice(0, 3)}) ${inputValue.slice(3, 6)}-${inputValue.slice(6, 10)}`;
    }

    onChange(inputValue);
  };

  return (
    <ModalWrappedContent
      isOpen={isOpen}
      onClose={onCancel}
      confirmButton={
        isLoading
          ? {
              name: 'Save',
              disabled: true,
              onClick: () => null
            }
          : {
              name: 'Save',
              disabled: !isValid,
              onClick: () => {
                if (!isValid) return;

                handleSubmit(onConfirm)();
              }
            }
      }
      showCancelButton={!isLoading}
      isUseOutsideClick={!isLoading}
      showCloseIcon={!isLoading}
    >
      <ModalContainer>
        {error && <ErrorNotification title={error.title} message={error.message ?? ''} />}
        <ModalTitle>Add New Tenant</ModalTitle>
        <ModalText fontWeight="400">
          {isLoading && (
            <>
              <br /> <br />
              <Loader size={30} />
              Creating new tenant...
            </>
          )}
          {!isLoading && (
            <>
              <br />
              <Input
                title={'Name'}
                nameField={'name'}
                rules={{
                  required: {
                    value: true,
                    message: 'Name is required'
                  }
                }}
                control={control}
                placeHolder={'Name of the firm'}
                isWarning={true}
              />
              <br />
              <Input
                title={'Primary Administrator Email'}
                nameField={'adminEmail'}
                rules={{
                  required: {
                    value: true,
                    message: 'Email is required'
                  }
                }}
                control={control}
                placeHolder={'Valid email address'}
                isWarning={true}
              />
              <br />
              <MobilePhoneFieldsWrapper>
                <Select
                  title={'Country Code'}
                  nameField={'countryCode'}
                  control={control}
                  rules={{
                    validate: (value: string) => {
                      if (watch('adminMobileNumber') && value === 'Select') {
                        return false;
                      }
                      return true;
                    }
                  }}
                  width="30%"
                  withSearch
                  defaultValue="Select"
                  selects={['Select', '+1']}
                />
                <div style={{ width: '100%' }}>
                  <Label required={false}>Mobile Phone for SMS</Label>
                  <Controller
                    name="adminMobileNumber"
                    control={control}
                    defaultValue=""
                    rules={{
                      validate: (value) => {
                        if (watch('countryCode') !== 'Select' && !value) {
                          return false;
                        }
                        if (watch('countryCode') === 'Select' && value) {
                          return false;
                        }
                        return true;
                      }
                    }}
                    render={({ field }) => (
                      <FormInput
                        value={field.value}
                        onChange={(e) => handleMobilePhoneChange(e, field.onChange)}
                        placeholder={'Valid mobile phone'}
                      />
                    )}
                  />
                </div>
              </MobilePhoneFieldsWrapper>
              <br />
              <Input
                title={'Primary Administrator First Name'}
                nameField={'adminFirstName'}
                rules={{
                  required: {
                    value: true,
                    message: 'First name is required'
                  }
                }}
                control={control}
                placeHolder={'First name of the user'}
                isWarning={true}
              />
              <br />
              <Input
                title={'Primary Administrator Last Name'}
                nameField={'adminLastName'}
                rules={{
                  required: {
                    value: true,
                    message: 'Last name is required'
                  }
                }}
                control={control}
                placeHolder={'Last name of the user'}
                isWarning={true}
              />
            </>
          )}
        </ModalText>
      </ModalContainer>
    </ModalWrappedContent>
  );
};

export default AddTenantModal;

const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  padding: 30px 0;
`;

const ModalTitle = styled.div`
  color: ${({ theme }) => theme.font.strong};
  text-align: center;
  font-family: Blinker;
  font-size: 33px;
  font-style: normal;
  font-weight: 400;
  line-height: 46.2px;
`;

const ModalText = styled.span<{ fontWeight: string }>`
  color: ${({ theme }) => theme.font.base};
  text-align: center;
  font-family: Blinker;
  font-size: 19px;
  font-style: normal;
  font-weight: ${({ fontWeight }) => fontWeight};
  line-height: 26.6px;
`;

const MobilePhoneFieldsWrapper = styled.div`
  display: flex;
  gap: 10px;
`;
