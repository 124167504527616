import { IRecordData } from '@/components/dueDiligence-page/types';
import ModalWrappedContent from '@/components/fat-basicComponents/modal/modalWrappedContent';
import styled from 'styled-components';

interface StartDueDiligenceProps {
  onClose: () => void;
  isOpen: boolean;
  currentRow: IRecordData | null;
  startDueDiligence: (row: IRecordData, status: string, withRedirect?: boolean) => void;
}

const StartDueDiligence = ({ onClose, isOpen, startDueDiligence, currentRow }: StartDueDiligenceProps) => {
  if (!currentRow) return <></>;
  return (
    <ModalWrappedContent
      isOpen={isOpen}
      onClose={onClose}
      confirmButton={{
        name: 'Confirm',
        onClick: () => startDueDiligence(currentRow, 'ACTIVE', true)
      }}
    >
      <ModalContainer>
        <ModalTitel>Start Due Diligence</ModalTitel>
        <ModalDescription>
          This action will start due diligence for this deal. You can find the deal on the {currentRow.activeTier.funnel.name} Tab of the Due
          Diligence Page.
        </ModalDescription>
      </ModalContainer>
    </ModalWrappedContent>
  );
};

export default StartDueDiligence;

const ModalContainer = styled.div`
  font-weight: 400;
  padding: 50px 0;
`;

const ModalTitel = styled.div`
  font-size: 33px;
  text-align: center;
  color: ${({ theme }) => theme.font.strong};
`;

const ModalDescription = styled.div`
  font-size: 19px;
  line-height: 27px;
  text-align: center;
  color: ${({ theme }) => theme.font.base};
`;
