import * as React from 'react';

export const DragAndDropIcon = ({ ...props }: React.SVGProps<SVGSVGElement>) => (
  <svg width="16" height="30" viewBox="0 0 16 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5 21.4554H11V18.8585H5V21.4554ZM5 26.6569H11V24.0523H5V26.6569ZM5 13.6569V16.2538H11V13.6569H5ZM5 21.4554H11V18.8585H5V21.4554ZM5 26.6569H11V24.0523H5V26.6569ZM5 13.6569V16.2538H11V13.6569H5Z"
      fill={props.fill || '#ABABAB'}
    />
    <path
      d="M5 5.94016H11V3.34326H5V5.94016ZM5 11.1417H11V8.53706H5V11.1417ZM5 5.94016H11V3.34326H5V5.94016ZM5 11.1417H11V8.53706H5V11.1417Z"
      fill={props.fill || '#ABABAB'}
    />
  </svg>
);
