import { getUTCDate } from '@/components/allocations-page/utils';
import BasicTooltip from '@/components/basicComponents/tooltip';
import FormattingTooltip from '@/components/basicComponents/tooltip/dollarsTooltip';
import {
  MobileRow,
  MobileRowTitle,
  MobileRowValue,
  MobileTableRowWrap,
  MobileTableWrapper,
  NoResults
} from '@/components/table/mobileTable/mobileTable';
import styled from 'styled-components';
import { ASSET_CLASS_COLUMN_TOOLTIP } from '../..';
import { ITransformedAllocation, ITransformedAssetClass } from '../../types';
import BulkDropdownActions from '../bulkActionDropdown';
import MobileSumFooter from './mobileSumFooter';

interface MobileTableProps {
  tableData: ITransformedAssetClass[];
  mode: 'edit' | 'view';
  handleAssetClassAllocationChange?: (assetClassId: string, date: string, newValue: string) => void;
  applyValueToAllAssetClassRows?: (assetClassId: string, acAllocation: ITransformedAllocation) => void;
  applyValueToAllGoingForwardAssetClassRows?: (assetClassId: string, acAllocation: ITransformedAllocation) => void;
}

const MobileTable = ({
  tableData,
  mode,
  handleAssetClassAllocationChange,
  applyValueToAllAssetClassRows,
  applyValueToAllGoingForwardAssetClassRows
}: MobileTableProps) => {
  return (
    <MobileTableWrapper>
      {tableData?.length ? (
        <>
          {tableData.map((el) => (
            <CustomMobileTableRowWrap key={el.assetClassId}>
              <MobileRow>
                <MobileRowTitleWithTooltip>
                  Asset Class
                  <BasicTooltip tooltipContent={ASSET_CLASS_COLUMN_TOOLTIP} tooltipWidth={300} withTooltipIcon />
                </MobileRowTitleWithTooltip>
                <MobileRowValue>{el.name}</MobileRowValue>
              </MobileRow>

              {el.allocations.map((allocation) => (
                <MobileRow key={allocation.id}>
                  <MobileRowTitle>{getUTCDate(new Date(allocation.date)).toUpperCase()}</MobileRowTitle>
                  <MobileRowValue>
                    {mode === 'edit' ? (
                      <BulkDropdownActionsWrapper>
                        <BulkDropdownActions
                          isListCenter
                          isMobile
                          items={[
                            {
                              name: 'Apply To All In This Row',
                              onClick: () => applyValueToAllAssetClassRows(el.assetClassId, allocation)
                            },
                            {
                              name: 'Apply To All Going Forward',
                              onClick: () => applyValueToAllGoingForwardAssetClassRows(el.assetClassId, allocation)
                            }
                          ]}
                          value={allocation.allocation}
                          onChange={(value) => handleAssetClassAllocationChange(el.assetClassId, allocation.date, value)}
                        />
                      </BulkDropdownActionsWrapper>
                    ) : allocation.allocation === '-' ? (
                      '-'
                    ) : (
                      <FormattingTooltip zIndex={1000}>{+allocation.allocation}</FormattingTooltip>
                    )}
                  </MobileRowValue>
                </MobileRow>
              ))}
            </CustomMobileTableRowWrap>
          ))}
          <MobileSumFooter tableData={tableData} />
        </>
      ) : (
        <NoResults>No Results</NoResults>
      )}
    </MobileTableWrapper>
  );
};

export default MobileTable;

const CustomMobileTableRowWrap = styled(MobileTableRowWrap)`
  background-color: ${({ theme }) => theme.layer.base};
`;

const BulkDropdownActionsWrapper = styled.div`
  display: flex;
`;

const MobileRowTitleWithTooltip = styled(MobileRowTitle)`
  display: flex;
  align-items: center;
  gap: 8px;
`;
