import { useMutation, useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';

import styled from 'styled-components';

import FilterSelect from '@/components/basicComponents/select';
import { UPDATE_INVESTMENT } from '@/components/dueDiligence-page/queries';
import ModalWrappedContent from '@/components/fat-basicComponents/modal/modalWrappedContent';
import { Investment } from '@/components/fat-dealCatalog-page/types';
import { LIST_INVESTMENT_ASSIGNEES } from '../queries';

interface Assignee {
  id: string;
  firstName: string;
  lastName: string;
}

interface ChangeAssigneeProps {
  onClose: () => void;
  isOpen: boolean;
  currentRow: Investment | null;
  refetch: () => void;
}

const ChangeAssignee = ({ onClose, isOpen, currentRow, refetch }: ChangeAssigneeProps) => {
  if (!currentRow) return <></>;

  const [listAssignee, setListAssignee] = useState<Assignee[]>([]);
  const [currentAssignee, setCurrentAssignee] = useState<string>('');
  const [updateInvestment] = useMutation(UPDATE_INVESTMENT);

  useEffect(() => {
    const { assignee } = currentRow;
    if (assignee) {
      setCurrentAssignee(`${assignee.firstName} ${assignee.lastName}`);
    }
  }, [currentRow]);

  const { loading } = useQuery(LIST_INVESTMENT_ASSIGNEES, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'no-cache',
    onCompleted: ({ listInvestmentAssignees }) => setListAssignee(listInvestmentAssignees),
    onError: (error) => console.log(error)
  });

  const handleChangeAssignee = () => {
    const newAssignee = listAssignee.find((assignee: Assignee) => `${assignee.firstName} ${assignee.lastName}` === currentAssignee);
    if (!newAssignee) return;
    updateInvestment({
      variables: {
        data: {
          id: currentRow.id,
          assigneeId: newAssignee.id,
          nextClose: currentRow.nextClose,
          finalClose: currentRow.finalClose
        }
      },
      onCompleted: () => {
        refetch();
        onClose();
      }
    });
  };

  return (
    <ModalWrappedContent
      isOpen={isOpen}
      onClose={onClose}
      title="Assign Task"
      confirmButton={{
        name: 'Assign',
        onClick: handleChangeAssignee
      }}
      showRequiredFields
    >
      <ModalBody>
        <FilterSelect
          withSearch
          required
          data={listAssignee.map((el) => `${el.firstName} ${el.lastName}`)}
          selected={currentAssignee}
          disabled={!listAssignee?.length}
          setSelected={setCurrentAssignee}
          width={'100%'}
          minHeight={'35px'}
          label="Select An Assignee"
          loading={loading}
        />
      </ModalBody>
    </ModalWrappedContent>
  );
};

export default ChangeAssignee;

const ModalBody = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  gap: 10px;
  margin-top: 26px;
`;
