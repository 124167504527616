import { DownloadItemIcon } from '@/assets/static/icons/download-item';
import { useResponsive } from '@/hooks/use-responsive';
import { MainWrap } from '@/styles/common';
import { useQuery } from '@apollo/client';
import axios from 'axios';
import fileDownload from 'js-file-download';
import { useState } from 'react';
import styled, { useTheme } from 'styled-components';
import Button from '../fat-basicComponents/button';
import Header from '../fat-header';
import { LoaderOnWholeScreen } from '../loaders/loader-on-whole-screen';
import { GET_SETTINGS } from '../settings-page/queries';
import { DatePicker } from './DatePicker';
import Failure from './fat-modals/failure';

export const ReportingData = () => {
  const theme = useTheme();
  const { isMobile, isTablet } = useResponsive();

  const [datesValue, setDatesValue] = useState<{ startDate: string | null; endDate: string | null }>({ startDate: null, endDate: null });
  const [failureModalWindow, setFailureModalWindow] = useState({ isOpen: false, error: '' });
  const [loading, setLoading] = useState(false);
  const [moduleConfig, setModuleConfig] = useState(null);

  const { loading: settingsLoading } = useQuery(GET_SETTINGS, {
    variables: {
      type: 'appSetting'
    },
    onCompleted: (data) => {
      setModuleConfig(JSON.parse(data.Settings[0].value));
    },
    onError: (error) => {
      console.error(error);
    }
  });

  const handleDownload = async () => {
    try {
      setLoading(true);
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_BASE_URL}/commitment/reporting/${moduleConfig?.['Reporting']}`,
        {
          startDate: datesValue.startDate,
          endDate: datesValue.endDate,
          exportType: moduleConfig?.['Reporting']
        },
        {
          headers: { authorization: `Bearer ${localStorage.getItem('token')}` },
          responseType: 'blob'
        }
      );
      fileDownload(response.data, moduleConfig?.['Reporting'] === 'tamarac' ? 'reporting_export.csv' : 'reporting_export.zip');
    } catch (error: any) {
      setFailureModalWindow({ isOpen: true, error: await error.response.data.text() });
    } finally {
      setLoading(false);
    }
  };

  if (settingsLoading) {
    return <LoaderOnWholeScreen />;
  }

  return (
    <>
      <Failure
        errorMessage={failureModalWindow.error}
        onClose={() => setFailureModalWindow({ isOpen: false, error: '' })}
        isOpen={failureModalWindow.isOpen}
      />
      <MainWrap>
        <Header />
        <PaddingWrap>
          <ExportWrapper isMobile={isMobile}>
            <ReportingDataImgWrapper>
              <ReportingDataImg src={`/static/fat-mock-images/${moduleConfig?.['Reporting']}.png`} draggable="false" alt="Reporting Data" />
            </ReportingDataImgWrapper>
            <DatesWrapper isTablet={isTablet} isMobile={isMobile}>
              <DatePicker
                title="Start Date"
                value={datesValue.startDate}
                changeValue={(value) => setDatesValue({ ...datesValue, startDate: value })}
              />
              <DatePicker title="End Date" value={datesValue.endDate} changeValue={(value) => setDatesValue({ ...datesValue, endDate: value })} />
              <div>
                <Button
                  size="md"
                  styleType={loading ? 'disabled' : 'default'}
                  onClick={handleDownload}
                  isLoading={loading}
                  icon={<DownloadItemIcon fill={theme.context.light} />}
                >
                  Download
                </Button>
              </div>
            </DatesWrapper>
          </ExportWrapper>
        </PaddingWrap>
      </MainWrap>
    </>
  );
};

const PaddingWrap = styled.div`
  padding: 50px 16px 0 16px;
  @media (min-width: 600px) {
    padding: 50px 50px 0 50px;
  }
`;

const ReportingDataImg = styled.img`
  width: 100%;
  height: 100%;
`;

const ReportingDataImgWrapper = styled.div`
  width: 215px;
`;

const ExportWrapper = styled.div<{ isMobile: boolean }>`
  padding: 40px;
  background: ${({ theme }) => theme.layer[1]};
  border-radius: 10px;
  display: flex;
  flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};
  justify-content: space-between;
  align-items: center;
`;

const DatesWrapper = styled.div<{ isTablet: boolean; isMobile: boolean }>`
  width: 60%;
  display: flex;
  flex-direction: ${({ isTablet, isMobile }) => (isTablet || isMobile ? 'column' : 'row')};
  align-items: flex-end;
  gap: 20px;
`;
