import EditAmount from '@/components/allocations-page/modals/editAmount';
import SubmitTo from '@/components/allocations-page/modals/submitTo';
import { Mode, ModeType } from '@/components/allocations-page/types';
import { FieldsForRedirectWindows, ScrollTo, TypeWindow } from '@/components/recommendations-page/types';
import TableHeader from '@/components/table/tableHeader';
import guid from '@/utils/guid';
import { NetworkStatus } from '@apollo/client';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import styled from 'styled-components';

import CancelChanges from '@/components/allocations-page/modals/cancelChanges';
import { ISort } from '@/components/table/types';
import { useResponsive } from '../../../hooks/use-responsive';
import { IAllocationRow } from '../../../types/opportunity/pending-allocation';
import { Loader } from '../../loaders/loader';
import { TableBodyWrapper, TableWrapper } from '../../table/styles';
import { basicColumns, columns } from './constants';
import MobileTable from './table/mobileTable/index';
import OpportunitiesList from './table/tableBody';
import { ModalWindow, ModalWindowType } from './types';

interface PendingAllocationProps {
  changeField: (field: string, id: string) => (value?: string | boolean | number) => void;
  potentialAllocations: IAllocationRow[];
  setPotentialAllocations: (value: IAllocationRow[]) => void;
  includeProposedInvestments: boolean;
  investmentId: string | undefined;
  // advisors: Advisor[];
  currentTab: any;
  // currentTabIndex: number;
  allocations: IAllocationRow[];
  total: number;
  refetch: ({}: any) => void;
  // createAllocations: ({}: any) => void;
  updateAllocations: ({}: any) => void;
  // updateSort: (tab: string, sort: string, order: string) => void;
  loading: boolean;
  // updatePagination: (pagination: any) => void;
  changedAllocations: IAllocationRow[];
  setChangedAllocations: Dispatch<SetStateAction<IAllocationRow[]>>;
  // callRefetchesOnAllTabs?: () => void;
  detailsClick: (type: TypeWindow, id: FieldsForRedirectWindows) => void;
  setScrollTo: Dispatch<SetStateAction<ScrollTo>>;
  scrollTo: ScrollTo;
  mode: ModeType;
  setMode: Dispatch<SetStateAction<ModeType>>;
  // chosenDecision: string[];
  // saveTotalResult: (total: number) => void;
  openModalWindow: (type: ModalWindowType, row?: IAllocationRow) => void;
  closeModalWindow: () => void;
  modalWindow: ModalWindow;
  currentRow: IAllocationRow | null;
  networkStatus: any;
  isDetailsOpen: boolean;
  // setCurrentRow: (value: IAllocationRow) => void;
  isLoadingAll: boolean;
  flagRefetchBegin: boolean;
  beginAllocationLoading: boolean;
  setCheckedIds: Dispatch<SetStateAction<IAllocationRow[]>>;
  submitToOutstanding: () => void;
  openMobileModal: (data: IAllocationRow, modalType: string) => void;
  switcher: boolean;
  setSwitcher: Dispatch<SetStateAction<boolean>>;
  checkedIds: IAllocationRow[];
  savedSetSort: (value: ISort) => void;
}

// const suitabilitySelects = Object.values(SUITABILITY);
// const decisionSelects = ['Any Decision', 'Yes', 'No'];
const PendingAllocation: React.FC<PendingAllocationProps> = ({
  beginAllocationLoading,
  isDetailsOpen,
  changeField,
  potentialAllocations,
  setPotentialAllocations,
  currentRow,
  openModalWindow,
  closeModalWindow,
  modalWindow,
  // callRefetchesOnAllTabs,
  // advisors,
  currentTab,
  // currentTabIndex,
  allocations,
  // total,
  loading,
  changedAllocations,
  includeProposedInvestments,
  refetch,
  // createAllocations,
  updateAllocations,
  // updateSort,
  // updatePagination,
  setChangedAllocations,
  detailsClick,
  scrollTo,
  setScrollTo,
  mode,
  setMode,
  // chosenDecision,
  // saveTotalResult,
  networkStatus,
  // setCurrentRow,
  isLoadingAll,
  flagRefetchBegin,
  setCheckedIds,
  submitToOutstanding,
  openMobileModal,
  switcher,
  setSwitcher,
  checkedIds,
  savedSetSort
}) => {
  // const { classes } = useStyles();
  const [missingSubEntityWindowOpen, setMissingSubEntityWindowOpen] = useState(false);
  const { isMobile, isTablet } = useResponsive();
  // const advisorSelects = ['All Advisors', ...advisors.map((advisor: Advisor) => advisor.initials)];
  // const initialSelectors = {
  //   pending: {
  //     advisor: advisorSelects[0],
  //     suitability: suitabilitySelects[0],
  //     decision: decisionSelects[0],
  //   },
  //   investmentReview: {
  //     advisor: advisorSelects[0],
  //     suitability: suitabilitySelects[0],
  //     decision: decisionSelects[0],
  //   },
  //   advisorReview: {
  //     advisor: advisorSelects[0],
  //     suitability: suitabilitySelects[0],
  //     decision: decisionSelects[0],
  //   },
  //   compliance: {
  //     advisor: advisorSelects[0],
  //     suitability: suitabilitySelects[0],
  //     decision: decisionSelects[0],
  //   },
  // };
  // const [selectors, setSelectors] = useState<ISelectors>(initialSelectors);
  const [isSubmitted, setIsSubmitted] = useState(false);
  // const [pagination, setPagination] = useState<IPagination>({
  //   total,
  //   rowsPerPageOptions: [50, 100, 200, 500],
  //   page: 1,
  //   perPage: 1000,
  // });

  // useEffect(() => {
  //   setPagination({
  //     ...pagination,
  //     total,
  //   });
  // }, [total]);

  // const [sort, setSort] = useState({
  //   key: 'entity',
  //   order: 'ASC',
  // });
  //
  // const submitTo = () => {
  //   if (currentTab.value === 'pending') {
  //     return 'Advisor Review';
  //   } else if (currentTab.value === 'advisorReview') {
  //     return 'Compliance Review';
  //   } else if (currentTab.value === 'compliance') {
  //     return 'Recommendations';
  //   }
  // };

  useEffect(() => {
    const checkedRows = changedAllocations.filter(({ checked }) => checked);
    const newPotentialAllocations = allocations.map((allocation: IAllocationRow) => ({
      ...allocation,
      checked: checkedRows.find((item) => item.legalEntity.id === allocation.legalEntity.id)?.checked ?? false,
      suitabilityStatus: allocation.suitability.toLowerCase().split(' ').join('-') as any,
      selectedGroupLegalEntity: allocation.selectedGroupLegalEntity,
      decisionReason: `${allocation.decision ? 'Yes' : 'No'}${allocation.reason ? ` - ${allocation.reason}` : ''}`,
      ...(allocation.legalEntity?.isGroupEntity && {
        selectedGroupLegalEntity: allocation.selectedGroupLegalEntity ? allocation.selectedGroupLegalEntity : allocation.legalEntity?.entities[0]
      })
    }));
    const changeRowsWithState: any = newPotentialAllocations.map((allocation) => {
      const isExistCurrentRowWithState = changedAllocations.find(({ legalEntity }) => allocation.legalEntity?.id === legalEntity?.id);
      return isExistCurrentRowWithState && mode === Mode.EDIT ? isExistCurrentRowWithState : allocation;
    });
    setPotentialAllocations(changeRowsWithState);
  }, [flagRefetchBegin, allocations, mode]);

  // useEffect(() => {
  //   setPagination({ ...pagination });
  //   updatePagination({ page: pagination.page, perPage: pagination.perPage });
  //
  //   // refetch({
  //   //   page: pagination.page,
  //   //   rowsPerPage: pagination.perPage,
  //   // });
  // }, [pagination.perPage, pagination.page]);

  useEffect(() => {
    // setChangedAllocations([]);
    // setPagination({
    //   ...pagination,
    //   total,
    // });
    //
    // // refetch({
    // //   advisorFilter: 'All Advisors',
    // //   suitabilityFilter: null,
    // // });
    // setSelectors(initialSelectors);
  }, [currentTab.value]);

  // const { user } = useAuth();

  // const changeSelector = (tab: ITabsSelectors, select: keyof ISelects, value: string) => {
  //   setSelectors({ ...selectors, [tab]: { ...selectors[tab], [select]: value } });
  //   if (select === 'decision') return;
  //
  //   refetch({
  //     advisorFilter: select === 'advisor' ? value : selectors[tab].advisor,
  //     suitabilityFilter:
  //       select === 'suitability'
  //         ? value === suitabilitySelects[0]
  //           ? null
  //           : value
  //         : selectors[tab].suitability === suitabilitySelects[0]
  //         ? null
  //         : selectors[tab].suitability,
  //   });
  // };

  const getAllocationStatus = (row: any, currentTabValue: any) => {
    switch (currentTabValue) {
      case 'pending':
        return 'Investment review';
      case 'investmentReview':
        return 'Advisor review';
      case 'advisorReview':
        return 'Compliance review';
      case 'compliance':
        if (row.decision) {
          return 'Outstanding';
        } else {
          return 'Internal Review Rejected';
        }
    }
  };
  const changeAllCheckBox = (bool: boolean) => {
    const newAllocations = potentialAllocations.map((row) => ({ ...row, checked: bool }));
    setChangedAllocations(newAllocations);
    setCheckedIds(
      potentialAllocations.map(
        (allocation) => newAllocations.find((newAllocation) => newAllocation.legalEntity?.id === allocation.legalEntity?.id) ?? allocation
      )
    );
    setPotentialAllocations(
      potentialAllocations.map(
        (allocation) => newAllocations.find((newAllocation) => newAllocation.legalEntity?.id === allocation.legalEntity?.id) ?? allocation
      )
    );
  };
  // const handlePageChange = (event: React.MouseEvent<HTMLButtonElement> | null, page: number) =>
  //   setPagination({
  //     ...pagination,
  //     page: page + 1,
  //   });
  // const handlePerPageChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
  //   setPagination({ ...pagination, page: 1, perPage: parseInt(event.target.value) });
  const getCheckedAllocations = () => changedAllocations.filter(({ checked }) => checked);
  const createDataToUpdate = () => {
    const checkedAllocations = getCheckedAllocations();
    if (!checkedAllocations.length) return [];
    const dataToRequest = checkedAllocations.map((row) => ({
      ...(row.id && { id: row.id }),
      ...(currentTab.value === 'pending' && { investmentId: row.investment.id }),
      ...(currentTab.value === 'pending' && { legalEntityId: row.legalEntity?.id }),
      ...(currentTab.value === 'compliance' && row.decision && { operationsStatus: 'Ready for client review' }),
      committedCapital: row.committedCapital,
      proposalType: row.proposalType,
      decision: row.decision,
      reason: row.reason,
      suitability: row.suitability,
      allocationStatus: getAllocationStatus(row, currentTab.value),
      ...(row.selectedGroupLegalEntity && { selectedGroupLegalEntityId: row.selectedGroupLegalEntity.id })
    }));
    return dataToRequest;
  };

  useEffect(() => {
    if (isSubmitted && !loading) {
      // refetch({
      //   advisorFilter: 'All Advisors',
      //   suitabilityFilter: null,
      // });
      setIsSubmitted(false);
      // setSelectors(initialSelectors);
    }
    // if (!loading && isSubmitted && currentTab.value === 'compliance') {
    //   refetchAllocationHistoryQuery({ advisorFilter: 'All Advisors', suitabilityFilter: null });
    // }
  }, [loading]);

  useEffect(() => {
    scrollTo.makeScroll && window.scrollTo({ top: scrollTo.y, behavior: 'auto' });
    setScrollTo({ ...scrollTo, makeScroll: false });
  }, [scrollTo.makeScroll]);

  // const updateAllocationRequest = () => {
  //   const data = createDataToUpdate();
  //   if (!data.length) return;
  //   const actionsOnTab = {
  //     pending: () => {
  //       createAllocations({
  //         variables: {
  //           data,
  //         },
  //       });
  //       setChangedAllocations([]);
  //     },
  //     advisorReview: () => {
  //       updateAllocations({
  //         variables: {
  //           data,
  //         },
  //       });
  //       setChangedAllocations([]);
  //     },
  //     compliance: () => openModalWindow('submitTo'),
  //   };
  //   actionsOnTab[currentTab.value as ITabsSelectors]();
  //   setIsSubmitted(true);
  //   callRefetchesOnAllTabs?.();
  // };

  // const sortData = (key: string) => {
  //   const reverseOrder = sort.order === 'ASC' ? 'DESC' : 'ASC';
  //   const order = sort.key === key ? reverseOrder : 'DESC';
  //   setSort({
  //     key,
  //     order,
  //   });
  //   if (key === 'decision') {
  //     const orderNumber = order === 'ASC' ? 1 : -1;
  //     const sortedByDecision = potentialAllocations.sort((a, b) => (a.decisionReason > b.decisionReason ? orderNumber : orderNumber * -1));
  //     setPotentialAllocations(sortedByDecision);
  //     return;
  //   }
  //   updateSort(currentTab.value, key, order);
  // };

  // const getAmountCheckedRows = () => {
  //   return changedAllocations.filter(allocation => allocation.checked).length;
  // };

  const updateAmountInState = (id: string, value: number) => {
    setPotentialAllocations(
      potentialAllocations.map((row) =>
        row.legalEntity?.id === id
          ? {
              ...row,
              committedCapital: value
            }
          : row
      )
    );

    setCheckedIds(
      checkedIds.map((row) =>
        row.legalEntity?.id === id
          ? {
              ...row,
              committedCapital: value
            }
          : row
      )
    );

    const changedAmountRow: any = potentialAllocations?.find((row) => row.legalEntity?.id === id);
    if (currentTab.value === 'pending') {
      setChangedAllocations(
        potentialAllocations.map((row) =>
          row.legalEntity?.id === id
            ? {
                ...row,
                committedCapital: value
              }
            : row
        )
      );
    } else {
      setChangedAllocations([...changedAllocations, changedAmountRow]);
    }
  };

  const submitCancelChanges = () => {
    setMode(Mode.VIEW);
    refetch({});
    closeModalWindow();
    setCheckedIds([]);
    setChangedAllocations([]);
  };
  const isChosenFilters = document.querySelector('#chosenFliters');

  return (
    <>
      {modalWindow.type === 'amount' && (
        <EditAmount
          key={guid()}
          updateAllocationAmount={updateAllocations}
          isOpen={modalWindow.isOpen}
          onClose={closeModalWindow}
          allocation={currentRow}
          refetch={refetch}
          currentTab={currentTab.value}
          updateAmountInState={updateAmountInState}
        />
      )}
      {modalWindow.type === 'submitTo' && (
        <SubmitTo
          isOpen={modalWindow.isOpen}
          onClose={closeModalWindow}
          getCheckedAllocations={getCheckedAllocations}
          submitToOutstanding={submitToOutstanding}
        />
      )}
      {modalWindow.type === 'cancelChanges' && (
        <CancelChanges isOpen={modalWindow.isOpen} onClose={closeModalWindow} onSubmit={submitCancelChanges} />
      )}
      <TableWrapper>
        {isMobile ? (
          <>
            {networkStatus === NetworkStatus.setVariables || networkStatus === NetworkStatus.loading || isLoadingAll || beginAllocationLoading ? (
              <Loader />
            ) : (
              <MobileTable
                currentTab={currentTab.value}
                refetch={refetch}
                columns={currentTab.value === 'pending' ? columns : basicColumns}
                potentialAllocations={potentialAllocations || []}
                detailsClick={detailsClick}
                openMobileModal={openMobileModal}
              />
            )}
          </>
        ) : (
          <>
            <CustomTableHeader
              isChosenFilters={Boolean(isChosenFilters)}
              isTablet={isTablet}
              columns={currentTab.value === 'pending' ? columns : basicColumns}
              refetch={refetch}
              savedSetSort={savedSetSort}
              checkBox={{
                checked: potentialAllocations.every((row) => row.checked) && !!potentialAllocations.length,
                onChange: changeAllCheckBox,
                show: mode === Mode.EDIT
              }}
            />
            <TableBodyWrapperOverflow>
              {networkStatus === NetworkStatus.setVariables || networkStatus === NetworkStatus.loading || isLoadingAll || beginAllocationLoading ? (
                <Loader />
              ) : (
                <OpportunitiesList
                  refetch={refetch}
                  changeField={changeField}
                  setMissingSubEntityWindowOpen={setMissingSubEntityWindowOpen}
                  includeProposedInvestments={includeProposedInvestments}
                  potentialAllocations={potentialAllocations}
                  setPotentialAllocations={setPotentialAllocations}
                  changedAllocations={changedAllocations}
                  setChangedAllocations={setChangedAllocations}
                  currentTab={currentTab}
                  allocations={allocations}
                  updateAllocations={updateAllocations}
                  loading={loading}
                  columns={columns}
                  detailsClick={detailsClick}
                  openModalWindow={openModalWindow}
                  mode={mode}
                  setMode={setMode}
                  currentRow={currentRow}
                  switcher={switcher}
                  setSwitcher={setSwitcher}
                />
              )}
            </TableBodyWrapperOverflow>
          </>
        )}
      </TableWrapper>
    </>
  );
};

const TableBodyWrapperOverflow = styled(TableBodyWrapper)`
  overflow-x: clip;
`;

const CustomTableHeader = styled(TableHeader)<{ isChosenFilters?: boolean; isTablet: boolean }>`
  position: sticky;
  top: ${({ isChosenFilters, isTablet }) => (isChosenFilters ? (isTablet ? '202px' : '147px') : isTablet ? '130px' : '80px')};
  z-index: 10;
  padding-top: 0;
  justify-content: flex-start;
`;

export default PendingAllocation;
