import styled from 'styled-components';

import { useResponsive } from '@/hooks/use-responsive';
import { InfoIcon } from '@/assets/icons/info-icons/info';
import { SuitabilityEnum } from '../types';

type SuitabilityProps = {
  suitability: SuitabilityEnum;
  mr?: string;
  width?: string;
};

type GetColorType = {
  bg: string;
  br: string;
  color: string;
};

const getColor = (suitability: SuitabilityEnum): GetColorType => {
  if (suitability === SuitabilityEnum.SUITABLE) {
    return {
      bg: '#EFFFEA',
      br: '#3DBD4A',
      color: '#3DBD4A'
    };
  } else {
    return {
      bg: '#FFEAEC',
      br: '#D63B4B',
      color: '#D63B4B'
    };
  }
};

const Suitability = ({
  suitability,
  mr = '7',
  width = '100'
}: SuitabilityProps) => {
  const { isMobile } = useResponsive();
  return (
    <Wrap mr={isMobile ? '0' : mr} width={width}>
      <SuitabilityWrap isMobile={isMobile} getColor={getColor(suitability)}>
        {suitability}
      </SuitabilityWrap>
      <SuitabilityInfo getColor={getColor(suitability)}>
        <InfoIcon width={12} height={12} fill="#fff" />
      </SuitabilityInfo>
    </Wrap>
  );
};

const Wrap = styled.div<{ mr: string; width: string }>`
  cursor: pointer;
  width: ${({ width }) => `${width}%`};
  display: flex;
  margin-right: ${({ mr }) => `${mr}%`};
`;

const SuitabilityInfo = styled.div<{ getColor: GetColorType }>`
  width: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px;
  border-radius: 0 14px 14px 0;
  background: ${({ getColor }) => getColor.br};
`;

const SuitabilityWrap = styled.div<{
  getColor: GetColorType;
  isMobile: boolean;
}>`
  font-family: 'Blinker', sans-serif;
  font-size: 13px;
  width: 100%;
  text-align: center;
  line-height: ${({ isMobile }) => (isMobile ? '18px' : '22.4px')};
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ getColor }) => getColor.bg};
  border-radius: 14px 0 0 14px;
  border: 2px solid ${({ getColor }) => getColor.br};
  border-right: none;
  color: ${({ getColor }) => getColor.color};
`;

export default Suitability;
