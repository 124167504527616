import {
  MobileRow,
  MobileRowTitle,
  MobileRowValue,
  MobileTableRowWrap,
  MobileTableWrapper,
  NoResults
} from '@/components/table/mobileTable/mobileTable';

import { MinusScoreIcon } from '@/assets/icons/minusScore';
import { getUTCDate } from '@/components/allocations-page/utils';
import BasicTooltip from '@/components/basicComponents/tooltip';
import FormattingTooltip from '@/components/basicComponents/tooltip/dollarsTooltip';
import Button from '@/components/fat-basicComponents/button';
import styled, { useTheme } from 'styled-components';
import guid from '../../../../utils/guid';
import { opportunitiesTableColumns } from '../../constants';
import { Investment, TypeWindow } from '../../types';

interface MobileTableProps {
  openOpportunitiesData: Investment[];
  openPage: (id: string, type: TypeWindow) => void;
}

const MobileTable = ({ openOpportunitiesData, openPage }: MobileTableProps) => {
  const theme = useTheme();

  const TableRow = ({ el }: { el: Investment }) => {
    return (
      <MobileTableRowWrap key={guid()}>
        <MobileRow>
          <MobileRowTitle>{opportunitiesTableColumns[0].title}</MobileRowTitle>
          <MobileRowValue
            isRedirect={el.securityStatus === 'Open'}
            onClick={() => openPage(el.id, el.securityStatus === 'Active' ? 'opportunityDetails' : 'allocator')}
          >
            {el.name}
          </MobileRowValue>
        </MobileRow>

        <MobileRow>
          <MobileRowTitle>{opportunitiesTableColumns[1].title}</MobileRowTitle>
          <MobileRowValue>{el.subAssetClass ? `${el.assetClass.name} - ${el.subAssetClass.name}` : el.assetClass.name}</MobileRowValue>
        </MobileRow>

        <MobileRow>
          <MobileRowTitle>{opportunitiesTableColumns[2].title}</MobileRowTitle>
          {el?.subAssetClasses?.length > 1 ? (
            <BasicTooltip tooltipContent={el.subAssetClasses.toString().replace(/,/g, ', ')}>
              <MobileRowValue>Multiple ({el.subAssetClasses.length})</MobileRowValue>
            </BasicTooltip>
          ) : (
            <MobileRowValue>{el?.subAssetClasses && el?.subAssetClasses[0]}</MobileRowValue>
          )}
        </MobileRow>

        <MobileRow>
          <MobileRowTitle>{opportunitiesTableColumns[3].title}</MobileRowTitle>
          <MobileRowValue>{el.type}</MobileRowValue>
        </MobileRow>

        <MobileRow>
          <MobileRowTitle>{opportunitiesTableColumns[4].title}</MobileRowTitle>
          <MobileRowValue>{el.investorDocsDue && getUTCDate(new Date(el.investorDocsDue))}</MobileRowValue>
        </MobileRow>

        <MobileRow>
          <MobileRowTitle>{opportunitiesTableColumns[5].title}</MobileRowTitle>
          <MobileRowValue>
            {el.aggregateCommitment ? (
              <FormattingTooltip zIndex={1000} key={guid()}>
                {el.aggregateCommitment}
              </FormattingTooltip>
            ) : (
              <></>
            )}
          </MobileRowValue>
        </MobileRow>
        {el.securityStatus === 'Open' ? (
          <ButtonWrap>
            <Button size="sm" onClick={() => openPage(el.id, 'allocator')}>
              Allocate
            </Button>
          </ButtonWrap>
        ) : (
          <TooltipWrapper>
            <BasicTooltip tooltipContent="Closed" zIndex={1000}>
              <MinusScoreIcon width={29} height={29} fill={theme.font.disabled} />
            </BasicTooltip>
          </TooltipWrapper>
        )}
      </MobileTableRowWrap>
    );
  };

  return (
    <MobileTableWrapper>
      {openOpportunitiesData.length ? (
        openOpportunitiesData.map((el, index) => {
          return <TableRow el={el} key={index} />;
        })
      ) : (
        <NoResults>No Results</NoResults>
      )}
    </MobileTableWrapper>
  );
};

export default MobileTable;

const ButtonWrap = styled.div`
  margin: 0 auto;
`;

const TooltipWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
