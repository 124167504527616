import { IListEntity } from '@/components/fat-investors-page/types';
import { useQuery } from '@apollo/client';
import { Dispatch, SetStateAction, useState } from 'react';
import styled from 'styled-components';
import FooterModal from '../../../footer';
import { ProgressBar } from '../../../progressBar';
import { LEGAL_ENTITIES_QUERY } from '../../../queries';
import { Status } from '../../../status';
import { ImportWrapper } from '../../../styles';
import { IEntityCodesTableData, MissingIdError } from '../../../types';
import { allocationsSteps } from '../../constants';
import { entityCodesStatusError, entityCodesStatusSuccess } from './constants';
import Table from './table/table';

interface EntityCodesProps {
  importSteps: typeof allocationsSteps;
  startOver: () => void;
  nextStep: () => void;
  tableData: IEntityCodesTableData[];
  setTableData: Dispatch<SetStateAction<IEntityCodesTableData[]>>;
  errorEntityCode: MissingIdError[];
  setErrors: Dispatch<SetStateAction<MissingIdError[]>>;
}

export const EntityCodes = ({ importSteps, startOver, nextStep, tableData, setTableData, errorEntityCode, setErrors }: EntityCodesProps) => {
  const [showTable, setShowTable] = useState(false);
  const [entityList, setEntityList] = useState<IListEntity[]>([]);

  const { data, loading: entityListLoading } = useQuery<{ LegalEntities: IListEntity[] }>(LEGAL_ENTITIES_QUERY, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
    variables: {
      filter: {
        missingEntityCode: true
      }
    },
    onCompleted: (data) => {
      const sortedList = [...data.LegalEntities].sort((a: IListEntity, b: IListEntity) => a.entityName.localeCompare(b.entityName));
      setEntityList(sortedList);
    },
    onError: (error) => {
      console.error(error);
    }
  });

  const handleChangeAssignment = (entityName: string, prevValue: string, entityCode: string) => {
    if (entityName === 'None') {
      const updatedTableData = tableData.map((row) => {
        if (row.entityCode === entityCode) {
          return { ...row, entityStatus: 'Required', entityAssignment: entityName, changed: false, entityId: null };
        }
        return row;
      });
      setTableData(updatedTableData);

      if (prevValue !== 'Select An Entity') {
        const prevSelectedEntity = data?.LegalEntities.find((entity) => `${entity.entityName} (${entity.tenant.name})` === prevValue);
        if (prevSelectedEntity) {
          setEntityList([prevSelectedEntity, ...entityList].sort((a: IListEntity, b: IListEntity) => a.entityName.localeCompare(b.entityName)));
        }
      }
      return;
    }

    const selectedEntity = entityList.find((entity) => `${entity.entityName} (${entity.tenant.name})` === entityName);
    if (!selectedEntity) return;

    const updatedTableData = tableData.map((row) => {
      if (row.entityCode === entityCode) {
        return { ...row, entityStatus: 'Complete', entityAssignment: entityName, changed: true, entityId: selectedEntity.id };
      }
      return row;
    });

    setTableData(updatedTableData);
    setErrors((prev) => {
      return prev.filter((error) => error.id !== entityCode);
    });

    if (!showTable) {
      setShowTable(true);
    }
    const updatedEntityList = entityList.filter((entity) => `${entity.entityName} (${entity.tenant.name})` !== entityName);

    if (prevValue === 'Select An Entity' || prevValue === 'None') {
      setEntityList(updatedEntityList);
      return;
    }
    const prevSelectedEntity = data?.LegalEntities.find((entity) => `${entity.entityName} (${entity.tenant.name})` === prevValue);

    if (prevSelectedEntity) {
      setEntityList([prevSelectedEntity, ...updatedEntityList].sort((a: IListEntity, b: IListEntity) => a.entityName.localeCompare(b.entityName)));
    }
  };

  const resetAssignment = (entityCode: string, prevValue: string) => {
    const prevSelectedEntity = data?.LegalEntities.find((entity) => `${entity.entityName} (${entity.tenant.name})` === prevValue);

    if (prevSelectedEntity) {
      setEntityList([prevSelectedEntity, ...entityList].sort((a: IListEntity, b: IListEntity) => a.entityName.localeCompare(b.entityName)));
    }

    const updatedTableData = tableData.map((row) => {
      if (row.entityCode === entityCode) {
        return { ...row, entityStatus: 'Required', entityAssignment: 'Select An Entity', changed: false, entityId: null };
      }
      return row;
    });

    setTableData(updatedTableData);
    const updatedErrors = [{ id: entityCode, message: `Missing legal entity for entity code ${entityCode}` }, ...errorEntityCode];

    setErrors(updatedErrors);
  };

  return (
    <>
      <ImportWrapper>
        <ProgressBar importSteps={importSteps} />
        <Status
          errorsCount={errorEntityCode.length || null}
          matched={[{ count: tableData.length, text: 'Matched' }]}
          showTable={showTable}
          setShowTable={setShowTable}
          startOver={startOver}
          confirmButton={{ name: 'Continue', onClick: nextStep, disabled: Boolean(errorEntityCode.length) }}
          success={entityCodesStatusSuccess}
          error={entityCodesStatusError}
        />
      </ImportWrapper>
      {showTable || errorEntityCode?.length ? (
        <TableWrapper>
          <Table
            tableData={tableData}
            errorEntityCode={errorEntityCode}
            entityList={entityList ?? []}
            entityListLoading={entityListLoading}
            handleChangeAssignment={handleChangeAssignment}
            resetAssignment={resetAssignment}
          />
        </TableWrapper>
      ) : (
        <></>
      )}
      {errorEntityCode?.length || showTable ? (
        <FooterModal startOver={startOver} confirmButton={{ name: 'Continue', onClick: nextStep, disabled: Boolean(errorEntityCode.length) }} />
      ) : (
        <></>
      )}
    </>
  );
};

const TableWrapper = styled.div`
  margin-bottom: 100px;
`;
