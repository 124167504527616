import RadioButton from '@/components/basicComponents/radioButton';
import FilterSelect from '@/components/basicComponents/select';
import Label from '@/components/fat-basicComponents/label';
import ModalWrappedContent from '@/components/fat-basicComponents/modal/modalWrappedContent';
import { Loader } from '@/components/loaders/loader';
import { useResponsive } from '@/hooks/use-responsive';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import styled from 'styled-components';

interface EntityTypeProps {
  onClose: () => void;
  isOpen: boolean;
}

interface IEntityTypeForm {
  taxExempt: boolean;
  entityType: string;
}

const defaultFormValues: IEntityTypeForm = {
  taxExempt: false,
  entityType: 'Select'
};

const taxExemptSectionOptions = ['No', 'Yes'];

const EntityType = ({ onClose, isOpen }: EntityTypeProps) => {
  const isMobile = useResponsive();

  const {
    handleSubmit,
    getValues,
    control,
    reset,
    formState: { isValid }
  } = useForm<IEntityTypeForm>({ defaultValues: defaultFormValues });

  const [isSubmitting, setIsSubmitting] = useState(false);

  const onSave = () => {};

  return (
    <ModalWrappedContent
      isOpen={isOpen}
      onClose={onClose}
      title={false ? 'Creating Entity Type' : 'Entity Type'}
      showRequiredFields={!false}
      showCloseIcon={!false}
      showCancelButton={!false}
      isUseOutsideClick={!false}
      confirmButton={{
        name: 'Save',
        onClick: handleSubmit(onSave),
        disabled: isSubmitting
      }}
    >
      {false ? (
        <LoaderWrapper>
          <ModalText>Please allow a few moment for this process to complete.</ModalText>
          <Loader />
        </LoaderWrapper>
      ) : (
        <ModalBody>
          {/* {false && <Banner icon={<InfoIcon width={26} height={26} />} title="Error" description={createAutoPlanError.message} bgColor="#D63B4B" />} */}
          <div>
            <Label>Tax Exempt</Label>
            <TaxExemptOptionsWrapper>
              <Controller
                name="taxExempt"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <>
                    {taxExemptSectionOptions.map((option, index) => (
                      <RadioButton
                        key={option + index}
                        name="taxExempt"
                        radioButtonText={option}
                        selectedOption={value ? taxExemptSectionOptions[1] : taxExemptSectionOptions[0]}
                        handleRadioChange={(event) => onChange(event.target.value === taxExemptSectionOptions[1])}
                      />
                    ))}
                  </>
                )}
              />
            </TaxExemptOptionsWrapper>
          </div>
          <div>
            <Label required>Entity Type</Label>
            <Controller
              name="entityType"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: 'Entity Type is required'
                },
                pattern: {
                  value: /^(?!Select$)/,
                  message: 'Entity Type is required'
                }
              }}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <>
                  <EntityTypeSelect data={['Select']} selected={value} setSelected={onChange} width={isMobile ? '100%' : '500px'} />
                  {error?.message && <ErrorText>{error.message}</ErrorText>}
                </>
              )}
            />
          </div>
        </ModalBody>
      )}
    </ModalWrappedContent>
  );
};

export default EntityType;

const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: 100%;
`;

const LoaderWrapper = styled.div`
  text-align: center;
`;

const ModalText = styled.div`
  display: inline-block;
  text-align: center;
  font-size: 19px;
  font-weight: 400;
  color: ${({ theme }) => theme.font.base};
`;

const TaxExemptOptionsWrapper = styled.div`
  display: flex;
`;

const EntityTypeSelect = styled(FilterSelect)`
  background-color: ${({ theme }) => theme.layer[2]};
  border: 1px solid ${({ theme }) => theme.border.base};
  padding: 10px 12px;
  border-radius: 5px;

  #titleSelect {
    text-transform: none;
    font-family: Blinker;
    font-size: 19px;
    line-height: 26.6px;
  }

  #dropDownSelect {
    top: 50px;
  }
`;

const ErrorText = styled.p`
  color: ${({ theme }) => theme.context.error};
  font-family: Blinker;
  font-style: normal;
  font-weight: 400;
  font-size: 19px;
  line-height: 26.6px;
`;
