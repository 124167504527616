import { CommitmentError } from '@/components/fat-settings-page/importData/types';
import FilterPage from '@/components/table/fat-filterPage';
import TableHeader from '@/components/table/fat-tableHeader';
import { TableBodyWrapper, TableWrapper } from '@/components/table/styles';
import { ISort } from '@/components/table/types';
import { useResponsive } from '@/hooks/use-responsive';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { columns } from '../constants';
import MobileTable from './mobileTable';
import TableBody from './tableBody';

interface TableProps {
  tableData: any;
  commitmentError: CommitmentError[];
}

const Table = ({ tableData, commitmentError }: TableProps) => {
  const { isMobile, isTablet } = useResponsive();

  const [sort, setSort] = useState<ISort>({
    key: 'ENTITY_CODE',
    asc: true
  });
  const [search, setSearch] = useState('');
  const [primaryFilter, setPrimaryFilter] = useState('All');
  const [sortedTableData, setSortedTableData] = useState<any>([]);

  useEffect(() => {
    if (commitmentError?.length) {
      setPrimaryFilter('Errors Only');
      return;
    }
    setPrimaryFilter('All');
  }, [commitmentError]);

  useEffect(() => {
    const sortedData = [...tableData].sort((a, b) => {
      switch (sort.key) {
        case 'ENTITY':
          return sort.asc ? a.entityName?.localeCompare(b.entityName) : b.entityName?.localeCompare(a.entityName);
        case 'ENTITY_CODE':
          return sort.asc ? a.entityCode.localeCompare(b.entityCode) : b.entityCode.localeCompare(a.entityCode);
        case 'SERIES':
          return sort.asc ? a.seriesName?.localeCompare(b.seriesName) : b.seriesName?.localeCompare(a.seriesName);
        case 'SERIES_CODE':
          return sort.asc ? a.investmentCode.localeCompare(b.investmentCode) : b.investmentCode.localeCompare(a.investmentCode);
        case 'TRANSACTION_TYPE':
          return sort.asc ? a.transactionType.localeCompare(b.transactionType) : b.transactionType.localeCompare(a.transactionType);
        case 'TRADE_DATE':
          return sort.asc ? a.tradeDate.localeCompare(b.tradeDate) : b.tradeDate.localeCompare(a.tradeDate);
        case 'INVESTMENT_AMOUNT':
          return sort.asc ? a.netAmount - b.netAmount : b.netAmount - a.netAmount;
        case 'TOTAL_AMOUNT':
          return sort.asc ? a.totalAmount - b.totalAmount : b.totalAmount - a.totalAmount;
        case 'STATUS':
          return sort.asc ? a.commitmentStatus.localeCompare(b.commitmentStatus) : b.commitmentStatus.localeCompare(a.commitmentStatus);
        default:
          break;
      }
    });

    setSortedTableData(sortedData);
  }, [sort]);

  const filteredTableData = () => {
    const newTableData = [...sortedTableData];
    const filteredTableData = newTableData.filter(
      (item: any) =>
        item.entityName?.toLowerCase().includes(search.toLowerCase()) ||
        item.entityCode.toLowerCase().includes(search.toLowerCase()) ||
        item.seriesName?.toLowerCase().includes(search.toLowerCase()) ||
        item.investmentCode.toLowerCase().includes(search.toLowerCase())
    );
    if (primaryFilter !== 'Errors Only') return filteredTableData;

    return filteredTableData.filter(
      (item: any) => item.commitmentStatus === 'Missing Commitment' || item.commitmentStatus === 'Unrecognized Transaction Type'
    );
  };

  return (
    <>
      <CustomFilterPage
        isMobile={isMobile}
        isTablet={isTablet}
        search={{
          value: search,
          onChange: setSearch,
          placeholder: 'Search'
        }}
        primaryFilter={{
          value: primaryFilter,
          onChange: setPrimaryFilter,
          selects: ['All', 'Errors Only']
        }}
        resultsValue={filteredTableData().length}
        refetch={() => null as any}
      />
      <TableWrapper padding="0">
        {isMobile ? (
          <MobileTable tableData={filteredTableData()} />
        ) : (
          <>
            <CustomTableHeader isTablet={isTablet} refetch={() => null as any} columns={columns} savedSort={sort} savedSetSort={setSort} />
            <TableBodyWrapper>
              <TableBody tableData={filteredTableData()} />
            </TableBodyWrapper>
          </>
        )}
      </TableWrapper>
    </>
  );
};

export default Table;

const CustomFilterPage = styled(FilterPage)<{ isMobile: boolean; isTablet: boolean }>`
  position: sticky;
  top: ${({ isMobile, isTablet }) => (isMobile || isTablet ? '50px' : '0')};
  z-index: 9;
  margin-top: 0;
  margin-left: -1px;
  width: calc(100% + 2px);
  padding: ${({ isMobile }) => (isMobile ? '20px 0 0 0' : '20px 0 10px 0')};
`;

const CustomTableHeader = styled(TableHeader)<{ isTablet: boolean }>`
  position: sticky;
  top: ${({ isTablet }) => (isTablet ? '130px' : '80px')};
  z-index: 8;
  padding-top: 0;
`;
