import NoResults from '@/components/table/noResults';
import { ITransformedAllocation, ITransformedAssetClass } from '../types';
import TableRow from './tableRow';

interface TableBodyProps {
  tableData: ITransformedAssetClass[];
  columnWidth: number;
  mode: 'edit' | 'view';
  targetSubAssetClasses: boolean;
  handleAssetClassAllocationChange?: (assetClassId: string, date: string, newValue: string) => void;
  handleSubAssetClassAllocationChange?: (
    assetClassId: string,
    subAssetClassId: string,
    sacAllocation: ITransformedAllocation,
    newValue: string
  ) => void;
  applyValueToAllAssetClassRows?: (assetClassId: string, acAllocation: ITransformedAllocation) => void;
  applyValueToAllGoingForwardAssetClassRows?: (assetClassId: string, acAllocation: ITransformedAllocation) => void;
  applyValueToAllSubAssetClassRows?: (assetClassId: string, subAssetClassId: string, sacAllocation: ITransformedAllocation) => void;
  applyValueToAllGoingForwardSubAssetClassRows?: (assetClassId: string, subAssetClassId: string, sacAllocation: ITransformedAllocation) => void;
}

const TableBody = ({
  tableData,
  columnWidth,
  mode,
  targetSubAssetClasses,
  handleAssetClassAllocationChange,
  handleSubAssetClassAllocationChange,
  applyValueToAllAssetClassRows,
  applyValueToAllGoingForwardAssetClassRows,
  applyValueToAllSubAssetClassRows,
  applyValueToAllGoingForwardSubAssetClassRows
}: TableBodyProps) => {
  if (!tableData?.length) {
    return <NoResults />;
  }

  return (
    <>
      {tableData.map((row) => (
        <TableRow
          key={row.assetClassId}
          row={row}
          columnWidth={columnWidth}
          mode={mode}
          targetSubAssetClasses={targetSubAssetClasses}
          handleAssetClassAllocationChange={handleAssetClassAllocationChange}
          handleSubAssetClassAllocationChange={handleSubAssetClassAllocationChange}
          applyValueToAllAssetClassRows={applyValueToAllAssetClassRows}
          applyValueToAllGoingForwardAssetClassRows={applyValueToAllGoingForwardAssetClassRows}
          applyValueToAllSubAssetClassRows={applyValueToAllSubAssetClassRows}
          applyValueToAllGoingForwardSubAssetClassRows={applyValueToAllGoingForwardSubAssetClassRows}
        />
      ))}
    </>
  );
};

export default TableBody;
