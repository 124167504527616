export const formatPrice = (
  price?: number,
  options: { numDecimals: number } = { numDecimals: 0 }
) => {
  if (!price) {
    return '$0';
  }

  return price.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  });
};

export const formatNumber = (number: number) => number?.toFixed(2) ?? '0.00';

export const formatIntegerPrice = (
  price?: number,
  options: { numDecimals: number } = { numDecimals: 2 }
) => {
  if (!price) {
    return '$0.00';
  }
  return formatPrice(price / 100, options);
};

export const formatPercent = (
  percentage?: number,
  options: { numDecimals: number; maxFractionDigits: number } = {
    numDecimals: 2,
    maxFractionDigits: 2
  }
) => {
  if (!percentage) {
    return '0.00%';
  }
  const formatter = new Intl.NumberFormat('en-us', {
    style: 'percent',
    minimumFractionDigits: options.numDecimals,
    maximumFractionDigits: options.maxFractionDigits
  });
  return formatter.format(percentage / 100);
};
