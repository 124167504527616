import { gql } from '@apollo/client';

export const GET_FUNNEL_QUERY = gql`
  query GetDueDiligenceFunnel($id: String!) {
    getDueDiligenceFunnel(id: $id) {
      id
      funnelStaticId
      name
      externalId
      status
      records {
        id
        investment {
          securityStatus
        }
      }
      tiers {
        id
        name
        order
        sections {
          id
          name
          userCanScore
          pdfLayout
          order
          fields {
            id
            name
            required
            type
            order
            linkedFieldId
            linkedField {
              id
              name
              funnelTierSection {
                name
                funnelTier {
                  name
                }
              }
            }
            promptGroup {
              id
              prompts
              summaryPrompt
              contextPrompt
              rubricTemplates
              useCustomContextPrompt
              useCustomSummaryPrompt
            }
          }
        }
        checklistItems {
          id
          name
          order
          required
        }
      }
    }
  }
`;

export const DUE_DILIGENCE_FUNNEL_LIST_SETTINGS_QUERY = gql`
  query ListDueDiligenceFunnels($data: DueDiligenceFunnelListDto!, $archivedFunnelData: DueDiligenceFunnelListByStatusDto!) {
    listDueDiligenceFunnels(data: $data) {
      id
      funnelStaticId
      externalId
      name
      createdAt
      status
      records {
        id
        investment {
          id
          name
          securityStatus
        }
      }
      draftFunnel {
        id
        createdAt
        name
      }
      archivedFunnels(data: $archivedFunnelData) {
        records {
          investment {
            securityStatus
          }
        }
      }
    }
  }
`;

export const CREATE_DEAL_PROMPT_GROUP_FOR_DDR = gql`
  mutation createDealAssistantPromptGroupForDDR($data: DueDiligenceFunnelTierSectionFieldPromptGroupCreateDto!) {
    createDealAssistantPromptGroupForDDR(data: $data) {
      id
      prompts
      summaryPrompt
      contextPrompt
      useCustomContextPrompt
      useCustomSummaryPrompt
    }
  }
`;

export const CREATE_DEAL_PROMPT_GROUP = gql`
  mutation createDealAssistantAIPromptGroup($data: DealAssistantPromptGroupCreateDto!) {
    createDealAssistantAIPromptGroup(data: $data) {
      id
      prompts
      summaryPrompt
      contextPrompt
      useCustomContextPrompt
      useCustomSummaryPrompt
    }
  }
`;

export const UPDATE_DEAL_PROMPT_GROUP = gql`
  mutation updateDealAssistantAIPromptGroup($data: DealAssistantPromptGroupUpdateDto!) {
    updateDealAssistantAIPromptGroup(data: $data) {
      id
      prompts
      summaryPrompt
      contextPrompt
      useCustomContextPrompt
      useCustomSummaryPrompt
    }
  }
`;

export const DELETE_DEAL_PROMPT_GROUP = gql`
  mutation deleteDealAssistantAIPromptGroup($id: String!) {
    deleteDealAssistantAIPromptGroup(id: $id)
  }
`;

export const GET_SETTINGS = gql`
  query ($ids: [String!], $type: String) {
    Settings(ids: $ids, type: $type) {
      id
      key
      description
      type
      value
    }
  }
`;

export const CREATE_SETTING = gql`
  mutation createSetting($data: CreateSettingDto!) {
    createSetting(data: $data) {
      id
      key
    }
  }
`;

export const UPDATE_SETTING = gql`
  mutation updateSetting($data: UpdateSettingDto!) {
    updateSetting(data: $data) {
      id
      key
    }
  }
`;

export const DELETE_SETTING = gql`
  mutation deleteSetting($id: String!) {
    deleteSetting(id: $id)
  }
`;

export const getSettingAttachmentUploadUrl = gql`
  query getSettingAttachmentUploadUrl($data: SettingAttachmentUploadUrlDto!) {
    getSettingAttachmentUploadUrl(data: $data) {
      assetKey
      url
    }
  }
`;

export const getAssetSignedUrl = gql`
  query getAssetSignedUrl($assetKey: String!) {
    getAssetSignedUrl(assetKey: $assetKey)
  }
`;

export const createSettingAttachment = gql`
  mutation createSettingAttachment($assetKey: String!) {
    createSettingAttachment(assetKey: $assetKey) {
      id
      url
    }
  }
`;

export const deleteSettingAttachment = gql`
  mutation deleteSettingAttachment($id: String!) {
    deleteSettingAttachment(id: $id)
  }
`;

export const importDealAssistantAIPromptGroup = gql`
  mutation importDealAssistantAIPromptGroup($data: DealAssistantPromptGroupImportRequestDto!) {
    importDealAssistantAIPromptGroup(data: $data) {
      status
    }
  }
`;

export const importDealAssistantAIGroundTruth = gql`
  mutation importDealAssistantAIGroundTruth($data: ImportDealAssistantAIGroundTruthDto!) {
    importDealAssistantAIGroundTruth(data: $data) {
      status
    }
  }
`;

export const DELETE_DUE_DILIGENCE_FUNNEL = gql`
  mutation deleteDueDiligenceFunnel($id: String!) {
    deleteDueDiligenceFunnel(id: $id)
  }
`;

export const CREATE_DUE_DILIGENCE_FUNNEL = gql`
  mutation createDueDiligenceFunnel($data: DueDiligenceFunnelCreateDto!) {
    createDueDiligenceFunnel(data: $data) {
      id
      name
    }
  }
`;

export const UPDATE_DUE_DILIGENCE_FUNNEL = gql`
  mutation updateDueDiligenceFunnel($data: DueDiligenceFunnelUpdateDto!) {
    updateDueDiligenceFunnel(data: $data) {
      id
      name
    }
  }
`;

export const UPDATE_DUE_DILIGENCE_FUNNEL_ORDER = gql`
  mutation updateDueDiligenceFunnelsOrder($data: [DueDiligenceFunnelUpdateOrderDto!]!) {
    updateDueDiligenceFunnelsOrder(data: $data) {
      id
    }
  }
`;

export const CREATE_DUE_DILIGENCE_TIER = gql`
  mutation createDueDiligenceFunnelTier($data: DueDiligenceFunnelTierCreateDto!) {
    createDueDiligenceFunnelTier(data: $data) {
      id
      name
      order
    }
  }
`;

export const UPDATE_DUE_DILIGENCE_TIER = gql`
  mutation updateDueDiligenceFunnelTier($data: DueDiligenceFunnelTierUpdateDto!) {
    updateDueDiligenceFunnelTier(data: $data) {
      id
      name
      order
    }
  }
`;

export const UPDATE_DRAFT_DUE_DILIGENCE_TIER = gql`
  mutation updateDraftDueDiligenceFunnelTier($data: DraftDueDiligenceFunnelTierUpdateDto!) {
    updateDraftDueDiligenceFunnelTier(data: $data) {
      id
      name
    }
  }
`;

export const CREATE_DUE_DILIGENCE_SECTION = gql`
  mutation createDueDiligenceFunnelTierSection($data: DueDiligenceFunnelTierSectionCreateDto!) {
    createDueDiligenceFunnelTierSection(data: $data) {
      id
      name
      order
      userCanScore
      pdfLayout
    }
  }
`;

export const UPDATE_DUE_DILIGENCE_SECTION = gql`
  mutation updateDueDiligenceFunnelTierSection($data: DueDiligenceFunnelTierSectionUpdateDto!) {
    updateDueDiligenceFunnelTierSection(data: $data) {
      id
      name
      order
      userCanScore
      pdfLayout
    }
  }
`;

export const UPDATE_DRAFT_DUE_DILIGENCE_SECTION = gql`
  mutation updateDraftDueDiligenceFunnelTierSection($data: DraftDueDiligenceFunnelTierSectionUpdateDto!) {
    updateDraftDueDiligenceFunnelTierSection(data: $data) {
      id
      name
      userCanScore
      pdfLayout
    }
  }
`;

export const CREATE_DUE_DILIGENCE_FIELD = gql`
  mutation createDueDiligenceFunnelTierSectionField($data: DueDiligenceFunnelTierSectionFieldCreateDto!) {
    createDueDiligenceFunnelTierSectionField(data: $data) {
      id
      name
      linkedFieldId
    }
  }
`;

export const UPDATE_DUE_DILIGENCE_FIELD = gql`
  mutation updateDueDiligenceFunnelTierSectionField($data: DueDiligenceFunnelTierSectionFieldUpdateDto!) {
    updateDueDiligenceFunnelTierSectionField(data: $data) {
      id
    }
  }
`;

export const UPDATE_DRAFT_DUE_DILIGENCE_FIELD = gql`
  mutation updateDraftDueDiligenceFunnelTierSectionField($data: DraftDueDiligenceFunnelTierSectionFieldUpdateDto!) {
    updateDraftDueDiligenceFunnelTierSectionField(data: $data) {
      id
    }
  }
`;

export const CREATE_DUE_DILIGENCE_CHECKLIST_ITEM = gql`
  mutation createDueDiligenceFunnelTierChecklistItem($data: DueDiligenceFunnelTierChecklistItemCreateDto!) {
    createDueDiligenceFunnelTierChecklistItem(data: $data) {
      id
      name
      order
      required
    }
  }
`;

export const UPDATE_DUE_DILIGENCE_CHECKLIST_ITEM = gql`
  mutation updateDueDiligenceFunnelTierChecklistItem($data: DueDiligenceFunnelTierChecklistItemUpdateDto!) {
    updateDueDiligenceFunnelTierChecklistItem(data: $data) {
      id
      name
      order
      required
    }
  }
`;

export const UPDATE_DRAFT_DUE_DILIGENCE_CHECKLIST_ITEM = gql`
  mutation updateDraftDueDiligenceFunnelTierChecklistItem($data: DraftDueDiligenceFunnelTierChecklistItemUpdateDto!) {
    updateDraftDueDiligenceFunnelTierChecklistItem(data: $data) {
      id
      name
      required
    }
  }
`;

export const DELETE_DUE_DILIGENCE_TIER = gql`
  mutation deleteDueDiligenceFunnelTier($id: String!) {
    deleteDueDiligenceFunnelTier(id: $id)
  }
`;

export const DELETE_DUE_DILIGENCE_SECTION = gql`
  mutation deleteDueDiligenceFunnelTierSection($id: String!) {
    deleteDueDiligenceFunnelTierSection(id: $id)
  }
`;

export const DELETE_DUE_DILIGENCE_FIELD = gql`
  mutation deleteDueDiligenceFunnelTierSectionField($id: String!) {
    deleteDueDiligenceFunnelTierSectionField(id: $id)
  }
`;

export const DELETE_DUE_DILIGENCE_CHECKLIST_ITEM = gql`
  mutation deleteDueDiligenceFunnelTierChecklistItem($id: String!) {
    deleteDueDiligenceFunnelTierChecklistItem(id: $id)
  }
`;
