import { User } from '../../../types/user';
import AdvanceTo from '../modals/advanceTo';
import ApproveDiligence from '../modals/approveDiligence';
import BackTo from '../modals/backTo';
import ChangeAssignee from '../modals/changeAssignee';
import DownloadPDF from '../modals/downloadPDF';
import OverrideAnswer from '../modals/overrideAnswer';
import RejectInvestment from '../modals/rejectInvestment';
import RelinkField from '../modals/relinkField';
import { IChecklistItem, ISection, ISectionFields, ITier } from './types';

type FieldModalProps = {
  modalWindow: { isOpen: boolean; type: string };
  closeModalWindow: () => void;
  currentRow: ITier | ISection | IChecklistItem | ISectionFields | null;
  recordId: string | null;
  updateTierStatus: (variables: any) => void;
  updateRecordStatus: (variables: any) => void;
  advanceToNexTier: () => void;
  owner?: User;
  backToPreviousTier: (tierData: ITier) => void;
  activeTab: string;
  openEditPage: (type: string, row: ISectionFields) => void;
};

const FieldModal = ({
  modalWindow,
  closeModalWindow,
  currentRow,
  updateTierStatus,
  updateRecordStatus,
  recordId,
  advanceToNexTier,
  owner,
  backToPreviousTier,
  activeTab,
  openEditPage,
}: FieldModalProps) => {
  switch (modalWindow.type) {
    case 'change-assignee':
      return <ChangeAssignee row={currentRow} owner={owner} isOpen={modalWindow.isOpen} onClose={closeModalWindow} />;
    case 'advance-to':
      return (
        <AdvanceTo
          isOpen={modalWindow.isOpen}
          onClose={closeModalWindow}
          currentRow={currentRow}
          updateTierStatus={updateTierStatus}
          advanceToNexTier={advanceToNexTier}
        />
      );
    case 'approve-diligence':
      return (
        <ApproveDiligence
          isOpen={modalWindow.isOpen}
          onClose={closeModalWindow}
          recordId={recordId}
          currentRow={currentRow}
          updateTierStatus={updateTierStatus}
          updateRecordStatus={updateRecordStatus}
        />
      );
    case 'reject-investment':
      return (
        <RejectInvestment
          isOpen={modalWindow.isOpen}
          onClose={closeModalWindow}
          recordId={recordId}
          currentRow={currentRow}
          updateTierStatus={updateTierStatus}
          updateRecordStatus={updateRecordStatus}
        />
      );
    case 'back-to':
      return <BackTo isOpen={modalWindow.isOpen} onClose={closeModalWindow} currentRow={currentRow} backToPreviousTier={backToPreviousTier} />;
    case 'download-pdf':
      return <DownloadPDF isOpen={modalWindow.isOpen} onClose={closeModalWindow} activeTab={activeTab} recordId={recordId} />;
    case 'relink-field':
      return <RelinkField isOpen={modalWindow.isOpen} onClose={closeModalWindow} currentRow={currentRow as ISectionFields} recordId={recordId} />;
    case 'override-answer':
      return (
        <OverrideAnswer
          isOpen={modalWindow.isOpen}
          onClose={closeModalWindow}
          currentRow={currentRow as ISectionFields}
          openEditPage={openEditPage}
        />
      );
    default:
      return <></>;
  }
};

export default FieldModal;
