import FormattingTooltip from '@/components/basicComponents/tooltip/dollarsTooltip';
import guid from '@/utils/guid';
import styled from 'styled-components';
import { CommitmentForecastValueDto } from '../../../types';

interface TableSumFooterProps {
  tableData: CommitmentForecastValueDto[];
  columnWidth: number;
}

interface CommitmentSummary {
  [year: number]: number;
}

const TableSumFooter = ({ tableData, columnWidth }: TableSumFooterProps) => {
  const dateCommitmentSummary: CommitmentSummary = {};

  const addItemValue = (date: string, allocation: number) => {
    const year = new Date(date).getFullYear();

    if (!dateCommitmentSummary[year]) {
      dateCommitmentSummary[year] = 0;
    }
    dateCommitmentSummary[year] += allocation;
  };

  tableData.forEach((commitment) => {
    commitment.data.forEach((item) => {
      addItemValue(item.date, item.value);
    });
  });

  return (
    <TableSumWrap>
      <TableSumItem width={15}>Total</TableSumItem>
      {Object.values(dateCommitmentSummary).map((value) => (
        <TableSumItem key={guid()} width={columnWidth}>
          <FormattingTooltip zIndex={1000}>{value}</FormattingTooltip>
        </TableSumItem>
      ))}
    </TableSumWrap>
  );
};

const TableSumWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  min-height: 60px;
  background-color: ${({ theme }) => theme.layer[2]};
  border-radius: 8px;
  padding: 0 24px;
`;

const TableSumItem = styled.div<{ width: number }>`
  display: flex;
  justify-content: flex-start;
  width: ${({ width }) => `${width}%`};
  color: ${({ theme }) => theme.font.base};
  font-family: Blinker, serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0;
`;

export default TableSumFooter;
