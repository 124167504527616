import { RejectedBannerIcon } from '@/assets/icons/info-icons/rejectedBanner';
import styled from 'styled-components';

export const OpenInvestmentBanner = () => {
  return (
    <StatusBannerWrapper>
      <RejectedBannerIcon />
      <SeparateStick />
      <StatusWrapper>
        <Status>
          Investment ID and Asset Class are not editable after this step
        </Status>
        <Description>
          This action will make this investment open to client allocations.
          Complete all required fields to complete this action. You will not be
          able to change the Investment ID and asset class after this step.
        </Description>
      </StatusWrapper>
    </StatusBannerWrapper>
  );
};

const StatusBannerWrapper = styled.div`
  background: #ef9208;
  margin: 0 -24px;
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 10px 40px;
`;

const SeparateStick = styled.div`
  width: 1px;
  height: 46px;
  background-color: #f4f5f6;
`;

const StatusWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Status = styled.span`
  color: #f4f5f6;
  font-weight: 700;
  font-size: 16px;
`;

const Description = styled.span`
  color: #f4f5f6;
  font-weight: 400;
  font-size: 13px;
`;
