import { ApolloQueryResult, OperationVariables } from '@apollo/client';
import { useState } from 'react';
import styled, { useTheme } from 'styled-components';

import { ArrowDown } from '@/assets/icons/info-icons/arrowDown';
import FilterSelect from '../../basicComponents/select';
import FormattingTooltip from '../../basicComponents/tooltip/dollarsTooltip';
import { Loader } from '../../loaders/loader';
import { Container, Title } from '../styles';
import { IDashboard } from '../types';
import { IRecommendations } from '../types/recommendations';
import { subtractDate } from '../utils';
import { createListOfRecommendations } from './utils';

const itemsForLastDays = ['Last 7 days', 'Last 30 days', 'Month To Date'];

interface RecommendationsProps {
  data: IRecommendations;
  loading: boolean;
  refetch: (variables?: Partial<OperationVariables> | undefined) => Promise<ApolloQueryResult<IDashboard>>;
}

export const Recommendations = ({ data, loading, refetch }: RecommendationsProps) => {
  const theme = useTheme();

  const [lastDaysSelect, setLastDaysSelect] = useState(itemsForLastDays[1]);

  const changeSelect = (value: string) => {
    const date = new Date();
    const dateToChosenItem = {
      'Last 7 days': subtractDate(date, 7),
      'Last 30 days': subtractDate(date, 30),
      'Month To Date': new Date(date.getFullYear(), date.getMonth(), 1)
    };
    refetch({
      date: dateToChosenItem[value as keyof typeof dateToChosenItem]
    });
    setLastDaysSelect(value);
  };

  return (
    <Container>
      <TitleContainer>
        <Title>Recommendations</Title>
        <Select
          arrow={<ArrowDown fill={theme.font.action} />}
          width={'auto'}
          data={itemsForLastDays}
          selected={lastDaysSelect}
          setSelected={changeSelect}
        />
      </TitleContainer>
      {loading ? (
        <CustomLoader />
      ) : (
        <Cards>
          {createListOfRecommendations(data).map(({ title, amount, icon, notification }) => (
            <Card key={title}>
              <ImageContainer>
                <Notification>{notification}</Notification>
                <img src={icon} alt={'image'} />
              </ImageContainer>
              <CardTitle>{title}</CardTitle>
              <CardAmount>
                <FormattingTooltip noCent>{amount}</FormattingTooltip>
              </CardAmount>
            </Card>
          ))}
        </Cards>
      )}
    </Container>
  );
};

const CustomLoader = styled(Loader)`
  min-height: 230px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 10px;
`;

const Cards = styled.div`
  width: 100%;
  display: flex;
  padding-top: 20px;
  gap: 30px 20px;
  @media (max-width: 1200px) {
    flex-wrap: wrap;
  }
`;
const Card = styled.div`
  width: 23%;
  display: flex;
  align-items: center;
  flex-direction: column;
  @media (max-width: 1200px) {
    width: 47%;
  }
  @media (max-width: 768px) {
    width: 100%;
  }
`;
const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  min-width: 120px;
  min-height: 120px;
  position: relative;
  border-radius: 50%;
`;
const Notification = styled.div`
  position: absolute;
  right: -5px;
  top: -5px;
  border-radius: 14px;
  padding: 1px 15px;
  background: ${({ theme }) => theme.context.light};
  font-weight: 700;
  font-size: 16px;
  color: ${({ theme }) => theme.font.strong};
`;
const CardTitle = styled.span`
  font-family: 'Lato', serif;
  font-weight: 700;
  font-size: 23px;
  color: ${({ theme }) => theme.font.weak};
  padding-top: 10px;
`;

const CardAmount = styled.span`
  font-family: 'Lato', serif;
  font-weight: 800;
  font-size: 19px;
  color: ${({ theme }) => theme.font.strong};
  padding-top: 15px;
`;

const Select = styled(FilterSelect)`
  background: transparent;
  border: none;
  padding: 6px 0 0;
  justify-content: flex-start;

  svg {
    margin: 0 6px;
  }

  #titleSelect {
    font-family: 'Lato', serif;
    font-size: 16px;
    font-weight: 700;
    color: ${({ theme }) => theme.font.action};
    white-space: normal;
  }

  #dropDownSelect {
    top: 30px;
  }
`;
