import { NavHide } from '@/assets/icons/navHide';
import { NavOpen } from '@/assets/icons/navOpen';
import styled, { useTheme } from 'styled-components';

interface ControlButtonProps {
  icon: boolean;
  visible: boolean;
  onClick: () => void;
}

const ControlButton = ({ visible, icon, onClick }: ControlButtonProps) => {
  const theme = useTheme();

  return (
    <ControlButtonWrap visible={visible} onClick={onClick}>
      {icon ? <NavHide fill={theme.border.base} /> : <NavOpen fill={theme.border.base} />}
    </ControlButtonWrap>
  );
};

const ControlButtonWrap = styled.div<{ visible: boolean }>`
  cursor: ${({ visible }) => (visible ? 'pointer' : 'default')};
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.layer[2]};
  border-radius: 2px;
  padding: 8px 2px;
  max-height: 12px;
  transition: 0.6s;
`;

export default ControlButton;
