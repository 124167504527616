import FormattingTooltip from '@/components/basicComponents/tooltip/dollarsTooltip';
import { MobileRow, MobileRowTitle, MobileRowValue, MobileTableRowWrap } from '@/components/table/mobileTable/mobileTable';
import styled from 'styled-components';
import { IInvestmentEntityByAdvisoryFirm } from '../../types';
import { advisorColumns } from '../constatnts';

interface MobileSumFooterProps {
  listInvestmentEntityByAdvisoryFirm: IInvestmentEntityByAdvisoryFirm[];
}

const MobileSumFooterAdvisor = ({ listInvestmentEntityByAdvisoryFirm }: MobileSumFooterProps) => {
  const total = listInvestmentEntityByAdvisoryFirm.reduce<{
    estimatedCommitment: number;
    taxableEstimate: number;
    exemptEstimate: number;
  }>(
    (sum, value: IInvestmentEntityByAdvisoryFirm) => {
      return {
        estimatedCommitment: (sum.estimatedCommitment += Number(value.estimatedCommitment)),
        taxableEstimate: (sum.taxableEstimate += Number(value.estimatedTaxableCommitment)),
        exemptEstimate: (sum.exemptEstimate += Number(value.estimatedExemptCommitment))
      };
    },
    { estimatedCommitment: 0, taxableEstimate: 0, exemptEstimate: 0 }
  );

  return (
    <>
      <MobileSumFooterWrap>
        <MobileRow>
          <MobileRowTitle>Total</MobileRowTitle>
        </MobileRow>

        <MobileRow>
          <MobileRowTitle>{advisorColumns[1].title}</MobileRowTitle>
          <MobileRowValue>
            <FormattingTooltip zIndex={1000}>{total.taxableEstimate}</FormattingTooltip>
          </MobileRowValue>
        </MobileRow>

        <MobileRow>
          <MobileRowTitle>{advisorColumns[2].title}</MobileRowTitle>
          <MobileRowValue>
            <FormattingTooltip zIndex={1000}>{total.exemptEstimate}</FormattingTooltip>
          </MobileRowValue>
        </MobileRow>

        <MobileRow>
          <MobileRowTitle>{advisorColumns[3].title}</MobileRowTitle>
          <MobileRowValue>
            <FormattingTooltip zIndex={1000}>{total.estimatedCommitment}</FormattingTooltip>
          </MobileRowValue>
        </MobileRow>
      </MobileSumFooterWrap>
    </>
  );
};

const MobileSumFooterWrap = styled(MobileTableRowWrap)`
  margin-top: 6px;
  background-color: ${({ theme }) => theme.layer[2]};
`;

export default MobileSumFooterAdvisor;
