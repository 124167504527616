import { DocumentIcon } from '@/assets/static/icons/document';
import RadioButton from '@/components/basicComponents/radioButton';
import { RadioButtonContainer } from '@/components/dealRegistry-page/modals/aiSuggestions';
import ModalWrappedContent from '@/components/fat-basicComponents/modal/modalWrappedContent';
import { ChangeEvent, Dispatch, SetStateAction } from 'react';
import styled, { useTheme } from 'styled-components';
import { IFile } from '../dueDiligenceRecord';

interface AiSuggestionsProps {
  onClose: () => void;
  isOpen: boolean;
  files: IFile[];
  setSelectedFile: Dispatch<SetStateAction<string>>;
  selectedFile: string;
  handleGenerateAnswers: () => void;
}

const AiSuggestions = ({ onClose, isOpen, files, setSelectedFile, selectedFile, handleGenerateAnswers }: AiSuggestionsProps) => {
  const theme = useTheme();

  const handleRadioChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSelectedFile(event.target.value);
  };

  return (
    <ModalWrappedContent
      isOpen={isOpen}
      onClose={onClose}
      confirmButton={{
        name: 'Confirm',
        onClick: handleGenerateAnswers,
        disabled: !selectedFile
      }}
    >
      <ModalContainer>
        <ModalTitel>Generate AI Suggestions</ModalTitel>
        <ModalContentWrap>
          <ModalDescription>
            The AI Assistant will suggest answers to the due diligence questions based on this deal’s source material. View this deal on the Due
            Diligence Page to review the suggestions once the process is finished.
          </ModalDescription>
          <ModalDescription>Documents selected for AI Assistant:</ModalDescription>
          <UploadedDocumnts>
            {files.map((file, index) => (
              <UploadedDocumentItem key={file.name + index}>
                <RadioButtonContainer>
                  <RadioButton
                    name="option"
                    radioButtonText={file.name}
                    selectedOption={selectedFile}
                    handleRadioChange={handleRadioChange}
                    icon={<DocumentIcon fill={theme.font.base} />}
                  />
                </RadioButtonContainer>
              </UploadedDocumentItem>
            ))}
          </UploadedDocumnts>
        </ModalContentWrap>
      </ModalContainer>
    </ModalWrappedContent>
  );
};

export default AiSuggestions;

const ModalContainer = styled.div`
  font-weight: 400;
  padding: 50px 0;
`;

const ModalTitel = styled.div`
  font-size: 33px;
  text-align: center;
  color: ${({ theme }) => theme.font.strong};
`;

const ModalDescription = styled.div`
  font-size: 19px;
  line-height: 27px;
  text-align: center;
  color: ${({ theme }) => theme.font.base};
`;

const ModalContentWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const UploadedDocumnts = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 5px 0 0 79.5px;
  max-height: 100px;
  overflow-y: auto;
`;

const UploadedDocumentItem = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  font-weight: 600;
  font-size: 16px;
  color: ${({ theme }) => theme.font.base};
  cursor: pointer;
`;
