import { Dispatch, SetStateAction, useState } from 'react';

import FilterSearch from '@/components/basicComponents/input';
import ModalWrappedContent from '@/components/basicComponents/modal/modalWrappedContent';
import { IListData } from '@/components/fat-dealPipeline-page/fat-modify/types';
import styled from 'styled-components';

interface CreateTagProps {
  onClose: () => void;
  isOpen: boolean;
  setTags: Dispatch<SetStateAction<IListData>>;
}

const CreateTag = ({ onClose, isOpen, setTags }: CreateTagProps) => {
  const [value, setValue] = useState('');

  const saveValue = () => {
    setTags((prevTags) => {
      if (!prevTags.list.includes(value)) {
        return { ...prevTags, list: [...prevTags.list, value] };
      }
      return prevTags;
    });

    setTags((prevTags) => {
      if (!prevTags.list.length) {
        return {
          ...prevTags,
          chosen: [...new Set([...prevTags.chosen, value])]
        };
      }

      let duplicateTag = '';

      const updatedTags = prevTags.chosen.map((tag) => {
        if (tag === 'None') {
          return value;
        }
        if (tag === value) {
          duplicateTag = value;
          return value;
        }
        return tag;
      });

      return { ...prevTags, chosen: updatedTags };
    });

    setTimeout(() => {
      setTags((prevTags) => {
        return {
          ...prevTags,
          duplicate: '',
          chosen: [...new Set(prevTags.chosen)]
        };
      });
    }, 1000);

    return onClose();
  };

  return (
    <ModalWrappedContent
      isOpen={isOpen}
      onClose={onClose}
      confirmButton={{
        name: 'save',
        onClick: saveValue,
        disabled: !value.length
      }}
      showRequiredFields
    >
      <ModalContainer>
        <ModalTitel>Create New Tag</ModalTitel>
        <ModalDescription>Use tags to call out common attributes on different investments.</ModalDescription>
      </ModalContainer>
      <ManagerInputWrap>
        <CustomManagerInput
          label="Tag"
          placeholder="Provide a tag"
          type="text"
          value={value}
          onChange={setValue}
          showSearchBtn={false}
          width="100%"
          required
          autoFocus
        />
      </ManagerInputWrap>
    </ModalWrappedContent>
  );
};

export default CreateTag;

const ModalContainer = styled.div`
  font-weight: 400;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const ModalTitel = styled.div`
  font-size: 33px;
  text-align: center;
  color: #2e2e2e;
`;

const ModalDescription = styled.div`
  font-size: 19px;
  line-height: 27px;
  text-align: center;
  color: #373f4e;
`;

const CustomManagerInput = styled(FilterSearch)`
  font-weight: 300;
  font-size: 19px;
  padding: 12px;
  border-radius: 5px;
  color: #373f4e;
`;

const ManagerInputWrap = styled.div`
  padding-top: 35px;
`;
