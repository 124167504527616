import { CommittedIcon } from '@/assets/icons/investment-status/committed';
import { RejectedIcon } from '@/assets/icons/investment-status/rejected';
import { TerminatedIcon } from '@/assets/icons/investment-status/treminated';
import { PreAllocationStatusType } from '@/components/allocations-page/types';
import BasicTooltip from '@/components/basicComponents/tooltip';
import { formatPrice } from '@/utils/currency';
import { format } from 'date-fns';
import { useMemo } from 'react';

import styled from 'styled-components';

type PrevStatusType = {
  data: PreAllocationStatusType;
  amount: number;
};

const PrevStatus = ({ data, amount }: PrevStatusType) => {
  const windowWidth = window.innerWidth;

  const getTranslateX = useMemo(() => {
    if (windowWidth > 1000 && windowWidth < 1230) {
      return -60;
    }
    if (windowWidth > 800 && windowWidth < 999) {
      return -70;
    }
    return -50;
  }, [windowWidth]);

  const getIcon = () => {
    if (data.alreadyCommitted) {
      return <CommittedIcon />;
    } else if (data.internalReviewRejections) {
      return <RejectedIcon />;
    } else {
      return <TerminatedIcon />;
    }
  };

  const getTooltipContent = () => {
    if (data.alreadyCommitted) {
      return (
        <TooltipContent>
          <TooltipRow>Committed: {formatPrice(amount)}</TooltipRow>
          {data.committedAt && (
            <TooltipRow>
              {format(new Date(data.committedAt), 'LLL d, yyyy').toUpperCase()}
            </TooltipRow>
          )}{' '}
        </TooltipContent>
      );
    } else if (data.internalReviewRejections) {
      return (
        <TooltipContent>
          <TooltipRow>Internal Review Rejected</TooltipRow>
          {data.internalReviewRejectionAt && (
            <TooltipRow>
              {format(
                new Date(data.internalReviewRejectionAt),
                'LLL d, yyyy'
              ).toUpperCase()}
            </TooltipRow>
          )}
        </TooltipContent>
      );
    } else if (data.terminated) {
      return (
        <TooltipContent>
          <TooltipRow>Terminated</TooltipRow>
          {data.terminatedAt && (
            <TooltipRow>
              {format(new Date(data.terminatedAt), 'LLL d, yyyy').toUpperCase()}
            </TooltipRow>
          )}{' '}
        </TooltipContent>
      );
    } else {
      return <></>;
    }
  };

  return data ? (
    <PrevStatusWrap>
      {getTooltipContent() ? (
        <BasicTooltip
          zIndex={20}
          translateX={getTranslateX}
          tooltipContent={getTooltipContent()}
        >
          {getIcon()}
        </BasicTooltip>
      ) : (
        <></>
      )}
    </PrevStatusWrap>
  ) : (
    <></>
  );
};

const TooltipRow = styled.div`
  display: flex;
`;

const TooltipContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const PrevStatusWrap = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  position: relative;
  top: 3px;
`;

export default PrevStatus;
