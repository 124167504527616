import { decimalToPercentage } from '@/components/dueDiligence-page/dueDiligenceRecord/utils';
import { formatCash } from '@/utils/formatCash';
import { format } from 'date-fns';
import { extractAndFormatDate } from '../edit-pages/dateContent';
import { extractAmount } from '../edit-pages/dollarContent';
import { extractNumbersFromString } from '../edit-pages/numberContent';
import { extractPercentage } from '../edit-pages/percentageContent';

export const aiFieldValueMu = (type: string, aiAnswer: string) => {
  switch (type) {
    case 'number':
      return extractNumbersFromString(aiAnswer) ? `${extractNumbersFromString(aiAnswer)} | ${aiAnswer}` : aiAnswer;
    case 'dollar':
      return extractAmount(aiAnswer) ? `${formatCash(+extractAmount(aiAnswer))} | ${aiAnswer}` : aiAnswer;
    case 'percentage':
      return extractPercentage(aiAnswer) ? `${decimalToPercentage(extractPercentage(aiAnswer))} | ${aiAnswer}` : aiAnswer;
    case 'date':
      return extractAndFormatDate(aiAnswer) ? `${format(new Date(extractAndFormatDate(aiAnswer)), 'LLL d, yyyy')} | ${aiAnswer}` : aiAnswer;
    default:
      return aiAnswer;
  }
};

export const fieldValueMu = (type: string, value: string, commentary: string): string => {
  switch (type) {
    case 'long_form_content':
      return value;
    case 'short_text':
      return value;
    case 'number':
      return value && commentary ? `${value} | ${commentary}` : (value && value) || (commentary && commentary);
    case 'dollar':
      return value && commentary ? `${formatCash(+value)} | ${commentary}` : (value && formatCash(+value)) || (commentary && commentary);
    case 'percentage':
      return value && commentary
        ? `${decimalToPercentage(value as string)} | ${commentary}`
        : (value && decimalToPercentage(value as string)) || (commentary && commentary);
    case 'date':
      return value && commentary
        ? `${format(new Date(value), 'LLL d, yyyy')} | ${commentary}`
        : (value && format(new Date(value), 'LLL d, yyyy')) || (commentary && commentary);
    default:
      return '';
  }
};
