import { useQuery } from '@apollo/client';
import { useNavigate, useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useResponsive } from '@/hooks/use-responsive';
import { MainWrap, PaddingWrap } from '@/styles/common';
import styled from 'styled-components';
import Button from '../basicComponents/button';
import Header from '../header';
import { settingItems } from './constants';
import { GET_SETTINGS } from './queries';
import { DealFunnelEditor } from './settings-pages/dealFunnelEditor';
import { DueDiligenceImportData } from './settings-pages/dealFunnelEditor/dueDiligenceUploads';
import { ExportData } from './settings-pages/exportData';
import { FirmSettings } from './settings-pages/firmSettings';
import { ImportData } from './settings-pages/importData';

export const Settings = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const { isMobile } = useResponsive();

  const [windowType, setWindowType] = useState({
    isOpen: false,
    type: 'default'
  });
  const [moduleConfigFilteredItems, setModuleConfigFilteredItems] =
    useState<typeof settingItems>();

  useEffect(() => {
    if (!queryParams.get('action')) {
      setWindowType({ isOpen: false, type: 'default' });
      return;
    }
    if (queryParams.get('action')) {
      setWindowType({
        isOpen: false,
        type: queryParams.get('action') as string
      });
      return;
    }
  }, [queryParams.get('action')]);

  const handleRedirect = (path: string) => {
    if (path === 'managerUpdates') {
      // router.push(`/managerUpdates`, undefined, { shallow: true });
      navigate('/managerUpdates', { state: undefined, replace: true });
      return;
    }
    // router.push(`/settings?action=${path}`, undefined, { shallow: true });
    navigate(`/settings?action=${path}`, { state: undefined, replace: true });
    setWindowType({ isOpen: true, type: path });
  };

  const handleClose = () => {
    // router.push(`/settings`, undefined, { shallow: true });
    navigate(`/settings`, { state: undefined, replace: true });
    setWindowType({ isOpen: false, type: 'default' });
  };

  useEffect(() => {
    setModuleConfigFilteredItems([
      {
        title: 'Firm Settings',
        path: 'firmSettings'
      },
      {
        title: 'Due Diligence',
        path: 'dueDiligence'
      },
      {
        title: 'Import Data',
        path: 'importData'
      },
      {
        title: 'Export Data',
        path: 'exportData'
      }
    ]);
  }, []);

  const { data: appSettingsData } = useQuery(GET_SETTINGS, {
    variables: {
      type: 'appSetting'
    },
    onCompleted: (data) => {
      const moduleConfig = JSON.parse(appSettingsData.Settings[0].value);
      setModuleConfigFilteredItems(
        settingItems.filter((setting) => {
          if (
            moduleConfig.DDR &&
            ['firmSettings', 'dueDiligence', 'managerUpdates'].includes(
              setting.path
            )
          ) {
            return setting;
          }

          if (
            moduleConfig.Allocator &&
            ['firmSettings', 'importData', 'exportData'].includes(setting.path)
          ) {
            return setting;
          }
          return undefined;
        })
      );
    }
  });

  return moduleConfigFilteredItems ? (
    <>
      {windowType.type === 'importData' && (
        <ImportData handleClose={handleClose} />
      )}
      {windowType.type === 'exportData' && (
        <ExportData handleClose={handleClose} />
      )}
      {windowType.type === 'firmSettings' && (
        <FirmSettings handleClose={handleClose} />
      )}
      {windowType.type === 'dueDiligence' && (
        <DealFunnelEditor handleClose={handleClose} />
      )}
      {windowType.type === 'dueDiligenceImportData' && (
        <DueDiligenceImportData handleClose={handleClose} />
      )}
      {windowType.type === 'default' && (
        <MainWrap>
          <Header />
          <PaddingWrap>
            <SettingsWrapper>
              {moduleConfigFilteredItems.map((item, index) => (
                <SettingsItem key={item.path + index}>
                  <ItemTitle
                    id="itemTitle"
                    onClick={() => handleRedirect(item.path)}
                  >
                    {item.title}
                  </ItemTitle>
                  <OpenButton
                    id="openButton"
                    onClick={() => handleRedirect(item.path)}
                  >
                    Open
                  </OpenButton>
                </SettingsItem>
              ))}
            </SettingsWrapper>
          </PaddingWrap>
        </MainWrap>
      )}
    </>
  ) : (
    <></>
  );
};

const SettingsWrapper = styled.div`
  margin-top: 30px;
  background: #ffffff;
  padding: 40px 60px;
  border: 1px solid #c7cbd2;
  border-radius: 0px 0px 10px 10px;
`;

const SettingsItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 21px 14px;
  border-bottom: 1px solid #c7cbd2;

  &:hover #openButton {
    opacity: 1;
    transition: background-color 100ms ease;
  }
  &:hover #itemTitle {
    color: #4587ec;
  }
  &:hover {
    background-color: #f0f1f3;
    transition: background-color 100ms ease;
  }
`;

const ItemTitle = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #2e2e2e;
  cursor: pointer;
`;

const OpenButton = styled(Button)`
  width: 70px;
  height: 32px;
  font-weight: 600;
  font-size: 13px;
  color: #f4f5f6;
  background: #1c488a;
  margin-right: 63px;
  opacity: 0;
`;
