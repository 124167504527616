import { gql } from '@apollo/client';

export const GET_INVESTOR_QUERY = gql`
  query getInvestor($data: getInvestorDto!) {
    getInvestor(data: $data) {
      name
      investmentCount
      totalValue
      totalCommitments
      totalCapacity
      economicExposure
      alwaysShow
      sectorsToAvoid
      onHold
      impact
      notes {
        id
        title
        notes
        notesDate
      }
      tenant {
        id
        name
      }
      advisor {
        id
        firstName
        lastName
        initials
      }
      legalEntities {
        id
        ignoreAllocationTargets
      }
      legalEntitiesPaginatedList {
        total
        page
        perPage
        lastPage
        data {
          id
          entityName
          totalEntityValue
          privatePercentage
          overCommitRatio
          capacity
          economicExposure
          overallCapacity
          privateCreditCapacity
          privateEquityCapacity
          privateRealAssetCapacity
          onHold
          sectorsToAvoid
          ignoreBiteSize
          ignoreAllocationTargets
        }
      }
    }
  }
`;

export const GET_INVESTOR_QUERY_BASIC = gql`
  query getInvestor($data: getInvestorDto!) {
    getInvestor(data: $data) {
      name
      tenant {
        id
        name
      }
      legalEntities {
        id
        entityName
        family {
          id
          name
        }
      }
    }
  }
`;

export const updateInvestorMutation = gql`
  mutation updateInvestor($data: InvestorUpdateDto!) {
    updateInvestor(data: $data) {
      id
    }
  }
`;

export const LIST_USERS_QUERY = gql`
  query listUsers($filters: ListUsersFilterDto!) {
    listUsers(filters: $filters) {
      id
      firstName
      lastName
    }
  }
`;

export const deleteLegalEntityMutation = gql`
  mutation DeleteLegalEntity($id: String!) {
    deleteLegalEntity(id: $id)
  }
`;
