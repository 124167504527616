import TableHeader from '@/components/table/fat-tableHeader';
import { ISort } from '@/components/table/types';
import { useResponsive } from '@/hooks/use-responsive';
import { useRef } from 'react';
import styled from 'styled-components';

import { Loader } from '@/components/loaders/loader';
import { TableBodyWrapper, TableWrapper } from '@/components/table/styles';
import useStickyFooter from '@/hooks/useStickyFooter';
import { commitmentsTablecolumns } from '../constatnts';
import { CommitmentsType } from '../types';
import MobileTable from './mobileTable';
import TableBody from './tableBody';
import TableSumFooter from './tableSumFooter';

interface TableCommitmentsProps {
  investorCommitmentRows: CommitmentsType[] | undefined;
  sort: ISort;
  setSort: (value: ISort) => void;
  refetch: () => void;
  loading: boolean;
  openOpportunityDetailsPage: (id: string) => void;
}

const TableCommitments = ({ investorCommitmentRows, sort, setSort, refetch, loading, openOpportunityDetailsPage }: TableCommitmentsProps) => {
  const { isMobile, isTablet } = useResponsive();

  const containerRef = useRef<HTMLDivElement | null>(null);
  const footerRef = useRef<HTMLDivElement | null>(null);

  useStickyFooter(containerRef, footerRef);

  return (
    <>
      {isMobile ? (
        <>
          {loading ? (
            <Loader />
          ) : (
            <MobileTable investorCommitmentRows={investorCommitmentRows} loading={loading} openOpportunityDetailsPage={openOpportunityDetailsPage} />
          )}
        </>
      ) : (
        <TableWrapper padding="0">
          <CustomTableHeader isTablet={isTablet} refetch={refetch} columns={commitmentsTablecolumns} savedSort={sort} savedSetSort={setSort} />
          <TableBodyWrapper>
            {loading ? (
              <Loader />
            ) : investorCommitmentRows ? (
              <>
                <TableBody investorCommitmentRows={investorCommitmentRows} openOpportunityDetailsPage={openOpportunityDetailsPage} />
                <TableSumFooterWrapper ref={footerRef}>
                  <TableSumFooter investorCommitmentRows={investorCommitmentRows} />
                </TableSumFooterWrapper>
                <div ref={containerRef}></div>
              </>
            ) : null}
          </TableBodyWrapper>
        </TableWrapper>
      )}
    </>
  );
};

export default TableCommitments;

const CustomTableHeader = styled(TableHeader)<{ isTablet: boolean }>`
  position: sticky;
  top: ${({ isTablet }) => (isTablet ? '50px' : '0')};
  z-index: 8;
  padding-top: 0;
`;

const TableSumFooterWrapper = styled.div`
  &.sticky {
    position: sticky;
    bottom: 0;
    z-index: 9;
  }
`;
