import { ApprovedIcon } from '@/assets/icons/info-icons/approved';
import { InfoIcon } from '@/assets/icons/info-icons/info';
import BasicTooltip from '@/components/basicComponents/tooltip';
import Button from '@/components/fat-basicComponents/button';
import Header from '@/components/fat-header';
import { GoBackButton } from '@/components/fat-header/goBackButton';
import { PageTitle } from '@/components/fat-header/pageTitle';
import { useResponsive } from '@/hooks/use-responsive';
import { MainWrap } from '@/styles/common';
import guid from '@/utils/guid';
import React from 'react';
import styled, { useTheme } from 'styled-components';
import { ButtonsControl, ImportText, ImportWrapper } from '../../styles';
import { IInvestorComparison } from '../types';

interface InvestorImportDetailProps {
  currentRow: IInvestorComparison | null;
  handleClose: () => void;
  backToTitle: string;
}

export const InvestorImportDetail = ({ currentRow, handleClose, backToTitle }: InvestorImportDetailProps) => {
  const { isMobile, isTablet } = useResponsive();
  const theme = useTheme();

  if (!currentRow) {
    return <></>;
  }

  return (
    <MainWrap>
      <Header modalControl={<GoBackButton handleClose={handleClose} backToTitle={backToTitle} />} />
      <PageTitle title={currentRow.investorName} />
      <PaddingWrap>
        <ImportWrapper>
          <TitleWrap>
            {currentRow.advisorConflict === 'error' ? (
              <>
                <InfoIcon width={30} height={30} fill={theme.context.warning} />
                <ImportText fontWeight="700">Multiple Client Advisors</ImportText>
              </>
            ) : (
              <>
                <ApprovedIcon width={30} height={30} fill={theme.context.success} />
                <ImportText fontWeight="700" color={theme.context.success}>
                  No Conflicts
                </ImportText>
              </>
            )}
          </TitleWrap>
          <DetailWrap>
            <ColumnsWrap>
              <Column isMobile={isMobile}>
                <ColumnTitle>ENTITY</ColumnTitle>
              </Column>
              <Column isMobile={isMobile}>
                <ColumnTitle>CLIENT ADVISOR OWNER</ColumnTitle>
              </Column>
            </ColumnsWrap>
            {currentRow.entityAdvisors.map((advisor) => (
              <React.Fragment key={guid()}>
                <ColumnsWrap>
                  <Column isMobile={isMobile}>
                    <RowWrap>
                      <RowValue>
                        <ImportText fontWeight="400" fontSize="16px">
                          {advisor.entityName}
                        </ImportText>
                        {advisor?.tooltip && (
                          <BasicTooltip tooltipContent={advisor?.tooltip} tooltipWidth={advisor?.tooltip?.length > 45 ? 300 : 0}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                              <path
                                d="M13.7561 16.1737L13.5706 16.928C13.0143 17.1459 12.5704 17.3107 12.2388 17.4224C11.9073 17.5398 11.5223 17.5984 11.084 17.5984C10.4097 17.5984 9.88429 17.4364 9.50779 17.1123C9.1369 16.7827 8.95146 16.3664 8.95146 15.8636C8.95146 15.668 8.96551 15.4697 8.99361 15.2685C9.02171 15.0618 9.06666 14.8299 9.12848 14.5729L9.81966 12.1257C9.88148 11.891 9.93205 11.6703 9.97139 11.4636C10.0163 11.2513 10.0388 11.0585 10.0388 10.8853C10.0388 10.5724 9.9742 10.3545 9.84495 10.2316C9.7157 10.1087 9.46845 10.0472 9.10319 10.0472C8.92337 10.0472 8.73793 10.0751 8.54687 10.131C8.36143 10.1869 8.20127 10.24 8.06641 10.2902L8.25185 9.53596C8.70702 9.35158 9.14252 9.19514 9.55836 9.06663C9.9742 8.93253 10.3676 8.86549 10.7384 8.86549C11.4071 8.86549 11.9213 9.02752 12.281 9.35158C12.6462 9.67006 12.8289 10.0863 12.8289 10.6003C12.8289 10.7065 12.8148 10.8937 12.7867 11.1619C12.7642 11.4301 12.7193 11.6759 12.6518 11.8994L11.9607 14.3382C11.9045 14.5338 11.8539 14.7573 11.8089 15.0087C11.764 15.2602 11.7415 15.4501 11.7415 15.5786C11.7415 15.9027 11.8146 16.1234 11.9607 16.2407C12.1068 16.3581 12.3596 16.4167 12.7193 16.4167C12.8879 16.4167 13.0789 16.3888 13.2925 16.3329C13.506 16.2715 13.6605 16.2184 13.7561 16.1737ZM13.9331 5.94053C13.9331 6.36517 13.7701 6.72834 13.4442 7.03006C13.1239 7.32618 12.7361 7.47425 12.281 7.47425C11.8258 7.47425 11.4352 7.32618 11.1093 7.03006C10.7834 6.72834 10.6204 6.36517 10.6204 5.94053C10.6204 5.5159 10.7834 5.15272 11.1093 4.85101C11.4352 4.54929 11.8258 4.39844 12.281 4.39844C12.7361 4.39844 13.1239 4.54929 13.4442 4.85101C13.7701 5.15272 13.9331 5.5159 13.9331 5.94053Z"
                                fill={theme.context.error}
                              />
                              <path
                                d="M11.0066 1.97893e-06C8.83043 -0.00130306 6.70281 0.642894 4.89286 1.85109C3.08291 3.05928 1.67199 4.77719 0.838607 6.78745C0.00522571 8.7977 -0.213187 11.01 0.21104 13.1444C0.635267 15.2788 1.68303 17.2394 3.22181 18.7782C4.76058 20.3169 6.72121 21.3647 8.85561 21.789C10.99 22.2132 13.2023 21.9948 15.2126 21.1614C17.2228 20.328 18.9407 18.9171 20.1489 17.1072C21.3571 15.2972 22.0013 13.1695 22 10.9934C21.9982 8.0783 20.8394 5.28311 18.7782 3.22183C16.7169 1.16055 13.9217 0.00175016 11.0066 1.97893e-06ZM11.0066 19.796C9.26531 19.7973 7.56278 19.2822 6.11432 18.3157C4.66587 17.3493 3.53658 15.975 2.86932 14.3666C2.20206 12.7582 2.02677 10.9881 2.36568 9.28015C2.70458 7.57217 3.54244 6.00306 4.77324 4.77133C6.00405 3.5396 7.57255 2.70059 9.28027 2.36041C10.988 2.02022 12.7582 2.19414 14.3671 2.8602C15.976 3.52626 17.3511 4.65452 18.3187 6.10224C19.2862 7.54997 19.8026 9.25212 19.8026 10.9934C19.7991 13.3258 18.8715 15.5618 17.2229 17.2116C15.5743 18.8615 13.339 19.7908 11.0066 19.796Z"
                                fill={theme.context.error}
                              />
                            </svg>
                          </BasicTooltip>
                        )}
                      </RowValue>
                    </RowWrap>
                  </Column>
                  <Column isMobile={isMobile}>
                    <RowWrap>
                      <RowValue
                        bgColor={
                          currentRow.advisorConflict === 'error' ? '#FFEAEC' : currentRow.advisorConflict === 'updated' ? '#EFFFEA' : '#F4F5F6'
                        }
                      >
                        <ImportText fontWeight="400" fontSize="16px">
                          {advisor.importEmail || 'Not Set'}
                        </ImportText>
                      </RowValue>
                    </RowWrap>
                  </Column>
                </ColumnsWrap>
                <DividerWrapper>
                  <Divider />
                  <Divider />
                </DividerWrapper>
              </React.Fragment>
            ))}
          </DetailWrap>
          <ButtonsControl>
            <Button size="lg" onClick={handleClose}>
              Done
            </Button>
          </ButtonsControl>
        </ImportWrapper>
      </PaddingWrap>
    </MainWrap>
  );
};

const PaddingWrap = styled.div`
  padding-left: 16px;
  padding-right: 16px;
  @media (min-width: 600px) {
    padding-left: 50px;
    padding-right: 50px;
  }
`;

const TitleWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  padding-bottom: 25px;
`;

const DetailWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const ColumnsWrap = styled.div`
  display: flex;
  gap: 10px;
`;

const Column = styled.div<{ isMobile: boolean }>`
  width: ${({ isMobile }) => (isMobile ? '100%' : '50%')};
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 5px;
`;

const ColumnTitle = styled.div`
  background: ${({ theme }) => theme.layer[2]};
  padding: 10px 30px;
  color: ${({ theme }) => theme.font.base};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22.4px;
`;

const RowWrap = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 30px;
  gap: 10px;
`;

const RowValue = styled.div<{ bgColor?: string }>`
  display: flex;
  align-items: center;
  gap: 10px;
  background: ${({ bgColor }) => bgColor ?? 'transparent'};
  line-height: 18.2px;
  padding: 8px 20px;
  border-radius: 4px;
`;

const DividerWrapper = styled.div`
  display: flex;
  width: 100%;
`;

const Divider = styled.div`
  background: ${({ theme }) => theme.border.base};
  height: 1px;
  margin: 0 30px;
  width: 50%;
`;
