import { AiAssistantIcon } from '@/assets/static/icons/ai-assistant';
import Textarea from '@/components/basicComponents/textarea';
import { ChangeEvent, useEffect, useState } from 'react';
import { useTheme } from 'styled-components';
import { ISectionFields } from '../types';
import { AiIconWrap, Answer, AnswerWrap, AutoGeneratedField, CommentaryWrap, FieldInput, FieldName } from './dollarContent';
import { EditWrapper } from './editWrapper';

interface NumberProps {
  handleClose: () => void;
  row: ISectionFields | null;
  updateFieldAnswer: (variables: any) => void;
  investmentId: string | null;
}

export function extractNumbersFromString(input: string) {
  if (!input) return '';

  const regex = /\d+/;
  const match = input.match(regex);

  if (match) {
    return match[0];
  }

  return '';
}

export const NumberPage = ({ handleClose, row, updateFieldAnswer, investmentId }: NumberProps) => {
  const theme = useTheme();

  const [numberValue, setNumberValue] = useState({
    fieldValue: '',
    commentary: ''
  });
  const [isEditMode, setIsEditMode] = useState(true);

  useEffect(() => {
    if (!row) return;

    if (row?.data.value !== null || row?.data.commentary !== null) {
      setIsEditMode(true);
      setNumberValue({
        ...numberValue,
        fieldValue: row?.data.value || numberValue.fieldValue,
        commentary: row?.data.commentary || numberValue.commentary
      });
      return;
    }
    if (row.AI?.answers[0]) {
      setIsEditMode(false);
      setNumberValue({
        ...numberValue,
        fieldValue: extractNumbersFromString(row.AI?.answers[0]),
        commentary: row.AI.answers[0]
      });
      return;
    }
    return () => {
      setNumberValue({ fieldValue: '', commentary: '' });
    };
  }, [row]);

  const saveValue = () => {
    updateFieldAnswer({
      variables: {
        data: {
          id: row.data.id,
          value: isEditMode ? numberValue.fieldValue : null,
          commentary: isEditMode ? numberValue.commentary : null
        }
      },
      onCompleted: () => {
        setNumberValue({ fieldValue: '', commentary: '' });
        handleClose();
      }
    });
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    const isValidInput = /^([0-9]+(\.[0-9]*)?|\.[0-9]+)$/.test(inputValue);

    if (isValidInput || inputValue === '') {
      setNumberValue({ ...numberValue, fieldValue: inputValue });
    }
  };

  const editSuggested = (suggestion: string) => {
    setNumberValue({
      fieldValue: extractNumbersFromString(suggestion),
      commentary: suggestion
    });
    setIsEditMode(true);
  };

  const clearSuggestion = () => {
    setNumberValue({ fieldValue: '', commentary: '' });
    setIsEditMode(true);
  };

  const startWithAISuggestion = () => {
    setNumberValue({
      fieldValue: extractNumbersFromString(row.AI?.answers[0]),
      commentary: row.AI?.answers[0]
    });
    setIsEditMode(false);
  };

  const revertPriorDraft = () => {
    if (row?.data.value || row?.data.commentary) {
      setNumberValue({
        ...numberValue,
        fieldValue: row?.data.value,
        commentary: row?.data.commentary
      });
      setIsEditMode(true);
    }
  };

  if (!row) return <></>;

  return (
    <EditWrapper
      showAiSuggestion={Boolean(row.AI?.answers.length > 0)}
      isEditMode={isEditMode}
      startWithAISuggestion={startWithAISuggestion}
      editSuggested={editSuggested}
      clearSuggestion={clearSuggestion}
      revertPriorDraft={revertPriorDraft}
      showRevertPriorDraft={
        (Boolean(row?.data.value) && row?.data.value !== numberValue.fieldValue) ||
        (Boolean(row?.data.commentary) && row?.data.commentary !== numberValue.commentary)
      }
      handleClose={handleClose}
      row={row}
      investmentId={investmentId}
      confirmButton={{ name: 'Save', onClick: saveValue }}
    >
      <div>
        {isEditMode ? (
          <>
            <FieldName>{row.name}</FieldName>
            <FieldInput isBackground={!!numberValue.fieldValue} type={'text'} value={numberValue.fieldValue} onChange={handleInputChange} autoFocus />
          </>
        ) : (
          <>
            <FieldName>{row.name}</FieldName>
            <AutoGeneratedField>
              <AiIconWrap>
                <AiAssistantIcon width={28} height={28} fill={theme.font.weak} />
              </AiIconWrap>
              <div>
                <span style={{ fontWeight: 700 }}>Suggested {row.AI?.citations.length >= 2 && `(${row.AI?.citations.length - 1} more)`}:</span>{' '}
                {numberValue.fieldValue && numberValue.fieldValue}
              </div>
            </AutoGeneratedField>
          </>
        )}
      </div>
      <CommentaryWrap>
        <FieldName>Commentary</FieldName>
        {isEditMode ? (
          <Textarea
            value={numberValue.commentary}
            textareaHeight="80px"
            isValidValue={!!numberValue.commentary}
            maxLength={250}
            setValue={(value) =>
              setNumberValue({
                ...numberValue,
                commentary: value
              })
            }
          />
        ) : (
          <AnswerWrap>
            <AiIconWrap>
              <AiAssistantIcon width={28} height={28} fill={theme.font.weak} />
            </AiIconWrap>
            <Answer>
              <span style={{ fontWeight: 700 }}>Suggested {row.AI?.citations.length >= 2 && `(${row.AI?.citations.length - 1} more)`}:</span>{' '}
              {row.AI?.answers[0]}
            </Answer>
          </AnswerWrap>
        )}
      </CommentaryWrap>
    </EditWrapper>
  );
};
