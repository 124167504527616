import { RefObject, useEffect } from 'react';

const useStickyFooter = (containerRef: RefObject<HTMLDivElement | null>, footerRef: RefObject<HTMLDivElement | null>): void => {
  useEffect(() => {
    const handleScroll = () => {
      if (containerRef.current && footerRef.current) {
        const footerTop = containerRef.current.getBoundingClientRect().top;

        if (footerTop > window.innerHeight) {
          footerRef.current.classList.add('sticky');
        } else {
          footerRef.current.classList.remove('sticky');
        }
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [containerRef, footerRef]);
};

export default useStickyFooter;
