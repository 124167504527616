import { useMutation, useQuery } from '@apollo/client';
import ModalWrappedContent from '@/components/basicComponents/modal/modalWrappedContent';
import { IFunnelData } from '@/components/dueDiligence-page/types';
import {
  CREATE_DUE_DILIGENCE_FUNNEL,
  DUE_DILIGENCE_FUNNEL_LIST_SETTINGS_QUERY,
  GET_FUNNEL_QUERY
} from '@/components/settings-page/queries';
import { SORT_ORDER } from '@/components/table/types';
import styled from 'styled-components';
import { formatTiersData } from '../utils';

interface CreateNewVersionProps {
  onClose: () => void;
  isOpen: boolean;
  openFunnelEditor: (
    funnelId: string,
    funnelName: string,
    isViewMode?: boolean,
    isDraft?: boolean
  ) => void;
  currentFunnel: IFunnelData | null;
}

const CreateNewVersion = ({
  onClose,
  isOpen,
  openFunnelEditor,
  currentFunnel
}: CreateNewVersionProps) => {
  if (!currentFunnel) return <></>;

  const { data } = useQuery<{ getDueDiligenceFunnel: IFunnelData }>(
    GET_FUNNEL_QUERY,
    {
      variables: {
        id: currentFunnel.id
      },
      skip: !currentFunnel.id
    }
  );

  const [createFunnel] = useMutation(CREATE_DUE_DILIGENCE_FUNNEL, {
    refetchQueries: () => [
      {
        query: DUE_DILIGENCE_FUNNEL_LIST_SETTINGS_QUERY,
        variables: {
          data: {
            statusFilter: ['PUBLISHED', 'DRAFT_WITHOUT_PUBLISHED'],
            sortDirection: SORT_ORDER.ASC,
            sortBy: 'ORDER'
          },
          archivedFunnelData: {
            sortDirection: SORT_ORDER.ASC,
            sortBy: 'ORDER'
          }
        }
      }
    ]
  });

  const saveValue = () => {
    if (data) {
      const { tiers } = data.getDueDiligenceFunnel;

      const updatedTiers = formatTiersData(tiers);

      createFunnel({
        variables: {
          data: {
            name: currentFunnel.name,
            funnelStaticId: currentFunnel.funnelStaticId,
            externalId: 'test-3',
            status: 'DRAFT',
            tiers: updatedTiers
          }
        },
        onCompleted: (data) => {
          const { id, name } = data.createDueDiligenceFunnel;
          openFunnelEditor(id, name, false, true);
        }
      });
      onClose();
    }
  };

  return (
    <ModalWrappedContent
      isOpen={isOpen}
      onClose={onClose}
      confirmButton={{ name: 'Confirm', onClick: saveValue, disabled: !data }}
    >
      <ModalContainer>
        <ModalTitle>Create New Draft Version</ModalTitle>
        <ModalDescription>
          You cannot edit a published funnel directly. This action will create a
          new draft version that you can modify. Once finished, you can publish
          it to replace the current version. Note that you cannot modify the
          tiers for an existing funnel, only the sections, fields, and
          checklists within each tier.
        </ModalDescription>
      </ModalContainer>
    </ModalWrappedContent>
  );
};

export default CreateNewVersion;

const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const ModalTitle = styled.div`
  font-size: 33px;
  text-align: center;
  color: #2e2e2e;
`;

const ModalDescription = styled.div`
  font-size: 16px;
  line-height: 27px;
  text-align: center;
  color: #373f4e;
  font-weight: 400;
`;
