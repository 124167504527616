import { GroupedIcon } from '@/assets/icons/info-icons/groupedIcon';
import { PlusIcon } from '@/assets/icons/info-icons/plusIcon';
import React, { useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { DrawdownTargetPercentagesWithSubAssetClassesDto } from '../../../../fat-pacingTab/types';

interface TableRowProps {
  rowData: DrawdownTargetPercentagesWithSubAssetClassesDto;
  targetSubAssetClasses: boolean;
}

const TableRow = ({ rowData, targetSubAssetClasses }: TableRowProps) => {
  const theme = useTheme();

  const [showSubAssetClass, setShowSubAssetClass] = useState(false);

  return (
    <>
      <React.Fragment>
        {rowData.portfolioPercentage !== 0 || rowData.privateCapitalPercentage !== 0 ? (
          <>
            <TableRowWrapper>
              <PortfolioWrapper>
                {targetSubAssetClasses && rowData.subAssetClassDrawdownTargets?.length ? (
                  <PlusIconWrapper onClick={() => setShowSubAssetClass(!showSubAssetClass)}>
                    <PlusIcon fill={theme.font.base} />
                  </PlusIconWrapper>
                ) : (
                  <></>
                )}
                <CellKey>{rowData.name}</CellKey>
                <PortfolioPercentageCell>{rowData.portfolioPercentage.toFixed(2)}%</PortfolioPercentageCell>
              </PortfolioWrapper>
              <PrivateCapitalPercentage>
                <CellValue color={theme.font.weak}>{rowData.privateCapitalPercentage.toFixed(2)}%</CellValue>
              </PrivateCapitalPercentage>
            </TableRowWrapper>
            {showSubAssetClass && (
              <>
                {rowData.subAssetClassDrawdownTargets?.map((subAssetClass) => (
                  <TableRowWrapper key={subAssetClass.id}>
                    <PortfolioWrapper>
                      <GroupIconWrapper>
                        <GroupedIcon width={10} height={10} />
                      </GroupIconWrapper>
                      <CellKey>{subAssetClass.name}</CellKey>
                      <PortfolioPercentageCell>{subAssetClass.portfolioPercentage.toFixed(2)}%</PortfolioPercentageCell>
                    </PortfolioWrapper>
                    <PrivateCapitalPercentage>
                      <CellValue color={theme.font.weak}>{subAssetClass.privateCapitalPercentage.toFixed(2)}%</CellValue>
                    </PrivateCapitalPercentage>
                  </TableRowWrapper>
                ))}
              </>
            )}
          </>
        ) : (
          <></>
        )}
      </React.Fragment>
    </>
  );
};

export default TableRow;

const TableRowWrapper = styled.div`
  display: flex;
  gap: 3px;
`;

const PlusIconWrapper = styled.div`
  padding: 0 5px;
  cursor: pointer;
`;

const GroupIconWrapper = styled.div`
  padding: 0 5px;
  position: relative;
  top: -5px;
  left: 5px;
`;

const PortfolioWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 5px 20px;
  width: 75%;
  background-color: ${({ theme }) => theme.layer[1]};
`;

const PrivateCapitalPercentage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 20px;
  width: 25%;
  background-color: ${({ theme }) => theme.layer[1]};
`;

const CellKey = styled.div<{ bold?: boolean; color?: string }>`
  font-weight: ${({ bold }) => (bold ? 600 : 400)};
  color: ${({ color }) => color || 'inherit'};
  word-break: break-all;
`;

const CellValue = styled.div<{ bold?: boolean; color?: string }>`
  font-weight: ${({ bold }) => (bold ? 600 : 400)};
  color: ${({ color }) => color || 'inherit'};
  white-space: nowrap;
`;

const PortfolioPercentageCell = styled(CellValue)`
  margin-left: auto;
`;
