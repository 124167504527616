export const columns = [
  { title: 'Investor', key: 'INVESTOR', width: 15, keySort: 'investor' },
  { title: 'Total Entities', key: 'TOTAL_ENTITIES', width: 15, keySort: 'total entities' },
  { title: 'Entities Added', key: 'ENTITIES_ADDED', width: 15, keySort: 'entities added' },
  { title: 'Entities Removed', key: 'ENTITIES_REMOVED', width: 15, keySort: 'entities removed' },
  { title: 'Advisor Owner', key: 'ADVISOR_OWNER', width: 15, keySort: 'advisor owner' },
  { title: 'Status', key: 'STATUS', width: 15, keySort: 'status' },
  { title: ' ', key: ' ', width: 10 },
];

export const investorHouseholdsStatusSuccess = {
  allMatched: 'No Conflicts',
  continue: 'Please continue to the next step',
  orContinue: 'Or continue to the next step',
  viewAll: 'View All Updates',
};

export const investorHouseholdsStatusError = {
  text: 'Conflicts',
  description: 'Please reconcile the conflicts found below by modifying the import file and uploading it again.',
};
