import FormattingTooltip from '@/components/basicComponents/tooltip/dollarsTooltip';
import { IInvestmentEntity } from '@/components/fat-dealCatalog-page/fat-seekingInterest-page/types';
import NoResults from '@/components/table/noResults';
import { TableRowCell, TableRowWrapper, TextCell } from '@/components/table/styles';
import { format } from 'date-fns';
import { investorColumns } from '../constatnts';

interface TableBodyInvestorProps {
  listInvestmentEntity: IInvestmentEntity[];
}

const TableBodyInvestor = ({ listInvestmentEntity }: TableBodyInvestorProps) => {
  if (!listInvestmentEntity?.length) {
    return <NoResults />;
  }

  return (
    <>
      {listInvestmentEntity.map((row) => (
        <TableRowWrapper key={row.id}>
          <TableRowCell width={investorColumns[0].width}>
            <TextCell>{row.entityName}</TextCell>
          </TableRowCell>
          <TableRowCell width={investorColumns[1].width}>
            <TextCell>{row.family.name}</TextCell>
          </TableRowCell>
          <TableRowCell width={investorColumns[2].width}>
            <TextCell>{row.tenant.name}</TextCell>
          </TableRowCell>
          <TableRowCell width={investorColumns[3].width}>
            <TextCell>
              {!row.investor_interest[0]?.exempt && <FormattingTooltip zIndex={1000}>{row.investor_interest[0]?.amount}</FormattingTooltip>}
            </TextCell>
          </TableRowCell>
          <TableRowCell width={investorColumns[4].width}>
            <TextCell>
              {row.investor_interest[0]?.exempt && <FormattingTooltip zIndex={1000}>{row.investor_interest[0]?.amount}</FormattingTooltip>}
            </TextCell>
          </TableRowCell>
          <TableRowCell width={investorColumns[5].width}>
            <TextCell>
              <FormattingTooltip zIndex={1000}>{row.investor_interest[0]?.amount}</FormattingTooltip>
            </TextCell>
          </TableRowCell>
          <TableRowCell width={investorColumns[6].width}>
            <TextCell>{row.investor_interest[0]?.updatedAt && format(new Date(row.investor_interest[0]?.updatedAt), 'LLL d, yyyy')}</TextCell>
          </TableRowCell>
        </TableRowWrapper>
      ))}
    </>
  );
};

export default TableBodyInvestor;
