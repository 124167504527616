import { gql } from '@apollo/client';

export const CLIENT_DETAILS_QUERY = gql`
  query ClientEntity($id: String!, $includeRecommendation: Boolean, $sort: String, $order: String, $limit: Int, $offset: Int) {
    ClientEntity(id: $id, includeRecommendation: $includeRecommendation, sort: $sort, order: $order, limit: $limit, offset: $offset) {
      limit
      offset
      name
      alwaysShow
      impact
      sectorsToAvoid
      onHold
      notes {
        title
        notes
        notesDate
      }
      advisor {
        initials
      }
      totalClientValue
      committedCapital
      economicExposure
      clientEntities {
        id
        entityName
        totalEntityValue
        privatePercentage
        overCommitRatio
        capacity
        entities {
          id
          entityName
          totalEntityValue
          privatePercentage
          overCommitRatio
          capacity
          moreInfo {
            id
            entityName
            overallCapacity
            privateEquityCapacity
            privateCreditCapacity
            privateRealAssetsCapacity
            onHold
            sectorsToAvoid
          }
        }
        moreInfo {
          id
          entityName
          overallCapacity
          privateEquityCapacity
          privateCreditCapacity
          privateRealAssetsCapacity
          onHold
          sectorsToAvoid
        }
      }
    }
  }
`;

export const CLIENT_DETAILS_QUERY_BASIC = gql`
  query ClientEntity($id: String!) {
    ClientEntity(id: $id) {
      name
      clientEntities {
        id
        entityName
        entities {
          id
          entityName
        }
      }
    }
  }
`;
