import * as React from 'react';

export const RejectedBannerIcon = ({ ...props }: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="33" height="34" viewBox="0 0 33 34" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.2055 20.8992H17.8024V23.5039H15.2055V20.8992ZM15.2055 10.5039H17.8024V18.3023H15.2055V10.5039ZM16.4922 4.00001C13.9214 4.00155 11.4087 4.7653 9.27191 6.1947C7.1351 7.62409 5.47007 9.65493 4.48733 12.0305C3.50459 14.4061 3.24826 17.0197 3.75075 19.5409C4.25324 22.0622 5.49197 24.3778 7.31036 26.1951C9.12875 28.0124 11.4451 29.2498 13.9667 29.7508C16.4882 30.2517 19.1017 29.9938 21.4767 29.0097C23.8516 28.0255 25.8815 26.3592 27.3096 24.2216C28.7378 22.0839 29.5 19.5708 29.5 17C29.501 15.2919 29.1652 13.6003 28.5118 12.0221C27.8583 10.4439 26.9001 9.01002 25.6919 7.80255C24.4837 6.59509 23.0493 5.63773 21.4707 4.98524C19.8921 4.33275 18.2003 3.99796 16.4922 4.00001V4.00001ZM16.4922 27.3953C14.4343 27.3968 12.4222 26.788 10.7104 25.6458C8.9986 24.5037 7.66399 22.8795 6.87541 20.9787C6.08682 19.0779 5.87972 16.986 6.28024 14.9674C6.68077 12.9489 7.67096 11.0945 9.12555 9.63885C10.5801 8.18317 12.4338 7.19159 14.452 6.78955C16.4702 6.38751 18.5623 6.59306 20.4637 7.38022C22.3651 8.16737 23.9903 9.50078 25.1337 11.2117C26.2772 12.9227 26.8875 14.9343 26.8875 16.9922C26.8896 19.7486 25.7977 22.3931 23.8516 24.3451C21.9054 26.297 19.2642 27.3969 16.5078 27.4031L16.4922 27.3953Z"
        fill={props.fill || '#F4F5F6'}
      />
    </svg>
  );
};
