import TableHeader from '@/components/table/fat-tableHeader';
import { ISort } from '@/components/table/types';
import { useResponsive } from '@/hooks/use-responsive';
import styled from 'styled-components';

import { Mode } from '@/components/allocations-page/types';
import { Loader } from '@/components/loaders/loader';
import { TableBodyWrapper, TableWrapper } from '@/components/table/styles';
import { columns } from '../constatnts';
import { IAllocationRow } from '../types';
import MobileTable from './mobileTable';
import TableBody from './tableBody';

interface TableProps {
  sort: ISort;
  setSort: (value: ISort) => void;
  potentialAllocationData: IAllocationRow[];
  refetch: () => void;
  loading: boolean;
  mode: Mode;
  selectAllRows: (isChecked: boolean) => void;
  selectRow: (row: IAllocationRow) => void;
  openModalWindow: (type: string, row: any) => void;
  updateDecisionReason: (id: string, value: string) => void;
  openMobileModal: (data: IAllocationRow, modalType: string) => void;
  openInvestorDetails: (id: string) => void;
  openEntityDetails: (investorId: string, entityId: string) => void;
}

const Table = ({
  sort,
  setSort,
  potentialAllocationData,
  refetch,
  loading,
  mode,
  selectAllRows,
  selectRow,
  openModalWindow,
  updateDecisionReason,
  openMobileModal,
  openInvestorDetails,
  openEntityDetails
}: TableProps) => {
  const { isMobile, isTablet } = useResponsive();

  const isChosenFilters = document.querySelector('#chosenFliters');

  return (
    <TableWrapper padding="0">
      {isMobile ? (
        <>
          {loading ? (
            <Loader />
          ) : (
            <MobileTable
              potentialAllocationData={potentialAllocationData}
              openMobileModal={openMobileModal}
              openInvestorDetails={openInvestorDetails}
              openEntityDetails={openEntityDetails}
            />
          )}
        </>
      ) : (
        <>
          <CustomTableHeader
            isChosenFilters={Boolean(isChosenFilters)}
            isTablet={isTablet}
            refetch={refetch}
            columns={columns}
            savedSort={sort}
            savedSetSort={setSort}
            checkBox={{
              checked: potentialAllocationData.every((row) => row.checked) && !!potentialAllocationData.length,
              onChange: (isChecked) => selectAllRows(isChecked),
              show: mode === Mode.EDIT
            }}
          />
          <TableBodyWrapper>
            {loading ? (
              <Loader />
            ) : (
              <TableBody
                potentialAllocationData={potentialAllocationData}
                mode={mode}
                selectRow={selectRow}
                openModalWindow={openModalWindow}
                updateDecisionReason={updateDecisionReason}
                openInvestorDetails={openInvestorDetails}
                openEntityDetails={openEntityDetails}
              />
            )}
          </TableBodyWrapper>
        </>
      )}
    </TableWrapper>
  );
};

export default Table;

const CustomTableHeader = styled(TableHeader)<{ isChosenFilters?: boolean; isTablet: boolean }>`
  position: sticky;
  top: ${({ isChosenFilters, isTablet }) => (isChosenFilters ? (isTablet ? '249px' : '194px') : isTablet ? '187px' : '137px')};
  z-index: 8;
  padding-top: 0;
  justify-content: flex-start;
`;
