import { useMemo } from 'react';

import FormattingTooltip from '@/components/basicComponents/tooltip/dollarsTooltip';
import { Loader } from '@/components/loaders/loader';
import { MobileRow, MobileRowTitle, MobileRowValue, MobileTableRowWrap } from '@/components/table/mobileTable/mobileTable';
import styled from 'styled-components';
import {
  overCommitmentRatioModeColumns,
  overCommitmentRatioModeSimplifiedColumns,
  pacingModelModeColumns,
  pacingModelModeSimplifiedColumns
} from '../../constatnts';
import { AssetClassTargetAllocationDto } from '../../types';
import { calculateCapacity } from '../tableSumFooter';

interface MobileTableProps {
  investorSummaryRows: AssetClassTargetAllocationDto[];
  loading: boolean;
  ignoreAllocationTargets: boolean;
  commitmentPlansTotal: number | null;
  targetOCRatio: number | null;
}

interface ISum {
  total: string;
  target: number;
  targetAllocations: number;
  exposure: number;
  capacity: number;
  currentNAV: number;
  unfundedCommitments: number;
}

const MobileSumFooter = ({ investorSummaryRows, loading, ignoreAllocationTargets, commitmentPlansTotal, targetOCRatio }: MobileTableProps) => {
  const rowSum: ISum = useMemo(() => {
    const sum = {
      total: 'Total',
      target: 0,
      targetAllocations: 0,
      exposure: 0,
      capacity: 0,
      currentNAV: 0,
      unfundedCommitments: 0
    };

    investorSummaryRows.forEach((row) => {
      sum.target += row.percentage;
      sum.targetAllocations += row.targetAllocation;
      sum.exposure += row.economicExposure;
      sum.currentNAV += row.currentNAV || 0;
      sum.unfundedCommitments += row.unfundedCommitments || 0;
      sum.capacity += calculateCapacity(row, commitmentPlansTotal, targetOCRatio);
    });

    return sum;
  }, [investorSummaryRows, commitmentPlansTotal, targetOCRatio]);

  if (ignoreAllocationTargets && targetOCRatio !== null) {
    return (
      <>
        {!loading ? (
          investorSummaryRows && (
            <MobileSumFooterWrap>
              <MobileRow>
                <MobileRowTitle>{overCommitmentRatioModeSimplifiedColumns[0].title}</MobileRowTitle>
                <MobileRowValue>{rowSum.total}</MobileRowValue>
              </MobileRow>

              <MobileRow>
                <MobileRowTitle>{overCommitmentRatioModeSimplifiedColumns[1].title}</MobileRowTitle>
                <MobileRowValue>
                  <FormattingTooltip>{rowSum.exposure}</FormattingTooltip>
                </MobileRowValue>
              </MobileRow>
            </MobileSumFooterWrap>
          )
        ) : (
          <Loader />
        )}
      </>
    );
  }

  if (ignoreAllocationTargets) {
    return (
      <>
        {!loading ? (
          investorSummaryRows && (
            <MobileSumFooterWrap>
              <MobileRow>
                <MobileRowTitle>{pacingModelModeSimplifiedColumns[0].title}</MobileRowTitle>
                <MobileRowValue>{rowSum.total}</MobileRowValue>
              </MobileRow>

              <MobileRow>
                <MobileRowTitle>{pacingModelModeSimplifiedColumns[1].title}</MobileRowTitle>
                <MobileRowValue>
                  <FormattingTooltip>{rowSum.currentNAV}</FormattingTooltip>
                </MobileRowValue>
              </MobileRow>

              <MobileRow>
                <MobileRowTitle>{pacingModelModeSimplifiedColumns[2].title}</MobileRowTitle>
                <MobileRowValue>
                  <FormattingTooltip>{rowSum.unfundedCommitments}</FormattingTooltip>
                </MobileRowValue>
              </MobileRow>
            </MobileSumFooterWrap>
          )
        ) : (
          <Loader />
        )}
      </>
    );
  }

  if (targetOCRatio === null) {
    return (
      <>
        {!loading ? (
          investorSummaryRows && (
            <MobileSumFooterWrap>
              <MobileRow>
                <MobileRowTitle>{pacingModelModeColumns[0].title}</MobileRowTitle>
                <MobileRowValue>{rowSum.total}</MobileRowValue>
              </MobileRow>

              <MobileRow>
                <MobileRowTitle>{pacingModelModeColumns[1].title}</MobileRowTitle>
                <MobileRowValue>{rowSum.target.toFixed(0)}%</MobileRowValue>
              </MobileRow>

              <MobileRow>
                <MobileRowTitle>{pacingModelModeColumns[2].title}</MobileRowTitle>
                <MobileRowValue>
                  <FormattingTooltip>{rowSum.targetAllocations}</FormattingTooltip>
                </MobileRowValue>
              </MobileRow>

              <MobileRow>
                <MobileRowTitle>{pacingModelModeColumns[3].title}</MobileRowTitle>
                <MobileRowValue>
                  <FormattingTooltip>{rowSum.currentNAV}</FormattingTooltip>
                </MobileRowValue>
              </MobileRow>

              <MobileRow>
                <MobileRowTitle>{pacingModelModeColumns[4].title}</MobileRowTitle>
                <MobileRowValue>
                  <FormattingTooltip>{rowSum.unfundedCommitments}</FormattingTooltip>
                </MobileRowValue>
              </MobileRow>

              <MobileRow>
                <MobileRowTitle>{pacingModelModeColumns[5].title}</MobileRowTitle>
                <MobileRowValue>
                  <FormattingTooltip>{rowSum.capacity}</FormattingTooltip>
                </MobileRowValue>
              </MobileRow>
            </MobileSumFooterWrap>
          )
        ) : (
          <Loader />
        )}
      </>
    );
  }

  return (
    <>
      {!loading ? (
        investorSummaryRows && (
          <MobileSumFooterWrap>
            <MobileRow>
              <MobileRowTitle>{overCommitmentRatioModeColumns[0].title}</MobileRowTitle>
              <MobileRowValue>{rowSum.total}</MobileRowValue>
            </MobileRow>

            <MobileRow>
              <MobileRowTitle>{overCommitmentRatioModeColumns[1].title}</MobileRowTitle>
              <MobileRowValue>{rowSum.target.toFixed(0)}%</MobileRowValue>
            </MobileRow>

            <MobileRow>
              <MobileRowTitle>{overCommitmentRatioModeColumns[2].title}</MobileRowTitle>
              <MobileRowValue>
                <FormattingTooltip>{rowSum.targetAllocations}</FormattingTooltip>
              </MobileRowValue>
            </MobileRow>

            <MobileRow>
              <MobileRowTitle>{overCommitmentRatioModeColumns[3].title}</MobileRowTitle>
              <MobileRowValue>
                <FormattingTooltip>{rowSum.exposure}</FormattingTooltip>
              </MobileRowValue>
            </MobileRow>

            <MobileRow>
              <MobileRowTitle>{overCommitmentRatioModeColumns[4].title}</MobileRowTitle>
              <MobileRowValue>
                <FormattingTooltip>{rowSum.capacity}</FormattingTooltip>
              </MobileRowValue>
            </MobileRow>
          </MobileSumFooterWrap>
        )
      ) : (
        <Loader />
      )}
    </>
  );
};

const MobileSumFooterWrap = styled(MobileTableRowWrap)`
  background-color: ${({ theme }) => theme.layer[2]};
`;

export default MobileSumFooter;
