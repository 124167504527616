import { defaultSelectValue } from '@/components/opportunities-entity/modify/constants';
import { AssetClassWithPrevValues, capacityCalculationOptions } from '.';
import { DrawdownTargetPercentagesWithSubAssetClassesDto } from '../fat-investorDetails-page/fat-entityDetails-page/fat-pacingTab/types';
import { CreateInvestorDto, IAssetClass, ILegalEntity } from './types';

const getDecimalPart = (number: number): number => {
  const decimalPart = parseFloat((number % 1).toFixed(2).slice(2));
  return decimalPart !== 0 ? decimalPart : 0;
};

export const getDefaultDataFields = (entityData: ILegalEntity): CreateInvestorDto => ({
  entityName: entityData.entityName ? entityData.entityName : '',
  familyId: entityData.family.name ? entityData.family.name : defaultSelectValue,
  ignoreBiteSize: entityData.ignoreBiteSize,
  ignoreAllocationTargets: entityData.ignoreAllocationTargets,
  taxExempt: entityData.taxExempt,
  onHold: entityData.onHold,
  defaultBiteSize1: entityData.defaultBiteSize1 ? entityData.defaultBiteSize1.toString() : '',
  defaultBiteSize2: entityData.defaultBiteSize2 ? entityData.defaultBiteSize2.toString() : '',
  overCommitRatio: entityData.overCommitRatio ? getDecimalPart(entityData.overCommitRatio).toString() : '',
  privatePercentage: entityData.privatePercentage ? `${entityData.privatePercentage.toString()}%` : '',
  totalEntityValue: entityData.totalEntityValue ? entityData.totalEntityValue.toString() : '',
  advisorReportingAccountNumber: entityData.advisorReportingAccountNumber ? entityData.advisorReportingAccountNumber : '',
  advisorCRMId: entityData.advisorCRMId ? entityData.advisorCRMId : '',
  administratorCode: entityData.administratorCode ? entityData.administratorCode : '',
  sectorsToAvoid: entityData?.sectorsToAvoid?.filter((sector: string) => sector !== ''),
  capacityCalculationOption: entityData.overCommitRatio === null ? capacityCalculationOptions[0] : capacityCalculationOptions[1]
});

export const buildDataToUpdate = (formData: CreateInvestorDto, entityId: string) => ({
  id: entityId,
  exportId: formData.advisorReportingAccountNumber,
  entityCRMId: formData.advisorCRMId,
  entityName: formData.entityName,
  totalEntityValue: formData.totalEntityValue,
  privatePercentage: formData.privatePercentage,
  overCommitRatio: Number('1.' + formData.overCommitRatio),
  directBiteSize: formData.defaultBiteSize1,
  fundBiteSize: formData.defaultBiteSize2,
  taxExempt: formData.taxExempt,
  onHold: formData.onHold,
  sectorsToAvoid: formData.sectorsToAvoid.filter((item) => item !== 'None'),
  ignoreBiteSize: formData.ignoreBiteSize,
  ignoreAllocationTargets: formData.ignoreAllocationTargets
});

export const getAllocationTargetsToUpdate = (
  assetClassTargets: AssetClassWithPrevValues[],
  targetSubAssetClasses: boolean,
  privatePercentage: string
) => {
  const hasSubAssetClasses = assetClassTargets.some((assetClass) => assetClass.subAssetClasses.length > 0);

  if (targetSubAssetClasses && hasSubAssetClasses) {
    return assetClassTargets.flatMap((assetClass) => {
      return assetClass.subAssetClasses.map((subAssetClass) => {
        return {
          subAssetClassId: subAssetClass.id,
          percentage: calculatePrivateCapitalPercentage(subAssetClass.percentage, privatePercentage)
        };
      });
    });
  }

  return assetClassTargets.map((assetClass) => ({
    assetClassId: assetClass.id,
    percentage: calculatePrivateCapitalPercentage(assetClass.percentage, privatePercentage)
  }));
};

export const sortByName = <T extends { name: string }>(arr: T[]) => {
  return [...arr].sort((a, b) => a.name.localeCompare(b.name));
};

export const structurAssetClassesWithPrevValues = (
  targets: DrawdownTargetPercentagesWithSubAssetClassesDto[],
  defaultAssetClasses: IAssetClass[]
): AssetClassWithPrevValues[] => {
  const structuredAssetClassesWithPrevValues = targets.map((assetClass) => {
    const defaultAssetClass: IAssetClass = defaultAssetClasses.find((item: IAssetClass) => item.id === assetClass.id);

    const structuredSubAssetClassesWithPrevValues = assetClass.subAssetClassDrawdownTargets.map((subAssetClass) => {
      const defaultSubAssetClass = defaultAssetClass?.subAssetClasses.find((item) => item.id === subAssetClass.id);
      return {
        id: subAssetClass.id,
        name: subAssetClass.name,
        defaultPercentage: `${defaultSubAssetClass?.defaultAllocationPercentage ?? '0'}%`,
        prevPercentage: `${subAssetClass.portfolioPercentage.toFixed(2)}%`,
        percentage: `${subAssetClass.portfolioPercentage.toFixed(2)}%`
      };
    });

    return {
      id: assetClass.id,
      name: assetClass.name,
      defaultPercentage: `${defaultAssetClass?.defaultAllocationPercentage ?? '0'}%`,
      prevPercentage: `${assetClass.portfolioPercentage.toFixed(2)}%`,
      percentage: `${assetClass.portfolioPercentage.toFixed(2)}%`,
      subAssetClasses: structuredSubAssetClassesWithPrevValues
    };
  });

  return structuredAssetClassesWithPrevValues;
};

export const calculatePrivateCapitalPercentage = (percentage: string, privatePercentage: string): number => {
  const calculatePercentageValue = (parseFloat(percentage) / parseFloat(privatePercentage)) * 100;
  return parseFloat(calculatePercentageValue?.toFixed(2)) || 0;
};

export const updateAssetClassDrawdownTargets = (
  targets: DrawdownTargetPercentagesWithSubAssetClassesDto[],
  changedAssetClassTargets: AssetClassWithPrevValues[],
  privatePercentage: string
): DrawdownTargetPercentagesWithSubAssetClassesDto[] => {
  const updatedAssetClassTargets = targets.map((assetClass) => {
    const currentAssetClass = changedAssetClassTargets.find((item) => item.id === assetClass.id);

    const updatedSubAssetClasses = assetClass.subAssetClassDrawdownTargets.map((subAssetClass) => {
      const currentSubAssetClass = currentAssetClass.subAssetClasses.find((item) => item.id === subAssetClass.id);
      return {
        ...subAssetClass,
        portfolioPercentage: parseFloat(currentSubAssetClass?.percentage) || 0,
        privateCapitalPercentage: calculatePrivateCapitalPercentage(currentSubAssetClass?.percentage, privatePercentage)
      };
    });
    return {
      ...assetClass,
      portfolioPercentage: parseFloat(currentAssetClass?.percentage) || 0,
      privateCapitalPercentage: calculatePrivateCapitalPercentage(currentAssetClass?.percentage, privatePercentage),
      subAssetClassDrawdownTargets: updatedSubAssetClasses
    };
  });

  return updatedAssetClassTargets;
};

export const resetAssetClassTargets = (assetClassTargets: AssetClassWithPrevValues[]) => {
  return assetClassTargets.map((assetClass) => {
    return {
      ...assetClass,
      prevPercentage: assetClass.defaultPercentage,
      percentage: assetClass.defaultPercentage,
      subAssetClasses: assetClass.subAssetClasses.map((subAssetClass) => {
        return {
          ...subAssetClass,
          prevPercentage: subAssetClass.defaultPercentage,
          percentage: subAssetClass.defaultPercentage
        };
      })
    };
  });
};

export const getSumAssetClassPercentages = (assetClassTargets: AssetClassWithPrevValues[]): string => {
  return (
    assetClassTargets
      .reduce((acc, assetClass) => {
        return acc + parseFloat(assetClass.percentage);
      }, 0)
      .toFixed(2) + '%'
  );
};
