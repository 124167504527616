import { useMutation } from '@apollo/client';
import { useNavigate, useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import CheckBox from '@/components/basicComponents/checkbox';
import Label from '@/components/basicComponents/label';
import ModalWrappedContent from '@/components/basicComponents/modal/modalWrappedContent';
import {
  IChecklistItem,
  ITier
} from '@/components/dueDiligence-page/dueDiligenceRecord/types';
import {
  CREATE_DUE_DILIGENCE_CHECKLIST_ITEM,
  GET_FUNNEL_QUERY,
  UPDATE_DRAFT_DUE_DILIGENCE_CHECKLIST_ITEM,
  UPDATE_DUE_DILIGENCE_CHECKLIST_ITEM
} from '@/components/settings-page/queries';
import { saveOnEnter } from '../utils';
import { CheckBoxWrap } from './addField';
import { MainSearch, ModalContainer, ModalTitle } from './addFunnel';

interface AddChecklistProps {
  onClose: () => void;
  isOpen: boolean;
  currentChecklistItem: IChecklistItem | null;
  currentId: string;
  tierId: string | null;
  numberOfChecklistItems: number;
  isLimitedEdits: boolean;
}

const AddChecklist = ({
  onClose,
  isOpen,
  currentChecklistItem,
  currentId,
  tierId,
  numberOfChecklistItems,
  isLimitedEdits
}: AddChecklistProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const [formValues, setFormValues] = useState({
    value: '',
    isChecklistRequired: true
  });

  const [createChecklistItem] = useMutation(
    CREATE_DUE_DILIGENCE_CHECKLIST_ITEM,
    {
      update(cache, { data }) {
        const currentData = cache.readQuery({
          query: GET_FUNNEL_QUERY,
          variables: {
            id: queryParams.get('funnelId')
          }
        });

        const { getDueDiligenceFunnel }: any = currentData;

        cache.writeQuery({
          query: GET_FUNNEL_QUERY,
          data: {
            getDueDiligenceFunnel: {
              ...getDueDiligenceFunnel,
              tiers: getDueDiligenceFunnel.tiers.map((tier: ITier) =>
                tierId === tier.id
                  ? {
                      ...tier,
                      checklistItems: [
                        ...tier.checklistItems,
                        data.createDueDiligenceFunnelTierChecklistItem
                      ]
                    }
                  : tier
              )
            }
          }
        });
      }
    }
  );
  const [updateChecklistItem] = useMutation(
    UPDATE_DUE_DILIGENCE_CHECKLIST_ITEM
  );
  const [updateDraftChecklistItem] = useMutation(
    UPDATE_DRAFT_DUE_DILIGENCE_CHECKLIST_ITEM
  );

  useEffect(() => {
    if (currentChecklistItem) {
      setFormValues({
        value: currentChecklistItem.name,
        isChecklistRequired: currentChecklistItem.required
      });
    } else {
      setFormValues({
        value: '',
        isChecklistRequired: true
      });
    }
  }, [currentChecklistItem]);

  const saveValue = () => {
    if (currentChecklistItem && isLimitedEdits) {
      updateChecklistItem({
        variables: {
          data: {
            id: currentId,
            name: formValues.value
          }
        },
        onCompleted: () => {
          onClose();
        }
      });

      return;
    }

    if (currentChecklistItem) {
      updateDraftChecklistItem({
        variables: {
          data: {
            id: currentId,
            name: formValues.value,
            required: formValues.isChecklistRequired
          }
        }
      });
    } else {
      createChecklistItem({
        variables: {
          data: {
            funnelTierId: tierId,
            name: formValues.value,
            required: formValues.isChecklistRequired,
            order: numberOfChecklistItems + 1
          }
        }
      });
    }

    onClose();
  };

  return (
    <ModalWrappedContent
      isOpen={isOpen}
      onClose={onClose}
      confirmButton={{
        name: 'Save',
        onClick: saveValue,
        disabled: !formValues.value
      }}
      showRequiredFields
    >
      <ModalTitle>
        {currentChecklistItem
          ? 'Edit Checklist Item'
          : 'Add New Checklist Item'}
      </ModalTitle>
      <ModalContainer
        tabIndex={0}
        onKeyDown={(event) => saveOnEnter(event, saveValue, !formValues.value)}
      >
        <Label required>Give this Field a name</Label>
        <MainSearch
          type="text"
          placeholder="Give this Section a unique name"
          value={formValues.value}
          onChange={(event) =>
            setFormValues({ ...formValues, value: event.target.value })
          }
          autoFocus
        />
        <CheckBoxWrap
          id="requiredField"
          onClick={
            isLimitedEdits
              ? () => null
              : () =>
                  setFormValues({
                    ...formValues,
                    isChecklistRequired: !formValues.isChecklistRequired
                  })
          }
          isDisabled={isLimitedEdits}
        >
          <CheckBox
            id="requiredField"
            isActive={formValues.isChecklistRequired}
          />
          Make Checklist Item Required
        </CheckBoxWrap>
      </ModalContainer>
    </ModalWrappedContent>
  );
};

export default AddChecklist;
