import * as React from 'react';

export const DatePickerIcon = ({ ...props }: React.SVGProps<SVGSVGElement>) => (
  <svg width={props.width || 22} height={props.height || 24} style={props?.style} viewBox="0 0 22 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_5249_416130)">
      <path
        d="M19.5942 2.39785H18.3989V0H15.9939V2.39785H6.39531V0H3.99746V2.39785H2.79495C2.4804 2.39785 2.16894 2.45992 1.87843 2.58051C1.58791 2.7011 1.32405 2.87785 1.10197 3.10061C0.87988 3.32336 0.703911 3.58773 0.584194 3.87861C0.464477 4.16948 0.40336 4.48116 0.404308 4.7957V21.6022C0.404308 22.2381 0.656942 22.848 1.10662 23.2977C1.55631 23.7474 2.16621 24 2.80216 24H19.6014C20.238 24 20.8487 23.7476 21.2995 23.2981C21.7503 22.8487 22.0046 22.2388 22.0065 21.6022V4.7957C22.0055 4.47987 21.9424 4.16731 21.8207 3.87588C21.6989 3.58445 21.5209 3.31984 21.2969 3.09718C21.0729 2.87452 20.8073 2.69817 20.5152 2.57818C20.223 2.45819 19.9101 2.3969 19.5942 2.39785ZM19.5942 21.6022H2.79495V9.59857H19.5942V21.6022ZM19.5942 7.20072H2.79495V4.7957H19.5942V7.20072ZM7.59782 14.4014H5.1928V11.9964H7.59782V14.4014ZM12.3935 14.4014H9.99567V11.9964H12.3935V14.4014ZM17.1964 14.4014H14.7985V11.9964H17.1964V14.4014ZM7.59782 19.1971H5.1928V16.7993H7.59782V19.1971ZM12.3935 19.1971H9.99567V16.7993H12.3935V19.1971ZM17.1964 19.1971H14.7985V16.7993H17.1964V19.1971Z"
        fill={props.fill || '#ABABAB'}
      />
    </g>
    <defs>
      <clipPath id="clip0_5249_416130">
        <rect width="21.6022" height="24" fill="white" transform="translate(0.397461)" />
      </clipPath>
    </defs>
  </svg>
);
