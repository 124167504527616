import { MobileRow, MobileRowTitle, MobileRowValue, MobileTableRowWrap, MobileTableWrapper } from '@/components/table/mobileTable/mobileTable';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { formatCash } from '../../../utils/formatCash';
import guid from '../../../utils/guid';
import Tooltip from '../../basicComponents/tooltip';
import FormattingTooltip from '../../basicComponents/tooltip/dollarsTooltip';
import { Loader } from '../../loaders/loader';
import { TableBodyWrapper, TableRowCell, TextCell } from '../../table/styles';
import { TableColumns } from '../../table/types';
import { CommitmentsType } from '../types';
import MobileSumFooter from './mobileSumFooter';
import { BasicTitle } from './tableBody';

type MobileTableProps = {
  columns: TableColumns[];
  clientsRows: CommitmentsType[] | undefined;
  loading: boolean;
  onRowClick: (data: CommitmentsType) => void;
};

const MobileTable = ({ columns, clientsRows, loading, onRowClick }: MobileTableProps) => {
  const navigate = useNavigate();

  const handleInvestment = (name: string, id: string) => {
    return navigate(`/investments/manager/investment/?mid=${name}&id=${id}`, {
      state: { prevPath: window.location.pathname }
    });
  };

  const toolTipContent = (row: CommitmentsType) => {
    return (
      <TableBodyWrapper>
        <TableRowCell width={100}>
          <TextCell>{formatCash(row.committedCapital)} Committed Capital</TextCell>
        </TableRowCell>
        <TableRowCell width={100}>
          <TextCell>{formatCash(row.capitalCalled)} Capital Called</TextCell>
        </TableRowCell>
        <TableRowCell width={100}>
          <TextCell>{formatCash(row.capitalRemaining)} Capital Remaining</TextCell>
        </TableRowCell>
        <TableRowCell width={100}>
          <TextCell>{((row.capitalCalled / row.committedCapital) * 100).toFixed(2)}% Called to Date</TextCell>
        </TableRowCell>
      </TableBodyWrapper>
    );
  };

  const getColorStatus = (status: string) => {
    if (status === 'Active') {
      return '#3DBD4A';
    } else if (status === 'Recommended' || status === 'Outstanding') {
      return '#DFC902';
    } else {
      return '#111827';
    }
  };

  const TableRow = ({ el }: { el: CommitmentsType }) => {
    return (
      <MobileTableRowWrap key={guid()}>
        <MobileRow>
          <MobileRowTitle>{columns[0].title}</MobileRowTitle>
          <MobileRowValue>
            <InvestmentTitle onClick={() => onRowClick(el)}>{el.name}</InvestmentTitle>
          </MobileRowValue>
        </MobileRow>

        <MobileRow>
          <MobileRowTitle>{columns[1].title}</MobileRowTitle>
          <MobileRowValue>{el.moreInfo.assetClass || ''}</MobileRowValue>
        </MobileRow>

        <MobileRow>
          <MobileRowTitle>{columns[2].title}</MobileRowTitle>
          <MobileRowValue>
            <FormattingTooltip>{el.committedCapital}</FormattingTooltip>
          </MobileRowValue>
        </MobileRow>

        <MobileRow>
          <MobileRowTitle>{columns[3].title}</MobileRowTitle>
          <MobileRowValue>
            <Tooltip tooltipContent={toolTipContent(el)}>{((el.capitalCalled / el.committedCapital) * 100).toFixed(2)}%</Tooltip>
          </MobileRowValue>
        </MobileRow>

        <MobileRow>
          <MobileRowTitle>{columns[4].title}</MobileRowTitle>
          <MobileRowValue>
            <FormattingTooltip>{+el.distributionsToDate}</FormattingTooltip>
          </MobileRowValue>
        </MobileRow>

        <MobileRow>
          <MobileRowTitle>{columns[5].title}</MobileRowTitle>
          <MobileRowValue>
            <FormattingTooltip>{el.marketValue}</FormattingTooltip>
          </MobileRowValue>
        </MobileRow>

        <MobileRow>
          <MobileRowTitle>{columns[5].title}</MobileRowTitle>
          <MobileRowValue>
            <ColorTitle color={getColorStatus(el.status)}>{el.status === 'Outstanding' ? 'Recommended' : el.status}</ColorTitle>
          </MobileRowValue>
        </MobileRow>
      </MobileTableRowWrap>
    );
  };

  return (
    <MobileTableWrapper>
      {!loading ? (
        clientsRows && (
          <>
            {clientsRows.map((el) => {
              return <TableRow el={el} key={guid()} />;
            })}
            <MobileSumFooter clientsRows={clientsRows} columns={columns} loading={loading} />
          </>
        )
      ) : (
        <Loader />
      )}
    </MobileTableWrapper>
  );
};

const ColorTitle = styled(BasicTitle)<{ color: string }>`
  color: ${({ color }) => color};
  font-weight: 600;
`;

const InvestmentTitle = styled(BasicTitle)`
  color: #4587ec;
  cursor: pointer;
  padding-right: 18px;
  font-weight: 600;
`;

export default MobileTable;
