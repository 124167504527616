import styled, { keyframes, useTheme } from 'styled-components';

import client from '@/apollo-client';
import { ArrowDown } from '@/assets/icons/info-icons/arrowDown';
import { CloseInfoIcon } from '@/assets/icons/info-icons/closeInfo';
import { InfoIcon } from '@/assets/icons/info-icons/info';
import { AiAssistantIcon } from '@/assets/static/icons/ai-assistant';
import { DocumentIcon } from '@/assets/static/icons/document';
import { SendIcon } from '@/assets/static/icons/send';
import { ArrowUp } from '@/assets/static/icons/todo-page/arrow-up';
import { UserChatIcon } from '@/assets/static/icons/user-chat';
import { Banner } from '@/components/basicComponents/banner';
import Tabs, { ITab, TabsLine } from '@/components/basicComponents/fat-tabs';
import BasicTooltip from '@/components/basicComponents/tooltip';
import { IFile } from '@/components/dueDiligence-page/dueDiligenceRecord';
import AiAssistantProgressBar from '@/components/dueDiligence-page/dueDiligenceRecord/ai-pane/progressBar';
import { CitationsType } from '@/components/dueDiligence-page/dueDiligenceRecord/types';
import AiSuggestions from '@/components/dueDiligence-page/modals/aiSuggestions';
import FileNotSupported from '@/components/dueDiligence-page/modals/fileNotSupported';
import {
  GET_AI_ASSETS,
  GET_AI_CHAT_THREAD,
  GET_AI_RUN_STATUS,
  GET_DUE_DILIGENCE_LIMITS,
  GET_URL_FOR_AI_ASSET_UPLOAD,
  createDealAssistantAIAsset,
  generateAnswersQuery,
  generateChatReplyQuery,
  processDealAssistantAIAsset
} from '@/components/dueDiligence-page/queries';
import Button from '@/components/fat-basicComponents/button';
import { Loader } from '@/components/loaders/loader';
import { GET_SETTINGS } from '@/components/settings-page/queries';
import { useAuth } from '@/hooks/use-auth';
import { useResponsive } from '@/hooks/use-responsive';
import { useClickOutside } from '@/hooks/useClickOutside';
import { User } from '@/types/user';
import { useMutation, useQuery } from '@apollo/client';
import { format } from 'date-fns';
import React, { Dispatch, KeyboardEventHandler, RefObject, SetStateAction, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { IGetDocumentRecordQuery } from '../../types';
import { AllSuggestions } from './allSuggestions';
import { DealTrialInfoBanner } from './dealTrialInfoBanner';
import { FileSelector } from './fileSelector';
import { SuccessToast } from './successToast';
import { AiPaneZeroState } from './zeroState';

interface AiPaneProps {
  onClose: () => void;
  isAiPaneOpen: boolean;
  documentRecord: IGetDocumentRecordQuery;
  files: IFile[];
  setFiles: Dispatch<SetStateAction<IFile[]>>;
  handleGenerateAnswersLoadingChange: (loadingStatus: boolean, showCompleteIcon: boolean) => void;
  documentRecordRefetch: () => void;
  freeChatOnly: boolean;
  openAdvancedOptions: () => void;
}

interface AiRunStatus {
  filenames: string[];
  id: string;
  investment: {
    name: string;
  };
  startedAt: Date;
  startedBy: User;
  completedAt: Date;
  status: string;
  currentAnswers: number;
  totalAnswers: number;
}

const fileExtensions = ['PDF'];
const DEFAULT_ERROR_MESSAGE = `We're sorry. This process did not complete. Please try again.`;

export const AiPane = ({
  onClose,
  isAiPaneOpen,
  documentRecord,
  files,
  setFiles,
  handleGenerateAnswersLoadingChange,
  documentRecordRefetch,
  freeChatOnly,
  openAdvancedOptions
}: AiPaneProps) => {
  const { user } = useAuth();
  const { isMobile, isTablet } = useResponsive();
  const theme = useTheme();

  const ref = useRef<HTMLDivElement | null>(null);
  const [drag, setDrag] = useState(false);
  const [isStatusDropDownOpen, setIsStatusDropDownOpen] = useState(true);
  const [showDragonDrop, setShowDragonDrop] = useState(false);
  const [showSuccessBanner, setShowSuccessBanner] = useState(false);
  const [aiRunStatus, setAiRunStatus] = useState<AiRunStatus | null>(null);
  const [modalWindow, setModalWindow] = useState({ isOpen: false, type: 'ai-suggestions' });
  const [tabs, setTabs] = useState<ITab[]>([
    { value: 'Autofill', amount: null },
    { value: 'Free Chat', amount: null }
  ]);
  const [activeTab, setActiveTab] = useState(tabs[0].value);
  const [chatPrompt, setChatPrompt] = useState('');
  const [chatHistory, setChatHistory] = useState<{ prompt: string; reply: string; citations: CitationsType[] }[]>([
    { prompt: '', reply: '', citations: [] }
  ]);
  const [filesForAIAssistant, setFilesForAIAssistant] = useState<IFile[]>([]);
  const [selectedFile, setSelectedFile] = useState('');
  const [selectedFileForAi, setSelectedFileForAi] = useState('');
  const [selectedProcessingOption, setSelectedProcessingOption] = useState('Select');
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const [trialLimits, setTrialLimits] = useState({ canCreateDeal: true, trialMode: false });
  const [unstructuredAvailablity, setUnstructuredAvailability] = useState({ useUnstructured: false });
  const [progressBarValues, setProgressBarValues] = useState({ currentAnswers: 0, totalAnswers: 0 });

  const contentRef: RefObject<HTMLDivElement> = useRef(null);
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const answerWrapRefs = useRef<any>({});

  const params = useParams();

  useClickOutside<HTMLDivElement>(ref, modalWindow.isOpen ? () => null : () => onClose());

  useEffect(() => {
    if (!isAiPaneOpen || aiRunStatus?.status !== 'Completed') {
      setShowSuccessBanner(false);
    }
  }, [isAiPaneOpen, aiRunStatus?.status]);

  const { data: getAIChatThreadData, refetch: getAIChatThreadRefetch } = useQuery(GET_AI_CHAT_THREAD, {
    variables: {
      data: {
        recordId: documentRecord?.id,
        type: 'mu'
      }
    },
    onCompleted: (data) => {}
  });

  const { data: getAIRunStatusData, refetch: getAIRunStatusRefetch } = useQuery<{ getAIRunStatus: AiRunStatus }>(GET_AI_RUN_STATUS, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    variables: {
      recordId: documentRecord?.id
    },
    onCompleted: (data) => {
      setAiRunStatus(data.getAIRunStatus);
      setProgressBarValues({ currentAnswers: data.getAIRunStatus?.currentAnswers ?? 0, totalAnswers: data.getAIRunStatus?.totalAnswers ?? 0 });
      if (data.getAIRunStatus?.status === 'Completed') {
        setShowSuccessBanner(true);
        documentRecordRefetch();
        setProgressBarValues({ currentAnswers: 0, totalAnswers: 0 });
      }
      setSelectedFile(data.getAIRunStatus?.filenames[0]);
    }
  });

  useQuery(GET_DUE_DILIGENCE_LIMITS, {
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      const { canCreateDeal, trialMode } = data.getDueDiligenceLimits;

      setTrialLimits({ canCreateDeal, trialMode });
    }
  });

  useQuery(GET_SETTINGS, {
    variables: {
      type: 'dealAssistantAiConfig'
    },
    onCompleted: (data) => {
      const { useUnstructured } = JSON.parse(data.Settings[0].value);
      setUnstructuredAvailability({ useUnstructured });
      setSelectedProcessingOption(useUnstructured ? 'Select' : 'Text only');
    }
  });

  useEffect(() => {
    if (getAIChatThreadData?.getChatThread?.answers) {
      setChatHistory(
        getAIChatThreadData.getChatThread.answers.map((answer: any) => ({
          prompt: answer.prompt,
          reply: answer.answer,
          citations: answer.citations
        }))
      );
      setTimeout(() => {
        if (contentRef.current) {
          contentRef.current.scrollTop = contentRef.current.scrollHeight;
        }
      }, 0);
    }
  }, [getAIChatThreadData]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      getAIRunStatusRefetch();
      console.log('timeout');
    }, 10000);

    if (aiRunStatus?.status !== 'In Progress') {
      clearInterval(intervalId);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [aiRunStatus?.status]);

  useEffect(() => {
    setShowDragonDrop(false);
  }, [activeTab]);

  useEffect(() => {
    if (freeChatOnly) {
      setActiveTab('Free Chat');
      setTabs([{ value: 'Free Chat', amount: null }]);
      return;
    }
    setTabs([
      { value: 'Autofill', amount: null },
      { value: 'Free Chat', amount: null }
    ]);
  }, [freeChatOnly]);

  useEffect(() => {
    if (contentRef.current) {
      const contentElement = contentRef.current;

      requestAnimationFrame(() => {
        contentElement.scrollTop = contentElement.scrollHeight;
      });
    }
  }, [activeTab]);

  const allowFileExtensions = (files: FileList | null) => {
    return Array.from(files || []).filter((file: File) => {
      const fileExt = file.name.split('.').pop()?.toLowerCase();
      if ([...fileExtensions.map((item) => item.toLowerCase())].includes(fileExt || '')) {
        return file;
      }
    });
  };

  const onDropHandler = async (e: React.DragEvent<HTMLDivElement>, selectedProcessingOption?: string) => {
    e.preventDefault();
    const uploadFiles = e.dataTransfer.files;

    setDrag(false);
    setShowDragonDrop(false);
    if (!allowFileExtensions(uploadFiles).length) {
      setModalWindow({ isOpen: true, type: 'not-supported' });
      return;
    }
    if (uploadFiles && uploadFiles.length > 0) {
      setFiles([...files, { ...uploadFiles[0], name: uploadFiles[0].name, loading: true }]);
      setFilesForAIAssistant([...files, { ...uploadFiles[0], name: uploadFiles[0].name, loading: true }]);
      await uploadFile(uploadFiles[0], selectedProcessingOption);
    }
  };

  const handleUploadFile = async (e: React.ChangeEvent<HTMLInputElement>, selectedProcessingOption?: string) => {
    e.preventDefault();
    const uploadFiles = e.target.files;

    setShowDragonDrop(false);
    if (!allowFileExtensions(uploadFiles).length) {
      setModalWindow({ isOpen: true, type: 'not-supported' });
      return;
    }
    if (uploadFiles && uploadFiles.length > 0) {
      setFiles([...files, { ...uploadFiles[0], name: uploadFiles[0].name, loading: true }]);
      setFilesForAIAssistant([...files, { ...uploadFiles[0], name: uploadFiles[0].name, loading: true }]);
      await uploadFile(uploadFiles[0], selectedProcessingOption);
    }
  };

  const [createAIAsset] = useMutation(createDealAssistantAIAsset);
  const [processAIAsset] = useMutation(processDealAssistantAIAsset);

  const [generateAnswers, { data: generateAnswersData, loading: generateAnswersLoading, error: generateAnswersError }] =
    useMutation(generateAnswersQuery);

  const [generateChatReply, { data: generateChatReplyData, loading: generateChatReplyLoading, error: generateChatReplyError }] =
    useMutation(generateChatReplyQuery);

  useEffect(() => {
    handleGenerateAnswersLoadingChange(generateAnswersLoading || aiRunStatus?.status === 'In Progress', showSuccessBanner);
  }, [generateAnswersLoading, handleGenerateAnswersLoadingChange, showSuccessBanner]);

  const handleGenerateAnswers = () => {
    setModalWindow({ type: 'ai-suggestions', isOpen: false });
    generateAnswers({
      variables: {
        data: {
          investmentId: params?.strategyId,
          recordId: documentRecord?.id,
          type: 'mu',
          filenames: [selectedFile]
        }
      },
      onCompleted: () => {
        setShowSuccessBanner(true);
        getAIRunStatusRefetch();
        documentRecordRefetch();
        aiAssetsRefetch();
      }
    });
    setTimeout(() => {
      getAIRunStatusRefetch();
    }, 1000);
  };

  const handleGetChatReply = () => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto';
    }
    const prompt = chatPrompt;
    setChatPrompt('');
    setChatHistory(
      chatHistory.concat({
        prompt,
        reply: '',
        citations: []
      })
    );
    setTimeout(() => {
      if (contentRef.current) {
        contentRef.current.scrollTop = contentRef.current.scrollHeight;
      }
    }, 0);
    generateChatReply({
      variables: {
        data: {
          investmentId: params?.strategyId,
          filenames: [selectedFileForAi],
          type: 'mu',
          prompts: [chatPrompt],
          contextPrompt: '',
          summaryPrompt: '',
          recordId: documentRecord?.id
        }
      },
      onCompleted: (data) => {
        getAIChatThreadRefetch();
        const previous = chatHistory.slice(0, chatHistory.length);
        const current = chatHistory.slice(chatHistory.length);
        const updatedChatHistory = previous.concat({
          prompt,
          reply: data.generateChatReply.answers[0].answer,
          citations: data.generateChatReply.answers[0].citations
        });
        if (contentRef.current) {
          contentRef.current.scrollTop = contentRef.current.scrollHeight;
        }
      }
    });
  };

  const handlePressEnter: KeyboardEventHandler<HTMLTextAreaElement> = (event) => {
    if (event.key === 'Enter') {
      if (!event.shiftKey) {
        event.preventDefault();
        handleGetChatReply();
      }
    }
  };

  const uploadFile = async (file: File, processingOption?: String) => {
    setErrorMessage(null);
    try {
      const { data: aiAssetData } = await client.query({
        query: GET_URL_FOR_AI_ASSET_UPLOAD,
        variables: {
          data: {
            contentType: file.type,
            type: 'mu',
            investmentId: params?.strategyId,
            recordId: documentRecord?.id,
            filename: file.name
          }
        }
      });

      await fetch(aiAssetData.getDealAssistantAIAssetUploadUrl.url, {
        method: 'PUT',
        body: file,
        headers: {
          'Content-Type': file.type
        }
      });

      await createAIAsset({
        variables: {
          data: {
            investmentId: params?.strategyId,
            recordId: documentRecord?.id,
            assetKey: aiAssetData.getDealAssistantAIAssetUploadUrl.assetKey,
            status: 'CREATED',
            type: 'mu',
            filename: file.name
          }
        }
      });

      await processAIAsset({
        variables: {
          data: {
            investmentId: params?.strategyId,
            recordId: documentRecord?.id,
            filename: file.name,
            type: 'mu',
            assetKey: aiAssetData.getDealAssistantAIAssetUploadUrl.assetKey,
            processingOption: processingOption
          }
        }
      });
      aiAssetsRefetch();
    } catch (error) {
      console.log('error', error);
      setErrorMessage(DEFAULT_ERROR_MESSAGE);
      aiAssetsRefetch();
    }
  };

  const { refetch: aiAssetsRefetch } = useQuery(GET_AI_ASSETS, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    variables: {
      data: {
        recordId: documentRecord?.id
      }
    },
    onCompleted: (data) => {
      setFiles(
        data.getDealAssistentAIAssets.map((file: any) => ({
          ...file,
          name: file.filename
        }))
      );
      setFilesForAIAssistant(
        data.getDealAssistentAIAssets.map((file: any) => ({
          ...file,
          name: file.filename
        }))
      );
    }
  });

  const scrollToBottom = (index: number, scrollBehavior: string) => {
    setTimeout(
      () => {
        if (answerWrapRefs.current[index]) {
          answerWrapRefs.current[index].scrollIntoView({ behavior: scrollBehavior, block: 'end', inline: 'nearest' });
        }
      },
      scrollBehavior === 'smooth' ? 200 : 0
    );
  };

  const scrollChatBottomByDefault = () => {
    if (contentRef.current) {
      const contentElement = contentRef.current;
      contentElement.scrollTop = contentElement.scrollHeight;
    }
  };

  if (!files.length) {
    return (
      <>
        {modalWindow.type === 'not-supported' && (
          <FileNotSupported isOpen={modalWindow.isOpen} onClose={() => setModalWindow({ ...modalWindow, isOpen: false })} />
        )}
        <AiPaneWrapper isAiPaneOpen={isAiPaneOpen} isMobile={isMobile} isTablet={isTablet} ref={ref} onAnimationEnd={scrollChatBottomByDefault}>
          <AiPaneHeader>
            <HeaderTitle>
              <AiAssistantIcon width={28} height={28} fill={theme.font.strong} />
              AI Assistant
            </HeaderTitle>
            <CloseBtn onClick={onClose}>
              <CloseInfoIcon width={14} height={14} fill={theme.action.default} />
            </CloseBtn>
          </AiPaneHeader>
          {errorMessage && (
            <Banner icon={<InfoIcon width={26} height={26} />} title="Error" description={errorMessage} bgColor={theme.context.error} />
          )}
          <Tabs tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab} />
          <TabsLine />
          <AiPaneZeroState
            trialMode={trialLimits.trialMode}
            useUnstructured={unstructuredAvailablity.useUnstructured}
            selectedProcessingOption={selectedProcessingOption}
            setSelectedProcessingOption={setSelectedProcessingOption}
            handleUploadFile={handleUploadFile}
            onDropHandler={onDropHandler}
            drag={drag}
            setDrag={setDrag}
          />
        </AiPaneWrapper>
      </>
    );
  }

  if (activeTab === 'Autofill' && !freeChatOnly) {
    return (
      <>
        {modalWindow.isOpen && modalWindow.type === 'ai-suggestions' && (
          <AiSuggestions
            onClose={() => setModalWindow({ type: 'ai-suggestions', isOpen: false })}
            isOpen={modalWindow.isOpen}
            files={files}
            setSelectedFile={setSelectedFile}
            selectedFile={selectedFile}
            handleGenerateAnswers={handleGenerateAnswers}
          />
        )}
        {modalWindow.type === 'not-supported' && (
          <FileNotSupported isOpen={modalWindow.isOpen} onClose={() => setModalWindow({ ...modalWindow, isOpen: false })} />
        )}
        <AiPaneWrapper isAiPaneOpen={isAiPaneOpen} isMobile={isMobile} isTablet={isTablet} ref={ref} onAnimationEnd={scrollChatBottomByDefault}>
          <AiPaneHeader>
            <HeaderTitle>
              <AiAssistantIcon width={28} height={28} fill={theme.font.strong} />
              AI Assistant
            </HeaderTitle>
            <CloseBtn onClick={onClose}>
              <CloseInfoIcon width={14} height={14} fill={theme.action.default} />
            </CloseBtn>
          </AiPaneHeader>
          {errorMessage && (
            <Banner icon={<InfoIcon width={26} height={26} />} title="Error" description={errorMessage} bgColor={theme.context.error} />
          )}
          <Tabs tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab} />
          <TabsLine />
          {showSuccessBanner && <SuccessToast setShowSuccessBanner={setShowSuccessBanner} numberFields={6} />}
          <AiPaneBodyWrapper>
            {aiRunStatus && aiRunStatus.status === 'Completed' && (
              <>
                <SelectedDocument withBorderBottom onClick={() => setIsStatusDropDownOpen(!isStatusDropDownOpen)}>
                  <div>
                    Suggestions last generated:
                    <BoldText> {` ${format(new Date(aiRunStatus.completedAt), 'LLL d, yyyy')}`}</BoldText> by
                    <BoldText>{` ${aiRunStatus?.startedBy?.firstName} ${aiRunStatus?.startedBy?.lastName}`}</BoldText>
                  </div>
                  {isStatusDropDownOpen ? (
                    <ArrowUp fill={theme.font.action} />
                  ) : (
                    <ArrowDown style={{ width: '28px', height: '28px', padding: '7px' }} fill={theme.font.action} />
                  )}
                </SelectedDocument>
                {isStatusDropDownOpen && (
                  <UploadedDocumnts>
                    {aiRunStatus.filenames.map((file, index) => (
                      <UploadedDocumentItem key={file + index} id={'input'}>
                        <CheckBoxWrap>
                          <DocumentIcon fill={theme.font.weak} />
                          {file}
                        </CheckBoxWrap>
                      </UploadedDocumentItem>
                    ))}
                  </UploadedDocumnts>
                )}
              </>
            )}
            {aiRunStatus && aiRunStatus.status === 'In Progress' && (
              <>
                <SelectedDocument>
                  <div>
                    Suggestions generation in progress:
                    <BoldText> {` ${format(new Date(aiRunStatus.startedAt), 'LLL d, yyyy')}`}</BoldText> by
                    <BoldText>{` ${aiRunStatus?.startedBy?.firstName} ${aiRunStatus?.startedBy?.lastName}`}</BoldText>
                  </div>
                </SelectedDocument>
                <TextDescription>This processing time will vary based on the size and number of documents that were selected.</TextDescription>
              </>
            )}
            <FileSelector
              generateAnswersLoading={generateAnswersLoading}
              files={files}
              setSelectedFile={setSelectedFile}
              selectedFile={selectedFile}
              showRadioButton={!modalWindow.isOpen}
              showDragonDrop={showDragonDrop}
              setShowDragonDrop={setShowDragonDrop}
              handleUploadFile={handleUploadFile}
              onDropHandler={onDropHandler}
              drag={drag}
              setDrag={setDrag}
              trialMode={trialLimits.trialMode}
            />
            {trialLimits.trialMode && (
              <DealTrialInfoBanner title="Deal Pilot Trial" description="You are limited to one file upload during the trial period." />
            )}
            <SuggestionButton
              onClick={() => setModalWindow({ isOpen: true, type: 'ai-suggestions' })}
              size="lg"
              styleType={
                !selectedFile ||
                generateAnswersLoading ||
                aiRunStatus?.status === 'In Progress' ||
                Boolean(aiRunStatus?.filenames.length && trialLimits.trialMode)
                  ? 'disabled'
                  : 'default'
              }
            >
              {generateAnswersLoading || aiRunStatus?.status === 'In Progress' ? (
                <>
                  <CustomLoader size={28} />
                  Suggesting Answers For Selected Documents
                </>
              ) : (
                <>
                  <AiAssistantIcon
                    width={28}
                    height={28}
                    fill={
                      !selectedFile || Boolean(aiRunStatus?.filenames.length && trialLimits.trialMode) ? theme.font.disabled : theme.context.light
                    }
                  />
                  Suggest Answers
                </>
              )}
            </SuggestionButton>
            {user && user.roles.includes('Super Admin') && <AdvancedOptions onClick={openAdvancedOptions}>Advanced Options</AdvancedOptions>}
            {(generateAnswersLoading || aiRunStatus?.status === 'In Progress') && (
              <AiAssistantProgressBar currentAnswers={progressBarValues.currentAnswers} totalAnswers={progressBarValues.totalAnswers} />
            )}
          </AiPaneBodyWrapper>
        </AiPaneWrapper>
      </>
    );
  }

  if (activeTab === 'Free Chat') {
    return (
      <>
        {modalWindow.isOpen && modalWindow.type === 'ai-suggestions' && (
          <AiSuggestions
            onClose={() => setModalWindow({ type: 'ai-suggestions', isOpen: false })}
            isOpen={modalWindow.isOpen}
            files={files}
            setSelectedFile={setSelectedFile}
            selectedFile={selectedFile}
            handleGenerateAnswers={handleGenerateAnswers}
          />
        )}
        {modalWindow.type === 'not-supported' && (
          <FileNotSupported isOpen={modalWindow.isOpen} onClose={() => setModalWindow({ ...modalWindow, isOpen: false })} />
        )}
        <AiPaneWrapper isAiPaneOpen={isAiPaneOpen} isMobile={isMobile} isTablet={isTablet} ref={ref} onAnimationEnd={scrollChatBottomByDefault}>
          <FreeChatWrapper>
            <AiPaneHeader>
              <HeaderTitle>
                <AiAssistantIcon width={28} height={28} fill={theme.font.strong} />
                AI Assistant
              </HeaderTitle>
              <CloseBtn onClick={onClose}>
                <CloseInfoIcon width={14} height={14} fill={theme.action.default} />
              </CloseBtn>
            </AiPaneHeader>
            {errorMessage && (
              <Banner icon={<InfoIcon width={26} height={26} />} title="Error" description={errorMessage} bgColor={theme.context.error} />
            )}
            <Tabs tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab} />
            <TabsLine />
            <div style={{ paddingTop: '40px' }}>
              <FileSelector
                files={filesForAIAssistant}
                setSelectedFile={setSelectedFileForAi}
                selectedFile={selectedFileForAi}
                showRadioButton={!modalWindow.isOpen}
                showDragonDrop={showDragonDrop}
                setShowDragonDrop={setShowDragonDrop}
                handleUploadFile={handleUploadFile}
                onDropHandler={onDropHandler}
                drag={drag}
                setDrag={setDrag}
                trialMode={trialLimits.trialMode}
              />
            </div>
            <ChatWrapper ref={contentRef}>
              {chatHistory.map((chatItem, index) => (
                <React.Fragment key={index}>
                  {chatItem.prompt ? (
                    <ChatItem>
                      <div>
                        <UserChatIcon fill={theme.border.base} style={{ marginTop: '7px' }} />
                      </div>
                      {chatItem.prompt}
                    </ChatItem>
                  ) : (
                    <></>
                  )}
                  {chatItem.reply ? (
                    <ChatAnswerWrap ref={(ref) => (answerWrapRefs.current[index] = ref)}>
                      <ChatItem>
                        <div>
                          <AiAssistantIcon width={28} height={28} style={{ margin: '8px 0 0 10px' }} fill={theme.font.base} />
                        </div>
                        {chatItem.reply}
                      </ChatItem>
                      <AllSuggestions
                        citations={chatItem.citations}
                        investmentId={params?.strategyId ?? null}
                        recordId={documentRecord?.id}
                        scrollToBottom={(scrollBehavior) => scrollToBottom(index, scrollBehavior)}
                      />
                    </ChatAnswerWrap>
                  ) : (
                    <></>
                  )}
                </React.Fragment>
              ))}
            </ChatWrapper>
            <InputWrap isTextareaExpand={textareaRef?.current && parseInt(textareaRef.current.style.height)} isTrialMode={trialLimits.trialMode}>
              {generateChatReplyLoading ? (
                <SuggestingAnswersWrap isTrialMode={trialLimits.trialMode}>
                  <CustomLoader size={28} />
                  Suggesting Answers For Selected Documents
                </SuggestingAnswersWrap>
              ) : (
                <BasicTooltip shouldShowContent={!selectedFileForAi} tooltipContent={'You must select a file before asking a question.'}>
                  <TextAreaWrapper>
                    <CustomStyledTextarea
                      isTextareaExpand={textareaRef?.current && parseInt(textareaRef.current.style.height)}
                      isDisabled={!selectedFileForAi || generateChatReplyLoading}
                      ref={textareaRef}
                      height="44px"
                      placeholder="Ask a question"
                      value={chatPrompt}
                      onChange={(event) => {
                        setChatPrompt(event.target.value);

                        event.target.style.height = 'auto';
                        event.target.style.height = `${event.target.scrollHeight < 45 ? '44px' : event.target.scrollHeight}px`;
                      }}
                      onKeyDown={handlePressEnter}
                      disabled={!selectedFileForAi}
                      rows={1}
                    />

                    <SendBtn isDisabled={!selectedFileForAi} onClick={selectedFileForAi ? handleGetChatReply : () => null}>
                      <SendIcon fill={selectedFileForAi ? theme.action.primary : theme.font.disabled} />
                    </SendBtn>
                  </TextAreaWrapper>
                </BasicTooltip>
              )}
            </InputWrap>
          </FreeChatWrapper>
        </AiPaneWrapper>
      </>
    );
  }

  return (
    <AiPaneWrapper isAiPaneOpen={isAiPaneOpen} isMobile={isMobile} isTablet={isTablet} ref={ref} onAnimationEnd={scrollChatBottomByDefault}>
      <AiPaneHeader>
        <HeaderTitle>
          <AiAssistantIcon width={28} height={28} fill={theme.font.strong} />
          AI Assistant
        </HeaderTitle>
        <CloseBtn onClick={onClose}>
          <CloseInfoIcon width={14} height={14} fill={theme.action.default} />
        </CloseBtn>
      </AiPaneHeader>
      <Banner icon={<InfoIcon width={26} height={26} />} title="Error" description="Something Went Wrong" bgColor={theme.context.error} />
    </AiPaneWrapper>
  );
};

const displayPane = keyframes`
  to {
    max-width: 600px;
  }
`;

const displayMobilePane = keyframes`
  to {
    max-width: 100%;
  }
`;

const AiPaneWrapper = styled.div<{ isMobile: boolean; isTablet: boolean; isAiPaneOpen: boolean }>`
  position: sticky;
  /* margin-top: -50px; */
  margin: 0 auto;
  height: 100vh;
  max-width: ${({ isMobile, isTablet }) => (isMobile || isTablet ? '100%' : '0px')};
  width: 100%;
  display: ${({ isAiPaneOpen }) => (isAiPaneOpen ? 'block' : 'none')};
  top: ${({ isMobile, isTablet }) => (isMobile || isTablet ? '50px' : 0)};
  background: ${({ theme }) => theme.layer[1]};
  box-shadow: -14px 0px 12px -10px rgba(0, 0, 0, 0.15);
  z-index: 101;
  padding: 0 20px;
  animation: ${({ isMobile, isTablet }) => (isMobile || isTablet ? displayMobilePane : displayPane)} 0.2s linear forwards;
`;

const AiPaneHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
`;

const HeaderTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  font-weight: 600;
  font-size: 28px;
  color: ${({ theme }) => theme.font.strong};
`;

const CloseBtn = styled.div`
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  min-width: 40px;
  border-radius: 50%;
  border: 1px solid ${({ theme }) => theme.action.default};
  background: ${({ theme }) => theme.layer.base};
`;

const AiPaneBodyWrapper = styled.div`
  padding-top: 40px;
`;

const FreeChatWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const SelectedDocument = styled.div<{ withBorderBottom?: boolean }>`
  margin: 0 20px;
  padding: 10px 0;
  border-bottom: ${({ withBorderBottom, theme }) => (withBorderBottom ? `1px solid ${theme.border.base}` : 'none')};
  font-weight: 400;
  font-size: 16px;
  color: ${({ theme }) => theme.font.weak};
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
`;

const TextDescription = styled.div`
  padding: 0 20px;
  color: ${({ theme }) => theme.font.base};
  font-family: Blinker;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 20px;
`;

const BoldText = styled.span`
  font-weight: 700;
`;

const UploadedDocumnts = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 15px 20px 0 35px;
  margin-bottom: 5px;
`;

const CheckBoxWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  font-weight: 600;
  font-size: 16px;
`;

const UploadedDocumentItem = styled.div<{ disabled?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${({ disabled, theme }) => (disabled ? theme.font.disabled : theme.font.base)};
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};

  #input {
    opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  }
`;

const CustomLoader = styled(Loader)`
  padding: 0;
`;

const SuggestionButton = styled(Button)`
  margin-top: 20px;
  display: flex;
  align-items: center;
  gap: 6px;
  width: 100%;
`;

const InputWrap = styled.div<{ isTextareaExpand?: number | null; isTrialMode: boolean }>`
  padding: 20px 0;
  border-top: 1px solid ${({ theme }) => theme.border.base};
  display: flex;
  width: 95%;
  height: ${({ isTrialMode }) => (isTrialMode ? '130px' : '80px')};
  position: absolute;
  bottom: ${({ isTextareaExpand }) => (isTextareaExpand && isTextareaExpand > 60 ? '20px' : '0px')};
  background-color: ${({ theme }) => theme.layer[1]};

  #basicTooltip {
    width: 100%;
  }
`;

const SuggestingAnswersWrap = styled.div<{ isTrialMode: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: 100%;
  color: ${({ theme }) => theme.font.disabled};
  font-size: 19px;
  font-weight: 600;
  margin-bottom: ${({ isTrialMode }) => (isTrialMode ? '50px' : '0')};
`;

const TextAreaWrapper = styled.div`
  position: relative;
`;

const CustomStyledTextarea = styled.textarea<{ height: string; isDisabled: boolean; isTextareaExpand?: number | null }>`
  width: 100%;
  border-radius: 4px;
  padding: 10px 8px;
  font-family: 'Blinker';
  font-weight: 300;
  font-size: 16px;
  padding: 10px 45px 10px 10px;
  background-color: ${({ isDisabled, theme }) => (isDisabled ? theme.action.disabled : theme.layer[1])};
  overflow-y: ${({ isTextareaExpand }) => (isTextareaExpand && isTextareaExpand > 80 ? 'auto' : 'hidden')};
  height: ${({ height }) => height};
  max-height: 70px;
  box-sizing: border-box;
  resize: none;
  color: ${({ theme }) => theme.font.base};

  &:focus {
    outline: none;
  }
`;

const SendBtn = styled.div<{ isDisabled: boolean }>`
  position: absolute;
  right: 20px;
  bottom: 12px;
  cursor: ${({ isDisabled }) => (isDisabled ? 'auto' : 'pointer')};
`;

const ChatWrapper = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
  overscroll-behavior: none;
  position: relative;
  margin-bottom: 100px;
`;

const ChatItem = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  font-weight: 300;
  font-size: 16px;
  color: ${({ theme }) => theme.font.base};
  padding: 5px;
`;

const ChatAnswerWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const AdvancedOptions = styled.span`
  margin-top: 10px;
  display: flex;
  justify-content: center;
  padding: 10px 0;
  cursor: pointer;
  color: ${({ theme }) => theme.font.action};
  font-weight: 600;
`;
