import { getUTCDate, isValidDate } from '@/components/allocations-page/utils';
import BasicTooltip from '@/components/basicComponents/tooltip';
import {
  MobileRow,
  MobileRowTitle,
  MobileRowValue,
  MobileTableRowWrap,
  MobileTableWrapper,
  NoResults
} from '@/components/table/mobileTable/mobileTable';
import guid from '@/utils/guid';
import { format } from 'date-fns';
import { ROLE, firmTableColumns } from '../../constants';
import { IUser } from '../../types';

interface MobileTableProps {
  firmTableTableData: IUser[];
}

const MobileTable = ({ firmTableTableData }: MobileTableProps) => {
  const TableRow = ({ el }: { el: IUser }) => {
    return (
      <MobileTableRowWrap key={guid()}>
        <MobileRow>
          <MobileRowTitle>{firmTableColumns[0].title}</MobileRowTitle>
          <MobileRowValue>{el.email}</MobileRowValue>
        </MobileRow>

        <MobileRow>
          <MobileRowTitle>{firmTableColumns[1].title}</MobileRowTitle>
          <MobileRowValue>
            {el.lastLogin && isValidDate(new Date(el.lastLogin)) && (
              <BasicTooltip tooltipContent={`${getUTCDate(new Date(el.lastLogin))} ${format(new Date(el.lastLogin), 'h:mm a')}`} zIndex={1000}>
                {getUTCDate(new Date(el.lastLogin))}
              </BasicTooltip>
            )}
          </MobileRowValue>
        </MobileRow>

        <MobileRow>
          <MobileRowTitle>{firmTableColumns[2].title}</MobileRowTitle>
          <MobileRowValue>{el.firstName}</MobileRowValue>
        </MobileRow>

        <MobileRow>
          <MobileRowTitle>{firmTableColumns[3].title}</MobileRowTitle>
          <MobileRowValue>{el.lastName}</MobileRowValue>
        </MobileRow>

        <MobileRow>
          <MobileRowTitle>{firmTableColumns[4].title}</MobileRowTitle>
          <MobileRowValue>{el.mobileNumber}</MobileRowValue>
        </MobileRow>

        <MobileRow>
          <MobileRowTitle>{firmTableColumns[5].title}</MobileRowTitle>
          <MobileRowValue>
            {el?.roles?.length > 1 ? (
              <BasicTooltip
                tooltipWidth={250}
                tooltipContent={el.roles
                  .map((role) => ROLE[role])
                  .toString()
                  .replace(/,/g, ', ')}
                zIndex={1000}
              >
                Multiple ({el.roles.length})
              </BasicTooltip>
            ) : (
              el?.roles && ROLE[el.roles[0]]
            )}
          </MobileRowValue>
        </MobileRow>
      </MobileTableRowWrap>
    );
  };

  return (
    <MobileTableWrapper>
      {firmTableTableData.length ? (
        firmTableTableData.map((el: any, index: number) => {
          return <TableRow el={el} key={index} />;
        })
      ) : (
        <NoResults>No Results</NoResults>
      )}
    </MobileTableWrapper>
  );
};

export default MobileTable;
