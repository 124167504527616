import Label from '@/components/basicComponents/label';
import ModalWrappedContent from '@/components/basicComponents/modal/modalWrappedContent';
import { ITier } from '@/components/dueDiligence-page/dueDiligenceRecord/types';
import {
  CREATE_DUE_DILIGENCE_TIER,
  GET_FUNNEL_QUERY,
  UPDATE_DRAFT_DUE_DILIGENCE_TIER,
  UPDATE_DUE_DILIGENCE_TIER
} from '@/components/settings-page/queries';
import { useMutation } from '@apollo/client';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { saveOnEnter } from '../utils';
import { MainSearch, ModalContainer, ModalTitle } from './addFunnel';

interface AddTierProps {
  onClose: () => void;
  isOpen: boolean;
  currentTier: ITier | null;
  currentId: string;
  funnelId: string | null;
  numberOfTiers: number;
  isLimitedEdits: boolean;
}

const AddTier = ({ onClose, isOpen, currentTier, currentId, funnelId, numberOfTiers, isLimitedEdits }: AddTierProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const [value, setValue] = useState(currentTier ? currentTier.name : '');

  const [createTier] = useMutation(CREATE_DUE_DILIGENCE_TIER, {
    update(cache, { data }) {
      const currentData = cache.readQuery({
        query: GET_FUNNEL_QUERY,
        variables: {
          id: queryParams.get('funnelId')
        }
      });

      const { getDueDiligenceFunnel }: any = currentData;

      cache.writeQuery({
        query: GET_FUNNEL_QUERY,
        data: {
          getDueDiligenceFunnel: {
            ...getDueDiligenceFunnel,
            tiers: [
              ...getDueDiligenceFunnel.tiers,
              {
                ...data.createDueDiligenceFunnelTier,
                checklistItems: [],
                sections: []
              }
            ]
          }
        }
      });
    }
  });
  const [updateTier] = useMutation(UPDATE_DUE_DILIGENCE_TIER);
  const [updateDraftTier] = useMutation(UPDATE_DRAFT_DUE_DILIGENCE_TIER);

  const saveValue = () => {
    if (currentTier && isLimitedEdits) {
      updateTier({
        variables: {
          data: {
            id: currentId,
            name: value
          }
        },
        onCompleted: () => {
          onClose();
        }
      });

      return;
    }

    if (currentTier) {
      updateDraftTier({
        variables: {
          data: {
            id: currentId,
            name: value
          }
        }
      });
    } else {
      createTier({
        variables: {
          data: {
            funnelId: funnelId,
            name: value,
            order: numberOfTiers + 1
          }
        }
      });
    }
    onClose();
  };

  return (
    <ModalWrappedContent isOpen={isOpen} onClose={onClose} confirmButton={{ name: 'Save', onClick: saveValue, disabled: !value }} showRequiredFields>
      <ModalTitle>{currentTier ? 'Edit Tier' : 'Add New Tier'}</ModalTitle>
      <ModalContainer onKeyDown={(event) => saveOnEnter(event, saveValue, !value)}>
        <Label required>Name</Label>
        <MainSearch
          type="text"
          placeholder="Give this Tier a unique name"
          value={value}
          onChange={(event) => setValue(event.target.value)}
          autoFocus
        />
      </ModalContainer>
    </ModalWrappedContent>
  );
};

export default AddTier;
