import styled, { useTheme } from 'styled-components';

import { CloseInfoIcon } from '@/assets/icons/info-icons/closeInfo';
import { Mode } from '@/components/allocations-page/types';
import Button from '@/components/fat-basicComponents/button';
import { useResponsive } from '@/hooks/use-responsive';
import { formatCash } from '@/utils/formatCash';
import { useMemo } from 'react';
import BasicTooltip from '../../basicComponents/tooltip';
import { IAllocationRow } from './types';

interface FooterModalProps {
  mode: Mode;
  setMode: (value: Mode) => void;
  openModalWindow: (type: string) => void;
  summary: any;
  selectedRows: IAllocationRow[];
}

const FooterModal = ({ mode, setMode, openModalWindow, summary, selectedRows }: FooterModalProps) => {
  const { isMobile, isTablet } = useResponsive();
  const theme = useTheme();

  const totalValue = useMemo(() => {
    return selectedRows
      .filter((selectedRow) => selectedRow.decision)
      .reduce((accumulator, currentValue) => accumulator + currentValue.committedCapital, 0);
  }, [selectedRows]);

  const totalYesSelected = useMemo(() => {
    return selectedRows.filter((selectedRow) => selectedRow.decision).length;
  }, [selectedRows]);

  return (
    <FooterModalWrap isMobile={isMobile} isTablet={isTablet}>
      {mode === Mode.VIEW ? (
        <>
          <BasicTooltip
            tooltipWidth={320}
            shouldShowContent={false}
            tooltipContent={'Your permissions do not allow you to edit any of the allocations shown here.'}
          >
            <Button size="lg" onClick={() => setMode(Mode.EDIT)}>
              Edit
            </Button>
          </BasicTooltip>

          <FooterDescription>
            You have
            <BackWrap>
              <Count>{summary.count}</Count>
            </BackWrap>
            allocations to review (
            <BoldInfo>
              {summary.yesCount} marked Yes totaling {formatCash(summary.totalAmount || 0)}
            </BoldInfo>
            )
          </FooterDescription>
        </>
      ) : (
        <EditWrap>
          <BtnWrap>
            <Button
              size="lg"
              onClick={() => openModalWindow('submit-to')}
              styleType={!selectedRows.length ? 'disabled' : 'default'}
              icon={
                <BackEditWrap disabled={!selectedRows.length}>
                  <EditCount disabled={!selectedRows.length}>{selectedRows.length}</EditCount>
                </BackEditWrap>
              }
            >
              Confirm Allocation
            </Button>
            <EditDesc>
              (
              <BoldInfo>
                {totalYesSelected} marked Yes totaling {formatCash(totalValue)}
              </BoldInfo>
              )
            </EditDesc>
          </BtnWrap>

          <ExitMyTask onClick={() => setMode(Mode.VIEW)}>
            <CloseInfoIcon width={18} height={18} fill={theme.action.primary} />
            Exit
          </ExitMyTask>
        </EditWrap>
      )}
    </FooterModalWrap>
  );
};

const EditDesc = styled.div`
  font-family: Blinker, serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0;
  text-align: left;
  color: ${({ theme }) => theme.font.base};
`;

const BtnWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  @media (max-width: 990px) {
    flex-direction: column;
    gap: 10px;
  }
`;

const Count = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 13px;
`;

const EditCount = styled(Count)<{ disabled: boolean }>`
  color: ${({ disabled, theme }) => (disabled ? theme.font.disabled : theme.font.strong)};
`;

const BackWrap = styled.div`
  background-color: ${({ theme }) => theme.footer.base};
  display: inline-block;
  border-radius: 12px;
  color: ${({ theme }) => theme.context.light};
  padding: 2px 12px;
  font-size: 13px;
  margin: 0 5px;
`;

const BackEditWrap = styled(BackWrap)<{ disabled: boolean }>`
  line-height: 15px;
  background-color: ${({ disabled, theme }) => (disabled ? theme.layer[2] : theme.layer[1])};
`;

const ExitMyTask = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 45px;
  border: 1px solid ${({ theme }) => theme.action.primary};
  border-radius: 5px;
  font-family: Blinker, serif;
  font-size: 19px;
  font-weight: 600;
  line-height: 27px;
  letter-spacing: 0;
  color: ${({ theme }) => theme.action.primary};
  gap: 13px;
`;

const EditWrap = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const FooterModalWrap = styled.div<{ isMobile: boolean; isTablet: boolean }>`
  width: ${({ isMobile, isTablet }) => (isMobile || isTablet ? '100%' : 'calc(100% - 80px)')};
  display: flex;
  align-items: center;
  padding: 20px 80px;
  position: fixed;
  bottom: 0;
  clear: both;
  background-color: ${({ theme }) => theme.layer[1]};
  border-top: 1px solid ${({ theme }) => theme.border.base};
  max-height: 90px;
  z-index: 30;
`;

const FooterDescription = styled.div`
  padding-left: 20px;
  font-family: Blinker, serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: ${({ theme }) => theme.font.base};
`;

const BoldInfo = styled.div`
  display: inline-block;
  font-weight: 700;
  font-size: 16px;
  color: ${({ theme }) => theme.font.base};
`;

export default FooterModal;
