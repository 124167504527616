import { createMongoAbility } from '@casl/ability';
import { ACTIONS } from '../components/recommendations-page/types';
import { Allocation } from '../types/investments';
import { User } from '../types/user';
import get from 'lodash/get';

const interpolate = (template: string, vars: object) => {
  return JSON.parse(template, (_, rawValue) => {
    if (rawValue[0] !== '$') {
      return rawValue;
    }

    const name = rawValue.slice(2, -1);
    const value = get(vars, name);

    if (typeof value === 'undefined') {
      throw new ReferenceError(`Variable ${name} is not defined`);
    }

    return value;
  });
};

type Actions = 'manage' | 'create' | 'read' | 'update' | 'delete';
type Subjects = 'Todo' | Allocation | 'all';

export const buildAbilityFor = (user: User) => {
  if (user) {
    const roles = user.roles;
    const interpolatedPermissions = interpolate(JSON.stringify(user.permissions), { user });
    return createMongoAbility(interpolatedPermissions);
  }
};
