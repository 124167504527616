import styled from 'styled-components';

import ModalWrappedContent from '@/components/fat-basicComponents/modal/modalWrappedContent';
import { useResponsive } from '@/hooks/use-responsive';
import { ITier } from '../dueDiligenceRecord/types';

interface BackToProps {
  onClose: () => void;
  isOpen: boolean;
  currentRow: any;
  backToPreviousTier: (tierData: ITier) => void;
}

const BackTo = ({ onClose, isOpen, backToPreviousTier, currentRow }: BackToProps) => {
  const { isMobile } = useResponsive();

  return (
    <ModalWrappedContent
      isOpen={isOpen}
      onClose={onClose}
      title={'Send Back To Previous Tier'}
      confirmButton={{
        name: `${isMobile ? 'Yes' : 'Yes, Send To Previous Tier'}`,
        onClick: () => {
          backToPreviousTier(currentRow);
          onClose();
        }
      }}
    >
      <ModalText>Are you sure you want back to previous tier?</ModalText>
    </ModalWrappedContent>
  );
};

export default BackTo;

const ModalText = styled.p`
  font-weight: 400;
  font-size: 19px;
  line-height: 27px;
  text-align: center;
  color: ${({ theme }) => theme.font.base};
`;
