import FormattingTooltip from '@/components/basicComponents/tooltip/dollarsTooltip';
import ModalWrappedContent from '@/components/fat-basicComponents/modal/modalWrappedContent';
import { Count, CountWrap, Text } from '@/components/fat-dealCatalog-page/fat-allocator-page/fat-modals/submitTo';
import { Loader } from '@/components/loaders/loader';
import { createAllocationMutation, deleteAllocationMutation } from '@/components/opportunities-entity/pendingAllocation/constants';
import { Allocation } from '@/types/investments';
import { useMutation } from '@apollo/client';
import { useState } from 'react';
import styled from 'styled-components';

interface ConfirmationProps {
  isOpen: boolean;
  onClose: () => void;
  createdAllocations: any;
  deletedCommittedAllocations: Allocation[];
  deletedPendingAllocations: Allocation[];
  totalDeletedAllocations: { committedAllocations: number; pendingAllocations: number };
  refetchAllocationConflicts: () => void;
  closeResolveConflictsPage: () => void;
}

const Confirmation = ({
  isOpen,
  onClose,
  createdAllocations,
  deletedCommittedAllocations,
  deletedPendingAllocations,
  totalDeletedAllocations,
  refetchAllocationConflicts,
  closeResolveConflictsPage
}: ConfirmationProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const totalCreatedAllocations = createdAllocations.reduce((sum: number, value: any) => (sum += Number(value.committedCapital)), 0);

  const [deleteAllocation] = useMutation(deleteAllocationMutation);
  const [createAllocations] = useMutation(createAllocationMutation);

  const saveValue = async () => {
    setIsLoading(true);
    if (createdAllocations.length) {
      const dataToUpdate = createdAllocations.map(({ comparisonIndex, ...allocations }: any) => allocations);

      await createAllocations({
        variables: {
          data: dataToUpdate
        }
      });
    }

    if (deletedCommittedAllocations.length) {
      for await (const allocation of deletedCommittedAllocations) {
        deleteAllocation({
          variables: {
            allocationId: allocation.id
          }
        });
      }
    }

    if (deletedPendingAllocations.length) {
      for await (const allocation of deletedPendingAllocations) {
        deleteAllocation({
          variables: {
            allocationId: allocation.id
          }
        });
      }
    }

    await refetchAllocationConflicts();
    await closeResolveConflictsPage();
    setIsLoading(false);
  };

  return (
    <ModalWrappedContent
      isOpen={isOpen}
      onClose={onClose}
      showCancelButton={!isLoading}
      showCloseIcon={!isLoading}
      isUseOutsideClick={!isLoading}
      title="Confirmation"
      confirmButton={{
        name: 'Yes, Confirm',
        onClick: saveValue,
        disabled: isLoading
      }}
    >
      <ModalBody>
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <Text fontWeight="400">Please confirm your changes before saving. This action cannot be undone.</Text>
            {!createdAllocations.length || (
              <Text fontWeight="400">
                <CountWrap>
                  <Count>{createdAllocations.length}</Count>
                </CountWrap>
                committed allocation(s) will be Created totaling <FormattingTooltip zIndex={1000}>{totalCreatedAllocations}</FormattingTooltip>
              </Text>
            )}
            {!deletedCommittedAllocations.length || (
              <Text fontWeight="400">
                <CountWrap>
                  <Count>{deletedCommittedAllocations.length}</Count>
                </CountWrap>
                committed allocation(s) will be Deleted totaling{' '}
                <FormattingTooltip zIndex={1000}>{totalDeletedAllocations.committedAllocations}</FormattingTooltip>
              </Text>
            )}
            {!deletedPendingAllocations.length || (
              <Text fontWeight="400">
                <CountWrap>
                  <Count>{deletedPendingAllocations.length}</Count>
                </CountWrap>
                pending allocation(s) will be Deleted totaling{' '}
                <FormattingTooltip zIndex={1000}>{totalDeletedAllocations.pendingAllocations}</FormattingTooltip>
              </Text>
            )}
          </>
        )}
      </ModalBody>
    </ModalWrappedContent>
  );
};

export default Confirmation;

const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
`;
