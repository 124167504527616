import DropdownActions from '@/components/table/fat-dropdownActions';
import {
  MobileRow,
  MobileRowTitle,
  MobileRowValue,
  MobileTableRowWrap,
  MobileTableWrapper,
  NoResults
} from '@/components/table/mobileTable/mobileTable';
import guid from '@/utils/guid';
import styled from 'styled-components';
import { tenantsTableColumns } from '../../constants';
import { ITenant } from '../../types';

interface MobileTableProps {
  tenantsTableTableData?: ITenant[];
  openModalWindow: (type: string, tenant?: ITenant) => void;
}

const MobileTable = ({ tenantsTableTableData, openModalWindow }: MobileTableProps) => {
  const TableRow = ({ tenant }: { tenant: ITenant }) => {
    const truncatedName = tenant.name.length > 30 ? `${tenant.name.substring(0, 30)}...` : tenant.name;
    const truncatedEmail = tenant.primaryAdmin?.email.length > 30 ? `${tenant.primaryAdmin.email.substring(0, 30)}...` : tenant.primaryAdmin?.email;

    return (
      <MobileTableRowWrap key={guid()}>
        <MobileRow>
          <MobileRowTitle>{tenantsTableColumns[0].title}</MobileRowTitle>
          <MobileRowValue>{truncatedName}</MobileRowValue>
        </MobileRow>

        <MobileRow>
          <MobileRowTitle>{tenantsTableColumns[1].title}</MobileRowTitle>
          <MobileRowValue>
            {truncatedEmail}
          </MobileRowValue>
        </MobileRow>

        <DropdownActionsWrapper>
          <DropdownActions
            isMobile
            items={[
              {
                name: 'Edit',
                onClick: () => openModalWindow('edit', tenant)
              },
              {
                name: 'Delete',
                onClick: () => openModalWindow('delete', tenant)
              },
            ]}
            isListCenter
          />
        </DropdownActionsWrapper>
      </MobileTableRowWrap>
    );
  };

  return (
    <MobileTableWrapper>
      {tenantsTableTableData?.length ? (
        tenantsTableTableData?.map((el: any, index: number) => {
          return <TableRow tenant={el} key={index} />;
        })
      ) : (
        <NoResults>No Results</NoResults>
      )}
    </MobileTableWrapper>
  );
};

export default MobileTable;

const DropdownActionsWrapper = styled.div`
  margin: 0 auto;
`;
