import { PendingInvestments } from '@/components/fat-pendingInvestments-page';
import { Helmet as Head } from 'react-helmet';
import useRefresh from '../../hooks/use-refresh';

const PendingInvestmentsPage = () => {
  useRefresh();
  return (
    <>
      <Head>
        <title>Pending Commitments</title>
      </Head>
      <PendingInvestments />
    </>
  );
};

export default PendingInvestmentsPage;
