export const columns = [
  { title: 'Entity', key: 'ENTITY', width: 16, keySort: 'entity' },
  { title: 'Strategy', key: 'STRATEGY', width: 13, keySort: 'strategy' },
  { title: 'Import Total', key: 'IMPORT_TOTAL', width: 13, keySort: 'import total' },
  { title: 'Import Count', key: 'IMPORT_COUNT', width: 10, keySort: 'import count' },
  { title: 'Committed Total', key: 'COMMITTED_TOTAL', width: 13, keySort: 'committed total' },
  { title: 'Pending Amount', key: 'PENDING_AMOUNT', width: 13, keySort: 'pending amount' },
  { title: 'Status', key: 'STATUS', width: 12, keySort: 'status' },
  { title: ' ', key: ' ', width: 10 },
];

export const allocationConflictsStatusSuccess = {
  allMatched: 'There Are No Allocation Conflicts',
  continue: ' Your commitments are ready to be saved.',
  orContinue: 'Your commitments are ready to be saved.',
  viewAll: 'View All Validated Allocations',
};

export const allocationConflictsStatusError = {
  text: 'Allocation Conflicts',
  description:
    'There are Allocation Conflicts that need to be reconciled.  Please reconcile the Allocations before saving and completing this process.',
};
