import styled, { keyframes } from 'styled-components';

import { Tooltip } from '../tooltip';

export const TableWrapper = styled.div<{ padding?: string }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 6px;
  padding: ${({ padding }) => padding ?? '10px 0 30px'};
`;
export const TableBodyWrapper = styled.div<{ padding?: string }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

export const blinkingFrame = keyframes`
  0% {
    opacity: 1;
  }
  25% {
    opacity: 0.4;
  }
  50% {
    opacity: 1;
  }
  75% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
`;

export const TableRowWrapper = styled.div<{
  addAdaptive?: boolean;
  loading?: boolean | number;
}>`
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 60px;
  background-color: ${({ theme }) => theme.layer[1]};
  border-radius: 8px;
  padding: 0 24px;
  gap: 5px;
  opacity: ${({ loading }) => (loading ? 0.5 : 1)};
  pointer-events: ${({ loading }) => (loading ? 'none' : 'auto')};
  animation: ${({ loading }) => (loading ? blinkingFrame : null)} 1.5s infinite;

  @media (max-width: 1200px) {
    padding: 0 ${({ addAdaptive }) => (addAdaptive ? 10 : 24)}px;
  }

  &:hover #infoWrap {
    opacity: 1;
  }

  &:hover #actionText {
    color: ${({ theme }) => theme.font.action};
  }

  &:hover #actionCurioText {
    color: ${({ theme }) => theme.font.action};
  }

  &:hover #actionOrangeText {
    color: #ef9208;
  }

  &:hover #tempInfoWrap {
    display: none;
  }

  &:hover #arrowInSelectRow {
    svg {
      path {
        fill: #ef9208;
      }
    }
  }

  &:hover #selector {
    outline: 1px solid ${({ theme }) => theme.border.base} !important;
    background-color: ${({ theme }) => theme.layer[1]};
  }

  &:hover #selectIcon {
    opacity: 1;
  }

  &:hover {
    background-color: ${({ theme }) => theme.layer[12]};
    transition: background-color 100ms ease;
  }
`;

export const TableRowCell = styled.div<{ width?: number }>`
  display: flex;
  justify-content: flex-start;
  width: ${({ width }) => (width === undefined ? 'auto' : `${width}%`)};
`;

export const TitleCell = styled.span<{ color?: string }>`
  font-family: Blinker, serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0;
  text-align: left;
  color: ${({ theme }) => theme.font.base};
  @media (max-width: 1200px) {
    font-size: 15px;
  }
`;

export const TextCell = styled(TitleCell)<{ bold?: boolean }>`
  font-weight: ${({ bold }) => (bold ? 700 : 400)};
`;

export const CellWrapper = styled.div<{
  width?: string;
  flexDirection?: 'row' | 'column';
  alignItems?: 'flex-start' | 'center';
  justifyContent?: 'space-between';
  isMobile?: boolean;
}>`
  width: ${({ width }) => width ?? 'auto'};
  display: flex;
  align-items: ${({ alignItems }) => alignItems ?? 'flex-start'};
  flex-direction: ${({ flexDirection }) => flexDirection ?? 'row'};
  justify-content: ${({ justifyContent }) => justifyContent ?? 'flex-start'};
  gap: ${({ isMobile }) => isMobile && '8px'};
`;

export const CustomTooltip = styled(Tooltip)`
  font-family: Blinker, serif;
  border: 1px solid #c7cbd2;
  border-radius: 6px;
  background: #f0f1f3;
  padding: 10px 20px;
  font-weight: 400;
  font-size: 12px;
  color: #373f4e;
`;
