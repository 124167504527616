import styled, { useTheme } from 'styled-components';

import { NewIcon } from '@/assets/static/icons/todo-page/new';
import { OlderIcon } from '@/assets/static/icons/todo-page/older';
import { Assignee } from '@/types/selectors/enums';
import guid from '@/utils/guid';
import React from 'react';
import { statusNextStepRelations } from '../client-details/constants';
import { Line } from './todo-list';

export interface AssignedToMe {
  new: number;
  old: number;
  status: string;
  total: number;
}

export interface OwnedByMeNotAssignedToMe {
  new: number;
  old: number;
  status: string;
  total: number;
}

interface CardProps {
  data: {
    assignedToMe: AssignedToMe[];
    ownedByMeNotAssignedToMe: OwnedByMeNotAssignedToMe[];
  };
  isMobile: boolean;
  isTablet: boolean;
  isPortrait: boolean;
  onRedirect?: any;
  seTab?: any;
}

export const AssignedToMeItem = ({ data, isMobile, onRedirect, seTab, isTablet, isPortrait }: CardProps) => {
  const theme = useTheme();

  return (
    <SubItemWrap>
      {data?.assignedToMe?.map((assigned) => (
        <React.Fragment key={guid()}>
          {assigned.total > 0 ? (
            <StatusWrap
              key={guid()}
              onClick={() => {
                onRedirect(Assignee.ASSIGNED_TO_ME, assigned.status);
              }}
              onMouseEnter={() => seTab(assigned.status)}
            >
              <StatusCount>{assigned.total}</StatusCount>
              <StatusTitle>{statusNextStepRelations[assigned.status as keyof typeof statusNextStepRelations] || assigned.status}</StatusTitle>
            </StatusWrap>
          ) : null}

          {assigned.new > 0 ? (
            <CounterWrap
              onClick={() => {
                onRedirect(Assignee.ASSIGNED_TO_ME, assigned.status);
              }}
              onMouseEnter={() => seTab(assigned.status)}
            >
              <NewIcon width={26.5} height={24} />
              <CounterTitle color={theme.context.success}>{assigned.new}</CounterTitle>
            </CounterWrap>
          ) : null}

          {assigned.old > 0 ? (
            <CounterWrap
              onClick={() => {
                onRedirect(Assignee.ASSIGNED_TO_ME, assigned.status);
              }}
              onMouseEnter={() => seTab(assigned.status)}
            >
              <OlderIcon width={26.5} height={24} />
              <CounterTitle color={theme.context.warning}>{assigned.old}</CounterTitle>
            </CounterWrap>
          ) : null}
          {assigned.total > 0 || assigned.new > 0 || assigned.old > 0 ? <Line isMobile={isMobile} /> : null}
        </React.Fragment>
      ))}
    </SubItemWrap>
  );
};

export const OwnedByMeItem = ({ data, isMobile, onRedirect, seTab, isTablet, isPortrait }: CardProps) => {
  const theme = useTheme();

  return (
    <SubItemWrap>
      {data?.ownedByMeNotAssignedToMe?.map((owned) => (
        <React.Fragment key={guid()}>
          {owned.total > 0 ? (
            <StatusWrap
              onClick={() => {
                onRedirect(Assignee.OWNED_BY_ME_ASSIGNED_TO_SOMEONE_ELSE, owned.status);
              }}
              onMouseEnter={() => seTab(owned.status)}
            >
              <StatusCount>{owned.total}</StatusCount>
              <StatusTitle>{statusNextStepRelations[owned.status as keyof typeof statusNextStepRelations] || owned.status}</StatusTitle>
            </StatusWrap>
          ) : null}

          {owned.new > 0 ? (
            <CounterWrap
              onClick={() => {
                onRedirect(Assignee.OWNED_BY_ME_ASSIGNED_TO_SOMEONE_ELSE, owned.status);
              }}
              onMouseEnter={() => seTab(owned.status)}
            >
              <NewIcon width={26.5} height={24} />
              <CounterTitle color={theme.context.success}>{owned.new}</CounterTitle>
            </CounterWrap>
          ) : null}

          {owned.old > 0 ? (
            <CounterWrap
              onClick={() => {
                onRedirect(Assignee.OWNED_BY_ME_ASSIGNED_TO_SOMEONE_ELSE, owned.status);
              }}
              onMouseEnter={() => seTab(owned.status)}
            >
              <OlderIcon width={26.5} height={24} />
              <CounterTitle color={theme.context.warning}>{owned.old}</CounterTitle>
            </CounterWrap>
          ) : null}
          {owned.total > 0 || owned.new > 0 || owned.old > 0 ? <Line isMobile={isMobile} /> : null}
        </React.Fragment>
      ))}
    </SubItemWrap>
  );
};

const SubItemWrap = styled.div`
  display: flex;
  gap: 12px;
  cursor: pointer;
  width: 100%;
  flex-wrap: wrap;
  align-items: center;
`;

const StatusWrap = styled.div`
  display: flex;
  align-items: center;
`;

const CounterWrap = styled.div`
  align-items: center;

  display: flex;
  gap: 6px;
`;

const StatusCount = styled.div`
  font-family: Blinker, serif;
  font-size: 20px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0;
  color: ${({ theme }) => theme.font.action};
  margin-right: 4px;
`;

const StatusTitle = styled.div`
  font-family: Blinker, serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0;
  color: ${({ theme }) => theme.font.action};
`;

const CounterTitle = styled.div<{ color: string }>`
  font-family: Blinker, serif;
  font-size: 20px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0;
  color: ${({ color }) => color};
`;
