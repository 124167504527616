import { styled } from 'styled-components';

export const Title = styled.span`
  font-weight: 600;
  font-size: 28px;
  color: ${({ theme }) => theme.font.strong};
`;

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 50px;
`;
