import Button from '@/components/fat-basicComponents/button';
import NoResults from '@/components/table/noResults';
import { TableRowCell, TableRowWrapper, TextCell } from '@/components/table/styles';
import guid from '@/utils/guid';
import styled from 'styled-components';
import { InvestorEntitiesRowStatus } from '../../../investorEntitiesRowStatus';
import { IEntityImportComparison, TypeWindow } from '../../../types';
import { columns } from '../constants';

interface TableBodyProps {
  tableData: IEntityImportComparison[];
  openEntityImportDetailPage: (type: TypeWindow, row: IEntityImportComparison) => void;
}

const TableBody = ({ tableData, openEntityImportDetailPage }: TableBodyProps) => {
  if (!tableData.length) {
    return <NoResults />;
  }

  return (
    <>
      {tableData.map((row) => (
        <CustomTableRowWrapper key={guid()}>
          <TableRowCell width={columns[0].width}>
            <TextCell>{row.entityCRMId}</TextCell>
          </TableRowCell>
          <TableRowCell width={columns[1].width}>
            <TextCell>{row.entityName}</TextCell>
          </TableRowCell>
          <TableRowCell width={columns[2].width}>
            <TextCell>
              <TextCell>{row.investorName}</TextCell>
            </TextCell>
          </TableRowCell>
          {/* <TableRowCell width={columns[3].width}>
            <TextCell >{row.fieldsModifiedCount}</TextCell>
          </TableRowCell> */}
          <TableRowCell width={columns[3].width}>
            <TextCell>
              <InvestorEntitiesRowStatus status={row.status} />
            </TextCell>
          </TableRowCell>
          <TableRowCell width={columns[4].width}>
            {row.status !== 'toDelete' && (
              <ButtonWrap id="infoWrap">
                <Button size="sm" onClick={() => openEntityImportDetailPage('entityImportDetail', row)}>
                  View
                </Button>
              </ButtonWrap>
            )}
          </TableRowCell>
        </CustomTableRowWrapper>
      ))}
    </>
  );
};

export default TableBody;

const CustomTableRowWrapper = styled(TableRowWrapper)`
  justify-content: space-between;
`;

const ButtonWrap = styled.div`
  opacity: 0;
`;
