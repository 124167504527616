import { MobileRow, MobileRowTitle, MobileRowValue, MobileTableRowWrap } from '@/components/table/mobileTable/mobileTable';
import { useMemo } from 'react';
import styled from 'styled-components';

import guid from '../../../utils/guid';
import FormattingTooltip from '../../basicComponents/tooltip/dollarsTooltip';
import { Loader } from '../../loaders/loader';
import { TableColumns } from '../../table/types';
import { SummaryTableData } from '../types';

type MobileTableProps = {
  columns: TableColumns[];
  clientsRows: SummaryTableData[];
  loading: boolean;
};

type RowSumType = {
  total: string;
  target: number;
  targetAllocations: number;
  exposure: number;
  capacity: number;
};

const MobileSumFooter = ({ columns, clientsRows, loading }: MobileTableProps) => {
  const rowSum: RowSumType = useMemo(() => {
    const sum = {
      total: 'Total',
      target: 0,
      targetAllocations: 0,
      exposure: 0,
      capacity: 0
    };
    for (let i = 0; i < clientsRows.length; i++) {
      sum.target += clientsRows[i].target;
      sum.targetAllocations += clientsRows[i].targetAllocations;
      sum.exposure += clientsRows[i].exposure;
      sum.capacity += clientsRows[i].capacity;
    }
    return sum;
  }, [clientsRows]);
  return (
    <>
      {!loading ? (
        clientsRows && (
          <MobileSumFooterWrap key={guid()}>
            <MobileRow>
              <MobileRowTitle>{columns[0].title}</MobileRowTitle>
              <MobileRowValue>{rowSum.total}</MobileRowValue>
            </MobileRow>

            <MobileRow>
              <MobileRowTitle>{columns[1].title}</MobileRowTitle>
              <MobileRowValue>{rowSum.target.toFixed(0)}%</MobileRowValue>
            </MobileRow>

            <MobileRow>
              <MobileRowTitle>{columns[2].title}</MobileRowTitle>
              <MobileRowValue>
                <FormattingTooltip>{rowSum.targetAllocations}</FormattingTooltip>
              </MobileRowValue>
            </MobileRow>

            <MobileRow>
              <MobileRowTitle>{columns[3].title}</MobileRowTitle>
              <MobileRowValue>
                <FormattingTooltip>{rowSum.exposure}</FormattingTooltip>
              </MobileRowValue>
            </MobileRow>

            <MobileRow>
              <MobileRowTitle>{columns[4].title}</MobileRowTitle>
              <MobileRowValue>
                <FormattingTooltip>{rowSum.capacity}</FormattingTooltip>
              </MobileRowValue>
            </MobileRow>
          </MobileSumFooterWrap>
        )
      ) : (
        <Loader />
      )}
    </>
  );
};

const MobileSumFooterWrap = styled(MobileTableRowWrap)`
  background-color: ${({ theme }) => theme.layer[2]};
`;

export default MobileSumFooter;
