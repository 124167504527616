import Button from '@/components/fat-basicComponents/button';
import { useAuth } from '@/hooks/use-auth';
import { Helmet as Head } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import useRefresh from '../../hooks/use-refresh';

const NotFound = () => {
  useRefresh();
  const { user } = useAuth();
  const navigate = useNavigate();

  return (
    <>
      <Head>
        <title>Error: Not Found | Material Kit Pro</title>
      </Head>
      <MainBox>
        <NotFoundTitle>404: The page you are looking for isn’t here</NotFoundTitle>
        <NotFoundSubTitle>You either tried some shady route or you came here by mistake. Whichever it is, try using the navigation.</NotFoundSubTitle>
        <CenterBox>
          <NotFoundImage alt="Under development" src={`/static/error/error404_light.svg`} />
        </CenterBox>
        <CenterBox>
          {user && (
            <Button
              styleType="outline"
              size="md"
              onClick={() => {
                navigate(user.tenant.type === 'advisoryFirm' ? '/dealCatalog' : '/dealPipeline');
              }}
            >{`Back To ${user.tenant.type === 'advisoryFirm' ? 'Deal Catalog' : 'Deal Pipeline'}`}</Button>
          )}
        </CenterBox>
      </MainBox>
    </>
  );
};

export default NotFound;

const MainBox = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 80px 0;
  min-height: 100vh;
  overflow: auto;
`;

const NotFoundTitle = styled.h1`
  font-weight: 300;
  font-size: 3.5rem;
  line-height: 1.375;
  color: ${({ theme }) => theme.font.strong};
`;

const NotFoundSubTitle = styled.p`
  margin-top: 4px;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.57;
  color: ${({ theme }) => theme.font.base};
`;

const CenterBox = styled.div`
  margin-top: 48px;
`;

const NotFoundImage = styled.img`
  height: auto;
  max-width: 100%;
  width: 400px;
`;
