import { Box, Checkbox as MuiCheckbox } from '@mui/material';
import React from 'react';
import { styled } from 'styled-components';

interface CheckboxProps {
  checked: boolean;
  onChange: (value: boolean) => void;
  className?: string;
}

export const Checkbox: React.FC<CheckboxProps> = ({ checked, onChange, className }) => {
  return (
    <CustomCheckbox
      className={className}
      checked={checked}
      onChange={(event) => onChange(event.target.checked)}
      checkedIcon={<img src="/static/icons/light-tick.svg" alt="tick" />}
      icon={<Box sx={{ height: 14, width: 16 }} />}
    />
  );
};

const CustomCheckbox = styled(MuiCheckbox)`
  background-color: ${(props) => props.theme.primary.dark};

  &.MuiCheckbox-root {
    background-color: ${(props) => props.theme.primary.dark};
    padding: 6px 4px;
    border-radius: 4px;
    border: 1px solid ${(props) => props.theme.secondary.light};
  }

  &.Mui-checked {
    background-color: ${(props) => props.theme.font.base};
    padding: 6px 4px;
    border-radius: 4px;
    border-width: 1px;
    border-style: solid;
    border-color: ${(props) => props.theme.secondary.light};
  }
`;
