import { MobileRow, MobileRowTitle, MobileRowValue, MobileTableRowWrap } from '@/components/table/mobileTable/mobileTable';
import styled from 'styled-components';

import { getUTCDate } from '@/components/allocations-page/utils';
import DropdownActions from '@/components/table/fat-dropdownActions';
import { TextCell } from '@/components/table/styles';
import guid from '@/utils/guid';
import { columns } from '../constatnts';
import { IRecordData } from '../types';

type MobileTableProps = {
  tasksCount: number;
  records: IRecordData[];
  handleTierClick: (row: IRecordData) => void;
  openModalWindow: (type: string, row: IRecordData) => void;
  handleModifyDiligenceClick: (row: IRecordData) => void;
  updateDueDiligenceRecordLoading: boolean;
  currentRow: IRecordData | null;
};

const MobileTable = ({
  tasksCount,
  records,
  handleTierClick,
  openModalWindow,
  handleModifyDiligenceClick,
  updateDueDiligenceRecordLoading,
  currentRow
}: MobileTableProps) => {
  const TableRow = ({ el }: { el: IRecordData }) => {
    return (
      <CustomMobileTableRowWrap key={guid()} loading={updateDueDiligenceRecordLoading && el.id === currentRow?.id ? 1 : 0}>
        <MobileRow>
          <MobileRowTitle>{columns[0].title}</MobileRowTitle>
          <MobileRowValue onClick={() => handleTierClick(el)} isRedirect>
            {el.investment.name}
          </MobileRowValue>
        </MobileRow>

        <MobileRow>
          <MobileRowTitle>{columns[1].title}</MobileRowTitle>
          <MobileRowValue>{el.owner.firstName + ' ' + el.owner.lastName}</MobileRowValue>
        </MobileRow>

        <MobileRow>
          <MobileRowTitle>{columns[2].title}</MobileRowTitle>
          <MobileRowValue>{el.investment?.finalClose && getUTCDate(new Date(el.investment.finalClose))}</MobileRowValue>
        </MobileRow>

        <MobileRow>
          <MobileRowTitle>{columns[3].title}</MobileRowTitle>
          <OpenTasksWrapper>
            <OpenTasks>
              <CountTasks>{el.activeTierOpenTasksCount}</CountTasks>
              <ProgressWrap>
                <Progress
                  isFull={Boolean(100 - (el.activeTierOpenTasksCount * 100) / (el.activeTier?.tasksCount ? el.activeTier.tasksCount : tasksCount))}
                  width={100 - (el.activeTierOpenTasksCount * 100) / (el.activeTier?.tasksCount ? el.activeTier.tasksCount : tasksCount)}
                />
              </ProgressWrap>
            </OpenTasks>
          </OpenTasksWrapper>
        </MobileRow>
        <DropdownActionsWrapper>
          <DropdownActions
            isMobile
            isListCenter
            items={[
              { name: 'View Tier', onClick: () => handleTierClick(el) },
              {
                name: 'Change Owner',
                onClick: () => openModalWindow('change-owner', el)
              },
              {
                name: 'Edit',
                onClick: () => handleModifyDiligenceClick(el)
              }
            ]}
          />
        </DropdownActionsWrapper>
      </CustomMobileTableRowWrap>
    );
  };

  return (
    <>
      {records.length ? (
        records.map((el, index) => {
          return <TableRow el={el} key={el.id + index} />;
        })
      ) : (
        <></>
      )}
    </>
  );
};

export default MobileTable;

const CustomMobileTableRowWrap = styled(MobileTableRowWrap)`
  margin-top: 20px;
`;

const OpenTasks = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
`;

const OpenTasksWrapper = styled(MobileRowValue)`
  display: flex;
  align-items: center;
`;

const CountTasks = styled(TextCell)`
  font-weight: 700;
  font-size: 28px;
  line-height: 39px;
`;

const ProgressWrap = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-start;
  overflow: hidden;
  width: 100%;
  height: 15px;
  border-radius: 10px;
  background: ${({ theme }) => theme.layer[2]};
  max-height: 20px;
`;

const Progress = styled.div<{ width: number | string; isFull: boolean }>`
  display: flex;
  width: ${({ width }) => (width ? `${width}%` : '100%')};
  height: 15px;
  background: ${({ isFull, theme }) => (isFull ? theme.context.success : theme.layer[2])};
  transition: 0.3s ease-in-out;
`;

const DropdownActionsWrapper = styled.div`
  margin: 0 auto;
`;
