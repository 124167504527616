import { useEffect, useState } from 'react';
import styled from 'styled-components';

import { SortIcon } from '@/assets/icons/sortIcon';
import guid from '@/utils/guid';
import { Checkbox } from '../checkbox';
import { StyledTooltip } from '../recommendations-page/styles';
import { initialSort, SIZE_DROPDOWN_ACTIONS } from './constants';
import { ISort, SORT_ORDER, TableColumns } from './types';

type CheckBox = {
  checked: boolean;
  onChange: (value: boolean) => void;
  show?: boolean;
};

interface TableHeaderProps {
  showActionButton?: boolean;
  columns: TableColumns[];
  withOutSort?: boolean;
  addAdaptive?: boolean;
  refetch: any;
  savedSort?: ISort;
  savedSetSort?: (value: ISort) => void;
  checkBox?: CheckBox;
  className?: string;
}

const TableHeader = ({
  refetch,
  columns,
  addAdaptive = false,
  showActionButton = false,
  withOutSort = false,
  savedSort,
  savedSetSort,
  checkBox,
  className
}: TableHeaderProps) => {
  const WIDTH = 100 / columns.length;
  const [sort, setSort] = useState<ISort>(initialSort);
  const [isHidden, setIsHidden] = useState(true);
  const [sortKey, setSortKey] = useState('');

  useEffect(() => {
    if (savedSort) {
      setSort(savedSort);
    }
  }, [savedSort]);

  const getStatusSort = (keySort: string) => {
    if (sort.asc) {
      return {
        forBoolean: `Sort ${keySort}  • No -> Yes`,
        forStatuses: `Sort ${keySort} • ${
          keySort === 'status' || keySort === 'asset class'
            ? 'A -> Z'
            : `earlier ${keySort === 'suitability' ? '' : 'statuses'} -> later ${keySort === 'suitability' ? '' : 'statuses'}`
        }`,
        forNumbers: `Sort ${keySort} • Min -> Max`,
        forDates: `Sort ${keySort} • oldest -> newest`,
        forStrings: `Sort ${keySort} • A -> Z`
      };
    } else {
      return {
        forBoolean: `Sort ${keySort} • Yes -> No`,
        forStatuses: `Sort ${keySort} • ${
          keySort === 'status' || keySort === 'asset class'
            ? 'Z -> A'
            : `later ${keySort === 'suitability' ? '' : 'statuses'} -> earlier ${keySort === 'suitability' ? '' : 'statuses'}`
        }`,
        forNumbers: `Sort ${keySort} • Max -> Min`,
        forDates: `Sort ${keySort} • newest -> oldest`,
        forStrings: `Sort ${keySort} • Z -> A `
      };
    }
  };

  const getToolTipTitle = (keySort: string) => {
    if (
      [
        'assignee(s)',
        'investment',
        'entity',
        'client/advisor',
        'manager',
        'client / advisor',
        'family',
        'name',
        'entityName',
        'assignee',
        'entityName',
        'investor',
        'owner'
      ].includes(keySort)
    ) {
      return getStatusSort(keySort).forStrings;
    } else if (
      [
        'next task',
        'suitability',
        'asset classes',
        'allocation status',
        'status',
        'asset class',
        'termination reason',
        'assetClass',
        'lastStatus'
      ].includes(keySort)
    ) {
      return getStatusSort(keySort).forStatuses;
    } else if (['last update', 'status date', 'next close', 'final close', 'finalClose', 'nextClose', 'lastStatusDate'].includes(keySort)) {
      return getStatusSort(keySort).forDates;
    } else if (['custodied', 'ubti blocker', 'sri', 'decision'].includes(keySort)) {
      return getStatusSort(keySort).forBoolean;
    } else {
      return getStatusSort(keySort).forNumbers;
    }
  };

  const handleSort = (key: string, order: boolean) => {
    setSort({
      key,
      asc: order
    });
    refetch({
      order: order ? SORT_ORDER.ASC : SORT_ORDER.DESC,
      sort: key
    });
    if (savedSetSort) {
      savedSetSort({
        key,
        asc: order
      });
    }
  };

  return (
    <TableHeaderWrap addAdaptive={addAdaptive} className={className}>
      {checkBox?.show && <CustomCheckbox checked={checkBox.checked} onChange={checkBox.onChange} />}
      {columns.map(({ key, title, width, keySort }) => (
        <TableColumnItem width={width ?? WIDTH} key={guid()}>
          <TableColumnTitle onClick={key ? () => handleSort(key as string, !sort.asc) : () => null}>{title}</TableColumnTitle>
          {key && !withOutSort ? (
            <StyledTooltip placement={'bottom'} title={getToolTipTitle(keySort as string)} arrow>
              <SortBtn
                onClick={() => handleSort(key as string, !sort.asc)}
                isRotate={key === sort.key && sort.asc}
                isActive={key === sort.key}
                addAdaptive={addAdaptive}
              >
                <SortIcon
                  style={{
                    marginBottom: -3,
                    marginTop: key === sort.key && sort.asc ? -2 : 0
                  }}
                  fill={key === sort.key ? '#FAFAFA' : '#4587EC'}
                />
              </SortBtn>
            </StyledTooltip>
          ) : !isHidden && keySort === sortKey && !withOutSort ? (
            <StyledTooltip placement={'bottom'} title={getToolTipTitle(keySort as string)} arrow>
              <SortBtn
                onClick={() => handleSort(key as string, !sort.asc)}
                isRotate={key === sort.key && sort.asc}
                isActive={key === sort.key}
                addAdaptive={addAdaptive}
              >
                <SortIcon
                  style={{
                    marginBottom: -3,
                    marginTop: key === sort.key && sort.asc ? -2 : 0
                  }}
                  fill={key === sort.key ? '#FAFAFA' : '#4587EC'}
                />
              </SortBtn>
            </StyledTooltip>
          ) : (
            <></>
          )}
        </TableColumnItem>
      ))}
      {showActionButton && <ActionButtonSpace />}
    </TableHeaderWrap>
  );
};

export const TableHeaderWrap = styled.div<{ addAdaptive: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  min-height: 70px;
  background-color: #36474f;
  border-radius: 8px;
  padding: 0 24px;
  gap: 5px;
  @media (max-width: 1200px) {
    padding: ${({ addAdaptive }) => ` 0 ${addAdaptive ? 10 : 24}px`};
  }
`;

export const SortBtn = styled.div<{
  isActive: boolean;
  isRotate: boolean;
  addAdaptive?: boolean;
}>`
  display: ${({ isActive }) => (isActive ? 'flex' : 'none')};
  transform: ${({ isRotate }) => (isRotate ? 'rotate(180deg)' : 'rotate(0deg)')};
  cursor: pointer;
  justify-content: center;
  align-items: center;
  width: ${({ addAdaptive }) => (addAdaptive ? 20 : 24)}px;
  min-width: ${({ addAdaptive }) => (addAdaptive ? 20 : 24)}px;
  height: ${({ addAdaptive }) => (addAdaptive ? 20 : 24)}px;
  border-radius: 50%;
  background-color: ${({ isActive }) => (isActive ? '#3ab070' : 'transparent')};
`;

export const TableColumnItem = styled.div<{ width: number }>`
  width: ${({ width }) => width}%;
  //min-width: ${({ width }) => width}%;
  display: flex;
  align-items: center;
  gap: 5px;

  &:hover ${SortBtn} {
    display: flex;
  }
`;

export const TableColumnTitle = styled.span`
  flex-basis: 0;
  color: #fafafa;
  font-family: 'Blinker', serif;
  font-weight: 600;
  font-size: 12px;
  line-height: 22.4px;
  text-transform: uppercase;
  align-items: flex-start;
  cursor: pointer;
`;
export const ActionButtonSpace = styled.div`
  width: ${SIZE_DROPDOWN_ACTIONS}px;
`;
export const CustomCheckbox = styled(Checkbox)`
  margin-right: 15px;
`;

export default TableHeader;
