import { IRecordData } from '@/components/dueDiligence-page/types';
import DropdownActions from '@/components/table/fat-dropdownActions';
import {
  MobileRow,
  MobileRowTitle,
  MobileRowValue,
  MobileTableRowWrap,
  MobileTableWrapper,
  NoResults
} from '@/components/table/mobileTable/mobileTable';
import guid from '@/utils/guid';
import styled from 'styled-components';
import { fixedColumns } from '../constatnts';
import { ColumnList } from '../types';
import { actionDropdownItems } from '../utils';
import { rowValue } from './tableBody';

type MobileTableProps = {
  dealRegistryRows: IRecordData[];
  columnsList: ColumnList[];
  moduleConfig: any;
  openModalWindow: (row: IRecordData, type: string) => void;
  handleModifyDealClick: (row: IRecordData) => void;
  handleClickDeal: (row: IRecordData) => void;
  handleUpdateInvestmentStatus: (row: IRecordData, status: string) => void;
  openInvestmentForAllocations: (row: IRecordData, isEdit?: boolean) => void;
  openManagerUpdates: (row: IRecordData) => void;
};

const MobileTable = ({
  dealRegistryRows,
  columnsList,
  openModalWindow,
  handleModifyDealClick,
  handleClickDeal,
  handleUpdateInvestmentStatus,
  openInvestmentForAllocations,
  openManagerUpdates,
  moduleConfig
}: MobileTableProps) => {
  const TableRow = ({ el }: { el: IRecordData }) => {
    return (
      <CustomMobileTableRowWrap key={guid()}>
        <MobileRow>
          <MobileRowTitle>{fixedColumns[0].title}</MobileRowTitle>
          <FixedMobileRowValue isRedirect>
            <div onClick={() => handleClickDeal(el)}>{el.investment.name}</div>
            <DropdownActionsWrapper>
              <DropdownActions
                isMobile
                items={actionDropdownItems(
                  el,
                  moduleConfig,
                  handleClickDeal,
                  handleModifyDealClick,
                  openModalWindow,
                  handleUpdateInvestmentStatus,
                  openInvestmentForAllocations,
                  openManagerUpdates
                )}
              />
            </DropdownActionsWrapper>
          </FixedMobileRowValue>
        </MobileRow>
        {columnsList.map((column, index) => {
          return (
            column.value && (
              <MobileRow key={column.keySort + index}>
                <MobileRowTitle>{column.title}</MobileRowTitle>
                <MobileRowValue key={column.key}>{rowValue(el, column)}</MobileRowValue>
              </MobileRow>
            )
          );
        })}
      </CustomMobileTableRowWrap>
    );
  };

  return (
    <MobileTableWrapper>
      {dealRegistryRows.length ? (
        dealRegistryRows.map((el: IRecordData) => {
          return <TableRow key={el.id} el={el} />;
        })
      ) : (
        <NoResults>No Results</NoResults>
      )}
    </MobileTableWrapper>
  );
};

export default MobileTable;

const CustomMobileTableRowWrap = styled(MobileTableRowWrap)`
  margin-top: 5px;
`;

const FixedMobileRowValue = styled(MobileRowValue)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
`;

const DropdownActionsWrapper = styled.div`
  width: max-content;
`;
