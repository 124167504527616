import FilterSelect from '@/components/basicComponents/select';
import { IListEntity } from '@/components/fat-investors-page/types';
import { IEntityCodesTableData } from '@/components/fat-settings-page/importData/types';
import {
  MobileRow,
  MobileRowTitle,
  MobileRowValue,
  MobileTableRowWrap,
  MobileTableWrapper,
  NoResults
} from '@/components/table/mobileTable/mobileTable';
import styled from 'styled-components';
import { columns } from '../constants';
import { RowStatus } from './cell/rowStatus';

interface MobileTableProps {
  tableData: IEntityCodesTableData[];
  entityList: IListEntity[];
  entityListLoading: boolean;
  handleChangeAssignment: (entityName: string, prevValue: string, entityCode: string) => void;
  resetAssignment: (entityCode: string, prevValue: string) => void;
}

const MobileTable = ({ tableData, entityList, entityListLoading, handleChangeAssignment, resetAssignment }: MobileTableProps) => {
  const TableRow = ({ el }: { el: IEntityCodesTableData }) => {
    return (
      <MobileTableRowWrap key={el.id}>
        <MobileRow>
          <MobileRowTitle>{columns[0].title}</MobileRowTitle>
          <MobileRowValue>{el.entityCode}</MobileRowValue>
        </MobileRow>

        <MobileRow>
          <MobileRowTitle>{columns[1].title}</MobileRowTitle>
          <MobileRowValue>{el.entityName}</MobileRowValue>
        </MobileRow>

        <MobileRow>
          <MobileRowTitle>{columns[2].title}</MobileRowTitle>
          <MobileRowValue>
            {el.entityStatus === 'Assigned' ? (
              <>{el.entityAssignment}</>
            ) : (
              <AssignmentSelect
                data={entityList.map((entity) => `${entity.entityName} (${entity.tenant.name})`)}
                selected={el.entityAssignment}
                setSelected={(value) => handleChangeAssignment(value, el.entityAssignment, el.entityCode)}
                width="100%"
                openZIndex={35}
                loading={entityListLoading}
                withSearch
                clearSelectionButton={{ defaultValue: 'Select An Entity', onClick: () => resetAssignment(el.entityCode, el.entityAssignment) }}
              />
            )}
          </MobileRowValue>
        </MobileRow>

        <MobileRow>
          <MobileRowTitle>{columns[3].title}</MobileRowTitle>
          <MobileRowValue>
            <RowStatus status={el.entityStatus} />
          </MobileRowValue>
        </MobileRow>
      </MobileTableRowWrap>
    );
  };

  return (
    <MobileTableWrapper>
      {tableData.length ? (
        tableData.map((el: IEntityCodesTableData, index: number) => {
          return <TableRow el={el} key={index} />;
        })
      ) : (
        <NoResults>No Results</NoResults>
      )}
    </MobileTableWrapper>
  );
};

export default MobileTable;

const AssignmentSelect = styled(FilterSelect)`
  border-radius: 4px;
  #titleSelect {
    color: #202020;
    font-size: 16px;
    line-height: 22.4px;
  }
`;
