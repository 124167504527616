import React, { useMemo } from 'react';
import styled from 'styled-components';

import jfgTabs from '@/constants/tabs/jfgTabs';
import { useAuth } from '@/hooks/use-auth';
import { useResponsive } from '@/hooks/use-responsive';
import { useLocation, useNavigate } from 'react-router-dom';

type HeaderProps = {
  modalControl?: JSX.Element;
  specialControl?: JSX.Element;
  title?: string;
};

const Header = ({ specialControl, modalControl, title }: HeaderProps) => {
  const { user, isAuthenticated } = useAuth();
  const navigate = useNavigate();

  const { pathname } = useLocation();
  const selectedTabIndex = jfgTabs.findIndex((tab) => pathname.includes(tab.path.split('/')[0])) ?? 0;
  const { isMobile, isDesktop, isTablet, isLargeDesktop } = useResponsive();
  const lastLogin = useMemo(() => {
    const lastLogin = isAuthenticated && user?.lastLogin ? new Date(user.lastLogin) : '';
    const lastLoginDate = lastLogin.toLocaleString('en-US', {
      month: 'short',
      day: 'numeric',
      year: 'numeric'
    });

    const lastLoginTime = lastLogin
      .toLocaleString('en-US', {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true
      })
      .toLowerCase()
      .replace(' ', '');

    return lastLoginDate.toUpperCase() + ' ' + 'at' + ' ' + lastLoginTime;
  }, [user]);

  const styledImage = jfgTabs[selectedTabIndex]?.icon
    ? React.cloneElement(jfgTabs[selectedTabIndex].icon, {
        fill: 'black',
        width: isMobile ? 33 : 40,
        height: isMobile ? 33 : 40
      })
    : null;

  return (
    <HeaderWrap modalControl={!!modalControl} isMobile={isMobile} isTablet={isTablet}>
      {modalControl ? (
        modalControl
      ) : (
        <HeaderIconWrap>
          {styledImage}
          <HeaderTitleWrap>
            <HeaderTitle isTablet={isTablet} isMobile={isMobile}>
              {title ? title : jfgTabs[selectedTabIndex].label}
            </HeaderTitle>
            {jfgTabs[selectedTabIndex]?.subTitle && !isMobile && <HeaderSubTitle>{jfgTabs[selectedTabIndex].subTitle}</HeaderSubTitle>}
          </HeaderTitleWrap>
        </HeaderIconWrap>
      )}
      <SpecialControlWrap>
        {(isDesktop || isLargeDesktop || isTablet) && specialControl}
        <HeaderAccountWrap onClick={() => navigate('/profile')}>
          {isAuthenticated && (isDesktop || isLargeDesktop) && (
            <AboutUserWrap>
              {/* <CustomLetterAvatars user={user} size={40} />*/}
              {user?.lastLogin ? (
                <AccountInfoWrap>
                  <SilverText>
                    Welcome back <NameText>{user.firstName}</NameText>
                  </SilverText>
                  {user.lastLogin && <SilverText>{`Your last sign on was ${lastLogin}`}</SilverText>}
                </AccountInfoWrap>
              ) : (
                <></>
              )}
            </AboutUserWrap>
          )}
        </HeaderAccountWrap>
      </SpecialControlWrap>
    </HeaderWrap>
  );
};

const AboutUserWrap = styled.div`
  display: flex;
  padding: 8.5px 30px;
  border-radius: 30px;
  border: 1px solid #c7cbd2;
`;

const SpecialControlWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 38px;
`;

const SilverText = styled.div`
  display: flex;
  font-family: Blinker, serif;
  color: #7f7f7f;
  font-weight: 400;
  font-size: 13px;
`;

const NameText = styled.div`
  padding-left: 3px;
  font-family: Blinker, serif;
  color: #4587ec;
  font-weight: 600;
  font-size: 13px;
`;

const AccountInfoWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const HeaderAccountWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
`;

export const HeaderSubTitle = styled.div`
  font-family: Blinker, serif;
  font-size: 13px;
  line-height: 18px;
  font-weight: 400;
  color: #7f7f7f;
`;

export const HeaderTitle = styled.div<{ isMobile: boolean; isTablet: boolean }>`
  font-family: Blinker, serif;
  font-size: ${({ isMobile, isTablet }) => (isTablet ? '24px' : isMobile ? '20px' : '33px')};
  line-height: 46px;
  font-weight: 700;
  color: #2e2e2e;
`;

export const HeaderIconWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const HeaderTitleWrap = styled.div`
  display: flex;
  flex-direction: column;
`;

const HeaderWrap = styled.div<{ isMobile: boolean; isTablet: boolean; modalControl: boolean }>`
  display: flex;
  margin: ${({ isMobile }) => (isMobile ? '0' : '0 -24px')};
  justify-content: space-between;
  align-items: center;
  width: ${({ isMobile, modalControl }) => (modalControl && isMobile ? 'calc(100% + 44px)' : 'calc(100% + 48px)')};
  width: ${({ isMobile, modalControl }) => (modalControl && isMobile ? '100%' : 'calc(100% + 48px)')};
  top: ${({ isMobile, isTablet }) => (isMobile || isTablet ? '50px' : 0)};
  position: sticky;
  height: 80px;
  background: #f0f1f3;
  z-index: 101;
  padding: ${({ isMobile }) => (isMobile ? '0 30px' : '0 50px')};
  box-shadow:
    0 12px 16px rgba(0, 0, 0, 0.04),
    0 2px 8px rgba(0, 0, 0, 0.03),
    0 1.12694px 2.75474px rgba(0, 0, 0, 0.01);
  transition: 0.3s;
`;

export default Header;
