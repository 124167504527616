import { Loader } from '@/components/loaders/loader';
import { TableWrapper } from '@/components/table/styles';
import TableHeader from '@/components/table/tableHeader';
import { ISort } from '@/components/table/types';
import { useResponsive } from '@/hooks/use-responsive';
import styled from 'styled-components';
import { header } from './constants';
import MobileTable from './mobile-table';
import TableBody from './tabelBody';

type ManagerDetailsTable = {
  managersInvestmentRows?: any;
  loading?: boolean;
  refetch?: any;
  savedSort: ISort;
  onChangeSort: (value: ISort) => void;
};

const Table = ({ managersInvestmentRows, loading, refetch, savedSort, onChangeSort }: ManagerDetailsTable) => {
  const { isMobile, isTablet } = useResponsive();
  return (
    <TableWrapper padding="10px 0">
      {isMobile ? (
        <MobileTable columns={header} managersRows={managersInvestmentRows} loading={loading} />
      ) : (
        <>
          <CustomTableHeader isTablet={isTablet} columns={header} refetch={refetch} savedSort={savedSort} savedSetSort={onChangeSort} />
          {loading ? (
            <Loader />
          ) : managersInvestmentRows ? (
            <>
              <TableBody columns={header} managersInvestmentRows={managersInvestmentRows} />
            </>
          ) : null}
        </>
      )}
    </TableWrapper>
  );
};

export default Table;

const CustomTableHeader = styled(TableHeader)<{ isTablet: boolean }>`
  position: sticky;
  top: ${({ isTablet }) => (isTablet ? '130px' : '80px')};
  z-index: 10;
  padding-top: 0;
`;
