import { ClientEntitySummary, ClientEntitySummaryTableType } from '@/components/client-entity-details/types';
import { IDataFields } from './types';

const getDecimalPart = (number: number): string => {
  const decimalPart = (number % 1).toFixed(2).slice(2);
  return parseFloat(decimalPart) !== 0 ? decimalPart : '0';
};

const hasTotalEqualHundred = (summary: ClientEntitySummaryTableType) => {
  const { privateEquityPercentage, privateCreditPercentage, privateRealAssetPercentage, diversifyingStrategiesPercentage } = summary;

  if (Math.round(privateEquityPercentage + privateCreditPercentage + privateRealAssetPercentage + diversifyingStrategiesPercentage) === 100) {
    return { privateEquityPercentage, privateCreditPercentage, privateRealAssetPercentage, diversifyingStrategiesPercentage };
  }
  return {
    privateEquityPercentage: '25',
    privateCreditPercentage: '25',
    privateRealAssetPercentage: '25',
    diversifyingStrategiesPercentage: '25'
  };
};

export const getDefaultDataFields = (entityData: ClientEntitySummary, id: string) => ({
  id,
  exportId: entityData.exportId ? entityData.exportId : '',
  entityCRMId: entityData.entityCRMId ? entityData.entityCRMId : '',
  entityName: entityData.name ? entityData.name : '',
  totalEntityValue: entityData.totalEntitiesValue ? entityData.totalEntitiesValue.toLocaleString('en-US') : '',
  privateCapital: entityData.privatePercentage ? entityData.privatePercentage : '',
  overCommitRatio: entityData.targetOCRatio ? getDecimalPart(entityData.targetOCRatio) : '',
  privateEquityTarget: hasTotalEqualHundred(entityData.summary).privateEquityPercentage,
  privateCreditTarget: hasTotalEqualHundred(entityData.summary).privateCreditPercentage,
  privateRealAssetTarget: hasTotalEqualHundred(entityData.summary).privateRealAssetPercentage,
  diversifyingStrategiesTarget: hasTotalEqualHundred(entityData.summary).diversifyingStrategiesPercentage,
  fundBiteSize: entityData.biteSize.fundBiteSize ? entityData.biteSize.fundBiteSize.toLocaleString('en-US') : '',
  directBiteSize: entityData.biteSize.directBiteSize ? entityData.biteSize.directBiteSize.toLocaleString('en-US') : '',
  onHold: entityData.onHold ? 'Yes' : 'No',
  custodied: entityData.custodied ? 'Yes' : 'No',
  ubtiBlocker: entityData.ubtiBlocker ? 'Yes' : 'No',
  sectorsToAvoid: entityData?.sectorsToAvoid?.filter((sector) => sector !== ''),
  ignoreBiteSize: entityData.ignoreBiteSize,
  ignoreAllocationTargets: entityData.ignoreAllocationTargets
});

export const buildDataToUpdate = (dataFields: IDataFields) => ({
  id: dataFields.id,
  exportId: dataFields.exportId,
  entityCRMId: dataFields.entityCRMId,
  entityName: dataFields.entityName,
  totalEntityValue: Number(dataFields.totalEntityValue.replace(/\D/g, '')),
  privatePercentage: Number(dataFields.privateCapital),
  overCommitRatio: Number('1.' + dataFields.overCommitRatio),
  privateCreditPercentage: Number(dataFields.privateCreditTarget),
  privateEquityPercentage: Number(dataFields.privateEquityTarget),
  privateRealAssetPercentage: Number(dataFields.privateRealAssetTarget),
  diversifyingStrategiesPercentage: Number(dataFields.diversifyingStrategiesTarget),
  directBiteSize: Number(dataFields.directBiteSize.replace(/\D/g, '')),
  fundBiteSize: Number(dataFields.fundBiteSize.replace(/\D/g, '')),
  ubtiBlocker: dataFields.ubtiBlocker === 'Yes',
  // custodied: dataFields.custodied === 'Yes',
  onHold: dataFields.onHold === 'Yes',
  sectorsToAvoid: dataFields.sectorsToAvoid.filter((item) => item !== 'None'),
  ignoreBiteSize: dataFields.ignoreBiteSize,
  ignoreAllocationTargets: dataFields.ignoreAllocationTargets
});

export const isDataReadyToSave = (dataFields: IDataFields, totalTargets: number) => {
  return (
    dataFields.entityName !== '' &&
    ((totalTargets === 100 &&
      dataFields.privateCapital !== '' &&
      dataFields.totalEntityValue !== '' &&
      dataFields.overCommitRatio !== '' &&
      dataFields.privateEquityTarget !== '' &&
      dataFields.privateCreditTarget !== '' &&
      dataFields.privateRealAssetTarget !== '') ||
      dataFields.ignoreAllocationTargets) &&
    ((dataFields.fundBiteSize !== '' && dataFields.directBiteSize !== '') || dataFields.ignoreBiteSize)
  );
};
