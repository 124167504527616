import { FormFooter } from '@/components/fat-basicComponents/formFooter';
import Header from '@/components/fat-header';
import { GoBackButton } from '@/components/fat-header/goBackButton';
import { PageTitle } from '@/components/fat-header/pageTitle';
import { useResponsive } from '@/hooks/use-responsive';
import { useGoBack } from '@/hooks/useGoBack';
import { MainWrap, PaddingWrap } from '@/styles/common';
import { useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';
import { CheckboxSection } from './checkboxSection';

const individualMode: any = [
  {
    title: 'Accredited Investor',
    subTitle: null,
    checkboxesData: [
      {
        title: 'Has Sufficient Net Worth',
        description:
          'Individual has individual net worth, or combined net worth with spouse, in excess of $1,000,000 (exclusive of primary residence). Net worth means the excess of total assets at fair market value over total liabilities.'
      },
      {
        title: 'Has Sufficient Income',
        description:
          'Has individual income (exclusive of any income attributable to spouse) of more than $200,000 for each of the past two years or joint income with spouse in excess of $300,000 in each of those years.'
      },
      {
        title: 'Has Professional Certifications',
        description:
          'Currently holds in good standing one or more of the professional certifications or designations established by the SEC as qualifying the holder as an accredited investor (currently, the FINRA Series 7 license, the FINRA Series 65 license, and the FINRA Series 82 license).'
      },
      {
        title: 'Knowledgeable employee',
        description:
          'Is a “knowledgeable employee” of the Fund, the General Partner or the Investment Manager or an affiliate thereof (as defined in SEC Rule 3c-5(a)(4) under the Company Act).'
      }
    ]
  },
  {
    title: 'Qualified Purchaser',
    subTitle: null,
    checkboxesData: [
      {
        title: 'Has $5M or more in Net Investments',
        description:
          'Individual owns Net Investments (as defined in Appendix B below) of $5 million or more; (note that this is really long not sure how we are going to show it)'
      },
      {
        title: 'Knowledgeable employee',
        description:
          'I am an employee of the Investment Manager and in connection with my regular functions or duties I participate in the investment activities of the Investment Manager and I have performed such activities for the Investment Manager or another investment adviser for at least 12 months.'
      }
    ]
  }
];

const nonIndividualMode: any = [
  {
    title: 'Accredited',
    subTitle: 'For Corporations, Partnerships, or LLCs:',
    checkboxesData: [
      {
        title: 'Corporation, LLC, Partnership, Business Trust',
        description: 'Subscriber has total assets in excess of $5,000,000, was not formed for the specific purpose of investing in the Fund'
      },
      {
        title: 'Family office as defined in Rule 202(a)(11)(G)-1',
        description:
          'Subscriber is a “family office” (as defined in SEC Rule 202(a)(11)(G)-1 under the Advisers Act) with AUM exceeding $5,000,000, not formed for the specific purpose of investing in a Series of the Fund'
      },
      {
        title: 'Entity owned entirely by accredited investors',
        description:
          'Subscriber hereby certifies that it is an accredited investor because all of its equity owners, unit owners and participants are accredited investors.'
      }
    ]
  },
  {
    title: null,
    subTitle: 'For Trusts:',
    checkboxesData: [
      {
        title: 'Irrevocable or personal non-business trust',
        description:
          'Subscriber has total assets in excess of $5,000,000, it was not formed for the specific purpose of investing in a Series of the Fund.'
      },
      {
        title: 'Bank Acting As Trustee',
        description:
          'Subscriber it is an accredited investor because it (i) is a bank as defined in Section 3(a)(2) of the Securities Act or a savings and loan association or other institution as defined in Section 3(a)(5)(A) of the Securities Act and (ii) is acting in its fiduciary capacity as trustee.'
      },
      {
        title: 'Revocable Trust',
        description:
          'Subscriber is a revocable trust which may be amended or revoked at any time by the grantors thereof and all of the grantors are accredited investors.'
      }
    ]
  },
  {
    title: null,
    subTitle: 'For Employee Benefit Plans:',
    checkboxesData: [
      {
        title: 'ERISA Plan with Plan Fiduciary',
        description:
          'Subscriber is an ERISA and the decision to invest in the Fund was made by a plan fiduciary (as defined in Section 3(21) of ERISA), which is either a bank, savings and loan association, insurance company or investment adviser registered as such under the Investment Advisers Act. Please state the name of such plan fiduciary.'
      },
      {
        title: 'ERISA Plan with $5M in Assets',
        description:
          'Subscriber is an accredited investor because it is an employee benefit plan within the meaning of ERISA, and it has total assets in excess of $5,000,000.'
      },
      {
        title: 'Participant Directed Employee Benefit Plan Account',
        description:
          'Subscriber is an accredited investor because it is a self-directed plan directed by a participant who is an accredited investor.'
      }
    ]
  },
  {
    title: null,
    subTitle: 'For Other Tax-Exempt Entities:',
    checkboxesData: [
      {
        title: '501c3 Organization',
        description:
          'Subscriber is an accredited investor because it is an organization described in Section 501(c)(3) of the Code, was not formed for the specific purpose of acquiring an Interest and has total assets in excess of $5,000,000.'
      },
      {
        title: 'Government Benefit Plan',
        description:
          'Subscriber is a plan established and maintained by a State, its political subdivisions, or any agency or instrumentality of a State or its political subdivisions for the benefit of its employees and has total assets in excess of $5,000,000.'
      }
    ]
  },
  {
    title: null,
    subTitle: 'For Banks:',
    checkboxesData: [
      {
        title: 'Bank Acting in its individual capacity',
        description:
          'Subscriber hereby certifies that it is an accredited investor because it is a bank as defined in Section 3()(2) of the Securities Act, or a savings and loan association or another institution as defined in Section 3(a)(5)(A) of the Securities Act, acting in its individual capacity.'
      }
    ]
  },
  {
    title: null,
    subTitle: 'For Insurance:',
    checkboxesData: [
      {
        title: 'Insurance Company',
        description:
          'Subscriber is an accredited investor because it is an insurance company as defined in Section 2(13) of the Securities Act, acting in its individual capacity. (“Insurance company” is defined in Section 2(13) of the Securities Act'
      }
    ]
  },
  {
    title: 'Qualified Purchaser',
    subTitle: null,
    checkboxesData: [
      {
        title: 'Private Investment Funds',
        description:
          'entity that (a) was not formed for the specific purpose of investing in a Series of the Fund; (b) would be an investment company but for the exclusions from investment company status in section 3(c)(7) thereof; (c) owns $25 million+ in Net Investments;  (d) each beneficial owner of the entity owns $25 million+ in Net Investments.'
      },
      {
        title: 'Employee Benefit Plans',
        description:
          'An employee benefit plan that (a) owns $25 million or more in Net Investments and (b) does not permit its participants to decide whether and how much to invest in particular investment alternatives, unless each participant is itself a QP.'
      },
      {
        title: 'Family Companies',
        description:
          'An entity that (a) was not formed for the specific purpose of investing in a Series of the Fund; (b) owns Net Investments of $5 million or more; and (c) is owned directly or indirectly by or for two or more natural persons who are related'
      },
      {
        title: 'Trusts',
        description:
          'A trust that was not formed for the specific purpose of investing in the Fund and with respect to which the trustee or other person authorized to make decisions with respect to the trust'
      },
      {
        title: 'Entities Generally',
        description:
          'Any entity--other than a private investment fund or employee benefit plan--that was not formed for the specific purpose of investing in the Fund and that owns and invests on a discretionary basis, for its own account or for the accounts of QPs, $25 million+in Net Investments.'
      },
      {
        title: 'Entities Composed Entirely of QPs',
        description:
          'Any entity all of whose securities are beneficially owned by QPs, whether or not the entity was formed for the specific purpose of investing in a Series of the Fund.'
      },
      {
        title: 'QIBs',
        description:
          'A qualified institutional buyer (“QIB”) as defined in SEC Rule 144A under the Securities Act that is acting for its own account or for the account of another QIB or a QP.'
      }
    ]
  }
];

export const EligibilityPage = () => {
  const { isMobile } = useResponsive();
  const theme = useTheme();
  const goBack = useGoBack();
  const {
    handleSubmit,
    getValues,
    setValue,
    control,
    setError,
    formState: { errors, isValid },
    reset,
    watch
  } = useForm();

  const [searchParams, setSearchParams] = useSearchParams();

  const backToTitle = searchParams.get('backToTitle') || '';

  const onSubmit = (data: any) => {
    console.log(data);
  };
  return (
    <MainWrap>
      <Header modalControl={<GoBackButton handleClose={() => goBack({ fallBack: '/investors' })} backToTitle={backToTitle} />} />
      <PageTitle title="Eligibility" />
      <PaddingWrap>
        <form id="eligibilityForm" onSubmit={handleSubmit(onSubmit, () => window.scrollTo(0, 0))}>
          <EligibilityWrapper isMobile={isMobile}>
            {nonIndividualMode.map((mode: any, index: number) => (
              <div key={index}>
                {mode.title && <FormTitle isMobile={isMobile}>{mode.title}</FormTitle>}
                {mode.subTitle && <FormSubTitle>{mode.subTitle}</FormSubTitle>}
                <CheckboxesWrapper isMobile={isMobile}>
                  {mode.checkboxesData.map((checkboxData: any, index: number) => (
                    <CheckboxSection key={checkboxData.title} title={checkboxData.title} description={checkboxData.description} />
                  ))}
                </CheckboxesWrapper>
              </div>
            ))}
          </EligibilityWrapper>
          <FormFooter onCancel={() => goBack({ fallBack: '/investors' })} disableSaveButton={false} showRequiredFieldsWarning={false} />
        </form>
      </PaddingWrap>
    </MainWrap>
  );
};

const EligibilityWrapper = styled.div<{ isMobile: boolean }>`
  margin-top: 50px;
  padding: ${({ isMobile }) => (isMobile ? '0 20px 20px' : '0 40px 40px')};
  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.border.base};
  background-color: ${({ theme }) => theme.layer[1]};
  display: flex;
  flex-direction: column;
`;

const FormTitle = styled.div<{ isMobile: boolean }>`
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  color: ${({ theme }) => theme.font.strong};
  padding-bottom: 10px;
  margin-top: ${({ isMobile }) => (isMobile ? '20px' : '40px')};
  border-bottom: 1px solid ${({ theme }) => theme.border.base};
`;

const FormSubTitle = styled.div`
  color: ${({ theme }) => theme.font.base};
  font-size: 16px;
  font-weight: 600;
  line-height: 28px;
  padding: 20px 0 10px;
`;

const CheckboxesWrapper = styled.div<{ isMobile: boolean }>`
  padding: ${({ isMobile }) => (isMobile ? '20px 10px 10px;' : '30px 20px 20px')};
`;
