import React from 'react';
import { TableRowWrapper, TextCell } from './styles';

const NoResults = () => {
  return (
    <TableRowWrapper>
      <TextCell>No Results</TextCell>
    </TableRowWrapper>
  );
};

export default NoResults;
