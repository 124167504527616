export const MANAGER_UPDATES_EDITABLE_ROLES = ['Super Admin', 'Admin', 'Investment Lead', 'Investment Member'];

export const columns = [
  { title: 'Strategy', key: 'STRATEGY', width: 20, keySort: 'strategy' },
  { title: 'Manager', key: 'MANAGER', width: 20, keySort: 'manager' },
  { title: 'Status', key: 'STATUS', width: 20, keySort: 'status' },
  { title: '# of updates', key: 'NUM_OF_UPDATES', width: 20, keySort: '# of updates' },
  { title: 'Last Published Update', key: 'LAST_PUBLISHED_UPDATE', width: 20, keySort: 'last published update' }
];

export const initialFilterBarValues = [
  {
    name: 'Strategies with Updates',
    type: 'managerUpdateFilter',
    value: 'STRATEGIES_WITH_UPDATES',
    enabled: true,
    count: 0
  },
  {
    name: 'Other Strategies',
    type: 'managerUpdateFilter',
    value: 'OTHER_STRATEGIES',
    enabled: false,
    count: 0
  }
];

export const updatesTableColumnsEditMode = [
  { title: 'Update', key: 'NAME', width: 24, keySort: 'update name' },
  { title: 'Owner', key: 'OWNER', width: 24, keySort: 'owner' },
  { title: 'Date', key: 'STATUS_UPDATED_DATE', width: 24, keySort: 'date' },
  { title: 'Status', key: 'STATUS', width: 24, keySort: 'status' },
  { title: '', key: '', width: 4, keySort: '' }
];

export const updatesTableColumnsViewMode = [
  { title: 'Update', key: 'NAME', width: 32, keySort: 'update name' },
  { title: 'Date', key: 'STATUS_UPDATED_DATE', width: 32, keySort: 'date' },
  { title: 'Status', key: 'STATUS', width: 32, keySort: 'status' },
  { title: '', key: '', width: 4, keySort: '' }
];

export const managerUpdatesSort = [
  {
    title: 'Investment • A -> Z',
    key: 'STRATEGY',
    asc: true
  },
  {
    title: 'Investment • Z -> A',
    key: 'STRATEGY',
    asc: false
  },
  {
    title: 'Manager • A -> Z',
    key: 'MANAGER',
    asc: true
  },
  {
    title: 'Manager • Z -> A',
    key: 'MANAGER',
    asc: false
  },
  {
    title: 'Status • A -> Z',
    key: 'STATUS',
    asc: false
  },
  {
    title: 'Status • Z -> A',
    key: 'STATUS',
    asc: true
  },
  {
    title: '# of updates • Max -> Min',
    key: 'NUM_OF_UPDATES',
    asc: false
  },
  {
    title: '# of updates • Min -> Max',
    key: 'NUM_OF_UPDATES',
    asc: true
  },
  {
    title: 'Last Published Update • Newest -> Oldest',
    key: 'LAST_PUBLISHED_UPDATE',
    asc: false
  },
  {
    title: 'Last Published Update • Oldest -> Newest',
    key: 'LAST_PUBLISHED_UPDATE',
    asc: true
  }
];
