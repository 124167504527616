import styled from 'styled-components';

import guid from '../../../utils/guid';
import FormattingTooltip from '../../basicComponents/tooltip/dollarsTooltip';
import { IClientMoreInformation } from '../../clients-page/types';

interface InfoListClientProps {
  data: IClientMoreInformation;
  onRedirect: () => void;
  title: string;
  isMobile?: boolean;
}

export const InfoListClient = ({ data, onRedirect, title, isMobile = false }: InfoListClientProps) => {
  const getBoolean = (value: boolean) => (value ? 'YES' : 'NO');
  const clearStr = (value: string): number => {
    return +value.replaceAll('$', '').replaceAll(',', '');
  };

  return (
    <MoreDataWrap id="modal">
      <MoreDataItem id="modal">
        <MoreDataItemTitle isMobile={isMobile} id="modal">
          Total Client Value
        </MoreDataItemTitle>
        <MoreDataItemValue isMobile={isMobile} id="modal">
          <FormattingTooltip>{clearStr(data.totalClientValue)}</FormattingTooltip>
        </MoreDataItemValue>
      </MoreDataItem>

      <MoreDataItem id="modal">
        <MoreDataItemTitle isMobile={isMobile} id="modal">
          Committed Capital
        </MoreDataItemTitle>
        <MoreDataItemValue isMobile={isMobile} id="modal">
          <FormattingTooltip>{clearStr(data.committedCapital)}</FormattingTooltip>
        </MoreDataItemValue>
      </MoreDataItem>

      <MoreDataItem id="modal">
        <MoreDataItemTitle isMobile={isMobile} id="modal">
          Economic Exposure
        </MoreDataItemTitle>
        <MoreDataItemValue isMobile={isMobile} id="modal">
          <FormattingTooltip>{clearStr(data.economicExposure)}</FormattingTooltip>
        </MoreDataItemValue>
      </MoreDataItem>

      <MoreDataItem id="modal">
        <MoreDataItemTitle isMobile={isMobile} id="modal">
          On Hold
        </MoreDataItemTitle>
        <MoreDataItemValue isMobile={isMobile} id="modal">
          {getBoolean(data.onHold)}
        </MoreDataItemValue>
      </MoreDataItem>

      {data.sectorToAvoid.filter((sector) => sector !== '').length ? (
        <MoreDataItem id="modal">
          <MoreDataItemTitle isMobile={isMobile} id="modal">
            Sectors To Avoid
          </MoreDataItemTitle>
          <SectorsWrap id="modal">
            {data.sectorToAvoid
              .filter((x, i, a) => a.indexOf(x) == i)
              .map((el) => {
                if (el.length) {
                  return <SectorsItem key={guid()}>{el.trim()}</SectorsItem>;
                }
              })}
          </SectorsWrap>
        </MoreDataItem>
      ) : null}
    </MoreDataWrap>
  );
};

const SectorsWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  width: 100%;
`;

const SectorsItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px 20px;
  background-color: ${({ theme }) => theme.layer[2]};
  color: ${({ theme }) => theme.font.base};
  border-radius: 12px;
  font-family: Blinker, serif;
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0;
`;

const MoreDataItemValue = styled.div<{ isMobile: boolean }>`
  font-family: Blinker, serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0;
  text-align: left;
  width: ${({ isMobile }) => (isMobile ? '50%' : '200px')};
  color: ${({ theme }) => theme.font.base};
`;

const MoreDataItemTitle = styled.div<{ isMobile: boolean }>`
  font-family: Blinker, serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0;
  text-align: left;
  width: ${({ isMobile }) => (isMobile ? '50%' : '150px')};
  min-width: ${({ isMobile }) => (isMobile ? '50%' : '150px')};
  color: ${({ theme }) => theme.font.base};
`;

const MoreDataItem = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  padding: 5px 20px;
  background-color: ${({ theme }) => theme.layer[1]};
  gap: 10px;
  border-radius: 4px;
`;

const MoreDataWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3px;
`;
