import { useResponsive } from '../../../hooks/use-responsive';
import RedirectTitleCell from '../../table/redirectTitleCell';
import { CellWrapper, TextCell } from '../../table/styles';

interface ClientsAdvisorProps {
  advisor: string;
  familyName: string;
  onRedirect: () => void;
}

const ClientsAdvisor = ({ familyName, advisor, onRedirect }: ClientsAdvisorProps) => {
  const { isMobile } = useResponsive();
  return (
    <CellWrapper flexDirection={'column'}>
      <RedirectTitleCell onClick={onRedirect}>{familyName}</RedirectTitleCell>
      <TextCell>{advisor}</TextCell>
    </CellWrapper>
  );
};

export default ClientsAdvisor;
