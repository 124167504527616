import ModalWrappedContent from '@/components/fat-basicComponents/modal/modalWrappedContent';
import { Loader } from '@/components/loaders/loader';
import styled from 'styled-components';
import { DeleteTenantDto, ITenant } from '../types';
import { DeleteModalIcon } from '@/assets/static/icons/delete-modal-icon';
import { WarningModalIcon } from '@/assets/static/icons/warning-modal-icon';

interface DeleteTenantModalProps {
  onCancel: () => void;
  onConfirm: (data: DeleteTenantDto) => void;
  isOpen: boolean;
  tenant: ITenant;
  isLoading?: boolean;
  error?: string;
}

const DeleteTenantModal = ({ onCancel, onConfirm, isOpen, tenant, isLoading, error }: DeleteTenantModalProps) => {
  return (
    <ModalWrappedContent
      isOpen={isOpen}
      onClose={onCancel}
      confirmButton={
        (isLoading || error)
          ? null
          : {
              name: 'Confirm',
              onClick: () => {
                onConfirm({ id: tenant.id });
              },
            }
      }
      showCancelButton={!isLoading}
      isUseOutsideClick={!isLoading}
      showCloseIcon={!isLoading}
    >
      <ModalContainer>
        <ModalTitle>
          {error && (
            <>
              <WarningModalIcon width={60} height={61} fill="#ABABAB" />
              <p>This tenant cannot be deleted because it has data associated with it.</p>
            </>
          )}
          {!error && (
            <>
              <DeleteModalIcon 
                width={48}
                height={60}
                fill="#ABABAB" />
              {!isLoading && 
                <>
                  <p>Please confirm you want to delete</p>
                  <p><b>{tenant?.name}</b></p>
                </>
              }
            </>
          )}
        </ModalTitle>
        <ModalText fontWeight="400">
          {!isLoading && !error &&
            <div style={{ fontSize: 16 }}>
              Data will be permanently lost upon deletion. <br />
              There is no undo for this operation.
            </div>
          }
          {isLoading && !error &&
            <>
              <br /> <br />
              <Loader size={30} />
              Deleting {tenant?.name}...
            </>
          }
        </ModalText>
      </ModalContainer>
    </ModalWrappedContent>
  );
};

export default DeleteTenantModal;

const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  padding: 30px 0;
`;

const ModalTitle = styled.div`
  color: ${({ theme }) => theme.font.strong};
  text-align: center;
  font-family: Blinker;
  font-size: 33px;
  font-style: normal;
  font-weight: 400;
  line-height: 46.2px;
`;

const ModalText = styled.span<{ fontWeight: string }>`
  color: ${({ theme }) => theme.font.base};
  text-align: center;
  font-family: Blinker;
  font-size: 16px;
  font-style: normal;
  font-weight: ${({ fontWeight }) => fontWeight};
  line-height: 22.4px;
`;
