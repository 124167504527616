import { gql } from '@apollo/client';

export const subAssetClasses = [
  'Buyout',
  'Real Estate',
  'Energy',
  'Growth Equity',
  'Direct Lending',
  'Diversifying',
  'Semi-liquid',
  'Capital Solutions',
  'Structured Equity',
  'Mezzanine',
  'Hedge Fund',
  'Venture Capital',
];
export const managerSelects = ['Manager Name', 'Manager State', 'Super'];
export const tabsItems = ['Yes', 'No'];
export const defaultAssetClassValue = 'Select';
export const defaultStatusValue = 'Select One';
export const defaultSelectValue = 'Select';
export const defaultToggleCircle = [
  {
    label: 'No',
    checked: true,
  },
  {
    label: 'Yes',
    checked: false,
  },
];
// export const initialState = {
//   symbol: '',
//   assetClass: 'Private Equity',
//   strategies: subAssetClasses.map(subAssetClass => ({ text: subAssetClass, checked: false })),
//   status: 'Status',
//   name: '',
//   manager: managers.map(subAssetClass => ({ text: subAssetClass, checked: false })),
//   custodied: '',
//   ubtiBlocker: '',
//   sri: '',
//   nextClose: [],
//   finalClose: new Date(),
//   minimum: '',
//   targetIrr: '',
//   targetYield: '',
//   vintage: '',
// };

export const createInvestmentMutation = gql`
  mutation createInvestment($data: InvestmentEntityCreateDto!) {
    createInvestment(data: $data) {
      id
      name
    }
  }
`;
