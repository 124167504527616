import * as React from 'react';

export const RejectedIcon = ({ ...props }: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width={props.width || 33} height={props.height || 33} viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.8689 11.2984L16.5078 14.6596L13.1389 11.2984L11.3062 13.1623L14.6752 16.5234L11.3062 19.8923L13.1389 21.725L16.5078 18.356L19.8689 21.725L21.7016 19.8923L18.3404 16.5234L21.7016 13.1623L19.8689 11.2984ZM16.5078 3.5C13.936 3.49846 11.4215 4.25978 9.28246 5.68765C7.14342 7.11551 5.47597 9.14578 4.49106 11.5215C3.50616 13.8973 3.24806 16.5118 3.74942 19.0343C4.25078 21.5567 5.48907 23.8739 7.30762 25.6924C9.12617 27.511 11.4433 28.7493 13.9658 29.2506C16.4882 29.752 19.1027 29.4939 21.4785 28.509C23.8542 27.5241 25.8845 25.8566 27.3124 23.7176C28.7402 21.5785 29.5015 19.064 29.5 16.4922C29.501 14.7858 29.1657 13.0959 28.5131 11.5191C27.8606 9.94236 26.9036 8.50969 25.697 7.30305C24.4903 6.09641 23.0577 5.13945 21.4809 4.4869C19.9042 3.83434 18.2142 3.49898 16.5078 3.5ZM16.5078 26.8953C14.4499 26.8969 12.4378 26.2881 10.726 25.1459C9.0142 24.0037 7.6796 22.3795 6.89102 20.4787C6.10244 18.5779 5.89531 16.486 6.29583 14.4675C6.69636 12.4489 7.68655 10.5945 9.14114 9.13885C10.5957 7.68317 12.4494 6.69161 14.4676 6.28957C16.4858 5.88753 18.5779 6.09308 20.4793 6.88024C22.3807 7.6674 24.0059 9.0008 25.1493 10.7118C26.2928 12.4227 26.9031 14.4343 26.9031 16.4922C26.899 19.2487 25.8027 21.8912 23.8543 23.841C21.9059 25.7909 19.2643 26.8891 16.5078 26.8953Z"
        fill={props?.fill || '#D63B4B'}
      />
    </svg>
  );
};
