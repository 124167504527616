import styled from 'styled-components';

import Button from '../fat-basicComponents/button';
import { IRowData } from './types';
import { formatterClient } from './utils';

interface InfoListProps {
  data: IRowData;
  onRedirect: () => void;
}

export const ComplianceEntityInfoList = ({ data, onRedirect }: InfoListProps) => {
  const getBoolean = (value: boolean) => (value ? 'YES' : 'NO');
  const biteSizeLabel = data.investment?.type ? `Bite Size (${data.investment?.type?.toLowerCase()})` : 'Bite Size';
  return (
    <MoreDataWrap id="modal">
      <MoreDataItem id="modal">
        <MoreDataItemTitle id="modal">Always Show</MoreDataItemTitle>
        <MoreDataItemValue id="modal">{getBoolean(data.moreInfoEntity.alwaysShow)}</MoreDataItemValue>
      </MoreDataItem>

      <MoreDataItem id="modal">
        <MoreDataItemTitle id="modal">Entity Value</MoreDataItemTitle>
        <MoreDataItemValue id="modal">{formatterClient.format(data.moreInfoEntity.entityValue)}</MoreDataItemValue>
      </MoreDataItem>

      <MoreDataItem id="modal">
        <MoreDataItemTitle id="modal">On Hold</MoreDataItemTitle>
        <MoreDataItemValue id="modal">{getBoolean(data.moreInfoEntity.onHold)}</MoreDataItemValue>
      </MoreDataItem>

      <MoreDataItem id="modal">
        <MoreDataItemTitle id="modal">{biteSizeLabel}</MoreDataItemTitle>
        <MoreDataItemValue id="modal">{formatterClient.format(data.moreInfoEntity.biteSize)}</MoreDataItemValue>
      </MoreDataItem>

      {onRedirect && (
        <RedirectButton styleType="outline" size="md" onClick={onRedirect}>
          View {data.legalEntity.entityName}
        </RedirectButton>
      )}
    </MoreDataWrap>
  );
};

const MoreDataItemValue = styled.div`
  display: flex;
  justify-content: center;
  font-family: 'Blinker', serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0;
  text-align: center;
  width: 50%;
  color: ${({ theme }) => theme.font.base};
`;

const MoreDataItemTitle = styled.div`
  font-family: 'Blinker', serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0;
  text-align: left;
  width: 50%;
  color: ${({ theme }) => theme.font.base};
`;

const MoreDataItem = styled.div`
  display: flex;
  align-items: center;
  padding: 5px 20px;
  background-color: ${({ theme }) => theme.layer[1]};
  gap: 10px;
  border-radius: 4px;
`;

const MoreDataWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3px;
`;

const RedirectButton = styled(Button)`
  margin-top: 13px;
`;
