import { CloseInfoIcon } from '@/assets/icons/info-icons/closeInfo';
import { DragAndDropIcon } from '@/assets/static/icons/drag-and-drop';
import { Reorder } from 'framer-motion';
import { Dispatch, SetStateAction } from 'react';
import styled, { useTheme } from 'styled-components';
import { fixedColumns } from './constatnts';
import { ColumnList } from './types';
import { handleRemoveColumn } from './utils';

interface SelectedColumnsListProps {
  newColumns: ColumnList[];
  setNewColumns: Dispatch<SetStateAction<ColumnList[]>>;
}

export const SelectedColumnsList = ({ newColumns, setNewColumns }: SelectedColumnsListProps) => {
  const theme = useTheme();

  return (
    <SelectColumnsWrapper>
      <SelectedColumns>
        <CountWrap>
          <Count>{newColumns.filter((item) => item.value).length + 1}</Count>
        </CountWrap>
        SELECTED COLUMNS
      </SelectedColumns>
      <FixedColumnWrapper>
        Fixed Columns
        {fixedColumns.map((item, index) => (
          <FixedColumnItem key={item.key + index}>
            {item.title}
            <svg width="23" height="24" viewBox="0 0 23 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M16.6034 9.09988H15.7515V7.40132C15.7713 6.83061 15.676 6.26179 15.4712 5.72872C15.2664 5.19564 14.9564 4.70923 14.5597 4.29851C14.163 3.88779 13.6876 3.56116 13.162 3.33807C12.6363 3.11498 12.0711 3 11.5 3C10.929 3 10.3638 3.11498 9.83812 3.33807C9.31245 3.56116 8.83707 3.88779 8.44033 4.29851C8.04359 4.70923 7.73358 5.19564 7.52883 5.72872C7.32407 6.26179 7.22874 6.83061 7.24851 7.40132V9.09988H6.40182C5.95087 9.09988 5.51827 9.27868 5.19892 9.59707C4.87957 9.91547 4.69947 10.3475 4.69812 10.7985V19.3014C4.69947 19.7524 4.87957 20.1844 5.19892 20.5028C5.51827 20.8212 5.95087 21 6.40182 21H16.6034C17.0534 20.9987 17.4847 20.8193 17.8029 20.501C18.1212 20.1828 18.3006 19.7515 18.3019 19.3014V10.7985C18.3006 10.3484 18.1212 9.91713 17.8029 9.59888C17.4847 9.28063 17.0534 9.10123 16.6034 9.09988ZM8.95221 7.40132C8.95221 6.72491 9.22092 6.07622 9.69921 5.59793C10.1775 5.11964 10.8262 4.85093 11.5026 4.85093C12.179 4.85093 12.8277 5.11964 13.306 5.59793C13.7843 6.07622 14.053 6.72491 14.053 7.40132V9.09988H8.95221V7.40132ZM16.6034 19.3014H6.40182V10.7985H16.6034V19.3014ZM11.5026 16.751C11.8398 16.751 12.1693 16.651 12.4496 16.4636C12.7299 16.2761 12.9482 16.0098 13.077 15.6982C13.2058 15.3866 13.2393 15.0437 13.1731 14.7131C13.107 14.3825 12.9442 14.079 12.7054 13.8409C12.4667 13.6029 12.1626 13.441 11.8318 13.3758C11.501 13.3107 11.1583 13.3452 10.8471 13.4749C10.5359 13.6046 10.2702 13.8238 10.0836 14.1046C9.89699 14.3855 9.79789 14.7153 9.79889 15.0525C9.80025 15.5034 9.98034 15.9355 10.2997 16.2539C10.619 16.5723 11.0516 16.751 11.5026 16.751Z"
                fill={theme.font.disabled}
              />
            </svg>
          </FixedColumnItem>
        ))}
      </FixedColumnWrapper>
      <div style={{ padding: '0 20px' }}>
        <SeparateStick />
      </div>
      <ExpandColumnWrapper>
        <Reorder.Group
          axis="y"
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
            overflow: 'hidden'
          }}
          onReorder={(newOrder) => setNewColumns(newOrder)}
          values={newColumns}
        >
          {newColumns.map((item) => {
            return (
              <Reorder.Item key={item.keySort + item.rowKey} style={{ display: `${item.value ? 'block' : 'none'}` }} value={item} id={item.keySort}>
                <ExpandColumnItem>
                  <DragAndDropIcon fill={theme.font.disabled} />
                  {item.title}
                  <CrossIconWrapper onClick={() => handleRemoveColumn(item.keySort, newColumns, setNewColumns)}>
                    <CloseInfoIcon width={12} height={11} fill={theme.font.action} />
                  </CrossIconWrapper>
                </ExpandColumnItem>
              </Reorder.Item>
            );
          })}
        </Reorder.Group>
      </ExpandColumnWrapper>
    </SelectColumnsWrapper>
  );
};

export const SelectColumnsWrapper = styled.div<{ isShadow?: boolean }>`
  margin-top: 20px;
  width: 100%;
  height: 672px;
  overflow-y: auto;
  background-color: ${({ theme }) => theme.layer[1]};
  padding: 10px 20px;
  box-shadow: ${({ isShadow }) => (isShadow ? 'inset 0px -6px 6px rgba(0, 0, 0, 0.1)' : 'none')};
`;

const SelectedColumns = styled.div`
  font-weight: 600;
  font-size: 16px;
  color: ${({ theme }) => theme.font.base};
`;

const CountWrap = styled.div`
  background-color: ${({ theme }) => theme.header.base};
  display: inline-block;
  border-radius: 12px;
  color: ${({ theme }) => theme.context.light};
  padding: 1px 11px;
  font-size: 13px;
  margin-right: 6px;
`;

const Count = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  font-weight: 700;
`;

const FixedColumnWrapper = styled.div`
  padding-top: 30px;
  font-weight: 400;
  font-size: 13px;
  color: ${({ theme }) => theme.font.weak};
`;

const FixedColumnItem = styled.div`
  padding: 8.5px 10px;
  background: ${({ theme }) => theme.action.disabled};
  border: 1px solid ${({ theme }) => theme.border.base};
  border-radius: 4px;
  font-weight: 400;
  font-size: 16px;
  color: ${({ theme }) => theme.font.disabled};
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ExpandColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow-y: auto;
`;

const ExpandColumnItem = styled(FixedColumnItem)`
  justify-content: flex-start;
  background: ${({ theme }) => theme.layer[1]};
  color: ${({ theme }) => theme.font.strong};
  gap: 15px;
  cursor: move;
`;

const CrossIconWrapper = styled.div`
  cursor: pointer;
  margin-left: auto;
`;

const SeparateStick = styled.div`
  height: 1px;
  width: 100%;
  background: ${({ theme }) => theme.border.base};
  margin: 20px 0;
`;
