import { ApolloClient, ApolloLink, createHttpLink, InMemoryCache, split } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition } from '@apollo/client/utilities';
import { createClient } from 'graphql-ws';

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path, extensions }) => {
      console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}, Code: ${extensions?.code}`);
    });
  if (networkError) console.log(`[Network error]: ${networkError}`);
});

const wsLink =
  typeof window !== 'undefined'
    ? new GraphQLWsLink(
        createClient({
          url: `${process.env.REACT_APP_BACKEND_SUB_URL}/subscriptions`,
          connectionParams: {
            authToken: localStorage.getItem('token')
          }
        })
      )
    : null;

const httpLink = createHttpLink({
  uri: `${process.env.REACT_APP_BACKEND_BASE_URL}/graphql`
});

const splitLink =
  typeof window !== 'undefined' && wsLink != null
    ? split(
        ({ query }) => {
          const definition = getMainDefinition(query);
          return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
        },
        wsLink,
        httpLink
      )
    : httpLink;

const authLink = setContext(async (operation) => {
  const token = localStorage.getItem('token');
  return {
    headers: {
      authorization: `Bearer ${token}`
    }
  };
});

const link = ApolloLink.from([authLink, errorLink, splitLink]);
// const link = ApolloLink.from([httpLink]);
// const client = new ApolloClient({
//   uri: 'https://flyby-router-demo.herokuapp.com/',
//   cache: new InMemoryCache()
// });

const client = new ApolloClient({
  link,
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache',
      nextFetchPolicy: 'no-cache'
    },
    query: {
      fetchPolicy: 'no-cache'
    }
  }
});

export default client;
