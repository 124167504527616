import ModalWrappedContent from '@/components/fat-basicComponents/modal/modalWrappedContent';
import { useAuth } from '@/hooks/use-auth';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

interface SignOutProps {
  onClose: () => void;
  isOpen: boolean;
}

const SignOut = ({ onClose, isOpen }: SignOutProps) => {
  const { signOut } = useAuth();
  const navigate = useNavigate();

  const saveValue = () => {
    signOut();
    navigate('/', { state: { isLastPageProfile: true } });
  };

  return (
    <ModalWrappedContent
      isOpen={isOpen}
      onClose={onClose}
      showCancelButton={false}
      title="Sign out?"
      confirmButton={{
        name: 'Sign out',
        onClick: saveValue
      }}
    >
      <Description>Are you sure you’re ready to sign out?</Description>
    </ModalWrappedContent>
  );
};

export default SignOut;

const Description = styled.p`
  font-weight: 400;
  font-size: 22px;
  line-height: 18px;
  text-align: center;
  color: ${({ theme }) => theme.font.base};
`;
