import React from 'react';
import styled from 'styled-components';

import { TitleCell } from './styles';

interface RedirectTitleCellProps {
  children: React.ReactNode;
  onClick?: () => void;
  limit?: boolean;
  id?: string;
  color?: string;
}

const RedirectTitleCell = ({ children, onClick, limit = false, id, color }: RedirectTitleCellProps) => {
  return (
    <StyledText id={id ?? 'actionText'} onClick={onClick} limit={limit} color={color}>
      {children}
    </StyledText>
  );
};

export default RedirectTitleCell;

export const StyledText = styled(TitleCell)<{
  limit: boolean;
  color: string | undefined;
}>`
  flex-basis: ${({ limit }) => (limit ? 'min-content' : 'auto')};
  min-width: ${({ limit }) => (limit ? '125px' : '0')};
  color: ${({ color, theme }) => color ?? theme.font.base};

  &:hover {
    color: ${({ theme }) => theme.font.action};
    cursor: pointer;
  }

  padding-right: 15px;
  word-break: break-word;
`;
