import client from '@/apollo-client';
import { getAssetSignedUrl } from '@/components/settings-page/queries';
import { ISetting } from '@/components/settings-page/settings-pages/firmSettings/types';
import { isURL } from '@/components/settings-page/settings-pages/firmSettings/utils';
import { Document, Font, Page, Image as PdfImage, StyleSheet, Text, View } from '@react-pdf/renderer';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { ISection, ITier } from '../dueDiligenceRecord/types';
import { OneColumnSection } from './oneColumnSection';
import { ThreeColumnSection } from './threeColumnSection';
import { TwoColumnSection } from './twoColumnSection';
import { getFontByKey, getValueByKey } from './utils';

interface PDFTemplateProps {
  tier: ITier;
  investmentDescription: string;
  investmentName: string;
  firmSettings: ISetting[];
  templateStylingData: ISetting[];
  includeAiSuggested: boolean;
  generatingPdf: (isPdfReady: boolean) => void;
}

Font.register({
  family: 'Roboto',
  fonts: [
    {
      src: 'https://fonts.gstatic.com/s/roboto/v30/KFOiCnqEu92Fr1Mu51QrIzcXLsnzjYk.ttf',
      fontStyle: '100italic'
    },
    {
      src: 'https://fonts.gstatic.com/s/roboto/v30/KFOjCnqEu92Fr1Mu51TjARc9AMX6lJBP.ttf',
      fontStyle: '300italic'
    },
    {
      src: 'https://fonts.gstatic.com/s/roboto/v30/KFOkCnqEu92Fr1Mu52xPKTM1K9nz.ttf',
      fontStyle: '400italic'
    },
    {
      src: 'https://fonts.gstatic.com/s/roboto/v30/KFOjCnqEu92Fr1Mu51S7ABc9AMX6lJBP.ttf',
      fontStyle: '500italic'
    },
    {
      src: 'https://fonts.gstatic.com/s/roboto/v30/KFOjCnqEu92Fr1Mu51TzBhc9AMX6lJBP.ttf',
      fontStyle: '700italic'
    },
    {
      src: 'https://fonts.gstatic.com/s/roboto/v30/KFOjCnqEu92Fr1Mu51TLBBc9AMX6lJBP.ttf',
      fontStyle: '900italic'
    },

    {
      src: 'https://fonts.gstatic.com/s/roboto/v30/KFOkCnqEu92Fr1MmgWxPKTM1K9nz.ttf',
      fontWeight: 100
    },
    {
      src: 'https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmSU5vAx05IsDqlA.ttf',
      fontWeight: 300
    },
    {
      src: 'https://fonts.gstatic.com/s/roboto/v30/KFOmCnqEu92Fr1Me5WZLCzYlKw.ttf',
      fontWeight: 400
    },
    {
      src: 'https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmEU9vAx05IsDqlA.ttf',
      fontWeight: 500
    },
    {
      src: 'https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmWUlvAx05IsDqlA.ttf',
      fontWeight: 700
    },
    {
      src: 'https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmYUtvAx05IsDqlA.ttf',
      fontWeight: 900
    }
  ]
});

Font.register({
  family: 'Roboto Slab',
  fonts: [
    {
      src: 'https://fonts.gstatic.com/s/robotoslab/v25/BngbUXZYTXPIvIBgJJSb6s3BzlRRfKOFbvjojIWWaG5iddG-1A.ttf',
      fontWeight: 100
    },
    {
      src: 'https://fonts.gstatic.com/s/robotoslab/v25/BngbUXZYTXPIvIBgJJSb6s3BzlRRfKOFbvjoDISWaG5iddG-1A.ttf',
      fontWeight: 200
    },
    {
      src: 'https://fonts.gstatic.com/s/robotoslab/v25/BngbUXZYTXPIvIBgJJSb6s3BzlRRfKOFbvjo0oSWaG5iddG-1A.ttf',
      fontWeight: 300
    },
    {
      src: 'https://fonts.gstatic.com/s/robotoslab/v25/BngbUXZYTXPIvIBgJJSb6s3BzlRRfKOFbvjojISWaG5iddG-1A.ttf',
      fontWeight: 400
    },
    {
      src: 'https://fonts.gstatic.com/s/robotoslab/v25/BngbUXZYTXPIvIBgJJSb6s3BzlRRfKOFbvjovoSWaG5iddG-1A.ttf',
      fontWeight: 500
    },
    {
      src: 'https://fonts.gstatic.com/s/robotoslab/v25/BngbUXZYTXPIvIBgJJSb6s3BzlRRfKOFbvjoUoOWaG5iddG-1A.ttf',
      fontWeight: 600
    },
    {
      src: 'https://fonts.gstatic.com/s/robotoslab/v25/BngbUXZYTXPIvIBgJJSb6s3BzlRRfKOFbvjoa4OWaG5iddG-1A.ttf',
      fontWeight: 700
    },
    {
      src: 'https://fonts.gstatic.com/s/robotoslab/v25/BngbUXZYTXPIvIBgJJSb6s3BzlRRfKOFbvjoDIOWaG5iddG-1A.ttf',
      fontWeight: 800
    },
    {
      src: 'https://fonts.gstatic.com/s/robotoslab/v25/BngbUXZYTXPIvIBgJJSb6s3BzlRRfKOFbvjoJYOWaG5iddG-1A.ttf',
      fontWeight: 900
    }
  ]
});

Font.register({
  family: 'Open Sans',
  fonts: [
    {
      src: 'https://fonts.gstatic.com/s/opensans/v35/memQYaGs126MiZpBA-UFUIcVXSCEkx2cmqvXlWq8tWZ0Pw86hd0Rk5hkaVcUwaERZjA.ttf',
      fontStyle: '300italic'
    },
    {
      src: 'https://fonts.gstatic.com/s/opensans/v35/memQYaGs126MiZpBA-UFUIcVXSCEkx2cmqvXlWq8tWZ0Pw86hd0Rk8ZkaVcUwaERZjA.ttf',
      fontStyle: '400italic'
    },
    {
      src: 'https://fonts.gstatic.com/s/opensans/v35/memQYaGs126MiZpBA-UFUIcVXSCEkx2cmqvXlWq8tWZ0Pw86hd0Rk_RkaVcUwaERZjA.ttf',
      fontStyle: '500italic'
    },
    {
      src: 'https://fonts.gstatic.com/s/opensans/v35/memQYaGs126MiZpBA-UFUIcVXSCEkx2cmqvXlWq8tWZ0Pw86hd0RkxhjaVcUwaERZjA.ttf',
      fontStyle: '600italic'
    },
    {
      src: 'https://fonts.gstatic.com/s/opensans/v35/memQYaGs126MiZpBA-UFUIcVXSCEkx2cmqvXlWq8tWZ0Pw86hd0RkyFjaVcUwaERZjA.ttf',
      fontStyle: '700italic'
    },
    {
      src: 'https://fonts.gstatic.com/s/opensans/v35/memQYaGs126MiZpBA-UFUIcVXSCEkx2cmqvXlWq8tWZ0Pw86hd0Rk0ZjaVcUwaERZjA.ttf',
      fontStyle: '800italic'
    },

    {
      src: 'https://fonts.gstatic.com/s/opensans/v35/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsiH0C4nY1M2xLER.ttf',
      fontWeight: 300
    },
    {
      src: 'https://fonts.gstatic.com/s/opensans/v35/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsjZ0C4nY1M2xLER.ttf',
      fontWeight: 400
    },
    {
      src: 'https://fonts.gstatic.com/s/opensans/v35/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsjr0C4nY1M2xLER.ttf',
      fontWeight: 500
    },
    {
      src: 'https://fonts.gstatic.com/s/opensans/v35/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsgH1y4nY1M2xLER.ttf',
      fontWeight: 600
    },
    {
      src: 'https://fonts.gstatic.com/s/opensans/v35/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsg-1y4nY1M2xLER.ttf',
      fontWeight: 700
    },
    {
      src: 'https://fonts.gstatic.com/s/opensans/v35/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgshZ1y4nY1M2xLER.ttf',
      fontWeight: 800
    }
  ]
});

Font.register({
  family: 'Montserrat',
  fonts: [
    {
      src: 'https://fonts.gstatic.com/s/montserrat/v25/JTUFjIg1_i6t8kCHKm459Wx7xQYXK0vOoz6jq6R8aX9-p7K5ILg.ttf',
      fontStyle: '100italic'
    },
    {
      src: 'https://fonts.gstatic.com/s/montserrat/v25/JTUFjIg1_i6t8kCHKm459Wx7xQYXK0vOoz6jqyR9aX9-p7K5ILg.ttf',
      fontStyle: '200italic'
    },
    {
      src: 'https://fonts.gstatic.com/s/montserrat/v25/JTUFjIg1_i6t8kCHKm459Wx7xQYXK0vOoz6jq_p9aX9-p7K5ILg.ttf',
      fontStyle: '300italic'
    },
    {
      src: 'https://fonts.gstatic.com/s/montserrat/v25/JTUFjIg1_i6t8kCHKm459Wx7xQYXK0vOoz6jq6R9aX9-p7K5ILg.ttf',
      fontStyle: '400italic'
    },
    {
      src: 'https://fonts.gstatic.com/s/montserrat/v25/JTUFjIg1_i6t8kCHKm459Wx7xQYXK0vOoz6jq5Z9aX9-p7K5ILg.ttf',
      fontStyle: '500italic'
    },
    {
      src: 'https://fonts.gstatic.com/s/montserrat/v25/JTUFjIg1_i6t8kCHKm459Wx7xQYXK0vOoz6jq3p6aX9-p7K5ILg.ttf',
      fontStyle: '600italic'
    },
    {
      src: 'https://fonts.gstatic.com/s/montserrat/v25/JTUFjIg1_i6t8kCHKm459Wx7xQYXK0vOoz6jq0N6aX9-p7K5ILg.ttf',
      fontStyle: '700italic'
    },
    {
      src: 'https://fonts.gstatic.com/s/montserrat/v25/JTUFjIg1_i6t8kCHKm459Wx7xQYXK0vOoz6jqyR6aX9-p7K5ILg.ttf',
      fontStyle: '800italic'
    },
    {
      src: 'https://fonts.gstatic.com/s/montserrat/v25/JTUFjIg1_i6t8kCHKm459Wx7xQYXK0vOoz6jqw16aX9-p7K5ILg.ttf',
      fontStyle: '900italic'
    },

    {
      src: 'https://fonts.gstatic.com/s/montserrat/v25/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCtr6Uw-Y3tcoqK5.ttf',
      fontWeight: 100
    },
    {
      src: 'https://fonts.gstatic.com/s/montserrat/v25/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCvr6Ew-Y3tcoqK5.ttf',
      fontWeight: 200
    },
    {
      src: 'https://fonts.gstatic.com/s/montserrat/v25/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCs16Ew-Y3tcoqK5.ttf',
      fontWeight: 300
    },
    {
      src: 'https://fonts.gstatic.com/s/montserrat/v25/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCtr6Ew-Y3tcoqK5.ttf',
      fontWeight: 400
    },
    {
      src: 'https://fonts.gstatic.com/s/montserrat/v25/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCtZ6Ew-Y3tcoqK5.ttf',
      fontWeight: 500
    },
    {
      src: 'https://fonts.gstatic.com/s/montserrat/v25/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCu170w-Y3tcoqK5.ttf',
      fontWeight: 600
    },
    {
      src: 'https://fonts.gstatic.com/s/montserrat/v25/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCuM70w-Y3tcoqK5.ttf',
      fontWeight: 700
    },
    {
      src: 'https://fonts.gstatic.com/s/montserrat/v25/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCvr70w-Y3tcoqK5.ttf',
      fontWeight: 800
    },
    {
      src: 'https://fonts.gstatic.com/s/montserrat/v25/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCvC70w-Y3tcoqK5.ttf',
      fontWeight: 900
    }
  ]
});

Font.register({
  family: 'Lato',
  fonts: [
    {
      src: 'https://fonts.gstatic.com/s/lato/v24/S6u-w4BMUTPHjxsIPy-vNiPg7MU0.ttf',
      fontStyle: '100italic'
    },
    {
      src: 'https://fonts.gstatic.com/s/lato/v24/S6u_w4BMUTPHjxsI9w2PHA3s5dwt7w.ttf',
      fontStyle: '300italic'
    },
    {
      src: 'https://fonts.gstatic.com/s/lato/v24/S6u8w4BMUTPHjxswWyWrFCbw7A.ttf',
      fontStyle: '400italic'
    },
    {
      src: 'https://fonts.gstatic.com/s/lato/v24/S6u_w4BMUTPHjxsI5wqPHA3s5dwt7w.ttf',
      fontStyle: '700italic'
    },
    {
      src: 'https://fonts.gstatic.com/s/lato/v24/S6u_w4BMUTPHjxsI3wiPHA3s5dwt7w.ttf',
      fontStyle: '900italic'
    },

    {
      src: 'https://fonts.gstatic.com/s/lato/v24/S6u8w4BMUTPHh30wWyWrFCbw7A.ttf',
      fontWeight: 100
    },
    {
      src: 'https://fonts.gstatic.com/s/lato/v24/S6u9w4BMUTPHh7USew-FGC_p9dw.ttf',
      fontWeight: 300
    },
    {
      src: 'https://fonts.gstatic.com/s/lato/v24/S6uyw4BMUTPHvxk6XweuBCY.ttf',
      fontWeight: 400
    },
    {
      src: 'https://fonts.gstatic.com/s/lato/v24/S6u9w4BMUTPHh6UVew-FGC_p9dw.ttf',
      fontWeight: 700
    },
    {
      src: 'https://fonts.gstatic.com/s/lato/v24/S6u9w4BMUTPHh50Xew-FGC_p9dw.ttf',
      fontWeight: 900
    }
  ]
});

Font.register({
  family: 'Raleway',
  fonts: [
    {
      src: 'https://fonts.gstatic.com/s/raleway/v28/1Pt_g8zYS_SKggPNyCgSQamb1W0lwk4S4WjNPrQVIT9c2c8.ttf',
      fontStyle: '100italic'
    },
    {
      src: 'https://fonts.gstatic.com/s/raleway/v28/1Pt_g8zYS_SKggPNyCgSQamb1W0lwk4S4ejMPrQVIT9c2c8.ttf',
      fontStyle: '200italic'
    },
    {
      src: 'https://fonts.gstatic.com/s/raleway/v28/1Pt_g8zYS_SKggPNyCgSQamb1W0lwk4S4TbMPrQVIT9c2c8.ttf',
      fontStyle: '300italic'
    },
    {
      src: 'https://fonts.gstatic.com/s/raleway/v28/1Pt_g8zYS_SKggPNyCgSQamb1W0lwk4S4WjMPrQVIT9c2c8.ttf',
      fontStyle: '400italic'
    },
    {
      src: 'https://fonts.gstatic.com/s/raleway/v28/1Pt_g8zYS_SKggPNyCgSQamb1W0lwk4S4VrMPrQVIT9c2c8.ttf',
      fontStyle: '500italic'
    },
    {
      src: 'https://fonts.gstatic.com/s/raleway/v28/1Pt_g8zYS_SKggPNyCgSQamb1W0lwk4S4bbLPrQVIT9c2c8.ttf',
      fontStyle: '600italic'
    },
    {
      src: 'https://fonts.gstatic.com/s/raleway/v28/1Pt_g8zYS_SKggPNyCgSQamb1W0lwk4S4Y_LPrQVIT9c2c8.ttf',
      fontStyle: '700italic'
    },
    {
      src: 'https://fonts.gstatic.com/s/raleway/v28/1Pt_g8zYS_SKggPNyCgSQamb1W0lwk4S4ejLPrQVIT9c2c8.ttf',
      fontStyle: '800italic'
    },
    {
      src: 'https://fonts.gstatic.com/s/raleway/v28/1Pt_g8zYS_SKggPNyCgSQamb1W0lwk4S4cHLPrQVIT9c2c8.ttf',
      fontStyle: '900italic'
    },

    {
      src: 'https://fonts.gstatic.com/s/raleway/v28/1Ptxg8zYS_SKggPN4iEgvnHyvveLxVvao4CPNLA3JC9c.ttf',
      fontWeight: 100
    },
    {
      src: 'https://fonts.gstatic.com/s/raleway/v28/1Ptxg8zYS_SKggPN4iEgvnHyvveLxVtaooCPNLA3JC9c.ttf',
      fontWeight: 200
    },
    {
      src: 'https://fonts.gstatic.com/s/raleway/v28/1Ptxg8zYS_SKggPN4iEgvnHyvveLxVuEooCPNLA3JC9c.ttf',
      fontWeight: 300
    },
    {
      src: 'https://fonts.gstatic.com/s/raleway/v28/1Ptxg8zYS_SKggPN4iEgvnHyvveLxVvaooCPNLA3JC9c.ttf',
      fontWeight: 400
    },
    {
      src: 'https://fonts.gstatic.com/s/raleway/v28/1Ptxg8zYS_SKggPN4iEgvnHyvveLxVvoooCPNLA3JC9c.ttf',
      fontWeight: 500
    },
    {
      src: 'https://fonts.gstatic.com/s/raleway/v28/1Ptxg8zYS_SKggPN4iEgvnHyvveLxVsEpYCPNLA3JC9c.ttf',
      fontWeight: 600
    },
    {
      src: 'https://fonts.gstatic.com/s/raleway/v28/1Ptxg8zYS_SKggPN4iEgvnHyvveLxVs9pYCPNLA3JC9c.ttf',
      fontWeight: 700
    },
    {
      src: 'https://fonts.gstatic.com/s/raleway/v28/1Ptxg8zYS_SKggPN4iEgvnHyvveLxVtapYCPNLA3JC9c.ttf',
      fontWeight: 800
    },
    {
      src: 'https://fonts.gstatic.com/s/raleway/v28/1Ptxg8zYS_SKggPN4iEgvnHyvveLxVtzpYCPNLA3JC9c.ttf',
      fontWeight: 900
    }
  ]
});

Font.register({
  family: 'Pacifico',
  fonts: [
    {
      src: 'https://fonts.gstatic.com/s/pacifico/v22/FwZY7-Qmy14u9lezJ96A4sijpFu_.ttf',
      fontWeight: 400
    }
  ]
});

Font.register({
  family: 'Playfair Display',
  fonts: [
    {
      src: 'https://fonts.gstatic.com/s/playfairdisplay/v36/nuFRD-vYSZviVYUb_rj3ij__anPXDTnCjmHKM4nYO7KN_qiTbtbK-F2rA0s.ttf',
      fontStyle: '400italic'
    },
    {
      src: 'https://fonts.gstatic.com/s/playfairdisplay/v36/nuFRD-vYSZviVYUb_rj3ij__anPXDTnCjmHKM4nYO7KN_pqTbtbK-F2rA0s.ttf',
      fontStyle: '500italic'
    },
    {
      src: 'https://fonts.gstatic.com/s/playfairdisplay/v36/nuFRD-vYSZviVYUb_rj3ij__anPXDTnCjmHKM4nYO7KN_naUbtbK-F2rA0s.ttf',
      fontStyle: '600italic'
    },
    {
      src: 'https://fonts.gstatic.com/s/playfairdisplay/v36/nuFRD-vYSZviVYUb_rj3ij__anPXDTnCjmHKM4nYO7KN_k-UbtbK-F2rA0s.ttf',
      fontStyle: '700italic'
    },
    {
      src: 'https://fonts.gstatic.com/s/playfairdisplay/v36/nuFRD-vYSZviVYUb_rj3ij__anPXDTnCjmHKM4nYO7KN_iiUbtbK-F2rA0s.ttf',
      fontStyle: '800italic'
    },
    {
      src: 'https://fonts.gstatic.com/s/playfairdisplay/v36/nuFRD-vYSZviVYUb_rj3ij__anPXDTnCjmHKM4nYO7KN_gGUbtbK-F2rA0s.ttf',
      fontStyle: '900italic'
    },

    {
      src: 'https://fonts.gstatic.com/s/playfairdisplay/v30/nuFvD-vYSZviVYUb_rj3ij__anPXJzDwcbmjWBN2PKdFvUDQZNLo_U2r.ttf',
      fontWeight: 400
    },
    {
      src: 'https://fonts.gstatic.com/s/playfairdisplay/v30/nuFvD-vYSZviVYUb_rj3ij__anPXJzDwcbmjWBN2PKd3vUDQZNLo_U2r.ttf',
      fontWeight: 500
    },
    {
      src: 'https://fonts.gstatic.com/s/playfairdisplay/v30/nuFvD-vYSZviVYUb_rj3ij__anPXJzDwcbmjWBN2PKebukDQZNLo_U2r.ttf',
      fontWeight: 600
    },
    {
      src: 'https://fonts.gstatic.com/s/playfairdisplay/v30/nuFvD-vYSZviVYUb_rj3ij__anPXJzDwcbmjWBN2PKeiukDQZNLo_U2r.ttf',
      fontWeight: 700
    },
    {
      src: 'https://fonts.gstatic.com/s/playfairdisplay/v30/nuFvD-vYSZviVYUb_rj3ij__anPXJzDwcbmjWBN2PKfFukDQZNLo_U2r.ttf',
      fontWeight: 800
    },
    {
      src: 'https://fonts.gstatic.com/s/playfairdisplay/v30/nuFvD-vYSZviVYUb_rj3ij__anPXJzDwcbmjWBN2PKfsukDQZNLo_U2r.ttf',
      fontWeight: 900
    }
  ]
});

Font.register({
  family: 'Oswald',
  fonts: [
    {
      src: 'https://fonts.gstatic.com/s/oswald/v49/TK3_WkUHHAIjg75cFRf3bXL8LICs13FvgUFoZAaRliE.ttf',
      fontWeight: 200
    },
    {
      src: 'https://fonts.gstatic.com/s/oswald/v49/TK3_WkUHHAIjg75cFRf3bXL8LICs169vgUFoZAaRliE.ttf',
      fontWeight: 300
    },
    {
      src: 'https://fonts.gstatic.com/s/oswald/v49/TK3_WkUHHAIjg75cFRf3bXL8LICs1_FvgUFoZAaRliE.ttf',
      fontWeight: 400
    },
    {
      src: 'https://fonts.gstatic.com/s/oswald/v49/TK3_WkUHHAIjg75cFRf3bXL8LICs18NvgUFoZAaRliE.ttf',
      fontWeight: 500
    },
    {
      src: 'https://fonts.gstatic.com/s/oswald/v49/TK3_WkUHHAIjg75cFRf3bXL8LICs1y9ogUFoZAaRliE.ttf',
      fontWeight: 600
    },
    {
      src: 'https://fonts.gstatic.com/s/oswald/v49/TK3_WkUHHAIjg75cFRf3bXL8LICs1xZogUFoZAaRliE.ttf',
      fontWeight: 700
    }
  ]
});

Font.register({
  family: 'Poppins',
  fonts: [
    {
      src: 'https://fonts.gstatic.com/s/poppins/v20/pxiAyp8kv8JHgFVrJJLmE3tFOvODSVFF.ttf',
      fontStyle: '100italic'
    },
    {
      src: 'https://fonts.gstatic.com/s/poppins/v20/pxiDyp8kv8JHgFVrJJLmv1plEN2PQEhcqw.ttf',
      fontStyle: '200italic'
    },
    {
      src: 'https://fonts.gstatic.com/s/poppins/v20/pxiDyp8kv8JHgFVrJJLm21llEN2PQEhcqw.ttf',
      fontStyle: '300italic'
    },
    {
      src: 'https://fonts.gstatic.com/s/poppins/v20/pxiGyp8kv8JHgFVrJJLed3FBGPaTSQ.ttf',
      fontStyle: '400italic'
    },
    {
      src: 'https://fonts.gstatic.com/s/poppins/v20/pxiDyp8kv8JHgFVrJJLmg1hlEN2PQEhcqw.ttf',
      fontStyle: '500italic'
    },
    {
      src: 'https://fonts.gstatic.com/s/poppins/v20/pxiDyp8kv8JHgFVrJJLmr19lEN2PQEhcqw.ttf',
      fontStyle: '600italic'
    },
    {
      src: 'https://fonts.gstatic.com/s/poppins/v20/pxiDyp8kv8JHgFVrJJLmy15lEN2PQEhcqw.ttf',
      fontStyle: '700italic'
    },
    {
      src: 'https://fonts.gstatic.com/s/poppins/v20/pxiDyp8kv8JHgFVrJJLm111lEN2PQEhcqw.ttf',
      fontStyle: '800italic'
    },
    {
      src: 'https://fonts.gstatic.com/s/poppins/v20/pxiDyp8kv8JHgFVrJJLm81xlEN2PQEhcqw.ttf',
      fontStyle: '900italic'
    },

    {
      src: 'https://fonts.gstatic.com/s/poppins/v20/pxiGyp8kv8JHgFVrLPTed3FBGPaTSQ.ttf',
      fontWeight: 100
    },
    {
      src: 'https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLFj_V1tvFP-KUEg.ttf',
      fontWeight: 200
    },
    {
      src: 'https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLDz8V1tvFP-KUEg.ttf',
      fontWeight: 300
    },
    {
      src: 'https://fonts.gstatic.com/s/poppins/v20/pxiEyp8kv8JHgFVrFJDUc1NECPY.ttf',
      fontWeight: 400
    },
    {
      src: 'https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLGT9V1tvFP-KUEg.ttf',
      fontWeight: 500
    },
    {
      src: 'https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLEj6V1tvFP-KUEg.ttf',
      fontWeight: 600
    },
    {
      src: 'https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLCz7V1tvFP-KUEg.ttf',
      fontWeight: 700
    },
    {
      src: 'https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLDD4V1tvFP-KUEg.ttf',
      fontWeight: 800
    },
    {
      src: 'https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLBT5V1tvFP-KUEg.ttf',
      fontWeight: 900
    }
  ]
});

Font.register({
  family: 'Sanchez',
  fonts: [
    {
      src: 'https://fonts.gstatic.com/s/sanchez/v13/Ycm0sZJORluHnXbIfmxR-D4Bxl3gkw.ttf',
      fontStyle: '400italic'
    },
    {
      src: 'https://fonts.gstatic.com/s/sanchez/v13/Ycm2sZJORluHnXbITm5b_BwE1l0.ttf',
      fontWeight: 400
    }
  ]
});

Font.register({
  family: 'Blinker',
  fonts: [
    {
      src: 'https://fonts.gstatic.com/s/blinker/v13/cIf_MaFatEE-VTaP_E2hZEsCkIt9QQ.ttf',
      fontWeight: 100
    },
    {
      src: 'https://fonts.gstatic.com/s/blinker/v13/cIf4MaFatEE-VTaP_OGARGEsnIJkWL4.ttf',
      fontWeight: 200
    },
    {
      src: 'https://fonts.gstatic.com/s/blinker/v13/cIf4MaFatEE-VTaP_IWDRGEsnIJkWL4.ttf',
      fontWeight: 300
    },
    {
      src: 'https://fonts.gstatic.com/s/blinker/v13/cIf9MaFatEE-VTaPxCmrYGkHgIs.ttf',
      fontWeight: 400
    },
    {
      src: 'https://fonts.gstatic.com/s/blinker/v13/cIf4MaFatEE-VTaP_PGFRGEsnIJkWL4.ttf',
      fontWeight: 600
    },
    {
      src: 'https://fonts.gstatic.com/s/blinker/v13/cIf4MaFatEE-VTaP_JWERGEsnIJkWL4.ttf',
      fontWeight: 700
    },
    {
      src: 'https://fonts.gstatic.com/s/blinker/v13/cIf4MaFatEE-VTaP_ImHRGEsnIJkWL4.ttf',
      fontWeight: 800
    },
    {
      src: 'https://fonts.gstatic.com/s/blinker/v13/cIf4MaFatEE-VTaP_K2GRGEsnIJkWL4.ttf',
      fontWeight: 900
    }
  ]
});

export const templateStyles = StyleSheet.create({
  page: { fontSize: '11px', paddingTop: '90px', paddingBottom: '120px' },
  headerWrap: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    backgroundColor: '#F4F5F6',
    height: '40px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '5px 40px'
  },
  titleWrap: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  totalScoreWrap: {
    marginLeft: 'auto',
    width: '127px',
    height: '40px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    borderRadius: '4px',
    color: '#F4F5F6',
    fontFamily: 'Blinker',
    fontWeight: 600,
    fontSize: '16px'
  },
  totalScore: {
    fontFamily: 'Blinker',
    fontWeight: 700,
    fontSize: '16px'
  },
  score: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    width: '30px',
    height: '18px',
    backgroundColor: '#3DBD4A',
    borderRadius: '8px',
    color: '#F4F5F6',
    fontFamily: 'Blinker',
    fontWeight: 700,
    fontSize: '13px'
  },
  previewWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    flexDirection: 'row',
    gap: '5px',
    flexWrap: 'wrap'
  },
  imgPreviewWrap: {
    overflow: 'hidden'
  },
  contentWrap: {
    padding: '0 70px',
    display: 'flex',
    flexDirection: 'column'
    // gap: '30px',
  },
  header: {
    marginBottom: '30px'
  },
  termSectionWrap: {
    display: 'flex',
    flexDirection: 'row',
    gap: '30px'
  },
  termFieldsWrap: {
    display: 'flex',
    flexDirection: 'column',
    width: '30%',
    gap: '6px'
  },
  swotWrapper: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    paddingTop: '20px',
    fontFamily: 'Open Sans',
    fontWeight: 600,
    fontSize: '9px',
    color: '#757575'
  },
  swotHeader: {
    fontFamily: 'Open Sans',
    fontWeight: 700,
    fontSize: '14px',
    color: '#757575',
    paddingBottom: '10px'
  },
  swotItem: {
    width: '49%',
    padding: 20,
    marginBottom: 20
  },
  swotPreviewWrap: {
    width: '90%'
  },
  watermarkWrap: {
    position: 'absolute',
    left: '100px',
    top: '150px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  watermark: {
    width: '411px',
    height: '392px',
    opacity: 0.1,
    objectFit: 'contain'
  },
  watermarkText: {
    textAlign: 'center',
    color: '#84939A',
    fontFamily: 'Blinker',
    fontSize: '70px',
    fontWeight: 600,
    transform: 'rotate(-49.757deg)'
  },
  logoImg: { width: '100px', height: '50px', objectFit: 'contain' },
  footerWrap: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    textAlign: 'center',
    padding: '20px 40px',
    borderTop: '1px solid #F4F5F6'
  },
  footer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '10px',
    width: '100%'
  },
  logoWrap: {
    width: '30%'
  },
  footerSectionWrap: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '4px',
    color: '#757575',
    fontSize: 7,
    fontWeight: 600,
    width: '30%'
  },
  firmName: {
    fontSize: 9,
    fontFamily: 'Roboto',
    fontWeight: 700,
    color: '#474747'
  },
  firmAddress: {
    textAlign: 'left',
    lineHeight: '1.3px'
  },
  tierName: {
    fontSize: 10,
    color: '#5E7681',
    fontFamily: 'Sanchez',
    fontStyle: '400italic'
  },
  footerText: {
    fontSize: 10,
    color: 'grey'
  }
});

export const PDFTemplate = ({
  tier,
  investmentDescription,
  firmSettings,
  templateStylingData,
  investmentName,
  includeAiSuggested,
  generatingPdf
}: PDFTemplateProps) => {
  if (!tier) return <></>;

  const [imageSizes, setImageSizes] = useState<{
    [url: string]: { width: number; height: number };
  }>({});
  const [logoImg, setLogoImg] = useState('');
  const [watermark, setWatermark] = useState('');

  const totalScore = tier.sections.reduce((accumulator, currentValue) => accumulator + currentValue.data.score, 0);

  useEffect(() => {
    try {
      const parsedValue = getValueByKey(templateStylingData, 'watermark') ? JSON.parse(getValueByKey(templateStylingData, 'watermark')) : '';
      if (isURL(parsedValue.value)) {
        updateImageUrl(parsedValue.assetKey, setWatermark);
        return;
      }
      if (parsedValue.value) {
        setWatermark(parsedValue.value);
      }
    } catch (error) {
      console.error(error);
    }
  }, [templateStylingData]);

  useEffect(() => {
    try {
      const parsedValue = getValueByKey(firmSettings, 'logo') ? JSON.parse(getValueByKey(firmSettings, 'logo')) : '';
      if (parsedValue.value) {
        updateImageUrl(parsedValue.assetKey, setLogoImg);
      }
    } catch (error) {
      console.error(error);
    }
  }, [firmSettings]);

  useEffect(() => {
    const getImageSizeFromUrl = (url: string) => {
      const img = new Image();

      img.onload = () => {
        const imageSize = {
          width: img.naturalWidth,
          height: img.naturalHeight
        };
        setImageSizes((prevSizes) => ({ ...prevSizes, [url]: imageSize }));
      };
      img.src = url;
    };

    tier.sections.forEach((section) => {
      section.fields.forEach((field) => {
        if (field.data.attachments && field.data.attachments.length > 0) {
          field.data.attachments.forEach((attachment) => {
            if (attachment.includeInAutomatedContent) {
              getImageSizeFromUrl(attachment.asset.url);
            }
          });
        }
      });
    });
  }, [tier.sections]);

  const updateImageUrl = async (assetKey: string, setImage: Dispatch<SetStateAction<string>>) => {
    const { data } = await client.query({
      query: getAssetSignedUrl,
      fetchPolicy: 'network-only',
      variables: {
        assetKey: assetKey
      }
    });
    setImage(data.getAssetSignedUrl);
  };

  const styles = StyleSheet.create({
    investmentName: {
      fontFamily: getFontByKey(templateStylingData, 'headerFontStyle').fontFamily
        ? getFontByKey(templateStylingData, 'headerFontStyle').fontFamily
        : 'Roboto Slab',
      fontWeight: 700,
      fontSize: '22px',
      color: Boolean(getFontByKey(templateStylingData, 'headerFontStyle').fontColor)
        ? `#${getFontByKey(templateStylingData, 'headerFontStyle').fontColor}`
        : '#757575'
    },
    fieldValue: {
      fontSize: 9,
      fontFamily: getFontByKey(templateStylingData, 'bodyFontStyle').fontFamily
        ? getFontByKey(templateStylingData, 'bodyFontStyle').fontFamily
        : 'Open Sans',
      color: Boolean(getFontByKey(templateStylingData, 'bodyFontStyle').fontColor)
        ? `#${getFontByKey(templateStylingData, 'bodyFontStyle').fontColor}`
        : '#757575',
      fontWeight: getFontByKey(templateStylingData, 'bodyFontStyle').fontWeight ? getFontByKey(templateStylingData, 'bodyFontStyle').fontWeight : 600,
      paddingTop: '2px',
      lineHeight: '1.3px'
    }
  });

  const getSection = (section: ISection) => {
    switch (section.pdfLayout) {
      case 'THREE_COLUMN':
        return (
          <ThreeColumnSection
            key={section.data.id}
            section={section}
            imageSizes={imageSizes}
            templateStylingData={templateStylingData}
            includeAiSuggested={includeAiSuggested}
          />
        );
      case 'TWO_COLUMN_GRID':
        return (
          <TwoColumnSection
            key={section.data.id}
            section={section}
            templateStylingData={templateStylingData}
            includeAiSuggested={includeAiSuggested}
          />
        );
      default:
        return (
          <OneColumnSection
            key={section.data.id}
            section={section}
            imageSizes={imageSizes}
            templateStylingData={templateStylingData}
            includeAiSuggested={includeAiSuggested}
          />
        );
    }
  };

  return (
    <Document onRender={() => generatingPdf(true)}>
      <Page size="A4" style={templateStyles.page}>
        <View style={templateStyles.headerWrap} fixed>
          <Text style={templateStyles.firmName}>{getValueByKey(firmSettings, 'firmName')}</Text>
          <Text style={templateStyles.tierName}>{tier.name}</Text>
        </View>
        {watermark !== 'None' && isURL(watermark) ? (
          <View style={templateStyles.watermarkWrap} fixed>
            <PdfImage style={templateStyles.watermark} src={watermark} />
          </View>
        ) : null}
        {watermark !== 'None' && !isURL(watermark) ? (
          <View
            style={{
              position: 'absolute',
              bottom: watermark.length > 15 ? '250px' : '400px',
              left: '50px',
              opacity: 0.1
            }}
            fixed
          >
            <Text style={templateStyles.watermarkText}>{watermark}</Text>
          </View>
        ) : null}
        <View style={templateStyles.contentWrap}>
          <View style={templateStyles.header}>
            <View style={templateStyles.titleWrap}>
              <View
                style={{
                  ...styles.investmentName,
                  width: tier.sections.filter((section) => section.userCanScore).length ? '70%' : '100%'
                }}
              >
                <Text>{investmentName}</Text>
              </View>
              {tier.sections.filter((section) => section.userCanScore).length ? (
                <>
                  {totalScore > 0 ? (
                    <View
                      style={{
                        ...templateStyles.totalScoreWrap,
                        backgroundColor: '#3DBD4A'
                      }}
                    >
                      <Text>Total score </Text>
                      <Text style={templateStyles.totalScore}>{`+${totalScore}`}</Text>
                    </View>
                  ) : null}
                  {totalScore === 0 ? (
                    <View
                      style={{
                        ...templateStyles.totalScoreWrap,
                        backgroundColor: '#F4F5F6',
                        color: '#474747',
                        border: '1px solid #474747'
                      }}
                    >
                      <Text>Total score </Text>
                      <Text style={templateStyles.totalScore}>{totalScore}</Text>
                    </View>
                  ) : null}
                  {totalScore < 0 ? (
                    <View
                      style={{
                        ...templateStyles.totalScoreWrap,
                        backgroundColor: '#D63B4B'
                      }}
                    >
                      <Text>Total score </Text>
                      <Text style={templateStyles.totalScore}>{totalScore}</Text>
                    </View>
                  ) : null}
                </>
              ) : (
                <></>
              )}
            </View>
            <Text
              style={{
                ...styles.fieldValue,
                fontSize: '10px',
                color: '#5A5A5A',
                paddingTop: '15px'
              }}
            >
              {investmentDescription}
            </Text>
          </View>

          {tier.sections.map((section) => getSection(section))}
        </View>
        <View style={templateStyles.footerWrap} fixed>
          <View style={templateStyles.footer}>
            <View style={templateStyles.logoWrap}>{logoImg.length ? <PdfImage style={templateStyles.logoImg} src={logoImg} /> : null}</View>
            <View style={templateStyles.footerSectionWrap}>
              <Text style={templateStyles.firmName}>{getValueByKey(firmSettings, 'firmName')}</Text>
              <Text style={templateStyles.firmAddress}>{getValueByKey(firmSettings, 'address')}</Text>
            </View>
            <View style={templateStyles.footerSectionWrap}>
              <Text>{getValueByKey(firmSettings, 'website')}</Text>
              <Text>{getValueByKey(firmSettings, 'email')}</Text>
              {getValueByKey(firmSettings, 'phone').length ? <Text>Office: {getValueByKey(firmSettings, 'phone')}</Text> : null}
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};
