import { useMutation } from '@apollo/client';
import { DeleteBucketIcon } from '@/assets/static/icons/delete-bucket';
import ModalWrappedContent from '@/components/basicComponents/modal/modalWrappedContent';
import { DELETE_DEAL_PROMPT_GROUP } from '@/components/settings-page/queries';
import styled from 'styled-components';

interface DeleteAllPromptProps {
  onClose: () => void;
  isOpen: boolean;
  fieldName: string;
  promptGroupId: string;
  closePromptPage: () => void;
  refetch: () => void;
  savePromptGroup?: (promptGroup: { [key: string]: string } | null) => void;
}

const DeleteAllPrompt = ({ onClose, isOpen, fieldName, promptGroupId, closePromptPage, refetch, savePromptGroup }: DeleteAllPromptProps) => {
  const [deletePromptGroup] = useMutation(DELETE_DEAL_PROMPT_GROUP);

  const saveValue = () => {
    deletePromptGroup({
      variables: {
        id: promptGroupId
      },
      onCompleted: () => {
        if (savePromptGroup) {
          savePromptGroup(null);
        }
        refetch();
        closePromptPage();
      }
    });
  };

  return (
    <ModalWrappedContent isOpen={isOpen} onClose={onClose} confirmButton={{ name: 'Confirm', onClick: saveValue }}>
      <ModalContainer>
        <DeleteBucketIcon />
        <ModalTitle>
          Please confirm you want to delete all Prompt Settings for <span style={{ fontWeight: '700' }}>{fieldName}</span>.
        </ModalTitle>
        <ModalDescription>
          Data will be permanently discarded upon deletion. Fields without prompt settings are ignored by the AI Assistant.
        </ModalDescription>
      </ModalContainer>
    </ModalWrappedContent>
  );
};

export default DeleteAllPrompt;

const ModalContainer = styled.div`
  font-weight: 400;
  padding: 10px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;

const ModalTitle = styled.div`
  font-size: 33px;
  font-weight: 400;
  text-align: center;
  color: #2e2e2e;
`;

const ModalDescription = styled.div`
  font-size: 16px;
  line-height: 22.4px;
  text-align: center;
  color: #373f4e;
`;
