import styled from 'styled-components';

import Switcher from './index';

type SpecialControlHeader = {
  switcher: boolean;
  setSwitcher: () => void;
  title?: string;
  hide?: boolean;
};

const SpecialControlHeader = ({ switcher, setSwitcher, title = '', hide }: SpecialControlHeader) => {
  if (hide) {
    return <></>;
  }
  return (
    <SpecialControlClient>
      <Switcher isActive={switcher} onClick={setSwitcher} />
      <IncludeRecommendations>{title}</IncludeRecommendations>
    </SpecialControlClient>
  );
};

const IncludeRecommendations = styled.div`
  font-family: Blinker, serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  color: ${({ theme }) => theme.font.base};
`;

const SpecialControlClient = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export default SpecialControlHeader;
