import styled from 'styled-components';
import FormattingTooltip from '../../basicComponents/tooltip/dollarsTooltip';
import { IFirmOverviewPrivate } from '../types/firm-overview';

interface CardProps {
  data: IFirmOverviewPrivate;
  title: string;
  isHighlighted: boolean;
}

export const Card = ({ data, title, isHighlighted }: CardProps) => {
  return (
    <BottomCard isHighlighted={isHighlighted}>
      <BottomCardTitle isHighlighted={isHighlighted}>{title}</BottomCardTitle>
      <BottomCardBody>
        <BottomCardRow>
          <BottomCardRowValue>
            <FormattingTooltip noCent>{data.targeted}</FormattingTooltip>
          </BottomCardRowValue>
          <BottomCardRowDesc>TARGETED</BottomCardRowDesc>
        </BottomCardRow>
        <BottomCardRow>
          <BottomCardRowValue>
            <FormattingTooltip noCent>{data.committed}</FormattingTooltip>
          </BottomCardRowValue>
          <BottomCardRowDesc>COMMITTED</BottomCardRowDesc>
        </BottomCardRow>
        <BottomCardRow>
          <BottomCardRowValue>
            <FormattingTooltip noCent>{data.capacity}</FormattingTooltip>
          </BottomCardRowValue>
          <BottomCardRowDesc>CAPACITY</BottomCardRowDesc>
        </BottomCardRow>
        <BottomCardRow>
          <div style={{ width: '50%', display: 'flex', justifyContent: 'flex-end' }}>
            <Amount>{data.openFundsCount}</Amount>
          </div>
          <BottomCardRowDesc>OPEN FUNDS</BottomCardRowDesc>
        </BottomCardRow>
      </BottomCardBody>
    </BottomCard>
  );
};

const BottomCard = styled.div<{ isHighlighted: boolean }>`
  width: 23%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme, isHighlighted }) => (isHighlighted ? 'transparent' : theme.layer[1])};
  padding: 0px;
  border: ${({ theme, isHighlighted }) => (isHighlighted ? `4px solid ${theme.header.base}` : 'none')};
  border-radius: 17px;
  @media (max-width: 1200px) {
    width: 47%;
  }
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const BottomCardTitle = styled.div<{ isHighlighted: boolean }>`
  background: ${({ theme, isHighlighted }) => (isHighlighted ? theme.header.base : theme.layer[2])};
  color: ${({ theme, isHighlighted }) => (isHighlighted ? theme.header.action : theme.font.base)};
  width: 100%;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Lato;
  font-weight: 700;
  font-size: 21px;
`;

const BottomCardBody = styled.div`
  padding: 20px 5px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  row-gap: 10px;
  background: ${({ theme }) => theme.layer[1]};
`;

const BottomCardRow = styled.div`
  width: 100%;
  display: flex;
  column-gap: 10px;
`;

const BottomCardRowValue = styled.span`
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-family: Lato;
  font-weight: 800;
  font-size: 16px;
  color: ${({ theme }) => theme.font.base};
`;

const BottomCardRowDesc = styled.span`
  width: 50%;
  display: flex;
  align-items: center;
  font-family: Lato;
  font-weight: 600;
  font-size: 13px;
  color: ${({ theme }) => theme.font.weak};
`;

const Amount = styled.div`
  background: ${({ theme }) => theme.header.base};
  border-radius: 14px;
  font-weight: 700;
  font-size: 16px;
  color: ${({ theme }) => theme.context.light};
  padding: 1px 15px;
`;
