import { gql } from '@apollo/client';

export const importInitialCommitmentsMutation = gql`
  mutation importInitialCommitments($data: AllocationsImportDto!) {
    importInitialCommitments(data: $data) {
      status
      summary {
        unchangedAllocations
        allocationsMarkedAsCommitted
        allocationsStillPending
      }
      errors {
        message
        id
      }
      allocationErrors {
        message
        importRow
        entityName
        investmentCode
        entityCode
        tradeDate
        netAmount
        investmentId
        legalEntityId
      }
      allocationComparisons {
        investmentCode
        entityCode
        investmentId
        legalEntityId
        validated
        legalEntity {
          id
          entityName
          tenant {
            name
          }
        }
        investment {
          id
          name
        }
        importAllocations {
          entityCode
          investmentCode
          netAmount
        }
        existingEntityCode
        existingInvestmentCodes
        existingAllocations {
          id
          committedCapital
          allocationStatus
          legalEntity {
            id
            entityName
          }
          investment {
            id
            name
          }
        }
        pendingAllocations {
          id
          allocationStatus
          committedCapital
          legalEntity {
            id
            entityName
          }
          investment {
            id
            name
          }
        }
        importTotal
        importCount
        committedTotal
        pendingAmount
      }
      mismatchedAllocationErrors {
        message
        allocationComparison {
          investmentCode
          entityCode
          investmentId
          legalEntityId
          legalEntity {
            id
            entityName
            tenant {
              name
            }
          }
          investment {
            id
            name
          }
          importAllocations {
            entityCode
            investmentCode
            netAmount
          }
          existingAllocations {
            id
            committedCapital
            allocationStatus
            legalEntity {
              id
              entityName
            }
            investment {
              id
              name
            }
          }
          existingEntityCode
          existingInvestmentCodes
          pendingAllocations {
            id
            allocationStatus
            committedCapital
            legalEntity {
              id
              entityName
            }
            investment {
              id
              name
            }
          }
          importTotal
          importCount
          committedTotal
          pendingAmount
        }
      }
      mismatchedAllocationwarnings {
        message
        investmentId
        legalEntityId
        committedCapital
      }
    }
  }
`;

export const importCommitmentsTransactionMutation = gql`
  mutation importCommitmentsTransaction($data: CommitmentTransactionImportDto!) {
    importCommitmentsTransaction(data: $data) {
      status
      errors {
        message
        id
      }
      commitmentErrors {
        message
        importRow
        investmentCode
        entityCode
      }
    }
  }
`;

export const importCommitmentsDetailMutation = gql`
  mutation importCommitmentsDetail($data: CommitmentDetailsImportDto!) {
    importCommitmentsDetail(data: $data) {
      status
      errors {
        message
        id
      }
      commitmentErrors {
        message
        importRow
        investmentCode
        entityCode
      }
    }
  }
`;

export const createInvestmentVehicleMutation = gql`
  mutation createInvestmentVehicle($data: InvestmentVehicleCreateDto!) {
    createInvestmentVehicle(data: $data) {
      id
      investmentId
    }
  }
`;

export const LEGAL_ENTITIES_QUERY = gql`
  query LegalEntities($filter: LegalEntityFilter) {
    LegalEntities(filter: $filter) {
      id
      entityName
      tenant {
        name
      }
    }
  }
`;

export const LIST_INVESTMENT_VEHICLES_QUERY = gql`
  query listInvestmentVehicles($seriesCodes: [String!]!) {
    listInvestmentVehicles(seriesCodes: $seriesCodes) {
      id
      investmentId
      investment {
        id
        name
      }
    }
  }
`;

export const INVESTMENTS_QUERY_BASIC = gql`
  query Investments($page: Int, $rowsPerPage: Int) {
    Investments(page: $page, rowsPerPage: $rowsPerPage) {
      total
      page
      perPage
      data {
        id
        name
      }
    }
  }
`;

export const LIST_ENTITIES_QUERY_BASIC = gql`
  query listEntities($data: LegalEntityListDto!) {
    listEntities(data: $data) {
      data {
        id
        entityName
        importId
        tenant {
          name
        }
      }
    }
  }
`;

export const importLegalEntitiesMutation = gql`
  mutation ImportLegalEntities($data: LegalEntityImportArgs!) {
    importLegalEntities(data: $data) {
      entityResponse {
        newEntitiesCount
        updatedEntitiesCount
        unchangedEntitiesCount
        entityImportComparisons {
          entityCRMId
          entityName
          investorName
          fieldsModifiedCount
          status
          entityFieldComparisons {
            fieldName
            currentValue
            newValue
            status
            tooltip
          }
        }
      }
      investorResponse {
        newInvestorsCount
        updatedInvestorsCount
        investorsToDeleteCount
        investorComparisons {
          investorName
          totalEntities
          entitiesAdded
          entitiesRemoved
          advisorEmail
          advisorConflict
          status
          entityAdvisors {
            entityName
            existingEmail
            importEmail
          }
        }
      }
      groupResponse {
        newGroupsCount
        updateGroupsCount
        groupsToDeleteCount
        groupImportComparisons {
          investorName
          totalGrouped
          newlyGrouped
          ungrouped
          primaryEntityName
          status
          entityGroups {
            entityName
            isPrimaryEntity
          }
        }
      }
    }
  }
`;
