export const header = [
  {
    width: 10,
    title: 'Investment',
    key: 'name',
    keySort: 'investment',
  },
  {
    width: 9,
    title: 'Manager',
    key: 'managerId',
    keySort: 'manager',
  },
  {
    width: 10,
    title: 'Asset Class',
    key: 'assetClass',
    keySort: 'asset class',
  },
  {
    width: 10,
    title: 'Next Close',
    key: 'nextClose',
    keySort: 'next close',
  },
  {
    width: 10,
    title: 'Final Close',
    key: 'finalClose',
    keySort: 'final close',
  },
  {
    width: 8,
    title: 'Minimum',
    key: 'minimum',
    keySort: 'minimum',
  },
  {
    width: 9,
    title: 'Custodied',
    key: 'custodied',
    keySort: 'custodied',
  },
  {
    width: 10,
    title: 'UBTI Blocker',
    key: 'ubtiBlocker',
    keySort: 'ubti blocker',
  },
  {
    width: 6,
    title: 'SRI',
    key: 'sri',
    keySort: 'sri',
  },
  {
    width: 9,
    title: 'Target IRR',
    key: 'targetIRR',
    keySort: 'target irr',
  },
  {
    width: 9,
    title: 'Target Yield',
    key: 'targetYield',
    keySort: 'target yield',
  },
  {
    width: 5,
    title: '',
    key: 'dropdown',
    keySort: 'dropdown',
  },
];

export enum SUITABILITY_STATUS {
  SUITABLE = 'Suitable',
  ALL_UNSUITABILITY = 'All Unsuitable',
  ON_HOLD = 'On hold',
  EXCLUDED_STRATEGY = 'Excluded strategy',
  CUSTODIED_REQUIREMENT = 'Custodied requirement',
  UBTI_REQUIREMENT = 'UBTI requirement',
  NO_CAPACITY = 'No capacity',
  ASSET_CLASS_FULL = 'Asset class full',
  BELOW_MINIMUM = 'Below minimum',
  NO_CAPACITY_PENDING_PROPOSAL = 'Below minimum (pending)',
  CAPACITY_BELOW_BITESIZE = 'Capacity below bitesize',
  CAPACITY_BELOW_BITESIZE_PENDING = 'Capacity below bitesize (pending)',
  ASSET_CLASS_BELOW_MINIMUM = 'Asset class below minimum',
  ASSET_CLASS_FULL_PENDING_PROPOSAL = 'Asset class below minimum (pending)',
  ASSET_CLASS_BELOW_BITESIZE = 'Asset class below bitesize',
  ASSET_CLASS_BELOW_BITESIZE_PENDING = 'Asset class below bitesize (pending)',
  BITESIZE_BELOW_MINIMUM = 'Bite size below minimum',
}

export enum DECISION_STATUS {
  YES = 'Yes',
  YES_ALWAYS_SHOW = 'Yes - Always Show',
  NO = 'No',
}

export const allocationListSort = [
  {
    title: 'Investment • A -> Z',
    key: 'name',
    asc: true,
  },
  {
    title: 'Investment • Z -> A',
    key: 'name',
    asc: false,
  },
  {
    title: 'Manager • A -> Z',
    key: 'managerId',
    asc: true,
  },
  {
    title: 'Manager • Z -> A',
    key: 'managerId',
    asc: false,
  },
  {
    title: 'Asset Class • Earlier statuses -> Later statuses',
    key: 'assetClass',
    asc: true,
  },
  {
    title: 'Asset Class • Later statuses -> Earlier statuses',
    key: 'assetClass',
    asc: false,
  },
  {
    title: 'Next Close • Newest -> Oldest',
    key: 'nextClose',
    asc: true,
  },
  {
    title: 'Next Close • Oldest -> Newest',
    key: 'nextClose',
    asc: false,
  },
  {
    title: 'Final Close • Newest -> Oldest',
    key: 'finalClose',
    asc: true,
  },
  {
    title: 'Final Close • Oldest -> Newest',
    key: 'finalClose',
    asc: false,
  },
  {
    title: 'Minimum • Max -> Min',
    key: 'minimum',
    asc: false,
  },
  {
    title: 'Minimum • Min -> Max',
    key: 'minimum',
    asc: true,
  },
  {
    title: 'Custodied • Yes -> No',
    key: 'custodied',
    asc: false,
  },
  {
    title: 'Custodied • No -> Yes',
    key: 'custodied',
    asc: true,
  },
  {
    title: 'UBTI Blocker • Yes -> No',
    key: 'ubtiBlocker',
    asc: false,
  },
  {
    title: 'UBTI Blocker • No -> Yes',
    key: 'ubtiBlocker',
    asc: true,
  },
  {
    title: 'SRI • Yes -> No',
    key: 'sri',
    asc: false,
  },
  {
    title: 'SRI • No -> Yes',
    key: 'sri',
    asc: true,
  },
  {
    title: 'Target IRR • Max -> Min',
    key: 'targetIRR',
    asc: false,
  },
  {
    title: 'Target IRR • Min -> Max',
    key: 'targetIRR',
    asc: true,
  },
  {
    title: 'Target Yield • Max -> Min',
    key: 'targetYield',
    asc: false,
  },
  {
    title: 'Target Yield • Min -> Max',
    key: 'targetYield',
    asc: true,
  },
];

export enum ROLE {
  INVESTMENT_LEAD = 'Investment Lead',
  INVESTMENT_MEMBER = 'Investment Member',
  ADVISOR_LEAD = 'Advisor Lead',
  ADVISOR_MEMBER = 'Advisor Member',
  ADVISOR_ASSOCIATE = 'Advisor Associate',
  OPERATIONS_LEAD = 'Operations Lead',
  OPERATIONS_MEMBER = 'Operations Member',
  COMPLIANCE_LEAD = 'Compliance Lead',
  COMPLIANCE_MEMBER = 'Compliance Member',
  ADMIN = 'Admin',
  SUPER_ADMIN = 'Super Admin',
  VIEWER = 'Viewer',
}
