import { AppRoutes } from '@/routes';
import GlobalStyle from '@/styles/global';
import { FC } from 'react';
import { Helmet } from 'react-helmet';
import { BrowserRouter } from 'react-router-dom';
import './App.css';
import { MaintenanceMode } from './components/fat-maintenance-page/maintenanceMode';

const App: FC = () => {
  return (
    <>
      <Helmet>
        <meta name="description" content={process.env.REACT_APP_WEBSITE_DESCRIPTION} />
      </Helmet>
      <GlobalStyle />
      <MaintenanceMode>
        <BrowserRouter>
          <AppRoutes />
        </BrowserRouter>
      </MaintenanceMode>
    </>
  );
};

export default App;
