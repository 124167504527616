import * as React from 'react';

export const MinusScoreIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={props?.width || '16'} height={props?.height || '16'} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.0018 7.39892V8.59748H11.0018V7.39892M8.0036 2C6.81661 1.99929 5.65607 2.35067 4.66883 3.00968C3.68158 3.6687 2.91199 4.60574 2.45741 5.70224C2.00284 6.79875 1.88372 8.00544 2.11512 9.16966C2.34651 10.3339 2.91803 11.4033 3.75736 12.2426C4.59669 13.082 5.66612 13.6535 6.83034 13.8849C7.99456 14.1163 9.20126 13.9972 10.2978 13.5426C11.3943 13.088 12.3313 12.3184 12.9903 11.3312C13.6493 10.3439 14.0007 9.18339 14 7.9964C13.999 6.40635 13.367 4.8817 12.2426 3.75736C11.1183 2.63303 9.59365 2.00095 8.0036 2ZM8.0036 12.7978C7.05381 12.7985 6.12515 12.5176 5.33508 11.9904C4.54502 11.4632 3.92905 10.7136 3.56509 9.83633C3.20112 8.95904 3.10553 7.99353 3.29038 7.0619C3.47524 6.13028 3.93225 5.2744 4.6036 4.60255C5.27495 3.93069 6.13047 3.47305 7.06196 3.28749C7.99344 3.10194 8.95904 3.19681 9.8366 3.56011C10.7142 3.92341 11.4642 4.53883 11.992 5.3285C12.5197 6.11817 12.8014 7.04661 12.8014 7.9964C12.7995 9.26861 12.2936 10.4882 11.3943 11.3882C10.495 12.2881 9.27581 12.795 8.0036 12.7978Z"
      fill={props?.fill || '#4587EC'}
    />
  </svg>
);
