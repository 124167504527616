import { RejectedBannerIcon } from '@/assets/icons/info-icons/rejectedBanner';
import { Banner } from '@/components/basicComponents/banner';
import { IInvestmentVehicle } from '@/components/fat-dealCatalog-page/types';
import { Input } from '@/components/fat-form-field';
import { useResponsive } from '@/hooks/use-responsive';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import styled, { useTheme } from 'styled-components';
import { Section } from '../../section';

interface VehiclesSectionProps {
  vehicles: IInvestmentVehicle[];
  setVehicles: React.Dispatch<React.SetStateAction<IInvestmentVehicle[]>>;
}

export const VehiclesSection = ({ vehicles, setVehicles }: VehiclesSectionProps) => {
  const { isMobile } = useResponsive();
  const theme = useTheme();
  const { setValue, control } = useForm();

  useEffect(() => {
    vehicles.map((vehicle) => {
      setValue(vehicle.id, vehicle.id);
      setValue(`${vehicle.id}_name`, vehicle.name);
    });
    console.log(vehicles);
  }, [vehicles]);

  const handleBlur = (id: string, name: string) => {
    setVehicles((prev) =>
      prev.map((vehicle) => {
        if (vehicle.id === id) {
          return { ...vehicle, name };
        }
        return vehicle;
      })
    );
  };

  if (!vehicles?.length) return null;

  return (
    <Section title="Vehicles">
      <Banner
        title=""
        description="Each vehicle associated with this strategy appears here. Provide a friendly name to use in reporting or leave blank to use the vehicle code by default."
        icon={<RejectedBannerIcon fill={theme.header.base} />}
        bgColor={theme.layer[1]}
        color={theme.font.base}
      />
      <VehiclesSectionWrap>
        <Line isMobile={isMobile}>
          {vehicles.map((vehicle, index) => (
            <InputWrapper isMobile={isMobile} key={index}>
              <Input
                title={vehicle.id}
                nameField={`${vehicle.id}_name`}
                rules={{}}
                control={control}
                placeHolder={'Friendly name for use internally and with clients'}
                onBlur={(evt: any) => handleBlur(vehicle.id, evt.target.value)}
              />
            </InputWrapper>
          ))}
        </Line>
      </VehiclesSectionWrap>
    </Section>
  );
};

const VehiclesSectionWrap = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 20px;
`;

const Line = styled.span<{ isMobile: boolean; width50?: boolean }>`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};
  width: ${({ width50, isMobile }) => (width50 && !isMobile ? '50%' : '100%')};
  gap: 30px;
  padding-right: ${({ width50, isMobile }) => (width50 && !isMobile ? '15px' : '0')};
  margin-top: 4px;
`;

const InputWrapper = styled.div<{ isMobile: boolean }>`
  width: ${({ isMobile }) => (!isMobile ? '48%' : '100%')};
`;
