import * as React from 'react';

export const UserChatIcon = ({ ...props }: React.SVGProps<SVGSVGElement>) => (
  <svg width="37" height="40" style={props?.style} viewBox="0 0 37 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M26.9222 26.8776C26.945 27.2984 26.879 27.7193 26.7283 28.1129C26.5776 28.5064 26.3456 28.8638 26.0475 29.1617C25.4101 29.737 24.5712 30.037 23.7135 29.9963H13.2179C12.3602 30.037 11.5212 29.737 10.8838 29.1617C10.5848 28.8642 10.3519 28.507 10.2004 28.1135C10.0488 27.7199 9.98193 27.2987 10.0042 26.8776C10.0042 26.4528 10.0042 26.0429 10.0492 25.6381C10.08 25.197 10.1368 24.7581 10.2191 24.3237C10.2947 23.8823 10.3999 23.4465 10.534 23.0192C10.6591 22.6129 10.8266 22.2209 11.0337 21.8497C11.2306 21.4811 11.4816 21.1442 11.7784 20.8501C12.0775 20.5729 12.427 20.3557 12.808 20.2104C13.2352 20.0462 13.6898 19.9648 14.1475 19.9705C14.3294 20.0237 14.4992 20.112 14.6473 20.2304L15.5419 20.8052C15.9494 21.0501 16.3859 21.2432 16.8413 21.3799C17.8846 21.7263 19.0118 21.7263 20.055 21.3799C20.5089 21.2432 20.9437 21.0502 21.3495 20.8052L22.2491 20.2304C22.3966 20.1111 22.5665 20.0227 22.7489 19.9705C23.2065 19.9648 23.6611 20.0462 24.0883 20.2104C24.4693 20.3557 24.8189 20.5729 25.1179 20.8501C25.4147 21.1442 25.6657 21.4811 25.8626 21.8497C26.0699 22.2209 26.2374 22.6129 26.3624 23.0192C26.4982 23.4463 26.6051 23.8821 26.6823 24.3237C26.7621 24.7585 26.8188 25.1973 26.8522 25.6381C26.9122 26.0429 26.9222 26.4528 26.9222 26.8776ZM21.4994 11.384C22.311 12.3108 22.7584 13.5008 22.7584 14.7327C22.7584 15.9645 22.311 17.1545 21.4994 18.0812C21.1208 18.5159 20.6535 18.8643 20.1289 19.103C19.6043 19.3418 19.0346 19.4653 18.4582 19.4653C17.8818 19.4653 17.3121 19.3418 16.7875 19.103C16.2629 18.8643 15.7955 18.5159 15.4169 18.0812C14.6024 17.1559 14.153 15.9654 14.153 14.7327C14.153 13.4999 14.6024 12.3093 15.4169 11.384C15.7955 10.9494 16.2629 10.601 16.7875 10.3623C17.3121 10.1235 17.8818 10 18.4582 10C19.0346 10 19.6043 10.1235 20.1289 10.3623C20.6535 10.601 21.1208 10.9494 21.4994 11.384Z"
      fill={props.fill || '#C7CBD2'}
    />
  </svg>
);
