import { IFilterBarData } from '../basicComponents/filterBar';
import { Investment } from '../fat-dealCatalog-page/types';

export interface IListInvestmentsForManagerUpdateQuery {
  total: number;
  page: number;
  perPage: number;
  filters: IFilterBarData[];
  data: Investment[];
}

export enum SECURITY_STATUS {
  LEAD = 'Lead',
  DECLINED = 'Declined',
  DUE_DILIGENCE = 'Due Diligence',
  APPROVED = 'Approved',
  REJECTED = 'Rejected',
  DRAFT = 'Draft',
  IN_RESEARCH = 'In Research',
  OTHER_RECOMMENDED = 'Other Recommended',
  OPEN = 'Open',
  ACTIVE = 'Active',
  REALIZED = 'Realized',
  REDEEMED = 'Redeemed',
  TERMINATED = 'Terminated'
}
