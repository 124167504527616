import React from 'react';

import { CellWrapper, TextCell } from '../../../table/styles';

interface NextStepProps {
  status: string;
  width?: string;
}

const NextStep = ({ status, width }: NextStepProps) => {
  return (
    <CellWrapper width={width} alignItems={'center'}>
      <TextCell>{status}</TextCell>
    </CellWrapper>
  );
};

export default React.memo(NextStep);
