import styled from 'styled-components';

import { DEFAULT_LOADER_SIZE } from './constants';
import { Loader } from './loader';

interface LoaderProps {
  size?: number;
  className?: string;
  isShadow?: boolean;
}

export const LoaderOnWholeScreen = ({ size = DEFAULT_LOADER_SIZE, isShadow, className }: LoaderProps) => {
  return (
    <ContainerLoader className={className} isShadow={!!isShadow}>
      <Loader size={size} />
    </ContainerLoader>
  );
};
const ContainerLoader = styled.div<{ isShadow: boolean }>`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ isShadow }) => `rgba(255, 255, 255, ${isShadow ? 0.7 : 0})`};
`;
