import { useState } from 'react';
import styled, { useTheme } from 'styled-components';

import { SortIcon } from '@/assets/icons/sortIcon';
import { InfoCircleIcon } from '@/assets/static/icons/info-circle';
import CheckBox from '../basicComponents/checkbox';
import BasicTooltip from '../basicComponents/tooltip';
import { StyledTooltip } from '../recommendations-page/styles';
import { SIZE_DROPDOWN_ACTIONS } from './constants';
import { ISort, SORT_ORDER, TableColumns } from './types';

type CheckBox = {
  checked: boolean;
  onChange: (isChecked?: boolean) => void;
  show?: boolean;
};

interface TableHeaderProps {
  showActionButton?: boolean;
  columns: TableColumns[];
  withOutSort?: boolean;
  addAdaptive?: boolean;
  refetch: any;
  savedSort?: ISort;
  savedSetSort?: (value: ISort) => void;
  checkBox?: CheckBox;
  className?: string;
}

const TableHeader = ({
  refetch,
  columns,
  addAdaptive = false,
  showActionButton = false,
  withOutSort = false,
  savedSort,
  savedSetSort,
  checkBox,
  className
}: TableHeaderProps) => {
  const theme = useTheme();

  const WIDTH = 100 / columns.length;
  const [sort, setSort] = useState<ISort>(savedSort);
  const [isHidden, setIsHidden] = useState(true);
  const [sortKey, setSortKey] = useState('');

  // useEffect(() => {
  //   if (savedSort) {
  //     setSort(savedSort);
  //   }
  // }, [savedSort]);

  const getStatusSort = (keySort: string) => {
    if (sort.asc) {
      return {
        forBoolean: `Sort ${keySort}  • No -> Yes`,
        forStatuses: `Sort ${keySort} • ${
          keySort === 'status' || keySort === 'asset class'
            ? 'A -> Z'
            : `earlier ${keySort === 'suitability' ? '' : 'statuses'} -> later ${keySort === 'suitability' ? '' : 'statuses'}`
        }`,
        forNumbers: `Sort ${keySort} • Min -> Max`,
        forDates: `Sort ${keySort} • oldest -> newest`,
        forStrings: `Sort ${keySort} • A -> Z`,
        forExpectedClose: `Sort ${keySort} • Sooner -> Later`,
        forInvestorDocsDue: `Sort ${keySort} • Sooner -> Later`,
        forNextTask: `Sort ${keySort} • First -> Last`,
        forPlanStart: `Sort ${keySort} • Oldest -> Most Recent`
      };
    } else {
      return {
        forBoolean: `Sort ${keySort} • Yes -> No`,
        forStatuses: `Sort ${keySort} • ${
          keySort === 'status' || keySort === 'asset class'
            ? 'Z -> A'
            : `later ${keySort === 'suitability' ? '' : 'statuses'} -> earlier ${keySort === 'suitability' ? '' : 'statuses'}`
        }`,
        forNumbers: `Sort ${keySort} • Max -> Min`,
        forDates: `Sort ${keySort} • newest -> oldest`,
        forStrings: `Sort ${keySort} • Z -> A `,
        forExpectedClose: `Sort ${keySort} • Later -> Sooner`,
        forInvestorDocsDue: `Sort ${keySort} • Later -> Sooner`,
        forNextTask: `Sort ${keySort} • Last -> First`,
        forPlanStart: `Sort ${keySort} • Most Recent -> Oldest`
      };
    }
  };

  const getToolTipTitle = (keySort: string) => {
    if (
      [
        'assignee(s)',
        'investment',
        'entity',
        'client/advisor',
        'manager',
        'client / advisor',
        'family',
        'name',
        'entityName',
        'assignee',
        'entityName',
        'investor',
        'owner',
        'opportunity',
        'deal',
        'advisory firms',
        'advisor',
        'strategy',
        'commitment',
        'sub asset class',
        'investment type',
        'entity code',
        'entity name',
        'series',
        'series code',
        'assignment',
        'status',
        'transaction type',
        'commitment plan',
        'update name'
      ].includes(keySort)
    ) {
      return getStatusSort(keySort).forStrings;
    } else if (
      ['suitability', 'asset classes', 'allocation status', 'status', 'asset class', 'termination reason', 'assetClass', 'lastStatus'].includes(
        keySort
      )
    ) {
      return getStatusSort(keySort).forStatuses;
    } else if (
      [
        'last update',
        'status date',
        'next close',
        'final close',
        'finalClose',
        'nextClose',
        'lastStatusDate',
        'date added',
        'update',
        'trade date',
        'last published update',
        'date'
      ].includes(keySort)
    ) {
      return getStatusSort(keySort).forDates;
    } else if (['custodied', 'ubti blocker', 'sri', 'decision'].includes(keySort)) {
      return getStatusSort(keySort).forBoolean;
    } else if (['investor docs due', 'expected close', 'close date'].includes(keySort)) {
      return getStatusSort(keySort).forInvestorDocsDue;
    } else if (['next task'].includes(keySort)) {
      return getStatusSort(keySort).forNextTask;
    } else if (['plan start'].includes(keySort)) {
      return getStatusSort(keySort).forPlanStart;
    } else {
      return getStatusSort(keySort).forNumbers;
    }
  };

  const handleSort = (key: string, order: boolean) => {
    setSort({
      key,
      asc: order
    });
    refetch({
      order: order ? SORT_ORDER.ASC : SORT_ORDER.DESC,
      sort: key
    });
    if (savedSetSort) {
      savedSetSort({
        key,
        asc: order
      });
    }
  };

  return (
    <TableHeaderWrap addAdaptive={addAdaptive} className={className}>
      {checkBox?.show && (
        <CheckboxWrapper>
          <CheckBox id={'input'} isActive={checkBox.checked} onClick={checkBox.onChange} />
        </CheckboxWrapper>
      )}
      {columns.map(({ key, title, width, keySort, tooltip }) => (
        <TableColumnItem width={width ?? WIDTH} key={key ? key : keySort}>
          <TableColumnTitle
            id="tableColumnTitle"
            withOutSort={withOutSort}
            onClick={key && !withOutSort ? () => handleSort(key as string, !sort.asc) : () => null}
          >
            {title}
          </TableColumnTitle>
          {key && !withOutSort ? (
            <StyledTooltip placement={'bottom'} title={getToolTipTitle(keySort as string)} arrow>
              <SortBtn
                onClick={() => handleSort(key as string, !sort.asc)}
                isRotate={key === sort.key && sort.asc}
                isActive={key === sort.key}
                addAdaptive={addAdaptive}
              >
                <SortIcon
                  style={{
                    marginBottom: -3,
                    marginTop: key === sort.key && sort.asc ? -2 : 0
                  }}
                  fill={key === sort.key ? theme.header.action : theme.font.action}
                />
              </SortBtn>
            </StyledTooltip>
          ) : !isHidden && keySort === sortKey && !withOutSort ? (
            <StyledTooltip placement={'bottom'} title={getToolTipTitle(keySort as string)} arrow>
              <SortBtn
                onClick={() => handleSort(key as string, !sort.asc)}
                isRotate={key === sort.key && sort.asc}
                isActive={key === sort.key}
                addAdaptive={addAdaptive}
              >
                <SortIcon
                  style={{
                    marginBottom: -3,
                    marginTop: key === sort.key && sort.asc ? -2 : 0
                  }}
                  fill={key === sort.key ? theme.header.action : theme.font.action}
                />
              </SortBtn>
            </StyledTooltip>
          ) : (
            <></>
          )}
          {tooltip && (
            <BasicTooltip tooltipContent={tooltip} tooltipWidth={300}>
              <InfoCircleIcon fill={theme.header.action} style={{ position: 'relative', top: '3px' }} />
            </BasicTooltip>
          )}
        </TableColumnItem>
      ))}
      {showActionButton && <ActionButtonSpace />}
    </TableHeaderWrap>
  );
};

const TableHeaderWrap = styled.div<{ addAdaptive: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  min-height: 70px;
  background-color: ${({ theme }) => theme.header.base};
  border-radius: 8px;
  padding: 0 24px;
  gap: 5px;
  @media (max-width: 1200px) {
    padding: ${({ addAdaptive }) => ` 0 ${addAdaptive ? 10 : 24}px`};
  }
`;

const CheckboxWrapper = styled.div`
  margin-right: 15px;
`;

const SortBtn = styled.div<{ isActive: boolean; isRotate: boolean; addAdaptive?: boolean }>`
  display: ${({ isActive }) => (isActive ? 'flex' : 'none')};
  transform: ${({ isRotate }) => (isRotate ? 'rotate(180deg)' : 'rotate(0deg)')};
  cursor: pointer;
  justify-content: center;
  align-items: center;
  width: ${({ addAdaptive }) => (addAdaptive ? 20 : 24)}px;
  min-width: ${({ addAdaptive }) => (addAdaptive ? 20 : 24)}px;
  height: ${({ addAdaptive }) => (addAdaptive ? 20 : 24)}px;
  border-radius: 50%;
  background-color: ${({ isActive, theme }) => (isActive ? theme.action.active : 'transparent')};
`;

const TableColumnItem = styled.div<{ width: number }>`
  width: ${({ width }) => width}%;
  //min-width: ${({ width }) => width}%;
  display: flex;
  align-items: center;
  gap: 5px;

  &:hover ${SortBtn} {
    display: flex;
  }
`;

const TableColumnTitle = styled.span<{ withOutSort?: boolean }>`
  flex-basis: 0;
  color: ${({ theme }) => theme.header.action};
  font-family: 'Blinker', serif;
  font-weight: 600;
  font-size: 12px;
  line-height: 22.4px;
  text-transform: uppercase;
  align-items: flex-start;
  cursor: ${({ withOutSort }) => (withOutSort ? 'default' : 'pointer')};
`;
const ActionButtonSpace = styled.div`
  width: ${SIZE_DROPDOWN_ACTIONS}px;
`;

export default TableHeader;
