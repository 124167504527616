import { PlusIcon } from '@/assets/icons/info-icons/plus';
import { IOwner } from '@/components/allocations-page/types';
import Label from '@/components/basicComponents/label';
import Input from '@/components/fat-basicComponents/input';
import { formatDollarInputValue, formatMaskedDollarInputValue } from '@/components/fat-basicComponents/input/utils';
import { Input as FormInput, Select } from '@/components/fat-form-field';
import { IAssetClass, ISubAssetClass } from '@/components/fat-investors-page/fat-addInvestors/types';
import { defaultSelectValue } from '@/components/opportunities-entity/modify/constants';
import { useResponsive } from '@/hooks/use-responsive';
import { useStatusData } from '@/hooks/useStatusData';
import { useEffect, useState } from 'react';
import { Controller, UseFormSetValue, UseFormWatch } from 'react-hook-form';
import styled, { useTheme } from 'styled-components';
import { Section } from '../../section';
import { IForm, PACING_TYPE } from '../../types';
import { getOwnerValues } from '../../utils';

interface DetailsSectionProps {
  control: any;
  onBlur?: any;
  managerList: string[];
  investmentOwners: IOwner[];
  operationsOwners: IOwner[];
  complianceOwners: IOwner[];
  openModalWindow: (type: string) => void;
  assetClasses: IAssetClass[];
  watch: UseFormWatch<IForm>;
  setValue: UseFormSetValue<IForm>;
}

const OWNER_TOOL_TIP = 'Each owner will be the default assignee for relevant tasks associated with this investment.';

const findSubAssetClass = (assetClasses: IAssetClass[], currentSubAssetClassName: string) => {
  for (const assetClass of assetClasses) {
    const subAssetClass = assetClass.subAssetClasses.find((subAssetClass) => subAssetClass.name === currentSubAssetClassName);
    if (subAssetClass) {
      return subAssetClass;
    }
  }
  return null;
};

export const DetailsSection = ({
  control,
  onBlur,
  managerList,
  investmentOwners,
  operationsOwners,
  complianceOwners,
  openModalWindow,
  assetClasses,
  watch,
  setValue
}: DetailsSectionProps) => {
  const { isMobile } = useResponsive();
  const theme = useTheme();
  const { data: statusData } = useStatusData();

  const [subAssetClasses, setSubAssetClasses] = useState<ISubAssetClass[]>([]);
  const [prevAssetClass, setPrevAssetClass] = useState(() => watch('assetClass'));
  const [showPacingTypeSelect, setShowPacingTypeSelect] = useState(false);
  const [shwoAdditionalPacingFields, setShowAdditionalPacingFields] = useState(false);

  useEffect(() => {
    const currentAssetClass = watch('assetClass');

    if (currentAssetClass !== prevAssetClass) {
      const assetClass = assetClasses.find((assetClass) => assetClass.name === currentAssetClass);
      setSubAssetClasses(assetClass?.subAssetClasses || []);
      setValue('subAssetClass', defaultSelectValue);
      // setValue('pacingType', null);
      setPrevAssetClass(currentAssetClass);
      return;
    }

    if (currentAssetClass === defaultSelectValue) {
      setSubAssetClasses([]);
      setValue('subAssetClass', defaultSelectValue);
      return;
    }

    const assetClass = assetClasses.find((assetClass) => assetClass.name === currentAssetClass);
    setSubAssetClasses(assetClass?.subAssetClasses || []);
    setPrevAssetClass(currentAssetClass);
  }, [watch('assetClass')]);

  useEffect(() => {
    if (statusData?.target_sub_asset_classes) return;

    const currentAssetClass = assetClasses.find((assetClass) => assetClass.name === watch('assetClass'));
    if (currentAssetClass) {
      setShowPacingTypeSelect(currentAssetClass.illiquid);
      return;
    }
    setShowPacingTypeSelect(false);
  }, [watch('assetClass')]);

  useEffect(() => {
    if (!statusData?.target_sub_asset_classes) return;

    if (watch('subAssetClass') === defaultSelectValue) {
      setValue('pacingType', null);
    }

    const currentSubAssetClass = findSubAssetClass(assetClasses, watch('subAssetClass'));
    if (currentSubAssetClass) {
      setShowPacingTypeSelect(currentSubAssetClass.illiquid);
      return;
    }
    setShowPacingTypeSelect(false);
  }, [watch('subAssetClass')]);

  useEffect(() => {
    setShowAdditionalPacingFields(showPacingTypeSelect && watch('pacingType') === PACING_TYPE.DRAWDOWN);
  }, [showPacingTypeSelect, watch('pacingType')]);

  useEffect(() => {
    if (showPacingTypeSelect && !watch('pacingType')) {
      setValue('pacingType', PACING_TYPE.DRAWDOWN);
    }
  }, [showPacingTypeSelect]);

  return (
    <Section title="Details">
      <DetailsSectionWrap>
        <Line isMobile={isMobile}>
          <FormInput
            title={'Name'}
            nameField={'name'}
            rules={{
              required: {
                value: true,
                message: 'Name is required'
              }
            }}
            control={control}
            placeHolder={'Friendly name for use internally and with clients'}
            isWarning={true}
            onBlur={onBlur}
          />
          <ManagerWrapper>
            <Select
              title={'Manager'}
              nameField={'manager'}
              control={control}
              rules={{
                required: {
                  value: true,
                  message: 'Manager is required'
                },
                pattern: {
                  value: /^(?!Select$)/,
                  message: 'Manager is required'
                }
              }}
              defaultValue={defaultSelectValue}
              selects={[defaultSelectValue, ...managerList]}
              isWarning={true}
              onBlur={onBlur}
              withSearch
              additionalOption={
                <AddManagerWrapper onClick={() => openModalWindow('create-manager')}>
                  <PlusIcon width={18} height={18} fill={theme.font.action} />
                  <AddText>Add New Manager</AddText>
                </AddManagerWrapper>
              }
            />
          </ManagerWrapper>
        </Line>
        <Line isMobile={isMobile} width50={!statusData?.target_sub_asset_classes}>
          <Select
            title={'Asset Class'}
            nameField={'assetClass'}
            control={control}
            rules={{
              required: {
                value: true,
                message: 'Asset Class is required'
              },
              pattern: {
                value: /^(?!Select$)/,
                message: 'Asset Class is required'
              }
            }}
            defaultValue={defaultSelectValue}
            selects={[defaultSelectValue, ...assetClasses.map((assetClass) => assetClass.name)]}
            isWarning={true}
            onBlur={onBlur}
          />
          {statusData?.target_sub_asset_classes && (
            <Select
              title={'Sub Asset Class'}
              nameField={'subAssetClass'}
              control={control}
              rules={{
                required: {
                  value: true,
                  message: 'Sub Asset Class is required'
                },
                pattern: {
                  value: /^(?!Select$)/,
                  message: 'Sub Asset Class is required'
                }
              }}
              defaultValue={defaultSelectValue}
              selects={[defaultSelectValue, ...subAssetClasses.map((subAssetClass) => subAssetClass.name)]}
              isWarning={true}
              disabled={!subAssetClasses.length}
              onBlur={onBlur}
            />
          )}
        </Line>
        {showPacingTypeSelect && (
          <PacingFieldsWrapper>
            <Line width50 isMobile={isMobile}>
              <Select
                title={'Pacing Type'}
                nameField={'pacingType'}
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: 'Pacing Type is required'
                  }
                }}
                tooltipWidth={300}
                tooltip="Pacing model forecasts will assume that evergreen funds are held indefinitely and that all investor capital is transferred as part of the initial subscription."
                selects={Object.values(PACING_TYPE)}
                isWarning={true}
                onBlur={onBlur}
              />
            </Line>
            {shwoAdditionalPacingFields && (
              <Line width50 isMobile={isMobile}>
                <FormInput
                  title={'Pacing Starting Year'}
                  nameField={'pacingStartingYear'}
                  rules={{
                    required: {
                      value: true,
                      message: 'Pacing Starting Year is required'
                    },
                    pattern: {
                      value: /^\d{4}$/,
                      message: 'Must be four digits only'
                    }
                  }}
                  tooltipWidth={300}
                  tooltip="Provide a year and quarter that roughly corresponds to the inception of the fund.  This is an input to the pacing model and cash flow forecasts."
                  control={control}
                  placeHolder={'Enter a year using four digits'}
                  isWarning={true}
                  onBlur={onBlur}
                />
                <Select
                  title={'Pacing Starting Quarter'}
                  nameField={'pacingStartingQuarter'}
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: 'Pacing Starting Quarter is required'
                    },
                    pattern: {
                      value: /^(?!Select$)/,
                      message: 'Sub Asset Class is required'
                    }
                  }}
                  defaultValue={defaultSelectValue}
                  tooltipWidth={300}
                  tooltip="Provide a year and quarter that roughly corresponds to the inception of the fund.  This is an input to the pacing model and cash flow forecasts."
                  selects={['Q1', 'Q2', 'Q3', 'Q4']}
                  isWarning={true}
                  onBlur={onBlur}
                />
              </Line>
            )}
          </PacingFieldsWrapper>
        )}
        <Line isMobile={isMobile}>
          <Controller
            name="minimum"
            control={control}
            rules={{
              required: {
                value: true,
                message: 'Minimum is required'
              }
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <MinimumInputWrapper>
                <Label
                  required
                  tooltipWidth={300}
                  tooltip="Provide the investment minimum. Entities with insufficient capacity will be flagged based on this value."
                >
                  Minimum Investor Commitment
                </Label>
                <Input
                  value={value ? formatMaskedDollarInputValue(value) : ''}
                  onChange={(e) => {
                    const formattedValue = formatDollarInputValue(e.target.value);
                    onChange(formattedValue);
                  }}
                  errorText={error?.message}
                  withDollarSign
                  styleType="form"
                  size="md"
                />
              </MinimumInputWrapper>
            )}
          />
          <Select
            title={'Type'}
            nameField={'type'}
            control={control}
            rules={{
              required: {
                value: true,
                message: 'Type is required'
              },
              pattern: {
                value: /^(?!Select$)/,
                message: 'Type is required'
              }
            }}
            defaultValue={defaultSelectValue}
            selects={[defaultSelectValue, 'Illiquid Alternative', 'Restricted Alternative']}
            isWarning={true}
            onBlur={onBlur}
          />
        </Line>
        <Line isMobile={isMobile}>
          <FormInput
            title={'Target IRR'}
            nameField={'targetIRR'}
            control={control}
            rules={{
              pattern: {
                value: /^(\d+(?:[\.\,]\d{1,2})?)$/,
                message: 'Only numbers are allowed'
              }
            }}
            placeHolder={'0%'}
            onBlur={onBlur}
          />
          <FormInput
            title={'Target Yield'}
            nameField={'targetYield'}
            control={control}
            rules={{
              pattern: {
                value: /^(\d+(?:[\.\,]\d{1,2})?)$/,
                message: 'Only numbers are allowed'
              }
            }}
            placeHolder={'0%'}
            onBlur={onBlur}
          />
          <FormInput
            title={'Vintage'}
            nameField={'vintage'}
            control={control}
            rules={{
              pattern: {
                value: /^\d{4}$/,
                message: 'Enter a year using four digits'
              }
            }}
            placeHolder={'Enter a year using four digits'}
            tooltip={'Vintage aids in portfolio diversification.'}
            onBlur={onBlur}
          />
        </Line>
        <Line isMobile={isMobile}>
          <Select
            title={'Investment Owner'}
            nameField={'investmentOwner'}
            tooltip={OWNER_TOOL_TIP}
            tooltipWidth={300}
            control={control}
            rules={{
              required: {
                value: true,
                message: 'Investment Owner is a required field'
              },
              pattern: {
                value: /^(?!Select$)/,
                message: 'Investment Owner is a required field'
              }
            }}
            defaultValue={defaultSelectValue}
            selects={[defaultSelectValue, ...getOwnerValues(investmentOwners)]}
            isWarning={true}
            onBlur={onBlur}
          />
          <Select
            title={'Operations Owner'}
            nameField={'operationsOwner'}
            tooltip={OWNER_TOOL_TIP}
            tooltipWidth={300}
            control={control}
            rules={{
              required: {
                value: true,
                message: 'Operations Owner is a required field'
              },
              pattern: {
                value: /^(?!Select$)/,
                message: 'Operations Owner is a required field'
              }
            }}
            defaultValue={defaultSelectValue}
            selects={[defaultSelectValue, ...getOwnerValues(operationsOwners)]}
            isWarning={true}
            onBlur={onBlur}
          />
          <Select
            title={'Compliance Owner'}
            nameField={'complianceOwner'}
            tooltip={OWNER_TOOL_TIP}
            tooltipWidth={300}
            control={control}
            rules={{
              required: {
                value: true,
                message: 'Compliance Owner is a required field'
              },
              pattern: {
                value: /^(?!Select$)/,
                message: 'Compliance Owner is a required field'
              }
            }}
            defaultValue={defaultSelectValue}
            selects={[defaultSelectValue, ...getOwnerValues(complianceOwners)]}
            isWarning={true}
            onBlur={onBlur}
          />
        </Line>
      </DetailsSectionWrap>
    </Section>
  );
};

const DetailsSectionWrap = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 20px;
`;

const PacingFieldsWrapper = styled.div`
  display: flex;
  width: 100%;
  gap: 30px;
`;

const MinimumInputWrapper = styled.div`
  width: 100%;
`;

const Line = styled.span<{ isMobile: boolean; width50?: boolean }>`
  display: flex;
  justify-content: space-between;
  flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};
  width: ${({ width50, isMobile }) => (width50 && !isMobile ? '50%' : '100%')};
  gap: 30px;
  padding-right: ${({ width50, isMobile }) => (width50 && !isMobile ? '15px' : '0')};
  margin-top: 4px;
`;

const ManagerWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const AddManagerWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  cursor: pointer;
`;

const AddText = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: ${({ theme }) => theme.font.action};
`;
