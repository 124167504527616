import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import { SelectIcon } from '@/assets/icons/selectIcon';
import { useClickOutside } from '../../../hooks/useClickOutside';
import { FadeAnimation } from '../../../styles/animations';
import Button from '../../basicComponents/button';
import CheckBox from '../checkbox';

export type TData = {
  value: boolean;
  name: string;
};

type FilterSelectProps = {
  data: TData[];
  setSelected: (id: number) => void;
  className?: string;
  width?: string; // default width = 204px
  withSearch?: boolean;
  disabled?: boolean;
  required?: boolean;
  label?: string;
  arrow?: React.ReactNode;
  setAllocationStatusFilter?: any;
  setCheckBoxData: Dispatch<SetStateAction<TData[]>>;
};

const SelectWithCheckBox = ({
  data,
  setSelected,
  label,
  required = false,
  disabled = false,
  width = '300px',
  className,
  arrow,
  setAllocationStatusFilter,
  setCheckBoxData
}: FilterSelectProps) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const [isOpenSelect, setIsOpenSelect] = useState(false);
  const [list, setList] = useState<TData[]>(data);
  const [initialData, setInitialData] = useState<string>(JSON.stringify(data));
  useEffect(() => {
    setList(data);
  }, [data]);
  useEffect(() => {
    setAllocationStatusFilter(list);
  }, []);
  const handleSelectCLick = () => {
    if (disabled) return;
    setIsOpenSelect(!isOpenSelect);
  };
  const onCloseFilter = () => {
    setList(JSON.parse(initialData));
    setCheckBoxData(JSON.parse(initialData));
    setIsOpenSelect(false);
  };

  const applyFilters = () => {
    setAllocationStatusFilter(list);
    setInitialData(JSON.stringify(list));
    setIsOpenSelect(false);
  };

  useClickOutside(ref, onCloseFilter);
  const checkedValue = data.map((item) => item.value);
  const isChecked = checkedValue.includes(true);

  return (
    <Container width={width}>
      <FilterWrap className={className} disabled={disabled} ref={ref}>
        <FilterTitleWrapper onClick={handleSelectCLick}>
          <FilterTitle id={'titleSelect'}>{label}</FilterTitle>
          <SelectIconWrap isOpen={isOpenSelect}>{arrow ?? <SelectIcon />}</SelectIconWrap>
        </FilterTitleWrapper>
        <FilterItems width={width} isOpen={isOpenSelect} id={'dropDownSelect'}>
          {list.map((el, index) => (
            <PrimaryFilterItem id={'input'} key={el.name + index} onClick={() => setSelected(index)}>
              <CheckBox id={'input'} isActive={el.value} /> {el.name}
            </PrimaryFilterItem>
          ))}
          <ButtonsWrap>
            <CustomButton disabled={false} onClick={onCloseFilter}>
              Cancel
            </CustomButton>
            <CustomButton disabled={!isChecked} onClick={applyFilters}>
              Apply
            </CustomButton>
          </ButtonsWrap>
        </FilterItems>
      </FilterWrap>
    </Container>
  );
};

const Container = styled.div<{ width: string }>`
  display: flex;
  flex-direction: column;
  width: ${({ width }) => width};
`;
export const SearchWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 11px 10px 0;
  gap: 11px;
`;

const FilterTitleWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6.5px 8px;
`;

export const ButtonsWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 5px;
`;

const CustomButton = styled(Button)<{ disabled: boolean }>`
  font-family: Blinker, serif;
  font-weight: 600;
  font-size: 16px;
  line-height: 22.4px;
  color: ${({ disabled }) => (disabled ? '#ABABAB !important' : '#4587ec !important')};
  border-radius: 4px;
  width: 100%;
  outline: ${({ disabled }) => (disabled ? '#DEDEDE !important' : ' 1px solid #4587ec !important')};
  gap: 10px;
  background-color: ${({ disabled }) => (disabled ? '#DEDEDE !important' : '#ffffff !important')};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  &:hover {
    color: ${({ disabled }) => (disabled ? '#ABABAB !important' : '#ffffff !important')};
    background: ${({ disabled }) => (disabled ? '#DEDEDE !important' : '#1c488a !important')};
    outline: ${({ disabled }) => (disabled ? '#DEDEDE !important' : '1px solid #1c488a !important')};
  }
`;
export const SearchInput = styled.input`
  width: 100%;
  border: 1px solid #c7cbd2;
  border-radius: 3px;
  padding: 6.5px 8px;

  &:focus {
    outline: none;
  }
`;

export const Divider = styled.div`
  display: flex;
  background: #c7cbd2;
  height: 1px;
  width: 110%;
  margin-left: -20px;
  margin-right: -20px;
`;

const SelectIconWrap = styled.div<{ isOpen: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  transform-origin: center;
  transform: ${({ isOpen }) => (isOpen ? 'rotate(180deg)' : 'rotate(0deg)')};
  transition: all 0.2s linear;
`;

export const FilterItems = styled(FadeAnimation)<{ isOpen: boolean; width: string }>`
  max-height: 260px;
  display: flex;
  flex-direction: column;
  position: absolute;
  z-index: 10;
  top: 42px;
  right: 0;
  width: 100%;
  border: ${({ isOpen }) => `${isOpen ? 1 : 0}px solid #c7cbd2`};
  background: #fff;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.15);
  border-radius: 3px;
  overflow-y: auto;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    width: 10px;
    left: -100px;
  }

  &::-webkit-scrollbar-track {
    background: #f0f1f3;
    border: 1px solid #c7cbd2;
    padding: 2px;
    border-radius: 10px;
    margin: 6px 0;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #868e99;
    background-clip: padding-box;
    border-radius: 10px;
    border: 2px solid rgba(0, 0, 0, 0);
  }
`;

const FilterWrap = styled.div<{ disabled: boolean }>`
  display: flex;
  position: relative;
  opacity: ${({ disabled }) => (disabled ? 0.4 : 1)};
  justify-content: space-between;
  align-items: center;
  border: 1px solid #c7cbd2;
  border-radius: 3px;
  // padding: 6.5px 8px;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
`;

const FilterTitle = styled.div`
  color: #000000;
  font-family: Blinker, serif;
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  text-transform: capitalize;

  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  white-space: nowrap;
`;

export const PrimaryFilterItem = styled.div`
  display: flex;
  align-items: center;
  gap: 9px;
  color: #000000;
  font-family: Blinker, serif;
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;

  width: 100%;
  cursor: pointer;
  padding: 6.5px 8px;
  background: #ffffff;
  text-transform: capitalize;

  &:hover {
    background: #f4f5f6;
  }
`;

export default SelectWithCheckBox;
