import { EditIcon } from '@/assets/icons/edit';
import { getUTCDate } from '@/components/allocations-page/utils';
import { TableColumns } from '@/components/table/types';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';
import CommitmentPlanTable from '../../edit-commitment-plan-page/fat-table/table';
import { CreateAllocationsByDateDto, ITransformedAssetClass } from '../../edit-commitment-plan-page/types';
import { transformData } from '../../edit-commitment-plan-page/utils';
import { Section } from '../../edit-entity/section';

interface CommitmentPlanSectionProps {
  planAllocations: CreateAllocationsByDateDto[];
  targetSubAssetClasses: boolean;
  commitmentPlanId: string | null;
}

export const CommitmentPlanSection = ({ planAllocations, targetSubAssetClasses, commitmentPlanId }: CommitmentPlanSectionProps) => {
  const navigate = useNavigate();
  const params = useParams();
  const theme = useTheme();

  const [tableHeaderColumns, setTableHeaderColumns] = useState<TableColumns[]>([]);
  const [tableData, setTableData] = useState<ITransformedAssetClass[]>([]);
  const columnWidth = planAllocations ? 85 / planAllocations.length : 0;

  useEffect(() => {
    if (!planAllocations) return;

    const columnNames = planAllocations.map((data: CreateAllocationsByDateDto) => {
      return {
        title: getUTCDate(new Date(data.date)).toUpperCase(),
        key: null as null,
        width: columnWidth,
        keySort: new Date(data.date).getFullYear().toString()
      };
    });

    const transformedData = transformData(planAllocations);

    const filteredTableData = transformedData.filter((data) => data.allocations.some((allocation) => allocation.allocation !== '-'));
    setTableData(filteredTableData);

    setTableHeaderColumns([{ title: 'Asset Class', key: null, width: 15, keySort: 'deal' }, ...columnNames]);
  }, [planAllocations]);

  return (
    <Section id="commitmentPlan" title="Commitment Plan">
      {tableData.length ? (
        <CommitmentPlanTable
          tableData={tableData}
          tableHeaderColumns={tableHeaderColumns}
          loading={false}
          columnWidth={columnWidth}
          mode="view"
          targetSubAssetClasses={targetSubAssetClasses}
        />
      ) : (
        <CommitmentPlanNoResults>
          No commitments are scheduled. This forecast is limited to existing commitments only. Edit the commitment plan to add future investments.
          {!commitmentPlanId || (
            <EditCommitmentPlanButtonWrapper
              onClick={() => {
                navigate(
                  `/investors/${params.investorId}/${params.entityId}/editCommitmentPlan?commitmentPlanId=${commitmentPlanId}&backToTitle=All Commitment Plans`
                );
              }}
            >
              <EditIcon fill={theme.font.action} />
              <AddText>Edit Commitment Plan</AddText>
            </EditCommitmentPlanButtonWrapper>
          )}
        </CommitmentPlanNoResults>
      )}
    </Section>
  );
};

const CommitmentPlanNoResults = styled.div`
  font-size: 18px;
  color: ${({ theme }) => theme.font.base};
`;

const EditCommitmentPlanButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  cursor: pointer;
`;

const AddText = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: ${({ theme }) => theme.font.action};
`;
