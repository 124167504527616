// import { Box } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';

import { RejectedBannerIcon } from '@/assets/icons/info-icons/rejectedBanner';
import { Banner } from '@/components/basicComponents/banner';
import EndImpersonation from '@/components/fat-settings-page/users/modals/endImpersonation';
import { GET_SETTINGS } from '@/components/settings-page/queries';
import tabs, { Tabs } from '@/constants/tabs/tabs';
import { useAuth } from '@/hooks/use-auth';
import { useResponsive } from '@/hooks/use-responsive';
import { DashboardNavbar } from '@/layouts/fat-dashboard/dashboard-navbar';
import { DashboardNavbarMobile } from '@/layouts/fat-dashboard/navbar-mobile';
import { useQuery } from '@apollo/client';

export function DashboardLayout({ children }: React.PropsWithChildren): JSX.Element {
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const { isMobile, isDesktop, isTablet, isLargeDesktop } = useResponsive();
  const { setUserTasks, signInAs, user } = useAuth();

  const [modalWindow, setModalWindow] = useState({ isOpen: false, type: 'end-impersonation' });
  const [isImpersonation, setIsImpersonation] = useState(false);
  const [endImpersonationLoading, setEndImpersonationLoading] = useState(false);

  const menuConfig: { [key: string]: boolean } = JSON.parse(process.env.REACT_APP_MENU_CONFIG);

  // const { data: taskData, refetch } = useQuery(TASKS_QUERY, {
  //   variables: {},
  //   notifyOnNetworkStatusChange: true,
  //   fetchPolicy: 'no-cache',
  //   onCompleted: (data) => {
  //     setUserTasks(data?.AllocationTasks?.assignedToMeTaskCount || 0);
  //   }
  // });

  const [moduleConfig, setModuleConfig] = useState(undefined);

  const { refetch: appSettingRefetch } = useQuery(GET_SETTINGS, {
    notifyOnNetworkStatusChange: true,
    variables: {
      type: 'appSetting'
    },
    onCompleted: (data) => {
      setModuleConfig(JSON.parse(data.Settings[0].value));
    },
    onError: (error) => {
      setModuleConfig({});
      console.error(error);
    }
  });

  const getAvailableTabs = (tabs: Tabs[], params: { [key: string]: boolean }): Tabs[] => {
    return tabs.filter((tab) => params[tab.path]);
  };

  const filteredTabs = useMemo(() => {
    return getAvailableTabs(tabs, menuConfig).filter((tab) => {
      if (
        user &&
        user.tenant?.type === 'advisoryFirm' &&
        ['dealCatalog', 'investors', 'pendingInvestments', 'activeInvestments', 'settings'].includes(tab.path)
      ) {
        return tab;
      }

      if (
        user &&
        user.tenant?.type === 'fundManager' &&
        (user.roles.includes('Super Admin') || user.roles.includes('Admin')) &&
        [
          'dealPipeline',
          'investors',
          'dueDiligence',
          'dealRegistry',
          'managerUpdates',
          'pendingInvestments',
          'activeInvestments',
          'settings'
        ].includes(tab.path)
      ) {
        return tab;
      }

      if (
        user &&
        user.tenant?.type === 'fundManager' &&
        ['dealPipeline', 'investors', 'dueDiligence', 'dealRegistry', 'pendingInvestments', 'activeInvestments'].includes(tab.path)
      ) {
        return tab;
      }

      if (
        user &&
        user.tenant?.type === 'advisoryFirm' &&
        user.roles.includes('Admin') &&
        moduleConfig?.['Reporting'] &&
        'reportingData' === tab.path
      ) {
        return tab;
      }
    });
  }, [user, moduleConfig]);

  useEffect(() => {
    if (!filteredTabs.length || moduleConfig === undefined) return;

    const currentPath = window.location.pathname;
    const hasMatchingPath = filteredTabs.find((item) => currentPath.includes(item.path));

    if (!hasMatchingPath && !currentPath.includes('profile')) {
      navigate('/404', { replace: true });
    }
  }, [location.pathname, moduleConfig]);

  useEffect(() => {
    appSettingRefetch();
    if (user?.impersonated) {
      setIsImpersonation(true);
    } else {
      setIsImpersonation(false);
    }
  }, [user]);

  const openModalWindow = (type: string) => {
    setEndImpersonationLoading(false);
    setModalWindow({ type, isOpen: true });
  };

  const closeModalWindow = () => {
    setModalWindow({ ...modalWindow, isOpen: false });
    setEndImpersonationLoading(false);
  };

  const endImpersonation = () => {
    if (!user?.impersonated?.originalUser.email) {
      closeModalWindow();
      return;
    }

    setEndImpersonationLoading(true);
    signInAs(user.impersonated.originalUser.email)
      .then(() => {
        navigate('/settings/?action=users');
        closeModalWindow();
      })
      .catch(console.error);
  };

  return (
    <DashboardLayoutWrap>
      {modalWindow.isOpen && modalWindow.type === 'end-impersonation' && (
        <EndImpersonation
          isOpen={modalWindow.isOpen}
          onCancel={closeModalWindow}
          onConfirm={endImpersonation}
          user={user}
          signingIn={endImpersonationLoading}
        />
      )}
      {isDesktop || isLargeDesktop ? <DashboardNavbar tabs={filteredTabs} /> : <DashboardNavbarMobile tabs={filteredTabs} />}
      <DashboardLayoutRoot isMobile={isMobile} isTablet={isTablet}>
        {isImpersonation && (
          <Banner
            icon={<RejectedBannerIcon />}
            title={`You are impersonating ${user?.email} (${user?.tenant?.name})`}
            description={`Your access level is determined by ${user?.email}’s roles and permissions in this tenant.`}
            bgColor={theme.context.warning}
            onClose={() => openModalWindow('end-impersonation')}
          />
        )}
        <Outlet />
      </DashboardLayoutRoot>
    </DashboardLayoutWrap>
  );
}

const DashboardLayoutRoot = styled.div<{ isMobile: boolean; isTablet: boolean }>`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  padding-left: 0;
  transition: 0.3s;
  margin-left: ${({ isMobile, isTablet }) => (isMobile || isTablet ? 0 : '80px')};
  margin-top: ${({ isMobile, isTablet }) => (isMobile || isTablet ? '50px' : 0)};
  min-height: 100vh;
  flex: 1 1 auto;
`;

const DashboardLayoutWrap = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  overflow-x: clip;
`;
