import { UploadFileIcon } from '@/assets/static/icons/upload-file';
import Button from '@/components/fat-basicComponents/button';
import FileUpload from '@/components/fat-basicComponents/fileUpload';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';
import { SectionZeroStateText, SectionZeroStateWrapper } from '..';
import { Section } from '../../edit-entity/section';

interface AmlDocumentsSectionProps {
  saveScrollPostion: () => void;
}

export const AmlDocumentsSection = ({ saveScrollPostion }: AmlDocumentsSectionProps) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const [showSectionZeroState, setShowSectionZeroState] = useState(false);

  const openAddDocumentPage = () => {
    saveScrollPostion();
    navigate('addDocument?fromSection=AML Documents&backToTitle=Investor Documentation');
  };

  if (showSectionZeroState) {
    return (
      <Section title="AML Documents">
        <SectionZeroStateWrapper>
          <SectionZeroStateText>No information has been added yet.</SectionZeroStateText>
          <Button
            onClick={openAddDocumentPage}
            styleType="outline"
            icon={<UploadFileIcon width={23} height={23} fill={theme.font.action} />}
            size="md"
            width="max-content"
          >
            Upload AML Documents
          </Button>
        </SectionZeroStateWrapper>
      </Section>
    );
  }

  return (
    <Section title="AML Documents">
      <FilesWrapper>
        <FileUpload fileName="MOCKED DATA.pdf" openFile={() => {}} deleteFile={() => {}} />
        <FileUpload fileName="MOCKED DATA.pdf" openFile={() => {}} deleteFile={() => {}} />
        <FileUpload fileName="MOCKED DATA.pdf" openFile={() => {}} deleteFile={() => {}} />
        <Button
          onClick={openAddDocumentPage}
          styleType="outline"
          icon={<UploadFileIcon width={23} height={23} fill={theme.font.action} />}
          size="md"
          width="max-content"
        >
          Upload AML Documents
        </Button>
      </FilesWrapper>
    </Section>
  );
};

const FilesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
