import { Loader } from '@/components/loaders/loader';
import TableHeader from '@/components/table/fat-tableHeader';
import { ISort } from '@/components/table/types';
import { useResponsive } from '@/hooks/use-responsive';
import { NetworkStatus } from '@apollo/client';
import { useState } from 'react';
import styled from 'styled-components';
import { TableBodyWrapper, TableWrapper } from '../../table/styles';
import { seekingInterestTableColumns } from '../constants';
import AddToWatchlist from '../fat-modals/addToWatchlist';
import EditToWatchlist from '../fat-modals/editWatchlist';
import { IInvestorInterestList, TypeWindow } from '../types';
import MobileTable from './mobileTable';
import TableBody from './tableBody';

interface SeekingInterestTableProps {
  openPage: (id: string, type: TypeWindow) => void;
  refetch: () => void;
  investorInterestsNetworkStatus: NetworkStatus;
  investorInterestsData: IInvestorInterestList[];
  sort: ISort;
  setSort: (value: ISort) => void;
}

const SeekingInterestTable = ({
  openPage,
  refetch,
  investorInterestsNetworkStatus,
  investorInterestsData,
  sort,
  setSort
}: SeekingInterestTableProps) => {
  const { isMobile, isTablet } = useResponsive();

  const [modalWindow, setModalWindow] = useState({
    isOpen: false,
    type: 'add-to-watchlist'
  });
  const [currentRow, setCurrentRow] = useState<IInvestorInterestList | null>(null);

  const openModalWindow = (type: string, row: IInvestorInterestList) => {
    setModalWindow({ type, isOpen: true });
    setCurrentRow(row);
  };

  const closeModalWindow = () => {
    setModalWindow({ ...modalWindow, isOpen: false });
    setCurrentRow(null);
  };

  return (
    <>
      {modalWindow.isOpen && modalWindow.type === 'add-to-watchlist' && (
        <AddToWatchlist isOpen={modalWindow.isOpen} onClose={closeModalWindow} currentRow={currentRow} openPage={openPage} refetch={refetch} />
      )}
      {modalWindow.isOpen && modalWindow.type === 'edit-watchlist' && (
        <EditToWatchlist isOpen={modalWindow.isOpen} onClose={closeModalWindow} currentRow={currentRow} openPage={openPage} refetch={refetch} />
      )}
      <TableWrapper padding="0">
        {isMobile ? (
          <>
            {investorInterestsNetworkStatus === NetworkStatus.loading ? (
              <Loader />
            ) : (
              <MobileTable investorInterestsData={investorInterestsData} openPage={openPage} />
            )}
          </>
        ) : (
          <>
            <CustomTableHeader
              isTablet={isTablet}
              refetch={() => null as any}
              columns={seekingInterestTableColumns}
              savedSort={sort}
              savedSetSort={setSort}
            />
            <TableBodyWrapper>
              {investorInterestsNetworkStatus === NetworkStatus.loading ? (
                <Loader />
              ) : (
                <TableBody investorInterestsData={investorInterestsData} openPage={openPage} openModalWindow={openModalWindow} />
              )}
            </TableBodyWrapper>
          </>
        )}
      </TableWrapper>
    </>
  );
};

export default SeekingInterestTable;

const CustomTableHeader = styled(TableHeader)<{ isTablet: boolean }>`
  position: sticky;
  /* top: ${({ isTablet }) => (isTablet ? '189px' : '139px')}; */
  top: ${({ isTablet }) => (isTablet ? '130px' : '80px')};
  z-index: 8;
  padding-top: 0;
`;
