import * as React from 'react';

export const CalendarIcon = ({ ...props }: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.828 4.38801H17.7134C17.9473 4.38731 18.1791 4.4327 18.3954 4.52157C18.6118 4.61044 18.8086 4.74106 18.9745 4.90598C19.1404 5.07089 19.2722 5.26688 19.3624 5.48273C19.4525 5.69859 19.4993 5.93009 19.5 6.16402V18.612C19.4986 19.0835 19.3103 19.5352 18.9764 19.8681C18.6425 20.2011 18.1902 20.388 17.7187 20.388H5.27602C4.80499 20.388 4.35325 20.2009 4.02019 19.8678C3.68713 19.5348 3.50001 19.083 3.50001 18.612V6.16402C3.49931 5.93104 3.54457 5.7002 3.63324 5.48475C3.72191 5.26931 3.85225 5.0735 4.01674 4.90852C4.18123 4.74353 4.37666 4.61261 4.59184 4.52329C4.80701 4.43398 5.0377 4.38801 5.27068 4.38801H6.16134V2.612H7.93735V4.38801H15.0467V2.612H16.828V4.38801ZM15.8111 10.315L9.61917 16.1197L7.19304 13.8506L5.87019 15.0907L9.61917 18.6105L17.1283 11.5709L15.8111 10.315ZM17.7208 6.1672H5.2776V8.53733H17.7208V6.1672Z"
        fill={props?.fill || '#EF9208'}
      />
    </svg>
  );
};
