import { TableColumns } from '../table/types';

export const opportunitiesTableColumns: TableColumns[] = [
  { title: 'Strategy', key: 'DEAL', width: 20, keySort: 'strategy' },
  { title: 'Asset Class', key: 'assetClass', width: 15, keySort: 'asset class' },
  { title: 'Sectors', key: null, width: 15, keySort: 'sub asset class' },
  { title: 'Investment Type', key: 'INVESTMENT_TYPE', width: 12, keySort: 'investment type' },
  { title: 'Investor Docs Due', key: 'INVESTOR_DOCS_DUE', width: 12, keySort: 'investor docs due' },
  { title: 'Aggregate Commitment', key: 'AGGREGATE_COMMITMENT', width: 14, keySort: 'aggregate commitment' },
  { title: ' ', key: null, width: 8, keySort: 'button' },
  { title: ' ', key: null, width: 4, keySort: 'tooltip' }
];

export const seekingInterestTableColumns: TableColumns[] = [
  { title: 'Strategy', key: 'INVESTMENT_NAME', width: 20, keySort: 'strategy' },
  { title: 'Asset Class', key: 'ASSET_CLASS', width: 15, keySort: 'asset class' },
  { title: 'Sectors', key: 'STRATEGY', width: 12, keySort: 'sub asset class' },
  { title: 'Investment Type', key: 'INVESTMENT_TYPE', width: 12, keySort: 'investment type' },
  { title: 'Investor Docs Due', key: 'INVESTOR_DOCS_DUE', width: 12, keySort: 'investor docs due' },
  { title: 'Est Commitment', key: 'ESTIMATED_COMMITMENT', width: 12, keySort: 'est commitment' },
  { title: ' ', key: null, width: 13, keySort: 'button' },
  { title: ' ', key: null, width: 4, keySort: 'tooltip' }
];

export const otherRecommendedTableColumns = [
  { title: 'Strategy', key: 'INVESTMENT_NAME', width: 20, keySort: 'strategy' },
  { title: 'Asset Class', key: 'ASSET_CLASS', width: 19, keySort: 'asset class' },
  { title: 'Sectors', key: 'STRATEGY', width: 19, keySort: 'sub asset class' },
  { title: 'Investment Type', key: 'INVESTMENT_TYPE', width: 19, keySort: 'investment type' },
  { title: 'Investor Docs Due', key: 'INVESTOR_DOCS_DUE', width: 19, keySort: 'investor docs due' },
  { title: ' ', key: ' ', width: 4 }
];

export const initialStatusFilter = [
  {
    type: 'Accepting Commitments',
    name: 'Current',
    value: 'Open',
    enabled: true,
    count: 0
  },
  {
    type: 'Accepting Commitments',
    name: 'Closed',
    value: 'Active',
    enabled: false,
    count: 0
  },
  {
    type: 'Seeking Interest',
    name: 'Current',
    value: 'Approved',
    enabled: true,
    count: 0
  },
  {
    type: 'Seeking Interest',
    name: 'Passed On',
    value: 'Terminated',
    enabled: false,
    count: 0
  },
  {
    type: 'Other Recommended',
    name: 'Current',
    value: 'Other Recommended',
    enabled: true,
    count: 0
  },
  {
    type: 'Other Recommended',
    name: 'Passed On',
    value: 'Terminated',
    enabled: false,
    count: 0
  },
  {
    type: 'In Research',
    name: 'Current',
    value: 'In Research',
    enabled: true,
    count: 0
  },
  {
    type: 'In Research',
    name: 'Passed On',
    value: 'Terminated',
    enabled: false,
    count: 0
  }
];

export const opportunitiesTableSort = [
  {
    title: 'Strategy • A -> Z',
    key: 'DEAL',
    asc: true
  },
  {
    title: 'Strategy • Z -> A',
    key: 'DEAL',
    asc: false
  },
  {
    title: 'Asset Class • A -> Z',
    key: 'assetClass',
    asc: true
  },
  {
    title: 'Asset Class • Z -> A',
    key: 'assetClass',
    asc: false
  },
  {
    title: 'Investment Type • A -> Z',
    key: 'INVESTMENT_TYPE',
    asc: true
  },
  {
    title: 'Investment Type • Z -> A',
    key: 'INVESTMENT_TYPE',
    asc: false
  },
  {
    title: 'Investor Docs Due  • Sooner -> Later',
    key: 'CLOSE_DATE',
    asc: true
  },
  {
    title: 'Investor Docs Due  • Later -> Sooner',
    key: 'CLOSE_DATE',
    asc: false
  },
  {
    title: 'Aggregate Commitment • Max -> Min',
    key: 'AGGREGATE_COMMITMENT',
    asc: false
  },
  {
    title: 'Aggregate Commitment • Min -> Max',
    key: 'AGGREGATE_COMMITMENT',
    asc: true
  }
];

export const seekingInterestTableSort = [
  {
    title: 'Strategy • A -> Z',
    key: 'INVESTMENT_NAME',
    asc: true
  },
  {
    title: 'Strategy • Z -> A',
    key: 'INVESTMENT_NAME',
    asc: false
  },
  {
    title: 'Asset Class • A -> Z',
    key: 'ASSET_CLASS',
    asc: false
  },
  {
    title: 'Asset Class • Z -> A',
    key: 'ASSET_CLASS',
    asc: true
  },
  {
    title: 'Investment Type • A -> Z',
    key: 'INVESTMENT_TYPE',
    asc: false
  },
  {
    title: 'Investment Type • Z -> A',
    key: 'INVESTMENT_TYPE',
    asc: true
  },
  {
    title: 'Investor Docs Due  • Sooner -> Later',
    key: 'EXPECTED_CLOSE',
    asc: true
  },
  {
    title: 'Investor Docs Due  • Later -> Sooner',
    key: 'EXPECTED_CLOSE',
    asc: false
  },
  {
    title: 'Est Commitment • Max -> Min',
    key: 'ESTIMATED_COMMITMENT',
    asc: false
  },
  {
    title: 'Est Commitment • Min -> Max',
    key: 'ESTIMATED_COMMITMENT',
    asc: true
  }
];
