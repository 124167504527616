import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { OpenInvestmentBanner } from '@/components/dealRegistry-page/openInvestmentBanner';
import { FormFooter } from '@/components/fat-basicComponents/formFooter';
import { defaultListData } from '@/components/fat-dealPipeline-page/fat-modify/constatnts';
import { IListData } from '@/components/fat-dealPipeline-page/fat-modify/types';
import Header from '@/components/fat-header';
import { GoBackButton } from '@/components/fat-header/goBackButton';
import { PageTitle } from '@/components/fat-header/pageTitle';
import { LoaderOnWholeScreen } from '@/components/loaders/loader-on-whole-screen';
import { useGoBack } from '@/hooks/useGoBack';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import { useResponsive } from '../../../hooks/use-responsive';
import { ContainerPage, MainWrap } from '../../../styles/common';
import { IDateFields } from '../../../types/opportunity/modify-opportunity';
import { UPDATE_INVESTMENT, getCurrentManagers, getCurrentSubAssetClasses, getCurrentTags } from '../../dueDiligence-page/queries';
import { createInvestmentMutation, defaultSelectValue } from '../../opportunities-entity/modify/constants';
import CreateManager from '../modals/createManager';
import CreateStrategy from '../modals/createStrategy';
import CreateTag from '../modals/createTag';
import Dialog, { DialogStatus } from '../modals/dialog';
import { ALLOCATION_INVESTMENT_QUERY, OWNERS_QUERY } from '../queries';
import { IForm, IOwner, Investment } from '../types';
import { INVESTMENT_QUERY } from './queries';
import { AllocationSettingsSection } from './sections/allocationSettings';
import { DetailsSection } from './sections/details';
import { buildData, getAllPossibleOwners, getDefaultDataFields, getDefaultFormValues, isSelectorsValid } from './utils';

const ModifyOpportunity = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const goBack = useGoBack();

  const [searchParams, setSearchParams] = useSearchParams();

  const investmentId = searchParams.get('investment') || '';
  const openInvestment = searchParams.get('openInvestment') || '';
  const backToTitle = searchParams.get('backToTitle') || '';

  const {
    data: investmentData,
    loading: loadingInvestment,
    error: errorInvestment
  } = useQuery(INVESTMENT_QUERY, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    variables: { id: investmentId },
    skip: !investmentId
  });

  const [investment, setInvestment] = useState();
  const [title, setTitle] = useState<string>();
  const [defaultFormValues, setDefaultFormValues] = useState<Investment>();

  const {
    handleSubmit,
    getValues,
    setValue,
    control,
    setError,
    formState: { errors, isValid },
    reset
  } = useForm<IForm>({ defaultValues: defaultFormValues as any });

  const [createInvestment, { loading: createAllocationsLoading }] = useMutation(createInvestmentMutation);
  const [updateInvestment, { loading: updateInvestmentsLoading }] = useMutation(UPDATE_INVESTMENT);
  const { data: ownersData, loading: ownersLoading } = useQuery(OWNERS_QUERY, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'no-cache',
    variables: {
      id: 'dafd1c4b-f85d-4e56-ad3c-1e60c0199349' // optional
    }
  });
  const [getActiveAllocationInfo, { loading: allocationInvestmentLoading }] = useLazyQuery(ALLOCATION_INVESTMENT_QUERY);

  const { isMobile, isTablet } = useResponsive();
  const [isModify, setIsModify] = useState<boolean>(false);
  const [isDialogOpen, setDialogOpen] = useState<boolean>(false);
  const [dialogStatus, setDialogStatus] = useState<DialogStatus>();
  const [tags, setTags] = useState<IListData>(defaultListData);
  const [strategies, setStrategies] = useState<IListData>(defaultListData);
  const [allPossibleOwners, setAllPossibleOwners] = useState<Array<IOwner>>([]);
  const [dataFields, setDataFields] = useState<IDateFields>({} as IDateFields);
  const [hasActiveAllocation, setActiveAllocation] = useState<boolean>(!!investment);
  const [modalWindow, setModalWindow] = useState({ isOpen: false, type: 'create-tag' });
  const [managerList, setManagerList] = useState<string[]>([]);
  const [descriptionValue, setDescriptionValue] = useState('');
  const [chosenManager, setChosenManager] = useState(defaultSelectValue);

  useQuery(getCurrentManagers, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      const sortedManagerList = [...data.getCurrentManagers.values].filter((manager: string) => manager !== '');
      setManagerList(sortedManagerList);
    }
  });

  const { data: tagsListData } = useQuery(getCurrentTags, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      const sortedTagsList = [...data.getCurrentTags.values].filter((tag: string) => tag !== '');
      setTags({ ...tags, list: sortedTagsList });
    }
  });

  const { data: strategiesListData } = useQuery(getCurrentSubAssetClasses, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      const sortedStrategiesList = [...data.getCurrentSubAssetClasses.values].filter((strategy: string) => strategy !== '');
      setStrategies({ ...strategies, list: sortedStrategiesList });
    }
  });

  useEffect(() => {
    const investment = investmentData?.Investment;
    if (investmentData) {
      setInvestment(investment);
      setIsModify(!!investment);
      setStrategies(investment?.sectors);
      setTags({ ...tags, chosen: investment.tags ?? [] });
      setStrategies({
        ...strategies,
        chosen: investment.subAssetClasses ?? []
      });
      setManagerList(investment.managerId ? [...managerList, investment.managerId] : managerList);
      setDescriptionValue(investment.description ?? '');
      setChosenManager(Boolean(investment.managerId) ? investment.managerId : defaultSelectValue);
    }
    reset(getDefaultFormValues(investment as Investment) as any);
    setDefaultFormValues(getDefaultFormValues(investment) as any);
    setDataFields(getDefaultDataFields(investment));
    setTitle((openInvestment ? openInvestment : investment ? 'Edit Investment' : 'Create New Investment') as string);
  }, [investmentData]);

  useEffect(() => {
    const sortedManager = [...managerList].filter((manager: string) => manager !== '').sort((a: string, b: string) => a.localeCompare(b));
    setManagerList(sortedManager);
  }, [chosenManager]);

  useEffect(() => {
    setTags((prevTags) => {
      return {
        ...prevTags,
        list: prevTags.list.filter((tag) => !tags.chosen.includes(tag))
      };
    });
  }, [investmentData, tagsListData, tags.chosen]);

  useEffect(() => {
    setStrategies((prevStrategies) => {
      return {
        ...prevStrategies,
        list: prevStrategies.list.filter((strategy) => !strategies.chosen.includes(strategy))
      };
    });
  }, [investmentData, strategiesListData, strategies.chosen]);

  useEffect(() => {
    reset();
  }, [defaultFormValues]);

  useEffect(() => {
    if (investment) {
      getActiveAllocationInfo({
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'no-cache',
        variables: {
          id: (investment as Investment).id
        }
      })
        .then((response) => {
          setActiveAllocation(response.data.Investment?.hasActiveAllocation);
        })
        .catch((error) => {});
    }
  }, []);

  useEffect(() => {
    if (ownersData) {
      const { investmentOwners, operationsOwners, complianceOwners } = ownersData.AllocationOwners;
      setAllPossibleOwners(getAllPossibleOwners({ investmentOwners, operationsOwners, complianceOwners }));
    }
    if (createAllocationsLoading || updateInvestmentsLoading) {
      setDialogStatus(DialogStatus.LOADING);
      setDialogOpen(true);
    }
  }, [ownersData, createAllocationsLoading, updateInvestmentsLoading]);

  const openModalWindow = (type: string) => {
    setModalWindow({ ...modalWindow, isOpen: true, type });
  };

  if (!ownersData || ownersLoading || allocationInvestmentLoading) {
    return <LoaderOnWholeScreen />;
  }
  const { investmentOwners, operationsOwners, complianceOwners } = ownersData.AllocationOwners;

  const onCloseDialog = () => setDialogOpen(false);
  const onDialogSuccess = () => {
    reset();
    setDialogOpen(false);
    handleRedirect();
  };
  const onUpdateInvestment = (data: IForm) => {
    if (dataFields) {
      const investmentStrategies = [...strategies.chosen].filter((el) => el.length > 0 && el !== 'None').map((el) => el.trim());
      const investmentTags = [...tags.chosen].filter((el) => el.length > 0 && el !== 'None').map((el) => el.trim());

      updateInvestment({
        variables: {
          data: buildData({
            id: investmentData.Investment.id,
            data,
            dataFields,
            investmentStrategies,
            investmentTags,
            allPossibleOwners,
            descriptionValue,
            securityStatus: investmentData.Investment.securityStatus,
            ...(openInvestment && { openIvestment: true })
          })
        }
      })
        .then((response) => {
          setDialogStatus(DialogStatus.SUCCESS);
          setDialogOpen(true);
        })
        .catch((error) => {
          setDialogStatus(DialogStatus.FAILED);
          setDialogOpen(true);
        });
    }
  };
  const onCreateInvestment = (data: IForm) => {
    if (dataFields) {
      const investmentStrategies = [...strategies.chosen].filter((el) => el.length > 0 && el !== 'None').map((el) => el.trim());
      const investmentTags = [...tags.chosen].filter((el) => el.length > 0 && el !== 'None').map((el) => el.trim());

      createInvestment({
        variables: {
          data: buildData({
            data,
            dataFields,
            investmentStrategies,
            investmentTags,
            allPossibleOwners,
            descriptionValue,
            openIvestment: true
          })
        }
      })
        .then((response) => {
          setDialogStatus(DialogStatus.SUCCESS);
          setDialogOpen(true);
        })
        .catch((error) => {
          setDialogStatus(DialogStatus.FAILED);
          setDialogOpen(true);
        });
    }
  };
  const onSubmit = (data: IForm) => {
    if (!isSelectorsValid(data)) return;

    if (isModify && hasActiveAllocation) {
      setDialogStatus(DialogStatus.WARNING);
      setDialogOpen(true);
    } else if (isModify && !hasActiveAllocation) {
      onUpdateInvestment(data);
    } else {
      onCreateInvestment(data);
    }
  };

  const handleClosePage = () => {
    reset();
    handleRedirect();
    return;
  };

  const handleRedirect = () => {
    goBack({ fallBack: '/allocations' });
  };

  return (
    <MainWrap>
      <Dialog
        isOpen={isDialogOpen}
        onClose={onCloseDialog}
        onSuccess={onDialogSuccess}
        onCreateInvestment={() => onCreateInvestment(getValues())}
        onUpdateInvestment={() => onUpdateInvestment(getValues())}
        status={dialogStatus}
        isModify={isModify}
      />
      {modalWindow.isOpen && modalWindow.type === 'create-strategy' && (
        <CreateStrategy
          onClose={() => setModalWindow({ type: 'create-strategy', isOpen: false })}
          isOpen={modalWindow.isOpen}
          setStrategies={setStrategies}
        />
      )}
      {modalWindow.isOpen && modalWindow.type === 'create-tag' && (
        <CreateTag onClose={() => setModalWindow({ type: 'create-tag', isOpen: false })} isOpen={modalWindow.isOpen} setTags={setTags} />
      )}
      {modalWindow.isOpen && modalWindow.type === 'create-manager' && (
        <CreateManager
          onClose={() => setModalWindow({ type: 'create-manager', isOpen: false })}
          isOpen={modalWindow.isOpen}
          managerList={managerList}
          setManagerList={setManagerList}
          getNewManager={(newManager) => setValue('manager', newManager)}
        />
      )}
      <Header modalControl={<GoBackButton handleClose={handleClosePage} backToTitle={backToTitle} />} />
      <PageTitle title={title} />

      {openInvestment && <OpenInvestmentBanner />}
      <Form id="opportunitiesForm" onSubmit={handleSubmit(onSubmit)}>
        <CustomContainerPage>
          <SectionsWrap>
            <DetailsSection
              control={control}
              errors={errors}
              setError={setError}
              managerList={managerList}
              investmentOwners={investmentOwners}
              operationsOwners={operationsOwners}
              complianceOwners={complianceOwners}
              openModalWindow={openModalWindow}
              isModify={isModify}
              openInvestment={openInvestment}
              hasActiveAllocation={hasActiveAllocation}
            />
            <AllocationSettingsSection
              strategies={strategies}
              setStrategies={setStrategies}
              tags={tags}
              setTags={setTags}
              control={control}
              openModalWindow={openModalWindow}
              descriptionValue={descriptionValue}
              setDescriptionValue={setDescriptionValue}
            />
          </SectionsWrap>
          <FormFooter onCancel={handleClosePage} disableSaveButton={!isValid} />
        </CustomContainerPage>
      </Form>
    </MainWrap>
  );
};

export default ModifyOpportunity;

const Form = styled.form`
  width: 100%;
`;

const CustomContainerPage = styled(ContainerPage)`
  padding-top: 40px;
`;

const SectionsWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
