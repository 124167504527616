import { gql } from '@apollo/client';

import { OwnersFragment } from './fragments';

export const ALLOCATION_QUERY = gql`
  ${OwnersFragment}
  query OpenOpportunities($page: Int, $rowsPerPage: Int, $sort: String, $order: String, $search: String) {
    OpenOpportunities(page: $page, rowsPerPage: $rowsPerPage, sort: $sort, order: $order, search: $search) {
      total
      page
      perPage
      investments {
        id
        assetClass
        subAssetClasses
        managerId
        custodied
        ubtiBlocker
        nextClose
        finalClose
        minimum
        targetIRR
        targetYield
        sri
        name
        type
        vintageYear
        investmentOwner {
          ...OwnersFragment
        }
        operationsOwner {
          ...OwnersFragment
        }
        complianceOwner {
          ...OwnersFragment
        }
      }
    }
  }
`;

export const OWNERS_QUERY = gql`
  ${OwnersFragment}
  query ($id: String!) {
    AllocationOwners(id: $id) {
      investmentOwners {
        ...OwnersFragment
      }
      operationsOwners {
        ...OwnersFragment
      }
      complianceOwners {
        ...OwnersFragment
      }
    }
  }
`;

export const ALLOCATION_INVESTMENT_QUERY = gql`
  query Investment($id: String!) {
    Investment(id: $id) {
      hasActiveAllocation
    }
  }
`;

export const LIST_DUE_DILIGENCE_RECORD_BASIC = gql`
  query listDueDiligenceRecords($data: DueDiligenceRecordsListDto!) {
    listDueDiligenceRecords(data: $data) {
      total
      records {
        id
        investment {
          id
          name
        }
      }
    }
  }
`;

export const addInvestmentOfferingMaterialsAttachment = gql`
  mutation addInvestmentOfferingMaterialsAttachment($data: InvestmentOfferingMaterialsAddAttachmentDto!) {
    addInvestmentOfferingMaterialsAttachment(data: $data) {
      id
      asset {
        assetKey
        url
      }
    }
  }
`;

export const deleteInvestmentOfferingMaterialsAttachment = gql`
  mutation deleteInvestmentOfferingMaterialsAttachment($id: String!) {
    deleteInvestmentOfferingMaterialsAttachment(id: $id)
  }
`;

export const GET_URL_FOR_UPLOAD_OFFERING_MATERIALS = gql`
  query getInvestmentOfferingMaterialsAttachmentUploadUrl($data: InvestmentOfferingMaterialsAttachmentUploadUrlDto!) {
    getInvestmentOfferingMaterialsAttachmentUploadUrl(data: $data) {
      assetKey
      url
    }
  }
`;
