import { SettingsMenuIcon } from '@/assets/icons/fat-menu-icons/settings';
import { ActiveInvestmentsMenuIcon } from '@/assets/icons/menu-icons/activeInvestments';
import { AllocationsMenuIcon } from '@/assets/icons/menu-icons/allocations';
import { ComplianceMenuIcon } from '@/assets/icons/menu-icons/compliance';
import { DashboardMenuIcon } from '@/assets/icons/menu-icons/dashboard';
import { DealRegistryMenuIcon } from '@/assets/icons/menu-icons/dealRegistry';
import { DueDiligenceMenuIcon } from '@/assets/icons/menu-icons/dueDiligence';
import { FamiliesMenuIcon } from '@/assets/icons/menu-icons/families';
import { ManagerUpdatesMenuIcon } from '@/assets/icons/menu-icons/managerUpdates';
import { PendingInvestmentsMenuIcon } from '@/assets/icons/menu-icons/pendingInvestments';
import { TodoCalendar } from '@/assets/icons/todo-calendar';
import { ITabs } from './types';

const jfgTabs: ITabs[] = [
  {
    label: 'To Do',
    path: 'todo',
    icon: <TodoCalendar style={{ minWidth: '28px' }} fill={'#FAFAFA'} />,
    disabled: true
  },
  { label: 'Dashboard', path: 'dashboard', icon: <DashboardMenuIcon style={{ minWidth: '28px' }} /> },
  {
    label: 'Deal Registry',
    subTitle: 'View all deals throughout their lifecycle',
    path: 'dealRegistry',
    icon: <DealRegistryMenuIcon style={{ minWidth: '28px' }} />
  },
  {
    label: 'Due Diligence',
    subTitle: '',
    path: 'dueDiligence',
    icon: <DueDiligenceMenuIcon style={{ minWidth: '28px' }} />
  },
  {
    label: 'Manager Updates',
    subTitle: 'View updates by manager and investment',
    path: 'managerUpdates',
    icon: <ManagerUpdatesMenuIcon style={{ minWidth: '28px' }} />
  },
  {
    label: 'Clients',
    subTitle: 'Find and view each client within your firm.',
    path: 'clients',
    icon: <FamiliesMenuIcon style={{ minWidth: '28px' }} />
  },
  { label: 'Allocations', path: 'allocations', icon: <AllocationsMenuIcon style={{ minWidth: '28px' }} /> },
  { label: 'Pending Investments', path: 'recommendations', icon: <PendingInvestmentsMenuIcon style={{ minWidth: '28px' }} /> },
  { label: 'Active Investments', path: 'investments', icon: <ActiveInvestmentsMenuIcon style={{ minWidth: '28px' }} /> },
  {
    label: 'Compliance',
    path: 'compliance',
    icon: <ComplianceMenuIcon style={{ minWidth: '28px' }} />
  },
  { label: 'Settings', path: 'settings', icon: <SettingsMenuIcon style={{ minWidth: '28px' }} /> }
];

export default jfgTabs;
