import styled from 'styled-components';

import { TickIcon } from '@/assets/icons/info-icons/tick';
import { Status } from './index';

const formattedDate = (date: string) => {
  return new Date(date).toLocaleString('en-US', {
    month: 'short',
    day: 'numeric',
    year: 'numeric'
  });
};

export const CompletedStatusRow = ({
  status,
  date,
  isMobile = false
}: {
  status: string;
  date: string;
  isMobile?: boolean;
}) => {
  return (
    <Row>
      <TickIcon />
      <Status isMobile={isMobile} color={'#3DBD4A'}>
        {status}
      </Status>
      <DateBlock>{formattedDate(date)}</DateBlock>
    </Row>
  );
};

const Row = styled.div<{ background?: string }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #ffffff;
  border-radius: 4px;
  padding: 7px 12px;
  background: ${({ background }) => background ?? '#fff'};
`;

const DateBlock = styled.span`
  color: #373f4e;
  font-family: 'Blinker';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  padding-left: 20px;
`;
