import * as React from 'react';

export const DashboardMenuIcon = ({ ...props }: React.SVGProps<SVGSVGElement>) => (
  <svg
    style={props.style}
    width={props.width || '28'}
    height={props.height || '28'}
    viewBox="0 0 28 28"
    fill={props.fill || 'none'}
    stroke={props.stroke || undefined}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.1229 7.47522C19.5118 7.65691 19.231 8.06987 18.6363 8.83519C18.0417 9.6005 12.8717 16.4718 12.2605 17.4133C11.6494 18.3548 11.4016 19.7918 12.9212 20.6287C14.4409 21.4601 15.509 20.4746 15.977 19.456C16.445 18.4374 19.4512 10.3878 19.776 9.46834C20.1009 8.54886 20.2991 8.10289 20.1229 7.47522Z"
      fill={props.fill || '#FAFAFA'}
    />
    <path
      d="M24.5937 15.0017C24.0632 13.0577 22.9989 11.3011 21.5215 9.93079L21.4719 10.0629C21.3893 10.3052 21.0865 11.1256 20.6846 12.1992C22.1041 13.8142 22.887 15.8908 22.8869 18.0409C22.8876 18.4906 22.8545 18.9397 22.7878 19.3843C22.7681 19.5147 22.7766 19.6478 22.8128 19.7746C22.849 19.9014 22.9121 20.019 22.9978 20.1192C23.0834 20.2195 23.1896 20.3001 23.3092 20.3557C23.4288 20.4113 23.5589 20.4406 23.6908 20.4415H23.922C24.1584 20.4398 24.3863 20.3533 24.5641 20.1977C24.742 20.042 24.8581 19.8276 24.8911 19.5936C25.1043 18.0586 25.0031 16.4963 24.5937 15.0017Z"
      fill={props.fill || '#FAFAFA'}
    />
    <path
      d="M14.5623 9.20405C15.0293 9.2334 15.4932 9.29967 15.9498 9.40225C16.5775 8.57087 17.051 7.94871 17.2051 7.75049L17.3373 7.5743L16.8472 7.43118C15.2189 6.99723 13.5126 6.94278 11.8599 7.27209C10.2071 7.6014 8.65206 8.30566 7.31436 9.33061C5.97666 10.3556 4.89205 11.6739 4.14407 13.184C3.39609 14.6941 3.00468 16.3558 3 18.041C3.00027 18.5623 3.03707 19.0829 3.11012 19.5991C3.14202 19.8322 3.25712 20.0458 3.43421 20.2007C3.6113 20.3555 3.83842 20.4411 4.07365 20.4415H4.32692C4.45907 20.4416 4.58965 20.413 4.70968 20.3577C4.8297 20.3024 4.93631 20.2216 5.02214 20.1211C5.10797 20.0207 5.171 19.9027 5.20687 19.7755C5.24273 19.6484 5.25057 19.5149 5.22987 19.3844C5.2005 19.2045 5.17848 19.021 5.1638 18.8338C5.0611 17.5629 5.23189 16.2849 5.66465 15.0856C6.09742 13.8862 6.78212 12.7936 7.67266 11.8811C8.5632 10.9687 9.63889 10.2576 10.8273 9.79584C12.0158 9.33405 13.2893 9.13226 14.5623 9.20405Z"
      fill={props.fill || '#FAFAFA'}
    />
  </svg>
);
