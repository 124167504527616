export const tenantsTableColumns = [
  { title: 'Name', key: 'NAME', width: 35, keySort: 'name' },
  { title: 'Primary Admin', key: 'PRIMARY_ADMIN', width: 25, keySort: 'primary admin' },
  { title: '', key: null, width: 40, keySort: '' }
];

export enum ROLE {
  INVESTMENT_LEAD = 'Investment Lead',
  INVESTMENT_MEMBER = 'Investment Member',
  ADVISOR_LEAD = 'Advisor Lead',
  ADVISOR_MEMBER = 'Advisor Member',
  ADVISOR_ASSOCIATE = 'Advisor Associate',
  OPERATIONS_LEAD = 'Operations Lead',
  OPERATIONS_MEMBER = 'Operations Member',
  COMPLIANCE_LEAD = 'Compliance Lead',
  COMPLIANCE_MEMBER = 'Compliance Member',
  ADMIN = 'Admin',
  SUPER_ADMIN = 'Super Admin',
  VIEWER = 'Viewer',
  DEFAULT_ADVISOR = 'Default Advisor'
}
