import { ArrowDown } from '@/assets/icons/info-icons/arrowDown';
import { RefreshIcon } from '@/assets/icons/info-icons/refresh';
import { ArrowUp } from '@/assets/static/icons/todo-page/arrow-up';
import FilterSelect from '@/components/basicComponents/select';
import { IPaginationValues } from '@/components/clients-page/types';
import { ArrowWrapper } from '@/components/dueDiligence-page/dueDiligenceRecord/tierDropdown';
import { RefreshButton, Results, TopWrapper } from '@/components/table/fat-filterPage/desktop-filter';
import TablePagination from '@/components/table/tablePagination';
import { IItems, ISort } from '@/components/table/types';
import { useResponsive } from '@/hooks/use-responsive';
import { useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { DOCUMENT_RECORD_STATUS_ENUM, IDocumentRecordEntity } from '../../types';
import MobileTable from './mobileTable';
import { Table } from './table';

interface AllUpdatesSectionProps {
  updatesTableData: IDocumentRecordEntity[];
  sort: ISort;
  setSort: (value: ISort) => void;
  loading: boolean;
  paginationValues: IPaginationValues;
  onChangePaginationData: (limit: number, offset: number) => void;
  listDocumentRecordsRefetch: () => void;
  primaryFilter: string;
  onChangePrimaryFilter: (filter: string) => void;
  dropdownActions: (row: IDocumentRecordEntity) => IItems[];
  openEditManagerUpdatePage: (updateId: string) => void;
  canUserEdit: boolean;
}

export const AllUpdatesSection = ({
  updatesTableData,
  sort,
  setSort,
  loading,
  paginationValues,
  onChangePaginationData,
  listDocumentRecordsRefetch,
  primaryFilter,
  onChangePrimaryFilter,
  dropdownActions,
  openEditManagerUpdatePage,
  canUserEdit
}: AllUpdatesSectionProps) => {
  const { isMobile, isTablet } = useResponsive();
  const theme = useTheme();

  const [isSectionOpen, setIsSectionOpen] = useState(true);
  const [arrowHover, setArrowHover] = useState(false);

  return (
    <SectionWrapper isMobile={isMobile}>
      <Section>
        <SctionItem>
          <SctionTitle isMobile={isMobile}>All Updates</SctionTitle>
        </SctionItem>
        <ArrowWrapper
          isMobile={isMobile}
          onClick={() => setIsSectionOpen(!isSectionOpen)}
          onMouseEnter={() => setArrowHover(true)}
          onMouseLeave={() => setArrowHover(false)}
        >
          <>
            {isSectionOpen ? (
              <ArrowUp style={{ cursor: 'pointer' }} fill={arrowHover && !isMobile ? theme.context.light : theme.font.action} />
            ) : (
              <ArrowDown
                style={{
                  cursor: 'pointer',
                  width: '28px',
                  height: '28px',
                  padding: '7px'
                }}
                fill={arrowHover && !isMobile ? theme.context.light : theme.font.action}
              />
            )}
          </>
        </ArrowWrapper>
      </Section>
      <SectionBodyWrapper isSectionOpen={isSectionOpen}>
        <ExpandableWrapp>
          <FilterWrapper isMobile={isMobile} isTablet={isTablet}>
            <TopWrapper>
              <Results>{paginationValues.total} Results</Results>
              <RefreshButton onClick={() => listDocumentRecordsRefetch()}>
                <RefreshIcon fill={theme.action.primary} />
              </RefreshButton>
            </TopWrapper>
            {canUserEdit && (
              <FilterSelect
                data={['All', ...Object.values(DOCUMENT_RECORD_STATUS_ENUM)]}
                selected={primaryFilter}
                setSelected={onChangePrimaryFilter}
                width="300px"
                longTitle
              />
            )}
          </FilterWrapper>
          {isMobile ? (
            <MobileTable
              tableData={updatesTableData}
              dropdownActions={dropdownActions}
              openEditManagerUpdatePage={openEditManagerUpdatePage}
              canUserEdit={canUserEdit}
            />
          ) : (
            <Table
              tableData={updatesTableData}
              sort={sort}
              setSort={setSort}
              loading={loading}
              dropdownActions={dropdownActions}
              openEditManagerUpdatePage={openEditManagerUpdatePage}
              canUserEdit={canUserEdit}
            />
          )}
        </ExpandableWrapp>
        <PaginationWrap>
          <TablePagination savePagination={onChangePaginationData} paginationValues={paginationValues} refetch={() => null as any} />
        </PaginationWrap>
      </SectionBodyWrapper>
    </SectionWrapper>
  );
};

const SectionWrapper = styled.div<{ isMobile?: boolean }>`
  border: 1px solid ${({ theme }) => theme.border.base};
  border-radius: 10px;
  padding: ${({ isMobile }) => (isMobile ? '20px' : '20px 40px')};
`;

const Section = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const SectionBodyWrapper = styled.div<{ isSectionOpen: boolean }>`
  display: grid;
  grid-template-rows: ${({ isSectionOpen }) => (isSectionOpen ? '1fr' : '0fr')};
  overflow: ${({ isSectionOpen }) => (isSectionOpen ? 'visible' : 'hidden')};
  transition: grid-template-rows 300ms;
`;

const ExpandableWrapp = styled.div`
  min-height: 0;
`;

const SctionItem = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;
`;

const SctionTitle = styled.div<{ isMobile: boolean }>`
  font-family: Lato;
  font-weight: 700;
  font-size: ${({ isMobile }) => (isMobile ? '20px' : '28px')};
  line-height: ${({ isMobile }) => (isMobile ? '30px' : '22px')};
  color: ${({ theme }) => theme.font.strong};
`;

const FilterWrapper = styled.div<{ isMobile: boolean; isTablet: boolean }>`
  display: flex;
  margin-top: 10px;
  padding: 6px 0;
  position: sticky;
  top: ${({ isMobile, isTablet }) => (isMobile ? '120px' : isTablet ? '50px' : '0')};
  background-color: ${({ theme }) => theme.layer.base};
  z-index: 10;
`;

const PaginationWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;
