import { ITier } from '@/components/dueDiligence-page/dueDiligenceRecord/types';

export const saveOnEnter = (
  event: React.KeyboardEvent<HTMLDivElement>,
  saveValue: () => void,
  disabled: boolean
) => {
  if (event.key === 'Enter' && !disabled) {
    saveValue();
  }
};

export const formatTiersData = (tiers: ITier[]) => {
  const newLinkedFieldMap = new Map();

  tiers.forEach((tier: ITier) => {
    tier.sections.forEach((section) => {
      section.fields.forEach((field) => {
        const newId = crypto.randomUUID();
        newLinkedFieldMap.set(field.id, newId);
      });
    });
  });

  const updatedTiers = tiers.map((tier: ITier) => {
    const updatedSections = tier.sections.map((section) => {
      const updatedFields = section.fields.map((field) => {
        const newId = newLinkedFieldMap.get(field.id);

        return {
          id: newId,
          name: field.name,
          required: field.required,
          type: field.type,
          linkedFieldId: field.linkedFieldId
            ? newLinkedFieldMap.get(field.linkedFieldId)
            : null,
          promptGroup: field.promptGroup
            ? {
                prompts: field.promptGroup.prompts,
                summaryPrompt: field.promptGroup.summaryPrompt,
                contextPrompt: field.promptGroup.contextPrompt,
                rubricTemplates: field.promptGroup.rubricTemplates
              }
            : null
        };
      });

      return {
        name: section.name,
        userCanScore: section.userCanScore,
        pdfLayout: section.pdfLayout,
        fields: updatedFields
      };
    });

    const updatedChecklist = tier.checklistItems.map((item) => {
      return { name: item.name, required: item.required };
    });

    return {
      name: tier.name,
      checklistItems: updatedChecklist,
      sections: updatedSections
    };
  });

  return updatedTiers;
};
