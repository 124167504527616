export type TableColumns = {
  title: string;
  key: string | null;
  width?: number;
  keySort?: string;
  tooltip?: string;
};

export enum SORT_ORDER {
  ASC = 'ASC',
  DESC = 'DESC'
}

export interface ISort {
  key: string;
  asc: boolean;
}

export interface IPagination {
  offset: number;
  limit: number;
  total: number;
}

export interface IInitialValuesFromTable {
  initialAmountRowsPerPage: number[];
  initialSortColumn: string;
}

export interface IItems {
  name: string;
  onClick: () => void;
}

export type IAdvisor = {
  firstName: string;
  initials: string;
  lastName: string;
};
