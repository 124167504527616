import * as React from 'react';

export const HistoryIcon = ({ ...props }: React.SVGProps<SVGSVGElement>) => (
    <svg 
        xmlns="http://www.w3.org/2000/svg"
        width={props.width || 42}
        height={props.height || 41}
        viewBox={`0 0 ${props.width || 42} ${props.height || 41}`}
        fill="none">
        <path 
            d="M8.34821 36.3836C7.9714 36.3836 7.62283 36.2229 7.28107 35.8919C1.41861 30.2212 -0.629983 23.5039 1.19272 15.9268C3.06509 8.13846 8.08337 3.06662 16.1074 0.85153C16.8707 0.640244 17.6506 0.510746 18.4753 0.372485C18.8317 0.313092 19.1997 0.251751 19.5649 0.183594H22.5482C22.9367 0.260514 23.331 0.333539 23.7117 0.403643C24.5783 0.564298 25.4741 0.729822 26.3289 0.950844C29.4447 1.75704 32.1817 3.25454 34.942 5.66242L35.4259 6.085L35.8397 5.59329C36.0403 5.35474 36.235 5.11425 36.423 4.88057C36.8407 4.36355 37.234 3.87477 37.6731 3.43564C37.9136 3.19515 38.1609 3.06273 38.3693 3.06273C38.7899 3.06273 38.973 3.6479 39.0187 3.82706C39.8697 7.1424 40.6866 10.4207 41.4471 13.5696C41.5551 14.0155 41.5337 14.3768 41.3906 14.5588C41.2903 14.6864 41.1043 14.7516 40.8385 14.7516C40.6866 14.7516 40.5162 14.7292 40.3303 14.6854C36.7326 13.8393 33.574 13.0769 30.6754 12.3535C30.1662 12.2259 29.8244 12.0176 29.7611 11.7956C29.6978 11.5726 29.8799 11.2162 30.247 10.8423C30.6968 10.3828 31.1671 9.93683 31.6656 9.4646C31.9003 9.24163 32.1408 9.01379 32.3881 8.77621L32.87 8.31275L32.3793 7.85804C30.7358 6.3362 28.8507 5.23109 26.6181 4.47942C24.7681 3.85627 22.8656 3.5408 20.964 3.5408C16.4813 3.5408 12.2448 5.26711 9.03562 8.40038C5.75144 11.6076 3.92581 15.958 3.89465 20.6511C3.86252 25.536 5.67354 29.7831 9.27903 33.2756C9.86616 33.8443 10.1329 34.5161 10.0122 35.1188C9.90997 35.6271 9.53414 36.0311 8.95383 36.258C8.74254 36.3408 8.53905 36.3826 8.34821 36.3826V36.3836Z"
            fill={props.fill || '#919195'}/>
        <path 
            d="M27.3613 26.2277C26.9835 26.2277 26.5687 26.0933 26.1608 25.8392C25.2445 25.2686 24.3176 24.6786 23.4209 24.108C22.4501 23.4907 21.4463 22.852 20.4512 22.2337C19.72 21.7799 19.4113 21.2327 19.4191 20.4042C19.4337 18.782 19.4317 17.1336 19.4298 15.5397C19.4279 14.1912 19.4259 12.7969 19.4347 11.4269C19.4425 10.2478 20.0588 9.51562 21.0451 9.51562C21.0617 9.51562 21.0772 9.51562 21.0938 9.51562C22.0684 9.53899 22.6604 10.2537 22.6789 11.4269C22.6916 12.2127 22.6887 13.015 22.6857 13.79C22.6848 14.1814 22.6828 14.5719 22.6838 14.9633C22.6838 15.3138 22.6877 15.6653 22.6906 16.0168C22.6994 16.8269 22.7072 17.6652 22.6682 18.4773C22.6244 19.3799 22.9642 20.0157 23.7373 20.4772C25.1832 21.3418 26.6447 22.2843 27.9348 23.1168C28.9279 23.7584 29.1967 24.6075 28.6709 25.4487C28.3525 25.958 27.8997 26.2267 27.3613 26.2267V26.2277Z"
            fill={props.fill || '#111827'}/>
        <path 
            d="M31.9491 37.7541C31.4906 37.7503 31.0767 37.5828 30.7846 37.2819C30.4848 36.9742 30.3241 36.541 30.3329 36.0639C30.3504 35.0707 31.6629 33.8945 32.7524 33.8945C33.1828 33.8945 33.5908 34.0678 33.8994 34.3823C34.2188 34.7085 34.3901 35.1389 34.3823 35.5946C34.3638 36.5838 33.0513 37.7541 31.9618 37.7541H31.9491Z"
            fill={props.fill || '#111827'}/>
        <path 
            d="M23.964 40.9993C23.9192 40.9993 23.8744 40.9993 23.8287 40.9974C23.0088 40.974 22.2036 40.1367 22.2036 39.3071C22.2036 38.2244 23.2445 37.4951 24.7955 37.4922C25.2415 37.4922 25.6825 37.683 26.0116 38.015C26.3261 38.3325 26.5043 38.7511 26.5004 39.1611C26.4887 40.2603 25.4703 40.9993 23.965 40.9993H23.964Z"
            fill={props.fill || '#111827'}/>
        <path
            d="M16.1131 40.4798C14.714 40.4798 13.5631 39.6347 13.5475 38.5967C13.5417 38.1751 13.7052 37.774 14.01 37.4673C14.3187 37.1567 14.7305 36.9824 15.1706 36.9766H15.2271C16.0664 36.9766 16.8161 37.2443 17.2845 37.7127C17.596 38.0252 17.7606 38.4079 17.7606 38.8207C17.7596 39.7379 17.0586 40.4672 16.1638 40.4798H16.1131Z"
            fill={props.fill || '#111827'}/>
    </svg>
);
