import * as React from 'react';

export const FlagIcon = ({ ...props }: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M27.6473 12.1579C27.5758 12.5674 27.5173 12.9249 27.4458 13.2759C27.1833 14.0407 26.8111 14.7632 26.3408 15.4209C25.8628 16.113 25.3066 16.7477 24.6833 17.3125C24.1188 17.8315 23.5023 18.291 22.8438 18.6839C22.2053 19.0608 21.5157 19.3431 20.7963 19.5224C20.4417 19.6023 20.0805 19.6503 19.7173 19.6655C19.4555 19.6849 19.1926 19.6849 18.9308 19.6655C18.2266 19.6272 17.5361 19.4551 16.8963 19.1584C15.678 18.5636 14.41 18.0764 13.1068 17.7024C12.3402 17.5113 11.5468 17.452 10.7603 17.5269C10.2013 17.5789 9.65532 17.7674 9.09632 17.8519C8.81869 17.9322 8.52162 17.9115 8.25783 17.7934C8.1992 17.7363 8.16239 17.6603 8.15381 17.5789C8.15381 17.1369 8.15381 16.6949 8.15381 16.2789C8.15381 13.0289 8.15381 9.7789 8.15381 6.5289C8.15935 6.15973 8.26431 5.79887 8.45762 5.48431C8.65094 5.16975 8.92546 4.91311 9.25231 4.7414C9.71487 4.45603 10.2432 4.29486 10.7863 4.27342C11.2408 4.24415 11.6968 4.24415 12.1513 4.27342C12.9164 4.31531 13.6715 4.46637 14.3938 4.72196C15.4612 5.11718 16.4177 5.76369 17.1823 6.60689C17.7406 7.22005 18.2189 7.90147 18.6058 8.63489C18.9643 9.3318 19.4004 9.98595 19.9058 10.5849C20.4387 11.1922 21.0802 11.6948 21.7973 12.0669C22.3983 12.3964 23.0636 12.5915 23.7473 12.6389C24.3374 12.6897 24.93 12.7049 25.5218 12.6845C26.0536 12.6213 26.5811 12.5257 27.1013 12.3984C27.2879 12.3291 27.4702 12.2488 27.6473 12.1579V12.1579Z"
        fill={props?.fill || '#373F4E'}
      />
      <path
        d="M6.68503 3.5H5.88554C5.59118 3.5 5.35254 3.7386 5.35254 4.03297V28.967C5.35254 29.2614 5.59118 29.5 5.88554 29.5H6.68503C6.9794 29.5 7.21804 29.2614 7.21804 28.967V4.03297C7.21804 3.7386 6.9794 3.5 6.68503 3.5Z"
        fill={props?.fill || '#373F4E'}
      />
    </svg>
  );
};
