export const columns = [
  {
    title: 'Advisory Firm',
    key: 'ADVISORY_FIRM',
    width: 15,
    keySort: 'name'
  },
  {
    title: 'Investor',
    key: 'family',
    width: 10,
    keySort: 'investor'
  },
  {
    title: 'Entity',
    key: 'entity',
    width: 10,
    keySort: 'entity'
  },
  {
    title: 'Strategy',
    key: 'investment',
    width: 15,
    keySort: 'strategy'
  },
  {
    title: 'Amount',
    key: 'amount',
    width: 10,
    keySort: 'amount'
  },
  {
    title: 'Assignee',
    key: 'assignee',
    width: 10,
    keySort: 'assignee'
  },
  {
    title: 'Last Update',
    key: 'lastStatusDate',
    width: 10,
    keySort: 'last update'
  },
  {
    title: 'Next Task',
    key: 'lastStatus',
    width: 15,
    keySort: 'next task'
  },
  {
    title: ' ',
    key: ' ',
    width: 5
  }
];

export const recommendationsSort = [
  {
    title: 'Advisory Firm • A -> Z',
    key: 'ADVISORY_FIRM',
    asc: true
  },
  {
    title: 'Advisory Firm • Z -> A',
    key: 'ADVISORY_FIRM',
    asc: false
  },
  {
    title: 'Investor • A -> Z',
    key: 'family',
    asc: true
  },
  {
    title: 'Investor • Z -> A',
    key: 'family',
    asc: false
  },
  {
    title: 'Entity • A -> Z',
    key: 'entity',
    asc: true
  },
  {
    title: 'Entity • Z -> A',
    key: 'entity',
    asc: false
  },
  {
    title: 'Strategy • A -> Z',
    key: 'investment',
    asc: true
  },
  {
    title: 'Strategy • Z -> A',
    key: 'investment',
    asc: false
  },
  {
    title: 'Amount • Max -> Min',
    key: 'amount',
    asc: false
  },
  {
    title: 'Amount • Min -> Max',
    key: 'amount',
    asc: true
  },
  {
    title: 'Assignee • A -> Z',
    key: 'assignee',
    asc: true
  },
  {
    title: 'Assignee • Z -> A',
    key: 'assignee',
    asc: false
  },
  {
    title: 'Last Update • Newest -> Oldest',
    key: 'lastStatusDate',
    asc: false
  },
  {
    title: 'Last Update • Oldest -> Newest',
    key: 'lastStatusDate',
    asc: true
  },
  {
    title: 'Next Task • Last -> First',
    key: 'lastStatus',
    asc: true
  },
  {
    title: 'Next Task • First -> Last',
    key: 'lastStatus',
    asc: false
  }
];
