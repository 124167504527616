import { Box, TextField } from '@mui/material';
import { styled, css } from 'styled-components';
import React, { useState } from 'react';
import { makeStyles } from 'tss-react/mui';

import {
  IModalWindowValues,
  ITypeModalWindow
} from '../../../../types/opportunity/proposed';
import { ButtonProps } from '../../../buttons';
import { Selector } from '../../../selector';
import { ModalButton } from '../modalWindows/complete';

interface TerminateProposalModalWindowProps {
  changeField: (
    field: string
  ) => (value: string | boolean | ITypeModalWindow) => void;
  state: IModalWindowValues;
  handleOnButton?: () => void;
  terminateAllocation?: (id: string, reason: string, note: string) => void;
}

const TerminateProposalModalWindow: React.FC<
  TerminateProposalModalWindowProps
> = ({ changeField, state, handleOnButton, terminateAllocation }) => {
  // const { classes } = useStyles();
  const [note, setNote] = useState('');
  const terminateProposal = () => {
    terminateAllocation?.(state.currentRowId, state.selector, note);
    handleOnButton?.();
    changeField('type')('confirmation');
  };
  return (
    <>
      <ModalWindowText fontWeight={600} fontSize={32} padding={'10px 0 0 0'}>
        This Action Cannot Be Undone
      </ModalWindowText>
      <ModalWindowText fontWeight={400} fontSize={22} padding={'30px 0 60px'}>
        You are terminating this allocation. This entity will appear on the
        History tab, where you can restart the allocation process.
      </ModalWindowText>
      <Box sx={{ margin: '0 auto' }}>
        <Selector
          currentItem={state.selector}
          items={[
            'Select',
            'Client preference',
            'Manager rejection',
            'Client unresponsive',
            'Account closure'
          ]}
          handleClick={changeField('selector')}
          // classNames={{ items: selectorItemsStyles }}
        />
      </Box>
      <CustomInput
        value={note}
        onChange={(event) => setNote(event.target.value)}
        placeholder={'note'}
      />
      <ModalWindowButton>
        <ModalButton type="Close" onClick={() => changeField('isOpen')(false)}>
          Cancel
        </ModalButton>
        <ButtonProps
          disabled={'Select' === state.selector}
          fullWidth
          size="medium"
          onClick={terminateProposal}
          label="Terminate Proposal"
        />
      </ModalWindowButton>
    </>
  );
};

export default TerminateProposalModalWindow;

const ModalWindowText = styled('span')<{
  fontSize: number;
  fontWeight: number;
  padding: string;
}>`
  text-align: center;
  margin: 0;
  font-size: ${(props) => `${props.fontSize}px`};
  font-weight: ${(props) => props.fontWeight};
  padding: ${(props) => props.padding};
`;

const ModalWindowButton = styled(Box)`
  margin: 48px auto 0;
  display: flex;
  columngap: 15;
`;

const CustomInput = styled(TextField)(({ theme }) => ({
  width: '80%',
  margin: '20px auto 0',
  borderRadius: 8,
  background: theme.primary.dark,
  border: `2px solid ${theme.secondary.light}`,
  boxShadow: 'none',
  '.MuiOutlinedInput-notchedOutline': { border: 0 },
  '.MuiInputBase-input': {
    padding: 10
  }
}));

const selectorItemsStyles = css`
  &.Mui-selected {
    color: ${({ theme }) => theme.info.contrastText};
    background: ${({ theme }) => theme.action.default} !important;

    &:hover {
      background-color: ${({ theme }) => theme.palette.action.default};
    }
  }
`;
