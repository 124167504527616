import { useMutation, useQuery } from '@apollo/client';
import { useState } from 'react';
import Label from '@/components/basicComponents/label';
import ModalWrappedContent from '@/components/basicComponents/modal/modalWrappedContent';
import { IFunnelData } from '@/components/dueDiligence-page/types';
import {
  CREATE_DUE_DILIGENCE_FUNNEL,
  DUE_DILIGENCE_FUNNEL_LIST_SETTINGS_QUERY,
  GET_FUNNEL_QUERY
} from '@/components/settings-page/queries';
import { SORT_ORDER } from '@/components/table/types';
import { formatTiersData } from '../utils';
import { MainSearch, ModalContainer, ModalTitle } from './addFunnel';

interface DuplicateFunnelProps {
  onClose: () => void;
  isOpen: boolean;
  currentFunnel: IFunnelData | null;
}

const DuplicateFunnel = ({
  onClose,
  isOpen,
  currentFunnel
}: DuplicateFunnelProps) => {
  if (!currentFunnel) return <></>;
  const [value, setValue] = useState('');

  const { data } = useQuery<{ getDueDiligenceFunnel: IFunnelData }>(
    GET_FUNNEL_QUERY,
    {
      notifyOnNetworkStatusChange: true,
      variables: {
        id: currentFunnel.id
      },
      skip: !currentFunnel.id
    }
  );

  const [createFunnel] = useMutation(CREATE_DUE_DILIGENCE_FUNNEL, {
    refetchQueries: () => [
      {
        query: DUE_DILIGENCE_FUNNEL_LIST_SETTINGS_QUERY,
        variables: {
          data: {
            statusFilter: ['PUBLISHED', 'DRAFT_WITHOUT_PUBLISHED'],
            sortDirection: SORT_ORDER.ASC,
            sortBy: 'ORDER'
          },
          archivedFunnelData: {
            sortDirection: SORT_ORDER.ASC,
            sortBy: 'ORDER'
          }
        }
      }
    ]
  });

  const saveValue = () => {
    if (data) {
      const { tiers } = data.getDueDiligenceFunnel;

      const updatedTiers = formatTiersData(tiers);

      createFunnel({
        variables: {
          data: {
            funnelStaticId: crypto.randomUUID(),
            name: value,
            status: 'DRAFT',
            externalId: currentFunnel.externalId,
            tiers: updatedTiers
          }
        }
      });
      onClose();
    }
  };

  return (
    <ModalWrappedContent
      isOpen={isOpen}
      onClose={onClose}
      confirmButton={{
        name: 'Save',
        onClick: saveValue,
        disabled: !value || !data
      }}
    >
      <ModalTitle>Duplicate Funnel</ModalTitle>
      <ModalContainer>
        <Label required>Name</Label>
        <MainSearch
          type="text"
          placeholder="Give this Section a unique name"
          value={value}
          onChange={(event) => setValue(event.target.value)}
          autoFocus
        />
      </ModalContainer>
    </ModalWrappedContent>
  );
};

export default DuplicateFunnel;
