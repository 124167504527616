const imageExtensions = ['.png', '.jpeg', '.gif', '.jpg'];

export const isImageExtension = (url: string) => {
  const fileExtension = url.substring(url.lastIndexOf('.')).toLowerCase();
  return imageExtensions.includes(fileExtension);
};

export const decimalToPercentage = (value: string) => {
  if (value.startsWith('.')) {
    const decimal = parseFloat(value);
    const percentage = decimal * 100;
    return `${percentage.toFixed(2)}%`;
  } else {
    return `${parseFloat(value).toFixed(2)}%`;
  }
};
