import styled from 'styled-components';

interface AiAssistantProgressBarProps {
  currentAnswers: number;
  totalAnswers: number;
}

const AiAssistantProgressBar = ({ currentAnswers, totalAnswers }: AiAssistantProgressBarProps) => {
  const percentage = totalAnswers === 0 ? 0 : (currentAnswers / totalAnswers) * 100;

  return (
    <ProgressBarWrapper>
      {`${Math.floor(percentage)}%`}
      <ProgressBar>
        <ProgressItem width={percentage} />
      </ProgressBar>
    </ProgressBarWrapper>
  );
};

export default AiAssistantProgressBar;

const ProgressBarWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 0 auto;
  width: 80%;
  min-width: 180px;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  margin-top: 10px;
  padding: 10px;
  color: ${({ theme }) => theme.font.base};
`;

const ProgressBar = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-start;
  overflow: hidden;
  width: 100%;
  height: 16px;
  border-radius: 10px;
  background: ${({ theme }) => theme.layer[2]};
  max-height: 20px;
`;

const ProgressItem = styled.div<{ width: number }>`
  display: flex;
  width: ${({ width }) => `${width}%`};
  background: ${({ theme }) => theme.context.success};
  transition: 0.3s ease-in-out;
`;
