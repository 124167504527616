import { EditTodoIcon } from '@/assets/icons/info-icons/editTodo';
import { AddButtonIcon } from '@/assets/static/icons/add-button-icon';
import Button from '@/components/fat-basicComponents/button';
import { useResponsive } from '@/hooks/use-responsive';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';
import { SectionZeroStateText, SectionZeroStateWrapper } from '..';
import { Section } from '../../edit-entity/section';

interface BenefitPlansSectionProps {
  saveScrollPostion: () => void;
}

export const BenefitPlansSection = ({ saveScrollPostion }: BenefitPlansSectionProps) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { isMobile } = useResponsive();

  const [showSectionZeroState, setShowSectionZeroState] = useState(false);

  const openBenefitPlansPage = () => {
    saveScrollPostion();
    navigate('benefitPlans?fromSection=Benefit Plans&backToTitle=Investor Documentation');
  };

  if (showSectionZeroState) {
    return (
      <Section title="Benefit Plans">
        <SectionZeroStateWrapper>
          <SectionZeroStateText>No information has been added yet.</SectionZeroStateText>
          <Button onClick={openBenefitPlansPage} styleType="outline" icon={<AddButtonIcon fill={theme.font.action} />} size="md" width="max-content">
            Add Benefit Plans
          </Button>
        </SectionZeroStateWrapper>
      </Section>
    );
  }

  return (
    <Section title="Benefit Plans">
      <BenefitPlansWrapper isMobile={isMobile}>
        <BenefitPlansItemsWrapper>
          <TwoColumnWrapper isMobile={isMobile}>
            <BenefitPlansItem>Is entity an ERISA Benefit Plan Investor?</BenefitPlansItem>
            <BenefitPlansItem isBold>MOCKED DATA</BenefitPlansItem>
          </TwoColumnWrapper>
          <Divider />
          <TwoColumnWrapper isMobile={isMobile}>
            <BenefitPlansItem>Is entity a Plan Asset?</BenefitPlansItem>
            <BenefitPlansItem isBold>MOCKED DATA</BenefitPlansItem>
          </TwoColumnWrapper>
          <TwoColumnWrapper isMobile={isMobile}>
            <BenefitPlansItem>
              Is subscriber primarily engaged, directly or through a majority owned subsidiary, in the production or sale of a product or service
              other than the investment of capital?
            </BenefitPlansItem>
            <BenefitPlansItem isBold>MOCKED DATA</BenefitPlansItem>
          </TwoColumnWrapper>
          <Divider />
          <TwoColumnWrapper isMobile={isMobile}>
            <BenefitPlansItem>
              Insurance Company Percentage
              <BenefitPlansItemWeak>
                (Subscriber is using the assets of an insurance company general account to purchase an Interest to not exceed the following
                percentage.)
              </BenefitPlansItemWeak>
            </BenefitPlansItem>
            <BenefitPlansItem isBold>MOCKED DATA</BenefitPlansItem>
          </TwoColumnWrapper>
        </BenefitPlansItemsWrapper>
        <EditIconWrapper onClick={openBenefitPlansPage} isMobile={isMobile} id="editButton">
          <EditTodoIcon />
        </EditIconWrapper>
      </BenefitPlansWrapper>
    </Section>
  );
};

const BenefitPlansWrapper = styled.div<{ isMobile: boolean }>`
  display: flex;
  align-items: center;
  gap: 15px;
  flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};
  padding: ${({ isMobile }) => (isMobile ? '0' : '20px')};
  transition: 0.3s ease-in-out;

  @media (hover: hover) {
    &:hover #editButton {
      opacity: 1;
    }

    &:hover {
      background-color: ${({ theme }) => theme.layer[12]};
    }
  }
`;

const BenefitPlansItemsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
`;

const TwoColumnWrapper = styled.div<{ isMobile: boolean }>`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  gap: 20px;
  width: ${({ isMobile }) => (isMobile ? '100%' : '60%')};
  padding: 2px 10px;
`;

const BenefitPlansItem = styled.div<{ isBold?: boolean }>`
  font-family: Blinker;
  font-size: 16px;
  font-weight: ${({ isBold }) => (isBold ? 600 : 400)};
  line-height: 22.4px;
  color: ${({ theme }) => theme.font.base};
  padding: 4px;
`;

const BenefitPlansItemWeak = styled(BenefitPlansItem)`
  color: ${({ theme }) => theme.font.weak};
  padding: 0;
`;

const EditIconWrapper = styled.div<{ isMobile: boolean }>`
  width: ${({ isMobile }) => (isMobile ? '100%' : 'max-content')};
  padding: 12px;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.action.default};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  cursor: pointer;
  opacity: ${({ isMobile }) => (isMobile ? '1' : '0')};
  transition: 0.3s ease-in-out;
`;

const Divider = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${({ theme }) => theme.border.base};
  margin: 20px 0;
`;
