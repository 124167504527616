import { format } from 'date-fns';

import {
  IInvestmentWorkflowTransitionTask,
  Investment
} from '@/components/fat-dealCatalog-page/types';
import { AnchorPopover } from '@/components/table/anchor-drop-down/fat-index';
import { dateFormatter } from '../../../../utils/dateFormatter';
import RedirectTitleCell from '../../../table/redirectTitleCell';
import { CellWrapper } from '../../../table/styles';
import { StepsList } from '../../fat-stepLists';

interface LastUpdateProps {
  row: Investment;
  isMobile?: boolean;
  statuses: IInvestmentWorkflowTransitionTask[];
  initialDate: Date;
  updatedAt: Date;
}

const LastUpdate = ({
  row,
  isMobile = false,
  statuses,
  initialDate,
  updatedAt
}: LastUpdateProps) => {
  return (
    <CellWrapper
      width={'100%'}
      alignItems={'center'}
      justifyContent={'space-between'}
    >
      {isMobile ? (
        <RedirectTitleCell color={isMobile ? '#00aeef' : '#373f4e'}>
          {updatedAt &&
            dateFormatter(
              format(new Date(updatedAt), 'yyyy-MM-dd'),
              'yyyy-MM-dd'
            )}
        </RedirectTitleCell>
      ) : (
        <AnchorPopover
          isMobile={isMobile}
          title={'Updates'}
          showLeft
          nameCell={
            updatedAt &&
            dateFormatter(
              format(new Date(updatedAt), 'yyyy-MM-dd'),
              'yyyy-MM-dd'
            )
          }
          requestMakesInChildren
        >
          <StepsList statuses={statuses} row={row} initialDate={initialDate} />
        </AnchorPopover>
      )}
    </CellWrapper>
  );
};

export default LastUpdate;
