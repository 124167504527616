import { gql } from '@apollo/client';

export const LIST_INVESTMENT_ENTITY_INTERESTS_BY_ADVISORY_FIRM_QUERY = gql`
  query listInvestmentEntityInterestsByAdvisoryFirm($data: InvestmentEntityListDto!) {
    listInvestmentEntityInterestsByAdvisoryFirm(data: $data) {
      total
      page
      perPage
      lastPage
      data {
        id
        name
        estimatedCommitment
        estimatedExemptCommitment
        estimatedTaxableCommitment
        investorCount
        updatedAt
      }
    }
  }
`;

export const LIST_INVESTMENT_ENTITY_INTERESTS_BY_INVESTOR_QUERY = gql`
  query listInvestmentEntityInterests($data: InvestmentEntityListDto!) {
    listInvestmentEntityInterests(data: $data) {
      total
      page
      perPage
      lastPage
      data {
        id
        entityName
        tenant {
          name
        }
        family {
          id
          name
        }
        advisor {
          firstName
          lastName
          initials
        }
        investor_interest {
          id
          amount
          legal_entity {
            id
            entityName
          }
          updatedAt
          exempt
        }
      }
    }
  }
`;

export const ALL_ADVISORY_FIRM_LIST_QUERY = gql`
  query AllAdvisoryFirmList($data: AdvisoryFirmListDto) {
    allAdvisoryFirmList(data: $data) {
      id
      name
      type
    }
  }
`;
