import RadioButton from '@/components/basicComponents/radioButton';
import FilterSelect from '@/components/basicComponents/select';
import { FormFooter } from '@/components/fat-basicComponents/formFooter';
import Input from '@/components/fat-basicComponents/input';
import Label from '@/components/fat-basicComponents/label';
import Header from '@/components/fat-header';
import { GoBackButton } from '@/components/fat-header/goBackButton';
import { PageTitle } from '@/components/fat-header/pageTitle';
import { useResponsive } from '@/hooks/use-responsive';
import { useGoBack } from '@/hooks/useGoBack';
import { MainWrap, PaddingWrap } from '@/styles/common';
import { Controller, useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import styled from 'styled-components';

export const BankAccountFormPage = () => {
  const goBack = useGoBack();
  const { isMobile } = useResponsive();
  const {
    handleSubmit,
    getValues,
    setValue,
    control,
    setError,
    formState: { errors, isValid },
    reset,
    watch
  } = useForm();

  const [searchParams, setSearchParams] = useSearchParams();

  const backToTitle = searchParams.get('backToTitle') || '';

  const onSubmit = (data: any) => {
    console.log(data);
  };

  return (
    <MainWrap>
      <Header modalControl={<GoBackButton handleClose={() => goBack({ fallBack: '/investors' })} backToTitle={backToTitle} />} />
      <PageTitle title="Enter Bank Account Info" />
      <PaddingWrap>
        <form id="bankAccountInfoForm" onSubmit={handleSubmit(onSubmit, () => window.scrollTo(0, 0))}>
          <BankAccountInfoWrapper isMobile={isMobile}>
            <BankAccountInfoSectionsWrapper>
              <div>
                <FormSubTitle>Beneficiary Bank Details</FormSubTitle>
                <ThreeColumnWrapper isMobile={isMobile}>
                  <Controller
                    name="entityName"
                    control={control}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                      <Input
                        label="ABA Number"
                        value={value}
                        onChange={(e) => {
                          onChange(e.target.value);
                        }}
                        errorText={error?.message}
                        size="md"
                      />
                    )}
                  />
                  <Controller
                    name="entityName"
                    control={control}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                      <Input
                        label="SWIFT/BIC Code (int’l wires only)"
                        value={value}
                        onChange={(e) => {
                          onChange(e.target.value);
                        }}
                        errorText={error?.message}
                        size="md"
                      />
                    )}
                  />
                  <Controller
                    name="entityName"
                    control={control}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                      <Input
                        label="Bank Name"
                        value={value}
                        onChange={(e) => {
                          onChange(e.target.value);
                        }}
                        errorText={error?.message}
                        size="md"
                      />
                    )}
                  />
                </ThreeColumnWrapper>
              </div>
              <div>
                <FormSubTitle>Beneficiary Bank Address</FormSubTitle>
                <TwoColumnWrapper isMobile={isMobile}>
                  <Controller
                    name="entityName"
                    control={control}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                      <Input
                        label="Street 1"
                        value={value}
                        onChange={(e) => {
                          onChange(e.target.value);
                        }}
                        errorText={error?.message}
                        size="md"
                      />
                    )}
                  />
                  <Controller
                    name="entityName"
                    control={control}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                      <Input
                        label="Street 2  (if appl.)"
                        value={value}
                        onChange={(e) => {
                          onChange(e.target.value);
                        }}
                        errorText={error?.message}
                        size="md"
                      />
                    )}
                  />
                </TwoColumnWrapper>
                <ThreeColumnWrapper isMobile={isMobile}>
                  <Controller
                    name="entityName"
                    control={control}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                      <Input
                        label="City"
                        value={value}
                        onChange={(e) => {
                          onChange(e.target.value);
                        }}
                        errorText={error?.message}
                        size="md"
                      />
                    )}
                  />
                  <div>
                    <Label size="md">State</Label>
                    <Controller
                      name="entityName"
                      control={control}
                      render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <>
                          <CustomSelect selected={'Select'} setSelected={onChange} data={['Select']} width="100%" />
                          {error?.message && <ErrorText>{error.message}</ErrorText>}
                        </>
                      )}
                    />
                  </div>
                  <Controller
                    name="entityName"
                    control={control}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                      <Input
                        label="Zip"
                        value={value}
                        onChange={(e) => {
                          onChange(e.target.value);
                        }}
                        errorText={error?.message}
                        size="md"
                      />
                    )}
                  />
                </ThreeColumnWrapper>
                <TwoColumnWrapper isMobile={isMobile}>
                  <div>
                    <Label size="md">Country</Label>
                    <Controller
                      name="entityName"
                      control={control}
                      render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <>
                          <CustomSelect selected={'Select'} setSelected={onChange} data={['Select']} width="100%" />
                          {error?.message && <ErrorText>{error.message}</ErrorText>}
                        </>
                      )}
                    />
                  </div>
                  <Controller
                    name="entityName"
                    control={control}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                      <Input
                        label="Phone Number"
                        value={value}
                        onChange={(e) => {
                          onChange(e.target.value);
                        }}
                        errorText={error?.message}
                        size="md"
                      />
                    )}
                  />
                </TwoColumnWrapper>
              </div>
              <div>
                <FormSubTitle>Intermediary Bank Details</FormSubTitle>
                <IntermediaryBankDetailsWrapper>
                  <div>
                    <Label size="md">Would you like to add an intermediary bank?</Label>
                    <RadioButtonsWrapper>
                      <Controller
                        name="intermediaryBankOption"
                        control={control}
                        render={({ field: { value, onChange } }) => (
                          <>
                            {['No', 'Yes'].map((option, index) => (
                              <RadioButton
                                key={option + index}
                                name="intermediaryBankOption"
                                radioButtonText={option}
                                selectedOption={value ? 'Yes' : 'No'}
                                handleRadioChange={(event) => onChange(event.target.value === 'Yes')}
                              />
                            ))}
                          </>
                        )}
                      />
                    </RadioButtonsWrapper>
                  </div>
                  <ThreeColumnWrapper isMobile={isMobile}>
                    <Controller
                      name="entityName"
                      control={control}
                      render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <Input
                          label="ABA Number"
                          value={value}
                          onChange={(e) => {
                            onChange(e.target.value);
                          }}
                          errorText={error?.message}
                          size="md"
                        />
                      )}
                    />
                    <Controller
                      name="entityName"
                      control={control}
                      render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <Input
                          label="SWIFT/BIC Code (int’l wires only)"
                          value={value}
                          onChange={(e) => {
                            onChange(e.target.value);
                          }}
                          errorText={error?.message}
                          size="md"
                        />
                      )}
                    />
                    <Controller
                      name="entityName"
                      control={control}
                      render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <Input
                          label="Bank Name"
                          value={value}
                          onChange={(e) => {
                            onChange(e.target.value);
                          }}
                          errorText={error?.message}
                          size="md"
                        />
                      )}
                    />
                  </ThreeColumnWrapper>
                  <FormSubTitle style={{ marginTop: '40px' }}>Intermediary Bank Address</FormSubTitle>
                  <TwoColumnWrapper isMobile={isMobile}>
                    <Controller
                      name="entityName"
                      control={control}
                      render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <Input
                          label="Street 1"
                          value={value}
                          onChange={(e) => {
                            onChange(e.target.value);
                          }}
                          errorText={error?.message}
                          size="md"
                        />
                      )}
                    />
                    <Controller
                      name="entityName"
                      control={control}
                      render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <Input
                          label="Street 2  (if appl.)"
                          value={value}
                          onChange={(e) => {
                            onChange(e.target.value);
                          }}
                          errorText={error?.message}
                          size="md"
                        />
                      )}
                    />
                  </TwoColumnWrapper>
                  <ThreeColumnWrapper isMobile={isMobile}>
                    <Controller
                      name="entityName"
                      control={control}
                      render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <Input
                          label="City"
                          value={value}
                          onChange={(e) => {
                            onChange(e.target.value);
                          }}
                          errorText={error?.message}
                          size="md"
                        />
                      )}
                    />
                    <div>
                      <Label size="md">State</Label>
                      <Controller
                        name="entityName"
                        control={control}
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                          <>
                            <CustomSelect selected={'Select'} setSelected={onChange} data={['Select']} width="100%" />
                            {error?.message && <ErrorText>{error.message}</ErrorText>}
                          </>
                        )}
                      />
                    </div>
                    <Controller
                      name="entityName"
                      control={control}
                      render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <Input
                          label="Zip"
                          value={value}
                          onChange={(e) => {
                            onChange(e.target.value);
                          }}
                          errorText={error?.message}
                          size="md"
                        />
                      )}
                    />
                  </ThreeColumnWrapper>
                  <TwoColumnWrapper isMobile={isMobile}>
                    <div>
                      <Label size="md">Country</Label>
                      <Controller
                        name="entityName"
                        control={control}
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                          <>
                            <CustomSelect selected={'Select'} setSelected={onChange} data={['Select']} width="100%" />
                            {error?.message && <ErrorText>{error.message}</ErrorText>}
                          </>
                        )}
                      />
                    </div>
                    <Controller
                      name="entityName"
                      control={control}
                      render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <Input
                          label="Phone Number"
                          value={value}
                          onChange={(e) => {
                            onChange(e.target.value);
                          }}
                          errorText={error?.message}
                          size="md"
                        />
                      )}
                    />
                  </TwoColumnWrapper>
                </IntermediaryBankDetailsWrapper>
              </div>
              <div>
                <FormSubTitle>Beneficiary Account Details</FormSubTitle>
                <TwoColumnWrapper isMobile={isMobile}>
                  <Controller
                    name="entityName"
                    control={control}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                      <Input
                        label="Beneficiary Account Name (as it appears on the account)"
                        value={value}
                        onChange={(e) => {
                          onChange(e.target.value);
                        }}
                        errorText={error?.message}
                        size="md"
                      />
                    )}
                  />
                  <Controller
                    name="entityName"
                    control={control}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                      <Input
                        label="Beneficiary Account Number"
                        value={value}
                        onChange={(e) => {
                          onChange(e.target.value);
                        }}
                        errorText={error?.message}
                        size="md"
                      />
                    )}
                  />
                </TwoColumnWrapper>
                <TwoColumnWrapper isMobile={isMobile}>
                  <div>
                    <Label size="md">Account Type</Label>
                    <Controller
                      name="entityName"
                      control={control}
                      render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <>
                          <CustomSelect selected={'Select'} setSelected={onChange} data={['Select']} width="100%" />
                          {error?.message && <ErrorText>{error.message}</ErrorText>}
                        </>
                      )}
                    />
                  </div>
                  <Controller
                    name="entityName"
                    control={control}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                      <Input
                        label="Additional Reference (if appl.)"
                        value={value}
                        onChange={(e) => {
                          onChange(e.target.value);
                        }}
                        errorText={error?.message}
                        size="md"
                      />
                    )}
                  />
                </TwoColumnWrapper>
              </div>
            </BankAccountInfoSectionsWrapper>
          </BankAccountInfoWrapper>
          <FormFooter onCancel={() => goBack({ fallBack: '/investors' })} disableSaveButton={false} showRequiredFieldsWarning={false} />
        </form>
      </PaddingWrap>
    </MainWrap>
  );
};

const BankAccountInfoWrapper = styled.div<{ isMobile: boolean }>`
  margin-top: 50px;
  padding: ${({ isMobile }) => (isMobile ? '20px' : '40px')};
  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.border.base};
  background-color: ${({ theme }) => theme.layer[1]};
  display: flex;
  flex-direction: column;
  gap: 25px;
`;

const BankAccountInfoSectionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
`;

const FormSubTitle = styled.div`
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  color: ${({ theme }) => theme.font.strong};
  padding-bottom: 10px;
  border-bottom: 1px solid ${({ theme }) => theme.border.base};
`;

const TwoColumnWrapper = styled.div<{ isMobile: boolean }>`
  display: grid;
  grid-template-columns: ${({ isMobile }) => (isMobile ? '1fr' : '1fr 1fr')};
  gap: ${({ isMobile }) => (isMobile ? '15px' : '30px')};
  padding-top: 25px;
`;

const ThreeColumnWrapper = styled.div<{ isMobile: boolean }>`
  display: grid;
  grid-template-columns: ${({ isMobile }) => (isMobile ? '1fr' : '1fr 1fr 1fr')};
  gap: ${({ isMobile }) => (isMobile ? '15px' : '30px')};
  padding-top: 25px;
`;

const CustomSelect = styled(FilterSelect)`
  background-color: ${({ theme }) => theme.layer[1]};
  border: 1px solid ${({ theme }) => theme.border.base};
  padding: 10px;
  border-radius: 4px;

  #titleSelect {
    text-transform: none;
    font-family: Blinker;
    font-size: 16px;
    font-weight: 400;
    line-height: 22.4px;
  }

  #dropDownSelect {
    top: 50px;
  }
`;

const ErrorText = styled.p`
  color: ${({ theme }) => theme.context.error};
  font-family: Blinker;
  font-style: normal;
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;
`;

const IntermediaryBankDetailsWrapper = styled.div`
  padding: 20px;
  margin-top: 20px;
  background-color: ${({ theme }) => theme.layer[12]};
`;

const RadioButtonsWrapper = styled.div`
  display: flex;
`;
