import { ISort } from '@/components/table/types';
import { useResponsive } from '@/hooks/use-responsive';
import { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';
import FooterModal from '../../../footer';
import { ProgressBar } from '../../../progressBar';
import { Status } from '../../../status';
import { ImportWrapper } from '../../../styles';
import { allocationsSteps } from '../../constants';
import { IAllocationConflictsTableData, InitialImportCommitmentSummary, MismatchedAllocationError } from '../../types';
import { allocationConflictsStatusError, allocationConflictsStatusSuccess } from './constants';
import Table from './table/table';

interface AllocationConflictsProps {
  importSteps: typeof allocationsSteps;
  startOver: () => void;
  nextStep: () => void;
  openResolveAllocationConflicts: (row: IAllocationConflictsTableData) => void;
  tableData: IAllocationConflictsTableData[];
  allocationConflictsErrors: MismatchedAllocationError[];
  showTable: boolean;
  setShowTable: Dispatch<SetStateAction<boolean>>;
  sort: ISort;
  setSort: Dispatch<SetStateAction<ISort>>;
  search: string;
  setSearch: Dispatch<SetStateAction<string>>;
  primaryFilter: string;
  setPrimaryFilter: Dispatch<SetStateAction<string>>;
  summary: InitialImportCommitmentSummary | undefined;
}

export const AllocationConflicts = ({
  importSteps,
  startOver,
  nextStep,
  openResolveAllocationConflicts,
  tableData,
  allocationConflictsErrors,
  showTable,
  setShowTable,
  sort,
  setSort,
  search,
  setSearch,
  primaryFilter,
  setPrimaryFilter,
  summary
}: AllocationConflictsProps) => {
  const { isMobile, isTablet } = useResponsive();

  return (
    <>
      <ImportWrapper>
        <ProgressBar importSteps={importSteps} />
        <Status
          errorsCount={allocationConflictsErrors.length || null}
          matched={[
            { count: summary?.unchangedAllocations ?? 0, text: 'Unchanged Allocations' },
            { count: summary?.allocationsMarkedAsCommitted ?? 0, text: 'Allocations Marked As Committed' },
            { count: summary?.allocationsStillPending ?? 0, text: 'Allocations Still Pending' }
          ]}
          showTable={showTable}
          setShowTable={setShowTable}
          startOver={startOver}
          confirmButton={{ name: 'Save and Complete', onClick: nextStep, disabled: Boolean(allocationConflictsErrors.length) }}
          success={allocationConflictsStatusSuccess}
          error={allocationConflictsStatusError}
        />
      </ImportWrapper>
      {showTable || allocationConflictsErrors?.length ? (
        <TableWrapper>
          <Table
            tableData={tableData}
            openResolveAllocationConflicts={openResolveAllocationConflicts}
            allocationConflictsErrors={allocationConflictsErrors}
            sort={sort}
            setSort={setSort}
            search={search}
            setSearch={setSearch}
            primaryFilter={primaryFilter}
            setPrimaryFilter={setPrimaryFilter}
          />
        </TableWrapper>
      ) : (
        <></>
      )}
      {allocationConflictsErrors?.length || showTable ? (
        <FooterModal
          startOver={startOver}
          confirmButton={{ name: 'Save and Complete', onClick: nextStep, disabled: Boolean(allocationConflictsErrors.length) }}
        />
      ) : (
        <></>
      )}
    </>
  );
};

const TableWrapper = styled.div`
  margin-bottom: 100px;
`;
