import { InfoIcon } from '@/assets/icons/info-icons/info';
import { DeleteBucketIcon } from '@/assets/static/icons/delete-bucket';
import { Banner } from '@/components/basicComponents/banner';
import ModalLoader from '@/components/fat-basicComponents/modal/modalLoader';
import ModalWrappedContent from '@/components/fat-basicComponents/modal/modalWrappedContent';
import { useGoBack } from '@/hooks/useGoBack';
import { useMutation } from '@apollo/client';
import { useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { deleteLegalEntityMutation } from '../queries';
import { ILegalEntity } from '../types';

interface DeleteEntityProps {
  onClose: () => void;
  isOpen: boolean;
  currentRow: ILegalEntity | null;
  refetch: () => void;
  total: number;
}

const DEFAULT_ERROR_MESSAGE = `We're sorry. This process did not complete. Please try again.`;

const DeleteEntity = ({ onClose, isOpen, currentRow, refetch, total }: DeleteEntityProps) => {
  const theme = useTheme();
  const goBack = useGoBack();

  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const [deleteLegalEntity, { loading: deleteLegalEntityLoading }] = useMutation(deleteLegalEntityMutation, {
    onError: (error) => {
      console.error(error);
      setErrorMessage(DEFAULT_ERROR_MESSAGE);
    }
  });

  const saveValue = () => {
    setErrorMessage(null);
    if (!currentRow) {
      setErrorMessage(DEFAULT_ERROR_MESSAGE);
      return;
    }

    deleteLegalEntity({
      variables: {
        id: currentRow.id
      },
      onCompleted: () => {
        if (total === 1) {
          goBack({ fallBack: '/investors' });
          return;
        }
        refetch();
        onClose();
      }
    });
  };

  return (
    <ModalWrappedContent
      isOpen={isOpen}
      onClose={onClose}
      confirmButton={{
        name: 'Confirm',
        onClick: saveValue,
        disabled: deleteLegalEntityLoading
      }}
      showCloseIcon={!deleteLegalEntityLoading}
      showCancelButton={!deleteLegalEntityLoading}
      isUseOutsideClick={!deleteLegalEntityLoading}
    >
      <ModalBody>
        <DeleteBucketIcon fill={theme.font.disabled} />
        {deleteLegalEntityLoading ? (
          <ModalLoader />
        ) : (
          <>
            <ModalTitle>Please confirm you want to delete this Entity.</ModalTitle>
            {errorMessage && (
              <ErrorBannerWrapper>
                <Banner icon={<InfoIcon width={26} height={26} />} title="Error" description={errorMessage} bgColor={theme.context.error} />
              </ErrorBannerWrapper>
            )}
            <ModalDescription>
              This action will delete this entity and all associated commitments, transaction activity, and investor interest data. This action cannot
              be undone. Are you absolutely sure that you want to proceed?
            </ModalDescription>
          </>
        )}
      </ModalBody>
    </ModalWrappedContent>
  );
};

export default DeleteEntity;

const ModalBody = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
`;

const ModalTitle = styled.div`
  font-family: Blinker;
  font-size: 33px;
  font-style: normal;
  font-weight: 400;
  line-height: 46.2px;
  text-align: center;
  color: ${({ theme }) => theme.font.strong};
`;

const ModalDescription = styled.div`
  font-size: 16px;
  font-style: normal;
  text-align: center;
  color: ${({ theme }) => theme.font.base};
`;

const ErrorBannerWrapper = styled.div`
  margin-bottom: 10px;
`;
