import * as React from 'react';

export const RedStarIcon = ({ ...props }: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width={props.width || 12} height={props.height || 12} viewBox={`0 0 12 ${props.width}`} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.57623 0.194458L7.07703 4.65894L11.5994 3.3933L12.0001 6.45282L7.87812 6.7431L10.5834 10.3425L7.83163 11.8055L5.93903 8.01447L4.27873 11.7822L1.41665 10.3425L4.09873 6.7431L0 6.42376L0.470281 3.3933L4.89996 4.65894L4.40063 0.194458H7.57623Z"
        fill={props.fill || '#D63B4B'}
      />
    </svg>
  );
};
