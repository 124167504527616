import ModalWrappedContent from '@/components/basicComponents/modal/modalWrappedContent';
import { ApprovedIcon } from '@/assets/icons/info-icons/approved';
import styled from 'styled-components';

interface SuccessDialogProps {
  onClose: () => void;
  isOpen: boolean;
  funnelName: string;
}

const SuccessDialog = ({ onClose, isOpen, funnelName }: SuccessDialogProps) => {
  const saveValue = () => {
    onClose();
  };

  return (
    <ModalWrappedContent
      isOpen={isOpen}
      onClose={onClose}
      confirmButton={{ name: 'OK', onClick: saveValue }}
      showCancelButton={false}
    >
      <ModalContainer>
        <ApprovedIcon width={75} height={75} />
        <ModalDescription>
          {funnelName} was published successfully.
        </ModalDescription>
      </ModalContainer>
    </ModalWrappedContent>
  );
};

export default SuccessDialog;

const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
`;

const ModalDescription = styled.div`
  color: #373f4e;
  text-align: center;
  font-family: Blinker;
  font-size: 33px;
  font-weight: 400;
  line-height: 46.2px;
`;
