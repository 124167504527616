import { gql } from '@apollo/client';

export const POTENTIAL_ALLOCATION_QUERY = gql`
  query PotentialAllocations(
    $investmentId: String!
    $advisorFilter: String
    $suitabilityFilter: String
    $page: Int
    $rowsPerPage: Int
    $sort: String
    $order: String
    $search: String
    $includeProposedInvestments: Boolean
    $allocationStatusFilter: AllocationFilter
    $additionalAdvisorFilters: [AdditionalFilterDto!]
    $additionalDecisionFilters: [AdditionalDecisionFilterDto!]
    $additionalSuitabilityFilters: [AdditionalFilterDto!]
  ) {
    PotentialAllocations(
      investmentId: $investmentId
      advisorFilter: $advisorFilter
      suitabilityFilter: $suitabilityFilter
      page: $page
      rowsPerPage: $rowsPerPage
      sort: $sort
      order: $order
      search: $search
      includeProposedInvestments: $includeProposedInvestments
      allocationStatusFilter: $allocationStatusFilter
      additionalAdvisorFilters: $additionalAdvisorFilters
      additionalDecisionFilters: $additionalDecisionFilters
      additionalSuitabilityFilters: $additionalSuitabilityFilters
    ) {
      total
      page
      perPage
      filters {
        name
        type
        value
        enabled
        count
      }
      totalAmount {
        length
        amount
      }
      investment {
        id
        assetClass {
          id
          name
        }
        subAssetClass {
          id
          name
        }
        subAssetClasses
        tags
        managerId
        custodied
        ubtiBlocker
        nextClose
        finalClose
        investorDocsDue
        minimum
        targetIRR
        targetYield
        sri
        name
        vintageYear
        targetIRR
        targetYield
        type
        securityStatus
        offeringMaterialsDescription
        offeringMaterialsAttachments {
          id
          asset {
            url
            assetKey
          }
        }
      }
      allocations {
        existingCommitment
        editable
        allocationStatus
        committedCapital
        decision
        reason
        override
        suitability
        proposalType
        suitabilityCriteria {
          label
          value
        }
        preAllocationStatus {
          alreadyCommitted
          committedAt
          terminated
          terminatedAt
          internalReviewRejections
          internalReviewRejectionAt
        }
        selectedGroupLegalEntity {
          id
          entityName
        }
        investment {
          id
          assetClass {
            id
            name
          }
          subAssetClass {
            id
            name
          }
          subAssetClasses
          managerId
          custodied
          ubtiBlocker
          nextClose
          minimum
          targetIRR
          targetYield
          sri
          name
          vintageYear
          targetIRR
          targetYield
          type
          securityStatus
        }
        legalEntity {
          id
          entityName
          alwaysShow
          isGroupEntity
          grouped
          overallCapacity
          assetClassCapacity
          totalEntityValue
          fundBiteSize
          directBiteSize
          ignoreBiteSize
          ignoreAllocationTargets
          family {
            id
            name
          }
          entities {
            id
            entityName
            outstandingAllocations {
              id
            }
          }
          outstandingAllocations {
            allocationStatus
            committedCapital
          }
          advisor {
            firstName
            lastName
            initials
          }
        }
      }
    }
  }
`;
