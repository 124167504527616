import { getUTCDate } from '@/components/allocations-page/utils';
import FormattingTooltip from '@/components/basicComponents/tooltip/dollarsTooltip';
import { MobileRow, MobileRowTitle, MobileRowValue, MobileTableRowWrap } from '@/components/table/mobileTable/mobileTable';
import styled from 'styled-components';
import { CommitmentForecastValueDto } from '../../../../types';

interface MobileTableProps {
  contributionsTableData: CommitmentForecastValueDto[];
  distributionsTableData: CommitmentForecastValueDto[];
}

interface CommitmentSummary {
  [year: string]: number;
}

const MobileSumFooter = ({ contributionsTableData, distributionsTableData }: MobileTableProps) => {
  const dateContributionsSummary: CommitmentSummary = {};
  const dateDistributionsSummary: CommitmentSummary = {};

  const addValueToSummary = (summary: CommitmentSummary, date: string, allocation: number) => {
    const year = getUTCDate(new Date(date)).toUpperCase();

    if (!summary[year]) {
      summary[year] = 0;
    }
    summary[year] += allocation;
  };

  contributionsTableData.forEach((commitment) => {
    commitment.data.forEach((item) => {
      addValueToSummary(dateContributionsSummary, item.date, item.value);
    });
  });

  distributionsTableData.forEach((commitment) => {
    commitment.data.forEach((item) => {
      addValueToSummary(dateDistributionsSummary, item.date, item.value);
    });
  });

  const calculateNetCashFlow = (contributions: CommitmentSummary, distributions: CommitmentSummary): CommitmentSummary => {
    const result: CommitmentSummary = {};
    for (const year in contributions) {
      if (contributions.hasOwnProperty(year) || distributions.hasOwnProperty(year)) {
        result[year] = (contributions[year] || 0) - (distributions[year] || 0);
      }
    }
    return result;
  };

  const netCashFlow = calculateNetCashFlow(dateContributionsSummary, dateDistributionsSummary);

  return (
    <MobileSumFooterWrap>
      <MobileRow>
        <MobileRowTitle>Net Cash Flow</MobileRowTitle>
      </MobileRow>

      {Object.entries(netCashFlow).map(([year, value]) => (
        <MobileRow key={year}>
          <MobileRowTitle>{year}</MobileRowTitle>
          <MobileRowValue>
            <FormattingTooltip zIndex={1000}>{value}</FormattingTooltip>
          </MobileRowValue>
        </MobileRow>
      ))}
    </MobileSumFooterWrap>
  );
};

const MobileSumFooterWrap = styled(MobileTableRowWrap)`
  background-color: ${({ theme }) => theme.layer[2]};
`;

export default MobileSumFooter;
