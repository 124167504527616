import { useResponsive } from '@/hooks/use-responsive';
import { useState } from 'react';
import styled from 'styled-components';
import FooterModal from '../../../footer';
import { ProgressBar } from '../../../progressBar';
import { Status } from '../../../status';
import { CommitmentError } from '../../../types';
import { valuationsSteps } from '../../constants';
import { commitmentsCodesStatusError, commitmentsStatusSuccess } from './constants';
import Table from './table/table';

interface CommitmentsProps {
  importSteps: typeof valuationsSteps;
  startOver: () => void;
  nextStep: () => void;
  tableData: any;
  commitmentError: CommitmentError[];
}

export const Commitments = ({ importSteps, startOver, nextStep, tableData, commitmentError }: CommitmentsProps) => {
  const { isMobile, isTablet } = useResponsive();

  const [showTable, setShowTable] = useState(false);

  return (
    <>
      <ImportWrapper>
        <ProgressBar importSteps={importSteps} />
        <Status
          errorsCount={commitmentError.length || null}
          matched={[{ count: tableData.length, text: 'Matched' }]}
          showTable={showTable}
          setShowTable={setShowTable}
          startOver={startOver}
          confirmButton={{ name: 'Save and Complete', onClick: nextStep, disabled: Boolean(commitmentError.length) }}
          success={commitmentsStatusSuccess}
          error={commitmentsCodesStatusError}
        />
      </ImportWrapper>
      {showTable || commitmentError?.length ? (
        <TableWrapper>
          <Table tableData={tableData} commitmentError={commitmentError} />
        </TableWrapper>
      ) : (
        <></>
      )}
      {commitmentError?.length || showTable ? (
        <FooterModal
          startOver={startOver}
          confirmButton={{ name: 'Save and Complete', onClick: nextStep, disabled: Boolean(commitmentError.length) }}
        />
      ) : (
        <></>
      )}
    </>
  );
};

const ImportWrapper = styled.div`
  margin-top: 30px;
  background: #ffffff;
  padding: 40px 60px;
  border: 1px solid #c7cbd2;
  border-radius: 10px;
`;

const TableWrapper = styled.div`
  margin-bottom: 100px;
`;
