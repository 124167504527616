import { EditTodoIcon } from '@/assets/icons/info-icons/editTodo';
import { AddButtonIcon } from '@/assets/static/icons/add-button-icon';
import { DeleteItemIcon } from '@/assets/static/icons/delete-item';
import { UploadFileIcon } from '@/assets/static/icons/upload-file';
import Button from '@/components/fat-basicComponents/button';
import { useResponsive } from '@/hooks/use-responsive';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';
import { SectionSelect, SectionZeroStateText, SectionZeroStateWrapper } from '..';
import { Section } from '../../edit-entity/section';

const ITEMS = ['Name', 'Individual Street Address', 'City', 'State', 'Zip', 'Email', 'Title of Signatory'];

interface AuthorizedSignatoriesSectionProps {
  saveScrollPostion: () => void;
  openModalWindow: (type: string) => void;
}

export const AuthorizedSignatoriesSection = ({ saveScrollPostion, openModalWindow }: AuthorizedSignatoriesSectionProps) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { isMobile } = useResponsive();

  const [showSectionZeroState, setShowSectionZeroState] = useState(false);
  const [selectedAction, setSelectedAction] = useState('Please Select');

  const openAddContactPage = () => {
    saveScrollPostion();
    navigate('addContact?fromSection=Authorized Signatories&backToTitle=Investor Documentation');
  };

  const openAddDocumentPage = () => {
    saveScrollPostion();
    navigate('addDocument?fromSection=Authorized Signatories&backToTitle=Investor Documentation');
  };

  if (showSectionZeroState) {
    return (
      <Section title="Authorized Signatories">
        <SectionZeroStateWrapper>
          <SectionZeroStateText>No information has been added yet.</SectionZeroStateText>
          <SectionSelect selected={selectedAction} setSelected={setSelectedAction} data={['Add contacts', 'Upload a file', 'Skip']} width="400px" />
          {selectedAction === 'Add contacts' && (
            <Button onClick={openAddContactPage} styleType="outline" icon={<AddButtonIcon fill={theme.font.action} />} size="md" width="max-content">
              Add
            </Button>
          )}
          {selectedAction === 'Upload a file' && (
            <Button
              onClick={openAddDocumentPage}
              styleType="outline"
              icon={<UploadFileIcon width={23} height={23} fill={theme.font.action} />}
              size="md"
              width="max-content"
            >
              Upload a file
            </Button>
          )}
        </SectionZeroStateWrapper>
      </Section>
    );
  }

  return (
    <Section title="Authorized Signatories">
      <AuthorizedSignatoriesSectionContent>
        <AuthorizedSignatoriesWrapper isMobile={isMobile}>
          <AuthorizedSignatoriesItemsWrapper>
            {ITEMS.map((item) => (
              <TwoColumnWrapper key={item} isMobile={isMobile}>
                <AuthorizedSignatoriesItem>{item}</AuthorizedSignatoriesItem>
                <AuthorizedSignatoriesItem isBold>MOCKED DATA</AuthorizedSignatoriesItem>
              </TwoColumnWrapper>
            ))}
          </AuthorizedSignatoriesItemsWrapper>
          <ButtonsWrapper id="editButton" isMobile={isMobile}>
            <DeleteIconWrapper isMobile={isMobile} onClick={() => openModalWindow('delete-dialog')}>
              <DeleteItemIcon width={23} height={23} fill={theme.context.error} />
            </DeleteIconWrapper>
            <EditIconWrapper isMobile={isMobile} onClick={openAddContactPage}>
              <EditTodoIcon />
            </EditIconWrapper>
          </ButtonsWrapper>
        </AuthorizedSignatoriesWrapper>
        <Divider />
        <Button onClick={openAddContactPage} styleType="outline" icon={<AddButtonIcon fill={theme.font.action} />} size="md" width="max-content">
          Add
        </Button>
      </AuthorizedSignatoriesSectionContent>
    </Section>
  );
};

const AuthorizedSignatoriesSectionContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const Divider = styled.div`
  height: 1px;
  width: 100%;
  margin: 20px 0;
  background-color: ${({ theme }) => theme.border.base};
`;

const AuthorizedSignatoriesWrapper = styled.div<{ isMobile: boolean }>`
  display: flex;
  align-items: center;
  gap: 15px;
  flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};
  padding: ${({ isMobile }) => (isMobile ? '0' : '20px')};
  transition: 0.3s ease-in-out;

  @media (hover: hover) {
    &:hover #editButton {
      opacity: 1;
    }

    &:hover {
      background-color: ${({ theme }) => theme.layer[12]};
    }
  }
`;

const AuthorizedSignatoriesItemsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const TwoColumnWrapper = styled.div<{ isMobile: boolean }>`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  width: ${({ isMobile }) => (isMobile ? '100%' : '60%')};
  padding: 2px 10px;
`;

const AuthorizedSignatoriesItem = styled.div<{ isBold?: boolean }>`
  font-family: Blinker;
  font-size: 16px;
  font-weight: ${({ isBold }) => (isBold ? 600 : 400)};
  line-height: 22.4px;
  color: ${({ theme }) => theme.font.base};
  padding: 4px;
`;

const ButtonsWrapper = styled.div<{ isMobile: boolean }>`
  display: flex;
  align-items: center;
  flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};
  width: ${({ isMobile }) => (isMobile ? '100%' : 'max-content')};
  gap: 10px;
  margin-left: ${({ isMobile }) => (isMobile ? '0' : 'auto')};
  opacity: ${({ isMobile }) => (isMobile ? '1' : '0')};
  transition: 0.3s ease-in-out;
`;

const EditIconWrapper = styled.div<{ isMobile: boolean }>`
  width: ${({ isMobile }) => (isMobile ? '100%' : '40px')};
  height: 40px;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.action.default};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const DeleteIconWrapper = styled.div<{ isMobile: boolean }>`
  width: ${({ isMobile }) => (isMobile ? '100%' : '40px')};
  height: 40px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: 1px solid ${({ theme }) => theme.context.error};
`;
