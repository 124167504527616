import * as React from 'react';

export const DeleteItemIcon = ({ ...props }: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={props.width || 24}
    height={props.height || 24}
    onClick={props.onClick || undefined}
    style={props?.style}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.999 9V19.002H8.00098V9H15.999ZM14.499 3H9.50098L8.52298 4.00199H5.00098V6H18.999V4.00199H15.501L14.499 3ZM18.003 7.00199H6.00298V19.002C6.00456 19.5314 6.21557 20.0387 6.58993 20.413C6.96428 20.7874 7.47156 20.9984 8.00098 21H15.999C16.5294 21 17.0383 20.7897 17.4139 20.4152C17.7896 20.0406 18.0014 19.5324 18.003 19.002V7.00199Z"
      fill={props.fill || '#D63B4B'}
    />
  </svg>
);
