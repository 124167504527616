import { StyleSheet, Text, View } from '@react-pdf/renderer';
import { ISetting } from '@/components/settings-page/settings-pages/firmSettings/types';
import { templateStyles } from './PDFTemplate';
import { getFontByKey } from './utils';

interface SectionScoreProps {
  score: number;
  templateStylingData: ISetting[];
}

export const SectionScore = ({
  score,
  templateStylingData
}: SectionScoreProps) => {
  const styles = StyleSheet.create({
    scoreWrap: {
      display: 'flex',
      flexDirection: 'row',
      gap: '10px',
      fontFamily: 'Sanchez',
      fontSize: '13px',
      color: Boolean(
        getFontByKey(templateStylingData, 'headerFontStyle').fontColor
      )
        ? `#${getFontByKey(templateStylingData, 'headerFontStyle').fontColor}`
        : '#35739C'
    }
  });

  if (score > 0) {
    return (
      <View style={styles.scoreWrap}>
        <Text>Score</Text>
        <View style={{ ...templateStyles.score, backgroundColor: '#3DBD4A' }}>
          <Text>{`+${score}`}</Text>
        </View>
      </View>
    );
  }
  if (score === 0) {
    return (
      <View style={styles.scoreWrap}>
        <Text>Score</Text>
        <View
          style={{
            ...templateStyles.score,
            backgroundColor: '#F4F5F6',
            color: '#474747',
            border: '1px solid #474747'
          }}
        >
          <Text>{score}</Text>
        </View>
      </View>
    );
  }
  if (score < 0) {
    return (
      <View style={styles.scoreWrap}>
        <Text>Score</Text>
        <View style={{ ...templateStyles.score, backgroundColor: '#D63B4B' }}>
          <Text>{score}</Text>
        </View>
      </View>
    );
  }
  return <></>;
};
