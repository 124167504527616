import aiMagicWand from '@/assets/icons/ai-suggested-icon.png';
import { getDocxImage, transparentDocxTableBorders } from '@/components/dueDiligence-page/DDR-DOCX/utils';
import * as docx from 'docx';
import { IDocumentRecordFieldEntity } from '../../types';

export const getAiSuggestedSection = async (fields: IDocumentRecordFieldEntity[], bodyFontColor: string) => {
  const aiSuggestedSectionData = [];

  const aiSuggestedIcon = await getDocxImage(aiMagicWand, '');

  const imageRun = new docx.ImageRun({
    data: aiSuggestedIcon ?? '',
    transformation: {
      width: 15,
      height: 15
    }
  });

  const textRun = new docx.TextRun({
    text: fields.length > 1 ? '* These fields are AI Suggested' : 'This field is AI Suggested',
    color: bodyFontColor
  });

  const table = new docx.Table({
    rows: [
      new docx.TableRow({
        children: [
          new docx.TableCell({
            children: [
              new docx.Paragraph({
                children: [imageRun]
              })
            ],
            borders: {
              top: { style: docx.BorderStyle.SINGLE, size: 0, color: 'FFFFFF' },
              bottom: { style: docx.BorderStyle.SINGLE, size: 0, color: 'FFFFFF' },
              left: { style: docx.BorderStyle.SINGLE, size: 0, color: 'FFFFFF' },
              right: { style: docx.BorderStyle.SINGLE, size: 6, color: 'C7CBD2' }
            },
            margins: {
              top: 100,
              bottom: 100,
              right: 100
            }
          }),
          new docx.TableCell({
            children: [
              new docx.Paragraph({
                children: [textRun]
              })
            ],
            borders: transparentDocxTableBorders,
            margins: {
              top: 100,
              bottom: 100,
              left: 100
            }
          })
        ]
      })
    ]
  });

  aiSuggestedSectionData.push(new docx.Paragraph({}));
  aiSuggestedSectionData.push(table);
  aiSuggestedSectionData.push(new docx.Paragraph({}));

  return aiSuggestedSectionData;
};
