import React from 'react';
import styled from 'styled-components';

import { ITypeModalWindow } from '../../../../types/opportunity/proposed';
import { ButtonProps } from '../../../buttons';

interface ConfirmationModalWindowProps {
  changeField: (
    field: string
  ) => (value: string | boolean | ITypeModalWindow) => void;
}

const Confirmation: React.FC<ConfirmationModalWindowProps> = ({
  changeField
}) => {
  return (
    <>
      <ModalWindowText fontWeight={600} fontSize={32} padding={'15px 0 0 0'}>
        Confirmation
      </ModalWindowText>
      <ModalWindowText fontWeight={400} fontSize={22} padding={'22px 0'}>
        This allocation has been terminated. You can find the entity in this
        investment’s allocation history.
      </ModalWindowText>
      <ModalWindowButton>
        <ButtonProps
          variant="contained"
          fullWidth
          size="medium"
          onClick={() => changeField('isOpen')(false)}
          label="Close"
        />
      </ModalWindowButton>
    </>
  );
};

export default Confirmation;
type ModalWindowTextProps = {
  fontWeight: number;
  fontSize: number;
  padding: string;
};
const ModalWindowText = styled.span<ModalWindowTextProps>`
  text-align: center;
  margin: 0;
  font-size: ${({ fontSize }: ModalWindowTextProps) => fontSize}px;
  font-weight: ${({ fontWeight }: ModalWindowTextProps) => fontWeight};
  padding: ${({ padding }: ModalWindowTextProps) => padding};
`;
const ModalWindowButton = styled.div`
  background: #868a90;
  margin: 48px auto 0;
  border-radius: 8px;
`;
