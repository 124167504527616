import styled from 'styled-components';

import FormattingTooltip from '@/components/basicComponents/tooltip/dollarsTooltip';
import { AnchorPopover } from '@/components/table/anchor-drop-down/fat-index';
import { InfoListClient } from '@/components/table/anchor-drop-down/infoListClient';
import DropdownActions from '@/components/table/fat-dropdownActions';
import NoResults from '@/components/table/noResults';
import RedirectTitleCell from '@/components/table/redirectTitleCell';
import { TableBodyWrapper, TableRowCell, TableRowWrapper, TextCell } from '@/components/table/styles';
import { formatCash } from '@/utils/formatCash';
import guid from '@/utils/guid';
import { IRowData } from '../types';

interface BodyRowProps {
  clientsRows: IRowData[];
  handleFamilyClick: (id: string) => void;
  columns: any;
}

export const TableBodyClient = ({ clientsRows, handleFamilyClick, columns }: BodyRowProps) => {
  if (!clientsRows.length) {
    return <NoResults />;
  }

  return (
    <TableBodyWrapper>
      {clientsRows.map((row) => (
        <TableRowWrapper key={guid()}>
          <TableRowCell width={columns[0].width} onClick={() => handleFamilyClick(row.name)}>
            <ClientAdvisorColumn>
              <AnchorPopover
                title={row.name}
                hyperTitle={() => handleFamilyClick(row.name)}
                rowBody={<RedirectTitleCell>{row.name}</RedirectTitleCell>}
              >
                <></>
              </AnchorPopover>
              <TextCell>{row.advisor?.initials}</TextCell>
            </ClientAdvisorColumn>
          </TableRowCell>
          <TableRowCell width={columns[1].width}>
            <AnchorPopover
              title={row.name}
              hyperTitle={() => handleFamilyClick(row.name)}
              onRedirect={() => handleFamilyClick(row.name)}
              rowBody={<RedirectTitleCell>{formatCash(+row.totalClientValue)}</RedirectTitleCell>}
            >
              <InfoListClient title={row.name} onRedirect={() => handleFamilyClick(row.name)} data={row.clientMoreInformation} />
            </AnchorPopover>
          </TableRowCell>
          <TableRowCell width={columns[2].width}>
            <TextCell>
              <FormattingTooltip zIndex={1000}>{+row.privateEquityCapacity}</FormattingTooltip>
            </TextCell>
          </TableRowCell>
          <TableRowCell width={columns[3].width}>
            <TextCell>
              <FormattingTooltip zIndex={1000}>{+row.privateCreditCapacity}</FormattingTooltip>
            </TextCell>
          </TableRowCell>
          <TableRowCell width={columns[4].width}>
            <TextCell>
              <FormattingTooltip zIndex={1000}>{+row.privateRealAssetsCapacity}</FormattingTooltip>
            </TextCell>
          </TableRowCell>
          <TableRowCell width={columns[5].width}>
            <TextCell>
              <FormattingTooltip zIndex={1000}>{+row.privateOverallCapacity}</FormattingTooltip>
            </TextCell>
          </TableRowCell>
          <TableRowCell width={columns[6].width}>
            <DropdownActions
              items={[
                {
                  name: 'View Client',
                  onClick: () => handleFamilyClick(row.name)
                }
              ]}
            />
          </TableRowCell>
        </TableRowWrapper>
      ))}
    </TableBodyWrapper>
  );
};

export const ClientAdvisorColumn = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;
