import { RedStarIcon } from '@/assets/icons/info-icons/redStar';
import { AddButtonIcon } from '@/assets/static/icons/add-button-icon';
import CheckBox from '@/components/basicComponents/checkbox';
import FilterSelect from '@/components/basicComponents/select';
import Button from '@/components/fat-basicComponents/button';
import { FormFooter } from '@/components/fat-basicComponents/formFooter';
import Input from '@/components/fat-basicComponents/input';
import Label from '@/components/fat-basicComponents/label';
import Header from '@/components/fat-header';
import { GoBackButton } from '@/components/fat-header/goBackButton';
import { PageTitle } from '@/components/fat-header/pageTitle';
import { useResponsive } from '@/hooks/use-responsive';
import { useGoBack } from '@/hooks/useGoBack';
import { MainWrap, PaddingWrap } from '@/styles/common';
import { Controller, useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';

type SectionNameType = 'Authorized Signatories' | 'Reporting Recipients';

const pageProperties: Record<SectionNameType, { title: string }> = {
  ['Authorized Signatories']: {
    title: 'Authorized Signatory'
  },
  ['Reporting Recipients']: {
    title: 'Reporting Recipients'
  }
};

export const AddContactPage = () => {
  const { isMobile } = useResponsive();
  const goBack = useGoBack();
  const theme = useTheme();
  const {
    handleSubmit,
    getValues,
    setValue,
    control,
    setError,
    formState: { errors, isValid },
    reset,
    watch
  } = useForm();

  const [searchParams, setSearchParams] = useSearchParams();

  const backToTitle = searchParams.get('backToTitle') || '';
  const sectionName = searchParams.get('fromSection') || '';

  const onSubmit = (data: any) => {
    console.log(data);
  };

  return (
    <MainWrap>
      <Header modalControl={<GoBackButton handleClose={() => goBack({ fallBack: '/investors' })} backToTitle={backToTitle} />} />
      <PageTitle title={pageProperties[sectionName as SectionNameType]?.title || ''} />
      <PaddingWrap>
        <AuthorizedSignatoryForm id="authorizedSignatoryForm" onSubmit={handleSubmit(onSubmit, () => window.scrollTo(0, 0))}>
          <AuthorizedSignatoryWrapper isMobile={isMobile}>
            <TwoColumnWrapper isMobile={isMobile}>
              <Controller
                name="name"
                control={control}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <Input
                    label="Name"
                    value={value}
                    onChange={(e) => {
                      onChange(e.target.value);
                    }}
                    errorText={error?.message}
                    size="md"
                  />
                )}
              />
              <Controller
                name="name"
                control={control}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <Input
                    label="Individual Street Address"
                    value={value}
                    onChange={(e) => {
                      onChange(e.target.value);
                    }}
                    errorText={error?.message}
                    size="md"
                  />
                )}
              />
            </TwoColumnWrapper>
            <ThreeColumnWrapper isMobile={isMobile}>
              <Controller
                name="name"
                control={control}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <Input
                    label="City"
                    value={value}
                    onChange={(e) => {
                      onChange(e.target.value);
                    }}
                    errorText={error?.message}
                    size="md"
                  />
                )}
              />
              <div>
                <Label size="md">State</Label>
                <Controller
                  name="entityName"
                  control={control}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <>
                      <CustomSelect
                        selected={'Select'}
                        setSelected={onChange}
                        data={['Select']}
                        width="100%"
                        additionalOption={
                          <AdditionalOptionWrapper>
                            <Button onClick={() => {}} styleType="outline" icon={<AddButtonIcon fill={theme.font.action} />} width="max-content">
                              Add New Contact
                            </Button>
                          </AdditionalOptionWrapper>
                        }
                      />
                      {error?.message && <ErrorText>{error.message}</ErrorText>}
                    </>
                  )}
                />
              </div>
              <Controller
                name="name"
                control={control}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <Input
                    label="Zip"
                    value={value}
                    onChange={(e) => {
                      onChange(e.target.value);
                    }}
                    errorText={error?.message}
                    size="md"
                  />
                )}
              />
            </ThreeColumnWrapper>
            <TwoColumnWrapper isMobile={isMobile}>
              <Controller
                name="name"
                control={control}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <Input
                    label="Email"
                    value={value}
                    onChange={(e) => {
                      onChange(e.target.value);
                    }}
                    errorText={error?.message}
                    size="md"
                  />
                )}
              />
            </TwoColumnWrapper>
          </AuthorizedSignatoryWrapper>
          <AuthorizedSignatoryWrapper isMobile={isMobile}>
            <ThreeColumnWrapper isMobile={isMobile}>
              <div>
                <Label size="md">Contact</Label>
                <Controller
                  name="entityName"
                  control={control}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <>
                      <CustomSelect
                        selected={'Select'}
                        setSelected={onChange}
                        data={['Select']}
                        width="100%"
                        additionalOption={
                          <AdditionalOptionWrapper>
                            <Button onClick={() => {}} styleType="outline" icon={<AddButtonIcon fill={theme.font.action} />} width="max-content">
                              Add New Contact
                            </Button>
                          </AdditionalOptionWrapper>
                        }
                      />
                      {error?.message && <ErrorText>{error.message}</ErrorText>}
                    </>
                  )}
                />
              </div>
            </ThreeColumnWrapper>
          </AuthorizedSignatoryWrapper>
          <AuthorizedSignatoryWrapper isMobile={isMobile}>
            <TwoColumnWrapper isMobile={isMobile}>
              <Controller
                name="name"
                control={control}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <Input
                    label="Title of Signatory"
                    isRequiredField
                    value={value}
                    onChange={(e) => {
                      onChange(e.target.value);
                    }}
                    errorText={error?.message}
                    size="md"
                  />
                )}
              />
            </TwoColumnWrapper>
          </AuthorizedSignatoryWrapper>
          <AuthorizedSignatoryWrapper isMobile={isMobile}>
            <PermissionCheckboxWrapper>
              <div>
                <RedStarIcon width={12} fill={theme.context.error} />
              </div>
              <Controller
                name="correspondenceAddress"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <CheckboxWrapper id="permissionCheckbox" onClick={onChange}>
                    <CheckBox id="permissionCheckbox" isActive={value} /> I hereby authorize the Fund to send copies of the following correspondence
                    to the person(s) or company(ies) named above.
                  </CheckboxWrapper>
                )}
              />
            </PermissionCheckboxWrapper>
          </AuthorizedSignatoryWrapper>
          <AuthorizedSignatoryWrapper isMobile={isMobile}>
            <div>
              <PermissionCheckboxWrapper>
                <div>
                  <RedStarIcon width={12} fill={theme.context.error} />
                </div>
                I hereby authorize the Fund to send copies of the following correspondence to the person(s) or company(ies) named above.
              </PermissionCheckboxWrapper>
              <PermissionCheckboxWrapper isMobile={isMobile}>
                <Controller
                  name="correspondenceAddress"
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <CheckboxWrapper id="monthlyCheckbox" onClick={onChange}>
                      <CheckBox id="monthlyCheckbox" isActive={value} />
                      Monthly/Quarterly Statements/Reports
                    </CheckboxWrapper>
                  )}
                />
                <Controller
                  name="correspondenceAddress"
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <CheckboxWrapper id="annualReportsCheckbox" onClick={onChange}>
                      <CheckBox id="annualReportsCheckbox" isActive={value} />
                      Annual Reports
                    </CheckboxWrapper>
                  )}
                />
                <Controller
                  name="correspondenceAddress"
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <CheckboxWrapper id="k1Checkbox" onClick={onChange}>
                      <CheckBox id="k1Checkbox" isActive={value} />
                      K-1
                    </CheckboxWrapper>
                  )}
                />
              </PermissionCheckboxWrapper>
            </div>
          </AuthorizedSignatoryWrapper>
          <FormFooter onCancel={() => goBack({ fallBack: '/investors' })} disableSaveButton={false} />
        </AuthorizedSignatoryForm>
      </PaddingWrap>
    </MainWrap>
  );
};

const AuthorizedSignatoryForm = styled.form`
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const AuthorizedSignatoryWrapper = styled.div<{ isMobile: boolean }>`
  padding: ${({ isMobile }) => (isMobile ? '20px' : '40px')};
  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.border.base};
  background-color: ${({ theme }) => theme.layer[1]};
  display: flex;
  flex-direction: column;
  gap: ${({ isMobile }) => (isMobile ? '15px' : '25px')};
`;

const TwoColumnWrapper = styled.div<{ isMobile: boolean }>`
  display: grid;
  grid-template-columns: ${({ isMobile }) => (isMobile ? '1fr' : '1fr 1fr')};
  gap: ${({ isMobile }) => (isMobile ? '15px' : '30px')};
`;

const ThreeColumnWrapper = styled.div<{ isMobile: boolean }>`
  display: grid;
  grid-template-columns: ${({ isMobile }) => (isMobile ? '1fr' : '1fr 1fr 1fr')};
  gap: ${({ isMobile }) => (isMobile ? '15px' : '30px')};
`;

const CustomSelect = styled(FilterSelect)`
  background-color: ${({ theme }) => theme.layer[1]};
  border: 1px solid ${({ theme }) => theme.border.base};
  padding: 10px;
  border-radius: 4px;

  #titleSelect {
    text-transform: none;
    font-family: Blinker;
    font-size: 16px;
    font-weight: 400;
    line-height: 22.4px;
  }

  #dropDownSelect {
    top: 50px;
  }
`;

const AdditionalOptionWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 6px;
  cursor: pointer;
`;

const ErrorText = styled.p`
  color: ${({ theme }) => theme.context.error};
  font-family: Blinker;
  font-style: normal;
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;
`;

const PermissionCheckboxWrapper = styled.div<{ isMobile?: boolean }>`
  display: flex;
  align-items: ${({ isMobile }) => (isMobile ? 'flex-start' : 'center')};
  flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};
  gap: ${({ isMobile }) => (isMobile ? '15px' : '20px')};
  padding: 15px 0 10px 0;
  color: ${({ theme }) => theme.font.base};
`;

const CheckboxWrapper = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 9px;
  color: ${({ theme }) => theme.font.base};
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;
`;
