import Textarea from '@/components/basicComponents/textarea';
import { FormFooter } from '@/components/fat-basicComponents/formFooter';
import Label from '@/components/fat-basicComponents/label';
import Header from '@/components/fat-header';
import { GoBackButton } from '@/components/fat-header/goBackButton';
import { PageTitle } from '@/components/fat-header/pageTitle';
import { useResponsive } from '@/hooks/use-responsive';
import { useGoBack } from '@/hooks/useGoBack';
import { MainWrap, PaddingWrap } from '@/styles/common';
import { useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';

export const TaxDetailsPage = () => {
  const { isMobile } = useResponsive();
  const theme = useTheme();
  const goBack = useGoBack();
  const {
    handleSubmit,
    getValues,
    setValue,
    control,
    setError,
    formState: { errors, isValid },
    reset,
    watch
  } = useForm();

  const [searchParams, setSearchParams] = useSearchParams();

  const backToTitle = searchParams.get('backToTitle') || '';

  const onSubmit = (data: any) => {
    console.log(data);
  };

  return (
    <MainWrap>
      <Header modalControl={<GoBackButton handleClose={() => goBack({ fallBack: '/investors' })} backToTitle={backToTitle} />} />
      <PageTitle title="Tax Details" />
      <PaddingWrap>
        <form id="taxDetailsForm" onSubmit={handleSubmit(onSubmit, () => window.scrollTo(0, 0))}>
          <TaxDetailsWrapper isMobile={isMobile}>
            <Label size="md">If applicable, please indicate below the basis on which the investor is exempt from U.S. federal income taxation:</Label>
            <Textarea value="" setValue={() => {}} />
          </TaxDetailsWrapper>
          <FormFooter onCancel={() => goBack({ fallBack: '/investors' })} disableSaveButton={false} showRequiredFieldsWarning={false} />
        </form>
      </PaddingWrap>
    </MainWrap>
  );
};

const TaxDetailsWrapper = styled.div<{ isMobile: boolean }>`
  margin-top: 50px;
  padding: ${({ isMobile }) => (isMobile ? '20px' : '40px')};
  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.border.base};
  background-color: ${({ theme }) => theme.layer[1]};
  display: flex;
  flex-direction: column;
`;
