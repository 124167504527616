import RedirectTitleCell from '@/components/table/redirectTitleCell';
import { CellWrapper } from '@/components/table/styles';
import { useResponsive } from '@/hooks/use-responsive';

interface ClientsAdvisorProps {
  advisor: string;
  familyName: string;
  onRedirect: () => void;
}

const ClientsAdvisor = ({ familyName, advisor, onRedirect }: ClientsAdvisorProps) => {
  const { isMobile } = useResponsive();
  return (
    <CellWrapper flexDirection={'column'}>
      <RedirectTitleCell id="actionCurioText" onClick={onRedirect}>
        {familyName}
      </RedirectTitleCell>
      {/* <TextCell>{advisor}</TextCell> */}
    </CellWrapper>
  );
};

export default ClientsAdvisor;
