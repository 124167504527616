import Label from '@/components/basicComponents/label';
import RadioButton from '@/components/basicComponents/radioButton';
import Textarea from '@/components/basicComponents/textarea';
import { ChangeEvent } from 'react';
import styled from 'styled-components';

interface PromptTemplateProps {
  title: string;
  tooltip: string;
  value: string;
  setValue: (value: string) => void;
  selectedOption: string;
  setSelectedOption: (option: string) => void;
  isValid: boolean;
  errorMessage: string;
}

const radioButtonItems = ['Use default', 'Customize'];

export const PromptTemplate = ({
  title,
  tooltip,
  value,
  setValue,
  selectedOption,
  setSelectedOption,
  isValid,
  errorMessage
}: PromptTemplateProps) => {
  const handleRadioChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSelectedOption(event.target.value);
  };

  return (
    <div>
      <Label tooltip={tooltip} tooltipWidth={250} required>
        {title}
      </Label>
      <RadioButtonsWrap>
        {radioButtonItems.map((item, index) => (
          <RadioButton name={title} radioButtonText={item} selectedOption={selectedOption} handleRadioChange={handleRadioChange} />
        ))}
      </RadioButtonsWrap>
      {selectedOption === 'Customize' && (
        <Textarea textareaHeight="110px" maxLength={1000} value={value} setValue={setValue} errorMessage={!isValid ? errorMessage : ''} />
      )}
    </div>
  );
};

const RadioButtonsWrap = styled.label`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
`;
