import { EditIcon } from '@/assets/icons/edit';
import FormattingTooltip from '@/components/basicComponents/tooltip/dollarsTooltip';
import CircleButtonRow from '@/components/table/circleButtonRow';
import RedirectTitleCell from '@/components/table/redirectTitleCell';
import { CellWrapper, TextCell } from '@/components/table/styles';
import styled, { useTheme } from 'styled-components';

interface AmountProps {
  amount: number;
  openWindow: () => void;
  showChangeAmountButton: boolean;
  isMobile?: boolean;
}

const Amount = ({ amount, openWindow, showChangeAmountButton, isMobile = false }: AmountProps) => {
  const theme = useTheme();

  return (
    <>
      {showChangeAmountButton ? (
        <CellWrapper alignItems={'center'} onClick={openWindow}>
          <RedirectTitleCell id="actionCurioText">
            <FormattingTooltip zIndex={1000}>{amount}</FormattingTooltip>
          </RedirectTitleCell>
          <CircleButtonRow background={'transparent'}>
            <WrapperIcon>
              <EditIcon fill={theme.font.action} />
            </WrapperIcon>
          </CircleButtonRow>
        </CellWrapper>
      ) : (
        <TextCell>
          <FormattingTooltip zIndex={1000}>{amount}</FormattingTooltip>
        </TextCell>
      )}
    </>
  );
};

export default Amount;

const WrapperIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  transform: scale(0.85);
`;
