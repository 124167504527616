import Label from '@/components/basicComponents/label';
import FilterSelect from '@/components/basicComponents/select';
import Textarea from '@/components/basicComponents/textarea';
import { AddButton } from '@/components/fat-dealPipeline-page/fat-modify/addButton';
import { Section } from '@/components/fat-dealPipeline-page/fat-modify/section';
import { IListData } from '@/components/fat-dealPipeline-page/fat-modify/types';
import { addStrategies, delStrategies, onChangeStrategies } from '@/components/fat-dealPipeline-page/fat-modify/utils';
import { Select, ToggleCircle } from '@/components/fat-form-field';
import { defaultSelectValue } from '@/components/opportunities-entity/modify/constants';
import { blinkingFrame } from '@/components/table/styles';
import { useResponsive } from '@/hooks/use-responsive';
import { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';

interface AllocationSettingsSectionProps {
  strategies: IListData;
  setStrategies: Dispatch<SetStateAction<IListData>>;
  tags: IListData;
  setTags: Dispatch<SetStateAction<IListData>>;
  control: any;
  openModalWindow: (type: string) => void;
  descriptionValue: string;
  setDescriptionValue: Dispatch<SetStateAction<string>>;
}

export const AllocationSettingsSection = ({
  strategies,
  setStrategies,
  tags,
  setTags,
  control,
  openModalWindow,
  descriptionValue,
  setDescriptionValue
}: AllocationSettingsSectionProps) => {
  const { isMobile } = useResponsive();

  return (
    <Section title="Allocation Settings">
      <AllocationSettingsSectionWrap>
        <Line isMobile={isMobile}>
          <Space>
            <ToggleCircleWrap>
              <ToggleCircle nameField="custodied" control={control} label="Custodied" />
            </ToggleCircleWrap>
          </Space>
          <Space>
            <ToggleCircleWrap>
              <ToggleCircle
                nameField="ubtiBlocker"
                control={control}
                label="UBTI Blocker"
                tooltip="Indicate whether there is a UBTI Blocker structure in place"
                tooltipWidth={300}
              />
            </ToggleCircleWrap>
          </Space>
          <Space>
            <Select
              title={'Impact'}
              nameField={'sri'}
              control={control}
              rules={{
                required: false
              }}
              selects={[defaultSelectValue, 'Yes', 'No']}
            />
          </Space>
        </Line>
        <Line isMobile={isMobile}>
          <StrategiesWrap>
            <Label required={false} tooltip={'Used to categorize investments and useful for portfolio construction.'} tooltipWidth={300}>
              Strategies
            </Label>
            {strategies.chosen.map((el, index) => (
              <StrategiesItemWrap key={`strategies-${index}`} loading={index === strategies.chosen.length - 1 && strategies.duplicate ? 1 : 0}>
                <CustomFilterSelect
                  data={strategies.list}
                  selected={strategies.chosen[index]}
                  setSelected={(value) => onChangeStrategies(value, index, strategies, setStrategies)}
                  disabled={
                    strategies.chosen[strategies.chosen.length - 1] !== el || strategies.chosen[strategies.chosen.length - 1] === strategies.duplicate
                  }
                  width="100%"
                  withSearch
                  fontSize="16px"
                />
                <DelItemWrap onClick={() => delStrategies(el, index, strategies, setStrategies)}>
                  <img src="/static/icons/blue-cross.svg" alt="cross" />
                </DelItemWrap>
              </StrategiesItemWrap>
            ))}
            {strategies.list.length && strategies.chosen[strategies.chosen.length - 1] !== 'None' ? (
              <AddButton text="Add" onClick={() => addStrategies(strategies, setStrategies)} />
            ) : (
              <AddButton text="Create New" onClick={() => openModalWindow('create-strategy')} />
            )}
          </StrategiesWrap>
          <StrategiesWrap>
            <Label
              required={false}
              tooltip={'Call out fund structure, ESG focus, or any other common attributes across different funds.'}
              tooltipWidth={300}
            >
              Tags
            </Label>
            {tags.chosen.map((el, index) => (
              <StrategiesItemWrap key={`tags-${index}`} loading={index === tags.chosen.length - 1 && tags.duplicate ? 1 : 0}>
                <CustomFilterSelect
                  data={tags.list}
                  selected={tags.chosen[index]}
                  setSelected={(value) => onChangeStrategies(value, index, tags, setTags)}
                  disabled={tags.chosen[tags.chosen.length - 1] !== el || tags.chosen[tags.chosen.length - 1] === tags.duplicate}
                  width="100%"
                  withSearch
                  fontSize="16px"
                />
                <DelItemWrap onClick={() => delStrategies(el, index, tags, setTags)}>
                  <img src="/static/icons/blue-cross.svg" alt="cross" />
                </DelItemWrap>
              </StrategiesItemWrap>
            ))}
            {tags.list.length && tags.chosen[tags.chosen.length - 1] !== 'None' ? (
              <AddButton text="Add" onClick={() => addStrategies(tags, setTags)} />
            ) : (
              <AddButton text="Create New" onClick={() => openModalWindow('create-tag')} />
            )}
          </StrategiesWrap>
        </Line>
        <Line isMobile={isMobile}>
          <DescriptionWrapper>
            <Label required={false}>Description</Label>
            <Textarea value={descriptionValue} setValue={setDescriptionValue} maxLength={250} textareaHeight={isMobile ? '110px' : '66px'} />
          </DescriptionWrapper>
        </Line>
      </AllocationSettingsSectionWrap>
    </Section>
  );
};

const AllocationSettingsSectionWrap = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 20px;
`;

const Space = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
`;

const Line = styled.span<{ isMobile: boolean; width50?: boolean }>`
  display: flex;
  justify-content: space-between;
  flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};
  width: ${({ width50, isMobile }) => (width50 && !isMobile ? '50%' : '100%')};
  gap: 30px;
  padding-right: ${({ width50, isMobile }) => (width50 && !isMobile ? '15px' : '0')};
  margin-top: 4px;
`;

const ToggleCircleWrap = styled.div`
  display: flex;
  justify-content: flex-start;
`;

const CustomFilterSelect = styled(FilterSelect)`
  padding: 10px 12px;
  border-radius: 5px;

  #titleSelect {
    text-transform: none;
    font-family: Blinker;
    font-size: 16px;
    font-weight: 400;
    line-height: 22.4px;
  }
`;

const StrategiesWrap = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

const StrategiesItemWrap = styled.div<{ loading?: boolean | number }>`
  display: flex;
  width: 100%;
  align-items: center;
  margin-bottom: 10px;
  position: relative;

  opacity: ${({ loading }) => (loading ? 0.5 : 1)};
  pointer-events: ${({ loading }) => (loading ? 'none' : 'auto')};
  animation: ${({ loading }) => (loading ? blinkingFrame : null)} 1.5s infinite;
`;

const DelItemWrap = styled.div`
  position: relative;
  top: 3px;
  padding-left: 15px;
  cursor: pointer;
`;

const DescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
