import { ArrowDown } from '@/assets/icons/info-icons/arrowDown';
import { PlusIcon } from '@/assets/icons/info-icons/plus';
import { DocumentIcon } from '@/assets/static/icons/document';
import { ArrowUp } from '@/assets/static/icons/todo-page/arrow-up';
import RadioButton from '@/components/basicComponents/radioButton';
import { IFile } from '@/components/dueDiligence-page/dueDiligenceRecord';
import { DropFileArea } from '@/components/dueDiligence-page/dueDiligenceRecord/dropFileArea';
import { Loader } from '@/components/loaders/loader';
import { ChangeEvent, Dispatch, SetStateAction, useState } from 'react';
import styled, { useTheme } from 'styled-components';

interface FileSelectorProps {
  generateAnswersLoading?: boolean;
  files: IFile[];
  selectedFile: string;
  setSelectedFile: Dispatch<SetStateAction<string>>;
  showRadioButton: boolean;
  showDragonDrop: boolean;
  setShowDragonDrop: Dispatch<SetStateAction<boolean>>;
  handleUploadFile: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onDropHandler: (e: React.DragEvent<HTMLDivElement>) => void;
  drag: boolean;
  setDrag: Dispatch<SetStateAction<boolean>>;
  trialMode: boolean;
}

export const FileSelector = ({
  generateAnswersLoading = false,
  files,
  showDragonDrop,
  setShowDragonDrop,
  handleUploadFile,
  onDropHandler,
  drag,
  setDrag,
  selectedFile,
  setSelectedFile,
  showRadioButton,
  trialMode
}: FileSelectorProps) => {
  const theme = useTheme();

  const [isDropDownOpen, setIsDropDownOpen] = useState(true);

  const handleRadioChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSelectedFile(event.target.value);
  };

  return (
    <>
      <SelectedDocument onClick={() => setIsDropDownOpen(!isDropDownOpen)}>
        Documents Selected for AI Assistant
        {isDropDownOpen ? (
          <ArrowUp fill={theme.font.action} />
        ) : (
          <ArrowDown style={{ width: '28px', height: '28px', padding: '7px' }} fill={theme.font.action} />
        )}
      </SelectedDocument>
      {isDropDownOpen && (
        <>
          <UploadedDocumnts>
            {files.map((file, index) => (
              <UploadedDocumentItem disabled={Boolean(generateAnswersLoading && selectedFile)} key={file.name + index} id={'input'}>
                <RadioButtonWrapper>
                  <RadioButton
                    name="option"
                    radioButtonText={file.name}
                    selectedOption={selectedFile}
                    handleRadioChange={file?.loading || generateAnswersLoading ? () => null : handleRadioChange}
                    icon={<DocumentIcon fill={theme.font.base} />}
                    disabled={Boolean(generateAnswersLoading && selectedFile)}
                    showRadioButton={showRadioButton}
                  />
                </RadioButtonWrapper>
                {file?.loading && !file.investmentId && (
                  <LoaderWrap>
                    <CustomLoader size={17} />
                    Processing
                  </LoaderWrap>
                )}
              </UploadedDocumentItem>
            ))}
          </UploadedDocumnts>
          {!showDragonDrop && Boolean(files.length && !trialMode) && (
            <Browse onClick={() => setShowDragonDrop(true)}>
              <PlusIcon width={19} height={19} fill={theme.font.action} />
              {'Add More Files'}
            </Browse>
          )}
          {showDragonDrop && (
            <DropFileArea
              id={'handle-upload-file'}
              handleUploadFile={handleUploadFile}
              onDropHandler={onDropHandler}
              drag={drag}
              setDrag={setDrag}
              onClose={() => setShowDragonDrop(false)}
              withIcon
            />
          )}
        </>
      )}
    </>
  );
};

const SelectedDocument = styled.div`
  margin: 0 20px;
  padding: 10px 0;
  border-bottom: 1px solid ${({ theme }) => theme.border.base};
  font-weight: 400;
  font-size: 16px;
  color: ${({ theme }) => theme.font.weak};
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
`;

const UploadedDocumnts = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 15px 20px 0 35px;
  margin-bottom: 5px;
  max-height: 120px;
  overflow-y: auto;
  font-weight: 600;
  font-size: 16px;
`;

const FileName = styled.div`
  margin-left: 4px;
`;

const RadioButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const UploadedDocumentItem = styled.div<{ disabled?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
`;

const LoaderWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  font-size: 13px;
  font-weight: 400;
  font-family: Blinker;
  color: ${({ theme }) => theme.font.base};
`;

const CustomLoader = styled(Loader)`
  padding: 0;
`;

const ImagePlus = styled.img`
  height: 20px;
  width: 20px;
`;

const Browse = styled.div`
  font-weight: 600;
  color: ${({ theme }) => theme.font.action};
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 4.5px;
  margin-top: 10px;
  padding: 0 20px;
`;
