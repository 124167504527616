import { EditIcon } from '@/assets/icons/edit';
import BasicTooltip from '@/components/basicComponents/tooltip';
import { useStatusData } from '@/hooks/useStatusData';
import styled, { useTheme } from 'styled-components';
import { DrawdownTargetPercentagesWithSubAssetClassesDto } from '../../../../fat-pacingTab/types';
import TableRow from './tableRow';

interface TableProps {
  drawdownData: DrawdownTargetPercentagesWithSubAssetClassesDto[];
  evergreenData: DrawdownTargetPercentagesWithSubAssetClassesDto[];
  drawdownTotals: { portfolioPercentage: number; privateCapitalPercentage: number };
  evergreenTotals: { portfolioPercentage: number; privateCapitalPercentage: number };
  showEditButton?: boolean;
  openAssetClassTargetsPage?: () => void;
}

const Table = ({ drawdownData, evergreenData, drawdownTotals, evergreenTotals, showEditButton = false, openAssetClassTargetsPage }: TableProps) => {
  const theme = useTheme();
  const { data: statusData } = useStatusData();

  return (
    <>
      {!!(showEditButton && openAssetClassTargetsPage) && (
        <EditIconWrap onClick={openAssetClassTargetsPage}>
          <EditButton>
            <EditIcon fill={theme.font.action} />
            Edit
          </EditButton>
        </EditIconWrap>
      )}
      <TableHeaderRow>
        <TableColumn width="50%">
          <AssetClassHeaderItem>ASSET CLASS</AssetClassHeaderItem>
        </TableColumn>
        <TableColumn width="25%">
          <PortfolioHeaderItem>Portfolio %</PortfolioHeaderItem>
        </TableColumn>
        <TableColumn width="25%">
          <PcHeaderItem>PC %</PcHeaderItem>
        </TableColumn>
      </TableHeaderRow>
      <TableBodyWrapper>
        <TableBody>
          <TableSubHeader>
            <PortfolioWrapper withBgColor>
              <CellKey bold>Drawdown</CellKey>
              <CellValue bold>{drawdownTotals.portfolioPercentage.toFixed(2)}%</CellValue>
            </PortfolioWrapper>
            <PrivateCapitalPercentage withBgColor>
              <CellValue color={theme.font.weak}>{drawdownTotals.privateCapitalPercentage.toFixed(2)}%</CellValue>
            </PrivateCapitalPercentage>
          </TableSubHeader>
          {drawdownData.map((item) => (
            <TableRow key={item.id} rowData={item} targetSubAssetClasses={statusData?.target_sub_asset_classes} />
          ))}
        </TableBody>
        {evergreenTotals.portfolioPercentage || evergreenTotals.privateCapitalPercentage ? (
          <>
            <TableBody>
              <TableSubHeader>
                <PortfolioWrapper withBgColor>
                  <CellKey bold>Evergreen</CellKey>
                  <CellValue bold>{evergreenTotals.portfolioPercentage.toFixed(2)}%</CellValue>
                </PortfolioWrapper>
                <PrivateCapitalPercentage withBgColor>
                  <CellValue color={theme.font.weak}>{evergreenTotals.privateCapitalPercentage.toFixed(2)}%</CellValue>
                </PrivateCapitalPercentage>
              </TableSubHeader>
              {evergreenData.map((item) => (
                <TableRow key={item.id} rowData={item} targetSubAssetClasses={statusData?.target_sub_asset_classes} />
              ))}
            </TableBody>
            <TableBody>
              <TableSubHeader>
                <PortfolioWrapper>
                  <CellKey bold>Total</CellKey>
                  <CellValue bold>
                    <BasicTooltip
                      tooltipContent={`${(drawdownTotals.portfolioPercentage + evergreenTotals.portfolioPercentage).toFixed(2)}%`}
                      zIndex={1000}
                    >
                      {(drawdownTotals.portfolioPercentage + evergreenTotals.portfolioPercentage).toFixed(0)}%
                    </BasicTooltip>
                  </CellValue>
                </PortfolioWrapper>
                <PrivateCapitalPercentage>
                  <CellValue bold color={theme.font.weak}>
                    <BasicTooltip
                      tooltipContent={`${(drawdownTotals.privateCapitalPercentage + evergreenTotals.privateCapitalPercentage).toFixed(2)}%`}
                      zIndex={1000}
                    >
                      {(drawdownTotals.privateCapitalPercentage + evergreenTotals.privateCapitalPercentage).toFixed(0)}%
                    </BasicTooltip>
                  </CellValue>
                </PrivateCapitalPercentage>
              </TableSubHeader>
            </TableBody>
          </>
        ) : (
          <></>
        )}
      </TableBodyWrapper>
    </>
  );
};

export default Table;

const EditIconWrap = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-bottom: 10px;
  color: ${({ theme }) => theme.font.action};
`;

const EditButton = styled.div`
  display: flex;
  gap: 4px;
  cursor: pointer;
`;

const TableBodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 13px;
`;

const TableBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3px;
`;

const TableHeaderRow = styled.div`
  display: flex;
  gap: 3px;
  color: ${({ theme }) => theme.header.action};
  background-color: ${({ theme }) => theme.header.base};
`;

const TableSubHeader = styled.div`
  display: flex;
  gap: 3px;
`;

const AssetClassHeaderItem = styled.div`
  font-weight: 700;
  padding: 5px 20px;
  white-space: nowrap;
`;

const PortfolioHeaderItem = styled.div`
  font-weight: 700;
  margin-left: auto;
  padding: 5px 20px;
  white-space: nowrap;
`;

const PcHeaderItem = styled.div`
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 20px;
  white-space: nowrap;
`;

const TableColumn = styled.div<{ width: string }>`
  font-size: 16px;
  line-height: 22.4px;
  width: ${({ width }) => width};
  display: flex;
  flex-direction: column;
  gap: 3px;
`;

const PortfolioWrapper = styled.div<{ withBgColor?: boolean }>`
  display: flex;
  justify-content: space-between;
  padding: 5px 20px;
  width: 75%;
  background-color: ${({ theme, withBgColor }) => (withBgColor ? theme.layer[1] : 'transparent')};
`;

const PrivateCapitalPercentage = styled.div<{ withBgColor?: boolean }>`
  display: flex;
  justify-content: center;
  padding: 5px 20px;
  width: 25%;
  background-color: ${({ theme, withBgColor }) => (withBgColor ? theme.layer[1] : 'transparent')};
`;

const CellKey = styled.div<{ bold?: boolean; color?: string }>`
  font-weight: ${({ bold }) => (bold ? 600 : 400)};
  color: ${({ color }) => color || 'inherit'};
  word-break: break-all;
`;

const CellValue = styled.div<{ bold?: boolean; color?: string }>`
  font-weight: ${({ bold }) => (bold ? 600 : 400)};
  color: ${({ color }) => color || 'inherit'};
  white-space: nowrap;
`;
