import { gql } from '@apollo/client';

import { ITypeModalWindow } from '../../../types/opportunity/proposed';
import { keySortOrder } from '../../../utils/sort-columns';
import { OPERATIONS_STATUS } from './index';

export const header = [
  {
    width: 12,
    justifyContent: 'flex-start',
    column: 'Entity',
    order: 'ASC' as keySortOrder,
    key: 'entity',
  },
  {
    width: 10,
    justifyContent: 'flex-start',
    column: 'Family',
    order: 'ASC' as keySortOrder,
    key: 'family',
  },
  {
    width: 8,
    justifyContent: 'flex-start',
    column: 'Advisor',
    order: 'ASC' as keySortOrder,
    key: 'advisor',
  },
  {
    width: 14,
    justifyContent: 'flex-start',
    column: 'Amount',
    order: null,
    key: 'amount',
  },
  {
    width: 18,
    justifyContent: 'flex-end',
    column: 'Client Approval Date',
    // order: 'ASC' as keySortOrder,
    key: 'clientApprovalDate',
  },
  {
    width: 14,
    justifyContent: 'flex-end',
    column: 'Last Status date',
    order: 'ASC' as keySortOrder,
    key: 'lastStatusDate',
  },
  {
    width: 14,
    justifyContent: 'flex-start',
    column: 'Last Status',
    order: 'ASC' as keySortOrder,
    key: 'lastStatus',
  },
  {
    width: 20,
    justifyContent: 'flex-start',
    column: 'Update Status',
    order: null,
    key: 'updateStatus',
  },
];

export const allocationsQuery = gql`
  query Allocations(
    $investmentId: String
    $status: String
    $advisorFilter: String
    $lastStatusFilter: String
    $page: Int
    $rowsPerPage: Int
    $sort: String
    $order: String
    $search: String
    $includeProposedInvestments: Boolean
  ) {
    Allocations(
      investmentId: $investmentId
      status: $status
      advisorFilter: $advisorFilter
      lastStatusFilter: $lastStatusFilter
      page: $page
      rowsPerPage: $rowsPerPage
      sort: $sort
      order: $order
      search: $search
      includeProposedInvestments: $includeProposedInvestments
    ) {
      total
      page
      perPage
      allocations {
        id
        allocationStatus
        committedCapital
        reason
        operationsStatus
        clientApprovedAt
        suitability
        updatedAt
        selectedGroupLegalEntity {
          id
          entityName
        }
        investment {
          name
        }
        legalEntity {
          entityName
          id
          isGroupEntity
          grouped
          entities {
            id
            entityName
            outstandingAllocations {
              allocationStatus
              committedCapital
              assetClass
            }
          }
          outstandingAllocations {
            committedCapital
            assetClass
          }
          alwaysShow
          family {
            name
          }
          advisor {
            initials
          }
        }
      }
    }
  }
`;

export const updateAllocationMutation = gql`
  mutation updateAllocations($data: [AllocationEntityUpdateDto!]!) {
    updateAllocations(data: $data) {
      id
      allocationStatus
    }
  }
`;

export const statusInvestments = [
  { status: 'Ready for client review' },
  { status: 'Sent to client' },
  { status: 'Client approved' },
  { status: 'Subdoc generated' },
  { status: 'Subdoc sent to client' },
  { status: 'Subdoc signed by client' },
  { status: 'Subdoc sent to fund manager' },
  { status: 'Fund manager accepted' },
  { status: 'Committed' },
  { status: 'Terminate Proposal' },
];
export const listOfStatusWhenAllowToEditAmount = ['Ready for client review', 'Recommendation sent to client', 'Client approved'];
export const initialSelectorsOptions = { status: 'All Statuses', advisors: 'All Advisors' };
export const initialModalWindowValuesOptions = {
  isOpen: false,
  selector: 'Select',
  type: null as ITypeModalWindow,
  currentRowId: '',
};
