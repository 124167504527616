import { NotesIcon } from '@/assets/icons/menu-icons/notes';
import { SortIcon } from '@/assets/icons/sortIcon';
import BasicTooltip from '@/components/basicComponents/tooltip';
import FormattingTooltip from '@/components/basicComponents/tooltip/dollarsTooltip';
import { INotesData } from '@/components/client-details/types';
import { useAuth } from '@/hooks/use-auth';
import { useResponsive } from '@/hooks/use-responsive';
import guid from '@/utils/guid';
import { useEffect, useMemo, useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { IInvestor } from './types';

interface IInvestorDetailsProps {
  data: IInvestor | undefined;
  isDetailsOpen: boolean;
  setIsDetailsOpen: () => void;
  onNotesClick: (data?: INotesData[]) => void;
  setIsNotesOpen: () => void;
}

const Details = ({ data, isDetailsOpen, setIsDetailsOpen, onNotesClick, setIsNotesOpen }: IInvestorDetailsProps) => {
  const { isMobile } = useResponsive();
  const { user } = useAuth();
  const theme = useTheme();

  const [detailsData, setDetailsData] = useState<IInvestor>();
  const detailsDataMemo = useMemo(() => {
    return data ? data : detailsData;
  }, [data]);

  useEffect(() => {
    if (data) {
      setDetailsData(data);
    }
  }, [data]);

  const parseNotes = (notes: string) => {
    try {
      return JSON.parse(notes);
    } catch (error) {
      return null;
    }
  };

  const notesLength = useMemo(() => {
    const parsedNotes = detailsDataMemo?.notes
      .map((note) => {
        return parseNotes(note.notes);
      })
      .filter((note) => note);

    if (!parsedNotes) return;

    let totalLength = 0;
    parsedNotes.forEach((subArr) => {
      totalLength += subArr.length;
    });

    return totalLength;
  }, [data]);

  const showTotalEntityValue = useMemo(() => {
    return !detailsDataMemo?.legalEntities.filter((entity) => entity.ignoreAllocationTargets).length;
  }, [data]);

  if (!detailsDataMemo) {
    return <></>;
  }

  return (
    <DetailsWrap isMobile={isMobile} isOpen={isDetailsOpen}>
      <DetailsBoby isMobile={isMobile} isOpen={isDetailsOpen}>
        <ExpandableWrapp isMobile={isMobile} isOpen={isDetailsOpen}>
          <CardsContainer isMobile={isMobile} isOpen={isDetailsOpen}>
            <Card isMobile={isMobile}>
              {showTotalEntityValue ? (
                <CardTitle>
                  <FormattingTooltip zIndex={120}>{detailsDataMemo?.totalValue}</FormattingTooltip>
                </CardTitle>
              ) : (
                <CardTitle>--</CardTitle>
              )}
              <CardDesc>TOTAL ENTITY VALUE</CardDesc>
            </Card>
            <Card isMobile={isMobile}>
              <CardTitle>
                <FormattingTooltip zIndex={120}>{detailsDataMemo.totalCommitments}</FormattingTooltip>
              </CardTitle>
              <CardDesc>COMMITTED CAPITAL</CardDesc>
            </Card>
            <Card isMobile={isMobile}>
              <CardTitle>
                <FormattingTooltip zIndex={120}>{detailsDataMemo.economicExposure}</FormattingTooltip>
              </CardTitle>
              <CardDesc>ECONOMIC EXPOSURE</CardDesc>
            </Card>
            {notesLength ? (
              <Card
                isMobile={isMobile}
                id="openNotes"
                onClick={isDetailsOpen && notesLength ? setIsNotesOpen : () => null}
                short
                isOpen={isDetailsOpen}
                notesLength={notesLength}
              >
                <NotesCounterWrap id="openNotes" onClick={isMobile ? () => onNotesClick(detailsDataMemo.notes) : () => null}>
                  <NotesIcon id="openNotes" width={24} height={24} fill={theme.action.primary} />
                  <NotesCounter id="openNotes">{notesLength || 0}</NotesCounter>
                </NotesCounterWrap>
                <NotesTitle id="openNotes">Notes</NotesTitle>
              </Card>
            ) : (
              <></>
            )}
          </CardsContainer>

          <MoreDetails isMobile={isMobile} isOpen={isDetailsOpen}>
            <MoreDetailItem isMobile={isMobile}>
              {user && user.tenant.type === 'advisoryFirm' && detailsDataMemo?.alwaysShow && (
                <ItemRow>
                  <ItemTitle>Always Show</ItemTitle>
                  <ItemValue>Yes</ItemValue>
                </ItemRow>
              )}

              {user && user.tenant.type === 'advisoryFirm' && !detailsDataMemo?.impact.startsWith('No') && (
                <ItemRow>
                  <ItemTitle>Impact</ItemTitle>
                  <ItemValue>{detailsDataMemo?.impact}</ItemValue>
                </ItemRow>
              )}

              <ItemRow>
                <ItemTitle>On Hold</ItemTitle>
                <ItemValue>{detailsDataMemo?.onHold ? 'Yes' : 'No'}</ItemValue>
              </ItemRow>
            </MoreDetailItem>

            <MoreDetailItem isMobile={isMobile}>
              <ItemRow>
                <ItemTitle>Excluded Sectors</ItemTitle>
                <SectorsWrap>
                  {detailsDataMemo?.sectorsToAvoid.filter((sector) => sector !== '').length ? (
                    detailsDataMemo?.sectorsToAvoid
                      .filter((x, i, a) => a.indexOf(x) == i)
                      .map((el) => {
                        if (el.length && el.length > 15) {
                          return (
                            <BasicTooltip tooltipContent={el.trim()}>
                              <SectorsItem key={guid()}>{el.trim().slice(0, 15) + ' ...'}</SectorsItem>
                            </BasicTooltip>
                          );
                        }
                        if (el.length) {
                          return <SectorsItem key={guid()}>{el.trim()}</SectorsItem>;
                        }
                      })
                  ) : (
                    <ItemValue>N/A</ItemValue>
                  )}
                </SectorsWrap>
              </ItemRow>
            </MoreDetailItem>
          </MoreDetails>
        </ExpandableWrapp>
      </DetailsBoby>

      <ControlDetailsWrapper isMobile={isMobile}>
        <ControlDetails onClick={setIsDetailsOpen}>
          <ArrowBtn isRotate={isDetailsOpen}>
            <SortIcon fill={theme.action.primary} />
          </ArrowBtn>
          <ControlDetailsTitle isMobile={isMobile}>{isDetailsOpen ? 'Hide Details' : 'Show Details'}</ControlDetailsTitle>
        </ControlDetails>
      </ControlDetailsWrapper>
    </DetailsWrap>
  );
};

const NotesTitle = styled.div`
  font-family: Blinker, serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 16px;
  color: ${({ theme }) => theme.action.primary};
  margin-top: 3.5px;
`;

const NotesCounterWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const NotesCounter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -5px;
  bottom: 0;
  right: 0;
  left: 0;
  font-family: Blinker, serif;
  font-size: 13px;
  font-weight: 700;
  line-height: 18px;
  text-align: center;
  color: ${({ theme }) => theme.action.primary};
`;

const ArrowBtn = styled.div<{ isRotate: boolean }>`
  transform: ${({ isRotate }) => (isRotate ? 'rotate(180deg)' : 'rotate(0deg)')};
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.3s;
`;

const ControlDetailsTitle = styled.div<{ isMobile: boolean }>`
  font-family: Blinker, serif;
  font-size: ${({ isMobile }) => (isMobile ? '13px' : '16px')};
  font-weight: 600;
  line-height: 22px;
  color: ${({ theme }) => theme.action.primary};
  z-index: 30;
`;

const ControlDetailsWrapper = styled.div<{ isMobile: boolean }>`
  width: auto;
  display: flex;
  justify-content: flex-end;
  align-self: flex-end;
  padding: 10px 0;
  gap: 12.5px;
  z-index: 100;
  margin-top: ${({ isMobile }) => (isMobile ? '15px' : 0)};
`;

const ControlDetails = styled.div`
  display: flex;
  gap: 12.5px;
  cursor: pointer;
`;

const SectorsWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
`;

const SectorsItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px 20px;
  background-color: ${({ theme }) => theme.layer[2]};
  color: ${({ theme }) => theme.font.base};
  border-radius: 12px;
  font-family: Blinker, serif;
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0;
`;

const ItemValue = styled.div`
  font-family: Blinker, serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  color: ${({ theme }) => theme.font.base};
`;

const ItemTitle = styled.div`
  font-family: Blinker, serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  color: ${({ theme }) => theme.font.base};
  white-space: nowrap;
`;

const ItemRow = styled.div`
  display: flex;
  /* width: 310px; */
  gap: 10px;
`;

const MoreDetailItem = styled.div<{ isMobile: boolean }>`
  display: flex;
  flex-direction: column;
  width: ${({ isMobile }) => (isMobile ? '100%' : '50% ')};
  gap: 14px;
`;

const CardsContainer = styled.div<{ isOpen: boolean; isMobile: boolean }>`
  display: flex;
  justify-content: space-between;
  gap: 10px;
  flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};
  height: ${({ isOpen, isMobile }) => (isMobile && isOpen ? '350px' : isOpen ? '80px' : '0px')};
`;

const MoreDetails = styled.div<{ isOpen: boolean; isMobile: boolean }>`
  display: flex;
  gap: ${({ isMobile }) => (isMobile ? '14px' : '0')};
  flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};
  padding: 15px 30px;
  height: ${({ isMobile }) => (isMobile ? '190px' : 'auto')};
`;

const CardTitle = styled.div`
  color: ${({ theme }) => theme.font.strong};
  font-family: Blinker, serif;
  font-size: 23px;
  font-weight: 700;
  line-height: 32px;
  text-align: center;
`;

const CardDesc = styled.div`
  color: ${({ theme }) => theme.font.weak};
  font-family: Blinker, serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0;
  text-align: center;
`;

const Card = styled.div<{ isMobile: boolean; short?: boolean; isOpen?: boolean; notesLength?: number }>`
  display: flex;
  cursor: ${({ short, isOpen, notesLength }) => (short && isOpen && notesLength ? 'pointer' : 'default')};
  width: ${({ short, isMobile }) => (isMobile ? '100%' : short ? '5.3%' : '30.9%')};
  height: 80px;
  min-height: 80px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.layer.base};
  border-radius: 10px;
`;

const DetailsWrap = styled.div<{ isOpen: boolean; isMobile: boolean }>`
  width: calc(100%);
  padding: ${({ isMobile }) => (isMobile ? '10px 20px 0 20px' : '20px 60px 0 60px')};
  background-color: ${({ theme }) => theme.layer[1]};
  border-bottom: 1px solid ${({ theme }) => theme.border.base};
`;

const DetailsBoby = styled.div<{ isOpen: boolean; isMobile: boolean }>`
  opacity: ${({ isOpen }) => (isOpen ? '1' : '0')};
  display: grid;
  grid-template-rows: ${({ isOpen }) => (isOpen ? '1fr' : '0fr')};
  overflow: ${({ isOpen }) => (isOpen ? 'visible' : 'hidden')};
  transition: grid-template-rows 300ms;
`;

const ExpandableWrapp = styled.div<{ isMobile: boolean; isOpen: boolean }>`
  min-height: 0;
`;

export default Details;
