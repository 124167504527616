import { getUTCDate } from '@/components/allocations-page/utils';
import { useStatusData } from '@/hooks/useStatusData';
import { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { AssetClassDrawdownTargetsDto } from '../types';
import { AssetClassRow } from './assetClassRow';

interface AssetClassTableProps {
  assetClassTableData?: AssetClassDrawdownTargetsDto | null;
}

export interface IZeroStateAssetClassTableData {
  name: string;
  portfolioPercentageTarget: number;
  portfolioPercentageActual: number;
  subAssetClassDrawdownTargets: IZeroStateSubAssetClassTableData[];
}

interface IZeroStateSubAssetClassTableData {
  name: string;
  portfolioPercentageTarget: number;
  portfolioPercentageActual: number;
}

const ZeroStateAssetClassTable = ({ assetClassTableData }: AssetClassTableProps) => {
  const { data: statusData } = useStatusData();

  const [currentDate, setCurrentDate] = useState<string>('');
  const [tableData, setTableData] = useState<IZeroStateAssetClassTableData[]>([]);

  useEffect(() => {
    setCurrentDate(getUTCDate(new Date()).toUpperCase());
  }, []);

  useEffect(() => {
    if (!assetClassTableData) return;

    const updatedTableData = assetClassTableData.targets.map((target) => {
      const updatedSubAssetClasses = target.subAssetClassDrawdownTargets.map((subAssetClass) => {
        const actualSubAssetClass = assetClassTableData.actuals.find((actual) => actual.name === subAssetClass.name);
        return {
          name: subAssetClass.name,
          portfolioPercentageTarget: subAssetClass.portfolioPercentage,
          portfolioPercentageActual: actualSubAssetClass?.portfolioPercentage ?? 0
        };
      });

      const actual = assetClassTableData.actuals.find((actual) => actual.name === target.name);
      return {
        name: target.name,
        portfolioPercentageTarget: target.portfolioPercentage,
        portfolioPercentageActual: actual?.portfolioPercentage ?? 0,
        subAssetClassDrawdownTargets: updatedSubAssetClasses
      };
    });

    setTableData(updatedTableData);
  }, [assetClassTableData]);

  const rowSum = useMemo(() => {
    const sum = {
      total: 'Total',
      portfolioPercentageTarget: 0,
      portfolioPercentageActual: 0
    };
    for (let i = 0; i < tableData?.length; i++) {
      sum.portfolioPercentageTarget += tableData[i].portfolioPercentageTarget;
      sum.portfolioPercentageActual += tableData[i].portfolioPercentageActual;
    }
    return sum;
  }, [tableData]);

  if (!assetClassTableData) return <></>;

  return (
    <SimpleTableWrap>
      <SimpleTableHeader>
        <HeaderItem width={'36%'}>ASSET CLASS</HeaderItem>
        <HeaderItem width={'37%'}>TARGET</HeaderItem>
        <HeaderItem width={'37%'}>AS OF {currentDate}</HeaderItem>
      </SimpleTableHeader>
      <SimpleTableBody>
        {tableData.map((assetClass) => (
          <AssetClassRow
            key={assetClass.name + assetClass.portfolioPercentageTarget}
            assetClass={assetClass}
            targetSubAssetClasses={statusData?.target_sub_asset_classes}
          />
        ))}
      </SimpleTableBody>
      <SimpleTableFooter>
        <FooterItem width={'36%'}>TOTAL</FooterItem>
        <FooterItem width={'37%'}>{rowSum.portfolioPercentageTarget.toFixed(2)}%</FooterItem>
        <FooterItem width={'37%'}>{rowSum.portfolioPercentageActual.toFixed(2)}%</FooterItem>
      </SimpleTableFooter>
    </SimpleTableWrap>
  );
};

const SimpleTableBody = styled.div`
  display: flex;
  flex-direction: column;
  /* max-height: 200px;
  overflow-y: auto; */
`;

const FooterItem = styled.div<{ width: string }>`
  width: ${({ width }) => width};
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-family: Blinker, serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0;
  color: ${({ theme }) => theme.font.strong};
`;

const SimpleTableFooter = styled.div`
  display: flex;
  border-radius: 4px;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.layer[2]};
  padding: 5px 24px;
`;

const HeaderItem = styled.div<{ width: string }>`
  width: ${({ width }) => width};
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-family: Blinker, serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0;
  color: ${({ theme }) => theme.header.action};
`;

const SimpleTableHeader = styled.div`
  display: flex;
  border-radius: 4px;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.header.base};
  padding: 5px 24px;
`;

const SimpleTableWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 3px;
`;

export default ZeroStateAssetClassTable;
