import styled, { useTheme } from 'styled-components';

import { MeditationIcon } from '@/assets/static/icons/todo-page/meditation';
import { useResponsive } from '@/hooks/use-responsive';
import { Container } from '../styles';

const EmptyToDo = () => {
  const { isMobile } = useResponsive();
  const theme = useTheme();

  return (
    <Container style={{ paddingRight: 50, paddingLeft: 50 }}>
      <hr style={{ color: theme.border.base }} />
      <Wrapper isMobile={isMobile}>
        <MeditationIcon width={'100%'} />
        <Title isMobile={isMobile}>You’re all caught up!</Title>
        <SubTitle isMobile={isMobile}>Good work on clearing your To Do List. Tasks will show up here as they are assigned to you.</SubTitle>
      </Wrapper>
    </Container>
  );
};

export default EmptyToDo;

const Wrapper = styled.div<{ isMobile?: boolean }>`
  margin-top: ${({ isMobile }) => (isMobile ? '0px' : '60px')};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Title = styled.div<{ isMobile?: boolean }>`
  display: flex;
  align-items: center;
  font-family: 'Lato', serif;
  font-style: normal;
  font-weight: 300;
  font-size: ${({ isMobile }) => (isMobile ? '40px' : '80px')};
  line-height: ${({ isMobile }) => (isMobile ? '40px' : '70px')};
  margin-bottom: ${({ isMobile }) => (isMobile ? '25px' : '0px')};
  text-align: center;
  color: ${({ theme }) => theme.font.weak};
`;

const SubTitle = styled.div<{ isMobile?: boolean }>`
  display: flex;
  align-items: center;
  font-family: 'Lato', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: ${({ isMobile }) => (isMobile ? '28px' : '60px')};
  text-align: center;
  color: ${({ theme }) => theme.font.weak};
`;
