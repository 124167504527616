import * as React from 'react';

export const FilterIcon = ({ ...props }: React.SVGProps<SVGSVGElement>) => (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.25207 1.49833H9.75264L5.99561 6.22274L2.25207 1.49833ZM0.191338 1.20586C1.70765 3.14962 4.50179 6.74917 4.50179 6.74917V11.2486C4.50179 11.4479 4.58096 11.639 4.72187 11.7799C4.86279 11.9208 5.05392 12 5.2532 12H6.75151C6.85018 12 6.9479 11.9806 7.03907 11.9428C7.13023 11.905 7.21306 11.8497 7.28284 11.7799C7.35261 11.7101 7.40795 11.6273 7.44572 11.5362C7.48348 11.445 7.50292 11.3473 7.50292 11.2486V6.74917C7.50292 6.74917 10.2926 3.14962 11.8089 1.20586C11.8951 1.0952 11.9484 0.962512 11.9626 0.82298C11.9769 0.683447 11.9516 0.542721 11.8897 0.416893C11.8277 0.291066 11.7315 0.185209 11.6122 0.111484C11.4929 0.0377595 11.3552 -0.000861532 11.215 1.45812e-05H0.785267C0.645007 -0.000861532 0.507333 0.0377595 0.38801 0.111484C0.268686 0.185209 0.172532 0.291066 0.110557 0.416893C0.0485826 0.542721 0.0232898 0.683447 0.0375762 0.82298C0.0518626 0.962512 0.10515 1.0952 0.191338 1.20586Z"
      fill={props.fill || '#7F7F7F'}
    />
  </svg>
);
