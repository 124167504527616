import { gql } from '@apollo/client';

export const LIST_USERS = gql`
  query listUsers($filters: ListUsersFilterDto!) {
    listUsers(filters: $filters) {
      id
      firstName
      lastName
      initials
      email
      officeNumber
      mobileNumber
      roles
      lastLogin
      tenant {
        id
        name
      }
    }
  }
`;
