import * as React from 'react';

export const InfoIcon = ({ ...props }: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      className={props.className}
      width={props.width || 18}
      height={props.height || 18}
      style={props?.style}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.10378 4.49731H9.90163V6.29514H8.10378V4.49731ZM8.10378 8.09838H9.90163V13.4973H8.10378V8.09838ZM9.00539 1.61912e-06C7.2249 -0.00106614 5.48412 0.526004 4.00325 1.51453C2.52238 2.50305 1.36799 3.90861 0.686133 5.55337C0.0042755 7.19812 -0.174425 9.00815 0.172669 10.7545C0.519764 12.5008 1.37703 14.105 2.63603 15.364C3.89502 16.623 5.49917 17.4802 7.2455 17.8273C8.99183 18.1744 10.8019 17.9957 12.4466 17.3139C14.0914 16.632 15.4969 15.4776 16.4855 13.9968C17.474 12.5159 18.0011 10.7751 18 8.9946C17.9986 6.60952 17.0504 4.32255 15.3639 2.63604C13.6774 0.949539 11.3905 0.00143195 9.00539 1.61912e-06ZM9.00539 16.1968C7.58071 16.1978 6.18773 15.7763 5.00263 14.9856C3.81753 14.1949 2.89357 13.0704 2.34763 11.7545C1.80168 10.4386 1.65827 8.99029 1.93556 7.59285C2.21284 6.19542 2.89836 4.9116 3.90538 3.90382C4.91241 2.89604 6.19572 2.20958 7.59295 1.93124C8.99018 1.65291 10.4386 1.79521 11.7549 2.34016C13.0712 2.88512 14.1964 3.80824 14.988 4.99275C15.7796 6.17725 16.2021 7.56992 16.2021 8.9946C16.1993 10.9029 15.4403 12.7324 14.0914 14.0822C12.7426 15.4321 10.9137 16.1925 9.00539 16.1968Z"
        fill={props.fill || '#F4F5F6'}
      />
    </svg>
  );
};
