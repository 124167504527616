import client from '@/apollo-client';
import FileNotSupported from '@/components/dueDiligence-page/modals/fileNotSupported';
import Header from '@/components/fat-header';
import { GoBackButton } from '@/components/fat-header/goBackButton';
import { PageTitle } from '@/components/fat-header/pageTitle';
import { updateEntityMutation } from '@/components/fat-investors-page/fat-investorDetails-page/fat-entityDetails-page/queries';
import { useResponsive } from '@/hooks/use-responsive';
import { MainWrap } from '@/styles/common';
import guid from '@/utils/guid';
import { useMutation } from '@apollo/client';
import { parse } from 'csv-parse/sync';
import { useState } from 'react';
import styled from 'styled-components';
import Loading from '../fat-modals/loading';
import { EntityCodes } from '../importAllocations/steps/entityCodes';
import { SeriesCodes } from '../importAllocations/steps/seriesCodes';
import { UploadDataFile } from '../importAllocations/steps/uploadDataFile';
import {
  LIST_ENTITIES_QUERY_BASIC,
  LIST_INVESTMENT_VEHICLES_QUERY,
  createInvestmentVehicleMutation,
  importCommitmentsTransactionMutation
} from '../queries';
import { CommitmentError, IEntityCodesTableData, ISeriesCodesTableData, MissingIdError } from '../types';
import { removeDuplicates } from '../utils';
import { transactionsColumnsSectionData, transactionsDescriptionSectionData, transactionsSteps } from './constants';
import { Commitments } from './steps/commitments';
import { ImportCommitmentsTransaction, ImportTransactionsFileData } from './types';

interface ImportAllocationsProps {
  handleCloseImportTransactions: () => void;
  backToTitle?: string;
}

const fileExtensions = ['CSV'];
const allowedTransactionTypes = ['Contribution', 'Distribution', 'Recallable Distribution'];

export const ImportTransactions = ({ handleCloseImportTransactions, backToTitle }: ImportAllocationsProps) => {
  const { isMobile, isTablet } = useResponsive();
  const [importSteps, setImportSteps] = useState(transactionsSteps);
  const [modalWindow, setModalWindow] = useState({ isOpen: false, type: 'not-supported' });
  const [drag, setDrag] = useState(false);
  const [file, setFile] = useState<File | null>(null);

  const [entityCodesTableData, setEntityCodesTableData] = useState<IEntityCodesTableData[]>([]);
  const [seriesCodesTableData, setSeriesCodesTableData] = useState<ISeriesCodesTableData[]>([]);
  const [commitmentsTableData, setCommitmentsTableData] = useState<any>([]);

  const [entityCodesErrors, setEntityCodesErrors] = useState<MissingIdError[]>([]);
  const [seriesCodesErrors, setSeriesCodesErrors] = useState<MissingIdError[]>([]);
  const [commitmentErrors, setCommitmentErrors] = useState<CommitmentError[]>([]);
  const [transactionTypeErrors, setTransactionTypeErrors] = useState<ImportTransactionsFileData[]>([]);

  const [isValidFile, setIsValidFile] = useState(true);
  const [recordsToUpdate, setRecordsToUpdate] = useState<any>([]);
  const [fileData, setFileData] = useState<ImportTransactionsFileData[]>([]);

  const [updateEntity] = useMutation(updateEntityMutation);
  const [createInvestmentVehicle] = useMutation(createInvestmentVehicleMutation);
  const [importCommitmentTransaction, { loading: importCommitmentsTransactionLoading }] = useMutation<{
    importCommitmentsTransaction: ImportCommitmentsTransaction;
  }>(importCommitmentsTransactionMutation, {
    onError: () => {
      setFile(null);
      setIsValidFile(false);
    }
  });

  const allowFileExtensions = (files: FileList | null) => {
    return Array.from(files || []).filter((file: File) => {
      const fileExt = file.name.split('.').pop()?.toLowerCase();
      if ([...fileExtensions.map((item) => item.toLowerCase())].includes(fileExt || '')) {
        return file;
      }
    });
  };

  const importFile = async (file: File) => {
    setIsValidFile(true);

    const text = await file.text();
    const records = parse(text, {
      columns: true,
      skip_empty_lines: true,
      trim: true
    });
    let isValidData = true;

    const gruppedFileData = records.map((record: any) => ({
      entityName: record['Entity Name'],
      advisoryFirm: record['Advisor'],
      seriesName: record['Series Name'],
      investmentCode: record['Series Code'],
      entityCode: record['Entity Code'],
      tradeDate: record['Trade Date'],
      netAmount: Number(record['Investment Amount']),
      totalAmount: Number(record['Total Amount']),
      transactionType: record['Transaction Type']
    }));

    const recordsToUpdate = records.map((record: any) => {
      if (
        !record['Entity Code'] ||
        !record['Series Code'] ||
        !record['Trade Date'] ||
        !record['Investment Amount'] ||
        !record['Total Amount'] ||
        !record['Transaction Type']
      ) {
        setFile(null);
        setIsValidFile(false);
        isValidData = false;
        return;
      }

      return {
        ...(record['Entity Code'] && { entityCode: record['Entity Code'] }),
        ...(record['Series Code'] && { investmentCode: record['Series Code'] }),
        ...(record['Trade Date'] && { tradeDate: record['Trade Date'] }),
        ...(record['Investment Amount'] && { netAmount: Number(record['Investment Amount']) }),
        ...(record['Total Amount'] && { totalAmount: Number(record['Total Amount']) }),
        ...(record['Transaction Type'] && { transactionType: record['Transaction Type'] })
      };
    });
    if (!isValidData) return;
    setFileData(gruppedFileData);
    setRecordsToUpdate(recordsToUpdate);

    const { data } = await importCommitmentTransaction({
      variables: {
        data: {
          save: false,
          data: recordsToUpdate
        }
      }
    });

    const newEntityCodesErrors = data?.importCommitmentsTransaction.errors?.filter((error) =>
      error.message.includes('Missing legal entity for entity code')
    );

    await setEntityCodesStepData(gruppedFileData, newEntityCodesErrors ?? []);
  };

  const onDropHandler = async (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const uploadFiles = e.dataTransfer.files;

    setDrag(false);
    if (!allowFileExtensions(uploadFiles).length) {
      setModalWindow({ isOpen: true, type: 'not-supported' });
      return;
    }
    if (uploadFiles && uploadFiles.length > 0) {
      setFile(uploadFiles[0]);
      importFile(uploadFiles[0]);
    }
  };

  const handleUploadFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const uploadFiles = e.target.files;

    if (!allowFileExtensions(uploadFiles).length) {
      setModalWindow({ isOpen: true, type: 'not-supported' });
      return;
    }
    if (uploadFiles && uploadFiles.length > 0) {
      setFile(uploadFiles[0]);
      importFile(uploadFiles[0]);
    }
  };

  const nextStep = () => {
    const currentStepIndex = importSteps.findIndex((step) => step.status === 'IN_PROGRESS');
    if (currentStepIndex === importSteps.length - 1) return;

    const updatedImportSteps = importSteps.map((step, index) => {
      if (currentStepIndex === index) {
        return { ...step, status: 'COMPLETED' };
      }
      if (currentStepIndex + 1 === index) {
        return { ...step, status: 'IN_PROGRESS' };
      }

      return step;
    });

    setImportSteps(updatedImportSteps);
  };

  const setEntityCodesStepData = async (fileData: ImportTransactionsFileData[], errors: MissingIdError[]) => {
    const { data: listEntities } = await client.query({
      query: LIST_ENTITIES_QUERY_BASIC,
      fetchPolicy: 'network-only',
      variables: {
        data: {
          limit: 1000
        }
      }
    });

    const updatedEntityCodesTableData = fileData.map((record) => {
      const errorEntityCode = errors.find((entityCode) => entityCode.id === record.entityCode);
      const matchedEntity = listEntities.listEntities.data.find((item: any) => item.importId === record.entityCode);

      return {
        id: guid(),
        entityName: record.entityName,
        entityCode: record.entityCode,
        advisoryFirm: record.advisoryFirm,
        entityAssignment: errorEntityCode || !matchedEntity ? 'Select An Entity' : `${matchedEntity.entityName} (${matchedEntity.tenant.name})`,
        entityStatus: errorEntityCode ? 'Required' : 'Assigned',
        changed: false,
        entityId: null
      };
    });

    setEntityCodesErrors(removeDuplicates(errors, 'id'));
    setEntityCodesTableData(removeDuplicates(updatedEntityCodesTableData, 'entityCode'));
  };

  const setSeriesCodesStepData = async (fileData: ImportTransactionsFileData[], errors: MissingIdError[]) => {
    const seriesCodes = recordsToUpdate.map((record: any) => record.investmentCode);

    const { data: investmentVehicleData } = await client.query({
      query: LIST_INVESTMENT_VEHICLES_QUERY,
      fetchPolicy: 'network-only',
      variables: {
        seriesCodes: seriesCodes
      }
    });

    const updatedSeriesCodesTableData = fileData.map((record) => {
      const errorSeriesCode = errors.find((seriesCode) => seriesCode.id === record.investmentCode);
      const matchedInvestment = investmentVehicleData.listInvestmentVehicles.find((item: any) => item.id === record.investmentCode);

      return {
        id: guid(),
        investmentCode: record.investmentCode,
        seriesName: record.seriesName,
        seriesAssignment: errorSeriesCode || !matchedInvestment ? 'Select A Strategy' : matchedInvestment.investment.name,
        seriesStatus: errorSeriesCode ? 'Required' : 'Assigned',
        changed: false,
        investmentId: null
      };
    });

    setSeriesCodesErrors(removeDuplicates(errors, 'id'));
    setSeriesCodesTableData(removeDuplicates(updatedSeriesCodesTableData, 'investmentCode'));
  };

  const setCommitmentsStepData = async (fileData: ImportTransactionsFileData[], errors: CommitmentError[]) => {
    const unrecognizedTransactionTypeErrors: ImportTransactionsFileData[] = [];

    const getCommitmentStatus = (errorCommitment: CommitmentError, row: ImportTransactionsFileData) => {
      if (!allowedTransactionTypes.includes(row.transactionType)) {
        unrecognizedTransactionTypeErrors.push(row);
      }

      if (errorCommitment) return 'Missing Commitment';

      if (!allowedTransactionTypes.includes(row.transactionType)) return 'Unrecognized Transaction Type';

      return 'Validated';
    };

    const updatedCommitmentsTableData = fileData.map((record) => {
      const errorCommitment = errors.find(
        (commitment) => commitment.entityCode === record.entityCode && commitment.investmentCode === record.investmentCode
      );

      return {
        entityName: record.entityName,
        entityCode: record.entityCode,
        investmentCode: record.investmentCode,
        seriesName: record.seriesName,
        tradeDate: record.tradeDate,
        netAmount: Number(record.netAmount),
        totalAmount: Number(record.totalAmount),
        commitmentStatus: getCommitmentStatus(errorCommitment, record),
        transactionType: record.transactionType
      };
    });

    setCommitmentErrors(errors);
    setTransactionTypeErrors(unrecognizedTransactionTypeErrors);
    setCommitmentsTableData(updatedCommitmentsTableData);
  };

  const moveToSeriesCodesStep = async () => {
    setModalWindow({ isOpen: true, type: 'loading' });

    const changedRows = entityCodesTableData.filter((row) => row.changed && row.entityId);
    for await (const row of changedRows) {
      await updateEntity({
        variables: {
          data: {
            id: row.entityId,
            importId: row.entityCode
          }
        }
      });
    }

    const { data } = await importCommitmentTransaction({
      variables: {
        data: {
          save: false,
          data: recordsToUpdate
        }
      }
    });

    const newEntityCodesErrors = data?.importCommitmentsTransaction.errors?.filter((error) =>
      error.message.includes('Missing legal entity for entity code')
    );

    if (newEntityCodesErrors?.length) {
      await setEntityCodesStepData(fileData, newEntityCodesErrors);
      setModalWindow({ isOpen: false, type: 'loading' });
      return;
    }

    const newSeriesCodesErrors = data?.importCommitmentsTransaction.errors?.filter((error) =>
      error.message.includes('Missing investment vehicle for investment code')
    );

    await setSeriesCodesStepData(fileData, newSeriesCodesErrors ?? []);
    setModalWindow({ isOpen: false, type: 'loading' });
    nextStep();
  };

  const moveToCommitmentsStep = async () => {
    setModalWindow({ isOpen: true, type: 'loading' });

    const changedRows = seriesCodesTableData.filter((row) => row.changed && row.investmentId);
    for await (const row of changedRows) {
      await createInvestmentVehicle({
        variables: {
          data: {
            seriesCode: row.investmentCode,
            seriesName: row.seriesName?.replace(` - ${row.investmentCode}`, ''),
            investmentId: row.investmentId
          }
        }
      });
    }

    const { data } = await importCommitmentTransaction({
      variables: {
        data: {
          save: false,
          data: recordsToUpdate
        }
      }
    });

    const newSeriesCodesErrors = data?.importCommitmentsTransaction.errors?.filter((error) =>
      error.message.includes('Missing investment vehicle for investment code')
    );

    if (newSeriesCodesErrors?.length) {
      await setSeriesCodesStepData(fileData, newSeriesCodesErrors);
      setModalWindow({ isOpen: false, type: 'loading' });
      return;
    }

    await setCommitmentsStepData(fileData, data?.importCommitmentsTransaction.commitmentErrors ?? []);
    setModalWindow({ isOpen: false, type: 'loading' });
    nextStep();
  };

  const completeImport = async () => {
    setModalWindow({ isOpen: true, type: 'loading' });

    const { data } = await importCommitmentTransaction({
      variables: {
        data: {
          save: true,
          data: recordsToUpdate
        }
      }
    });

    if (data?.importCommitmentsTransaction.status === 'done') {
      handleCloseImportTransactions();
      setModalWindow({ isOpen: false, type: 'loading' });
      return;
    }

    await setCommitmentsStepData(fileData, data?.importCommitmentsTransaction.commitmentErrors ?? []);
    setModalWindow({ isOpen: false, type: 'loading' });
  };

  const startOver = () => {
    if (!file) return;
    importFile(file);
    setImportSteps(transactionsSteps);
  };

  return (
    <>
      {modalWindow.type === 'not-supported' && (
        <FileNotSupported isOpen={modalWindow.isOpen} onClose={() => setModalWindow({ ...modalWindow, isOpen: false })} />
      )}
      {modalWindow.type === 'loading' && <Loading isOpen={modalWindow.isOpen} />}
      <MainWrap>
        <Header modalControl={<GoBackButton handleClose={handleCloseImportTransactions} backToTitle={backToTitle} />} />
        <PageTitle title="Import Transactions" />
      </MainWrap>
      <MainWrap>
        <PaddingWrap>
          {importSteps[0].status === 'IN_PROGRESS' && (
            <UploadDataFile
              importSteps={importSteps}
              file={file}
              handleUploadFile={handleUploadFile}
              onDropHandler={onDropHandler}
              drag={drag}
              setDrag={setDrag}
              handleClose={handleCloseImportTransactions}
              nextStep={nextStep}
              loading={importCommitmentsTransactionLoading}
              isValidFile={isValidFile}
              descriptionSection={transactionsDescriptionSectionData}
              columnsSection={transactionsColumnsSectionData}
              deleteFile={() => {
                setFile(null);
              }}
            />
          )}
          {importSteps[1].status === 'IN_PROGRESS' && (
            <EntityCodes
              importSteps={importSteps}
              startOver={startOver}
              nextStep={moveToSeriesCodesStep}
              tableData={entityCodesTableData}
              setTableData={setEntityCodesTableData}
              errorEntityCode={entityCodesErrors}
              setErrors={setEntityCodesErrors}
            />
          )}
          {importSteps[2].status === 'IN_PROGRESS' && (
            <SeriesCodes
              importSteps={importSteps}
              startOver={startOver}
              nextStep={moveToCommitmentsStep}
              tableData={seriesCodesTableData}
              setTableData={setSeriesCodesTableData}
              seriesCodesError={seriesCodesErrors}
              setErrors={setSeriesCodesErrors}
            />
          )}
          {importSteps[3].status === 'IN_PROGRESS' && (
            <Commitments
              importSteps={importSteps}
              startOver={startOver}
              nextStep={completeImport}
              commitmentError={commitmentErrors}
              transactionTypeErrors={transactionTypeErrors}
              tableData={commitmentsTableData}
            />
          )}
        </PaddingWrap>
      </MainWrap>
    </>
  );
};

const PaddingWrap = styled.div`
  padding-left: 16px;
  padding-right: 16px;
  @media (min-width: 600px) {
    padding-left: 50px;
    padding-right: 50px;
  }
`;
