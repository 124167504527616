import { ApprovedIcon } from '@/assets/icons/info-icons/approved';
import { DeleteItemIcon } from '@/assets/static/icons/delete-item';
import { OpenFileIcon } from '@/assets/static/icons/open-file';
import styled, { useTheme } from 'styled-components';

interface FileUploadProps {
  fileName: string;
  imageSrc?: string;
  type?: 'document' | 'image';
  openFile?: () => void;
  deleteFile?: () => void;
  downloadFile?: () => void;
}

const FileUpload = ({ fileName, imageSrc, type = 'document', openFile, deleteFile, downloadFile }: FileUploadProps) => {
  const theme = useTheme();

  return (
    <FileUploadWrapper>
      <FileUploadPreview>
        <ApprovedIcon width="23" height="23" fill={theme.font.base} />
        {type === 'document' && <FileName onClick={downloadFile}>{fileName}</FileName>}
        {type === 'image' && (
          <ImagePreviewWrapper onClick={openFile}>
            <ImagePreview src={imageSrc} alt={fileName} />
          </ImagePreviewWrapper>
        )}
      </FileUploadPreview>
      <ActionButtons>
        {openFile && (
          <OpenFileIconWrapper onClick={openFile}>
            <OpenFileIcon fill={theme.font.action} />
          </OpenFileIconWrapper>
        )}
        {deleteFile && (
          <DeleteFileIconWrapper onClick={deleteFile}>
            <DeleteItemIcon fill={theme.context.error} />
          </DeleteFileIconWrapper>
        )}
      </ActionButtons>
    </FileUploadWrapper>
  );
};

export default FileUpload;

const FileUploadWrapper = styled.div`
  padding: 10px 20px;
  border-radius: 5px;
  border: 1px solid ${({ theme }) => theme.action.active};
  background: ${({ theme }) => theme.layer[1]};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const FileUploadPreview = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  padding-left: 5px;
  border-left: 5px solid ${({ theme }) => theme.action.active};
`;

const FileName = styled.p`
  font-size: 16px;
  font-weight: 600;
  line-height: 22.4px;
  cursor: pointer;
  color: ${({ theme }) => theme.font.base};
`;

const ImagePreviewWrapper = styled.div`
  display: block;
  width: 163px;
  height: 100px;
  overflow: hidden;
  cursor: pointer;
`;

const ImagePreview = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const ActionButtons = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const ActionButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  height: 40px;
  width: 40px;
  border-radius: 20px;
  background: ${({ theme }) => theme.layer.base};
`;

const OpenFileIconWrapper = styled(ActionButtonWrapper)`
  border: 1px solid ${({ theme }) => theme.font.action};
`;

const DeleteFileIconWrapper = styled(ActionButtonWrapper)`
  border: 1px solid ${({ theme }) => theme.context.error};
`;
