import styled from 'styled-components';

import ModalWrappedContent from '@/components/fat-basicComponents/modal/modalWrappedContent';
import { useResponsive } from '@/hooks/use-responsive';
import { RecordStatusEnum, TierStatusEnum } from '../dueDiligenceRecord/types';

interface ApproveDiligenceProps {
  onClose: () => void;
  isOpen: boolean;
  updateRecordStatus: (variables: any) => void;
  updateTierStatus: (variables: any) => void;
  currentRow: any;
  recordId: string | null;
}

const ApproveDiligence = ({ onClose, isOpen, updateRecordStatus, updateTierStatus, currentRow, recordId }: ApproveDiligenceProps) => {
  const { isMobile } = useResponsive();

  const saveValue = () => {
    if (recordId) {
      updateTierStatus({
        variables: {
          data: {
            id: currentRow.data.id,
            status: TierStatusEnum.COMPLETED
          }
        }
      });

      updateRecordStatus({
        variables: {
          data: {
            id: recordId,
            status: RecordStatusEnum.APPROVED
          }
        },
        onCompleted: () => {
          onClose();
          window.scrollTo(0, 0);
        }
      });
    }
  };

  return (
    <ModalWrappedContent
      isOpen={isOpen}
      onClose={onClose}
      title={'Approve Diligence'}
      confirmButton={{
        name: `${isMobile ? 'Yes' : 'Yes, Approve Diligence'}`,
        onClick: saveValue
      }}
    >
      <ModalText>Are you sure you want to approve this investment?</ModalText>
    </ModalWrappedContent>
  );
};

export default ApproveDiligence;

const ModalText = styled.p`
  font-weight: 400;
  font-size: 19px;
  line-height: 27px;
  text-align: center;
  color: ${({ theme }) => theme.font.base};
`;
