import { format } from 'date-fns';
import styled, { useTheme } from 'styled-components';

import { ApprovedIcon } from '@/assets/icons/info-icons/approved';
import { CalendarIcon } from '@/assets/icons/info-icons/calendar';
import { EditIcon } from '@/assets/icons/info-icons/edit';
import { User } from '@/types/user';
import { useResponsive } from '../../../hooks/use-responsive';
import { blinkingFrame } from '../../table/styles';
import { NoteButton } from '../noteButton';
import { IChecklistItem, ISection, RecordStatusEnum, TierStatusEnum } from './types';

type SectionStatusProps = {
  currentRow?: ISection | IChecklistItem;
  openNotes: (note: string, updateAt: Date, task: string, assignedBy: User, assignedTo: User) => void;
  openModalWindow: (type: string, row: ISection | IChecklistItem) => void;
  sectionTitle: string;
  isSection?: boolean;
  owner: User;
  prevTierStatus: TierStatusEnum | null;
  tierStatus?: TierStatusEnum;
  loading?: boolean;
  recordStatus: RecordStatusEnum | null;
};

const SectionStatus = ({
  currentRow,
  openNotes,
  openModalWindow,
  sectionTitle,
  isSection = false,
  owner,
  prevTierStatus,
  tierStatus,
  loading = false,
  recordStatus
}: SectionStatusProps) => {
  const { isMobile } = useResponsive();
  const theme = useTheme();

  if (!currentRow) return <></>;

  const isDisabled =
    tierStatus === TierStatusEnum.COMPLETED ||
    tierStatus === TierStatusEnum.REJECTED ||
    prevTierStatus === TierStatusEnum.REJECTED ||
    recordStatus === RecordStatusEnum.APPROVED ||
    recordStatus === RecordStatusEnum.REJECTED;

  return (
    <>
      {currentRow.data.completed ? (
        <AssignedToWrapper isCompletedMobile={isMobile} loading={loading ? 1 : 0}>
          <ApprovedIcon fill={theme.context.success} width={18} height={18} />
          <AssignedTo isCompletedMobile={isMobile} isMobile={isMobile}>
            Completed By:
            <BoldText>{` ${currentRow.data.completedBy?.firstName} ${currentRow.data.completedBy?.lastName} on ${format(
              new Date(currentRow.data.completedAt),
              'LLL d, yyyy'
            )} `}</BoldText>
          </AssignedTo>
        </AssignedToWrapper>
      ) : (
        <AssignedToWrapper isMobile={isMobile} loading={loading ? 1 : 0}>
          <EditAssignedTo isMobile={isMobile} onClick={!isDisabled ? () => openModalWindow('change-assignee', currentRow) : () => null}>
            <CalendarIcon fill={theme.context.warning} />
            <AssignedTo isMobile={isMobile}>
              Assigned To:{' '}
              <RedirectText disabled={isDisabled}>
                {currentRow.data.assignedTo
                  ? currentRow.data.assignedTo.firstName + ' ' + currentRow.data.assignedTo.lastName
                  : owner?.firstName + ' ' + owner?.lastName}
              </RedirectText>
            </AssignedTo>
            {!isDisabled && <EditIcon width={16} height={16} fill={theme.font.action} style={{ cursor: 'pointer', margin: '0 20px 0 6px' }} />}
          </EditAssignedTo>
          {isMobile && !isDisabled && currentRow.data.note && (
            <NoteButton
              openNotes={() =>
                openNotes(currentRow.data.note, currentRow.data.updatedAt, sectionTitle, currentRow.data.assignedBy, currentRow.data.assignedTo)
              }
            />
          )}
          {!isMobile && !isDisabled && (
            <NoteButton
              isVisible={Boolean(currentRow.data.note)}
              openNotes={() =>
                openNotes(currentRow.data.note, currentRow.data.updatedAt, sectionTitle, currentRow.data.assignedBy, currentRow.data.assignedTo)
              }
            />
          )}
        </AssignedToWrapper>
      )}
    </>
  );
};

export default SectionStatus;

export const AssignedToWrapper = styled.div<{
  isMobile?: boolean;
  isCompletedMobile?: boolean;
  loading?: boolean | number;
}>`
  display: flex;
  gap: ${({ isMobile, isCompletedMobile }) => (isMobile || isCompletedMobile ? '10px' : '0')};
  align-items: ${({ isMobile, isCompletedMobile }) => (isMobile || isCompletedMobile ? 'flex-start' : 'center')};
  flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};

  opacity: ${({ loading }) => (loading ? 0.5 : 1)};
  pointer-events: ${({ loading }) => (loading ? 'none' : 'auto')};
  animation: ${({ loading }) => (loading ? blinkingFrame : null)} 1.5s infinite;
`;

export const EditAssignedTo = styled.div<{ isMobile: boolean }>`
  display: flex;
  align-items: center;
  gap: ${({ isMobile }) => (isMobile ? '10px' : '0')};
`;

export const AssignedTo = styled.div<{ isMobile: boolean; isCompletedMobile?: boolean }>`
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  padding-left: ${({ isMobile }) => (isMobile ? '0' : '11px')};
  color: ${({ theme }) => theme.font.base};
  display: flex;
  flex-direction: ${({ isMobile, isCompletedMobile }) => (isMobile && isCompletedMobile ? 'column' : 'row')};
  gap: 3px;
`;

const BoldText = styled.span`
  font-weight: 700;
`;

export const RedirectText = styled.span<{ disabled: boolean }>`
  color: ${({ disabled, theme }) => (disabled ? theme.font.base : theme.font.action)};
  font-weight: 700;
  cursor: ${({ disabled }) => (disabled ? 'auto' : 'pointer')};
`;
