import CheckBox from '@/components/basicComponents/checkbox';
import FilterSelect from '@/components/basicComponents/select';
import { FormFooter } from '@/components/fat-basicComponents/formFooter';
import Input from '@/components/fat-basicComponents/input';
import Label from '@/components/fat-basicComponents/label';
import Header from '@/components/fat-header';
import { GoBackButton } from '@/components/fat-header/goBackButton';
import { PageTitle } from '@/components/fat-header/pageTitle';
import { useResponsive } from '@/hooks/use-responsive';
import { useGoBack } from '@/hooks/useGoBack';
import { MainWrap, PaddingWrap } from '@/styles/common';
import { Controller, useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import { DatePicker } from './datePicker';

const ENTITY_TYPE: string = 'non-individual'; // non-individual || individual

export const GeneralInformationFormPage = () => {
  const { isMobile } = useResponsive();
  const goBack = useGoBack();
  const {
    handleSubmit,
    getValues,
    setValue,
    control,
    setError,
    formState: { errors, isValid },
    reset,
    watch
  } = useForm();

  const [searchParams, setSearchParams] = useSearchParams();

  const backToTitle = searchParams.get('backToTitle') || '';

  const onSubmit = (data: any) => {
    console.log(data);
  };

  return (
    <MainWrap>
      <Header modalControl={<GoBackButton handleClose={() => goBack({ fallBack: '/investors' })} backToTitle={backToTitle} />} />
      <PageTitle title="General Information" />
      <PaddingWrap>
        <form id="generalInformationForm" onSubmit={handleSubmit(onSubmit, () => window.scrollTo(0, 0))}>
          <GeneralInformationWrapper isMobile={isMobile}>
            <TwoColumnWrapper isMobile={isMobile}>
              <Controller
                name="entityName"
                control={control}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <Input
                    label="Legal Entity Name To Be Used On Subdocs"
                    value={value}
                    onChange={(e) => {
                      onChange(e.target.value);
                    }}
                    errorText={error?.message}
                    size="md"
                  />
                )}
              />
            </TwoColumnWrapper>
            {ENTITY_TYPE === 'non-individual' && (
              <TwoColumnWrapper isMobile={isMobile}>
                <div>
                  <Label size="md">Jurisdiction Where Formed</Label>
                  <Controller
                    name="entityName"
                    control={control}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                      <>
                        <CustomSelect selected={'Select'} setSelected={onChange} data={['Select']} width="100%" />
                        {error?.message && <ErrorText>{error.message}</ErrorText>}
                      </>
                    )}
                  />
                </div>
                <DatePicker title="Date Of Formation" nameField="dateOfBirth" control={control} errors={errors} />
              </TwoColumnWrapper>
            )}
            <TwoColumnWrapper isMobile={isMobile}>
              <Controller
                name="entityName"
                control={control}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <Input
                    label="Entity Street Address"
                    value={value}
                    onChange={(e) => {
                      onChange(e.target.value);
                    }}
                    errorText={error?.message}
                    size="md"
                  />
                )}
              />
            </TwoColumnWrapper>
            <TwoColumnWrapper isMobile={isMobile}>
              <TwoColumnWrapper isMobile={isMobile}>
                <Controller
                  name="entityName"
                  control={control}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <Input
                      label="City"
                      value={value}
                      onChange={(e) => {
                        onChange(e.target.value);
                      }}
                      errorText={error?.message}
                      size="md"
                    />
                  )}
                />
                <div>
                  <Label size="md">State</Label>
                  <Controller
                    name="entityName"
                    control={control}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                      <>
                        <CustomSelect selected={'Select'} setSelected={onChange} data={['Select']} width="100%" />
                        {error?.message && <ErrorText>{error.message}</ErrorText>}
                      </>
                    )}
                  />
                </div>
              </TwoColumnWrapper>
              <TwoColumnWrapper isMobile={isMobile}>
                <Controller
                  name="entityName"
                  control={control}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <Input
                      label="Zip"
                      value={value}
                      onChange={(e) => {
                        onChange(e.target.value);
                      }}
                      errorText={error?.message}
                      size="md"
                    />
                  )}
                />
                <div>
                  <Label size="md">Country</Label>
                  <Controller
                    name="entityName"
                    control={control}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                      <>
                        <CustomSelect selected={'Select'} setSelected={onChange} data={['Select']} width="100%" />
                        {error?.message && <ErrorText>{error.message}</ErrorText>}
                      </>
                    )}
                  />
                </div>
              </TwoColumnWrapper>
            </TwoColumnWrapper>
            <PhoneFieldsWrapper isMobile={isMobile}>
              <PhoneFields isMobile={isMobile}>
                <div>
                  <Label size="md">Country Code</Label>
                  <Controller
                    name="entityName"
                    control={control}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                      <>
                        <CustomSelect selected={'Select'} setSelected={onChange} data={['Select']} width="117px" />
                        {error?.message && <ErrorText>{error.message}</ErrorText>}
                      </>
                    )}
                  />
                </div>
                <Controller
                  name="entityName"
                  control={control}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <Input
                      label="Business Phone"
                      value={value}
                      onChange={(e) => {
                        onChange(e.target.value);
                      }}
                      errorText={error?.message}
                      size="md"
                    />
                  )}
                />
              </PhoneFields>
              {ENTITY_TYPE === 'individual' && (
                <PhoneFields isMobile={isMobile}>
                  <div>
                    <Label size="md">Country Code</Label>
                    <Controller
                      name="entityName"
                      control={control}
                      render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <>
                          <CustomSelect selected={'Select'} setSelected={onChange} data={['Select']} width="117px" />
                          {error?.message && <ErrorText>{error.message}</ErrorText>}
                        </>
                      )}
                    />
                  </div>
                  <Controller
                    name="entityName"
                    control={control}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                      <Input
                        label="Home Phone"
                        value={value}
                        onChange={(e) => {
                          onChange(e.target.value);
                        }}
                        errorText={error?.message}
                        size="md"
                      />
                    )}
                  />
                </PhoneFields>
              )}
            </PhoneFieldsWrapper>
            <TwoColumnWrapper isMobile={isMobile}>
              <Controller
                name="entityName"
                control={control}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <Input
                    label="Email"
                    value={value}
                    onChange={(e) => {
                      onChange(e.target.value);
                    }}
                    errorText={error?.message}
                    size="md"
                  />
                )}
              />
              {ENTITY_TYPE === 'non-individual' && (
                <Controller
                  name="entityName"
                  control={control}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <Input
                      label="Contact Person"
                      value={value}
                      onChange={(e) => {
                        onChange(e.target.value);
                      }}
                      errorText={error?.message}
                      size="md"
                    />
                  )}
                />
              )}
            </TwoColumnWrapper>
            {ENTITY_TYPE === 'individual' && (
              <ThreeColumnWrapper isMobile={isMobile}>
                <div>
                  <Label size="md">Tax ID Type</Label>
                  <Controller
                    name="entityName"
                    control={control}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                      <>
                        <CustomSelect selected={'Select'} setSelected={onChange} data={['Select']} width="100%" />
                        {error?.message && <ErrorText>{error.message}</ErrorText>}
                      </>
                    )}
                  />
                </div>
                <Controller
                  name="entityName"
                  control={control}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <Input
                      label="Tax ID"
                      value={value}
                      onChange={(e) => {
                        onChange(e.target.value);
                      }}
                      errorText={error?.message}
                      size="md"
                    />
                  )}
                />
                <DatePicker title="Date Of Birth" nameField="dateOfBirth" control={control} errors={errors} />
              </ThreeColumnWrapper>
            )}
            {ENTITY_TYPE === 'non-individual' && (
              <TwoColumnWrapper isMobile={isMobile}>
                <div>
                  <Label size="md">Tax ID Type</Label>
                  <Controller
                    name="entityName"
                    control={control}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                      <>
                        <CustomSelect selected={'Select'} setSelected={onChange} data={['Select']} width="100%" />
                        {error?.message && <ErrorText>{error.message}</ErrorText>}
                      </>
                    )}
                  />
                </div>
                <Controller
                  name="entityName"
                  control={control}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <Input
                      label="Tax ID"
                      value={value}
                      onChange={(e) => {
                        onChange(e.target.value);
                      }}
                      errorText={error?.message}
                      size="md"
                    />
                  )}
                />
              </TwoColumnWrapper>
            )}
            {ENTITY_TYPE === 'individual' && (
              <ThreeColumnWrapper isMobile={isMobile}>
                <div>
                  <Label size="md">Country of Citizenship</Label>
                  <Controller
                    name="entityName"
                    control={control}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                      <>
                        <CustomSelect selected={'Select'} setSelected={onChange} data={['Select']} width="100%" />
                        {error?.message && <ErrorText>{error.message}</ErrorText>}
                      </>
                    )}
                  />
                </div>
                <Controller
                  name="entityName"
                  control={control}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <Input
                      label="Occupation"
                      value={value}
                      onChange={(e) => {
                        onChange(e.target.value);
                      }}
                      errorText={error?.message}
                      size="md"
                    />
                  )}
                />
                <Controller
                  name="entityName"
                  control={control}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <Input
                      label="Title"
                      value={value}
                      onChange={(e) => {
                        onChange(e.target.value);
                      }}
                      errorText={error?.message}
                      size="md"
                    />
                  )}
                />
              </ThreeColumnWrapper>
            )}
            {ENTITY_TYPE === 'non-individual' && (
              <TwoColumnWrapper isMobile={isMobile}>
                <div>
                  <Label size="md">Fiscal Year End Month</Label>
                  <Controller
                    name="entityName"
                    control={control}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                      <>
                        <CustomSelect selected={'Select'} setSelected={onChange} data={['Select']} width="100%" />
                        {error?.message && <ErrorText>{error.message}</ErrorText>}
                      </>
                    )}
                  />
                </div>
                <div>
                  <Label size="md">Fiscal Year End Day</Label>
                  <Controller
                    name="entityName"
                    control={control}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                      <>
                        <CustomSelect selected={'Select'} setSelected={onChange} data={['Select']} width="100%" />
                        {error?.message && <ErrorText>{error.message}</ErrorText>}
                      </>
                    )}
                  />
                </div>
              </TwoColumnWrapper>
            )}
            <CorrespondenceAddressWrapper>
              <Label size="md">Correspondence Address</Label>
              <Controller
                name="correspondenceAddress"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <CheckboxWrapper id="correspondenceAddress" onClick={onChange}>
                    <CheckBox id="correspondenceAddress" isActive={value} /> Same As Above
                  </CheckboxWrapper>
                )}
              />
            </CorrespondenceAddressWrapper>
            <TwoColumnWrapper isMobile={isMobile}>
              <Controller
                name="entityName"
                control={control}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <Input
                    label="Correspondence Street Address"
                    value={value}
                    onChange={(e) => {
                      onChange(e.target.value);
                    }}
                    errorText={error?.message}
                    size="md"
                  />
                )}
              />
            </TwoColumnWrapper>
            <TwoColumnWrapper isMobile={isMobile}>
              <TwoColumnWrapper isMobile={isMobile}>
                <Controller
                  name="entityName"
                  control={control}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <Input
                      label="City"
                      value={value}
                      onChange={(e) => {
                        onChange(e.target.value);
                      }}
                      errorText={error?.message}
                      size="md"
                    />
                  )}
                />
                <div>
                  <Label size="md">State</Label>
                  <Controller
                    name="entityName"
                    control={control}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                      <>
                        <CustomSelect selected={'Select'} setSelected={onChange} data={['Select']} width="100%" />
                        {error?.message && <ErrorText>{error.message}</ErrorText>}
                      </>
                    )}
                  />
                </div>
              </TwoColumnWrapper>
              <TwoColumnWrapper isMobile={isMobile}>
                <Controller
                  name="entityName"
                  control={control}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <Input
                      label="Zip"
                      value={value}
                      onChange={(e) => {
                        onChange(e.target.value);
                      }}
                      errorText={error?.message}
                      size="md"
                    />
                  )}
                />
                <div>
                  <Label size="md">Country</Label>
                  <Controller
                    name="entityName"
                    control={control}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                      <>
                        <CustomSelect selected={'Select'} setSelected={onChange} data={['Select']} width="100%" />
                        {error?.message && <ErrorText>{error.message}</ErrorText>}
                      </>
                    )}
                  />
                </div>
              </TwoColumnWrapper>
            </TwoColumnWrapper>
          </GeneralInformationWrapper>
          <FormFooter onCancel={() => goBack({ fallBack: '/investors' })} disableSaveButton={false} showRequiredFieldsWarning={false} />
        </form>
      </PaddingWrap>
    </MainWrap>
  );
};

const GeneralInformationWrapper = styled.div<{ isMobile: boolean }>`
  margin-top: 50px;
  padding: ${({ isMobile }) => (isMobile ? '20px' : '40px')};
  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.border.base};
  background-color: ${({ theme }) => theme.layer[1]};
  display: flex;
  flex-direction: column;
  gap: ${({ isMobile }) => (isMobile ? '15px' : '25px')};
`;

const TwoColumnWrapper = styled.div<{ isMobile: boolean }>`
  display: grid;
  grid-template-columns: ${({ isMobile }) => (isMobile ? '1fr' : '1fr 1fr')};
  gap: ${({ isMobile }) => (isMobile ? '15px' : '30px')};
`;

const ThreeColumnWrapper = styled.div<{ isMobile: boolean }>`
  display: grid;
  grid-template-columns: ${({ isMobile }) => (isMobile ? '1fr' : '1fr 1fr 1fr')};
  gap: ${({ isMobile }) => (isMobile ? '15px' : '30px')};
`;

const CustomSelect = styled(FilterSelect)`
  background-color: ${({ theme }) => theme.layer[1]};
  border: 1px solid ${({ theme }) => theme.border.base};
  padding: 10px;
  border-radius: 4px;

  #titleSelect {
    text-transform: none;
    font-family: Blinker;
    font-size: 16px;
    font-weight: 400;
    line-height: 22.4px;
  }

  #dropDownSelect {
    top: 50px;
  }
`;

const ErrorText = styled.p`
  color: ${({ theme }) => theme.context.error};
  font-family: Blinker;
  font-style: normal;
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;
`;

const PhoneFieldsWrapper = styled.div<{ isMobile: boolean }>`
  display: flex;
  flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};
  gap: ${({ isMobile }) => (isMobile ? '15px' : '30px')};
  width: 100%;
`;

const PhoneFields = styled.div<{ isMobile: boolean }>`
  display: flex;
  gap: 10px;
  width: ${({ isMobile }) => (isMobile ? '100%' : '50%')};
`;

const CorrespondenceAddressWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 15px 0 10px 0;
`;

const CheckboxWrapper = styled.div`
  cursor: pointer;
  display: flex;
  gap: 9px;
  color: ${({ theme }) => theme.font.base};
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;
`;
