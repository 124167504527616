import client from '@/apollo-client';
import { AddButtonIcon } from '@/assets/static/icons/add-button-icon';
import { AddPageIcon } from '@/assets/static/icons/add-page-icon';
import { DollarSignCircleIcon } from '@/assets/static/icons/dollar-sign-circle-icon';
import { GearPageIcon } from '@/assets/static/icons/gear-page-icon';
import { HistoryIcon } from '@/assets/static/icons/history-icon';
import { useAuth } from '@/hooks/use-auth';
import { useResponsive } from '@/hooks/use-responsive';
import { MainWrap } from '@/styles/common';
import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import { Divider } from '../basicComponents/selectWithCheckBox';
import Button from '../fat-basicComponents/button';
import SpecialControlHeader from '../fat-dealCatalog-page/fat-switcher/specialControlHeader';
import Header from '../fat-header';
import { LoaderOnWholeScreen } from '../loaders/loader-on-whole-screen';
import { initialValuesFromTable } from '../table/constants';
import FilterPage from '../table/fat-filterPage';
import TablePagination from '../table/tablePagination';
import { ISort, SORT_ORDER } from '../table/types';
import { advisorTableSort, entityTableSort, initialListValue, investorTableSort } from './constatnts';
import { GET_INVESTOR_QUERY_BASIC } from './fat-investorDetails-page/queries';
import AddNewInvestorEntity from './fat-modals/addNewInvestorEntity';
import Table from './fat-table/table';
import {
  ALL_ADVISORY_FIRM_LIST_QUERY,
  GET_TOTAL_LIST_ADVISORS_QUERY,
  GET_TOTAL_LIST_ENTITY_QUERY,
  GET_TOTAL_LIST_INVESTORS_QUERY,
  LIST_ADVISORS_QUERY,
  LIST_ENTITY_QUERY,
  LIST_INVESTORS_QUERY
} from './queries';
import {
  CurrentStatus,
  IListAdvisor,
  IListAdvisorsQuery,
  IListEntity,
  IListEntityQuery,
  IListInvestors,
  IListInvestorsQuery,
  ITenant,
  ListCallback
} from './types';

interface IMobileSort {
  title: string;
  key: string;
  asc: boolean;
}

export const Investors = () => {
  const { user } = useAuth();
  const { isMobile, isTablet, isPortrait } = useResponsive();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const q = searchParams.get('q') || '';
  const viewBy = searchParams.get('viewBy');
  const limit = searchParams.get('limit') || initialValuesFromTable.initialAmountRowsPerPage[0];
  const offset = searchParams.get('offset') || 0;
  const key = searchParams.get('key') || 'TOTAL_COMMITMENTS';
  const asc = searchParams.get('asc') === 'true' || false;
  const position = searchParams.get('position') || 0;
  const primaryFilter = searchParams.get('primaryFilter') || 'All Advisory Firms';
  const switcher = searchParams.get('switcher') === null || searchParams.get('switcher') === 'true';

  const [primaryFilterList, setPrimaryFilterList] = useState<string[]>([]);
  const [viewByList, setViewByList] = useState<string[]>([]);
  const [listAdvisors, setListAdvisors] = useState<ListCallback<IListAdvisor>>({
    ...initialListValue
  });
  const [listInvestor, setListInvestor] = useState<ListCallback<IListInvestors>>({ ...initialListValue });
  const [listEntity, setListEntity] = useState<ListCallback<IListEntity>>({
    ...initialListValue
  });
  const [firmsTotal, setFirmsTotal] = useState<number | null>(null);
  const [investorsTotal, setInvestorsTotal] = useState<number | null>(null);
  const [entitiesTotal, setEntitiesTotal] = useState<number | null>(null);
  const [modalWindow, setModalWindow] = useState({
    isOpen: false,
    type: 'add-new-investor-entity'
  });
  const [mobileSortItems, setMobileSortItems] = useState<IMobileSort[]>([]);
  const [mobileSort, setMobileSort] = useState('');
  const sortItem = mobileSortItems.find((item) => item.title === mobileSort);
  const mobileSortList = mobileSortItems.map((item) => item.title);

  useEffect(() => {
    if (!user) return;

    if (user.tenant.type === 'advisoryFirm') {
      setViewByList(['Investor', 'Entity']);
      // setPrimaryFilter('All Investors');
      setPrimaryFilterList(['All Investors']);
      if (viewBy === 'Investor' || viewBy === 'Entity') return;
      onChangeViewBy('Investor');
      return;
    }

    if (user.tenant.type === 'fundManager') {
      setViewByList(['Advisor Firm', 'Investor', 'Entity']);
      // setPrimaryFilter('All Advisory Firms');
      setPrimaryFilterList(['All Advisory Firms']);
      if (!viewBy) {
        onChangeViewBy('Advisor Firm');
      }
      return;
    }
  }, [user]);

  useEffect(() => {
    if (!viewBy && user) {
      onChangeViewBy(user.tenant.type === 'fundManager' ? 'Advisor Firm' : 'Investor');
      return;
    }
  }, [viewBy]);

  const filteredMobileSortItems = (items: IMobileSort[]) => {
    if (user && user.tenant.type === 'advisoryFirm') {
      return items.filter((item) => item.key !== 'ADVISORY_FIRMS');
    }
    return items;
  };

  useEffect(() => {
    if (!isMobile) return;

    switch (viewBy) {
      case 'Advisor Firm':
        const filteredAdvisoryFirmTableSort = filteredMobileSortItems(advisorTableSort);
        setMobileSortItems(filteredAdvisoryFirmTableSort);
        setMobileSort(filteredAdvisoryFirmTableSort[0].title);
        break;
      case 'Investor':
        const filteredInvestorTableSort = filteredMobileSortItems(investorTableSort);
        setMobileSortItems(filteredInvestorTableSort);
        setMobileSort(filteredInvestorTableSort[0].title);
        break;
      case 'Entity':
        const filteredEntityTableSort = filteredMobileSortItems(entityTableSort);
        setMobileSortItems(filteredEntityTableSort);
        setMobileSort(filteredEntityTableSort[0].title);
        break;
      default:
        break;
    }
  }, [viewBy, isMobile]);

  useEffect(() => {
    if (!isMobile) return;
    getCurrentStatus().refetch({
      data: {
        sortDirection: sortItem?.asc ? SORT_ORDER.ASC : SORT_ORDER.DESC,
        sortBy: sortItem?.key
      }
    });
  }, [mobileSort]);

  const { data: advisoryFirmListData } = useQuery<{ allAdvisoryFirmList: ITenant[] }>(ALL_ADVISORY_FIRM_LIST_QUERY, {
    skip: Boolean(user && user.tenant.type === 'advisoryFirm'),
    onCompleted: ({ allAdvisoryFirmList }) => {
      setPrimaryFilterList(['All Advisory Firms', ...new Set(allAdvisoryFirmList.map((el) => el.name))]);
    }
  });

  const { loading: getTotalListAdvisorsLoading } = useQuery<IListAdvisorsQuery>(GET_TOTAL_LIST_ADVISORS_QUERY, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
    variables: {
      data: {
        sortBy: 'ADVISORY_FIRMS',
        sortDirection: SORT_ORDER.ASC,
        limit: 1,
        includeRecommendations: true
      }
    },
    onCompleted: ({ listFirms }: { listFirms: ListCallback<IListAdvisor> }) => {
      setFirmsTotal(listFirms.total);
    },
    onError: (error) => {
      console.error(error);
    }
  });

  const { loading: getTotalListInvestorsLoading } = useQuery<IListInvestorsQuery>(GET_TOTAL_LIST_INVESTORS_QUERY, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
    variables: {
      data: {
        sortBy: 'INVESTOR',
        sortDirection: SORT_ORDER.ASC,
        limit: 1,
        includeRecommendations: true
      }
    },
    onCompleted: ({ listInvestors }: { listInvestors: ListCallback<IListInvestors> }) => {
      setInvestorsTotal(listInvestors.total);
    },
    onError: (error) => {
      console.error(error);
    }
  });

  const { loading: getTotalListEntityLoading } = useQuery<IListEntityQuery>(GET_TOTAL_LIST_ENTITY_QUERY, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
    variables: {
      data: {
        sortBy: 'ENTITY',
        sortDirection: SORT_ORDER.ASC,
        limit: 1,
        includeRecommendations: true
      }
    },
    onCompleted: ({ listEntities }: { listEntities: ListCallback<IListEntity> }) => {
      setEntitiesTotal(listEntities.total);
    },
    onError: (error) => {
      console.error(error);
    }
  });

  const {
    data: listAdvisorData,
    refetch: listAdvisorRefetch,
    loading: listAdvisorLoading
  } = useQuery<IListAdvisorsQuery>(LIST_ADVISORS_QUERY, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'all',
    skip: viewBy !== 'Advisor Firm',
    variables: {
      data: {
        sortBy: key,
        sortDirection: asc ? SORT_ORDER.ASC : SORT_ORDER.DESC,
        search: q.toLowerCase().trim(),
        limit: Number(limit),
        offset: Number(offset),
        includeRecommendations: switcher,
        filter: ['All Advisory Firms'].includes(primaryFilter) ? null : primaryFilter
      }
    },
    onCompleted: ({ listFirms }: { listFirms: ListCallback<IListAdvisor> }) => {
      setListAdvisors(listFirms);
      makeScroll(position);
    },
    onError: (error) => {
      console.error(error);
      if (listAdvisorData) {
        setListAdvisors(listAdvisorData.listFirms || { ...initialListValue });
      }
    }
  });

  const {
    data: listInvestorsData,
    refetch: listInvestorsRefetch,
    loading: listInvestorsLoading
  } = useQuery<IListInvestorsQuery>(LIST_INVESTORS_QUERY, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'all',
    skip: viewBy !== 'Investor',
    variables: {
      data: {
        sortBy: key,
        sortDirection: asc ? SORT_ORDER.ASC : SORT_ORDER.DESC,
        search: q.toLowerCase().trim(),
        limit: Number(limit),
        offset: Number(offset),
        includeRecommendations: switcher,
        filter: ['All Advisory Firms'].includes(primaryFilter) ? null : primaryFilter
      }
    },
    onCompleted: ({ listInvestors }: { listInvestors: ListCallback<IListInvestors> }) => {
      setListInvestor(listInvestors);
      makeScroll(position);
    },
    onError: (error) => {
      console.error(error);
      if (listInvestorsData) {
        setListInvestor(listInvestorsData.listInvestors || { ...initialListValue });
      }
    }
  });

  const {
    data: listEntityData,
    refetch: listEntityRefetch,
    loading: listEntityLoading
  } = useQuery<IListEntityQuery>(LIST_ENTITY_QUERY, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'all',
    skip: viewBy !== 'Entity',
    variables: {
      data: {
        sortBy: key,
        sortDirection: asc ? SORT_ORDER.ASC : SORT_ORDER.DESC,
        search: q.toLowerCase().trim(),
        limit: Number(limit),
        offset: Number(offset),
        includeRecommendations: switcher,
        filter: ['All Advisory Firms'].includes(primaryFilter) ? null : primaryFilter
      }
    },
    onCompleted: ({ listEntities }: { listEntities: ListCallback<IListEntity> }) => {
      setListEntity(listEntities);
      makeScroll(position);
    },
    onError: (error) => {
      console.error(error);
      if (listEntityData) {
        setListEntity(listEntityData.listEntities || { ...initialListValue });
      }
    }
  });

  const getCurrentStatus = (): CurrentStatus => {
    switch (viewBy) {
      case 'Advisor Firm':
        return {
          loading: listAdvisorLoading,
          refetch: listAdvisorRefetch,
          total: listAdvisors.total
        };
      case 'Investor':
        return {
          loading: listInvestorsLoading,
          refetch: listInvestorsRefetch,
          total: listInvestor.total
        };
      case 'Entity':
        return {
          loading: listEntityLoading,
          refetch: listEntityRefetch,
          total: listEntity.total
        };
      default:
        return {
          loading: false,
          refetch: () => null as any,
          total: 0
        };
    }
  };
  const openAdvisorFirmDetails = (id: string) => {
    onChangeViewBy('Investor');
    const selectedAdvisor = listAdvisors.data.find((item) => item.id === id)?.name || 'All Advisory Firms';
    onChangePrimaryFilter(selectedAdvisor);
  };

  const openInvestorDetails = async (id: string) => {
    const { data } = await client.query({
      query: GET_INVESTOR_QUERY_BASIC,
      variables: {
        data: {
          id
        }
      }
    });

    if (data.getInvestor.legalEntities.length > 1) {
      saveScrollPostion();
      navigate(`${id}?backToTitle=Investors`);
      return;
    }

    openEntityDetails(id, data.getInvestor.legalEntities[0].id);
  };

  const openEntityDetails = (investorId: string, entityId: string) => {
    saveScrollPostion();
    navigate(`${investorId}/${entityId}?backToTitle=Investors`);
  };

  const openCreateInvestor = () => {
    if (!user) return;
    if (user.tenant.type === 'fundManager') {
      setModalWindow({ isOpen: true, type: 'add-new-investor-entity' });
      return;
    }
    saveScrollPostion();
    navigate(`create-investor?backToTitle=Investors`);
  };

  const toggleSwitcher = () => {
    setSearchParams(
      (prev) => {
        prev.set('switcher', Boolean(!switcher).toString());
        return prev;
      },
      { replace: true }
    );
    saveScrollPostion();
  };

  // const onViewByChange = (value: string) => {
  //   if (user) {
  //     onChangeViewBy(value);
  //     if (primaryFilter !== 'All Investors' && primaryFilter !== 'All Advisory Firms') return;
  //     const { tenant } = user;
  //     if (tenant.type === 'advisoryFirm') {
  //       setPrimaryFilter('All Investors');
  //       return;
  //     }

  //     if (tenant.type === 'fundManager') {
  //       setPrimaryFilter('All Advisory Firms');
  //     }
  //   }
  // };

  const onChangeSearch = (searchValue: string) => {
    setSearchParams(
      (prev) => {
        if (!searchValue) {
          prev.delete('q');
          return prev;
        }
        prev.delete('limit');
        prev.delete('offset');
        prev.set('q', searchValue);
        return prev;
      },
      { replace: true }
    );
  };

  const onChangeViewBy = (viewByValue: string) => {
    setSearchParams(
      (prev) => {
        prev.set('viewBy', viewByValue);
        return prev;
      },
      { replace: true }
    );
  };

  const onChangeSort = (value: ISort) => {
    setSearchParams(
      (prev) => {
        prev.set('key', value.key);
        prev.set('asc', value.asc.toString());
        return prev;
      },
      { replace: true }
    );
  };

  const onChangePrimaryFilter = (filter: string) => {
    setSearchParams(
      (prev) => {
        prev.set('primaryFilter', filter);
        prev.delete('limit');
        prev.delete('offset');
        return prev;
      },
      { replace: true }
    );
  };

  const saveScrollPostion = () => {
    setSearchParams(
      (prev) => {
        prev.set('position', window.scrollY.toString());
        return prev;
      },
      { replace: true }
    );
  };

  const makeScroll = (position: string | number) => {
    if (!position) return;
    setTimeout(() => window.scrollTo({ top: Number(position), behavior: 'smooth' }), 0);
    setSearchParams(
      (prev) => {
        prev.delete('position');
        return prev;
      },
      { replace: true }
    );
  };

  const onChangePaginationData = (limit: number, offset: number) => {
    setSearchParams(
      (prev) => {
        prev.set('limit', limit.toString());
        prev.set('offset', offset.toString());
        return prev;
      },
      { replace: true }
    );
  };

  if (getTotalListAdvisorsLoading || getTotalListInvestorsLoading || getTotalListEntityLoading) {
    return <LoaderOnWholeScreen />;
  }

  if (firmsTotal !== null && firmsTotal === 0 && investorsTotal !== null && investorsTotal === 0 && entitiesTotal !== null && entitiesTotal === 0) {
    return (
      <>
        <MainWrap>
          <Header specialControl={<SpecialControlHeader switcher={switcher} setSwitcher={toggleSwitcher} title="Include Pending Commitments" />} />
        </MainWrap>
        <MainWrap>
          <PaddingWrap>
            <NoInvestorsWrapper>
              <NoInvestorsTitle>
                <img src="/static/investors-page/missing-mag-glass-graphic.png" draggable="false" alt="No Investors" />
                <div>
                  <h2>No Investors Added.</h2>
                  <p>You can start by adding an investor below.</p>
                </div>
              </NoInvestorsTitle>
              <Divider />
              <NoInvestorsSteps>
                <NoInvestorsStep>
                  <AddPageIcon />
                  <p>Add or bulk upload Investors</p>
                </NoInvestorsStep>
                <NoInvestorsStepSeparator />
                <NoInvestorsStep>
                  <GearPageIcon />
                  <p>Provide Investor Documentation</p>
                </NoInvestorsStep>
                <NoInvestorsStepSeparator />
                <NoInvestorsStep>
                  <DollarSignCircleIcon />
                  <p>Request Allocations for desired amount</p>
                </NoInvestorsStep>
                <NoInvestorsStepSeparator />
                <NoInvestorsStep>
                  <HistoryIcon />
                  <p>Check commitment status at any time</p>
                </NoInvestorsStep>
              </NoInvestorsSteps>
              <NoInvestorsButtons>
                <Button size="lg" onClick={() => openCreateInvestor()}>
                  Add Investor
                </Button>
                {/* <Button size="lg" onClick={() => navigate('/settings?action=importData&type=investorEntities')} styleType="outline">
                  Bulk Upload
                </Button> */}
              </NoInvestorsButtons>
            </NoInvestorsWrapper>
          </PaddingWrap>
        </MainWrap>
      </>
    );
  }

  return (
    <>
      {modalWindow.isOpen && modalWindow.type === 'add-new-investor-entity' && (
        <AddNewInvestorEntity
          isOpen={modalWindow.isOpen}
          onClose={() => setModalWindow({ ...modalWindow, isOpen: false })}
          advisoryFirmList={advisoryFirmListData?.allAdvisoryFirmList || []}
        />
      )}
      <MainWrap>
        <Header specialControl={<SpecialControlHeader switcher={switcher} setSwitcher={toggleSwitcher} title="Include Pending Commitments" />} />
      </MainWrap>
      <MainWrap>
        <PaddingWrap>
          <AddButtonWrap>
            <Button onClick={() => openCreateInvestor()} icon={<AddButtonIcon />} size="md">
              Add New Investor
            </Button>
          </AddButtonWrap>
          <CustomFilterPage
            isMobile={isMobile}
            isTablet={isTablet}
            search={{
              value: q,
              onChange: onChangeSearch,
              placeholder: 'Search'
            }}
            {...(user &&
              user.tenant.type === 'fundManager' && {
                primaryFilter: {
                  value: primaryFilter,
                  onChange: onChangePrimaryFilter,
                  selects: primaryFilterList
                }
              })}
            resultsValue={getCurrentStatus().total}
            refetch={getCurrentStatus().refetch}
            viewBySelect={{
              value: viewBy,
              onChange: onChangeViewBy,
              selects: viewByList
            }}
            mobileSort={{
              value: mobileSort,
              onChange: setMobileSort,
              selects: mobileSortList
            }}
          />
          <Table
            sort={{ key, asc }}
            setSort={onChangeSort}
            refetch={() => null}
            loading={getCurrentStatus().loading}
            viewByValue={viewBy}
            openAdvisorFirmDetails={openAdvisorFirmDetails}
            openInvestorDetails={openInvestorDetails}
            openEntityDetails={openEntityDetails}
            changeViewBy={onChangeViewBy}
            listAdvisers={listAdvisors.data}
            listInvestors={listInvestor.data}
            listEntity={listEntity.data}
          />
          <PaginationWrap>
            <TablePagination
              savePagination={onChangePaginationData}
              paginationValues={{
                limit: Number(limit),
                offset: Number(offset),
                total: getCurrentStatus().total
              }}
              refetch={() => null as any}
            />
          </PaginationWrap>
        </PaddingWrap>
      </MainWrap>
    </>
  );
};

const PaddingWrap = styled.div`
  padding-left: 16px;
  padding-right: 16px;
  @media (min-width: 600px) {
    padding-left: 50px;
    padding-right: 50px;
  }
`;

const AddButtonWrap = styled.div`
  margin-top: 20px;
`;

const NoInvestorsWrapper = styled.div`
  margin: 100px;
`;

const NoInvestorsTitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: ${({ theme }) => theme.font.weak};
  padding-bottom: 40px;
`;

const NoInvestorsSteps = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

const NoInvestorsStep = styled.div`
  display: flex;
  width: 200px;
  height: 139px;
  padding: 10px;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  gap: 15px;
  color: ${({ theme }) => theme.font.base};
  text-align: center;
`;

const NoInvestorsStepSeparatorWrapper = styled.div`
  display: flex;
  width: 40px;
  height: 40px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.font.weak};
  & > div {
    width: 20px;
    height: 12.35px;
    flex-shrink: 0;
  }
`;

const NoInvestorsStepSeparator = () => (
  <NoInvestorsStepSeparatorWrapper>
    <div>
      <svg xmlns="http://www.w3.org/2000/svg" width="14" height="21" viewBox="0 0 14 21" fill="none">
        <path d="M0.856689 2.85001L8.48669 10.5L0.856689 18.15L3.20669 20.5L13.2067 10.5L3.20669 0.5L0.856689 2.85001Z" fill="#ABABAB" />
      </svg>
    </div>
  </NoInvestorsStepSeparatorWrapper>
);

const NoInvestorsButtons = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding-top: 40px;
`;

const CustomFilterPage = styled(FilterPage)<{ isMobile: boolean; isTablet: boolean }>`
  position: sticky;
  top: ${({ isMobile, isTablet }) => (isMobile || isTablet ? '50px' : '0')};
  z-index: 9;
  margin-top: 0;
  margin-left: -1px;
  width: calc(100% + 2px);
  padding: ${({ isMobile }) => (isMobile ? '20px 0 0 0' : '20px 0 10px 0')};
`;

const PaginationWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;
