import { format, parse } from 'date-fns';
import styled from 'styled-components';

import Button from '@/components/fat-basicComponents/button';
import FormattingTooltip from '../../basicComponents/tooltip/dollarsTooltip';
import { CommitmentsType } from '../types';

type InfoListProps = {
  data: CommitmentsType;
  title: string;
  handleInvestment: (name: string, id: string) => void;
};

export const InfoList = ({ data, title, handleInvestment }: InfoListProps) => {
  const getBoolean = (value: boolean) => (value ? 'YES' : 'NO');
  return (
    <MoreDataWrap id="modal">
      <MoreDataItem id="modal">
        <MoreDataItemTitle id="modal">Manager</MoreDataItemTitle>
        <MoreDataItemValue id="modal">{data.moreInfo.managerId}</MoreDataItemValue>
      </MoreDataItem>

      <MoreDataItem id="modal">
        <MoreDataItemTitle id="modal">Asset Class</MoreDataItemTitle>
        <MoreDataItemValue id="modal">{data.moreInfo.assetClass}</MoreDataItemValue>
      </MoreDataItem>

      <MoreDataItem id="modal">
        <MoreDataItemTitle id="modal">Next Close</MoreDataItemTitle>
        <MoreDataItemValue id="modal">
          {data.moreInfo.nextClose ? format(parse(data.moreInfo.nextClose, 'yyyy-MM-dd', new Date()), 'LLL d, yyyy').toUpperCase() : 'No date'}
        </MoreDataItemValue>
      </MoreDataItem>

      <MoreDataItem id="modal">
        <MoreDataItemTitle id="modal">Final Close</MoreDataItemTitle>
        <MoreDataItemValue id="modal">
          {data.moreInfo.finalClose ? format(parse(data.moreInfo.finalClose, 'yyyy-MM-dd', new Date()), 'LLL d, yyyy').toUpperCase() : 'No date'}
        </MoreDataItemValue>
      </MoreDataItem>

      <MoreDataItem id="modal">
        <MoreDataItemTitle id="modal">Minimum</MoreDataItemTitle>
        <MoreDataItemValue id="modal">
          <FormattingTooltip>{data.moreInfo.minimum}</FormattingTooltip>
        </MoreDataItemValue>
      </MoreDataItem>

      <MoreDataItem id="modal">
        <MoreDataItemTitle id="modal">UBTI Blocker</MoreDataItemTitle>
        <MoreDataItemValue id="modal">{getBoolean(data.moreInfo.ubtiBlocker)}</MoreDataItemValue>
      </MoreDataItem>

      <MoreDataItem id="modal">
        <MoreDataItemTitle id="modal">SRI</MoreDataItemTitle>
        <MoreDataItemValue id="modal">{getBoolean(data.moreInfo.sri)}</MoreDataItemValue>
      </MoreDataItem>

      <MoreDataItem id="modal">
        <MoreDataItemTitle id="modal">Target IRR</MoreDataItemTitle>
        <MoreDataItemValue id="modal">{data.moreInfo.targetIRR}%</MoreDataItemValue>
      </MoreDataItem>
      <MoreDataItem id="modal">
        <MoreDataItemTitle id="modal">Target Yield</MoreDataItemTitle>
        <MoreDataItemValue id="modal">{data.moreInfo.targetYield}%</MoreDataItemValue>
      </MoreDataItem>
      {data.investmentId && (
        <RedirectButton styleType="outline" size="md" onClick={() => handleInvestment(data.moreInfo.managerId, data.investmentId)}>
          View {title}
        </RedirectButton>
      )}
    </MoreDataWrap>
  );
};

const MoreDataItemValue = styled.div`
  font-family: 'Blinker', serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0;
  text-align: left;
  width: 200px;
  color: ${({ theme }) => theme.font.base};
`;

const MoreDataItemTitle = styled.div`
  font-family: Blinker, serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0;
  text-align: left;
  width: 150px;
  min-width: 150px;
  color: ${({ theme }) => theme.font.base};
`;

const MoreDataItem = styled.div`
  display: flex;
  align-items: center;
  padding: 5px 20px;
  background-color: ${({ theme }) => theme.layer[1]};
  gap: 10px;
  border-radius: 4px;
`;

const MoreDataWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3px;
`;

const RedirectButton = styled(Button)`
  margin-top: 13px;
`;
