import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import styled, { useTheme } from 'styled-components';

import { SelectIcon } from '@/assets/icons/selectIcon';
import Button from '@/components/fat-basicComponents/button';
import { Loader } from '@/components/loaders/loader';
import { useClickOutside } from '@/hooks/useClickOutside';
import { FadeAnimation } from '@/styles/animations';
import Label from '../label';
import BasicTooltip from '../tooltip';

type FilterSelectProps = {
  data: string[];
  selected: string;
  setSelected: (value: string) => void;
  className?: string;
  width?: string; // default width = 204px
  withSearch?: boolean;
  disabled?: boolean;
  required?: boolean;
  label?: string;
  arrow?: React.ReactNode;
  id?: string;
  forbiddenOpen?: boolean;
  longTitle?: boolean;
  openZIndex?: number;
  isMobile?: boolean;
  paddingContainer?: string;
  minHeight?: string;
  fontSize?: string;
  fontWeight?: string;
  textTransform?: string;
  loading?: boolean;
  clearSelectionButton?: {
    defaultValue: string;
    onClick: () => void;
  };
  additionalOption?: React.ReactNode;
};

const FilterSelect = ({
  longTitle,
  data,
  selected,
  setSelected,
  withSearch,
  label,
  required = false,
  disabled = false,
  width = '204px',
  className,
  arrow,
  id,
  forbiddenOpen,
  openZIndex = 10,
  isMobile = false,
  paddingContainer = '0',
  minHeight,
  fontSize,
  fontWeight,
  textTransform = 'capitalize',
  loading,
  clearSelectionButton,
  additionalOption
}: FilterSelectProps) => {
  const theme = useTheme();
  const ref = useRef<HTMLDivElement | null>(null);
  const [isOpenSelect, setIsOpenSelect] = useState(false);
  const [search, setSearch] = useState('');
  const [list, setList] = useState<string[]>(data);

  useEffect(() => {
    setList(data);
  }, [data]);

  useEffect(() => {
    if (!clearSelectionButton || clearSelectionButton.defaultValue === selected) return;
    const updatedList = list.filter((item) => item !== selected);
    setList(updatedList);
  }, [selected, search]);

  const handleSelectCLick = (event: any) => {
    if (disabled || forbiddenOpen) return;
    if (event.target.id !== 'input') {
      setIsOpenSelect(!isOpenSelect);
    }
  };

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearch(value);
    const newList = [...data];
    const filteredList = newList.filter((item) => item.toLowerCase().indexOf(event.target.value.toLowerCase()) !== -1);
    if (value === '' && filteredList.length === 0) {
      setList(data);
    } else {
      setList(filteredList);
    }
  };

  useClickOutside(ref, () => setIsOpenSelect(false));
  return (
    <Container paddingContainer={paddingContainer} width={width}>
      {label && isMobile && <MobileLabel required={required}>{label}</MobileLabel>}
      {label && !isMobile && <Label required={required}>{label}</Label>}
      <FilterWrap className={className} id={id} disabled={disabled} onClick={(event) => handleSelectCLick(event)} ref={ref} minHeight={minHeight}>
        {longTitle && selected.length > 23 ? (
          <BasicTooltip tooltipContent={selected} tooltipWidth={300} position="bottom" zIndex={100}>
            <FilterLongTitle id={'titleSelect'}>{selected}</FilterLongTitle>
          </BasicTooltip>
        ) : (
          <FilterTitle fontSize={fontSize} fontWeight={fontWeight} id={'titleSelect'}>
            {selected}
          </FilterTitle>
        )}
        <SelectIconWrap id={'selectIcon'} isOpen={isOpenSelect}>
          {arrow ?? <SelectIcon fill={theme.action.primary} />}
        </SelectIconWrap>
        <FilterItems openZIndex={openZIndex} width={width} isOpen={isOpenSelect} id={'dropDownSelect'}>
          {withSearch && (
            <SearchWrap id="input">
              <SearchInput id="input" placeholder={'Type Here To Filter Results'} value={search} onChange={onChange} />
              <Divider />
            </SearchWrap>
          )}
          {loading ? (
            <Loader size={20} />
          ) : (
            <>
              {list?.map((el, index) => (
                <PrimaryFilterItem
                  isActive={el === selected}
                  textTransform={textTransform}
                  key={el + index}
                  onClick={() => setSelected(el)}
                  id="dropDownSelectItem"
                >
                  {el}
                </PrimaryFilterItem>
              ))}
              {clearSelectionButton && (
                <ClearSelectionWrapper>
                  <ClearSelection>
                    <Button
                      size="sm"
                      onClick={clearSelectionButton.onClick}
                      styleType={selected === clearSelectionButton.defaultValue ? 'disabled' : 'outline'}
                    >
                      Clear Selection
                    </Button>
                  </ClearSelection>
                </ClearSelectionWrapper>
              )}
            </>
          )}
          {additionalOption && (
            <AdditionalOptionWrapper>
              <AdditionalOption>{additionalOption}</AdditionalOption>
            </AdditionalOptionWrapper>
          )}
        </FilterItems>
      </FilterWrap>
    </Container>
  );
};

const Container = styled.div<{ width: string; paddingContainer: string }>`
  display: flex;
  flex-direction: column;
  padding: ${({ paddingContainer }) => paddingContainer};
  width: ${({ width }) => width};
`;
export const SearchWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 11px 10px 0;
  gap: 11px;
`;

export const SearchInput = styled.input`
  width: 100%;
  border: 1px solid ${({ theme }) => theme.border.base};
  border-radius: 3px;
  padding: 6.5px 8px;
  background-color: ${({ theme }) => theme.layer[1]};
  color: ${({ theme }) => theme.font.strong};

  &:focus {
    outline: none;
  }
`;

export const Divider = styled.div`
  position: relative;
  z-index: 100;
  display: flex;
  background: ${({ theme }) => theme.border.base};
  height: 1px;
  width: 110%;
  margin-left: -20px;
  margin-right: -20px;
`;

const SelectIconWrap = styled.div<{ isOpen: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  transform-origin: center;
  transform: ${({ isOpen }) => (isOpen ? 'rotate(180deg)' : 'rotate(0deg)')};
  transition: all 0.2s linear;
`;

const MobileLabel = styled(Label)`
  font-size: 13px;
  font-weight: 400;
  padding-bottom: 0;
`;
export const FilterItems = styled(FadeAnimation)<{ isOpen: boolean; width: string; openZIndex: number }>`
  max-height: 260px;
  display: flex;
  flex-direction: column;
  position: absolute;
  z-index: ${({ openZIndex }) => openZIndex};
  top: 42px;
  right: 0;
  width: 100%;
  border: ${({ isOpen, theme }) => `${isOpen ? 1 : 0}px solid ${theme.border.base}`};
  background: ${({ theme }) => theme.layer[1]};
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.15);
  border-radius: 3px;
  overflow-y: auto;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    width: 10px;
    left: -100px;
  }

  &::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.layer[2]};
    border: 1px solid ${({ theme }) => theme.border.base};
    padding: 2px;
    border-radius: 10px;
    margin: 6px 0;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.layer[4]};
    background-clip: padding-box;
    border-radius: 10px;
  }
`;

const FilterWrap = styled.div<{ disabled: boolean; minHeight?: string }>`
  display: flex;
  position: relative;
  opacity: ${({ disabled }) => (disabled ? 0.4 : 1)};
  justify-content: space-between;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.border.base};
  border-radius: 3px;
  padding: 6.5px 8px;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  min-height: ${({ minHeight }) => (minHeight ? minHeight : '10px')};
`;

export const FilterTitle = styled.div<{ fontSize?: string; fontWeight?: string }>`
  color: ${({ theme }) => theme.font.strong};
  font-family: Blinker, serif;
  font-size: ${({ fontSize }) => (fontSize ? fontSize : '13px')};
  font-weight: ${({ fontWeight }) => (fontWeight ? fontWeight : '400')};
  line-height: 18px;
  text-align: left;
  text-transform: capitalize;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
`;

const FilterLongTitle = styled(FilterTitle)`
  max-width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const PrimaryFilterItem = styled.div<{ isActive?: boolean; textTransform?: string }>`
  color: ${({ theme }) => theme.font.strong};
  font-family: Blinker, serif;
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;

  width: 100%;
  cursor: pointer;
  padding: 6.5px 8px;
  background: ${({ isActive, theme }) => (isActive ? theme.layer[12] : theme.layer[1])};
  text-transform: ${({ textTransform }) => (textTransform ? textTransform : 'capitalize')};

  &:hover {
    background: ${({ theme }) => theme.layer[12]};
  }
`;

const AdditionalOptionWrapper = styled.div`
  position: sticky;
  bottom: 0;
  background-color: ${({ theme }) => theme.layer[1]};
`;

const AdditionalOption = styled.div`
  padding: 10px 0;
  margin: 0 10px;
  border-top: 1px solid ${({ theme }) => theme.border.base};
`;

const ClearSelectionWrapper = styled.div`
  position: sticky;
  bottom: 0;
  background-color: ${({ theme }) => theme.layer[1]};
`;

const ClearSelection = styled.div`
  position: sticky;
  bottom: 0;
  padding: 8px 0;
  margin: 0 5px -2px 5px;
  display: flex;
  justify-content: flex-end;
  cursor: auto;
  border-top: 1px solid ${({ theme }) => theme.border.base};
`;

// const ClearSelectionButton = styled(Button)`
//   padding: 8px 15px;
//   font-family: Blinker;
//   font-size: 13px;
//   font-style: normal;
//   font-weight: 600;
//   line-height: 18.2px;
//   border: 1px solid ${({ disabled }) => (disabled ? '#DEDEDE' : '#00aeef')};
//   background: ${({ disabled }) => (disabled ? '#dedede' : '#fafafa')};
//   color: ${({ disabled }) => (disabled ? '#ABABAB' : '#00aeef')};
// `;

export default FilterSelect;
