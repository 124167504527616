import Label from '@/components/basicComponents/label';
import RadioButton from '@/components/basicComponents/radioButton';
import styled from 'styled-components';
import { LabelWrap } from './input';

interface ToggleCircleProps {
  data: string;
  label: string;
  tooltip: string;
  name: string;
  handleRadioChange: (value: string) => void;
}

const options = ['No', 'Yes'];

export const ToggleCircle = ({ data, label, tooltip, name, handleRadioChange }: ToggleCircleProps) => {
  return (
    <ToggleWrap>
      <LabelWrap>
        <Label fontSize="16px" required={false} tooltip={tooltip}>
          {label}
        </Label>
      </LabelWrap>
      <RadioButtonWrap>
        {options.map((option, index) => (
          <RadioButton
            key={option + index}
            name={name}
            radioButtonText={option}
            selectedOption={data}
            handleRadioChange={(event) => handleRadioChange(event.target.value)}
          />
        ))}
      </RadioButtonWrap>
    </ToggleWrap>
  );
};

const ToggleWrap = styled.div`
  width: 100%;
`;

const RadioButtonWrap = styled.div`
  display: flex;
`;
