import { format, parse } from 'date-fns';
import styled from 'styled-components';

import Button from '@/components/fat-basicComponents/button';
import FormattingTooltip from '../../basicComponents/tooltip/dollarsTooltip';
import { CommitmentsType } from '../types';

type MobileInfoListProps = {
  data: CommitmentsType;
  onClick: () => void;
};

export const MobileInfoList = ({ data, onClick }: MobileInfoListProps) => {
  const getBoolean = (value: boolean) => (value ? 'YES' : 'NO');
  return (
    <MoreDataWrap>
      <MoreDataItem>
        <MoreDataItemTitle>Manager</MoreDataItemTitle>
        <MoreDataItemValue>{data.moreInfo.managerId}</MoreDataItemValue>
      </MoreDataItem>

      <MoreDataItem>
        <MoreDataItemTitle>Asset Class</MoreDataItemTitle>
        <MoreDataItemValue>{data.moreInfo.assetClass}</MoreDataItemValue>
      </MoreDataItem>

      <MoreDataItem>
        <MoreDataItemTitle>Next Close</MoreDataItemTitle>
        <MoreDataItemValue>
          {data.moreInfo.nextClose ? format(parse(data.moreInfo.nextClose, 'yyyy-MM-dd', new Date()), 'LLL d, yyyy').toUpperCase() : 'No date'}
        </MoreDataItemValue>
      </MoreDataItem>

      <MoreDataItem>
        <MoreDataItemTitle>Final Close</MoreDataItemTitle>
        <MoreDataItemValue>
          {data.moreInfo.finalClose ? format(parse(data.moreInfo.finalClose, 'yyyy-MM-dd', new Date()), 'LLL d, yyyy').toUpperCase() : 'No date'}
        </MoreDataItemValue>
      </MoreDataItem>

      <MoreDataItem>
        <MoreDataItemTitle>Minimum</MoreDataItemTitle>
        <MoreDataItemValue>
          <FormattingTooltip>{data.moreInfo.minimum}</FormattingTooltip>
        </MoreDataItemValue>
      </MoreDataItem>

      <MoreDataItem>
        <MoreDataItemTitle>UBTI Blocker</MoreDataItemTitle>
        <MoreDataItemValue>{getBoolean(data.moreInfo.ubtiBlocker)}</MoreDataItemValue>
      </MoreDataItem>

      <MoreDataItem>
        <MoreDataItemTitle>SRI</MoreDataItemTitle>
        <MoreDataItemValue>{getBoolean(data.moreInfo.sri)}</MoreDataItemValue>
      </MoreDataItem>

      <MoreDataItem>
        <MoreDataItemTitle>Target IRR</MoreDataItemTitle>
        <MoreDataItemValue>{data.moreInfo.targetIRR}%</MoreDataItemValue>
      </MoreDataItem>

      <MoreDataItem>
        <MoreDataItemTitle>Target Yield</MoreDataItemTitle>
        <MoreDataItemValue>{data.moreInfo.targetYield}%</MoreDataItemValue>
      </MoreDataItem>
      {data.investmentId && (
        <RedirectButton styleType="outline" size="md" onClick={onClick}>
          View {data.name}
        </RedirectButton>
      )}
    </MoreDataWrap>
  );
};

const MoreDataItemValue = styled.div`
  display: flex;
  text-align: center;
  justify-content: center;
  font-family: 'Blinker', serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0;
  width: 50%;
  color: ${({ theme }) => theme.font.base};
`;

const MoreDataItemTitle = styled.div`
  font-family: 'Blinker', serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0;
  text-align: left;
  width: 50%;
  min-width: 50%;
  color: ${({ theme }) => theme.font.base};
`;

const MoreDataItem = styled.div`
  display: flex;
  align-items: center;
  padding: 5px 20px;
  background-color: ${({ theme }) => theme.layer[1]};
  gap: 10px;
  border-radius: 4px;
`;

const MoreDataWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3px;
`;

const RedirectButton = styled(Button)`
  margin-top: 13px;
`;
